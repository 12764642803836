import React from "react";
import "./index.css";

const PasswordMeter = ({ password }) => {
  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  function containsUppercase(myString) {
    return /[A-Z]/.test(myString);
  }

  function containsSpecialChars(str) {
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
    return specialChars.test(str);
  }

  function strongPasswordRegex(str) {
    const password_regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/;
    return password_regex.test(str);
  }

  const calculatePasswordStrength = () => {
    let strength = 0;

    if (password) {
      if (password.match(/[a-z]+/)) {
        strength += 1;
      }

      if (containsUppercase(password)) {
        strength += 1;
      }

      if (hasNumber(password)) {
        strength += 1;
      }

      if (containsSpecialChars(password)) {
        strength += 1;
      }

      if (strongPasswordRegex(password)) {
        strength += 1;
      }
    }
    return strength;
  };

  const getStrengthColor = (strength) => {
    if (strength === 0) {
      return "transparent";
    } else if (strength <= 2) {
      return "red";
    } else if (strength <= 4) {
      return "orange";
    } else if (strength >= 5) {
      return "green";
    }
  };

  const strength = calculatePasswordStrength();
  const strengthColor = getStrengthColor(strength);
  return (
    <div className="password_meter_root d-flex align-items-center flex-wrap mb-16">
      {[...Array(10)].map((_, index) => (
        <div
          key={index}
          className="password_meter_box"
          style={{
            background: index < strength * 2 ? strengthColor : "#efefef",
          }}
        />
      ))}
      {strengthColor === "red" ? (
        <div
          className="ml-8 password_metershow_box"
          style={{ background: "#ff7c7c85" }}
        >
          <p className="font_13_600" style={{ color: strengthColor }}>
            Weak Security
          </p>
        </div>
      ) : strengthColor === "green" ? (
        <div
          className="ml-8 password_metershow_box"
          style={{ background: "#CDF1E3" }}
        >
          <p className="font_13_600" style={{ color: strengthColor }}>
            Secure
          </p>
        </div>
      ) : (
        strengthColor === "orange" && (
          <div
            className="ml-8 password_metershow_box"
            style={{ background: "#FBE9B4" }}
          >
            <p className="font_13_600" style={{ color: strengthColor }}>
              Medium Security
            </p>
          </div>
        )
      )}
      <p></p>
    </div>
  );
};

export default PasswordMeter;
