import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CreateTopic from "./Topics/CreateTopic";
import Topics from "./Topics/Topics";
import CreateTutorial from "./Tutorials/CreateTutorial";
import CreateFaq from "./Faqs/CreateFaq";
import Knowledge from "./Knowledgehub";
import CreateKnowledge from "./Knowledgehub/CreateKnowledge";
import Glossary from "./Glossary";
import CreateGlossary from "./Glossary/CreateGlossary";
import getUrlqueries from "../../../Utils/getUrlQueries";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Faqs from "./Faqs/Faqs";
import AllTutorials from "./Tutorials/AllTutorials";
import CreateTag from "./CreateTag/CreateTag";
import CreateAuthor from "./CreateAuthor/CreateAuthor";
import "./index.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Academy = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/admin/dashboard?tab=5&activetab=${newValue}`);
  };
  const activetab = +getUrlqueries("activetab");
  useEffect(() => {
    if (activetab) {
      setValue(activetab);
    } else {
      setValue(0);
    }
  }, [activetab]);
  return (
    <div className="ml-100 mr-50 mt-50">
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="general_root_tabs admin_academy_tab"
          >
            <Tab label="Topics Published" {...a11yProps(0)} />
            <Tab label="Create Topic" {...a11yProps(1)} />
            <Tab label="Create Tutorials" {...a11yProps(2)} />
            <Tab label="Create FAQs" {...a11yProps(3)} />
            <Tab label="Knowledge Hub" {...a11yProps(4)} />
            <Tab label="Create Knowledge Blog" {...a11yProps(5)} />
            <Tab label="Glossary" {...a11yProps(6)} />
            <Tab label="Create Glossary" {...a11yProps(7)} />
            <Tab label="FAQs" {...a11yProps(8)} />
            <Tab label="Tutorials" {...a11yProps(9)} />
            <Tab label="Create Tags" {...a11yProps(10)} />
            <Tab label="Create Author" {...a11yProps(11)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Topics />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <CreateTopic />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <CreateTutorial />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <CreateFaq />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={4}>
          <Knowledge />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={5}>
          <CreateKnowledge />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={6}>
          <Glossary />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={7}>
          <CreateGlossary />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={8}>
          <Faqs />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={9}>
          <AllTutorials />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={10}>
          <CreateTag />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={11}>
          <CreateAuthor />
        </CustomTabPanel>
      </Box>
    </div>
  );
};

export default Academy;
