import React from "react";

const Card = ({ isApproach, imgIcon, title, stepNo, description }) => {
  return (
    <div className="approacth_card_root">
      <div className="approacth_card_head">
        {isApproach ? (
          <div
            className="bg_soft_green_d9f border_radius_5 d-flex align-items-center justify-content-center"
            style={{ padding: "3px 8px" }}
          >
            <p className="font_12_700 color_0094">Step {stepNo}</p>
          </div>
        ) : (
          <img src={imgIcon} alt={title} />
        )}
        <p className="font_16_700 ml-16">{title}</p>
      </div>
      <div className="approacth_card_body">
        <p className="font_16_400 color_57 line_height_24">{description}</p>
      </div>
    </div>
  );
};

export default Card;
