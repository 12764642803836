import React from "react";

const LeftChevronIcon = ({ cc, className, style, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="12"
      fill="none"
      viewBox="0 0 8 12"
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        stroke={cc || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6.5 1l-5 5 5 5"
      ></path>
    </svg>
  );
};

export default LeftChevronIcon;
