import React from "react";

function ControlIcon(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="14"
        fill="none"
        viewBox="0 0 15 14"
      >
        <path
          fill={props.stroke}
          fillRule="evenodd"
          d="M5.992 3.318c0-.452.366-.818.818-.818h7.364a.818.818 0 110 1.636H6.81a.818.818 0 01-.818-.818zM.266 9.865c0-.452.366-.818.818-.818h8.181a.818.818 0 010 1.636H1.084a.818.818 0 01-.818-.818z"
          clipRule="evenodd"
        ></path>
        <path
          fill={props.stroke}
          fillRule="evenodd"
          d="M5.176 3.32a1.636 1.636 0 10-3.272 0 1.636 1.636 0 003.272 0zM3.54.046a3.273 3.273 0 110 6.545 3.273 3.273 0 010-6.545zM13.356 9.866a1.636 1.636 0 10-3.273 0 1.636 1.636 0 003.273 0zM11.72 6.594a3.273 3.273 0 110 6.545 3.273 3.273 0 010-6.545z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default ControlIcon;
