import { ArrowForward, KeyboardArrowLeft } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WarningIcon from "../../../../../Assets/icons/WarningIcon.png";
import InfoSqaureIcon from "../../../../../Assets/icons/InfoSquareImageIcon.png";
import { Button } from "@mui/material";

const AssessmentResult = ({ assessmentQuestion }) => {
  const navigate = useNavigate();
  const [isSubject, setIsSubject] = useState(false);
  const stokeListQ = "Is your company publicly listed on any stock exchange?";
  useEffect(() => {
    if (
      assessmentQuestion[5].question === stokeListQ &&
      assessmentQuestion[5].answer === assessmentQuestion[5].correctAnswer
    ) {
      setIsSubject(true);
    } else {
      let count = 0;
      for (let index = 0; index < assessmentQuestion.length; index++) {
        const element = assessmentQuestion[index];
        if (index === 2 || index === 3 || index === 4) {
          if (element.answer === element.correctAnswer && index !== 2) {
            count++;
          } else if (
            index === 2 &&
            element.correctAnswer.includes(element.answer)
          ) {
            count++;
          }
        }
      }
      if (count >= 2) {
        setIsSubject(true);
      } else {
        setIsSubject(false);
      }
    }
  }, [assessmentQuestion]);
  return (
    <div className="csrd_result_root">
      <div className="row">
        <div className="col-lg-10 col-md-11 col-12">
          <div className="csrd_result_navigate d-flex align-items-center mt-32">
            <p
              className="breadcrumb_text_light_1 pointer"
              onClick={() => navigate("/dashboard?tab=6")}
            >
              <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
              Share
            </p>
            <p className="breadcrumb_text_light_1 ml-6 mr-6">/</p>
            <p
              className="breadcrumb_text_light_1 pointer"
              onClick={() => navigate("/dashboard?tab=6&subtab=0&step=0")}
            >
              reports
            </p>
            <p className="breadcrumb_text_light_1 ml-6 mr-6">/</p>
            <p className="breadcrumb_text_bold_1">CSRD ASSESSMENT</p>
          </div>
          <p className="font_24_600 mt-20">Your CSRD Assessment Results</p>
          <div className="mt-50 csrd_result_reporting_main">
            <div className="csrd_result_reporting_head border-btm_ef p-24">
              <div
                className="subtitle_box border_radius_10 subheading_5 text-black width_fit py_10"
                style={{ backgroundColor: isSubject ? "#FFF0BB" : "#D4F8D3" }}
              >
                {isSubject
                  ? "Your are subject to CSRD reporting"
                  : "Your are not subject to CSRD reporting"}
              </div>
            </div>
            <div className="p-24 ">
              <div className="csrd_result_reporting_body">
                <img src={WarningIcon} alt="warning" />
                <p className="font_16_400 color_57 mt-6">
                  <span className="fw-700">Important</span> SMEs that are
                  publicly listed, as well as companies based outside the EU
                  with either subsidiaries or securities within the EU, are also
                  mandated to adhere to CSRD regulations (listed SMEs have been
                  granted a timeline extending up to 2026 to ensure compliance
                  with the CSRD.).
                </p>
              </div>
            </div>
          </div>
          <div className="mt-40 csrd_result_defintion_root">
            <div className="d-flex align-items-center">
              <div
                className="bg-color-green font_12_700 color_white width_fit border_radius_5 d-flex align-items-center justify-content-center"
                style={{ padding: "3px 8px" }}
              >
                01
              </div>
              <p className="plus_jakarta_24_800 ml-10">What is CSRD ?</p>
            </div>
            <p className="color_57 font_16_400 mt-10">
              Launched by the European Commission in 2021, the Corporate
              Sustainability Reporting Directive (CSRD) aims to unify
              non-financial reporting among businesses, elevating the uniformity
              and caliber of data accessible to the public. Significantly, these
              updated guidelines will influence a broad spectrum of entities,
              becoming operational from January 1, 2024.
              <br />
              <br />
              The term CSRD refers to the Corporate Sustainability Reporting
              Directive, initiated by the European Commission in April 2021.
              This innovative regulatory structure for sustainability in Europe
              was officially documented on December 16, 2022, within the
              Official Journal of the EU. This directive mandates that large
              enterprises release non-financial disclosures in line with the
              standards set at the European scale (ESRS). The directive
              emphasizes transparency on threats, prospects, and significant
              repercussions concerning social, environmental, and governance
              facets. At its core, the directive aims to realize the goals set
              by the European Green Deal, with the target of achieving carbon
              neutrality by 2050.
            </p>
            <div className="csrd_result_reporting_body mt-10">
              <img src={InfoSqaureIcon} alt="warning" />
              <p className="font_16_400 color_57 mt-6">
                Previously, the non-financial performance reports of European
                businesses fell under the guidance of the{" "}
                <span className="fw-700">NFRD</span> (Non-Financial Reporting
                Directive). Yet, viewed as not being adequately
                forward-thinking, it will be succeeded by the renowned directive
                (EU) 2022/2464, commonly referred to as{" "}
                <span className="fw-700">&quot;CSRD.&quot;</span>
              </p>
            </div>
          </div>
          <div className="mt-40 csrd_result_defintion_root mb-40">
            <div className="d-flex align-items-center">
              <div
                className="bg-color-green font_12_700 color_white width_fit border_radius_5 d-flex align-items-center justify-content-center"
                style={{ padding: "3px 8px" }}
              >
                02
              </div>
              <p className="plus_jakarta_24_800 ml-10">Dive in</p>
            </div>
            <div className="mt-16 csrd_dive">
              <p className="subheading_5">
                Learn more about who is affected, reporting timelines &
                frameworks and the 6-step checklist to be prepared.
              </p>
              <a
                href="https://www.today.green/assessments/csrd-guidelines"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button className="csrd_dive_btn">
                  Dive into CSRD <ArrowForward htmlColor="#fff" />
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssessmentResult;

// document.getElementById("csrd-next-btn-stock").onclick = function () {
//   if (document.getElementById("Yes").checked) {
//     document
//       .getElementById("csrd-submit-btn")
//       .setAttribute("redirect", "/assessments/csrd-guidelines-required");
//     document
//       .getElementById("csrd-submit-btn")
//       .setAttribute("data-redirect", "/assessments/csrd-guidelines-required");
//   } else {
//     let sum = 0;
//     if (document.getElementById("More-than-40-million").checked) sum = sum + 1;
//     if (document.getElementById("More-than-20-million").checked) sum = sum + 1;
//     if (!document.getElementById("250-or-less").checked) sum = sum + 1;
//     if (sum >= 2) {
//       document
//         .getElementById("csrd-submit-btn")
//         .setAttribute("redirect", "/assessments/csrd-guidelines-required");
//       document
//         .getElementById("csrd-submit-btn")
//         .setAttribute("data-redirect", "/assessments/csrd-guidelines-required");
//     } else {
//       document
//         .getElementById("csrd-submit-btn")
//         .setAttribute("redirect", "/assessments/csrd-guidelines-not-required");
//       document
//         .getElementById("csrd-submit-btn")
//         .setAttribute(
//           "data-redirect",
//           "/assessments/csrd-guidelines-not-required"
//         );
//     }
//   }
// };
