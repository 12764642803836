import React, { useEffect, useRef, useState } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { Avatar, CircularProgress, IconButton } from "@mui/material";
import EditIcon from "../../../../Assets/icons/EditIcon";
import DeleteModal from "../../../../Assets/components/DeleteModal/DeleteModal";
import DeleteIcon from "../../../../Assets/icons/DelteIcon";

const CreateAuthor = () => {
  const [authors, setAuthors] = useState([]);
  const [authorName, setAuthorName] = useState("");
  const [designation, setDesignation] = useState("");
  const [designation_de, setDesignation_de] = useState("");
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [logo, setLogo] = useState("");
  const navigate = useNavigate();
  const inputFile = useRef();
  const [isEdit, setIsEdit] = useState({
    status: false,
    id: null,
  });
  const [dltId, setDltId] = useState("");
  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = () => setdltOpen(true);
  const handledltClose = () => setdltOpen(false);

  const getAuthors = async () => {
    await Axios({
      url: `/admin/academy/all-authors`,
      method: "get",
    })
      .then((res) => {
        setAuthors(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };

  const deleteAuthor = async () => {
    if (dltId) {
      await Axios({
        url: `/admin/academy/delete-author/${dltId}`,
        method: "delete",
      })
        .then((res) => {
          if (res.data.success) {
            setDltId(null);
            handledltClose();
            getAuthors();
            toast.success("author deleted successfully");
          }
        })
        .catch((err) => {
          toastResponseError(err);
        });
    }
  };

  const handleCreateAuthor = async () => {
    await Axios({
      url: isEdit.status
        ? `/admin/academy/update-author/${isEdit.id}`
        : `/admin/academy/create-author`,
      method: isEdit.status ? "put" : "post",
      data: {
        image: logo,
        name: authorName,
        designation: designation,
        designation_de: designation_de,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setLogo("");
          setAuthorName("");
          setDesignation("");
          setDesignation_de("")
          getAuthors();
          toast.success("Tag Created");
          setIsEdit({
            status: false,
            id: null,
          });
        }
      })
      .catch((err) => {
        return toastResponseError(err);
      });
  };

  useEffect(() => {
    getAuthors();
  }, []);

  const handlePicUpload = async (e) => {
    const image = e.target.files[0];
    if (image.size > 2048 * 1024) {
      toast.error("File size exceeded 2MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append(`image`, image);
    setIsLogoUploading(true);
    await Axios({
      method: "post",
      url: `/admin/academy/image`,
      data: formdata,
    })
      .then((res) => {
        if (res.data.success) {
          setLogo(res.data.location);
          setIsLogoUploading(false);
          toast.success(`Logo uploaded`);
        }
      })
      .catch((error) => {
        if (error && error.response.status === 401) {
          navigate("/admin/login");
        }
        toastResponseError(error);
      });
  };

  return (
    <div>
      <div className="form-group">
        {isLogoUploading ? (
          <div
            style={{
              height: 72,
              width: 72,
              border: "1px solid #efefef",
              borderRadius: "50%",
            }}
            className="d-flex justify-content-center align-items-center"
          >
            <CircularProgress />
          </div>
        ) : logo ? (
          <div className="position-relative width_fit">
            <Avatar
              src={logo}
              sx={{
                height: 72,
                width: 72,
                border: "1px solid #efefef",
              }}
            />
            <button
              className="profileEditbtn profileImg_upload_btn"
              onClick={() => inputFile.current.click()}
            >
              <EditIcon />
            </button>
            <input
              type="file"
              hidden
              ref={inputFile}
              accept="image/*"
              onChange={handlePicUpload}
            />
          </div>
        ) : (
          <input type="file" accept="image/*" onChange={handlePicUpload} />
        )}
        <input
          type="text"
          value={authorName}
          onChange={(e) => setAuthorName(e.target.value)}
          className="form-control mt-12"
          placeholder="Author Name"
        />
         <input
          type="text"
          value={designation}
          onChange={(e) => setDesignation(e.target.value)}
          className="form-control mt-12"
          placeholder="Designation in English"
        />
        <input
          type="text"
          value={designation_de}
          onChange={(e) => setDesignation_de(e.target.value)}
          className="form-control mt-12"
          placeholder="Designation in German"
        />
       
        {isEdit.status ? (
          <ButtonComp status={2} className="mt-20" onClick={handleCreateAuthor}>
            Update
          </ButtonComp>
        ) : (
          <ButtonComp status={2} className="mt-20" onClick={handleCreateAuthor}>
            Create
          </ButtonComp>
        )}
      </div>
      {authors.length > 0 && (
        <div className="mt-40">
          All Authors
          <div className="row mt-12">
            {authors.map((author) => (
              <div
                key={author._id}
                className="col-lg-3 col-md-4 col-sm-6 col-12"
              >
                <div className="d-flex align-items-center general_card p-14">
                  <img
                    src={author.image}
                    alt={author.name}
                    className="img_40"
                  />
                  <div className="ml-8 flex-1">
                    <p className="font_14_500">{author.name}</p>
                    <p className="font_13_700 mt-6">{author.designation}</p>
                  </div>
                  <div>
                    <IconButton
                      onClick={() => {
                        setLogo(author.image);
                        setAuthorName(author.name);
                        setDesignation(author.designation);
                        setIsEdit({
                          status: true,
                          id: author._id,
                        });
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDltId(author._id);
                        handledltOpen();
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <DeleteModal
        open={dltOpen}
        handleClose={handledltClose}
        confirmFunction={() => deleteAuthor()}
        subtitle={`Once you delete Author, you can't recover it.\n Please be sure before deleting it.`}
        deleteTitle={"Delete Author"}
      />
    </div>
  );
};

export default CreateAuthor;
