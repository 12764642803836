import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../Utils/Auth";
import axios from "../../../../Api/index";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm.js";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { KeyboardArrowDown } from "@mui/icons-material";
import Sepa from "../../../../Assets/images/Sepa.png";
import BankTransfer from "../../../../Assets/images/BankTransfer.png";
import CreditCard from "../../../../Assets/images/CreditCard.png";
import IDEAL from "../../../../Assets/images/iDEAL.png";
import BankContact from "../../../../Assets/images/BankContact.png";
import Sofort from "../../../../Assets/images/Sofort.png";
import EditIcon from "../../../../Assets/icons/EditIcon";
import "./index.css";
import Loader from "../../../../Assets/components/Loader";
import useQuery from "../../../../Utils/query";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import BackForwardButton from "../../../../Assets/components/BackForwardButton/BackForwardButton";
import {
  CreditCardsJson,
  CreditCardsNameJson,
} from "../../../../Utils/CommonFunctions";
import ButtonComp from "../../../../Assets/components/Button";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none",
  borderBottom: "1px solid #E8EBEF",
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDown />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function BillAddress({
  cart,
  callHandleSubmit,
  setcallHandleSubmit,
  setisdisabled,
}) {
  const { user, profile, setSessionExpire, getUser } = useAuth();
  // useEffect(() => {
  //   if (user) {
  //     if (user.isBasePaid && !user.is2FASet) {
  //       history(`/set-two-factor`);
  //     }
  //     if (user.isBasePaid && user.is2FASet) {
  //       history(`/dashboard`);
  //     }
  //   }
  // }, [user]);
  const history = useNavigate();
  const [value, setValue] = React.useState(0);
  const [firstname, setfirstname] = useState(user && user.firstname);
  const [lastName, setlastName] = useState(user && user.lastname);
  const [email, setemail] = useState(user && user.email);
  const [address, setaddress] = useState(null);
  const [companyName, setcompanyName] = useState(
    profile && profile.companyName
  );
  const [street, setstreet] = useState(profile && profile.street);
  const [streetNumber, setstreetNumber] = useState(
    profile && profile.streetNumber
  );
  const [state, setstate] = useState(profile && profile.state);
  const [city, setcity] = useState(profile && profile.city);
  const [zip, setzip] = useState(profile && profile.zip);
  const [country, setcountry] = useState(profile && profile.country);
  const [vat, setVat] = useState("");

  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);

  const [countrycode, setcountrycode] = useState(
    profile && profile.countryCode
  );
  const [statecode, setstatecode] = useState(profile && profile.stateCode);
  const [billingStep, setBillingStep] = useState(0);
  const [billingLoading, setBillingLoading] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(null);
  const [selectBankTransfer, setSelectBankTransfer] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    history(`/register/subscription?step=1&tab=${newValue}`);
  };
  const query = useQuery();
  const tabNo = Number(query.get("tab"));

  useEffect(() => {
    if (tabNo || tabNo === 0) {
      setValue(tabNo);
    }
  }, [tabNo]);

  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
  const [termsCheck, setTermsCheck] = useState(false);

  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const fetchcountries = async () => {
    const response = await axios.get(`/location/countries`);
    setcountries(response.data);
  };
  const fetchstates = async () => {
    const response = await axios.get(`/location/country/${countrycode}`);
    setstates(response.data);
  };

  const fetchcities = async () => {
    const response = await axios.get(
      `/location/cities/${countrycode}/${statecode}`
    );
    setcities(response.data);
  };

  useEffect(() => {
    if (!countries || !countries.length > 0) fetchcountries();
  }, []);

  useEffect(() => {
    if (countrycode) fetchstates();
  }, [countrycode]);

  useEffect(() => {
    if (statecode) fetchcities();
  }, [statecode]);

  const handleSubmit = async () => {
    if (firstname === "" || firstname === null) {
      setcallHandleSubmit(false);
      return toast.error("Please enter first name");
    }
    if (lastName === "" || lastName === null) {
      setcallHandleSubmit(false);
      return toast.error("Please enter last name");
    }
    if (email === "" || email === null) {
      setcallHandleSubmit(false);
      return toast.error("Please enter email");
    }
    if (
      street === "" ||
      street === null ||
      streetNumber === "" ||
      streetNumber === null ||
      country === "" ||
      country === null ||
      state === "" ||
      state === null ||
      city === "" ||
      city === null ||
      zip === "" ||
      zip === null ||
      countrycode === "" ||
      countrycode === null
    ) {
      setcallHandleSubmit(false);
      return toast.error("Please enter full address");
    }
    try {
      setisdisabled(true);
      const response = await axios.post(
        `/address/billing-address/${cart._id}`,
        {
          firstName: firstname,
          lastName: lastName,
          emailAddress: email,
          CompanyName: companyName,
          street: street,
          houseNumber: streetNumber,
          zipCode: zip,
          State: state,
          City: city,
          Country: country,
          VATnumber: vat,
          countryCode: countrycode,
        }
      );
      if (response.data.success) {
        setcallHandleSubmit(false);
        getAddress();
        toast.success("Details Submitted");
        setValue(1);
        setisdisabled(false);
      }
    } catch (error) {
      setisdisabled(false);
      setcallHandleSubmit(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    if (callHandleSubmit) {
      handleSubmit();
    }
  }, [callHandleSubmit]);

  const [options, setoptions] = useState(null);
  // const [clientPayementSecret, setClientPaymentSecret] = useState(null);
  const appearance = {
    theme: "stripe",
    rules: {
      ".Label": {
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "20px",
        textTransform: "uppercase",
        color: "#313B3E",
        marginBottom: "8px",
      },
    },
    // variables: {
    //   colorPrimary: '#000',
    //   colorBackground: '#ffffff',
    //   colorText: '#000',
    //   colorDanger: '#df1b41',
    //   spacingUnit: '2px',
    //   borderRadius: '5px',
    //   // See all possible variables below
    // }
  };
  const handleCardPayment = async (method) => {
    setBillingLoading(true);
    const response = await axios({
      url: `/stripe/payment-intent`,
      method: "get",
      params: {
        methodType: [method],
      },
    });
    setoptions({
      clientSecret: response.data.data.client_secret,
      appearance: appearance,
    });
    setSelectBankTransfer(false);
    setBillingLoading(false);
    setBillingStep(1);
  };

  const getPaymentMethods = async () => {
    await axios({
      url: `/stripe/paymentMethod`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data.data;
          setPaymentMethod(data[0]);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getAddress = async () => {
    await axios({
      url: `/address/address`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setaddress(res.data.data[0]);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const updateBasePaid = async (basePaid, invoiceGenerate) => {
    try {
      const updatedUser = await axios({
        url: `/user/auth/edit/user`,
        method: "put",
        data: {
          isBasePaid: basePaid,
          isInvoiceGenerated: invoiceGenerate,
        },
      });
      if (updatedUser) {
        getUser();
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  // const updateCartInformation = async (
  //   invoiceID,
  //   invoiceLink,
  //   invoicePdfLink,
  //   paymentIntentId,
  //   paymentMethodId
  // ) => {
  //   try {
  //     await axios({
  //       url: `/stripe/update-cart/${cart._id}`,
  //       method: "put",
  //       data: {
  //         invoiceID: invoiceID,
  //         invoiceLink: invoiceLink,
  //         invoicePdfLink: invoicePdfLink,
  //         paymentIntentId: paymentIntentId,
  //         paymentMethodId: paymentMethodId,
  //         purchasedOn: new Date(),
  //       },
  //     });
  //   } catch (error) {
  //     toastResponseError(error, setSessionExpire);
  //   }
  // };

  const updateCredits = async () => {
    try {
      await axios({
        url: `/credit/create-credit/${cart._id}`,
        method: "post",
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  // const updateCartIsPaid = async () => {
  //   try {
  //     await axios({
  //       url: `/stripe/update-cart/${cart._id}`,
  //       method: "put",
  //       data: {
  //         isPaid: true,
  //         purchasedOn: new Date(),
  //       },
  //     });
  //   } catch (error) {
  //     toastResponseError(error, setSessionExpire);
  //   }
  // };

  const generateInvoiceBankTransfer = async () => {
    try {
      setBillingLoading(true);
      let res = await axios({
        url: `/stripe/generate-invoice/${cart._id}`, // update invoice data also on cart
        method: "POST",
        data: { date: new Date() },
      });
      setBillingLoading(false);
      if (res.data.success) {
        await updateBasePaid(false, true);
        const {
          // id: invoiceID,
          // hosted_invoice_url,
          invoice_pdf,
        } = res.data.data;

        // await updateCartInformation(
        //   invoiceID,
        //   hosted_invoice_url,
        //   invoice_pdf,
        //   null,
        //   "bank_transfer"
        // );
        toast.success("Invoice sent you successfully");
        history("/payment/success", {
          state: {
            invoiceUrl: invoice_pdf,
          },
        });
        setValue(2);
        setBillingStep(0);
      }
    } catch (error) {
      setBillingLoading(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  const handleGenerateInvoiceAfterPayment = async (
    paymentIntent_id,
    status
  ) => {
    try {
      setBillingLoading(true);
      let response = await axios({
        url: `/stripe/make/invoice/${cart._id}`, // update cart is paid, add all invoice items,
        method: "POST",
        data: {
          paymentType: paymentMethod.type,
          paymentMethodId: paymentMethod.id,
          paymentIntentId: paymentIntent_id,
          date: new Date(),
          status: status,
        },
      });
      if (response.data.success) {
        if (status === "processing") await updateBasePaid(false, true);
        else await updateBasePaid(true, true);

        // await updateCartIsPaid();
        await updateCredits();
        // setClientPaymentSecret(null);
        toast.success("Payment Successful");
        return history("/payment/success");
      }
      return toast.error("Invoice could not be processed");
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const handlePaymentResponse = async (response) => {
    // alert(response.data.status);

    if (response.data.status === "succeeded") {
      setBillingLoading(false);
      await handleGenerateInvoiceAfterPayment(
        response.data.id,
        response.data.status
      );
    } else if (
      response.data.status === "requires_action" ||
      response.data.status === "requires_confirmation"
    ) {
      // await makePayment(response.data.id);
      var stripe = await stripePromise;
      if (paymentMethod.type === "card") {
        const { error: errorAction, paymentIntent } =
          await stripe.confirmCardPayment(response.data.client_secret);

        if (errorAction) {
          setBillingLoading(false);
          return toast.error("Error in payment, please try again later");
        } else if (paymentIntent.status === "succeeded") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent.id,
            paymentIntent.status
          );
        } else if (
          paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_confirmation"
        ) {
          await makePayment(response.data.id);
        } else {
          setBillingLoading(false);
          toast.error("Please try again later");
        }
      } else if (paymentMethod.type === "sepa_debit") {
        const { error: errorAction, paymentIntent } =
          await stripe.confirmSepaDebitPayment(response.data.client_secret);
        if (errorAction) {
          setBillingLoading(false);
          return toast.error("Error in payment, please try again later");
        } else if (paymentIntent.status === "succeeded") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent.id,
            paymentIntent.status
          );
        } else if (paymentIntent.status === "processing") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent.id,
            paymentIntent.status
          );
        }
      } else {
        setBillingLoading(false);
        toast.error("Please try again later");
      }
    } else {
      setBillingLoading(false);
      toast.error("Please try again later");
    }
  };

  const makePayment = async (paymentIntent_id) => {
    try {
      setBillingLoading(true);
      let res = await axios({
        url: `/stripe/make/payment/${cart._id}`,
        method: "GET",
        params: {
          paymentType: paymentMethod.type,
          paymentMethodId: paymentMethod.id,
          paymentIntent_id: paymentIntent_id,
        },
      });
      if (res.data.success) {
        await handlePaymentResponse(res.data);
        //   res.data.data.status === "requires_action" ||
        //   res.data.data.status === "requires_confirmation"
        // ) {
        //   var stripe = await stripePromise;
        //   const { paymentIntent, error } = await stripe.confirmCardPayment(
        //     res.data.data.clientSecret
        //   );
        //   if (error)
        //     return toast.error("Error in payment, please try again later");
        //   if (paymentIntent.status === "succeeded") {
        //     // return alert(`Payment successful, payment ID - ${res.data.id}`);
        //     setBillingLoading(false);
        //     await updateCartInformation(
        //       invoiceID,
        //       hosted_invoice_url,
        //       invoice_pdf,
        //       paymentIntentId,
        //       payment_method
        //     );
        //     await updateBasePaid(true, true);
        //     await updateCartIsPaid();
        //     await updateCredits();
        //     // setClientPaymentSecret(null);
        //     toast.success("Payment Successful");
        //     history("/payment/success");
        //     setBillingLoading(false);
        //   }
        // } else if (res.data.data.status === "succeeded") {

        // } else {
        //   setBillingLoading(false);
        //   toast.error("Cannot complete the transaction");
        // }
        // // handleOpen();
      }
    } catch (err) {
      setBillingLoading(false);
      toastResponseError(err, setSessionExpire);
    }
  };

  useEffect(() => {
    getPaymentMethods();
    getAddress();
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     if (clientPayementSecret) {
  //       setBillingLoading(true);
  //       var stripe = await stripePromise;
  //       var { paymentIntent, error } =
  //         stripe.confirmPaymentIntent(clientPayementSecret);
  //       if (error) {
  //         setBillingLoading(false);
  //         toast.success(error.message);
  //       } else if (paymentIntent.status === "succeeded") {
  //         await updateBasePaid(true, true);
  //         await updateCartIsPaid();
  //         await updateCredits();
  //         setClientPaymentSecret(null);
  //         toast.success("Payment Successful");
  //         history("/payment/success");
  //         setBillingLoading(false);
  //       }
  //     }
  //   })();
  // }, [clientPayementSecret]);

  useEffect(() => {
    setVat(address?.VATnumber);
    setfirstname(address?.firstName || user?.firstname);
    setlastName(address?.lastName || user?.lastname);
    setemail(address?.emailAddress || user?.email);
    setcompanyName(address?.CompanyName || user?.companyId?.companyName);
    setstreet(address?.street || user?.companyId?.street);
    setstreetNumber(address?.houseNumber || user?.companyId?.streetNumber);
    setcountry(address?.Country || user?.companyId?.country);
    setcountrycode(address?.countryCode || user?.companyId?.countryCode);
    setstate(address?.State || user?.companyId?.state);
    setstatecode(user?.companyId?.stateCode);
    setcity(address?.City || user?.companyId?.city);
    setzip(address?.zipCode || user?.companyId?.zip);
  }, [user, address]);

  // if (user && user.isBasePaid) {
  //   history("/");
  // } else
  return (
    <div>
      {billingLoading && <Loader />}
      <div className="d-flex align-items-center mt-16 mb-16">
        <p className="heading_1">Billing Information</p>
        <div className="subtitle_box width_fit bg_soft_blue ml-20">
          <p className="color_blue font_13_600">Payment</p>
        </div>
      </div>

      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="general_root_tabs"
          >
            <Tab label="1. Billing Information" {...a11yProps(0)} />
            <Tab label="2. Payment" {...a11yProps(1)} />
            <Tab label="3. Complete" {...a11yProps(2)} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} className="general_tabpanel">
          <div className="row mt-24 mb-32">
            <div className="col-6">
              <label className="heading_6 mb-8">FIRST NAME</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="First Name"
                type="text"
                value={firstname}
                onChange={(e) => setfirstname(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label className="heading_6 mb-8">LAST NAME</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
              />
            </div>
            <div className="col-6 mt-16">
              <label className="heading_6 mb-8">E-MAIL ADDRESS</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="E-mail Address"
                type="email"
                value={email}
                onChange={(e) => setemail(e.target.value)}
              />
            </div>
            <div className="col-6 mt-16">
              <label className="heading_6 mb-8">COMPANY NAME</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="Company Name"
                type="text"
                value={companyName}
                onChange={(e) => setcompanyName(e.target.value)}
              />
            </div>
          </div>
          <div className="row mt-56">
            <div className="col-6">
              <label className="heading_6 mb-8">STREET</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="Street"
                type="text"
                value={street}
                onChange={(e) => setstreet(e.target.value)}
              />
            </div>
            <div className="col-6">
              <label className="heading_6 mb-8">NUMBER</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="Street Number"
                type="text"
                value={streetNumber}
                onChange={(e) => setstreetNumber(e.target.value)}
              />
            </div>
            <div className="col-6 mt-16">
              <label className="heading_6 mb-8">COUNTRY</label>
              <select
                className="border_input w-100 subheading_2"
                placeholder="Country"
                type="text"
                value={`${countrycode}` + "#" + `${country}`}
                onChange={(e) => {
                  setcountry(e.target.value.split("#")[1]);
                  setcountrycode(e.target.value.split("#")[0]);
                }}
              >
                <option value="">Choose Country</option>
                {countries &&
                  countries.map((option, i) => (
                    <option
                      className=""
                      key={i}
                      value={`${option.iso2}` + "#" + `${option.name}`}
                    >
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-6 mt-16">
              <label className="heading_6 mb-8">STATE</label>
              <select
                className="border_input w-100 subheading_2"
                placeholder="State"
                type="text"
                value={`${statecode}` + "#" + `${state}`}
                onChange={(e) => {
                  setstate(e.target.value.split("#")[1]);
                  setstatecode(e.target.value.split("#")[0]);
                }}
              >
                <option value="">Choose State</option>
                {states &&
                  states.map((option, i) => (
                    <option
                      className=""
                      key={i}
                      value={`${option.iso2}` + "#" + `${option.name}`}
                    >
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-4 mt-16">
              <label className="heading_6 mb-8">CITY</label>
              <select
                className="border_input w-100 subheading_2"
                placeholder="City"
                type="text"
                value={city}
                onChange={(e) => setcity(e.target.value)}
              >
                <option value="">Choose City</option>
                {cities &&
                  cities.map((option, i) => (
                    <option className="" key={i} value={option.name}>
                      {option.name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="col-4 mt-16">
              <label className="heading_6 mb-8">ZIP</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="Zip"
                type="text"
                value={zip}
                onChange={(e) => setzip(e.target.value)}
              />
            </div>
            <div className="col-4 mt-16">
              <label className="heading_6 mb-8">VAT (OPTIONAL)</label>
              <input
                className="border_input w-100 subheading_2"
                placeholder="VAT"
                type="text"
                value={vat}
                onChange={(e) => setVat(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-24 d-flex justify-content-end">
            <ButtonComp onClick={handleSubmit} className="" status={2}>
              Continue
            </ButtonComp>
          </div>
        </TabPanel>
        <TabPanel value={value} index={1} className="general_tabpanel">
          <div className="mt-32">
            {billingStep === 0 ? (
              <>
                <Accordion
                  expanded={expanded === 1}
                  onChange={handleExpandChange(1)}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <img src={Sepa} alt="Sepa" />
                        <p className="subheading_light_2 color_black ml-12">
                          SEPA Direct Debit
                        </p>
                        <div className="subtitle_box bg_soft_green ml-12">
                          <p className="color_green subheading_4">0% Fees</p>
                        </div>
                      </div>
                      <ButtonComp
                        status={3}
                        className="text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedPaymentInfo({
                            img: Sepa,
                            title: "SEPA Direct Debit",
                            fees: "0% Fees",
                            bg: "bg_soft_green",
                            color: "color_green",
                            buttonText: "Authorize Mandate",
                            forwardBtn: false,
                          });
                          handleCardPayment("sepa_debit");
                        }}
                      >
                        Select
                      </ButtonComp>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="body_text_s_light">
                      Select &apos;SEPA Direct Debit&apos; for an automatic
                      payment withdrawl. Provide your IBAN number, name and
                      email adress. Once the transaction has been completed,
                      you&apos;ll gain instant access to today.green. This
                      payment method does not include any additional charges.
                    </p>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 2}
                  onChange={handleExpandChange(2)}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <img src={BankTransfer} alt="Sepa" />
                        <p className="subheading_light_2 color_black ml-12">
                          Bank Transfer (Invoice)
                        </p>
                        <div className="subtitle_box bg_soft_green ml-12">
                          <p className="color_green subheading_4">0% Fees</p>
                        </div>
                      </div>
                      <ButtonComp
                        status={3}
                        className="text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          setBillingStep(2);
                        }}
                      >
                        Select
                      </ButtonComp>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="body_text_s_light">
                      This payment method provides flexibility when you prefer
                      to work with traditional invoices. Enter your billing
                      information and confirm your selection by clicking
                      &apos;Purchase & Download Invoice&apos; - the invoice will
                      download automatically. Make sure to submit your payment
                      within 14 days. Once the transaction has been completed,
                      you can start using today.green.
                    </p>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 3}
                  onChange={handleExpandChange(3)}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <img src={CreditCard} alt="Sepa" />
                        <p className="subheading_light_2 color_black ml-12">
                          Credit Card
                        </p>
                        <div className="subtitle_box bg_soft_orange ml-12">
                          <p className="color_orange subheading_4">+1.9%</p>
                        </div>
                      </div>
                      <ButtonComp
                        status={3}
                        className="text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedPaymentInfo({
                            img: CreditCard,
                            title: "Credit Card",
                            fees: "+1.9%",
                            bg: "bg_soft_orange",
                            color: "color_orange",
                            buttonText: "Save & Continue",
                            forwardBtn: false,
                          });
                          handleCardPayment("card");
                        }}
                      >
                        Select
                      </ButtonComp>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="body_text_s_light">
                      Pay with ease using the credit card payment method. Enter
                      your credit card information and get instant access to
                      today.green. This payment method includes an additional
                      charge of 1.9%.
                    </p>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 4}
                  onChange={handleExpandChange(4)}
                >
                  <AccordionSummary
                    aria-controls="panel4d-content"
                    id="panel4d-header"
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <img src={Sofort} alt="Sofort" />
                        <p className="subheading_light_2 color_black ml-12">
                          Sofort
                        </p>
                        <div className="subtitle_box bg_soft_orange ml-12">
                          <p className="color_orange subheading_4">+1.4%</p>
                        </div>
                      </div>
                      <ButtonComp
                        status={3}
                        className="text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedPaymentInfo({
                            img: Sofort,
                            title: "Sofort",
                            fees: "+1.4%",
                            bg: "bg_soft_orange",
                            color: "color_orange",
                            buttonText: "Continue to SOFORT",
                            forwardBtn: true,
                          });
                          handleCardPayment("sofort");
                        }}
                      >
                        Select
                      </ButtonComp>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="body_text_s_light">
                      Choose &apos;Sofort&apos; for a quick and secure bank
                      transfer. You&apos;ll be redirected to your bank&apos;s
                      online portal to authenticate your payment. Please note,
                      it may take 2 to 14 days for transaction confirmation.
                      Once the transaction has been completed, you can start
                      using today.green. This payment method includes and
                      additional charge of 1.4%.
                    </p>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 5}
                  onChange={handleExpandChange(5)}
                >
                  <AccordionSummary
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <img src={BankContact} alt="Bankcontact" />
                        <p className="subheading_light_2 color_black ml-12">
                          Bankcontact
                        </p>
                        <div className="subtitle_box bg_soft_orange ml-12">
                          <p className="color_orange subheading_4">+1.4%</p>
                        </div>
                      </div>
                      <ButtonComp
                        status={3}
                        className="text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedPaymentInfo({
                            img: BankContact,
                            title: "Bankcontact",
                            fees: "+1.4%",
                            bg: "bg_soft_orange",
                            color: "color_orange",
                            buttonText: "Continue to Bankcontact",
                            forwardBtn: true,
                          });
                          handleCardPayment("bancontact");
                        }}
                      >
                        Select
                      </ButtonComp>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="body_text_s_light">
                      Select &apos;Bankcontact&apos; for a quick and secure bank
                      transfer. You&apos;ll be redirected to Bancontact to
                      authorize your payment. Once the transaction has been
                      completed, you can start using today.green. This payment
                      method includes and additional charge of 1.4%.
                    </p>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={expanded === 6}
                  onChange={handleExpandChange(6)}
                >
                  <AccordionSummary
                    aria-controls="panel6d-content"
                    id="panel6d-header"
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      <div className="d-flex align-items-center">
                        <img src={IDEAL} alt="iDEAL" />
                        <p className="subheading_light_2 color_black ml-12">
                          iDEAL
                        </p>
                        <div className="subtitle_box bg_soft_green ml-12">
                          <p className="color_green subheading_4">0% Fees</p>
                        </div>
                      </div>
                      <ButtonComp
                        status={3}
                        className="text-black"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedPaymentInfo({
                            img: IDEAL,
                            title: "iDEAL",
                            fees: "0% Fees",
                            bg: "bg_soft_green",
                            color: "color_green",
                            buttonText: "Continue to iDEAL",
                            forwardBtn: true,
                          });
                          handleCardPayment("ideal");
                        }}
                      >
                        Select
                      </ButtonComp>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p className="body_text_s_light">
                      iDeal allows for a quick and secure payment. Provide your
                      bank name and account holder information to continue to
                      iDeal. Confirm your payment and instantly gain access to
                      today.green. No additional charges are included.
                    </p>
                  </AccordionDetails>
                </Accordion>
              </>
            ) : billingStep === 1 ? (
              options && (
                <Elements stripe={stripePromise} options={options}>
                  <CheckoutForm
                    selectedPaymentInfo={selectedPaymentInfo}
                    setValue={setValue}
                    setBillingStep={setBillingStep}
                    getPaymentMethods={getPaymentMethods}
                  />
                </Elements>
              )
            ) : (
              billingStep === 2 && (
                <div>
                  <div className="d-flex align-items-center border-btm_ef pb-16 mb-24">
                    <img src={BankTransfer} alt="Sepa" />
                    <p className="subheading_light_2 color_black ml-12">
                      Bank Transfer (Invoice)
                    </p>
                    <div className="subtitle_box bg_soft_green ml-12">
                      <p className="color_green subheading_4">0% Fees</p>
                    </div>
                  </div>
                  <div className="row border-btm_ef pb-20 mb-20">
                    <div className="col">
                      <p className="text-uppercase mb-10 heading_6">
                        Name on Invoice
                      </p>
                      <p className="subheading_2">
                        {user.firstname} {user.lastname}
                      </p>
                      <p className="subheading_2">{user.email}</p>
                    </div>
                    <div className="col">
                      <p className="text-uppercase mb-10 heading_6">
                        Billing Address
                      </p>
                      <p className="mt-12 subheading_2">
                        {address.CompanyName}
                        <br />
                        {address.firstName} {address.lastName}
                        <br />
                        {address.City} {address.street}
                        <br />
                        {address.zipCode}
                        <br />
                        {address.State}, {address.Country}
                      </p>
                    </div>
                    <div className="" style={{ width: "fit-content" }}>
                      <button
                        className="profileEditbtn"
                        onClick={() => setValue(0)}
                      >
                        <EditIcon />
                      </button>
                    </div>
                  </div>
                  <div className="  d-flex justify-content-between ">
                    <BackForwardButton
                      doNotShowForward={true}
                      handleGoBack={() => setBillingStep(0)}
                    />
                    <ButtonComp
                      className="lg"
                      onClick={() => {
                        setSelectBankTransfer(true);
                        setValue(2);
                      }}
                      status={1}
                    >
                      Confirm & Continue
                    </ButtonComp>
                  </div>
                </div>
              )
            )}
          </div>
        </TabPanel>
        <TabPanel value={value} index={2} className="general_tabpanel">
          <div className="mt-32">
            <p className="font_17_700 text-uppercase">
              🚀 Almost there. Let&apos;s check your details one last time!
            </p>
            <div className="mt-32 register_address_main ">
              {address && (
                <div className="register_address_sub">
                  <div className="d-flex justify-content-between">
                    <p className="heading_6">Billing Address</p>
                    <button
                      className="profileEditbtn"
                      onClick={() => setValue(0)}
                    >
                      <EditIcon />
                    </button>
                  </div>
                  <p className="mt-12 subheading_2">
                    {address.CompanyName}
                    <br />
                    {address.firstName} {address.lastName}
                    <br />
                    {address.City} {address.street}
                    <br />
                    {address.zipCode}
                    <br />
                    {address.State}, {address.Country}
                  </p>
                </div>
              )}
              <div className="register_address_sub">
                <div className="d-flex justify-content-between">
                  <p className="heading_6">
                    {selectBankTransfer ? "Bank Transfer" : "Payment"}
                  </p>
                  <button
                    className="profileEditbtn"
                    onClick={() => setValue(1)}
                  >
                    <EditIcon />
                  </button>
                </div>
                {selectBankTransfer ? (
                  <>
                    <p className="text-uppercase mb-10 heading_6">
                      Name on Invoice
                    </p>
                    <p className="subheading_2">
                      {user.firstname} {user.lastname}
                    </p>
                    <p className="subheading_2">{user.email}</p>
                  </>
                ) : (
                  paymentMethod &&
                  (paymentMethod.type === "card" ? (
                    <>
                      <img
                        src={CreditCardsJson[paymentMethod.card.brand]}
                        alt={paymentMethod.card.brand}
                      />
                      <p className="mt-12 font_14_500">
                        {CreditCardsNameJson[paymentMethod.card.brand]}:
                        <span className="ml-8">{paymentMethod.card.last4}</span>
                      </p>
                      <p className="font_14_500 mt-1">
                        Exp. Date:
                        <span className="ml-8">
                          {paymentMethod.card.exp_month}/
                          {paymentMethod.card.exp_year}
                        </span>
                      </p>
                      {/* <p>Name: Maxine Murphy</p> */}
                    </>
                  ) : (
                    paymentMethod.type === "sepa_debit" && (
                      <>
                        <p className="font_14_500 mt-1">
                          {paymentMethod.type}:
                          <span className="ml-8">
                            {paymentMethod.sepa_debit.last4}
                          </span>
                        </p>
                        <p className="font_14_500 mt-1">
                          Bank :
                          <span className="ml-8">
                            {paymentMethod.sepa_debit.country}
                            {paymentMethod.sepa_debit.bank_code}
                          </span>
                        </p>
                        <p className="font_14_500 mt-1">
                          Name :
                          <span className="ml-8">
                            {paymentMethod.billing_details.name}
                          </span>
                        </p>
                      </>
                    )
                  ))
                )}
              </div>
            </div>
            <div className="float-end mt-32">
              <div className="d-flex align-items-center width_fit">
                <input
                  type="radio"
                  id="termscheck"
                  name="termscheck"
                  checked={termsCheck}
                  onClick={() => setTermsCheck(!termsCheck)}
                />
                <label htmlFor="termscheck" className="overline_text ml-8">
                  I accept the{" "}
                  <a
                    href="https://www.today.green/legal/agb"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-bold termsNconditionsAnchor"
                  >
                    terms & conditions
                  </a>{" "}
                  of today.green
                </label>
              </div>
              <ButtonComp
                status={1}
                className=" mt-24"
                style={{ padding: "10px 8rem" }}
                disabled={!termsCheck}
                onClick={() => {
                  if (selectBankTransfer) {
                    generateInvoiceBankTransfer();
                  } else {
                    makePayment();
                  }
                }}
              >
                {selectBankTransfer ? "Complete" : "Purchase Now"}
              </ButtonComp>
            </div>
          </div>
        </TabPanel>
      </Box>
    </div>
  );
}
