import React from "react";
import { Paper, Table, TableBody, TableContainer } from "@mui/material";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../../../../Utils/TableComp";
import { v4 as uuidv4 } from "uuid";
import { Avatar } from "@mui/joy";

function UserTable(props) {
  //  const [users,] = useState(["", "", "", "", "", "", ""])

  return (
    <div className="createSurvey_userTable_container">
      <TableContainer
        component={Paper}
        sx={{ minWidth: 640, borderRadius: "8px" }}
        className="mr-50"
      >
        <Table sx={{ minWidth: 640 }} aria-label="simple table">
          <TableBody>
            {props.allUsers
              .filter((curr) => curr.selected)
              .map((currUser) => (
                <StyledTableRow
                  key={uuidv4()}
                  sx={{
                    "&:last-child td, &:last-child th": { borderBottom: 0 },
                  }}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{ border: 0 }}
                  >
                    <div className="d-flex align-items-center">
                      <Avatar className="ml-10" sx={{ bgcolor: "pink" }}>
                        {currUser.firstName
                          ? currUser.firstName[0]
                          : currUser.firstname[0]}
                      </Avatar>

                      <p className="font_13_400 color_72 ml-10">
                        {currUser.firstName
                          ? currUser.firstName
                          : currUser.firstname}{" "}
                      </p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ border: 0 }}
                    // className="border_bottom_0 "
                  >
                    <div className="d-flex">
                      {currUser.isExternal ? (
                        <p className="flex-grow-0 font_13_400 border_radius_10 bg_light_yellow pt-7 pb-7 pl-12 pr-12">
                          External
                        </p>
                      ) : (
                        <p className="flex-grow-0 font_13_400 border_radius_10 bg_d4 pt-7 pb-7 pl-12 pr-12">
                          My team
                        </p>
                      )}
                      <div className="d-flex align-items-center ml-20">
                        <p className="font_13_400 color_72">{currUser.email}</p>
                      </div>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{ border: 0, paddingRight: 0 }}
                    className="align_text_right"
                  >
                    <div className="d-flex justify-content-end pointer" onClick={props.handleGoBack}>
                      <p className="font_13_400 color_72">Edit</p>
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default UserTable;
