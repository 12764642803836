import { Close } from "@mui/icons-material";
import React, { useState } from "react";
import ClimateFactComp from "./ClimateFactComp";
import { useEffect } from "react";
import { handleSaveData } from "./CommonFunctions";
import { useAuth } from "../../../../Utils/Auth";
import QuestionMarkCircle from "../../../../Assets/icons/QuestionMarkCircle";

const ClimateFactsModal = ({
  handleClose,
  companyData,
  getCompanyData,
  climateFacts,
}) => {
  const [fact, setFact] = useState({
    factMemberSince: true,
    factManagingCarbonSince: true,
    factEmisssionTarget: true,
    fact_tCo2_compensated: true,
    fact_tCo2_emitted: true,
    fact_tCo2_reduced: true,
  });
  const { setSessionExpire } = useAuth();

  useEffect(() => {
    if (companyData) {
      setFact({
        factMemberSince: companyData.factMemberSince,
        factManagingCarbonSince: companyData.factManagingCarbonSince,
        factEmisssionTarget: companyData.factEmisssionTarget,
        fact_tCo2_compensated: companyData.fact_tCo2_compensated,
        fact_tCo2_emitted: companyData.fact_tCo2_emitted,
        fact_tCo2_reduced: companyData.fact_tCo2_reduced,
      });
    }
  }, [companyData]);

  const handleChange = (value, name) => {
    const data = {
      factMemberSince: fact.factMemberSince,
      factManagingCarbonSince: fact.factManagingCarbonSince,
      factEmisssionTarget: fact.factEmisssionTarget,
      fact_tCo2_compensated: fact.fact_tCo2_compensated,
      fact_tCo2_emitted: fact.fact_tCo2_emitted,
      fact_tCo2_reduced: fact.fact_tCo2_reduced,
      [name]: value,
    };
    setFact({
      factMemberSince: companyData.factMemberSince,
      factManagingCarbonSince: companyData.factManagingCarbonSince,
      factEmisssionTarget: companyData.factEmisssionTarget,
      fact_tCo2_compensated: companyData.fact_tCo2_compensated,
      fact_tCo2_emitted: companyData.fact_tCo2_emitted,
      fact_tCo2_reduced: companyData.fact_tCo2_reduced,
      [name]: value,
    });
    const abc = () => {};
    handleSaveData(data, getCompanyData, abc, setSessionExpire);
  };
  return (
    <div className="row_20_gap">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font_16_500">
          Climate Action Facts
          <QuestionMarkCircle className={"ml-10"} />
        </p>
        <Close className="pointer" onClick={handleClose} />
      </div>
      <div className="mt-20">
        <p className="font_12_600 color_7e">Displayed Cards</p>
        <div className="share_climate_grid_main mt-10">
          <ClimateFactComp
            year={climateFacts?.memberSince}
            title={"Member since"}
            isVisible={fact.factMemberSince}
            name="factMemberSince"
            handleChange={handleChange}
          />
          <ClimateFactComp
            year={climateFacts?.carbonSince}
            title={"Managing carbon since"}
            isVisible={fact.factManagingCarbonSince}
            name="factManagingCarbonSince"
            handleChange={handleChange}
          />
          <ClimateFactComp
            year={
              climateFacts && climateFacts.target
                ? Number(climateFacts.target).toLocaleString()
                : 0
            }
            title={"Emission target until 2030"}
            isVisible={fact.factEmisssionTarget}
            name="factEmisssionTarget"
            handleChange={handleChange}
          />
          <ClimateFactComp
            year={
              climateFacts && climateFacts.compensated
                ? Number(climateFacts.compensated).toLocaleString()
                : 0
            }
            title={"tCO2e compensated"}
            isVisible={fact.fact_tCo2_compensated}
            name="fact_tCo2_compensated"
            handleChange={handleChange}
          />
          <ClimateFactComp
            year={
              climateFacts && climateFacts.totalEmitted
                ? Number(climateFacts.totalEmitted).toLocaleString()
                : 0
            }
            title={"tCO2e emitted 2022"}
            isVisible={fact.fact_tCo2_emitted}
            name="fact_tCo2_emitted"
            handleChange={handleChange}
          />
          <ClimateFactComp
            year={
              climateFacts && climateFacts.reduced
                ? Number(climateFacts.reduced).toLocaleString()
                : 0
            }
            title={"tCO2e reduced"}
            isVisible={fact.fact_tCo2_reduced}
            name="fact_tCo2_reduced"
            handleChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default ClimateFactsModal;
