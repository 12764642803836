import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Bar,
  Cell,
  //  Line,
  //  Area,
  XAxis,
  // CartesianGrid,
  YAxis,
  Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
//import likertScaleData from '../../../../../../../../../../Utils/likertScale.json'
// const data = [
//   {
//     name: 'Page A',
//     uv: 590,
//     pv: 800,
//     amt: 1400,
//   },
//   {
//     name: 'Page B',
//     uv: 868,
//     pv: 967,
//     amt: 1506,
//   },
//   {
//     name: 'Page C',
//     uv: 1397,
//     pv: 1098,
//     amt: 989,
//   },
//   {
//     name: 'Page D',
//     uv: 1480,
//     pv: 1200,
//     amt: 1228,
//   },
//   {
//     name: 'Page E',
//     uv: 1520,
//     pv: 1108,
//     amt: 1100,
//   },
//   {
//     name: 'Page F',
//     uv: 1400,
//     pv: 680,
//     amt: 1700,
//   },
// ];

const colors = ["#AB9AF6", "#65A9FA", "#D679F7", "#FBD271", "#55B08E"];

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="d-flex align-items-center custom_tooltip_">
        <div className="d-flex align-items-center">
          <span
            className="span_cirlce mr-8"
            style={{ backgroundColor: payload[0].color }}
          ></span>
          <p className="tooltip_chart_text">
            count: {Number(payload[0].value)}
          </p>
        </div>
      </div>
    );
  }
  return null;
};

function LikertScaleChart(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempData = [];

    props.options.forEach((currOption, index) => {
      tempData.push({
        name: currOption,
        count: props.data[index],
      });
    });
    setData(tempData);
  }, [props.data, props.options]);
  return (
    <ResponsiveContainer width={"100%"} height={300}>
      <ComposedChart
        layout="vertical"
        width={500}
        height={400}
        data={data}
        margin={{
          top: 10,
          right: 20,
          bottom: 10,
          left: 20,
        }}
      >
        {/* <CartesianGrid stroke="#f5f5f5" /> */}
        <XAxis tick={false} tickLine={false} axisLine={false} type="number" />
        <YAxis
          tickLine={false}
          axisLine={false}
          dataKey="name"
          type="category"
          width={150}
          dx={-150}
          textAnchor="left"
          style={{
            color: "#4C4C4C",
            fontFamily: "Inter",
            fontSize: 12,
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "14px",
            // whiteSpace: "nowrap",
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Bar
          dataKey="count"
          barSize={20}
          fill={"#AB9AF6"}
          radius={[5, 5, 5, 5]}
        >
          {data &&
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % 4]} />
            ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
}

export default LikertScaleChart;
