import React from "react";

function ChartIcon(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 19 19"
      >
        <path
          stroke={props.stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M6.188 7.71v4.618M9.266 9.25v3.078M12.344 6.172v6.156"
        ></path>
        <rect
          width="13.852"
          height="12.313"
          x="2.34"
          y="3.094"
          stroke={props.stroke}
          strokeWidth="2"
          rx="2"
        ></rect>
      </svg>
    </div>
  );
}

export default ChartIcon;
