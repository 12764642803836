import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatNumberIntoReadableFormat } from "../../../../../Utils/CommonFunctions";

const CustomTooltip = ({ payload }) => {
  if (payload && payload[0] && payload[0]?.value) {
    return (
      <div className="d-flex align-items-center custom_tooltip_">
        <div className="d-flex align-items-center mr-16">
          <span className="span_cirlce bg_blue mr-8"></span>
          <p className="tooltip_chart_text">{payload[0]?.value} Logs</p>
        </div>
      </div>
    );
  }
};

function LogActivity({ data }) {
  return (
    <ResponsiveContainer height={300}>
      <BarChart
        data={data}
        barGap={"30%"}
        barCategoryGap="38%"
        bar
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#efefef" vertical={false} />
        <XAxis
          dataKey={"month"}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
        />
        <YAxis
          tickFormatter={(val) => `${formatNumberIntoReadableFormat(val)} Logs`}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
        {/* <Legend /> */}
        <Bar dataKey="count" stackId="a" fill="#00915b" radius={[0, 0, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default LogActivity;
