import { Button } from "@mui/material";
import React from "react";

const OptionQuestionPlate = ({ question, handleSelectAnswer }) => {
  return (
    <div>
      <p className="heading_9">{question.question}</p>
      <div className="mt-40 csrd_assessment_options_buttons">
        <Button className={question.answer === "Yes" ? "csrd_options_btnYes" : "csrd_options_btnNo"} onClick={()=>{
          handleSelectAnswer("Yes")
        }}>Yes</Button>
        <Button className={question.answer === "No" ? "csrd_options_btnYes" : "csrd_options_btnNo"} onClick={()=>{
          handleSelectAnswer("No")
        }}>No</Button>
      </div>
    </div>
  );
};

export default OptionQuestionPlate;
