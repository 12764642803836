import React from "react";

export default function Name() {
  return (
    <div className="row mx-0 align-items-center">
      <div className="col-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          fill="none"
          viewBox="0 0 26 26"
        >
          <circle cx="13.086" cy="12.984" r="12.5" fill="#F7F7F7"></circle>
        </svg>
      </div>
      <div className="col-10">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="154"
          height="12"
          fill="none"
          viewBox="0 0 154 12"
        >
          <rect
            width="153.312"
            height="10.43"
            x="0.297"
            y="0.586"
            fill="#F7F7F7"
            rx="5.215"
          ></rect>
        </svg>
      </div>
    </div>
  );
}
