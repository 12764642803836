import React from "react";

function TextInputSettings(props) {
  
  return (
    <div>
      <label className="sr-only subheading_1 mb-12" htmlFor="service_name">
        {props.title}
      </label>
      <input
        disabled={!props.disabled}
        type={props.type}
        value={props.answers[props.name]}
        className="form-control shadow-none itemSettings_input w-80"
        id="service_name"
        placeholder="type name"
        onChange={(event) => props.handleAnswerChange(props.name,event.target.value)}

      />
      <p className="helper_text fw-400 ml-10 mt-14">
        This name will be used to refer to this item in future
      </p>
    </div>
  );
}

export default TextInputSettings;
