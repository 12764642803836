import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MinusIcon from "../../../../Components/Companydashboard/Store/Components/StoreCard/MinusIcon";
import PlusIcon from "../../../../Components/Companydashboard/Store/Components/StoreCard/PlusIcon";

const Card = ({ item, cart, handleIncrease, isOther }) => {
  const [value, setvalue] = useState(0);
  const history = useNavigate();
  useEffect(() => {
    if (cart) {
      var itemFind = cart.find((items) => items.name === item.name);
      if (itemFind) {
        setvalue(itemFind.quantity);
      } else {
        setvalue(0);
      }
    }
  }, [cart]);
  return (
    <div
      className="d-flex flex-column justify-content-between container_design_1 storeCard_container pointer h-350px p-0 pl-10 pb-10"
      onClick={() => {
        if (isOther) {
          history(`/register/subscription?step=4&item=${item._id}`);
        } else {
          history(`/register/subscription?step=4&item=${item._id}`);
        }
      }}
    >
      <div
        className="d-flex justify-content-end mt-10 mr-10"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="store_card_cart_add_root">
          <MinusIcon
            onClick={() => {
              if (value > 0) {
                handleIncrease(item._id, value - 1);
              }
            }}
          />

          <span className="font_15_600 flex-1 d-flex align-items-center justify-content-center line_height_normal">
            {value}
          </span>
          <PlusIcon onClick={() => handleIncrease(item._id, value + 1)} />
        </div>
      </div>

      <div className="bg_white storeCard_bottom_info border-ef border_radius_10 storeCard_card_details ml-10 mr-10  mb-10">
        <div
          className={
            "d-flex justify-content-between  pb-24 pt-24 pl-12 pr-24 " +
            (!isOther && "align-items-center")
          }
        >
          <div>
            <p className="fw-600 f-16">{item && item.name}</p>
            <p className="pointer f-14 fw-14">
              Learn More{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </p>
          </div>

          <div>
            {item.name === "Custom Pricing" ? (
              <div></div>
            ) : (
              <>
                {isOther && <p className="font_12_300">from</p>}
                <p className="f-24 fw-200"> {item && item.price}€</p>
              </>
            )}
          </div>
        </div>
      </div>
      <img src={item?.image} className="w-100 h-75 storeCard_bg_image p-0" />
    </div>
  );
};

export default Card;
