import React from "react";

function TickMark({ className, style, cc }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="9"
      fill="none"
      viewBox="0 0 10 9"
      className={className}
      style={style}
    >
      <path
        stroke={cc || "#2441F5"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M1.266 5.16l2.759 2.76L8.285.898"
      ></path>
    </svg>
  );
}

export default TickMark;
