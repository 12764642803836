import React, { useState, useEffect } from "react";
// import PencilIcon from "../../../../../../../Assets/icons/PencilIcon";
import useQuery from "../../../../../../../Utils/query";
import { FormatListBulleted } from "@mui/icons-material";
import TargetHeaderCard from "../TargetHeaderCard";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../../Utils/TableComp";
import LeftChevronIcon from "../../../../../../../Assets/icons/LeftChevronIcon";
import { useNavigate } from "react-router-dom";
import SubScopeChart from "../Chart/SubScopeChart";
import RightChevronIcon from "../../../../../../../Assets/icons/RightChevronIcon";

const TargetStep1 = ({
  targetData,
  currAccItems,
  baseAccItems,
  handleTaskDrawerOpen,
  emissionCard,
  setstep,
  getYtd,
  getytdPerc,
  setTarget2HeaderData,
}) => {
  const [accItem, setaccItem] = useState();
  const [notbaseLineCard, setNotbaseLineCard] = useState(false);
  const [accIndex, setaccIndex] = useState();
  const query = useQuery();
  const itemId = query.get("accItem");
  const history = useNavigate();
  const [baseAcc, setbaseAcc] = useState();
  const [currAcc, setcurrAcc] = useState();
  const [chartData, setchartData] = useState([]);

  useEffect(() => {
    if (itemId) {
      if (targetData) {
        let currIndex = targetData.targetScopes.findIndex(
          (item) => item._id === itemId
        );
        setaccIndex(currIndex);
        setaccItem(targetData.targetScopes[currIndex]);
        setbaseAcc(baseAccItems.accItems[currIndex]);
        setcurrAcc(currAccItems.accItems[currIndex]);

        let chartTemp = [];
        targetData.accItems.map((item) => {
          let Obj = {
            year: item.year,
            scope1: item.accItems[currIndex].scope1Emission,
            scope2: item.accItems[currIndex].scope2Emission,
            scope3: item.accItems[currIndex].scope3Emission,
            total:
              item.accItems[currIndex].scope1Emission +
              item.accItems[currIndex].scope2Emission +
              item.accItems[currIndex].scope3Emission,
          };
          chartTemp.push(Obj);
        });
        setchartData(chartTemp);
      }
    }
  }, [itemId]);

  useEffect(() => {
    const scroll = () => window.scrollTo(0, 0);
    return () => scroll();
  }, []);

  return (
    <div className="row_20_gap py-20">
      <div className="between">
        <div className="d-flex align-items-center gap_20">
          <button
            className="h-35px w-35px center bg_green border-none border_radius_5"
            onClick={() => {
              setstep(0);
              history(`/dashboard?tab=5&subtab=0&step=0`);
            }}
          >
            <LeftChevronIcon cc={"#fff"} />
          </button>

          <div className="container_br_5_p_10_8 h-40px center">
            <p className="offsetProjectTitle line_height_24">
              {accItem?.itemDetails.name}
            </p>
          </div>
          {/* <div className="container_br_5_p_10_8 h-40px center">
              <p className="font_20_600 line_height_20 color_57"></p>
            </div>
            <div className="container_br_5_p_10_8 h-40px center bg_green">
              <p className="font_20_600 line_height_20 text-white"></p>
            </div> */}
          {/* <button
              className="profileEditbtn h-40px w-40px"
              // onClick={() => {
              //   handleDrawerOpen(true);
              // }}
            >
              <PencilIcon cc={"#000"} />
            </button> */}
        </div>
        <div className="d-flex align-items-center gap_30">
          <div
            className="w-40px h-40px bg_soft_green border_radius_5 center pointer position-relative"
            onClick={handleTaskDrawerOpen}
          >
            <FormatListBulleted htmlColor="#00915B" />
          </div>
        </div>
      </div>
      <hr className="mt-30 mb20" />
      {notbaseLineCard ? (
        <div className="row">
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(false)}
          >
            <TargetHeaderCard
              title={"Overall"}
              percent={targetData.overallReduction}
              papercent={`${
                (targetData.scope1ReductionPercentage +
                  targetData.scope2ReductionPercentage +
                  targetData.scope3ReductionPercentage) /
                (targetData.targetTimeFrame * 3)
              }% p.a`}
              tonsValue={-3000}
              isBaseLine={false}
            />
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(false)}
          >
            <TargetHeaderCard
              title={"Scope 1"}
              percent={targetData.scope1Reduction}
              papercent={`${
                targetData.scope1ReductionPercentage /
                targetData.targetTimeFrame
              }% p.a`}
              tonsValue={-3000}
              isBaseLine={false}
            />
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(false)}
          >
            <TargetHeaderCard
              title={"Scope 2"}
              percent={targetData.scope2Reduction}
              papercent={`${
                targetData.scope2ReductionPercentage /
                targetData.targetTimeFrame
              }% p.a`}
              tonsValue={-3000}
              isBaseLine={false}
            />
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(false)}
          >
            <TargetHeaderCard
              title={"Scope 3"}
              percent={targetData.scope3Reduction}
              papercent={`${
                targetData.scope3ReductionPercentage /
                targetData.targetTimeFrame
              }% p.a`}
              tonsValue={-3000}
              isBaseLine={false}
            />
          </div>
        </div>
      ) : (
        <div className="row">
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(true)}
          >
            <TargetHeaderCard
              title={"Baseline"}
              percent={
                baseAcc?.scope1Emission +
                baseAcc?.scope2Emission +
                baseAcc?.scope3Emission
              }
              papercent={emissionCard?.baseLineYear}
              footerValue={
                <span>Baseline emissions for {emissionCard?.baseLineYear}</span>
              }
              isBaseLine={true}
            />
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(true)}
          >
            <TargetHeaderCard
              title={"Target Emissions"}
              percent={
                currAcc?.scope1Emission +
                currAcc?.scope2Emission +
                currAcc?.scope3Emission
              }
              papercent={new Date().getFullYear()}
              isBaseLine={true}
              footerValue={
                <span>
                  <span className="color_green">
                    {Number(accItem?.currentEmission).toLocaleString(
                      undefined,
                      {
                        maximumFractionDigits: 2,
                      }
                    )}{" "}
                    tCO2e
                  </span>{" "}
                  <span>Until now</span>
                </span>
              }
            />
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(true)}
          >
            <TargetHeaderCard
              title={"Reduced YTD"}
              percent={
                getYtd(
                  currAcc?.scope1Emission +
                    currAcc?.scope2Emission +
                    currAcc?.scope3Emission,
                  accItem?.currentEmission
                ) * -1
              }
              papercent={`${String(emissionCard?.baseLineYear).slice(
                -2
              )}/${String(new Date().getFullYear()).slice(-2)}`}
              isBaseLine={true}
              footerValue={String(
                Number(
                  getytdPerc(
                    currAcc?.scope1Emission +
                      currAcc?.scope2Emission +
                      currAcc?.scope3Emission,
                    accItem?.currentEmission
                  )
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }) + "% of yearly target reduction"
              )}
            />
          </div>
          <div
            className="col-lg-3 col-md-3 col-6"
            onClick={() => setNotbaseLineCard(true)}
          >
            <TargetHeaderCard
              title={"Planned Reduction"}
              percent={
                "-" +
                (Number(
                  targetData?.accScopes[accIndex]?.scope1BaseEmission +
                    targetData?.accScopes[accIndex]?.scope2BaseEmission +
                    targetData?.accScopes[accIndex]?.scope3BaseEmission
                ) -
                  Number(
                    targetData?.accScopes[accIndex]?.scope1TargetEmission +
                      targetData?.accScopes[accIndex]?.scope2TargetEmission +
                      targetData?.accScopes[accIndex]?.scope3TargetEmission
                  ))
              }
              papercent={targetData.targetYear}
              isBaseLine={true}
              tonsValue={-3000}
              footerValue={String(
                Number(
                  100 -
                    getytdPerc(
                      currAcc?.scope1Emission +
                        currAcc?.scope2Emission +
                        currAcc?.scope3Emission,
                      accItem?.currentEmission
                    )
                ).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                }) + "% left to meet yearly target"
              )}
            />
          </div>
        </div>
      )}
      <div className="container_br_10_p_20 mt-20">
        <SubScopeChart
          data={chartData.length > 0 ? chartData : []}
          includeScope3={targetData.includeScope3}
        />
      </div>
      <div className="mt-20 container_br_10_p_20 h-70px d-flex align-items-center">
        <p className="font_15_700 color_57">CO2e Budget</p>
        <div className="targetProgressbar_root flex-1 ml-40">
          <div
            className={`targetProgressbar ${
              emissionCard?.currentEmission > emissionCard?.targetEmissions
                ? "red_pg_bar"
                : ""
            }`}
            style={{
              width: `${
                emissionCard?.currentEmission > emissionCard?.targetEmissions
                  ? 100
                  : (emissionCard?.currentEmission * 100) /
                    emissionCard?.targetEmissions
              }%`,
            }}
          ></div>
        </div>
        <p className="font_14_700 color_7e ml-52">
          {Number(accItem?.currentEmission).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
          /
          {Number(
            currAcc?.scope1Emission +
              currAcc?.scope2Emission +
              currAcc?.scope3Emission
          ).toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}
        </p>
        <span className="font_10_700 line_height_10 color_7e ml-6 pt-2">
          tCO2e
        </span>
      </div>
      {targetData.targetScopes && targetData.targetScopes.length > 0 && (
        <TableContainer
          component={Paper}
          sx={{
            minWidth: 640,
            borderRadius: "9px",
            boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
          }}
          className="mt-30 mb-20"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  component="th"
                  className="text-uppercase text-center"
                  sx={{ borderLeft: "0", width: "20%" }}
                >
                  Scope
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase text-center"
                  sx={{ width: "25%" }}
                >
                  CO2 Budget {String(new Date().getFullYear()).slice(-2)}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase text-center"
                  sx={{ width: "20%" }}
                >
                  BASE VALUE
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase text-center"
                  sx={{ width: "20%" }}
                >
                  TARGET REDUCTION
                </HeadCell>

                <HeadCell
                  component="th"
                  className="text-uppercase text-center"
                  sx={{ width: "15%", borderRight: "none" }}
                >
                  REDUCED YTD
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase text-center"
                  sx={{ width: "15%", borderRight: "none" }}
                ></HeadCell>
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    borderBottom: 0,
                  },
                }}
              >
                <StyledTableCell
                  className="text-center border_left_0"
                  component="th"
                  scope="row"
                >
                  <p className="font_14_600 line_height_14 bg_soft_blue_bc p-10 color_white fit-w border_radius_5 mx-auto">
                    SCOPE 1
                  </p>
                </StyledTableCell>
                <StyledTableCell className="text-center">
                  <div className="d-flex">
                    <div className="col-6 h-12px center px-10 py-8 targetProgressbar_root">
                      <div
                        className={`targetProgressbar_mini bg_soft_blue_bc  ${
                          Number(accItem?.scope1CurrEmission) >
                          currAcc?.scope1Emission
                            ? "red_pg_bar"
                            : ""
                        }`}
                        style={{
                          width: `${
                            Number(accItem?.scope1CurrEmission) >
                            currAcc?.scope1Emission
                              ? 100
                              : (Number(accItem?.scope1CurrEmission) * 100) /
                                currAcc?.scope1Emission
                          }%`,
                        }}
                      ></div>
                    </div>
                    <span className="font_14_600  color_7e">
                      &nbsp;{" "}
                      <span className="color_black">
                        {Number(accItem?.scope1CurrEmission).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                        /
                        {Number(currAcc?.scope1Emission).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}{" "}
                      </span>
                      &nbsp;tCO2e
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell className="text-center">
                  <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                    <p className="font_14_600 line_height_14 ">
                      {Number(baseAcc?.scope1Emission).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 0,
                        }
                      )}
                      <span className="color_7e">&nbsp;tCO2e</span>
                    </p>
                  </div>
                </StyledTableCell>
                <StyledTableCell className="text-center">
                  <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                    <p className="font_14_600 line_height_14 ">
                      {Number(currAcc?.scope1Emission).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 0,
                        }
                      )}
                      <span className="color_7e">&nbsp;tCO2e</span>
                    </p>
                  </div>
                </StyledTableCell>

                <StyledTableCell
                  className="text-centert"
                  sx={{ borderRight: "none" }}
                >
                  <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                    <p className="font_14_600 line_height_14 ">
                      {Number(
                        getYtd(
                          currAcc?.scope1Emission,
                          accItem?.scope1CurrEmission
                        ) * -1
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                      <span className="color_7e">&nbsp;tCO2e</span>
                    </p>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-center"
                  sx={{ borderRight: "none" }}
                >
                  <button
                    className="h-30px w-30px center bg_green border-none border_radius_5"
                    onClick={() => {
                      setstep(0);
                      setTarget2HeaderData({
                        baseline: baseAcc?.scope1Emission,
                        baseLineYear: emissionCard?.baseLineYear,
                        targetEmission: currAcc?.scope1Emission,
                        currentEmission: accItem?.scope1CurrEmission,
                        reducedYtd:
                          getYtd(
                            currAcc?.scope1Emission,
                            accItem?.scope1CurrEmission
                          ) * -1,
                        reducedYtdPer:
                          getytdPerc(
                            currAcc?.scope1Emission,
                            accItem?.scope1CurrEmission
                          ) * -1,
                        plannedReduction: 0,
                      });
                      history(
                        `/dashboard?tab=5&subtab=0&step=2&accItem=${itemId}&scope=1`
                      );
                    }}
                  >
                    <RightChevronIcon cc={"#fff"} />
                  </button>
                </StyledTableCell>
              </StyledTableRow>
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    borderBottom: 0,
                  },
                }}
              >
                <StyledTableCell
                  className="text-center border_left_0"
                  component="th"
                  scope="row"
                >
                  <p className="font_14_600 line_height_14 bg_scope2 p-10 color_white fit-w border_radius_5 mx-auto">
                    SCOPE 2
                  </p>
                </StyledTableCell>
                <StyledTableCell className="text-center">
                  <div className="d-flex">
                    <div className="col-6 h-12px center px-10 py-8 targetProgressbar_root">
                      <div
                        className={`targetProgressbar_mini bg_scope2  ${
                          Number(accItem?.scope2CurrEmission) >
                          currAcc?.scope2Emission
                            ? "red_pg_bar"
                            : ""
                        }`}
                        style={{
                          width: `${
                            Number(accItem?.scope2CurrEmission) >
                            currAcc?.scope2Emission
                              ? 100
                              : (Number(accItem?.scope2CurrEmission) * 100) /
                                currAcc?.scope2Emission
                          }%`,
                        }}
                      ></div>
                    </div>
                    <span className="font_14_600  color_7e">
                      &nbsp;{" "}
                      <span className="color_black">
                        {Number(accItem?.scope2CurrEmission).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                        /
                        {Number(currAcc?.scope2Emission).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}{" "}
                      </span>
                      &nbsp;tCO2e
                    </span>
                  </div>
                </StyledTableCell>
                <StyledTableCell className="text-center">
                  <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                    <p className="font_14_600 line_height_14 ">
                      {Number(baseAcc?.scope2Emission).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 0,
                        }
                      )}
                      <span className="color_7e">&nbsp;tCO2e</span>
                    </p>
                  </div>
                </StyledTableCell>
                <StyledTableCell className="text-center">
                  <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                    <p className="font_14_600 line_height_14 ">
                      {Number(currAcc?.scope2Emission).toLocaleString(
                        undefined,
                        {
                          maximumFractionDigits: 0,
                        }
                      )}
                      <span className="color_7e">&nbsp;tCO2e</span>
                    </p>
                  </div>
                </StyledTableCell>

                <StyledTableCell
                  className="text-center"
                  sx={{ borderRight: "none" }}
                >
                  <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                    <p className="font_14_600 line_height_14 ">
                      {Number(
                        getYtd(
                          currAcc?.scope2Emission,
                          accItem?.scope2CurrEmission
                        ) * -1
                      ).toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                      })}
                      <span className="color_7e">&nbsp;tCO2e</span>
                    </p>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-center"
                  sx={{ borderRight: "none" }}
                >
                  <button
                    className="h-30px w-30px center bg_green border-none border_radius_5"
                    onClick={() => {
                      setstep(0);
                      setTarget2HeaderData({
                        baseline: baseAcc?.scope2Emission,
                        baseLineYear: emissionCard?.baseLineYear,
                        targetEmission: currAcc?.scope2Emission,
                        currentEmission: accItem?.scope2CurrEmission,
                        reducedYtd:
                          getYtd(
                            currAcc?.scope2Emission,
                            accItem?.scope2CurrEmission
                          ) * -1,
                        reducedYtdPer:
                          getytdPerc(
                            currAcc?.scope2Emission,
                            accItem?.scope2CurrEmission
                          ) * -1,
                        plannedReduction: 0,
                      });
                      history(
                        `/dashboard?tab=5&subtab=0&step=2&accItem=${itemId}&scope=2`
                      );
                    }}
                  >
                    <RightChevronIcon cc={"#fff"} />
                  </button>
                </StyledTableCell>
              </StyledTableRow>
              {targetData.includeScope3 && (
                <StyledTableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      borderBottom: 0,
                    },
                  }}
                >
                  <StyledTableCell
                    className="text-center border_left_0"
                    component="th"
                    scope="row"
                  >
                    <p className="font_14_600 line_height_14 bg_scope3 p-10 color_white fit-w border_radius_5 mx-auto">
                      SCOPE 3
                    </p>
                  </StyledTableCell>
                  <StyledTableCell className="text-center">
                    <div className="d-flex">
                      <div className="col-6 h-12px center px-10 py-8 targetProgressbar_root">
                        <div
                          className={`targetProgressbar_mini bg_scope3  ${
                            Number(accItem?.scope3CurrEmission) >
                            currAcc?.scope3Emission
                              ? "red_pg_bar"
                              : ""
                          }`}
                          style={{
                            width: `${
                              Number(accItem?.scope3CurrEmission) >
                              currAcc?.scope3Emission
                                ? 100
                                : (Number(accItem?.scope3CurrEmission) * 100) /
                                  currAcc?.scope3Emission
                            }%`,
                          }}
                        ></div>
                      </div>
                      <span className="font_14_600  color_7e">
                        &nbsp;{" "}
                        <span className="color_black">
                          {Number(accItem?.scope3CurrEmission).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}
                          /
                          {Number(currAcc?.scope3Emission).toLocaleString(
                            undefined,
                            {
                              maximumFractionDigits: 0,
                            }
                          )}{" "}
                        </span>
                        &nbsp;tCO2e
                      </span>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className="text-center">
                    <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                      <p className="font_14_600 line_height_14 ">
                        {Number(baseAcc?.scope3Emission).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                        <span className="color_7e">&nbsp;tCO2e</span>
                      </p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell className="text-center">
                    <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                      <p className="font_14_600 line_height_14 ">
                        {Number(currAcc?.scope3Emission).toLocaleString(
                          undefined,
                          {
                            maximumFractionDigits: 0,
                          }
                        )}
                        <span className="color_7e">&nbsp;tCO2e</span>
                      </p>
                    </div>
                  </StyledTableCell>

                  <StyledTableCell
                    className="text-center"
                    sx={{ borderRight: "none" }}
                  >
                    <div className="border_radius_10  h-30px center px-10 py-8 text-center">
                      <p className="font_14_600 line_height_14 ">
                        {Number(
                          getYtd(
                            currAcc?.scope3Emission,
                            accItem?.scope3CurrEmission
                          ) * -1
                        ).toLocaleString(undefined, {
                          maximumFractionDigits: 2,
                        })}
                        <span className="color_7e">&nbsp;tCO2e</span>
                      </p>
                    </div>
                  </StyledTableCell>
                  <StyledTableCell
                    className="text-center"
                    sx={{ borderRight: "none" }}
                  >
                    <button
                      className="h-30px w-30px center bg_green border-none border_radius_5"
                      onClick={() => {
                        setstep(0);
                        setTarget2HeaderData({
                          baseline: baseAcc?.scope3Emission,
                          baseLineYear: emissionCard?.baseLineYear,
                          targetEmission: currAcc?.scope3Emission,
                          currentEmission: accItem?.scope3CurrEmission,
                          reducedYtd:
                            getYtd(
                              currAcc?.scope3Emission,
                              accItem?.scope3CurrEmission
                            ) * -1,
                          reducedYtdPer:
                            getytdPerc(
                              currAcc?.scope3Emission,
                              accItem?.scope3CurrEmission
                            ) * -1,
                          plannedReduction: 0,
                        });
                        history(
                          `/dashboard?tab=5&subtab=0&step=2&accItem=${itemId}&scope=3`
                        );
                      }}
                    >
                      <RightChevronIcon cc={"#fff"} />
                    </button>
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default TargetStep1;
