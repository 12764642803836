import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import axios from "../../../../Api";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
const FAQTYPE = {
  generalfaq: "General",
  topicfaq: "Topic FAQ",
};

const Faqs = () => {
  const [faqs, setfaqs] = useState([]);
  const getdata = async () => {
    try {
      const response = await axios.get(`/admin/academy/faqs`);
      if (response.data.success) {
        setfaqs(response.data.data);
      }
    } catch (error) {
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  const navigate = useNavigate();
  const handleDeletefaq = async (fid) => {
    try {
      const response = await axios.delete(`/admin/academy/deletefaq/${fid}`);
      if (response.data.success) {
        getdata();
        toast.success("Faq deleted successfully");
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  return (
    <div>
      <h1 className="heading_9">FAQs</h1>
      <div className="row mx-0">
        {faqs.map((v, i) => {
          return (
            <div key={i} className="mb-4 col-4">
              <Card>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {v.question}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Answer: {v.answer}
                  </Typography>
                  {v.link && v.link?.trim() != "" && (
                    <Typography variant="body2" color="text.secondary">
                      Link : {v.link}
                    </Typography>
                  )}
                  <Typography variant="body2" color="text.secondary">
                    Faqtype : {FAQTYPE[v.faqtype]}
                  </Typography>
                  {v.faqtype === "topicfaq" && (
                    <Typography variant="body2" color="text.secondary">
                      Topic : {v?.topicid?.title}
                    </Typography>
                  )}
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() =>
                      navigate(
                        `/admin/dashboard?tab=5&activetab=3&fid=${v._id}`
                      )
                    }
                    size="small"
                  >
                    Edit
                  </Button>
                  <Button onClick={() => handleDeletefaq(v._id)} size="small">
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faqs;
