import React from "react";
import { useAuth } from "../../../../../../../../Utils/Auth";
import { botAvatarGroup } from "../../../../../../../../Utils/botAvatarsGroup";
import ButtonComp from "../../../../../../../../Assets/components/Button";
function OverWriteEmissions(props) {
  const { user } = useAuth();
  return (
    <>
      <div className="productDetail_drawerHead pb-40">
        <div className="align_text_right mt-20 pr-20">
          <i
            className="bi bi-x-lg SideDrawer_close_icon pointer"
            onClick={() => props.CloseDrawer()}
          ></i>
        </div>
        <div className="pl-35 mt-10">
          {/* <p className="faint_text_2">Scope 1.2</p> */}
          <div className="d-flex justify-items-center">
            <p className="f-27 fw-700 align-items-center">
              Overwrite Existing Emissions
            </p>
            {/* <div className="d-flex align-items-center ml-8">
              <span className="orange_label">Mandatory</span>
            </div> */}
          </div>
          <p className="subheading_light_2 mt-10">
            Overwriting an existing monthly input with yearly output.
          </p>
        </div>
      </div>

      <div className="container p-50 pt-0">
        <div className="row card_design_1 w-80 mt-50 p-32">
          <div className="col-md-6">
            <p className="f-40 fw-700 font-inter">Are you sure?</p>

            <p className="body_text mt-32">
              When overwriting existing emission data in a monthly formate we
              want to make sure you understand that a yearly input can be less
              accurate than a monthly input.If you want to override the data
              anyway, go ahead and confirm, by clicking on the button below.
            </p>

            <ButtonComp
              status={1}
              className="md f-15 mt-32"
              onClick={() => {
                props.handleMatrixCellSubmit();
              }}
            >
              Yes, Override Existing Emission Data
            </ButtonComp>
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <img
              className="subClassDataFill_img_fit "
              src={botAvatarGroup[user?.bot_avatar_id]?.sentSuccessful?.url}
              style={{ maxHeight: 280, width: "auto" }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OverWriteEmissions;
