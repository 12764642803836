import Icons from "./avatars.json";
export const getAvatars = (avatar_id, type) => {
  var iconItem = Icons.find((item) => item.avatar_id === avatar_id);
  return iconItem[type];
};

export const convertToTonnes = (per_kg) => {
  let euro_perKg = (per_kg / 100) * 0.92;
  let euro_pertonne = euro_perKg * 1000;
  return per_kg ? euro_pertonne.toFixed(2) : 0;
};
