import { VisibilityOffOutlined } from "@mui/icons-material";
import React from "react";

const ClimateFactBox = ({ year, title, iconImage, visible }) => {
  return (
    <div className={"climate_fact_box "+(!visible && "climate_fact_box_disabled")}>
      <div>
        <p className="font_42_800_plus_jakarta">{year}</p>
        <p className="font_16_400 color_57">{title}</p>
      </div>
      {visible ? (
        <div className="climate_icon_box">
          <img src={iconImage} alt="fact" />
        </div>
      ) : (
        <VisibilityOffOutlined sx={{ height: 25, width: 25 }} htmlColor="#949494" />
      )}
    </div>
  );
};

export default ClimateFactBox;
