import React, { useEffect, useState } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import Axios from "../../../../Api";
import toast from "react-hot-toast";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { Close } from "@mui/icons-material";
import DeleteModal from "../../../../Assets/components/DeleteModal/DeleteModal";
import { FormControl, MenuItem, Select } from "@mui/material";
import { SketchPicker } from "react-color";

const CreateTag = () => {
  const [tagName, setTagName] = useState("");
  const [tagName_de, setTagName_de] = useState("");
  const [bg_color, setBGColor] = useState("");
  const [color, setColor] = useState("");
  const [allTags, setAllTags] = useState([]);
  const [colorComb, setColorComb] = useState("");
  const [isEdit, setIsEdit] = useState({
    status: false,
    id: null,
  });
  const [dltId, setDltId] = useState("");
  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = () => setdltOpen(true);
  const handledltClose = () => setdltOpen(false);

  // add color;
  const [allColors, setAllColors] = useState([]);
  const [txtColor, setTxtColor] = useState("#ffffff");
  const [backgroundColor, setBackgroundColor] = useState("#000000");
  const [isEditColor, setIsEditColor] = useState({
    status: false,
    id: null,
  });

  const [dltColorId, setDltColorId] = useState("");
  const [dltColorOpen, setdltColorOpen] = useState(false);
  const handledltColorOpen = () => setdltColorOpen(true);
  const handledltColorClose = () => setdltColorOpen(false);

  const getTags = async () => {
    await Axios({
      url: `/admin/academy/all-tags`,
      method: "get",
    })
      .then((res) => {
        setAllTags(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };

  const getColors = async () => {
    await Axios({
      url: `/admin/academy/all-colors`,
      method: "get",
    })
      .then((res) => {
        setAllColors(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };

  const deleteTag = async () => {
    if (dltId) {
      await Axios({
        url: `/admin/academy/delete-tag/${dltId}`,
        method: "delete",
      })
        .then((res) => {
          if (res.data.success) {
            setDltId(null);
            handledltClose();
            getTags();
            toast.success("Tag deleted successfully");
          }
        })
        .catch((err) => {
          toastResponseError(err);
        });
    }
  };

  const deleteColor = async () => {
    if (dltColorId) {
      await Axios({
        url: `/admin/academy/delete-color/${dltColorId}`,
        method: "delete",
      })
        .then((res) => {
          if (res.data.success) {
            setDltColorId(null);
            handledltColorClose();
            getColors();
            toast.success("Color deleted successfully");
          }
        })
        .catch((err) => {
          toastResponseError(err);
        });
    }
  };

  const handleCreateColor = async () => {
    await Axios({
      url: isEditColor.status
        ? `/admin/academy/update-color/${isEditColor.id}`
        : `/admin/academy/create-color`,
      method: isEditColor.status ? "put" : "post",
      data: {
        text_color: txtColor,
        bg_color: backgroundColor,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setTxtColor("");
          setBackgroundColor("");
          toast.success(isEditColor.status ? "Color Updated" : "Color Created");
          getColors();
          setIsEditColor({
            status: false,
            id: null,
          });
        }
      })
      .catch((err) => {
        return toastResponseError(err);
      });
  };

  const handleCreateTag = async () => {
    await Axios({
      url: isEdit.status
        ? `/admin/academy/update-tag/${isEdit.id}`
        : `/admin/academy/create-tag`,
      method: isEdit.status ? "put" : "post",
      data: {
        tag_title: tagName,
        tag_title_de: tagName_de,
        text_color: color,
        bg_color: bg_color,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setColor("");
          setTagName("");
          setTagName_de("")
          setBGColor("");
          setColorComb("");
          getTags();
          toast.success(isEdit.status ? "Tag Updated" : "Tag Created");
          setIsEdit({
            status: false,
            id: null,
          });
        }
      })
      .catch((err) => {
        return toastResponseError(err);
      });
  };

  useEffect(() => {
    getTags();
    getColors();
  }, []);

  return (
    <div className="create-tag_root">
      <div className="form-group">
        <input
          type="text"
          value={tagName}
          onChange={(e) => setTagName(e.target.value)}
          className="form-control"
          placeholder="Tag title in English"
        />
        <input
          type="text"
          value={tagName_de}
          onChange={(e) => setTagName_de(e.target.value)}
          className="form-control mt-12"
          placeholder="Tag title in German"
        />
        <FormControl sx={{ width: "100%" }} className="mt-20">
          <Select
            displayEmpty
            onChange={(e) => {
              const { value } = e.target;
              const [bg, color] = value.split("-");
              setColor(color);
              setBGColor(bg);
              setColorComb(value);
            }}
            value={colorComb}
            style={{ backgroundColor: bg_color, color: color }}
            inputProps={{ "aria-label": "Without label" }}
          >
            <MenuItem value="">Select Color Combination</MenuItem>
            {allColors.map((color) => (
              <MenuItem
                value={`${color.bg_color}-${color.text_color}`}
                style={{
                  backgroundColor: color.bg_color,
                  color: color.text_color,
                }}
                key={color._id}
              >
                Text
              </MenuItem>
            ))}
            {/* <MenuItem
              value="#CDD4FF-#2441F5"
              style={{ backgroundColor: "#CDD4FF", color: "#2441F5" }}
            >
              Blue
            </MenuItem>
            <MenuItem
              value="#CDF1E3-#00915B"
              style={{ backgroundColor: "#CDF1E3", color: "#00915B" }}
            >
              Green
            </MenuItem>
            <MenuItem
              value="#F2D1FE-#AB00C7"
              style={{ backgroundColor: "#F2D1FE", color: "#AB00C7" }}
            >
              Purple
            </MenuItem> */}
          </Select>
        </FormControl>
        {isEdit.status ? (
          <ButtonComp status={2} className="mt-20" onClick={handleCreateTag}>
            Update
          </ButtonComp>
        ) : (
          <ButtonComp status={2} className="mt-20" onClick={handleCreateTag}>
            Create
          </ButtonComp>
        )}
      </div>

      {allTags.length > 0 && (
        <div className="mt-40">
          <h3>All Tags</h3>
          <div className="d-flex align-items-center gap-4 flex-wrap mt-20">
            {allTags.map((tag) => (
              <div
                key={tag._id}
                style={{
                  backgroundColor: tag.bg_color,
                  padding: "4px 8px",
                  borderRadius: 5,
                }}
                onDoubleClick={() => {
                  setTagName(tag.tag_title);
                  setTagName_de(tag.tag_title_de ?tag.tag_title_de:"" )
                  setBGColor(tag.bg_color);
                  setColor(tag.text_color);
                  setColorComb(`${tag.bg_color}-${tag.text_color}`);
                  setIsEdit({
                    status: true,
                    id: tag._id,
                  });
                }}
              >
                <p className="heading_5" style={{ color: tag.text_color }}>
                  {tag.tag_title}{" "}
                  <Close
                    fontSize="small"
                    className="ml-8 pointer"
                    htmlColor={tag.text_color}
                    onClick={() => {
                      setDltId(tag._id);
                      handledltOpen();
                    }}
                  />
                </p>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="mt-64">
        <h1>Add new color combination</h1>
        <div className="d-flex gap-4">
          <div>
            <p className="mb-10">Choose Background color</p>
            <SketchPicker
              color={backgroundColor}
              onChange={(val) => {
                setBackgroundColor(val.hex);
              }}
            />
          </div>
          <div>
            <p className="mb-10">Choose text color</p>
            <SketchPicker
              color={txtColor}
              onChange={(val) => {
                setTxtColor(val.hex);
              }}
            />
          </div>
        </div>
        <ButtonComp status={2} className="mt-20" onClick={handleCreateColor}>
          {isEditColor.status ? "Update Color" : "Add Color"}
        </ButtonComp>
      </div>
      {allColors.length > 0 && (
        <div className="mt-40">
          <h3>All Colors</h3>
          <div className="d-flex align-items-center gap-4 flex-wrap mt-20">
            {allColors.map((color) => (
              <div
                key={color._id}
                style={{
                  backgroundColor: color.bg_color,
                  padding: "4px 8px",
                  borderRadius: 5,
                }}
                onDoubleClick={() => {
                  setBackgroundColor(color.bg_color);
                  setTxtColor(color.text_color);
                  setIsEditColor({
                    status: true,
                    id: color._id,
                  });
                }}
              >
                <p className="heading_5" style={{ color: color.text_color }}>
                  Text
                  <Close
                    fontSize="small"
                    className="ml-8 pointer"
                    htmlColor={color.text_color}
                    onClick={() => {
                      setDltColorId(color._id);
                      handledltColorOpen();
                    }}
                  />
                </p>
              </div>
            ))}
          </div>
        </div>
      )}
      <DeleteModal
        open={dltOpen}
        handleClose={handledltClose}
        confirmFunction={() => deleteTag()}
        subtitle={`Once you delete a Tag, you can't recover it.\n Please be sure before deleting it.`}
        deleteTitle={"Delete Tag"}
      />
      <DeleteModal
        open={dltColorOpen}
        handleClose={handledltColorClose}
        confirmFunction={() => deleteColor()}
        subtitle={`Once you delete This, you can't recover it.\n Please be sure before deleting it.`}
        deleteTitle={"Delete Color"}
      />
    </div>
  );
};

export default CreateTag;
