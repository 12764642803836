import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import axios from "../../../../Api";
import ButtonComp from "../../../../Assets/components/Button";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import { useNavigate } from "react-router-dom";
import CoverImageCropper from "../../../../Components/CommonComponents/CoverImageCropper/CoverImageCropper";

const CreateTopic = () => {
  const navigate = useNavigate();
  const [allValues, setAllValues] = useState({
    title: "",
    description: "",
    icon: null,
    coverimage: null,
    title_de:"",
    description_de:""
  });
  const [cover, setCover] = useState("");
  const inputFile = useRef();
  const inputFileCover = useRef();
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  // const deleteIcon = () => {
  //   setAllValues({ ...allValues, icon: null });
  // };
  // const deleteCover = () => {
  //   setAllValues({ ...allValues, coverimage: null });
  // };

  const handlePicUpload = async (e, type) => {
    const image = e.target.files[0];
    // if (image.size > 2048 * 1024) {
    //   toast.error("File size exceeded 2MB!", { position: "bottom-right" });
    //   return;
    // }
    const formdata = new FormData();
    formdata.append(`${type}`, image);
    try {
      const res = await axios({
        method: "post",
        url: `/admin/academy/${type}`,
        data: formdata,
      });
      if (res) {
        toast.success("Icon upload");
        setAllValues({ ...allValues, icon: res.data.location });
      } else {
        toast.error("Something went wrong, please try again");
      }
    } catch (error) {
      // if (error && error.response.status === 401) {
      //   navigate("/admin/login");
      // }
      toastResponseError(error);
      return false;
    }
  };

  const handleCoverUpload = async (imageFile) => {
    const formdata = new FormData();
    formdata.append(`topiccover`, imageFile);
    try {
      const res = await axios({
        method: "post",
        url: `/admin/academy/topiccover`,
        data: formdata,
      });
      if (res) {
        toast.success("Cover Image upload");
        setAllValues({ ...allValues, coverimage: res.data.location });
        return res.data.location;
      } else {
        toast.error("Something went wrong, please try again");
        return false;
      }
    } catch (error) {
      // if (error && error.response.status === 401) {
      //   navigate("/admin/login");
      // }
      toastResponseError(error);
      return false;
    }

    // await axios({
    //   method: "post",
    //   url: `/admin/academy/topiccover`,
    //   data: formdata,
    // })
    //   .then((res) => {
    //     if (type === "topiccover") {
    //       setAllValues({ ...allValues, coverimage: res.data.location });
    //     } else if (type === "topicicon") {
    //       setAllValues({ ...allValues, icon: res.data.location });
    //     }

    //     setIsLogoUploading(false);

    //     toast.success("Logo uploaded");
    //   })
    //   .catch((error) => {
    //     if (error && error.response.status === 401) {
    //       navigate("/admin/login");
    //     }
    //     toastResponseError(error);
    //   });
  };

  const handleCreateTopic = async () => {
    // e.preventDefault();
    try {
      const url = topicid
        ? `/admin/academy/update-topic/${topicid}`
        : `/admin/academy/create-topic`;
      const response = await axios.post(url, allValues);
      if (response.data.success) {
        if (topicid) {
          toast.success("Topic updated successfully");
        } else {
          toast.success("Topic created successfully");
        }
        navigate(
          `/admin/dashboard?tab=5&activetab=0&step=1&topicid=${response.data?.data?._id}`
        );
        setAllValues({
          title: "",
          description: "",
          icon: null,
          coverimage: null,
          title_de:"",
          description_de:""
        });
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  const getdata = async () => {
    try {
      if (!topicid) return;
      const response = await axios.get(`/admin/academy/topic/${topicid}`);
      if (response.data.success) {
        setAllValues(response.data.data);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  const topicid = getUrlqueries("topicid");
  useEffect(() => {
    getdata();
  }, [topicid]);
  return (
    <div>
      {/* <form onSubmit={handleCreateTopic}> */}
      <div className="form-group">
        <CoverImageCropper
          avatar={cover}
          setAvatar={setCover}
          handlePicUpload={handleCoverUpload}
          inputFileRef={inputFileCover}
          ratio={3 / 1}
        />
      </div>
      <div className="form-group mt-24">
        <label htmlFor="exampleInputEmail1">English Title</label>
        <input
          onChange={changeHandler}
          type="text"
          value={allValues.title}
          required
          name="title"
          className="form-control"
          placeholder="Enter Title"
        />
      </div>
      <div className="form-group mt-24">
        <label htmlFor="exampleInputEmail1">German Title</label>
        <input
          onChange={changeHandler}
          type="text"
          value={allValues.title_de}
          required
          name="title_de"
          className="form-control"
          placeholder="Enter German title"
        />
      </div>
      <div className="form-group mt-24">
        <label>English Description</label>
        <textarea
          required
          value={allValues.description}
          onChange={changeHandler}
          type="text"
          name="description"
          className="form-control"
          placeholder="Enter Description"
        />
      </div>
      <div className="form-group mt-24">
        <label>German description</label>
        <textarea
          required
          value={allValues.description_de}
          onChange={changeHandler}
          type="text"
          name="description_de"
          className="form-control"
          placeholder="Enter Description"
        />
      </div>
      <div className="form-group mt-24">
        <label htmlFor="exampleFormControlFile1">Icon Upload</label>
        <div hidden={!allValues.icon}>
          Uploaded icon
          <img src={allValues.icon} height="50px" width="50px" />
        </div>
        <br />

        <input
          ref={inputFile}
          onChange={(e) => handlePicUpload(e, "topicicon")}
          type="file"
          className="form-control-file"
          id="exampleFormControlFile1"
          accept="image/*"
        />
      </div>
      <br />

      <br />

      <ButtonComp onClick={() => handleCreateTopic()} status={1} type="button">
        {topicid ? "Update Topic" : "Create Topic"}
      </ButtonComp>
      {/* </form> */}
    </div>
  );
};

export default CreateTopic;
