import React, { useEffect } from "react";
import { useAuth } from "../../Utils/Auth";
import { useNavigate } from "react-router";
import ButtonComp from "../../Assets/components/Button";

export default function CompleteRegisteration() {
  const { signOutBasic } = useAuth();
  const history = useNavigate();
  useEffect(() => {
    signOutBasic();
  }, []);
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      history("/");
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-7 text-center avatar_select_success">
          <img
            src="https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/1-joy.png"
            alt="joy-avatar"
          />
        </div>
        <div className="d-flex col-md-5 align-items-center register_info_container">
          <div className="d-flex align-items-start flex-column justify-content-center ml-32 col-md-10">
            <p className="purple_label mb-16">Sign-Up Complete!</p>
            <p className="heading_1 mt-16 mb-16 text-start">
              Welcome to today.green. We are looking forward to seeing you
              manage to zero!
            </p>
            <p className="text-start body_text_l">
              Once your invoice was [aid, you are ready to log in and start your
              carbon management journey.
            </p>
            <div className="text-end mt-32 w-100">
              <ButtonComp
                type="submit"
                className="lg"
                status={1}
                onClick={() => history("/")}
              >
                Go to Login{" "}
              </ButtonComp>
              <div className="d-flex justify-content-end mt-8">
                <p className="register_enter"> Press Enter</p>
                <i className="bi bi-arrow-return-left"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
