import React, { useEffect, useRef, useState } from "react";
import "./createSurvey.css";
import PropTypes from "prop-types";
import {
  Drawer,
  Step,
  Stepper,
  StepLabel,
  Modal,
  Backdrop,
  Fade,
  Box,
} from "@mui/material";
import ButtonComp from "../../../../../../Assets/components/Button";
import { v4 as uuidv4 } from "uuid";
import ReviewAndShare from "./Components/ReviewAndShare";
import NewSurveyQuestions from "./Components/NewSurveyQuestions";
import AddParticipants from "./Components/AddParticipants";
import Axios from "../../../../../../Api";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../Utils/Auth";
import sustainabilityTemplates from "../../../../../../DataJSON/surveyTemplates.json";
import toast from "react-hot-toast";
import { checkWorkEmail } from "../../../../../../Utils/CommonFunctions";
const steps = ["1. Creation", "2. Participants", "3. Review & Share"];
import partyTiemty from "../../../../../../Assets/icons/partyTiemty.png";
import LeftChevronIcon from "../../../../../../Assets/icons/LeftChevronIcon";
import RightChevronIcon from "../../../../../../Assets/icons/RightChevronIcon";

const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 910,
  maxWidth: "90%",
  bgcolor: "white",
  outline: "none",
  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
  padding: "70px 50px 50px 50px",
  borderRadius: "10px",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
const CreateSurvey = (props) => {
  const newQuestionref = useRef();
  const { user } = useAuth();
  const { setSessionExpire } = useAuth();
  const [dragId, setDragId] = useState();
  const [boxes, setBoxes] = useState([
    {
      id: uuidv4(),
      order: 0,
      question: "",
      options: [],
      type: 0,
      "currQ.scaleEnd": "10",
    },
  ]);
  const [surveyName, setSurveyName] = useState("");
  const [surveyDesp, setSurveyDesp] = useState("");
  const [step, setStep] = useState(0);
  const [allUsers, setAllUsers] = useState(["", "", "", "", "", "", ""]);
  const [selectedUsers, setSelectUsers] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [participantsUser, setParticipants] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const checkParticipantList = () => {
    let found = false;
    allUsers.forEach((currUser) => {
      if (currUser.selected) found = true;
    });
    if (!found) toast.error("Please add atleast one participant");
    return found;
  };
  const checkQuestionsFilled = () => {
    let isEmpty = false;
    if (boxes.length == 0) {
      toast.error("Please Add Atleast One Question");
      isEmpty = true;
    }
    if (surveyName.trim().length == 0) {
      isEmpty = true;
      toast.error("Please Enter Survey Name");
    }
    if (surveyDesp.trim().length == 0) {
      isEmpty = true;
      toast.error("Please Enter Survey Description");
    }
    boxes.forEach((currQ, index) => {
      if (currQ.question.length == 0) {
        toast.error(
          `please fill the question field for question no. ${index + 1}`
        );
        isEmpty = true;
      }
      if (currQ.type == 0) {
        if (currQ.options.length == 0) {
          isEmpty = true;
          toast.error(
            `please add atleast one option for question no : ${index + 1}`
          );
        }
        currQ.options.forEach((currOp) => {
          if (currOp.length == 0) {
            toast.error(
              `please fill the option fields for question no. ${index + 1}`
            );

            isEmpty = true;
          }
        });
      }
      if (currQ.type == 2) {
        if (
          currQ.scaleEnd.length == 0 ||
          parseInt(currQ.scaleEnd) < 5 ||
          parseInt(currQ.scaleEnd) > 10
        ) {
          toast.error("Please Enter Rating Between 5 an 10");
          isEmpty = true;
        }
      }
    });
    return isEmpty;
  };
  useEffect(() => {
    //console.log("this is user ",user)
    getAllUsers();
    getSurveyTemplates(false);
  }, []);
  function isNum(v) {
    return /^\d+$/.test(v);
  }

  const handleQuestionRatingChange = (id, value) => {
    if (isNum(value) || value.length == 0) {
      // if(parseInt(value)>10 || parseInt(value)<5) return;
      let currQuestions = [...boxes];
      let qIndex = currQuestions.findIndex((currQ) => currQ.id == id);
      currQuestions[qIndex]["scaleEnd"] = value;
      setBoxes(currQuestions);
    }
  };
  const handleUserClick = (email) => {
    let tempUser = [...allUsers];
    let findIndex = tempUser.findIndex(
      (currUser) => currUser.email.toLowerCase() == email.toLowerCase()
    );
    tempUser[findIndex]["selected"] = !tempUser[findIndex]["selected"];

    setAllUsers(tempUser);
  };

  const selectAllUsers = (val) => {
    let tempUser = [...allUsers];
    tempUser.forEach((currUser) => {
      if (val === "internal") {
        if (!currUser.isExternal) {
          currUser["selected"] = true;
        }
      } else {
        if (currUser.isExternal) {
          currUser["selected"] = true;
        }
      }
    });
    setAllUsers(tempUser);
  };

  const removeAllUsers = () => {
    let tempUser = [...allUsers];
    tempUser.forEach((currUser) => {
      currUser["selected"] = false;
    });
    setAllUsers(tempUser);
  };

  const addUserToParticipants = (index) => {
    let tempSelectedUser = [...selectedUsers];
    let isExists = tempSelectedUser.findIndex(
      (currUser) => currUser.email === allUsers[index].email
    );
    if (isExists == -1) {
      tempSelectedUser.push(allUsers[index]);
      setSelectUsers(tempSelectedUser);
    }
  };
  // const sendSurveyMails = async () =>{
  //   try{
  //     await Axios({
  //       url: `/company/user-survey`,
  //       method: "post",
  //       data: {
  //       },
  //     })
  //       .then((res) => {
  //         //   setDisable(false);
  //         if (res.data.success) {
  //           // getAllUsers();
  //           // setEmail("");
  //           // setFirstName("");
  //           // setLastName("");
  //           // setLogo("");
  //           //setRole(roles.viewer);
  //           toast.success(res.data.message);
  //           // handleClose();
  //         }
  //       })
  //       .catch((err) => {
  //         //  setDisable(false);
  //         toastResponseError(err, setSessionExpire);
  //       });
  //   }catch(err){
  //     console.log("err is ",err)
  //   }
  // }
  const addUserToList = async (firstName, lastName, email, userType, role) => {
    // console.log("checker ", firstName, lastName, email, user.companyId._id)
    if (
      firstName.trim().length == 0 ||
      lastName.trim().length == 0 ||
      email.trim().length == 0
    ) {
      return;
    }
    if (!checkWorkEmail(email) && userType.toLowerCase() === "internal") {
      return toast.error("Only work email are allowed");
    }
    let currUsers = [...allUsers];
    let isExists = currUsers.findIndex((curr) => curr.email === email);

    if (isExists === -1) {
      if (userType.toLowerCase() === "internal") {
        await Axios({
          url: `/company/invite-user`,
          method: "post",
          data: {
            email,
            firstName,
            lastName,
            role,
          },
        })
          .then((res) => {
            if (res.data.success) {
              currUsers.push({
                firstName: firstName,
                lastName: lastName,
                email: email,
                isExternal: false,
              });
              toast.success(res.data.message);
            } else {
              toast.error(res.data.message);
            }
          })
          .catch((err) => {
            //  setDisable(false);
            toastResponseError(err, setSessionExpire);
          });
      } else {
        currUsers.push({
          firstName: firstName,
          lastName: lastName,
          email: email,
          isExternal: true,
        });
      }
      setAllUsers(currUsers);
    } else {
      return toast.error("User already exists");
    }
  };
  // const addUserToList = async (firstName, lastName, email, userType) => {

  // }

  const removeFromSelectedUserList = (index) => {
    let tempUsers = [...selectedUsers];
    tempUsers.splice(index, 1);
    setSelectUsers(tempUsers);
  };

  const setTemplateFormat = (id, isDraft) => {
    let reqQuestions = templates.find((currQ) => currQ._id == id);
    setSurveyName(reqQuestions.surveyName);
    setSurveyDesp(reqQuestions.surveyDescription);
    let currQuestion = reqQuestions.questions.map((currQ, index) => {
      return {
        ...currQ,
        order: index,
        id: uuidv4(),
      };
    });
    setBoxes(currQuestion);
    if (isDraft) {
      props.setEditId(id);
    }
  };
  const getSurveyTemplates = async (isDraft) => {
    try {
      await Axios.get("/surveys/get-surveys", {
        isDraft: isDraft,
      }).then((res) => {
        if (res.data.success) {
          // console.log(res.data.data)
          let templateKeys = Object.keys(sustainabilityTemplates);

          let allTemplates = [...res.data.data];
          templateKeys.forEach((currTemplate) => {
            allTemplates.push({
              ...sustainabilityTemplates[currTemplate],
              surveyName: currTemplate,
            });
          });
          setTemplates(allTemplates);
        }
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const getAllUsers = async () => {
    await Axios({
      url: `/company/all-users`,
      method: "get",
    })
      .then((res) => {
        setAllUsers(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const handleSurveyDespChange = (desp) => {
    setSurveyDesp(desp);
  };

  const handleSurveyNameChange = (surveyName) => {
    setSurveyName(surveyName);
  };

  const handleDrag = (ev) => {
    setDragId(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = boxes.find((box) => box.id === dragId);
    const dropBox = boxes.find((box) => box.id === ev.currentTarget.id);

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newBoxState = boxes.map((box) => {
      if (box.id === dragId) {
        box.order = dropBoxOrder;
      }
      if (box.id === ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    setBoxes(newBoxState);
  };

  const handleGoBack = () => {
    if (step > 0) setStep(step - 1);
  };

  const handleAddQuestion = () => {
    let currQuestions = [...boxes];

    currQuestions.push({
      id: uuidv4(),
      order: boxes.length,
      question: "",
      options: [],
      type: 0,
      "currQ.scaleEnd": 10,
    });
    setBoxes(currQuestions);
    if (newQuestionref.current) {
      newQuestionref.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleQuestionChange = (id, value) => {
    let tempQuestion = [...boxes];
    let index = tempQuestion.findIndex((currQ) => currQ.id === id);

    tempQuestion[index]["question"] = value;
    setBoxes(tempQuestion);
  };

  const selectQuestionType = (type, id) => {
    let currQuestions = [...boxes];

    let QuestionIndex = currQuestions.findIndex((currQ) => currQ.id === id);
    currQuestions[QuestionIndex]["type"] = type;
    currQuestions[QuestionIndex]["options"] = [];
    setBoxes(currQuestions);
  };
  const removeQuestion = (id) => {
    let currQuestions = [...boxes].sort((a, b) => a.order - b.order);
    let foundQuestion = -1;
    currQuestions.forEach((currQ, index) => {
      if (foundQuestion != -1) {
        currQ.order -= 1;
        currQuestions[index] = currQ;
      }
      if (id === currQ.id) foundQuestion = index;
    });
    currQuestions.splice(foundQuestion, 1);
    setBoxes(currQuestions);
  };

  const addLikertScaleOptions = (id, options) => {
    let currQuestions = [...boxes];
    let QuestionIndex = currQuestions.findIndex((currQ) => currQ.id === id);

    currQuestions[QuestionIndex]["options"] = options;
    setBoxes(currQuestions);
  };
  const removeMulOptions = (id, index) => {
    let currQuestions = [...boxes];
    let QuestionIndex = currQuestions.findIndex((currQ) => currQ.id === id);
    currQuestions[QuestionIndex]["options"].splice(index, 1);
    setBoxes(currQuestions);
  };
  const addMulOptions = (id) => {
    let currQuestions = [...boxes];
    let QuestionIndex = currQuestions.findIndex((currQ) => currQ.id === id);
    currQuestions[QuestionIndex]["options"].push("");
    setBoxes(currQuestions);
  };
  const editMulOption = (id, index, value) => {
    let currQuestions = [...boxes];
    let QuestionIndex = currQuestions.findIndex((currQ) => currQ.id === id);
    currQuestions[QuestionIndex]["options"][index] = value;
    setBoxes(currQuestions);
  };

  const setToDefaultSurvey = () => {
    setBoxes([
      {
        id: uuidv4(),
        order: 0,
        question: "",
        options: [],
        type: 0,
        "currQ.scaleEnd": "10",
      },
    ]);
    setSurveyName("");
    setSurveyDesp("");
    getAllUsers();
  };
  const submitSurvey = async (isDraft) => {
    if (boxes.length == 0) {
      if (isDraft)
        toast.error("To save this survey as a draft, please add a question.");
      else toast.error("Please add atleast one question");
      return;
    }
    let questions = boxes.map((currQ) => {
      return {
        question: currQ.question,
        options: currQ.options,
        type: currQ.type,
        scaleStart: 1,
        scaleEnd: currQ.scaleEnd,
      };
    });
    let participants = [];
    allUsers.forEach((currUser) => {
      if (currUser.selected) {
        participants.push({
          userId: currUser._id,
          firstName: currUser.firstName
            ? currUser.firstName
            : currUser.firstname,
          lastName: currUser.lastName ? currUser.lastName : currUser.lastname,
          email: currUser.email,
          isInvited: currUser.inviteStatus ? true : false,
        });
      }
    });
    setParticipants(participants);
    setIsDisabled(true);
    try {
      await Axios.post("/surveys/create-survey", {
        questions,
        participants: isDraft ? [] : participants,
        surveyName,
        surveyDescription: surveyDesp,
        isDraft,
        surveyId: props.editId,
      }).then((res) => {
        if (res.data.success) {
          setIsDisabled(false);
          getSurveyTemplates();
          setToDefaultSurvey();
          props.getUserSurveys();
          props.handleOpenCreateSurvey();
          toast.success(res.data.message);
          setStep(0);
          if (!isDraft) {
            handleOpen();
          }
        }
      });
    } catch (error) {
      setIsDisabled(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    if (props.editId) {
      setTemplateFormat(props.editId);
    }
  }, [props.editId]);

  return (
    <div className="">
      <Drawer
        anchor={"right"}
        open={props.openCreateSurvey}
        onClose={() => {
          setToDefaultSurvey();
          props.handleOpenCreateSurvey();
        }}
        PaperProps={{
          sx: {
            minWidth: 1080,
            width: 1080,
            borderRadius: "5px 0px 0px 5px !important",
          },
        }}
      >
        <div className="survey_root">
          <div className="survey_main">
            <div className="flex-1">
              <div className="d-flex justify-content-between">
                <div className="flex-grow-1 d-flex justify-content-start align-items-center">
                  <p className="font_24_400">
                    {step === 0
                      ? "Create a New Survey"
                      : step === 1
                      ? "Add Participants"
                      : "Review & Share"}{" "}
                  </p>
                </div>
                <div className="flex-grow-1 d-flex align-items-center ">
                  <div className="flex-grow-1">
                    <Stepper nonLinear activeStep={step}>
                      {steps.map((label, index) => (
                        <Step
                          key={label}
                          className="border_1_bb br_round py-8 pl-8 pr-15"
                        >
                          <StepLabel
                            StepIconComponent={() => {
                              return step > index ? (
                                <div className="d-flex justify-content-center align-items-center br_50 h-30px w-30px border-ef">
                                  <i className="bi bi-check color_07"></i>
                                </div>
                              ) : index > step ? (
                                <div className="br_50 h-30px w-30px bg_f6 border-ef"></div>
                              ) : (
                                <div className="br_50 h-30px w-30px bg_green_07"></div>
                              );
                            }}
                          >
                            <span
                              className={
                                index === step ? "color_07" : "color_7e"
                              }
                            >
                              {label}
                            </span>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </div>
                </div>
              </div>
              {step == 0 ? (
                <NewSurveyQuestions
                  surveyName={surveyName}
                  setSurveyName={handleSurveyNameChange}
                  surveyDesp={surveyDesp}
                  setSurveyDesp={handleSurveyDespChange}
                  boxes={boxes}
                  handleDrag={handleDrag}
                  handleDrop={handleDrop}
                  handleAddQuestion={handleAddQuestion}
                  selectQuestionType={selectQuestionType}
                  removeQuestion={removeQuestion}
                  removeMulOptions={removeMulOptions}
                  addMulOptions={addMulOptions}
                  editMulOption={editMulOption}
                  handleQuestionChange={handleQuestionChange}
                  templates={templates}
                  setTemplateFormat={setTemplateFormat}
                  handleQuestionRatingChange={handleQuestionRatingChange}
                  addLikertScaleOptions={addLikertScaleOptions}
                />
              ) : step == 1 ? (
                <AddParticipants
                  allUsers={allUsers}
                  selectedUsers={selectedUsers}
                  addUserToParticipants={addUserToParticipants}
                  addUserToList={addUserToList}
                  removeFromSelectedUserList={removeFromSelectedUserList}
                  handleUserClick={handleUserClick}
                  selectAllUsers={selectAllUsers}
                  removeAllUsers={removeAllUsers}
                />
              ) : step == 2 ? (
                <ReviewAndShare
                  surveyName={surveyName}
                  surveyDescription={surveyDesp}
                  handleSurveyNameChange={handleSurveyNameChange}
                  handleSurveyDespChange={handleSurveyDespChange}
                  questions={boxes}
                  allUsers={allUsers}
                  removeQuestion={removeQuestion}
                  getSurveyTemplates={getSurveyTemplates}
                  handleGoBack={handleGoBack}
                />
              ) : null}
            </div>
            <div className="ml-52 pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="36"
                fill="none"
                viewBox="0 0 36 36"
                onClick={() => {
                  setToDefaultSurvey();
                  props.handleOpenCreateSurvey();
                }}
              >
                <path
                  stroke="#4C4C4C"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M27 9L9 27M9 9l18 18"
                ></path>
              </svg>
            </div>
          </div>
          <div className="surevey_footer" ref={newQuestionref}>
            {step === 0 ? (
              <div className="flex-grow-0 d-flex align-items-center">
                <p
                  onClick={() => {
                    if (!isDisabled) {
                      if (checkQuestionsFilled()) return;
                      submitSurvey(true);
                    }
                  }}
                  className="d-flex align-items-center font_13_500 color_72 bg_f6 pt-14 pb-14 pl-12 pr-20 pointer h-40px"
                >
                  <i className="bi bi-bookmark mr-6"></i> Save as draft
                </p>
                {boxes.length == 0 ? (
                  <p className="font_13_500 color_f4 ml-24">
                    To save this survey as a draft, please add a question.
                  </p>
                ) : (
                  ""
                )}
              </div>
            ) : (
              <div />
            )}

            <div className="d-flex flex-grow-0">
              {step > 0 ? (
                <div className="mr-20">
                  <ButtonComp
                    onClick={handleGoBack}
                    status={1}
                    className="bg_f6 color_7e h-40px"
                  >
                    <LeftChevronIcon cc={"#7e8999"} className={"mr-6"} />{" "}
                    Previous
                  </ButtonComp>
                </div>
              ) : (
                ""
              )}
              {step < 2 ? (
                <ButtonComp
                  onClick={() => {
                    if (step == 0) if (checkQuestionsFilled()) return;

                    if (step == 1) {
                      if (checkQuestionsFilled()) return;
                      if (!checkParticipantList()) return;
                    }
                    if (step < 2) setStep(step + 1);
                  }}
                  status={5}
                  className="h-40px"
                >
                  Next <RightChevronIcon className={"ml-6"} />
                </ButtonComp>
              ) : (
                <ButtonComp
                  onClick={() => {
                    if (!checkParticipantList() || checkQuestionsFilled()) {
                      return;
                    }
                    // sendSurveyMails()
                    submitSurvey(false);
                  }}
                  status={5}
                  disabled={isDisabled}
                  className="h-40px"
                >
                  Share Survey <RightChevronIcon className={"ml-6"} />
                </ButtonComp>
              )}
            </div>
          </div>
        </div>
      </Drawer>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="d-flex align-items-center">
              <img src={partyTiemty} alt="success" />
              <p className="font_40_700 line_height_18 color_009 ml-20">
                Success
              </p>
            </div>
            <p className="mt-24 font_20_500 line_height_28 color_54">
              Your survey has been sent out to your participants. You will be
              notified once all participants have completed your survey.
            </p>
            <div className="d-flex justify-content-end mt-40">
              {participantsUser.findIndex((currUser) => {
                currUser.userId === user.id;
              }) === -1 ? (
                <ButtonComp status={5} onClick={handleClose}>
                  Close
                </ButtonComp>
              ) : (
                <>
                  <ButtonComp status={8} onClick={handleClose}>
                    Close
                  </ButtonComp>
                  <ButtonComp
                    status={5}
                    onClick={handleClose}
                    className="ml-24"
                  >
                    Take Survey
                  </ButtonComp>
                </>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default CreateSurvey;
