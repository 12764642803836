import React, { useCallback, useEffect, useState } from "react";
import { botAvatarGroup } from "../../../../../Utils/botAvatarsGroup";
import { useAuth } from "../../../../../Utils/Auth";
import ButtonComp from "../../../../../Assets/components/Button";
import CreateTargetDrawer from "./CreateTargetDrawer";
import PencilIcon from "../../../../../Assets/icons/PencilIcon";
import TargetHeaderCard from "./Components/TargetHeaderCard";
import "./index.css";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../Utils/TableComp";

import RightChevronIcon from "../../../../../Assets/icons/RightChevronIcon";
import { FormatListBulleted } from "@mui/icons-material";
// import DownloadIcon from "../../../../../Assets/icons/DownloadIcon";
import TasksDrawer from "./TasksDrawer";
import ChartMain from "./Components/Chart/ChartMain";
import Axios from "../../../../../Api";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import UpIntradayIcon from "../../../../../Assets/icons/UpIntradayIcon";
import DonwIntradayIcon from "../../../../../Assets/icons/DownIntraday";
import useQuery from "../../../../../Utils/query";
import { useNavigate } from "react-router-dom";
import TargetStep1 from "./Components/TargetStep1";
import TargetStep2 from "./Components/TargetStep2";
// import EditIcon from "../../../../../Assets/icons/EditIcon";

const Targets = () => {
  const query = useQuery();
  const screenStep = Number(query.get("step"));
  const history = useNavigate();
  const { user, setSessionExpire } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = (val) => {
    setIsEdit(val);
    setOpen(true);
  };
  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, [open]);

  const [taskOpen, setTaskOpen] = useState(false);
  const handleTaskDrawerOpen = () => setTaskOpen(true);
  const handleTaskDrawerClose = useCallback(() => {
    setTaskOpen(false);
  }, [taskOpen]);
  const [targetData, setTargetData] = useState(null);
  const [allTasks, setAllTasks] = useState([]);
  const [emissionCard, setEmissionCard] = useState(null);
  const [notbaseLineCard, setNotbaseLineCard] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [currAccItems, setcurrAccItems] = useState();
  const [baseAccItems, setbaseAccItems] = useState();
  const [step, setstep] = useState(0);
  const [target2HeaderData, setTarget2HeaderData] = useState({
    baseline: 0,
    baseLineYear: 0,
    targetEmission: 0,
    currentEmission: 0,
    reducedYtd: 0,
    reducedYtdPer: 0,
    plannedReduction: 0,
  });

  const getTarget = async () => {
    await Axios({
      url: `/target/get-target`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          setTargetData(res.data.target);
          setEmissionCard(res.data.emissionCards);
          let curObj = res.data.target.accItems.find(
            (item) => item.year === new Date().getFullYear()
          );
          if (curObj) setcurrAccItems(curObj);
          if (res.data.target.baseYear < new Date().getFullYear()) {
            setbaseAccItems(
              res.data.target.accItems.find(
                (item) => item.year === new Date().getFullYear() - 1
              )
            );
          } else {
            setbaseAccItems(curObj);
          }
        } else {
          setTargetData(null);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getTasks = async (id) => {
    await Axios({
      url: `/target/get-task/${id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setAllTasks(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getChartData = async () => {
    await Axios({
      url: `/target/get-chart`,
      method: "get",
    })
      .then((res) => {
        setChartData(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getTarget();
    getChartData();
  }, []);

  const avgYTd = emissionCard?.baseLineEmission / 12;
  const tilYtd = avgYTd * (new Date().getMonth() + 1);

  const ytd =
    emissionCard &&
    emissionCard.baseLineEmission &&
    emissionCard.currentEmission
      ? Number(tilYtd - emissionCard.currentEmission) * -1
      : 0;

  const ytdpercent = ytd > 0 ? 0 : (ytd * 100) / tilYtd;

  const getYtd = (baseEmis, currEmis) => {
    let baseAvg = baseEmis / 12;
    let curBase = baseAvg * (new Date().getMonth() + 1);
    return Number(curBase - currEmis);
  };

  const getytdPerc = (baseEmis, currEmis) => {
    let baseAvg = baseEmis / 12;
    let tilYtd = baseAvg * (new Date().getMonth() + 1);
    let ytdA = getYtd(baseEmis, currEmis);
    let ytdpercent = ytdA < 0 ? 0 : (ytdA * 100) / tilYtd;
    return ytdpercent;
  };

  useEffect(() => {
    if (screenStep || screenStep === 0) {
      setstep(screenStep);
    }
  }, [screenStep]);

  return (
    <div>
      {targetData ? (
        <>
          {step === 0 ? (
            <div className="row_20_gap py-20">
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap_20">
                  <div className="container_br_5_p_10_8 h-40px center">
                    <p className="offsetProjectTitle line_height_24">
                      Reduction Target
                    </p>
                  </div>
                  <div className="container_br_5_p_10_8 h-40px center">
                    {targetData.targetType === "Custom" ? (
                      <p className="font_20_600 line_height_20 color_57">
                        {targetData.targetType}
                      </p>
                    ) : (
                      <p className="font_20_600 line_height_20 color_57">
                        SBTi{" "}
                        {targetData.targetType === "gt2"
                          ? ">2"
                          : targetData.targetType}{" "}
                        °C
                      </p>
                    )}
                  </div>
                  <div className="container_br_5_p_10_8 h-40px center bg_green">
                    <p className="font_20_600 line_height_20 text-white">
                      {targetData.targetYear}
                    </p>
                  </div>
                  <button
                    className="profileEditbtn h-40px w-40px"
                    onClick={() => {
                      handleDrawerOpen(true);
                    }}
                  >
                    <PencilIcon cc={"#000"} />
                  </button>
                </div>
                <div className="d-flex align-items-center gap_30">
                  <div
                    className="w-40px h-40px bg_soft_green border_radius_5 center pointer position-relative"
                    onClick={handleTaskDrawerOpen}
                  >
                    <FormatListBulleted htmlColor="#00915B" />
                    {/* <div className="task_toggleCount center">
                      <p className="font_12_600 line_height_10 text-white">3</p>
                    </div> */}
                  </div>
                  {/* <div className="w-40px h-40px bg_white border_radius_5 center">
                <DownloadIcon cc="#000" />
              </div> */}
                </div>
              </div>
              <hr className="mt-30 mb20" />
              {notbaseLineCard ? (
                <div className="row">
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(false)}
                  >
                    <TargetHeaderCard
                      title={"Overall"}
                      percent={targetData.overallReduction}
                      papercent={`${
                        (targetData.scope1ReductionPercentage +
                          targetData.scope2ReductionPercentage +
                          targetData.scope3ReductionPercentage) /
                        (targetData.targetTimeFrame * 3)
                      }% p.a`}
                      tonsValue={-3000}
                      isBaseLine={false}
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(false)}
                  >
                    <TargetHeaderCard
                      title={"Scope 1"}
                      percent={targetData.scope1Reduction}
                      papercent={`${
                        targetData.scope1ReductionPercentage /
                        targetData.targetTimeFrame
                      }% p.a`}
                      tonsValue={-3000}
                      isBaseLine={false}
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(false)}
                  >
                    <TargetHeaderCard
                      title={"Scope 2"}
                      percent={targetData.scope2Reduction}
                      papercent={`${
                        targetData.scope2ReductionPercentage /
                        targetData.targetTimeFrame
                      }% p.a`}
                      tonsValue={-3000}
                      isBaseLine={false}
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(false)}
                  >
                    <TargetHeaderCard
                      title={"Scope 3"}
                      percent={targetData.scope3Reduction}
                      papercent={`${
                        targetData.scope3ReductionPercentage /
                        targetData.targetTimeFrame
                      }% p.a`}
                      tonsValue={-3000}
                      isBaseLine={false}
                    />
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(true)}
                  >
                    <TargetHeaderCard
                      title={"Baseline"}
                      percent={emissionCard?.baseLineTarget}
                      papercent={emissionCard?.baseLineYear}
                      footerValue={
                        <span>
                          Baseline emissions for {emissionCard?.baseLineYear}
                        </span>
                      }
                      isBaseLine={true}
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(true)}
                  >
                    <TargetHeaderCard
                      title={"Target Emissions"}
                      percent={emissionCard?.targetEmissions}
                      papercent={new Date().getFullYear()}
                      isBaseLine={true}
                      footerValue={
                        <span>
                          <span className="color_green">
                            {Number(
                              emissionCard?.currentEmission
                            ).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}{" "}
                            tCO2e
                          </span>{" "}
                          <span>Until now</span>
                        </span>
                      }
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(true)}
                  >
                    <TargetHeaderCard
                      title={"Reduced YTD"}
                      percent={ytd}
                      papercent={`${String(emissionCard?.baseLineYear).slice(
                        -2
                      )}/${String(new Date().getFullYear()).slice(-2)}`}
                      isBaseLine={true}
                      footerValue={
                        <span>
                          <span
                            className={`${
                              ytdpercent >= 0 ? "color_red" : "color_green"
                            }`}
                          >
                            {Number(ytdpercent).toLocaleString(undefined, {
                              maximumFractionDigits: 2,
                            })}
                            %
                          </span>{" "}
                          <span className="color_7e">
                            of Yearly Target Reduction
                          </span>
                        </span>
                      }
                    />
                  </div>
                  <div
                    className="col-lg-3 col-md-3 col-6"
                    onClick={() => setNotbaseLineCard(true)}
                  >
                    <TargetHeaderCard
                      title={"Planned Reduction"}
                      percent={"-" + targetData?.overallReduction}
                      papercent={targetData.targetYear}
                      isBaseLine={true}
                      tonsValue={-3000}
                      footerValue={
                        <span>
                          <span
                            className={`${
                              ytdpercent >= 0 ? "color_red" : "color_green"
                            }`}
                          >
                            {Number(100 - ytdpercent * -1).toLocaleString(
                              undefined,
                              {
                                maximumFractionDigits: 2,
                              }
                            )}
                            %
                          </span>{" "}
                          <span className="color_7e">
                            left to meet yearly target
                          </span>
                        </span>
                      }
                    />
                  </div>
                </div>
              )}
              <div className="container_br_10_p_20 mt-20">
                <div className="center gap_20 mb-48">
                  <div className="align_center gap_5">
                    <div className="circle_10 bg_soft_blue_bc" />
                    <p className="font_12_500 color_57">S1</p>
                  </div>
                  <div className="align_center gap_5">
                    <div className="circle_10 bg_scope2" />
                    <p className="font_12_500 color_57">S2</p>
                  </div>
                  {targetData.includeScope3 && (
                    <div className="align_center gap_5">
                      <div className="circle_10 bg_scope3" />
                      <p className="font_12_500 color_57">S3</p>
                    </div>
                  )}
                </div>
                <ChartMain
                  data={chartData.length > 0 ? chartData : []}
                  includeScope3={targetData.includeScope3}
                />
              </div>
              <div className="mt-20 container_br_10_p_20 h-70px d-flex align-items-center">
                <p className="font_15_700 color_57">CO2e Budget</p>
                <div className="targetProgressbar_root flex-1 ml-40">
                  <div
                    className={`targetProgressbar ${
                      emissionCard?.currentEmission >
                      emissionCard?.targetEmissions
                        ? "red_pg_bar"
                        : ""
                    }`}
                    style={{
                      width: `${
                        emissionCard?.currentEmission >
                        emissionCard?.targetEmissions
                          ? 100
                          : (emissionCard?.currentEmission * 100) /
                            emissionCard?.targetEmissions
                      }%`,
                    }}
                  />
                </div>
                <p className="font_14_700 color_7e ml-52">
                  {emissionCard?.currentEmission.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                  })}
                  /
                  {Number(emissionCard?.targetEmissions).toLocaleString(
                    undefined,
                    {
                      maximumFractionDigits: 2,
                    }
                  )}
                </p>
                <span className="font_10_700 line_height_10 color_7e ml-6 pt-2">
                  tCO2e
                </span>
              </div>
              {targetData.targetScopes &&
                targetData.targetScopes.length > 0 && (
                  <TableContainer
                    component={Paper}
                    sx={{
                      minWidth: 640,
                      borderRadius: "9px",
                      boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
                    }}
                    className="mt-30 mb-20"
                  >
                    <Table sx={{ minWidth: 640 }} aria-label="simple table">
                      <TableHead>
                        <StyledHeadRow>
                          <HeadCell
                            component="th"
                            className="text-uppercase"
                            sx={{ borderLeft: "0", width: 500 }}
                          >
                            Accounting item
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase">
                            Baseline [{emissionCard?.baseLineYear}]
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase">
                            Target [{new Date().getFullYear()}]
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase">
                            Reduced YTD
                          </HeadCell>

                          <HeadCell
                            component="th"
                            className="text-uppercase"
                            sx={{ borderRight: "none", width: 70 }}
                          ></HeadCell>
                        </StyledHeadRow>
                      </TableHead>
                      <TableBody>
                        {targetData.targetScopes.map((accItem, i) => (
                          <StyledTableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                borderBottom: 0,
                              },
                            }}
                          >
                            <StyledTableCell
                              className="text-start border_left_0"
                              component="th"
                              scope="row"
                            >
                              <p className="font_14_400 line_height_14">
                                {accItem.itemDetails.name}
                              </p>
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              <div className="border_radius_10  h-30px center px-10 py-8 width_fit">
                                <p className="font_14_600 line_height_14">
                                  {Number(
                                    baseAccItems?.accItems[i]?.scope1Emission +
                                      baseAccItems?.accItems[i]
                                        ?.scope2Emission +
                                      baseAccItems?.accItems[i]?.scope3Emission
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}
                                  <span className="color_7e">&nbsp;tCO2e</span>
                                </p>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              <div className="border_radius_10  h-30px center px-10 py-8 width_fit gap_10">
                                <p className="font_14_600 line_height_14 ">
                                  {(
                                    currAccItems?.accItems[i]?.scope1Emission +
                                    currAccItems?.accItems[i]?.scope2Emission +
                                    currAccItems?.accItems[i]?.scope3Emission
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}
                                  <span className="color_7e">&nbsp;tCO2e</span>
                                </p>
                              </div>
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              <div className="border_radius_10  h-30px center py-8 width_fit gap_10">
                                <p
                                  className={`font_14_600 line_height_14 ${
                                    getYtd(
                                      baseAccItems?.accItems[i]
                                        ?.scope1Emission +
                                        baseAccItems?.accItems[i]
                                          ?.scope2Emission +
                                        baseAccItems?.accItems[i]
                                          ?.scope3Emission,
                                      accItem.currentEmission
                                    ) < 0
                                      ? "color_red"
                                      : "color_green"
                                  }`}
                                >
                                  {getYtd(
                                    baseAccItems?.accItems[i]?.scope1Emission +
                                      baseAccItems?.accItems[i]
                                        ?.scope2Emission +
                                      baseAccItems?.accItems[i]?.scope3Emission,
                                    accItem.currentEmission
                                  ) < 0 ? (
                                    <UpIntradayIcon />
                                  ) : (
                                    <DonwIntradayIcon />
                                  )}{" "}
                                  {Number(
                                    getYtd(
                                      baseAccItems?.accItems[i]
                                        ?.scope1Emission +
                                        baseAccItems?.accItems[i]
                                          ?.scope2Emission +
                                        baseAccItems?.accItems[i]
                                          ?.scope3Emission,
                                      accItem.currentEmission
                                    ) * -1
                                  ).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                  })}{" "}
                                  tCO2e
                                </p>
                              </div>
                            </StyledTableCell>

                            <StyledTableCell
                              className="text-start"
                              sx={{ borderRight: "none" }}
                            >
                              <button
                                className="h-30px w-30px center bg_green border-none border_radius_5"
                                onClick={() => {
                                  setstep(1);
                                  history(
                                    `/dashboard?tab=5&subtab=0&step=1&accItem=${accItem._id}`
                                  );
                                }}
                              >
                                <RightChevronIcon cc={"#fff"} />
                              </button>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
            </div>
          ) : step === 1 ? (
            <TargetStep1
              currAccItems={currAccItems}
              baseAccItems={baseAccItems}
              targetData={targetData}
              handleTaskDrawerOpen={handleTaskDrawerOpen}
              emissionCard={emissionCard}
              chartData={chartData}
              setstep={setstep}
              getYtd={getYtd}
              getytdPerc={getytdPerc}
              target2HeaderData={target2HeaderData}
              setTarget2HeaderData={setTarget2HeaderData}
            />
          ) : step === 2 ? (
            <TargetStep2
              currAccItems={currAccItems}
              baseAccItems={baseAccItems}
              targetData={targetData}
              handleTaskDrawerOpen={handleTaskDrawerOpen}
              emissionCard={emissionCard}
              chartData={chartData}
              setstep={setstep}
              getYtd={getYtd}
              getytdPerc={getytdPerc}
              target2HeaderData={target2HeaderData}
              setTarget2HeaderData={setTarget2HeaderData}
              getTarget={getTarget}
              getTasks={() => getTasks(targetData._id)}
              allTasks={allTasks}
            />
          ) : (
            ""
          )}
        </>
      ) : (
        <div
          className="no-data-root d-flex justify-content-between p-32 p_relative mt-32"
          style={{ maxWidth: "1200px" }}
        >
          <div className="d-flex justify-content-between flex-column">
            <div>
              <p className="heading_lg">
                The companies of tomorrow need targets
              </p>
              <p className="subheading_light mt-40 w-90">
                The Companies of tomorrow have targets. Set reduction targets
                and create actions and policies to get where you want to be.
              </p>
            </div>

            <div className="pt-24 mt-24">
              <ButtonComp status={5} onClick={() => handleDrawerOpen(false)}>
                Set Reduction Target
              </ButtonComp>
            </div>
          </div>
          {user && (
            <div style={{ zIndex: 1 }}>
              <img
                src={botAvatarGroup[user?.bot_avatar_id]["avatarSelect"]?.url}
                alt="launching"
                style={{ maxHeight: 310, width: "auto" }}
              />
            </div>
          )}
          <hr className="bg_divider" />
        </div>
      )}

      <CreateTargetDrawer
        open={open}
        handleDrawerClose={handleDrawerClose}
        getTarget={() => {
          getTarget();
          getChartData();
        }}
        isEdit={isEdit}
        targetData={targetData}
      />
      <TasksDrawer
        open={taskOpen}
        handleClose={handleTaskDrawerClose}
        targetData={targetData}
        allTasks={allTasks}
        setAllTasks={setAllTasks}
      />
    </div>
  );
};

export default Targets;
