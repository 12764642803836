import {
  Avatar,
  AvatarGroup,
  FormControl,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useState, useEffect, useRef, useMemo } from "react";
import { getExistUsersofCompany } from "../../../../../Utils/CommonApiRequests";
import { getServiceIcon } from "../../../../../Utils/itemIcon";
import TopChevronIcon from "../../../../../Assets/icons/TopChevronIcon";
import TwoUpArrow from "../../../../../Assets/icons/TwoUpArrow";
import ThreeUpArrow from "../../../../../Assets/icons/ThreeUpArrow";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ButtonComp from "../../../../../Assets/components/Button";
import RightChevronIcon from "../../../../../Assets/icons/RightChevronIcon";
import Axios from "../../../../../Api";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
import UserAddIcon from "../../../../../Assets/icons/UserAddIcon";
import AssigneeComp from "./Components/AssigneeComp";
import toast from "react-hot-toast";
import LeftChevronIcon from "../../../../../Assets/icons/LeftChevronIcon";
import {
  Close,
  FormatListBulleted,
  KeyboardArrowDown,
  MoreHoriz,
} from "@mui/icons-material";
import useQuery from "../../../../../Utils/query";
import DeleteIcon from "../../../../../Assets/icons/DelteIcon";
import PencilSmallIcon from "../../../../../Assets/icons/PencilSmallIcon";

const type = {
  todo: "Todo",
  progress: "In Progress",
  complete: "Completed",
};
const sx = {
  backgroundColor: "#fff",
  //   boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.03)",
  border: "none",
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "500",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

const MenuItemSx = {
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
};

const style_w_96 = {
  width: 96,
};

const CreateTask = ({
  targetData,
  setStep,
  getTasks,
  isFromSubScopeTarget,
  selectedScopeFromScopeTarget,
  selectedSubClassFromScopeTarget,
  selectedSubClassHeadFromScopeTarget,
  createScopeTarget,
  setTaskInfo,
  taskInfo,
  isOnlyTaskCreation,
  setEditTargetData,
  // step,
  // handleClose,
}) => {
  const quillRef = useRef();
  const { setSessionExpire } = useAuth();
  const query = useQuery();
  const itemId = query.get("accItem");

  const [accountingItems, setAccountingItems] = useState([]);
  const [scopeList, setScopeList] = useState([]);
  const [classesList, setClassList] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const [taskName, setTaskName] = useState("");
  const [selectedAccItem, setSelectedAccItem] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  // const [selectedsubClassHead, setSelectedSubClassHead] = useState("");
  const [selectedSubClass, setSelectedSubClass] = useState(null);
  const [priority, setPriority] = useState("low");
  const [dueDate, setDueDate] = useState("");
  const [status, setStatus] = useState("Todo");
  const [description, setDescription] = useState("");
  const [impact, setImpact] = useState("Low");
  const [cost, setCost] = useState();
  const [costCurrency, setCostCurrency] = useState("€");
  const [effort, setEffort] = useState("Low");
  const [selectedUsers, setSelectedUser] = useState([]);
  const [invitedUsers, setInvitedUser] = useState([]);
  const [showAssignMoToggle, setShowAssignMoToggle] = useState(false);
  const [allDisable, setAllDisable] = useState(false);
  const [openMenuOption, setOpenMenuOption] = useState(false);

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }],
        ],
      },
    }),
    []
  );
  const formats = ["bold", "italic", "underline", "list", "bullet"];

  useEffect(() => {
    (async () => {
      let users = await getExistUsersofCompany();
      setAllUsers(users);
    })();
  }, []);

  useEffect(() => {
    if (targetData) {
      let data = targetData.targetScopes;
      setAccountingItems(data);
    }
  }, [targetData]);
  const getIconName = (item) => {
    if (item.itemDetails.facilityType) return item.itemDetails.facilityType;
    else if (item.itemDetails.productType) {
      return item.itemDetails.productType;
    } else if (item.itemDetails.event) {
      return item.itemDetails.event;
    } else if (item.itemDetails.hotelType) {
      return item.itemDetails.hotelType;
    } else if (item.itemDetails.buildingType) {
      return item.itemDetails.buildingType;
    } else return item.accountingItemName;
  };

  const handleChangeAccountingItem = (val) => {
    setSelectedAccItem(val);
    const accItemJsonData = targetData.targetScopes.find(
      (item) => item._id === val
    );
    const itemScopes = accItemJsonData?.matrixItems.map((item) => item.scope);
    setScopeList(itemScopes);
  };

  useEffect(() => {
    if (itemId && targetData) {
      setSelectedAccItem(itemId);
      const accItemJsonData = targetData.targetScopes.find(
        (item) => item._id === itemId
      );
      const itemScopes = accItemJsonData?.matrixItems.map((item) => item.scope);
      setScopeList(itemScopes);
    }
  }, [itemId, targetData]);

  useEffect(() => {
    if (
      selectedScopeFromScopeTarget &&
      isFromSubScopeTarget &&
      scopeList.length > 0
    ) {
      const newString = scopeList.find(
        (scope) =>
          scope.toLowerCase() === selectedScopeFromScopeTarget.toLowerCase()
      );
      handleChangeScope(newString, itemId);
    }
  }, [selectedScopeFromScopeTarget, isFromSubScopeTarget, scopeList]);
  useEffect(() => {
    if (
      selectedScopeFromScopeTarget &&
      isFromSubScopeTarget &&
      selectedSubClassFromScopeTarget &&
      selectedSubClassHeadFromScopeTarget &&
      classesList.length > 0
    ) {
      const findSubClass = classesList.find((data) => {
        if (
          data.subscope === selectedScopeFromScopeTarget &&
          data.subClass === selectedSubClassFromScopeTarget &&
          data.subClassHead === selectedSubClassHeadFromScopeTarget
        ) {
          return data;
        }
      });
      setSelectedSubClass(findSubClass);
    }
  }, [isFromSubScopeTarget, classesList]);

  const handleChangeScope = async (scope, logId) => {
    if (scope) {
      setSelectedScope(scope);
      await Axios({
        url: `/log/subclasses`,
        params: {
          subScopeName: scope.toLowerCase(),
          logId: logId,
        },
      })
        .then((res) => {
          if (res.data.success) {
            const { data } = res.data;
            const classListData = data
              .map((classItem) => {
                const particulars = classItem.particulars.map((particular) => ({
                  subscope: classItem.subScope,
                  subClass: particular.subClass,
                  subClassHead: particular.subClassHead,
                }));
                return particulars;
              })
              .flat();
            const uniqueSubClass = [];
            classListData.forEach((obj) => {
              if (
                !uniqueSubClass.some(
                  (uniqueObj) => uniqueObj.subClass === obj.subClass
                )
              ) {
                uniqueSubClass.push(obj);
              }
            });
            setClassList([...uniqueSubClass]);
          }
        })
        .catch((err) => {
          toastResponseError(err, setSessionExpire);
        });
    }
  };

  const handleAssignUser = (user) => {
    const findIndex = selectedUsers.findIndex((data) => data._id === user._id);
    if (findIndex === -1) {
      setSelectedUser((prev) => [...prev, user]);
      const newAllUsers = allUsers.filter((data) => data._id !== user._id);
      setAllUsers(newAllUsers);
    }
  };

  const handleRemoveUser = (user) => {
    setAllUsers((prev) => [...prev, user]);
    const newSelectedUser = selectedUsers.filter(
      (data) => data._id !== user._id
    );
    setSelectedUser(newSelectedUser);
  };

  const handleRemoveInvitedUser = (user) => {
    const newSelectedUser = invitedUsers.filter(
      (data) => data._id !== user._id
    );
    setInvitedUser(newSelectedUser);
  };

  const handleCreateTask = async () => {
    await Axios({
      url: taskInfo
        ? `/target/edit-task/${taskInfo._id}/${targetData._id}`
        : `/target/create-task/${targetData._id}/${selectedAccItem}`,
      method: taskInfo ? "put" : "POST",
      data: {
        taskName: taskName,
        subScope: selectedScope,
        subclass: selectedSubClass,
        assignee: selectedUsers.map((user) => user._id),
        invitedAssignee: invitedUsers.map((user) => user._id),
        priority: priority,
        due_date: dueDate,
        status: status,
        description: description,
        impact,
        cost: Number(cost),
        costCurrency,
        effort,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          if (isFromSubScopeTarget && !isOnlyTaskCreation) {
            createScopeTarget();
          } else if (isFromSubScopeTarget && isOnlyTaskCreation) {
            createScopeTarget();
            setTaskInfo(null);
            setEditTargetData({
              isEdit: false,
              subClassHead: "",
              subClass: "",
              subscope: "",
              reductionValue: 0,
              subClassEmision: "",
              isCreateTask: false,
            });
            await getTasks();
          }
          toast.success(res.data.message);
          setTaskName("");
          setSelectedAccItem(null);
          setSelectedScope(null);
          setSelectedSubClass(null);
          setPriority("low");
          setDueDate("");
          setStatus("Todo");
          setDescription("");
          setImpact("Low");
          setCost("");
          setEffort("Low");
          setSelectedUser([]);
          setInvitedUser([]);
          if (!isFromSubScopeTarget) {
            setTaskInfo(null);
            setStep(0);
            await getTasks();
          }
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const MarkAsComplete = async (id, status, sourceStatus, index) => {
    await Axios({
      url: `/target/update-task-status/${id}/${targetData._id}`,
      method: "PUT",
      data: {
        status: type[status],
        index: index,
        sourceStatus: sourceStatus,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          toast.success("Task Marked As Completed");
          setTaskName("");
          setSelectedAccItem(null);
          setSelectedScope(null);
          setSelectedSubClass(null);
          setPriority("low");
          setDueDate("");
          setStatus("Todo");
          setDescription("");
          setImpact("Low");
          setCost("");
          setEffort("Low");
          setSelectedUser([]);
          setInvitedUser([]);
          setTaskInfo(null);
          setStep(0);
          await getTasks();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const deleteTask = async (id, status, index) => {
    await Axios({
      url: `/target/delete-task/${id}/${targetData._id}`,
      method: "delete",
      data: {
        status: status,
        index: index,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          setTaskName("");
          setSelectedAccItem(null);
          setSelectedScope(null);
          setSelectedSubClass(null);
          setPriority("low");
          setDueDate("");
          setStatus("Todo");
          setDescription("");
          setImpact("Low");
          setCost("");
          setEffort("Low");
          setSelectedUser([]);
          setInvitedUser([]);
          setTaskInfo(null);
          setStep(0);
          await getTasks();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (taskInfo && taskInfo !== null) {
      setAllDisable(true);
      handleChangeAccountingItem(taskInfo?.logId?._id);
      handleChangeScope(taskInfo?.subScope, taskInfo.logId._id);
      setTaskName(taskInfo?.taskName);
      setPriority(taskInfo.priority);
      setDueDate(new Date(taskInfo.due_date).toISOString().split("T")[0]);
      setStatus(taskInfo.status);
      setDescription(taskInfo.description);
      setImpact(taskInfo.impact);
      setCost(taskInfo.cost);
      setCostCurrency(taskInfo.costCurrency);
      setEffort(taskInfo.effort);
      setSelectedUser(taskInfo.assignee);
      setInvitedUser(taskInfo.invitedAssignee);
    }
    return () => {};
  }, [taskInfo]);
  useEffect(() => {
    if (
      taskInfo &&
      taskInfo !== null &&
      classesList.length > 0 &&
      taskInfo.subclass
    ) {
      const findSubClass = classesList.find((data) => {
        if (
          data.subscope === taskInfo.subclass.subscope &&
          data.subClass === taskInfo.subclass.subClass &&
          data.subClassHead === taskInfo.subclass.subClassHead
        ) {
          return data;
        }
      });
      setSelectedSubClass(findSubClass);
    }
  }, [taskInfo, classesList]);

  return (
    <React.Fragment>
      {!isFromSubScopeTarget && (
        <div
          className="tasks_drawer_header"
          onClick={() => setOpenMenuOption(false)}
        >
          <div className="d-flex justify-content-between align-items-center">
            {taskInfo ? (
              <div className="d-flex align-items-center gap_10">
                <div
                  className="w-30px h-30px border_radius_5 center bg_d9 pointer"
                  onClick={() => {
                    setTaskInfo(null);
                    setStep(0);
                  }}
                >
                  <LeftChevronIcon cc={"#fff"} />
                </div>
                <div
                  className="border_radius_5 h-40px center bg_soft_green align_center gap_10 px-10 pointer"
                  onClick={() => {
                    if (taskInfo.status !== type.complete) {
                      MarkAsComplete(
                        taskInfo._id,
                        "complete",
                        taskInfo.status,
                        0
                      );
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="none"
                    viewBox="0 0 18 18"
                  >
                    <path
                      stroke="#00915B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      d="M15 4.5l-8.25 8.25L3 9"
                    ></path>
                  </svg>
                  <p className="line_height_14 font_14_500 color_green">
                    {taskInfo.status === type.complete
                      ? "Completed"
                      : "Mark as Complete"}
                  </p>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center gap_10">
                <div className="w-40px h-40px bg_soft_green border_radius_5 center pointer position-relative">
                  <span>
                    <FormatListBulleted htmlColor="#00915B" />
                  </span>
                </div>
                <div className="container_br_5_p_10_8 h-40px center">
                  <p className="offsetProjectTitle line_height_24">New Task</p>
                </div>
              </div>
            )}
            <div className="align_center gap_16">
              {taskInfo && (
                <div
                  className="position-relative"
                  onClick={(e) => e.stopPropagation()}
                >
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setOpenMenuOption(!openMenuOption);
                    }}
                  >
                    <MoreHoriz />
                  </IconButton>
                  {openMenuOption && (
                    <div className="task_edit_menu_box">
                      <div
                        className="align_center gap_10 px-10 h-35px border-btm_f6 pointer"
                        onClick={() => {
                          setAllDisable(false);
                          setOpenMenuOption(false);
                        }}
                      >
                        <PencilSmallIcon cc={"#727E8F"} />{" "}
                        <p className="font_13_500 color_72">Edit</p>
                      </div>
                      <div
                        className="align_center gap_10 px-10 h-35px pointer"
                        onClick={() => {
                          deleteTask(
                            taskInfo._id,
                            taskInfo.status,
                            taskInfo.index
                          );
                        }}
                      >
                        <DeleteIcon cc={"#F4635A"} />{" "}
                        <p className="font_13_500 color_red">Delete</p>
                      </div>
                    </div>
                  )}
                </div>
              )}
              <Close
                htmlColor="#4C4C4C"
                className="pointer"
                onClick={() => {
                  setTaskInfo(null);
                  setStep(0);
                }}
              />
            </div>
          </div>
        </div>
      )}
      <div
        className="create_task_root"
        onClick={() => {
          setShowAssignMoToggle(false);
          setOpenMenuOption(false);
        }}
      >
        <input
          type="text"
          className="grey_input w-100 font_16_400"
          placeholder="Enter Task Name"
          value={taskName}
          onChange={(e) => setTaskName(e.target.value)}
          disabled={allDisable}
        />
        <div className="mt-30">
          <div className="align_center gap_24">
            <div className="" style={style_w_96}>
              <p className="font_12_600 color_57">Accounting item</p>
            </div>
            <div>
              <FormControl sx={{ height: "40px", width: 270 }} size="small">
                <Select
                  value={selectedAccItem}
                  onChange={(e) => handleChangeAccountingItem(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={sx}
                  IconComponent={KeyboardArrowDown}
                  disabled={
                    accountingItems.length === 0 || itemId || allDisable
                  }
                >
                  <MenuItem sx={MenuItemSx} value={null}>
                    Select Accounting Item
                  </MenuItem>
                  {accountingItems.length > 0 &&
                    accountingItems.map((item) => (
                      <MenuItem sx={MenuItemSx} key={item._id} value={item._id}>
                        <img
                          className="w-20px h-20px border_radius_5 mr-24"
                          src={getServiceIcon(getIconName(item))}
                          alt="accounting item "
                        />
                        {item.itemDetails.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="align_center gap_24 mt-20">
            <div className="" style={style_w_96}>
              <p className="font_12_600 color_57">Subscope</p>
            </div>
            <div>
              <FormControl sx={{ height: "40px", width: 270 }} size="small">
                <Select
                  value={selectedScope}
                  onChange={(e) =>
                    handleChangeScope(e.target.value, selectedAccItem)
                  }
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={sx}
                  IconComponent={KeyboardArrowDown}
                  disabled={
                    selectedAccItem === null ||
                    scopeList.length === 0 ||
                    isFromSubScopeTarget ||
                    allDisable
                  }
                >
                  <MenuItem value={null} sx={MenuItemSx}>
                    Select Subcope
                  </MenuItem>
                  {scopeList &&
                    scopeList.length > 0 &&
                    scopeList.map((scope, i) => (
                      <MenuItem value={scope} key={scope + i} sx={MenuItemSx}>
                        <img
                          className="w-20px h-20px border_radius_5 mr-24"
                          src={getServiceIcon(scope)}
                          alt="accounting item "
                        />
                        {scope}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="align_center gap_24 mt-20">
            <div className="" style={style_w_96}>
              <p className="font_12_600 color_57">Subclass</p>
            </div>
            <div>
              <FormControl sx={{ height: "40px", width: 270 }} size="small">
                <Select
                  value={selectedSubClass}
                  onChange={(e) => {
                    setSelectedSubClass(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={KeyboardArrowDown}
                  sx={sx}
                  disabled={
                    selectedScope === null ||
                    classesList.length === 0 ||
                    isFromSubScopeTarget ||
                    allDisable
                  }
                >
                  <MenuItem sx={MenuItemSx} value={null}>
                    Select Subclass
                  </MenuItem>
                  {classesList &&
                    classesList.length > 0 &&
                    classesList.map((subclassData, subclassIndex) => (
                      <MenuItem
                        sx={MenuItemSx}
                        key={subclassIndex}
                        value={subclassData}
                      >
                        <img
                          className="w-20px h-20px border_radius_5 mr-24"
                          src={getServiceIcon(subclassData.subClass)}
                          alt={subclassData.subClass}
                        />
                        {subclassData.subClass}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <hr className="mt-30 mb-24" />
        <div>
          <div className="align_center gap_24">
            <div className="" style={style_w_96}>
              <p className="font_12_600 color_57">Assignee</p>
            </div>
            <div
              className="position-relative d-flex"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div
                className="circle_30 center bg_f6 pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!allDisable) {
                    setShowAssignMoToggle(!showAssignMoToggle);
                  }
                }}
              >
                <UserAddIcon cc={"#7E8999"} />
              </div>
              <div className="ml-40">
                {selectedUsers.length > 0 && (
                  <AvatarGroup
                    sx={{
                      flexDirection: "row-reverse",
                      "&.MuiAvatarGroup-root .MuiAvatar-root ": {
                        // marginLeft: "-8px",
                        height: 30,
                        width: 30,
                        color: "#000",
                        fontSize: "13px",
                        fontWeight: 700,
                        lineHeight: "46px",
                        border: "1px solid #fff",
                        backgroundColor: "#e7e7e7",
                      },
                    }}
                    max={3}
                  >
                    {selectedUsers.length > 0 &&
                      selectedUsers.map((user) => (
                        <Avatar key={user._id} src={user.avatar} />
                      ))}
                  </AvatarGroup>
                )}
              </div>
              {showAssignMoToggle && (
                <AssigneeComp
                  handleRemoveInvitedUser={handleRemoveInvitedUser}
                  handleRemoveUser={handleRemoveUser}
                  invitedUsers={invitedUsers}
                  selectedUsers={selectedUsers}
                  allUsers={allUsers}
                  handleAssignUser={handleAssignUser}
                  setInvitedUser={setInvitedUser}
                />
              )}
            </div>
          </div>
          <div className="align_center gap_24 mt-20">
            <div className="" style={style_w_96}>
              <p className="font_12_600 color_57">Priority</p>
            </div>
            <div>
              <FormControl sx={{ height: "40px", width: 160 }} size="small">
                <Select
                  value={priority}
                  onChange={(e) => setPriority(e.target.value)}
                  IconComponent={KeyboardArrowDown}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={sx}
                  disabled={allDisable}
                >
                  <MenuItem sx={MenuItemSx} value={"low"}>
                    <TopChevronIcon />
                    <span style={{ marginLeft: 5 }}>Low Priority</span>
                  </MenuItem>
                  <MenuItem sx={MenuItemSx} value={"medium"}>
                    <TwoUpArrow />
                    <span style={{ marginLeft: 5 }}>Medium Priority</span>
                  </MenuItem>
                  <MenuItem sx={MenuItemSx} value={"high"}>
                    <ThreeUpArrow />
                    <span style={{ marginLeft: 5 }}>High Priority</span>
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="align_center gap_24 mt-20">
            <div className="" style={style_w_96}>
              <p className="font_12_600 color_57">Due Date</p>
            </div>
            <input
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              type="date"
              className="grey_input"
              style={{ width: 160 }}
              disabled={allDisable}
            />
          </div>
          <div className="align_center gap_24 mt-20">
            <div className="" style={style_w_96}>
              <p className="font_12_600 color_57">Status</p>
            </div>
            <div>
              <FormControl sx={{ height: "40px", width: 160 }} size="small">
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  IconComponent={KeyboardArrowDown}
                  sx={sx}
                  disabled={allDisable}
                >
                  <MenuItem sx={MenuItemSx} value={"Todo"}>
                    Todo
                  </MenuItem>
                  <MenuItem sx={MenuItemSx} value={"In Progress"}>
                    In Progress
                  </MenuItem>
                  <MenuItem sx={MenuItemSx} value={"Completed"}>
                    Completed
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        <hr className="mt-30 mb-30" />
        <div>
          <p className="font_12_600 color_57 mb-10">Description</p>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={description}
            onChange={(v) => {
              if (allDisable) {
                setDescription(v);
              }
            }}
            modules={modules}
            formats={formats}
            className="task_description"
            placeholder=""
            readOnly={allDisable}
          />
        </div>
        <hr className="mt-30 mb-30" />
        <div className="align_center gap_40">
          <div className="w-40px">
            <p className="font_12_600 color_57">Impact</p>
          </div>
          <div>
            <FormControl sx={{ height: "40px", width: 100 }} size="small">
              <Select
                value={impact}
                onChange={(e) => setImpact(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={KeyboardArrowDown}
                sx={sx}
                disabled={allDisable}
              >
                <MenuItem sx={MenuItemSx} value={"Low"}>
                  Low
                </MenuItem>
                <MenuItem sx={MenuItemSx} value={"Medium"}>
                  Medium
                </MenuItem>
                <MenuItem sx={MenuItemSx} value={"High"}>
                  High
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="align_center gap_40">
          <div className="w-40px">
            <p className="font_12_600 color_57">Cost</p>
          </div>
          <div className="align_center gap_10">
            <input
              value={cost}
              onChange={(e) => setCost(e.target.value)}
              type="number"
              className="grey_input font_12_500"
              style={{ width: 160, height: 40, backgroundColor: "#FCFCFC" }}
              disabled={allDisable}
            />
            <FormControl sx={{ height: "40px", width: 60 }} size="small">
              <Select
                value={costCurrency}
                onChange={(e) => setCostCurrency(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  backgroundColor: "#FCFCFC",
                  //   boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.03)",
                  border: "none",
                  "& .MuiInputBase-input": {
                    fontFamily: "Inter",
                    fontSize: "12px",
                    fontStyle: "normal",
                    fontWeight: "500",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                IconComponent={KeyboardArrowDown}
                disabled={allDisable}
              >
                <MenuItem sx={MenuItemSx} value={"€"}>
                  €
                </MenuItem>
                <MenuItem sx={MenuItemSx} value={"$"}>
                  $
                </MenuItem>
                <MenuItem sx={MenuItemSx} value={"£"}>
                  £
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="align_center gap_40">
          <div className="w-40px">
            <p className="font_12_600 color_57">Effort</p>
          </div>
          <div>
            <FormControl sx={{ height: "40px", width: 100 }} size="small">
              <Select
                value={effort}
                onChange={(e) => setEffort(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                IconComponent={KeyboardArrowDown}
                sx={sx}
                disabled={allDisable}
              >
                <MenuItem sx={MenuItemSx} value={"Low"}>
                  Low
                </MenuItem>
                <MenuItem sx={MenuItemSx} value={"Medium"}>
                  Medium
                </MenuItem>
                <MenuItem sx={MenuItemSx} value={"High"}>
                  High
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </div>

      <div
        className="quickLog-footer"
        onClick={() => setShowAssignMoToggle(false)}
      >
        <ButtonComp
          onClick={() => {
            if (isFromSubScopeTarget) {
              setStep((prev) => prev - 1);
            } else {
              setStep(0);
              setTaskInfo(null);
            }
          }}
          status={6}
          size="sm"
          style={{ height: 40 }}
          className="bg_d9"
        >
          <LeftChevronIcon className={"mr-10"} /> Go Back
        </ButtonComp>
        {isFromSubScopeTarget && !isOnlyTaskCreation && (
          <ButtonComp
            onClick={() => {
              createScopeTarget();
            }}
            status={5}
            size="sm"
            style={{ height: 40, padding: 0, width: "130px" }}
            className="center"
          >
            Skip Task
            <RightChevronIcon className={"ml-10"} />
          </ButtonComp>
        )}
        <ButtonComp
          onClick={() => {
            handleCreateTask();
          }}
          status={5}
          size="sm"
          style={{
            height: 40,
            padding: isFromSubScopeTarget && 0,
            width: isFromSubScopeTarget && "205px",
          }}
          disabled={taskInfo && allDisable}
        >
          {isFromSubScopeTarget && !isOnlyTaskCreation
            ? "Create Target & Task"
            : taskInfo
            ? "Edit Task"
            : "Create Task"}

          <RightChevronIcon className={"ml-10"} />
        </ButtonComp>
      </div>
    </React.Fragment>
  );
};

export default CreateTask;
