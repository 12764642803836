import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import {
  HeadCell,
  StyledHeadRow,
  //   StyledTableCell,
  //   StyledTableRow,
} from "../../../../Utils/TableComp";
// import DonwIntradayIcon from "../../../../Assets/icons/DownIntraday";
import SubScopeRow from "./SubScopeRow";
import { getServiceIcon } from "../../../../Utils/itemIcon";

const SubScopeTables = ({ data, totalEmission }) => {
  const [subscopeData, setSubscopeData] = useState([]);
  const [totalSubEmissions, setSubscopeEmission] = useState(0);
  const [columnWidth, setColumnWidth] = useState([
    null,
    null,
    null,
    null,
    null,
  ]);
  const column1 = useRef();
  const column2 = useRef();
  const column3 = useRef();
  const column4 = useRef();
  const column5 = useRef();

  const handleColumnWidthChange = () => {
    setColumnWidth([
      column1?.current.offsetWidth,
      column2?.current.offsetWidth,
      column3?.current.offsetWidth,
      column4?.current.offsetWidth,
      column5?.current?.offsetWidth,
    ]);
  };

  useEffect(() => {
    const groupedData = data?.years[0]?.cells.reduce((group, item) => {
      const { subscope, emission, particulars } = item;
      if (!group[subscope]) {
        group[subscope] = { subscope, emission: 0, data: [] };
      }
      group[subscope].emission += emission;
      group[subscope].data.push({ emission, particulars });
      return group;
    }, {});
    let result = [];
    result = groupedData ? Object.values(groupedData) : [];
    const subEmission = result.reduce((prev, val) => prev + val.emission, 0);
    setSubscopeEmission(subEmission);
    setSubscopeData(result);
    handleColumnWidthChange();
  }, [data]);

  useEffect(() => {
    handleColumnWidthChange();
  }, [
    column1.current,
    column2.current,
    column3.current,
    column4.current,
    column5.current,
  ]);

  useEffect(() => {
    window.addEventListener("resize", handleColumnWidthChange);
    return () => {
      window.removeEventListener("resize", handleColumnWidthChange);
    };
  });

  return (
    <div className="mb-40">
      <p className="font_17_700">
        <img
          src={getServiceIcon(data._id)}
          alt={data._id}
          height="20px"
          className="me-2 mb-1"
        />{" "}
        {data._id}
      </p>
      <div className="analytics_table_container">
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640 }}
          className="mt-24"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  sx={{ borderLeft: "0", width: "32%" }}
                  component="th"
                  className="text-uppercase"
                  ref={column1}
                >
                  Subscope Name
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  ref={column2}
                >
                  emissions
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  ref={column3}
                  sx={{ width: "25%" }}
                >
                  % of total emissions
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  ref={column4}
                  sx={{ borderRight: "0", width: "18%" }}
                >
                  % of Scope emissions
                </HeadCell>
                {/* <HeadCell
                  sx={{ borderRight: "0" }}
                  component="th"
                  className="text-uppercase"
                  ref={column5}
                >
                  Comparison (YOY)
                </HeadCell> */}
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {subscopeData && subscopeData.length > 0
                ? subscopeData.map((sc) => (
                    <SubScopeRow
                      sc={sc}
                      key={sc.subscope}
                      totalEmission={totalEmission}
                      totalSubEmissions={totalSubEmissions}
                      columnWidth={columnWidth}
                    />
                  ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default SubScopeTables;
