import React, { useEffect, useState } from "react";
import "./subClassDataFill.css";
import axios from "../../../../../../Api/index";
import { toast } from "react-hot-toast";
import { Drawer } from "@mui/material";
import subScopeCategory from "../../../../../../DataJSON/subScopes.json";
import location from "../../../../../../DataJSON/companyLocation.json";
import event from "../../../../../../DataJSON/event.json";
import hotel from "../../../../../../DataJSON/hotel.json";
import product from "../../../../../../DataJSON/product.json";
import service from "../../../../../../DataJSON/service.json";
import realEstateA from "../../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../../DataJSON/building.json";
import agency from "../../../../../../DataJSON/agency.json";
import education from "../../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../../DataJSON/shipping.json";
import skilledTrades from "../../../../../../DataJSON/skilledTradesSector.json";

import OverWriteEmissions from "./Components/OverWriteEmissions";
import { TextField, InputAdornment } from "@mui/material";
import { useJsApiLoader } from "@react-google-maps/api";
import GetPath from "./Components/Map/newMap";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";
// import EditIcon from "../../../../../../Assets/icons/EditIcon";
const libraries = ["places", "drawing", "geometry"];
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { IconButton } from "@mui/material";
import BackForwardButton from "../../../../../../Assets/components/BackForwardButton/BackForwardButton";
import MinusIconWithoutBox from "../../../../../../Assets/icons/MinusIconWithoutBox";
import PlusIcon from "../../../../../../Assets/icons/PlusIcon";
import ButtonComp from "../../../../../../Assets/components/Button";

function SubClassDataFill(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [step, setStep] = useState(0);
  const [directEntry, setDirectEntry] = useState(false);
  const [calcMethods, setCalcMethods] = useState([]);
  const [currData, setCurrData] = useState(["", "", "", "", "", "", "", ""]);
  const [selectValues, setSelectValues] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState([0, 0, 0, 0, 0, 0, 0]);
  const [selectLength, setSelectLength] = useState(5);
  const [selectionConfirm, setSelectionConfirm] = useState(false);
  // const [unitCount, setUnitCount] = useState(1);
  // const [amount, setAmount] = useState(1);
  // const [comment, setComment] = useState("");
  const [amounts, setAmounts] = useState([]);
  const [conversionFactor, setConversionFactor] = useState("");
  const [isOverWriteEmission, setIsOverWriteEmission] = useState(false);
  const [filterValues, setFilterValues] = useState([]);
  const [filterProp, setFilterProp] = useState([]);
  const [useMapCalculation, setUseMapCalculation] = useState(false);
  const [totalEmission, settotalEmission] = useState(0);
  const [totalAmount, settotalAmount] = useState(0);

  // const [isEditable, setisEditable] = useState(true);
  const [visibleFields, setVisibleFields] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);
  const labels = [
    "calculation method",
    "activity data",
    "type",
    "subtype",
    "unit",
    "input Method",
  ];
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "November",
    "October",
    "November",
    "December",
  ];
  // let directionsService;
  const data = {
    location,
    event,
    hotel,
    product,
    service,
    "real-estate agency": realEstateA,
    "real estate development": realEstateD,
    building,
    agency,
    "educational institution": education,
    shipping,
    "skilled trades company": skilledTrades,
  };

  const { isLoaded, mapLoadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP,
    libraries: libraries,
  });

  const handleConfirmSelection = () => {
    setSelectionConfirm(!selectionConfirm);
  };
  const handleSaveMatrixCell = () => {
    if (props.currRowMatrixCellCount > 0) setIsOverWriteEmission(true);
    // if (!(props.subClassData.isFullYear && props.currRowMatrixCellCount > 0)) {
    else {
      handleMatrixCellSubmit();
    }
  };
  const onFilterChange = (e) => {
    setFilterProp(e.target.value);
    let currFiltered = [];
    selectValues.forEach((currVal) => {
      if (currVal.name.toLowerCase().includes(e.target.value.toLowerCase())) {
        currFiltered.push(currVal);
      }
    });
    setFilterValues(currFiltered);
  };
  const onSelectionClick = (selectedValue) => {
    if (step === 6 && selectedValue.index === 1) {
      setUseMapCalculation(true);
    }
    if (step === 1 && selectedValue.index === -1) {
      let updatedData = [...currData];
      updatedData[5] = "tCO2e";
      updatedData[1] = selectedValue.name;
      setCurrData(updatedData);
      setSelectionConfirm(true);
      setDirectEntry(true);
      setStep(7);
      return;
    }
    let updatedData = [...currData];
    let updateIndex = [...selectedIndex];
    updateIndex[step] = selectedValue.index;
    updatedData[step] = selectedValue.name;
    if (step === 5) setConversionFactor(selectedValue.cf);
    setCurrData(updatedData);
    setSelectedIndex(updateIndex);
    setStep(step + 1);
    setFilterProp("");
  };

  useEffect(() => {
    if (props.subClassData && props.subClassData.scope == "Business Travel") {
      let currVisibleFields = [...visibleFields];
      currVisibleFields[visibleFields.length - 1] = true;
      setVisibleFields(currVisibleFields);
      setSelectLength(selectLength + 1);
    } else {
      setSelectLength(labels.length - 1);
      let currVisibleFields = [...visibleFields];
      currVisibleFields[visibleFields.length - 1] = false;
      setVisibleFields(currVisibleFields);
    }
  }, [props.subClassData]);

  useEffect(() => {
    if (!props.itemName) return;
    let currItemName =
      props.itemName.charAt(0).toUpperCase() + props.itemName.slice(1);
    let currItem = data[props.itemName.toLowerCase()][currItemName];
    let currSubClass = [];
    if (
      props.selectedClassScopeName &&
      props.selectedClassScopeName.class &&
      props.selectedClassScopeName.scope
    ) {
      currSubClass =
        currItem[props.subClassData.class]?.[props.subClassData.scope]?.classes;
    }

    currSubClass?.forEach((currClass) => {
      let keys = Object.keys(currClass);
      keys.forEach((currKey) => {
        if (currKey === props.subClassData.scopeClass) {
          currClass[currKey].forEach((subClass) => {
            if (subClass.Subclass === props.subClassData.subClass) {
              setCalcMethods(subClass.calculationMethods);
            }
          });
        }
      });
    });
  }, [props.subClassData]);

  useEffect(() => {
    if (calcMethods.length > 0) {
      let showInputs = [true, true, true, true, true, true, true];
      let currCal = calcMethods[0];

      let currActivities = Object.keys(currCal.Activities);
      if (currActivities[0] === "") showInputs[2] = false;
      let currType = Object.keys(currCal.Activities[currActivities[0]])[0];
      if (currType == "") showInputs[3] = false;
      let currSubtype = Object.keys(
        currCal.Activities[currActivities[0]][currType]
      );
      if (currSubtype[0] === "") showInputs[4] = false;
      if (
        currCal.Activities[currActivities[0]][currType][currSubtype[0]].unit ==
        ""
      )
        showInputs[5] = false;
      setVisibleFields(showInputs);
    }
  }, [calcMethods]);

  useEffect(() => {
    if (
      props.updateMonthEmissionData &&
      Object.keys(props.updateMonthEmissionData).length > 0
    ) {
      let allExistingAmounts = props.updateMonthEmissionData.amounts?.map(
        (amount) => {
          return { ...amount, isEditable: false };
        }
      );
      setAmounts(allExistingAmounts);
      let cellData = props.updateMonthEmissionData;
      setCurrData([
        cellData.name,
        cellData.calculationMethod,
        cellData.activity,
        cellData.type,
        cellData.subType,
        cellData.unit,
        cellData.inputMethod,
        "",
      ]);
      if (cellData.unit === "tCO2e") {
        setDirectEntry(true);
      }
      // setCurrData(props.updateMonthEmissionData.currData);
      setStep(labels.length);
      setConversionFactor(props.updateMonthEmissionData.conversionFactor);
      setSelectionConfirm(true);
      if (props.updateMonthEmissionData.cell.subScope === "Business Travel")
        setSelectLength(labels.length);

      // get conversion factor
      let currItemName =
        props.itemName.charAt(0).toUpperCase() + props.itemName.slice(1);
      let currItem = data[props.itemName.toLowerCase()][currItemName];
      let currSubClass = [];
      if (
        props.selectedClassScopeName.class &&
        props.selectedClassScopeName.scope
      ) {
        currSubClass =
          currItem[props.selectedClassScopeName.class][
            props.selectedClassScopeName.scope
          ].classes;
      }
      let currSelectedIndex = [...selectedIndex];
      currSubClass.forEach((currClass) => {
        let keys = Object.keys(currClass);
        keys.forEach((currKey) => {
          if (currKey === props.updateMonthEmissionData.cell.scopeClass) {
            currClass[currKey].forEach((subClass) => {
              if (
                subClass.Subclass === props.updateMonthEmissionData.subClass
              ) {
                setCalcMethods(subClass.calculationMethods);

                subClass.calculationMethods.forEach((calcMethod, calcIndex) => {
                  if (
                    calcMethod.calcMethodName ===
                    props.updateMonthEmissionData.calculationMethod
                  ) {
                    currSelectedIndex[1] = calcIndex;
                    currSelectedIndex[2] =
                      props.updateMonthEmissionData.activity;
                    currSelectedIndex[3] = props.updateMonthEmissionData.type;
                    currSelectedIndex[4] =
                      props.updateMonthEmissionData.subType;
                    calcMethod["Activities"][
                      props.updateMonthEmissionData.activity
                    ][props.updateMonthEmissionData.type][
                      props.updateMonthEmissionData.subType
                    ].forEach((currUnit, unitIndex) => {
                      if (
                        currUnit.unit === props.updateMonthEmissionData.unit
                      ) {
                        currSelectedIndex[5] = unitIndex;
                        setSelectedIndex(currSelectedIndex);
                        setConversionFactor(currUnit.kgCO2e);
                      }
                    });
                  }
                });
              }
            });
          }
        });
      });
    }
  }, [props.updateMonthEmissionData]);

  const CloseDrawer = () => {
    props.setToggleSubClassDataFill(false);
    setCurrData(["", "", "", "", "", "", "", ""]);
    setSelectValues([]);
    setSelectedIndex([0, 0, 0, 0, 0, 0]);
    setStep(0);
    setSelectionConfirm(false);
    setSelectLength(5);
    setIsOverWriteEmission(false);
    setDirectEntry(false);
    setAmounts([{ amount: 1, comment: "", noOfUnit: 1, isEditable: true }]);
  };
  const handleGetBackToSubClassSelection = () => {
    setCurrData(["", "", "", "", "", "", "", ""]);
    setSelectValues([]);
    setSelectedIndex([0, 0, 0, 0, 0, 0]);
    setStep(0);
    setSelectionConfirm(false);
    setSelectLength(5);
    setDirectEntry(false);
    props.updateSubClassSelection({
      isFullYear: props.subClassData.isFullYear,
      scopeClass: props.subClassData.scopeClass,
      subClass: props.subClassData.subClass,
    });
  };
  const saveFirstStep = (event) => {
    let updateData = [...currData];
    updateData[0] = event.target.value;
    setCurrData(updateData);
  };

  const handleNameSave = (event) => {
    event.preventDefault();
    if (currData[0].trim().length === 0) return;
    setStep(step + 1);
  };
  const handleCancelChange = () => {
    if (
      props.updateMonthEmissionData &&
      Object.keys(props.updateMonthEmissionData).length > 0
    ) {
      props.setToggleSubClassDataFill(false);
    }
    if (directEntry) {
      let updatedData = [...currData];
      updatedData[5] = "";
      setCurrData(updatedData);
      setDirectEntry(false);
      setStep(1);
    }

    setAmounts([{ amount: 1, comment: "", noOfUnit: 1, isEditable: true }]);
    // setComment("");
    // setAmount(1);
    // setUnitCount(1);
    setSelectionConfirm(false);
  };
  useEffect(() => {
    setFilterValues(selectValues);
  }, [selectValues]);

  useEffect(() => {
    if (step <= 6) {
      setUseMapCalculation(false);
    }
    if (step == 1) {
      let currSelection = [];
      currSelection.push({ name: "Direct tCO2e Entry", index: -1 });
      calcMethods.forEach((currCal, index) => {
        currSelection.push({ name: currCal["calcMethodName"], index: index });
      });
      setSelectValues(currSelection);
    } else if (step == 2) {
      let currSelection = [];
      let currKeys = Object.keys(
        calcMethods[selectedIndex[step - 1]].Activities
      );
      let emptyCount = 0;
      currKeys.forEach((key) => {
        if (key === "") emptyCount++;
        currSelection.push({ name: key, index: key });
      });
      if (emptyCount === currKeys.length) {
        let updateIndex = [...selectedIndex];
        updateIndex[step] = "";
        let currVisibleFields = [...visibleFields];
        currVisibleFields[step] = false;
        setVisibleFields(currVisibleFields);
        setSelectedIndex(updateIndex);
        setStep(step + 1);
      }
      setSelectValues(currSelection);
    } else if (step == 3) {
      let currSelection = [];
      let currSubType =
        calcMethods[selectedIndex[step - 2]]["Activities"][
          selectedIndex[step - 1]
        ];
      let currKeys = Object.keys(currSubType);
      let emptyCount = 0;

      currKeys.forEach((key) => {
        if (key === "") emptyCount++;
        currSelection.push({ name: key, index: key });
      });

      if (emptyCount === currKeys.length) {
        let updateIndex = [...selectedIndex];
        updateIndex[step] = "";
        let currVisibleFields = [...visibleFields];
        currVisibleFields[step] = false;
        setVisibleFields(currVisibleFields);
        setSelectedIndex(updateIndex);
        setStep(step + 1);
      }
      setSelectValues(currSelection);
    } else if (step == 4) {
      let currSelection = [];
      let currWay =
        calcMethods[selectedIndex[step - 3]]["Activities"][
          selectedIndex[step - 2]
        ][selectedIndex[step - 1]];

      let currKeys = Object.keys(currWay);
      let emptyCount = 0;
      currKeys.forEach((key) => {
        if (key === "") emptyCount++;
        currSelection.push({ name: key, index: key });
      });

      if (emptyCount === currKeys.length) {
        let updateIndex = [...selectedIndex];
        updateIndex[step] = "";
        setSelectedIndex(updateIndex);
        let currVisibleFields = [...visibleFields];
        currVisibleFields[step] = false;
        setVisibleFields(currVisibleFields);
        setStep(step + 1);
      }
      setSelectValues(currSelection);
    } else if (step == 5) {
      let currSelection = [];
      let currWay =
        calcMethods[selectedIndex[step - 4]]["Activities"][
          selectedIndex[step - 3]
        ][selectedIndex[step - 2]][selectedIndex[step - 1]];
      currWay.forEach((currUnit, currIndex) => {
        currSelection.push({
          name: currUnit.unit,
          index: currIndex,
          cf: currUnit.kgCO2e,
        });
      });

      setSelectValues(currSelection);
    } else if (step == 6) {
      if (step - 1 == selectLength) {
        setSelectValues([]);
      } else {
        if (currData[step - 1] === "EUR") {
          let currSelection = [
            { name: `Enter ${currData[step - 1]} Amount`, index: 0 },
          ];
          setSelectValues(currSelection);
        } else {
          let currSelection = [
            { name: `Enter ${currData[step - 1]} Amount`, index: 0 },
            { name: `Calculate ${currData[step - 1]} Amount`, index: 1 },
          ];
          setSelectValues(currSelection);
        }
      }
    } else if (step == 7) {
      setSelectValues([]);
    }
  }, [step, calcMethods]);

  const handleOneStepBack = () => {
    if (step <= 6) setUseMapCalculation(false);
    let updateData = [...currData];
    updateData[step] = "";
    setCurrData(updateData);
    let currStep = step - 1;
    while (currStep > 0 && !visibleFields[currStep]) currStep--;
    if (currStep < 0) currStep = 0;
    setStep(currStep);
  };

  const handleMatrixCellSubmit = async () => {
    try {
      // if (directEntry) {
      //   setAmount(0);
      // }
      await axios
        .post("/cell/create-cell", {
          matrix_id: props.selectedCell ? props.selectedCell : "",
          logId: props.subClassData.logId
            ? props.subClassData.logId
            : props.updateMonthEmissionData.cell.logId,
          itemId: props.itemId,
          GHGscope: "",
          isFullYear: props.subClassData.isFullYear,
          scopeClass: props.subClassData.class,
          subScope: props.subClassData.scope,
          year: props.subClassData.year
            ? Number(props.subClassData.year)
            : props.updateMonthEmissionData.cell.year,
          month: props.subClassData.month
            ? Number(props.subClassData.month)
            : props.updateMonthEmissionData.cell.month,
          scopeIndex: props.subClassData.scopeIndex,
          particulars: {
            id: props.updateMonthEmissionData
              ? props.updateMonthEmissionData._id
              : null,
            name: currData[0],
            activity: currData[2],
            calculationMethod: currData[1],
            type: currData[3],
            subType: currData[4],
            inputMethod: currData[6],
            subClassHead: props.subClassData.scopeClass,
            subClass: props.subClassData.subClass,
            conversionFactor: conversionFactor,
            unit: currData[5],
            amounts,
          },
        })
        .then((res) => {
          props.getMatrixCellData();
          props.handleOpenSubClassDataFill({});
          setCurrData(["", "", "", "", "", "", "", ""]);
          setSelectValues([]);
          setSelectedIndex([0, 0, 0, 0, 0, 0]);
          setAmounts([{ amount: 1, comment: "", noOfUnit: 1 }]);
          setStep(0);
          setSelectionConfirm(false);
          setSelectLength(5);
          setCalcMethods([]);
          // setUnitCount(1);
          // setAmount(1);
          setConversionFactor("");
          setDirectEntry(false);
          setIsOverWriteEmission(false);
          setFilterValues([]);
          setUseMapCalculation(false);
          props.handleOpenSubClassDataFill({});
          if (res.data.success) toast.success("Log created");
          props.setIsCrateCellUpdate(!props.isCrateCellUpdate);
          props.handleToggleSubClassDataFill(false);
        });
    } catch (err) {
      toast.error(err.message);
    }
  };
  const addAmountObj = () => {
    let emission = directEntry
      ? 1
      : conversionFactor
      ? Number(Number(conversionFactor) / 1000).toFixed(2)
      : 0;

    let newAmount = {
      amount: 1,
      comment: "",
      noOfUnit: 1,
      emission: Number(emission),
      isEditable: true,
    };
    setAmounts([...amounts, newAmount]);
  };

  const handleDeleteAccount = (e, index) => {
    if (index >= 0 && index < amounts.length) {
      amounts.splice(index, 1);
    }
    handleClose(e);
  };

  const handleDublicate = (e, i) => {
    let newObj = amounts[i];
    let amountAr = amounts;
    // newObj.isEditable = true;
    amountAr.push(newObj);
    amountAr[amountAr.length - 1].isEditable = true;
    setAmounts(amountAr);
    handleClose(e);
  };

  const handleAmountChange = (e, i) => {
    // alert(i);
    let newArr = amounts.map((obj, index) => {
      if (i === index) {
        return {
          ...obj,
          amount: e.target ? e.target.value : e,
          emission: directEntry
            ? Number((e.target ? e.target.value : e) * obj.noOfUnit)
            : conversionFactor
            ? Number(
                (
                  ((e.target ? e.target.value : e) *
                    obj.noOfUnit *
                    Number(conversionFactor)) /
                  1000
                ).toFixed(2)
              )
            : 0,
        };
      }
      return obj;
    });
    setAmounts(newArr);
  };

  const handleCount = (e, i) => {
    let newArr = amounts.map((obj, index) => {
      if (i === index) {
        let unit = e ? obj.noOfUnit + 1 : obj.noOfUnit - 1;
        return {
          ...obj,
          noOfUnit: unit,
          emission: directEntry
            ? Number(obj.amount * unit)
            : conversionFactor
            ? Number(
                ((obj.amount * unit * Number(conversionFactor)) / 1000).toFixed(
                  2
                )
              )
            : 0,
        };
      }
      return obj;
    });
    setAmounts(newArr);
  };

  const handleSetComment = (e, index) => {
    // let allAmounts = amounts;
    // allAmounts[i].comment = e.target.value;
    // setAmounts(allAmounts);
    // console.log(i, e.target.value);
    // setAmounts((prevData) => {
    //   const newData = [...prevData];
    //   newData[i].comment = e.target.value;
    //   return newData;
    // });

    setAmounts((prevAmounts) => {
      const updatedAmounts = prevAmounts.map((item, i) => {
        if (i === index) {
          return { ...item, comment: e.target.value };
        }
        return item;
      });
      return updatedAmounts;
    });
  };

  useEffect(() => {
    if (
      !props.updateMonthEmissionData ||
      Object.keys(props.updateMonthEmissionData).length === 0
    ) {
      let emission = directEntry
        ? 1
        : conversionFactor
        ? Number((1 * Number(conversionFactor)) / 1000).toFixed(2)
        : 0;
      setAmounts([
        {
          amount: 1,
          comment: "",
          noOfUnit: 1,
          emission: Number(emission),
          isEditable: true,
        },
      ]);
    } else {
      let arr = props.updateMonthEmissionData.amounts;
      let emission = 0;
      let amount = 0;
      for (let i = 0; i < arr.length; i++) {
        emission += arr[i].emission;
        amount += arr[i].amount;
      }
      settotalAmount(amount);
      settotalEmission(emission ? emission.toFixed(2) : 0);
    }
  }, [
    conversionFactor,
    props.toggleSubClassDataFill,
    props.updateMonthEmissionData,
  ]);

  return (
    <Drawer
      anchor={"right"}
      open={props.toggleSubClassDataFill}
      onClose={() => {
        CloseDrawer();
      }}
      PaperProps={{
        sx: { width: "70%" },
      }}
    >
      <div className="drawer_root">
        {selectionConfirm &&
        isOverWriteEmission &&
        props.currRowMatrixCellCount > 0 ? (
          <OverWriteEmissions
            CloseDrawer={CloseDrawer}
            handleMatrixCellSubmit={handleMatrixCellSubmit}
          />
        ) : (
          <>
            <div className="productDetail_drawerHead pb-40">
              <div className="align_text_right pr-20 mt-10">
                <i
                  className="bi bi-x-lg SideDrawer_close_icon pointer"
                  onClick={() => CloseDrawer()}
                ></i>
              </div>
              <div className="pl-35">
                <p className="faint_text_2">
                  {props.ScopeInfo &&
                    props.ScopeInfo.scope &&
                    subScopeCategory[props.ScopeInfo.scope.toLowerCase()] &&
                    "Scope " +
                      subScopeCategory[props.ScopeInfo.scope.toLowerCase()]}
                </p>
                <div className="d-flex justify-items-center">
                  <p className="f-27 fw-700 align-items-center">
                    {props.subClassData && props.subClassData.scope
                      ? props.subClassData.scope
                      : props.updateMonthEmissionData?.cell?.subScope}
                  </p>
                  <div className="d-flex align-items-center ml-8">
                    {props.isMandatory ? (
                      <span className="orange_label">Mandatory</span>
                    ) : (
                      <span className="green_label">Recommended</span>
                    )}
                  </div>
                </div>
                <p
                  className="subheading_light_2 mt-10"
                  style={{ maxWidth: "75%" }}
                >
                  {props.subClassData.description
                    ? props.subClassData.description
                    : props.ScopeInfo &&
                      props.ScopeInfo.scope &&
                      data[props.itemName.toLowerCase()] &&
                      data[props.itemName.toLowerCase()][props.itemName] && [
                        props.itemName,
                      ] &&
                      data[props.itemName.toLowerCase()][props.itemName][
                        props.ScopeInfo.class
                      ][props.ScopeInfo.scope].description}
                </p>
              </div>
            </div>
            <div className="p-32">
              <div className="main_box">
                {!selectionConfirm ? (
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        {step == 0 ? (
                          <p className="subheading_3 text_capital">
                            Please Name Your Log Item
                          </p>
                        ) : (
                          <p className=" subheading_3 text_capital">
                            YOUR SELECTION
                          </p>
                        )}
                      </div>

                      {/* <div>
                        <ButtonComp status={3} className="text-black">
                          Missing Data
                        </ButtonComp>
                      </div> */}
                    </div>
                    <div className="card_design_1 mt-12">
                      <div className="d-flex justify-content-between">
                        <div
                          className={`d-flex p-32   ${
                            step === 0
                              ? "flex-column align-items-start"
                              : "align-items-center"
                          }`}
                        >
                          <div>
                            {step > 0 ? (
                              <p className="neutral_label">
                                {props.subClassData.isFullYear
                                  ? `full year ${props.subClassData.year}`
                                  : monthNames[props.subClassData.month - 1]}
                              </p>
                            ) : (
                              <p className="subheading_3 text_capital">
                                Log Item Name
                              </p>
                            )}
                          </div>
                          <div className="">
                            {step > 0 ? (
                              <p className="f-16 fw-700 font-inter ml-10 ">
                                {currData[0]}
                              </p>
                            ) : (
                              <div className="mt-10">
                                <input
                                  // style={{
                                  //   background: `url(${getServiceIcon(
                                  //     props.subClassData.subClass
                                  //   )}) no-repeat left center`,
                                  //   backgroundSize: `${
                                  //     currData[0].length == 0 && "15px"
                                  //   }`,
                                  //   backgroundPosition: `${
                                  //     currData[0].length == 0 && "10px 50%"
                                  //   }`,
                                  // }}
                                  value={currData[0]}
                                  onChange={(event) => saveFirstStep(event)}
                                  type="text"
                                  className="form-control shadow-none subClassDataFill_input_text"
                                  id="service_name"
                                  placeholder={`${props.subClassData.subClass} - ${props.subClassData.scope}`}
                                  autoComplete="off"
                                />
                                <p className="overline_text mt-10">
                                  This name will be used to refer to this log
                                  item in the future
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                        {step != 0 ? (
                          <div className="d-flex align-items-center">
                            <div>
                              <button
                                onClick={() => {
                                  handleOneStepBack();
                                }}
                                className="button_style_none"
                              >
                                <i className="pointer bi bi-chevron-left neutral_rec_label border_radius_5  py-6 px-8"></i>
                              </button>
                            </div>

                            <div className="ml-20 mr-20">
                              {step - 1 === selectLength &&
                              currData[step - 1].length > 0 ? (
                                <ButtonComp
                                  status={1}
                                  onClick={() => {
                                    handleConfirmSelection();
                                  }}
                                  className="blue_active_button"
                                >
                                  Confirm Selection
                                </ButtonComp>
                              ) : (
                                <ButtonComp
                                  status={1}
                                  disabled
                                  className="grey_inactive_button color_white"
                                >
                                  Confirm Selection
                                </ButtonComp>
                              )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={` d-flex flex-wrap  mb-0 border-top_ef ${
                          step === 0 ? "justify-content-end p-16" : " p-32"
                        }`}
                      >
                        {step === 0 ? (
                          <div className="d-flex">
                            <BackForwardButton
                              className={"subclass_data_back"}
                              doNotShowForward={true}
                              handleGoBack={handleGetBackToSubClassSelection}
                              backBtnStyle={{ height: 36, width: 36 }}
                            />
                            {/* <button
                              onClick={() => {
                                handleGetBackToSubClassSelection();
                              }}
                              className="button_style_none"
                            >
                              <i className="pointer bi bi-chevron-left neutral_rec_label border_radius_5  py-6 px-8"></i>
                            </button> */}
                            <div className="mr-10 ml-10">
                              <ButtonComp
                                status={1}
                                onClick={(event) => handleNameSave(event)}
                                disabled={currData[0].length === 0}
                              >
                                Save Name
                              </ButtonComp>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {step > 0 &&
                          currData &&
                          labels.map((currLabel, currIndex) => {
                            return currIndex < selectLength ? (
                              <>
                                {currData[currIndex + 1].length > 0 ? (
                                  <div
                                    className={`ml-32 mt-10 ${
                                      currIndex + 1 === step
                                        ? "border_dashed"
                                        : ""
                                    }`}
                                    key={currIndex}
                                  >
                                    <p className="neutral_rec_label p-16 subheading_5 color_black">
                                      {currData[currIndex + 1]}
                                    </p>
                                  </div>
                                ) : visibleFields[currIndex + 1] ? (
                                  <div
                                    className={`ml-32 mt-10 ${
                                      currIndex + 1 === step
                                        ? "border_dashed"
                                        : ""
                                    }`}
                                    key={currIndex}
                                  >
                                    <p className="neutral_rec_label p-16 subheading_5 color_light_2">
                                      {currLabel}
                                    </p>{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              ""
                            );
                          })}
                      </div>
                    </div>

                    <div className="mt-50">
                      {step === 6 && selectLength != 6 ? (
                        ""
                      ) : (
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <p className="subheading_3 text_capital">
                              {step === 7 || step == 0
                                ? ""
                                : `${step + 1}. Please select`}{" "}
                              {step == 0 ? "" : labels[step - 1]}
                            </p>
                          </div>
                          {step > 0 ? (
                            <div>
                              <TextField
                                value={filterProp}
                                onChange={(e) => onFilterChange(e)}
                                className="body_text_l"
                                id="input-with-icon-textfield"
                                placeholder={`Search for ${
                                  step == 0 ? "" : labels[step - 1]
                                }`}
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="right">
                                      <i className="bi bi-search"></i>
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  "& .MuiInputBase-root": {
                                    height: 40,
                                    width: 250,
                                  },
                                }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}
                      {step > 0 ? (
                        <div className="d-flex mt-20 mb-32 gap_16 flex-wrap">
                          {filterValues &&
                            filterValues.map((currValue, index) => {
                              return (
                                <button
                                  key={index}
                                  onClick={() => {
                                    onSelectionClick(currValue);
                                  }}
                                  className="button_style_none mt-10"
                                >
                                  <p className="neutral_rec_label p-16 subheading_5 color_black pointer">
                                    {currValue.name}
                                  </p>
                                </button>
                              );
                            })}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="">
                    <div className="row header_card_blue p-24">
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                          {/* <span className="offsetProjectTitle color_white"> */}
                          <img
                            src={getServiceIcon(
                              props.subClassData.subClass
                                ? props.subClassData.subClass
                                : props.updateMonthEmissionData?.subClass
                            )}
                            height="24px"
                          />
                          <p className="color_white font_24_600 ml-8">
                            {currData[0]}
                          </p>
                          {/* </span> */}
                        </div>
                        <div>
                          <span className="neutral_label f-14 fw-400 ml-20">
                            {props.subClassData.isFullYear
                              ? `full year ${props.subClassData.year}`
                              : monthNames[
                                  props.subClassData.month
                                    ? props.subClassData.month - 1
                                    : props.updateMonthEmissionData.cell.month -
                                      1
                                ]}
                          </span>
                          <span className="neutral_label f-14 fw-400 ml-20">
                            Log
                          </span>
                        </div>

                        {/* <div
                      className="pointer"
                      onClick={() => handleCancelChange()}
                    >
                      <span className="neutral_label">
                        <EditIcon />
                      </span>
                    </div> */}
                      </div>
                      <div className="row mt-32 align-items-center">
                        <div className="col-4">
                          <div>
                            <p>
                              <span className="font_30_700">
                                {Number(totalEmission)
                                  .toFixed(2)
                                  .toLocaleString()}
                              </span>
                              <span className="body_text_sm ml-8">tCO₂e</span>
                            </p>
                            <p className="overline_text color_white">
                              Total Emission
                            </p>
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <p>
                              <span className="font_30_700">
                                {totalAmount?.toFixed(2).toLocaleString()}
                              </span>
                              <span className="body_text_sm ml-8">
                                {currData[5]}
                              </span>
                            </p>
                            <p className="overline_text color_white">
                              Total Amount
                            </p>
                          </div>
                        </div>
                        <div className="col-4">
                          <div>
                            <div className="d-flex align-items-center flex-wrap">
                              {currData.map((eachData, index) => {
                                if (eachData.length > 0)
                                  return index != 0 ? (
                                    <p className="font_13_600 color_white mb-6">
                                      - {eachData}
                                    </p>
                                  ) : (
                                    <p className="font_13_600 color_white mb-6">
                                      {eachData}
                                    </p>
                                  );
                                return "";
                              })}
                            </div>

                            <p className="overline_text color_white">
                              CO₂e Calculation
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row mt-32 mb-2">
                      <div className="col-md-4 subheading_1 ">Amount</div>
                      <div className="col-md-2 subheading_1 ">Emission</div>
                      <div className="col-md-4 subheading_1 ">Comment</div>
                      <div className="col-md-1 subheading_1 ">Created</div>
                      <div className="col-md-1 subheading_1 "></div>
                    </div>
                    {amounts &&
                      amounts.length > 0 &&
                      amounts.map((data, i) => (
                        <div className="card_design_2 mb-20" key={i}>
                          <div className="pt-16 row mx-0 mb-20">
                            <div className="col-md-4 align-items-start row mx-0">
                              {data.isEditable ? (
                                <div className="neutral_label d-flex align-items-center border_radius_8 subheading_2 py-2 px-2 col-5 justify-content-between">
                                  <span
                                    onClick={() => {
                                      handleCount(false, i);
                                    }}
                                    className="pointer"
                                  >
                                    <MinusIconWithoutBox />
                                  </span>
                                  <span className="body_text_l p-6 ml-12 mr-12">
                                    {data.noOfUnit}
                                  </span>
                                  <span
                                    onClick={() => {
                                      handleCount(true, i);
                                    }}
                                    className="pointer"
                                  >
                                    <PlusIcon />
                                  </span>
                                </div>
                              ) : (
                                <p className="neutral_label border_radius_8 subheading_2 py-12 px-2 col-4 ps-0 pe-2">
                                  {data.noOfUnit}
                                </p>
                              )}
                              <div className="border_radius_8 p-0 col-6 ps-2">
                                {data.isEditable ? (
                                  <div className="d-flex align-items-center border_light neutral_label border_radius_8">
                                    <input
                                      type="number"
                                      value={data.amount}
                                      onChange={(e) => {
                                        handleAmountChange(e, i);
                                      }}
                                      className="form-control shadow-none subClassDataFill_num_border subheading_2"
                                      id="service_name"
                                    />

                                    <span className="subheading_2 ms-auto me-2">
                                      {currData[5]?.substring(0, 3) +
                                        (currData[5] && currData[5].length > 3
                                          ? ".."
                                          : "")}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="d-flex align-items-center py-12 px-3 neutral_label border_radius_8">
                                    <p className="subheading_2 ">
                                      {data.amount?.toFixed(2).toLocaleString()}
                                    </p>
                                    <span className="subheading_2 ms-auto me-2">
                                      {(currData[5] && currData[5].length > 5
                                        ? currData[5].substring(0, 3)
                                        : currData[5]) +
                                        (currData[5] && currData[5].length > 5
                                          ? ".."
                                          : "")}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="col-md-2 d-flex align-items-start">
                              <p className="subheading_2 mt-10">
                                {directEntry
                                  ? Number(
                                      (data.noOfUnit * data.amount).toFixed(2)
                                    ).toLocaleString()
                                  : data.emission
                                  ? Number(
                                      data.emission.toFixed(2)
                                    ).toLocaleString()
                                  : 0.0}{" "}
                                {/* // : // data.amount && data.noOfUnit &&
                            conversionFactor // ? ( // (Number(data.amount *
                            data.noOfUnit) * // Number(conversionFactor)) / //
                            1000 // ).toFixed(2) */}
                                tCO2e
                              </p>
                            </div>

                            <div className="col-md-4">
                              {data.isEditable ? (
                                <>
                                  <textarea
                                    value={data.comment}
                                    onChange={(e) => {
                                      handleSetComment(e, i);
                                    }}
                                    className="subheading_2 border_1_ef p-2 border_radius_5"
                                    type="textarea"
                                    rows="5"
                                    cols="30"
                                    placeholder="type here"
                                  />
                                  <p className="overline_text color_light_1 mt-20">
                                    {data.comment ? data.comment.length : 0}/260
                                  </p>
                                </>
                              ) : (
                                <p className="neutral_label border_radius_8 subheading_2 py-12 px-2 w-100 text-left">
                                  {data.comment && data.comment.length > 0
                                    ? data.comment
                                    : "NA"}
                                </p>
                              )}
                            </div>

                            <div className="col-md-1 ">
                              <p className="subheading_2 ml-10 mt-10">today</p>
                            </div>
                            <div className="col-md-1 ">
                              <IconButton
                                onClick={handleClick}
                                size="small"
                                sx={{ ml: 2 }}
                                aria-controls={
                                  open ? "account-menu" : undefined
                                }
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                              >
                                <i className="bi bi-three-dots-vertical"></i>
                              </IconButton>
                              <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                  "aria-labelledby": "basic-button",
                                }}
                              >
                                <MenuItem
                                  className="subclass_Data_menu_pop color_red"
                                  onClick={(e) => handleDeleteAccount(e, i)}
                                >
                                  delete
                                </MenuItem>
                                <MenuItem
                                  onClick={(e) => handleDublicate(e, i)}
                                >
                                  duplicate
                                </MenuItem>
                              </Menu>
                            </div>
                          </div>
                          {useMapCalculation ? (
                            <div>
                              {mapLoadError
                                ? "Cannot load map at this moment "
                                : ""}
                              {isLoaded ? (
                                <>
                                  <GetPath
                                    unit={currData[5]}
                                    setAmounts={setAmounts}
                                    handleAmountChange={handleAmountChange}
                                    index={i}
                                  />
                                </>
                              ) : (
                                "Loading"
                              )}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))}

                    <div className="mt-20">
                      <span
                        className="subClassDataFill_circular_background pointer"
                        onClick={() => addAmountObj()}
                      >
                        <i className="bi bi-plus-lg f-20"></i>
                      </span>
                    </div>

                    <div className="mt-10 d-flex justify-content-end">
                      <div className="text-black px_30 py_10">
                        <ButtonComp
                          onClick={() => {
                            //   setStep(step - 1);
                            //setSelectionConfirm(false);
                            handleCancelChange();
                          }}
                          status={3}
                        >
                          Cancel
                        </ButtonComp>
                      </div>
                      {/* {
                  data.amount >= 1 &&
                  data.unitCount > 0 &&
                  data.comment.length > 0 ? (
                    <button
                      onClick={() => handleSaveMatrixCell()}
                      className="grey_inactive_button background_color_blue  px_60 py_10 ml-20"
                    >
                      Save
                    </button>
                  ) : ( */}
                      <div className=" py_10 ml-20">
                        <ButtonComp
                          status={1}
                          onClick={() => handleSaveMatrixCell()}
                          // className="grey_inactive_button background_color_blue "
                        >
                          Save
                        </ButtonComp>
                      </div>
                      {/* )} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Drawer>
  );
}

export default SubClassDataFill;
