import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import axios from "../../../Api";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { useAuth } from "../../../Utils/Auth";
import { getServiceIcon } from "../../../Utils/itemIcon";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import nextYearDate from "../../../Utils/nextYearDate";
import isNotAllowedCancel from "./checkAllowCancel";
import ButtonComp from "../../../Assets/components/Button";

import KeyboardForwardIcon from "../../../Assets/icons/KeyboardForwardIcon";

import { botAvatarGroup } from "../../../Utils/botAvatarsGroup";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "white",
  border: "1px solid #EFEFEF",
  boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.0588235)",
  borderRadius: "8px",
};

const Subscriptions = () => {
  const {t} = useTranslation()
  const { user, setSessionExpire, getUser, signOutBasic, profile } = useAuth();
  const [reason, setreason] = useState("");
  const [description, setdescription] = useState("");
  const [subscriptionsArr, setSubscriptionsArr] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [open, setOpen] = React.useState(false);
  const [b_open, setb_open] = React.useState(false);
  const [base_step, setbase_step] = useState(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleb_Open = () => setb_open(true);
  const handleb_Close = () => setb_open(false);
  const [selectedSub, setselectedSub] = useState(null);
  const [saveData, setsaveData] = useState(true);
  const history = useNavigate();

  const getSubscription = async () => {
    await axios({
      url: `/cart/company-subscriptions`,
      method: "get",
    })
      .then((res) => {
        // console.log(res.data.data);
        setSubscriptionsArr(res.data.data?.items);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      subscriptionsArr.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [page, rowsPerPage, subscriptionsArr]
  );

  useEffect(() => {
    getSubscription();
  }, []);

  function getLastDateOfMonth(date) {
    const newdate = new Date(date.year, date.month, 1); // month start from 0, so 0. Jan, 11: dec, 12: jan..
    newdate.setDate(0); // it sets the date , if it is 0 then get just before the date like if newdate is 01/01/2024 then setDate(0) -> 31/12/2023 , same as if newdate is 02/01/2024 then after setDate(0) -> 01/01/2024
    return `${newdate.getDate()}/${date.month}/${date.year}`;
  }

  const checkItemIsCarbonNeutral = (date) => {
    const today = new Date();
    const itemDate = new Date(date);
    return today < itemDate;
  };

  const [cancellationStep, setCancellationStep] = useState(0);

  const handleCancel = async (creditId, log) => {
    try {
      const res = await axios({
        method: "post",
        url: "/credit/cancel-credit",
        data: { creditId, reason, description },
      });
      if (res.data.success) {
        toast.success(`Your ${log} credit subscription has been cancelled`);
        handleClose();
        setCancellationStep(0);
        setdescription("");
        setreason("");
        getSubscription();
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const handleBaseSubcription = async (isCancel) => {
    try {
      const res = await axios({
        method: "post",
        url: "/company/handle-cancel",
        data: { isCancel, reason, description },
      });
      if (res.data.success) {
        toast.success(
          `Your All-In-One Suite subscription has been ${
            isCancel ? "cancelled" : "paused"
          }`
        );
        if (isCancel) {
          signOutBasic();
          history("/signup");
        } else {
          getUser();
          history("/dashboard?tab=3");
        }
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  return (
    <div className="user_box mt-56">
      <div className="border-btm_ef pb-24">
        <p className="offsetProjectTitle">{t("settings.manageSubscription")}</p>
      </div>
      <div>
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640, borderRadius: "9px", boxShadow: "none" }}
          className="mr-50 mt-20"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  component="th"
                  className="text-uppercase border_left_0"
                  sx={{ width: "25%" }}
                >
                  {t("settings.subscription")}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ width: "30%" }}
                >
                   {t("settings.NameofActiveItem")}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ width: "15%" }}
                >
                  {t("settings.nextInvoice")}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase border_right_0"
                  sx={{ width: "18%" }}
                >
                  {t("settings.autoRenewal")}
                </HeadCell>
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {visibleRows &&
                visibleRows.length > 0 &&
                visibleRows.map((subs) => (
                  <StyledTableRow
                    key={subs._id}
                    sx={{
                      "&:last-child td, &:last-child th": { borderBottom: 0 },
                    }}
                  >
                    <StyledTableCell
                      className="text-start border_left_0"
                      component="th"
                      scope="row"
                    >
                      {subs?.name === "Base Subscription"
                        ? "All-In-One Suite"
                        : subs?.name}
                    </StyledTableCell>
                    <StyledTableCell className="text-start">
                      {subs.logId ? (
                        <div>
                          <img
                            src={getServiceIcon(subs.logId?.accountingItemName)}
                            alt=""
                          />
                          <span className="ml-12">
                            {subs.logId?.itemDetails.name}
                          </span>
                        </div>
                      ) : (
                        "-"
                      )}
                    </StyledTableCell>
                    <StyledTableCell className="text-start">
                      {subs.purchasedOn && nextYearDate(subs.purchasedOn)}
                    </StyledTableCell>
                    <StyledTableCell className="text-start border_right_0">
                      {subs.isPaused ? (
                        <>
                          {profile.hasBaseCredit ? (
                            <p
                              className="underline overline_text pointer"
                              onClick={() =>
                                history(`/dashboard?tab=9&renew=${subs._id}`)
                              }
                            >
                              Re-activate
                            </p>
                          ) : (
                            <p
                              className={`${
                                subs.name === "Base Subscription"
                                  ? "underline"
                                  : ""
                              } overline_text pointer`}
                              onClick={() =>
                                history("/dashboard?tab=9&renew=base")
                              }
                            >
                              {subs.name === "Base Subscription"
                                ? "Re-activate"
                                : "-"}
                            </p>
                          )}
                        </>
                      ) : (
                        <p
                          className="underline overline_text pointer"
                          onClick={() => {
                            if (subs?.name === "Base Subscription")
                              handleb_Open();
                            else handleOpen();
                            setselectedSub(subs);
                          }}
                        >
                          Cancel Subscription
                        </p>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {subscriptionsArr && subscriptionsArr.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={subscriptionsArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="table-pagiation"
          />
        )}
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="p-50">
              {cancellationStep === 0 ? (
                <>
                  <p className="offsetProjectTitle">Cancel Subscription</p>
                  <hr />
                  {selectedSub &&
                    selectedSub.purchasedOn &&
                    isNotAllowedCancel(selectedSub.purchasedOn) && (
                      <div className="bg_grey_light width_fit d-flex align-items-center gap-5 px-24 py-20 border_radius_10">
                        <p className="font_16_500 ">
                          You have passed the cancelation period for this
                          subscription.
                        </p>
                        <p className="font_16_500 color_blue ">
                          Learn More
                          <KeyboardForwardIcon
                            cc={"#2441F5"}
                            className={"ml-8"}
                          />
                        </p>
                      </div>
                    )}
                  {selectedSub &&
                    selectedSub.logId &&
                    selectedSub.logId.isFirstOffset &&
                    checkItemIsCarbonNeutral(
                      getLastDateOfMonth(
                        selectedSub.logId.offsetDetails.find(
                          (val) => val.type === 2
                        ).endDate
                      )
                    ) && (
                      <div className="bg_grey_light width_fit d-flex align-items-center gap-5 px-24 py-20 border_radius_10">
                        <p className="font_16_500 ">
                          ❗️This accounting item is carbon neutral and
                          can&apos;t be cancelled
                        </p>
                        <p className="font_16_500 color_blue ">
                          Learn More
                          <KeyboardForwardIcon
                            cc={"#2441F5"}
                            className={"ml-8"}
                          />
                        </p>
                      </div>
                    )}
                  <TableContainer
                    component={Paper}
                    sx={{
                      minWidth: 640,
                      borderRadius: "9px",
                      boxShadow: "none",
                    }}
                    className="mr-50 mt-20"
                  >
                    <Table sx={{ minWidth: 640 }} aria-label="simple table">
                      <TableHead>
                        <StyledHeadRow>
                          <HeadCell
                            component="th"
                            className="text-uppercase border_left_0"
                          >
                            {t("settings.subscription")}
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase">
                          {t("settings.NameofActiveItem")}
                          </HeadCell>
                          <HeadCell
                            component="th"
                            className="text-uppercase border_right_0"
                          >
                            {t("settings.nextInvoice")}
                          </HeadCell>
                          {selectedSub &&
                            selectedSub.logId &&
                            selectedSub.logId.isFirstOffset && (
                              <HeadCell
                                component="th"
                                className="text-uppercase border_right_0"
                              >
                                END OF CONTRIBUTION PERIOD
                              </HeadCell>
                            )}
                        </StyledHeadRow>
                      </TableHead>
                      <TableBody>
                        {selectedSub && (
                          <StyledTableRow
                            key={selectedSub._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                borderBottom: 0,
                              },
                            }}
                          >
                            <StyledTableCell
                              className="text-start border_left_0"
                              component="th"
                              scope="row"
                            >
                              {selectedSub?.name}
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              {selectedSub.item === "-" ? (
                                "-"
                              ) : (
                                <div>
                                  <img
                                    src={getServiceIcon(
                                      selectedSub?.logId?.accountingItemName
                                    )}
                                    alt=""
                                  />
                                  <span className="ml-12">
                                    {selectedSub?.logId?.itemDetails?.name}
                                  </span>
                                </div>
                              )}
                            </StyledTableCell>
                            <StyledTableCell className="text-start ">
                              {selectedSub.purchasedOn &&
                                nextYearDate(selectedSub.purchasedOn)}
                              {/* {isWithinOneMonth(selectedSub.purchasedOn)} */}
                            </StyledTableCell>
                            {selectedSub &&
                              selectedSub.logId &&
                              selectedSub.logId.isFirstOffset && (
                                <StyledTableCell className="text-start">
                                  {getLastDateOfMonth(
                                    selectedSub.logId.offsetDetails.find(
                                      (val) => val.type === 2
                                    ).endDate
                                  )}
                                </StyledTableCell>
                              )}
                          </StyledTableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <hr />
                  <p className="helper_text ">
                    You are able to cancel your subscription up until 30 days
                    before the yearly autorenewal of your subscription. If your
                    item is carbon neutral, you can’t cancel the subscription,
                    but have to continue the contribution payment until the end
                    of your contribution period. Navigate to your Item profile,
                    to decativate the contribution. This is because of consumer
                    protection regulations.
                    <br />
                    <br />
                    If you have any questions regarding the termination
                    policies, please contact our customer success team. 📧
                  </p>
                  <hr />
                  <div className="row mx-0 justify-content-end">
                    <div className="mt-8 col-lg-3 col-md-3 col-sm-4 col-6 pe-4">
                      <ButtonComp
                        status={3}
                        className="text-black w-100"
                        onClick={() => handleClose()}
                      >
                        Go Back
                      </ButtonComp>
                    </div>
                    {selectedSub &&
                    ((selectedSub.purchasedOn &&
                      isNotAllowedCancel(selectedSub.purchasedOn)) ||
                      (selectedSub.logId &&
                        selectedSub.logId.isFirstOffset &&
                        checkItemIsCarbonNeutral(
                          getLastDateOfMonth(
                            selectedSub.logId.offsetDetails.find(
                              (val) => val.type === 2
                            ).endDate
                          )
                        ))) ? null : (
                      <div className="mt-8 col-lg-3 col-md-3 col-sm-4 col-6">
                        <ButtonComp
                          status={3}
                          className="color_red w-100 px-2"
                          onClick={() => setCancellationStep(1)}
                        >
                          Cancel Subscription
                        </ButtonComp>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <>
                  <div className="border-btm_ef pb-24">
                    <p className="offsetProjectTitle">
                      Hey {user.firstname}, do you have a minute? 💚
                    </p>
                  </div>
                  <div className="border-btm_ef pb-24 mt-24">
                    <p className="font_13_700 text_capital">
                      Why did you cancel the subscription?
                    </p>
                    <div className="mt-24 row">
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option1"}
                            name="answer"
                            checked={
                              reason ===
                              "Accounted Item is no longer part of the company"
                            }
                            onChange={() =>
                              setreason(
                                "Accounted Item is no longer part of the company"
                              )
                            }
                          />
                          <label
                            htmlFor={"option1"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            Accounted Item is no longer part of the company
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option2"}
                            name="answer"
                            checked={
                              reason ===
                              "I wasn’t satisfied with the feature set"
                            }
                            onChange={() =>
                              setreason(
                                "I wasn’t satisfied with the feature set"
                              )
                            }
                          />
                          <label
                            htmlFor={"option2"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            I wasn’t satisfied with the feature set
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option3"}
                            name="answer"
                            checked={
                              reason ===
                              "I was unsure, how to account emissions for this item"
                            }
                            onChange={() =>
                              setreason(
                                "I was unsure, how to account emissions for this item"
                              )
                            }
                          />
                          <label
                            htmlFor={"option3"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            I was unsure, how to account emissions for this item
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option4"}
                            name="answer"
                            checked={reason === "other"}
                            onChange={() => setreason("other")}
                          />
                          <label
                            htmlFor={"option4"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-24 input_div">
                    <p className="font_13_700">
                      WE WANT TO IMPROVE YOUR EXPERIENCE! LET US KNOW WHAT YOU
                      WERE MISSING.
                    </p>
                    <textarea
                      className="mt-16 subheading_2"
                      style={{ height: 127 }}
                      placeholder="We appreciate your feedback..."
                      value={description}
                      onChange={(e) => setdescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="mt-20 d-flex justify-content-end">
                    <ButtonComp
                      status={1}
                      onClick={() =>
                        handleCancel(selectedSub._id, selectedSub.name)
                      }
                    >
                      Send
                    </ButtonComp>
                  </div>
                </>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={b_open}
        onClose={handleb_Close}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={b_open}>
          <Box sx={style}>
            <div className="p-50">
              {base_step === 0 ? (
                <>
                  <p className="offsetProjectTitle">
                    {saveData ? "Pause" : "Cancel"} All-In-One Suite
                    Subscription
                  </p>
                  <hr />
                  <div className="row mx-0">
                    <div className="col-8">
                      <p className="user_box bg_grey_light">
                        ❗Are you sure about{" "}
                        {saveData ? "pausing" : "canceling"} your All-In-One
                        Suite Subscription?
                      </p>
                      <br />

                      <p>
                        If you cancel your subscription, all of your accounting
                        item credits will be canceled along with it, and you
                        won’t be able to use your today.green account any
                        longer.
                      </p>
                      <br />
                      <br />
                      <br />
                    </div>
                    <div className="col-4 text-center pb-20">
                      <img
                        src={
                          botAvatarGroup[user?.bot_avatar_id]?.logSupport.url
                        }
                        style={{ height: 280, width: "auto" }}
                      />
                    </div>
                    <hr />
                    <div className="row mx-0 justify-content-end">
                      <div className="mt-8 col-lg-3 col-md-3 col-sm-4 col-6 pe-4">
                        <ButtonComp
                          status={3}
                          className="text-black w-100"
                          onClick={() => handleb_Close()}
                        >
                          Go Back
                        </ButtonComp>
                      </div>

                      <div className="mt-8 col-lg-3 col-md-3 col-sm-4 col-6">
                        <ButtonComp
                          status={3}
                          className="w-100 px-2"
                          onClick={() => setbase_step(1)}
                        >
                          I understand
                        </ButtonComp>
                      </div>
                    </div>
                  </div>
                </>
              ) : base_step === 1 ? (
                <div>
                  <p className="offsetProjectTitle">
                    {saveData ? "Pause" : "Cancel"} All-In-One Suite
                    Subscription
                  </p>
                  <hr />
                  <TableContainer
                    component={Paper}
                    sx={{
                      minWidth: 640,
                      borderRadius: "9px",
                      boxShadow: "none",
                    }}
                    className="mr-50 mt-20"
                  >
                    <Table sx={{ minWidth: 640 }} aria-label="simple table">
                      <TableHead>
                        <StyledHeadRow>
                          <HeadCell
                            component="th"
                            className="text-uppercase border_left_0"
                            sx={{ width: "30%" }}
                          >
                            {t("settings.subscription")}
                          </HeadCell>

                          <HeadCell
                            component="th"
                            className="text-uppercase border_right_0"
                          >
                            {t("settings.nextInvoice")}
                          </HeadCell>
                          {/* <HeadCell
                        component="th"
                        className="text-uppercase border_right_0"
                      >
                        END OF OFFSET PERIOD
                      </HeadCell> */}
                        </StyledHeadRow>
                      </TableHead>
                      <TableBody>
                        {selectedSub ? (
                          <StyledTableRow
                            key={selectedSub._id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                borderBottom: 0,
                              },
                            }}
                          >
                            <StyledTableCell
                              className="text-start border_left_0"
                              component="th"
                              scope="row"
                            >
                              All-In-One Suite
                            </StyledTableCell>

                            <StyledTableCell className="text-start ">
                              {selectedSub.purchasedOn &&
                                nextYearDate(selectedSub.purchasedOn)}
                            </StyledTableCell>
                            {/* <StyledTableCell className="text-start border_right_0">
                          <p className="underline overline_text "></p>
                        </StyledTableCell> */}
                          </StyledTableRow>
                        ) : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <p className="card_heading text_capital f-14 mt-30 mb-10">
                    Do you want to save your emission data? &nbsp; &nbsp; &nbsp;
                    <span className="purple_label">Recommended</span>
                  </p>
                  <input
                    type="checkbox"
                    className="mt-2"
                    id="checkbox"
                    value={saveData}
                    checked={saveData}
                    onChange={() => setsaveData(!saveData)}
                  />
                  <label htmlFor="checkbox" className="f-13 ml-10 mb-1 lh-15">
                    Save all of my company’s emission data
                  </label>
                  <hr />
                  <p className="helper_text pe-4">
                    You are about to cancel your All-In-One subscription. This
                    will automatically halt all future payments for any active
                    credit subscriptions. If you wish to preserve the current
                    emission data associated with your subscription, please
                    check the box above. This action will pause the payments for
                    all your subscriptions, but you will retain view-only access
                    to your today.green account and the emission data related to
                    it.
                    <br />
                    If you need assistance, please{" "}
                    <a
                      className="underline"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:support@today.green"
                    >
                      reach out to us.
                    </a>{" "}
                    📧
                    <br />
                    <br />
                    You always have the option to delete your account later.
                  </p>
                  <hr />
                  <div className="row mx-0 justify-content-end">
                    <div className="mt-8 col-lg-3 col-md-3 col-sm-4 col-6 pe-4">
                      <ButtonComp
                        status={3}
                        className="text-black w-100"
                        onClick={() => setbase_step(0)}
                      >
                        Go Back
                      </ButtonComp>
                    </div>

                    <div className="mt-8 col-lg-3 col-md-3 col-sm-4 col-6">
                      <ButtonComp
                        status={3}
                        className="color_red w-100 px-2"
                        onClick={() => setbase_step(2)}
                      >
                        {saveData ? "Pause" : "Cancel"} Subscription
                      </ButtonComp>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <p className="offsetProjectTitle">
                    Hey {user.firstname}, we are very sorry to see you{" "}
                    {saveData ? "pause your subscription" : "go"} ...🌳
                  </p>
                  <hr />
                  <div className="border-btm_ef pb-24 mt-24">
                    <p className="font_13_700 text_capital">
                      Why did you {saveData ? "pause" : "cancel"} the
                      subscription?
                    </p>
                    <div className="mt-24 row">
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option1"}
                            name="answer"
                            checked={
                              reason ===
                              "The accounting process is too time consuming"
                            }
                            onChange={() =>
                              setreason(
                                "The accounting process is too time consuming"
                              )
                            }
                          />
                          <label
                            htmlFor={"option1"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            The accounting process is too time consuming
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option2"}
                            name="answer"
                            checked={
                              reason ===
                              "I wasn’t satisfied with the feature set"
                            }
                            onChange={() =>
                              setreason(
                                "I wasn’t satisfied with the feature set"
                              )
                            }
                          />
                          <label
                            htmlFor={"option2"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            I wasn&apos;t satisfied with the feature set
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option3"}
                            name="answer"
                            checked={
                              reason ===
                              "The analytics weren't detailed/actionable enough"
                            }
                            onChange={() =>
                              setreason(
                                "The analytics weren't detailed/actionable enough"
                              )
                            }
                          />
                          <label
                            htmlFor={"option3"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            The analytics weren&apos;t detailed/actionable
                            enough
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-12 mb-16">
                        <div className="d-flex csrd_assessment_input_radio">
                          <input
                            type="checkbox"
                            id={"option4"}
                            name="answer"
                            checked={reason === "other"}
                            onChange={() => setreason("other")}
                          />
                          <label
                            htmlFor={"option4"}
                            className="csrd_assessment_radio-label body_text_s ml-8"
                          >
                            Other
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-24 input_div">
                    <p className="font_13_700">
                      WE WANT TO IMPROVE YOUR EXPERIENCE! LET US KNOW WHAT YOU
                      WERE MISSING.
                    </p>
                    <textarea
                      className="mt-16 subheading_2"
                      style={{ height: 127 }}
                      placeholder="We appreciate your feedback..."
                      value={description}
                      onChange={(e) => setdescription(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="row mx-0 justify-content-end mt-16">
                    <div className=" col-lg-3 col-md-3 col-sm-4 col-6 pe-4">
                      <ButtonComp
                        status={3}
                        className="text-black w-100"
                        onClick={() => setbase_step(1)}
                      >
                        Go Back
                      </ButtonComp>
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-4 col-6">
                      <ButtonComp
                        status={1}
                        className=" w-100 px-2"
                        onClick={() =>
                          handleBaseSubcription(saveData ? false : true)
                        }
                      >
                        SEND
                      </ButtonComp>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Subscriptions;
