import React from "react";
import "./numberInputType.css";
import { TextField } from "@mui/material";
function NumberInputType(props) {
  function isNum(v) {
    return /^\d+$/.test(v);
  }

  //   useEffect(() => {
  //     if (isNum(currVal) || currVal.length == 0)
  //       props.setAnswers((prev) => {
  //         return {
  //           ...prev,
  //           [props.questionNo]: currVal,
  //         };
  //       });
  //     //props.handleAnswerChange(currVal, props.questionNo)
  //   }, [currVal]);
  //   useEffect(()=>{
  //     if(props.defaultValue) setCurrVal(props.defaultValue)
  //   },[])
  const handleChange = (value) => {
    if (isNum(value) || value.length == 0) {
      if ((props.percentage && value <= 100 && value >= 0) || !props.percentage)
        props.handleAnswerChange(props.answerIndex, value);
    }
  };
  return (
    <>
      <TextField
        value={
          props.answers != undefined && props.answerIndex != undefined
            ? props.answers[props.answerIndex]
            : ""
        }
        //  placeholder={"Address"}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        id="demo-helper-text-aligned"
        size="small"
        sx={{
          border: "none",
          borderRadius: 1,
          boxShadow: "none",
          outline: "none",
          backgroundColor: "#F6F6F6",
          "& fieldset": { border: "none" },
          "& .MuiInputBase-root": {
            border: "none !important",
          },
        }}
        className={`form-control shadow-none mt-1  

        ${
          props.percentage
            ? "numberInputType_input_symbol"
            : props.unit
            ? "numberInputType_input_symbol_m2"
            : ""
        }
          `}
        inputProps={{
          style: {
            paddingLeft: "30px",
            height: "30px",
          },
        }}
      />
    </>
  );
}

export default NumberInputType;
