import React from "react";
import './dateInputType.css'
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//import dayjs from "dayjs";
//import { TextField } from "@mui/material";
function DateInputType(props) {

  return (
    <div className="dateInputType_date_container">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            className="w-100 bg_f6 border-none"
            value={props.answers != undefined && props.answerIndex != undefined ? props.answers[props.answerIndex]:""}
            onChange={(new_date) => {
              props.handleAnswerChange(props.answerIndex, new_date)
            }}
            slotProps={{ textField: { size: "small" } }}
            //openTo={props.baseYearField ? "year" : "month"}
         //   format={props.baseYearField ? "YYYY" : "MM-YYYY"}
          //  views={!props.baseYearField ? ["year", "month"] : ["year"]}
            format={"MM-YYYY"}
           views={["year", "month"]}
           
          />
        </DemoContainer>
      </LocalizationProvider>
    </div>
  );
}

export default DateInputType;
