import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import axios from "../../../../Api";
import useQuery from "../../../../Utils/query";
import { toast } from "react-hot-toast";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getSDGImg } from "../../../../Utils/SDGJSON";
import { convertToTonnes } from "../../../../Utils/getAvatars";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../Utils/TableComp";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
import { motion } from "framer-motion";
import ButtonComp from "../../../../Assets/components/Button";
import ReactToPrint from "react-to-print";
import "./print.css";

const ProductDetails = ({
  totalProjects,
  visibleEmission,
  getCartDetails,
  selectedOption,
  setStep,
  unique_id,
  isFirstOffset,
}) => {
  const query = useQuery();
  const navigate = useNavigate();
  const { setSessionExpire } = useAuth();
  const [product, setProduct] = useState();
  const [productImages, setProductImages] = useState([]);
  const [productImage, setProductImage] = useState(null);
  const [selectedEmissions, SetSelectedEmissions] = useState("");
  const [totalAddedEmittion, setTotalAddedEmittion] = useState(0);
  const [showProjects, setShowProjects] = useState(false);

  const productId = query.get("productid");
  const logid = query.get("log");

  const getSingleProduct = async (id) => {
    await axios({
      url: `/cloverly/one-project?id=${id}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          const { product } = res.data;
          setProduct(product);
          setProductImages(product.image_list);
          setProductImage(product.image_list[0]);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (productId) {
      getSingleProduct(productId);
    }
  }, [productId]);
  useEffect(() => {
    if (totalProjects.length > 0) {
      for (let el of totalProjects) {
        if (el.projectId === productId) {
          SetSelectedEmissions(Number(el.tonsOffset));
          break;
        }
      }
    }
    const totalProjectEmi = totalProjects.reduce(
      (prev, sum) => prev + Number(sum.tonsOffset),
      0
    );
    setTotalAddedEmittion(totalProjectEmi);
  }, [totalProjects]);

  const handleAddToCart = async () => {
    if (logid === "null" || logid === undefined || logid === null || logid === false) {
      return toast.error("Please activate an accounting item in Contributions first");
    }
    if (selectedOption === 2 && !isFirstOffset && selectedEmissions > 1) {
      return toast.error("You can not add more than 1 tonne");
    }
    if (
      selectedEmissions === "" ||
      selectedEmissions === 0 ||
      selectedEmissions === null
    ) {
      return toast.error("No carbon amount added");
    }
    await axios({
      url: `/project-cart/add-project`,
      method: "post",
      data: {
        projectId: productId,
        logId: logid,
        tonsOffset: selectedEmissions,
        projectImage: productImage.url,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Added to cart successfully");
          SetSelectedEmissions(0);
          getCartDetails(logid);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const ref = React.createRef();

  useEffect(() => {
    const printStyles = `
      @media print {
        /* Set the page size to A4 */
        @page {
          size: A4;
        }
        .project_cart_btn_root,
        .productDetails_select_card,
        .prd_download,
        .product_desc_1 {
          display: none;
        }
        .product_desc_2 {
          display: block;
        }
        .prd_sgds,
        .prd_main {
          margin-top: 0;
        }
      }
    `;

    const styleElement = document.createElement("style");
    styleElement.type = "text/css";

    if (styleElement.styleSheet) {
      // For IE
      styleElement.styleSheet.cssText = printStyles;
    } else {
      styleElement.appendChild(document.createTextNode(printStyles));
    }

    document.head.appendChild(styleElement);

    // Clean up the style element when the component unmounts
    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  return (
    <div className="offsetMarketPlace_root">
      <div className="offsetMarketPlace_breadcrumbs d-flex align-items-center">
        <p
          className="breadcrumb_text_light_1 pointer"
          onClick={() => navigate(-2)}
        >
          <span className="">
            <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
          </span>
          Contribution Overview
        </p>{" "}
        <p className="breadcrumb_text_light_1 ml-8">/</p>{" "}
        <p
          className="breadcrumb_text_light_1 ml-8 pointer"
          onClick={() => navigate(-1)}
        >
          {" "}
          Contribution Marketplace
        </p>
        <p className="breadcrumb_text ml-8">/</p>
        <p className="breadcrumb_text ml-8"> Project Detail</p>
      </div>
      <div className="main_box prd_main" ref={ref}>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <p className="offsetProjectTitle">{product?.name}</p>
            <p>
              <span className="body_text_s_light">{product?.short_name}</span>
              <span className="ml-8 color_blue heading_5 fw-600">
                {product?.country} • {product?.active_tranche?.vintage_year}
              </span>
            </p>
          </div>
          <div className="project_cart_btn_root">
            <div
              className="d-flex align-items-center project_cart_btn_main pointer"
              onClick={() => setShowProjects(!showProjects)}
            >
              <span
                className="mr-8 project_cart_icon_"
                onClick={() =>
                  navigate(
                    `/dashboard?tab=5&subtab=1&step=3&log=${logid}&offset=${unique_id}`
                  )
                }
              >
                <ShoppingCartOutlined fontSize="small" htmlColor="#ffffff" />
              </span>
              <div className="flex-grow-1 text-center">
                <p className="font_15_500 color_black">
                  {totalProjects.length} Projects
                </p>
              </div>
              <div className="pr-16 pointer">
                <KeyboardArrowDown />
              </div>
            </div>
            {showProjects && (
              <motion.div
                className="position-relative"
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                transition={{ duration: 0.3 }}
              >
                <motion.div className="project_cart_btn_projects_root">
                  {totalProjects &&
                    totalProjects.length > 0 &&
                    totalProjects.map((prj, i) => (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 1.2 }}
                        key={prj._id}
                        className={
                          "d-flex align-items-center project_cart_btn_projects_main p-16 " +
                          (i + 1 !== totalProjects.length && "border_1_ef")
                        }
                      >
                        <img
                          src={prj.projectImage}
                          alt={prj.offsetProjectName}
                          className="cartbtn_project_img"
                        />
                        <div className="ml-12">
                          <p className="heading_4 fw-600 text_overflow-1">
                            {prj.offsetProjectName}
                          </p>
                          <p className="subheading_light_2">
                            {prj.totalPrice.toFixed(2).toLocaleString()}€
                          </p>
                          <p className="mt-8 font_15_600">
                            {prj.tonsOffset.toFixed(2).toLocaleString()}t
                          </p>
                        </div>
                      </motion.div>
                    ))}
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1.2 }}
                    className="project_cart_btn_projects_card pointer"
                    onClick={() => {
                      setStep(3);
                      navigate(
                        `/dashboard?tab=5&subtab=1&step=3&log=${logid}&offset=${unique_id}`
                      );
                    }}
                  >
                    <span className="mr-16">
                      <ShoppingCartOutlined htmlColor="#ffffff" />
                    </span>{" "}
                    <p className="font_15_500 color_white">Go to Cart</p>
                  </motion.div>
                </motion.div>
              </motion.div>
            )}
          </div>
        </div>
        {product && (
          <div className="productDetails_main">
            <div className="row mt-20 mx-0">
              <div className="col-lg-7 col-ms-6 col-sm-12 col-12 ps-0 pe-4">
                {productImages && productImages.length > 0 && (
                  <div className="productDetails_images_root">
                    {productImage && (
                      <div className="productDetails_images_main">
                        <img
                          src={productImage.url}
                          alt={product?.offset_classification}
                        />
                      </div>
                    )}
                    <div className="mt-20">
                      <div className="row">
                        {productImages.map((img) => (
                          <div className="col-3" key={img.url}>
                            <div
                              className="productDetails_images_ pointer "
                              onClick={() => setProductImage(img)}
                            >
                              <img
                                src={img.url}
                                alt={product?.offset_classification}
                              />
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-40 product_desc_1">
                  <p className="font_17_700">Project Description</p>
                  <p className="subheading_light mt-20">
                    {product.long_description}
                  </p>
                </div>
              </div>
              <div className="col-lg-5 col-ms-6 col-sm-12 col-12 px-0">
                <div className="productDetails_select_card">
                  <div className="productDetails_select_card_header d-flex align-items-center flex-wrap justify-content-between">
                    <p className="subheading_3">CHOOSE AMOUNT</p>
                    {product?.offset_classification && (
                      <div className="subtitle_box bg_soft_green ml-24">
                        <p className="font_13_600 green_label">
                          {product?.offset_classification}
                        </p>
                      </div>
                    )}
                    <div className="subtitle_box border_1_ef pl-20 pr-20 ml-24">
                      <p className="subheading_light_2 color_black">
                        {convertToTonnes(
                          product.cost_per_kg_carbon_in_usd_cents
                        ).toLocaleString()}{" "}
                        €/t
                      </p>
                    </div>
                  </div>
                  <div className="productDetails_select_card_header pt-0">
                    <p className="">
                      <span className="heading_4 fw-600">
                        {(visibleEmission - totalAddedEmittion)
                          .toFixed(2)
                          .toLocaleString()}
                        t remaining
                      </span>
                      <span className="heading_4 fw-400 ml-6">
                        ({totalAddedEmittion.toFixed(2).toLocaleString()}/
                        {visibleEmission.toFixed(2).toLocaleString()}t)
                      </span>
                    </p>
                    {/* <p>Total added : {totalAddedEmittion}</p>
                  <p>
                    <span className="heading_lg2">
                      {selectedEmissions ? selectedEmissions : 0}/
                      {totalEmission}
                    </span>
                    <span className="faint_text_2 ml-8 text-lowercase">
                      tonnes selected
                    </span>
                  </p> */}
                  </div>
                  {(selectedOption === 1 || isFirstOffset) && (
                    <div className="productDetails_slct_card_main">
                      <p className="body_text_s">Select percentage</p>
                      <div className="row mt-16">
                        <div className="col">
                          <div
                            className={
                              "productDetails_slct_per pointer " +
                              (selectedEmissions ===
                                (visibleEmission - totalAddedEmittion) * 0.25 &&
                                "productDetails_slcted")
                            }
                            onClick={() => {
                              if (logid !== "null") {
                                SetSelectedEmissions(
                                  (visibleEmission - totalAddedEmittion) * 0.25
                                );
                              }
                            }}
                          >
                            <p className="body_text_s">25%</p>
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className={
                              "productDetails_slct_per pointer " +
                              (selectedEmissions ===
                                (visibleEmission - totalAddedEmittion) / 2 &&
                                "productDetails_slcted")
                            }
                            onClick={() => {
                              if (logid !== "null") {
                                SetSelectedEmissions(
                                  (visibleEmission - totalAddedEmittion) / 2
                                );
                              }
                            }}
                          >
                            <p className="body_text_s">50%</p>
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className={
                              "productDetails_slct_per pointer " +
                              (selectedEmissions ===
                                (visibleEmission - totalAddedEmittion) * 0.75 &&
                                "productDetails_slcted")
                            }
                            onClick={() => {
                              if (logid !== "null") {
                                SetSelectedEmissions(
                                  (visibleEmission - totalAddedEmittion) * 0.75
                                );
                              }
                            }}
                          >
                            <p className="body_text_s">75%</p>
                          </div>
                        </div>
                        <div className="col">
                          <div
                            className={
                              "productDetails_slct_per pointer " +
                              (selectedEmissions ===
                                visibleEmission - totalAddedEmittion &&
                                "productDetails_slcted")
                            }
                            onClick={() => {
                              if (logid !== "null") {
                                SetSelectedEmissions(
                                  visibleEmission - totalAddedEmittion
                                );
                              }
                            }}
                          >
                            <p className="body_text_s">100%</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="productDetails_slct_card_main pb-24">
                    {(selectedOption === 1 || isFirstOffset) && (
                      <p className="body_text_s">
                        or enter manually (available carbon:{" "}
                        {product.available_carbon_in_kg / 1000} tonnes)
                      </p>
                    )}
                    <div className="row mt-16">
                      <div className="col">
                        <div className="border_1_ef">
                          <div className="tonne_input">
                            <input
                              type="number"
                              value={selectedEmissions}
                              placeholder="0"
                              onChange={(e) => {
                                if (selectedOption === 2 && !isFirstOffset) {
                                  if (e.target.value <= 1) {
                                    SetSelectedEmissions(e.target.value);
                                  } else {
                                    toast.error(
                                      "You cannot add more than 1 ton"
                                    );
                                  }
                                } else {
                                  if (
                                    e.target.value <=
                                    visibleEmission - totalAddedEmittion
                                  ) {
                                    SetSelectedEmissions(e.target.value);
                                  } else {
                                    toast.error(
                                      "You can not add more than total remaining emission"
                                    );
                                  }
                                }
                              }}
                              disabled={
                                logid === "null" ||
                                logid === null ||
                                logid === undefined ||
                                !logid
                              }
                            />
                            <p className="subheading_1 text-lowercase">
                              tonnes
                            </p>
                          </div>
                          <div className="d-flex align-items-center justify-content-between p-10">
                            <p className="subheading_light_2 color_8a">
                              {(
                                convertToTonnes(
                                  product.cost_per_kg_carbon_in_usd_cents
                                ) * (selectedEmissions ? selectedEmissions : 0)
                              )
                                .toFixed(2)
                                .toLocaleString()}
                            </p>
                            <p className="subheading_light_2 color_8a">€</p>
                          </div>
                        </div>
                      </div>
                      <div className="col ml-16">
                        <ButtonComp
                          status={1}
                          // className="primary_button_lg"
                          width="lg"
                          onClick={handleAddToCart}
                          disabled={
                            selectedEmissions &&
                            selectedEmissions >
                              product.available_carbon_in_kg / 1000
                          }
                        >
                          + Add to Cart
                        </ButtonComp>
                      </div>
                    </div>
                  </div>
                </div>
                {product?.unsdg.length > 0 && (
                  <div className="prd_sgds">
                    <p className="card_heading text_capital">
                      This project&apos;s SDG Goals
                    </p>
                    <div className="mt-20 d-flex align-items-center flex-wrap">
                      {product?.unsdg.map((sdg, i) => (
                        <img
                          key={i}
                          src={getSDGImg[sdg].img}
                          alt="sdg1"
                          className="mr-16 sdg_img mt-1"
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="mt-40 product_desc_2">
              <p className="font_17_700">Project Description</p>
              <p className="subheading_light mt-20">
                {product.long_description}
              </p>
            </div>
            {/* <div className="mt-40">
              <p className="subheading_light">{product.long_description}</p>
            </div> */}
            <div className="mt-40 analytics_table_container">
              <p className="card_heading">Project Details</p>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640 }}
                className="logTable_container mr-50 mt-20"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        component="th"
                        sx={{ borderLeft: "0", width: "20%" }}
                        className="text-uppercase"
                      >
                        Methodology Type
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        Mechanism
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        Registry
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        Project ID
                      </HeadCell>
                      <HeadCell
                        component="th"
                        className="text-uppercase"
                        sx={{ borderRight: "0" }}
                      >
                        Ratings
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell
                        scope="row"
                        textleft={true}
                        className="border_left_0"
                      >
                        <p className="body_text_m">
                          {product?.type ? product?.type : "-"}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell align="center" textleft={true}>
                        <p className="body_text_m">
                          {product?.offset_classification
                            ? product?.offset_classification
                            : "-"}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell align="center" textleft={true}>
                        <p className="body_text_m">
                          {product?.registry_name
                            ? product?.registry_name
                            : "-"}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell align="center" textleft={true}>
                        <p className="body_text_m">
                          {product?.registry_project_id
                            ? product?.registry_project_id
                            : "-"}
                        </p>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        textleft={true}
                        className="border_right_0"
                      >
                        <p className="body_text_m">
                          {product?.project_ratings[0]
                            ? `${product?.project_ratings[0].rating} (${product?.project_ratings[0].rating_title})`
                            : "-"}
                        </p>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <ReactToPrint
              // pageStyles={"@media print @page { size: A4; }}"}
              trigger={() => (
                <ButtonComp
                  status={3}
                  className="mt-40 text-black prd_download"
                  style={{ display: "none" }}
                >
                  Download PDF
                </ButtonComp>
              )}
              content={() => ref.current}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetails;
