import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";

const COLORS = ["#BCE0FF", "#79C1FF", "#FFCB11"];

const CustomTooltip = ({ active, payload, coordinate }) => {
  const [tooltipPos, setTooltipPos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (active && payload && payload.length) {
      const { x, y } = coordinate;
      setTooltipPos({ x, y });
    }
  }, [active, payload, coordinate]);

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    return (
      <div
        className="d-flex align-items-center custom_tooltip_"
        style={{ left: tooltipPos.x, top: tooltipPos.y }}
      >
        {data.name === "Scope 1" ? (
          <span className="span_cirlce bg_soft_blue_bc mr-8"></span>
        ) : data.name === "Scope 2" ? (
          <span className="span_cirlce bg_scope2 mr-8"></span>
        ) : (
          data.name === "Scope 3" && (
            <span className="span_cirlce bg_scope3 mr-8"></span>
          )
        )}
        <p className="tooltip_chart_text">
          {Number(data.value).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          t
        </p>
      </div>
    );
  }

  return null;
};

const Scops = ({ data }) => {
  return (
    <ResponsiveContainer height={270} minWidth={150}>
      <PieChart>
        <Pie
          data={data}
          cx={"50%"}
          cy={"50%"}
          innerRadius={60}
          outerRadius={85}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
          startAngle={360}
          endAngle={0}
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <text
          x={"48%"}
          dy={"51%"}
          fill="#000"
          fontSize={30}
          fontFamily="Inter"
          fontWeight="700"
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
        >
          3
        </text>
        <text
          x={"45%"}
          dy={"56%"}
          fill="#000"
          fontSize={13.5}
          fontFamily="Inter"
          fontWeight="600"
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
        >
          Scopes
        </text>
        <Tooltip
          content={<CustomTooltip />}
          position="right"
          cursor={{ fill: "transparent" }}
        />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default Scops;
