import React from "react";
import ButtonComp from "../Button";
import { useAuth } from "../../../Utils/Auth";
import { useNavigate } from "react-router-dom";

const ReactiveSubscription = ({ title, isPause }) => {
  const { profile } = useAuth();
  const history = useNavigate();
  return (
    <>
      {(!profile.hasBaseCredit || isPause) && (
        <div className="bg_grey_light width_fit d-flex align-items-center gap-5 px-24 py-20 border_radius_10 mb-24">
          <p className="font_16_500 ">
            {title
              ? title
              : "❗Your subscription is currently paused. Reactivate your subscription to add logs again."}
          </p>
          <ButtonComp
            status={3}
            className="text-black"
            onClick={() => history("/dashboard?tab=9&renew=base")}
          >
            Reactivate Subscription
          </ButtonComp>
        </div>
      )}
    </>
  );
};

export default ReactiveSubscription;
