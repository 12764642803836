import React, { useEffect, useState } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import axios from "../../../../Api";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-hot-toast";
import CardMedia from "@mui/material/CardMedia";
import KnowledgeProfile from "./KnowledgeProfile";
import { useNavigate } from "react-router-dom";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import parse from "html-react-parser";

const Knowdlgehub = () => {
  const [currentStep, setcurrentStep] = useState(0);
  const navigate = useNavigate();

  const [knowledges, setknowledges] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(`/admin/academy/knowledge`);
      if (response.data.success) {
        setknowledges(response.data.data);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  const step = +getUrlqueries("step");

  useEffect(() => {
    if (step === 1) {
      setcurrentStep(1);
    } else {
      setcurrentStep(0);
    }
  }, [step]);

  const handleDeleteTopic = async (cid) => {
    try {
      const response = await axios.delete(
        `/admin/academy/deleteknowledge/${cid}`
      );
      if (response.data.success) {
        getdata();
        toast.success("Knowledge deleted successfully");
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };

  return (
    <>
      {currentStep === 1 ? (
        <KnowledgeProfile />
      ) : (
        <div className="row mx-0">
          {knowledges.map((v, i) => {
            return (
              <>
                <Card className="mb-2 me-4" key={i} sx={{ maxWidth: 345 }}>
                  <CardMedia
                    sx={{ height: 140 }}
                    image={v.coverimage}
                    title={v.title}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {parse(v.title)}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {parse(v.description)}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() =>
                        navigate(
                          `/admin/dashboard?tab=5&activetab=4&step=1&kid=${v._id}`
                        )
                      }
                      size="small"
                    >
                      View
                    </Button>
                    <Button
                      onClick={() =>
                        navigate(
                          `/admin/dashboard?tab=5&activetab=5&kid=${v._id}`
                        )
                      }
                      size="small"
                    >
                      Edit
                    </Button>
                    <Button
                      onClick={() => handleDeleteTopic(v._id)}
                      size="small"
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Knowdlgehub;
