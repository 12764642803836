import React, { useState, useEffect } from "react";
import "./scopeCard.css";
import { getServiceIcon } from "../../../../../../../../Utils/itemIcon";
function ScopeCard(props) {
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "November",
    "October",
    "November",
    "December",
  ];
  const handleAddOnClick = (data) => {
    props.openUpdateMonthEmission(data);
  };
  const [totalEmission, settotalEmission] = useState(0);
  const [totalAmount, settotalAmount] = useState(0);
  useEffect(() => {
    if (
      props.cardDetail &&
      props.cardDetail.amounts &&
      props.cardDetail.amounts.length > 0
    ) {
      let arr = props.cardDetail.amounts;
      let emission = 0;
      let amount = 0;
      for (let i = 0; i < arr.length; i++) {
        emission += arr[i].emission;
        amount += arr[i].amount;
      }
      settotalAmount(amount);
      settotalEmission(emission ? emission.toFixed(2) : 0);
    }
  }, [props.cardDetail]);

  return (
    <div className="card_design_1">
      <div className="scopeCard_container p-3">
        <p
          className="scopeCard_heading pointer"
          onClick={() => {
            handleAddOnClick(props.cardDetail);
          }}
        >
          <img
            src={getServiceIcon(props.cardDetail.subClass)}
            alt={props.cardDetail.subClass}
            height="24px"
          />
          &nbsp;{props.cardDetail.name}
        </p>
        <p className="body_text_s_light mt-10">
          {`${props.cardDetail.subClassHead}  ${
            props.cardDetail.subClass ? " - " + props.cardDetail.subClass : ""
          }  
          ${
            props.cardDetail.calculationMethod
              ? " - " + props.cardDetail.calculationMethod
              : ""
          }  ${props.cardDetail.type ? " - " + props.cardDetail.type : ""}  
          ${props.cardDetail.unit ? " - " + props.cardDetail.unit : ""}`}
        </p>
      </div>
      <div className="scopeCard_container d-flex flex-column align-items-start p-3">
        <p className="f-35 fw-700">
          {Number(totalEmission).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
          <span className="f-13 f-500"> tCO2e</span>
        </p>
        <p className="body_text_l mt-6">
          Amount:{" "}
          {Number(totalAmount).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          {props.cardDetail.unit ? props.cardDetail.unit : ""}
          {/* {props.cardDetail.currData[5] ? props.cardDetail.currData[5] : ""} */}
        </p>
      </div>
      <div className="container d-flex justify-content-around align-items-center my-3">
        <div className="col-2">
          <p className="purple_label p-2 px-3">
            {props.cardDetail.amounts ? props.cardDetail.amounts.length : 0}{" "}
            {props.cardDetail.amounts.length > 1 ? "Logs" : "Log"}
          </p>
        </div>

        <div className="col-2">
          <p className="neutral_label p-2 px-3">
            {monthNames[props.month - 1]}
          </p>
        </div>

        <div className="col-6 ps-3">
          <p className="overline_text">
            last logged/edited: {props.date.slice(8, 10)}-
            {props.date.slice(5, 7)}-{props.date.slice(0, 4)}
          </p>
        </div>

        <div className="col-2 text-right p-2">
          <button
            onClick={() => {
              handleAddOnClick(props.cardDetail);
            }}
            className="scopeCard_plus_button"
          >
            <i className="bi bi-plus-lg f-27"></i>
          </button>
        </div>
      </div>
    </div>
  );
}

export default ScopeCard;
