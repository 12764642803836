import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DashboardIcon from "../../../Assets/icons/dashboardIcon";
import CheckBook from "../../../Assets/icons/checkbook";
import ChartIcon from "../../../Assets/icons/chartIcon";
import GraphFall from "../../../Assets/icons/graphFall";
import PaperCheck from "../../../Assets/icons/papercheck";
import EduHat from "../../../Assets/icons/eduhat";
import AddContact from "../../../Assets/icons/addContact";
import AddIcon from "../../../Assets/icons/addIcon";
import ControlIcon from "../../../Assets/icons/controlIcon";
import LogoutIcon from "../../../Assets/icons/logoutIcon";
import Icon from "../../../Assets/components/logo_sm";
import ProfileIcon from "./profileIcon";
import LogDashboard from "../../Companydashboard/LogDashboard";
// import Dashboard from "../../Companydashboard/Dashboard";
import Analytics from "../../Companydashboard/Analytics";
import "./sidebar.css";
import useQuery from "../../../Utils/query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import Profile from "../../Companydashboard/Profile";
import CompanySettings from "../../Companydashboard/CompanySettings";
import { motion, AnimatePresence } from "framer-motion";
import { KeyboardArrowDown } from "@mui/icons-material";
import Act from "../../Companydashboard/Act";
import Share from "../../Companydashboard/Share";
import Connect from "../../Companydashboard/Connect";
import Academy from "../../Companydashboard/Academy";
import Store from "../../Companydashboard/Store";
import Loader from "../../../Assets/components/Loader";
import NotFound from "../../CommonComponents/NotFound/NotFound";
import { useIntercom } from "react-use-intercom";
import CompanyMap from "../../Companydashboard/CompanyMap";
import CompanyMapIcon from "../../../Assets/icons/companyMapIcon";
import NewDashboard from "../../Companydashboard/Dashboard/NewDashboard";
// import { Helmet } from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-100 sidebar_tabpanel"
    >
      {value === index && <Box sx={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function Sidebar() {
  const { signOut, user, isLoading, profile } = useAuth();
  const { boot } = useIntercom();
  const [value, setValue] = useState(2);
  const [subValue, setSubValue] = useState(-1);
  const [activeStroke] = useState("white");
  const disabledStroke = "#575757";
  const stroke = "black";
  const [active] = useState(true);
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const [subValueToggle, setSubValueToggle] = useState(false);
  const query = useQuery();
  const tabNo = Number(query.get("tab"));
  const navigate = useNavigate();
  useEffect(() => {
    if (tabNo === 0) {
      setValue(2);
    } else if (tabNo) {
      setValue(tabNo);
    } else setValue(2);
  }, [tabNo]);

  const handleChange = (newValue, subValue) => {
    setValue(newValue);
    if (newValue === 5)
      navigate(`/dashboard?tab=${newValue}&subtab=${subValue}`);
    else {
      setSubValue(-1);
      navigate(`/dashboard?tab=${newValue}`);
    }
  };
  const navopen = () => {
    setToggleNavbar(true);
  };
  const navclose = () => {
    setToggleNavbar(false);
    setSubValueToggle(false);
  };

  // function a11yProps(index) {
  //   return {
  //     id: `vertical-tab-${index}`,
  //     "aria-controls": `vertical-tabpanel-${index}`,
  //   };
  // }
  React.useEffect(() => {
    if (user && document.getElementById("sidebar_container")) {
      document
        ?.getElementById("sidebar_container")
        ?.addEventListener("mouseover", function () {
          document.getElementById("sidebar_container").style.width = "180px";
          navopen();
        });
      document
        ?.getElementById("sidebar_container")
        ?.addEventListener("mouseleave", function () {
          navclose();
          document.getElementById("sidebar_container").style.width = "76px";
        });
    }
  }, [user]);

  React.useEffect(() => {
    boot();
  }, []);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : user ? (
        <div className="sidebar_main_container">
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
              height: "100vh",
            }}
          >
            <div className="sidebar_container" id="sidebar_container">
              <div className="side_tab_group_root">
                <div className="side_tab_logo">
                  <Icon />
                </div>
                <div className="side_tab_group">
                  <div className="">
                    <div className="side_tab_subgroup">
                      <div
                        className="sidebar_tab"
                        onClick={() => {
                          handleChange(2);
                        }}
                      >
                        {value === 2 ? (
                          <DashboardIcon
                            stroke={activeStroke}
                            active={active}
                          />
                        ) : (
                          <DashboardIcon
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        {toggleNavbar && (
                          <div>
                            <span className="ml-12 mr-20 color_black ">
                              Dashboard
                            </span>
                          </div>
                        )}
                      </div>

                      <div
                        className="sidebar_tab mt-16"
                        onClick={() => {
                          handleChange(13);
                        }}
                      >
                        {value === 13 ? (
                          <CompanyMapIcon
                            stroke={activeStroke}
                            active={active}
                          />
                        ) : (
                          <CompanyMapIcon
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        {toggleNavbar && (
                          <div>
                            <p className="ml-12 mr-20 color_black">Map</p>
                          </div>
                        )}
                      </div>

                      <div
                        className="sidebar_tab mt-16"
                        onClick={() => {
                          handleChange(3);
                        }}
                      >
                        {value === 3 ? (
                          <CheckBook stroke={activeStroke} active={active} />
                        ) : (
                          <CheckBook
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        {toggleNavbar && (
                          <div>
                            <p className="ml-12 mr-20 color_black">Log</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="sidebar_tab mt-16"
                        onClick={() => {
                          handleChange(4);
                        }}
                      >
                        {value === 4 ? (
                          <ChartIcon stroke={activeStroke} active={active} />
                        ) : (
                          <ChartIcon
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        {toggleNavbar && (
                          <div>
                            <p className="ml-12 mr-20 color_black ">
                              Analytics
                            </p>
                          </div>
                        )}
                      </div>
                      <div
                        className="sidebar_tab mt-16"
                        onClick={() => {
                          setSubValueToggle(!subValueToggle);
                        }}
                      >
                        {value === 5 ? (
                          <GraphFall stroke={activeStroke} active={active} />
                        ) : (
                          <GraphFall
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        {toggleNavbar && (
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ width: "100%" }}
                          >
                            <p className="ml-12 mr-20 color_black">Act</p>
                            {/* {value === 5 && ( */}
                            <motion.span
                              initial={{ rotate: 0 }}
                              animate={{
                                rotate: subValueToggle ? 180 : 0,
                              }}
                              transition={{
                                duration: 0.3,
                                type: "tween",
                              }}
                            >
                              <KeyboardArrowDown />
                            </motion.span>
                            {/* )} */}
                          </div>
                        )}
                      </div>
                      <AnimatePresence>
                        {toggleNavbar && (subValue === 0 || subValueToggle) && (
                          <motion.div
                            initial={{ height: 0 }}
                            animate={{ height: "auto" }}
                            transition={{ duration: 0.4, type: "tween" }}
                            exit={{ duration: 0.1, height: 0, opacity: 0 }}
                            className="sub_tab_root"
                          >
                            <motion.div
                              // initial={{ opacity: 1 }}
                              // animate={{ opacity: 1 }}
                              transition={{ duration: 1, type: "tween" }}
                              className="sub_tab_box pt-8 pointer"
                              onClick={() => {
                                handleChange(5, 0);
                                setSubValue(0);
                              }}
                            >
                              <p
                                className={
                                  "font_14_500 " +
                                  (subValue === 0
                                    ? "color_black"
                                    : "text_color_light")
                                }
                              >
                                Reduce
                              </p>
                            </motion.div>
                            <motion.div
                              // initial={{ opacity: 0 }}
                              // animate={{ opacity: 1 }}
                              transition={{ duration: 1, type: "tween" }}
                              className="sub_tab_box pt-16 pointer"
                              onClick={() => {
                                handleChange(5, 1);
                                setSubValue(1);
                              }}
                            >
                              <p
                                className={
                                  "font_14_500 " +
                                  (subValue === 1
                                    ? "color_black"
                                    : "text_color_light")
                                }
                              >
                                Offset
                              </p>
                            </motion.div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                      <div
                        className="sidebar_tab mt-16"
                        onClick={() => {
                          handleChange(6);
                        }}
                      >
                        {value === 6 ? (
                          <PaperCheck stroke={activeStroke} active={active} />
                        ) : (
                          <PaperCheck
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        {toggleNavbar && (
                          <div>
                            <p className="ml-12 mr-20 color_black">Share</p>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="side_tab_subgroup">
                      <div
                        className="sidebar_tab"
                        onClick={() => {
                          handleChange(7);
                        }}
                      >
                        {value === 7 ? (
                          <EduHat stroke={activeStroke} active={active} />
                        ) : (
                          <EduHat
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        {toggleNavbar && (
                          <div>
                            <p className="ml-12 mr-20 color_black">Academy</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="sidebar_tab mt-16"
                        onClick={() => {
                          handleChange(8);
                        }}
                      >
                        {value === 8 ? (
                          <AddContact stroke={activeStroke} active={active} />
                        ) : (
                          <AddContact
                            stroke={
                              profile.hasBaseCredit ? stroke : disabledStroke
                            }
                            active={!active}
                          />
                        )}
                        <div>
                          {toggleNavbar && (
                            <p className="ml-12 mr-20 color_black">Connect</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="sidebar_tab"
                      onClick={() => {
                        handleChange(9);
                      }}
                    >
                      {value === 9 ? (
                        <AddIcon stroke={activeStroke} active={active} />
                      ) : (
                        <AddIcon
                          stroke={
                            profile.hasBaseCredit ? stroke : disabledStroke
                          }
                          active={!active}
                        />
                      )}
                      {toggleNavbar && (
                        <div>
                          <p className="ml-12 mr-20 color_black "> Store</p>
                        </div>
                      )}
                    </div>
                    <div
                      className="sidebar_tab mt-16"
                      onClick={() => {
                        handleChange(10);
                      }}
                    >
                      <ProfileIcon />
                      {toggleNavbar && (
                        <div>
                          <p className="ml-12 mr-20 color_black ">Profile</p>
                        </div>
                      )}
                    </div>
                    <div
                      className="sidebar_tab mt-16"
                      onClick={() => {
                        handleChange(11);
                      }}
                    >
                      {value === 11 ? (
                        <ControlIcon stroke={activeStroke} active={active} />
                      ) : (
                        <ControlIcon
                          stroke={
                            profile.hasBaseCredit ? stroke : disabledStroke
                          }
                          active={!active}
                        />
                      )}
                      {toggleNavbar && (
                        <div>
                          <p className="ml-12 mr-20 color_black ">Settings</p>
                        </div>
                      )}
                    </div>

                    <div
                      className="sidebar_tab mt-16"
                      onClick={() => {
                        signOut();
                        navigate("/");
                      }}
                    >
                      {value === 12 ? (
                        <LogoutIcon stroke={activeStroke} active={active} />
                      ) : (
                        <LogoutIcon
                          stroke={
                            profile.hasBaseCredit ? stroke : disabledStroke
                          }
                          active={!active}
                        />
                      )}

                      {toggleNavbar && (
                        <div>
                          <p className="ml-12 mr-20 color_black ">Logout</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TabPanel value={value} index={2}>
              {/* <Dashboard /> */}
              <NewDashboard />
            </TabPanel>

            <TabPanel value={value} index={3}>
              <LogDashboard />
            </TabPanel>

            <TabPanel value={value} index={4}>
              <Analytics />
            </TabPanel>

            <TabPanel value={value} index={5}>
              <Act subValue={subValue} setSubValue={setSubValue} />
            </TabPanel>

            <TabPanel value={value} index={6}>
              <Share />
            </TabPanel>

            <TabPanel value={value} index={7}>
              <Academy />
            </TabPanel>
            <TabPanel value={value} index={8}>
              <Connect />
            </TabPanel>
            <TabPanel value={value} index={9}>
              <Store />
            </TabPanel>
            <TabPanel value={value} index={10}>
              <Profile />
            </TabPanel>
            <TabPanel value={value} index={11}>
              <CompanySettings />
            </TabPanel>
            <TabPanel value={value} index={13}>
              <CompanyMap />
            </TabPanel>
          </Box>
        </div>
      ) : (
        <NotFound />
      )}
    </>
  );
}
