import { Avatar } from "@mui/material";
import React from "react";
import { useAuth } from "../../../Utils/Auth";

function ProfileIcon() {
  const { user } = useAuth();
  return (
    <div>
      {user && (
        <Avatar src={user.avatar} className="sidebar_profile">
          {user.firstName}
        </Avatar>
      )}
    </div>
  );
}

export default ProfileIcon;
