import { ArrowDownward } from "@mui/icons-material";
import React from "react";

const ReportComponent = ({ title, year, downloadLink }) => {
  return (
    <div className="share_transparency_report_box_main">
      <div className="share_transparency_report_box_main share_transparency_report_box">
        <div className="d-flex align-items-center">
          <p className="font_18_600">{title}</p>
          <div className="share_transparency_report_yeara_box ml-10">
            <p className="font_16_500">{year}</p>
          </div>
        </div>
        <a href={downloadLink} target="_blank" rel="noopener noreferrer">
          <div className="share_transparency_report_yeara_box">
            <ArrowDownward htmlColor="#3574E0" />
          </div>
        </a>
      </div>
    </div>
  );
};

export default ReportComponent;
