import { Box, Tab, Tabs, Fade, Backdrop, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import TabLabels from "../../../Components/Companydashboard/LogDashboard/Components/TabLabels";
// import LaunchingCard from "../../../Assets/components/LaunchingCard";
import PropTypes from "prop-types";
import ActiveOrg from "./ActiveOrg";
import axios from "../../../Api";
import ButtonComp from "../../../Assets/components/Button";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { modalStyle } from "../../../Utils/CommonFunctions";

const email_regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Organizations = () => {
  const [value, setValue] = useState(0);
  const [companies, setCompanies] = useState([]);

  const getCompanies = async () => {
    await axios({
      url: `/admin/companies`,
      method: "GET",
    })
      .then((res) => {
        setCompanies(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [disabled, setDisable] = useState(false);
  const [name, setname] = useState("");
  const [email, setEmail] = useState("");
  const [orgname, setorgname] = useState("");

  const handleInvite = async () => {
    if (!email_regex.test(email)) {
      return toast.error("Only work email are allowed");
    }
    setDisable(true);
    await axios({
      url: `/admin/invite-org`,
      method: "post",
      data: {
        name,
        email,
        orgname,
      },
    })
      .then((res) => {
        setDisable(false);
        if (res.data.success) {
          setEmail("");
          setorgname("");
          setname("");
          toast.success(res.data.message);
          handleClose();
        }
      })
      .catch((err) => {
        setDisable(false);
        toastResponseError(err);
      });
  };
  return (
    <div className="ml-100 mr-50">
      <div className="d-flex justify-content-between mt-40 mb-24">
        <p className="offsetProjectTitle">Organizations</p>
        <div className="mr-50">
          <ButtonComp status={3} className="">
            ???
          </ButtonComp>
          <ButtonComp status={2} className=" ml-20" onClick={handleOpen}>
            + New Organization
          </ButtonComp>
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="general_root_tabs"
          >
            <Tab
              label={<TabLabels count={companies.length} title={"Active"} />}
              {...a11yProps(0)}
            />
            <Tab
              label={<TabLabels count={0} title={"Inactive"} />}
              {...a11yProps(1)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <div className="mt-72">
            <ActiveOrg companies={companies} />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="mt-50"></div>
        </TabPanel>
      </Box>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">Invite Organization</p>
              </div>
              <div className="row pb-24 border-btm_dc mb-32">
                <div className="col-lg-5 col-md-6 col-12">
                  <div className="d-flex">
                    <div className="input_div flex-grow-1">
                      <label className="subheading_1" htmlFor="newPassword">
                        Name
                      </label>
                      <input
                        type="text"
                        name="fname"
                        className="mt-12 subheading_2"
                        placeholder="Full Name"
                        value={name}
                        onChange={(e) => setname(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6 col-12">
                  <div className="input_div">
                    <label className="subheading_1" htmlFor="newPassword">
                      E-MAIL ADDRESS
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="mt-12 subheading_2"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="input_div">
                <label className="subheading_1" htmlFor="newPassword">
                  Organization Name
                </label>
                <input
                  type="text"
                  className="mt-12 subheading_2"
                  placeholder="Enter Organization Name"
                  value={orgname}
                  onChange={(e) => setorgname(e.target.value)}
                />
              </div>
              <div className="row justify-content-between">
                <div className="col text-end">
                  <ButtonComp
                    status={1}
                    className="lg"
                    disabled={disabled}
                    onClick={handleInvite}
                  >
                    Send Invitation
                  </ButtonComp>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Organizations;
