export const roles = {
  viewer: "viewer",
  editor: "editor",
  admin: "admin",
  consultant: "consultant",
};

/* This code exports a constant object named `VisibleRoles` that maps the role names defined in the
`roles` object to their corresponding display names. This can be used to display user roles in a
more user-friendly way in the UI. */

export const VisibleRoles = {
  viewer: "Team Member (Viewer)",
  editor: "Editor",
  admin: "Admin",
  consultant: "Consultant",
};

export const email_regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

export const invitedUserSingupCode =
  "JzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6ImRrZnNqaGFzamtmZCBranNkYSBrYXNkaGtqYWRoc2Zqa2hhc2Rqa2ZoamFrcyIsImlhdCI6MTUxNjIzOTAyMn0";
