import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  // useRef,
} from "react";
// import Axios from "axios";
import PropTypes from "prop-types";
import axios from "../../Api/index";

import { useNavigate } from "react-router-dom";

const authContext = createContext();

function useProvideAuth() {
  const [auth, setAuth] = useState({ rit: "sdf" });
  const [loadCurrent, setLoadCurrent] = useState(false);
  const [role, setRole] = useState("admin");
  const [user, setUser] = useState(null);
  const [isUser, setIsUser] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isVerified, setIsVerified] = useState(false);
  const [name, setName] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isAdminLoading, setIsAdminLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loadAdminCurrent, setLoadAdminCurrent] = useState(false);
  const history = useNavigate();
  const [notificationsCount, setNotificationsCount] = useState(null);

  const [sessionExpire, setSessionExpire] = useState(false);

  const signOut = async (redirectTo) => {
    await axios
      .get(`/user/auth/logout`)
      .then((res) => {
        if (res.data.success) {
          setIsUser(false);
          setUser(null);
          setProfile(null);
          setName(null);
          setIsVerified(null);
          setRole(null);
          localStorage.clear();
          if (redirectTo) {
            history(`/?redirect=${redirectTo}`);
          } else {
            history("/");
          }
          // cookies.remove('auth_tk');
        }
      })
      .catch(() => {
        history("/");
      });
  };

  const signOutBasic = async () => {
    await axios
      .get(`/user/auth/logout`)
      .then((res) => {
        if (res.data.success) {
          setIsUser(false);
          setUser(null);
          setProfile(null);
          setName(null);
          setIsVerified(null);
          setRole(null);
          localStorage.clear();
          // cookies.remove('auth_tk');
        }
      })
      .catch(() => {});
  };
  async function getUser() {
    setIsLoading(true);
    await axios
      .get("/user/auth/current")
      .then((res) => {
        setUser({
          email: res.data.user.email,
          firstname: res.data.user.firstname,
          lastname: res.data.user.lastname,
          avatar: res.data.user.avatar,
          name: res.data.user.name,
          id: res.data.user._id,
          type: res.data.user.type,
          isNewUser: res.data.user.isNewUser,
          is2FASet: res.data.user.is2fAuth,
          phone: res.data.user.phone,
          companyId: res.data.user.companyId,
          isCompanyCreated: res.data.user.isCompanyCreated,
          isBasePaid: res.data.user.isBasePaid,
          isInvoiceGenerated: res.data.user.isInvoiceGenerated,
          twofactorType: res.data.user.twofactorType,
          bot_avatar_id: res.data.user.bot_avatar_id,
          updatedAt: res.data.user.updatedAt,
          createdAt: res.data.user.createdAt,
          language:res.data.user.language
        });
        setIsAdmin(false);
        setProfile(res.data.user.companyId);
        setRole(res.data.user.type);
        setIsUser(true);
        setName(res.data.user.name);
        setIsLoading(false);
      })
      .catch(() => {
        setIsUser(false);
        setUser(null);
        setIsLoading(false);
      });
  }
  // const navigate = useNavigate();
  async function getAdmin() {
    setIsAdminLoading(true);
    await axios
      .get("/user/auth/admin/current")
      .then(() => {
        setIsAdmin(true);
        setIsAdminLoading(false);
      })
      .catch((error) => {
        if (error && error.response.status === 401) {
          // navigate("/admin/login");
          setIsAdmin(false);
          setIsAdminLoading(false);
        }
      });
  }
  const getNotifications = async () => {
    const response = await axios(
      `${process.env.REACT_APP_baseURL}/api/notification/stats`
    );
    setNotificationsCount(response.data);
  };
  useEffect(() => {
    getUser();
  }, [role, loadCurrent]);
  useEffect(() => {
    getAdmin();
  }, [loadAdminCurrent]);

  return {
    auth,
    setAuth,
    role,
    setRole,
    isUser,
    user,
    setIsUser,
    setUser,
    profile,
    setProfile,
    isVerified,
    signOut,
    setName,
    name,
    isLoading,
    notificationsCount,
    setLoadCurrent,
    loadCurrent,
    setIsVerified,
    getNotifications,
    sessionExpire,
    setSessionExpire,
    getUser,
    signOutBasic,
    setNotificationsCount,
    isAdminLoading,
    isAdmin,
    setLoadAdminCurrent,
    loadAdminCurrent,
    setIsAdmin,
    setIsAdminLoading,
  };
}

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
ProvideAuth.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(authContext);
