import React from "react";
import "./index.css";
import WhiteEarthMediumIcon from "../../icons/WhiteEarthMediumIcon";
import { useAuth } from "../../../Utils/Auth";

const VeritacalLabel = ({ imageRef }) => {
  const { profile } = useAuth();
  return (
    <div className="vertical_carbon_label_root" ref={imageRef}>
      <div className="vertical_carbon_label_top">
        <WhiteEarthMediumIcon />
      </div>
      <div className="vertical_carbon_label_bottom">
        <div>
          <p className="carlabel_vert_extrabold">Carbon</p>
          <p className="carlabel_vert_extrabold">Neutral</p>
        </div>
        <p className="carlabel_vert_normal_italic">
          today.green/{profile.labelId || "00000-000-0000"}
        </p>
      </div>
    </div>
  );
};

export default VeritacalLabel;
