import React from "react";
//import logoImage from '../../../../../Assets/images/dashboard_background_img.png'
import CardProgressBar from "../CardProgressBar";
import { Handle, Position } from "reactflow";
import { useMapStore } from "../../../../../Utils/CompanyMapStore";
import LinearProgressBar from "../../../../../Assets/components/LinearProgrssBar/LinearProgressBar";
function CompanyNode(props) {
  const { totalS1Emission, totalS2Emission, totalS3Emission, budgetData } =
    useMapStore();
  return (
    <div className="w-475px bg_white border_radius_5 pl-24 pt-20 pr-24 pb-20 border-half-black">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <img src={props?.data?.logo} className="w-30px h-30px br_round" />
        </div>
        <div className="d-flex align-items-center ml-10">
          <p className="font_20_600 text_capital_f">
            {props?.data?.companyName}
          </p>
        </div>
      </div>

      <div className="mt-26 mb-24">
        <div>
          <div className="d-flex justify-content-between align-items-center">
            <p className="font_10_500">Total Emissions</p>
            <p className="font_10_600 color_7e">
              {Number(
                totalS1Emission + totalS2Emission + totalS3Emission
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              tCO2e
            </p>
          </div>
          <div className="mt-10">
            <CardProgressBar
              color={1}
              value={
                ((totalS1Emission + totalS2Emission + totalS3Emission) /
                  (totalS1Emission + totalS2Emission + totalS3Emission)) *
                100
              }
            />

            <div></div>
          </div>
        </div>

        {budgetData ? (
          <div className="mt-20">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font_10_500">
                CO2 Budget {new Date().getFullYear()}
              </p>
              <p className="font_10_600 color_7e">
                {Number(budgetData.currentEmission).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                /{" "}
                {Number(budgetData.targetEmission).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                tCO2e
              </p>
            </div>

            <div className="mt-10">
              {/* <CardProgressBar color={4} value={0} /> */}
              <LinearProgressBar
                percent={
                  budgetData
                    ? budgetData.currentEmission > budgetData.targetEmission
                      ? 100
                      : (budgetData.currentEmission * 100) /
                        budgetData.targetEmission
                    : 0
                }
                bg={
                  budgetData?.currentEmission > budgetData?.targetEmission
                    ? "#e35700"
                    : "#00915B"
                }
                barClassName={"flex-1"}
                barWidth={"100%"}
                barHeight={"12px"}
              />
            </div>
          </div>
        ) : null}

        <div className="d-flex align-items-center mt-20 mb-30">
          <div className="flex-grow-1">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font_10_500">S1 & S2</p>
              <p className="font_10_600 color_7e">
                {Number(totalS1Emission + totalS2Emission).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}{" "}
                tCO2e
              </p>
            </div>
            <div className="mt-10">
              <CardProgressBar
                color={2}
                value={
                  ((totalS1Emission + totalS2Emission) /
                    (totalS1Emission + totalS2Emission + totalS3Emission)) *
                  100
                }
              />
            </div>
          </div>

          <div className="flex-grow-1 ml-24">
            <div className="d-flex justify-content-between align-items-center">
              <p className="font_10_500">S3</p>
              <p className="font_10_600 color_7e">
                {Number(totalS3Emission).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                tCO2e
              </p>
            </div>
            <div className="mt-10">
              <CardProgressBar
                color={3}
                value={
                  (totalS3Emission /
                    (totalS1Emission + totalS2Emission + totalS3Emission)) *
                  100
                }
              />
            </div>
          </div>
        </div>
      </div>
      <Handle
        type="source"
        isConnectableStart={false}
        position={Position.Bottom}
        id="a"
      />
    </div>
  );
}

export default CompanyNode;
