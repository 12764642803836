import React from "react";
import CardProgressBar from "../../CardProgressBar";
import { Handle, Position, useReactFlow } from "reactflow";
import { useMapStore } from "../../../../../../Utils/CompanyMapStore";
import "./index.css";
function AccountingItemNode(props) {
  const {
    handleNodeClick,
    totalS1Emission,
    totalS2Emission,
    totalS3Emission,
    activeNodeData,
    addNodeAtLevel1,
    mapNodes,
    // addNodeAtlevel2,
  } = useMapStore();

  const handleMouseDown = (event) => {
    event.stopPropagation();
  };

  const { setCenter } = useReactFlow();

  let firstLevelOptions = [
    "6442af32ca73043a8ec66c78",
    "645337d99d60aa8fc7a24dbe",
    "645337d99d60aa8fc7a24dc2",
    "645337d99d60aa8fc7a24dc4",
    "645337d99d60aa8fc7a24dc3",
    "645337d99d60aa8fc7a24dc5",
  ];

  let secondLevelItems = [
    "6442af32ca73043a8ec66c79",
    "6442af32ca73043a8ec66c7a",
    "645337d99d60aa8fc7a24dbf",
    "645337d99d60aa8fc7a24dc1",
    "6442af32ca73043a8ec66c7b",
    "645337d99d60aa8fc7a24dc0",
  ];

  const handleAddNode = (direction) => {
    let parentNode = direction !== "bottom" ? props.data.parentNode : props.id;
    let level = 1;
    let childNodeIndex = -1;
    // pending
    // alert(props.data.level);
    if (direction === "left" || direction === "right") {
      level = props.data.level;
    }

    let childNode =
      props.data.level === 1 &&
      direction === "top" &&
      secondLevelItems.findIndex(
        (currItemId) => currItemId == props.data.data.itemId
      ) !== -1
        ? props.id
        : "";
    if (childNode !== "") {
      childNodeIndex = mapNodes.findIndex(
        (currNode) => currNode.id == props.id
      );
      if (childNodeIndex !== -1) {
        mapNodes[childNodeIndex].position.y += 400;
        mapNodes[childNodeIndex].data.level = 2;
        level = 1;
      }
    }

    if (direction === "bottom") {
      level = 2;
    }
    // alert(childNodeIndex);
    let xPos =
      childNodeIndex !== -1
        ? props.xPos
        : direction === "left"
        ? props.xPos - 270
        : direction === "right"
        ? props.xPos + 270
        : props.xPos;

    // final
    let yPos =
      childNodeIndex !== -1
        ? props.yPos
        : direction === "bottom"
        ? props.yPos + 400
        : direction === "top"
        ? props.yPos - 400
        : props.yPos;

    // setCenter(yPos - 100, xPos - 200, {
    //   zoom: 0.5,
    //   duration: 800,
    // });
    let parentLogId;
    let parentNodeIndex = mapNodes.findIndex(
      (currNode) => currNode.id == props.data.parentNode
    );
    if (parentNodeIndex != -1) {
      parentLogId = mapNodes[parentNodeIndex].data.data?._id;
    }

    addNodeAtLevel1(
      parentNode,
      childNode,
      xPos,
      yPos,
      level,
      parentLogId,
      childNodeIndex
    );
  };

  return (
    <div
      className="account_item_node_root nodrag"
      onMouseDown={handleMouseDown}
    >
      <div
        className={`bg_white border_radius_5 border-half-black   ${
          activeNodeData._id
            ? activeNodeData._id === props.data.data._id
              ? "border-009-2"
              : "opacity_half"
            : ""
        }`}
      >
        <Handle type="target" position={Position.Top} />
        <div
          onClick={() => {
            handleNodeClick(props.data.data);
            if (props.xPos) {
              setCenter(props.xPos + 250, props.yPos + 200, {
                zoom: 0.9,
                duration: 800,
              });
            }
          }}
          className="w-250px border_radius_5 pl-24 pr-24 pb-36 nodrag"
        >
          <div className="d-flex align-items-center justify-content-between">
            <p className="font_20_600 mt-20">
              {props.data.data?.itemDetails?.name &&
              props.data.data?.itemDetails?.name.length > 0
                ? props.data.data.itemDetails.name.slice(0, 12)
                : ""}{" "}
              {props.data.data?.itemDetails?.name &&
              props.data.data?.itemDetails?.name.length > 12
                ? "..."
                : ""}
            </p>
            {props.data.data?.isActivate ? (
              <div className="circle_14 bg_009 ml-20 mt-20 custom-drag-handle" />
            ) : (
              <div className="circle_14 bg_red ml-20 mt-20" />
            )}
          </div>

          <div className="mt-10">
            <p className="font_10_600 color_7e">
              {props.data.data?.accountingItemName}
            </p>
          </div>

          <div className="d-flex justify-content-between align-items-center mt-34">
            <p className="font_10_500">% Total Emissions</p>

            <p className="font_10_600 color_7e">
              {Number(
                props.data.data?.scopeWiseData?.Scope1 +
                  props.data.data?.scopeWiseData?.Scope2 +
                  props.data.data?.scopeWiseData?.Scope3
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              tCO2e
            </p>
          </div>

          <div className="mt-12">
            <CardProgressBar
              color={1}
              value={
                ((props.data.data?.scopeWiseData?.Scope1 +
                  props.data.data?.scopeWiseData?.Scope2 +
                  props.data.data?.scopeWiseData?.Scope3) /
                  (totalS1Emission + totalS2Emission + totalS3Emission)) *
                100
              }
            />
          </div>

          <div className="d-flex justify-content-between align-items-center mt-34">
            <p className="font_10_500">S1 & S2</p>

            <p className="font_10_600 color_7e">
              {Number(
                props.data.data?.scopeWiseData?.Scope1 +
                  props.data.data?.scopeWiseData?.Scope2
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              tCO2e
            </p>
          </div>

          <div className="mt-12">
            <CardProgressBar
              color={2}
              value={
                ((props.data.data?.scopeWiseData?.Scope1 +
                  props.data.data?.scopeWiseData?.Scope2) /
                  (totalS1Emission + totalS2Emission + totalS3Emission)) *
                100
              }
            />
          </div>

          <div className="d-flex justify-content-between align-items-center mt-34">
            <p className="font_10_500">S3</p>

            <p className="font_10_600 color_7e">
              {Number(props.data.data?.scopeWiseData?.Scope3).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )}{" "}
              tCO2e
            </p>
          </div>

          <div className="mt-12">
            <CardProgressBar
              color={3}
              value={
                (props.data.data?.scopeWiseData?.Scope3 /
                  (totalS1Emission + totalS2Emission + totalS3Emission)) *
                100
              }
            />
          </div>
        </div>
        <Handle
          type="source"
          isConnectableStart={true}
          position={Position.Bottom}
          id="a"
        />
        {/* <Handle type="source" position={Position.Bottom} id="b" style={handleStyle} /> */}
      </div>
      <div className="account_item_node_bg">
        <div className="account_item_node_add_icons">
          {/* left */}

          <div
            onClick={() => {
              // addNodeAtLevel1(props, "left");
              handleAddNode("left");
            }}
            className="d-flex align-items-center justify-content-center pointer border_radius_5 w-30px h-30px bg_009 account_item_node_left_add"
          >
            <i className="bi bi-plus-lg color_white"></i>
          </div>

          {/* right */}
          <div
            onClick={() => {
              handleAddNode("right");
            }}
            className="d-flex align-items-center justify-content-center pointer border_radius_5 w-30px h-30px bg_009 account_item_node_right_add"
          >
            <i className="bi bi-plus-lg color_white"></i>
          </div>

          {/* bottom */}
          {props.data.level === 1 &&
          firstLevelOptions.findIndex(
            (currItemId) => currItemId == props.data.data.itemId
          ) !== -1 ? (
            <div
              onClick={() => {
                // addNodeAtLevel1(props, "bottom");
                handleAddNode("bottom");
              }}
              className="d-flex align-items-center justify-content-center pointer border_radius_5 w-30px h-30px bg_009 account_item_node_bottom_add"
            >
              <i className="bi bi-plus-lg color_white"></i>
            </div>
          ) : null}
          {/* top  */}
          {props.data.level === 1 &&
          secondLevelItems.findIndex(
            (currItemId) => currItemId == props.data.data.itemId
          ) !== -1 ? (
            <div
              onClick={() => {
                // addNodeAtLevel1(props, "top");
                handleAddNode("top");
              }}
              className="d-flex align-items-center justify-content-center pointer border_radius_5 w-30px h-30px bg_009 account_item_node_top_add"
            >
              <i className="bi bi-plus-lg color_white"></i>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default AccountingItemNode;
