import React, { useEffect, useState } from "react";
import TutorialCard from "./Components/TutorialCard";
import "./tutorials.css";
import { TextField, InputAdornment } from "@mui/material";
import VideoCard from "./Components/VideoCard";
import FAQAccordion from "./Components/FAQAccordion";
import { Link } from "react-router-dom";
import axios from "../../../../../Api/index";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useTranslation } from "react-i18next";
//import TutorialCardDetails from './Components/TutorialCardDetails.js'

function Tutorials() {
  const {t} = useTranslation()
  const [topics, setTopics] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const [faqs, setFaqs] = useState([]);
  const getTopics = async () => {
    try {
      await axios.get("/academy/topics").then((response) => {
        if (response.data.success) {
          setTopics([...response.data.data]);
        }
      });
    } catch (error) {
      toastResponseError(error);
    }
  };
  const getTutorials = async () => {
    try {
      await axios.get("/academy/tutorials").then((response) => {
        if (response.data.success) {
          // let x=[...response.data.data,...response.data.data,...response.data.data]
          setTutorials([...response.data.data]);
        }
      });
    } catch (error) {
      toastResponseError(error);
    }
  };
  const getFaqs = async () => {
    try {
      await axios.get("/academy/faqs").then((response) => {
        if (response.data.success) {
          // let x=[...response.data.data,...response.data.data,...response.data.data]
          setFaqs(response.data.data);
        }
      });
    } catch (error) {
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getTopics();
    getTutorials();
    getFaqs();
  }, []);
  return (
    <div className="mt-50">
      <div>
        <p className="subheading_3 text_capital">{t("academy.howToUse")}</p>

        <div className="mt-14">
          <div className="row pr-16">
            {topics.map((currTopic, index) => {
              return (
                <div
                  className="col-3 mt-24"
                  key={currTopic._id + index + currTopic.title}
                >
                  <Link
                    to={`/dashboard?tab=7&acadTab=0&id=${currTopic._id}&step=1`}
                  >
                    <TutorialCard topicDetails={currTopic} />
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {tutorials && tutorials.length > 0 ? (
        <div className="mt-60 pr-16">
          <div className="d-flex justify-content-between align-items-center">
            <p className="subheading_3 text_capital">Tutorial library</p>

            <TextField
              //value={filterProp}
              // onChange={(e) => onFilterChange(e)}
              className="body_text_l"
              id="input-with-icon-textfield"
              placeholder="Search for Keywords"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="right">
                    <i className="bi bi-search"></i>
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  height: 40,
                  width: 250,
                },
              }}
            />
          </div>

          <div className="mt-14">
            <div className="row ">
              {tutorials.map((currTut, index) => {
                return (
                  <div
                    className="col-4 mt-30"
                    key={index + currTut._id + currTut.title}
                  >
                    <Link
                      to={`/dashboard?tab=7&acadTab=0&id=${currTut._id}&step=2`}
                    >
                      <VideoCard tutorialDetail={currTut} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : null}
      <div className="row pb-40">
        <div className="mt-66 col-10">
          <p className="subheading_3 text_capital">{t("academy.faq")}</p>

          <div className="tutorials_accordion_container mt-26">
            {faqs.map((currFaq, index) => {
              return (
                <div
                  key={index + currFaq.question + index}
                  className="border-top_ef mb-12"
                >
                  <FAQAccordion faqDetails={currFaq} faqIndex={index} />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tutorials;
