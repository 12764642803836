import { ArrowBackIosTwoTone } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import Tutorials from "../Tutorials";
import Faqprofile from "../Faqs/Faqprofile";

const Topicprofile = () => {
  const navigate = useNavigate();
  const topicid = getUrlqueries("topicid");
  const [data, setdata] = useState();
  const [tutorials, settutorials] = useState([]);
  const [faqs, setfaqs] = useState([]);
  const getdata = async () => {
    try {
      if (!topicid) return;
      const response = await axios.get(`/admin/academy/topic/${topicid}`);
      if (response.data.success) {
        setdata(response.data.data);
        settutorials(response.data.tutorials);
        setfaqs(response.data.faqs);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };

  useEffect(() => {
    getdata(topicid);
  }, [topicid]);
  if (data)
    return (
      <>
        <div
          className="pointer"
          onClick={() => navigate(`/admin/dashboard?tab=5`)}
        >
          <ArrowBackIosTwoTone /> back
        </div>
        <div className="mt-50">
          <p className="subheading_3 mb-16">
            <img src={data.icon} height="30px" width="30px" />
            &nbsp;
            {data.title}
          </p>
          <img src={data.coverimage} height="150px" width="auto" />

          <p className="mt-16"> {data.description}</p>
          <hr />
          <Tutorials tutorials={tutorials} getdata={getdata} />
          <hr />

          <Faqprofile faqs={faqs} />
        </div>
      </>
    );
};

export default Topicprofile;
