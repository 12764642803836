import React, { useState, useMemo } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import axios from "../../../../Api";
import { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import { useRef } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Utils/Auth";
import { extractTitleFromHTML } from "../../../../Utils/CommonFunctions";
Quill.register("modules/imageResize", ImageResize);

const fontSizeStyle = Quill.import("attributors/style/size");
fontSizeStyle.whitelist = [
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "28px",
  "32px",
  "36px",
  "40px",
  "48px",
  "50px",
  "56px",
  "60px",
  "64px",
  "68px",
  "70px",
  "72px",
  "76px",
  "80px",
  "96px",
  "100px",
];
Quill.register(fontSizeStyle, true);

const CreateGlossary = () => {
  const quillRef = useRef();
  const { setSessionExpire } = useAuth();
  const [allValues, setAllValues] = useState({
    tutorialid: "",
    keyword: "",
    keyword_de: "",
    description: "",
    description_de: "",
  });
  const [description, setDescription] = useState("");
  const [description_de, setDescription_de] = useState("");
  const [tutorials, settutorials] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(`/admin/academy/tutorials`);
      if (response.data.success) {
        settutorials(response.data.data);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const handleCreateGlossary = async (e) => {
    e.preventDefault();
    try {
      const url = gid
        ? `/admin/academy/update-glossary/${gid}`
        : `/admin/academy/create-glossary`;
      const newData = {
        tutorialid: allValues.tutorialid,
        keyword: allValues.keyword,
        description: description,
        keyword_de:allValues.keyword_de,
        description_de:description_de
      };
      const response = await axios.post(url, newData);
      if (response.data.success) {
        if (gid) toast.success("Glossary updated successfully");
        else toast.success("Glossary created successfully");
        navigate("/admin/dashboard?tab=5&activetab=6");
        setAllValues({
          tutorialid: "",
          keyword: "",
          description: "",
          keyword_de:"",
          description_de:""
        });
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["image", "link"],
          [{ size: fontSizeStyle.whitelist }],
          [{ color: [] }, { background: [] }],
          ["clean"],
          ["code-block"],
        ],
        handlers: {
          image: () => {},
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "align",
    "link",
    "image",
    "font",
    "size",
    "color",
    "background",
    "code-block",
  ];
  const getgdata = async () => {
    try {
      if (!gid) return;
      const response = await axios.get(`/admin/academy/glossary/${gid}`);
      if (response.data.success) {
        setAllValues(response.data.data);
        setDescription(response.data.data.description);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const gid = getUrlqueries("gid");
  useEffect(() => {
    getgdata();
  }, [gid]);
  return (
    <div>
      <form onSubmit={handleCreateGlossary}>
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1">Choose Tutorial</label>
          <select
            name="tutorialid"
            value={allValues.tutorialid}
            onChange={changeHandler}
            className="form-control"
            id="exampleFormControlSelect1"
          >
            <option value="">Select </option>;
            <option value="general">General</option>;
            {tutorials.map((v, i) => {
              return (
                <option key={i} value={v._id}>
                  {extractTitleFromHTML(v.title)}
                </option>
              );
            })}
          </select>
        </div>
        <br />

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Keyword in English</label>
          <input
            onChange={changeHandler}
            value={allValues.keyword}
            type="text"
            required
            name="keyword"
            className="form-control"
            placeholder="Enter Title"
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Keyword in German</label>
          <input
            onChange={changeHandler}
            value={allValues.keyword_de}
            type="text"
            required
            name="keyword_de"
            className="form-control"
            placeholder="Enter Title"
          />
        </div>
        <br />

        <div className="form-group">
          <label>Description in English</label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={description}
            onChange={(v) => setDescription(v)}
            modules={modules}
            formats={formats}
            className="blog-editor"
            placeholder="write bullet point..."
          />
        </div>
        <br />

        <div className="form-group">
          <label>Description in German</label>
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={description_de}
            onChange={(v) => setDescription_de(v)}
            modules={modules}
            formats={formats}
            className="blog-editor"
            placeholder="write bullet point..."
          />
        </div>
        <br />

        <div className="form-group">
          <ButtonComp status={1} type="submit">
            {gid ? "Update" : "Create"}
          </ButtonComp>
        </div>
      </form>
    </div>
  );
};

export default CreateGlossary;
