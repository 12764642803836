import React from "react";
import { InlineWidget } from "react-calendly";
import { useAuth } from "../../../../../Utils/Auth";

function ScheduleCall() {
  const { user } = useAuth();

  return (
    <div className="">
      <InlineWidget
        url={process.env.REACT_APP_CALENDLY_URL}
        prefill={{
          email: user.email,
          firstName: user.firstname,
          lastName: user.lastname,
          name: `${user.firstname} ${user.lastname}`,
        }}
        styles={{
          height: "800px",
        }}
      />
    </div>
  );
}

export default ScheduleCall;
