import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../Api";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import PasswordMeter from "../../Components/CommonComponents/PasswordMeter/PasswordMeter";
// import bg from "../../Assets/images/Placeholder_GIF.gif";
import ButtonComp from "../../Assets/components/Button";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";
import CryptoJS from "crypto-js";

export default function ResetPassword() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const tk = useQuery().get("tk");
  const regex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  const history = useNavigate();
  const [password, setPassword] = useState("");
  const [disable, setDisable] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validPassword, setValidPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const txt = e.target.value;
    if (regex.test(txt)) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    setDisable(true);
    if (!validPassword) {
      toast.error(
        "Invalid password, password should include atleast an uppercase letter, a lowercase letter,a number and a special character like @,!,etc. "
      );
      return;
    }
    if (password !== confirmPassword) {
      setDisable(false);
      toast.error("Passwords do not match!");
      return;
    } else {
      await axios({
        url: `/user/auth/reset-password`,
        method: "post",
        data: {
          token: tk.toString(),
          password: CryptoJS.AES.encrypt(
            password,
            "H~jP,/%6R*'Tc|xg"
          ).toString(),
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success("Password changed successfully");
            history("/");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setDisable(false);
        });
    }
  };

  return (
    <div>
      <div className="container-fluid">
        <div className="row register_background auth_background">
          <div
            className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
            style={{
              backgroundImage: `url(${AnimationScreens[7]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <video
              className="reg_vdo"
              src={AnimationVideos[7]}
              autoPlay
              playsInline
              loop
              preload="auto"
              muted
              controls={false}
              poster={AnimationScreens[7]}
            ></video>
          </div>
          <div className="d-flex col-md-5 align-items-center register_info_container">
            <div className="d-flex align-items-start flex-column justify-content-center ml-32 col-md-10 max_width_552">
              <p className="purple_label mb-16">New Password</p>
              <p className="heading_1 mt-16 mb-32 text-start">
                Reset your Password
              </p>
              <div className="reset_pass_input mb-24">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  placeholder="New password"
                  className="form-control auth_input border-bottom  shadow-none"
                  value={password}
                  onChange={(e) => handlePasswordChange(e)}
                />
                <button
                  className="auth_input_pas_btn2"
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </button>
              </div>

              <div className="reset_pass_input mb-16">
                <input
                  type={showPassword2 ? "text" : "password"}
                  id="password"
                  placeholder="Confirm new password"
                  className="form-control auth_input border-bottom  shadow-none"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button
                  className="auth_input_pas_btn2"
                  type="button"
                  onClick={() => setShowPassword2(!showPassword2)}
                >
                  {showPassword2 ? <VisibilityOff /> : <Visibility />}
                </button>
              </div>
              <PasswordMeter password={password} />
              {/* <div className=" mt-40 justify-content-end"> */}
              <ButtonComp
                // className={`fw-600 w-100 mt-40 ${
                //   validPassword
                //     ? "primary_button_lg"
                //     : "secondary_button_inactive"
                // }`}
                status={1}
                onClick={handleOnSubmit}
                disabled={validPassword ? disable : true}
              >
                Save and Go to Login
              </ButtonComp>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
