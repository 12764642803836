import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { getServiceIcon } from "../../../../../Utils/itemIcon";
import { formatNumberIntoReadableFormat } from "../../../../../Utils/CommonFunctions";

const EmissionCategory = ({ data }) => {
  const CustomXAxisTick = ({ x, y, payload }) => {
    // Here, you can customize how the image should be displayed based on the 'payload' or other data
    // const imageUrl = 'path/to/your/image.png';

    return (
      <g transform={`translate(${x},${y})`}>
        <image
          xlinkHref={getServiceIcon(payload.value)}
          x={-10} // Adjust the position of the image if needed
          y={10} // Adjust the position of the image if needed
          width={20} // Set the width of the image
          height={20} // Set the height of the image
        />
      </g>
    );
  };

  const CustomTooltip = ({ payload }) => {
    if (payload && payload[0] && payload[0]?.value) {
      return (
        <div className="d-flex align-items-center custom_tooltip_">
          <div className="d-flex align-items-center mr-16">
            <span className="span_cirlce bg_blue mr-8"></span>
            <p className="tooltip_chart_text">
              {Number(payload[0]?.value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              tons
            </p>
          </div>
        </div>
      );
    }
  };

  return (
    <ResponsiveContainer height={300} width={"100%"}>
      <BarChart
        data={data}
        barGap={"30%"}
        barCategoryGap="38%"
        bar
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 10,
        }}
      >
        <CartesianGrid stroke="#efefef" vertical={false} />

        <Bar dataKey="value" fill="#00915b" radius={[0, 0, 0, 0]} />
        <XAxis
          dataKey="name"
          tick={<CustomXAxisTick />}
          interval={0}
          // tickFormatter={(val) => val[0]}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
        />
        <YAxis
          tickFormatter={(val) => `${formatNumberIntoReadableFormat(val)} t`}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EmissionCategory;
