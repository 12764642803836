import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Tooltip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import axios from "../../../../../../../../Api/index";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../Utils/Auth";
import { getServiceIcon } from "../../../../../../../../Utils/itemIcon";
import Text from "../../../../../../../../Assets/components/Empty/text";
import ReactiveSubscription from "../../../../../../../../Assets/components/ReactiveSubscription/ReactiveSubscription";
// import FeedTableHead from "./FeedTableHead";

const HeadCell = styled(TableCell)(() => ({
  "&:first-of-type": {
    borderRight: "1px solid #E6E6E6",
  },
  "&:nth-of-type(2)": {
    borderRight: "1px solid #E6E6E6",
  },
  border: "1px solid #E6E6E6",
  borderTop: "0",
  fontFamily: '"Inter", sans-serif',
}));
const StyledTableCell = styled(TableCell)(() => ({
  "&:first-of-type": {
    borderRight: "1px solid #E6E6E6",
  },
  "&:nth-of-type(2)": {
    borderRight: "1px solid #E6E6E6",
  },

  border: "1px solid #E6E6E6",
  fontFamily: '"Inter", sans-serif',
}));

const StyledTableRow = styled(TableRow)(() => ({
  // hide last border

  "&:nth-of-type(9)": {
    borderBottom: "1px solid #E6E6E6",
  },
  "&:nth-of-type(10)": {
    fontSize: "14px",
    fontWeight: "600",
  },
}));

const StyledHeadRow = styled(TableRow)(() => ({
  "&:last-child": {
    backgroundColor: "#F9FAFC",
  },
}));

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const gotoPage = (event, newPage) => {
    onPageChange(event, newPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
        className="border_radius_8 p-4px"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {page === props.count ? (
        <IconButton
          sx={{ fontSize: "13px", fontWeight: 400 }}
          onClick={(event) => gotoPage(event, page - 1)}
        >
          {page - 1}
        </IconButton>
      ) : (
        ""
      )}
      <IconButton
        sx={{ fontSize: "13px", fontWeight: 400, color: "black" }}
        onClick={(event) => gotoPage(event, page)}
      >
        {page}
      </IconButton>

      {page < props.count ? (
        <IconButton
          sx={{ fontSize: "13px", fontWeight: 400 }}
          onClick={(event) => gotoPage(event, page + 1)}
        >
          {page + 1}
        </IconButton>
      ) : (
        ""
      )}

      <IconButton>...</IconButton>
      <IconButton
        sx={{ fontSize: "13px", fontWeight: 400 }}
        onClick={(event) => gotoPage(event, props.count)}
      >
        {props.count}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="border_radius_8 p-4px"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}
function FeedTable(props) {
  const [page, setPage] = useState(1);
  const [feedTableData, setFeedTableData] = useState([]);
  const [rowPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const { setSessionExpire, profile } = useAuth();
  const tableHeads = [
    "Subscope",
    "Emission Source",
    "Emission Factor",
    "Activity Data",
    "Emissions",
    "Emission Period",
    "Comments",
    "Creator",
    "Date of Entry",
  ];
  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - feedTableData.length) : 0;

  const handleChangePage = (event, newPage) => {
    if (newPage <= 0 || newPage > totalPages) return;
    setPage(newPage);
  };
  const getMatrixCellData = async () => {
    try {
      await axios
        .get(`/cell/feed?logId=${props.logId}&year=${props.year}&page=${page}`)
        .then((res) => {
          let currFeedTableData = [];
          setTotalPages(res.data.totalPages);
          res.data.cells.forEach((currCell) => {
            currCell.particulars?.forEach((obj) => {
              obj.amounts?.forEach((amount) => {
                currFeedTableData.push({
                  subScope: currCell.subScope,
                  comment: amount.comment,
                  user: currCell.userId,
                  profileUrl: currCell.userId?.avatar,
                  activityData: obj.activity,
                  totalAmount: amount.amount,
                  emission: amount.emission,
                  scopeClass: currCell.scopeClass,
                  subClass: obj.subClass,
                  calcualtion: obj.calculationMethod,
                  type: obj.type,
                  subType: obj.subType,
                  unit: obj.unit,
                  period: currCell.month + "." + currCell.year,
                  date: new Date(currCell.updatedAt).toLocaleDateString(
                    "en-GB"
                  ),
                });
              });
            });
          });
          setFeedTableData(currFeedTableData);
        });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    if (props.logId.length > 0 && props.year.length > 0) {
      getMatrixCellData();
    }
  }, [props.year, page, props.isCrateCellUpdate]);
  return (
    <>
      <ReactiveSubscription isPause={props.isPaused} />
      {profile.hasBaseCredit && !props.isPaused && (
        <>
          <div className="matrix_table_root feeed_table_root">
            <TableContainer component={Paper} className="feed_table_overflow">
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <StyledHeadRow>
                    {tableHeads.map((item, i) => {
                      return i == 0 ? (
                        <HeadCell
                          sx={{ borderLeft: "0", width: "10%" }}
                          key={i}
                          className="text_capital fw-600"
                        >
                          {item}
                        </HeadCell>
                      ) : i == tableHeads.length - 1 ? (
                        <HeadCell
                          sx={{ borderRight: "0", width: "11%" }}
                          align="left"
                          key={i}
                          className="text_capital fw-600"
                        >
                          {/* <FeedTableHead name={item} /> */}
                          {item}
                        </HeadCell>
                      ) : (
                        <HeadCell
                          align="left"
                          key={i}
                          className="text_capital fw-600"
                          sx={{
                            width:
                              item === "Emission Factor"
                                ? "18%"
                                : item === "Emission Source"
                                ? "10%"
                                : item === "Activity Data"
                                ? "7%"
                                : item === "Emissions"
                                ? "8%"
                                : item === "Emission Period"
                                ? "9%"
                                : item === "Comments"
                                ? "18%"
                                : item === "Creator"
                                ? "9%"
                                : "",
                          }}
                        >
                          {item}
                        </HeadCell>
                      );
                    })}
                  </StyledHeadRow>
                </TableHead>
                <TableBody>
                  {feedTableData && feedTableData.length > 0 ? (
                    feedTableData.map((row, index) => (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            borderBottom: 0,
                          },
                        }}
                        key={index}
                      >
                        <StyledTableCell
                          sx={{ borderLeft: "0",  }}
                          component="th"
                          className="text-capitalize"
                        >
                          <Tooltip title={row.subScope} placement="left-start">
                            <img
                              src={getServiceIcon(row.subScope)}
                              className="icon_sizing"
                            />
                          </Tooltip>
                          &nbsp; {row.subScope}
                        </StyledTableCell>
                        <StyledTableCell
                          className="text-capitalize"
                          align="left"
                        >
                          {row.subClass}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {(row.calcualtion ? row.calcualtion + " / " : "") +
                            (row.type ? row.type + " / " : "") +
                            (row.subType ? row.subType + " / " : "") +
                            (row.unit ? row.unit : "")}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.totalAmount &&
                            Number(row.totalAmount).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }) +
                              " " +
                              row.unit}
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {Number(row.emission).toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}{" "}
                          tCO2e
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          {row.period}
                        </StyledTableCell>
                        <StyledTableCell>
                          {row.comment && row.comment.length > 50
                            ? row.comment.substring(0, 50) + "..."
                            : row.comment}
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="d-flex align-items-center">
                            <img
                              src={row.user.avatar}
                              alt={row.user.avatar}
                              className="img_25"
                            />
                            <p className="font_14_400 ml-8">
                              {row.user.firstname + " " + row.user.lastname}
                            </p>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell sx={{ borderRight: "0" }}>
                          {row.date}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": { borderBottom: 0 },
                      }}
                    >
                      <StyledTableCell
                        sx={{ borderLeft: "0" }}
                        component="th"
                        align="center"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="none"
                          viewBox="0 0 26 26"
                        >
                          <circle
                            cx="13.086"
                            cy="12.984"
                            r="12.5"
                            fill="#F7F7F7"
                          ></circle>
                        </svg>
                      </StyledTableCell>
                      <StyledTableCell className="text-capitalize" align="left">
                        <Text />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Text />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Text />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Text />
                      </StyledTableCell>
                      <StyledTableCell align="left">
                        <Text />
                      </StyledTableCell>
                      <StyledTableCell sx={{ borderRight: "0" }} align="left">
                        <Text />
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {totalPages && totalPages > 1 ? (
            <div className="d-flex justify-content-end">
              <TablePaginationActions
                count={totalPages}
                rowsPerPage={rowPerPage}
                page={page}
                onPageChange={handleChangePage}
              />
            </div>
          ) : null}
        </>
      )}
    </>
  );
}

export default FeedTable;
