import React from "react";
import "./analyticsTable.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DonwIntradayIcon from "../../../../../../../../../../Assets/icons/DownIntraday";
import UpIntradayIcon from "../../../../../../../../../../Assets/icons/UpIntradayIcon";
import { getServiceIcon } from "../../../../../../../../../../Utils/itemIcon";
import Paper from "@mui/material/Paper";
import Name from "../../../../../../../../../../Assets/components/Empty/name";
import Text from "../../../../../../../../../../Assets/components/Empty/text";

const HeadCell = styled(TableCell)(() => ({
  "&:last-child": {
    borderRadius: "0px 8px 0px 0px",
  },
  border: "1px solid #E6E6E6",
  borderTop: "0px !important",
  borderLeft: "0px !important",
  fontFamily: '"Inter", sans-serif',
}));
const StyledTableCell = styled(TableCell)(() => ({
  border: "1px solid #E6E6E6",
  fontFamily: '"Inter", sans-serif',
}));

const StyledTableRow = styled(TableRow)(() => ({}));

const StyledHeadRow = styled(TableRow)(() => ({
  "&:last-child": {
    backgroundColor: "#F9FAFC",
  },
}));

function AnalyticsTable(props) {
  const tableHeads = [
    "SubScope Name & Number",
    "Emission",
    "% Of Total Emission",
    "% Of Scope Total Emission",
    "Comparison (YOY)",
  ];
  return (
    <div className="analyticsTable_container">
      <TableContainer component={Paper}>
        <Table
          className="border_radius_8"
          sx={{ minWidth: 700 }}
          aria-label="customized table"
        >
          <TableHead>
            <StyledHeadRow>
              {tableHeads.map((item, i) => {
                return (
                  <HeadCell
                    className={
                      i == tableHeads.length - 1
                        ? "border_right_0 text-uppercase"
                        : " text-uppercase"
                    }
                    sx={{ width: "27%" }}
                    align="left"
                    key={i}
                  >
                    {item}
                  </HeadCell>
                );
              })}
            </StyledHeadRow>
          </TableHead>
          <TableBody className="body_text_m">
            {props.data && props.data.length > 0 ? (
              props.data
                .sort((a, b) => a.exact - b.exact)
                .map((row, index) => (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        borderBottom: 0,
                      },
                      width: "25%",
                    }}
                  >
                    <StyledTableCell align="left" className="border_left_0">
                      <div className="d-flex">
                        <img
                          src={getServiceIcon(row.Scope)}
                          className="icon_sizing"
                        />
                        <p className="ml-6 text-capitalize">
                          {row.Scope}({row.exact})
                        </p>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell align="left" className=" text-capitalize">
                      {Number(row.totalEmission).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                      tCO₂e
                    </StyledTableCell>
                    <StyledTableCell align="left" className=" text-capitalize">
                      {((Number(row.totalEmission) * 100) / props.totalEmission)
                        .toFixed(2)
                        .toLocaleString()}{" "}
                      %
                    </StyledTableCell>
                    <StyledTableCell align="left" className=" text-capitalize">
                      {((Number(row.totalEmission) * 100) / props.scopeEmission)
                        .toFixed(2)
                        .toLocaleString()}
                      %
                    </StyledTableCell>
                    <StyledTableCell
                      className="border_right_0  text-capitalize"
                      align="left"
                    >
                      <p>
                        <span className="mr-16">
                          {Number(row.comparison) < 0 ? (
                            <DonwIntradayIcon />
                          ) : (
                            <UpIntradayIcon />
                          )}
                        </span>
                        <span
                          className={
                            Number(row.comparison) < 0
                              ? "color_green"
                              : "color_red"
                          }
                        >
                          {(
                            Number(row.comparison ? row.comparison : 0) /
                            (Number(row.totalEmission) * 100)
                          )
                            .toFixed(2)
                            .toLocaleString()}{" "}
                          %
                        </span>
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
            ) : (
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": {
                    borderBottom: 0,
                  },
                }}
              >
                <StyledTableCell align="left" className="border_left_0">
                  <Name />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text />
                </StyledTableCell>
                <StyledTableCell align="left">
                  <Text />
                </StyledTableCell>
                <StyledTableCell className="border_right_0" align="left">
                  <Text />
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default AnalyticsTable;
