// const { toast } = require("react-hot-toast");
import { toast } from "react-hot-toast";

export const toastResponseError = (error, setSessionExpire) => {
  if (error.response && error.response.status === 401 && setSessionExpire) {
    return setSessionExpire(true);
  } else if (error.response && error.response.data.error) {
    return toast.error(error.response.data.error);
  } else if (error.response && error.response.data.message) {
    return toast.error(error.response.data.message);
  }
};

export const toastResponseErrorWithoutLogout = (error) => {
  if (error.response && error.response.status === 401) {
    return toast.error(error.response.data.error);
  } else if (error.response && error.response.data.error) {
    return toast.error(error.response.data.error);
  } else if (error.response && error.response.data.message) {
    return toast.error(error.response.data.message);
  }
};
