import React from "react";
import CameraAddIcon from "../../../../Assets/icons/CameraAddIcon";

const ImpressionImagePlaceholder = ({ imgSource }) => {
  return imgSource ? (
    <img
      src={imgSource}
      className="impresssion_image_placeholder"
      alt="company"
    />
  ) : (
    <div className="impresssion_image_placeholder">
      <div className="share_trans_camera_cover">
        <CameraAddIcon />
      </div>
    </div>
  );
};

export default ImpressionImagePlaceholder;
