import React from "react";
import LaunchingCard from "../../../../../Assets/components/LaunchingCard";
import { notifedFeatures } from "../../../../../Utils/CommonFunctions";

const ScenarioBuilder = () => {
  return (
    <div className="main_box mt-40">
      <LaunchingCard
        title={"Understand emissions before they exist"}
        chip={"Launching in December"}
        subtitle={
          "Build unlimited scenarios to make informed decisions about supply chains and predict your project's environmental and financial impact."
        }
        isShowWiki={true}
        feature={notifedFeatures.analyticsScenario}
      />
    </div>
  );
};

export default ScenarioBuilder;
