import React from "react";

const DownloadIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="14"
      fill="none"
      viewBox="0 0 9 14"
    >
      <g fill={cc ? cc : "#fff"} fillRule="evenodd" clipRule="evenodd">
        <path d="M7.951 6.43a.5.5 0 01-.024.708l-3.469 3.238a.5.5 0 01-.682 0L.306 7.138a.5.5 0 01.683-.731L3.617 8.86V.883a.5.5 0 011 0V8.86l2.628-2.453a.5.5 0 01.706.024z"></path>
        <path d="M.023 13.281a.5.5 0 01.5-.5h7.188a.5.5 0 010 1H.523a.5.5 0 01-.5-.5z"></path>
      </g>
    </svg>
  );
};

export default DownloadIcon;
