import React from "react";

function EduHat(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="15"
        fill="none"
        viewBox="0 0 15 13"
      >
        <path
          fill={props.stroke}
          fillRule="evenodd"
          d="M7.263.133a.818.818 0 01.732 0l6.545 3.273a.818.818 0 010 1.463L7.995 8.142a.818.818 0 01-.732 0L.718 4.869a.818.818 0 010-1.463L7.263.133zm-4.35 4.005L7.63 6.495l4.716-2.357L7.629 1.78 2.913 4.138z"
          clipRule="evenodd"
        ></path>
        <path
          fill={props.stroke}
          fillRule="evenodd"
          d="M14.178 3.32c.451 0 .818.367.818.818v3.273a.818.818 0 01-1.637 0V4.138c0-.451.367-.818.819-.818zM3.537 4.953c.452 0 .818.366.818.818v3.926c.04.066.131.187.317.323.376.276 1.211.66 2.956.66 1.744 0 2.58-.384 2.956-.66.185-.136.276-.257.316-.323V5.771a.818.818 0 111.636 0v4.091a.82.82 0 01-.02.181l-.798-.181c.798.181.798.182.798.183v.002l-.002.003-.001.008-.005.018a1.126 1.126 0 01-.05.153 2.028 2.028 0 01-.156.313 2.799 2.799 0 01-.75.798c-.74.542-1.95.976-3.924.976-1.975 0-3.185-.434-3.924-.976a2.798 2.798 0 01-.75-.798 2.023 2.023 0 01-.195-.424 1.202 1.202 0 01-.012-.043l-.004-.017-.002-.008-.001-.003v-.002s0-.002.797-.183l-.798.181a.818.818 0 01-.02-.181v-4.09c0-.453.366-.819.818-.819z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default EduHat;
