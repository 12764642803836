import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import startingPointImg from "../../../../../../Assets/images/Starting Point.png";
import destinationIcon from "../../../../../../Assets/images/Destination.png";
import ButtonComp from "../../../../../../Assets/components/Button";
import stopOverImg from "../../../../../../Assets/images/Stopover.png";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import toast from "react-hot-toast";

const TravelMap = ({ handleAmountChange, index, unit }) => {
  const [origin, setOrigin] = useState(null);
  const [destination, setDestination] = useState(null);
  const [wayPointComp, setWayPointComp] = useState([]);
  const [wayPointsCount, setWayPointsCount] = useState(0);
  const [wayPoints, setWayPoints] = useState([]);
  const [path, setPath] = useState(null);
  const [travelMode] = useState("DRIVING");
  const [startDestination, setStartDestination] = useState("");
  const [endDestination, setEndDestination] = useState("");
  const [wayPointsLabel, setWayPointsLabel] = useState([]);
  const [pathChanged, setPathChanged] = useState(false);

  const directionsCallback = (response) => {
    if (!pathChanged) return;
    setPathChanged(false);
    if (response !== null) {
      if (response.status === "OK") {
        setPath(response);
        return;
      }
    }
  };

  const placeChange = (place, index) => {
    if (place == null) return;
    let curr = [...wayPoints];
    curr[index] = place;
    setWayPoints(curr);
  };

  const handleWayPointChange = () => {
    let curr = [...wayPoints];
    curr[wayPointsCount] = null;
    let y = curr.map((currPoint, index) => {
      return (
        <div key={index} className="mt-20">
          <div className="d-flex align-items-center mb-10">
            <img src={stopOverImg} height={15} width={15} />
            <p className="subheading_1 ml-4">Stopover</p>
          </div>
          <GooglePlacesAutocomplete
            debounce={800}
            apiKey={process.env.REACT_APP_GOOGLE_MAP}
            selectProps={{
              currPoint,
              onChange: (e) => placeChange(e, index),
              placeholder: "Add wayPoint",
              styles: {
                container: (p) => ({
                  ...p,
                  boxSizing: `border-box`,
                  border: `1px solid #efefef`,
                  borderRadius: `5px`,
                  fontSize: `15px`,
                  fontWeight: "400",
                  boxShadow: "none",
                  fontFamily: '"Inter", sans-serif',
                  outline: `none`,
                }),
                indicatorsContainer: (p) => ({
                  ...p,
                  display: "none",
                }),
                input: (p) => ({
                  ...p,
                  // padding: "8px",
                }),
              },
            }}
          />
        </div>
      );
    });
    setWayPointComp(y);
    setWayPointsCount(wayPointsCount + 1);
    setWayPoints(curr);
  };

  const getDirectionAndDistance = () => {
    if (origin && destination && origin.label && destination.label) {
      setPathChanged(true);
      setStartDestination(origin.label);
      setEndDestination(destination.label);
      let emptyField = false;
      let currLabels = [];
      wayPoints.forEach((point) => {
        if (point != null) {
          currLabels.push({
            location: point.label,
            stopover: true,
          });
        } else {
          emptyField = true;
          //  toast.error("please fill the stop over point");
          return;
        }
      });
      if (emptyField) return;
      setWayPointsLabel(currLabels);
      try {
        //  var service = new window.google.maps.DistanceMatrixService();
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: origin.label,
            destination: destination.label,
            waypoints: currLabels.map((waypoint) => ({
              location: waypoint.location,
            })),
            travelMode: "DRIVING",
          },
          calculateFinalDistance
        );
      } catch (err) {
        toast.error("error occurred while calculating distance");
      }
    }
  };

  function calculateFinalDistance(response, status) {
    if (status === "OK") {
      let currDistSum = 0;
      response.routes[0].legs.forEach((route) => {
        currDistSum += route.distance.value;
      });
      if (unit === "miles") {
        handleAmountChange(index, currDistSum * 0.000621371192); //miles
      } else {
        handleAmountChange(index, currDistSum / 1000); //km
      }
    }
  }

  useEffect(() => {
    getDirectionAndDistance();
  }, [origin, destination, wayPoints]);

  return (
    <div>
      <div>
        <div className="d-flex align-items-center mb-10">
          <img src={startingPointImg} height={15} width={15} />
          <p className="subheading_1 ml-4">Starting Point</p>
        </div>
        <GooglePlacesAutocomplete
          debounce={800}
          apiKey={process.env.REACT_APP_GOOGLE_MAP}
          selectProps={{
            origin,
            onChange: setOrigin,
            placeholder: "Starting Point Address",
            styles: {
              container: (p) => ({
                ...p,
                boxSizing: `border-box`,
                border: `1px solid #efefef`,
                borderRadius: `5px`,
                fontSize: `15px`,
                fontWeight: "400",
                boxShadow: "none",
                fontFamily: '"Inter", sans-serif',
                outline: `none`,
              }),
              indicatorsContainer: (p) => ({
                ...p,
                display: "none",
              }),
              input: (p) => ({
                ...p,
                // padding: "8px",
              }),
            },
          }}
        />
      </div>
      <div>{wayPointComp.map((stopOver) => stopOver)}</div>
      <div className="mt-16">
        <div className="d-flex align-items-center mb-10">
          <img src={destinationIcon} height={15} width={15} />
          <p className="subheading_1 ml-4">Destination</p>
        </div>

        <GooglePlacesAutocomplete
          placeholder="Destination Address"
          debounce={800}
          apiKey={process.env.REACT_APP_GOOGLE_MAP}
          selectProps={{
            destination,
            onChange: setDestination,
            placeholder: "Destination Address",
            styles: {
              container: (p) => ({
                ...p,
                boxSizing: `border-box`,
                border: `1px solid #efefef`,
                borderRadius: `5px`,
                fontSize: `15px`,
                fontWeight: "400",
                boxShadow: "none",
                fontFamily: '"Inter", sans-serif',
                outline: `none`,
              }),
              indicatorsContainer: (p) => ({
                ...p,
                display: "none",
              }),
              input: (p) => ({
                ...p,
                // padding: "8px",
              }),
            },
          }}
        />
      </div>
      <ButtonComp
        status={3}
        onClick={() => handleWayPointChange()}
        className="text-black p-30x-10y border_radius_5 mt-20"
      >
        <i className="bi bi-plus"></i> Add Stop
      </ButtonComp>
      <div className="mt-20">
        <GoogleMap
          mapContainerStyle={{
            height: "300px",
            width: "100%",
            borderRadius: "10px",
          }}
          zoom={2}
          center={{
            lat: 0,
            lng: -180,
          }}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
            fullscreenControl: false,
          }}
        >
          {endDestination !== "" && startDestination !== "" && (
            <DirectionsService
              // required
              options={{
                destination: endDestination,
                origin: startDestination,
                travelMode: travelMode,
                waypoints: wayPointsLabel,
              }}
              // required
              callback={directionsCallback}
            />
          )}

          {path !== null && (
            <DirectionsRenderer
              // required
              options={{
                directions: path,
              }}
            />
          )}
        </GoogleMap>
      </div>
    </div>
  );
};

export default TravelMap;
