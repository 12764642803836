import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import {
  formatNumberIntoReadableFormat,
  localNumber,
  monthNames,
} from "../../../../Utils/CommonFunctions";

const CustomTooltip = ({ active, data, barActiveIndex }) => {
  const [scopeObj, setScopeObj] = useState(null);

  useEffect(() => {
    if (
      data &&
      (barActiveIndex || barActiveIndex === 0) &&
      data[barActiveIndex]
    ) {
      const obj = {};
      let temp = Object.keys(data[barActiveIndex]);
      temp.forEach((item) => {
        if (item != "month") {
          obj[item] = data[barActiveIndex][item];
        }
      });
      setScopeObj(obj);
    }
  }, [data, barActiveIndex, active]);

  if (
    active &&
    data &&
    data.length &&
    barActiveIndex != null &&
    scopeObj !== null
  ) {
    return (
      <div className="d-flex align-items-center custom_tooltip_">
        {Object.entries(scopeObj).map(([key, value], i) => {
          if (key !== "year") {
            return (
              <div className="d-flex align-items-center mr-16" key={i}>
                {key === "Scope 1" ? (
                  <span className="span_cirlce bg_soft_blue_bc mr-8"></span>
                ) : key === "Scope 2" ? (
                  <span className="span_cirlce bg_scope2 mr-8"></span>
                ) : (
                  key === "Scope 3" && (
                    <span className="span_cirlce bg_scope3 mr-8"></span>
                  )
                )}
                <p className="tooltip_chart_text">
                  {localNumber(value)}{" "}
                  t
                </p>
              </div>
            );
          }
        })}
      </div>
    );
  }
};

const ScopeOverview = ({ data, isMonthWise }) => {
  const [barActiveIndex, setBarActiveIndex] = useState(null);
  const onMouseMove = (state) => {
    if (state.isTooltipActive) {
      setBarActiveIndex(state.activeTooltipIndex);
    } else setBarActiveIndex(null);
  };

  // c

  return (
    <ResponsiveContainer height={300} width={"100%"}>
      <BarChart
        data={data}
        barGap={"30%"}
        barCategoryGap="30%"
        onMouseMove={onMouseMove}
        bar
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <CartesianGrid stroke="#efefef" vertical={false} />
        <XAxis
          tickFormatter={(val, i) => (isMonthWise ? monthNames[i] : val)}
          fontSize={12}
          fontFamily={`"Inter", sans-serif`}
          fontWeight={400}
          tickLine={{ display: "none" }}
          interval={0}
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
          axisLine={{ strokeWidth: 1, stroke: "#efefef" }}
          dataKey={"year"}
        />
        <YAxis
          tickFormatter={(val) => `${formatNumberIntoReadableFormat(val)} t`}
          fontSize={12}
          axisLine={false}
          tickLine={false}
          fontFamily={`"Inter", sans-serif`}
          fontWeight={400}
          style={{
            fontFamily: `"Inter", sans-serif`,
          }}
          // width={150}
          dx={-33}
        />
        <Tooltip
          content={
            <CustomTooltip data={data} barActiveIndex={barActiveIndex} />
          }
          cursor={{ fill: "transparent" }}
        />
        {/* <Legend /> */}
        <Bar dataKey="Scope 1" stackId="a" fill="#BCE0FF" barSize={20} />
        <Bar dataKey="Scope 2" stackId="a" fill="#79C1FF" barSize={20} />
        <Bar
          dataKey="Scope 3"
          stackId="a"
          fill="#FFCB11"
          radius={[0, 0, 0, 0]}
          barSize={20}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ScopeOverview;
