import React, { useEffect, useState } from "react";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Collapse, Fade, IconButton, Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import nexDate from "../../../../Utils/nexDate";
import { getServiceIcon } from "../../../../Utils/itemIcon";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { StyledTableCell, StyledTableRow } from "../../../../Utils/TableComp";
import { Table, TableBody, TableContainer, TableHead } from "@mui/material";
import { HeadCell, StyledHeadRow } from "../../../../Utils/TableComp";

import LogTableProgressBar from "../../LogDashboard/Components/LogTable/Components/LogTableProgressBar";
import { daysDiffFromCurrent } from "../../../../Utils/DayDifference";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
import axios from "../../../../Api";
import ButtonComp from "../../../../Assets/components/Button";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="right-start"
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 600 }}
    {...props}
    classes={{ popper: className }}
    followCursor
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    backgroundColor: "transparent",
  },
}));
const LogDataTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="right"
    TransitionProps={{ timeout: 600 }}
    TransitionComponent={Fade}
    {...props}
    classes={{ popper: className }}
    followCursor
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#ffffff",
    borderRadius: "46px",
    maxWidth: 250,
    padding: "8px 20px",
    filter: "drop-shadow(0px 3px 16px rgba(0, 0, 0, 0.059))",
    border: "1px solid #EFEFEF",
  },
}));

const Row = ({ setStep, log }) => {
  const [open, setOpen] = useState(false);
  const { setSessionExpire } = useAuth();
  const navigate = useNavigate();
  const [finalCharts, setfinalcharts] = useState([]);
  const [finalOffsets, setfinalOffsets] = useState([]);
  const [finalDates, setfinalDates] = useState([]);
  const [OffsetDetails2, setOffsetDetails2] = useState([]);
  const [offsetfeed, setoffsetfeed] = useState(null);
  const getTotalEmission = (loggedMonths) => {
    let sum = 0;
    if (loggedMonths && loggedMonths.length > 0) {
      loggedMonths.map((log) => {
        sum += log.emission;
      });
    }
    return sum;
  };
  const getTotalOffsetEmsission = (loggedMonths) => {
    let sum = 0;
    if (loggedMonths && loggedMonths.length > 0) {
      loggedMonths.map((log) => {
        if (log.offset && log.offset === true) sum += log.emission;
      });
    }
    return sum;
  };

  const gethistorydata = async () => {
    try {
      const response = await axios.get(
        `/project-cart/offset-feed?log=${log._id}`
      );
      if (response.data.success) {
        setoffsetfeed(response.data.data);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    let mainOffsets = log.offsetDetails;
    mainOffsets = mainOffsets.filter((obj) => obj.type !== 2 && obj.isOffset);
    if (mainOffsets.length >= 2) {
      mainOffsets = [
        mainOffsets[mainOffsets.length - 2],
        mainOffsets[mainOffsets.length - 1],
      ];
    }

    mainOffsets[0].offsetPer =
      log.optionNumber === 2 &&
      getTotalEmission(mainOffsets[0].loggedMonthEmissions) === 0 &&
      log.isFirstOffset
        ? 1
        : getTotalEmission(mainOffsets[0].loggedMonthEmissions) === 0
        ? 0
        : (getTotalOffsetEmsission(mainOffsets[0].loggedMonthEmissions) /
            getTotalEmission(mainOffsets[0].loggedMonthEmissions)) *
          100;

    if (mainOffsets[1]) {
      mainOffsets[1].offsetPer =
        getTotalEmission(mainOffsets[1].loggedMonthEmissions) === 0
          ? 0
          : (getTotalOffsetEmsission(mainOffsets[1].loggedMonthEmissions) /
              getTotalEmission(mainOffsets[1].loggedMonthEmissions)) *
            100;
    }

    let offsetLog1 =
      mainOffsets[0] &&
      log.offsetDetails.find(
        (log) => log.uniqueId === mainOffsets[0].uniqueId && log.type === 2
      );
    let offsetLog2 =
      mainOffsets[1] &&
      log.offsetDetails.find(
        (log) => log.uniqueId === mainOffsets[1].uniqueId && log.type === 2
      );
    let arr = [offsetLog1, offsetLog2];
    setOffsetDetails2(arr);
    setfinalOffsets(mainOffsets);
  }, []);
  let firstOffset;
  let firstChartLog = {};
  let firstChartCarbon = {};

  let secondOffset = null;
  let secondCLog = {};
  let secondCCarb = {};

  let thirdChartLog = {};
  let thirdChartCarbon = {};

  useEffect(() => {
    let mainOffsets = log.offsetDetails;
    mainOffsets = mainOffsets.filter((obj) => obj.type !== 2);

    if (mainOffsets.length >= 2) {
      mainOffsets = [
        mainOffsets[mainOffsets.length - 2],
        mainOffsets[mainOffsets.length - 1],
      ];
    }
    if (open) {
      if (log) {
        gethistorydata();
      }
      let dates = [];
      let charts = [];
      // dates code
      dates.push(mainOffsets[0].startDate); // date 1
      dates.push(nexDate(mainOffsets[0].endDate)); // date 2
      firstOffset = log.offsetDetails.find(
        (log) => log.uniqueId === mainOffsets[0].uniqueId && log.type === 2
      );
      // -------------- first chart log start----------------
      (firstChartLog.pos = 1),
        (firstChartLog.total =
          mainOffsets[0].loggedMonthEmissions.length === 0
            ? 1
            : (mainOffsets[0].loggedMonthEmissions.length * 100) /
              mainOffsets[0].totalMonths);
      (firstChartLog.visible = true),
        (firstChartLog.date = [
          mainOffsets[0].startDate,
          mainOffsets[0].endDate,
        ]);
      firstChartLog.lastLoggedAt =
        mainOffsets[0].loggedMonthEmissions[
          mainOffsets[0].loggedMonthEmissions.length - 1
        ];
      // -------------- first chart log end------------------------------------------------------------

      // -------------- first chart Carbon start-------------------------------------------------

      if (firstOffset && log.optionNumber === 2) {
        (firstChartCarbon.pos = 1),
          (firstChartCarbon.visible = true),
          (firstChartCarbon.total =
            getTotalEmission(mainOffsets[0].loggedMonthEmissions) === 0
              ? 1
              : (getTotalOffsetEmsission(mainOffsets[0].loggedMonthEmissions) *
                  100) /
                getTotalEmission(mainOffsets[0].loggedMonthEmissions));
        firstChartCarbon.date = [firstOffset.startDate, firstOffset.endDate];
      } else {
        firstChartCarbon.visible = false;
      }
      // -------------- first chart Carbon end-------------------------------------------------

      charts.push({ log: firstChartLog, carbon: firstChartCarbon }); // 1 chart
      // -------------- first chart end-------------------------------------------------

      // if (firstOffset) mainOffsets.push(firstOffset);

      if (firstOffset && log.optionNumber === 1) {
        dates.push(nexDate(firstOffset.endDate)); // 3 date
      } else if (mainOffsets.length > 1) {
        dates.push(nexDate(mainOffsets[1].endDate)); // 3 date
      }

      if (mainOffsets[1]) {
        secondOffset = log.offsetDetails.find(
          (log) => log.uniqueId === mainOffsets[1].uniqueId && log.type === 2
        );
        if (secondOffset) mainOffsets.push(secondOffset);
        if (secondOffset && log.optionNumber === 1) {
          dates.push(nexDate(secondOffset.endDate)); // 4th date
        }
        (secondCLog.pos = 2),
          (secondCLog.visible = true),
          (secondCLog.total =
            (mainOffsets[1].loggedMonthEmissions.length * 100) /
            mainOffsets[1].totalMonths);
        secondCLog.date = [mainOffsets[1].startDate, mainOffsets[1].endDate];
        secondCLog.lastLoggedAt =
          mainOffsets[1].loggedMonthEmissions[
            mainOffsets[1].loggedMonthEmissions.length - 1
          ];
      } else {
        secondCLog.visible = false;
      }
      if (firstOffset && log.optionNumber === 1) {
        (secondCCarb.pos = 2),
          (secondCCarb.visible = true),
          (secondCCarb.total =
            (getTotalOffsetEmsission(mainOffsets[0].loggedMonthEmissions) /
              getTotalEmission(mainOffsets[0].loggedMonthEmissions)) *
            100);
        secondCCarb.date = [firstOffset.startDate, firstOffset.endDate];
      } else if (secondOffset && log.optionNumber === 2) {
        (secondCCarb.pos = 2),
          (secondCCarb.visible = true),
          (secondCCarb.total =
            getTotalEmission(mainOffsets[1].loggedMonthEmissions) === 0
              ? 1
              : (getTotalOffsetEmsission(mainOffsets[1].loggedMonthEmissions) *
                  100) /
                getTotalEmission(mainOffsets[1].loggedMonthEmissions));
        secondCCarb.date = [secondOffset.startDate, secondOffset.endDate];
      } else secondCCarb.visible = false;
      charts.push({ log: secondCLog, carbon: secondCCarb }); // 2 chart

      if (secondOffset && log.optionNumber === 1) {
        (thirdChartLog.visible = false),
          (thirdChartCarbon.visible = true),
          (thirdChartCarbon.pos = 2),
          (thirdChartCarbon.total =
            (getTotalOffsetEmsission(mainOffsets[1].loggedMonthEmissions) *
              100) /
            getTotalEmission(mainOffsets[1].loggedMonthEmissions));
        thirdChartCarbon.lastLoggedAt =
          mainOffsets[1].loggedMonthEmissions[
            mainOffsets[1].loggedMonthEmissions.length - 1
          ];

        charts.push({ log: thirdChartLog, carbon: thirdChartCarbon });
      }

      setfinalcharts(charts);
      if (dates && dates.length <= 2) {
        dates.push({});
      }
      setfinalDates(dates);
    }
  }, [open]);

  return (
    <React.Fragment>
      {finalOffsets &&
        finalOffsets.length > 0 &&
        finalOffsets
          .filter((log) => log.type === 1)
          .map((details, i) => (
            <StyledTableRow
              key={i}
              sx={{
                "&:last-child td, &:last-child th": { borderBottom: 0 },
              }}
            >
              {i === 0 && (
                <StyledTableCell
                  textleft={true}
                  rowSpan={finalOffsets.length}
                  sx={{ width: "23%" }}
                  className="border_left_0"
                >
                  <div className="d-flex align-items-center">
                    {log.isFirstOffset ? (
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    ) : (
                      <>
                        {" "}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </>
                    )}

                    <p className="ml-8 body_text_m text_overflow-1">
                      {" "}
                      <img
                        className="item_icon_img"
                        src={getServiceIcon(log.accountingItemName)}
                        alt={log.accountingItemName}
                      />
                      &nbsp;
                      {log.itemDetails.name}
                    </p>
                  </div>
                </StyledTableCell>
              )}
              <StyledTableCell component="th" scope="row">
                {details.startDate.month +
                  "." +
                  details.startDate.year +
                  " - " +
                  details.endDate.month +
                  "." +
                  details.endDate.year}
              </StyledTableCell>
              <StyledTableCell>
                {" "}
                {OffsetDetails2[i]
                  ? OffsetDetails2[i].startDate.month +
                    "." +
                    OffsetDetails2[i].startDate.year +
                    " - " +
                    OffsetDetails2[i].endDate.month +
                    "." +
                    OffsetDetails2[i].endDate.year
                  : ""}
              </StyledTableCell>
              <StyledTableCell sx={{ width: "20%" }}>
                <div className="row align-items-center">
                  <div className="col-md-6 col-lg-8 pr-0 pl-0 ml-8">
                    <LogTableProgressBar val={details.offsetPer} />
                  </div>
                  <div className="col-md-3 col-lg-3  pr-0">
                    <p
                      className={`${
                        details.offsetPer <= 20
                          ? "red_label"
                          : details.offsetPer > 20 && details.offsetPer < 80
                          ? "orange_label"
                          : "green_label"
                      }  f-11`}
                    >
                      {details.offsetPer.toFixed(0).toLocaleString()}%
                    </p>
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell>
                {log.isFirstOffset === false ? (
                  <p className="red_label">Complete first offset</p>
                ) : (
                  <>
                    {OffsetDetails2[i] && OffsetDetails2[i].endDate ? (
                      <p className="green_label">
                        {daysDiffFromCurrent(OffsetDetails2[i].endDate)} days
                        remaining
                      </p>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </StyledTableCell>
              <StyledTableCell className="border_right_0">
                {details.offsetPer === 100 ? (
                  <ButtonComp
                    // className="tertiary_button_variant_1"
                    status={3}
                    width="lg"
                    onClick={() => {
                      setStep(1);
                      navigate(
                        `/dashboard?tab=3&step=1&logId=${log._id}&subtab=3&view-label=true`
                      );
                    }}
                  >
                    View Label
                  </ButtonComp>
                ) : (
                  <ButtonComp
                    // className="tertiary_button_variant_1"
                    status={3}
                    width="lg"
                    onClick={() => {
                      setStep(1);
                      navigate(
                        `/dashboard?tab=5&subtab=1&step=1&log=${log._id}&offset=${details.uniqueId}`
                      );
                    }}
                  >
                    Offset
                  </ButtonComp>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))}
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="offsetActiveTable_collapse_root">
              <div className="">
                <div className="d-flex justify-content-start align-items-center">
                  <p className="font_17_700 align_text_left text_capital ">
                    Carbon Neutrality timeline
                  </p>
                  {log.optionNumber === 1 ? (
                    <div className="offsetBox retrospectiveBox px-12 pt-10 pb-10  ml-20">
                      <p className="font_13_600">Retrospective Offset</p>
                    </div>
                  ) : (
                    <div className="offsetBox propectiveBox px-10 py-6  ml-20">
                      <p className="font_13_600">Prospective Offset</p>
                    </div>
                  )}
                </div>
                {/* <hr className="mt-0" /> */}
                <div className="border-ef border_radius_10_t h-68px  mt-40"></div>
                <div className="border-ef border_radius_10_b border_top_0 offsetActiveTable_timeLine_root">
                  <div className="row border-btm_ef pb-16 pt-18 mx-0 pr-24">
                    <div className="col-2  mr-20 text-left ps-0">
                      <div className="respectiveTextBox d-flex justify-content-end align-items-center">
                        <p className="subheading_1">Carbon Neutral</p>
                      </div>
                      <div className="respectiveTextBox d-flex justify-content-end align-items-center">
                        <p className="subheading_1">Log Period</p>
                      </div>
                    </div>
                    <div className="col d-flex align-items-center flex-grow-1 ps-0 me-4">
                      {finalCharts &&
                        finalCharts.length > 0 &&
                        finalCharts.map((chart, i) => (
                          <div
                            className="flex-grow-1"
                            style={{ marginRight: 2 }}
                            key={i}
                          >
                            {chart.carbon.visible ? (
                              <div
                                className={
                                  log.optionNumber === 1
                                    ? "bar"
                                    : "prospectiveBar"
                                }
                              >
                                <HtmlTooltip
                                  title={
                                    <div
                                      className={
                                        "subtitle_box width_fit " +
                                        (chart.carbon.total === 100
                                          ? "bg_soft_green"
                                          : "bg_soft_red")
                                      }
                                    >
                                      <p
                                        className={
                                          "font_13_600 " +
                                          (chart.carbon.total === 100
                                            ? "color_green"
                                            : "color_red")
                                        }
                                      >
                                        {chart.carbon.total &&
                                          chart.carbon.total
                                            .toFixed(0)
                                            .toLocaleString()}
                                        % Emissions Offset
                                      </p>
                                    </div>
                                  }
                                >
                                  <span
                                    className={
                                      chart.carbon.pos === 1
                                        ? log.optionNumber === 1
                                          ? "retrospectiveBar1"
                                          : "prospectiveBar1"
                                        : log.optionNumber === 1
                                        ? "retrospectiveBar2"
                                        : "prospectiveBar2"
                                    }
                                    style={{ width: chart.carbon.total + "%" }}
                                  ></span>
                                </HtmlTooltip>
                                <span
                                  className="nullSpan"
                                  style={{
                                    width: 100 - chart.carbon.total + "%",
                                  }}
                                ></span>
                              </div>
                            ) : (
                              <div className="nullBar"></div>
                            )}
                            {chart.log.visible ? (
                              <div
                                className={
                                  log.optionNumber === 1
                                    ? "bar"
                                    : "prospectiveBar"
                                }
                              >
                                <LogDataTooltip
                                  title={
                                    <React.Fragment>
                                      <p className="overline_text color_black">
                                        Log data complete until{" "}
                                        {chart.log.lastLoggedAt?.month}/
                                        {chart.log.lastLoggedAt?.year}
                                      </p>
                                    </React.Fragment>
                                  }
                                >
                                  <span
                                    className={
                                      chart.log.pos === 1
                                        ? log.optionNumber === 1
                                          ? "retrospectiveBar1"
                                          : "prospectiveBar1"
                                        : log.optionNumber === 1
                                        ? "retrospectiveBar2"
                                        : "prospectiveBar2"
                                    }
                                    style={{ width: chart.log.total + "%" }}
                                  ></span>
                                </LogDataTooltip>
                                <span
                                  className="nullSpan"
                                  style={{ width: 100 - chart.log.total + "%" }}
                                ></span>
                              </div>
                            ) : (
                              <div className="nullBar"></div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                  {/* <hr /> */}
                  <div className="row px-2 py-3 pr-24">
                    <div className="col-2 mr-20"></div>
                    <div className="col d-flex align-items-center flex-grow-1 ps-0 me-4">
                      {finalDates &&
                        finalDates.length > 0 &&
                        finalDates.map((date, dateIndex) => (
                          <div
                            className={`flex-grow-1 d-flex  ${
                              dateIndex == 0
                                ? `justify-content-start`
                                : dateIndex == finalDates.length - 1
                                ? "justify-content-end"
                                : "justify-content-center"
                            }`}
                            key={dateIndex}
                          >
                            <p
                              className={`${
                                dateIndex == 0
                                  ? "mln-20"
                                  : dateIndex == finalDates.length - 1
                                  ? "mrn-10"
                                  : ""
                              }`}
                              style={{
                                paddingRight: dateIndex % 2 !== 0 ? "55px" : "",
                              }}
                            >
                              {date.month}
                              {date.month ? "/" : ""}
                              {date.year}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* <hr /> */}
              <div className="my-4 row mx-0 justify-content-between">
                <div className="col-45 ps-0">
                  {log.offsetDetails[0] && (
                    <div className="row mx-0 border_radius_8 border_1_ef px-4">
                      <div className="col-6 text-left ps-0 border_r_ef py-4">
                        <p
                          className={`${
                            log.optionNumber === 1
                              ? "retrospectiveBox"
                              : "propectiveBox"
                          } fit-w pt-10 pb-10 px-4 offsetBox mb-24`}
                        >
                          {log.offsetDetails[0].startDate.month +
                            "/" +
                            log.offsetDetails[0].startDate.year}
                          &nbsp; -&nbsp;
                          {log.offsetDetails[0].endDate.month +
                            "/" +
                            log.offsetDetails[0].endDate.year}
                        </p>
                        <p className="ms-2">
                          <span className="heading_lg2 mb-6">
                            {getTotalEmission(
                              log.offsetDetails[0].loggedMonthEmissions
                            ).toLocaleString()}
                          </span>
                          <span className="body_text_sm color_black">
                            {" "}
                            tCO₂e
                          </span>
                        </p>
                        <p className="ms-2 f-10">logged emissions</p>
                      </div>
                      {OffsetDetails2[0] && (
                        <div className="col-6 text-left ps-4 py-4">
                          <p
                            className={`${
                              log.optionNumber === 1
                                ? "retrospectiveBox"
                                : "propectiveBox"
                            } fit-w pt-10 pb-10 px-4 offsetBox mb-24`}
                          >
                            {OffsetDetails2[0].startDate.month +
                              "/" +
                              OffsetDetails2[0].startDate.year}
                            &nbsp; -&nbsp;
                            {OffsetDetails2[0].endDate.month +
                              "/" +
                              OffsetDetails2[0].endDate.year}
                          </p>
                          <p className="ms-2">
                            <span className="heading_lg2 mb-6">
                              {log.offsetDetails[0].loggedMonthEmissions
                                ?.length +
                                "/" +
                                log.offsetDetails[0].totalMonths}
                            </span>
                          </p>
                          <p className="ms-2 f-10">complete months logged</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="col-45 pe-0">
                  {log.offsetDetails[1] && log.offsetDetails[1].type === 1 && (
                    <div className="row mx-0 border_radius_8 border_1_ef px-4">
                      <div className="col-6 text-left ps-0 border_r_ef py-4">
                        <p
                          className={`${
                            log.optionNumber === 1
                              ? "retrospectiveBox2"
                              : "propectiveBox2"
                          } fit-w pt-10 pb-10 px-4 offsetBox mb-24`}
                        >
                          {log.offsetDetails[1].startDate.month +
                            "/" +
                            log.offsetDetails[1].startDate.year}
                          &nbsp; -&nbsp;
                          {log.offsetDetails[1].endDate.month +
                            "/" +
                            log.offsetDetails[1].endDate.year}
                        </p>
                        <p className="ms-2">
                          <span className="heading_lg2 mb-6">
                            {getTotalEmission(
                              log.offsetDetails[1].loggedMonthEmissions
                            ).toLocaleString()}
                          </span>
                          <span className="body_text_sm color_black">
                            {" "}
                            tCO₂e
                          </span>
                        </p>
                        <p className="ms-2 f-10">logged emissions</p>
                      </div>
                      {OffsetDetails2[1] && (
                        <div className="col-6 text-left ps-4 py-4">
                          <p
                            className={`${
                              log.optionNumber === 1
                                ? "retrospectiveBox2"
                                : "propectiveBox2"
                            } fit-w pt-10 pb-10 px-4 offsetBox mb-24`}
                          >
                            {OffsetDetails2[1].startDate.month +
                              "/" +
                              OffsetDetails2[1].startDate.year}
                            &nbsp; -&nbsp;
                            {OffsetDetails2[1].endDate.month +
                              "/" +
                              OffsetDetails2[1].endDate.year}
                          </p>
                          <p className="ms-2">
                            <span className="heading_lg2 mb-6">
                              {log.offsetDetails[1].loggedMonthEmissions
                                ?.length +
                                "/" +
                                log.offsetDetails[1].totalMonths}
                            </span>
                          </p>
                          <p className="ms-2 f-10">complete months logged</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              {offsetfeed && offsetfeed.length > 0 && (
                <div className="mt-40 analytics_table_container">
                  <p className="card_heading text_capital">
                    Carbon Contribution Feed
                  </p>
                  <TableContainer
                    component={Paper}
                    sx={{ minWidth: 640, borderRadius: "8px" }}
                    className="mr-50 mt-20"
                  >
                    <Table sx={{ minWidth: 640 }} aria-label="simple table">
                      <TableHead>
                        <StyledHeadRow>
                          <HeadCell
                            sx={{ borderLeft: "0" }}
                            component="th"
                            className="text-uppercase"
                          >
                            Contribution Project
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase">
                            Tons offset
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase ">
                            Price
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase">
                            Date
                          </HeadCell>
                          <HeadCell component="th" className="text-uppercase">
                            Emissions offset for
                          </HeadCell>
                          <HeadCell
                            sx={{ borderRight: "0" }}
                            component="th"
                            className="text-uppercase"
                          >
                            Team member
                          </HeadCell>
                        </StyledHeadRow>
                      </TableHead>
                      <TableBody>
                        {offsetfeed && offsetfeed.length > 0
                          ? offsetfeed.map((feed, i) => (
                              <StyledTableRow key={i}>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  textleft={true}
                                  className="border_bottom_0"
                                >
                                  {feed.project
                                    .split("")
                                    .map((letter, letterIndex) => {
                                      return letterIndex < 25 ? letter : "";
                                    })}
                                  {feed.project.length >= 25 ? "..." : ""}
                                </StyledTableCell>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  textleft={true}
                                  className="border_bottom_0"
                                >
                                  {feed.tons} tons
                                </StyledTableCell>
                                <StyledTableCell
                                  component="th"
                                  scope="row"
                                  textleft={true}
                                  className="border_bottom_0"
                                >
                                  {feed.price}
                                </StyledTableCell>
                                <StyledTableCell
                                  textleft={true}
                                  component="th"
                                  scope="row"
                                  className="border_bottom_0"
                                >
                                  {new Date(feed.date).toLocaleDateString(
                                    "en-GB"
                                  )}
                                </StyledTableCell>
                                <StyledTableCell
                                  textleft={true}
                                  component="th"
                                  scope="row"
                                  className="border_bottom_0"
                                >
                                  {feed.timeline}
                                </StyledTableCell>
                                <StyledTableCell
                                  textleft={true}
                                  component="th"
                                  sx={{ borderRight: "0" }}
                                  scope="row"
                                  className="border_bottom_0"
                                >
                                  {feed.user.firstname} {feed.user.lastname}
                                </StyledTableCell>
                              </StyledTableRow>
                            ))
                          : null}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default Row;
