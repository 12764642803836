import React, { useEffect, useState } from "react";
import AddressInfo from "./Components/AddressInfo";
import Axios from "../../../../../../../../../Api";
import { toastResponseError } from "../../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../../Utils/Auth";
import PaymentCardInfo from "./Components/PaymentCardInfo";
import AddNewPaymentMethod from "./Components/AddNewPaymentMethod";
import { useMapStore } from "../../../../../../../../../Utils/CompanyMapStore";
import ButtonComp from "../../../../../../../../../Assets/components/Button";
function BillingAndPayments(props) {
  const {
    selectedAddress,
    handleSelectedAddressChange,
    selectedPaymentMethod,
    handleSelectedPaymentMethodChange,
  } = useMapStore();
  const { setSessionExpire } = useAuth();
  const [billingAddresses, setBillingAddresses] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const getAllAddress = async () => {
    try {
      await Axios.get("/address/address").then((res) => {
        if (res.data.success) {
          setBillingAddresses(res.data.data);
          handleSelectedAddressChange(
            res.data.data && res.data.data[0] ? res.data.data[0] : null
          );
        }
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const getPaymentMethods = async () => {
    await Axios({
      url: `/stripe/paymentMethod`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data.data;
          let parArr = paymentMethods;
          parArr = [...parArr, ...data];

          setPaymentMethods(parArr);
          // setselectedPayment(data[0] ? data[0] : null);
          handleSelectedPaymentMethodChange(data[0] ? data[0] : null);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getAllAddress();
    getPaymentMethods();
  }, []);

  return (
    <div className="">
      <div className="mt-15">
        <p className="font_16_600 color_7e">Billing Information</p>

        <div className="mt-30">
          {billingAddresses.map((currentAddress, addressIndex) => {
            return (
              <div
                key={currentAddress._id}
                className={`${addressIndex != 0 ? "mt-10" : ""} ${
                  selectedAddress._id == currentAddress._id
                    ? "border-009-4 border_radius_5"
                    : ""
                }`}
              >
                <AddressInfo
                  getAllAddress={getAllAddress}
                  addressDetails={currentAddress}
                />
              </div>
            );
          })}

          <div className="mt-10">
            <AddressInfo getAllAddress={getAllAddress} isNewAddress={true} />
          </div>
        </div>
      </div>

      <div className="mt-35">
        <p className="font_16_600 color_7e">Payment Information</p>

        <div className="mt-30">
          {paymentMethods.map((paymentMethod, paymentMethodIndex) => {
            return (
              <div
                key={paymentMethod._id}
                className={`${paymentMethodIndex ? "mt-10" : ""} ${
                  selectedPaymentMethod.id == paymentMethod.id
                    ? "border-009-4 border_radius_5"
                    : ""
                }`}
              >
                <PaymentCardInfo paymentMethodDetails={paymentMethod} />
              </div>
            );
          })}

          <div className="mt-10">
            <AddNewPaymentMethod getPaymentMethods={getPaymentMethods} />
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-end align-items-center mt-20 mb-10">
        <ButtonComp
          onClick={() => {
            props.handleCreditOptionChange(props.activationStep - 1);
          }}
          status={4}
        >
          Go Back
        </ButtonComp>

        <div className="ml-10">
          <ButtonComp
            status={5}
            onClick={() => {
              props.handleCreditOptionChange(props.activationStep + 1);
            }}
          >
            Overview
          </ButtonComp>
        </div>
      </div>
    </div>
  );
}

export default BillingAndPayments;
