import React, { useEffect, useRef, useState } from "react";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { HeadCell, StyledHeadRow } from "../../../../../../Utils/TableComp";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Backdrop,
  Box,
  Modal,
  Fade,
  Paper,
  IconButton,
  CircularProgress,
} from "@mui/material";
import ButtonComp from "../../../../../../Assets/components/Button";
import Axios from "../../../../../../Api";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../../../../Utils/Auth";
import EsgRow from "./Row";
import KeyboardForwardIcon from "../../../../../../Assets/icons/KeyboardForwardIcon";
import { botAvatarGroup } from "../../../../../../Utils/botAvatarsGroup";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "#ffffff",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
};

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const gotoPage = (event, newPage) => {
    onPageChange(event, newPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
        className="border_radius_8 p-4px"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {page === props.count ? (
        <IconButton
          sx={{ fontSize: "13px", fontWeight: 400 }}
          onClick={(event) => gotoPage(event, page - 1)}
        >
          {page - 1}
        </IconButton>
      ) : (
        ""
      )}
      <IconButton
        sx={{ fontSize: "13px", fontWeight: 400, color: "black" }}
        onClick={(event) => gotoPage(event, page)}
      >
        {page}
      </IconButton>

      {page < props.count ? (
        <IconButton
          sx={{ fontSize: "13px", fontWeight: 400 }}
          onClick={(event) => gotoPage(event, page + 1)}
        >
          {page + 1}
        </IconButton>
      ) : (
        ""
      )}

      <IconButton>...</IconButton>
      <IconButton
        sx={{ fontSize: "13px", fontWeight: 400 }}
        onClick={(event) => gotoPage(event, props.count)}
      >
        {props.count}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="border_radius_8 p-4px"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}
function ESGReportTable({ setStep }) {
  const [page, setPage] = useState(1);
  const { user, setSessionExpire } = useAuth();
  // const [feedTableData, setFeedTableData] = useState([]);
  const [rowPerPage] = useState(10);
  const [totalPages] = useState(1);
  const tableHeads = ["Name", "Framework", "TIME FRAME", "uploaded"];
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [years, setYears] = useState([]);
  const [reportUploadedLink, setReportUploadedLink] = useState(null);
  const [isdocUploading, setisdocUploading] = useState(false);
  const [reportName, setReportName] = useState("");
  const [framework, setFramework] = useState("CSRD (Corporate Sustainability Reporting Directive)");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const fileref = useRef();
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    let yearsArr = [];

    for (let year = currentYear; year >= 2000; year--) {
      yearsArr.push(year);
    }

    setYears(yearsArr);
  }, []);

  const handleChangePage = (event, newPage) => {
    if (newPage <= 0 || newPage > totalPages) return;
    setPage(newPage);
  };

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (file.size > 4 * 1024 * 1024) {
      toast.error("File size exceeded 4MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("esg-report", file);
    setisdocUploading(true);
    await Axios({
      method: "post",
      url: `/share/esg/upload/esg-pdf`,
      data: formdata,
    })
      .then((res) => {
        setisdocUploading(false);
        setReportUploadedLink(res.data.location);
      })
      .catch((err) => {
        setisdocUploading(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const getEsgReports = async () => {
    await Axios({
      url: `/share/esg/all`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setReports(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleCreateReport = async () => {
    if (!reportName || !framework || !reportUploadedLink || !selectedYear) {
      return toast.error("Please fill all the fields");
    }
    await Axios({
      url: `/share/esg/create`,
      method: "POST",
      data: {
        name: reportName,
        framework: framework,
        link: reportUploadedLink,
        timeFrame: selectedYear,
      },
    })
      .then((res) => {
        if (res.data.success) {
          getEsgReports();
          handleClose();
          toast.success("report created successfully");
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getEsgReports();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center ">
        <p className="font_17_700 text_capital">esg reports</p>

        <ButtonComp status={2} onClick={handleOpen}>
          Upload ESG Report
        </ButtonComp>
      </div>
      {reports.length > 0 ? (
        <div className="matrix_table_root mt-24">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <StyledHeadRow>
                  {tableHeads.map((item, i) => {
                    return i == 0 ? (
                      <HeadCell
                        sx={{ borderLeft: "0", width: "20%" }}
                        align="left"
                        key={i}
                        className="text_capital font_13_600"
                      >
                        {item}
                      </HeadCell>
                    ) : i == tableHeads.length - 1 ? (
                      <HeadCell
                        sx={{ borderRight: "0" }}
                        align="left"
                        key={i}
                        className="text_capital font_13_600"
                      >
                        {item}
                      </HeadCell>
                    ) : (
                      <HeadCell
                        align="left"
                        key={i}
                        className="text_capital font_13_600"
                      >
                        {item}
                      </HeadCell>
                    );
                  })}
                </StyledHeadRow>
              </TableHead>
              <TableBody>
                {reports.map((data) => (
                  <EsgRow
                    data={data}
                    key={data._id}
                    getEsgReports={getEsgReports}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="csrd_placeholder_block d-flex justify-content-between p_relative mt-32">
          <div>
            <p className="heading_lg">Take our CSRD Assessment</p>
            <p className="mt-40 subheading_light" style={{ maxWidth: 540 }}>
              Learn how CSRD (Corporate Sustainability Reporting Directive)
              affects your company and prepare for the new disclosure
              regulations from the European Commission, starting in 2024, using
              our CSRD Checklist.
            </p>
            <ButtonComp
              status={3}
              className="mt-60"
              onClick={() => {
                navigate(`/dashboard?tab=6&step=2`);
                setStep(2);
              }}
            >
              Take CSRD Assessment
              <KeyboardForwardIcon className={"ml-8"} cc={"#3574E0"} />
            </ButtonComp>
          </div>
          <div style={{ zIndex: 1 }}>
            <img
              src={botAvatarGroup[user?.bot_avatar_id]?.avatarSelect?.url}
              alt="launching"
              style={{ maxHeight: 340, width: "auto" }}
            />
          </div>
          <hr className="bg_divider" style={{ bottom: "44%" }} />
        </div>
      )}
      {reports.length > 5 && (
        <div className="d-flex justify-content-end">
          <TablePaginationActions
            count={totalPages}
            rowsPerPage={rowPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="esg_report_modal">
              <div className="p-24 border-btm_dc">
                <p className="font_24_600">Upload ESG Report</p>
              </div>
              <div className="esg_report_upload_body mt-8">
                <p className="subheading_1">Upload your existing ESG report</p>
                <div className="esg_report_upload_box mt-12">
                  {isdocUploading ? (
                    <CircularProgress />
                  ) : reportUploadedLink ? (
                    <a
                      href={reportUploadedLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="font_14_500"
                    >
                      View
                    </a>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={28}
                      height={28}
                      viewBox="0 0 28 28"
                      fill="none"
                      className="pointer"
                      onClick={() => fileref.current.click()}
                    >
                      <rect
                        x={1.13281}
                        y={0.519531}
                        width={26}
                        height={26}
                        rx={4.5}
                        fill="white"
                        stroke="#EFEFEF"
                      />
                      <path
                        d="M14.1328 6.51953L13.4257 5.81242C13.8162 5.4219 14.4494 5.4219 14.8399 5.81242L14.1328 6.51953ZM15.1328 15.5195C15.1328 16.0718 14.6851 16.5195 14.1328 16.5195C13.5805 16.5195 13.1328 16.0718 13.1328 15.5195L15.1328 15.5195ZM8.42571 10.8124L13.4257 5.81242L14.8399 7.22664L9.83992 12.2266L8.42571 10.8124ZM14.8399 5.81242L19.8399 10.8124L18.4257 12.2266L13.4257 7.22664L14.8399 5.81242ZM15.1328 6.51953L15.1328 15.5195L13.1328 15.5195L13.1328 6.51953L15.1328 6.51953Z"
                        fill="black"
                      />
                      <path
                        d="M7.13281 17.5195L7.13281 18.5195C7.13281 19.6241 8.02824 20.5195 9.13281 20.5195L19.1328 20.5195C20.2374 20.5195 21.1328 19.6241 21.1328 18.5195V17.5195"
                        stroke="black"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}

                  <p className="mt-8 font_12_500 color_8a">
                    .Pdf files accepted
                  </p>
                  <input
                    type="file"
                    ref={fileref}
                    onChange={handleFileUpload}
                    hidden
                    accept=".pdf"
                  />
                </div>
              </div>
              <div className="esg_report_upload_body">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="input_div">
                      <p className="font_13_700 text-uppercase">Report Name</p>
                      <input
                        type="text"
                        className="mt-12 subheading_2 "
                        placeholder="Name "
                        value={reportName}
                        onChange={(e) => setReportName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="input_div">
                      <p className="font_13_700 text-uppercase">
                        Reporting Framework
                      </p>
                      <select
                        className="mt-12 subheading_2"
                        value={framework}
                        onChange={(e) => setFramework(e.target.value)}
                      >
                        <option value="CSRD (Corporate Sustainability Reporting Directive)">
                          CSRD (Corporate Sustainability Reporting Directive)
                        </option>
                        <option
                          value="TCFD (Task Force on Climate-Related Financial
                          Disclosures)"
                        >
                          TCFD (Task Force on Climate-Related Financial
                          Disclosures)
                        </option>
                        <option value="SBTi (Science-Bases Targets initiatives)">
                          SBTi (Science-Bases Targets initiatives)
                        </option>
                        <option value="CDP (Carbon Disclosure Project)">
                          CDP (Carbon Disclosure Project)
                        </option>
                        <option value="GRI (Global Reporting Initiative)">
                          GRI (Global Reporting Initiative)
                        </option>
                        <option value="SECR (Streamlined Energy and Carbon Reporting)">
                          SECR (Streamlined Energy and Carbon Reporting)
                        </option>
                        <option value="ISSB (IFRS Sustainability Disclosure Standard)">
                          ISSB (IFRS Sustainability Disclosure Standard)
                        </option>
                        <option value="SME Climate Commitment">
                          SME Climate Commitment
                        </option>
                        <option value="SASB (Value Reporting Foundation)">
                          SASB (Value Reporting Foundation)
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-6 col-12">
                    <div className="input_div">
                      <p className="font_13_700 text-uppercase">Year</p>
                      <select
                        className="mt-12 subheading_2"
                        value={selectedYear}
                        onChange={(e) => setSelectedYear(e.target.value)}
                      >
                        {years.map((year) => (
                          <option value={year} key={year}>
                            {year}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end p-24">
                <ButtonComp status={2} onClick={handleCreateReport}>
                  Upload Report
                </ButtonComp>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
}

export default ESGReportTable;
