import React, { useEffect } from "react";
import GoogleAutheticatorImg from "../../Assets/images/GoogleAuthenticator.png";
import PhoneAuthenticatorImg from "../../Assets/images/PhoneImage.png";
import BackForwardButton from "../../Assets/components/BackForwardButton/BackForwardButton";
import ButtonComp from "../../Assets/components/Button";
import PressEnter from "../../Assets/components/PressEnter/PressEnter";
import { useAuth } from "../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import useQuery from "../../Utils/query";

const Step0 = ({ setAuthType, authType, setStep }) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const redirectPath = query.get("redirect");

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      setStep(1);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);

  return (
    <>
      <div className="subtitle_box bg_soft_blue width_fit">
        <p className="font_13_600 color_blue">Optional Security Settings</p>
      </div>
      <p className="mt-32 offsetProjectTitle">
        Set-Up a Two-Factor <br /> Authentication for your Account
      </p>
      <div className="mt-32 d-flex align-items-center">
        <div
          className={
            "two_authenticator_box_ mr-40 " +
            (authType === "google" && "auth_box_selected")
          }
          onClick={() => setAuthType("google")}
        >
          <img src={GoogleAutheticatorImg} alt="google authenticator" />
          <p className="subheading_light_2 color_black mt-24">
            Google Authenticator
          </p>
        </div>
        <div
          className={
            "two_authenticator_box_ " +
            (authType === "phone" && "auth_box_selected")
          }
          onClick={() => setAuthType("phone")}
        >
          <img src={PhoneAuthenticatorImg} alt="phone authenticator" />
          <p className="subheading_light_2 color_black mt-24">
            SMS Authenticator
          </p>
        </div>
      </div>
      <div className="mt-6rem d-flex align-items-start justify-content-between">
        <span className="mt-1">
          <BackForwardButton
            handleGoBack={() => navigate(-1)}
            doNotShowForward={true}
          />
        </span>
        <div>
          <div>
            <ButtonComp
              status={3}
              size="lg"
              // className="tertiary_button_variant_1"
              style={{ padding: "6px 35px" }}
              onClick={() => {
                if (user && user.isBasePaid) {
                  if (redirectPath) {
                    navigate(redirectPath);
                  } else {
                    navigate("/dashboard");
                  }
                } else setStep(3);
              }}
            >
              Skip for now
            </ButtonComp>
            <ButtonComp
              status={1}
              size="lg"
              style={{ padding: "6px 35px", marginLeft: "16px" }}
              type="submit"
              // className="secondary_button_active ml-16"
              onClick={() => setStep(1)}
            >
              Continue
            </ButtonComp>
          </div>
          <PressEnter />
        </div>
      </div>
    </>
  );
};

export default Step0;
