import React, { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Axios from "../../Api";
import { toastResponseError } from "../toastResponseError";
import { useAuth } from "../Auth";
import { v4 as uuidv4 } from "uuid";
import toast from "react-hot-toast";

// const levelOneAllowedItems=[
//   "6442af32ca73043a8ec66c78",
//   "645337d99d60aa8fc7a24dbe",
//   "645337d99d60aa8fc7a24dc2",
//   "645337d99d60aa8fc7a24dc4",
//   "645337d99d60aa8fc7a24dc3",
//   "645337d99d60aa8fc7a24dc5"
// ]
// const levelTwoAllowedItems=[
//   "6442af32ca73043a8ec66c79",
//   "6442af32ca73043a8ec66c7a",
//   "645337d99d60aa8fc7a24dbf",
//   "645337d99d60aa8fc7a24dc1",
//   "6442af32ca73043a8ec66c7b",
//   "645337d99d60aa8fc7a24dc0",
// ]
const mapStoreContext = createContext();
function useProvideMapStore() {
  const { profile, setSessionExpire, user } = useAuth();
  const [mapNodes, setMapNodes] = useState([]);
  const [mapEdges, setMapEdges] = useState([]);
  const [addAccItemDrawerOpen, setAddAccItemDrawerOpen] = useState(false);
  const [activeNodeData, setActiveNodeData] = useState({});
  const [activeAccItemTabIndex, setActivateAccItemTabIndex] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [addNewNodeEventData, setAddNewNodeEventData] = useState({});
  const [selectedAccountingItem, setSelectedAccountingItem] = useState("1");
  const [mapCenter, setMapCenter] = useState({ x: 100, y: 100 });
  const [totalS1Emission, setTotalS1Emission] = useState(0);
  const [totalS2Emission, setTotalS2Emission] = useState(0);
  const [totalS3Emission, setTotalS3Emission] = useState(0);
  const [initViewPort, setinitViewPort] = useState();
  const [budgetData, setbudgetData] = useState(null);

  const handleSelectedAddressChange = (address) => {
    setSelectedAddress(address);
  };
  const handleSelectedAccountingItemChange = (value) => {
    setSelectedAccountingItem(value);
  };
  const handleSelectedPaymentMethodChange = (paymentMethod) => {
    setSelectedPaymentMethod(paymentMethod);
  };
  const handleAccItemDrawer = (nodeAdded, viewNodeDetails) => {
    //
    if (addAccItemDrawerOpen) {
      setActiveNodeData({});
      setActivateAccItemTabIndex(0);
    }
    if (viewNodeDetails && addAccItemDrawerOpen) setActiveNodeData({});
    if (!nodeAdded && addAccItemDrawerOpen) resetAddNewNode();
    setAddAccItemDrawerOpen(!addAccItemDrawerOpen);
  };
  const handleAccItemTabChange = (value) => {
    //   if (!activeNodeData.isActivate && (value == 0 || value == 1 || value == 3)) return;
    setActivateAccItemTabIndex(value);
  };

  const handleNodeDragStart = () => {
    //  setDraggableNode(data)
  };
  const handleNodeClick = (data) => {
    setActiveNodeData(data);
    if (data.noOfScopes === 0) {
      // alert("b");
      setActivateAccItemTabIndex(1);
      handleAccItemDrawer(false, true);
    } else if (!data.isActivate) {
      // alert("a");
      setActivateAccItemTabIndex(3);
      handleAccItemDrawer(false, true);
    } else {
      // alert("c");
      setActivateAccItemTabIndex(0);
      handleAccItemDrawer(false, true);
    }
  };

  const onNodeDragStop = async (_, draggableNode) => {
    if (!draggableNode || draggableNode.type === "addNode") return;
    let firstLevelOptions = [
      "6442af32ca73043a8ec66c78",
      "645337d99d60aa8fc7a24dbe",
      "645337d99d60aa8fc7a24dc2",
      "645337d99d60aa8fc7a24dc4",
      "645337d99d60aa8fc7a24dc3",
      "645337d99d60aa8fc7a24dc5",
    ];
    if (!draggableNode || !draggableNode.data || draggableNode.data?.level == 0)
      return;
    let isPresent = firstLevelOptions.findIndex(
      (currentOption) => currentOption == draggableNode.data?.data?.itemId
    );

    if (isPresent != -1) return;

    const closestNode = mapNodes.reduce(
      (res, n) => {
        if (n.id !== draggableNode.id && n.data.fake) {
          const dx = n.position.x - draggableNode.positionAbsolute.x;
          const dy = n.position.y - draggableNode.positionAbsolute.y;
          const d = Math.sqrt(dx * dx + dy * dy);

          if (d < res.distance && d < 40) {
            res.distance = d;
            res.node = { ...n };
          }
        }

        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null,
      }
    );
    //  console.log("on drag stop ",closestNode.node)
    let currentNodes = [...mapNodes];
    let currentEdges = [...mapEdges];

    let dummyNodeIndex = -1;
    dummyNodeIndex = currentNodes.findIndex((currNode) => currNode.data.fake);
    while (dummyNodeIndex != -1) {
      let dummyEdgeIndex = currentEdges.findIndex(
        (currEdge) =>
          currEdge.id ==
          `${draggableNode.id}-${currentNodes[dummyNodeIndex].data.parentNode}`
      );
      currentEdges.splice(dummyEdgeIndex, 1);
      currentNodes.splice(dummyNodeIndex, 1);
      dummyNodeIndex = currentNodes.findIndex((currNode) => currNode.data.fake);
    }

    if (closestNode.node) {
      let prevEdgeIndex = currentEdges.findIndex(
        (currEdge) =>
          currEdge.source == draggableNode.data.parentNode &&
          currEdge.target == draggableNode.id
      );
      currentEdges.splice(prevEdgeIndex, 1);

      let activeNodeIndex = currentNodes.findIndex(
        (currNode) => draggableNode.id == currNode.id
      );
      let newParentNodeIndex = currentNodes.findIndex(
        (currNode) => closestNode.node.data.parentNode == currNode.id
      );
      currentNodes[activeNodeIndex].position.x =
        closestNode.node.position.x - 50;
      currentNodes[activeNodeIndex].position.y = closestNode.node.position.y;
      currentNodes[activeNodeIndex].data.parentNode =
        closestNode.node.data.parentNode;
      currentNodes[activeNodeIndex].data.level = closestNode.node.data.level;

      currentNodes[newParentNodeIndex].data.startChildX = Math.min(
        currentNodes[newParentNodeIndex].data.startChildX,
        closestNode.node.position.x - 40
      );

      //  currentNodes[newParentNodeIndex].data.startChildY= Math.max(currentNodes[newParentNodeIndex].data.startChildY,closestNode.node.position.Y)

      currentNodes[newParentNodeIndex].data.endChildX = Math.max(
        currentNodes[newParentNodeIndex].data.endChildX,
        closestNode.node.position.x
      );

      currentNodes[newParentNodeIndex].data.endChildY = Math.max(
        currentNodes[newParentNodeIndex].data.endChildY,
        closestNode.node.position.Y
      );

      let parentNodeData = currentNodes.find(
        (cNode) => cNode.id == closestNode.node.data.parentNode
      );

      let newEdge = {
        id: uuidv4(),
        source: closestNode.node.data.parentNode,
        target: draggableNode.id,
        type: "step",
        style: { stroke: "black", zIndex: "-1" },
        dragHandle: ".custom-drag-handle",
      };
      try {
        await Axios.post(
          `log/updateLogDetails?logId=${draggableNode.data.data._id}`,
          {
            connectedLogId: parentNodeData?.data?.data?._id,
          }
        ).then((res) => {
          if (res.data.success) {
            toast.success("info has been updated");
          }
        });
      } catch (err) {
        toastResponseError(err, setSessionExpire);
      }

      currentEdges.push(newEdge);
    }
    if (!closestNode.node) {
      let activeNodeIndex = currentNodes.findIndex(
        (currNode) => draggableNode.id == currNode.id
      );
      currentNodes[activeNodeIndex].position = {
        x: draggableNode.positionAbsolute.x,
        y: draggableNode.positionAbsolute.y,
      };
    }
    setMapEdges(currentEdges);
    setMapNodes(currentNodes);
  };

  const onNodeDrag = (_, e) => {
    //console.log("this is e. type ",e,e.type)
    if (!e || (e && e?.type === "addNode")) return;
    let firstLevelOptions = [
      "6442af32ca73043a8ec66c78",
      "645337d99d60aa8fc7a24dbe",
      "645337d99d60aa8fc7a24dc2",
      "645337d99d60aa8fc7a24dc4",
      "645337d99d60aa8fc7a24dc3",
      "645337d99d60aa8fc7a24dc5",
    ];
    if (!e || !e.data || e?.data?.level == 0) return;
    let isPresent = firstLevelOptions.findIndex(
      (currentOption) => currentOption == e?.data?.data?.itemId
    );

    if (isPresent == -1) findNearestParent(e);
  };

  const findNearestParent = (e) => {
    //  console.log("this is e ",e)
    let draggableNode = e;
    if (draggableNode == undefined) return;

    const closestNode = mapNodes.reduce(
      (res, n) => {
        if (n.id !== draggableNode.id) {
          const dx = n.position.x - draggableNode.positionAbsolute.x;
          const dy = n.position.y - draggableNode.positionAbsolute.y;
          const d = Math.sqrt(dx * dx + dy * dy);

          if (d < res.distance && d < 250) {
            res.distance = d;
            res.node = n;
          }
        }

        return res;
      },
      {
        distance: Number.MAX_VALUE,
        node: null,
      }
    );

    if (closestNode.node) {
      let isPresent = mapEdges.findIndex((currEdge) => {
        return (
          currEdge.source == closestNode.node.data.parentNode &&
          currEdge.target == draggableNode.id
        );
      });

      let isDumyPresent = mapEdges.findIndex((currEdge) => {
        return (
          `${draggableNode.id}-${closestNode.node.data.parentNode}` ==
          currEdge.id
        );
      });

      if (isPresent != -1 || isDumyPresent != -1) return;

      let parentNode = {};
      if (draggableNode.data.level == 2 && closestNode.node.data.level == 2)
        parentNode = mapNodes.find(
          (currNode) => currNode.id == closestNode.node.data.parentNode
        );
      else if (
        draggableNode.data.level == 2 &&
        closestNode.node.data.level == 1
      )
        parentNode = closestNode.node;

      if (!parentNode || Object.keys(parentNode).length == 0) return;

      if (parentNode.data.level === 0) return;
      let dumyNode = {
        id: uuidv4(),
        type: "DragAndDropDummyNode",
        data: {
          level: 2,
          parentNode: parentNode.id,
          fake: true,
        },
        position: {
          x: parentNode.data.startChildX - 250,
          y: parentNode.data.startChildY,
        },
        //  parentNode:currItemNode.id
        // draggable:false
      };

      let newEdge = {
        id: `${draggableNode.id}-${closestNode.node.data.parentNode}`,
        source: parentNode.id,
        target: dumyNode.id,
        animated: true,
        type: "step",
        style: { stroke: "black", zIndex: "-2px" },
        data: {
          truEdge: false,
        },
        dragHandle: ".custom-drag-handle",
      };

      setMapNodes([...mapNodes, dumyNode]);
      setMapEdges([...mapEdges, newEdge]);
    }
  };

  const connectlevel2 = (logs) => {
    const { parentNodes, childNodes } = getParentAndChildNodes(logs);
    let currentX = 50;
    let currentY = 800;
    const allNodes = [];
    const edgesConnection = [];
    const mainCompanyNode = {
      id: uuidv4(),
      type: "companyNode",
      data: {
        companyName: profile.companyName,
        logo: profile.logo,
        level: 0,
        label: "company Node", // send total emission co2 budget s1,s2,s3 from here
      },
      position: { x: 250, y: 0 },
    };
    // ---------------------------------------------------------------------------------------------
    // let mainNodeLeftAddNode = {
    //   id: uuidv4(),
    //   type: "addNode",
    //   data: {
    //     level: 1,
    //     parentNode: mainCompanyNode.id,
    //     childNode: "",
    //     childLogId: "",
    //   },
    //   position: {
    //     x: currentX + 110,
    //     y: currentY - 250,
    //   },
    //   //  parentNode:currItemNode.id
    //   // draggable:false
    // };
    // let mainNodeLeftAddNodeEdge = {
    //   id: uuidv4(),
    //   source: mainCompanyNode.id,
    //   target: mainNodeLeftAddNode.id,
    //   type: "step",
    //   style: { stroke: "transparent" },
    //   dragHandle: ".custom-drag-handle",
    // };
    // ---------------------------------------------------------------------------------------------

    currentX += 200;
    parentNodes.forEach((currLog, logIndex) => {
      if (Math.round(logs.length / 2) == logIndex) {
        mainCompanyNode.position.x = currentX;
      }

      let currItemNode = {
        id: uuidv4(),
        type: "AccountingItemNode",
        data: {
          data: currLog,
          level: 1,
          startChildX: currentX,
          startChildY: currentY,
          parentNode: mainCompanyNode.id,
        },
        position: {
          x: currentX,
          y: currentY - 425,
        },
        style: { zIndex: "-1px" },
        // parentNode:mainCompanyNode.id
        // draggable:false
      };
      // --------------------------------------------------------------------------------------
      // let addNewNode = {
      //   id: uuidv4(),
      //   type: "addNode",
      //   data: {
      //     level: 1,
      //     parentNode: mainCompanyNode.id,
      //     childNode: currItemNode.id,
      //     childLogId: currItemNode.data.data._id,
      //   },
      //   position: {
      //     x: currentX + 110,
      //     y: currentY - 450,
      //   },
      //   //  parentNode:currItemNode.id
      //   // draggable:false
      // };

      // let addNewNodeEdge = {
      //   id: uuidv4(),
      //   source: mainCompanyNode.id,
      //   target: addNewNode.id,
      //   type: "step",
      //   style: { stroke: "transparent" },
      // };
      // --------------------------------------------------------------------------------------
      let actualChildren = [];
      actualChildren = childNodes.filter(
        (currChildNode) => currLog._id == currChildNode.connectedLogId
      );

      actualChildren.forEach((currentChildNode, childIndex) => {
        if (
          (actualChildren.length % 2 &&
            Math.round(actualChildren.length / 2) - 1 == childIndex) ||
          (actualChildren.length % 2 == 0 &&
            Math.round(actualChildren.length / 2) == childIndex)
        ) {
          currItemNode.position.x = currentX;
          // --------------------------------------------------------------------------------------

          // addNewNode.position.x = currentX + 110;
          // --------------------------------------------------------------------------------------
        }

        let dumyNode = {
          id: uuidv4(),
          type: "AccountingItemNode",
          data: {
            data: currentChildNode,
            level: 2,
            parentNode: currItemNode.id,
          },
          position: {
            x: currentX,
            y: currentY,
          },
          style: { zIndex: "-1px" },
        };
        edgesConnection.push({
          id: uuidv4(),
          source: currItemNode.id,
          target: dumyNode.id,
          type: "step",
          style: { stroke: "black", zIndex: "-2px" },
        });
        allNodes.push(dumyNode);
        currentX += 360;
      });

      if (!actualChildren || actualChildren.length == 0) {
        let firstLevelOptions = [
          "6442af32ca73043a8ec66c78",
          "645337d99d60aa8fc7a24dbe",
          "645337d99d60aa8fc7a24dc2",
          "645337d99d60aa8fc7a24dc4",
          "645337d99d60aa8fc7a24dc3",
          "645337d99d60aa8fc7a24dc5",
        ];
        let isPresentInFirstLevel = firstLevelOptions.findIndex(
          (currentItem) => currentItem == currLog.itemId
        );
        if (isPresentInFirstLevel === -1) {
          // --------------------------------------------------------------------------------------
          // allNodes.push(addNewNode);
          // edgesConnection.push(addNewNodeEdge);
          // --------------------------------------------------------------------------------------
        }
      }

      currItemNode.data.endChildX = currentX + 280;
      currItemNode.data.endChildY = currentY;
      currentX += 330;

      // --------------------------------------------------------------------------------------

      // if (actualChildren?.length > 0) {
      //   let leftAddNewNode = {
      //     id: uuidv4(),
      //     type: "addNode",
      //     data: {
      //       level: 2,
      //       left: 1,
      //       parentNode: currItemNode.id,
      //       childNode: "",
      //       parentLogId: currLog._id,
      //     },
      //     position: {
      //       x: currItemNode.data.startChildX - 50,
      //       y: currItemNode.data.startChildY + 150,
      //     },
      //   };
      //   edgesConnection.push({
      //     id: uuidv4(),
      //     source: currItemNode.id,
      //     target: leftAddNewNode.id,
      //     type: "step",
      //     style: { stroke: "transparent" },
      //   });

      //   let rightAddNewNode = {
      //     id: uuidv4(),
      //     type: "addNode",
      //     data: {
      //       level: 2,
      //       right: 1,
      //       parentNode: currItemNode.id,
      //       childNode: "",
      //       parentLogId: currLog._id,
      //     },
      //     position: {
      //       x: currItemNode.data.endChildX - 280,
      //       y: currItemNode.data.endChildY + 150,
      //     },
      //   };
      //   edgesConnection.push({
      //     id: uuidv4(),
      //     source: currItemNode.id,
      //     target: rightAddNewNode.id,
      //     type: "step",
      //     style: { stroke: "transparent" },
      //   });

      //   allNodes.push(leftAddNewNode);
      //   allNodes.push(rightAddNewNode);
      // } else {
      //   let secondLevelItems = [
      //     "6442af32ca73043a8ec66c79",
      //     "6442af32ca73043a8ec66c7a",
      //     "645337d99d60aa8fc7a24dbf",
      //     "645337d99d60aa8fc7a24dc1",
      //     "6442af32ca73043a8ec66c7b",
      //     "645337d99d60aa8fc7a24dc0",
      //   ];
      //   let isParentinSecondLevelItems = secondLevelItems.findIndex(
      //     (currItemId) => currItemId == currLog.itemId
      //   );

      //   let centerAddNewNode = {
      //     id: uuidv4(),
      //     type: "addNode",
      //     data: {
      //       level: 2,
      //       right: 1,
      //       parentNode: currItemNode.id,
      //       childNode: "",
      //       parentLogId: currLog._id,
      //     },
      //     position: {
      //       x: currItemNode.data.startChildX + 110,
      //       y: currItemNode.data.startChildY,
      //     },
      //   };
      //   if (isParentinSecondLevelItems == -1) {
      //     edgesConnection.push({
      //       id: uuidv4(),
      //       source: currItemNode.id,
      //       target: centerAddNewNode.id,
      //       type: "step",
      //       style: { stroke: "transparent" },
      //     });

      //     allNodes.push(centerAddNewNode);
      //   }
      // }

      // --------------------------------------------------------------------------------------

      edgesConnection.push({
        id: uuidv4(),
        source: mainCompanyNode.id,
        target: currItemNode.id,
        type: "step",
        style: { stroke: "black", zIndex: "-2px" },
      });
      allNodes.push(currItemNode);
    });

    setMapCenter({
      x: mainCompanyNode.position.x,
      y: mainCompanyNode.position.y,
    });
    // --------------------------------------------------------------------------------------

    // let mainNodeRightAddNode = {
    //   id: uuidv4(),
    //   type: "addNode",
    //   data: {
    //     level: 1,
    //     parentNode: mainCompanyNode.id,
    //     childNode: "",
    //     childLogId: "",
    //   },
    //   position: {
    //     x: currentX + 110,
    //     y: currentY - 250,
    //   },
    //   //  parentNode:currItemNode.id
    //   // draggable:false
    // };
    // let mainNodeRightAddNodeEdge = {
    //   id: uuidv4(),
    //   source: mainCompanyNode.id,
    //   target: mainNodeLeftAddNode.id,
    //   type: "step",
    //   style: { stroke: "transparent" },
    // };

    // --------------------------------------------------------------------------------------

    setMapEdges([
      ...edgesConnection,
      // mainNodeLeftAddNodeEdge,
      // mainNodeRightAddNodeEdge,
    ]);
    setMapNodes([
      mainCompanyNode,
      ...allNodes,
      // mainNodeLeftAddNode,
      // mainNodeRightAddNode,
    ]);
  };

  const getParentAndChildNodes = (logs) => {
    let parentNodes = [];
    let childNodes = [];
    let totalS1 = 0;
    let totalS2 = 0;
    let totalS3 = 0;
    logs.forEach((currentLog) => {
      if (
        currentLog.itemId != selectedAccountingItem &&
        currentLog.connectedLogId
      ) {
        childNodes.push(currentLog);
      } else parentNodes.push(currentLog);

      totalS1 += currentLog.scopeWiseData.Scope1;
      totalS2 += currentLog.scopeWiseData.Scope2;
      totalS3 += currentLog.scopeWiseData.Scope3;
    });
    setTotalS1Emission(totalS1);
    setTotalS2Emission(totalS2);
    setTotalS3Emission(totalS3);

    return { parentNodes, childNodes };
  };

  useEffect(() => {
    if (user) {
      getItemData();
    }
  }, [selectedAccountingItem]);

  useEffect(() => {}, [mapNodes]);
  const getItemData = async () => {
    try {
      const response = await Axios.get(
        `/log/get-log-scopes?itemId=${selectedAccountingItem}` // should get data less than equal to selected year
      );
      if (response.data.success) {
        //  nodeMapping(response.data.logs)
        connectlevel2(response.data.logs);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const createDummyNode = (parentId, childId, positionX, positionY, level) => {
    return {
      id: uuidv4(),
      type: "SlotHolderNode",
      data: {
        level: level,
        parentNode: parentId,
        childNode: childId,
        fake: true,
      },
      position: {
        x: positionX,
        y: positionY,
      },
    };
  };

  const newNodeAddedLevel1 = (data) => {
    let nodeLevel = addNewNodeEventData.data.level;
    let currentNodes = [...mapNodes];
    let currentEdges = [...mapEdges];
    let dummyNodeIndex = currentNodes.findIndex(
      (currNode) => currNode.data.fake
    );

    let newAccItem = {
      id: uuidv4(),
      type: "AccountingItemNode",
      data: {
        data: data,
        level: 1,
        startChildX: currentNodes[dummyNodeIndex].position.x,
        startChildY: currentNodes[dummyNodeIndex].position.y + 300,
        parentNode: addNewNodeEventData.data.parentNode,
      },
      position: {
        x:
          currentNodes[dummyNodeIndex].position.x +
          (nodeLevel == 2 && addNewNodeEventData.left == 1
            ? -200
            : nodeLevel == 2 && addNewNodeEventData.right == 1
            ? 0
            : 0),
        y: currentNodes[dummyNodeIndex].position.y,
      },
      style: { zIndex: "-1px" },
    };
    currentNodes.push(newAccItem);
    let mainToDummyNodeEdge = currentEdges.findIndex(
      (currEdge) =>
        currEdge.source == currentNodes[dummyNodeIndex].data.parentNode &&
        currEdge.target == currentNodes[dummyNodeIndex].id
    );
    currentEdges.splice(mainToDummyNodeEdge, 1);

    if (nodeLevel == 1) {
      let dummyToChildNodeEdge = currentEdges.findIndex(
        (currEdge) =>
          currEdge.source == currentNodes[dummyNodeIndex].id &&
          currEdge.target == currentNodes[dummyNodeIndex].data.childNode
      );
      if (dummyToChildNodeEdge != -1)
        currentEdges.splice(dummyToChildNodeEdge, 1);
    }

    let newAccItemToMainEdge = {
      id: uuidv4(),
      source: currentNodes[dummyNodeIndex].data.parentNode,
      target: newAccItem.id,
      type: "step",
      style: { stroke: "black", zIndex: "-2px" },
    };
    currentEdges.push(newAccItemToMainEdge);

    if (nodeLevel == 1) {
      let newAccItemToChildEdge = {
        id: uuidv4(),
        source: newAccItem.id,
        target: currentNodes[dummyNodeIndex].data.childNode,
        type: "step",
        style: { stroke: "black", zIndex: "-2px" },
      };
      if (
        currentNodes[dummyNodeIndex].data.childNode &&
        currentNodes[dummyNodeIndex].data.childNode.length > 0
      )
        currentEdges.push(newAccItemToChildEdge);
    }

    if (nodeLevel == 2) {
      let insertNode = {
        id: uuidv4(),
        type: "addNode",
        data: {
          level: 2,
          left: addNewNodeEventData.data.left == 1 ? 1 : 0,
          right: addNewNodeEventData.data.right == 1 ? 1 : 0,
          parentNode: currentNodes[dummyNodeIndex].data.parentNode,
          childNode: "",
          parentLogId: addNewNodeEventData.data.parentLogId, // missing
        },
        position: {
          x:
            newAccItem.position.x +
            (addNewNodeEventData.data.left == 1
              ? -150
              : addNewNodeEventData.data.right == 1
              ? 250
              : 0),
          y: newAccItem.position.y + 150,
        },
      };
      currentNodes.push(insertNode);

      currentEdges.push({
        id: uuidv4(),
        source: addNewNodeEventData.data.parentNode,
        target: insertNode.id,
        type: "step",
        style: { stroke: "transparent" },
        dragHandle: ".custom-drag-handle",
      });
    }
    currentNodes.splice(dummyNodeIndex, 1);
    setMapNodes(currentNodes);
    setMapEdges(currentEdges);
    setAddNewNodeEventData({});
  };
  const resetAddNewNode = () => {
    if (!addNewNodeEventData || !addNewNodeEventData.data) return;

    // let nodeLevel = addNewNodeEventData.data.level;
    let currentNodes = [...mapNodes];
    let currentEdges = [...mapEdges];

    let dummyNodeIndex = currentNodes.findIndex(
      (currNode) => currNode.data.fake
    );

    let mainToDummyEdge = currentEdges.findIndex(
      (currEdge) =>
        currEdge.source == addNewNodeEventData.id &&
        currEdge.target == currentNodes[dummyNodeIndex]?.id
    );

    currentEdges.splice(mainToDummyEdge, 1);

    // if (nodeLevel == 1 && dummyNodeIndex != -1) {
    //   let dummyToChild = currentEdges.findIndex(
    //     (currEdge) =>
    //       currEdge.source == currentNodes[dummyNodeIndex].id &&
    //       currEdge.target == addNewNodeEventData.data.childNode
    //   );
    //   if (dummyToChild != -1) currentEdges.splice(dummyToChild, 1);
    // }
    if (dummyNodeIndex != -1) currentNodes.splice(dummyNodeIndex, 1);
    if (addNewNodeEventData.childNode) {
      let maintoChildNodeEdge = {
        id: uuidv4(),
        source: addNewNodeEventData.parentNode,
        target: addNewNodeEventData.childNode,
        type: "step",
        style: { stroke: "black", zIndex: "-2px" },
      };
      if (addNewNodeEventData.childNodeIndex !== -1) {
        currentNodes[addNewNodeEventData.childNodeIndex].position.y -= 400;
        currentNodes[addNewNodeEventData.childNodeIndex].data.level = 1;
        currentNodes[addNewNodeEventData.childNodeIndex].data.parentNode =
          addNewNodeEventData.parentNode;
      }

      currentEdges.push(maintoChildNodeEdge);
    }

    // let addNewNode = {
    //   id: uuidv4(),
    //   type: "addNode",
    //   data: {
    //     level: nodeLevel,
    //     parentNode: addNewNodeEventData?.data.parentNode,
    //     childNode: addNewNodeEventData?.data.childNode,
    //     childLogId: addNewNodeEventData?.data._id,
    //     parentLogId: addNewNodeEventData?.data.parentLogId,
    //   },
    //   position: {
    //     x: addNewNodeEventData?.position.x,
    //     y: addNewNodeEventData?.position.y,
    //   },
    // };
    // currentNodes.push(addNewNode);

    // let addNodeToMainNodeEdge = {
    //   id: uuidv4(),
    //   source: addNewNodeEventData?.data.parentNode,
    //   target: addNewNode.id,
    //   type: "step",
    //   style: { stroke: "transparent" },
    // };
    // currentEdges.push(addNodeToMainNodeEdge);

    // if (nodeLevel == 1) {
    //   let childNodeIndex = currentNodes.findIndex(
    //     (currNode) => currNode.id == addNewNodeEventData.data.childNode
    //   );
    //   if (childNodeIndex != -1) currentNodes[childNodeIndex].position.y -= 400;
    // }

    setMapEdges(currentEdges);
    setMapNodes(currentNodes);
  };

  const addNodeAtlevel2 = (id, data, position) => {
    let currentNodes = [...mapNodes];
    let currentEdges = [...mapEdges];

    // removed edge
    let newNodeToParentEdgeIndex = currentEdges.findIndex(
      (currentEdge) =>
        currentEdge.source == data.parendNode && currentEdge.target == id
    );
    currentEdges.splice(newNodeToParentEdgeIndex, 1);

    // remove add newNode Node
    let addNodeIndex = currentNodes.findIndex(
      (currentNode) => currentNode.id == id
    );
    currentNodes.splice(addNodeIndex, 1);
    let dummyNodePosition = {
      x: position.x + (data.left ? -200 : 0),
      y: position.y - 120,
    };
    let dummyNode = createDummyNode(
      data.parentNode,
      data.childNode,
      dummyNodePosition.x,
      dummyNodePosition.y
    );
    currentNodes.push(dummyNode);

    currentEdges.push({
      id: uuidv4(),
      source: data.parentNode,
      target: dummyNode.id,
      type: "step",
      style: { stroke: "black", zIndex: "-2px" },
    });
    setMapNodes(currentNodes);
    setMapEdges(currentEdges);
    setAddNewNodeEventData({ id, data, position });
    handleAccItemDrawer(false, false);
  };

  const addNodeAtLevel1 = (
    parentNode,
    childNode,
    xPos,
    yPos,
    level,
    parentLogId,
    childNodeIndex
  ) => {
    let currentNodes = [...mapNodes];
    let currentEdges = [...mapEdges];
    // insert dummy node
    // remove addNode node and its edge with main
    // connect dummy to main and dummy to child edge
    // let childNodeIndex = mapNodes.findIndex(
    //   (currNode) => currNode.id == data.childNode
    // );

    // let mainToChildEdgeIndex = mapEdges.findIndex(
    //   (currEdge) =>
    //     currEdge.source == data.parentNode && currEdge.target == data.childNode
    // );
    // currentEdges.splice(mainToChildEdgeIndex, 1);

    // let maintoAddNodeIndex = currentNodes.findIndex(
    //   (currNode) => currNode.id == id
    // );
    // currentNodes.splice(maintoAddNodeIndex, 1);

    // let mainToAddNodeEdgeIndex = currentEdges.findIndex(
    //   (currEdge) => currEdge.source == data.parentNode && currEdge.target == id
    // );
    // currentEdges.splice(mainToAddNodeEdgeIndex, 1);
    // if (childNodeIndex != -1) mapNodes[childNodeIndex].position.y += 400;

    // finalise
    // let parentNode = direction !== "bottom" ? data.data.parentNode : data.id;

    // let childNodeIndex = -1;
    // // pending
    // let childNode = data.data.level === 1 && direction !== "top" ? "" : data.id;
    // if (childNode !== "") {
    //   childNodeIndex = mapNodes.findIndex((currNode) => currNode.id == data.id);
    //   if (childNodeIndex != -1) mapNodes[childNodeIndex].position.y += 400;
    // }
    //finalise
    // let xPos =
    //   childNodeIndex != -1
    //     ? data.xPos
    //     : direction === "left"
    //     ? data.xPos - 270
    //     : direction === "right"
    //     ? data.xPos + 270
    //     : data.xPos;

    // final
    // let yPos =
    //   childNodeIndex != -1
    //     ? data.yPos
    //     : direction === "bottom"
    //     ? data.yPos + 400
    //     : direction === "top"
    //     ? data.yPos - 400
    //     : data.yPos;

    let dummyNode = createDummyNode(parentNode, childNode, xPos, yPos, level);

    let dummyNodeEdge = {
      id: uuidv4(),
      source: parentNode,
      target: dummyNode.id,
      type: "step",
      style: { stroke: "black", zIndex: "-2px" },
    };

    let dummytoChildEdge;
    if (childNodeIndex != -1) {
      currentNodes[childNodeIndex].data.parentNode = dummyNode.id;
      dummytoChildEdge = {
        id: uuidv4(),
        source: dummyNode.id,
        target: childNode,
        type: "step",
        style: { stroke: "black", zIndex: "-2px" },
      };
      currentEdges.push(dummytoChildEdge);

      let childtoMainEdgeIndex = currentEdges.findIndex(
        (currEdge) =>
          currEdge.source == parentNode && currEdge.target == childNode
      );
      currentEdges.splice(childtoMainEdgeIndex, 1);
    }

    currentNodes.push(dummyNode);
    currentEdges.push(dummyNodeEdge);

    setMapEdges(currentEdges);
    setMapNodes(currentNodes);

    setAddNewNodeEventData({
      id: dummyNode.id,
      data: dummyNode.data,
      xPos: xPos,
      yPos: yPos,
      parentLogId: parentLogId,
      childNode: childNode,
      parentNode: parentNode,
      childNodeIndex: childNodeIndex,
    });

    handleAccItemDrawer(false, false);
  };

  return {
    mapNodes,
    setMapNodes,
    mapEdges,
    setMapEdges,
    getItemData,
    addAccItemDrawerOpen,
    handleAccItemDrawer,
    activeAccItemTabIndex,
    addNodeAtLevel1,
    handleAccItemTabChange,
    handleNodeClick,
    activeNodeData,
    selectedPaymentMethod,
    handleSelectedPaymentMethodChange,
    selectedAddress,
    handleSelectedAddressChange,
    handleNodeDragStart,
    onNodeDrag,
    onNodeDragStop,
    addNewNodeEventData,
    newNodeAddedLevel1,
    addNodeAtlevel2,
    selectedAccountingItem,
    handleSelectedAccountingItemChange,
    totalS1Emission,
    totalS2Emission,
    totalS3Emission,
    mapCenter,
    initViewPort,
    setinitViewPort,
    budgetData,
    setbudgetData,
  };
}

export function ProvideMapStore({ children }) {
  const companyMapStore = useProvideMapStore();

  return (
    <mapStoreContext.Provider value={companyMapStore}>
      {children}
    </mapStoreContext.Provider>
  );
}

ProvideMapStore.prototype = {
  children: PropTypes.node.isRequired,
};

export const useMapStore = () => useContext(mapStoreContext);
