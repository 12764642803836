import { Close, HelpOutlined } from "@mui/icons-material";
import React, { useRef, useState } from "react";
import AboutImageUpload from "./AboutImageUpload";
import ButtonComp from "../../../../Assets/components/Button";
import { useAuth } from "../../../../Utils/Auth";
import { handleSaveData } from "./CommonFunctions";
import { useEffect } from "react";
import toast from "react-hot-toast";

const AboutModal = ({ handleClose, getCompanyData, companyData }) => {
  const { setSessionExpire } = useAuth();
  const [companyAbout, setCompanyAbout] = useState("");
  const [companyImpressionImg1, setCompanyImpressionImg1] = useState("");
  const [companyImpressionImg2, setCompanyImpressionImg2] = useState("");
  const [companyImpressionImg3, setCompanyImpressionImg3] = useState("");
  const [companyImpressionImg4, setCompanyImpressionImg4] = useState("");
  const textareaRef = useRef();

  const handleChange = () => {
    if (companyAbout?.length > 1600) {
      return toast.error("About should be less than 1600 characters");
    }
    const data = {
      companyAbout,
      companyImpressionImg1,
      companyImpressionImg2,
      companyImpressionImg3,
      companyImpressionImg4,
    };
    handleSaveData(data, getCompanyData, handleClose, setSessionExpire);
  };

  useEffect(() => {
    if (companyData) {
      setCompanyAbout(companyData.companyAbout);
      setCompanyImpressionImg1(companyData.companyImpressionImg1);
      setCompanyImpressionImg2(companyData.companyImpressionImg2);
      setCompanyImpressionImg3(companyData.companyImpressionImg3);
      setCompanyImpressionImg4(companyData.companyImpressionImg4);
    }
  }, [companyData]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textareaRef) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [companyAbout]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Enter key was pressed, adjust the height
      adjustTextareaHeight();
    }
  };

  const handleCut = () => {
    // Text is being cut (e.g., via Ctrl+X), adjust the height
    setTimeout(adjustTextareaHeight, 0);
  };

  return (
    <div className="row_20_gap">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font_16_500">
          About
          <HelpOutlined
            htmlColor="#7E8999"
            fontSize="small"
            className="ml-10"
            sx={{ height: 14, width: 14 }}
          />
        </p>
        <Close className="pointer" onClick={handleClose} />
      </div>
      <div className="mt-20 ">
        <div className="d-flex align-items-center justify-content-between">
          <p className="font_12_600 color_7e">
            Tell us about your company. What should others know about it?
          </p>
          <p
            className={
              "font_12_600 " +
              (companyAbout?.length > 1600 ? "color_red" : "color_7e")
            }
          >
            {companyAbout?.length}/1600
          </p>
        </div>
        <textarea
          style={{
            height: 40,
            padding: 10,
            maxHeight: 220,
            minHeight: 40,
          }}
          ref={textareaRef}
          className="mt-10 share_transpancy_input font_14_400"
          value={companyAbout}
          onChange={(e) => setCompanyAbout(e.target.value)}
          onCut={handleCut}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
        />
        {/* <input
          type="text"
          className="mt-10 share_transpancy_input font_14_400"
        /> */}
      </div>
      <div className="mt-30 ">
        <p className="font_12_600 color_7e">Company Photos</p>
        <div className="impression_placeholder_main mt-10">
          <AboutImageUpload
            avatar={companyImpressionImg1}
            setAvatar={setCompanyImpressionImg1}
          />
          <AboutImageUpload
            avatar={companyImpressionImg2}
            setAvatar={setCompanyImpressionImg2}
          />
          <AboutImageUpload
            avatar={companyImpressionImg3}
            setAvatar={setCompanyImpressionImg3}
          />
          <AboutImageUpload
            avatar={companyImpressionImg4}
            setAvatar={setCompanyImpressionImg4}
          />
        </div>
      </div>
      <div className="mt-20 d-flex justify-content-end">
        <ButtonComp status={5} onClick={handleChange}>
          Save
        </ButtonComp>
      </div>
    </div>
  );
};

export default AboutModal;
