import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#CDF1E3"];

const OffsetCompensatedPlaceholder = () => {
  const data = [
    {
      name: "NonOffset",
      value: 100,
    },
  ];
  return (
    <ResponsiveContainer height={300}>
      <PieChart>
        <Pie
          data={data}
          cx={130}
          cy={140}
          innerRadius={90}
          outerRadius={123}
          fill="#8884d8"
          paddingAngle={0}
          startAngle={0}
          endAngle={360}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <text
          x={110}
          dy={150}
          fill="#000"
          fontSize={30}
          fontFamily="Inter"
          fontWeight="700"
        >
          0%
        </text>
        <text
          x={110}
          dy={170}
          fill="#000"
          fontSize={13.5}
          fontFamily="Inter"
          fontWeight="600"
        >
          Offset
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default OffsetCompensatedPlaceholder;
