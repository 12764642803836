import React, { useEffect, useState } from "react";
import "./store.css";
import StoreCard from "./Components/StoreCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
// import { TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import axios from "../../../Api/index";
import { toastResponseError } from "../../../Utils/toastResponseError";
// import ServiceImg from "../../../Assets/images/OurServicesImg.png";
//import { getServiceIcon } from "../../../Utils/itemIcon";
import useQuery from "../../../Utils/query";
import ScheduleCall from "./Components/ScheduleCall";
import StorePayment from "./Components/StorePayment";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ButtonComp from "../../../Assets/components/Button";
import ItemDetailsPage from "./Components/ItemDetailsPage";
import OtherItemDetails from "./Components/OtherItemDetails/OtherItemDetails";
// import { motion } from "framer-motion";

//import InlineWidget from "react-calendly";
import { useTranslation } from "react-i18next";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function Store() {
  const { setSessionExpire,user } = useAuth();
  const [value, setValue] = useState(0);
  const [cardDetails, setCardDetails] = useState([]);
  const [cartDetails, setCartDetails] = useState([]);
  const [userCart, setUserCart] = useState({});
  const [currStep, setCurrStep] = useState(0);
  const [checkStep, setcheckStep] = useState(0);
  const [isAcceptTerms, setisAcceptTerms] = useState(false);
  const [submitPayment, setsubmitPayment] = useState(false);
  const [userAddress, setUserAddress] = useState(null);
  const [grandTotal, setgrandTotal] = useState(0);
  const query = useQuery();
  const step = Number(query.get("step"));
  const stage = Number(query.get("stage"));
  const cstep = Number(query.get("checkout"));
  const qitem = query.get("item");

  const [openDropDown, setopenDropDown] = useState(false);
  const history = useNavigate();
  const [d_code, setd_code] = useState("");
  const [submitCode, setsubmitCode] = useState(false);
  const renew_id = query.get("renew");

  useEffect(() => {
    if (step) setCurrStep(step);
    else setCurrStep(0);
  }, [step]);

  useEffect(() => {
    if (cstep) setcheckStep(cstep);
    else setcheckStep(0);
  }, [cstep]);

  const handleValChange = (event, newVal) => {
    setValue(newVal);
  };

  const handleQuantityChange = async (id, quantity) => {
    try {
      var response = await axios.patch(
        `/cart/store-cart-update/${userCart._id}`,
        {
          itemId: id,
          quantity: quantity,
        }
      );
      if (response.data.success) {
        setUserCart(response.data.cart);
        setCartDetails(response.data.cart.items);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const getData = async () => {
    try {
      const response = await axios.get(`/items/all`);
      if (response.data.success) {
        setCardDetails(response.data.items);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const getCart = async () => {
    try {
      const response = await axios.get(
        `/cart/user-store-cart?renew=${renew_id}`
      );
      if (response.data.success) {
        setCartDetails(response.data.data.items);
        setUserCart(response.data.data);
        setgrandTotal(response.data.data.grandTotal);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    getData();
    getCart();
  }, []);

  useEffect(() => {
    let tax = userCart.tax ? userCart.tax.toFixed(2) : 0;
    let subTotal = userCart.total ? userCart.total.toFixed(2) : 0;
    if (userAddress && userAddress.Country === "Germany") {
      setgrandTotal(Number(tax) + Number(subTotal));
    } else {
      setgrandTotal(subTotal);
    }
  }, [userAddress, userCart]);

  useEffect(() => {
    if (d_code && d_code.length === 0) {
      setsubmitCode(false);
    }
  }, [d_code]);

  const { t } = useTranslation();

  return (
    <>
      {stage == 1 ? (
        <div className="ml-116 mr-62">
          <ItemDetailsPage
            handleQuantityChange={handleQuantityChange}
            cartDetails={cartDetails}
            setCartDetails={setCartDetails}
            userCart={userCart}
            setUserCart={setUserCart}
          />
        </div>
      ) : stage === 2 ? (
        <div className="ml-116 mr-62">
          <OtherItemDetails
            handleQuantityChange={handleQuantityChange}
            cartDetails={cartDetails}
            setCartDetails={setCartDetails}
            userCart={userCart}
            setUserCart={setUserCart}
          />
        </div>
      ) : currStep == 1 ? (
        <div className="ml-116 mr-62">
          <div className="mt-34 d-flex justify-content-between align-items-center">
            <p className=" breadcrumb_text fw-400 color_ba">
              <span
                onClick={() => history("/dashboard?tab=9")}
                className="pointer"
              >
                store
              </span>{" "}
              / other services & products /{" "}
              <span className="fw-700">{qitem ? qitem : ""}</span>
            </p>

            <ButtonComp
              status={2}
              //  className="secondary_button_variant_2"
            >
              <i className="bi bi-cart3"></i> Go to Cart
            </ButtonComp>
          </div>

          <div className="mt-50">
            <p className="font_17_700 text_capital">
              {qitem ? qitem + " by today.green" : ""}
            </p>

            <div className="row mt-34">
              <div className="col-md-7 d-flex">
                <div className="flow-grow-1">
                  <video width="95%" controls>
                    <source
                      src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                      type="video/mp4"
                    />
                  </video>
                </div>
              </div>
              {/* <div className="col-md-"></div> */}
              <div className="col-md-4">
                <div className="container_design_1 pl-28">
                  <p className="mt-28 f-20 fw-600 font-inter">
                    Accounting Service
                  </p>

                  <p className="subheading_light mt-20 mr-46">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                    diam nonumy eirmod tempor invidunt ut labore et dolore
                    magna.
                  </p>

                  <div className="mt-30">
                    <p className="body_text_s">
                      <i className="bi bi-check color_blue"></i>
                      Accounting for 1 item (1 year)
                    </p>

                    <p className="mt-6 body_text_s">
                      <i className="bi bi-check color_blue"></i>
                      Up to 250 Logs/month
                    </p>

                    <p className="mt-6 body_text_s">
                      <i className="bi bi-check color_blue"></i>
                      Quarterly GHG Reports
                    </p>

                    <div className="mt-40 d-flex align-items-center">
                      <p className="body_text_sm fw-700 color_desp_grey">
                        {Number(1590).toLocaleString()}€ Setup fee
                      </p>
                      <p className="helper_text color_ba ml-26">
                        (one time payment)
                      </p>
                    </div>

                    <p className="body_text_sm color_desp_grey mt-36">from</p>

                    <div className="mt-6 d-flex align-items-center">
                      <p className="heading_9">429€</p>
                      <p className="helper_text color_ba ml-26">
                        /mo ([aid annually)
                      </p>
                    </div>
                    <div className="mt-22 mb-20">
                      <ButtonComp
                        status={2}
                        onClick={() => history("/dashboard?tab=9&step=2")}
                        // className="secondary_button_variant_2 "
                      >
                        Schedule free Assessment Call
                      </ButtonComp>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-50">
            <div className="col-md-7">
              <p className="font_17_700 text_capital">Description</p>
              <p className="body_text color_desp_grey mt-12">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam erat, sed diam voluptua. At vero eos et accusam et
                justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum
                dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                eirmod tempor invidunt ut labore et dolore magna aliquyam erat,
                sed diam voluptua. At vero eos et accusam et justo duo dolores
                et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                est Lorem ipsum dolor sit amet.
              </p>
            </div>
          </div>

          <div className="mt-50 mb-70 pb-40">
            <p className="font_17_700 text_capital">Step-by-step</p>

            <div className="col-lg-7 col-md-9 row container_design_1 mt-28 mx-0 ">
              <div className="col-4 pl-20">
                <p className="purple_label mt-26">1</p>

                <p className="card_heading mt-32">🔎 Assessment Call (free)</p>
                <p className="body_text_s color_desp_grey w-75 mt-12 mb-32">
                  We have a meeting to understand the scope of logs required for
                  your item/s.
                </p>
              </div>

              <div className="col-4 pl-20">
                <p className="purple_label mt-26">2</p>

                <p className="card_heading mt-32">📄 Quote</p>
                <p className="body_text_s color_desp_grey w-75 mt-12 mb-32">
                  After the call, we send you a quote without any commitment on
                  your end.
                </p>
              </div>

              <div className="col-4 pl-20">
                <p className="purple_label mt-26">3</p>

                <p className="card_heading mt-32">🧑🏻‍💻 Setup</p>
                <p className="body_text_s color_desp_grey w-75 mt-12 mb-32">
                  We set up a secure file storage for you to upload the emission
                  data we requested from you.
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : currStep == 2 ? (
        <div className="ml-116 mr-62">
          <div className="mt-34 d-flex justify-content-between align-items-center">
            <p className=" breadcrumb_text fw-400 color_ba">
              <span
                onClick={() => history("/dashboard?tab=9")}
                className="pointer"
              >
                store
              </span>{" "}
              /{" "}
              <span onClick={() => history(-1)} className="pointer">
                our services
              </span>{" "}
              / <span className="fw-700">{qitem ? qitem : ""}</span>
            </p>
          </div>
          <div className="main_box mt-50">
            <div className="">
              <p className="font_17_700 text_capital">{qitem ? qitem : ""}</p>
            </div>
            <ScheduleCall />
          </div>
        </div>
      ) : (
        <div className="ml-116 row me-0">
          <div className="col-7 store_card_container border_left_0">
            {checkStep === 0 ? (
              <div className="mt-40">
                <p className="heading_1 mt-16 mb-16">Store</p>
                <Box sx={{ width: "100%" }}>
                  <Box sx={{ borderBottom: 0, borderColor: "divider" }}>
                    <Tabs
                      value={value}
                      onChange={handleValChange}
                      aria-label="basic tabs example"
                      className="general_root_tabs"
                    >
                      <Tab label={t("store.generalItems")} {...a11yProps(0)} />
                      <Tab label={t("store.industrySpecificItems")} {...a11yProps(1)} />
                      {/* <Tab label="Our Services" {...a11yProps(2)} /> */}
                    </Tabs>
                  </Box>
                  <TabPanel
                    value={value}
                    index={0}
                    className="general_tabpanel"
                    isOther={false}
                  >
                    <div className="row pb-40 store_card_body">
                      {cardDetails &&
                        cardDetails
                          .filter((item) => item.category === "general")
                          .map((item, i) => (
                            <div key={i} className={`col-6 mt-30`}>
                              <StoreCard
                                item={item}
                                cartDetails={cartDetails}
                                handleQuantityChange={handleQuantityChange}
                              />
                            </div>
                          ))}
                    </div>
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={1}
                    isOther={false}
                    className="general_tabpanel"
                  >
                    <div className="row pb-40 store_card_body">
                      {cardDetails &&
                        cardDetails
                          .filter((item) => item.category === "industry")
                          .map((item, i) => (
                            <div
                              key={i}
                              className={`col-6 mt-30 pointer ${
                                i % 2 === 0 ? "pe-3 ps-0" : "ps-3 pe-0"
                              }`}
                            >
                              <StoreCard
                                item={item}
                                cartDetails={cartDetails}
                                handleQuantityChange={handleQuantityChange}
                              />
                            </div>
                          ))}
                    </div>
                    {/* <div className="container_design_1 mt-40 row mb-20 mx-0">
                      <div className="col-6 pl-20">
                        <p className="font_36_700 mt-22">Our Services</p>

                        <p className="mt-10 body_text_s_light">
                          We provide a number of different services to support
                          you on your climate action journey.
                        </p>
                        <br />
                        <ButtonComp
                          status={1}
                          width="lg"
                          // className="primary_button_md p-10x-40y mt-24"

                          onClick={() => setValue(2)}
                        >
                          View
                        </ButtonComp>
                      </div>

                      <div className="col-6 p-0">
                        <img src={ServiceImg} className="image_styles w-100" />
                      </div>
                    </div> */}
                  </TabPanel>
                  <TabPanel
                    value={value}
                    index={2}
                    className="general_tabpanel"
                  >
                    <div className="row store_card_body pb-40">
                      {cardDetails &&
                        cardDetails
                          .filter((item) => item.category === "other")
                          .map((item, i) => (
                            <div
                              key={i}
                              className={`col-6 mt-30 ${
                                i % 2 === 0 ? "pe-3 ps-0" : "ps-3 pe-0"
                              }`}
                            >
                              <StoreCard
                                item={item}
                                setCurrStep={setCurrStep}
                                cartDetails={cartDetails}
                                handleQuantityChange={handleQuantityChange}
                                isOther={true}
                              />
                            </div>
                          ))}
                    </div>
                  </TabPanel>
                </Box>
              </div>
            ) : (
              <StorePayment
                isAcceptTerms={isAcceptTerms}
                userCart={userCart}
                submitPayment={submitPayment}
                setsubmitPayment={setsubmitPayment}
                userAddress={userAddress}
                setUserAddress={setUserAddress}
                renew_id={renew_id}
              />
            )}
          </div>
          <div className="col-5 mt-160 store_cartsumm_body">
            <p className="subheading_3" style={{ marginTop: 5 }}>
              {t("store.cartSummary")}
            </p>

            <div className="" style={{ maxWidth: 348 }}>
              {cartDetails && cartDetails.length > 0
                ? cartDetails.map((item, i) => (
                    <div className="d-flex mt-40" key={i}>
                      <div className="flex-grow-1 d-flex justify-content-between">
                        <div>
                          <p className="f-16 font_15_600">{user?.language==='de'?item.name_de:item.name}</p>

                          <p className="subheading_light_3 mt-10">
                            {Number(item.price).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                            € /{t("store.credit")}
                          </p>
                        </div>
                        <div>
                          <p className="font_15_600 text-right">
                            {(item.price * item.quantity * 12).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            €
                          </p>
                          <p className="subheading_light_3 mt-10">
                          {t("store.Quantity")}: {item.quantity}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>

            <div className="mt-70" style={{ maxWidth: 348 }}>
              <div className="d-flex align-items-center">
                <p className="font_13_500 line_height_10 text-uppercase">
                {t("store.discountCode")}
                </p>
                <div
                  className="ml-10  pointer"
                  onClick={() => {
                    setopenDropDown(!openDropDown);
                  }}
                >
                  {openDropDown ? (
                    <KeyboardArrowUp
                      fontSize="small"
                      htmlColor="#575757"
                      style={{ marginTop: "-4px" }}
                    />
                  ) : (
                    <KeyboardArrowDown
                      fontSize="small"
                      htmlColor="#575757"
                      style={{ marginTop: "-4px" }}
                    />
                  )}
                </div>
              </div>
              {openDropDown && (
                <>
                  <div className="row mx-0 mt-13">
                    <input
                      className="border_input col-6 py-2 font_14_400 placeholder_8a"
                      placeholder={t("store.discountCode")}
                      value={d_code}
                      onChange={(e) => setd_code(e.target.value)}
                    />
                    {d_code && d_code.length > 0 && (
                      <div className="col-md-4 col-6 col-lg-4">
                        <ButtonComp
                          status={1}
                          onClick={() => setsubmitCode(true)}
                        >
                          {t("store.applyCode")}
                        </ButtonComp>
                      </div>
                    )}
                  </div>
                  {submitCode && d_code.length > 0 && (
                    <p className="color_red subheading_light_2">
                      The code is invalid, please try entering again.
                    </p>
                  )}
                </>
              )}

              <div className="border-btm_ef pb-20 mt-25">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="subheading_light_3">{t("store.subtotal")}</p>
                  <p className="subheading_light_3">
                    {userCart.total &&
                      userCart.total.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}{" "}
                    €
                  </p>
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mt-10">
                  <p className="subheading_light_3">Payment Fee</p>
                  <p className="subheading_light_3">12.00,00€</p>
                </div> */}
                <div className="d-flex align-items-center justify-content-between mt-10">
                  <p className="subheading_light_3">
                  {t("store.tax")} (
                    {userAddress && userAddress.Country === "Germany"
                      ? "19%"
                      : "0%"}
                    )
                  </p>
                  <p className="subheading_light_3">
                    {userAddress && userAddress.Country === "Germany"
                      ? userCart.tax &&
                        userCart.tax.toFixed(2).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + " €"
                      : Number(0).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }) + " €"}
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-10">
                <p className="heading_9">{t("store.total")}</p>
                <p className="heading_9">
                  {Number(grandTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}{" "}
                  €
                </p>
              </div>
              {checkStep === 1 && (
                <div className="mt-30 d-flex align-items-center">
                  <input
                    type="radio"
                    checked={isAcceptTerms}
                    value={isAcceptTerms}
                    onClick={() => setisAcceptTerms(!isAcceptTerms)}
                  />
                  <p className="overline_text ml-8">
                    {t("store.terms")}{" "}
                    <span style={{ borderBottom: "1px solid" }}>
                      <a
                        href="https://www.today.green/legal/agb"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fw-bold termsNconditionsAnchor"
                      >
                        {t("store.terms&cond")}
                      </a>
                    </span>{" "}
                    {t("store.fromTg")}
                  </p>
                </div>
              )}

              {checkStep === 0 ? (
                <div className="mt-30">
                  <ButtonComp
                    status={2}
                    style={{ width: "168px", height: 40 }}
                    onClick={() => {
                      setcheckStep(1);
                      history(`/dashboard?tab=9&checkout=1&renew=${renew_id}`);
                    }}
                  >
                    {t("store.goToCheckout")}
                  </ButtonComp>
                </div>
              ) : checkStep === 1 ? (
                <div className="mt-30">
                  <ButtonComp
                    status={1}
                    size="xl"
                    // className=" mt-30"
                    disabled={!isAcceptTerms}
                    onClick={() => setsubmitPayment(true)}
                  >
                    {t("store.purchaseNow")}
                  </ButtonComp>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Store;
