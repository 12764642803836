import React, { useEffect, useState, useRef } from "react";
import "./quickLog.css";
import { TextField, InputAdornment, Drawer } from "@mui/material";
import { getServiceIcon } from "../../../../../Utils/itemIcon";
import ButtonComp from "../../../../../Assets/components/Button";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
import itemData from "../../../../Companydashboard/LogDashboard/items-new.json";
import Axios from "../../../../../Api";
import location from "../../../../../DataJSON/companyLocation.json";
import event from "../../../../../DataJSON/event.json";
import hotel from "../../../../../DataJSON/hotel.json";
import product from "../../../../../DataJSON/product.json";
import service from "../../../../../DataJSON/service.json";
import realEstateA from "../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../DataJSON/building.json";
import agency from "../../../../../DataJSON/agency.json";
import education from "../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../DataJSON/shipping.json";
import skilledTrades from "../../../../../DataJSON/skilledTradesSector.json";
import { Close, HelpOutlined } from "@mui/icons-material";
import {
  getAccountingItems,
  getAllYearOfLog,
} from "../../../../../Utils/CommonApiRequests";
import LeftChevronIcon from "../../../../../Assets/icons/LeftChevronIcon";
import RightChevronIcon from "../../../../../Assets/icons/RightChevronIcon";
import EmissionPeriod from "./Components/EmissionPeriod";
import { FullMonthNames } from "../../../../../Utils/CommonFunctions";
import CalculationMethod from "./Components/CalculationMethod";
import toast from "react-hot-toast";
import EndScreen from "./Components/EndScreen";
import SeachBigCircleIcon from "../../../../../Assets/icons/SeachBigCircleIcon";

const data = {
  location,
  event,
  hotel,
  product,
  service,
  "real-estate agency": realEstateA,
  "real estate development": realEstateD,
  building,
  agency,
  "educational institution": education,
  shipping,
  "skilled trades company": skilledTrades,
};

const amountStructure = {
  amount: "",
  comment: "",
  noOfUnit: 1,
  emission: 0,
  isEditable: true,
};

function QuickLog(props) {
  const { setSessionExpire } = useAuth();
  const scrollContainerRef = useRef();
  // const [allAccountingItemsData, setAllAccountingItemsData] = useState([]);
  const [accountingItemsList, setAccountingItemList] = useState([]);
  const [itemSelectionList, setItemSelectionList] = useState([]);
  const [quickLogStep, setQuickLogStep] = useState(0);
  const [accItemScopes, setAccItemScopes] = useState({});
  const [scopeList, setScopeList] = useState([]);
  const [classList, setClassList] = useState([]);
  const [periodType, setPeriodType] = useState("Month");
  const [yearList, setYearList] = useState([]);
  const [activities, setActivities] = useState(null);
  const [activityTypes, setActivityTypes] = useState(null);
  const [activitySubTypes, setActivitySubTypes] = useState(null);
  const [allUnits, setAllUnits] = useState([]);
  const [amounts, setAmounts] = useState([{ ...amountStructure }]);
  const [scopeIndex, setScopeIndex] = useState(null);
  const [filterValue, setFilterValue] = useState("");
  const [showTimeframeAtHeader, setShowTimeframeAtHeader] = useState(false);

  const [selectedAccItem, setSelectedAccItem] = useState(null);
  const [selectedScope, setSelectedScope] = useState(null);
  const [selectedSubClass, setSelectedSubClass] = useState(null);
  const [selectedsubClassHead, setSelectedSubClassHead] = useState("");
  const [selectedCalcMethodName, setSelectedCalcMethodName] = useState("");
  const [selectedActivityName, setSelectedActivityName] = useState("");
  const [selectedActivityTypeName, setSelectedActivityTypeName] = useState("");
  const [selectedActivitySubTypeName, setSelectedActivitySubTypeName] =
    useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const cleanAllStates = () => {
    setAccItemScopes({});
    setScopeList([]);
    setClassList([]);
    setPeriodType("Month");
    setYearList([]);
    setActivities(null);
    setActivityTypes(null);
    setActivitySubTypes(null);
    setAllUnits([]);
    setAmounts([{ ...amountStructure }]);
    setScopeIndex(null);
    setSelectedAccItem(null);
    setSelectedScope(null);
    setSelectedSubClass(null);
    setSelectedSubClassHead("");
    setSelectedCalcMethodName("");
    setSelectedActivityName("");
    setSelectedActivityTypeName("");
    setSelectedActivitySubTypeName("");
    setSelectedUnit("");
    setSelectedMonth(FullMonthNames[0]);
    setSelectedYear(new Date().getFullYear().toString());
    setQuickLogStep(0);
    setShowTimeframeAtHeader(false);
  };

  useEffect(() => {
    (async () => {
      const res = await getAccountingItems();
      const tempItemListFilter = itemData.filter((data) =>
        res.accountingItemCategories.includes(data.name)
      );
      let tempItemList = tempItemListFilter.map((currentItem) => {
        return {
          name: currentItem.name,
          id: currentItem._id,
        };
      });
      setAccountingItemList(tempItemList);
      getAllItems();
    })();
  }, []);

  const getScopeWiseList = () => {
    let accItemJsonData =
      data[selectedAccItem.accountingItemName.toLowerCase()];
    let scopesList = [];
    let itemName = Object.keys(accItemJsonData)[0];
    let classesList = Object.keys(accItemJsonData[itemName]);
    classesList.forEach((currClass) => {
      let currentClassScopes = Object.keys(
        accItemJsonData[itemName][currClass]
      );
      let currentScopeObjects = currentClassScopes.map((currScope) => {
        return {
          superClass: currClass,
          name: currScope,
          selected: false,
        };
      });
      scopesList = [...scopesList, ...currentScopeObjects];
    });
    setScopeList(scopesList);
  };
  const getStep2Data = () => {
    let accItemJsonData =
      data[selectedAccItem.accountingItemName.toLowerCase()];
    let itemName = Object.keys(accItemJsonData)[0];
    setClassList(
      accItemJsonData[itemName][selectedScope.superClass][selectedScope.name]
        .classes
    );
  };
  const handleStepIncrease = () => {
    if (quickLogStep === 0 && selectedAccItem) {
      setQuickLogStep(1);
    } else if (quickLogStep === 1 && selectedScope) {
      setQuickLogStep(2);
    } else if (quickLogStep === 2 && selectedSubClass && selectedsubClassHead) {
      setQuickLogStep(3);
    } else if (quickLogStep === 3 && selectedMonth && selectedYear) {
      setQuickLogStep(4);
    }
    setFilterValue("");
  };

  const handleStepDecrease = () => {
    if (quickLogStep > 0) {
      setQuickLogStep(quickLogStep - 1);
      setFilterValue("");
    }
  };
  useEffect(() => {
    if (quickLogStep === 1) {
      getScopeWiseList();
    } else if (quickLogStep === 2) {
      getStep2Data();
    } else if (quickLogStep === 4) {
      setShowTimeframeAtHeader(true);
    }
  }, [quickLogStep]);

  const handleSelectionAtStep1 = (scopeName, data) => {
    const findScopeIndex = selectedAccItem.matrixItems.findIndex(
      (item) => item.scope === data.name
    );
    setScopeIndex(findScopeIndex);
    setSelectedScope(data);
    let tempDataSelection = [...scopeList];

    let prevSelectedIndex = tempDataSelection.findIndex(
      (currentLog) => currentLog.selected
    );
    if (prevSelectedIndex !== -1) {
      tempDataSelection[prevSelectedIndex].selected = false;
    }
    let scopeIndex = tempDataSelection.findIndex(
      (currScope) => currScope.name === scopeName
    );

    tempDataSelection[scopeIndex].selected =
      !tempDataSelection[scopeIndex].selected;

    setScopeList(tempDataSelection);
    // reset states
    setSelectedCalcMethodName("");
    setActivities(null);
    setActivityTypes(null);
    setActivitySubTypes(null);
    setAllUnits([]);
    setAmounts([{ ...amountStructure }]);
  };

  const handleSelectionAtStep2 = (currentClass, subclassHead) => {
    setSelectedSubClassHead(subclassHead);
    setSelectedSubClass(currentClass);
    setSelectedCalcMethodName("");
    setActivities(null);
    setActivityTypes(null);
    setActivitySubTypes(null);
    setAllUnits([]);
    setAmounts([{ ...amountStructure }]);
  };
  const selectAccItem = async (log) => {
    let currentScopeList = itemData.find(
      (currentItem) =>
        currentItem.name.toLowerCase() === log.accountingItemName.toLowerCase()
    );
    setSelectedAccItem(log);
    const years = await getAllYearOfLog(log._id);
    setYearList(years);
    setAccItemScopes(currentScopeList.scopes);
    // reset states
    setSelectedCalcMethodName("");
    setActivities(null);
    setActivityTypes(null);
    setActivitySubTypes(null);
    setAllUnits([]);
    setAmounts([{ ...amountStructure }]);
  };

  const getAllItems = async () => {
    try {
      let response = await Axios.get("/log/all-user-items");
      if (response.data.success) {
        setItemSelectionList(response.data.data);
        // setAllAccountingItemsData(response.data.data);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  // create log api
  const handleMatrixCellSubmit = async () => {
    for (const element of amounts) {
      if (
        element.amount === "" ||
        element.amount === null ||
        element.amount === "0" ||
        element.amount === Number(0)
      ) {
        return toast.error(
          "Amount cannot be empty. please fill all the required fields"
        );
      }
      if (element.comment.length > 260) {
        return toast.error("Comment can't have more than 260 characters");
      }
    }
    try {
      const matriCellData = {
        matrix_id: "",
        GHGscope: "",
        logId: selectedAccItem._id,
        itemId: selectedAccItem.itemId,
        isFullYear: periodType === "Month" ? false : true,
        month: selectedMonth + 1,
        year: Number(selectedYear),
        scopeClass: selectedScope.superClass,
        scopeIndex: scopeIndex,
        subScope: selectedScope.name,
        particulars: {
          name: "",
          activity: selectedActivityName,
          calculationMethod: selectedCalcMethodName,
          type: selectedActivityTypeName,
          subType: selectedActivitySubTypeName,
          inputMethod: "",
          subClassHead: selectedsubClassHead,
          subClass: selectedSubClass.Subclass,
          conversionFactor:
            selectedCalcMethodName === "Direct tCO2e Entry"
              ? 1
              : selectedUnit.kgCO2e,
          unit: selectedUnit.unit,
          amounts: amounts,
        },
      };
      await Axios({
        url: `/cell/create-cell`,
        method: "POST",
        data: matriCellData,
      })
        .then((res) => {
          if (res.data.success) {
            setQuickLogStep(quickLogStep + 1);
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          toastResponseError(err, setSessionExpire);
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getIconName = (item) => {
    if (item.itemDetails.facilityType) return item.itemDetails.facilityType;
    else if (item.itemDetails.productType) {
      return item.itemDetails.productType;
    } else if (item.itemDetails.event) {
      return item.itemDetails.event;
    } else if (item.itemDetails.hotelType) {
      return item.itemDetails.hotelType;
    } else if (item.itemDetails.buildingType) {
      return item.itemDetails.buildingType;
    } else return item.accountingItemName;
  };

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
  }, [
    scrollContainerRef.current,
    selectedAccItem,
    selectedScope,
    selectedSubClass,
    showTimeframeAtHeader,
  ]);

  return (
    <Drawer
      anchor={"right"}
      open={props.quickLogDrawer}
      onClose={() => {
        if (
          (quickLogStep === 0 && selectedAccItem === null) ||
          quickLogStep === 5
        ) {
          cleanAllStates();
          props.handleQuickLogDrawer();
        }
      }}
      PaperProps={{
        sx: { overflow: "hidden" },
      }}
      // sx={{ zIndex: 10000000000 }}
    >
      <div className="quickLog-container">
        <div className="quickLog-main">
          <div className="d-flex justify-content-between align-items-center">
            {quickLogStep === 5 ? (
              <p className="font_24_500 line_height_10 ">
                Your emission log was added.
              </p>
            ) : (
              <div className="d-flex align-items-center">
                <p className="font_24_500 ">
                  {quickLogStep === 0
                    ? "Accounting item"
                    : quickLogStep === 1
                    ? "Scope"
                    : quickLogStep === 2
                    ? "Emission Source"
                    : quickLogStep === 3
                    ? "Emission Period"
                    : "Activity Data"}
                </p>
                <div className="center border_radius_5 bg_white pointer h-40px w-40px ml-20 drop_shadow">
                  <div className="circle_20 center bg_black">
                    <p className="font_14_600 text-white line_height_normal">
                      ?
                    </p>
                  </div>
                </div>
              </div>
            )}
            <Close
              htmlColor="#4C4C4C"
              className="pointer"
              onClick={() => {
                cleanAllStates();
                props.handleQuickLogDrawer();
              }}
            />
          </div>
          {selectedAccItem && quickLogStep < 5 && (
            <div
              className="mt-30 d-flex align-items-center gap_10 quick_show_items_main_box"
              ref={scrollContainerRef}
            >
              <div className="bg_soft_green_53a border_radius_5 quick_show_items_box">
                <p className="font_12_500 line_height_10 text-white">
                  {selectedAccItem.itemDetails.name}
                </p>
              </div>
              {selectedScope && (
                <div className="bg_dark_yellow border_radius_5 quick_show_items_box">
                  <p className="font_12_500 line_height_10 text-white">
                    {selectedScope.name}
                  </p>
                </div>
              )}
              {selectedSubClass && (
                <div className="bg_soft_purple border_radius_5 quick_show_items_box">
                  <p className="font_12_500 line_height_10 text-white">
                    {selectedSubClass.Subclass}
                  </p>
                </div>
              )}
              {showTimeframeAtHeader && (selectedMonth || selectedYear) && (
                <div className="bg_soft_sky_blue border_radius_5 quick_show_items_box">
                  {periodType === "Month" ? (
                    <p className="font_12_500 line_height_10 text-white">
                      {FullMonthNames[selectedMonth]} - {selectedYear}
                    </p>
                  ) : (
                    <p className="font_12_500 line_height_10 text-white">
                      {selectedYear}
                    </p>
                  )}
                </div>
              )}
            </div>
          )}
          {quickLogStep <= 2 && (
            <div className="mt-30">
              <TextField
                value={filterValue}
                onChange={(e) => setFilterValue(e.target.value)}
                className="font_12_500"
                id="input-with-icon-textfield"
                placeholder={
                  quickLogStep === 0
                    ? "Accounting Item"
                    : quickLogStep === 1
                    ? "Subscope"
                    : "Emission Source"
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SeachBigCircleIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{
                  width: "100%",
                  borderRadius: "5px",
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
                  "& .MuiInputBase-root": {
                    height: 40,
                    width: "100%",
                    border: "none",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "& .MuiInputBase-input": {
                    fontFamily: "Inter",
                    fontSize: " 12px",
                    fontStyle: "normal",
                    fontWeight: 500,
                    lineHeight: "10px",
                    color: "7E8999",
                    paddingLeft: "4px",
                  },
                }}
              />
            </div>
          )}

          {quickLogStep === 0 ? (
            accountingItemsList.map((currentItem) => {
              return (
                <div key={currentItem.id} className="mt-30">
                  {itemSelectionList
                    .filter(
                      (currentLog) => currentLog.itemId === currentItem.id
                    )
                    .filter((currentLog) => {
                      if (filterValue === "") {
                        return currentLog;
                      }
                      return String(currentLog.itemDetails.name)
                        .toLowerCase()
                        .includes(filterValue.toLowerCase());
                    }).length > 0 && (
                    <p className="font_14_600 line_height_10 color_7e">
                      {currentItem.name}(s)
                    </p>
                  )}

                  {itemSelectionList
                    .filter(
                      (currentLog) => currentLog.itemId === currentItem.id
                    )
                    .filter((currentLog) => {
                      if (filterValue === "") {
                        return currentLog;
                      }
                      return String(currentLog.itemDetails.name)
                        .toLowerCase()
                        .includes(filterValue.toLowerCase());
                    })
                    .map((currentLog) => {
                      if (!currentLog.isPaused && currentLog.noOfScopes > 0) {
                        return (
                          <div
                            key={"item" + currentLog._id}
                            className="d-flex justify-content-between align-items-center bg_f6 pl-10 pt-10 pr-10 pb-10 mt-10 pointer border_radius_5"
                            onClick={() => {
                              selectAccItem(currentLog);
                            }}
                          >
                            {/* {console.log(getIconName(currentLog))} */}
                            <div className="d-flex align-items-center">
                              <img
                                className="img_square_20 border-radius-none"
                                src={getServiceIcon(getIconName(currentLog))}
                                alt="accounting item "
                              />
                              <p className="font_14_500 line_height_normal text-capitalize ml-10 text_overflow-1">
                                {currentLog.itemDetails.name}
                              </p>
                            </div>

                            <div className="">
                              {selectedAccItem &&
                              currentLog._id === selectedAccItem._id ? (
                                <div
                                  onClick={() => {
                                    selectAccItem(currentLog);
                                  }}
                                  className="quickLog-radio-container w-25px h-25px bg_009 br_round pointer"
                                ></div>
                              ) : (
                                <div
                                  onClick={() => {
                                    selectAccItem(currentLog);
                                  }}
                                  className="quickLog-radio-container w-25px h-25px bg_white br_round pointer"
                                ></div>
                              )}
                            </div>
                          </div>
                        );
                      }
                    })}
                </div>
              );
            })
          ) : quickLogStep === 1 ? (
            <div className="mt-30">
              <div>
                <p className="font_14_600 color_7e">Scope 1</p>
                {scopeList
                  .filter((currentScope) => {
                    let currentScopesName = Object.keys(accItemScopes);
                    let isPresent = currentScopesName.findIndex(
                      (currScope) =>
                        currScope.toLowerCase() ===
                        currentScope.name.toLowerCase()
                    );
                    if (isPresent === -1) return false;
                    return (
                      accItemScopes[currentScope.name] &&
                      accItemScopes[currentScopesName[isPresent]].scope === 1
                    );
                  })
                  .filter((currentScope) => {
                    if (filterValue === "") {
                      return currentScope;
                    }
                    return String(currentScope.name)
                      .toLowerCase()
                      .includes(filterValue.toLowerCase());
                  })
                  .map((currentScope, currentScopeIndex) => {
                    return (
                      <div
                        key={"scope1" + currentScopeIndex}
                        className="d-flex border_radius_5 justify-content-between align-items-center bg_f6 pl-10 pt-10 pr-10 pb-10 mt-10 pointer"
                        onClick={() => {
                          handleSelectionAtStep1(
                            currentScope.name,
                            currentScope
                          );
                        }}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={getServiceIcon(currentScope.name)}
                            alt="accounting item "
                            className="img_square_20 border-radius-none"
                          />
                          <p className="font_14_500 ml-10 line_height_normal text_overflow-1">
                            {currentScope.name}
                          </p>
                          <HelpOutlined
                            htmlColor="#7E8999"
                            className="ml-20"
                            fontSize="small"
                          />
                        </div>

                        <div className="">
                          {selectedScope &&
                          selectedScope.name === currentScope.name ? (
                            <div
                              onClick={() => {
                                handleSelectionAtStep1(
                                  currentScope.name,
                                  currentScope
                                );
                              }}
                              className="quickLog-radio-container w-25px h-25px bg_009 br_round"
                            ></div>
                          ) : (
                            <div
                              onClick={() => {
                                handleSelectionAtStep1(
                                  currentScope.name,
                                  currentScope
                                );
                              }}
                              className="quickLog-radio-container w-25px h-25px bg_white br_round"
                            ></div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="mt-30">
                <p className="font_14_600 color_7e">Scope 2</p>
                {scopeList
                  .filter((currentScope) => {
                    let currentScopesName = Object.keys(accItemScopes);
                    let isPresent = currentScopesName.findIndex(
                      (currScope) =>
                        currScope.toLowerCase() ===
                        currentScope.name.toLowerCase()
                    );
                    if (isPresent === -1) return false;
                    return (
                      accItemScopes[currentScope.name] &&
                      accItemScopes[currentScopesName[isPresent]].scope === 2
                    );
                  })
                  .filter((currentScope) => {
                    if (filterValue === "") {
                      return currentScope;
                    }
                    return String(currentScope.name)
                      .toLowerCase()
                      .includes(filterValue.toLowerCase());
                  })
                  .map((currentScope, currentScopeIndex) => {
                    return (
                      <div
                        key={"scope2" + currentScopeIndex}
                        className="d-flex justify-content-between align-items-center bg_f6 pl-10 pt-10 pr-10 pb-10 mt-10"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={getServiceIcon(currentScope.name)}
                            alt="accounting item "
                            className="img_square_20 border-radius-none"
                          />
                          <p className="font_14_500 ml-10 line_height_normal text_overflow-1">
                            {currentScope.name}
                          </p>
                          <HelpOutlined
                            htmlColor="#7E8999"
                            className="ml-20"
                            fontSize="small"
                          />
                        </div>

                        <div className="">
                          {selectedScope &&
                          selectedScope.name === currentScope.name ? (
                            <div
                              onClick={() => {
                                handleSelectionAtStep1(
                                  currentScope.name,
                                  currentScope
                                );
                              }}
                              className="quickLog-radio-container w-25px h-25px bg_009 br_round"
                            ></div>
                          ) : (
                            <div
                              onClick={() => {
                                handleSelectionAtStep1(
                                  currentScope.name,
                                  currentScope
                                );
                              }}
                              className="quickLog-radio-container w-25px h-25px bg_white br_round"
                            ></div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>

              <div className="mt-30">
                <p className="font_14_600 color_7e">Scope 3</p>
                {scopeList
                  .filter((currentScope) => {
                    let currentScopesName = Object.keys(accItemScopes);
                    let isPresent = currentScopesName.findIndex(
                      (currScope) =>
                        currScope.toLowerCase() ===
                        currentScope.name.toLowerCase()
                    );
                    if (isPresent === -1) return false;
                    return (
                      accItemScopes[currentScope.name] &&
                      accItemScopes[currentScopesName[isPresent]].scope === 3
                    );
                  })
                  .filter((currentScope) => {
                    if (filterValue === "") {
                      return currentScope;
                    }
                    return String(currentScope.name)
                      .toLowerCase()
                      .includes(filterValue.toLowerCase());
                  })
                  .map((currentScope, currentScopeIndex) => {
                    return (
                      <div
                        key={"scope3" + currentScopeIndex}
                        className="d-flex justify-content-between align-items-center bg_f6 pl-10 pt-10 pr-10 pb-10 mt-10"
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={getServiceIcon(currentScope.name)}
                            alt="accounting item "
                            className="img_square_20 border-radius-none"
                          />
                          <p className="font_14_500 ml-10 line_height_normal text_overflow-1">
                            {currentScope.name}
                          </p>
                          <HelpOutlined
                            htmlColor="#7E8999"
                            className="ml-20"
                            fontSize="small"
                          />
                        </div>

                        <div className="">
                          {selectedScope &&
                          selectedScope.name === currentScope.name ? (
                            <div
                              onClick={() => {
                                handleSelectionAtStep1(
                                  currentScope.name,
                                  currentScope
                                );
                              }}
                              className="quickLog-radio-container w-25px h-25px bg_009 br_round"
                            ></div>
                          ) : (
                            <div
                              onClick={() => {
                                handleSelectionAtStep1(
                                  currentScope.name,
                                  currentScope
                                );
                              }}
                              className="quickLog-radio-container w-25px h-25px  bg_white br_round"
                            ></div>
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : quickLogStep === 2 ? (
            classList.map((currentClass, currentClassIndex) => {
              const dataArr = Object.entries(currentClass);
              const heading = dataArr[0][0];
              const subclasses = dataArr[0][1];
              return (
                <div className="mt-30" key={currentClass + currentClassIndex}>
                  <p className="font_14_600 color_7e">{heading}</p>

                  {subclasses &&
                    subclasses.length > 0 &&
                    subclasses
                      .filter((currentSubClass) => {
                        if (filterValue === "") {
                          return currentSubClass;
                        }
                        return String(currentSubClass.Subclass)
                          .toLowerCase()
                          .includes(filterValue.toLowerCase());
                      })
                      .map((currentSubClass, currentSubClassIndex) => {
                        return (
                          <div
                            key={
                              "subclass" +
                              currentSubClass.name +
                              currentSubClassIndex
                            }
                            className="d-flex border_radius_5 justify-content-between align-items-center bg_f6 pl-10 pt-10 pr-10 pb-10 mt-10 pointer"
                            onClick={() => {
                              handleSelectionAtStep2(currentSubClass, heading);
                            }}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                src={getServiceIcon(currentSubClass.Subclass)}
                                alt="accounting item "
                                className="img_square_20 border-radius-none"
                              />
                              <p className="font_14_500 ml-10 line_height_normal text_overflow-1">
                                {currentSubClass.Subclass}
                              </p>
                            </div>
                            <div className="">
                              {selectedSubClass &&
                              selectedsubClassHead &&
                              selectedSubClass.Subclass ===
                                currentSubClass.Subclass &&
                              selectedsubClassHead === heading ? (
                                <div
                                  onClick={() => {
                                    handleSelectionAtStep2(
                                      currentSubClass,
                                      heading
                                    );
                                  }}
                                  className="quickLog-radio-container w-25px h-25px bg_009 br_round"
                                ></div>
                              ) : (
                                <div
                                  onClick={() => {
                                    handleSelectionAtStep2(
                                      currentSubClass,
                                      heading
                                    );
                                  }}
                                  className="quickLog-radio-container w-25px h-25px bg_white br_round"
                                ></div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                </div>
              );
            })
          ) : quickLogStep === 3 ? (
            <EmissionPeriod
              periodType={periodType}
              setPeriodType={setPeriodType}
              yearList={yearList}
              selectedMonth={selectedMonth}
              setSelectedMonth={setSelectedMonth}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              logId={selectedAccItem?._id}
              isQuickLog={true}
            />
          ) : quickLogStep === 4 ? (
            <CalculationMethod
              selectedSubClass={selectedSubClass}
              selectedCalcMethodName={selectedCalcMethodName}
              setSelectedCalcMethodName={setSelectedCalcMethodName}
              selectedActivityName={selectedActivityName}
              setSelectedActivityName={setSelectedActivityName}
              selectedActivityTypeName={selectedActivityTypeName}
              setSelectedActivityTypeName={setSelectedActivityTypeName}
              selectedActivitySubTypeName={selectedActivitySubTypeName}
              setSelectedActivitySubTypeName={setSelectedActivitySubTypeName}
              activities={activities}
              setActivities={setActivities}
              activityTypes={activityTypes}
              setActivityTypes={setActivityTypes}
              activitySubTypes={activitySubTypes}
              setActivitySubTypes={setActivitySubTypes}
              allUnits={allUnits}
              setAllUnits={setAllUnits}
              selectedUnit={selectedUnit}
              setSelectedUnit={setSelectedUnit}
              amounts={amounts}
              setAmounts={setAmounts}
              amountStructure={amountStructure}
            />
          ) : (
            quickLogStep === 5 && (
              <EndScreen
                cleanAllStates={cleanAllStates}
                handleQuickLogDrawer={() => props.handleQuickLogDrawer()}
              />
            )
          )}
        </div>

        <div className="quickLog-footer">
          {quickLogStep > 0 && (
            <ButtonComp
              onClick={() => {
                handleStepDecrease();
              }}
              status={6}
              size="sm"
              style={{ height: 40 }}
              className="bg_d9"
            >
              <LeftChevronIcon className={"mr-10"} /> Go Back
            </ButtonComp>
          )}
          {quickLogStep === 4 ? (
            <ButtonComp
              onClick={() => {
                handleMatrixCellSubmit();
              }}
              status={5}
              size="sm"
              style={{ height: 40 }}
            >
              Save & Add
            </ButtonComp>
          ) : (
            quickLogStep < 4 && (
              <ButtonComp
                onClick={() => {
                  handleStepIncrease();
                  // handleMatrixCellSubmit();
                }}
                status={5}
                size="sm"
                style={{ height: 40 }}
              >
                Next Step <RightChevronIcon className={"ml-10"} />
              </ButtonComp>
            )
          )}
        </div>
      </div>
    </Drawer>
  );
}

export default QuickLog;
