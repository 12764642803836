import React from "react";
import { BarChart, Bar, ResponsiveContainer, Cell } from "recharts";

const barColors = ["#2441F5", "#CDD4FF"];

const OffsetTypeBarChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={270}>
      <BarChart width={150} height={40} data={data}>
        <Bar dataKey="amount" fill="#8884d8" radius={[10, 10, 10, 10]}>
          {data &&
            data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={barColors[index]} />
            ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default OffsetTypeBarChart;
