import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
// import { Avatar, CircularProgress } from "@mui/material";
// import { GppGood } from "@mui/icons-material";
import { Box, Modal } from "@mui/material";
import axios from "../../../Api";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../Utils/toastResponseError";
// import EditIcon from "../../../Assets/icons/EditIcon";
import ImageUploadCropper from "../../CommonComponents/ImageUploadCropper/ImageUploadCropper";
import ButtonComp from "../../../Assets/components/Button";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "white",
  boxShadow: 24,
  padding: "24px",
  borderRadius: "10px",
  outline: "none",
};

const EditProfile = ({ setprofileEditStep }) => {
  const {  i18n,t } = useTranslation();
  const navigate = useNavigate();
  const { user, profile, setSessionExpire, getUser } = useAuth();
  const [firstName, setFirstName] = useState();
  const [lastName, setlastName] = useState();
  const [email, setEmail] = useState();
  const [avatar, setAvatar] = useState("");
  // const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputFile = useRef();
  const eightChar = useRef();
  const containsNumber = useRef();
  const containsLetter = useRef();
  const containsSymbol = useRef();

// Change langugae
const activeLanguage = i18n.language;
const [lang,setLang] =  useState(activeLanguage)
const handleLangChange = (e) => {
  i18n.changeLanguage(e.target.value);
  setLang(e.target.value)
}

  function hasNumber(myString) {
    return /\d/.test(myString);
  }

  function containsUppercase(myString) {
    return /[A-Z]/.test(myString);
  }

  function containsSpecialChars(str) {
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
    return specialChars.test(str);
  }

  useEffect(() => {
    if (user) {
      setFirstName(user.firstname);
      setlastName(user.lastname);
      setEmail(user.email);
      setAvatar(user.avatar);
      setLang(user.language)
    }
  }, [user]);

  const handlePicUpload = async (imageFile) => {
    // const image = e.target.files[0];
    // if (image.size > 2048 * 1024) {
    //   toast.error("File size exceeded 2MB!", { position: "bottom-right" });
    //   return;
    // }
    const formdata = new FormData();
    formdata.append("logo", imageFile);
    // setIsLogoUploading(true);
    try {
      const res = await axios({
        method: "post",
        url: `/user/auth/logo`,
        data: formdata,
      });
      if (res) {
        toast.success("Profile Image upload");
        return res.data.location;
      } else {
        toast.error("Something went wrong, please try again");
        return false;
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
      return false;
    }
  };

  const changeProfileDetails = async () => {
    await axios({
      url: `/user/auth/edit/user`,
      method: "put",
      data: {
        firstname: firstName,
        lastname: lastName,
        email: email,
        avatar: avatar,
        language:lang
      },
    })
      .then((res) => {
        if (res.data.success) {
          setprofileEditStep(1);
          navigate(-1);
          getUser();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const changePassword = async () => {
    const password_regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/;
    if (!currentPassword || !newPassword || !confirmPassword) {
      return toast.error("Fields must be filled");
    }
    if (newPassword !== confirmPassword) {
      return toast.error("password should be match with confirm password");
    }
    if (!password_regex.test(newPassword)) {
      return toast.error("password should pass all criteria");
    }
    await axios({
      url: `/user/auth/change-password`,
      method: "put",
      data: {
        oldpassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmPassword,
      },
    })
      .then((res) => {
        if (res.data.success) {
          handleClose();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (
      eightChar.current &&
      containsNumber.current &&
      containsLetter.current &&
      containsSymbol.current
    ) {
      if (newPassword.length >= 8) {
        eightChar.current.checked = true;
      } else {
        eightChar.current.checked = false;
      }
      if (hasNumber(newPassword)) {
        containsNumber.current.checked = true;
      } else {
        containsNumber.current.checked = false;
      }
      if (containsUppercase(newPassword)) {
        containsLetter.current.checked = true;
      } else {
        containsLetter.current.checked = false;
      }
      if (containsSpecialChars(newPassword)) {
        containsSymbol.current.checked = true;
      } else {
        containsSymbol.current.checked = false;
      }
    }
  }, [newPassword]);

  
  return (
    <div className="profile_setting_card">
      <div className="border-btm_dc d-flex align-items-center justify-content-between pb-24">
        <p className="offsetProjectTitle">{t("profile.profileSettings")}</p>
        <ButtonComp
          status={1}
          className="md"
          onClick={() => {
            changeProfileDetails();
          }}
        >
          {t("common.save")}
        </ButtonComp>
      </div>
      <div className="border-btm_dc d-flex align-items-center pt-24 pb-24">
        <ImageUploadCropper
          avatar={avatar}
          handlePicUpload={handlePicUpload}
          inputFileRef={inputFile}
          setAvatar={setAvatar}
        />

        <div className="row ml-24 flex-grow-1">
          <div className="col-lg-4 col-md-6 col-12">
            <div className="input_div">
              <label className="subheading_1" htmlFor="firstName">
                {t("common.firstName")}
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="mt-12 subheading_2"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="John"
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="input_div">
              <label className="subheading_1" htmlFor="lastName">
              {t("common.lastName")}
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="doe"
                className="mt-12 subheading_2"
                value={lastName}
                onChange={(e) => setlastName(e.target.value)}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-12">
            <div className="input_div">
              <label className="subheading_1" htmlFor="email">
                {t("common.email")}
              </label>
              <input
                type="text"
                placeholder="abc@xyz.com"
                name="email"
                id="email"
                className="mt-12 subheading_2"
                value={email}
                disabled
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="border-btm_dc row pt-32 pb-40">
        <div className="col-lg-4 col-md-6 col-12">
          <div className="input_div">
            <label className="subheading_1" htmlFor="password">
              {t("profile.password")}
            </label>
            <input
              type="password"
              name="password"
              id="password"
              className="mt-12 subheading_2"
              placeholder="**************"
              disabled
            />
            <div className="mt-8">
              <p
                className="overline_text underline pointer"
                onClick={handleOpen}
              >
                 {t("profile.changePassword")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-40">
        <div className="row">
          <div className="col-lg-2 col-md-3 col-sm-3 col-4">
            <p className="font_14_500">{t("common.company")}</p>
          </div>
          <div className="col-3">
            <p className="body_text_m">{profile?.companyName}</p>
          </div>
        </div>
        <div className="row mt-24">
          <div className="col-lg-2 col-md-3 col-sm-3 col-4">
            <p className="font_14_500">{t("profile.language")}</p>
          </div>
          <div className="col-3">
            <select className="select_input subheading_2" name="language" value={lang} onChange={handleLangChange}>
              <option className="subheading_2" value="en">
                English
              </option>
              <option className="subheading_2" value="de">
              German
              </option>

              {/* <option className="subheading_2" value="English">
                English
              </option> */}
            </select>
          </div>
        </div>
        {/* <div className="row mt-24">
          <div className="col-lg-2 col-md-3 col-sm-3 col-4">
            <p className="font_14_500">Time Zone</p>
          </div>
          <div className="col-3">
            <select className="select_input subheading_2" name="timezome">
              <option className="subheading_2" value=""></option>
              <option className="subheading_2" value="Germany">
                India (GMT+4:30)
              </option>
            </select>
          </div>
        </div> */}
      </div>
      {/* <div className="mt-48 secureAccount_learn_box">
        <p className="card_heading">
          <span className="mr-14">
            <GppGood fontSize="small" htmlColor="#00915B" />
          </span>
          Secure Your Account
        </p>
        <p
          className="mt-8 ml-32 subheading_light_2"
          style={{ color: "#8a8a8a" }}
        >
          Two-factor authentication adds an extra layer of security to your
          account. To log in, in addition you&apos;ll need to provide a 6 digit
          code.{" "}
          <span
            className="link_text_blue pointer"
            onClick={() => {
              if (user.phone) {
                setActivateOtp(2);
                sendOtp(user.phone, 2);
              } else {
                setPhoneAuthStep(1);
              }
              handleOpen();
            }}
          >
            set up two factor authentication
          </span>
        </p>
      </div> */}

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
      >
        <Box sx={style}>
          <div className="pb-24 border-btm_dc">
            <p className="offsetProjectTitle">Create New Password</p>
          </div>
          <div className="row pb-24 border-btm_dc pt-24">
            <div className="col-lg-5 col-md-6 col-12">
              <div className="input_div">
                <label className="subheading_1" htmlFor="currentPassword">
                  ENTER CURRENT PASSWORD
                </label>
                <input
                  type="password"
                  name="currentPassword"
                  id="currentPassword"
                  className="mt-12 subheading_2"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  placeholder="Current Password"
                />
              </div>
            </div>
          </div>
          <div className="pb-24 border-btm_dc pt-24">
            <div className="row">
              <div className="col-lg-5 col-md-6 col-12">
                <div className="input_div">
                  <label className="subheading_1" htmlFor="newPassword">
                    Enter New Password
                  </label>
                  <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    className="mt-12 subheading_2"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                  />
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-12">
                <div className="input_div">
                  <label className="subheading_1" htmlFor="confirmPassword">
                    Repeat New Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="mt-12 subheading_2"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Repeat New Password"
                  />
                </div>
              </div>
            </div>
            <div className="mt-24 row checkpasswordStrict">
              <div className="col-3 d-flex align-items-center">
                <input type="checkbox" disabled ref={eightChar} />
                <p className="body_text_s ml-8">At least eight characters</p>
              </div>
              <div className="col-3 d-flex align-items-center ml-8">
                <input type="checkbox" disabled ref={containsNumber} />
                <p className="body_text_s ml-8">Contains a number</p>
              </div>
            </div>
            <div className="mt-12 row checkpasswordStrict">
              <div className="col-3 d-flex align-items-center">
                <input type="checkbox" disabled ref={containsLetter} />
                <p className="body_text_s ml-8">Contains a capital letter</p>
              </div>
              <div className="col-3 d-flex align-items-center ml-8">
                <input type="checkbox" disabled ref={containsSymbol} />
                <p className="body_text_s ml-8">Contains a symbol</p>
              </div>
            </div>
          </div>
          <div className="text-end mt-24">
            <ButtonComp className="md" status={1} onClick={changePassword}>
              Save
            </ButtonComp>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default EditProfile;
