import Axios from "../Api";
import { toastResponseErrorWithoutLogout } from "./toastResponseError";

export const getAccountingItems = async () => {
  try {
    const res = await Axios({
      url: "/log/logged-accounting-items",
      method: "GET",
    });
    return {
      accountingItemCategories: res.data.accountingItemCategories,
      accountingItemYears: res.data.accountingItemYears,
    };
  } catch (error) {
    toastResponseErrorWithoutLogout(error);
  }
};

export const getAllLogItems = async () => {
  try {
    const res = await Axios({
      url: "/log/all-user-items",
      method: "GET",
    });

    return res.data.data;
  } catch (error) {
    toastResponseErrorWithoutLogout(error);
  }
};

export const getAllYearOfLog = async (logId) => {
  try {
    const res = await Axios({
      url: `/log/log-allyear/${logId}`,
      method: "GET",
    });
    if (res.data.success) {
      return res.data.years;
    }
    return null;
  } catch (err) {
    toastResponseErrorWithoutLogout(err);
  }
};

export const getAllUsersofCompany = async () => {
  try {
    const res = await Axios({
      url: `/company/all-users`,
      method: "GET",
    });
    return res.data.data;
  } catch (err) {
    toastResponseErrorWithoutLogout(err);
  }
};

export const getExistUsersofCompany = async () => {
  try {
    const res = await Axios({
      url: `/company/company-users`,
      method: "GET",
    });
    return res.data.data;
  } catch (err) {
    toastResponseErrorWithoutLogout(err);
  }
};
