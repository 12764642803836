import React, { useEffect, useState } from "react";
import "./glossary.css";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { createFilterOptions } from "@mui/material/Autocomplete";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import axios from "../../../../../Api/index";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
import { useTranslation } from "react-i18next";
function Glossary() {
  const {t} = useTranslation()
  const [glossaryKeywords, setGlossaryKeywords] = useState([]);
  const [keyWordsDetails, setKeyWordsDetails] = useState([]);
  const [filterKey, setFilterKey] = useState("");
  const [activeKeyWord, setActiveKeyWord] = useState(0);
  const filter = createFilterOptions();
  const { setSessionExpire,user } = useAuth();
  useEffect(() => {
    handleSearch(filterKey.keyword);
  }, [filterKey]);

  const handleSearch = (query) => {
    glossaryKeywords.forEach((currKeyword, index) => {
      if (currKeyword.keyword === query) {
        document
          .getElementById(query + index)
          .scrollIntoView({ behavior: "smooth" });
      }
    });
  };
  const getGlossaryKeywords = async () => {
    try {
      await axios.get(`/academy/glossary`).then((response) => {
        if (response.data.success) {
          setKeyWordsDetails(response.data.data);
          let tempKeywords = response.data.data.map((currKeyWord) => {
            return {
              keyword: currKeyWord.keyword,
              keyword_de: currKeyWord.keyword_de,
              id: currKeyWord._id,
            };
          });

          setGlossaryKeywords(tempKeywords);
        }
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    getGlossaryKeywords();
  }, []);

  return (
    <div className="pr-20">
      <div className="d-flex justify-content-between align-items-center mt-48">
        <p className="font_17_700 text_capital">{t("academy.Today.greenwiki")}</p>
        {/* <TextField
          //  value={filterProp}
          //   onChange={(e) => onFilterChange(e)}
          className="body_text_l"
          id="input-with-icon-textfield"
          placeholder={`Search`}
          InputProps={{
            endAdornment: (
              <InputAdornment position="right">
                <i className="bi bi-search"></i>
              </InputAdornment>
            ),
          }}
          sx={{
            "& .MuiInputBase-root": {
              height: 40,
              width: 250,
            },
          }}
        /> */}
        <div className="glossary_searchBox">
          <Autocomplete
            value={filterKey}
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                setFilterKey({
                  keyword: newValue,
                });
              } else if (newValue === null) {
                setFilterKey("");
              } else if (newValue && newValue.inputValue) {
                // Create a new value from the user input
                setFilterKey({
                  keyword: newValue.inputValue || "",
                });
              } else {
                setFilterKey(newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              // Suggest the creation of a new value
              const isExisting = options.some(
                (option) => {
                  let searchPara;
                  if(user.language === 'de' && option.keyword_de){
                    searchPara = option.keyword_de
                  }else{
                    searchPara = option.keyword
                  }
                  return inputValue === searchPara
                }
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push({
                  inputValue,
                  keyword: `Add "${inputValue}"`,
                });
              }

              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={glossaryKeywords}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return user.language === 'de' && option.keyword_de ?option.keyword_de :option.keyword;
            }}
            renderOption={(props, option) => (
              <li {...props}>{user.language === 'de' && option.keyword_de ? option.keyword_de :option.keyword}</li>
            )}
            sx={{ width: 300 }}
            freeSolo
            renderInput={(params) => (
              <TextField
                sx={{
                  fieldset: {
                    padding: "0px",
                    // border: "2px solid grey",
                    // borderRadius: "16px",
                    // boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px"
                  },
                }}
                {...params}
                label={t("academy.search")}
              />
            )}
          />
        </div>
      </div>

      <div className="row h-100 mt-50">
        <div className="col-2 d-flex glossary_keyword_container flex-column br-right-ef overflow-auto mb-30">
          {glossaryKeywords.map((currKeyWord, index) => {
            if (index === 0) {
              return (
                <div key={currKeyWord.keyword[0] + index}>
                  <p className="font_24_600 text_capital color_main_blue mb-8">
                    {currKeyWord.keyword[0]}
                  </p>

                  <a
                    href={`#${currKeyWord.keyword + index}`}
                    onClick={() => {
                      // getArticleDetails(index);
                      setActiveKeyWord(index);
                    }}
                    className={`body_text_m pointer mt-12 ${
                      index == activeKeyWord ? "fw-700" : ""
                    }`}
                  >
                    {user.language === 'de' && currKeyWord?.keyword_de ?currKeyWord?.keyword_de:currKeyWord?.keyword}
                  </a>
                </div>
              );
            } else {
              if (
                currKeyWord.keyword[0] != glossaryKeywords[index - 1].keyword[0]
              ) {
                return (
                  <div key={index + currKeyWord.keyword + index}>
                    <p className="font_24_600 text_capital color_main_blue mt-38 mb-8">
                      {currKeyWord.keyword[0]}
                    </p>

                    <a
                      href={`#${currKeyWord.keyword + index}`}
                      onClick={() => {
                        // getArticleDetails(index);
                        setActiveKeyWord(index);
                      }}
                      className={`body_text_m pointer mt-12 ${
                        index == activeKeyWord ? "fw-700" : ""
                      }`}
                    >
                      {user.language === 'de' && currKeyWord?.keyword_de ?currKeyWord?.keyword_de:currKeyWord?.keyword}
                    </a>
                  </div>
                );
              } else {
                return (
                  <a
                    href={`#${currKeyWord.keyword + index}`}
                    onClick={() => {
                      //getArticleDetails(index);
                      setActiveKeyWord(index);
                    }}
                    key={currKeyWord.keyword + index}
                    className={`body_text_m pointer mt-12 ${
                      index == activeKeyWord ? "fw-700" : ""
                    }`}
                  >
                    {user.language === 'de' && currKeyWord?.keyword_de ?currKeyWord?.keyword_de:currKeyWord?.keyword}
                  </a>
                );
              }
            }
          })}
        </div>

        <div className="col-10 glossary_keyword_container overflow-auto pl-40">
          {keyWordsDetails.map((currKeyWord, index) => {
            return (
              <div
                id={`${currKeyWord.keyword + index}`}
                key={currKeyWord._id + index}
                className={`${index ? "mt-50" : "mt-30"}`}
              >
                <p className="font_24_600">{user.language === 'de' && currKeyWord?.keyword_de ?currKeyWord?.keyword_de:currKeyWord?.keyword}</p>

                <p className="body_text mt-30">
                  
                  {currKeyWord?.description || currKeyWord?.description_de
                    ?user.language === 'de' && currKeyWord?.description_de ?parse(currKeyWord?.description_de):parse(currKeyWord?.description)
                    : ""}
                </p>
                {currKeyWord?.tutorialid?.cover?.video && (
                  <div className="border-ef border_radius_10 pl-22 pr-40 pb-40 mt-45">
                    <div className="d-flex justify-content-between align-items-center mt-18">
                      <p className="font_16_500">
                        {currKeyWord?.tutorialid?.title}
                      </p>
                      <Link
                        to={`/dashboard?tab=7&acadTab=0&id=${currKeyWord?.tutorialid?._id}&step=2`}
                      >
                        <p className="color_blue">
                          Go to tutorial{" "}
                          <i className="bi bi-arrow-right ml-48"></i>
                        </p>
                      </Link>
                    </div>
                    <div className="mt-22">
                      <video width={"100%"} controls>
                        <source
                          src={currKeyWord?.tutorialid?.cover.video}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  </div>
                )}

                <div className="mb-30">
                  {currKeyWord?.tutorialid?.content?.map(
                    (currContent, index) => {
                      return (
                        <div className="mt-30" key={index + currContent._id}>
                          <p className="font_19_600">{currContent.heading}</p>

                          <p className="body_text color_desp_grey mt-20">
                            {currContent.content}
                          </p>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Glossary;
