import React, { useState } from 'react'
import SelectInputType from '../../../../Components/SelectInputType'
import TextInputType from '../../../TextInputType'
import { toastResponseError } from '../../../../../../../../../Utils/toastResponseError'
import ButtonComp from '../../../../../../../../../Assets/components/Button'
import toast from 'react-hot-toast'
import { useAuth } from '../../../../../../../../../Utils/Auth'
import Axios from '../../../../../../../../../Api'
const email_regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

function InviteUser() {
    const {setSessionExpire}=useAuth()
    const [userType ,setUserType]=useState("")
    const [firstName,setFirstName]=useState("")
    const [lastName,setLastName]=useState("")
    const [email,setEmail]=useState("")
    const handleUserTypeChange = (index,value) =>{
        setUserType(value)
    }
    const handleFirstNameChange= (index,value) =>{
        setFirstName(value)
    }
    const handleLastNameChange= (index,value) =>{
        setLastName(value)
    }
    const handleEmailChange= (index,value) =>{
        setEmail(value)
    }

    const sendUserInvitation= async () =>{
        if (firstName.trim().length == 0 || lastName.trim().length == 0 || email.trim().length == 0 || userType.trim().length==0) return;
        if (!email_regex.test(email)) {
            return toast.error("Only work email are allowed");
          }
          if (userType.toLowerCase() == "internal") {
            let role = "Editor"
            await Axios({
              url: `/company/invite-user`,
              method: "post",
              data: {
                email,
                firstName,
                lastName,
                role,
              },
            })
              .then((res) => {
                //   setDisable(false);
                if (res.data.success) {
                    setFirstName("")
                    setLastName("")
                    setEmail("")
                    setUserType("")
                  toast.success(res.data.message);
                }
              })
              .catch((err) => {
                toastResponseError(err, setSessionExpire);
              });
          }
    }

    return (
        <div>
            <div>
                <p className="font_12_600 color_7e">Type</p>

                <div className="mt-10">
                    <SelectInputType
                    selectedValue={userType}
                    handleAnswerChange={handleUserTypeChange}
                    options={["Internal","External"]}
                     />
                </div>
            </div>

            <div className="mt-20">
                <p className="font_12_600 color_7e">First Name</p>

                <div className="mt-10">
                    <TextInputType 
                    answerValue={firstName}
                    handleAnswerChange={handleFirstNameChange}
                    />
                </div>
            </div>

            <div className="mt-20">
                <p className="font_12_600 color_7e">Last Name</p>

                <div className="mt-10">
                    <TextInputType 
                    answerValue={lastName}
                    handleAnswerChange={handleLastNameChange}
                    />
                </div>
            </div>


            <div className="mt-20">
                <p className="font_12_600 color_7e">Email</p>

                <div className="mt-10">
                    <TextInputType 
                    answerValue={email}
                    handleAnswerChange={handleEmailChange}
                    />
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-end mt-20">
                <ButtonComp 
                onClick={() =>{
                    sendUserInvitation()
                }}
                status={2}>
                    Invite
                </ButtonComp>
            </div>

        </div>
    )
}

export default InviteUser