import React, { useState } from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../Utils/TableComp";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";
import { Button, IconButton, Menu, MenuItem, Popover } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { monthNames } from "../../../../../../Utils/CommonFunctions";
import DeleteModal from "../../../../../../Assets/components/DeleteModal/DeleteModal";
import Axios from "../../../../../../Api";
import toast from "react-hot-toast";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../Utils/Auth";

const GhgTableRow = ({ currReport, cr, getTableData }) => {
  const navigate = useNavigate();
  const { setSessionExpire } = useAuth();
  const [anchorEl, setAnchorEl] = useState([]);
  const [open, setOpen] = useState([]);

  const handleClick = (event, index) => {
    let currAnchorEl = [...anchorEl];
    let currOpen = [...open];
    currOpen[index] = !currOpen[index];
    setOpen(currOpen);
    currAnchorEl[index] = event.currentTarget;
    setAnchorEl(currAnchorEl);
  };
  const handleClose = (index) => {
    let currAnchorEl = [...anchorEl];
    let currOpen = [...open];
    currOpen[index] = !currOpen[index];
    setOpen(currOpen);
    currAnchorEl[index] = null;
    setAnchorEl(currAnchorEl);
  };

  const [anchorElPop, setAnchorElPop] = useState(null);
  const handlepopClick = (event) => {
    setAnchorElPop(event.currentTarget);
  };

  const handlepopClose = () => {
    setAnchorElPop(null);
  };

  const popopen = Boolean(anchorElPop);
  const id = popopen ? "simple-popover" : undefined;

  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = () => setdltOpen(true);
  const handledltClose = () => setdltOpen(false);

  const handleDeleteReport = async () => {
    await Axios({
      url: `/ghg/delete-ghg/${currReport._id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Report deleted successfully");
          getTableData();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  return (
    <>
      <StyledTableRow
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}
      >
        <StyledTableCell sx={{ borderLeft: "0", textAlign:"left" }} component="th" align="left">
          {currReport.name} ( {currReport.userId.firstname})
        </StyledTableCell>
        <StyledTableCell className="text-capitalize" align="left">
          {currReport.type}{" "}
          {!currReport.published ? (
            <span className="text_lowerCase"> (draft) </span>
          ) : (
            ""
          )}
        </StyledTableCell>
        <StyledTableCell align="left"  sx={{ textAlign:"left" }}>
          <div className="ghgTable_muiList d-flex">
            <p className="d-flex align-items-center border-ef border_radius_25 px-16 pt-0 pb-0 mt-0 mb-0 ml-10 mr-10">
              <img
                className="h-20px"
                src={getServiceIcon(
                  currReport.accountingItems[0].accountingItemName
                )}
              />
              <span className="ml-10">
                {currReport.accountingItems[0].name}
              </span>
            </p>

            {currReport.accountingItems.length > 1 ? (
              <div className="mb-2">
                <Button
                  id="basic-button"
                  aria-controls={open[cr] ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open[cr] ? "true" : undefined}
                  onClick={(e) => {
                    handleClick(e, cr);
                  }}
                  sx={{ padding: 0 }}
                >
                  <p className="border-ef border_radius_25 px-16 pt-6 pb-6 ml-10 mr-10">
                    <i className="bi bi-three-dots"></i>
                  </p>
                </Button>
                <Menu
                  id="basic-menu"
                  sx={{ boxShadow: 0 }}
                  anchorEl={anchorEl[cr]}
                  open={open[cr]}
                  onClose={() => {
                    handleClose(cr);
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {currReport.accountingItems.length > 1 &&
                    currReport.accountingItems
                      .slice(1, currReport.accountingItems.length)
                      .map((currItem, itemIndex) => {
                        return (
                          <MenuItem
                            key={
                              itemIndex +
                              currItem.name +
                              currReport._id +
                              currItem.id
                            }
                          >
                            <p className="border-ef border_radius_25 px-16 pt-7 pb-7">
                              <img
                                className="h-20px"
                                src={getServiceIcon(
                                  currItem.accountingItemName
                                )}
                              />
                              <span className="ml-10">{currItem.name}</span>
                            </p>
                          </MenuItem>
                        );
                      })}
                </Menu>
              </div>
            ) : (
              ""
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell align="left"  sx={{ textAlign:"left" }}>
          {currReport.timeFrameOption == 1 ? (
            <p>{currReport.timeFrame?.year}</p>
          ) : currReport.timeFrameOption == 2 ? (
            <p>
              {currReport.timeFrame?.startYear} -{" "}
              {currReport.timeFrame?.quarter}
            </p>
          ) : currReport.timeFrameOption == 3 ? (
            <p>{currReport.timeFrame?.year}</p>
          ) : currReport.timeFrameOption == 4 ? (
            <p>
              {monthNames[currReport.timeFrame?.startMonth]}{" "}
              {currReport.timeFrame?.startYear} -{" "}
              {monthNames[currReport.timeFrame?.endMonth]}{" "}
              {currReport.timeFrame?.endYear}
            </p>
          ) : (
            ""
          )}
        </StyledTableCell>
        <StyledTableCell align="left"  sx={{ textAlign:"left" }}>
          <div className="d-flex align-items-center justify-content-between">
            <p>
              {new Date(currReport.createdAt).getDate()}.
              {new Date(currReport.createdAt).getMonth()}.
              {new Date(currReport.createdAt).getFullYear()}
            </p>
            <div>
              <IconButton
                onClick={handlepopClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={"account-menu"}
                aria-haspopup="true"
                aria-expanded={"true"}
              >
                <i className="bi bi-three-dots-vertical"></i>
              </IconButton>
              {/* <Menu
                id="basic-menu"
                anchorEl={anchorElRowMenu[cr]}
                open={openRowMenu[cr]}
                onClose={() => {
                  handleCloseRowMenu(cr);
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  className="subclass_Data_menu_pop"
                  onClick={() => handleCloseRowMenu(cr)}
                >
                  <a
                    href={currReport.reportUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={`${currReport.name}.pdf`}
                  >
                    download
                  </a>
                </MenuItem>
                <MenuItem
                  className="subclass_Data_menu_pop"
                  onClick={() => {
                    navigate(
                      `/dashboard?tab=6&report=1&step=1&report_id=${currReport._id}`
                    );
                  }}
                >
                  edit
                </MenuItem>
                <MenuItem
                  className="color_red"
                  // onClick={(e) =>}
                >
                  delete
                </MenuItem>
              </Menu> */}
            </div>
          </div>
        </StyledTableCell>
      </StyledTableRow>

      <Popover
        id={id}
        open={popopen}
        anchorEl={anchorElPop}
        onClose={handlepopClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="basic-popover-root">
          <div className="basic-popover">
            <p className="font_14_500">
              <a
                href={currReport.reportUrl}
                target="_blank"
                rel="noopener noreferrer"
                download={`${currReport.name}.pdf`}
              >
                download
              </a>
            </p>
          </div>
          <div className="basic-popover">
            <p
              className="font_14_500"
              onClick={() => {
                navigate(
                  `/dashboard?tab=6&report=1&step=1&report_id=${currReport._id}`
                );
              }}
            >
              edit
            </p>
          </div>
          <div className="basic-popover">
            <p
              className="font_14_500 color_red"
              onClick={() => {
                handledltOpen();
                handlepopClose();
              }}
            >
              delete
            </p>
          </div>
        </div>
      </Popover>

      <DeleteModal
        open={dltOpen}
        handleClose={handledltClose}
        confirmFunction={() => handleDeleteReport()}
        subtitle={`Once you delete a GHG report, you can&apos;t recover it.<br /> Please be sure before deleting it.`}
      />
    </>
  );
};

export default GhgTableRow;
