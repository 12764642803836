// import React, { useEffect, useState } from "react";
import React, { useEffect } from "react";
import BackForwardButton from "../../../Assets/components/BackForwardButton/BackForwardButton";
import PressEnter from "../../../Assets/components/PressEnter/PressEnter";
import ButtonComp from "../../../Assets/components/Button";

// import { useSearchParams } from "react-router-dom";

function Questions(props) {
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      props.handleSubmit(props.regDetails[props.target]);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);
  return (
    <div className="register_text_start col-md-10 register_question_container max_width_552">
      <div className="purple_label register_question_no">
        {props.currQuestion + 1}/6
      </div>
      <div className="form-group register_text_start">
        <label
          className=" register_sm_label register_question_label"
          htmlFor="label1"
        >
          {props.question.question}
        </label>
        <input
          type="text"
          className="py-3 border-bottom  shadow-none register_input"
          id="label1"
          value={props.regDetails[props.target]}
          onChange={(e) => props.handleChange(e, props.question.fields.type)}
          placeholder={props.question.fields.placeholder}
          name={props.target}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              props.handleSubmit(props.regDetails[props.target]);
            }
          }}
        />
      </div>
      <div className="mt-30 d-flex align-items-start justify-content-between">
        <BackForwardButton
          handleGoBack={() =>
            props.decQuestionNo(props.regDetails[props.target])
          }
          hanldeGoForward={() =>
            props.incQuestionNo(props.regDetails[props.target])
          }
          backDisabled={props.currQuestion === 0 ? true : false}
        />
        <div>
          <ButtonComp
            status={2}
            size="large"
            onClick={() => props.handleSubmit(props.regDetails[props.target])}
          >
            Save
          </ButtonComp>
          <PressEnter />
        </div>
      </div>
    </div>
  );
}

export default Questions;

// press enter icon change: figma
