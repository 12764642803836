import React, { useEffect, useState } from "react";
import "./sideDrawer.css";
import Drawer from "@mui/material/Drawer";
import Switch from "@mui/material/Switch";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
//import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { toast } from "react-hot-toast";
import TextInput from "./Components/textInput";
import SelectInput from "./Components/selectinput";
import DateInput from "./Components/dateInput";
import axios from "../../../../../Api/index";
import events from "../../../../../DataJSON/DropdownTypes/events.json";
import facilities from "../../../../../DataJSON/DropdownTypes/facilities.json";
import industries from "../../../../../DataJSON/DropdownTypes/industries.json";
import products from "../../../../../DataJSON/DropdownTypes/product.json";
import hotels from "../../../../../DataJSON/DropdownTypes/hotelsDropDown.json";

import { getServiceIcon } from "../../../../../Utils/itemIcon";
import AddressInput from "./Components/addressInput";
import NumberInput from "./Components/numberInput";
import { Close } from "@mui/icons-material";
import ButtonComp from "../../../../../Assets/components/Button";
import CustomToolTip from "./Components/customToolTip";

function SideDrawer(props) {
  const [baseYearAccount, setBaseYearAccount] = useState(false);
  //const [doubleAccount, setDoubleAccount] = useState(true);
  const [baseYear, setBaseYear] = useState(dayjs(new Date()));
  const [answers, setAnswers] = useState({});
  const [questionRows, setQuestionRows] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const handleDrawerClosing = () => {
    setAnswers({});
    setBaseYearAccount(false);
    props.toggleDrawer(false);
  };
  const handleIsBaseYearChange = (event, value) => {
    event.preventDefault();
    setBaseYearAccount(value);
  };
  const handleBaseYearChange = (year) => {
    setBaseYear(year);
  };

  const getCurrentQuestionIndex = (index, subIndex) => {
    let current = props.questions.length;
    for (let i = 0; i < props.secondQuestionSet.length; i++) {
      if (i < index) {
        current += props.secondQuestionSet[i].questions.length;
      }
    }
    return current + subIndex;
  };

  const handleDrawerOpen = () => {
    // setBaseYearAccount(true);
    setBaseYear(dayjs(new Date()));
    setAnswers({});
    props.toggleDrawer(false);
  };

  const handleAnswerChange = (value, index) => {
    setAnswers((prevState) => ({ ...prevState, [index]: value }));
  };

  const handleToggleChange = (value, index, subIndex) => {
    let currentIndex = getCurrentQuestionIndex(index, subIndex);
    if (props.secondQuestionSet[index].questions.length > subIndex + 1) {
      setAnswers((prevState) => {
        return {
          ...prevState,
          [currentIndex]: value,
          [currentIndex + 1]: "",
        };
      });
    } else {
      setAnswers((prevState) => ({
        ...prevState,
        [currentIndex]: value,
      }));
    }
    //setAnswers(current_answers);
  };

  const getQuestionCol = (index) => {
    let dropDownOptions = [];
    if (props.questions[index] && props.questions[index].type === "select") {
      if (props.questions[index].name.toLowerCase() === "industry") {
        dropDownOptions = industries;
      }
      if (props.questions[index].name.toLowerCase() === "event") {
        dropDownOptions = events;
      }
      if (props.questions[index].name.toLowerCase() === "facilitytype") {
        dropDownOptions = facilities;
      }
      if (props.questions[index].name.toLowerCase() === "producttype") {
        dropDownOptions = products;
      }
      if (props.questions[index].name.toLowerCase() === "hoteltype") {
        dropDownOptions = hotels;
      }
    }

    return (
      <div>
        {props.questions.length > index ? (
          <>
            {props.questions[index].type === "text" ? (
              <TextInput
                title={props.questions[index].title}
                type={props.questions[index].type}
                helperText={props.questions[index].helperText}
                description={props.questions[index].description}
                placeholder={props.questions[index].placeholder}
                value={answers[index]}
                answers={answers}
                setAnswers={setAnswers}
                handleAnswerChange={handleAnswerChange}
                questionNo={index}
              />
            ) : props.questions[index].type === "number" ? (
              <NumberInput
                title={props.questions[index].title}
                type={props.questions[index].type}
                unit={props.questions[index].unit}
                defaultValue={props.questions[index].defaultValue}
                percentage={props.questions[index].percentage}
                helperText={props.questions[index].helperText}
                description={props.questions[index].description}
                placeholder={props.questions[index].placeholder}
                value={answers[index]}
                answers={answers}
                setAnswers={setAnswers}
                handleAnswerChange={handleAnswerChange}
                questionNo={index}
              />
            ) : props.questions[index].type === "select" ? (
              <SelectInput
                title={props.questions[index].title}
                type={props.questions[index].type}
                data={props.questions[index].data}
                helperText={props.questions[index].helperText}
                description={props.questions[index].description}
                placeholder={props.questions[index].placeholder}
                dropDownList={dropDownOptions}
                value={answers[index]}
                answers={answers}
                setAnswers={setAnswers}
                handleAnswerChange={handleAnswerChange}
                questionNo={index}
                baseYearField={props.baseYearField}
              />
            ) : props.questions[index].type === "date" ? (
              <DateInput
                title={props.questions[index].title}
                helperText={props.questions[index].helperText}
                description={props.questions[index].description}
                placeholder={props.questions[index].placeholder}
                value={answers[index]}
                answers={answers}
                setAnswers={setAnswers}
                handleAnswerChange={handleAnswerChange}
                questionNo={index}
              />
            ) : props.questions[index].type === "address" ? (
              <AddressInput
                title={props.questions[index].title}
                helperText={props.questions[index].helperText}
                description={props.questions[index].description}
                placeholder={props.questions[index].placeholder}
                type={props.questions[index].type}
                value={answers[index]}
                answers={answers}
                setAnswers={setAnswers}
                handleAnswerChange={handleAnswerChange}
                questionNo={index}
              />
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
      </div>
    );
  };
  const getQuestionRow = () => {
    let passColumns = true;
    let rows = [];
    props.questions.forEach((currQuestion, index) => {
      if (passColumns) {
        let firstCol = getQuestionCol(index);
        let secondCol = getQuestionCol(index + 1);
        rows.push(
          <div className={index == 0 ? "row" : "row mt-40"} key={index}>
            <div className="col-md-6 col-lg-5">{firstCol}</div>

            <div className="col-md-6 col-lg-5">{secondCol}</div>
          </div>
        );
      }
      passColumns = !passColumns;
    });
    setQuestionRows(rows);
  };

  useEffect(() => {
    let current_answers = {};
    let currentIndex = props.questions.length;
    props.questions.forEach((ques, index) => {
      if (ques.type === "date") {
        current_answers[index] = dayjs(new Date());
      } else current_answers[index] = "";
    });
    props.secondQuestionSet.forEach((questionSet) => {
      questionSet.questions.forEach((question, subIndex) => {
        if (question.type === "toggle") {
          current_answers[currentIndex + subIndex] = true;
        } else if (question.type === "date") {
          current_answers[currentIndex + subIndex] = dayjs(new Date());
        } else
          current_answers[currentIndex + subIndex] = question.defaultValue
            ? question.defaultValue
            : "";
      });

      currentIndex += questionSet.questions.length;
    });
    setAnswers(() => ({
      ...current_answers,
    }));
  }, [props.questions, props.secondQuestionSet]);

  useEffect(() => {
    getQuestionRow();
  }, [props.questions]);

  useEffect(() => {
    for (let i = 0; i < props.questions.length; i++) {
      if (
        props.questions[i].name === "endDate" ||
        props.questions[i].name === "realEstateStartDate"
      ) {
        if (answers[i]) getLocations(Number(answers[i]["$y"]));
      }
    }
  }, [answers]);
  const getLocations = async (year) => {
    if (year) {
      const response = await axios.get(
        `/log/get-log?itemId=${`6442af32ca73043a8ec66c78`}&year=${year}` // should get data less than equal to selected year
      );
      if (response.data.success) {
        let currLocations = [];
        response.data.logs.forEach((location) => {
          currLocations.push(location.itemDetails.name);
        });
        setLocationList(currLocations);
      }
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let currStartDate = new Date();
    let currEndDate = new Date();
    for (let i = 0; i < props.questions.length; i++) {
      if (
        answers[i] === null ||
        answers[i] === undefined ||
        (typeof answers[i] === "string" && answers[i].trim().length === 0)
      ) {
        toast.error(`${props.questions[i].name} field is incomplete`);
        return;
      }
      if (props.questions[i].name === "startDate") currStartDate = answers[i];
      if (props.questions[i].name === "endDate") currEndDate = answers[i];
    }

    if (currStartDate["$y"] > currEndDate["$y"]) {
      toast.error("start date cannot be greater than end date");
      return;
    }
    if (
      currStartDate["$y"] === currEndDate["$y"] &&
      currStartDate["$M"] > currEndDate["$M"]
    ) {
      toast.error("start date cannot be greater than end date");
      return;
    }
    let currentYear = new Date().getFullYear();
    if (currEndDate["$y"] > currentYear || currStartDate["$y"] > currentYear) {
      toast.error("dates cannot be greater than current running year");
      return;
    }

    if (props.baseYearField && baseYear === "") {
      toast.error("Please fill correct Base year");
      return;
    }

    let currentIndex = props.questions.length;
    let foundError = false;
    props.secondQuestionSet.forEach((questionSet) => {
      let skipNext = false;
      questionSet.questions.forEach((question, subIndex) => {
        if (skipNext) {
          skipNext;
          skipNext = !skipNext;
        } else if (question.type === "toggle") {
          if (answers[currentIndex + subIndex]) {
            if (
              subIndex + 1 < questionSet.questions.length &&
              answers[currentIndex + subIndex + 1] === ""
            ) {
              toast.error(`${question.name} field is invalid`);
              foundError = true;
              return;
            }
          } else if (answers[currentIndex + subIndex] === undefined) {
            toast.error(`${question.name}field is not initialized`);
            foundError = true;
            return;
          }
          skipNext = true;
        } else {
          if (answers[currentIndex + subIndex] === "") {
            toast.error(`field not selected from dropdown ${question.name}`);
            foundError = true;
            return;
          }
        }
      });
      currentIndex += questionSet.questions.length;
    });
    if (foundError) return;
    props.handleAddServiceSubmit(
      answers,
      baseYearAccount,
      baseYear["$y"],
      baseYear["$M"] + 1
    );
    setAnswers({});
    setBaseYearAccount(false);
    props.toggleDrawer(false);
  };
  return (
    <div className="SideDrawer_width">
      <Drawer
        anchor={"right"}
        open={props.state}
        onClose={() => {
          handleDrawerOpen();
        }}
        PaperProps={{
          sx: { width: "70%" },
        }}
      >
        <div className="drawer_root">
          <div>
            <div className="align_text_right mt-20 pr-20">
              <Close fontSize="small" onClick={() => handleDrawerClosing()} />
            </div>
            <div className="pl-35 border-btm_ef pb-32">
              <div className="d-flex align-items-center">
                <img
                  src={getServiceIcon(props.serviceName)}
                  className="icon_sizing mt-2"
                />
                <p className="f-27 fw-700 ml-10 mt-6">
                  {" "}
                  New {props.serviceName}{" "}
                </p>
              </div>
              <p className="subheading_light_2 mt-10">
                Please enter the following information for your{" "}
                {props.serviceName}.
              </p>
              <p className="subheading_light_2">
                You can always edit the information you enter here.
              </p>
            </div>
          </div>
          <div className="main_box SideDrawer_form ml-40 mt-24">
            <form>
              <div className="form-row row align-items-center">
                {questionRows.map((questionRow) => {
                  return questionRow;
                })}
                {props.baseYearField ? (
                  <div className="mt-50">
                    <p className="purple_label">Recommended</p>
                    <div className="row mt-20">
                      <div className="col-md-6 col-lg-5 col-5">
                        <div className="d-flex align-items-center">
                          <p className="subheading_1 mb-8">
                            DO YOU WANT TO ACCOUNT A BASE YEAR?
                          </p>
                          <CustomToolTip
                            title={
                              "A base year refers to a specific year against which you compare your GHG emissions in the following years. It serves as a reference point for tracking and measuring progress in reducing emissions over time."
                            }
                          />
                        </div>
                        <div className="SideDrawer_toggle_section d-flex mt-2 align-items-center">
                          <Switch
                            onChange={(event) => {
                              handleIsBaseYearChange(
                                event,
                                event.target.checked
                              );
                            }}
                            className="side_drawer-toggle"
                            defaultValue={baseYearAccount}
                          />

                          <div className="">
                            <p className="f-12 fw-600 ml-4">
                              {baseYearAccount ? "Yes" : "No"}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-5 col-5 sidebar_baseyear_select pl-0">
                        <p className="subheading_1 mb-2 ml-8">
                          {baseYearAccount === true
                            ? "Select your baseyear"
                            : "CARBON ACCOUNTING START DATE"}
                        </p>
                        <div className="mt-2">
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={["DatePicker"]}>
                              <DatePicker
                                onChange={(new_date) => {
                                  handleBaseYearChange(new_date);
                                }}
                                slotProps={{ textField: { size: "small" } }}
                                views={
                                  baseYearAccount ? ["year"] : ["month", "year"]
                                }
                                sx={{ width: "100%" }}
                              />
                            </DemoContainer>
                            {/* <p className="helper_text mt-2 ml-8">
                              This name will be used to refer to this item in
                              the future
                            </p> */}
                          </LocalizationProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {props.secondQuestionSet.length > 0 && (
                  <div className="border-top_ef mt-32 pt-32">
                    {props.serviceName !== "Location" &&
                      props.secondQuestionSet.map((questionSet, index) => {
                        return (
                          <div className="mb-32 row mx-0" key={index}>
                            <p className="subheading_1 px-0">
                              {questionSet.title}
                            </p>
                            <div
                              className={`col-md-8 col-lg-5 ${
                                questionSet.isNoBg
                                  ? ""
                                  : "SideDrawer_card_background"
                              }`}
                            >
                              <div className="">
                                {questionSet.questions.map(
                                  (currQuestion, subIndex) => {
                                    let dropDownList = [];
                                    if (currQuestion.type === "select") {
                                      if (
                                        currQuestion.name ===
                                        "DoubleAccountingLocation"
                                      ) {
                                        dropDownList = locationList;
                                      }
                                    }
                                    return (
                                      <div className="" key={subIndex}>
                                        <p className="f-14 fw-400 mt-20">
                                          {currQuestion.title}
                                        </p>
                                        {currQuestion.type === "toggle" ? (
                                          <div className="SideDrawer_toggle_section d-flex align-items-center mt-10">
                                            <div>
                                              {" "}
                                              <Switch
                                                onChange={(event) => {
                                                  handleToggleChange(
                                                    event.target.checked,
                                                    index,
                                                    subIndex
                                                  );
                                                }}
                                                defaultChecked
                                                className="side_drawer-toggle"
                                              />
                                            </div>
                                            <div>
                                              <p className="f-12 fw-600 ml-4">
                                                {answers[
                                                  getCurrentQuestionIndex(
                                                    index,
                                                    subIndex
                                                  )
                                                ]
                                                  ? "Yes"
                                                  : "No"}
                                              </p>
                                            </div>
                                          </div>
                                        ) : currQuestion.type === "select" ? (
                                          <div className="w-60 mt-10">
                                            <SelectInput
                                              title={""}
                                              type={currQuestion.type}
                                              placeholder={
                                                currQuestion.placeholder
                                              }
                                              description={
                                                currQuestion.description
                                              }
                                              helperText={
                                                currQuestion.helperText
                                              }
                                              value={
                                                answers[
                                                  getCurrentQuestionIndex(
                                                    index,
                                                    subIndex
                                                  )
                                                ]
                                              }
                                              data={currQuestion.data}
                                              dropDownList={dropDownList}
                                              handleAnswerChange={
                                                handleAnswerChange
                                              }
                                              questionNo={getCurrentQuestionIndex(
                                                index,
                                                subIndex
                                              )}
                                              answers={answers}
                                              setAnswers={setAnswers}
                                            />
                                            {/* <p
                                              className={
                                                "helper_text fw-400 mt-10"
                                              }
                                            >
                                              {currQuestion.helperText}
                                            </p> */}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                )}

                <div className="d-flex mt-30 mb-32 align-items-center border-top_ef pt-32">
                  <div>
                    <ButtonComp
                      status={1}
                      onClick={(event) => {
                        handleSubmit(event);
                      }}
                      // className="primary_button"
                    >
                      Create
                    </ButtonComp>
                  </div>
                  <div className="ml-20">
                    <ButtonComp
                      status={3}
                      className="secondary_button_variant_1 black"
                    >
                      Delete
                    </ButtonComp>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default SideDrawer;
