import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./storeCard.css";
import MinusIcon from "./MinusIcon";
import PlusIcon from "./PlusIcon";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../Utils/Auth";

function StoreCard(props) {
  const [quantity, setQuantity] = useState(0);
  const history = useNavigate();
  useEffect(() => {
    if (props.cartDetails) {
      var itemFind = props.cartDetails.find(
        (items) => items.name === props.item.name
      );
      if (itemFind) {
        setQuantity(itemFind.quantity);
      } else {
        setQuantity(0);
      }
    }
  }, [props.cartDetails]);
  const {t} = useTranslation()
  const {user} =  useAuth()

  return (
    <div
      className="d-flex flex-column justify-content-between container_design_1 storeCard_container pointer h-350px p-0 pl-10 pb-10"
      onClick={() => {
        if (props.isOther) {
          history(`/dashboard?tab=9&stage=2&item=${props.item._id}`);
        } else {
          history(`/dashboard?tab=9&stage=1&item=${props.item._id}`);
        }
      }}
    >
      <div
        className="d-flex justify-content-end mt-10 mr-10"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="store_card_cart_add_root">
          <MinusIcon
            onClick={() => {
              if (quantity > 0) {
                props.handleQuantityChange(props.item._id, quantity - 1);
              }
            }}
          />

          <span className="font_15_600 flex-1 d-flex align-items-center justify-content-center line_height_normal">
            {quantity}
          </span>
          <PlusIcon
            onClick={() =>
              props.handleQuantityChange(props.item._id, quantity + 1)
            }
          />
        </div>
      </div>

      <div className="bg_white storeCard_bottom_info border-ef border_radius_10 storeCard_card_details ml-10 mr-10  mb-10">
        <div
          className={
            "d-flex justify-content-between  pb-24 pt-24 pl-12 pr-24 " +
            (!props.isOther && "align-items-center")
          }
        >
          <div>
            <p className="fw-600 f-16">{props.item && (user?.language === 'de'?props.item.name_de:props.item.name) }</p>
            <p className="pointer f-14 fw-14">
              {t("common.learnMore")}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-arrow-right"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                />
              </svg>
            </p>
          </div>

          <div>
            {props.item.name === "Custom Pricing" ? (
              <div></div>
            ) : (
              <>
                {props.isOther && <p className="font_12_300">from</p>}
                <p className="f-24 fw-200">
                  {" "}
                  {props.item && props.item.price}€
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <img
        src={props.item?.image}
        className="w-100 h-75 storeCard_bg_image p-0"
      />
    </div>
  );
}

export default StoreCard;
