import React, { useState } from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../Utils/TableComp";
import { Avatar, AvatarGroup, IconButton, Popover } from "@mui/material";
import { useAuth } from "../../../../../Utils/Auth";
import { MoreVert } from "@mui/icons-material";

const Row = ({
  currSurvey,
  index,
  deleteSurvey,
  callChildSetTemplateFunc,
  handleTakeSurvey,
  handleCheckSurveyStats,
}) => {
  const { user } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const colors = ["#FFE5E5", "#D4F8D3", "#D8ECFD", "#FFF0BB"];

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <StyledTableRow
        key={currSurvey._id}
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}
        onClick={() => handleCheckSurveyStats(index)}
        className="pointer"
      >
        <StyledTableCell
          component="th"
          scope="row"
          sx={{ borderLeft: "0", borderRight: "0" }}
          //  className="border_left_0 border_right_0 border_bottom_0"
        >
          <div className="d-flex">
            {currSurvey.isDraft ? (
              <div
                className="w-75px center border_radius_10 bg_light_sky_blue "
                style={{ height: 28 }}
              >
                <p className="font_13_400 ">Draft</p>
              </div>
            ) : currSurvey.participants.filter((currUser) => currUser.submitted)
                .length === currSurvey.participants.length ? (
              <div
                className="w-75px center border_radius_10 color_bg_d4"
                style={{ height: 28 }}
              >
                <p className="font_13_400">Complete</p>
              </div>
            ) : (
              <div
                className="w-75px center border_radius_10 bg_light_yellow"
                style={{ height: 28 }}
              >
                <p className="font_13_400 ">Pending</p>
              </div>
            )}
          </div>
        </StyledTableCell>
        <StyledTableCell
          sx={{ borderLeft: "0", borderRight: "0" }}
          className="align_text_left"
        >
          <p className="font_13_400">{currSurvey.surveyName}</p>
        </StyledTableCell>
        <StyledTableCell
          sx={{ borderLeft: "0", borderRight: "0" }}
          //className="border_bottom_0"
        >
          <div className="d-flex align-items-center">
            <img className="br_50 h-30px w-30px " src={user.avatar} />

            <div className="flex-grow-0 ml-16">
              <p>
                {user.firstName ? user.firstName : user.firstname}{" "}
                {user.lastName ? user.lastName : user.lastname}
              </p>
            </div>
          </div>
        </StyledTableCell>
        <StyledTableCell
          sx={{ borderLeft: "0", borderRight: "0" }}
          //className="border_bottom_0"
        >
          <p className="font_14_400 align_text_left">
            {new Date(currSurvey.createdAt).getDate()}.
            {new Date(currSurvey.createdAt).getMonth()}.
            {new Date(currSurvey.createdAt).getFullYear()}
          </p>
        </StyledTableCell>
        <StyledTableCell
          sx={{ borderLeft: "0", borderRight: "0" }}
          //className="border_bottom_0"
        >
          <p className="font_14_400 align_text_left">
            {
              currSurvey.participants.filter((currUser) => currUser.submitted)
                .length
            }
            /{currSurvey.participants.length}
          </p>
        </StyledTableCell>
        <StyledTableCell
          sx={{ borderLeft: "0", borderRight: "0" }}
          //  className="border_bottom_0"
        >
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <AvatarGroup
                sx={{
                  flexDirection: "row-reverse",
                  "&.MuiAvatarGroup-root .MuiAvatar-root ": {
                    marginLeft: "-8px",
                    height: 30,
                    width: 30,
                    color: "#000",
                    fontSize: "13px",
                    fontWeight: 700,
                    lineHeight: "46px",
                    border: "1px solid #fff",
                    backgroundColor: "#e7e7e7",
                  },
                }}
              >
                {currSurvey.participants
                  .slice(0, 5)
                  .map((currParticipant, pIndex) => {
                    return (
                      <Avatar
                        key={pIndex + currParticipant.firstName}
                        sx={{
                          backgroundColor:
                            colors[pIndex % colors.length] + " !important",
                        }}
                      >
                        {currParticipant.firstName
                          ? currParticipant.firstName[0].toUpperCase()
                          : ""}
                      </Avatar>
                    );
                  })}

                {currSurvey.participants.length > 5 ? (
                  <Avatar sx={{ backgroundColor: "#e7e7e7" }}>
                    +{currSurvey.participants.length - 5}
                  </Avatar>
                ) : (
                  ""
                )}
              </AvatarGroup>
            </div>

            <div>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleClick(e);
                }}
                size="small"
                sx={{ ml: 2 }}
              >
                <MoreVert />
              </IconButton>
            </div>
          </div>
        </StyledTableCell>
      </StyledTableRow>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="basic-popover-root">
          {currSurvey.isDraft ? (
            <>
              <div className="basic-popover">
                <p
                  className="font_14_500 color_red"
                  onClick={() => {
                    deleteSurvey(currSurvey._id);
                    handleClose();
                  }}
                >
                  delete
                </p>
              </div>
              <div
                className="basic-popover"
                onClick={() => {
                  callChildSetTemplateFunc(currSurvey._id);
                  handleClose();
                }}
              >
                <p className="font_14_500">Edit</p>
              </div>
            </>
          ) : (
            <>
              {currSurvey.participants.findIndex(
                (currUser) => currUser.userId === user.id
              ) !== -1 && (
                <div
                  className="basic-popover"
                  onClick={() => {
                    handleTakeSurvey(index);
                    handleClose();
                  }}
                >
                  <p className="font_14_500">Take Survey</p>
                </div>
              )}
              <div
                className="basic-popover"
                onClick={() => {
                  handleCheckSurveyStats(index);
                  handleClose();
                }}
              >
                <p className="font_14_500">Check Survey Stats</p>
              </div>
              <div
                className="basic-popover"
                onClick={() => {
                  deleteSurvey(currSurvey._id);
                  handleClose();
                }}
              >
                <p className="font_14_500 color_red">Delete</p>
              </div>
            </>
          )}
        </div>
      </Popover>
    </>
  );
};

export default Row;
