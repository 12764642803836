export const maskMobileNumber = (mobileNumber) => {
  if (mobileNumber.length >= 4) {
    var lastFourDigits = mobileNumber.slice(-4);
    var maskedNumber = "*".repeat(mobileNumber.length - 4) + lastFourDigits;
    return maskedNumber;
  } else {
    return mobileNumber;
  }
};

export const modalStyle = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1050,
  maxWidth: "90%",
  bgcolor: "white",
  border: "1px solid #efefef",
  boxShadow: 24,
  borderRadius: "10px",
};

export const selectSx = {
  border: "none",
  backgroundColor: "#F6F6F6",
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "300",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

export const CreditCardsJson = {
  amex: "https://js.stripe.com/v3/fingerprinted/img/amex-a49b82f46c5cd6a96a6e418a6ca1717c.svg",
  diners:
    "https://js.stripe.com/v3/fingerprinted/img/diners-fbcbd3360f8e3f629cdaa80e93abdb8b.svg",
  discover:
    "https://js.stripe.com/v3/fingerprinted/img/discover-ac52cd46f89fa40a29a0bfb954e33173.svg",
  jcb: "https://js.stripe.com/v3/fingerprinted/img/jcb-271fd06e6e7a2c52692ffa91a95fb64f.svg",
  mastercard:
    "https://js.stripe.com/v3/fingerprinted/img/mastercard-4d8844094130711885b5e41b28c9848f.svg",
  visa: "https://js.stripe.com/v3/fingerprinted/img/visa-729c05c240c4bdb47b03ac81d9945bfe.svg",
  unionpay:
    "https://js.stripe.com/v3/fingerprinted/img/unionpay-8a10aefc7295216c338ba4e1224627a1.svg",
};

export const CreditCardsNameJson = {
  amex: "American Express",
  diners: "Diners Club",
  discover: "Discover",
  jcb: "JCB",
  mastercard: "Mastercard",
  visa: "Visa",
  unionpay: "UnionPay",
};

export const notifedFeatures = {
  connect: "connect",
  survey: "survey",
  reduceTarget: "reduce-target",
  reduceConsultDB: "reduce-consultDb",
  analyticsScenario: "analytics-scenario",
  transparencyDirectory: "transparency-directory",
};

export const getDateString = (date) => {
  const d = new Date(date);
  const current = new Date();

  if (
    d.getDate() === current.getDate() &&
    d.getMonth() === current.getMonth() &&
    d.getFullYear() === current.getFullYear()
  ) {
    return "Today";
  } else if (
    d.getDate() === current.getDate() - 1 &&
    d.getMonth() === current.getMonth() &&
    d.getFullYear() === current.getFullYear()
  ) {
    return "Yesterday";
  } else if (
    d.getDate() - 1 === current.getDate() &&
    d.getMonth() === current.getMonth() &&
    d.getFullYear() === current.getFullYear()
  ) {
    return "Tommorrow";
  } else {
    return d.toLocaleDateString("en-GB");
  }
};

export const AnimationVideos = [
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_1.webm",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_2.webm",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_3.webm",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_4.webm",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_5.webm",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_6.webm",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_7.webm",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_8.webm",
];
export const AnimationScreens = [
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/Step_1.webp",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/Step_2.webp",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/Step_3.webp",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/Step_4.webp",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/Step_5.webp",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/step_6.webp",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/step_7.webp",
  "https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/screens/Step_8.webp",
];

export function extractTitleFromHTML(htmlString) {
  const parser = new DOMParser();
  const parsedHTML = parser.parseFromString(htmlString, "text/html");
  const titleText = parsedHTML.body.textContent;
  return titleText;
}

export const knowledgeCategories = [
  "Sustainability Essentials",
  "Policies & Regulations",
  "Case Studies",
  "Trends & Updates",
  "Op-ed",
  "More",
];
export const knowledgeCategories_de = [
  "Grundlagen der Nachhaltigkeit",
  "Politik und Regulierungen",
  "Case Studies",
  "Trends & Updates",
  "Meinungsartikel",
  "Weitere",
];

export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

export const FullMonthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export function formatNumberIntoReadableFormat(num) {
  if (num >= 1e9) {
    return (
      Number(num / 1e9).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "B"
    ); // Convert to billions
  } else if (num >= 1e6) {
    return (
      Number(num / 1e6).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "M"
    ); // Convert to millions
  } else if (num >= 1e3) {
    return (
      Number(num / 1e3).toLocaleString(undefined, {
        maximumFractionDigits: 1,
      }) + "K"
    ); // Convert to thousands
  }
  return Number(num).toLocaleString(undefined, {
    maximumFractionDigits: 2,
  }); // Number is less than 1,000, so no conversion needed
}

export const checkWorkEmail = (email) => {
  let emailRex =
    /^[a-zA-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!yahoo.co.in)(?!aol.com)(?!live.com)(?!outlook.com)(?!rediff.com)(?!protonmail.com)(?!gmx.de)(?!googlemail.com)(?!yahoo.co.uk)[a-zA-Z0-9_-]+.[a-zA-Z0-9-.]{2,61}$/;
  return emailRex.test(email);
};

export const chars = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];

export const localNumber = (val) =>
  Number(val).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
