import React, { useState } from "react";
import { Menu, Box, Tabs, Tab } from "@mui/material";
import TabLabels from "../../../../../../../LogDashboard/Components/TabLabels";
import PropTypes from "prop-types";
import InviteUser from "../InviteUser";
import onClickOutside from "react-onclickoutside";
import { Avatar } from "@mui/joy";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function AddUsers(props) {
  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  AddUsers.handleClickOutside = () => {
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleTabIndexChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className="d-flex align-items-center">
      <div
        onClick={(e) => {
          handleClick(e);
        }}
        className="circle_30 center bg-white pointer"
      >
        <i className="bi bi-person-plus fw-600"></i>
      </div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(e) => {
          handleClose(e);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="pl-20 pr-20 pb-20">
          <p className="font_14_600 color_7e mt-20">Assigned To</p>
          {props.companyUsers
            .filter(
              (currUser) =>
                currUser.assigned &&
                currUser?.assigned.find((assignedScope) => {
                  return assignedScope.scope === props.scopeDetails.scope;
                })
            )
            .map((currUser) => {
              return (
                <div
                  key={currUser.firstname}
                  className="d-flex justify-content-between align-items-center mt-20"
                >
                  <div className="d-flex align-items-center">
                    <Avatar sx={{ bgcolor: "pink" }}>
                      {currUser.firstname[0]}
                    </Avatar>
                    <p className="font_13_400 ml-12">{currUser.firstname}</p>
                  </div>

                  <div>
                    <p
                      onClick={() => {
                        props.removeUserFromScope(
                          currUser.email,
                          props.scopeDetails
                        );
                      }}
                      className="font_12_500 color_f4 pointer"
                    >
                      Remove
                    </p>
                  </div>
                </div>
              );
            })}

          <hr className="mt-20" />

          <div className="mt-20">
            <Box sx={{ width: "100%" }}>
              <Box>
                <Tabs
                  value={tabIndex}
                  onChange={handleTabIndexChange}
                  sx={() => ({
                    "& .MuiTouchRipple-child": {
                      backgroundColor: `#cacaca !important`,
                    },
                  })}
                  aria-label="basic tabs example"
                  className="general_root_tabs w-100 h-50px"
                >
                  <Tab
                    label={<TabLabels title={"My Team"} count={0} />}
                    {...a11yProps(0)}
                  />

                  <Tab
                    label={<TabLabels title={"External"} count={0} />}
                    {...a11yProps(1)}
                  />

                  <Tab
                    label={<TabLabels title={"Invite"} count={0} />}
                    {...a11yProps(2)}
                  />
                </Tabs>
              </Box>

              <TabPanel value={tabIndex} index={0}>
                {props.companyUsers
                  .filter((currUser) => !currUser.isExternal)
                  .map((currUser) => {
                    return (
                      <div
                        key={currUser.firstname}
                        className="d-flex justify-content-between align-items-center mt-20"
                      >
                        <div className="d-flex align-items-center">
                          <Avatar sx={{ bgcolor: "pink" }}>
                            {currUser.firstname ? currUser.firstname[0] : ""}
                          </Avatar>
                          <p className="font_13_400 ml-12">
                            {currUser.firstname}
                          </p>
                        </div>

                        <div>
                          <p
                            onClick={() => {
                              props.assignUserToScope(
                                currUser.email,
                                props.scopeDetails
                              );
                            }}
                            className="font_12_500 color_009 pointer"
                          >
                            Add
                          </p>
                        </div>
                      </div>
                    );
                  })}
              </TabPanel>

              <TabPanel value={tabIndex} index={1}>
                {props.companyUsers
                  .filter((currUser) => currUser.isExternal)
                  .map((currUser) => {
                    return (
                      <div
                        key={currUser.firstname}
                        className="d-flex justify-content-between align-items-center mt-20"
                      >
                        <div className="d-flex align-items-center">
                          <Avatar sx={{ bgcolor: "pink" }}>
                            {currUser.firstname ? currUser.firstname[0] : ""}
                          </Avatar>
                          <p className="font_13_400 ml-12">
                            {currUser.firstname}
                          </p>
                        </div>

                        <div>
                          <p className="font_12_500 color_009">Add</p>
                        </div>
                      </div>
                    );
                  })}
              </TabPanel>

              <TabPanel value={tabIndex} index={2}>
                <InviteUser />
              </TabPanel>
            </Box>
          </div>
        </div>
      </Menu>
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => AddUsers.handleClickOutside,
};
export default onClickOutside(AddUsers, clickOutsideConfig);
