import React from "react";

function AddIcon(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="18"
        fill="none"
        viewBox="0 0 17 18"
      >
        <path
          fill={props.stroke}
          fillRule="evenodd"
          d="M8.65 12.344a.822.822 0 01-.822-.822V6.587a.822.822 0 011.645 0v4.933a.822.822 0 01-.823.823z"
          clipRule="evenodd"
        ></path>
        <path
          fill={props.stroke}
          fillRule="evenodd"
          d="M5.352 9.057c0-.454.368-.823.822-.823h4.933a.822.822 0 010 1.645H6.174a.822.822 0 01-.822-.822z"
          clipRule="evenodd"
        ></path>
        <path
          fill={props.stroke}
          fillRule="evenodd"
          d="M8.645 2.473a6.578 6.578 0 100 13.156 6.578 6.578 0 000-13.156zM.422 9.05a8.223 8.223 0 1116.445 0 8.223 8.223 0 01-16.445 0z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default AddIcon;
