import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../../../Assets/icons/DelteIcon";
import useQuery from "../../../../Utils/query";
import { toast } from "react-hot-toast";
import axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../../../../Assets/components/Loader";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { KeyboardArrowDown, KeyboardArrowLeft } from "@mui/icons-material";
import BankTransfer from "../../../../Assets/images/BankTransfer.png";
import CreditCard from "../../../../Assets/images/CreditCard.png";
import IDEAL from "../../../../Assets/images/iDEAL.png";
import BankContact from "../../../../Assets/images/BankContact.png";
import Sofort from "../../../../Assets/images/Sofort.png";
import EditIcon from "../../../../Assets/icons/EditIcon";
import Sepa from "../../../../Assets/images/Sepa.png";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import BackForwardButton from "../../../../Assets/components/BackForwardButton/BackForwardButton";
import {
  CreditCardsJson,
  CreditCardsNameJson,
} from "../../../../Utils/CommonFunctions";
import ButtonComp from "../../../../Assets/components/Button";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none",
  borderBottom: "1px solid #E8EBEF",
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDown />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const Cart = ({
  // totalEmission,
  // logItem,
  totalProjects,
  visibleEmission,
  getCartDetails,
  // selectedOption,
  projectCart,
  totalAddedEmittion,
  unique_id,
  selectedMonths,
}) => {
  // const tax = 19;
  const [projects, setProjects] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const [ustPer, setUstPer] = useState(0);
  const [cart, setCart] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [allAddressess, setallAddressess] = useState(null);
  const { setSessionExpire, user, profile } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const logid = query.get("log");
  const [isAcceptTerms, setisAcceptTerms] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([
    { id: null, type: "bank_transfer" },
  ]);
  const [selectedPayment, setselectedPayment] = useState(null);
  const [, setclientSecret] = useState(null);
  const [billingLoading, setBillingLoading] = useState(false);

  const [step, setStep] = useState(0);
  const [billingStep, setBillingStep] = useState(0);

  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
  const [options, setoptions] = useState(null);
  // const [selectBankTransfer, setSelectBankTransfer] = useState(false);

  const [firstname, setfirstname] = useState(user && user.firstname);
  const [lastName, setlastName] = useState(user && user.lastname);
  const [email, setemail] = useState(user && user.email);
  const [companyName, setcompanyName] = useState(
    profile && profile.companyName
  );
  const [street, setstreet] = useState(profile && profile.street);
  const [streetNumber, setstreetNumber] = useState(
    profile && profile.streetNumber
  );
  const [state, setstate] = useState(profile && profile.state);
  const [city, setcity] = useState(profile && profile.city);
  const [zip, setzip] = useState(profile && profile.zip);
  const [country, setcountry] = useState(profile && profile.country);
  const [vat, setVat] = useState("");

  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [isDisabled, setisdisabled] = useState(false);

  const [countrycode, setcountrycode] = useState(
    profile && profile.countryCode
  );
  const [statecode, setstatecode] = useState(profile && profile.stateCode);

  const appearance = {
    theme: "stripe",
    rules: {
      ".Label": {
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "20px",
        textTransform: "uppercase",
        color: "#313B3E",
        marginBottom: "8px",
      },
    },
  };

  useEffect(() => {
    setCart(projectCart);
    if (totalProjects && projectCart) {
      setProjects(totalProjects);
      setTotalPrice(projectCart.grandTotal);
      setSubtotal(projectCart.allProjectPrice);
      setUstPer(projectCart.vat);
    }
  }, [totalProjects, projectCart]);

  const getAddress = async () => {
    try {
      const res = await axios({
        url: `/address/address`,
        method: "get",
      });

      if (res.data.success) {
        setallAddressess(res.data.data);
        setUserAddress(res.data.data[0]);
      }
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const removeItem = async (id) => {
    try {
      let res = await axios({
        url: `/project-cart/delete-project`,
        method: "delete",
        params: {
          cartId: cart._id,
          projectId: id,
        },
      });
      if (res.data.success) {
        getCartDetails(logid);
        toast.success("Project removed successfully");
      }
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const getPaymentMethods = async () => {
    await axios({
      url: `/stripe/paymentMethod`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data.data;
          let parArr = paymentMethods;
          parArr = [...parArr, ...data];
          setPaymentMethods(parArr);
          setselectedPayment(data[0] ? data[0] : null);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getAddress();
    getPaymentMethods();
  }, []);

  const handleUpdateCartSuccess = async (invoice, paymentIntent) => {
    try {
      setBillingLoading(true);
      const res = await axios.post(
        `/project-cart/update-success-cart/${projectCart._id}`,
        {
          invoice_id: invoice.id,
          invoice_url: invoice.hosted_invoice_url,
          invoice_pdf: invoice.invoice_pdf,
          paymentMethod: paymentIntent
            ? paymentIntent.payment_method
            : "bank_transfer",
          paymentIntent: paymentIntent ? paymentIntent.id : "",
          isPaid: paymentIntent ? true : false,
          log_id: logid,
          offset_log_id: unique_id,
          selectedMonths: selectedMonths,
        }
      );
      if (res.data.success) {
        setBillingLoading(false);
        toast.success("Purchase Complete");
        navigate(
          `/dashboard?tab=3&step=1&logId=${logid}&subtab=3&view-label=true`
        );
      }
    } catch (error) {
      setBillingLoading(false);
      toastResponseError(error, setSessionExpire);
    }
  };
  // make payment in stripe ** done
  // generate invoice ** done
  // purchase offset in cloverly ** done

  // generate offset payment orders & history ** done
  // update offset months ** done
  // update offset emissions ** done
  // generate CN label if offset is 100% for log period of 1 year
  // if option 2 ? update startdate enddate of log

  const handleConfirmPayment = async (paymentIntent, invoice) => {
    if (paymentIntent && paymentIntent.client_secret) {
      setBillingLoading(true);
      var stripe = await stripePromise;
      var { error } = stripe.confirmPaymentIntent(paymentIntent.client_secret);
      if (error) {
        setBillingLoading(false);
        toast.success(error.message);
      } else {
        handleUpdateCartSuccess(invoice, paymentIntent);
        setclientSecret(null);
        toast.success("Payment Successful");
        // history("/payment/success");
        setBillingLoading(false);
      }
    }
  };
  const handlePayment = async () => {
    try {
      setBillingLoading(true);
      let response = await axios.post("/stripe/offset-purchase", {
        payment_method: selectedPayment ? selectedPayment.id : null,
        paymentType: selectedPayment ? selectedPayment.type : null,
        offset_cart: projectCart._id,
        log: logid,
        offset_log_id: unique_id,
        billing_id: userAddress._id,
      });
      if (response.data.success) {
        let paymentIntent = response.data.data;
        let invoice = response.data.invoice;
        if (selectedPayment && selectedPayment.type !== "bank_transfer")
          handleConfirmPayment(paymentIntent, invoice);
        else handleUpdateCartSuccess(invoice, null);
        setclientSecret(paymentIntent.client_secret);
        setBillingLoading(false);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
      setBillingLoading(false);
    }
  };

  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const fetchcountries = async () => {
    const response = await axios.get(`/location/countries`);
    setcountries(response.data);
  };
  const fetchstates = async () => {
    const response = await axios.get(`/location/country/${countrycode}`);
    setstates(response.data);
  };

  const fetchcities = async () => {
    const response = await axios.get(
      `/location/cities/${countrycode}/${statecode}`
    );
    setcities(response.data);
  };

  useEffect(() => {
    if (!countries || !countries.length > 0) fetchcountries();
  }, []);

  useEffect(() => {
    if (countrycode) fetchstates();
  }, [countrycode]);

  useEffect(() => {
    if (statecode) fetchcities();
  }, [statecode]);

  const handleCardPayment = async (method) => {
    setBillingLoading(true);
    try {
      const response = await axios({
        url: `/stripe/payment-intent`,
        method: "get",
        params: {
          methodType: [method],
          isprojectCart: true,
        },
      });
      setoptions({
        clientSecret: response.data.data.client_secret,
        appearance: appearance,
      });
      // setSelectBankTransfer(false);
      setBillingLoading(false);
      setBillingStep(1);
    } catch (error) {
      setBillingLoading(false);
      toast.error(error.message);
    }
  };

  const handleSubmit = async () => {
    if (firstname === "" || firstname === null) {
      return toast.error("Please enter first name");
    }
    if (lastName === "" || lastName === null) {
      return toast.error("Please enter last name");
    }
    if (email === "" || email === null) {
      return toast.error("Please enter email");
    }
    if (
      street === "" ||
      street === null ||
      streetNumber === "" ||
      streetNumber === null ||
      country === "" ||
      country === null ||
      state === "" ||
      state === null ||
      city === "" ||
      city === null ||
      zip === "" ||
      zip === null ||
      countrycode === "" ||
      countrycode === null
    ) {
      return toast.error("Please enter full address");
    }
    try {
      setisdisabled(true);
      const response = await axios.post(
        `/address/billing-address/${cart._id}`,
        {
          firstName: firstname,
          lastName: lastName,
          emailAddress: email,
          CompanyName: companyName,
          street: street,
          houseNumber: streetNumber,
          zipCode: zip,
          State: state,
          City: city,
          Country: country,
          VATnumber: vat,
          countryCode: countrycode,
        }
      );
      if (response.data.success) {
        getAddress();
        toast.success("Details Submitted");
        setStep(0);
        setisdisabled(false);
      }
    } catch (error) {
      setisdisabled(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  return (
    <div className="cart_root">
      {billingLoading && <Loader />}
      <div className="row">
        <div className="col-lg-7 col-md-6 col-12 px-4">
          <div className="offsetmarket_left_box pe-4">
            <div className="offsetMarketPlace_breadcrumbs d-flex align-items-center pt-32 text_capital_f">
              <p
                className="breadcrumb_text_light_1 pointer"
                onClick={() => navigate(-2)}
              >
                <span className="">
                  <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
                </span>{" "}
                Contribution Overview
              </p>{" "}
              <p className="breadcrumb_text_light_1 ml-8">/</p>{" "}
              <p
                className="breadcrumb_text_light_1 ml-8 pointer"
                onClick={() => navigate(-1)}
              >
                {" "}
                Contribution marketplace
              </p>
              <p className="breadcrumb_text ml-8">/</p>
              <p className="breadcrumb_text ml-8"> Checkout</p>
            </div>
            {step === 0 ? (
              <>
                <div className="mt-32 mb-32">
                  {Number(totalAddedEmittion) &&
                  Number(visibleEmission) &&
                  Number(totalAddedEmittion).toFixed(2) <
                    Number(visibleEmission).toFixed(2) ? (
                    <p className="user_box bg_grey_light">
                      ❗️Please select 100% of the required contribution amount,
                      in order to checkout.
                    </p>
                  ) : Number(totalAddedEmittion).toFixed(0) ===
                    Number(visibleEmission).toFixed(0) ? (
                    <p className="text_capital heading_3">
                      🚀 Almost there. Let’s check your details one last time!
                    </p>
                  ) : Number(totalAddedEmittion) >= 1000 ? (
                    <p className="user_box bg_grey_light">
                      💳 ️You have selected 1000 tonnes or more for an
                      contribution project. We would like to offer you to
                      contact our sales team to see if we can negotiate a better
                      price for the large volume that you want to purchase.
                    </p>
                  ) : Number(totalAddedEmittion).toFixed(0) >
                    Number(visibleEmission).toFixed(0) ? (
                    <p className="user_box bg_grey_light">
                      ❗️You have selected more than the required contribution
                      amount.
                    </p>
                  ) : null}
                </div>
                <div className="mt-32 register_address_main ">
                  {userAddress && (
                    <div className="register_address_sub">
                      <div className="d-flex justify-content-between">
                        <p className="heading_6">
                          Billing Address{" "}
                          <span className="purple_label ms-3">default</span>
                        </p>
                      </div>
                      <p className="mt-12 subheading_2">
                        {userAddress.CompanyName}
                        <br />
                        {userAddress.firstName} {userAddress.lastName}
                        <br />
                        {userAddress.City} {userAddress.street}
                        <br />
                        {userAddress.zipCode}
                        <br />
                        {userAddress.State}, {userAddress.Country}
                      </p>
                    </div>
                  )}
                  {selectedPayment ? (
                    <div className="register_address_sub">
                      <div className="d-flex justify-content-between">
                        <p className="heading_6 mb-12">
                          Payment{" "}
                          <span className="purple_label ms-3">default</span>
                        </p>
                      </div>
                      {selectedPayment.type === "card" ? (
                        <>
                          <img
                            src={CreditCardsJson[selectedPayment.card.brand]}
                            alt={selectedPayment.card.brand}
                          />
                          <p className="mt-12 font_14_500">
                            {CreditCardsNameJson[selectedPayment.card.brand]}:
                            <span className="ml-8">
                              {selectedPayment.card.last4}
                            </span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Exp. Date:
                            <span className="ml-8">
                              {selectedPayment.card.exp_month}/
                              {selectedPayment.card.exp_year}
                            </span>
                          </p>
                          {/* <p>Name: Maxine Murphy</p> */}
                        </>
                      ) : selectedPayment.type === "sepa_debit" ? (
                        <>
                          <p className="font_14_500 mt-1">
                            {selectedPayment.type}:
                            <span className="ml-8">
                              {selectedPayment.sepa_debit.last4}
                            </span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Bank :
                            <span className="ml-8">
                              {selectedPayment.sepa_debit.country}
                              {selectedPayment.sepa_debit.bank_code}
                            </span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Name :
                            <span className="ml-8">
                              {selectedPayment.billing_details.name}
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="text-uppercase mb-10 heading_6">
                            Name on Invoice
                          </p>
                          <p className="subheading_2">
                            {user.firstname} {user.lastname}
                          </p>
                          <p className="subheading_2">{user.email}</p>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="register_address_sub">
                      <p className="heading_6 mb-12">
                        Payment{" "}
                        <span className="purple_label ms-3">default</span>
                      </p>
                      <p className="text-uppercase mb-10 heading_6">
                        Name on Invoice
                      </p>
                      <p className="subheading_2">
                        {user.firstname} {user.lastname}
                      </p>
                      <p className="subheading_2">{user.email}</p>
                    </div>
                  )}
                </div>
                <hr />
                <div className="row mt-24 mb-24">
                  <div className="col-6">
                    <ButtonComp
                      status={3}
                      className="text-black"
                      onClick={() => setStep(2)}
                    >
                      + New Billing Address
                    </ButtonComp>
                  </div>
                  <div className="col-6">
                    <ButtonComp
                      status={3}
                      className="text-black"
                      onClick={() => setStep(1)}
                    >
                      + New Payment Method
                    </ButtonComp>
                  </div>
                </div>
                <hr />
                <p className="mb-20">Other Payment Options</p>
                <div className="row mx-0">
                  <div className="col-6 ps-0">
                    <div className="d-flex flex-column">
                      {allAddressess &&
                        allAddressess.map((address, i) => (
                          <div className="general_card w-100 mb-20" key={i}>
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="heading_6">Billing Address</p>
                              <ButtonComp
                                status={3}
                                className="text-black"
                                onClick={() => setUserAddress(address)}
                                disabled={userAddress._id === address._id}
                              >
                                Select
                              </ButtonComp>
                            </div>
                            <p className="mt-12 subheading_2">
                              {address.CompanyName}
                              <br />
                              {address.firstName} {address.lastName}
                              <br />
                              {address.City} {address.street}
                              <br />
                              {address.zipCode}
                              <br />
                              {address.State}, {address.Country}
                            </p>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className="col-6 pe-0">
                    <div className="d-flex flex-column">
                      {paymentMethods &&
                        paymentMethods.map((pay, i) => (
                          <div
                            className="general_card w-100 mb-20 ml-0"
                            key={i}
                          >
                            <div className="d-flex justify-content-between align-items-center">
                              <p className="heading_6">Payment</p>
                              <ButtonComp
                                status={3}
                                className="text-black"
                                onClick={() => setselectedPayment(pay)}
                                disabled={
                                  selectedPayment &&
                                  selectedPayment.id === pay.id
                                }
                              >
                                Select
                              </ButtonComp>
                            </div>
                            {pay.type === "card" ? (
                              <>
                                <img
                                  src={CreditCardsJson[pay.card.brand]}
                                  alt={pay.card.brand}
                                />
                                <p className="mt-12 font_14_500">
                                  {CreditCardsNameJson[pay.card.brand]}:
                                  <span className="ml-8">{pay.card.last4}</span>
                                </p>
                                <p className="font_14_500 mt-1">
                                  Exp. Date:
                                  <span className="ml-8">
                                    {pay.card.exp_month}/{pay.card.exp_year}
                                  </span>
                                </p>
                                {/* <p>Name: Maxine Murphy</p> */}
                              </>
                            ) : pay.type === "sepa_debit" ? (
                              <>
                                <p className="font_14_500 mt-1">
                                  {pay.type}:
                                  <span className="ml-8">
                                    {pay.sepa_debit.last4}
                                  </span>
                                </p>
                                <p className="font_14_500 mt-1">
                                  Bank :
                                  <span className="ml-8">
                                    {pay.sepa_debit.country}
                                    {pay.sepa_debit.bank_code}
                                  </span>
                                </p>
                                <p className="font_14_500 mt-1">
                                  Name :
                                  <span className="ml-8">
                                    {pay.billing_details.name}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className="text-uppercase mb-10 heading_6">
                                  Name on Invoice
                                </p>
                                <p className="subheading_2">
                                  {user.firstname} {user.lastname}
                                </p>
                                <p className="subheading_2">{user.email}</p>
                              </>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </>
            ) : step === 1 ? (
              <div className="mt-32">
                {billingStep === 0 ? (
                  <>
                    <Accordion
                      expanded={expanded === 1}
                      onChange={handleExpandChange(1)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={Sepa} alt="Sepa" />
                            <p className="subheading_light_2 color_black ml-12">
                              SEPA Direct Debit
                            </p>
                            <div className="subtitle_box bg_soft_green ml-12">
                              <p className="color_green subheading_4">
                                0% Fees
                              </p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: Sepa,
                                title: "SEPA Direct Debit",
                                fees: "0% Fees",
                                bg: "bg_soft_green",
                                color: "color_green",
                                buttonText: "Authorize Mandate",
                                forwardBtn: false,
                              });
                              handleCardPayment("sepa_debit");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Select &apos;SEPA Direct Debit&apos; for an automatic
                          payment withdrawl. Provide your IBAN number, name and
                          email adress. Once the transaction has been completed,
                          you&apos;ll gain instant access to today.green. This
                          payment method does not include any additional
                          charges.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 2}
                      onChange={handleExpandChange(2)}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={BankTransfer} alt="Sepa" />
                            <p className="subheading_light_2 color_black ml-12">
                              Bank Transfer (Invoice)
                            </p>
                            <div className="subtitle_box bg_soft_green ml-12">
                              <p className="color_green subheading_4">
                                0% Fees
                              </p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setBillingStep(2);
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          This payment method provides flexibility when you
                          prefer to work with traditional invoices. Enter your
                          billing information and confirm your selection by
                          clicking &apos;Purchase & Download Invoice&apos; - the
                          invoice will download automatically. Make sure to
                          submit your payment within 14 days. Once the
                          transaction has been completed, you can start using
                          today.green.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 3}
                      onChange={handleExpandChange(3)}
                    >
                      <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={CreditCard} alt="Sepa" />
                            <p className="subheading_light_2 color_black ml-12">
                              Credit Card
                            </p>
                            <div className="subtitle_box bg_soft_orange ml-12">
                              <p className="color_orange subheading_4">+1,9%</p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: CreditCard,
                                title: "Credit Card",
                                fees: "+1.9%",
                                bg: "bg_soft_orange",
                                color: "color_orange",
                                buttonText: "Save & Continue",
                                forwardBtn: false,
                              });
                              handleCardPayment("card");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Pay with ease using the credit card payment method.
                          Enter your credit card information and get instant
                          access to today.green. This payment method includes an
                          additional charge of 1.9%.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 4}
                      onChange={handleExpandChange(4)}
                    >
                      <AccordionSummary
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={Sofort} alt="Sofort" />
                            <p className="subheading_light_2 color_black ml-12">
                              Sofort
                            </p>
                            <div className="subtitle_box bg_soft_orange ml-12">
                              <p className="color_orange subheading_4">+1.4%</p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: Sofort,
                                title: "Sofort",
                                fees: "+1.4%",
                                bg: "bg_soft_orange",
                                color: "color_orange",
                                buttonText: "Continue to SOFORT",
                                forwardBtn: true,
                              });
                              handleCardPayment("sofort");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Choose &apos;Sofort&apos; for a quick and secure bank
                          transfer. You&apos;ll be redirected to your
                          bank&apos;s online portal to authenticate your
                          payment. Please note, it may take 2 to 14 days for
                          transaction confirmation. Once the transaction has
                          been completed, you can start using today.green. This
                          payment method includes and additional charge of 1.4%.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 5}
                      onChange={handleExpandChange(5)}
                    >
                      <AccordionSummary
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={BankContact} alt="Bankcontact" />
                            <p className="subheading_light_2 color_black ml-12">
                              Bankcontact
                            </p>
                            <div className="subtitle_box bg_soft_orange ml-12">
                              <p className="color_orange subheading_4">+1.4%</p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: BankContact,
                                title: "Bankcontact",
                                fees: "+1.4%",
                                bg: "bg_soft_orange",
                                color: "color_orange",
                                buttonText: "Continue to Bankcontact",
                                forwardBtn: true,
                              });
                              handleCardPayment("bancontact");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Select &apos;Bankcontact&apos; for a quick and secure
                          bank transfer. You&apos;ll be redirected to Bancontact
                          to authorize your payment. Once the transaction has
                          been completed, you can start using today.green. This
                          payment method includes and additional charge of 1.4%.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 6}
                      onChange={handleExpandChange(6)}
                    >
                      <AccordionSummary
                        aria-controls="panel6d-content"
                        id="panel6d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={IDEAL} alt="iDEAL" />
                            <p className="subheading_light_2 color_black ml-12">
                              iDEAL
                            </p>
                            <div className="subtitle_box bg_soft_green ml-12">
                              <p className="color_green subheading_4">
                                0% Fees
                              </p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: IDEAL,
                                title: "iDEAL",
                                fees: "0% Fees",
                                bg: "bg_soft_green",
                                color: "color_green",
                                buttonText: "Continue to iDEAL",
                                forwardBtn: true,
                              });
                              handleCardPayment("ideal");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          iDeal allows for a quick and secure payment. Provide
                          your bank name and account holder information to
                          continue to iDeal. Confirm your payment and instantly
                          gain access to today.green. No additional charges are
                          included.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  </>
                ) : billingStep === 1 ? (
                  options && (
                    <Elements stripe={stripePromise} options={options}>
                      <CheckoutForm
                        selectedPaymentInfo={selectedPaymentInfo}
                        setValue={setStep}
                        setBillingStep={setBillingStep}
                        getPaymentMethods={getPaymentMethods}
                        log={logid}
                        unique_id={unique_id}
                      />
                    </Elements>
                  )
                ) : (
                  billingStep === 2 && (
                    <div>
                      <div className="d-flex align-items-center border-btm_ef pb-16 mb-24">
                        <img src={BankTransfer} alt="Sepa" />
                        <p className="subheading_light_2 color_black ml-12">
                          Bank Transfer (Invoice)
                        </p>
                        <div className="subtitle_box bg_soft_green ml-12">
                          <p className="color_green subheading_4">0% Fees</p>
                        </div>
                      </div>
                      <div className="row border-btm_ef pb-20 mb-20">
                        <div className="col">
                          <p className="text-uppercase mb-10 heading_6">
                            Name on Invoice
                          </p>
                          <p className="subheading_2">
                            {user.firstname} {user.lastname}
                          </p>
                          <p className="subheading_2">{user.email}</p>
                        </div>
                        <div className="col">
                          <p className="text-uppercase mb-10 heading_6">
                            Billing Address
                          </p>
                          <p className="mt-12 subheading_2">
                            {userAddress.CompanyName}
                            <br />
                            {userAddress.firstName} {userAddress.lastName}
                            <br />
                            {userAddress.City} {userAddress.street}
                            <br />
                            {userAddress.zipCode}
                            <br />
                            {userAddress.State}, {userAddress.Country}
                          </p>
                        </div>
                        <div className="" style={{ width: "fit-content" }}>
                          <button
                            className="profileEditbtn"
                            onClick={() => setStep(2)}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      </div>
                      <div className="  d-flex justify-content-between ">
                        <BackForwardButton
                          doNotShowForward={true}
                          handleGoBack={() => setBillingStep(0)}
                        />
                        <button
                          onClick={() => {
                            // setSelectBankTransfer(true);
                            setStep(0);
                          }}
                          className="primary_button_lg"
                        >
                          Confirm & Continue
                        </button>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              step === 2 && (
                <>
                  <div className="row mt-24 mb-32">
                    <div className="col-6">
                      <label className="heading_6 mb-8">FIRST NAME</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="First Name"
                        type="text"
                        value={firstname}
                        onChange={(e) => setfirstname(e.target.value)}
                      />
                    </div>
                    <div className="col-6">
                      <label className="heading_6 mb-8">LAST NAME</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="Last Name"
                        type="text"
                        value={lastName}
                        onChange={(e) => setlastName(e.target.value)}
                      />
                    </div>
                    <div className="col-6 mt-16">
                      <label className="heading_6 mb-8">E-MAIL ADDRESS</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="E-mail Address"
                        type="email"
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                      />
                    </div>
                    <div className="col-6 mt-16">
                      <label className="heading_6 mb-8">COMPANY NAME</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="Company Name"
                        type="text"
                        value={companyName}
                        onChange={(e) => setcompanyName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row mt-56">
                    <div className="col-6">
                      <label className="heading_6 mb-8">STREET</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="Street"
                        type="text"
                        value={street}
                        onChange={(e) => setstreet(e.target.value)}
                      />
                    </div>
                    <div className="col-6">
                      <label className="heading_6 mb-8">NUMBER</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="Street Number"
                        type="text"
                        value={streetNumber}
                        onChange={(e) => setstreetNumber(e.target.value)}
                      />
                    </div>
                    <div className="col-6 mt-16">
                      <label className="heading_6 mb-8">COUNTRY</label>
                      <select
                        className="border_input w-100 subheading_2"
                        placeholder="Country"
                        type="text"
                        value={`${countrycode}` + "#" + `${country}`}
                        onChange={(e) => {
                          setcountry(e.target.value.split("#")[1]);
                          setcountrycode(e.target.value.split("#")[0]);
                        }}
                      >
                        <option value="">Choose Country</option>
                        {countries &&
                          countries.map((option, i) => (
                            <option
                              className=""
                              key={i}
                              value={`${option.iso2}` + "#" + `${option.name}`}
                            >
                              {option.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-6 mt-16">
                      <label className="heading_6 mb-8">STATE</label>
                      <select
                        className="border_input w-100 subheading_2"
                        placeholder="State"
                        type="text"
                        value={`${statecode}` + "#" + `${state}`}
                        onChange={(e) => {
                          setstate(e.target.value.split("#")[1]);
                          setstatecode(e.target.value.split("#")[0]);
                        }}
                      >
                        <option value="">Choose State</option>
                        {states &&
                          states.map((option, i) => (
                            <option
                              className=""
                              key={i}
                              value={`${option.iso2}` + "#" + `${option.name}`}
                            >
                              {option.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-4 mt-16">
                      <label className="heading_6 mb-8">CITY</label>
                      <select
                        className="border_input w-100 subheading_2"
                        placeholder="City"
                        type="text"
                        value={city}
                        onChange={(e) => setcity(e.target.value)}
                      >
                        <option value="">Choose City</option>
                        {cities &&
                          cities.map((option, i) => (
                            <option className="" key={i} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div className="col-4 mt-16">
                      <label className="heading_6 mb-8">ZIP</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="Zip"
                        type="text"
                        value={zip}
                        onChange={(e) => setzip(e.target.value)}
                      />
                    </div>
                    <div className="col-4 mt-16">
                      <label className="heading_6 mb-8">VAT (OPTIONAL)</label>
                      <input
                        className="border_input w-100 subheading_2"
                        placeholder="VAT"
                        type="text"
                        value={vat}
                        onChange={(e) => setVat(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="mt-24 d-flex justify-content-end">
                    <button
                      disabled={isDisabled}
                      onClick={handleSubmit}
                      className="primary_button_lg"
                    >
                      Continue
                    </button>
                  </div>
                </>
              )
            )}
          </div>
        </div>
        <div className="col-lg-5 col-md-6 col-12">
          <div className="offsetmarket_right_">
            <div className="offsetCheckout_items_root">
              <p className="font_17_700">
                Cart Summary ({projects.length} Project
                {projects.length === 1 ? "" : "s"})
              </p>
              <div className="offsetCheckout_items_main mt-32">
                {projects.length > 0 &&
                  projects.map((project) => (
                    <div
                      className="offsetCheckout_items d-flex align-items-center justify-content-between mb-24"
                      key={project._id}
                    >
                      <div className="d-flex align-items-center mr-24 flex-grow-1">
                        <img
                          src={project.projectImage}
                          alt="item"
                          className="offsetCheckout_items_img"
                        />
                        <div className="ml-12 col-12">
                          <p className="subheading_5 text_overflow-1">
                            {project.offsetProjectName}
                          </p>
                          <p className="subheading_light_2 color_92 mt-8">
                            {Number(project.tonsOffset).toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                            tons (
                            {Number(
                              project.totalPrice / project.tonsOffset
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                            EUR/t)
                          </p>
                        </div>
                      </div>
                      <div
                        className="d-flex align-items-end flex-column"
                        style={{ width: "-webkit-fill-available" }}
                      >
                        <p className="font_15_600">
                          {Number(project.totalPrice).toLocaleString(
                            undefined,
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            }
                          )}
                          €
                        </p>
                        <button
                          className="profileEditbtn mt-10"
                          onClick={() => removeItem(project.projectId)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
            <div className="price_calculation_ mt-32">
              <div className="d-flex align-items-center justify-content-between">
                <p className="subheading_light_2 color_8a">Subtotal</p>
                <p className="subheading_light_2 color_8a">
                  {subtotal &&
                    Number(subtotal).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  €
                </p>
              </div>
              {/* <div className="d-flex align-items-center justify-content-between mt-10">
                <p className="subheading_light_2 color_8a">Payment Fees</p>
                <p className="subheading_light_2 color_8a">12,00€</p>
              </div> */}
              {userAddress && userAddress.Country === "Germany" ? (
                <div className="d-flex align-items-center justify-content-between mt-10">
                  <p className="subheading_light_2 color_8a">Tax (19%)</p>
                  <p className="subheading_light_2 color_8a">
                    {ustPer &&
                      Number(ustPer).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    €
                  </p>
                </div>
              ) : null}
              <hr className="mt-b mb-16" />
              <div className="d-flex align-items-center justify-content-between">
                <p className="heading_9">Total</p>
                <p className="heading_9">
                  {userAddress && userAddress.Country === "Germany"
                    ? Number(totalPrice).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                    : Number(subtotal).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                  €
                </p>
              </div>
              <div className="mt-50 d-flex align-items-center">
                <input
                  type="radio"
                  checked={isAcceptTerms}
                  value={isAcceptTerms}
                  onClick={() => setisAcceptTerms(!isAcceptTerms)}
                />
                <p className="overline_text ml-8">
                  I accept the{" "}
                  <a
                    href="https://www.today.green/legal/agb"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="fw-bold termsNconditionsAnchor"
                  >
                    terms & conditions
                  </a>{" "}
                  from today.green
                </p>
              </div>
              <div className="row  mt-20">
                <div className="col">
                  <ButtonComp
                    status={1}
                    className="w-100"
                    disabled={
                      (totalProjects && totalProjects.length === 0) ||
                      Number(totalAddedEmittion).toFixed(2) <
                        Number(visibleEmission).toFixed(2) ||
                      Number(totalAddedEmittion).toFixed(2) >
                        Number(visibleEmission).toFixed(2) ||
                      !isAcceptTerms
                    }
                    onClick={() => handlePayment()}
                  >
                    Purchase Now
                  </ButtonComp>
                </div>
                <div className="col">
                  <ButtonComp
                    status={3}
                    className="text-black w-100"
                    disabled={totalProjects && totalProjects.length === 0}
                  >
                    Download Cart
                  </ButtonComp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
