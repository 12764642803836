import React from "react";
import KeyboardDownIcon from "../../../../../../Assets/icons/KeyboardDownIcon";

const TargetHeaderCard = ({
  title,
  percent,
  papercent,
  // tonsValue,
  isBaseLine,
  footerValue,
}) => {
  return (
    <div className="container_br_10_p_10_15 w-100">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font_20_700">{title || ""}</p>
        <div className="bg_soft_green p-10x-3y h-20px center border_radius_10">
          <p className="color_green font_14_600 line_height_14">
            {papercent || ""}
          </p>
        </div>
      </div>
      <div className="d-flex align-items-end justify-content-between mt-18">
        <div>
          <p
            className={`heading_2 line_height_22 ${
              title === "Reduced YTD"
                ? percent >= 0
                  ? "color_red"
                  : "color_green"
                : ""
            }`}
          >
            {percent || percent === 0
              ? Number(percent).toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                })
              : ""}{" "}
            <span className="font_14_700 color_7e">tCO2e</span>
          </p>

          <p className="mt-8 font_10_600">{footerValue}</p>
        </div>
        {!isBaseLine && (
          <div className="h-30px w-30px border_radius_5 bg_f6 center">
            <KeyboardDownIcon cc={"#00915B"} />
          </div>
        )}
      </div>
    </div>
  );
};

export default TargetHeaderCard;
