import React, { useEffect, useState } from "react";
import { useMapStore } from "../../../../../Utils/CompanyMapStore";
import { Drawer, Box, Tab, Tabs } from "@mui/material";
import TabLabels from "../../../LogDashboard/Components/TabLabels";
import DetailTab from "./Components/DetailsTab";
import CompanyMapEmissionSources from "./Components/EmissionSources";
import CompanyMapAssignment from "./Components/Assignment";
import ActivationAccItem from "./Components/ActivationAccItem";
import OverviewTab from "./Components/OverviewTab";

const tabTitles = [
  ["Details", "Emission Sources", "Assignment", "Activation"],
  ["Overview", "Details", "Emission Sources", "Assignment"],
];
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

function AddAccountingItemDrawer() {
  const {
    addAccItemDrawerOpen,
    handleAccItemDrawer,
    activeAccItemTabIndex,
    handleAccItemTabChange,
    activeNodeData,
  } = useMapStore();
  const [isNodeActivated, setIsNodeActivated] = useState(0);
  // const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    if (activeNodeData.isActivate) setIsNodeActivated(1);
    else setIsNodeActivated(0);
  }, [activeNodeData]);

  const handleTabIndexChange = (event, newValue) => {
    handleAccItemTabChange(newValue);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Drawer
      anchor={"right"}
      open={addAccItemDrawerOpen}
      onClose={() => {
        handleAccItemDrawer(false, true);
      }}
      PaperProps={{
        sx: { width: "40%", maxWidth: 1056 },
      }}
    >
      <Box className="mt-12 pl-20 pr-20" sx={{ width: "100%" }}>
        <Box>
          <Tabs
            value={activeAccItemTabIndex}
            onChange={handleTabIndexChange}
            sx={() => ({
              "& .MuiTouchRipple-child": {
                backgroundColor: `#cacaca !important`,
              },
            })}
            aria-label="basic tabs example"
            className="general_root_tabs w-100 h-50px"
          >
            {tabTitles[isNodeActivated].map((currTitle, currTitleIndex) => {
              return (
                <Tab
                  key={currTitle + currTitleIndex}
                  label={
                    <TabLabels
                      title={currTitle}
                      classes={"font_16_600"}
                      count={0}
                    />
                  }
                  {...a11yProps(currTitleIndex)}
                />
              );
            })}
          </Tabs>
        </Box>

        {isNodeActivated ? (
          <TabPanel value={activeAccItemTabIndex} index={0}>
            <OverviewTab />
          </TabPanel>
        ) : (
          ""
        )}

        <TabPanel value={activeAccItemTabIndex} index={isNodeActivated}>
          <DetailTab />
        </TabPanel>

        <TabPanel value={activeAccItemTabIndex} index={1 + isNodeActivated}>
          <CompanyMapEmissionSources />
        </TabPanel>

        <TabPanel value={activeAccItemTabIndex} index={2 + isNodeActivated}>
          <CompanyMapAssignment />
        </TabPanel>

        {!isNodeActivated ? (
          <TabPanel value={activeAccItemTabIndex} index={3 + isNodeActivated}>
            <ActivationAccItem />
          </TabPanel>
        ) : (
          ""
        )}
      </Box>
    </Drawer>
  );
}

export default AddAccountingItemDrawer;
