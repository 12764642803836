import moment from "moment-timezone";

const getTimeDifference = (date) => {
  var scheduleTime = moment(date);
  var current = moment();
  var yearDiff = current.diff(scheduleTime, "years");
  if (yearDiff > 1) return Math.abs(yearDiff) + " years ago";
  else if (yearDiff === 1) return Math.abs(yearDiff) + " year ago";
  else {
    let monthDiff = current.diff(scheduleTime, "months");
    if (monthDiff > 1) return Math.abs(monthDiff) + " months ago";
    else if (monthDiff === 1) return Math.abs(monthDiff) + " month ago";
    else {
      let daysdiff = current.diff(scheduleTime, "days");
      if (daysdiff > 1) return Math.abs(daysdiff) + " days ago";
      // Check if it's today
      else if (current.isSame(scheduleTime, "day")) {
        return "today";
      } else if (current.subtract(1, "day").isSame(scheduleTime, "day")) {
        // Check if it's yesterday
        return "yesterday";
      } else if (daysdiff === 1) return Math.abs(daysdiff) + " day ago";
      else {
        let hourdiff = current.diff(scheduleTime, "hours");
        if (hourdiff > 1) return Math.abs(hourdiff) + " hours ago";
        else if (hourdiff === 1) return Math.abs(hourdiff) + " hour ago";
        else {
          let mindiff = current.diff(scheduleTime, "minutes");
          if (mindiff > 0) return Math.abs(mindiff) + " min ago";
          else {
            let secdiff = current.diff(scheduleTime, "seconds");
            return Math.abs(secdiff) + " sec ago";
          }
        }
      }
    }
  }
};

export default getTimeDifference;
