import React, { useState } from "react";
import axios from "../../Api/index";
import { useNavigate } from "react-router-dom";
import useQuery from "../../Utils/query";
import { toast } from "react-hot-toast";
// import bg from "../../Assets/images/Placeholder_GIF.gif";
import ButtonComp from "../../Assets/components/Button";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";

export default function ForgetPassword() {
  const [email, setemail] = useState("");
  const [isDisable, setisDisable] = useState(false);
  const query = useQuery();
  const emailQuery = Boolean(query.get("email"));
  const history = useNavigate();
  //   const [emailSent] = useState(emailQuery ? emailQuery : false);
  const handleSubmit = async () => {
    try {
      setisDisable(true);
      const response = await axios.post("/user/auth/forgot-password", {
        email,
      });
      if (response.data.success) {
        history("/forget-password?email=true");
        setisDisable(false);
      }
    } catch (error) {
      setisDisable(false);
      toast.error(error.message);
    }
  };

  return (
    <div className="container-fluid">
      <div className=" row register_background auth_background">
        <div
          className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
          style={{
            backgroundImage: `url(${AnimationScreens[7]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <video
            className="reg_vdo"
            src={AnimationVideos[7]}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
            controls={false}
            poster={AnimationScreens[7]}
          ></video>
        </div>
        {!emailQuery ? (
          <div className="d-flex col-md-5 align-items-center register_info_container">
            <div className="d-flex align-items-start flex-column justify-content-center ml-32 col-md-10 max_width_552">
              {/* <p className="purple_label mb-16"></p> */}
              <p className="heading_1 mt-16 mb-16 text-start">
                Forgot your Password?
              </p>
              <p className="text-start body_text_l mt-24 mb-24">
                Please enter your email
              </p>
              <input
                type="email"
                className="form-control auth_input  border-bottom  shadow-none"
                id="email"
                placeholder="Email"
                aria-describedby="emailHelp"
                value={email}
                onChange={(e) => setemail(e.target.value)}
                // placeholder="Enter email"
              />
              <div className="d-flex mt-40">
                <ButtonComp
                  status={1}
                  size="lg"
                  style={{ width: "100%", padding: "10px 35px" }}
                  type="submit"
                  // className="secondary_button_active"
                  onClick={() => handleSubmit()}
                  disabled={isDisable}
                >
                  Submit
                </ButtonComp>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex col-md-5 align-items-center register_info_container">
            <div className="d-flex align-items-start flex-column justify-content-center ml-32 col-md-10 max_width_552">
              {/* <p className="purple_label mb-16"></p> */}
              <p className="heading_1 mt-16 mb-16 text-start">
                Please check your email!
              </p>
              <p className="text-start body_text_l mt-24">
                We have sent a verification link on your registered email,
                please click on the link to proceed.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
