import React from "react";
import "./ProductCards.css";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";

function ProductCard3(props) {
  return (
    <div className="productCard_container px-3">
      <div className="productCard_content align_text_left ">
        <p className="f-13 fw-600 align_text_left mt-20">ACTION STATUS</p>
        <div className="d-flex pt-12 pb-2">
          <div>
            <p className="green_label">
              {" "}
              <img
                src={getServiceIcon("offset status")}
                className="h-16px"
              />{" "}
              No Contribution Required
            </p>
          </div>
          <div className="ml-16">
            <p className="neutral_label color_light">
              {" "}
              <img
                src={getServiceIcon("target status")}
                className="h-16px mb-1"
              />{" "}
              Target not set
            </p>
          </div>
          <div className="ml-16">
            <p className="neutral_label color_light">
              <img
                src={getServiceIcon("reporting status")}
                className="h-16px"
              />{" "}
              Not Ready to Report({props.year})
            </p>
          </div>
        </div>
      </div>

      <div className="productCard_height mt-20">
        <div className="align_text_left">
          <p className="f-13 fw-600 align_text_left mt-20">CONNECTED ITEMS</p>
        </div>
        <div className="d-flex justify-content-between mt-20">
          {/* <div className="neutral_label">
            <p className="body_text_s ">HQ Berlin, 63637</p>
          </div> */}
          {props.connectedItems && props.connectedItems.length > 0 ? (
            props.connectedItems.map((item, index) => {
              return (
                <div key={index} className="neutral_label ml-4">
                  <p className="body_text_s ">{item}</p>
                </div>
              );
            })
          ) : (
            <div className="neutral_label ml-4">
              <p className="body_text_s ">N/A</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProductCard3;
