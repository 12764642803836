import React from "react";
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  //   XAxis,
  YAxis,
  CartesianGrid,
} from "recharts";
import { formatNumberIntoReadableFormat } from "../../../Utils/CommonFunctions";

const EmissionCategoryPlaceholder = () => {
  const data = [
    { value: 18 },
    { value: 15 },
    { value: 10 },
    { value: 15 },
    { value: 12 },
    { value: 10 },
    { value: 10 },
    { value: 16 },
    { value: 13 },
    { value: 12 },
    { value: 15 },
    { value: 10 },
  ];
  return (
    <ResponsiveContainer height={300} width={"100%"}>
      <BarChart
        data={data}
        barGap={"30%"}
        barCategoryGap="38%"
        bar
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#efefef" vertical={false} />

        <Bar
          dataKey="value"
          fill="#00915b"
          radius={[0, 0, 0, 0]}
          opacity={0.5}
        />
        {/* <XAxis
          dataKey="name"
          // tickFormatter={(val) => <img src={getServiceIcon(val)} />}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
        /> */}
        <YAxis
          tickFormatter={(val) => `${formatNumberIntoReadableFormat(val)} t`}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default EmissionCategoryPlaceholder;
