import React from "react";

const SearchIcon = ({ cc, className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      fill="none"
      viewBox="0 0 17 17"
      className={className}
      style={style}
    >
      <g clipPath="url(#clip0_8411_6279)">
        <path
          fill={cc || "#727E8F"}
          d="M10.98 9.917h-.56l-.199-.192a4.584 4.584 0 001.112-2.996 4.604 4.604 0 10-4.604 4.604c1.14 0 2.189-.418 2.996-1.112l.192.199v.56l3.541 3.534 1.056-1.056-3.535-3.541zm-4.25 0a3.183 3.183 0 01-3.188-3.188 3.183 3.183 0 013.187-3.187 3.183 3.183 0 013.188 3.187 3.183 3.183 0 01-3.188 3.188z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_8411_6279">
          <path fill="#fff" d="M0 0H17V17H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
