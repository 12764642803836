import React from "react";
import './index.css';

const EmptyText = ({ className, height, bg, borderRadius, width }) => {
  return (
    <div
      className={"EmptyText "+className}
      style={{
        height: height,
        backgroundColor: bg,
        borderRadius: borderRadius,
        width:width
      }}
    />
  );
};

export default EmptyText;
