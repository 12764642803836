import React from "react";
import "./index.css";
import WhilteEarthLargeIcon from "../../icons/WhilteEarthLargeIcon";
import { useAuth } from "../../../Utils/Auth";
import { useTranslation } from "react-i18next";

const HorizontalLabel = ({ imageRef, item, isProfile_zero }) => {
  const {t} = useTranslation()
  const { profile } = useAuth();
  return (
    <div className="horizontal_label_root" ref={imageRef}>
      <div className="horizontal_label_left">
        <WhilteEarthLargeIcon />
      </div>
      <div className="horizontal_label_right">
        <p className="carlabel_extrabold">{t("store.carbonNeutralLabel")}</p>
        <p className="carlabel_semibold">{item}</p>
        <p className="mt-6 carlabel_bold_italic">{t("store.byCompensation")}</p>
        <hr className="carlabel_hr" />
        <p className="carlabel_normal_italic">
          {isProfile_zero
            ? `today.green/00000-000-0000`
            : `today.green/${
                profile.labelId ? profile.labelId : "00000-000-0000"
              }`}
        </p>
      </div>
    </div>
  );
};

export default HorizontalLabel;
