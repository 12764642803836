import React from "react";

const CameraAddIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="20"
      fill="none"
      viewBox="0 0 22 20"
    >
      <path
        stroke={cc || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 14.91a3.28 3.28 0 003.285-3.274A3.28 3.28 0 0011 8.364a3.28 3.28 0 00-3.286 3.272A3.28 3.28 0 0011 14.91z"
      ></path>
      <path
        stroke={cc || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.285 4.682l-.821-1.227H8.535L6.892 5.909H3.607c-.436 0-.854.173-1.162.48a1.633 1.633 0 00-.481 1.156v9c0 .434.173.85.481 1.158.308.306.726.479 1.162.479h14.785c.436 0 .854-.172 1.162-.48.308-.306.481-.723.481-1.157v-9M16.75 3.045h4.107M18.804 1v4.09"
      ></path>
    </svg>
  );
};

export default CameraAddIcon;
