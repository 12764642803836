import { Info } from "@mui/icons-material";
import { Slider, useTheme } from "@mui/material";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import "./index.css";
import ButtonComp from "../../../Assets/components/Button";

const ImageCropper = ({ image, onCropDone, onCropCancel, infoStr, ratio }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [rotation, setRotation] = useState(0);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const theme = useTheme();
  const fixedRatio = 1 / 1;
  return (
    <div className="setting-cropper-main position-relative">
      <p className="title2 mb-32">Upload Cover Image</p>
      <button className="setting-cropper-close" onClick={onCropCancel}>
        X
      </button>
      <div>
        <p className="overline grey_75">
          <span className="ml-8">
            <Info htmlColor="#000000" fontSize="small" />
          </span>{" "}
          {infoStr ? infoStr : "File aspect ratio should be in 4:1"}
        </p>
        <p className="overline grey_75 mt-1">
          <span className="ml-8">
            <Info htmlColor="#000000" fontSize="small" />
          </span>{" "}
          File format should be in .png , .jpeg, .jpg
        </p>
      </div>
      <div
        style={{ height: 260, width: "100%" }}
        className="position-relative mt-16"
      >
        <Cropper
          image={image}
          aspect={ratio ? ratio : fixedRatio}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          cropShape="rect"
          showGrid={true}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onRotationChange={setRotation}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "80%",
              backgroundColor: "#fff",
            },
          }}
        />
      </div>
      <div className="setting-cropper-contrls-main">
        <div className="setting-cropper-contrls d-flex align-items-center">
          <p className="body1 mr-32">Zoom</p>
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            sx={{
              color:
                theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
              "& .MuiSlider-track": {
                border: "none",
              },
              "& .MuiSlider-thumb": {
                width: 24,
                height: 24,
                backgroundColor: "#fff",
                "&:before": {
                  boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible, &.Mui-active": {
                  boxShadow: "none",
                },
              },
            }}
          />
        </div>
        <div className="setting-cropper-contrls d-flex align-items-center mt-16">
          <p className="body1 mr-32">Rotate</p>
          <Slider
            value={rotation}
            min={0}
            max={180}
            step={1}
            aria-labelledby="Rotation"
            onChange={(e, rot) => {
              setRotation(rot);
            }}
            sx={{
              color:
                theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
              "& .MuiSlider-track": {
                border: "none",
              },
              "& .MuiSlider-thumb": {
                width: 24,
                height: 24,
                backgroundColor: "#fff",
                "&:before": {
                  boxShadow: "0 4px 8px rgba(0,0,0,0.4)",
                },
                "&:hover, &.Mui-focusVisible, &.Mui-active": {
                  boxShadow: "none",
                },
              },
            }}
          />
        </div>
      </div>

      <div className="mt-48 d-flex align-items-center">
        <ButtonComp
          className="mr-24"
          status={1}
          onClick={() => {
            onCropDone(croppedArea, rotation);
          }}
        >
          Upload
        </ButtonComp>
        <ButtonComp status={2} onClick={onCropCancel}>
          Cancel
        </ButtonComp>
      </div>
    </div>
  );
};

export default ImageCropper;
