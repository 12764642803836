import React from "react";

const ListViewIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      fill="none"
      viewBox="0 0 20 17"
    >
      <path
        stroke={cc || "#575757"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19 1H1M19 6H1M19 11H1M19 16H1"
      ></path>
    </svg>
  );
};

export default ListViewIcon;
