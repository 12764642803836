import React, { useEffect, useRef, useState } from "react";
import EditIcon from "../../../Assets/icons/EditIcon";
import { useAuth } from "../../../Utils/Auth";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import TabLabels from "../../../Components/Companydashboard/LogDashboard/Components/TabLabels";
import Users from "./Users";
import "./index.css";
// import Invoices from "./Invoices";
// import Billing from "./Billing";
// import Subscriptions from "./Subscriptions";
import axios from "../../../Api";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../Utils/toastResponseError";
import industries from "../../../Authentication/Register/industries.json";
// import nextYearDate from "../../../Utils/nextYearDate";
import ButtonComp from "../../../Assets/components/Button";
import getUrlqueries from "../../../Utils/getUrlQueries";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanySettings = () => {
  const { setSessionExpire } = useAuth();
  // const [user] = useState(null);
  const [profile, setProfile] = useState(null);
  const cid = getUrlqueries("cid");
  async function getUser() {
    await axios
      .get(`/admin/particularcompany/${cid}`)
      .then((res) => {
        if (res.data.success) setProfile(res.data.user);
      })
      .catch(() => {});
  }
  useEffect(() => {
    if (cid) {
      getUser();
    }
  }, [cid]);
  const [value, setValue] = useState(0);
  const [isCompanyEdit, setIsCompanyEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  // const [firstCart, setFirstCart] = useState(null);
  const [companyProfile, setCompanyProfile] = useState({
    companyName: "",
    website: "",
    industry: "",
    empCount: "",
    logo: "",
    facilities: "",
  });

  // refs
  const inputFile = useRef();

  const handlePicUpload = async (e) => {
    const image = e.target.files[0];
    if (image.size > 4 * 1024 * 1024) {
      toast.error("File size exceeded 4MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("company-logo", image);
    setIsLogoUploading(true);
    await axios({
      method: "post",
      url: `/admin/company-logo`,
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setIsLogoUploading(false);
        setCompanyProfile({ ...companyProfile, logo: data.location });
        toast.success("Logo uploaded");
      })
      .catch((err) => {
        setIsLogoUploading(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInformation = (e) => {
    const { name, value } = e.target;
    setCompanyProfile({ ...companyProfile, [name]: value });
  };

  // const getFirstCart = async () => {
  //   try {
  //     let res = await axios.get("/cart/first-cart");
  //     if (res.data.success && res.data.cart && res.data.cart.length > 0) {
  //       setFirstCart(res.data.cart[0]);
  //     }
  //   } catch (error) {
  //     toastResponseError(error, setSessionExpire);
  //   }
  // };

  useEffect(() => {
    if (profile) {
      setCompanyProfile({
        companyName: profile?.companyName,
        website: profile?.website,
        industry: profile?.industry,
        empCount: profile?.empCount,
        facilities: profile?.facilities,
        logo: profile?.logo,
      });
      // getFirstCart();
    }
  }, [profile]);

  const updateDetails = async () => {
    if (
      !companyProfile.companyName ||
      !companyProfile.website ||
      !companyProfile.industry ||
      !companyProfile.empCount ||
      !companyProfile.facilities
    ) {
      return toast.error("Please fill all the fields");
    }
    setDisable(true);
    await axios({
      url: `/admin/edit-company/${cid}`,
      method: "patch",
      data: { regDetails: companyProfile },
    })
      .then(async (res) => {
        if (res.data.success) {
          await getUser();
          setDisable(false);
          setIsCompanyEdit(false);
          toast.success("Settings updated successfully");
        }
      })
      .catch((err) => {
        setDisable(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const updatePaidCompany = async () => {
    try {
      let res = await axios({
        url: `/admin/mark-paid/${cid}`,
        method: "get",
      });
      if (res.data.success) {
        await getUser();
        setDisable(false);
        setIsCompanyEdit(false);
        toast.success("Marked as paid!");
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  return (
    <div className="profile_root ml-100 mr-50">
      <div className="row">
        <div className="col-lg-10 col-md-11 col-12">
          <div className="main_box">
            <div className="profile_setting_card">
              <div className="border-btm_dc d-flex align-items-center justify-content-between pb-24">
                <p className="offsetProjectTitle">Company Settings</p>
                {isCompanyEdit ? (
                  <ButtonComp
                    status={1}
                    className="md"
                    onClick={() => {
                      updateDetails();
                    }}
                    disabled={disable}
                  >
                    Save
                  </ButtonComp>
                ) : (
                  <button
                    className="profileEditbtn"
                    onClick={() => {
                      setIsCompanyEdit(true);
                    }}
                  >
                    <EditIcon />
                  </button>
                )}
              </div>
              {isCompanyEdit ? (
                <div>
                  <div className="border-btm_dc d-flex align-items-center pt-24 pb-24">
                    {isLogoUploading ? (
                      <div
                        style={{
                          height: 72,
                          width: 72,
                          border: "1px solid #efefef",
                          borderRadius: "50%",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="position-relative">
                        <Avatar
                          src={companyProfile.logo}
                          sx={{
                            height: 72,
                            width: 72,
                            border: "1px solid #efefef",
                          }}
                        >
                          {String(profile?.companyName).charAt(0).toUpperCase()}
                        </Avatar>
                        <button
                          className="profileEditbtn profileImg_upload_btn"
                          onClick={() => inputFile.current.click()}
                        >
                          <EditIcon />
                        </button>
                        <input
                          type="file"
                          hidden
                          ref={inputFile}
                          onChange={handlePicUpload}
                        />
                      </div>
                    )}
                    <div className="ml-24">
                      <div className="d-flex align-items-center">
                        <p className="card_heading">{profile?.companyName}</p>
                        {/* <div className="adminBox">Admin</div> */}
                      </div>
                      <p className="mt-6 subheading_light_2 color_8a">
                        {profile?.street} {profile?.zip} {profile?.state}{" "}
                        {profile?.country}
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-between mt-30 border-btm_dc pb-40">
                    <div className="col-lg-5 col-md-6 col-12">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">Company</p>
                        <input
                          type="text"
                          className="body_text_m basicinput"
                          value={companyProfile.companyName}
                          onChange={handleInformation}
                          name="companyName"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">Number of Locations</p>
                        <div
                          className={`d-flex basicinput align-items-center justify-content-between`}
                        >
                          <span
                            className="pointer"
                            onClick={() => {
                              if (companyProfile.facilities > 0) {
                                setCompanyProfile({
                                  ...companyProfile,
                                  facilities: companyProfile.facilities - 1,
                                });
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="3"
                              fill="none"
                              viewBox="0 0 12 3"
                            >
                              <path
                                fill="#575757"
                                d="M1.344 1.45h8.914-8.914z"
                              ></path>
                              <path
                                stroke="#4C4C4C"
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M1.344 1.45h8.914"
                              ></path>
                            </svg>
                          </span>
                          <input
                            type="number"
                            className="facility_input_inc body_text_m"
                            value={companyProfile.facilities}
                            onChange={handleInformation}
                          />
                          <span
                            className="pointer"
                            onClick={() => {
                              setCompanyProfile({
                                ...companyProfile,
                                facilities: companyProfile.facilities + 1,
                              });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="12"
                              fill="none"
                              viewBox="0 0 11 12"
                            >
                              <path
                                fill="#575757"
                                d="M1.313 6.45h8.914-8.915z"
                              ></path>
                              <path
                                stroke="#4C4C4C"
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M1.313 6.45h8.914"
                              ></path>
                              <path
                                fill="#575757"
                                d="M5.766 11.172V1.726v9.446z"
                              ></path>
                              <path
                                stroke="#4C4C4C"
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M5.766 11.172V1.726"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-16">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">Website</p>
                        <input
                          type="text"
                          className="body_text_m basicinput"
                          value={companyProfile.website}
                          onChange={handleInformation}
                          name="website"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-16">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">Number of Employees</p>
                        <select
                          name="empCount"
                          id="empCount"
                          value={companyProfile.empCount}
                          onChange={handleInformation}
                          className="body_text_m basicinput"
                          style={{ minWidth: 220 }}
                        >
                          <option value="1-10">1-10</option>
                          <option value="11-50">11-50</option>
                          <option value="51-250">51-250</option>
                          <option value="250+">250+</option>
                        </select>
                        {/* <input
                        type="text"
                        value={companyProfile.empCount}
                        onChange={handleInformation}
                        name="empCount"
                      /> */}
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-16">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">Industry</p>
                        {/* <input
                        type="text"
                        className="body_text_m basicinput"
                        value={companyProfile.industry}
                        onChange={handleInformation}
                        name="industry"
                      /> */}
                        <Autocomplete
                          className="company_autocomplete"
                          disablePortal
                          id="industry"
                          options={industries}
                          value={companyProfile.industry}
                          name={"industry"}
                          onChange={(e) => {
                            setCompanyProfile({
                              ...companyProfile,
                              industry: e.target.outerText,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="border-btm_dc d-flex align-items-center pt-24 pb-24">
                    <Avatar
                      src={profile?.logo}
                      sx={{
                        height: 72,
                        width: 72,
                        border: "1px solid #efefef",
                      }}
                    >
                      {String(profile?.companyName).charAt(0).toUpperCase()}
                    </Avatar>
                    <div className="ml-24">
                      <div className="d-flex align-items-center">
                        <p className="card_heading">{profile?.companyName}</p>
                        {profile?.admins[0].isBasePaid ? (
                          <div className="square_box bg_soft_blue ml-16">
                            <p className="color_blue text-capitalize font_12_500">
                              All-In-One Suite
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      <p className="mt-6 subheading_light_2 color_8a">
                        {profile?.street} {profile?.zip} {profile?.state}{" "}
                        {profile?.country}
                      </p>
                    </div>
                    {/* {user ? (
                      <>
                        {user.isBasePaid ? (
                          <div className="ms-auto">
                            <p>470.00€ /month</p>
                            <p className="mt-6 subheading_light_2 color_8a">
                              Next Invoice on{" "}
                              {firstCart
                                ? nextYearDate("2023-06-26T11:40:42.578Z")
                                : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="pt-32">
                    <div className="row">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">Company</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.companyName}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">Website</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.website}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">Industry</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.industry}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">Number of Locations</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.facilities}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">Number of Employees</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.empCount}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="mt-24 row align-items-center justify-content-between">
              <p className="body_text_m fit-w">
                Account Status &nbsp;&nbsp;
                {profile?.admins[0].isBasePaid ? (
                  <span className=" text-capitalize green_label">Active</span>
                ) : (
                  <span className=" text-capitalize red_label">In-Active</span>
                )}{" "}
              </p>
              {!profile?.admins[0].isBasePaid && (
                <ButtonComp
                  status={1}
                  width="lg"
                  onClick={() => updatePaidCompany()}
                >
                  MARK AS PAID
                </ButtonComp>
              )}
            </div>
            <Box sx={{ width: "100%" }} className="mt-40">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="general_root_tabs"
                >
                  <Tab
                    label={<TabLabels title={"Users"} />}
                    {...a11yProps(0)}
                  />
                  {/* <Tab
                    label={<TabLabels title={"Invoices"} />}
                    {...a11yProps(1)}
                  /> */}
                </Tabs>
              </Box>
              <div className="mt-32">
                <TabPanel value={value} index={0}>
                  <Users />
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                  <Invoices />
                  <Billing />
                  <Subscriptions />
                </TabPanel> */}
              </div>
            </Box>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanySettings;
