import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="32"
      fill="none"
      viewBox="0 0 31 32"
    >
      <path
        fill="#00915B"
        d="M27.906 11.405h-3.382c-.02 0-.041.003-.062.003h-.113a4.392 4.392 0 01-4.392-4.392v-.18.002-3.383A2.861 2.861 0 0017.094.594h-3.382a2.861 2.861 0 00-2.861 2.861v3.383a2.861 2.861 0 002.86 2.86H17.277a4.392 4.392 0 014.392 4.391v.074c-.001.035-.005.069-.005.103v3.383a2.861 2.861 0 002.861 2.861h3.382a2.861 2.861 0 002.862-2.861v-3.383a2.861 2.861 0 00-2.862-2.861z"
      ></path>
      <path
        fill="#00915B"
        d="M27.907 22.226h-3.382l-.063.003h-.112a4.392 4.392 0 01-4.392-4.392v-.18.001-3.382a2.85 2.85 0 00-.844-2.028 2.851 2.851 0 00-2.028-.843h-3.382c-.021 0-.042.003-.062.003h-.113a4.392 4.392 0 01-4.392-4.392v-.18.002-3.383A2.861 2.861 0 006.275.594H2.893A2.861 2.861 0 00.03 3.455v3.383a2.861 2.861 0 002.862 2.86H6.456a4.392 4.392 0 014.392 4.391v.074c-.002.034-.005.069-.005.103v3.383c0 .793.322 1.51.843 2.028.518.52 1.235.843 2.027.843h3.383l.033-.002h.148a4.392 4.392 0 014.392 4.392v.074c-.002.035-.006.068-.006.103v3.382a2.861 2.861 0 002.862 2.862h3.382a2.861 2.861 0 002.862-2.862v-3.382a2.861 2.861 0 00-2.862-2.861z"
      ></path>
      <path
        fill="#00915B"
        d="M17.086 22.225h-3.382c-.021 0-.042.003-.062.003h-.113a4.392 4.392 0 01-4.392-4.392v-.18.002-3.383a2.861 2.861 0 00-2.862-2.86H2.893a2.861 2.861 0 00-2.862 2.86v3.383a2.861 2.861 0 002.862 2.861h3.382l.034-.002h.147a4.392 4.392 0 014.392 4.393v.074c-.002.034-.005.068-.005.103v3.382a2.861 2.861 0 002.86 2.861h3.383a2.861 2.861 0 002.862-2.861v-3.382a2.861 2.861 0 00-2.862-2.862z"
      ></path>
    </svg>
  );
}

export default Icon;
