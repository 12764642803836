import React, { useEffect, useRef, useState } from "react";
import EditIcon from "../../../Assets/icons/EditIcon";
import { useAuth } from "../../../Utils/Auth";
import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Tabs,
  TextField,
} from "@mui/material";
import PropTypes from "prop-types";
import TabLabels from "../LogDashboard/Components/TabLabels";
import Users from "./Users";
import "./index.css";
import Invoices from "./Invoices";
import Billing from "./Billing";
import Subscriptions from "./Subscriptions";
import axios from "../../../Api";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../Utils/toastResponseError";
import industries from "../../../Authentication/Register/industries.json";
import nextYearDate from "../../../Utils/nextYearDate";
import ButtonComp from "../../../Assets/components/Button";
import KeyboardForwardIcon from "../../../Assets/icons/KeyboardForwardIcon";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "white",
  border: "1px solid #EFEFEF",
  boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.0588235)",
  borderRadius: "8px",
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CompanySettings = () => {
  const { getUser, profile, setSessionExpire, user, signOutBasic } = useAuth();
  const [value, setValue] = useState(0);
  const [isCompanyEdit, setIsCompanyEdit] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [firstCart, setFirstCart] = useState(null);
  const [isConfirm, setisConfirm] = useState(false);
  const [companyProfile, setCompanyProfile] = useState({
    companyName: "",
    website: "",
    industry: "",
    empCount: "",
    logo: "",
    facilities: "",
  });
  const history = useNavigate();
  const [error, seterror] = useState(false);
  // refs
  const inputFile = useRef();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handlePicUpload = async (e) => {
    const image = e.target.files[0];
    if (image.size > 4 * 1024 * 1024) {
      toast.error("File size exceeded 4MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("company-logo", image);
    setIsLogoUploading(true);
    await axios({
      method: "post",
      url: `/company/company-logo`,
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setIsLogoUploading(false);
        setCompanyProfile({ ...companyProfile, logo: data.location });
        toast.success("Logo uploaded");
      })
      .catch((err) => {
        setIsLogoUploading(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInformation = (e) => {
    const { name, value } = e.target;
    setCompanyProfile({ ...companyProfile, [name]: value });
  };

  const getFirstCart = async () => {
    try {
      let res = await axios.get("/cart/first-cart");
      if (res.data.success && res.data.cart && res.data.cart.length > 0) {
        setFirstCart(res.data.cart[0]);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    if (profile) {
      setCompanyProfile({
        companyName: profile?.companyName,
        website: profile?.website,
        industry: profile?.industry,
        empCount: profile?.empCount,
        facilities: profile?.facilities,
        logo: profile?.logo,
      });
      getFirstCart();
    }
  }, [profile]);

  const updateDetails = async () => {
    if (
      !companyProfile.companyName ||
      !companyProfile.website ||
      !companyProfile.industry ||
      !companyProfile.empCount ||
      !companyProfile.facilities
    ) {
      return toast.error("Please fill all the fields");
    }
    setDisable(true);
    await axios({
      url: `company/edit-company`,
      method: "patch",
      data: { regDetails: companyProfile },
    })
      .then(async (res) => {
        if (res.data.success) {
          await getUser();
          setDisable(false);
          setIsCompanyEdit(false);
          toast.success("Settings updated successfully");
        }
      })
      .catch((err) => {
        setDisable(false);
        toastResponseError(err, setSessionExpire);
      });
  };
  const handleDeleteAccount = async () => {
    if (!isConfirm) {
      return seterror(true);
    }
    try {
      const res = await axios({
        method: "post",
        url: "/company/handle-cancel",
        data: { isCancel: true },
      });
      if (res.data.success) {
        toast.success(`Your account has been deleted!`);
        signOutBasic();
        history("/signup");
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const { t } = useTranslation();
  return (
    <div className="profile_root ml-100 mr-50">
      <div className="row">
        <div className="col-lg-10 col-md-11 col-12">
          <div className="main_box">
            <div className="profile_setting_card">
              <div className="border-btm_dc d-flex align-items-center justify-content-between pb-24">
                <p className="offsetProjectTitle">{t("settings.companySettings")}</p>
                {isCompanyEdit ? (
                  <ButtonComp
                    status={1}
                    className="md"
                    onClick={() => {
                      updateDetails();
                    }}
                    disabled={disable}
                  >
                    {t("common.save")}
                  </ButtonComp>
                ) : (
                  <button
                    className="profileEditbtn"
                    onClick={() => {
                      setIsCompanyEdit(true);
                    }}
                  >
                    <EditIcon />
                  </button>
                )}
              </div>
              {isCompanyEdit ? (
                <div>
                  <div className="border-btm_dc d-flex align-items-center pt-24 pb-24">
                    {isLogoUploading ? (
                      <div
                        style={{
                          height: 72,
                          width: 72,
                          border: "1px solid #efefef",
                          borderRadius: "50%",
                        }}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <CircularProgress />
                      </div>
                    ) : (
                      <div className="position-relative">
                        <Avatar
                          src={companyProfile.logo}
                          sx={{
                            height: 72,
                            width: 72,
                            border: "1px solid #efefef",
                          }}
                        >
                          {String(profile?.companyName).charAt(0).toUpperCase()}
                        </Avatar>
                        <button
                          className="profileEditbtn profileImg_upload_btn"
                          onClick={() => inputFile.current.click()}
                        >
                          <EditIcon />
                        </button>
                        <input
                          type="file"
                          hidden
                          ref={inputFile}
                          onChange={handlePicUpload}
                        />
                      </div>
                    )}
                    <div className="ml-24">
                      <div className="d-flex align-items-center">
                        <p className="card_heading">{profile?.companyName}</p>
                        {/* <div className="adminBox">Admin</div> */}
                      </div>
                      <p className="mt-6 subheading_light_2 color_8a">
                        {profile?.street} {profile?.zip} {profile?.state}{" "}
                        {profile?.country}
                      </p>
                    </div>
                  </div>
                  <div className="row justify-content-between mt-30 border-btm_dc pb-40">
                    <div className="col-lg-5 col-md-6 col-12">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">{t("common.company")}</p>
                        <input
                          type="text"
                          className="body_text_m basicinput"
                          value={companyProfile.companyName}
                          onChange={handleInformation}
                          name="companyName"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">{t("settings.numberOfLocations")}</p>
                        <div
                          className={`d-flex basicinput align-items-center justify-content-between`}
                        >
                          <span
                            className="pointer"
                            onClick={() => {
                              if (companyProfile.facilities > 0) {
                                setCompanyProfile({
                                  ...companyProfile,
                                  facilities: companyProfile.facilities - 1,
                                });
                              }
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="12"
                              height="3"
                              fill="none"
                              viewBox="0 0 12 3"
                            >
                              <path
                                fill="#575757"
                                d="M1.344 1.45h8.914-8.914z"
                              ></path>
                              <path
                                stroke="#4C4C4C"
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M1.344 1.45h8.914"
                              ></path>
                            </svg>
                          </span>
                          <input
                            type="number"
                            className="facility_input_inc body_text_m"
                            value={companyProfile.facilities}
                            onChange={handleInformation}
                          />
                          <span
                            className="pointer"
                            onClick={() => {
                              setCompanyProfile({
                                ...companyProfile,
                                facilities: companyProfile.facilities + 1,
                              });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="11"
                              height="12"
                              fill="none"
                              viewBox="0 0 11 12"
                            >
                              <path
                                fill="#575757"
                                d="M1.313 6.45h8.914-8.915z"
                              ></path>
                              <path
                                stroke="#4C4C4C"
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M1.313 6.45h8.914"
                              ></path>
                              <path
                                fill="#575757"
                                d="M5.766 11.172V1.726v9.446z"
                              ></path>
                              <path
                                stroke="#4C4C4C"
                                strokeLinecap="round"
                                strokeWidth="1.5"
                                d="M5.766 11.172V1.726"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-16">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">{t("settings.website")}</p>
                        <input
                          type="text"
                          className="body_text_m basicinput"
                          value={companyProfile.website}
                          onChange={handleInformation}
                          name="website"
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-16">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">{t("settings.numberOfEmp")}</p>
                        <select
                          name="empCount"
                          id="empCount"
                          value={companyProfile.empCount}
                          onChange={handleInformation}
                          className="body_text_m basicinput"
                          style={{ minWidth: 220 }}
                        >
                          <option value="1-10">1-10</option>
                          <option value="11-50">11-50</option>
                          <option value="51-250">51-250</option>
                          <option value="250+">250+</option>
                        </select>
                        {/* <input
                        type="text"
                        value={companyProfile.empCount}
                        onChange={handleInformation}
                        name="empCount"
                      /> */}
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 col-12 mt-16">
                      <div className="d-flex align-items-center justify-content-between ">
                        <p className="font_14_500">{t("settings.industry")}</p>
                        {/* <input
                        type="text"
                        className="body_text_m basicinput"
                        value={companyProfile.industry}
                        onChange={handleInformation}
                        name="industry"
                      /> */}
                        <Autocomplete
                          className="company_autocomplete"
                          disablePortal
                          id="industry"
                          options={industries}
                          value={companyProfile.industry}
                          name={"industry"}
                          onChange={(e) => {
                            setCompanyProfile({
                              ...companyProfile,
                              industry: e.target.outerText,
                            });
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="standard" />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="border-btm_dc d-flex align-items-center pt-24 pb-24">
                    <Avatar
                      src={profile?.logo}
                      sx={{
                        height: 72,
                        width: 72,
                        border: "1px solid #efefef",
                      }}
                    >
                      {String(profile?.companyName).charAt(0).toUpperCase()}
                    </Avatar>
                    <div className="ml-24">
                      <div className="d-flex align-items-center">
                        <p className="card_heading">{profile?.companyName}</p>
                        <div className="square_box bg_soft_blue ml-16">
                          <p className="color_blue text-capitalize font_12_500">
                            All-In-One Suite
                          </p>
                        </div>
                      </div>
                      <p className="mt-6 subheading_light_2 color_8a">
                        {profile?.street} {profile?.zip} {profile?.state}{" "}
                        {profile?.country}
                      </p>
                    </div>
                    {profile ? (
                      <>
                        {profile.hasBaseCredit ? (
                          <div className="ms-auto">
                            <p>570€ /{t("common.month")}</p>
                            <p className="mt-6 subheading_light_2 color_8a">
                              {t("settings.nextInvoiceOn")}{" "}
                              {firstCart
                                ? nextYearDate("2023-06-26T11:40:42.578Z")
                                : ""}
                            </p>
                          </div>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="pt-32">
                    <div className="row">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">{t("common.company")}</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.companyName}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">{t("settings.website")}</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.website}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">{t("settings.industry")}</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.industry}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">{t("settings.numberOfLocations")}</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.facilities}</p>
                      </div>
                    </div>
                    <div className="row mt-24">
                      <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                        <p className="font_14_500">{t("settings.numberOfEmp")}</p>
                      </div>
                      <div className="col">
                        <p className="body_text_m">{profile?.empCount}</p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            <Box sx={{ width: "100%" }} className="mt-72">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="general_root_tabs"
                >
                  <Tab
                    label={<TabLabels title={t("settings.users")} />}
                    {...a11yProps(0)}
                  />
                  <Tab
                    label={<TabLabels title={t("settings.invoices")} />}
                    {...a11yProps(1)}
                  />
                </Tabs>
              </Box>
              <div className="mt-32">
                <TabPanel value={value} index={0}>
                  <Users />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <Invoices />
                  <Billing />
                  <Subscriptions />
                  <div className="border-top_ef mt-56 d-flex justify-content-end">
                    <ButtonComp
                      status={3}
                      className="color_red mt-16"
                      onClick={handleOpen}
                    >
                      {t("settings.deleteAccount")}
                    </ButtonComp>
                  </div>
                </TabPanel>
              </div>
            </Box>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="p-32">
              <div className="border-btm_ef pb-24">
                <p className="offsetProjectTitle">Detele Account</p>
              </div>
              <div className="bg_grey_light width_fit d-flex align-items-center gap-5 px-24 py-20 border_radius_10 mt-16">
                <p className="font_16_500 ">
                  ❗️Deleting your account will result in emission data loss
                </p>
                <p className="font_16_500 color_blue ">
                  Learn More
                  <KeyboardForwardIcon cc={"#2441F5"} className={"ml-8"} />
                </p>
              </div>
              <TableContainer
                component={Paper}
                sx={{
                  minWidth: 640,
                  borderRadius: "9px",
                  boxShadow: "none",
                }}
                className="mr-50 mt-20"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        component="th"
                        className="text-uppercase border_left_0"
                      >
                        Account
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        E-Mail
                      </HeadCell>
                      <HeadCell
                        component="th"
                        className="text-uppercase border_right_0"
                      >
                        Member since
                      </HeadCell>

                      <HeadCell
                        component="th"
                        className="text-uppercase border_right_0"
                      >
                        Emission Data present
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell
                        className="text-start border_left_0"
                        component="th"
                        scope="row"
                      >
                        {user.firstname} {user.lastname}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {user.email}
                      </StyledTableCell>
                      <StyledTableCell className="text-start ">
                        {user.createdAt
                          ? new Date(user.createdAt).toLocaleDateString("en-GB")
                          : "-"}
                      </StyledTableCell>

                      <StyledTableCell className="text-start">
                        23GB
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="border-top_ef mt-24 pt-24 ">
                <p className="helper_text">
                  You are about to delete your account. This will result in the
                  loss of the emission data present in this account. If you
                  delete your account, all active subscriptions will be
                  cancelled automatically.
                  <br />
                  <br />
                  If you have any questions regarding the termination policies,
                  please contact our customer success team. 📧
                </p>
                <div className="mt-40 csrd_assessment_input_radio">
                  <input
                    type="radio"
                    name="acceptdelteaccount"
                    id="acceptdelteaccount"
                    checked={isConfirm}
                    onChange={() => setisConfirm(!isConfirm)}
                  />
                  <label
                    className={`font_13_400 csrd_assessment_radio-label ml-10 ${
                      error ? "color_red" : ""
                    }`}
                    htmlFor="acceptdelteaccount"
                  >
                    I understand the implications of deleting my account
                  </label>
                </div>
              </div>
              <div className="border-top_ef mt-16 pt-16 d-flex justify-content-end gap-3">
                <ButtonComp
                  status={3}
                  className="text-black"
                  onClick={handleClose}
                >
                  GoBack
                </ButtonComp>
                <ButtonComp
                  status={3}
                  className="color_red"
                  onClick={() => handleDeleteAccount()}
                >
                  Delete Account
                </ButtonComp>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default CompanySettings;
