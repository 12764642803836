import React from "react";

function CenterIcon({onClick, className}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      onClick={onClick}
      className={"pointer "+ " " + className}
    >
      <rect
        width="30"
        height="30"
        fill="#00915B"
        rx="5"
        transform="matrix(-1 0 0 1 30 0)"
      ></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 26.5C8.649 26.5 3.5 21.351 3.5 15S8.649 3.5 15 3.5 26.5 8.649 26.5 15 21.351 26.5 15 26.5z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15.25 25.5v-2M25.5 15.5h-2M6.5 15.5h-2M15 6.5v-2"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M15 10.5l-3.5 9 3.5-1.895 3.5 1.895-3.5-9z"
      ></path>
    </svg>
  );
}

export default CenterIcon;
