import React from "react";

const Faqprofile = ({ faqs }) => {
  return (
    <div>
      <h1 className="heading_9">FAQs</h1>
      {faqs.map((v, i) => {
        return (
          <div key={i}>
            <h1>Question : {v.question} </h1>
            <p>Answer : {v.answer} </p>
            <p>Link : {v.link} </p>
          </div>
        );
      })}
    </div>
  );
};

export default Faqprofile;
