import React from "react";

const TwoUpArrow = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      fill="none"
      viewBox="0 0 12 11"
    >
      <path
        stroke={cc || "#FF993C"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 6l5-5 5 5"
      ></path>
      <path
        stroke={cc || "#FF993C"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 10l5-5 5 5"
      ></path>
    </svg>
  );
};

export default TwoUpArrow;
