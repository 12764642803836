import React, { useEffect, useMemo, useState } from "react";
import ButtonComp from "../../../../../Assets/components/Button";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { PropTypes } from "prop-types";
import PostCard from "./Components/PostCard";
import { Link } from "react-router-dom";
import axios from "../../../../../Api/index";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import parse from "html-react-parser";
import { ArrowForward } from "@mui/icons-material";
import { TablePagination } from "@mui/material";
import {useTranslation} from 'react-i18next'
import { useAuth } from "../../../../../Utils/Auth";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function KnowledgeHub() {
  const {user} = useAuth()
  const {t} = useTranslation()
  const [tabValue, setTabValue] = useState(0);
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [mostReadDetails, setMostReadDetails] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(9);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getKnowledgeDetails = async () => {
    try {
      await axios.get(`/academy/knowledge/${tabValue}`).then((response) => {
        if (response.data.success) {
          setKnowledgeData(response.data.data);
        }
      });
    } catch (error) {
      toastResponseError(error);
    }
  };

  const getMostReadDetails = async () => {
    try {
      await axios.get(`/academy/mostread`).then((response) => {
        if (response.data.success) {
          setMostReadDetails(response.data.data);
        }
      });
    } catch (error) {
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getKnowledgeDetails();
  }, [tabValue]);
  useEffect(() => {
    getMostReadDetails();
  }, []);

  const visibleRows = useMemo(
    () =>
      knowledgeData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, knowledgeData]
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className=" mt-20 pr-20">
        <div className="card_design_2 row border_radius_10 mx-0 box_shadow_none border-ef">
          <div className="col-6 p-30 vertical_between">
            <div>
              <div className="black_box_small fit-w">
                <p className="font_16_700 text_capital fit-w">
                  {t("academy.mostRead")}
                </p>
              </div>
              {mostReadDetails.title && (
                <p className="font_36_700 mt-20">
                  {user.language === 'de' && mostReadDetails.title_de ?parse(mostReadDetails.title_de) :parse(mostReadDetails.title)}
                </p>
              )}
            </div>
            <div className="mt-36">
              <Link to={`/dashboard?tab=7&mostRead=1`}>
                <ButtonComp status={3}>
                  {t("academy.readNow")}
                  <ArrowForward className="ml-10" htmlColor="#3574E0" />
                </ButtonComp>
              </Link>
            </div>
          </div>

          <div className="col-6 p-20 d-flex justify-content-end">
            <img
              src={mostReadDetails.coverimage}
              className="border_radius_10"
              style={{ height: 300 }}
            />
            {/* <p className="neutral_label most_read_date">
              <span>{new Date(mostReadDetails.createdAt).getDate()}</span>{" "}
              <span>
                {monthNames[new Date(mostReadDetails.createdAt).getMonth()]}
              </span>{" "}
              <span>{new Date(mostReadDetails.createdAt).getFullYear()}</span>
            </p> */}
          </div>
        </div>
      </div>

      <div className="mt-50 pb-40">
        <Box sx={{ width: "100%" }} className="mt-32">
          <Box>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              variant="scrollable"
              aria-label="basic tabs example"
              // className="general_root_tabs"
              className="academy_knowledge_tab"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "&.MuiTabs-scroller ": {
                  "&.MuiTabs-flexContainer": {
                    columnGap: 30,
                  },
                },
              }}
            >
              <Tab
                label={<span className={`font_16_500`}>{t("academy.allPosts")}</span>}
                className={tabValue === 0 ? "green_box" : "green_outline_box"}
                {...a11yProps(0)}
              />
              <Tab
                label={
                  <span className={`font_16_500`}>
                    Sustainability Essentials
                  </span>
                }
                className={tabValue === 1 ? "green_box" : "green_outline_box"}
                {...a11yProps(1)}
              />
              <Tab
                label={
                  <span className={`font_16_500`}>{t("academy.Policies&Regulations")}</span>
                }
                className={tabValue === 2 ? "green_box" : "green_outline_box"}
                {...a11yProps(2)}
              />
              <Tab
                label={<span className={`font_16_500`}>{t("academy.CaseStudies")}</span>}
                className={tabValue === 3 ? "green_box" : "green_outline_box"}
                {...a11yProps(3)}
              />
              <Tab
                label={<span className={`font_16_500`}>{t("academy.Trends&Updates")}</span>}
                className={tabValue === 4 ? "green_box" : "green_outline_box"}
                {...a11yProps(4)}
              />
              <Tab
                label={<span className={`font_16_500`}>{t("academy.Op-ed")}</span>}
                className={tabValue === 5 ? "green_box" : "green_outline_box"}
                {...a11yProps(5)}
              />
              <Tab
                label={<span className={`font_16_500`}>{t("academy.more")}</span>}
                className={tabValue === 6 ? "green_box" : "green_outline_box"}
                {...a11yProps(6)}
              />
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0}>
            <div className="row mt-22 mb-20">
              {visibleRows.map((currInfo, index) => {
                return (
                  <div
                    className="col-lg-4 col-md-6 col-sm-6 col-12 mb-20"
                    key={index + currInfo._id}
                  >
                    <Link
                      to={`/dashboard?tab=7&acadTab=1&kid=${currInfo._id}&step=1`}
                    >
                      <PostCard cardDetails={currInfo} />
                    </Link>
                  </div>
                );
              })}

              {knowledgeData && knowledgeData.length > 9 && (
                <TablePagination
                  rowsPerPageOptions={[9, 15, 21]}
                  component="div"
                  count={knowledgeData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  className="table-pagiation"
                />
              )}
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <div className="row mt-22 mb-20">
              {knowledgeData.map((currInfo, index) => {
                return (
                  <div className="col-4 mb-20" key={index + currInfo._id}>
                    <Link
                      to={`/dashboard?tab=7&acadTab=1&kid=${currInfo._id}&step=1`}
                    >
                      <PostCard cardDetails={currInfo} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <div className="row mt-22 mb-20">
              {knowledgeData.map((currInfo, index) => {
                return (
                  <div className="col-4 mb-20" key={index + currInfo._id}>
                    <Link
                      to={`/dashboard?tab=7&acadTab=1&kid=${currInfo._id}&step=1`}
                    >
                      <PostCard cardDetails={currInfo} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <div className="row mt-22 mb-20">
              {knowledgeData.map((currInfo, index) => {
                return (
                  <div className="col-4 mb-20" key={index + currInfo._id}>
                    <Link
                      to={`/dashboard?tab=7&acadTab=1&kid=${currInfo._id}&step=1`}
                    >
                      <PostCard cardDetails={currInfo} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={4}>
            <div className="row mt-22 mb-20">
              {knowledgeData.map((currInfo, index) => {
                return (
                  <div className="col-4 mb-20" key={index + currInfo._id}>
                    <Link
                      to={`/dashboard?tab=7&acadTab=1&kid=${currInfo._id}&step=1`}
                    >
                      <PostCard cardDetails={currInfo} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={5}>
            <div className="row mt-22 mb-20">
              {knowledgeData.map((currInfo, index) => {
                return (
                  <div className="col-4 mb-20" key={index + currInfo._id}>
                    <Link
                      to={`/dashboard?tab=7&acadTab=1&kid=${currInfo._id}&step=1`}
                    >
                      <PostCard cardDetails={currInfo} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </TabPanel>
          <TabPanel value={tabValue} index={6}>
            <div className="row mt-22 mb-20">
              {knowledgeData.map((currInfo, index) => {
                return (
                  <div className="col-4 mb-20" key={index + currInfo._id}>
                    <Link
                      to={`/dashboard?tab=7&acadTab=1&kid=${currInfo._id}&step=1`}
                    >
                      <PostCard cardDetails={currInfo} />
                    </Link>
                  </div>
                );
              })}
            </div>
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export default KnowledgeHub;
