import React from "react";

const KeyboardForwardIcon = ({ cc, style, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="8"
      fill="none"
      viewBox="0 0 18 8"
      style={style}
      className={className}
    >
      <path
        stroke={cc ? cc : "#000000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M1.258 3.993h16m0 0l-3.24-3.24m3.24 3.24l-3.24 3.239"
      ></path>
    </svg>
  );
};

export default KeyboardForwardIcon;
