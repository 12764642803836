import React, { useState } from 'react'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import BankTransfer from "../../../../../../../../../../../Assets/images/BankTransfer.png";
import CreditCard from "../../../../../../../../../../../Assets/images/CreditCard.png";
import IDEAL from "../../../../../../../../../../../Assets/images/iDEAL.png";
import BankContact from "../../../../../../../../../../../Assets/images/BankContact.png";
import Sofort from "../../../../../../../../../../../Assets/images/Sofort.png";
import Sepa from "../../../../../../../../../../../Assets/images/Sepa.png";
import PaymentMethodDetails from './Components/PaymentMethodDetails';
import PlusCounter from '../../../../../../../../../../../Assets/icons/plusCounter';
import Axios from '../../../../../../../../../../../Api';
import { useAuth } from '../../../../../../../../../../../Utils/Auth';
import { toastResponseError } from '../../../../../../../../../../../Utils/toastResponseError';
import Loader from '../../../../../../../../../../../Assets/components/Loader';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from '../../../../../../../../../Store/Components/StorePayment/CheckoutForm';
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const allCardData = [
    {
        img: Sepa,
        title: "SEPA Direct Debit",
        fees: "0% Fees",
        bg: "bg_soft_green",
        color: "color_green",
        buttonText: "Authorize Mandate",
        forwardBtn: false,
        description: "Select &apos;SEPA Direct Debit&apos; for an automatic payment withdrawl. Provide your IBAN number, name and email adress. Once the transaction has been completed, you&apos;ll gain instant access to today.green. This payment method does not include any additional charges."
    },
    {
        img: BankTransfer,
        title: "Bank Transfer (Invoice)",
        fees: "0% Fees",
        bg: "",
        color: "",
        buttonText: "",
        forwardBtn: false,
        description: "This payment method provides flexibility when you prefer to work with traditional invoices. Enter your billing information and confirm your selection by clicking &apos;Purchase & Download Invoice&apos; - the invoice will download automatically. Make sure to submit your payment within 14 days. Once the transaction has been completed, you can start using today.green."
    },
    {
        img: CreditCard,
        title: "Credit Card",
        fees: "+1.9%",
        bg: "bg_soft_orange",
        color: "color_orange",
        buttonText: "Save & Continue",
        forwardBtn: false,
        description: "Pay with ease using the credit card payment method. Enter your credit card information and get instant access to today.green. This payment method includes an additional charge of 1.9%."
    },
    {

        img: Sofort,
        title: "Sofort",
        fees: "+1.4%",
        bg: "bg_soft_orange",
        color: "color_orange",
        buttonText: "Continue to SOFORT",
        forwardBtn: true,
        description: "Choose &apos;Sofort&apos; for a quick and secure bank transfer. You&apos;ll be redirected to your bank&apos;s online portal to authenticate your payment. Please note, it may take 2 to 14 days for transaction confirmation. Once the transaction has been completed, you can start using today.green. This payment method includes and additional charge of 1.4%."

    },
    {

        img: BankContact,
        title: "Bankcontact",
        fees: "+1.4%",
        bg: "bg_soft_orange",
        color: "color_orange",
        buttonText: "Continue to Bankcontact",
        forwardBtn: true,
        description: "Select &apos;Bankcontact&apos; for a quick and secure bank transfer. You&apos;ll be redirected to Bancontact to authorize your payment. Once the transaction has been completed, you can start using today.green. This payment method includes and additional charge of 1.4%."

    },
    {

        img: IDEAL,
        title: "iDEAL",
        fees: "0% Fees",
        bg: "bg_soft_green",
        color: "color_green",
        buttonText: "Continue to iDEAL",
        forwardBtn: true,
        description: "iDeal allows for a quick and secure payment. Provide your bank name and account holder information to continue to iDeal. Confirm your payment and instantly gain access to today.green. No additional charges are included."

    },

]

function AddNewPaymentMethod(props) {
    const { setSessionExpire } = useAuth()
    const [expanded, setExpanded] = useState("panel1")
    const [mainExpanded, setMainExpanded] = useState(false)
    const [billingStep, setBillingStep] = useState(0)
    const [billingLoading, setBillingLoading] = useState(false)
    const [options, setOptions] = useState(null);

    const [selectedPaymentInfo, setSelectPaymentInfo] = useState({})
    const appearance = {
        theme: "stripe",
        rules: {
            ".Label": {
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "20px",
                textTransform: "uppercase",
                color: "#313B3E",
                marginBottom: "8px",
            },
        },
    };
    const handleExpandChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const handleBillingStepChange = (value) => {
        setBillingStep(value);
    }
    const handleSelectedPaymentInfo = (paymentDetails) => {
        setSelectPaymentInfo(paymentDetails)
    }

    const handleCardIntent = async (method) => {
        setBillingLoading(true);
        try {
            const response = await Axios({
                url: `/stripe/payment-intent`,
                method: "get",
                params: {
                    methodType: [method],
                },
            });
            setOptions({
                clientSecret: response.data.data.client_secret,
                appearance: appearance,
            });
            // setSelectBankTransfer(false);
            setBillingLoading(false);
            setBillingStep(1);
        } catch (error) {
            setBillingLoading(false);
            toastResponseError(error, setSessionExpire);
        }
    };

    return (
        <div className="paymentCardInfo_container">
            {billingLoading ? <Loader /> : null}
            <Accordion
                expanded={mainExpanded}
            >
                <AccordionSummary
                    expandIcon={""}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div
                        onClick={() => {
                            setMainExpanded(!mainExpanded)
                        }}
                        className="d-flex justify-content-between align-items-center w-100">
                        <div
                            // onClick={() => { setIsAccordionExpanded(!isAccordionExpanded) }}
                            className="d-flex align-items-center">

                            <p className="ml-20">
                                Add New Payment Method
                            </p>
                        </div>

                        <div className="d-flex align-items-center">
                            {!mainExpanded ?
                                <div
                                    onClick={() => {
                                        setMainExpanded(!mainExpanded)
                                    }}
                                >
                                    <PlusCounter />
                                </div>
                                :
                                <div className="d-flex justify-content-end align-items-center">
                                    <i
                                        onClick={() => {
                                            setMainExpanded(!mainExpanded)
                                        }}
                                        className="bi bi-x-lg"></i>
                                </div>
                            }

                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>
                    <div>
                        <>
                            {
                                billingStep == 0 ?
                                    allCardData.map((currentCard, cardIndex) => {
                                        return (
                                            <div key={cardIndex + currentCard.title}>
                                                <PaymentMethodDetails
                                                    cardDetails={currentCard}
                                                    cardIndex={cardIndex}
                                                    expanded={expanded}
                                                    handleExpandChange={handleExpandChange}
                                                    handleSelectedPaymentInfo={handleSelectedPaymentInfo}
                                                    handleBillingStepChange={handleBillingStepChange}
                                                    handleCardIntent={handleCardIntent} />
                                            </div>
                                        )
                                    })
                                    :
                                    billingStep == 1 ? (
                                        options && (
                                            <Elements stripe={stripePromise} options={options}>
                                                <CheckoutForm
                                                    selectedPaymentInfo={selectedPaymentInfo}
                                                    setValue={handleBillingStepChange}
                                                    setBillingStep={setBillingStep}
                                                    getPaymentMethods={props.getPaymentMethods}
                                                />
                                            </Elements>
                                        )
                                    ) : ""
                            }
                        </>
                    </div>
                </AccordionDetails>
            </Accordion>


        </div>
    )
}

export default AddNewPaymentMethod