import React, { useEffect, useState } from 'react'
import bulletImage from '../../../../Assets/images/bullets.png'
import ButtonComp from '../../../../Assets/components/Button'
import { toast } from 'react-hot-toast'
import CopyToClipboard from 'react-copy-to-clipboard'
import axios from '../../../../Api/index'
import { useNavigate } from 'react-router'
import { toastResponseError } from '../../../../Utils/toastResponseError'
function AllTutorials() {
    const navigate = useNavigate();
    const [tutorials, setTutorials] = useState([])

    const getAllTutorials = async () => {
        try {
            const response = await axios.get(`/admin/academy/tutorials`);
            if (response.data.success) {
                setTutorials(response.data.data)
            }
        } catch (error) {
            if (error && error.response.status === 401) {
                navigate("/admin/login");
            }
            return toastResponseError(error);
        }
    };
    useEffect(() => {
        getAllTutorials()
    }, [])
    return (
        <div className="row mx-0">

            {tutorials.map((v, i) => (
                <div className="col-3" key={i}>
                    <div className="container_design_1 p-3">
                        <div className="d-flex justify-content-end mb-20">
                            <CopyToClipboard
                                text={`http://localhost:3000/dashboard?tab=7&acadTab=0&id=${v._id}&step=2`}
                                onCopy={() => toast.success("link copied!")}
                            >
                                <div className="copy-area">
                                    <ButtonComp status={3}>
                                        Copy link
                                    </ButtonComp>
                                </div>
                            </CopyToClipboard>
                        </div>
                        {v.covertype === "video" || v.covertype === "image" ? (
                            <img
                                className=""
                                src={v.cover.image}
                                width="100%"
                                height="auto"
                            />
                        ) : (
                            <img src={bulletImage} width="100%" height="auto" />
                        )}
                        <p className="mt-8">
                            {" "}
                            <img src={v.icon} height="24px" width="24px" /> &nbsp;{v.title}
                        </p>
                        <p>{v.description}</p>
                        {!v.isLive && <p style={{ color: "red" }}> Draft </p>}
                        <br />
                        <div className="d-flex justify-content-between align-item-center">
                            {/* <div className="">
              <ButtonComp
                status={1}
                onClick={() =>
                  navigate(
                    `/admin/dashboard?tab=5&activetab=2&tutorialid=${v._id}`
                  )
                }
              >
                Update
              </ButtonComp>
              &nbsp;
              <ButtonComp status={2} onClick={() => handleDeleteTopic(v._id)}>
                Delete
              </ButtonComp>
              </div> */}
                            {/* <CopyToClipboard
                text={`http://localhost:3000/dashboard?tab=7&acadTab=0&id=${v._id}&step=2`}
              onCopy={() => toast.success("link copied!")}
              >
                <div className="copy-area">
                  <ButtonComp status={3}>
                    Copy link
                  </ButtonComp>
                </div>
              </CopyToClipboard> */}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}

export default AllTutorials