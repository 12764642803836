import { Close } from "@mui/icons-material";
import { Drawer, InputAdornment, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import SeachBigCircleIcon from "../../../../../../../../Assets/icons/SeachBigCircleIcon";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import LeftChevronIcon from "../../../../../../../../Assets/icons/LeftChevronIcon";
import RightChevronIcon from "../../../../../../../../Assets/icons/RightChevronIcon";
import { localNumber } from "../../../../../../../../Utils/CommonFunctions";
import SubScopeTarget from "./SubScopeTarget";
import CreateTask from "../../../CreateTask";
import Axios from "../../../../../../../../Api";
import useQuery from "../../../../../../../../Utils/query";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../Utils/Auth";
import toast from "react-hot-toast";
import { getServiceIcon } from "../../../../../../../../Utils/itemIcon";

const SubScopeDrawer = ({
  open,
  handleClose,
  scopeTargetData,
  targetData,
  selectedScope,
  getTarget,
  getTasks,
  EditTargetData,
  setEditTargetData,
  // scopeTargets,
  // setScopeTargets,
}) => {
  const { setSessionExpire } = useAuth();
  const [step, setStep] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [selectSubClass, setSelectSubClass] = useState("");
  const [selectSubClassEmisson, setSelectSubClassEmisson] = useState("");
  const [currentEmisson, setSelectSubClassCurrentEmisson] = useState("");
  const [selectSubClassHead, setSelectSubClassHead] = useState("");
  const [reductionValue, setReductionValue] = useState("");
  const [reductionPercentage, setReductionPercentage] = useState("");
  const query = useQuery();
  const scopeNo = query.get("scope");

  const itemId = query.get("accItem");

  const handleIncreaseStep = () => {
    if (step === 0 && selectSubClass) {
      setStep(1);
    }
    if (step === 1 && reductionValue && reductionPercentage) {
      setStep(2);
    }
  };

  const handleDecreaseStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const createScopeTarget = async () => {
    await Axios({
      url: "/target/create-scope-target",
      method: "put",
      data: {
        scopeNo: scopeNo,
        subscope: selectedScope,
        subclassHead: selectSubClassHead,
        subClass: selectSubClass,
        subClassEmission: Number(selectSubClassEmisson),
        currentEmission: Number(currentEmisson),
        subClassEmissionReduced: reductionValue,
        subClassEmissionReducedPercetage: reductionPercentage,
        logid: itemId,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          await getTarget();
          toast.success(res.data.message);
          setReductionValue("");
          setReductionPercentage("");
          handleClose();
          setStep(0);
          // let newScopeTarget = { ...scopeTargets };
          // if (!newScopeTarget) {
          //   newScopeTarget = {
          //     [selectedScope]: {
          //       [selectSubClassHead]: [],
          //     },
          //   };
          // } else if (!newScopeTarget[selectedScope]) {
          //   newScopeTarget[selectedScope] = {
          //     [selectSubClassHead]: [],
          //   };
          // } else if (!newScopeTarget[selectedScope][selectSubClassHead]) {
          //   newScopeTarget[selectedScope][selectSubClassHead] = [];
          // } else if (newScopeTarget[selectedScope][selectSubClassHead]) {
          //   let headArrIndex = newScopeTarget[selectedScope][
          //     selectSubClassHead
          //   ].findIndex((data) => data.subClass === selectSubClass);
          //   if (headArrIndex !== -1) {
          //     newScopeTarget[selectedScope][selectSubClassHead][headArrIndex] =
          //       {
          //         subscope: selectedScope,
          //         subclassHead: selectSubClassHead,
          //         subClass: selectSubClass,
          //         subClassEmission: selectSubClassEmisson,
          //         subClassEmissionReduced: reductionValue,
          //         subClassEmissionReducedPercetage: reductionPercentage,
          //       };
          //     setScopeTargets(newScopeTarget);
          //     return;
          //   }
          // }

          // newScopeTarget[selectedScope][selectSubClassHead].push({
          //   subscope: selectedScope,
          //   subclassHead: selectSubClassHead,
          //   subClass: selectSubClass,
          //   subClassEmission: selectSubClassEmisson,
          //   subClassEmissionReduced: reductionValue,
          //   subClassEmissionReducedPercetage: reductionPercentage,
          // });
          // setScopeTargets(newScopeTarget);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (EditTargetData.isEdit) {
      setReductionValue(EditTargetData.reductionValue);
      setSelectSubClass(EditTargetData.subClass);
      setSelectSubClassHead(EditTargetData.subClassHead);
      setSelectSubClassEmisson(EditTargetData.subClassEmision);
      setReductionPercentage(
        (EditTargetData.reductionValue * 100) / EditTargetData.subClassEmision
      );
      if (EditTargetData.isCreateTask) {
        setStep(2);
      }
    }
  }, [EditTargetData]);

  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => {
        if (step === 0) {
          setEditTargetData({
            isEdit: false,
            subClassHead: "",
            subClass: "",
            subscope: "",
            reductionValue: 0,
            subClassEmision: "",
            isCreateTask: false,
          });
          handleClose();
        }
      }}
      PaperProps={{
        sx: {
          overflow: "hidden",
          padding: 0,
          width: 532,
          maxWidth: "90%",
        },
      }}
      // sx={{ zIndex: 10000000000 }}
    >
      <div className="tasks_drawer_root">
        <div className="tasks_drawer_header" style={{ border: "none" }}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center gap_20">
              <p className="font_24_500 line_height_10">
                {step === 0
                  ? "Select an Emission Source"
                  : step === 1
                  ? "Enter your Subclass Target"
                  : "Connect a Task (Optional)"}
              </p>
              <div className="center border_radius_5 bg_white pointer h-40px w-40px ml-20 drop_shadow">
                <div className="circle_20 center bg_black">
                  <p className="font_14_600 text-white line_height_normal">?</p>
                </div>
              </div>
            </div>
            <Close
              htmlColor="#4C4C4C"
              className="pointer"
              onClick={() => {
                setReductionValue("");
                setReductionPercentage("");
                handleClose();
                setStep(0);
              }}
            />
          </div>
          <div className="mt-10 align_center flex-wrap gap_6">
            <div className="bg_scope2 border_radius_5 pxy-5 width_fit">
              <p className="font_12_500 text-capitalize text-white">
                {selectedScope}
              </p>
            </div>
            {selectSubClass && (
              <div className="bg_scope2 border_radius_5 pxy-5 width_fit">
                <p className="font_12_500 text-capitalize text-white">
                  {selectSubClass}
                </p>
              </div>
            )}
            {reductionValue ? (
              <div className="bg_scope2 border_radius_5 pxy-5 width_fit">
                <p className="font_12_500  text-white">-{reductionValue} t</p>
              </div>
            ) : (
              ""
            )}
          </div>
          {step === 0 && (
            <TextField
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              className="font_12_500"
              id="input-with-icon-textfield"
              placeholder={"Search..."}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SeachBigCircleIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #EFEFEF",
                marginTop: "30px",
                filter: "drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.03))",
                "& .MuiInputBase-root": {
                  height: 40,
                  width: "100%",
                  border: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiInputBase-input": {
                  fontFamily: "Inter",
                  fontSize: " 12px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "10px",
                  color: "7E8999",
                  paddingLeft: "4px",
                },
              }}
            />
          )}
        </div>
        {step === 0 ? (
          <div className="quickLog-main" style={{ paddingTop: 10 }}>
            {scopeTargetData &&
              Object.keys(scopeTargetData).length > 0 &&
              Object.keys(scopeTargetData).map((headClass, i) => (
                <div
                  className="d-flex flex-column gap_10 mb-10"
                  key={headClass + i}
                >
                  <div className="align_center gap_22">
                    <div className="" style={{ width: 307 }}>
                      <p className="font_14_600 color_7e">{headClass}</p>
                    </div>
                    <div className="">
                      <p className="font_14_600 color_7e">
                        Baseline{" "}
                        <span className="color_green">
                          {targetData?.baseYear}
                        </span>
                      </p>
                    </div>
                  </div>
                  {scopeTargetData[headClass].length > 0 &&
                    scopeTargetData[headClass].map((subClassInfo, i) => (
                      <div className="align_center gap_22" key={i}>
                        <div
                          className="subclass_target_left_box"
                          onClick={() => {
                            if (
                              !EditTargetData.isEdit &&
                              Object.values(subClassInfo)[0]
                                .baseYearEmission !== 0
                            ) {
                              setSelectSubClass(Object.keys(subClassInfo)[0]);
                              setSelectSubClassEmisson(
                                Object.values(subClassInfo)[0].baseYearEmission
                              );
                              setSelectSubClassCurrentEmisson(
                                Object.values(subClassInfo)[0]
                                  .currentYearEmission
                              );
                              setSelectSubClassHead(headClass);
                            }
                          }}
                        >
                          <div className="align_center gap_10">
                            <img
                              src={getServiceIcon(Object.keys(subClassInfo)[0])}
                              alt={Object.keys(subClassInfo)[0]}
                              className="h-20px w-20px border_radius_5"
                            />
                            <p className="font_14_600 color_7e">
                              {Object.keys(subClassInfo)[0]}
                            </p>
                          </div>
                          {selectSubClass === Object.keys(subClassInfo)[0] &&
                          selectSubClassHead === headClass ? (
                            <div className={"circle_24 border_1_ef bg_green"} />
                          ) : (
                            <div className={"circle_24 border_1_ef "} />
                          )}
                        </div>
                        <div className="">
                          <p className="font_20_700">
                            {localNumber(
                              Object.values(subClassInfo)[0].baseYearEmission
                            )}
                            {"   "}
                            <span className="font_10_600 color_31">tCO2e</span>
                          </p>
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ) : step === 1 ? (
          <SubScopeTarget
            selectSubClassEmisson={selectSubClassEmisson}
            reductionValue={reductionValue}
            setReductionValue={setReductionValue}
            reductionPercentage={reductionPercentage}
            setReductionPercentage={setReductionPercentage}
            selectedScope={selectedScope}
          />
        ) : step === 2 ? (
          <CreateTask
            setStep={setStep}
            isFromSubScopeTarget={true}
            isOnlyTaskCreation={EditTargetData.isCreateTask}
            targetData={targetData}
            selectedScopeFromScopeTarget={selectedScope}
            selectedSubClassFromScopeTarget={selectSubClass}
            selectedSubClassHeadFromScopeTarget={selectSubClassHead}
            createScopeTarget={createScopeTarget}
            getTasks={getTasks}
            setEditTargetData={setEditTargetData}
          />
        ) : null}
        {step < 2 && (
          <div className="quickLog-footer">
            {step > 0 && (
              <ButtonComp
                onClick={() => {
                  handleDecreaseStep();
                }}
                status={6}
                size="sm"
                style={{ height: 40 }}
                className="bg_d9"
              >
                <LeftChevronIcon className={"mr-10"} /> Go Back
              </ButtonComp>
            )}
            <ButtonComp
              onClick={() => {
                if (EditTargetData.isEdit && step === 1) {
                  createScopeTarget();
                } else {
                  handleIncreaseStep();
                }
                // handleMatrixCellSubmit();
              }}
              status={5}
              size="sm"
              style={{ height: 40 }}
            >
              {EditTargetData.isEdit && step === 1
                ? "Edit Target"
                : "Next Step "}
              <RightChevronIcon className={"ml-10"} />
            </ButtonComp>
          </div>
        )}
      </div>
    </Drawer>
  );
};

export default SubScopeDrawer;
