import React from "react";

export default function TabLabels(props) {

  return (
    <div className="d-flex align-items-center">
      <p className={`${props.classes}`}>{props.title}</p>
      {props.count
        ? props.count !== 0 && (
            <span className="tab_label_span ml-6">
              {props.count === 0 ? null : props.count}
            </span>
          )
        : null}

    </div>
  );
}
