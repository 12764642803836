import React, { useEffect, useState } from "react";
import { FullMonthNames } from "../../../../../../Utils/CommonFunctions";
import { CalendarTodayOutlined } from "@mui/icons-material";
import LeftChevronIcon from "../../../../../../Assets/icons/LeftChevronIcon";
import RightChevronIcon from "../../../../../../Assets/icons/RightChevronIcon";
import { IconButton } from "@mui/material";
import Axios from "../../../../../../Api";
import toast from "react-hot-toast";

const EmissionPeriod = ({
  periodType,
  setPeriodType,
  yearList,
  selectedMonth,
  setSelectedMonth,
  selectedYear,
  setSelectedYear,
  logId,
  isQuickLog
}) => {
  // const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [yearIndex, setYearIndex] = useState(0);
  const [planMonths, setPlanMonths] = useState(
    Array.from({ length: 12 }, () => false)
  );
  // const [yearIndex, setYearIndex] = useState(0);
  // const yearList2 = Array.from(
  //   { length: 2045 - 1999 + 1 },
  //   (_, index) => 1999 + index + ""
  // );
  // console.log(yearList2)

  useEffect(() => {
    const year = new Date().getFullYear();
    if (yearList.includes(year.toString())) {
      // setCurrentYear(year);
      const index = yearList.indexOf(year.toString());
      if (index !== -1) {
        setSelectedYear(yearList[index]);
        if (periodType === "Month") {
          setYearIndex(index);
        } else {
          setYearIndex(Math.floor(index / 12));
        }
      }
    } else {
      // setCurrentYear(yearList[0]);
      setSelectedYear(yearList[0]);
      setYearIndex(0);
    }
  }, [periodType]);

  const handleIncraeseIndex = () => {
    if (periodType === "Month") {
      if (yearIndex + 1 < yearList.length) {
        setSelectedYear(yearList[yearIndex + 1]);
        setYearIndex(yearIndex + 1);
      }
    } else {
      if (yearIndex < Math.ceil(yearList.length / 12) - 1) {
        setYearIndex(yearIndex + 1);
      }
    }
  };

  const handleDecraeseIndex = () => {
    if (periodType === "Month") {
      if (yearIndex > 0) {
        setSelectedYear(yearList[yearIndex - 1]);
        setYearIndex(yearIndex - 1);
      }
    } else {
      if (yearIndex > 0) {
        setYearIndex(yearIndex - 1);
      }
    }
  };

  const getActiveMonts = async (year) => {
    await Axios.get(`/log/matrix-columns?logId=${logId}&year=${year}`, {
      logId: logId,
    })
      .then((res) => {
        let currMonths = [...planMonths];
        res.data.latestYear[year].forEach((data) => {
          if (data.month) {
            currMonths[data.month - 1] = true;
          }
        });
        if (isQuickLog) {
          const firstTrueMonthIndex = currMonths.findIndex(
            (data) => data === true
          );
          setSelectedMonth(firstTrueMonthIndex);
        }
        setPlanMonths(currMonths);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  useEffect(() => {
    if (logId) {
      getActiveMonts(selectedYear);
    }
  }, [selectedYear]);

  return (
    <div className="mt-30">
      <p className="font_12_600 color_7e line_height_10">
        Please specify the timeframe for your emission log
      </p>
      <div className="mt-20 d-flex align-items-center gap_10">
        <div
          className={`emission_period_tab ${
            periodType === "Month" && "activeTab"
          }`}
          onClick={() => setPeriodType("Month")}
        >
          <p className="font_14_600 text-capitalize">Month</p>
        </div>
        <div
          className={`emission_period_tab ${
            periodType === "Year" && "activeTab"
          }`}
          onClick={() => setPeriodType("Year")}
        >
          <p className="font_14_600 text-capitalize">Year</p>
        </div>
      </div>
      <div className="emissionPeriod_calender_box mt-20">
        <div className="emissionPeriod_calender_header">
          <CalendarTodayOutlined htmlColor="#7B7B7B" />
          <div
            className="flex-1 d-flex align-items-center justify-content-center"
            style={{ gap: 12.5 }}
          >
            <IconButton
              sx={{
                height: 24,
                width: 24,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              disabled={yearIndex === 0}
              onClick={handleDecraeseIndex}
            >
              <LeftChevronIcon cc={yearIndex === 0 ? "#cacaca" : "#000"} />
            </IconButton>
            {periodType === "Month" ? (
              <p className="font_12_500 text-black">{yearList[yearIndex]}</p>
            ) : (
              <p className="font_12_500 text-black">
                {yearList[yearIndex * 12]} -{" "}
                {
                  yearList[
                    (yearIndex + 1) * 12 - 1 > yearList.length - 1
                      ? yearList.length - 1
                      : (yearIndex + 1) * 12 - 1
                  ]
                }
              </p>
            )}
            <IconButton
              sx={{
                height: 24,
                width: 24,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleIncraeseIndex}
              disabled={
                periodType === "Month"
                  ? yearIndex === yearList.length - 1
                    ? true
                    : false
                  : yearIndex >= Math.ceil(yearList.length / 12) - 1
                  ? true
                  : false
              }
            >
              <RightChevronIcon
                cc={
                  periodType === "Month"
                    ? yearIndex === yearList.length - 1
                      ? "#cacaca"
                      : "#000"
                    : yearIndex >= Math.ceil(yearList.length / 12) - 1
                    ? "#cacaca"
                    : "#000"
                }
              />
            </IconButton>
          </div>
        </div>
        <div className="emissionPeriod_calender_main">
          {periodType === "Month"
            ? FullMonthNames.map((month, i) => (
                <div
                  className={
                    "emissionPeriod_calender_tile " +
                    (selectedMonth === i && "activeTile") +
                    " " +
                    (!planMonths[i] && "notAllowedTile")
                  }
                  key={month}
                  onClick={() => {
                    if (planMonths[i]) {
                      setSelectedMonth(i);
                      setSelectedYear(yearList[yearIndex]);
                    }
                  }}
                >
                  <p className="font_14_600">{FullMonthNames[i]}</p>
                </div>
              ))
            : yearList
                .slice(yearIndex * 12, (yearIndex + 1) * 12)
                .map((year) => (
                  <div
                    className={
                      "emissionPeriod_calender_tile " +
                      (selectedYear === year && "activeTile")
                    }
                    key={year}
                    onClick={() => setSelectedYear(year)}
                  >
                    <p className="font_14_600">{year}</p>
                  </div>
                ))}
        </div>
      </div>
    </div>
  );
};

export default EmissionPeriod;
