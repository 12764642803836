import React, { useState } from "react";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../Utils/TableComp";
import { MoreVert } from "@mui/icons-material";
import { getDateString } from "../../../../../../Utils/CommonFunctions";
import { IconButton, Popover } from "@mui/material";
import DeleteModal from "../../../../../../Assets/components/DeleteModal/DeleteModal";
import Axios from "../../../../../../Api";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../Utils/Auth";

const EsgRow = ({ data, getEsgReports }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = () => setdltOpen(true);
  const handledltClose = () => setdltOpen(false);
  const { setSessionExpire } = useAuth();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlepopClose = () => {
    setAnchorEl(null);
  };

  const popopen = Boolean(anchorEl);
  const id = popopen ? "simple-popover" : undefined;

  const handleDeleteReport = async () => {
    await Axios({
      url: `/share/esg/${data._id}`,
      method: "DELETE",
    })
      .then((res) => {
        if (res.data.success) {
          getEsgReports();
          toast.success("Report deleted successfully");
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  return (
    <>
      <StyledTableRow
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}
        key={data._id}
      >
        <StyledTableCell
          sx={{ borderLeft: "0", textAlign: "left" }}
          component="th"
          align="left"
        >
          {data.name} ({data.userId.firstname} {data.userId.lastname})
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "left" }} className="text-capitalize">
          {data.framework}
        </StyledTableCell>
        <StyledTableCell sx={{ textAlign: "left" }}>
          {data.timeFrame}
        </StyledTableCell>
        {/* <StyledTableCell align="left">{data.timeFrame}</StyledTableCell> */}
        <StyledTableCell>
          <div className="d-flex align-items-center justify-content-between">
            <p className="body_text_m">{getDateString(data.createdAt)}</p>
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </div>
        </StyledTableCell>
      </StyledTableRow>

      <Popover
        id={id}
        open={popopen}
        anchorEl={anchorEl}
        onClose={handlepopClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="basic-popover-root">
          <div
            className="basic-popover"
            // onClick={() => {
            //   handleEditOpen();
            //   handleClose();
            // }}
          >
            <p className="font_14_500">
              <a
                href={data.link}
                target="_blank"
                rel="noopener noreferrer"
                className="text-black"
                download={data.name}
              >
                download
              </a>
            </p>
          </div>
          <div className="basic-popover">
            <p
              className="font_14_500 color_red"
              onClick={() => {
                handledltOpen();
                handlepopClose();
              }}
            >
              delete
            </p>
          </div>
        </div>
      </Popover>

      <DeleteModal
        open={dltOpen}
        handleClose={handledltClose}
        confirmFunction={() => handleDeleteReport()}
        subtitle={`Once you delete a ESG report, you can&apos;t recover it.<br /> Please be sure before deleting it.`}
      />
    </>
  );
};

export default EsgRow;
