import React from "react";

function PlusCounter() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="52"
      height="52"
      fill="none"
      viewBox="0 0 52 52"
    >
      <g filter="url(#filter0_d_7907_32046)">
        <g filter="url(#filter1_d_7907_32046)">
          <rect
            width="20"
            height="20"
            x="36"
            y="36"
            fill="#00915B"
            rx="5"
            transform="rotate(-180 36 36)"
          ></rect>
          <rect
            width="19.5"
            height="19.5"
            x="35.75"
            y="35.75"
            stroke="#fff"
            strokeWidth="0.5"
            rx="4.75"
            transform="rotate(-180 35.75 35.75)"
          ></rect>
        </g>
        <g
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M21 26h10"></path>
          <path d="M26 21v10"></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_7907_32046"
          width="52"
          height="52"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7907_32046"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_7907_32046"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d_7907_32046"
          width="52"
          height="52"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset></feOffset>
          <feGaussianBlur stdDeviation="8"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow_7907_32046"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow_7907_32046"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default PlusCounter;
