import React, { useEffect } from "react";
import "./scopeData.css";
import Drawer from "@mui/material/Drawer";
import ScopeCard from "../ScopeCard";
import axios from "../../../../../../../../Api/index";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../Utils/Auth";
import subScopeCategory from "../../../../../../../../DataJSON/subScopes.json";
import location from "../../../../../../../../DataJSON/companyLocation.json";
import event from "../../../../../../../../DataJSON/event.json";
import hotel from "../../../../../../../../DataJSON/hotel.json";
import product from "../../../../../../../../DataJSON/product.json";
import service from "../../../../../../../../DataJSON/service.json";
import realEstateA from "../../../../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../../../../DataJSON/building.json";
import agency from "../../../../../../../../DataJSON/agency.json";
import education from "../../../../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../../../../DataJSON/shipping.json";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import skilledTrades from "../../../../../../../../DataJSON/skilledTradesSector.json";

function ScopeData(props) {
  // const [emissionCardData, setEmissionCardData] = useState([]);
  // const [logMonthCount, setLogMonthCount] = useState(0);
  const { setSessionExpire } = useAuth();
  // const [currSubScopeTotalEmission, setCurrSubScopeTotalEmission] = useState(0);
  const data = {
    location,
    event,
    hotel,
    product,
    service,
    "real-estate agency": realEstateA,
    "real estate development": realEstateD,
    building,
    agency,
    "educational institution": education,
    shipping,
    "skilled trades company": skilledTrades,
  };

  const getMatrixCellData = async () => {
    if (props.logId.length === 0 || props.year.length === 0) return;
    await axios
      .get(`/cell/matrix-cell?logId=${props.logId}&year=${props.year}`)
      .then((res) => {
        let currCellData = [];
        // let currLogMonthCount = 0;
        // let currTotalEmission = 0;
        res.data.cellData.forEach((cell) => {
          if (cell.scopeIndex === props.ScopeInfo.scopeIndex) {
            // currLogMonthCount++;
            // currTotalEmission += Number(cell.emission);
            let currData = [
              cell.name,
              cell.calculationMethod,
              cell.activity,
              cell.type,
              cell.subType,
              cell.unit,
              cell.inputMethod,
            ];
            currCellData.push({
              currData,
              emission: cell.emission,
              updatedAt: cell.updatedAt,
              month: cell.month,
              year: cell.year,
              totalAmount: cell.totalAmount,
              scope: cell.subScope,
              class: cell.scopeClass,
              scopeClass: cell.subClassHead,
              subClass: cell.subClass,
            });
          }
        });
        // setCurrSubScopeTotalEmission(currTotalEmission);
        // setLogMonthCount(currLogMonthCount);
        // setEmissionCardData(currCellData);
      })
      .catch((error) => {
        toastResponseError(error, setSessionExpire);
      });
  };
  useEffect(() => {
    getMatrixCellData();
  }, [props.logId, props.year, props.ScopeInfo]);

  useEffect(() => {
    if (
      props.ScopeInfo &&
      props.ScopeInfo.scope &&
      data[props.itemName.toLowerCase()][props.itemName][props.ScopeInfo.class][
        props.ScopeInfo.scope
      ].isMandatory
    )
      props.setisMandatory(true);
    else {
      props.setisMandatory(false);
    }
  }, [props.ScopeInfo]);

  // useEffect(() => {
  //   if (props.selectedCell) console.log(props.selectedCell.particulars);
  // }, [props.selectedCell]);

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={props.toggleScopeDrawer}
        onClose={() => {
          props.handleScopeDrawer();
        }}
        PaperProps={{
          sx: { width: "70%" },
        }}
      >
        <div className="drawer_root">
          <div className="productDetail_drawerHead pb-40">
            <div className="align_text_right pr-20 mt-10">
              <i
                className="bi bi-x-lg SideDrawer_close_icon pointer"
                onClick={() => props.handleScopeDrawer()}
              ></i>
            </div>
            <div className="pl-40 ">
              <p className="faint_text_2">
                {props.ScopeInfo &&
                  props.ScopeInfo.scope &&
                  subScopeCategory[props.ScopeInfo.scope.toLowerCase()] &&
                  "Scope " +
                    subScopeCategory[props.ScopeInfo.scope.toLowerCase()]}
              </p>
              <div className="d-flex justify-items-center">
                <p className="f-27 fw-700 align-items-center">
                  {props.ScopeInfo.scope}
                </p>
                <div className="d-flex align-items-center ml-8">
                  {props.ScopeInfo &&
                  props.ScopeInfo.scope &&
                  data[props.itemName.toLowerCase()] &&
                  data[props.itemName.toLowerCase()][props.itemName] &&
                  data[props.itemName.toLowerCase()][props.itemName][
                    props.ScopeInfo.class
                  ][props.ScopeInfo.scope].isMandatory ? (
                    <span className="orange_label">Mandatory</span>
                  ) : (
                    <span className="green_label">Recommended</span>
                  )}
                </div>
              </div>
              <p className="subheading_light_2 w-60 mt-10">
                {props.ScopeInfo &&
                  props.ScopeInfo.scope &&
                  data[props.itemName.toLowerCase()] &&
                  data[props.itemName.toLowerCase()][props.itemName] && [
                    props.itemName,
                  ] &&
                  data[props.itemName.toLowerCase()][props.itemName][
                    props.ScopeInfo.class
                  ][props.ScopeInfo.scope].description}
              </p>
            </div>
          </div>
          <div className="p-40 pt-18">
            <div className="main_box">
              <div className="pb-16">
                <div className="scopeData_card_background">
                  <div className="row">
                    <div className="col-4">
                      <div className="scope_blue_left_bar scopeData_card_padding align_text_left">
                        <p>
                          <span
                            className="font_30_700 line_height_normal"
                            style={{ marginRight: 6 }}
                          >
                            {props.selectedCell && props.selectedCell.emission
                              ? props.selectedCell.emission
                                  .toFixed(2)
                                  .toLocaleString()
                              : 0}
                          </span>
                          <span className="body_text_sm line_height_10">
                            tCO2e
                          </span>
                        </p>
                        <p
                          className="font_12_400 line_height_10 text-white"
                          style={{ marginTop: 3.2 }}
                        >
                          Total Emission
                        </p>
                      </div>
                    </div>

                    <div className="col-3">
                      <div className="align_text_left scopeData_card_padding">
                        <p>
                          <span
                            className="font_30_700 line_height_normal"
                            style={{ marginRight: 6 }}
                          >
                            {props.selectedCell &&
                            props.selectedCell.particulars
                              ? props.selectedCell.particulars.length
                              : 0}
                          </span>
                        </p>
                        <p
                          className="font_12_400 line_height_10 text-white"
                          style={{ marginTop: 3.2 }}
                        >
                          No. of Log Items
                        </p>
                      </div>
                    </div>

                    <div className="col-5 d-flex flex-column">
                      <div className=" flex-grow-1 d-flex justify-content-end align-items-end">
                        <div className="d-flex align-items-end p-12">
                          <p
                            onClick={() => {
                              props.handleMissingDataDrawer({
                                subScope: props.ScopeInfo.scope,
                                class: props.ScopeInfo.class,
                                month: props.ScopeInfo.month,
                                year: props.ScopeInfo.year,
                                logId: props.ScopeInfo.logId,
                                itemName: props.itemName,
                              });
                            }}
                            className="link_text_white pointer"
                          >
                            Missing Data
                          </p>

                          <ButtonComp
                            status={3}
                            onClick={() => {
                              props.handleToggleSubClasses(
                                true,
                                props.cellData
                                  ? props.cellData.particulars.length
                                  : 0
                              );
                            }}
                            className="text-black ml-20"
                          >
                            <i className="bi bi-plus"></i> New log Items
                          </ButtonComp>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="pt-0">
                <div className="row mx-0">
                  {props.selectedCell && props.selectedCell.particulars
                    ? props.selectedCell.particulars.map(
                        (cardDetail, index) => {
                          return (
                            <div className="col-lg-6 ps-0 mt-30" key={index}>
                              <ScopeCard
                                cardDetail={cardDetail}
                                month={props.selectedCell.month}
                                date={props.selectedCell.updatedAt}
                                openUpdateMonthEmission={
                                  props.openUpdateMonthEmission
                                }
                              />
                            </div>
                          );
                        }
                      )
                    : ""}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default ScopeData;
