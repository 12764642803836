import { Tab, Tabs } from "@mui/material";
import React, {useState} from "react";
import TabLabels from "../../../../LogDashboard/Components/TabLabels";
import InviteUser from "./InviteUser";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AssigneeComp = ({
  selectedUsers,
  invitedUsers,
  handleRemoveUser,
  handleRemoveInvitedUser,
  allUsers,
  handleAssignUser,
  setInvitedUser,
}) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="task_assignee_root">
      <div className="task_assigned_to">
        <p className="font_14_600 line_height_10 color_7e">Assigned to</p>
        <div className="flex-column d-flex gap_10 mt-10">
          {selectedUsers.length > 0 &&
            selectedUsers.map((user) => (
              <div key={user._id} className="d-flex align_center">
                <div className="align_center gap_12 flex-1">
                  <img
                    src={user.avatar}
                    alt={user.firstname}
                    className="img_30"
                  />
                  <p className="font_13_500 line_height_14">
                    {user.firstname} {user.lastname}
                  </p>
                </div>
                <p
                  className="font_12_500 line_height_10 color_red pointer"
                  onClick={() => handleRemoveUser(user)}
                >
                  Remove
                </p>
              </div>
            ))}
          {invitedUsers.length > 0 &&
            invitedUsers.map((user, i) => (
              <div key={i} className="d-flex align_center">
                <div className="align_center gap_12 flex-1">
                  <div className="img_30 border_1_ef bg_light_pink_2 center">
                    <p className="font_13_500 ">
                      {user.firstName.charAt(0).toUpperCase()}
                    </p>
                  </div>
                  <p className="font_13_500 line_height_14">
                    {user.firstName} {user.lastName}
                  </p>
                </div>
                <p
                  className="font_12_500 line_height_10 color_red pointer"
                  onClick={() => handleRemoveInvitedUser(user)}
                >
                  Remove
                </p>
              </div>
            ))}
        </div>
      </div>
      <hr />
      <div className="task_assignees_users">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="general_root_tabs"
          sx={{
            minHeight: 32,
            "& .MuiTab-root": {
              minHeight: 12,
            },
            "& .MuiTabs-indicator": {
              top: 22,
            },
          }}
          // TabIndicatorProps={{  }}
        >
          <Tab
            label={
              <TabLabels
                classes={"font_14_600 line_height_10"}
                title={"My Team"}
              />
            }
            {...a11yProps(0)}
          />
          <Tab
            label={
              <TabLabels
                classes={"font_14_600 line_height_10"}
                title={"Invite"}
              />
            }
            {...a11yProps(1)}
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <div className="mt-20 d-flex flex-column gap_10 assignee_user_overflow">
            {allUsers &&
              allUsers.length > 0 &&
              allUsers.map((user) => (
                <div key={user._id} className="d-flex align_center">
                  <div className="align_center gap_12 flex-1">
                    <img
                      src={user.avatar}
                      alt={user.firstname}
                      className="img_30"
                    />
                    <p className="font_13_500 line_height_14">
                      {user.firstname} {user.lastname}
                    </p>
                  </div>
                  <p
                    className="font_12_500 line_height_10 color_green pointer"
                    onClick={() => handleAssignUser(user)}
                  >
                    Assign
                  </p>
                </div>
              ))}
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <InviteUser
            invitedUsers={invitedUsers}
            setInvitedUser={setInvitedUser}
          />
        </TabPanel>
      </div>
    </div>
  );
};

export default AssigneeComp;
