import { Drawer } from "@mui/material";
import React, { useEffect, useState } from "react";
import AllTasks from "./AllTasks";
import CreateTask from "./CreateTask";
import { useAuth } from "../../../../../Utils/Auth";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import Axios from "../../../../../Api";

const TasksDrawer = ({
  open,
  handleClose,
  targetData,
  allTasks,
  setAllTasks,
}) => {
  const { setSessionExpire } = useAuth();

  const [isCardView, setIsCardView] = useState(true);
  const [step, setStep] = useState(0);
  const [taskInfo, setTaskInfo] = useState(null);

  const getTasks = async () => {
    await Axios({
      url: `/target/get-task/${targetData._id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setAllTasks(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (targetData) {
      getTasks();
    }
  }, [targetData]);
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => {
        if (step === 0) {
          handleClose();
        }
      }}
      PaperProps={{
        sx: {
          overflow: "hidden",
          padding: 0,
          width: 1157,
          maxWidth: "90%",
        },
      }}
      // sx={{ zIndex: 10000000000 }}
    >
      <div className="tasks_drawer_root">
        {step === 0 ? (
          <AllTasks
            setStep={setStep}
            isCardView={isCardView}
            allTasks={allTasks}
            getTasks={getTasks}
            setAllTasks={setAllTasks}
            targetData={targetData}
            taskInfo={taskInfo}
            setTaskInfo={setTaskInfo}
            setIsCardView={setIsCardView}
            step={step}
            handleClose={handleClose}
          />
        ) : (
          <CreateTask
            setStep={setStep}
            targetData={targetData}
            getTasks={getTasks}
            isFromSubScopeTarget={false}
            taskInfo={taskInfo}
            setTaskInfo={setTaskInfo}
            step={step}
            handleClose={handleClose}
          />
        )}
      </div>
    </Drawer>
  );
};

export default TasksDrawer;
