import React from "react";

const UserAddIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="15"
      fill="none"
      viewBox="0 0 18 15"
    >
      <path
        stroke={cc || "#7E8999"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M11.91 14.002v-1.445a2.88 2.88 0 00-.852-2.043A2.92 2.92 0 009 9.668H3.91a2.92 2.92 0 00-2.057.846A2.879 2.879 0 001 12.557v1.445M14.818 4.617v4.334M17 6.781h-4.363M6.455 6.782a2.9 2.9 0 002.91-2.889 2.9 2.9 0 00-2.91-2.89 2.9 2.9 0 00-2.91 2.89 2.9 2.9 0 002.91 2.89z"
      ></path>
    </svg>
  );
};

export default UserAddIcon;
