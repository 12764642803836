import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import useQuery from "../../../../../../../Utils/query";
import axios from "../../../../../../../Api/index";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import BookYellow from "../../../../../../../Assets/icons/BookYellow.png";
import { useAuth } from "../../../../../../../Utils/Auth";
import { useTranslation } from "react-i18next";
function PlatformTourVid() {
  const {t} = useTranslation()
  const {user} = useAuth()
  const query = useQuery();
  const itemId = query.get("id");
  const tutItemId = query.get("tutItemId");
  const [tutorialDetails, setTutorialDetails] = useState({});
  // const [activeSection, setActiveSection] = useState(0);
  const getTutorialDetails = async () => {
    try {
      await axios.get(`/academy/tutorial/${itemId}`).then((response) => {
        if (response.data.success) {
          setTutorialDetails(response.data.tutorials);
          console.log(response.data.tutorials);
        }
      });
    } catch (error) {
      toastResponseError(error);
    }
  };

  useEffect(() => {
    getTutorialDetails();
  }, [itemId]);

  function extractTitleFromHTML(htmlString) {
    const parser = new DOMParser();
    const parsedHTML = parser.parseFromString(htmlString, "text/html");
    const titleText = parsedHTML.body.textContent;
    return titleText;
  }

  function scrollToContent(id) {
    const element = document.getElementById(`content-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  return (
    <div className="row ml-116 mr-80">
      <div className="col-lg-11 col-md-11 col-12 mb-40">
        <div className="align_text_left mt-50">
          <p className="breadcrumb_text_light_1 text_capital">
            <Link to={`/dashboard?tab=7`}>
              <span className="color_light_2">Tutorials</span>
            </Link>{" "}
            {tutItemId && (
              <Link to={`/dashboard?tab=7&acadTab=0&id=${tutItemId}&step=1`}>
                <span className="color_light_2">
                  {" "}
                  / {user.language === 'de' && tutorialDetails?.title_de ?  extractTitleFromHTML(tutorialDetails?.title_de) :extractTitleFromHTML(tutorialDetails?.title)}
                </span>
              </Link>
            )}
            <span className="text_capital fw-700"> / Platform Tour</span>
          </p>
        </div>

        <div className="border-ef border_radius_10 p-30 mt-30">
          <div className="d-flex align-items-center">
            <div className="tutorials_container" style={{ padding: 12 }}>
              <img
                src={tutorialDetails?.icon}
                style={{ width: 26, height: 26 }}
              />
            </div>
            {tutorialDetails.title && (
              <p className="font_40_700 f-35 ml-16">
                {user.language === 'de' && tutorialDetails?.title_de ?  parse(tutorialDetails?.title_de) :parse(tutorialDetails?.title)}
              </p>
            )}
          </div>
          {tutorialDetails.description && (
            <p className="mt-16 font_18_500 color_57">
              {user.language === 'de' && tutorialDetails?.description_de ?  parse(tutorialDetails?.description_de) :parse(tutorialDetails?.description)}
            </p>
          )}
          {tutorialDetails.keywords && tutorialDetails.keywords.length > 0 && (
            <div className="mt-20 d-flex align-items-center flex-wrap gap-3 mt-64">
              {tutorialDetails.keywords.map((keyword) => (
                <div
                  key={keyword._id}
                  style={{
                    backgroundColor: keyword.bg_color,
                    padding: "3px 8px",
                    borderRadius: 5,
                  }}
                >
                  <p
                    className="font_12_700"
                    style={{
                      color: keyword.text_color,
                      paddingTop: "2px",
                    }}
                  >
                    {user.language === 'de' && keyword?.tag_title_de ?  parse(keyword?.tag_title_de) :parse(keyword?.tag_title)}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>

        {tutorialDetails?.covertype == "video" ? (
          <div
            className="border-ef border_radius_10 pl-24 pr-24 pb-24 mt-45"
            style={{ backgroundColor: "#F9FDFF" }}
          >
            <div className="mt-22">
              <video width={"100%"} controls>
                <source src={tutorialDetails?.cover.video} type="video/mp4" />
              </video>
            </div>
          </div>
        ) : tutorialDetails?.covertype == "list" ? (
          <div
            className="border-ef border_radius_10 pl-22 pr-86 pb-32 mt-45"
            style={{ backgroundColor: "#F9FDFF" }}
          >
            <p className="font_15_400 mt-20">
              {user.language === 'de' && tutorialDetails?.cover.listheading_de ? tutorialDetails?.cover.listheading_de :tutorialDetails?.cover.listheading}
            </p>
            <div className="pl-32">
            {user.language === 'de' && tutorialDetails?.cover.listbullets_de.length > 0 ? <>
            {tutorialDetails.cover?.listbullets_de.map((obj, i) => (
                <div className="d-flex mt-30 align-items-center" key={i}>
                  <div className="circle_border bg-color-f5 bullet_div">
                    <span className="color_white p-2x-8y">{i + 1}</span>
                  </div>
                  <p className="body_text ml-20">{parse(obj)}</p>
                </div>
              ))}
            
            </>:<>
            {tutorialDetails.cover?.listbullets.map((obj, i) => (
                <div className="d-flex mt-30 align-items-center" key={i}>
                  <div className="circle_border bg-color-f5 bullet_div">
                    <span className="color_white p-2x-8y">{i + 1}</span>
                  </div>
                  <p className="body_text ml-20">{parse(obj)}</p>
                </div>
              ))}
            </> }
              
            </div>
          </div>
        ) : tutorialDetails?.cover?.image ? (
          <div
            className="border-ef border_radius_10 pl-24 pr-24 pb-24 mt-45"
            style={{ backgroundColor: "#F9FDFF" }}
          >
            <div className="mt-22">
              <img
                src={tutorialDetails?.cover?.image}
                className="w-100 h-100"
              />
            </div>
          </div>
        ) : null}

        <div className="border-ef border_radius_10 p-30 mt-30">
          {tutorialDetails.SummarydescriptionHeadig && (
            <div className="black_box_small width_fit">
              <p className="font_16_700 text_capital">
                {user.language === 'de' && tutorialDetails?.SummarydescriptionHeadig_de ? tutorialDetails?.SummarydescriptionHeadig_de :tutorialDetails?.SummarydescriptionHeadig}
              </p>
            </div>
          )}
          {tutorialDetails.Summarydescription && (
            <div className="mt-16">
              {user.language === 'de' && tutorialDetails?.Summarydescription_de ? parse(tutorialDetails?.Summarydescription_de) : parse(tutorialDetails?.Summarydescription)}
            </div>
          )}
        </div>

        <div className="mt-30 d-flex">
          <div className="knowledge_post_aboout col-2 pl-0 pr-24">
            {tutorialDetails.author && (
              <div className="knowledge_post_aboout_author">
                <img
                  src={tutorialDetails.author.image}
                  alt={tutorialDetails.author.name}
                  className="img_40"
                />
                <p className="mt-10 font_12_700 color_57 ">{t("academy.author")}</p>
                <p className="mt-6 font_15_500 mb-10">
                  {tutorialDetails.author.name}
                </p>
                <p className="mt-6 overline_text color_57">
                {user.language === 'de' && tutorialDetails?.author.designation_de ? tutorialDetails.author.designation_de : tutorialDetails.author.designation}
                </p>
                <p className="mt-10 font_12_700 color_57 mt-3">{t("academy.published")} </p>
                <p className="mt-6 font_15_500 fw-600">
                  {new Date(tutorialDetails.createdAt).toLocaleDateString(
                    "en-GB",
                    {
                      dateStyle: "medium",
                    }
                  )}
                </p>
              </div>
            )}
            <div className="m-2"></div>

            {tutorialDetails.content && tutorialDetails.content.length > 0 && (
              <div className="knowledge_post_aboout_content">
                <div className="knowledge_post_aboout_content_footer">
                  <img src={BookYellow} alt="Contents" />
                  <p className="font_16_700 text-uppercase">{t("academy.content")} </p>
                </div>
                <div className="knowledge_post_aboout_content_body">
                  {tutorialDetails.content.map((article) => (
                    <p
                      className="font_13_400 color_57 pointer"
                      key={article._id}
                      onClick={() => scrollToContent(article._id)}
                    >
                      {user.language === 'de' && article?.heading_de ? extractTitleFromHTML(article?.heading_de) : extractTitleFromHTML(article?.heading)}
                    </p>
                  ))}
                </div>
              </div>
            )}
          </div>
          {tutorialDetails.content && tutorialDetails.content.length > 0 && (
            <div className="knowledge_post_blog col-10 p-30">
              {tutorialDetails.content &&
                tutorialDetails.content.length > 0 &&
                tutorialDetails.content.map((article) => (
                  <div key={article._id} id={`content-${article._id}`}>
                    <div>
                      {user.language === 'de' && article?.heading_de ? parse(article?.heading_de) : parse(article?.heading)}
                    </div>
                    <div className="mt-10">
                      {user.language === 'de' && article?.content_de ? parse(article?.content_de) : parse(article?.content)}
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PlatformTourVid;
