import React, { useState } from "react";
import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { handleSaveData } from "./CommonFunctions";
import { useAuth } from "../../../../Utils/Auth";
import { useEffect } from "react";

const ShowSectionSelect = ({ name, getCompanyData, companyData }) => {
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const { setSessionExpire } = useAuth();

  useEffect(() => {
    if (companyData) {
      setIsVisible(companyData[name]);
    }
  }, [companyData]);

  return (
    <div className="d-flex justify-content-end">
      <div className="share_select_root position-relative">
        <div className="share_select_main ">
          <div
            className="d-flex align-items-center"
            onClick={() => setOpen(!open)}
          >
            {isVisible ? (
              <VisibilityOutlined fontSize="small" />
            ) : (
              <VisibilityOffOutlined fontSize="small" />
            )}
            <p className="font_14_500 text-black ml-10">
              {isVisible ? "Show" : "Hide"} Section{" "}
            </p>
            <KeyboardArrowDown sx={{ marginLeft: "20px" }} />
          </div>
          {open && (
            <div
              className="d-flex align-items-center share_select_option pointer"
              onClick={() => {
                setIsVisible(!isVisible);
                const data = {
                  [name]: !isVisible,
                };

                handleSaveData(
                  data,
                  getCompanyData,
                  setOpen(false),
                  setSessionExpire
                );
              }}
            >
              {isVisible ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
              <p className="font_14_500 text-black ml-10">
                {isVisible ? "Hide" : "Show"} Section{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ShowSectionSelect;
