import React from "react";
import { Accordion } from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import { ExpandMore } from "@mui/icons-material";
import AccordionDetails from "@mui/material/AccordionDetails";
import ButtonComp from "../../../../../../Assets/components/Button";
import { useAuth } from "../../../../../../Utils/Auth";
import { useTranslation } from "react-i18next";
function FAQAccordion({ faqDetails }) {
  const {user} = useAuth()
  const {t} = useTranslation()
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{ padding: 0, marginBottom: 0 }}
      >
        <p className="font_16_500 mt-18 mb-10">
          {user.language === 'de' && faqDetails?.question_de ?faqDetails?.question_de:faqDetails?.question}
          </p>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: "0 0 12px 0" }}>
        <p className="font_13_300">{user.language === 'de' && faqDetails?.answer_de ?faqDetails?.answer_de:faqDetails?.answer}</p>
        {faqDetails.link && (
          <div className="mt-20">
            <ButtonComp status={1} type="button">
              <div className="d-flex align-items-center">
                <a className="color_white" href={faqDetails.link}>
                  <span className="link_text_white underline-none">
                    {t("common.learnMore")}
                  </span>{" "}
                  <i className="bi bi-arrow-right ml-10"></i>
                </a>
              </div>
            </ButtonComp>
          </div>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default FAQAccordion;
