import React, { useState } from "react";
import { notifedFeatures } from "../../../Utils/CommonFunctions";
import ButtonComp from "../../../Assets/components/Button";
import Axios from "../../../Api";
import DeleteModal from "../../../Assets/components/DeleteModal/DeleteModal";
import toast from "react-hot-toast";

const AdminDashboard = () => {
  const [notifyFeatureName, setNotifyFeature] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleNotifyUser = async () => {
    await Axios({
      url: `/admin/notified-user/${notifyFeatureName}`,
      method: "GET",
    })
      .then((response) => {
        if (response.data.success) {
          toast.success(response.data.message);
          handleClose()
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };
  return (
    <div className="ml-100 mr-50 mt-50">
      <p className="heading_1">Notify Users for new service</p>
      <div className="d-flex flex-column align-items-start mt-12">
        <select
          value={notifyFeatureName}
          onChange={(e) => setNotifyFeature(e.target.value)}
        >
          <option value="">Notify</option>
          <option value={notifedFeatures.connect}>Connect Feature</option>
          <option value={notifedFeatures.survey}>Survey Feature</option>
          <option value={notifedFeatures.reduceTarget}>
            Reduce Target Feature
          </option>
          {/* <option value={notifedFeatures.reduceConsultDB}>
            Reduce Consult Database Feature
          </option> */}
          <option value={notifedFeatures.analyticsScenario}>
            Scenario Builder(Analytics)
          </option>
          <option value={notifedFeatures.transparencyDirectory}>
            Transparency Directory
          </option>
        </select>
        <ButtonComp className="mt-24" onClick={handleOpen}>
          Notify
        </ButtonComp>
      </div>

      <DeleteModal
        open={open}
        handleClose={handleClose}
        confirmFunction={() => handleNotifyUser()}
        subtitle={`Are you sure you select the correct feature`}
        deleteTitle={"Notify User"}
      />
    </div>
  );
};

export default AdminDashboard;
