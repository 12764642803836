import React from "react";

const QuestionMarkCircle = ({ circleColor, cc, style, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      className={className}
      style={style}
    >
      <circle cx="7" cy="7" r="7" fill={circleColor || "#7E8999"}></circle>
      <path
        fill={cc || "#fff"}
        d="M6.39 8.862V8.77c.002-.429.042-.77.12-1.023.08-.253.194-.457.34-.61.148-.157.326-.3.537-.43.144-.09.274-.189.387-.295.116-.109.207-.23.274-.362.066-.135.099-.286.099-.451a.885.885 0 00-.131-.487.891.891 0 00-.355-.32 1.06 1.06 0 00-.494-.113c-.168 0-.328.037-.48.11a.93.93 0 00-.372.327 1.072 1.072 0 00-.164.55H4.887c.012-.45.12-.826.327-1.13.208-.302.483-.53.824-.68a2.784 2.784 0 011.136-.228c.452 0 .85.08 1.193.238.346.158.615.384.806.678.194.291.291.637.291 1.037 0 .27-.043.511-.13.724a1.76 1.76 0 01-.367.569 2.677 2.677 0 01-.564.443c-.192.119-.35.242-.473.37-.12.128-.21.278-.27.45-.056.171-.086.382-.088.633v.092H6.389zm.617 2.216a.757.757 0 01-.55-.227.748.748 0 01-.231-.554.74.74 0 01.23-.547.757.757 0 01.551-.227c.21 0 .393.075.547.227a.734.734 0 01.234.547.733.733 0 01-.11.394.816.816 0 01-.284.284.745.745 0 01-.387.103z"
      ></path>
    </svg>
  );
};

export default QuestionMarkCircle;
