import React from "react";
import Offset from "./Offset";
import Reduce from "./Reduce";
import useQuery from "../../../Utils/query";

const Act = ({ setSubValue }) => {
  const query = useQuery();
  const subQuery = Number(query.get("subtab"));
  React.useEffect(() => {
    if (subQuery || subQuery === 0) {
      setSubValue(subQuery);
    }
  }, [subQuery]);

  return <div>{subQuery === 0 ? <Reduce /> : <Offset />}</div>;
};

export default Act;
