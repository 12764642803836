// import React, { useEffect, useState } from "react";
import React, { useEffect } from "react";
import BackForwardButton from "../../../Assets/components/BackForwardButton/BackForwardButton";
import PressEnter from "../../../Assets/components/PressEnter/PressEnter";
import ButtonComp from "../../../Assets/components/Button";

function NumberQues(props) {
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      props.handleSubmit(props.regDetails[props.target]);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);

  return (
    <div className="register_text_start col-md-10 max_width_552 register_question_container">
      <div className="purple_label register_question_no">
        {props.currQuestion + 1}/6
      </div>
      <div className="form-group register_text_start">
        <label
          className=" register_sm_label register_question_label"
          htmlFor="label1"
        >
          {props.question.question}
        </label>
        <div className="company_number_box">
          <span
            className="pointer text-right"
            onClick={() => {
              if (props.regDetails[props.target] > 0)
                props.setRegDetails((prev) => ({
                  ...prev,
                  [props.target]: Number(props.regDetails[props.target]) - 1,
                }));
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="2"
              fill="none"
              viewBox="0 0 16 2"
            >
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.031 1.164H.86"
              ></path>
            </svg>
          </span>
          <input
            type="text"
            className="form-control text-center shadow-none register_input facility_input border-none mb-0"
            id="label1"
            value={props.regDetails[props.target]}
            onChange={(e) => props.handleChange(e, props.question.fields.type)}
            placeholder={props.question.fields.placeholder}
            name={props.target}
          />
          <span
            className="text-start pointer"
            onClick={() => {
              if (props.regDetails[props.target] >= 0)
                props.setRegDetails((prev) => ({
                  ...prev,
                  facilities: Number(props.regDetails[props.target]) + 1,
                }));
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 16 16"
            >
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.5 8.164H1.328M8.406 15.25V1.078"
              ></path>
            </svg>
          </span>
        </div>
      </div>

      <div className="mt-30 d-flex align-items-start justify-content-between">
        <BackForwardButton
          handleGoBack={() =>
            props.decQuestionNo(props.regDetails[props.target])
          }
          hanldeGoForward={() =>
            props.incQuestionNo(props.regDetails[props.target])
          }
        />
        <div>
          <ButtonComp
            size="large"
            status={2}
            onClick={() => props.handleSubmit(props.regDetails[props.target])}
          >
            Save
          </ButtonComp>
          <PressEnter />
        </div>
      </div>
      {/* <div className="mt-30 register_button d-flex justify-content-end">
        <button
          className="btn secondary_button_active secondary_button_sx_active"
          onClick={() => props.handleSubmit(props.regDetails[props.target])}
        >
          Save
        </button>
      </div>
      <div className="d-flex justify-content-between mt-10">
        <div>
          <i
            className={`pointer bi bi-chevron-left ${
              props.currQuestion > 0
                ? "register_button_enable"
                : "register_button_disable"
            }`}
            onClick={() => props.decQuestionNo(props.regDetails[props.target])}
          ></i>
          <i
            className={`pointer bi bi-chevron-right ${
              props.currQuestion < 5
                ? "register_button_enable"
                : "register_button_disable"
            }`}
            onClick={() => props.incQuestionNo(props.regDetails[props.target])}
          ></i>
        </div>

        <div className="d-flex">
          <p className="register_enter"> Press Enter</p>
          <i className="bi bi-arrow-return-left"></i>
        </div>
      </div> */}
    </div>
  );
}

export default NumberQues;

// press enter icon change: figma
