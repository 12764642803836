import React from "react";

const NotificationIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={cc || "#00915B"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M18 8A6 6 0 106 8c0 7-3 9-3 9h18s-3-2-3-9zM13.73 21a2 2 0 01-3.46 0"
      ></path>
    </svg>
  );
};

export default NotificationIcon;
