import React from "react";

function LogoutIcon(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="15"
        fill="none"
        viewBox="0 0 17 15"
      >
        <rect
          width="7.32"
          height="12.313"
          x="1.758"
          y="1.508"
          stroke={props.stroke}
          strokeWidth="2"
          rx="2"
        ></rect>
        <path
          fill={props.stroke}
          stroke={props.active ? props.stroke : "#ff"}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M11.727 8.916l-.516.516.353.354-.353-.354a1.25 1.25 0 001.767 1.768l2.65-2.65a1.25 1.25 0 000-1.768l-2.65-2.65a1.25 1.25 0 00-1.767 0l.353.353-.353-.353a1.25 1.25 0 000 1.768l.35-.351-.35.35.516.517h-6.32a1.25 1.25 0 100 2.5h6.32z"
        ></path>
      </svg>
    </div>
  );
}

export default LogoutIcon;
