import React from "react";
import "./noService.css";
import { botAvatarGroup } from "../../../../../Utils/botAvatarsGroup";
import { useAuth } from "../../../../../Utils/Auth";
import ButtonComp from "../../../../../Assets/components/Button";
function NoService(props) {
  const { user } = useAuth();
  return (
    <div className="main_box mt-40">
      <div className="noService_container " style={{ maxWidth: "1200px" }}>
        <div className="row noService_main justify-content-between mx-0">
          {/* <SideDrawer toggleDrawer={props.toggleDrawer} state={props.drawerState} /> */}

          <div className="col-md-8 p-0">
            <div className="mt-40 mb-40">
              <p className="heading_lg ml-40 align_text_left">
                Go Green Today!
              </p>
              <p className="subheading_light ml-40 mt-20 align_text_left">
                you have no {props.serviceName} yet...
              </p>
            </div>
            <div className="d-flex align-items-center r flex-wrap mt-150 ml-32 mb-40 ml-50">
              <div className="mt-20">
                <ButtonComp
                  status={1}
                  // width="lg"
                  // className="primary_button_md noService_no_wrap"
                  onClick={() => props.toggleDrawer(true)}
                >
                  Add&nbsp;{" "}
                  <span style={{ textTransform: "lowercase" }}>
                    {" "}
                    {props.serviceName === "Event" ||
                    props.serviceName === "Agency" ||
                    props.serviceName === "Educational Institution"
                      ? "an"
                      : "a"}
                    &nbsp;{" "}
                  </span>
                  {props.serviceName}
                </ButtonComp>
              </div>
              {/* <div className="mt-20 ml-20">
                <ButtonComp
                  status={3}
                  // className="secondary_button_variant_1 noService_no_wrap"
                >
                  Bulk import
                </ButtonComp>
              </div>
              <div className="d-flex mt-20 ml-20">
                <div className="link_text noService_no_wrap">
                  Download bulk import template
                </div>
              </div> */}
            </div>
          </div>
          <div className="col-md-3 d-flex align-items-center ">
            <div className="noService_image_container d-flex align-items-center">
              <img
                className="noService_img"
                src={botAvatarGroup[user?.bot_avatar_id]?.noActiveOffset.url}
                style={{ height: 330, width: "auto" }}
              />
            </div>
          </div>
          <hr className="bg_divider" />
        </div>
      </div>
    </div>
  );
}

export default NoService;
