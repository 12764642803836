import React, { useEffect, useState } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import axios from "../../../../Api";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-hot-toast";
import Topicprofile from "./Topicprofile";
import { useNavigate } from "react-router-dom";
import getUrlqueries from "../../../../Utils/getUrlQueries";

const Topics = () => {
  const [currentStep, setcurrentStep] = useState(0);
  const [topics, settopics] = useState([]);
  const navigate = useNavigate();

  const getdata = async () => {
    try {
      const response = await axios.get(`/admin/academy/topics`);
      if (response.data.success) {
        settopics(response.data.data);
      }
    } catch (error) {
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  const handleDeleteTopic = async (cid) => {
    try {
      const response = await axios.delete(`/admin/academy/deletetopic/${cid}`);
      if (response.data.success) {
        getdata();
        toast.success("Topic deleted successfully");
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };

  // const topicid = getUrlqueries("topicid");
  const step = getUrlqueries("step");
  useEffect(() => {
    if (step) {
      setcurrentStep(1);
    } else {
      setcurrentStep(0);
    }
  }, [step]);
  return (
    <>
      {currentStep === 1 ? (
        <Topicprofile />
      ) : (
        <div className="row mx-0">
          {topics.map((v, i) => {
            return (
              <Card className="mb-2 me-4" key={i} sx={{ maxWidth: 345 }}>
                <CardMedia
                  sx={{ height: 140 }}
                  image={v.coverimage}
                  title={v.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    {v.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {v.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    onClick={() =>
                      navigate(
                        `/admin/dashboard?tab=5&activetab=0&step=1&topicid=${v._id}`
                      )
                    }
                    size="small"
                  >
                    View Topic
                  </Button>

                  <Button
                    onClick={() =>
                      navigate(
                        `/admin/dashboard?tab=5&activetab=1&topicid=${v._id}`
                      )
                    }
                    size="small"
                  >
                    Edit Topic
                  </Button>
                  <Button onClick={() => handleDeleteTopic(v._id)} size="small">
                    Delete Topic
                  </Button>
                </CardActions>
              </Card>
            );
          })}
        </div>
      )}
    </>
  );
};

export default Topics;
