import React from "react";

const LinearPlaceholder = ({
  className,
  width,
  height,
  borderRadius,
  background,
}) => {
  return (
    <div
      className={className}
      style={{
        width: width || "100%",
        height: height || "12px",
        borderRadius: borderRadius || "6px",
        background: background || "#d9d9d9",
      }}
    ></div>
  );
};

export default LinearPlaceholder;
