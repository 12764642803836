import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import ScopeDetailsTable from "../../../Components/Companydashboard/Store/Components/ItemDetailsPage/Components/scopeDetailsTable";
import ItemDetails from "../../../Components/Companydashboard/LogDashboard/items-new.json";
import { toastResponseError } from "../../../Utils/toastResponseError";
import Axios from "../../../Api";
import PlusIcon from "../../../Components/Companydashboard/Store/Components/StoreCard/PlusIcon";
import ProductDetailsTable from "../../../Components/Companydashboard/Store/Components/ItemDetailsPage/Components/productDetailsTable";
import Card from "./Item/Card";
import TickMark from "../../../Assets/icons/tickMark";
import MinusIcon from "../../../Components/Companydashboard/Store/Components/StoreCard/MinusIcon";
import { useAuth } from "../../../Utils/Auth";
import useQuery from "../../../Utils/query";
import HorizontalLabel from "../../../Assets/components/CarbonNuetrolLabel/HorizontalLabel";
import ButtonComp from "../../../Assets/components/Button";
// import ButtonComp from "../../../Assets/components/Button";
// import meetingRoomImage from "../../../Assets/images/store_our_services.png";

function ItemDetailsPage({
  cartDetails,
  //   setCartDetails,
  //   userCart,
  //   setUserCart,
  handleQuantityChange,
}) {
  const { setSessionExpire } = useAuth();
  const query = useQuery();
  const itemId = query.get("item");
  const [otherItemDetails, setOtherItemDetails] = useState([]);
  const [itemInfo, setItemInfo] = useState({});

  const getOtherItemsData = async () => {
    if (!itemInfo.category) return;
    try {
      const response = await Axios.get(`/items/all`);
      if (response.data.success) {
        let currItemDetails = response.data.items
          .filter(
            (currItem) =>
              currItem.category.toLowerCase() ==
                itemInfo.category.toLowerCase() && currItem._id != itemInfo._id
          )
          .slice(0, 2);
        setOtherItemDetails(currItemDetails);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    if (itemId && itemId.length) {
      ItemDetails.forEach((currItem) => {
        if (currItem._id == itemId) {
          setItemInfo({
            _id: currItem._id,
            name: currItem.name,
            price: currItem.price,
            image: currItem.image,
            description: currItem.description,
            category: currItem.category,
            thumbnailImage: currItem.thumbnailImage,
            coverImage: currItem.coverImage,
            labelImage: currItem.labelImage,
            scopes: currItem.scopes,
          });
        }
      });
    }
  }, [itemId]);

  useEffect(() => {
    getOtherItemsData();
  }, [itemInfo]);

  const [curQuantity, setcurQuantity] = useState(0);

  useEffect(() => {
    if (cartDetails) {
      var itemFind = cartDetails.find((items) => items.itemId === itemId);

      if (itemFind) {
        setcurQuantity(itemFind.quantity);
      } else {
        setcurQuantity(0);
      }
    }
  }, [itemId, cartDetails]);

  return (
    <div className="p-40">
      <p className="breadcrumb_text_light_1 text_capital">
        <Link to={`/register/subscription`}>
          <span className="color_light_2 mb-2">
            <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
          </span>
          <span className="color_light_2">Subscription </span>
          <span className="color_light_2">/ general items </span>
        </Link>{" "}
        <span className="fw-700 text-uppercase"> / {itemInfo.name}</span>
      </p>
      <div className="row mt-50">
        <div className="col-md-8 d-flex flex-column">
          <div className="flow-grow-1">
            <div className="item_profile_cover_img_bg">
              <img src={itemInfo?.image} />
            </div>

            <p className="body_text_m mt-20">{itemInfo.description}</p>
          </div>

          <div className="mt-60">
            <p className="font_17_700 text_capital mb-30">Included Scopes</p>
            {String(itemInfo.name).toLowerCase() === "product" ? (
              <ProductDetailsTable tableData={itemInfo.scopes} />
            ) : (
              <ScopeDetailsTable tableData={itemInfo.scopes} />
            )}
          </div>

          <div className="" style={{ marginTop: 59 }}>
            <p className="font_17_700 text_capital">label</p>

            <div className="d-flex gap_60 mt-10 align-items-center">
              <p className="font_14_400 line_height_22 flex-1">
                We encourage you to approach carbon management holistically.
                This includes compensating for your residual emissions with our
                certified climate protection projects. You can choose whether to
                compensate for your emissions as you log them throughout the
                year or compensate the last 12 months you&apos;ve logged. Once
                you make the first compensation, your label will be available
                for download. Learn more in our Academy under Contribution.
              </p>
              <div className="item_details_label_card">
                <HorizontalLabel item={itemInfo.name} isProfile_zero={true} />
              </div>
            </div>
          </div>

          <div className="mt-60">
            <hr />
          </div>

          <div className="mt-60 pb-32">
            <p className="font_17_700 text_capital">Other Accounting items</p>

            <div className="row mt-30">
              {otherItemDetails.map((currItem, index) => {
                return (
                  <div key={currItem._id + index} className="col-md-6">
                    <Card
                      item={currItem}
                      cart={cartDetails}
                      handleIncrease={handleQuantityChange}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div
            className="d-flex mt-30 mb-32 border_1_ef border_radius_10"
            style={{ gap: 45 }}
          >
            <div className=" py-20 pl-32">
              <p className="font_36_700_plus_jakarta">Our Services</p>
              <p className="body_text_s color_31 mt-20">
                We provide a number of different services to support you on your
                climate action journey.
              </p>
              <ButtonComp className="mt-20">View</ButtonComp>
            </div>

            <img src={meetingRoomImage} className="h-100 w-100 image-fit" />
          </div> */}
        </div>

        <div className="col ml-50">
          <div
            className="container_design_1 pos_sticky_20"
            style={{ padding: "42px 27px 28px 27px", maxWidth: 358 }}
          >
            <p className="f-20 fw-600 font-inter">{itemInfo.name}</p>

            <p className="subheading_light mt-20 mr-46 color_57 line_height_22">
              Measure, analyse and reduce the emissions from your{" "}
              {itemInfo.name}.
            </p>

            <div className="mt-32">
              <p className="body_text_s">
                <TickMark className={"mr-13"} />
                Unlimited Logs
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                Scope 1, 2 & 3
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                GHG Reports
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                Consultant Database
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                Analytics
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                Targets
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                Offset
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                12 Month Access
              </p>

              <p className="mt-8 body_text_s">
                <TickMark cc={"#00915B"} className={"mr-13"} />
                Carbon Neutral Label*
              </p>

              <div className="d-flex align-items-center mt-30">
                <p className="heading_9 d-flex align-items-center">
                  {Number(itemInfo.price).toFixed(2).toLocaleString()} €{" "}
                  <span className="body_text_s color_ba">/month</span>
                </p>

                <p className="body_text_s color_ba ml-10">(billed annually)</p>
              </div>

              <div className="d-flex mt-30 justify-content-between">
                <div className="store_card_cart_add_root">
                  <MinusIcon
                    onClick={() => {
                      if (curQuantity > 0) {
                        handleQuantityChange(itemId, curQuantity - 1);
                      }
                    }}
                  />

                  <span className="font_15_600 flex-1 d-flex align-items-center justify-content-center line_height_normal">
                    {curQuantity}
                  </span>
                  <PlusIcon
                    onClick={() =>
                      handleQuantityChange(itemId, curQuantity + 1)
                    }
                  />
                </div>
              </div>
              <div>
                <ButtonComp
                  status={2}
                  onClick={() => handleQuantityChange(itemId, curQuantity + 1)}
                >
                  ADD TO CART
                </ButtonComp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetailsPage;
