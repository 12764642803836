import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
function NumberPercentInput(props) {
  const [value, setValue] = useState("-1");
  function isNum(v) {
    return /^\d+$/.test(v);
  }

  const handleValueChange = (event) => {
    if (isNum(event.target.value) || event.target.value.length == 0) {
      setValue(event.target.value);
      props.handleAnswerChange(event.target.value, props.name);
    }
  };
  useEffect(() => {
    if (isNum(props.answers[props.name]) && value === "-1")
      setValue(props.answers[props.name]);
  }, [props.answers]);
  return (
    <div>
      <p className="sr-only subheading_1 mb-10" htmlFor="product_name">
        {props.title}(%)
      </p>

      <TextField
        disabled={!props.disabled}
        value={value}
        onChange={(e) => {
          handleValueChange(e);
        }}
        id="demo-helper-text-aligned"
        size="small"
        sx={{
          border: "1px solid var(--color-table-stroke)",
          borderRadius: 1,
          boxShadow: "none",
          outline: "none",
          "& fieldset": { border: "none" },
        }}
        className="itemSettings_input_symbol itemSettings_input itemSettings_input::placeholder mt-6"
        inputProps={{
          style: {
            height: "27px",
          },
        }}
      />
      <p className="helper_text fw-400 mt-10">
        Help us understand the amount of emissions you are accountable for
      </p>
    </div>
  );
}

export default NumberPercentInput;
