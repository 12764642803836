import { Avatar, Box, CircularProgress, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../Utils/Auth";
import ImageCropper from "../ImageCropper/ImageCropper";
import { toast } from "react-hot-toast";
import EditIcon from "../../../Assets/icons/EditIcon";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxWidth: "90%",
  bgcolor: "transparent",
};

const ImageUploadCropper = ({
  avatar,
  inputFileRef,
  handlePicUpload,
  setAvatar,
}) => {
  const { user } = useAuth();
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [currentPage, setCurrentPage] = useState("choose-img");

  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  const updateBg = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        onImageSelected(reader.result);
      };
    }
  };

  // Generating Cropped Image When Done Button Clicked
  // const onCropDone = async (imgCroppedArea, rotation = 0) => {
  //   const canvasEle = document.createElement("canvas");
  //   canvasEle.width = imgCroppedArea.width;
  //   canvasEle.height = imgCroppedArea.height;

  //   const context = canvasEle.getContext("2d");

  //   let imageObj1 = new Image();
  //   imageObj1.src = image;
  //   imageObj1.onload = async function () {
  //     context.translate(imgCroppedArea.width / 2, imgCroppedArea.height / 2);
  //     context.rotate(getRadianAngle(rotation));
  //     context.translate(-imageObj1.width / 2, -imageObj1.height / 2);
  //     context.drawImage(imageObj1, 0, 0);
  //     const dataURL = canvasEle.toDataURL("image/jpeg");
  //     const blobImage = dataURItoBlob(dataURL);
  //     setCurrentPage("img-cropped");
  //     const extension = blobImage.type.split("/")[1];
  //     const imageFile = new File([blobImage], `my-image.${extension}`, {
  //       type: blobImage.type,
  //     });
  //     if (imageFile.size > 2048 * 1024) {
  //       toast.error("File size exceeded 2MB!", { position: "bottom-right" });
  //       return;
  //     }
  //     setIsLogoUploading(true);
  //     const location = await handlePicUpload(imageFile);
  //     setIsLogoUploading(false);
  //     if (location) {
  //       setAvatar(location);
  //       setCurrentPage("choose-img");
  //     }
  //   };
  //   handleClose();
  // };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const onCropDone = async (pixelCrop, rotation = 0) => {
    const img = await createImage(image);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const maxSize = Math.max(img.width, img.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);
    ctx.drawImage(
      img,
      safeArea / 2 - img.width * 0.5,
      safeArea / 2 - img.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + img.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + img.height * 0.5 - pixelCrop.y)
    );

    const dataURL = canvas.toDataURL("image/jpeg");
    const blobImage = dataURItoBlob(dataURL);
    setCurrentPage("img-cropped");
    const extension = blobImage.type.split("/")[1];
    const imageFile = new File([blobImage], `my-image.${extension}`, {
      type: blobImage.type,
    });
    if (imageFile.size > 4 * 1024 * 1024) {
      toast.error("File size exceeded 4MB!", { position: "bottom-right" });
      return;
    }
    handleClose();

    setIsLogoUploading(true);
    const location = await handlePicUpload(imageFile);
    setIsLogoUploading(false);
    if (location) {
      setAvatar(location);
      setCurrentPage("choose-img");
    }
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
    handleClose();
  };

  function dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(",")[1]);

    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], { type: mimeString });
    return blob;
  }

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  useEffect(() => {
    if (currentPage === "crop-img") {
      handleOpen();
    }
  }, [currentPage]);

  return (
    <div>
      {isLogoUploading ? (
        <CircularProgress />
      ) : avatar ? (
        <div className="position-relative width_fit">
          <Avatar
            src={avatar}
            sx={{ height: 72, width: 72, border: "1px solid #efefef" }}
          >
            {String(user?.firstname).charAt(0).toUpperCase()}
          </Avatar>
          <button
            className="profileEditbtn profileImg_upload_btn"
            onClick={() => inputFileRef.current.click()}
          >
            <EditIcon />
          </button>
        </div>
      ) : (
        <div className="position-relative width_fit">
          <Avatar
            src={avatar}
            sx={{ height: 72, width: 72, border: "1px solid #efefef" }}
          >
            {user ? String(user?.firstname).charAt(0).toUpperCase() : "Upload"}
          </Avatar>
          <button
            className="profileEditbtn profileImg_upload_btn"
            onClick={() => inputFileRef.current.click()}
          >
            <EditIcon />
          </button>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        hidden
        ref={inputFileRef}
        onChange={updateBg}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ImageCropper
            image={image}
            onCropDone={onCropDone}
            onCropCancel={onCropCancel}
            ratio={1 / 1}
            infoStr={"File aspect ratio should be in 1:1"}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default ImageUploadCropper;
