import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import TabLabels from "../../LogDashboard/Components/TabLabels";
import PropTypes from "prop-types";
import LaunchingCard from "../../../../Assets/components/LaunchingCard";
import { notifedFeatures } from "../../../../Utils/CommonFunctions";
import ScenarioBuilder from "./ScenarioBuilder";
import Targets from "./Targets";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Reduce = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="offset_root ml-100 mr-50">
      <p className="offsetProjectTitle mt-40 mb-24">Reduce</p>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            className="general_root_tabs"
          >
            <Tab label={<TabLabels title={"Targets"} />} {...a11yProps(0)} />
            <Tab
              label={<TabLabels title={"Consultant Database"} />}
              {...a11yProps(1)}
            />
            <Tab
              label={<TabLabels title="Scenario Builder" />}
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Targets />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <div className="mt-50 main_box mb-40">
            <LaunchingCard
              title={"Connect consultants to your account"}
              chip={"Launching in December"}
              subtitle={
                "You don't need to be an expert. Why? Because we already have them connected to our platform, standing by to support you."
              }
              isShowWiki={true}
              feature={notifedFeatures.connect}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ScenarioBuilder />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Reduce;
