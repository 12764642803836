import { Done } from "@mui/icons-material";
import React from "react";
import BackForwardButton from "../../../../../../Assets/components/BackForwardButton/BackForwardButton";
import ButtonComp from "../../../../../../Assets/components/Button";

const Step3 = ({ setStep, option, setOption, disableOption1 }) => {
  // const [monthLength, setMonthLength] = useState(12);

  // useEffect(() => {
  //   if (data) {
  //     if (data.offsetDetails[0].loggedMonths.length < 12) {
  //       setOption(2);
  //       setMonthLength(data.offsetDetails[0].loggedMonths.length);
  //     }
  //   }
  // }, [data]);

  return (
    <div>
      <div className="d-flex align-items-center mb-40">
        <p className="font_17_700">2. Select your Offset Option</p>
        {disableOption1 ? (
          <div className="subtitle_box bg_soft_orange ml-20">
            <p className="font_13_600 color_orange">
              You don&apos;t have 12 complete months of log data!
            </p>
          </div>
        ) : (
          <div className="subtitle_box bg_green ml-20">
            <p className="font_13_600 color_green">
              You have 12 complete months of log data!
            </p>
          </div>
        )}
      </div>
      <div
        className={
          "inactive_offset_option_card " +
          (option === 1 ? "inactive_offset_option_card_selected " : "") +
          (disableOption1 ? "inactive_offset_option_card_disabled" : "")
        }
        onClick={() => {
          if (!disableOption1) {
            setOption(1);
          }
        }}
        disabled={disableOption1}
      >
        <div className="inactive_offset_option_body">
          <div className="d-flex align-items-center">
            <div className="offsetProjectTitle">Retrospective Offset</div>

            <div className="offsetBox retrospectiveBox ml-20">
              <p className="font_13_600 py-2">Option 1</p>
            </div>
          </div>
          <p className="body_text_s mt-16">
            The retrospective offset will let you offset the emissions, you have
            logged over the last 12 months. This means you offset the last 12
            months, and communicate your carbon neutrality for the next 12
            months. Your contribution period and your log period are therefore
            12 months removed. We know, this sounds confusing at first, but it
            has been the industry best practise for years now. 👍🏼
          </p>
        </div>
        <div className="inactive_offset_option_footer">
          <p className="body_text_m">
            <span className="mr-10">
              <Done
                sx={{ width: 10, height: 10 }}
                // fontSize="small"
                htmlColor={disableOption1 ? "#8a8a8a" : "#2441F5"}
              />
            </span>
            Carbon neutral starting today (+ No Price uncertaincy)
          </p>
          <p className="body_text_m ml-40">
            <span className="mr-10">
              <Done
                sx={{ width: 10, height: 10 }}
                // fontSize="small"
                htmlColor={disableOption1 ? "#8a8a8a" : "#2441F5"}
              />
            </span>
            Pay in Steps from next year
          </p>
          <p className="body_text_m ml-40">
            <span className="mr-10">
              <Done
                sx={{ width: 10, height: 10 }}
                // fontSize="small"
                htmlColor={disableOption1 ? "#8a8a8a" : "#2441F5"}
              />
            </span>
            Carbon Neutral Label
          </p>
        </div>
      </div>
      <div
        className={
          "inactive_offset_option_card mt-50 " +
          (option === 2 && "inactive_offset_option_card_selected")
        }
        onClick={() => setOption(2)}
      >
        <div className="inactive_offset_option_body">
          <div className="d-flex align-items-center">
            <p className="offsetProjectTitle">Prospective Offset</p>
            <div className="offsetBox propectiveBox ml-20">
              <p className="font_13_600 py-2">Option 2</p>
            </div>
          </div>
          <p className="body_text_s mt-16">
            The prospective offset will let you log and offset emissions almost
            simultaniously. This means your contribution period and your log
            period are identical. You will offset 1 ton in the offset store
            today, and continue to log your accounting items emissions for the
            next 12 months. While doing so, you can decide weather you want to
            offset these emissions in steps, or at the end of the 12 months - up
            to you! 🌳
          </p>
        </div>
        <div className="inactive_offset_option_footer">
          <p className="body_text_m">
            <span className="mr-10">
              <Done
                sx={{ width: 10, height: 10 }}
                // fontSize="small"
                htmlColor="#2441F5"
              />
            </span>
            Carbon neutral starting today (+ No Price uncertaincy)
          </p>
          <p className="body_text_m ml-40">
            <span className="mr-10">
              <Done
                sx={{ width: 10, height: 10 }}
                // fontSize="small"
                htmlColor="#2441F5"
              />
            </span>
            Pay in Steps from next year
          </p>
          <p className="body_text_m ml-40">
            <span className="mr-10">
              <Done
                sx={{ width: 10, height: 10 }}
                // fontSize="small"
                htmlColor="#2441F5"
              />
            </span>
            Carbon Neutral Label
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-40">
        <BackForwardButton
          doNotShowForward={true}
          handleGoBack={() => setStep(2)}
        />
        <ButtonComp
          status={1}
          className="md"
          disabled={!option}
          onClick={() => {
            setStep(4);
          }}
        >
          Select & Continue to Offset
        </ButtonComp>
      </div>
    </div>
  );
};

export default Step3;
