import React, { useEffect, useState } from 'react'
import './customSelect.css'
import onClickOutside from "react-onclickoutside";

//import Axios from '../../../../../../../../Api/index'
import searchIconImage from '../../../../../../../../Assets/images/serachIconImage.png'

function CustomSelect({ filteredList,
  setFilteredList,
  accountingItemName,
  addItemtoList,
  selectedItemLength,
  selectedAccountingItems }) {
  const [showOptionList, setShowOptionList] = useState(false)
  // const [accountingItemName, setAllAccountingItemsName] = useState([])
  // const [filteredList, setFilteredList] = useState([])
  const [searchKey, setSearchKey] = useState("")
  //const { setSessionExpire } = useAuth();
  CustomSelect.handleClickOutside = () => {
    setShowOptionList(false);
  };

  useEffect(() => {
    if (searchKey.length == 0) {
      setFilteredList(accountingItemName)
      return;
    }
    let tempFilters = accountingItemName.filter((currItem) =>
      currItem.itemDetails.name.toLowerCase().includes(searchKey.toLowerCase())

    )
    setFilteredList(tempFilters)
  }, [searchKey])
  const handleListDisplay = () => {
    setShowOptionList(!showOptionList)
  }
  return (
    <div className={`customSelect-container border_radius_5 border-ef w-100`}>
      <div
        className={`h-100 d-flex justify-content-between align-items-center border_radius_5 pl-10`}
        onClick={handleListDisplay}
      >
        <p className={`body_text_l ${selectedItemLength ? "" : "color_ba"}`}>
          {selectedItemLength ? `${selectedItemLength} Accounting Items selected` : "Select"}
        </p>
        <p className="mr-20">
          {!showOptionList ?
            <i className="bi bi-chevron-down"></i>
            : <i className="bi bi-chevron-up"></i>
          }
        </p>
      </div>
      {showOptionList && (
        <div className="mt-1px">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => { setSearchKey(e.target.value) }}
            className="customSelect_search_type w-100 border-ef pt-10 pl-10 pb-10" />
          <img src={searchIconImage} className="customSelect_search_icon mt-16" />
          <ul className="select-options">
            <li
              className="d-flex justify-content-between customSelect_option custom-select-option"
              data-name={"Select all Accounting Items"}
              onClick={() => { addItemtoList(-1, true) }}
            >
              <p>Select all Accounting Items</p>
              {selectedAccountingItems.length === accountingItemName.length ?
                <p className=""><i className="blue_label_sm color_main_blue bi bi-check-lg"></i></p> : <input type="checkbox" className="border-ef border_radius_5 customSelect_checkbox" />
              }
            </li>
            {filteredList.length ? filteredList.map((option, index) => {
              return (
                <li
                  className="d-flex justify-content-between customSelect_option custom-select-option"
                  data-name={option.itemDetails.name}
                  key={option._id}
                  onClick={() => { addItemtoList(index, false) }}
                >
                  <p>{option.itemDetails.name}</p>
                  {selectedAccountingItems.findIndex(currItem => currItem.id === option._id) != -1 ?
                    <p className=""><i className="blue_label_sm color_main_blue bi bi-check-lg"></i></p> : <input type="checkbox" className="border-ef border_radius_5 customSelect_checkbox" />
                  }

                </li>
              );
            }) : ""}
          </ul>
        </div>
      )}
    </div>
  )
}
const clickOutsideConfig = {
  handleClickOutside: () => CustomSelect.handleClickOutside,
};
export default onClickOutside(CustomSelect, clickOutsideConfig);