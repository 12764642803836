import React, { useEffect, useState } from "react";
import "./analytics.css";
import { Box, Tab, Tabs } from "@mui/material";
import Emission from "./Emissions";
import TabLabels from "../LogDashboard/Components/TabLabels";
import DataMonitor from "./DataMonitor";
import { useNavigate } from "react-router-dom";
import useQuery from "../../../Utils/query";

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ marginTop:"60px" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

const Analytics = () => {
  const navigate = useNavigate();
  const query = useQuery();
  const subtab = Number(query.get('subtab'));
  const [activeTab, setActiveTab] = useState(0);
  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
    navigate(`/dashboard?tab=4&subtab=${newValue}`)
  };

  useEffect(()=>{
    if(subtab || subtab === 0){
      setActiveTab(subtab);
    }
  },[subtab])

  return (
    <div className=" ml-116 mt-40 mr-80">
      <h1 className="heading_2">Analytics</h1>
      <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={handleChange}
          className="general_root_tabs"
        >
          <Tab label={<TabLabels title="Emissions" />} {...a11yProps(0)} />
          <Tab label={<TabLabels title="Data Monitor" />} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={activeTab} index={0}>
        <Emission />
      </TabPanel>
      <TabPanel value={activeTab} index={1}>
        <DataMonitor />
      </TabPanel>
      </Box>
    </div>
  );
};

export default Analytics;
