export const daysDiffFromCurrent = (dateObj) => {
  if (dateObj) {
    let endDate = dateObj
      ? new Date(dateObj.year, dateObj.month, 0)
      : new Date();
    let currentDate = new Date();

    const timeDifference = endDate.getTime() - currentDate.getTime();
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    return daysDifference;
  } else return;
};
