import React, { useEffect, useRef, useState } from "react";
import "./productDetail.css";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ProductCard1 from "./Components/ProductCards/productCard1";
import ProductCard2 from "./Components/ProductCards/productCard2";
import ProductCard3 from "./Components/ProductCards/productCard3";
import ProductDetailsTab from "./Components/ProductDetailsTabs";
import axios from "../../../../Api/index";
import { toast } from "react-hot-toast";
import ProductMainTable from "./Components/ProductMainTable";
import useQuery from "../../../../Utils/query";
import { Link } from "react-router-dom";
import { getServiceIcon } from "../../../../Utils/itemIcon";

function ProductDetail() {
  const [year, setYear] = useState("");
  const [itemName, setItemName] = useState("");
  const [currYearEmission, setCurrYearEmission] = useState(0);
  const [emissionLogCount, setEmissionLogCount] = useState(0);
  const [itemId, setItemId] = useState("");
  const [logItem, setLogItem] = useState("");
  const [yearList, setYearList] = useState([]);
  const [logId, setLogId] = useState("");
  const [matrixItems, setMatrixItems] = useState([]);
  const [matrixCellData, setMatrixCellData] = useState([]);
  const [planMonths, setPlanMonths] = useState([]);
  const [isOffset, setIsOffset] = useState([]);
  const [monthWiseEmissionSum, setMonthWiseEmissionSum] = useState([]);
  const [logStatusYear, setLogStatusYear] = useState([]);
  const [logStatusMonth, setLogStatusMonth] = useState(0);
  const [connectedItems, setConnectedItems] = useState([]);
  const [lastYearEmission, setlastYearEmission] = useState(0);
  const [isCrateCellUpdate, setIsCrateCellUpdate] = useState(false);
  const query = useQuery();
  const id = query.get("logId");
  const yearCreated = query.get("year");
  const monthCreated = query.get("month");
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const [subDetailsTab, setsubDetailsTab] = useState(0);
  const subtab = Number(query.get("subtab"));
  const isViewLabel = Boolean(query.get("view-label"));
  const detailsSection = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  useEffect(() => {
    if (subtab || subtab === 0) {
      setsubDetailsTab(subtab);
    }
    if (isViewLabel) {
      detailsSection.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isViewLabel, subtab]);

  const getData = async () => {
    if (logId.length === 0 || year.length === 0) return;
    await axios
      .get(`/log/matrix-columns?logId=${logId}&year=${year}`, { logId: logId })
      .then((res) => {
        if (
          res.data.itemDetails.DoubleAccountingLocation &&
          res.data.itemDetails.DoubleAccountingLocation.length > 0
        ) {
          let temp = [...connectedItems];
          temp.push(res.data.itemDetails.DoubleAccountingLocation);
          setConnectedItems(temp);
        }
        setIsPaused(res.data.isPaused);
        setMatrixItems(res.data.matrixItems);
        // setPlanMonths(res.data.latestYearDocument.planMonths)
        setItemId(res.data.itemId);
        setItemName(res.data.itemName);
        setLogItem(res.data.itemDetails);
        setlastYearEmission(res.data.lastYearEmission);
        let currMonths = [];
        let currOffset = [];
        for (let month = 0; month < 12; month++) {
          currMonths.push(false);
          currOffset.push(false);
        }
        res.data.latestYear[year].forEach((month) => {
          if (month.month) currMonths[month.month - 1] = true;
          else currMonths[month - 1] = true;
          if (month.offset) currOffset[month.month - 1] = true;
        });
        setPlanMonths(currMonths);
        setIsOffset(currOffset);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const getMatrixCellData = async () => {
    try {
      await axios
        .get(`/cell/matrix-cell?logId=${logId}&year=${year}`)
        .then((res) => {
          if (res.data.success) {
            let currMatrixCellData = [];
            for (let index = 0; index < matrixItems.length; index++) {
              let temp = new Array(13);
              temp.fill(0);
              currMatrixCellData.push(temp);
            }
            let currMonthWiseCompletion = new Array(12);
            currMonthWiseCompletion.fill(0);
            let currTotalEmission = 0;
            let currEmissionCount = 0;
            let currMonthWiseSum = new Array(12);
            currMonthWiseSum.fill(0);
            let currMonthCompletion = 0;
            let currMonth = new Date().getMonth();
            res.data.cellData.forEach((cell) => {
              if (cell.month === currMonth + 1) currMonthCompletion++;
              currMonthWiseCompletion[cell.month - 1]++;
              // currMatrixCellData[cell.scopeIndex][cell.month] =
              //   cell.emission.toFixed(2).toLocaleString();
              currMatrixCellData[cell.scopeIndex][cell.month] = cell;
              currMonthWiseSum[cell.month - 1] += cell.emission;
              currTotalEmission += cell.emission;
              currEmissionCount++;
            });
            setLogStatusMonth(currMonthCompletion);
            setLogStatusYear(currMonthWiseCompletion);
            setMonthWiseEmissionSum(currMonthWiseSum);
            setEmissionLogCount(currEmissionCount);
            setCurrYearEmission(currTotalEmission);
            setMatrixCellData(currMatrixCellData);
            if (isViewLabel) {
              detailsSection.current.scrollIntoView({ behavior: "smooth" });
            }
          }
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getAllYear = async () => {
    try {
      await axios.get(`/log/log-allyear/${logId}`).then((res) => {
        if (res.data.success) {
          if (res.data.years && res.data.years.length > 0)
            setYear(res.data.years[res.data.years.length - 1]);
          setYearList(res.data.years);
        }
      });
    } catch (err) {
      toast.error(err.message);
    }
  };
  useEffect(() => {
    if (matrixItems.length > 0) getMatrixCellData();
  }, [matrixItems]);

  useEffect(() => {
    setLogId(id);
  }, [id]);

  useEffect(() => {
    if (logId.length > 0) {
      getAllYear();
      getData();
    }
  }, [logId]);
  useEffect(() => {
    if (logId.length > 0) {
      getData();
      if (matrixItems.length > 0) getMatrixCellData();
    }
  }, [year]);

  const getIconName = (item) => {
    if (item?.facilityType) return item.facilityType;
    else if (item?.productType) {
      return item?.productType;
    } else if (item?.event) {
      return item?.event;
    } else if (item?.hotelType) {
      return item?.hotelType;
    } else if (item?.buildingType) {
      return item?.buildingType;
    } else return itemName;
  };

  return (
    <div>
      <div className="align_text_left mt-50">
        <p className="breadcrumb_text_light_1">
          <Link to={`/dashboard?tab=3&step=0`}>
            <span className="color_light_2">LOG</span>
          </Link>{" "}
          <span className="fw-700 text-uppercase"> / {itemName}</span>
        </p>
      </div>
      <div className="  mt-40">
        <div className="d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={getServiceIcon(getIconName(logItem))}
              className="icon_sizing"
            />
            <p className="f-24 fw-600 ml-10">{logItem.name}</p>
          </div>
          <div>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={year}
                onChange={handleYearChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {yearList.map((eachYear) => (
                  <MenuItem key={eachYear} value={eachYear}>
                    {eachYear}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="row mt-12">
          <div className="col-3">
            <ProductCard1
              currTotalEmission={currYearEmission}
              year={year}
              lastYearEmission={lastYearEmission}
            />
          </div>
          <div className="col-3">
            <ProductCard2
              logStatusYear={logStatusYear}
              planMonths={planMonths}
              matrixItemLength={matrixItems.length}
              logStatusMonth={logStatusMonth}
              year={year}
              month={new Date().getMonth()}
            />
          </div>
          <div className="col-6">
            <ProductCard3 connectedItems={connectedItems} year={year} />
          </div>
        </div>

        <div className="mt-50">
          <ProductMainTable
            monthWiseEmissionSum={monthWiseEmissionSum}
            matrixItems={matrixItems}
            itemId={itemId}
            itemName={itemName}
            emissionLogCount={emissionLogCount}
            currYearEmission={currYearEmission}
            matrixCellData={matrixCellData}
            getMatrixCellData={getMatrixCellData}
            planMonths={planMonths}
            isOffset={isOffset}
            logId={logId}
            year={year}
            isPaused={isPaused}
            setIsCrateCellUpdate={setIsCrateCellUpdate}
            isCrateCellUpdate={isCrateCellUpdate}
            yearList={yearList}
            logItemName={logItem.name}
          />
        </div>

        <div className="mt-50" ref={detailsSection}>
          <ProductDetailsTab
            yearCreated={yearCreated}
            monthCreated={monthCreated}
            logId={logId}
            year={year}
            itemName={itemName}
            subDetailsTab={subDetailsTab}
            setsubDetailsTab={setsubDetailsTab}
            isPaused={isPaused}
            isCrateCellUpdate={isCrateCellUpdate}
            yearList={yearList}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductDetail;
