import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { formatNumberIntoReadableFormat } from "../../../Utils/CommonFunctions";

function EmissionChartPlaceholder({ interval }) {
  const data = [
    {
      month: 1,
      "Scope 1": 3,
      "Scope 2": 2,
      "Scope 3": 3,
    },
    {
      month: 2,
      "Scope 1": 3,
      "Scope 2": 3,
      "Scope 3": 2,
    },
    {
      month: 3,
      "Scope 1": 4,
      "Scope 2": 1,
      "Scope 3": 4,
    },
    {
      month: 4,
      "Scope 1": 2,
      "Scope 2": 4,
      "Scope 3": 5,
    },
    {
      month: 5,
      "Scope 1": 2,
      "Scope 2": 4,
      "Scope 3": 2,
    },
    {
      month: 6,
      "Scope 1": 3,
      "Scope 2": 3,
      "Scope 3": 3,
    },
    {
      month: 7,
      "Scope 1": 3,
      "Scope 2": 3,
      "Scope 3": 3,
    },
    {
      month: 8,
      "Scope 1": 4,
      "Scope 2": 2,
      "Scope 3": 4,
    },
    {
      month: 9,
      "Scope 1": 2,
      "Scope 2": 4,
      "Scope 3": 2,
    },
    {
      month: 10,
      "Scope 1": 1,
      "Scope 2": 4,
      "Scope 3": 4,
    },
    {
      month: 11,
      "Scope 1": 3,
      "Scope 2": 3,
      "Scope 3": 3,
    },
    {
      month: 12,
      "Scope 1": 3,
      "Scope 2": 3,
      "Scope 3": 3,
    },
  ];
  return (
    <ResponsiveContainer height={300}>
      <BarChart
        data={data}
        barGap={"30%"}
        barCategoryGap="30%"
        bar
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <CartesianGrid stroke="#efefef" vertical={false} />
        <XAxis
          tickFormatter={(_, i) =>
            `${String(i + 1).padStart(2, "0")}-${new Date().getFullYear()}`
          }
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
          tickLine={{ display: "none" }}
          interval={interval ? interval : 0}
        />
        <YAxis
          tickFormatter={(val) => `${formatNumberIntoReadableFormat(val)} t`}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
        />
        <Bar dataKey="Scope 1" stackId="a" fill="#BCE0FF" opacity={0.5} />
        <Bar dataKey="Scope 2" stackId="a" fill="#79C1FF" opacity={0.5} />
        <Bar
          dataKey="Scope 3"
          stackId="a"
          fill="#FFCB11"
          radius={[0, 0, 0, 0]}
          opacity={0.5}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default EmissionChartPlaceholder;
