import React from "react";
import { FormControl, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";

const BootstrapInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    fontFamily: "var(--inter-font)",
    fontWeight: 400,
    fontSize: "15px",
    borderRadius: "5px",
    height: "40px",
    marginTop: "auto",
    padding: "5px",
    display: "flex",
    alignItems: "center",
  },
}));
function SelectInputSettings(props) {
  return (
    <div>
      <div>
        {props.title.length > 0 ? (
          <label htmlFor="service_industry" className="subheading_1 mb-12">
            {props.title}
          </label>
        ) : (
          ""
        )}
      </div>
      <FormControl style={{ width: "90%", marginTop: "4px" }}>
        <Select
          disabled={!props.disabled}
          value={props.answers[props.name] ? props.answers[props.name] : ""}
          onChange={(event) =>
            props.handleAnswerChange(props.name, event.target.value)
          }
          input={<BootstrapInput />}
          className="item_select_"
        >
          {props.dropDownList &&
            props.dropDownList.map((current_item, index) => {
              return (
                <MenuItem
                  value={current_item.item ? current_item.item : current_item}
                  key={index}
                >
                  {current_item.icon ? (
                    <img src={current_item.icon} height="15px" />
                  ) : null}

                  <p className="ml-8">
                    {current_item.item ? current_item.item : current_item}
                  </p>
                </MenuItem>
              );
            })}
        </Select>

        {/* <select
          disabled={!props.disabled}
          id="service_industry"
          value={props.answers[props.name]}
          onChange={(event) =>
            props.handleAnswerChange(props.name, event.target.value)
          }
          className="form-select w-100 form-control shadow-none itemSettings_input w-80"
        >
          <option className="SideDrawer_input" key={-1}>
            Choose {props.title}
          </option>
          {props.dropDownList &&
            props.dropDownList.map((current_item, index) => {
              return (
                <option className="SideDrawer_input" key={index}>
                  {current_item.item}
                </option>
              );
            })}
        </select> */}
      </FormControl>
      <p className="helper_text fw-400 ml-10 mt-14">
        selecting industry will pre filter the raw materials for you
      </p>
    </div>
  );
}

export default SelectInputSettings;
