import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
import { formatNumberIntoReadableFormat } from "../../../../../../../Utils/CommonFunctions";

const ChartMain = ({ data, includeScope3 }) => {
  let chartData = data;
  if (data[0]) {
    data[0].scope1 = 0;
    data[0].scope2 = 0;
    data[0].scope3 = 0;
  }

  const isRed = (data) => {
    if (
      data.total <
      data.actual_scope1 +
        data.actual_scope2 +
        (includeScope3 ? data.actual_scope3 : 0)
    )
      return true;
    else return false;
  };

  const CustomTick = ({ x, y, payload, index }) => {
    // Customize the appearance of the tick line here
    return (
      <g transform={`translate(${x},${y})`}>
        <line
          x1="-20"
          y1="0"
          x2="20"
          y2="0"
          stroke={isRed(chartData[index]) ? "#E35700" : "#00915B"}
          strokeWidth="3px"
        />
        {index === 0 || index === data.length - 1 ? (
          <>
            <text
              x="0"
              y="0"
              dy={16}
              textAnchor="middle"
              fill="#575757"
              style={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 10,
                fontFamily: "Inter",
                color: "#575757",
              }}
            >
              {index === 0
                ? "Baseyear"
                : index === data.length - 1
                ? "Target"
                : ""}
            </text>
            <text
              x="0"
              y="13"
              dy={16}
              textAnchor="middle"
              fill="#575757"
              style={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 10,
                fontFamily: "Inter",
                color: "#575757",
              }}
            >
              {payload.value}
            </text>
          </>
        ) : (
          <text
            x="0"
            y="0"
            dy={16}
            textAnchor="middle"
            fill="#575757"
            style={{
              fontSize: 12,
              fontWeight: 500,
              lineHeight: 10,
              fontFamily: "Inter",
              color: "#575757",
            }}
          >
            {payload.value}
          </text>
        )}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        width={500}
        height={"100%"}
        data={chartData}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="year"
          scale="auto"
          tickLine={false}
          tick={<CustomTick />}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          style={{
            fontSize: 10,
            fontWeight: 500,
            lineHeight: 10,
            fontFamily: "Inter",
            color: "#7E8999",
          }}
          tickFormatter={(val) => formatNumberIntoReadableFormat(Number(val))}
        />
        {/* <Tooltip /> */}
        {/* <Legend /> */}
        <Bar dataKey="scope1" stackId={"a"} barSize={20} fill="#BCE0FF50" />
        <Bar
          dataKey="scope2"
          stackId={"a"}
          barSize={20}
          fill="#79C1FF50"
          // radius={includeScope3 ? [0, 0, 0, 0] : [3, 3, 0, 0]}
        />
        {includeScope3 && (
          <Bar
            dataKey="scope3"
            stackId={"a"}
            barSize={20}
            fill="#FFCB1150"
            // radius={[0, 0, 0, 0]}
          />
        )}
        <Bar
          dataKey="actual_scope1"
          stackId={"b"}
          barSize={20}
          fill="#BCE0FF"
        />
        <Bar
          dataKey="actual_scope2"
          stackId={"b"}
          barSize={20}
          fill="#79C1FF"
          // radius={includeScope3 ? [0, 0, 0, 0] : [3, 3, 0, 0]}
        />
        {includeScope3 && (
          <Bar
            dataKey="actual_scope3"
            stackId={"b"}
            barSize={20}
            fill="#FFCB11"
            radius={[0, 0, 0, 0]}
          />
        )}

        <Line type="linear" dataKey="total" stroke="#7E8999" dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartMain;
