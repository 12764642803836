import React from "react";
import { Drawer } from "@mui/material";
import FillSurveyForm from "../FillSurveyForm";
function TakeSurvey(props) {
  return (
    <Drawer
      anchor={"right"}
      open={props.isTakeSurveyOpen}
      onClose={() => {
        props.handleTakeSurveyOpen();
      }}
      PaperProps={{
        sx: {
          minWidth: 1080,
          width: 1080,
          borderRadius: "5px 0px 0px 5px !important",
        },
      }}
    >
      <FillSurveyForm
        isForInternal={true}
        userId={props.userId}
        surveyId={props.takeSurveyId}
        handleClose={()=>props.handleTakeSurveyOpen()}
        getUserSurveys={props.getUserSurveys}
      />
    </Drawer>
  );
}

export default TakeSurvey;
