import React from "react";
import "../../productDetailLabel.css";
// import DownloadIcon from "../../../../../../../../../../Assets/icons/DownloadIcon";
import pc_1 from "../../../../../../../../../../Assets/images/p_c_1.png";
import pc_2 from "../../../../../../../../../../Assets/images/p_c_2.png";
import pc_3 from "../../../../../../../../../../Assets/images/p_c_3.png";
import ButtonComp from "../../../../../../../../../../Assets/components/Button";
import unoFlag from "../../../../../../../../../../Assets/images/Uno Flag.png";

function OneCard(index) {
  return (
    <div className="col-4">
      <div className="offsetMarketPlace_products_card">
        <div className="offsetMarketPlace_products_img">
          <img src={index === 0 ? pc_1 : index === 1 ? pc_2 : pc_3} />
        </div>
        <div className="offsetMarketPlace_products_cmain">
          <div className="row mx-0 ">
            <p className="white_progress_bar col-2 py-2 me-3"></p>

            <p className="white_progress_bar col-6 py-2"></p>
          </div>

          <div className="mt-32 d-flex align-items-center justify-content-between">
            <ButtonComp
              status={3}
              // className="tertiary_button_variant_1 w-100"
              style={{ width: "100%", padding: "12px 0" }}
              disabled
            >
              <p className="white_progress_bar col-10 py-2 mx-auto"></p>
            </ButtonComp>
          </div>
        </div>
      </div>
    </div>
  );
}
function LabelCards({ data }) {
  const [Cards, setCards] = React.useState([]);

  React.useEffect(() => {
    if (data && data.length === 0) {
      let cards = [];
      for (let i = 0; i < 3; i++) {
        cards.push(OneCard(i));
      }
      setCards(cards);
    }
  }, [data]);

  return (
    <div className="row">
      {data && data.length > 0
        ? data.map((labelData) => (
            <div className="col-md-3" key={labelData.id}>
              <div className="productDetailLabel_cardDiv  d-flex flex-column container_design_1 p-0">
                <div className="position-relative">
                  <img
                    className="productDetailLabel_cardImg"
                    src={labelData.image.url}
                  />
                  {labelData.unsdg && labelData.unsdg.length > 0 && (
                    <div className="offsetMarketPlace_sdg_logo body_text_m">
                      <img src={unoFlag} alt="unoSDG" />{" "}
                      {labelData.unsdg.length}
                    </div>
                  )}
                </div>

                <div className="p-16">
                  <div className="d-flex align-items-center">
                    <span className="heading_6 color_ba text_capital">
                      {labelData.offset_classification}
                    </span>
                    <span className="heading_6 fw-600 color_main_blue ml-6">
                      {labelData.county}
                    </span>
                    <span className="round_dot_sm bg_blue ml-6"></span>
                    <span className="heading_6 fw-600 color_main_blue ml-6">
                      {labelData.year}
                    </span>
                  </div>
                  <p className="card_heading mt-10">{labelData.name}</p>
                  <p className="body_text_s fw-400 mt-10">
                    {labelData.short_description}
                  </p>
                  {/* <button className="secondary_button_variant_1 p-60x-20y mt-10 w-100">
                    Project Data
                    <span className="ml-16">
                      <DownloadIcon cc={"#000"} />
                    </span>
                  </button> */}
                </div>
              </div>
            </div>
          ))
        : Cards}
    </div>
  );
}

export default LabelCards;
