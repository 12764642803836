import React from "react";

export default function Text() {
  return (
    <div className="col-12">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="154"
        height="12"
        fill="none"
        viewBox="0 0 154 12"
      >
        <rect
          width="153.312"
          height="10.43"
          x="0.297"
          y="0.586"
          fill="#F7F7F7"
          rx="5.215"
        ></rect>
      </svg>
    </div>
  );
}
