import React from "react";

const PlusIcon = ({ onClick, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      className={className + " pointer"}
      onClick={onClick}
    >
      <circle cx="15" cy="15" r="15" fill="#fff"></circle>
      <circle cx="15" cy="15" r="14" fill="#F6F6F6"></circle>
      <path
        stroke="#00915B"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M10 15h10M15 10v10"
      ></path>
    </svg>
  );
};

export default PlusIcon;
