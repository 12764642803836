import React, { useEffect, useState } from "react";
import LogActivity from "./Charts/LogActivity";
import OffsetCompensated from "./Charts/OffsetCompensated";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
import axios from "../../../../Api";
import { getServiceIcon } from "../../../../Utils/itemIcon";
import OffsetTypeBarChart from "./Charts/offsetTypeBarChart";
import LogActivityPlaceholder from "../../../CommonComponents/Placeholders/LogActivityPlaceholder";
import OffsetCompensatedPlaceholder from "../../../CommonComponents/Placeholders/OffsetCompensatedPlaceholder";
import OffsetTypeBarChartPlaceholder from "../../../CommonComponents/Placeholders/offsetTypeBarChartPlaceholder";
import { FormControl, MenuItem, Select, Skeleton } from "@mui/material";
import green_dot from "../../../../Assets/images/green_dot.svg";
import orange_dot from "../../../../Assets/images/orange_dot.svg";
import { monthNames } from "../../../../Utils/CommonFunctions";

const DataMonitor = () => {
  const { setSessionExpire } = useAuth();
  const [logData, setLogData] = useState([]);
  const [logDataLoading, setLogDataLoading] = useState(true);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [offSetEmission, setOffSetEmission] = useState([]);
  const [offSetEmissionLoading, setOffSetEmissionLoading] = useState(true);
  const [dataCompletionIndex, setDataCompletionIndex] = useState(0);
  const [filterLogCompData, setFilterLogCompData] = useState([]);
  const [offsetFeed, setoffsetFeed] = useState([]);
  const [offsetFeedLoading, setoffsetFeedLoading] = useState(true);

  useEffect(() => {
    getLogCompletionData(dataCompletionIndex);
  }, [dataCompletionIndex]);

  const getAccountingItems = async () => {
    try {
      await axios.get(`/log/logged-accounting-items`).then((res) => {
        setYears(res.data.accountingItemYears);
      });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  useEffect(() => {
    getAccountingItems();
  }, []);
  const getLogCompletionData = async (month) => {
    try {
      await axios
        .get(
          `/analytics/log-completion?year=${new Date().getFullYear()}&month=${month}`
        )
        .then((res) => {
          if (res.data.success) {
            setFilterLogCompData(res.data.data);
          }
        });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const getOffSetData = async () => {
    await axios
      .get(`/analytics/offset-status?year=${selectedYear}`)
      .then((res) => {
        let currOffSetData = [];

        currOffSetData.push({
          name: "NonOffset",
          value: res.data.nonOffsetsEmission,
        });
        currOffSetData.push({
          name: "Offset",
          value: res.data.offsetEmission,
        });
        setOffSetEmissionLoading(false);
        setOffSetEmission(currOffSetData);
      });
  };
  const getOffsetFeed = async () => {
    try {
      let res = await axios.get(`/analytics/offset-type?year=${selectedYear}`);
      if (res.data.success) {
        setoffsetFeedLoading(false);
        let currKeys = Object.keys(res.data);
        currKeys = currKeys.filter((currItem) => {
          if (currItem != "success") return currItem;
        });
        setoffsetFeed([
          {
            name: currKeys[0],
            amount: res.data[currKeys[0]],
          },
          {
            name: currKeys[1],
            amount: res.data[currKeys[1]],
          },
        ]);
      }
    } catch (error) {
      setoffsetFeedLoading(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  const getTotalEmissions = async () => {
    await axios({
      url: `/analytics/total-emission-data`,
      method: "get",
      params: {
        year: selectedYear,
      },
    })
      .then((res) => {
        const { AllLogData } = res.data;
        setLogData(AllLogData);
        setLogDataLoading(false);
        // const { totalEmission, comparedEmissions, totalLogs } = res.data;
        // setEmissionData({
        //   totalEmission: totalEmission,
        //   comparedEmission: comparedEmissions,
        //   totalAccount: totalLogs,
        // });
      })
      .catch((err) => {
        setLogDataLoading(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getTotalEmissions();
    getOffSetData();
    getOffsetFeed();
  }, [selectedYear]);

  return (
    <div className="main_box mb-40">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font_17_700 text-uppercase">Log activity</p>
        <FormControl sx={{ minWidth: 145 }} size="small">
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="reduction_target_root mt-20 p-0 pt-10">
        {logDataLoading ? (
          <LogActivity data={logData} />
        ) : logData.every((val) => val.count === 0) ? (
          <LogActivityPlaceholder />
        ) : (
          <LogActivity data={logData} />
        )}

        <div className="emission-overview-footer d-flex align-items-center">
          <div className="d-flex align-items-center">
            <span className="span_cirlce bg_blue mr-8"></span>
            <p className="overline_text">Logs</p>
          </div>
          <div className="d-flex align-items-center ml-16">
            <span className="span_cirlce bg_blue_scop1 mr-8"></span>
            <p className="overline_text">Logs last year</p>
          </div>
        </div>
      </div>
      <div className="mt-56">
        <p className="font_17_700 text-uppercase">Log data completion</p>
        <div className="reduction_target_root p-0 mt-20">
          <div className="row mx-0 flex-wrap align-items-center pt-16 pb-16 pl-16 pr-16 border-btm_ef justify-content-start">
            <button
              onClick={() => setDataCompletionIndex(0)}
              className="button_style_none fit-w"
            >
              <p
                className={`neutral_label f-14 fw-400 ml-2 mt-10 ${
                  dataCompletionIndex === 0 ? "card_selection_border" : ""
                }
            `}
              >
                {new Date().getFullYear()}
              </p>
            </button>

            {monthNames.map((month, index) => {
              return (
                <button
                  onClick={() => setDataCompletionIndex(index + 1)}
                  key={month}
                  className="button_style_none fit-w"
                >
                  <p
                    className={`neutral_label ml-2 mt-10 f-14 fw-400
                  ${
                    dataCompletionIndex === index + 1
                      ? "card_selection_border"
                      : ""
                  }
                  `}
                  >
                    {month}
                  </p>
                </button>
              );
            })}
          </div>
          <div className="mt-20 mb-20 d-flex flex-wrap">
            {filterLogCompData.length > 0 ? (
              filterLogCompData.map((log, index) => (
                <div
                  key={index}
                  className="neutral_label ml-32 mt-10 f-14 fw-400 d-flex align-items-center "
                >
                  {log.isLogged ? (
                    <img src={green_dot} />
                  ) : (
                    <img src={orange_dot} />
                  )}
                  <img
                    src={getServiceIcon(log.accountItem)}
                    className="icon_sizing ml-12"
                  />

                  <p className="ml-6">{log.name}</p>
                </div>
              ))
            ) : (
              <div className="neutral_label fw-400 ml-20 body_text_m d-flex align-items-center">
                <img src={green_dot} />
                <Skeleton
                  sx={{
                    bgcolor: "#f7f7f7",
                    minHeight: "24px",
                    width: 190,
                    borderRadius: "100px",
                    marginLeft: "8px",
                  }}
                  animation="wave"
                />
              </div>
            )}
            {}
          </div>
          <div className="emission-overview-footer d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="span_cirlce bg_green mr-8"></span>
              <p className="overline_text">Complete</p>
            </div>
            <div className="d-flex align-items-center ml-16">
              <span className="span_cirlce bg_red mr-8"></span>
              <p className="overline_text">Incomplete</p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-56">
        <p className="font_17_700 text-uppercase">Contributions</p>
        <div className="reduction_target_root mt-20">
          <div className="row">
            <div className="col-6">
              <p className="card_heading">
                Compensated vs. Uncompensated Emissions
              </p>
              <div className="row mx-0 align-items-center mt-24">
                <div className="col-7">
                  {offSetEmissionLoading ? null : offSetEmission.every(
                      (val) => val.value === 0
                    ) ? (
                    <OffsetCompensatedPlaceholder />
                  ) : (
                    <OffsetCompensated data={offSetEmission} />
                  )}
                </div>
                <div className="col-5">
                  <div className="reduction_target_root pb-12 pt-12 pr-32 pr-8">
                    <p>
                      <span className="heading_lg2">
                        {offSetEmission[1]
                          ? offSetEmission[1].value.toFixed(2).toLocaleString()
                          : 0}
                      </span>
                      <span className="body_text_sm color_black"> tCO₂e</span>
                    </p>
                    <div
                      className={"scope_tabLine mt-8 bg_green"}
                      style={{ width: "75%" }}
                    ></div>
                    <p className="overline_text color_black mt-12">
                      Compensated Emissions
                    </p>
                  </div>
                  <div className="reduction_target_root pb-12 pt-12 pr-32 pr-8 mt-20">
                    <p>
                      <span className="heading_lg2">
                        {offSetEmission[0]
                          ? offSetEmission[0].value.toFixed(2).toLocaleString()
                          : 0}
                      </span>
                      <span className="body_text_sm color_black">tCO₂e</span>
                    </p>
                    <div
                      className={"scope_tabLine mt-8 bg_soft_green"}
                      style={{ width: "75%" }}
                    ></div>
                    <p className="overline_text color_black mt-12">
                      Uncompensated Emissions
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div>
                <p className="card_heading">Contribution Types</p>
              </div>
              <div className="row mt-24">
                <div className="col-5">
                  {offsetFeedLoading ? null : offsetFeed.every(
                      (val) => val.amount === 0
                    ) ? (
                    <OffsetTypeBarChartPlaceholder />
                  ) : (
                    <OffsetTypeBarChart data={offsetFeed} />
                  )}
                </div>
                <div className="col-7 pt-16">
                  <div className="card_design_2 d-flex justify-content-between mt-14 mr-50 pt-12 pl-8 pr-20 pb-20">
                    <div>
                      <p className="font_30_700 color_black">
                        {offsetFeed &&
                          offsetFeed[0] &&
                          offsetFeed[0].amount + "%"}
                      </p>
                      <div
                        className={"scope_tabLine mt-8 bg_main_blue"}
                        //style={{ width: "75%" }}
                      ></div>
                      <p className="overline_text color_black mt-16">
                        Removal Credits
                      </p>
                    </div>
                    <div className="d-flex align-items-end">
                      <p className="neutral_label">
                        {offsetFeed && offsetFeed[0] && offsetFeed[0].amount
                          ? offsetFeed[0].amount
                          : 0}{" "}
                        tCO₂e
                      </p>
                    </div>
                  </div>

                  <div className="card_design_2 d-flex justify-content-between mt-14 mr-50 pt-12 pl-8 pr-20 pb-20">
                    <div>
                      <p className="font_30_700 color_black">
                        {offsetFeed &&
                          offsetFeed[1] &&
                          offsetFeed[1].amount + "%"}
                      </p>
                      <div
                        className={"scope_tabLine mt-8 bg_soft_blue"}
                        //style={{ width: "75%" }}
                      ></div>
                      <p className="overline_text color_black mt-16">
                        Avoidance Credits
                      </p>
                    </div>
                    <div className="d-flex align-items-end">
                      <p className="neutral_label">
                        {offsetFeed && offsetFeed[1] && offsetFeed[1].amount
                          ? offsetFeed[1].amount
                          : 0}{" "}
                        tCO₂e
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataMonitor;
