import React, { useState } from "react";
import {
  AnimationScreens,
  AnimationVideos,
} from "../../../../../Utils/CommonFunctions";
import { useNavigate } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import IntroScreen from "./Steps/IntroScreen";
import Question from "./Steps/Question";

const Assessment = ({
  setResultState,
  CreateAssessment,
  assessmentQuestion,
  setAssessmentQuestion,
}) => {
  const [step, setStep] = useState(0);
  const [currentQuestionNo, setCurrentQuestionNo] = useState(0);
  const navigate = useNavigate();

  const [videos] = useState([
    AnimationVideos[0],
    AnimationVideos[1],
    AnimationVideos[2],
    AnimationVideos[3],
    AnimationVideos[4],
    AnimationVideos[5],
    AnimationVideos[6],
    AnimationVideos[7],
  ]);
  const [screens] = useState([
    AnimationScreens[0],
    AnimationScreens[1],
    AnimationScreens[2],
    AnimationScreens[3],
    AnimationScreens[4],
    AnimationScreens[5],
    AnimationScreens[6],
    AnimationScreens[7],
  ]);
  return (
    <div className="csrd_assessment_root">
      <div className="csrd_assessment_lefts">
        <div className="csrd_assessment_navigate d-flex align-items-center">
          <p
            className="breadcrumb_text_light_1 pointer"
            onClick={() => navigate("/dashboard?tab=6")}
          >
            <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
            Share
          </p>
          <p className="breadcrumb_text_light_1 ml-6 mr-6">/</p>
          <p
            className="breadcrumb_text_light_1 pointer"
            onClick={() => navigate("/dashboard?tab=6&subtab=0&step=0")}
          >
            reports
          </p>
          <p className="breadcrumb_text_light_1 ml-6 mr-6">/</p>
          <p className="breadcrumb_text_bold_1">CSRD ASSESSMENT</p>
        </div>
        <p className="font_24_600 mt-20 ml-64">CSRD Assessment</p>
        <div
          style={{
            backgroundImage: `linear-gradient(to bottom, #297ed9, #2f86dc, #378edf, #4095e2, #499de5, #55a3e6, #60a9e8, #6bafe9, #79b5ea, #87bbeb, #94c1eb, #a0c7ec)`,
          }}
          className="mt-32 csrd_assessment_left_bg"
        >
          <video
            className="csrd_vdo"
            src={step === 0 ? videos[0] : videos[currentQuestionNo + 1]}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
            controls={false}
            poster={step === 0 ? screens[0] : screens[currentQuestionNo + 1]}
          ></video>
        </div>
      </div>
      <div className="csrd_assessment_rightScreen">
        {step === 0 ? (
          <IntroScreen setStep={setStep} step={step} />
        ) : (
          <>
            <div className="csrd_assessment_stepper">
              <div
                className="csrd_assessment_stepper_innter"
                style={{
                  width: (100 * (currentQuestionNo + 1)) / 7 + "%",
                }}
              >
                <div className="csrd_assessment_stepper_qn">
                  <p className="body_text_m">{currentQuestionNo + 1}/7</p>
                </div>
              </div>
            </div>
            <Question
              currentQuestionNo={currentQuestionNo}
              setCurrentQuestionNo={setCurrentQuestionNo}
              question={assessmentQuestion[currentQuestionNo]}
              setAssessmentQuestion={setAssessmentQuestion}
              assessmentQuestion={assessmentQuestion}
              setStep={setStep}
              step={step}
              CreateAssessment={CreateAssessment}
              setResultState={setResultState}
            />
          </>
        )}
      </div>
      <div hidden>
        {videos.map((v, i) => (
          <video
            key={i}
            className="reg_vdo"
            src={v}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
          ></video>
        ))}
      </div>
    </div>
  );
};

export default Assessment;
