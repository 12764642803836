import React from "react";

const CloseWIthRedCross = ({onClick, className}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      className={className}
      onClick={onClick}
    >
      <circle cx="15" cy="15" r="15" fill="#fff"></circle>
      <circle cx="15" cy="15" r="14" fill="#F6F6F6"></circle>
      <path
        stroke="#F45555"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11.464 18.535l7.071-7.07M11.465 11.464l7.07 7.071"
      ></path>
    </svg>
  );
};

export default CloseWIthRedCross;
