const getOffsetStatus = (row) => {
  if (row) {
    if (!row.isOffsetActivate) {
      return { title: "Inactive carbon neutral label", class: "grey_label" };
    } else {
      return { title: "No contribution required", class: "green_label" };
    }
  } else return { title: "Inactive carbon neutral label", class: "grey_label" };
};

export default getOffsetStatus;
