import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  TextField,
  FormControl,
  Select,
  MenuItem,
  styled,
  OutlinedInput,
} from "@mui/material";
import likertScaleList from "../../../../../../../../Utils/likertScale.json";
import { DragIndicatorOutlined } from "@mui/icons-material";

const BootstrapInput = styled(OutlinedInput)(() => ({
  "& .MuiInputBase-input": {
    fontFamily: "var(--inter-font)",
    fontWeight: 400,
    fontSize: "13px",
    borderRadius: "5px",
    height: "40px !important",
    marginTop: "auto",
    padding: "0px 5px",
    display: "flex",
    alignItems: "center",
    border: "0",
    backgroundColor: "#F6F6F6",
    color: "#727E8F",
  },
}));
const SurveyQuestion = (props) => {
  const [options, setOptions] = useState([]);
  const [linkertValue, setLinkertValue] = useState("Agreement");

  useEffect(() => {
    setOptions(props.questionDetails["options"]);
  }, [props.questionDetails]);

  const removeMulOptions = (id, index) => {
    let currOptions = [...options];
    currOptions.splice(index, 1);
    setOptions(currOptions);
    props.removeMulOptions(id, index);
  };
  const addMulOptions = (id) => {
    let currOptions = [...options];
    currOptions.push("");
    props.addMulOptions(id);
    setOptions(currOptions);
  };
  const editMulOption = (id, index, value) => {
    let currOption = [...options];
    currOption[index] = value;
    props.editMulOption(id, index, value);
    setOptions(currOption);
  };
  return (
    <div
      draggable={true}
      id={props.id}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={props.handleDrag}
      onDrop={props.handleDrop}
      className="border-d7 border_radius_5 pb-40 mt-20"
    >
      <div className="d-flex justify-content-between ml-24 mt-28 mr-24 ">
        <p className="font_13_400 color_4c d-flex align-items-center">
          <DragIndicatorOutlined
            htmlColor="#4c4c4c"
            fontSize="small"
            sx={{ height: 14, width: 14, marginRight: "8px" }}
          />
          Q #{props.order + 1}
        </p>

        <div
          className="pointer"
          onClick={() => {
            props.removeQuestion(props.id);
          }}
        >
          <CloseIcon />
        </div>
      </div>

      <p className="font_13_500 color_72 mt-30 ml-24">Question Type</p>

      <div className="d-flex mt-20 ml-24 mr-24">
        <div
          onClick={() => props.selectQuestionType(0, props.questionDetails.id)}
          className="w-50 pointer d-flex border-d7 border_radius_5 pl-12 pt-10 pb-10"
        >
          {props.questionDetails && props.questionDetails.type == 0 ? (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_green_07">
              <i className="bi bi-check-lg color_white"></i>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_d9">
              {/* <i className="bi bi-check-lg color_white"></i> */}
            </div>
          )}
          <div className="flex-grow-1 d-flex justify-content-center align-items-center">
            <p className="font_14_400"> Multiple Choice</p>
          </div>
        </div>

        <div
          onClick={() => props.selectQuestionType(1, props.questionDetails.id)}
          className="w-50 pointer ml-20 d-flex border-d7 border_radius_5 pl-12 pt-10 pb-10"
        >
          {props.questionDetails && props.questionDetails.type == 1 ? (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_green_07">
              <i className="bi bi-check-lg color_white"></i>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_d9">
              {/* <i className="bi bi-check-lg color_white"></i> */}
            </div>
          )}

          <div className="flex-grow-1 d-flex justify-content-center align-items-center">
            <p className="font_14_400"> Open Ended Question</p>
          </div>
        </div>
      </div>

      <div className="d-flex mt-20 ml-24 mr-24">
        <div
          onClick={() => props.selectQuestionType(2, props.questionDetails.id)}
          className="w-50 pointer d-flex border-d7 border_radius_5 pl-12 pt-10 pb-10"
        >
          {props.questionDetails && props.questionDetails.type == 2 ? (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_green_07">
              <i className="bi bi-check-lg color_white"></i>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_d9">
              {/* <i className="bi bi-check-lg color_white"></i> */}
            </div>
          )}

          <div className="flex-grow-1 d-flex justify-content-center align-items-center">
            <p className="font_14_400"> Rating Scale</p>
          </div>
        </div>

        <div
          onClick={() => props.selectQuestionType(3, props.questionDetails.id)}
          className="w-50 pointer ml-20 d-flex border-d7 border_radius_5 pl-12 pt-10 pb-10"
        >
          {props.questionDetails && props.questionDetails.type == 3 ? (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_green_07">
              <i className="bi bi-check-lg color_white"></i>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center br_round h-30px w-30px bg_d9">
              {/* <i className="bi bi-check-lg color_white"></i> */}
            </div>
          )}

          <div className="flex-grow-1 d-flex justify-content-center align-items-center">
            <p className="font_14_400"> Likert Scale</p>
          </div>
        </div>
      </div>

      <div className="createSurvey_question_container mt-20 ml-24 mr-24">
        <p className="font_13_500 color_72">Question</p>

        <TextField
          onChange={(e) => {
            props.handleQuestionChange(
              props.questionDetails.id,
              e.target.value
            );
          }}
          value={props.questionDetails.question}
          className="font_13_500 color_72 mt-12"
          size="small"
          fullWidth
        />
      </div>

      <div className="mt-30 ml-24 mr-24">
        {props.questionDetails && props.questionDetails.type == 0 ? (
          <p className="font_13_500 color_72">Answer</p>
        ) : null}

        {props.questionDetails &&
          props.questionDetails.type === 0 &&
          options.map((currOption, index) => {
            return (
              <div key={index} className="d-flex mt-12">
                <div
                  onClick={() => {
                    removeMulOptions(props.questionDetails.id, index);
                  }}
                  className="d-flex justify-content-center align-items-center bg_f6 h-40px w-40px border_radius_5"
                >
                  <p>
                    <i className="bi bi-dash-lg color_red"></i>
                  </p>
                </div>

                <div className="flex-grow-1 createSurvey_question_container ml-6">
                  <TextField
                    value={currOption}
                    onChange={(e) => {
                      editMulOption(
                        props.questionDetails.id,
                        index,
                        e.target.value
                      );
                    }}
                    className="font_13_500 color_72"
                    size="small"
                    fullWidth
                  />
                </div>
              </div>
            );
          })}
        {props.questionDetails && props.questionDetails.type === 0 && (
          <div className="d-flex mt-12">
            <div
              onClick={() => {
                addMulOptions(props.questionDetails.id);
              }}
              className="d-flex justify-content-center align-items-center bg_f6 h-40px w-40px border_radius_5"
            >
              <p>
                <i className="bi bi-plus-lg color_green"></i>
              </p>
            </div>
          </div>
        )}
        {props.questionDetails && props.questionDetails.type === 2 && (
          <div className="mt-30">
            <p className="font_13_500 color_72">Scale</p>

            <div className="d-flex align-items-center mt-12">
              <div className="d-flex justify-content-center align-items-center bg_f6 h-40px w-50px border_radius_5">
                <p className="font_13_500 color_72">1</p>
              </div>

              <p className="font_13_500 color_72 ml-8">to</p>
              {/* <div className="d-flex justify-content-center align-items-center bg_f6 h-40px w-40px ml-8">
                  <p className="font_13_500 color_72">10</p>
                </div> */}

              <div className="createSurvey_question_container ml-8 border_radius_5">
                <TextField
                  value={props.questionDetails.scaleEnd}
                  onChange={(e) => {
                    props.handleQuestionRatingChange(
                      props.questionDetails.id,
                      e.target.value
                    );
                  }}
                  className="w-50px border_radius_5"
                  size="small"
                  sx={{
                    "& .MuiInputBase-input": {
                      fontSize: "13px",
                      fontWeight: "500",
                      lineHeight: "10px",
                      fontFamily: "Inter",
                      textAlign: "center",
                    },
                  }}
                />
              </div>
              {parseInt(props.questionDetails.scaleEnd) > 10 ||
              parseInt(props.questionDetails.scaleEnd) < 5 ? (
                <p className="font_13_500 color_f4 ml-24">
                  Invalid entry. Please enter a number between 5 and 10.{" "}
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {props.questionDetails && props.questionDetails.type === 3 && (
          <div className="d-flex mt-30">
            <div className="addParticipants_select_container flex-grow-0">
              <p className="font_13_600 color_72">Likert Scale Type</p>

              <FormControl
                sx={{
                  m: "4px 0",
                  width: "150px",
                  backgroundColor: "#f6f6f6",
                  //  border: "1px solid #efefef",
                  borderRadius: "5px",
                  "& .MuiSelect-select:focus": {
                    backgroundColor: "#f6f6f6 !important",
                  },
                }}
                className="mt-12"
              >
                <Select
                  // displayEmpty
                  value={linkertValue}
                  input={<BootstrapInput />}
                  onChange={(e) => {
                    setLinkertValue(e.target.value);
                    props.addLikertScaleOptions(
                      props.questionDetails.id,
                      likertScaleList[e.target.value]["options"]
                    );
                  }}
                  renderValue={(selected) => {
                    return selected ? selected : "Agreement";
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 150,
                        width: 150,
                        borderRadius: "5px",
                        border: "1px solid #EDEDED",
                        background: "#FFFFFF",
                        boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
                      },
                    },
                    MenuListProps: {
                      style: { padding: "0px" },
                    },
                  }}
                >
                  {Object.keys(likertScaleList).map((currOption, i) => {
                    return (
                      <MenuItem
                        key={currOption}
                        className="color_72"
                        value={currOption}
                        sx={{
                          borderBottom:
                            i === Object.keys(likertScaleList).length - 1
                              ? "none"
                              : "1px solid #efefef",
                          color: "#727E8F",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "10px",
                          padding: "13px 10px",
                        }}
                      >
                        {currOption}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </div>
            <div className="ml-30">
              {props.questionDetails["options"] && (
                <>
                  <p className="font_13_600 color_72">Response Values</p>
                  <div className="w-210px bg_f6 mt-12 pb-10 pt-6 border_radius_5">
                    {likertScaleList[linkertValue]["options"] &&
                      likertScaleList[linkertValue]["options"].map(
                        (currOption) => {
                          return (
                            <div
                              key={currOption}
                              className="d-flex align-items-center mt-10 gap_10 ml-10"
                            >
                              <p className="span_cirlce h-14px w-14px border-ef bg_white"></p>
                              <p className="font_13_500 color_72">
                                {currOption}
                              </p>
                            </div>
                          );
                        }
                      )}
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SurveyQuestion;
