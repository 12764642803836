import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import CustomToolTip from "./customToolTip";
function NumberInput(props) {
  const [currVal, setCurrVal] = useState("");
  function isNum(v) {
    return /^\d+$/.test(v);
  }
  useEffect(() => {
    if (isNum(currVal) || currVal.length == 0)
      props.setAnswers((prev) => {
        return {
          ...prev,
          [props.questionNo]: currVal,
        };
      });
    //props.handleAnswerChange(currVal, props.questionNo)
  }, [currVal]);
  useEffect(() => {
    if (props.defaultValue) setCurrVal(props.defaultValue);
  }, []);
  const handleChange = (value) => {
    if (isNum(value) || value.length == 0) {
      //  if(parseInt(value)<=100 || value.length==0)
      setCurrVal(value);
      // }
    }
  };
  return (
    <>
      <div className="d-flex align-items-center">
        <p className="sr-only subheading_1 mb-8">
          {props.title}{" "}
          {props.unit && (
            <span className="text_lowerCase">({props.unit}) </span>
          )}
        </p>
        {props.description && props.description.length > 0 ? (
          <CustomToolTip title={props.description} />
        ) : (
          ""
        )}
      </div>
      <TextField
        value={currVal}
        placeholder={props.placeholder}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        id="demo-helper-text-aligned"
        size="small"
        sx={{
          border: "1px solid #efefef",
          borderRadius: 1,
          boxShadow: "none",
          outline: "none",
          "& fieldset": { border: "none" },
          "& .MuiInputBase-root": {
            border: "none !important",
          },
        }}
        className={`form-control shadow-none  SideDrawer_input mt-1 sideDrawer_input_number ${
          props.percentage
            ? "sideDrawer_input_symbol"
            : props.unit
            ? "sideDrawer_input_symbol_m2"
            : ""
        }`}
        inputProps={{
          style: {
            height: "27px",
          },
        }}
      />
      <p className="helper_text fw-400 mt-10">{props.helperText}</p>
    </>
  );
}

export default NumberInput;
