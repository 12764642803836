import { Close } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import UserPictureUpload from "./UserPictureUpload";
import { useAuth } from "../../../../Utils/Auth";
import { handleSaveData } from "./CommonFunctions";
import toast from "react-hot-toast";
import QuestionMarkCircle from "../../../../Assets/icons/QuestionMarkCircle";

const WhyWeManageModal = ({ handleClose, getCompanyData, companyData }) => {
  const [whyManageCarbon, setWhyManageCarbon] = useState("");
  const [userPicture, setUserPicture] = useState("");
  const [userFirstname, setUserFirstname] = useState("");
  const [userLastname, setUserLastname] = useState("");
  const [userJobtitle, setUserJobtitle] = useState("");
  const { setSessionExpire } = useAuth();
  // const [textareaHeight, setTextareaHeight] = useState(40);
  const textareaRef = useRef();
  useEffect(() => {
    if (companyData) {
      setWhyManageCarbon(companyData.whyManageCarbon);
      setUserPicture(companyData.userPicture);
      setUserFirstname(companyData.userFirstname);
      setUserLastname(companyData.userLastname);
      setUserJobtitle(companyData.userJobtitle);
    }
  }, [companyData, textareaRef]);

  const handleChange = () => {
    if (whyManageCarbon.length > 600) {
      return toast.error("Description should be less than 600 characters");
    }
    const data = {
      whyManageCarbon,
      userPicture,
      userFirstname,
      userLastname,
      userJobtitle,
    };
    handleSaveData(data, getCompanyData, handleClose, setSessionExpire);
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textareaRef) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [whyManageCarbon]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Enter key was pressed, adjust the height
      adjustTextareaHeight();
    }
  };

  const handleCut = () => {
    // Text is being cut (e.g., via Ctrl+X), adjust the height
    setTimeout(adjustTextareaHeight, 0);
  };

  return (
    <div className="row_20_gap">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font_16_500">
          Why we manage our carbon <QuestionMarkCircle className={"ml-20"} />
          {/* <HelpOutlined
            htmlColor="#7E8999"
            fontSize="small"
            className="ml-10"
            sx={{ height: 14, width: 14 }}
          /> */}
        </p>
        <Close className="pointer" onClick={handleClose} />
      </div>
      <div className="mt-20 ">
        <div className="d-flex align-items-center justify-content-between">
          <p className="font_12_600 color_7e">
            Tell us about your cabon management. What should others know about
            it?
          </p>
          <p
            className={
              "font_12_600 " +
              (whyManageCarbon?.length > 600 ? "color_red" : "color_7e")
            }
          >
            {whyManageCarbon?.length}/600
          </p>
        </div>
        <textarea
          style={{
            height: 40,
            padding: 10,
            maxHeight: 65,
            minHeight: 40,
          }}
          ref={textareaRef}
          className="mt-10 share_transpancy_input font_14_400"
          value={whyManageCarbon}
          onChange={(e) => {
            setWhyManageCarbon(e.target.value);
          }}
          onCut={handleCut}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
        />
        {/* <input type="text" /> */}
      </div>
      <div className="mt-30 row">
        <div style={{ width: "fit-content" }}>
          <p className="font_12_600 color_7e width_fit">Picture</p>
          <UserPictureUpload avatar={userPicture} setAvatar={setUserPicture} />
        </div>
        <div className="col">
          <div>
            <p className="font_12_600 color_7e">First Name</p>
            <input
              type="text"
              className="mt-10 share_transpancy_input font_14_400"
              value={userFirstname}
              onChange={(e) => setUserFirstname(e.target.value)}
            />
          </div>
        </div>
        <div className="col">
          <div>
            <p className="font_12_600 color_7e">Last Name</p>
            <input
              type="text"
              className="mt-10 share_transpancy_input font_14_400"
              value={userLastname}
              onChange={(e) => setUserLastname(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-30 ">
        <p className="font_12_600 color_7e">Job title</p>
        <input
          type="text"
          className="mt-10 share_transpancy_input font_14_400"
          value={userJobtitle}
          onChange={(e) => setUserJobtitle(e.target.value)}
        />
      </div>
      <div className="mt-20 d-flex justify-content-end">
        <ButtonComp status={5} onClick={handleChange}>
          Save
        </ButtonComp>
      </div>
    </div>
  );
};

export default WhyWeManageModal;
