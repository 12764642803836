import { Box, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TabLabels from "../LogDashboard/Components/TabLabels";
//import { useAuth } from "../../../Utils/Auth";
import ButtonComp from "../../../Assets/components/Button";
import KeyboardForwardIcon from "../../../Assets/icons/KeyboardForwardIcon";
import Reports from "./Reports/Reports";
import Labels from "./Labels/Labels";
import CSRDAssessment from "./Reports/CSRDAssessment";
import "./index.css";
import useQuery from "../../../Utils/query";
import CreateGHGReport from "./Reports/Components/CreateGHGReport/index";
import { useNavigate } from "react-router-dom";
import Surveys from "./Surveys";
import TransparencyDirectory from "./TransparencyDirectory";

// import { notifedFeatures } from "../../../Utils/CommonFunctions";
// import TransparencyDirectory from "./TransparencyDirectory";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Share = () => {
  const [value, setValue] = useState(0);
  const [step, setStep] = useState(0);
  // const { user } = useAuth();
  const query = useQuery();
  const reportNo = Number(query.get("report"));
  const currReportStep = Number(query.get("step"));
  const subtab = Number(query.get("subtab"));
  const navigate = useNavigate();

  useEffect(() => {
    if (currReportStep) {
      setStep(currReportStep);
    } else {
      setStep(0);
    }
  }, [currReportStep]);

  useEffect(() => {
    if (subtab) {
      setValue(subtab);
    } else {
      setValue(0);
    }
  }, [subtab]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/dashboard?tab=6&subtab=${newValue}`);
  };
  return (
    <>
      {reportNo == 1 && step === 1 ? (
        <CreateGHGReport />
      ) : step === 2 ? (
        <CSRDAssessment />
      ) : (
        <div className="offset_root ml-140 mr-50">
          <p className="offsetProjectTitle mt-40">Share</p>
          <div className="d-flex justify-content-end">
            <ButtonComp
              status={3}
              onClick={() => {
                navigate(`/dashboard?tab=6&step=2`);
                setStep(2);
              }}
            >
              Take CSRD Assessment
              <KeyboardForwardIcon className={"ml-8"} cc={"#3574E0"} />
            </ButtonComp>
          </div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="general_root_tabs"
              >
                {/* <Tab
              label={<TabLabels title={"ESG Consultants"} />}
              {...a11yProps(0)}
            /> */}
                <Tab
                  label={<TabLabels title={"Reports"} />}
                  {...a11yProps(0)}
                />
                <Tab label={<TabLabels title={"Labels"} />} {...a11yProps(1)} />
                <Tab
                  label={<TabLabels title={"Surveys"} />}
                  {...a11yProps(2)}
                />
                <Tab
                  label={<TabLabels title={"Transparency Directory"} />}
                  {...a11yProps(3)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Reports setStep={setStep} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Labels />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Surveys />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <TransparencyDirectory />
            </TabPanel>
          </Box>
        </div>
      )}
    </>
  );
};

export default Share;
