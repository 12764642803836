import React from "react";
import { useAuth } from "../../../../Utils/Auth";
import { botAvatarGroup } from "../../../../Utils/botAvatarsGroup";
import { useNavigate } from "react-router-dom";

export default function EmptyActiveOffsets({ isActiveTab }) {
  const { user } = useAuth();
  const navigate = useNavigate();

  return (
    <div
      className="no-data-root d-flex justify-content-between p-32 mt-40"
      style={{ position: "relative" }}
    >
      <div className="d-flex justify-content-between flex-column">
        <div>
          <p className="heading_lg">
            Support handpicked projects worldwide! 🌳
          </p>

          <p className="subheading_light mt-16 w-65">
            Welcome to our contribution section. Explore our marketplace to find
            projects and carbon contribution portfolios tailored for
            compensating your emissions. By investing, you can reduce or even
            capture carbon from the atmosphere. Get started by activating the
            log items.
          </p>
        </div>
        <div className=" pt-24 mt-24">
          <button
            className="primary_button_md width_fit"
            onClick={() => {
              if (isActiveTab) {
                navigate(`/dashboard?tab=5&subtab=1&activeTab=1`);
              } else {
                navigate(`/dashboard?tab=3`);
              }
            }}
          >
            Activate Items
          </button>
        </div>
      </div>

      <div style={{ zIndex: 1 }}>
        <img
          src={botAvatarGroup[user?.bot_avatar_id]?.targetPlaceholder?.url}
          alt="launching"
          style={{ maxHeight: 250, width: "auto" }}
        />
      </div>
      <hr className="bg_divider" />
    </div>
  );
}
