import React from "react";
import useQuery from "../../../../Utils/query";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../../../../Assets/components/Button";

const LearnMore = () => {
  const query = useQuery();
  const qitem = query.get("item");
  const navigate = useNavigate();
  return (
    <div>
      <div className="mt-34 d-flex justify-content-between align-items-center">
        <p className=" breadcrumb_text fw-400 color_ba pointer">
          <span onClick={() => navigate(-1)} className="pointer">
            Subscription
          </span>{" "}
          / <span className="fw-700">{qitem ? qitem : ""}</span>
        </p>

        {/* <button className="secondary_button_variant_2">
          <i className="bi bi-cart3"></i> Go to Cart
        </button> */}
      </div>

      <div className="mt-50">
        <p className="font_17_700 text_capital">
          {qitem ? qitem + " by today.green" : ""}
        </p>

        <div className="row mt-34">
          <div className="col-md-7 d-flex">
            <div className="flow-grow-1">
              <video width="95%" controls>
                <source
                  src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          {/* <div className="col-md-"></div> */}
          <div className="col-md-4">
            <div className="container_design_1 pl-28">
              <p className="mt-28 f-20 fw-600 font-inter">Accounting Service</p>

              <p className="subheading_light mt-20 mr-46">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna.
              </p>

              <div className="mt-30">
                <p className="body_text_s">
                  <i className="bi bi-check color_blue"></i>
                  Accounting for 1 item (1 year)
                </p>

                <p className="mt-6 body_text_s">
                  <i className="bi bi-check color_blue"></i>
                  Up to 250 Logs/month
                </p>

                <p className="mt-6 body_text_s">
                  <i className="bi bi-check color_blue"></i>
                  Quarterly GHG Reports
                </p>

                <div className="mt-40 d-flex align-items-center">
                  <p className="body_text_sm fw-700 color_desp_grey">
                    1.590€ Setup fee
                  </p>
                  <p className="helper_text color_ba ml-26">
                    (one time payment)
                  </p>
                </div>

                <p className="body_text_sm color_desp_grey mt-36">from</p>

                <div className="mt-6 d-flex align-items-center">
                  <p className="heading_9">429,00€</p>
                  <p className="helper_text color_ba ml-26">
                    /mo ([aid annually)
                  </p>
                </div>

                <ButtonComp
                  onClick={() => history("/dashboard?tab=9&step=2")}
                  className="mt-22 mb-20 px-20"
                  status={2}
                >
                  Schedule free Assessment Call
                </ButtonComp>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mt-50">
        <div className="col-md-7">
          <p className="font_17_700 text_capital">Description</p>
          <p className="body_text color_desp_grey mt-12">
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
            nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
            erat, sed diam voluptua. At vero eos et accusam et justo duo dolores
            et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est
            Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur
            sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore
            et dolore magna aliquyam erat, sed diam voluptua. At vero eos et
            accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren,
            no sea takimata sanctus est Lorem ipsum dolor sit amet.
          </p>
        </div>
      </div>

      <div className="mt-50 mb-70 pb-40">
        <p className="font_17_700 text_capital">Step-by-step</p>

        <div className="col-lg-7 col-md-9 row container_design_1 mt-28 mx-0 ">
          <div className="col-4 pl-20">
            <p className="purple_label mt-26">1</p>

            <p className="card_heading mt-32">🔎 Assessment Call (free)</p>
            <p className="body_text_s color_desp_grey w-75 mt-12 mb-32">
              We have a meeting to understand the scope of logs required for
              your item/s.
            </p>
          </div>

          <div className="col-4 pl-20">
            <p className="purple_label mt-26">2</p>

            <p className="card_heading mt-32">📄 Quote</p>
            <p className="body_text_s color_desp_grey w-75 mt-12 mb-32">
              After the call, we send you a quote without any commitment on your
              end.
            </p>
          </div>

          <div className="col-4 pl-20">
            <p className="purple_label mt-26">3</p>

            <p className="card_heading mt-32">🧑🏻‍💻 Setup</p>
            <p className="body_text_s color_desp_grey w-75 mt-12 mb-32">
              We set up a secure file storage for you to upload the emission
              data we requested from you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnMore;
