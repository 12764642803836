import React, { useState, useEffect } from "react";
// import TextInputType from "../TextInputType";
import { useMapStore } from "../../../../../../../Utils/CompanyMapStore";
import itemData from "../../../../../LogDashboard/items-new.json";
import ScopeDataList from "./Components/ScopeDataList";
import Axios from "../../../../../../../Api";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../Utils/Auth";
import { Avatar, AvatarGroup } from "@mui/joy";
import ButtonComp from "../../../../../../../Assets/components/Button";
import toast from "react-hot-toast";
import { InputAdornment, TextField } from "@mui/material";
import SeachBigCircleIcon from "../../../../../../../Assets/icons/SeachBigCircleIcon";

function CompanyMapAssignment() {
  const { setSessionExpire } = useAuth();
  const { activeNodeData } = useMapStore();
  const [companyUsers, setCompanyUsers] = useState([]);
  const [scopeWiseData, setScopeWiseData] = useState([]);

  // const addUserMatrixItem = () =>{

  // }
  useEffect(() => {
    if (activeNodeData) {
      let accItemIndex = itemData.findIndex(
        (currAcc) =>
          currAcc.name?.toLowerCase() ==
          activeNodeData.accountingItemName?.toLowerCase()
      );
      if (accItemIndex == -1) return;

      let currScopeWiseData = [[], [], []];
      activeNodeData.matrixItems.forEach((currItem) => {
        Object.keys(itemData[accItemIndex].scopes).forEach((currScope) => {
          if (currScope === currItem.scope)
            currScopeWiseData[
              itemData[accItemIndex].scopes[currScope].scope - 1
            ]?.push(currItem);
        });
      });
      getAllUsers();
      setScopeWiseData(currScopeWiseData);
    }
  }, [activeNodeData]);

  const addUserToCompany = (user) => {
    let tempUsers = [...companyUsers];
    tempUsers.push(user);
    setCompanyUsers(tempUsers);
  };
  const assignUserToScope = (email, scopeDetails) => {
    let userIndex = companyUsers.findIndex(
      (currUser) => currUser.email === email
    );
    if (userIndex >= 0) {
      let tempUsers = [...companyUsers];
      let currAssignee = tempUsers[userIndex].assigned
        ? tempUsers[userIndex].assigned
        : [];
      currAssignee.push(scopeDetails);
      tempUsers[userIndex].assigned = currAssignee;
      setCompanyUsers(tempUsers);
    }
  };

  const removeUserFromScope = (email, scopeDetails) => {
    let userIndex = companyUsers.findIndex(
      (currUser) => currUser.email == email
    );
    if (userIndex >= 0) {
      let tempUsers = [...companyUsers];
      let currAssignee = tempUsers[userIndex].assigned
        ? tempUsers[userIndex].assigned
        : [];
      if (currAssignee.length == 0) return;
      let scopeIndex = currAssignee.findIndex(
        (currScope) => currScope.scope === scopeDetails.scope
      );
      currAssignee.splice(scopeIndex, 1);
      tempUsers[userIndex].assigned = currAssignee;
      setCompanyUsers(tempUsers);
    }
  };
  const getAllUsers = async () => {
    await Axios({
      url: `/company/all-users`,
      method: "get",
    })
      .then((res) => {
        //  setCompanyUsers(res.data.data);
        let tempCompanyUsers = res.data.data;
        activeNodeData.matrixItems.forEach((currItem) => {
          currItem.assigned.forEach((currAssignee) => {
            tempCompanyUsers.forEach((currUser) => {
              if (currUser._id === currAssignee._id) {
                if (!currUser.assigned) currUser.assigned = [];
                currUser.assigned.push(currItem);
              }
            });
          });
        });
        //  console.log("this is company user ",tempCompanyUsers)
        setCompanyUsers(tempCompanyUsers);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleSubmit = async () => {
    // console.log("this is node data ", activeNodeData._id)
    // console.log("this is company user ",companyUsers)
    let currMatrixItem = activeNodeData.matrixItems;

    currMatrixItem.forEach((currItem) => {
      companyUsers.forEach((currUser) => {
        if (currUser.assigned && currUser.assigned.length > 0) {
          currUser.assigned.forEach((currAssignedItem) => {
            if (currAssignedItem.scope === currItem.scope) {
              if (!currItem.assigned) currItem.assigned = [];
              let userIndex = currItem.assigned.findIndex(
                (currAddedUser) => currAddedUser._id === currUser._id
              );
              if (userIndex === -1)
                currItem.assigned.push({
                  firstname: currUser.firstname,
                  lastname: currUser.lastname,
                  _id: currUser._id,
                  email: currUser.email,
                });
            }
          });
        }
      });
    });

    //  console.log("this is company user ",currMatrixItem)

    try {
      await Axios.post(`log/updateLogMatrixItem?logId=${activeNodeData._id}`, {
        updatedMatrixItem: currMatrixItem,
      }).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
        }
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  return (
    <div>
      <hr className="mt-14" />
      <div className="mt-14">
        <TextField
          // value={filterValue}
          // onChange={(e) => setFilterValue(e.target.value)}
          className="font_12_500"
          id="input-with-icon-textfield"
          placeholder={"Emission Source"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SeachBigCircleIcon />
              </InputAdornment>
            ),
          }}
          sx={{
            width: "100%",
            borderRadius: "5px",
            boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
            "& .MuiInputBase-root": {
              height: 40,
              width: "100%",
              border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input": {
              fontFamily: "Inter",
              fontSize: " 12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "10px",
              color: "7E8999",
              paddingLeft: "4px",
            },
          }}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center mt-20">
        <div className="d-flex align-items-center">
          <p className="font_14_600 color_7e">
            {activeNodeData.accountingItemName}
          </p>

          <div className="d-flex align-items-center ml-10">
            <AvatarGroup sx={{ flexDirection: "row" }}>
              {companyUsers
                .filter(
                  (currUser) =>
                    currUser.assigned && currUser.assigned.length > 0
                )
                .map((currParticipant, pIndex) => {
                  return (
                    <Avatar
                      key={pIndex + currParticipant.firstname}
                      sx={{ width: "30px", height: "30px" }}
                    >
                      {currParticipant.firstname
                        ? currParticipant.firstname[0].toUpperCase()
                        : ""}
                    </Avatar>
                  );
                })}

              {/* <Avatar alt="Travis Howard" src={demoImage} />
                                                        <Avatar alt="Cindy Baker" src={demoImage} /> */}
              {companyUsers.filter(
                (currUser) => currUser.assigned && currUser.assigned.length > 0
              ).length > 3 ? (
                <Avatar>
                  +
                  {companyUsers.filter(
                    (currUser) =>
                      currUser.assigned && currUser.assigned.length > 0
                  ).length - 3}
                </Avatar>
              ) : (
                ""
              )}
            </AvatarGroup>
            <i className="bi bi-person-plus ml-10"></i>
          </div>
        </div>

        <div>
          <i className="bi bi-question-circle-fill"></i>
        </div>
      </div>

      <ScopeDataList
        companyUsers={companyUsers}
        addUserToCompany={addUserToCompany}
        scopeWiseData={scopeWiseData}
        assignUserToScope={assignUserToScope}
        removeUserFromScope={removeUserFromScope}
      />

      <div className="d-flex justify-content-end align-items-center mt-20 mb-40">
        <ButtonComp
          status={5}
          onClick={() => {
            handleSubmit();
          }}
        >
          Submit
        </ButtonComp>
      </div>
    </div>
  );
}

export default CompanyMapAssignment;
