import React from "react";
import { getServiceIcon } from "../../../Utils/itemIcon";

export default function Action() {
  return (
    <div className="d-flex justify-content-around">
      <div className="d-flex align-items-center grey_label">
        <img src={getServiceIcon("offset status")} className="h-16px mr-4" />
      </div>
      <div className="d-flex align-items-center grey_label">
        <img src={getServiceIcon("reporting status")} className="h-16px mr-4" />
      </div>
      <div className="d-flex align-items-center grey_label">
        <img src={getServiceIcon("target status")} className="h-16px mr-4" />
      </div>
    </div>
  );
}
