import React, { useEffect, useState } from "react";
import { localNumber } from "../../../../../../../../Utils/CommonFunctions";
import { NumericFormat } from "react-number-format";
// import useQuery from "../../../../../../../../Utils/query";

const SubScopeTarget = ({
  selectSubClassEmisson,
  setReductionValue,
  setReductionPercentage,
  reductionValue,
  reductionPercentage,
}) => {
  const [reductionTempPercentage, setReductionTempPercentage] = useState("");
  const [reductionTempValue, setReductionTempValue] = useState("");

  useEffect(() => {
    if (reductionValue) {
      setReductionTempValue(reductionValue);
    }
    if (reductionPercentage) {
      setReductionTempPercentage(reductionPercentage);
    }
  }, [reductionValue, reductionPercentage]);

  //   const query = useQuery();
  //   const itemId = query.get("accItem");

  return (
    <div className="quickLog-main pt-10">
      <div className="align_center">
        <div className="mr-50">
          <p className="font_14_600 color_7e">
            <span className="color_green">Subclass</span> Baseline
          </p>
          <div className="h-40px mt-10 align_center">
            <p className="color_31 font_20_700">
              {localNumber(selectSubClassEmisson)}{" "}
              <span className="font_10_600 ">tCO2e</span>
            </p>
          </div>
        </div>
        <div>
          <p className="font_14_600 color_7e">Reduction Target</p>
          <div className="align_center gap_10 mt-10">
            <NumericFormat
              suffix=" tCO2e"
              className="grey_input font_14_400 flex-1 targetPercentageInput"
              placeholder="0.00 tCO2e"
              value={reductionTempValue}
              isAllowed={(e) => {
                if (e.floatValue === undefined) return true;
                if (e.floatValue <= selectSubClassEmisson) return true;
                return false;
              }}
              onChange={(e) => {
                const value = Number(e.target.value.split("tCO2e")[0]);
                if (value <= selectSubClassEmisson) {
                  setReductionTempValue(e.target.value);
                  setReductionTempPercentage(
                    (value * 100) / selectSubClassEmisson
                  );
                  setReductionPercentage((value * 100) / selectSubClassEmisson);
                  setReductionValue(value);
                }
              }}
            />
            <NumericFormat
              suffix=" %"
              className="font_14_300 grey_input targetPercentageInput"
              placeholder="0 %"
              style={{ width: 70 }}
              value={reductionTempPercentage}
              isAllowed={(e) => {
                if (e.floatValue === undefined) return true;
                if (e.floatValue <= 100) return true;
                return false;
              }}
              onChange={(e) => {
                const value = Number(e.target.value.split("%")[0]);
                if (value <= 100) {
                  setReductionTempPercentage(e.target.value);
                  setReductionTempValue(selectSubClassEmisson * (value / 100));
                  setReductionValue(selectSubClassEmisson * (value / 100));
                  setReductionPercentage(value);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubScopeTarget;
