import React from "react";

function DashboardIcon(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 22 22"
      >
        <rect
          width="5.256"
          height="5.256"
          x="4.27"
          y="4.047"
          stroke={props.stroke}
          strokeLinejoin="round"
          strokeWidth="2"
          rx="1"
        ></rect>
        <rect
          width="5.256"
          height="5.256"
          x="4.27"
          y="12.805"
          stroke={props.stroke}
          strokeLinejoin="round"
          strokeWidth="2"
          rx="1"
        ></rect>
        <rect
          width="5.256"
          height="5.256"
          x="13.031"
          y="12.805"
          stroke={props.stroke}
          strokeLinejoin="round"
          strokeWidth="2"
          rx="1"
        ></rect>
        <rect
          width="5.256"
          height="5.256"
          x="13.031"
          y="4.047"
          stroke={props.stroke}
          strokeLinejoin="round"
          strokeWidth="2"
          rx="1"
        ></rect>
      </svg>
    </div>
  );
}

export default DashboardIcon;
