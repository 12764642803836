import React from "react";

const LogIcon = ({ cc, className, style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
      className={className}
      style={style}
    >
      <path
        stroke={cc || "#fff"}
        strokeWidth="2"
        d="M17.774 11v3.621c0 1.886 0 2.828-.586 3.414-.585.586-1.528.586-3.414.586H6.343a2.117 2.117 0 01-2.117-2.117v0c0-1.17.948-2.117 2.117-2.117h7.431c1.886 0 2.829 0 3.414-.586.586-.585.586-1.528.586-3.414V7.38c0-1.886 0-2.828-.586-3.414-.585-.586-1.528-.586-3.414-.586H8.226c-1.886 0-2.829 0-3.414.586-.586.586-.586 1.528-.586 3.414v9.125"
      ></path>
      <path
        stroke={cc || "#fff"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M8.46 9.306l.986.987a1 1 0 001.414 0l2.68-2.68"
      ></path>
    </svg>
  );
};

export default LogIcon;
