import React from "react";
import { Backdrop, Box, Fade, Modal } from "@mui/material";
import { modalStyle } from "../../../Utils/CommonFunctions";
import { botAvatarGroup } from "../../../Utils/botAvatarsGroup";
import { useAuth } from "../../../Utils/Auth";
import ButtonComp from "../Button";

const DeleteModal = ({ open, handleClose, img, subtitle, deleteTitle, confirmFunction }) => {
  const { user } = useAuth();
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={modalStyle}>
          <div className="d-flex justify-content-between p-32 p_relative">
            <div>
              <p className="heading_lg">Are you sure?</p>
              <p className="mt-32 body_text">
                {subtitle ? (
                  <span dangerouslySetInnerHTML={{__html: subtitle}}/>
                ) : (
                  <span>
                    Once you delete a GHG report, you can&apos;t recover it.{" "}
                    <br /> Please be sure before deleting it.
                  </span>
                )}
              </p>
              <div className="mt-40">
                <ButtonComp status={3} className="color_red" onClick={confirmFunction}>
                  {deleteTitle ? deleteTitle : "Delete this Report"}
                </ButtonComp>
              </div>
            </div>
            <div style={{ zIndex: 1 }}>
              <img
                src={
                  img
                    ? img
                    : botAvatarGroup[user?.bot_avatar_id]?.sentSuccessful?.url
                }
                alt="launching"
                style={{ maxHeight: 190, width: "auto" }}
              />
            </div>
            <hr className="bg_divider" />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteModal;
