import { Close, HelpOutlined, KeyboardArrowDown } from "@mui/icons-material";
import React, { useState } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import CompanyBgImageUpload from "./CompanyBgImageUpload";
import CompanyLogoUpload from "./CompanyLogoUpload";
import { useAuth } from "../../../../Utils/Auth";
import { handleSaveData } from "./CommonFunctions";
import industries from "../../../../Authentication/Register/industries.json";
import { useEffect } from "react";
import { Tooltip } from "@mui/material";
import TickMark from "../../../../Assets/icons/tickMark";
import toast from "react-hot-toast";
import {
  // TextField,
  FormControl,
  Select,
  MenuItem,
  styled,
  OutlinedInput,
} from "@mui/material";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

const BootstrapInput = styled(OutlinedInput)(() => ({
  "& .MuiInputBase-input": {
    fontFamily: "var(--inter-font)",
    fontWeight: 400,
    fontSize: "13px",
    borderRadius: "5px",
    height: "40px !important",
    marginTop: "auto",
    padding: "0px 5px",
    display: "flex",
    alignItems: "center",
    border: "none",
    backgroundColor: "#F6F6F6",
    color: "#000",
  },
}));

const CompanyProfileModal = ({ handleClose, getCompanyData, companyData }) => {
  const [companyBg, setCompanyBg] = useState("");
  const [logo, setLogo] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [industry, setIndustry] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [noOfEmployees, setNoOfEmployees] = useState("");
  const [companyWebsite, setCompanyWebsite] = useState("https://");
  const [establishedYear, setEstablishedYear] = useState("");
  const { profile, setSessionExpire } = useAuth();
  const urlPattern = /^(https?:\/\/www\.)[A-Za-z0-9.-]+\.[A-Za-z]{2,}(\/.*)?$/;

  const [isValidUrl, setIsValidUrl] = useState(false);
  const handleChange = () => {
    if (!isValidUrl) {
      return toast.error("Please enter a valid url");
    }
    const data = {
      companyBackground: companyBg,
      companylogo: logo,
      companyName: companyName,
      establishedYear,
      companyIndustry: industry,
      companyWebsite,
      companyEmployeesNo: noOfEmployees,
      companyLocation: companyAddress,
    };
    handleSaveData(data, getCompanyData, handleClose, setSessionExpire);
  };

  useEffect(() => {
    if (companyData) {
      setCompanyBg(companyData.companyBackground);
      setEstablishedYear(companyData.establishedYear);
      if (companyData.companylogo) {
        setLogo(companyData.companylogo);
      } else {
        setLogo(profile?.logo);
      }
      if (companyData.companyName) {
        setCompanyName(companyData.companyName);
      } else {
        setCompanyName(profile?.companyName);
      }
      if (companyData.companyIndustry) {
        setIndustry(companyData.companyIndustry);
      } else {
        setIndustry(profile?.industry);
      }
      if (companyData.companyLocation) {
        setCompanyAddress(companyData.companyLocation);
      } else {
        setCompanyAddress(`${profile?.stateCode}, ${profile?.country}`);
      }
      if (companyData.companyEmployeesNo) {
        setNoOfEmployees(companyData.companyEmployeesNo);
      } else {
        setNoOfEmployees(profile?.empCount);
      }
      if (companyData.companyWebsite) {
        setCompanyWebsite(companyData.companyWebsite);
        setIsValidUrl(urlPattern.test(companyData.companyWebsite));
      } else {
        setCompanyWebsite(profile?.website);
        setIsValidUrl(urlPattern.test(profile?.website));
      }
    }
  }, [companyData, profile]);

  const [origin, setOrigin] = useState(null);
  useEffect(() => {
    let newAddress = origin && origin.value ? origin.value.description : "";
    if (newAddress && newAddress.length > 0) {
      setCompanyAddress(newAddress);
    }
    // props.handleAnswerChange(props.name, newAddress);
  }, [origin]);

  return (
    <div className="row_20_gap">
      <div className="d-flex align-items-center justify-content-between">
        <p className="font_16_500">Company Profile</p>
        <Close className="pointer" onClick={handleClose} />
      </div>
      <CompanyBgImageUpload avatar={companyBg} setAvatar={setCompanyBg} />
      <CompanyLogoUpload avatar={logo} setAvatar={setLogo} />
      <div className="mt-20 ">
        <p className="font_12_600 color_7e">Company Name</p>
        <input
          type="text"
          className="mt-10 share_transpancy_input font_14_400"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </div>
      <div className="mt-20 row">
        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
          <div>
            <p className="font_12_600 color_7e">Industry</p>
            <FormControl
              sx={{
                width: "100%",
                border: "none",
                borderRadius: "5px",
                "& .MuiSelect-select:focus": {
                  backgroundColor: "transparent !important",
                },
              }}
              className="mt-10"
            >
              <Select
                input={<BootstrapInput />}
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                className="share_transparency_select"
                sx={{
                  "&.MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                IconComponent={KeyboardArrowDown}
              >
                {industries.map((currOption, i) => {
                  return (
                    <MenuItem key={i} className="color_72" value={currOption}>
                      {currOption}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-5 col-12">
          <div>
            <p className="font_12_600 color_7e">Company Address</p>
            <GooglePlacesAutocomplete
              debounce={800}
              defaultValue={companyAddress}
              apiKey={process.env.REACT_APP_GOOGLE_MAP}
              //   autocompletionRequest={}
              selectProps={{
                origin,

                onChange: (e) => {
                  setOrigin(e);
                },
                placeholder: !origin ? companyAddress : "Address",
                styles: {
                  container: (p) => ({
                    ...p,
                    boxSizing: `border-box`,
                    // border: `1px solid #efefef`,
                    borderRadius: `5px`,
                    fontSize: `15px`,
                    fontWeight: "400",
                    boxShadow: "none",
                    fontFamily: '"Inter", sans-serif',
                    outline: `none`,
                    maxHeight: 40,
                    backgroundColor: "#F6F6F6",
                    marginTop: 10,
                  }),
                  valueContainer: (p) => ({
                    ...p,
                    border: `none`,
                    borderRadius: "5px",
                    color: "transparent",
                    padding: "0px 10px",
                    height: 42,
                    backgroundColor: "#F6F6F6",
                  }),
                  control: (p) => ({
                    ...p,
                    border: `0`,
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }),
                  indicatorsContainer: (p) => ({
                    ...p,
                    display: "none",
                  }),
                  placeholder: (p) => ({
                    ...p,
                    color: "black",
                  }),
                  input: (p) => ({
                    ...p,
                    // padding: "0px",
                  }),
                },
              }}
            />
            {/* <input
              type="text"
              className="mt-10 share_transpancy_input font_14_400"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
            /> */}
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-12">
          <div>
            <p className="font_12_600 color_7e">Employees</p>
            <input
              type="number"
              className="mt-10 share_transpancy_input font_14_400"
              value={noOfEmployees}
              onChange={(e) => setNoOfEmployees(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-20 row">
        <div className="col-lg-7 col-md-7 col-sm-7 col-12">
          <div>
            <p className="font_12_600 color_7e">Company Website URL</p>
            <div className="share_transpancy_input mt-10 d-flex align-items-center">
              <input
                type="url"
                className="font_14_400 flex-1 border-none"
                style={{ background: "transparent", outline: "none" }}
                value={companyWebsite}
                onChange={(e) => {
                  const { value } = e.target;
                  // Ensure the prefix is always at the beginning of the input value
                  if (!value.startsWith("https://")) {
                    setCompanyAddress("https://");
                  } else if (value.startsWith("https://")) {
                    setIsValidUrl(urlPattern.test(value));
                    setCompanyWebsite(value);
                  } else {
                    setIsValidUrl(urlPattern.test("https://" + e.target.value));
                    setCompanyWebsite("https://" + value);
                  }
                }}
              />
              {isValidUrl && <TickMark cc={"#00915B"} className={"ml-10"} />}
            </div>
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-2 col-4">
          <div>
            <p className="font_12_600 color_7e">
              est.{" "}
              <Tooltip title="Establishment Year">
                <HelpOutlined
                  htmlColor="#7E8999"
                  fontSize="small"
                  sx={{ height: 14, width: 14 }}
                />
              </Tooltip>
            </p>
            <input
              type="number"
              className="share_transpancy_input font_14_400"
              placeholder="YYYY"
              value={establishedYear}
              onChange={(e) => setEstablishedYear(e.target.value)}
              style={{ marginTop: 6 }}
            />
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-3 col-8">
          <div>
            <p className="font_12_600 color_7e opacity-0">.</p>
            <ButtonComp
              status={5}
              className="mt-10 w-100 h-40px"
              onClick={handleChange}
            >
              Save
            </ButtonComp>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyProfileModal;
