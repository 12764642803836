import React,{useState, useEffect} from 'react'
import { KeyboardArrowUp,KeyboardArrowDown } from '@mui/icons-material';
import ButtonComp from '../../../../../../../../../Assets/components/Button';
import { useMapStore } from '../../../../../../../../../Utils/CompanyMapStore';
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";
import Axios from '../../../../../../../../../Api';
import Loader from '../../../../../../../../../Assets/components/Loader';
import { toastResponseError } from '../../../../../../../../../Utils/toastResponseError';
import { useAuth } from '../../../../../../../../../Utils/Auth';
import { loadStripe } from '@stripe/stripe-js';
import PaymentModel from './paymentPopup';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function BuyCredits(props) {
  const {setSessionExpire}= useAuth()
  const history = useNavigate();
  const {selectedAddress,selectedPaymentMethod}= useMapStore()
  const [submitPayment, setsubmitPayment] = useState(false);
    const [isAcceptTerms, setisAcceptTerms] = useState(false);
    const [openDropDown, setopenDropDown] = useState(false);
    const [d_code, setd_code] = useState("");
    const [submitCode, setsubmitCode] = useState(false);
    const [billingLoading,setBillingLoading]=useState(false)
    const [paymentModalOpen, setPaymentModalOpen] = useState(false);
    const handlePaymentModalOpen = () => setPaymentModalOpen(true);
    const handlePaymentModalClose = () => {
      setPaymentModalOpen(false);
    };



    const handleUpdateFinalCart = async (invoice, paymentIntent) => {
      try {
        setBillingLoading(true);
        const res = await Axios.post(
          `/cart/update-success-cart/${props.userCart._id}`,
          {
            invoice_id: invoice.id,
            invoice_url: invoice.hosted_invoice_url,
            invoice_pdf: invoice.invoice_pdf,
            paymentMethod: paymentIntent
              ? paymentIntent.payment_method
              : "bank_transfer",
            paymentIntent: paymentIntent ? paymentIntent.id : "",
            isPaid: paymentIntent ? true : false,
          }
        );
        if (res.data.success) {
          setBillingLoading(false);
          toast.success("Purchase Complete");
          history(`/dashboard?tab=3`);
          handlePaymentModalOpen()
        }
      } catch (error) {
        setBillingLoading(false);
        toastResponseError(error, setSessionExpire);
      }
    };

    const handleConfirmPayment = async (paymentIntent, invoice) => {
      if (paymentIntent && paymentIntent.client_secret) {
        setBillingLoading(true);
        var stripe = await stripePromise;
        var { error } = stripe.confirmPaymentIntent(paymentIntent.client_secret);
        if (error) {
          setBillingLoading(false);
          toast.error(error.message);
        } else {
          handleUpdateFinalCart(invoice, paymentIntent);
          toast.success("Payment Successful");
          // history("/payment/success");
          setBillingLoading(false);
        }
      }
    };

    const handlePayment = async () => {
      try {
        if (!isAcceptTerms) {
          return toast.error("Please accept terms and conditions");
        }
        handlePaymentModalOpen()
        let x=true
        if(x)
        {
          return
        }
        setBillingLoading(true);
        let response = await Axios.post(
          `/stripe/store-purchase?cart=${props.userCart._id}`,
          {
            data: {
              payment_method: selectedPaymentMethod ? selectedPaymentMethod.id : null,
              paymentType: selectedPaymentMethod ? selectedPaymentMethod.type : null,
              billing_id: selectedAddress._id,
            },
          }
        );
        if (response.data.success) {
          setBillingLoading(false);
          let paymentIntent = response.data.data;
          let invoice = response.data.invoice;
          if (selectedPaymentMethod) {
            handleConfirmPayment(paymentIntent, invoice);
          } else {
            handleUpdateFinalCart(invoice, null);
          }
        }
      } catch (error) {
        toastResponseError(error, setSessionExpire);
        setBillingLoading(false);
      }
    };

    useEffect(() =>{
        if(submitPayment)
        {
          handlePayment();
          setsubmitPayment(false);
        }
    },[submitPayment])
  return (
    <div>
      {billingLoading ? <Loader/> :null}
      <PaymentModel
        open={paymentModalOpen}
        handleOpen={handlePaymentModalOpen}
        handleClose= {handlePaymentModalClose}
      />
        <div>
        {props.cartDetails.map((item, i) => {
                    return (
                      <div className="d-flex mt-30" key={i}>
                        {/* <div className="store_image_container d-flex align-items-center p-4 mt-1">
                          <img src={getServiceIcon(item.name)} />
                        </div> */}
                        <div className="flex-grow-1 d-flex justify-content-between ml-10 mt-16">
                          <div>
                            <p className="f-16 font_15_600">{item.name}</p>

                            <p className="subheading_light_3 mt-10">
                              {Number(item.price).toLocaleString()}€ /credit
                            </p>
                          </div>
                          <div>
                            <p className="font_15_600 text-right">
                              {(item.price * item.quantity * 12)
                                .toFixed(2)
                                .toLocaleString()}
                              €
                            </p>
                            <p className="subheading_light_3 mt-10">
                              Quantity: {item.quantity}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
        </div>


        <div className="mt-72">
              <div className="d-flex align-items-center">
                <p className="subheading_1">discount code</p>
                <div
                  className="ml-10  pointer"
                  onClick={() => {
                    setopenDropDown(!openDropDown);
                  }}
                >
                  {openDropDown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </div>
              </div>
              {openDropDown ? (
                <>
                  <div className="row mx-0">
                    <input
                      className="border_input mb-16 col-5 py-2"
                      placeholder="Discount Code"
                      value={d_code}
                      onChange={(e) => setd_code(e.target.value)}
                    />
                    {d_code && d_code.length > 0 ? (
                      <div className="col-md-4 col-6 col-lg-4">
                        <ButtonComp
                          status={1}
                          onClick={() => setsubmitCode(true)}
                        >
                          Apply Code{" "}
                        </ButtonComp>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {submitCode && d_code.length > 0 && (
                    <p className="color_red subheading_light_2">
                      The code is invalid, please try entering again.
                    </p>
                  )}
                </>
              ) : null}

              <div className="border-btm_ef pb-20">
                <div className="d-flex align-items-center justify-content-between mt-14">
                  <p className="subheading_light_3">Subtotal</p>
                  <p className="subheading_light_3">
                    {props.userCart.total &&
                      props.userCart.total.toFixed(2).toLocaleString()}
                    €
                  </p>
                </div>
                {/* <div className="d-flex align-items-center justify-content-between mt-10">
                  <p className="subheading_light_3">Payment Fee</p>
                  <p className="subheading_light_3">12.00,00€</p>
                </div> */}
                <div className="d-flex align-items-center justify-content-between mt-10">
                  <p className="subheading_light_3">
                    Tax (
                    {selectedAddress && selectedAddress.Country === "Germany"
                      ? "19%"
                      : "0%"}
                    )
                  </p>
                  <p className="subheading_light_3">
                    {selectedAddress && selectedAddress.Country === "Germany"
                      ? props.userCart.tax &&
                        props.userCart.tax.toFixed(2).toLocaleString() + " €"
                      : "0,00 €"}
                  </p>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between mt-20">
                <p className="heading_9">Total</p>
                <p className="heading_9">{props.grandTotal.toFixed(2)}€</p>
              </div>
               
                <div className="mt-30 d-flex align-items-center">
                  <input
                    type="radio"
                    checked={isAcceptTerms}
                    value={isAcceptTerms}
                    onChange={() => setisAcceptTerms(!isAcceptTerms)}
                  />
                  <p className="overline_text ml-8">
                    I accept the{" "}
                    <span style={{ borderBottom: "1px solid" }}>
                      <a
                        href="https://www.today.green/legal/agb"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fw-bold termsNconditionsAnchor"
                      >
                        terms & conditions
                      </a>
                    </span>{" "}
                    from today.green
                  </p>
                </div>
              

             
                <div className="mt-30">
                  <ButtonComp
                    status={1}
                    size="xl"
                    // className=" mt-30"
                    disabled={!isAcceptTerms}
                    onClick={() => setsubmitPayment(true)}
                  >
                    Purchase Now
                  </ButtonComp>
                </div>
            </div>
    </div>
  )
}

export default BuyCredits