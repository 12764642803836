import React from "react";
import "./index.css";
import KeyboardLeftIcon from "../../icons/KeyboardLeftIcon";
import KeyboardRightIcon from "../../icons/KeyboardRightIcon";

const BackForwardButton = ({
  handleGoBack,
  hanldeGoForward,
  doNotShowBack,
  doNotShowForward,
  backDisabled,
  forwardDisabled,
  className,
  backBtnClassName,
  forwardBtnClassName,
  backBtnStyle,
  ForwardBtnStyle,
}) => {
  return (
    <div className={"d-flex align-items-center " + className}>
      {!doNotShowBack && (
        <button
          className={
            "backForwardBtn mr-8 " +
            (backDisabled && "border_1_ef") +
            " " +
            backBtnClassName
          }
          style={backBtnStyle}
          onClick={handleGoBack}
          type="button"
          disabled={backDisabled}
        >
          <KeyboardLeftIcon />
        </button>
      )}
      {!doNotShowForward && (
        <button
          className={
            "backForwardBtn " +
            (forwardDisabled && "border_1_ef") +
            " " +
            forwardBtnClassName
          }
          style={ForwardBtnStyle}
          type="button"
          onClick={hanldeGoForward}
          disabled={forwardDisabled}
        >
          <KeyboardRightIcon />
        </button>
      )}
    </div>
  );
};

export default BackForwardButton;
