import React from 'react'
import { useAuth } from '../../../../../../Utils/Auth'

function VideoCard({tutorialDetail}) {
  const {user} = useAuth()
  return (
    <div>
      <div className="h-220px">
        <img src={tutorialDetail?.cover.image} className="img-fluid w-100 h-100 border_radius_10" alt=""/>
    </div>
    <p className="subheading_4 mt-10">{user.language === 'de' && tutorialDetail?.title_de?tutorialDetail?.title_de:tutorialDetail?.title}</p>
    </div>
  )
}

export default VideoCard