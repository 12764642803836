import React from "react";
import "./postCard.css";
import {
  extractTitleFromHTML,
  knowledgeCategories,
  knowledgeCategories_de,
} from "../../../../../../../Utils/CommonFunctions";
import {useAuth} from '../../../../../../../Utils/Auth'
//import bgi from '../../../../../../../Assets/images/OurServicesImg.png'
function PostCard({ cardDetails }) {
  const {user} = useAuth()
  return (
    <div className="postcard_root">
      <img
        src={cardDetails.coverimage}
        className="w-100 border_radius_10"
        style={{
          aspectRatio: 1.76 / 1,
          objectFit: "cover",
        }}
      />
      <div className="mt-10 d-flex align-items-center justify-content-between">
        <p className="breadcrumb_text_bold_1">
          {user.language === 'de' ? knowledgeCategories_de[Number(cardDetails.category) - 1] :knowledgeCategories[Number(cardDetails.category) - 1]}
        </p>
        <p className="helper_text color_57">
          {new Date(cardDetails.createdAt).toLocaleDateString("en-GB", {
            dateStyle: "medium",
          })}
        </p>
      </div>
      {cardDetails.title && (
        <p className="plus_jakarta_24_800">
          {user.language === 'de' && cardDetails.title_de ?extractTitleFromHTML(cardDetails.title_de):extractTitleFromHTML(cardDetails.title)}
        </p>
      )}
      {cardDetails.keywords && cardDetails.keywords.length > 0 && (
        <div className="mt-20 d-flex align-items-center flex-wrap gap-3">
          {cardDetails.keywords.slice(0, 2).map((keyword) => (
            <div
              key={keyword._id}
              style={{
                backgroundColor: keyword.bg_color,
                padding: "3px 8px",
                borderRadius: 5,
              }}
            >
              <p
                className="font_12_700"
                style={{
                  color: keyword.text_color,
                }}
              >
                {user.language === 'de' && keyword.tag_title_de?keyword.tag_title_de  :keyword.tag_title}
              </p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default PostCard;
