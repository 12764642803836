import React from 'react'

const DonwIntradayIcon = ({cc}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="11"
      fill="none"
      viewBox="0 0 16 11"
    >
      <path
        fill={ cc || "#00915B"}
        d="M13.633 8.646a.719.719 0 01-.511-.213L9.104 4.416 6.756 6.764a.723.723 0 01-1.022 0L1.08 2.11A.723.723 0 112.1 1.087L6.246 5.23l2.346-2.348a.724.724 0 011.024 0l4.528 4.529a.722.722 0 01-.51 1.234z"
      ></path>
      <path
        fill={ cc || "#00915B"}
        fillRule="evenodd"
        d="M1.924 1.263a.472.472 0 10-.668.67l4.655 4.655a.473.473 0 00.669 0l2.524-2.526 4.194 4.195a.47.47 0 00.668 0h.002a.472.472 0 000-.668l-4.529-4.53a.474.474 0 00-.67 0L6.246 5.585 1.924 1.263zM.903.91a.972.972 0 011.375 0l3.968 3.968 2.17-2.172a.975.975 0 011.377 0l4.528 4.53a.972.972 0 11-1.376 1.374L9.104 4.77 6.934 6.94a.974.974 0 01-1.377 0L.903 2.287a.973.973 0 010-1.377z"
        clipRule="evenodd"
      ></path>
      <path
        fill={ cc || "#00915B"}
        d="M14.794 9.798h-3.977a.724.724 0 010-1.446h3.254V5.067a.722.722 0 111.446 0v4.008a.723.723 0 01-.723.723z"
      ></path>
      <path
        fill={ cc || "#00915B"}
        fillRule="evenodd"
        d="M14.794 4.594a.472.472 0 00-.473.473v3.535h-3.504a.473.473 0 100 .946h3.977a.473.473 0 00.473-.473V5.067a.472.472 0 00-.473-.473zm-.973.473a.972.972 0 111.946 0v4.008a.973.973 0 01-.973.973h-3.977a.974.974 0 010-1.946h3.004V5.067z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default DonwIntradayIcon