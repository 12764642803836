import React from "react";

const PlusIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="19"
      fill="none"
      viewBox="0 0 19 19"
    >
      <path
        stroke={cc || "#000"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M9.102 1.5v16M1.102 9.5h16"
      ></path>
    </svg>
  );
};

export default PlusIcon;
