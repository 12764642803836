import { DescriptionOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import onClickOutside from "react-onclickoutside";

function TemplateSelect(props) {
  const [showTemplates, setShowTemplates] = useState(false);
  const [templateTabIndex, setTemplateTabIndex] = useState(0);
  const handleListDisplay = () => {
    setShowTemplates(!showTemplates);
  };
  TemplateSelect.handleClickOutside = () => {
    setShowTemplates(false);
  };

  return (
    <div className={`customSelect-container`}>
      <div
        className={`h-100 d-flex justify-content-between align-items-center border_radius_5 w-110px pointer`}
        onClick={handleListDisplay}
      >
        <DescriptionOutlined htmlColor="#575757" fontSize="small" />
        <p className="ml-10">Templates</p>
        <p className="mr-20 ml-10">
          {!showTemplates ? (
            <i className="bi bi-chevron-down"></i>
          ) : (
            <i className="bi bi-chevron-up"></i>
          )}
        </p>
      </div>
      {showTemplates && (
        <div className="bg_white border-ef zIndex-100 position_ab w-440px border_radius_8">
          <div className=" border-btm_ef pb-20 pt-16 ">
            <div className="d-flex align-items-center pl-20 mt-10 pr-40">
              <p
                onClick={() => {
                  setTemplateTabIndex(0);
                }}
                className={`font_13_500 border_radius_5  p-12x-10y pointer ${
                  templateTabIndex == 0
                    ? "color_white bg_009"
                    : "color_009 border-009"
                }`}
              >
                Drafts
              </p>
              <p
                onClick={() => {
                  setTemplateTabIndex(1);
                }}
                className={`font_13_500 border_radius_5  p-12x-10y pointer ml-10 ${
                  templateTabIndex == 1
                    ? "color_white bg_009"
                    : "color_009 border-009"
                }`}
              >
                Sustainability Templates
              </p>
              <p
                onClick={() => {
                  setTemplateTabIndex(2);
                }}
                className={`font_13_500 border_radius_5  p-12x-10y pointer ml-10 ${
                  templateTabIndex == 2
                    ? "color_white bg_009"
                    : "color_009 border-009"
                }`}
              >
                Recents
              </p>
            </div>
          </div>
          <div className="newSurveyQuestion_template_options h-150px px-20 pb-2">
            {templateTabIndex == 0 &&
              (props.templates.filter((currT) => currT.isDraft).length > 0 ? (
                props.templates
                  .filter((currT) => currT.isDraft)
                  .map((currTemplate, index) => {
                    return (
                      <div
                        key={currTemplate._id}
                        className={`d-flex justify-content-between align-items-center ${
                          index == 0 ? "mt-10" : "mt-14"
                        }`}
                      >
                        <p className="font_13_500 color_72">
                          {currTemplate.surveyName}
                        </p>

                        <p
                          onClick={() => {
                            props.setTemplateFormat(currTemplate._id, true);
                            setShowTemplates(false);
                          }}
                          className={`font_13_400 border_radius_5  p-10x-4y pointer ml-10 color_009 border_1_72`}
                        >
                          Use
                        </p>
                      </div>
                    );
                  })
              ) : (
                <p className="font_13_500 color_72 text-start mt-12">No drafts saved</p>
              ))}
            {templateTabIndex == 1 &&
              props.templates
                .filter((currT) => currT.isCompanyTemplate)
                .map((currTemplate, index) => {
                  return (
                    <div
                      key={currTemplate._id}
                      className={`d-flex justify-content-between align-items-center ${
                        index == 0 ? "mt-10" : "mt-14"
                      }`}
                    >
                      <p className="font_13_500 color_72">
                        {currTemplate.surveyName}
                      </p>

                      <p
                        onClick={() => {
                          props.setTemplateFormat(currTemplate._id, false);
                          setShowTemplates(false);
                        }}
                        className={`font_13_400 border_radius_5  p-10x-4y pointer ml-10 color_009 border_1_72`}
                      >
                        Use
                      </p>
                    </div>
                  );
                })}
            {templateTabIndex == 2 &&
              props.templates
                .filter((currT) => !currT.isDraft)
                .map((currTemplate, index) => {
                  return (
                    <div
                      key={currTemplate._id}
                      className={`d-flex justify-content-between align-items-center ${
                        index == 0 ? "mt-10" : "mt-14"
                      }`}
                    >
                      <p className="font_13_500 color_72">
                        {currTemplate.surveyName}
                      </p>

                      <p
                        onClick={() => {
                          props.setTemplateFormat(currTemplate._id, false);
                          setShowTemplates(false);
                        }}
                        className={`font_13_400 border_radius_5  p-10x-4y pointer ml-10 color_009 border_1_72`}
                      >
                        Use
                      </p>
                    </div>
                  );
                })}
          </div>
        </div>
      )}
    </div>
  );
}
const clickOutsideConfig = {
  handleClickOutside: () => TemplateSelect.handleClickOutside,
};
export default onClickOutside(TemplateSelect, clickOutsideConfig);
