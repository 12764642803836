import React from "react";
import Button from "@mui/material/Button";
import "./button.css";
// import { styled } from "@mui/material/styles";

export default function ButtonComp(props) {
  return (
    <Button
      focusRippleColor="darkRed"
      touchRippleColor="red"
      {...props}
      className={`button_global ${
        !props.status
          ? "primary"
          : props.status === 1
          ? "primary"
          : props.status === 2
          ? "secondary"
          : props.status === 3
          ? "tertiary"
          : props.status === 4
          ? "quaternary"
          : props.status === 5
          ? "greenState"
          : props.status === 6
          ? "greyState"
          : props.status === 7
          ? "text_green"
          : ""
      } ${props.color === "r" ? "red" : ""} ${
        props.size === "lg" ? "lg" : props.size === "xl" ? "xl" : ""
      } ${props.width === "lg" ? "width_lg" : ""} ${props.className}`}
      variant={`${props.status === 3 ? "outlined" : "contained"}`}
      sx={{
        position: "relative",
        overflow: "hidden",
        transform: "translate3d(0, 0, 0)",
        "&::after": {
          content: '""',
          position: "absolute",
          borderRadius: "50%",
          backgroundColor: "rgba(255, 255, 255, 0.4)",
          transform: "scale(0)",
          pointerEvents: "none",
          animation: "rippleEffect 0.1s linear",
        },
        "&:active::after": {
          transform: "scale(4)",
          opacity: 0,
        },
        "@keyframes rippleEffect": {
          to: {
            transform: "scale(4)",
            opacity: 0,
          },
        },
      }}
      size={props.size}
    >
      {props.children}
    </Button>
  );
}
