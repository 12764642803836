import React from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  // Tooltip,
  // Legend,
  ResponsiveContainer,
} from "recharts";
import { formatNumberIntoReadableFormat } from "../../../../../../../Utils/CommonFunctions";

const ChartWithLine = ({ data, scopeNo }) => {
  const CustomTick = ({ x, y, payload, index }) => {
    // Customize the appearance of the tick line here
    return (
      <g transform={`translate(${x},${y})`}>
        {/* <line x1="-11" y1="0" x2="11" y2="0" stroke="#333" /> */}
        {index === 0 || index === data.length - 1 ? (
          <>
            <text
              x="0"
              y="0"
              dy={16}
              textAnchor="middle"
              fill="#575757"
              style={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 10,
                fontFamily: "Inter",
                color: "#575757",
              }}
            >
              {index === 0
                ? "Baseyear"
                : index === data.length - 1
                ? "Target"
                : ""}
            </text>
            <text
              x="0"
              y="13"
              dy={16}
              textAnchor="middle"
              fill="#575757"
              style={{
                fontSize: 12,
                fontWeight: 500,
                lineHeight: 10,
                fontFamily: "Inter",
                color: "#575757",
              }}
            >
              {payload.value}
            </text>
          </>
        ) : (
          <text
            x="0"
            y="0"
            dy={16}
            textAnchor="middle"
            fill="#575757"
            style={{
              fontSize: 12,
              fontWeight: 500,
              lineHeight: 10,
              fontFamily: "Inter",
              color: "#575757",
            }}
          >
            {payload.value}
          </text>
        )}
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%" height={300}>
      <ComposedChart
        width={500}
        height={"100%"}
        data={data}
        margin={{
          top: 20,
          right: 20,
          bottom: 20,
          left: 20,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="year"
          scale="auto"
          tickLine={false}
          tick={<CustomTick />}
        />
        <YAxis
          axisLine={false}
          tickLine={false}
          style={{
            fontSize: 10,
            fontWeight: 500,
            lineHeight: 10,
            fontFamily: "Inter",
            color: "#7E8999",
          }}
          tickFormatter={(val) => formatNumberIntoReadableFormat(Number(val))}
        />
        {/* <Tooltip /> */}
        {/* // <Legend /> */}
        <Bar
          dataKey="scope"
          stackId={"a"}
          barSize={20}
          fill={
            Number(scopeNo) === 1
              ? "#BCE0FF"
              : Number(scopeNo) === 2
              ? "#79C1FF"
              : Number(scopeNo) === 3
              ? "#FFCB11"
              : ""
          }
        />

        <Line type="linear" dataKey="total" stroke="#7E8999" dot={false} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ChartWithLine;
