import React, { useState, useEffect } from "react";
import "../../logDashboard.css";
import Drawer from "@mui/material/Drawer";
import TickMark from "../../../../../Assets/icons/tickMark";
import axios from "../../../../../Api";
import { useAuth } from "../../../../../Utils/Auth";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import {
  CreditCardsJson,
  CreditCardsNameJson,
} from "../../../../../Utils/CommonFunctions";
import Loader from "../../../../../Assets/components/Loader";
import { toast } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import BankTransfer from "../../../../../Assets/images/BankTransfer.png";
import CreditCard from "../../../../../Assets/images/CreditCard.png";
import IDEAL from "../../../../../Assets/images/iDEAL.png";
import BankContact from "../../../../../Assets/images/BankContact.png";
import Sofort from "../../../../../Assets/images/Sofort.png";
import Sepa from "../../../../../Assets/images/Sepa.png";
import { KeyboardArrowDown } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import ButtonComp from "../../../../../Assets/components/Button";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none",
  borderBottom: "1px solid #E8EBEF",
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDown />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function ZeroCredits(props) {
  const [paymentMethods, setPaymentMethods] = useState([
    { id: null, type: "bank_transfer" },
  ]);
  const [selectedPayment, setselectedPayment] = useState(null);
  const [userAddress, setUserAddress] = useState(null);
  const [allAddressess, setallAddressess] = useState(null);
  const { user, setSessionExpire } = useAuth();
  const [isAcceptTerms, setisAcceptTerms] = useState(false);
  const [billingLoading, setBillingLoading] = useState(false);
  const [currStep, setcurrStep] = useState(0);
  const [expanded, setExpanded] = useState("panel1");
  const [options, setoptions] = useState(null);
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
  const [isSuccessPayment, setisSuccessPayment] = useState(false);
  const appearance = {
    theme: "stripe",
    rules: {
      ".Label": {
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "20px",
        textTransform: "uppercase",
        color: "#313B3E",
        marginBottom: "8px",
      },
    },
  };

  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleCardIntent = async (method) => {
    setBillingLoading(true);
    try {
      const response = await axios({
        url: `/stripe/payment-intent`,
        method: "get",
        params: {
          methodType: [method],
        },
      });
      setoptions({
        clientSecret: response.data.data.client_secret,
        appearance: appearance,
      });
      // setSelectBankTransfer(false);
      setBillingLoading(false);
      setcurrStep(2);
    } catch (error) {
      setBillingLoading(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  const handleUpdateFinalCart = async (invoice, paymentIntent, cart) => {
    try {
      setBillingLoading(true);
      const res = await axios.post(`/cart/update-success-cart/${cart}`, {
        invoice_id: invoice.id,
        invoice_url: invoice.hosted_invoice_url,
        invoice_pdf: invoice.invoice_pdf,
        paymentMethod: paymentIntent
          ? paymentIntent.payment_method
          : "bank_transfer",
        paymentIntent: paymentIntent ? paymentIntent.id : "",
        isPaid: paymentIntent ? true : false,
      });
      if (res.data.success) {
        setBillingLoading(false);
        toast.success("Purchase Complete");
        props.getItemCreditCount();
        setisSuccessPayment(true);
        // props.setActivateDrawer(true);
        // props.setToggleZeroCredit(false);
      } else {
        toast.error("Could not update credits, please contact support");
        setBillingLoading(false);
      }
    } catch (error) {
      setBillingLoading(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  const handleGenerateInvoiceAfterPayment = async (
    paymentIntent,
    status,
    cart
  ) => {
    try {
      setBillingLoading(true);
      let response = await axios({
        url: `/stripe/make/store-invoice/${cart}`, // update cart is paid, add all invoice items,
        method: "POST",
        data: {
          paymentType: selectedPayment.type,
          // paymentMethodId: selectedPayment.id,
          // paymentIntentId: paymentIntent_id,
          // date: new Date(),
          status: status,
          billing_id: userAddress._id,
        },
      });
      if (response.data.success) {
        setBillingLoading(false);

        return handleUpdateFinalCart(
          response.data.invoice,
          paymentIntent,
          cart
        );
        // toast.success("Purchase Complete");
        // return history(`/dashboard?tab=3`);
      }
      return toast.error("Invoice could not be processed");
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const handleConfirmPayment = async (intent, cart) => {
    if (intent.status === "succeeded") {
      setBillingLoading(false);
      await handleGenerateInvoiceAfterPayment(intent, intent.status, cart);
    } else if (
      intent.status === "requires_action" ||
      intent.status === "requires_confirmation"
    ) {
      var stripe = await stripePromise;
      if (selectedPayment.type === "card") {
        const { error: errorAction, paymentIntent } =
          await stripe.confirmCardPayment(intent.client_secret);

        if (errorAction) {
          setBillingLoading(false);
          return toast.error("Error in payment, please try again later");
        } else if (paymentIntent.status === "succeeded") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent,
            paymentIntent.status,
            cart
          );
        } else if (
          paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_confirmation"
        ) {
          await handlePurchaseCredit(intent.id);
        } else {
          setBillingLoading(false);
          toast.error("Please try again later");
        }
      } else if (selectedPayment.type === "sepa_debit") {
        const { error: errorAction, paymentIntent } =
          await stripe.confirmSepaDebitPayment(intent.client_secret);
        if (errorAction) {
          setBillingLoading(false);
          return toast.error("Error in payment, please try again later");
        } else if (paymentIntent.status === "succeeded") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent,
            paymentIntent.status,
            cart
          );
        } else if (paymentIntent.status === "processing") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent,
            paymentIntent.status,
            cart
          );
        }
      } else {
        setBillingLoading(false);
        toast.error("Please try again later");
      }
    } else {
      setBillingLoading(false);
      toast.error("Please try again later");
    }

    // if (paymentIntent && paymentIntent.client_secret) {
    //   setBillingLoading(true);
    //   var stripe = await stripePromise;
    //   var { error } = stripe.confirmPaymentIntent(paymentIntent.client_secret);
    //   if (error) {
    //     setBillingLoading(false);
    //     toast.error(error.message);
    //   } else {
    //     handleUpdateFinalCart(invoice, paymentIntent, cart);
    //     toast.success("Payment Successful");
    //     // history("/payment/success");
    //     setBillingLoading(false);
    //   }
    // }
  };

  const handlePurchaseCredit = async (intent) => {
    try {
      setBillingLoading(true);
      if (!isAcceptTerms) {
        setBillingLoading(false);
        return toast.error("Please accept terms and conditions");
      }
      let response = await axios.post(
        `/stripe/store-purchase?item=${props.item_id}`,
        {
          data: {
            payment_method: selectedPayment ? selectedPayment.id : null,
            paymentType: selectedPayment ? selectedPayment.type : null,
            billing_id: userAddress._id,
            paymentIntent: intent,
          },
        }
      );
      if (response.data.success) {
        setBillingLoading(false);
        let paymentIntent = response.data.data;
        let invoice = response.data.invoice;
        let cart = response.data.cart;
        if (selectedPayment && selectedPayment.type !== "bank_transfer") {
          handleConfirmPayment(paymentIntent, cart);
        } else {
          handleUpdateFinalCart(invoice, null, cart);
        }
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
      setBillingLoading(false);
    }
  };

  const getAddress = async () => {
    try {
      const res = await axios({
        url: `/address/address`,
        method: "get",
      });

      if (res.data.success) {
        setallAddressess(res.data.data);
        setUserAddress(res.data.data[0]);
      }
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const getPaymentMethods = async () => {
    await axios({
      url: `/stripe/paymentMethod`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data.data;
          let parArr = paymentMethods;
          parArr = [...parArr, ...data];
          setPaymentMethods(parArr);
          setselectedPayment(data[0] ? data[0] : null);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (props.toggleZeroCredit) {
      getAddress();
      getPaymentMethods();
    }
  }, [props.toggleZeroCredit]);

  return (
    <div>
      {billingLoading ? <Loader /> : null}

      <Drawer
        anchor={"right"}
        open={props.toggleZeroCredit}
        onClose={() => props.setToggleZeroCredit(false)}
        PaperProps={{
          sx: { width: "70%" },
        }}
        BackdropProps={{ style: { backgroundColor: "white", opacity: 0 } }}
      >
        <div className="fluid-container drawer_root">
          <div className="credit_heading_container pb-60 ">
            <div className="align_text_right mt-20 pr-20">
              <i
                className="bi bi-x-lg SideDrawer_close_icon pointer"
                onClick={() => props.setToggleZeroCredit(false)}
              ></i>
            </div>
            <div className="pl-35 mt-10">
              <p className="f-27 fw-700 font-inter">
                Activate your {props.itemName}
              </p>
              <p className="subheading_light_2 mt-10">
                Set up a new accounting {props.itemName}
              </p>
            </div>
          </div>

          {!isSuccessPayment ? (
            <div className="row credit_card_container p-40 ml-40 mr-40 mt-30 mb-40">
              <div className="col-lg-6">
                <div className="credit_items_center_md">
                  <p className="f-40 fw-700 font-inter">Connect a Credit</p>
                  <p className="body_text mt-16">
                    This credit was purchased by you and can now
                  </p>
                  <p className="body_text">
                    be connected to this service item, in order to
                  </p>
                  <p className="body_text">account and offset it.</p>
                  <p className="red_label fw-600 mt-16">
                    You have no {props.itemName} Credits
                  </p>
                </div>
                <div className="mt-40 credit_items_center_md">
                  <div className="mb-24 row align-items-center mx-0">
                    <input
                      type="radio"
                      name="t&c"
                      checked={isAcceptTerms}
                      onClick={() => setisAcceptTerms(!isAcceptTerms)}
                      className="fit-w"
                    />
                    <span className="breadcrumb_text_light_1 ml-4 col pl-8">
                      I accept the{" "}
                      <a
                        href="https://www.today.green/legal/agb"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="fw-bold termsNconditionsAnchor"
                      >
                        terms & conditions
                      </a>{" "}
                      from today.green
                    </span>
                  </div>
                  <ButtonComp
                    status={1}
                    onClick={() => {
                      handlePurchaseCredit();
                    }}
                    disabled={!isAcceptTerms}
                    className="xl"
                  >
                    Buy Credit & Activate
                  </ButtonComp>
                </div>
              </div>

              <div className="col-lg-6 p-16 pt-0">
                <div className="credit_details ">
                  <div className="credit_border_bottom p-4">
                    <p className="card_heading">New {props.itemName}</p>
                  </div>

                  <div className="p-4 f-13 fw-600 font-inter">
                    <div className="mt-2">
                      <TickMark />{" "}
                      <span className="ml-6">Accounting for 1 year</span>
                    </div>
                    <div className="mt-2">
                      <TickMark />{" "}
                      <span className="ml-6">
                        Carbon Neutral Label(excl. contribution expenses)
                      </span>
                    </div>
                    <div className="mt-2">
                      <TickMark />{" "}
                      <span className="ml-6">Unlimited GHG Reports</span>
                    </div>
                  </div>

                  <div className="p-4 pt-2">
                    <p className="credit_price_tag">
                      {props.creditPrice} &euro;
                      <span className="breadcrumb_text_light_1 ml-10">
                        /mo ([aid yearly)
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <p className="font_17_700 text-uppercase mt-40">
                Payment information
              </p>
              <div className="mt-32 d-flex mx-0 mb-32">
                {userAddress && (
                  <div className=" col-6 pe-2 ps-0">
                    <div className="general_card">
                      <div className="d-flex justify-content-between">
                        <p className="heading_6">
                          Billing Address{" "}
                          {/* <span className="purple_label ms-3">default</span> */}
                        </p>
                      </div>
                      <p className="mt-12 subheading_2">
                        {userAddress.CompanyName}
                        <br />
                        {userAddress.firstName} {userAddress.lastName}
                        <br />
                        {userAddress.City} {userAddress.street}
                        <br />
                        {userAddress.zipCode}
                        <br />
                        {userAddress.State}, {userAddress.Country}
                      </p>
                    </div>
                  </div>
                )}
                {selectedPayment ? (
                  <div className="col-6 ps-2 pe-0">
                    <div className="general_card">
                      <div className="d-flex justify-content-between">
                        <p className="heading_6 mb-12">
                          Payment{" "}
                          {/* <span className="purple_label ms-3">default</span> */}
                        </p>
                      </div>
                      {selectedPayment.type === "card" ? (
                        <>
                          <img
                            src={CreditCardsJson[selectedPayment.card.brand]}
                            alt={selectedPayment.card.brand}
                          />
                          <p className="mt-12 font_14_500">
                            {CreditCardsNameJson[selectedPayment.card.brand]}:
                            <span className="ml-8">
                              {selectedPayment.card.last4}
                            </span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Exp. Date:
                            <span className="ml-8">
                              {selectedPayment.card.exp_month}/
                              {selectedPayment.card.exp_year}
                            </span>
                          </p>
                          {/* <p>Name: Maxine Murphy</p> */}
                        </>
                      ) : selectedPayment.type === "sepa_debit" ? (
                        <>
                          <p className="font_14_500 mt-1">
                            {selectedPayment.type}:
                            <span className="ml-8">
                              {selectedPayment.sepa_debit.last4}
                            </span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Bank :
                            <span className="ml-8">
                              {selectedPayment.sepa_debit.country}
                              {selectedPayment.sepa_debit.bank_code}
                            </span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Name :
                            <span className="ml-8">
                              {selectedPayment.billing_details.name}
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="text-uppercase mb-10 heading_6">
                            Name on Invoice
                          </p>
                          <p className="subheading_2">
                            {user.firstname} {user.lastname}
                          </p>
                          <p className="subheading_2">{user.email}</p>
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="register_address_sub">
                    <p className="heading_6 mb-12">
                      Payment <span className="purple_label ms-3">default</span>
                    </p>
                    <p className="text-uppercase mb-10 heading_6">
                      Name on Invoice
                    </p>
                    <p className="subheading_2">
                      {user.firstname} {user.lastname}
                    </p>
                    <p className="subheading_2">{user.email}</p>
                  </div>
                )}
              </div>

              <hr />

              {currStep === 1 ? (
                <div>
                  <p className="font_17_700 text-uppercase mt-40">
                    Add a new Payment method
                  </p>
                  <div className="py-3">
                    <Accordion
                      expanded={expanded === 1}
                      onChange={handleExpandChange(1)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={Sepa} alt="Sepa" />
                            <p className="subheading_light_2 color_black ml-12">
                              SEPA Direct Debit
                            </p>
                            <div className="subtitle_box bg_soft_green ml-12">
                              <p className="color_green subheading_4">
                                0% Fees
                              </p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: Sepa,
                                title: "SEPA Direct Debit",
                                fees: "0% Fees",
                                bg: "bg_soft_green",
                                color: "color_green",
                                buttonText: "Authorize Mandate",
                                forwardBtn: false,
                              });
                              handleCardIntent("sepa_debit");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Select &apos;SEPA Direct Debit&apos; for an automatic
                          payment withdrawl. Provide your IBAN number, name and
                          email adress. Once the transaction has been completed,
                          you&apos;ll gain instant access to today.green. This
                          payment method does not include any additional
                          charges.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 2}
                      onChange={handleExpandChange(2)}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={BankTransfer} alt="Sepa" />
                            <p className="subheading_light_2 color_black ml-12">
                              Bank Transfer (Invoice)
                            </p>
                            <div className="subtitle_box bg_soft_green ml-12">
                              <p className="color_green subheading_4">
                                0% Fees
                              </p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setselectedPayment(paymentMethods[0]);
                              setcurrStep(0);
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          This payment method provides flexibility when you
                          prefer to work with traditional invoices. Enter your
                          billing information and confirm your selection by
                          clicking &apos;Purchase & Download Invoice&apos; - the
                          invoice will download automatically. Make sure to
                          submit your payment within 14 days. Once the
                          transaction has been completed, you can start using
                          today.green.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 3}
                      onChange={handleExpandChange(3)}
                    >
                      <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={CreditCard} alt="Sepa" />
                            <p className="subheading_light_2 color_black ml-12">
                              Credit Card
                            </p>
                            <div className="subtitle_box bg_soft_orange ml-12">
                              <p className="color_orange subheading_4">+1.9%</p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: CreditCard,
                                title: "Credit Card",
                                fees: "+1.9%",
                                bg: "bg_soft_orange",
                                color: "color_orange",
                                buttonText: "Save & Continue",
                                forwardBtn: false,
                              });
                              handleCardIntent("card");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Pay with ease using the credit card payment method.
                          Enter your credit card information and get instant
                          access to today.green. This payment method includes an
                          additional charge of 1.9%.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 4}
                      onChange={handleExpandChange(4)}
                    >
                      <AccordionSummary
                        aria-controls="panel4d-content"
                        id="panel4d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={Sofort} alt="Sofort" />
                            <p className="subheading_light_2 color_black ml-12">
                              Sofort
                            </p>
                            <div className="subtitle_box bg_soft_orange ml-12">
                              <p className="color_orange subheading_4">+1.4%</p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: Sofort,
                                title: "Sofort",
                                fees: "+1.4%",
                                bg: "bg_soft_orange",
                                color: "color_orange",
                                buttonText: "Continue to SOFORT",
                                forwardBtn: true,
                              });
                              handleCardIntent("sofort");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Choose &apos;Sofort&apos; for a quick and secure bank
                          transfer. You&apos;ll be redirected to your
                          bank&apos;s online portal to authenticate your
                          payment. Please note, it may take 2 to 14 days for
                          transaction confirmation. Once the transaction has
                          been completed, you can start using today.green. This
                          payment method includes and additional charge of 1.4%.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 5}
                      onChange={handleExpandChange(5)}
                    >
                      <AccordionSummary
                        aria-controls="panel5d-content"
                        id="panel5d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={BankContact} alt="Bankcontact" />
                            <p className="subheading_light_2 color_black ml-12">
                              Bankcontact
                            </p>
                            <div className="subtitle_box bg_soft_orange ml-12">
                              <p className="color_orange subheading_4">+1.4%</p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: BankContact,
                                title: "Bankcontact",
                                fees: "+1.4%",
                                bg: "bg_soft_orange",
                                color: "color_orange",
                                buttonText: "Continue to Bankcontact",
                                forwardBtn: true,
                              });
                              handleCardIntent("bancontact");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          Select &apos;Bankcontact&apos; for a quick and secure
                          bank transfer. You&apos;ll be redirected to Bancontact
                          to authorize your payment. Once the transaction has
                          been completed, you can start using today.green. This
                          payment method includes and additional charge of 1.4%.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={expanded === 6}
                      onChange={handleExpandChange(6)}
                    >
                      <AccordionSummary
                        aria-controls="panel6d-content"
                        id="panel6d-header"
                      >
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img src={IDEAL} alt="iDEAL" />
                            <p className="subheading_light_2 color_black ml-12">
                              iDEAL
                            </p>
                            <div className="subtitle_box bg_soft_green ml-12">
                              <p className="color_green subheading_4">
                                0% Fees
                              </p>
                            </div>
                          </div>
                          <ButtonComp
                            status={3}
                            className="text-black"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedPaymentInfo({
                                img: IDEAL,
                                title: "iDEAL",
                                fees: "0% Fees",
                                bg: "bg_soft_green",
                                color: "color_green",
                                buttonText: "Continue to iDEAL",
                                forwardBtn: true,
                              });
                              handleCardIntent("ideal");
                            }}
                          >
                            Select
                          </ButtonComp>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <p className="body_text_s_light">
                          iDeal allows for a quick and secure payment. Provide
                          your bank name and account holder information to
                          continue to iDeal. Confirm your payment and instantly
                          gain access to today.green. No additional charges are
                          included.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              ) : currStep === 2 ? (
                options && (
                  <Elements stripe={stripePromise} options={options}>
                    <CheckoutForm
                      selectedPaymentInfo={selectedPaymentInfo}
                      setcurrStep={setcurrStep}
                      getPaymentMethods={getPaymentMethods}
                    />
                  </Elements>
                )
              ) : currStep === 2 ? (
                <></>
              ) : null}

              <p className="font_17_700 text-uppercase mt-40">
                Other Payment Options
              </p>
              <div className="row mx-0 mt-40">
                <div className="col-6 pe-2 ps-0">
                  <div className="d-flex flex-column">
                    {allAddressess &&
                      allAddressess.map((address, i) => (
                        <div className="general_card w-100 mb-20" key={i}>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="heading_6">Billing Address</p>
                            <ButtonComp
                              status={3}
                              className="text-black fit-w"
                              onClick={() => setUserAddress(address)}
                              disabled={userAddress._id === address._id}
                            >
                              Select
                            </ButtonComp>
                          </div>
                          <p className="mt-12 subheading_2">
                            {address.CompanyName}
                            <br />
                            {address.firstName} {address.lastName}
                            <br />
                            {address.City} {address.street}
                            <br />
                            {address.zipCode}
                            <br />
                            {address.State}, {address.Country}
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-6 ps-2 pe-0">
                  <div className="d-flex flex-column">
                    {paymentMethods &&
                      paymentMethods.map((pay, i) => (
                        <div className="general_card w-100 mb-20 ml-0" key={i}>
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="heading_6">Payment</p>
                            <ButtonComp
                              status={3}
                              className="text-black fit-w"
                              onClick={() => setselectedPayment(pay)}
                              disabled={
                                selectedPayment && selectedPayment.id === pay.id
                              }
                            >
                              Select
                            </ButtonComp>
                          </div>
                          {pay.type === "card" ? (
                            <>
                              <img
                                src={CreditCardsJson[pay.card.brand]}
                                alt={pay.card.brand}
                              />
                              <p className="mt-12 font_14_500">
                                {CreditCardsNameJson[pay.card.brand]}:
                                <span className="ml-8">{pay.card.last4}</span>
                              </p>
                              <p>
                                {" "}
                                Exp. Date: {pay.card.exp_month}/
                                {pay.card.exp_year}
                              </p>
                              {/* <p>Name: Maxine Murphy</p> */}
                            </>
                          ) : pay.type === "sepa_debit" ? (
                            <>
                              <p>
                                {pay.type}:{pay.sepa_debit.last4}{" "}
                              </p>
                              <p>
                                Bank : {pay.sepa_debit.country}
                                {pay.sepa_debit.bank_code}
                              </p>
                              <p>Name : {pay.billing_details.name}</p>
                            </>
                          ) : (
                            <>
                              <p className="text-uppercase mb-10 heading_6">
                                Name on Invoice
                              </p>
                              <p className="subheading_2">
                                {user.firstname} {user.lastname}
                              </p>
                              <p className="subheading_2">{user.email}</p>
                            </>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="credit_card_container p-40 ml-40 mr-40 mt-30">
              <div className="row">
                <div className="col-8">
                  <p className="f-40 fw-700 font-inter">✅ Credit Purchased!</p>
                  <p className="body_text mt-16">
                    We have sent you the invoice via mail. You can also download
                    it in the billing section in your settings panel.
                    <br />
                    <br />
                    Once you don’t want to account for this Service anymore, you
                    can close the accounting on this item in the items settings.
                    This will retire the accounting of carbon emissions for this
                    item and make the connected credit available again.
                  </p>
                </div>
                <div className="col-4"></div>
              </div>
              <hr />
              <ButtonComp
                status={1}
                // className="lg"
                onClick={() => {
                  props.setActivateDrawer(true);
                  props.setToggleZeroCredit(false);
                  setisSuccessPayment(false);
                }}
              >
                Continue to Accounting Set-Up &nbsp;
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="16"
                  fill="currentColor"
                  className="bi bi-arrow-right"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                  />
                </svg>
              </ButtonComp>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default ZeroCredits;
