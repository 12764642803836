import { Drawer, InputAdornment, TextField } from "@mui/material";
import React, { useState, useRef } from "react";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import LeftChevronIcon from "../../../../../../../../Assets/icons/LeftChevronIcon";
import RightChevronIcon from "../../../../../../../../Assets/icons/RightChevronIcon";
import { Close, HelpOutlined } from "@mui/icons-material";
import SearchIcon from "../../../../../../../../Assets/icons/SearchIcon";
import { getServiceIcon } from "../../../../../../../../Utils/itemIcon";
import EmissionPeriod from "../../../../../../Dashboard/Components/QuickLog/Components/EmissionPeriod";
import CalculationMethod from "../../../../../../Dashboard/Components/QuickLog/Components/CalculationMethod";
import Axios from "../../../../../../../../Api";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import toast from "react-hot-toast";
import { useAuth } from "../../../../../../../../Utils/Auth";
import { FullMonthNames } from "../../../../../../../../Utils/CommonFunctions";
import { useEffect } from "react";

const amountStructure = {
  amount: "",
  comment: "",
  noOfUnit: 1,
  emission: 0,
  isEditable: true,
};

const LogDrawer = ({
  classList,
  quickLogDrawer,
  handleQuickLogDrawer,
  logId,
  itemId,
  yearList,
  selectedMonth,
  setSelectedMonth,
  scopeIndex,
  selectedScope,
  getMatrixCellData,
  logItemName,
}) => {
  const { setSessionExpire } = useAuth();
  const scrollContainerRef = useRef();
  const [quickLogStep, setQuickLogStep] = useState(0);
  const [filterValue, setFilterValue] = useState("");
  const [periodType, setPeriodType] = useState("Month");
  const [activities, setActivities] = useState(null);
  const [activityTypes, setActivityTypes] = useState(null);
  const [activitySubTypes, setActivitySubTypes] = useState(null);
  const [allUnits, setAllUnits] = useState([]);
  const [amounts, setAmounts] = useState([{ ...amountStructure }]);
  //
  const [selectedCalcMethodName, setSelectedCalcMethodName] = useState("");
  const [selectedActivityName, setSelectedActivityName] = useState("");
  const [selectedActivityTypeName, setSelectedActivityTypeName] = useState("");
  const [selectedActivitySubTypeName, setSelectedActivitySubTypeName] =
    useState("");
  const [selectedUnit, setSelectedUnit] = useState("");
  const [selectedSubClass, setSelectedSubClass] = useState(null);
  const [selectedsubClassHead, setSelectedSubClassHead] = useState("");
  const [selectedYear, setSelectedYear] = useState(
    new Date().getFullYear().toString()
  );
  const [disabled, setDisabled] = useState(false);
  const [showTimeframeAtHeader, setShowTimeframeAtHeader] = useState(false);

  const cleanAllStates = () => {
    setPeriodType("Month");
    setActivities(null);
    setActivityTypes(null);
    setActivitySubTypes(null);
    setAllUnits([]);
    setAmounts([{ ...amountStructure }]);
    setSelectedSubClass(null);
    setSelectedSubClassHead("");
    setSelectedCalcMethodName("");
    setSelectedActivityName("");
    setSelectedActivityTypeName("");
    setSelectedActivitySubTypeName("");
    setSelectedUnit("");
    setSelectedMonth(0);
    setSelectedYear(new Date().getFullYear().toString());
    setQuickLogStep(0);
    setShowTimeframeAtHeader(false);
  };

  const handleSelectClasss = (currentClass, subclassHead) => {
    setSelectedSubClassHead(subclassHead);
    setSelectedSubClass(currentClass);
    setSelectedCalcMethodName("");
    setActivities(null);
    setActivityTypes(null);
    setActivitySubTypes(null);
    setAllUnits([]);
    setAmounts([{ ...amountStructure }]);
  };

  const handleStepIncrease = () => {
    setQuickLogStep(quickLogStep + 1);
    setFilterValue("");
  };

  const handleStepDecrease = () => {
    if (quickLogStep > 0) {
      setQuickLogStep(quickLogStep - 1);
      setFilterValue("");
    }
  };

  useEffect(() => {
    if (quickLogStep === 2) {
      setShowTimeframeAtHeader(true);
    }
  }, [quickLogStep]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollLeft =
        scrollContainerRef.current.scrollWidth;
    }
  }, [
    scrollContainerRef.current,
    logItemName,
    selectedScope,
    selectedSubClass,
    showTimeframeAtHeader,
  ]);

  // create log api
  const handleMatrixCellSubmit = async () => {
    try {
      const matriCellData = {
        matrix_id: "",
        GHGscope: "",
        logId: logId,
        itemId: itemId,
        isFullYear: periodType === "Month" ? false : true,
        month: selectedMonth + 1,
        year: Number(selectedYear),
        scopeClass: selectedScope.class,
        scopeIndex: scopeIndex,
        subScope: selectedScope.scope,
        particulars: {
          name: "",
          activity: selectedActivityName,
          calculationMethod: selectedCalcMethodName,
          type: selectedActivityTypeName,
          subType: selectedActivitySubTypeName,
          inputMethod: "",
          subClassHead: selectedsubClassHead,
          subClass: selectedSubClass.Subclass,
          conversionFactor:
            selectedCalcMethodName === "Direct tCO2e Entry"
              ? 1
              : selectedUnit.kgCO2e,
          unit: selectedUnit.unit,
          amounts: amounts,
        },
      };
      setDisabled(true);
      await Axios({
        url: `/cell/create-cell`,
        method: "POST",
        data: matriCellData,
      })
        .then(async (res) => {
          setDisabled(false);
          if (res.data.success) {
            // setQuickLogStep(quickLogStep + 1);
            await getMatrixCellData();
            toast.success(res.data.message);
            cleanAllStates();
            handleQuickLogDrawer();
          }
        })
        .catch((err) => {
          setDisabled(false);
          toastResponseError(err, setSessionExpire);
        });
    } catch (err) {
      setDisabled(false);
      toast.error(err.message);
    }
  };

  return (
    <div>
      <Drawer
        anchor={"right"}
        open={quickLogDrawer}
        onClose={() => {
          if (quickLogStep === 0 && selectedSubClass === null) {
            handleQuickLogDrawer();
          }
        }}
        PaperProps={{
          sx: { overflow: "hidden" },
        }}
        // sx={{ zIndex: 10000000000 }}
      >
        <div className="quickLog-container">
          <div className="quickLog-main">
            <div className="d-flex justify-content-between align-items-center">
              {quickLogStep === 5 ? (
                <p className="font_24_500 line_height_10 ">
                  Your emission log was added.
                </p>
              ) : (
                <div className="d-flex align-items-center">
                  <p className="font_24_500">Accounting item </p>
                  <HelpOutlined
                    fontSize="small"
                    sx={{ height: 20, width: 20, marginLeft: "20px" }}
                  />
                </div>
              )}
              <Close
                htmlColor="#4C4C4C"
                className="pointer"
                onClick={() => {
                  cleanAllStates();
                  handleQuickLogDrawer();
                }}
              />
            </div>

            {logItemName && quickLogStep < 3 && (
              <div
                className="mt-30 d-flex align-items-center gap_10 quick_show_items_main_box"
                ref={scrollContainerRef}
              >
                <div className="bg_soft_green_53a border_radius_5 quick_show_items_box">
                  <p className="font_12_500 line_height_10 text-white">
                    {logItemName}
                  </p>
                </div>
                {selectedScope && (
                  <div className="bg_dark_yellow border_radius_5 quick_show_items_box">
                    <p className="font_12_500 line_height_10 text-white">
                      {selectedScope.scope}
                    </p>
                  </div>
                )}
                {selectedSubClass && (
                  <div className="bg_soft_purple border_radius_5 quick_show_items_box">
                    <p className="font_12_500 line_height_10 text-white">
                      {selectedSubClass.Subclass}
                    </p>
                  </div>
                )}
                {showTimeframeAtHeader && (selectedMonth || selectedYear) && (
                  <div className="bg_soft_sky_blue border_radius_5 quick_show_items_box">
                    {periodType === "Month" ? (
                      <p className="font_12_500 line_height_10 text-white">
                        {FullMonthNames[selectedMonth]} - {selectedYear}
                      </p>
                    ) : (
                      <p className="font_12_500 line_height_10 text-white">
                        {selectedYear}
                      </p>
                    )}
                  </div>
                )}
              </div>
            )}

            {quickLogStep === 0 && (
              <div className="mt-30">
                <TextField
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                  className="font_12_500"
                  id="input-with-icon-textfield"
                  placeholder={
                    quickLogStep === 0
                      ? "Accounting Item"
                      : quickLogStep === 1
                      ? "Subscope"
                      : "Emission Source"
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    width: "100%",
                    borderRadius: "5px",
                    boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
                    "& .MuiInputBase-root": {
                      height: 40,
                      width: "100%",
                      border: "none",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                    },
                    "& .MuiInputBase-input": {
                      fontFamily: "Inter",
                      fontSize: " 12px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "10px",
                      paddingLeft: "4px",
                    },
                  }}
                />
              </div>
            )}
            {quickLogStep === 0 ? (
              classList &&
              classList.length > 0 &&
              classList.map((currentClass, currentClassIndex) => {
                const dataArr = Object.entries(currentClass);
                const heading = dataArr[0][0];
                const subclasses = dataArr[0][1];
                return (
                  <div className="mt-30" key={currentClass + currentClassIndex}>
                    <p className="font_14_600 color_7e">{heading}</p>

                    {subclasses &&
                      subclasses.length > 0 &&
                      subclasses
                        .filter((currentSubClass) => {
                          if (filterValue === "") {
                            return currentSubClass;
                          }
                          return String(currentSubClass.Subclass)
                            .toLowerCase()
                            .includes(filterValue.toLowerCase());
                        })
                        .map((currentSubClass, currentSubClassIndex) => {
                          return (
                            <div
                              key={
                                "subclass" +
                                currentSubClass.name +
                                currentSubClassIndex
                              }
                              className="d-flex justify-content-between align-items-center bg_f6 pl-10 pt-10 pr-10 pb-10 mt-10 pointer"
                              onClick={() => {
                                handleSelectClasss(currentSubClass, heading);
                              }}
                            >
                              <div className="d-flex align-items-center">
                                <img
                                  src={getServiceIcon(currentSubClass.Subclass)}
                                  alt="accounting item "
                                  className="img_20"
                                />
                                <p className="font_14_500 ml-10 line_height_10">
                                  {currentSubClass.Subclass}
                                </p>
                              </div>
                              <div className="">
                                {selectedSubClass &&
                                selectedsubClassHead &&
                                selectedSubClass.Subclass ===
                                  currentSubClass.Subclass &&
                                selectedsubClassHead === heading ? (
                                  <div
                                    onClick={() => {
                                      handleSelectClasss(
                                        currentSubClass,
                                        heading
                                      );
                                    }}
                                    className="quickLog-radio-container w-25px h-25px bg_009 br_round"
                                  ></div>
                                ) : (
                                  <div
                                    onClick={() => {
                                      handleSelectClasss(
                                        currentSubClass,
                                        heading
                                      );
                                    }}
                                    className="quickLog-radio-container w-25px h-25px bg_white br_round"
                                  ></div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                  </div>
                );
              })
            ) : quickLogStep === 1 ? (
              <EmissionPeriod
                periodType={periodType}
                setPeriodType={setPeriodType}
                yearList={yearList}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                selectedYear={selectedYear}
                setSelectedYear={setSelectedYear}
                logId={logId}
                isQuickLog={false}
              />
            ) : quickLogStep === 2 ? (
              <CalculationMethod
                selectedSubClass={selectedSubClass}
                selectedCalcMethodName={selectedCalcMethodName}
                setSelectedCalcMethodName={setSelectedCalcMethodName}
                selectedActivityName={selectedActivityName}
                setSelectedActivityName={setSelectedActivityName}
                selectedActivityTypeName={selectedActivityTypeName}
                setSelectedActivityTypeName={setSelectedActivityTypeName}
                selectedActivitySubTypeName={selectedActivitySubTypeName}
                setSelectedActivitySubTypeName={setSelectedActivitySubTypeName}
                activities={activities}
                setActivities={setActivities}
                activityTypes={activityTypes}
                setActivityTypes={setActivityTypes}
                activitySubTypes={activitySubTypes}
                setActivitySubTypes={setActivitySubTypes}
                allUnits={allUnits}
                setAllUnits={setAllUnits}
                selectedUnit={selectedUnit}
                setSelectedUnit={setSelectedUnit}
                amounts={amounts}
                setAmounts={setAmounts}
                amountStructure={amountStructure}
              />
            ) : (
              {
                /* quickLogStep === 3 && (
                <EndScreen
                  cleanAllStates={cleanAllStates}
                  handleQuickLogDrawer={() => handleQuickLogDrawer()}
                />
              ) */
              }
            )}
          </div>
          <div className="quickLog-footer">
            {quickLogStep > 0 && (
              <ButtonComp
                onClick={() => {
                  handleStepDecrease();
                }}
                status={6}
                size="sm"
                style={{ height: 40 }}
                className="bg_d9"
              >
                <LeftChevronIcon className={"mr-10"} /> Go Back
              </ButtonComp>
            )}
            {quickLogStep === 2 ? (
              <ButtonComp
                onClick={() => {
                  handleMatrixCellSubmit();
                }}
                status={5}
                size="sm"
                style={{ height: 40 }}
                disabled={disabled}
              >
                Save & Add
              </ButtonComp>
            ) : (
              quickLogStep < 2 && (
                <ButtonComp
                  onClick={() => {
                    handleStepIncrease();
                    // handleMatrixCellSubmit();
                  }}
                  status={5}
                  size="sm"
                  style={{ height: 40 }}
                >
                  Next Step <RightChevronIcon className={"ml-10"} />
                </ButtonComp>
              )
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default LogDrawer;
