import React, { useState, useEffect } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatNumberIntoReadableFormat } from "../../../../../../../../../../Utils/CommonFunctions";

const CustomTooltip = ({ active, data, barActiveIndex }) => {
  const [scopeObj, setScopeObj] = useState(null);
  useEffect(() => {
    if (data && barActiveIndex && data[barActiveIndex]) {
      const obj = {};
      let temp = Object.keys(data[barActiveIndex]);
      temp.forEach((item) => {
        if (item != "month") {
          obj[item] = data[barActiveIndex][item];
        }
      });
      setScopeObj(obj);
    }
  }, [data, barActiveIndex, active]);

  if (
    active &&
    data &&
    data.length &&
    barActiveIndex != null &&
    scopeObj !== null
  ) {
    return (
      <div className="d-flex align-items-center custom_tooltip_">
        {Object.entries(scopeObj).map(([key, value], i) => (
          <div className="d-flex align-items-center mr-16" key={i}>
            {key === "Scope 1" ? (
              <span className="span_cirlce bg_blue_scop1 mr-8"></span>
            ) : key === "Scope 2" ? (
              <span className="span_cirlce bg_blue mr-8"></span>
            ) : (
              key === "Scope 3" && (
                <span className="span_cirlce bg_blue_hard mr-8"></span>
              )
            )}
            <p className="tooltip_chart_text">
              {Number(value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              t
            </p>
          </div>
        ))}
      </div>
    );
  }
};

function ProductDetailEmissionChart({ data, year }) {
  const [barActiveIndex, setBarActiveIndex] = useState(null);
  const onMouseMove = (state) => {
    if (state.isTooltipActive) {
      setBarActiveIndex(state.activeTooltipIndex);
    } else setBarActiveIndex(null);
  };
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        data={data}
        width={500}
        height={300}
        barGap={"30%"}
        onMouseMove={onMouseMove}
        barCategoryGap="38%"
        bar
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#efefef" />
        {/* <XAxis dataKey="month" />  */}
        <XAxis
          dataKey="month"
          tickFormatter={(_, i) => `${String(i + 1).padStart(2, "0")}-${year}`}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
          tickLine={{ display: "none" }}
          // interval={interval ? interval : 0}
        />

        <YAxis
          tickFormatter={(val) => `${formatNumberIntoReadableFormat(val)} t`}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
        />

        <Tooltip
          content={
            <CustomTooltip data={data} barActiveIndex={barActiveIndex} />
          }
          cursor={{ fill: "transparent" }}
        />
        {/* <Legend  wrapperStyle={{ lineHeight: '40px', marginLeft:"30px" }} align={"left"} iconType="circle" iconSize={10} /> */}

        <Bar dataKey="Scope 1" stackId="a" fill="#BCE0FF" />
        <Bar dataKey="Scope 2" stackId="a" fill="#79C1FF" />
        <Bar
          dataKey="Scope 3"
          stackId="a"
          fill="#FFCB11"
          radius={[0, 0, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default ProductDetailEmissionChart;
