import React from 'react'
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 5,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:"#EFEFEF"
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: theme.palette.mode === "light" ? "#00915B" : "#CDF1E3"
  }
}));

function SmallProgressBar(props) {
  return (
    <BorderLinearProgress variant="determinate" value={props.val} />
  )
}

export default SmallProgressBar