import React, { useState, useEffect } from "react";
import "bootstrap-icons/font/bootstrap-icons.css";
import "../../Components/common.css";
import "../auth.css";
// import Icon from "../../Assets/components/logo_sm";
import GoogleIcon from "../../Assets/components/google_icon";
import axios from "../../Api/index";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LinkedInIcon from "../../Assets/icons/linkedInIcon";
//import axios  from "axios";
import CryptoJS from "crypto-js";
import PasswordMeter from "../../Components/CommonComponents/PasswordMeter/PasswordMeter";
import { Visibility, VisibilityOff } from "@mui/icons-material";
// import bg from "../../Assets/images/bg.jpg";
import useQuery from "../../Utils/query";
import { invitedUserSingupCode } from "../../Utils/UserRoleConstAndFunc";
import { toastResponseError } from "../../Utils/toastResponseError";
import { useAuth } from "../../Utils/Auth";
import ButtonComp from "../../Assets/components/Button";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";
import Icon from "../../Assets/components/logo_sm";

// const user_regex = /^[a-zA-Z]{2,30}$/;
const user_regex =
  /^[a-zA-Z\xC0-\uFFFF]+([ \-'¿¡]{0,1}[a-zA-Z\xC0-\uFFFF]+){0,2}[.]{0,1}$/;
const email_regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
const password_regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,}$/;

export default function Signup() {
  const history = useNavigate();
  const { setSessionExpire } = useAuth();
  const [passwordType, setPasswordType] = useState("password");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [validName] = useState(false);
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [validFirstName, setValidFirstName] = useState(false);
  const [validLastName, setValidLastName] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [tokenVerified, setTokenVerified] = useState(false);
  const query = useQuery();
  const token = query.get("token");
  const invitedUserId = query.get("userid");

  // const [videoLoaded, setVideoLoaded] = useState(false);

  // useEffect(() => {
  //   const video = document.createElement("video");
  //   video.src = AnimationVideos[0];

  //   video.addEventListener("canplaythrough", () => {
  //     setVideoLoaded(true);
  //   });

  //   return () => {
  //     // Cleanup event listeners and remove the video element
  //     video.removeEventListener("canplaythrough", () => {
  //       setVideoLoaded(true);
  //     });
  //     video.remove();
  //   };
  // }, []);

  useEffect(() => {
    if (token) {
      if (token === invitedUserSingupCode) {
        setTokenVerified(true);
      }
    }
  }, [token]);

  useEffect(() => {
    setValidFirstName(user_regex.test(firstName.trim()));
  }, [firstName]);
  //eslint and priettier seetup
  useEffect(() => {
    setValidLastName(user_regex.test(lastName.trim()));
  }, [lastName]);

  useEffect(() => {
    setValidEmail(email_regex.test(email.trim()));
    if (email.trim().length === 0) setValidEmail(false);
  }, [email]);

  useEffect(() => {
    setValidPassword(password_regex.test(password));
  }, [password]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const firstNameCheck = user_regex.test(firstName.trim());
    const lastNameCheck = user_regex.test(lastName.trim());
    // const emailCheck = email_regex.test(email.trim());
    const passCheck = password_regex.test(password);
    if (!firstNameCheck || !lastNameCheck || !passCheck) {
      toast.error("Invalid Entry");
      return;
    }
    if (!validEmail) {
      toast.error("Please use work email");
      return;
    }
    try {
      // after this email verify page,
      await axios
        .post(
          "/user/auth/signup",
          {
            firstname: firstName.trim(),
            lastname: lastName.trim(),
            email: email,
            password: CryptoJS.AES.encrypt(
              password,
              "H~jP,/%6R*'Tc|xg"
            ).toString(),
            type: "admin",
          } //user: main user, employee user, conslutant
        )
        .then((res) => {
          if (res.data.success) {
            toast.success("Signup complete");
            setFirstName("");
            setLastName("");
            setEmail("");
            setPassword("");
            history("/email-verify");
          }
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
      // setSuccess(true);
    } catch (err) {
      if (!err?.response) {
        toast.error("No Server Response");
      } else if (err.response?.status === 409) {
        toast.error("Username Taken");
      } else {
        toast.error("Registration Failed");
      }
      // errRef.current.focus();
    }
  };

  const handleInvitedSignup = async (e) => {
    e.preventDefault();
    if (tokenVerified && invitedUserId) {
      const firstNameCheck = user_regex.test(firstName.trim());
      const lastNameCheck = user_regex.test(lastName.trim());
      // const emailCheck = email_regex.test(email.trim());
      const passCheck = password_regex.test(password);
      if (!firstNameCheck || !lastNameCheck || !passCheck) {
        toast.error("Invalid Entry");
        return;
      }
      if (!validEmail) {
        toast.error("Please use work email");
        return;
      }
      try {
        // after this email verify page,
        await axios
          .post(
            "/company/signup",
            {
              firstname: firstName,
              lastname: lastName,
              email: email,
              password: CryptoJS.AES.encrypt(
                password,
                "H~jP,/%6R*'Tc|xg"
              ).toString(),
              invitedUserId,
            } //user: main user, employee user, conslutant
          )
          .then((res) => {
            if (res.data.success) {
              toast.success("Signup complete");
              setFirstName("");
              setLastName("");
              setEmail("");
              setPassword("");
              history("/");
            }
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
        // setSuccess(true);
      } catch (err) {
        if (!err?.response) {
          toast.error("No Server Response");
        } else if (err.response?.status === 409) {
          toast.error("Username Taken");
        } else {
          toast.error("Registration Failed");
        }
        // errRef.current.focus();
      }
    } else {
      toast.error("You are not with your correct url, please try again");
    }
  };

  // const handleLinkedinSignup = async () => {
  //   // e.preventDefault();
  //   // after this email verify page,
  //   await axios.get("http://localhost:5000/auth/linkedin");
  // };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  useEffect(() => {
    if (invitedUserId) {
      (async () => {
        await axios({
          url: `/company/get-invited-user/${invitedUserId}`,
          method: "GET",
        })
          .then((res) => {
            const { data } = res.data;
            setFirstName(data.firstName);
            setLastName(data.lastName);
            setEmail(data.email);
          })
          .catch((err) => {
            toastResponseError(err, setSessionExpire);
          });
      })();
    }
  }, [invitedUserId]);

  return (
    <div>
      <div className="container-fluid">
        <div className=" row auth_background">
          <div
            className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
            style={{
              backgroundImage: `url(${AnimationScreens[0]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            {/* {videoLoaded ? ( */}
            <video
              className="reg_vdo"
              src={AnimationVideos[0]}
              autoPlay
              playsInline
              loop
              preload="auto"
              muted
              controls={false}
              poster={AnimationScreens[0]}
            ></video>
            {/* ) : (
              <img
                src={AnimationScreens[0]}
                alt="video-thumn"
                className="reg_vdo_img"
              />
            )} */}
          </div>
          <div
            className="d-flex col-md-5 align-items-center register_info_container"
            style={{ paddingTop: "5rem" }}
          >
            <div className="col-md-10 pt-24 pb-24 max_width_552">
              <form className="d-flex flex-column">
                <div className="auth_text_start align_text_left ml-2 fw-600 mt-16 mb-16">
                  <Icon />
                </div>
                <div className="subtitle_box width_fit bg_soft_blue">
                  <p className="color_blue font_13_600">Hello!</p>
                </div>
                <p className="offsetProjectTitle mb-2  mt-20">Sign up</p>

                <div className="form-group align_text_left ml-2 fw-600 mt-20">
                  {/* <label className="f-16 d-flex" htmlFor="first_name">
                    <p>First Name</p>
                    <i
                      className={
                        validFirstName ||
                        !(firstName || firstName.trim().length > 0)
                          ? "auth_hide"
                          : "bi bi-asterisk auth_invalid"
                      }
                    ></i>
                  </label> */}
                  <input
                    type="text"
                    placeholder="First Name"
                    className="auth_input    shadow-none"
                    id="first_name"
                    autoComplete="off"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    required
                    aria-invalid={validName ? "false" : "true"}
                  />
                </div>

                <div className="form-group align_text_left ml-2 fw-600 mt-20">
                  {/* <label className=" f-16 d-flex" htmlFor="last_name">
                    <p>Last Name</p>
                    <i
                      className={
                        validLastName ||
                        !(lastName || lastName.trim().length > 0)
                          ? "auth_hide"
                          : "bi bi-asterisk auth_invalid"
                      }
                    ></i>
                  </label> */}
                  <input
                    type="text"
                    placeholder="Last Name"
                    className=" auth_input    shadow-none"
                    id="last_name"
                    autoComplete="off"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                    // aria-invalid={validName ? "false" : "true"}
                  />
                </div>

                <div className="form-group align_text_left ml-2 fw-600 mt-20">
                  {/* <label className=" f-16 d-flex" htmlFor="email">
                    <p>Email</p>
                    <i
                      className={
                        validEmail || !(email || email.trim().length > 0)
                          ? "auth_hide"
                          : "bi bi-asterisk auth_invalid"
                      }
                    ></i>
                  </label> */}
                  <input
                    placeholder="Email"
                    type="text"
                    className=" auth_input    shadow-none"
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    required
                  />
                </div>

                <div className="form-group align_text_left ml-2 fw-600 mt-20">
                  {/* <label className="f-16" htmlFor="password">
                    Password
                  </label> */}
                  <div className="d-flex mb-16">
                    <div className="flex-grow-1 reset_pass_input">
                      <input
                        type={passwordType}
                        name="password"
                        placeholder="Password"
                        id="password"
                        className=" auth_input   shadow-none"
                        onChange={(e) => setPassword(e.target.value)}
                        value={password}
                        aria-invalid={validPassword ? "false" : "true"}
                        aria-describedby="password_description"
                        // onFocus={() => setPassFocus(true)}
                        // onBlur={() => setPassFocus(false)}
                        required
                      />
                      <button
                        className="auth_input_pas_btn2"
                        type="button"
                        onClick={togglePassword}
                      >
                        {passwordType === "password" ? (
                          <VisibilityOff sx={{ width: 20 }} />
                        ) : (
                          <Visibility sx={{ width: 20 }} />
                        )}
                      </button>
                    </div>
                    {/* // className=" auth_eye pointer" */}
                  </div>
                  {/* <p
                    id="password_description"
                    className={
                      (passFocus && !validPassword
                        ? "auth_instructions"
                        : "auth_hide") + " mb-16"
                    }
                  > */}
                  {/* 8 to 24 characters.
                    <br />
                    Must include uppercase and lowercase letters, a number and a
                    special character.
                    <br />
                    Allowed special characters:{" "}
                    <span aria-label="exclamation mark">!</span>{" "}
                    <span aria-label="at symbol">@</span>{" "}
                    <span aria-label="hashtag">#</span>{" "}
                    <span aria-label="dollar sign">$</span>{" "}
                    <span aria-label="percent">%</span> */}
                  {/* </p> */}
                  <PasswordMeter password={password} />
                </div>

                <div className="d-flex align-items-center mt-16">
                  <input
                    type="radio"
                    id="teams_checkbox"
                    checked={agreeTerms}
                    onClick={() => setAgreeTerms(!agreeTerms)}
                  />
                  <p className="overline_text ms-2">
                    I accept the{" "}
                    <a
                      href="https://www.today.green/legal/agb"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="fw-bold termsNconditionsAnchor"
                    >
                      terms & conditions
                    </a>{" "}
                    from today.green
                  </p>
                </div>
                <ButtonComp
                  status={1}
                  size="lg"
                  style={{ padding: "6px 35px", marginTop: "24px" }}
                  // className="primary_button_lg mt-24 fw-600"
                  disabled={
                    !validFirstName ||
                    !validLastName ||
                    !validPassword ||
                    !agreeTerms
                      ? true
                      : false
                  }
                  onClick={(e) => {
                    if (token) {
                      handleInvitedSignup(e);
                    } else {
                      handleSubmit(e);
                    }
                  }}
                >
                  Sign up
                </ButtonComp>
              </form>
              <div className="w-100">
                <a href="https://api.today.green/auth/google">
                  <ButtonComp
                    status={3}
                    style={{ width: "100%", marginTop: "32px" }}
                    // className=" auth_media w-100"
                  >
                    <GoogleIcon /> &nbsp; Sign in with Google
                  </ButtonComp>
                </a>
                <a href="https://api.today.green/auth/linkedin">
                  <ButtonComp
                    status={3}
                    style={{ width: "100%", marginTop: "16px" }}
                  >
                    <LinkedInIcon /> &nbsp; Sign in with LinkedIn
                  </ButtonComp>
                </a>
              </div>

              <div className="auth_text_left">
                <p className="faint_text_1">
                  Already have an account? &nbsp;
                  <a href="/" className="auth_login">
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
