import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "./logDashboard.css";
import TabLabels from "./Components/TabLabels";
import SideDrawer from "./Components/SideDrawer";
import NoService from "./Components/NoService";
// import LogTable from "./Components/LogTable";
// import ProductDetail from "./ProductDetail";
import { toast } from "react-hot-toast";
import axios from "../../../Api/index";
import tabData from "./items-new.json";
import LogTable from "./Components/LogTable";
import useQuery from "../../../Utils/query";
import ProductDetail from "./ProductDetail";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import doubleArrowIcon from "../../../Assets/images/Double_Arrow.png";
import ButtonComp from "../../../Assets/components/Button";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { useAuth } from "../../../Utils/Auth";

//import ActivateService from "./Components/ActivateService";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function LogDashboard(props) {
  const { setSessionExpire } = useAuth();
  const { parentvalue } = props;
  const [value, setValue] = useState(0);
  const [logYear, setLogYear] = useState(new Date().getFullYear());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [tabInfo, setTabInfo] = useState([]);
  const [tabQuestions, setTabQuestions] = useState([]);
  const [allTabsDetails, setAllTabsDetails] = useState([]);
  const [serviceName, setServiceName] = useState("");
  const [toggleActivateDrawer, setToggleActivateDrawer] = useState(false);
  const [itemCreditCount, setItemCreditCount] = useState("");
  const [step, setStep] = useState(0);
  const query = useQuery();
  const currStep = Number(query.get("step"));
  const navigate = useNavigate();

  useEffect(() => {
    if (currStep || currStep === 0) {
      setStep(currStep);
    }
  }, [currStep]);

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleActivateService = (value) => {
    if (value) getItemCreditCount();
    setToggleActivateDrawer(value);
  };
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const getItemCreditCount = async () => {
    try {
      const response = await axios.get(
        `/credit/iscredit/${allTabsDetails[value]._id}`
      );
      if (response.data.success) {
        setItemCreditCount(response.data.count);
      } else {
        setItemCreditCount(0);
      }
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };
  const get_count = async () => {
    try {
      const response = await axios.get(`/log/count-log`);
      let counts = response.data.data;

      const updatedArr = tabData.map((elem) => {
        let currentItemCount = 0;
        counts.find((current) => {
          if (current.name.toLowerCase() === elem.name.toLowerCase()) {
            currentItemCount = current.count;
            return;
          }
        });
        return {
          ...elem,
          count: currentItemCount,
        };
      });
      const sortedArr = updatedArr.sort((a, b) => b.count - a.count);
      setServiceName(sortedArr[value].name);
      setTabQuestions(sortedArr[value].addService);
      setAllTabsDetails([...sortedArr]);
      if (value > 0) {
        let currentActiveTabId = allTabsDetails[value]._id;
        sortedArr.forEach((currentTab, index) => {
          if (currentTab._id === currentActiveTabId) {
            setValue(index);
            return;
          }
        });
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const getItemData = async () => {
    try {
      if (logYear) {
        const response = await axios.get(
          `/log/get-log?itemId=${allTabsDetails[value]._id}&year=${logYear}` // should get data less than equal to selected year
        );
        if (response.data.success) {
          setTabInfo(response.data.logs);
        }
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    let current_tabs = [];
    get_count();
    setTabInfo(current_tabs);
  }, []);

  useEffect(() => {
    if (allTabsDetails[value]) {
      setTabQuestions(allTabsDetails[value].addService);
      setServiceName(allTabsDetails[value].name);
      getItemData();
      getItemCreditCount();
      //  setItemName(allTabsDetails[value].name)
    }
  }, [value]);

  useEffect(() => {
    if (allTabsDetails[value]) {
      getItemData();
    }
  }, [logYear]);

  useEffect(() => {
    if (allTabsDetails[value]) {
      getItemCreditCount();
      getItemData();
      //  setItemName(allTabsDetails[value].name)
    }
  }, [allTabsDetails]);

  const handleAddServiceSubmit = async (
    answers,
    baseYearAccount,
    baseYear,
    baseMonth
  ) => {
    let isDoubleAccount = false;
    const Details = {};
    let startYear = 1;
    let startMonth = 1;
    let endYear = 1;
    let endMonth = 1;
    if (!baseYearAccount) startMonth = baseMonth;

    allTabsDetails[value].addService.forEach((question, index) => {
      if (question.name == "startDate") {
        startYear = parseInt(answers[index]["$y"]);
        startMonth = parseInt(answers[index]["$M"]) + 1;
      } else if (question.name == "endDate") {
        endYear = parseInt(answers[index]["$y"]);
        endMonth = parseInt(answers[index]["$M"]) + 1;
      } else Details[question.name] = answers[index];
    });

    let currentIndex = allTabsDetails[value].addService.length;

    allTabsDetails[value].secondQuestionSet.forEach((questionSet) => {
      let skipNext = false;
      questionSet.questions.forEach((question, subIndex) => {
        if (!skipNext) {
          if (question.name === "isDoubleAccount") {
            isDoubleAccount = answers[currentIndex + subIndex];
            if (!answers[currentIndex + subIndex]) {
              skipNext = true;
            }
          } else {
            Details[question.name] = answers[currentIndex + subIndex];
          }
        } else {
          Details[question.name] = "";
          skipNext = false;
        }
      });
      currentIndex += questionSet.questions.length;
    });
    if (startYear == 1) {
      startYear = parseInt(baseYear);
    }
    await axios
      .post(`/log/log-create/${allTabsDetails[value]._id}`, {
        accountingItemName: allTabsDetails[value].name,
        itemDetails: Details,
        year: startYear,
        month: startMonth,
        endYear: endYear,
        endMonth: endMonth,
        isBaseYear: baseYearAccount,
        isDoubleAccount: isDoubleAccount,
        loggedEmissions: "",
        offsetEmissions: "",
        offsetPeriod: "",
      })
      .then(() => {
        toast.success("Log Created");
        get_count();
      })
      .catch((err) => {
        toast.error(err, "handle submit error");
      });
  };
  return (
    <div className="ml-116 mr-80">
      {step === 0 ? (
        <>
          {allTabsDetails[value] && (
            <SideDrawer
              toggleDrawer={toggleDrawer}
              state={drawerOpen}
              questions={tabQuestions}
              secondQuestionSet={allTabsDetails[value].secondQuestionSet}
              baseYearField={allTabsDetails[value].baseYearField}
              handleAddServiceSubmit={handleAddServiceSubmit}
              serviceName={serviceName}
              itemId={allTabsDetails[value]._id}
            />
          )}

          {parentvalue === props.index && (
            <div className=" logDashboard_width">
              <div className="d-flex justify-content-between logDashboard_heading mt-40">
                <div>
                  <p className="heading_1 ">Log</p>
                </div>
                <div className="mr-30">
                  {/* <button className="tertiary_button_variant_1">
                    Company Map
                  </button> */}
                  {/* <button
                    className="secondary_button_variant_3 ml-20"
                    onClick={() => navigate(`/dashboard?tab=9`)}
                  >
                    + New item
                  </button> */}
                  <ButtonComp
                    status={2}
                    onClick={() => navigate(`/dashboard?tab=9`)}
                  >
                    + New item
                  </ButtonComp>
                </div>
              </div>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    sx={() => ({
                      "& .MuiTouchRipple-child": {
                        backgroundColor: `#cacaca !important`,
                      },
                    })}
                    aria-label="basic tabs example"
                    className="general_root_tabs logDashboard_tabWidth"
                    ScrollButtonComponent={(props) => {
                      if (props.direction === "left" && !props.disabled) {
                        return (
                          <IconButton {...props}>
                            <img
                              src={doubleArrowIcon}
                              className="h-10px rotateImg180"
                            />
                          </IconButton>
                        );
                      } else if (
                        props.direction === "right" &&
                        !props.disabled
                      ) {
                        return (
                          <IconButton {...props}>
                            <img src={doubleArrowIcon} className="h-10px" />
                          </IconButton>
                        );
                      } else {
                        return null;
                      }
                    }}
                  >
                    {allTabsDetails.map((json_tab, index) => {
                      return (
                        <Tab
                          key={index}
                          label={
                            <TabLabels
                              title={json_tab.name}
                              count={json_tab.count}
                            />
                          }
                          {...a11yProps(index)}
                        />
                      );
                    })}
                  </Tabs>
                </Box>
                {allTabsDetails.map((json_tab, index) => {
                  return (
                    <TabPanel value={value} index={index} key={index}>
                      {json_tab.count > 0 ? (
                        <LogTable
                          logYear={logYear}
                          setLogYear={setLogYear}
                          getItemData={getItemData}
                          tabInfo={tabInfo}
                          toggleActivateDrawer={toggleActivateDrawer}
                          handleActivateService={handleActivateService}
                          serviceDrawer={drawerOpen}
                          toggleServiceDrawer={toggleDrawer}
                          itemCreditCount={itemCreditCount}
                          itemName={json_tab.name}
                          item_id={json_tab._id}
                          creditPrice={json_tab.price}
                          getItemCreditCount={getItemCreditCount}
                        />
                      ) : (
                        <NoService
                          drawerState={drawerOpen}
                          toggleDrawer={toggleDrawer}
                          questions={tabData[index].addService}
                          serviceName={json_tab.name}
                        />
                      )}
                    </TabPanel>
                  );
                })}
              </Box>
            </div>
          )}
        </>
      ) : step === 1 ? (
        <ProductDetail />
      ) : (
        ""
      )}
    </div>
  );
}
export default LogDashboard;
