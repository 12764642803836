export const botAvatarGroup = {
  Zv7V8Hf6MZ_01: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_01.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Avatar+was+selected%2C+No+Active+offsets+yet.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Avatar+was+selected%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Create+your+first+log+item%2C++Target+Placeholder.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Create+your+first+log+item%2C++Target+Placeholder.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Create+your+first+log+item%2C+Log+Support.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Purchassed+Successfully.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Successfully+Activated.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Zv7V8Hf6MZ_01/Surveys_Connect+Placeholder.png`,
    },
  },
  TQ2HJ9wstv_02: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_02.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Avatar+was+selected%2C+Successfully+Activated.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Create+your+first+log+item%2C++Target+Placeholder.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Create+your+first+log+item%2C++Target+Placeholder.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Purchassed+Successfully%2C+Log+Support.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Purchassed+Successfully%2C+Log+Support.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Avatar+was+selected%2C+Successfully+Activated.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Successfully+Sent.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/TQ2HJ9wstv_02/Surveys_Connect+Placeholder.png`,
    },
  },
  WJTcolwqlI_03: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_03.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Avatar+was+selected.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Log+Support%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Create+your+first+log+item%2C+Successfully+Sent%2C++Target+Placeholder.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Create+your+first+log+item%2C+Successfully+Sent%2C++Target+Placeholder.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Log+Support%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Purchassed+Successfully.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Successfully+Activated.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Create+your+first+log+item%2C+Successfully+Sent%2C++Target+Placeholder.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Log+Support%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/WJTcolwqlI_03/Surveys_Connect+Placeholder.png`,
    },
  },
  XXQo1L8Cad_04: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_04.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Avatar+was+selected.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Target+Placeholder%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Create+your+first+log+item.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Target+Placeholder%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Log+Support.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Target+Placeholder%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/XXQo1L8Cad_04/Surveys_Connect+Placeholder.png`,
    },
  },
  bEpWhtIcgB_05: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_05.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Avatar+was+selected.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Log+Support%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Create+your+first+log+item.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Target+Placeholder%2C+Offset+Marketplace.png.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Log+Support%2C+No+Active+offsets+yet.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Purchassed+Successfully%2C+Successfully+Sent.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Successfully+Activated.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Purchassed+Successfully%2C+Successfully+Sent.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Target+Placeholder%2C+Offset+Marketplace.png.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Surveys_Connect+Placeholder.png`,
    },
    findYOurConsultant: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/bEpWhtIcgB_05/Find+your+Consultant.png`,
    },
  },
  BMYZFHL7U7_06: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_06.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Avatar+was+selected.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Create+your+first+log+item.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Target+Placeholder.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Log+Support.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Purchassed+Successfully.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Successfully+Activated%2C+Successfully+Sent.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Successfully+Activated%2C+Successfully+Sent.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Offset+Marketplace.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/BMYZFHL7U7_06/Surveys_Connect+Placeholder.png`,
    },
  },
  iUwR3g5vie_07: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_07.jpg`
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Avatar+was+selected%2C++Target+Placeholder%2C+No+Active+offsets+yet.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Avatar+was+selected%2C++Target+Placeholder%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Create+your+first+log+item%2C+Log+Support.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Avatar+was+selected%2C++Target+Placeholder%2C+No+Active+offsets+yet.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Create+your+first+log+item%2C+Log+Support.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Purchassed+Successfully%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Successfully+Activated%2C+Surveys_Connect+Placeholder.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Purchassed+Successfully%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Purchassed+Successfully%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/iUwR3g5vie_07/Successfully+Activated%2C+Surveys_Connect+Placeholder.png`,
    },
  },
  yc0nmnYzEa_08: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_08.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Avatar+was+selected%2C+Successfully+Activated%2C+No+Active+offsets+yet.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Avatar+was+selected%2C+Successfully+Activated%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Create+your+first+log+item%2C+Log+Support%2C++Target+Placeholder.png.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Create+your+first+log+item%2C+Log+Support%2C++Target+Placeholder.png.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Create+your+first+log+item%2C+Log+Support%2C++Target+Placeholder.png.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Purchassed+Successfully%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Avatar+was+selected%2C+Successfully+Activated%2C+No+Active+offsets+yet.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Purchassed+Successfully%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Purchassed+Successfully%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/yc0nmnYzEa_08/Surveys_Connect+Placeholder.png`,
    },
  },
  NZpXenkWWZ_09: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_09.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Avatar+was+selected%2C++Target+Placeholder%2C+No+Active+offsets+yet.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Avatar+was+selected%2C++Target+Placeholder%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Create+your+first+log+item.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Avatar+was+selected%2C++Target+Placeholder%2C+No+Active+offsets+yet.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Log+Support.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Purchassed+Successfully%2C+Successfully+Activated%2C+Offset+Marketplace.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Purchassed+Successfully%2C+Successfully+Activated%2C+Offset+Marketplace.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Successfully+Sent.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Purchassed+Successfully%2C+Successfully+Activated%2C+Offset+Marketplace.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/NZpXenkWWZ_09/Surveys_Connect+Placeholder.png`,
    },
  },
  ywhc1UzBKF_10: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_10.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Avatar+was+selected.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Create+your+first+log+item%2C+Successfully+Activated%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Create+your+first+log+item%2C+Successfully+Activated%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Create+your+first+log+item%2C+Successfully+Activated%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Create+your+first+log+item%2C+Successfully+Activated%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C+Offset+Marketplace.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/ywhc1UzBKF_10/Create+your+first+log+item%2C+Successfully+Activated%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
  },
  Qp9SVh0au5_11: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_11.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Avatar+was+selected%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Avatar+was+selected%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Create+your+first+log+item%2C+Successfully+Activated.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C++Target+Placeholder%2C+Offset+Marketplace.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C++Target+Placeholder%2C+Offset+Marketplace.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C++Target+Placeholder%2C+Offset+Marketplace.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Create+your+first+log+item%2C+Successfully+Activated.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C++Target+Placeholder%2C+Offset+Marketplace.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Purchassed+Successfully%2C+Log+Support%2C+Successfully+Sent%2C++Target+Placeholder%2C+Offset+Marketplace.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/Qp9SVh0au5_11/Avatar+was+selected%2C+No+Active+offsets+yet%2C+Surveys_Connect+Placeholder.png`,
    },
  },
  eSfQGKheBi_12: {
    face: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/Character_12.jpg`,
    },
    avatarSelect: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Avatar+was+selected%2C+Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent%2C++Target+Placeholder.png.png`,
    },
    noActiveOffset: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Create+your+first+log+item%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    firstLogItem: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Create+your+first+log+item%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    targetPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Avatar+was+selected%2C+Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent%2C++Target+Placeholder.png.png`,
    },
    logSupport: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Log+Support.png`,
    },
    purchasedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Avatar+was+selected%2C+Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent%2C++Target+Placeholder.png.png`,
    },
    activatedSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Avatar+was+selected%2C+Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent%2C++Target+Placeholder.png.png`,
    },
    sentSuccessful: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Avatar+was+selected%2C+Purchassed+Successfully%2C+Successfully+Activated%2C+Successfully+Sent%2C++Target+Placeholder.png.png`,
    },
    offsetMarketplace: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Create+your+first+log+item%2C+Offset+Marketplace%2C+No+Active+offsets+yet.png`,
    },
    surveyConnectPlaceholder: {
      url: `https://tg-icons.s3.eu-central-1.amazonaws.com/avatars/eSfQGKheBi_12/Surveys_Connect+Placeholder.png`,
    },
  },
};
