import React, { useEffect, useState } from "react";
import location from "../../../../../../../DataJSON/companyLocation.json";
import event from "../../../../../../../DataJSON/event.json";
import hotel from "../../../../../../../DataJSON/hotel.json";
import product from "../../../../../../../DataJSON/product.json";
import service from "../../../../../../../DataJSON/service.json";
import realEstateA from "../../../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../../../DataJSON/building.json";
import agency from "../../../../../../../DataJSON/agency.json";
import education from "../../../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../../../DataJSON/shipping.json";
import skilledTrades from "../../../../../../../DataJSON/skilledTradesSector.json";
import { useMapStore } from "../../../../../../../Utils/CompanyMapStore";
import ButtonComp from "../../../../../../../Assets/components/Button";
import toast from "react-hot-toast";
import Axios from "../../../../../../../Api";
const data = {
  location,
  event,
  hotel,
  product,
  service,
  "real-estate agency": realEstateA,
  "real estate development": realEstateD,
  building,
  agency,
  education,
  shipping,
  "skilled trades company": skilledTrades,
};
function CompanyMapEmissionSources() {
  const { activeNodeData } = useMapStore();
  const [
    ,
    //    activateButton
    setActivateButton,
  ] = useState([]);
  const [selectScope, setSelectScope] = useState({});
  const [emissionData, setEmissionData] = useState({});
  const [emissionSourcesCount, setEmissionSourcesCount] = useState([]);
  const [emissionKeys, setEmissionKeys] = useState([]);
  useEffect(() => {
    let item = activeNodeData.accountingItemName?.toLowerCase();
    if (!item) return;
    let itemKey;
    if (item === "real-estate agency") {
      itemKey = "Real-Estate Agency";
    } else
      itemKey = item
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");

    if (data[item] && data[item][itemKey]) {
      let currKeys = Object.keys(data[item][itemKey]);

      let currCount = []; // energy count
      let currEmissionData = [];
      let currSelectData = {};
      let currActivateButton = [];
      currKeys.map((name, index) => {
        //  currActivateButton.push(false);
        currCount.push(Object.keys(data[item][itemKey][name]).length);

        let currScopeData = [];
        let currScope = Object.keys(data[item][itemKey][name]);
        let mandatoryCount = 0;

        currScope.map((scope, subIndex) => {
          let temp = data[item][itemKey][name];
          let position = index + 1 + "." + (subIndex + 1);
          currSelectData[position] = temp[scope].isMandatory;
          if (temp[scope].isMandatory) mandatoryCount++;

          currScopeData.push({
            position: position,
            name: scope,
            isMandatory: temp[scope].isMandatory,
            description: temp[scope].description,
          });
        });

        mandatoryCount > 0
          ? currActivateButton.push(true)
          : currActivateButton.push(false);
        currEmissionData.push(currScopeData);
      });
      setActivateButton(currActivateButton);
      setSelectScope(currSelectData);
      setEmissionData(currEmissionData);
      setEmissionSourcesCount(currCount);
      setEmissionKeys(currKeys);
      //checkEligible(value);
    }
  }, []);

  const handleCardClick = (position, index, subIndex) => {
    if (emissionData[index][subIndex].isMandatory) return;
    let currSelectArray = { ...selectScope };
    currSelectArray[position] = !currSelectArray[position];
    setSelectScope(currSelectArray);
  };
  const handleSubmit = async () => {
    let isFullFilled = true;
    let selectedResponse = [];
    emissionData.forEach((data, index) => {
      let mandatoryCount = 0;
      let selectedCount = 0;
      data.forEach((currScope) => {
        if (currScope.isMandatory) mandatoryCount++;
        if (selectScope[currScope.position]) {
          selectedResponse.push({
            class: emissionKeys[index],
            scope: currScope.name,
          });
          selectedCount++;
        }
      });
      if (mandatoryCount >= selectedCount && data.length != mandatoryCount) {
        isFullFilled = false;
      }
      if (!isFullFilled) return;
    });
    if (!isFullFilled) {
      toast.error("some selections remained");
      return;
    }

    await Axios.patch("/log/matrix-log", {
      logId: activeNodeData._id,
      matrixItems: selectedResponse,
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Log updated");
        }
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };
  return (
    <div className="mt-16">
      <p className="font_16_600 color_7e">Select your Emissions Sources</p>

      {emissionKeys.map((emissionKey, index) => {
        return (
          <div key={index} className="mt-32">
            <p className="font_14_400">
              🔋 {emissionKey}{" "}
              <span className="blue_label ml-8">
                {emissionSourcesCount[index]}
              </span>
            </p>
            <hr className="mt-12" />

            {emissionData[index].map((currScope, subIndex) => {
              return (
                <div
                  key={emissionKey + index + subIndex}
                  onClick={() => {
                    handleCardClick(currScope.position, index, subIndex);
                  }}
                  className={`border-ef border_radius_8  pl-20 pt-20 pr-20 pb-24 mt-16  ${
                    selectScope[currScope.position]
                      ? "card_selection_border"
                      : ""
                  } `}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="font_16_600">{currScope.name}</p>

                    <p
                      className={`${
                        currScope.isMandatory ? "orange_label" : "green_label"
                      }`}
                    >
                      {currScope.isMandatory ? "Mandatory" : "Recommended"}
                    </p>
                  </div>

                  <div>
                    <p className="font_14_600 color_7e mt-10">Scope 1</p>
                  </div>

                  <div className="mt-20">
                    <p className="font_13_400"> {currScope.description}</p>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}

      <div className="d-flex justify-content-end align-items-center mt-20 h-70px border-top_ef">
        <ButtonComp
          onClick={() => {
            handleSubmit();
          }}
          status={7}
          className="h-40px"
        >
          Save
        </ButtonComp>
      </div>
    </div>
  );
}

export default CompanyMapEmissionSources;
