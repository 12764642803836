import { Drawer, IconButton } from "@mui/material";
import React, { useState } from "react";
import Step1 from "./Step1";
import { Close } from "@mui/icons-material";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import { useEffect } from "react";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";

const ActiveOffsetDrawer = ({
  handleToggle,
  open,
  data,
  disableOption1,
  getAllTables,
}) => {
  const [step, setStep] = useState(2);
  const [option, setOption] = useState("");

  useEffect(() => {
    if (disableOption1) {
      setOption(2);
    } else {
      setOption(1);
    }
  }, [disableOption1]);

  return (
    <div className="SideDrawer_width">
      <Drawer
        anchor={"right"}
        open={open}
        onClose={() => handleToggle(false)}
        PaperProps={{
          sx: { width: "70%" },
        }}
        BackdropProps={{
          style: { backgroundColor: "rgba(35, 40, 65, 0.15)", opacity: 0 },
        }}
      >
        <div className="drawer_root">
          <div className="inactive_drawer_intro_header ">
            <IconButton
              className="offsetMarketPlace_close_cta"
              onClick={() => handleToggle(false)}
            >
              <Close />
            </IconButton>
            <div className="w-90">
              <div className="d-flex align-items-center">
                <p className="fsb_title">
                  🌳 Let&apos;s make your item carbon neutral!
                </p>
                <div className="item_wrapper_circle ml-32">
                  <p className="body_text_m">
                    <img
                      height="16px"
                      src={getServiceIcon(data.accountingItemName)}
                    />
                    &nbsp;
                    {data.itemDetails.name}
                  </p>
                </div>
              </div>
              <p className="body_text_m mt-12">
                CO2 compensation, and the carbon neutrality associated with it,
                refers to the compensation of emissions caused by an accounting
                item. In the next step, we will explain how you can obtain a
                carbon neutral label for your accounting item and which data
                must be available.
              </p>
            </div>
          </div>
          <div className="p-40">
            {step === 1 ? (
              <Step1
                setStep={setStep}
                data={data}
                disableOption1={disableOption1}
              />
            ) : step === 2 ? (
              <Step2
                setStep={setStep}
                data={data}
                disableOption1={disableOption1}
              />
            ) : step === 3 ? (
              <Step3
                setStep={setStep}
                data={data}
                option={option}
                setOption={setOption}
                disableOption1={disableOption1}
              />
            ) : step === 4 ? (
              <Step4
                getAllTables={getAllTables}
                setStep={setStep}
                data={data}
                option={option}
              />
            ) : null}
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default ActiveOffsetDrawer;
