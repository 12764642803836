import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import CustomToolTip from "./customToolTip";

function AddressInput(props) {
  const [origin, setOrigin] = useState(null);
  useEffect(() => {
    props.setAnswers((prev) => {
      return {
        ...prev,
        [props.questionNo]:
          origin && origin.value ? origin.value.description : "",
      };
    });
    //props.handleAnswerChange(currVal, props.questionNo)
  }, [origin]);
  return (
    <>
      <div className="d-flex align-items-center">
        <p className="sr-only subheading_1 mb-14">{props.title}</p>
        {props.description && props.description.length>0 ?<div>
          <CustomToolTip
            title={props.description}
            cursor={{ fill: "transparent" }}
          />
        </div>:""}
      </div>
      <GooglePlacesAutocomplete
        debounce={800}
        apiKey={process.env.REACT_APP_GOOGLE_MAP}
        selectProps={{
          origin,
          onChange: setOrigin,
          placeholder: "Address",
          styles: {
            container: (p) => ({
              ...p,
              boxSizing: `border-box`,
             // border: `1px solid #efefef`,
              borderRadius: `5px`,
              fontSize: `15px`,
              fontWeight: "400",
              boxShadow: "none",
              fontFamily: '"Inter", sans-serif',
              outline: `none`,
              maxHeight: 40,
            }),
            valueContainer: (p) => ({
              ...p,
              border:`1px solid #efefef`,
              borderRadius:"5px",
              color:"transparent",
              padding: "0px 10px",
              height: 42,
            }),
            control: (p) => ({
              ...p,
              border: `0`,
              "&:hover": {
                boxShadow: "none",
              },
            }),
            indicatorsContainer: (p) => ({
              ...p,
              display: "none",
            }),
            input: (p) => ({
              ...p,
              // padding: "8px",
            }),
          },
        }}
      />
      <p className="helper_text mt-2 ml-8">{props.helperText}</p>
    </>
  );
}

export default AddressInput;
