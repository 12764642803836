import React from "react";
import ButtonComp from "../../../../../../Assets/components/Button";

const Step1 = ({ setStep }) => {
  return (
    <div className="inactive_drawer_intro_main">
      <p className="font_17_700 text-uppercase">
        1. Understanding Offset (Recommended)
      </p>
      <div className="mt-32 text-end">
        {/* <button className="tertiary_button_variant_1">
          Continue & Don&apos;t Show Again
        </button> */}
        <ButtonComp status={1} onClick={() => setStep(2)}>
          Continue
        </ButtonComp>
      </div>
    </div>
  );
};

export default Step1;
