import React, { useEffect, useState } from "react";
import ActiveOffsetDrawer from "./InActiveDrawer/ActiveOffsetDrawer";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../Utils/TableComp";
import { getServiceIcon } from "../../../../../Utils/itemIcon";
import ButtonComp from "../../../../../Assets/components/Button";

const InActiveTableRow = ({ log, getAllTables }) => {
  const [open, setOpen] = useState(false);
  const handleToggle = (open) => setOpen(open);
  const [totalEmission, settotalEmission] = useState(0);
  const [totalMonths, settotalMonths] = useState(0);

  const getIconName = (item) => {
    if (item.itemDetails.facilityType) return item.itemDetails.facilityType;
    else if (item.itemDetails.productType) {
      return item.itemDetails.productType;
    } else if (item.itemDetails.event) {
      return item.itemDetails.event;
    } else if (item.itemDetails.hotelType) {
      return item.itemDetails.hotelType;
    } else if (item.itemDetails.buildingType) {
      return item.itemDetails.buildingType;
    } else return item.accountingItemName;
  };

  useEffect(() => {
    let total =
      log.offsetDetails[0].loggedMonthEmissions &&
      log.offsetDetails[0].loggedMonthEmissions.length > 0
        ? log.offsetDetails[0].loggedMonthEmissions.reduce(
            (sum, obj) => sum + obj.emission,
            0
          )
        : 0;
    settotalMonths(
      log.offsetDetails[0].loggedMonthEmissions
        ? log.offsetDetails[0].loggedMonthEmissions.length
        : 0
    );
    settotalEmission(total);
  }, [log]);

  return (
    <>
      <StyledTableRow
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}
      >
        <StyledTableCell
          component="th"
          scope="row"
          className="border_left_0 border_bottom_0"
        >
          <p className="text-left">
            {/* <img
              className="item_icon_img"
              src={getServiceIcon(log.accountingItemName)}
              alt={log.accountingItemName}
            /> */}
            <img
              src={getServiceIcon(getIconName(log))}
              className="icon_sizing"
              alt={log.accountingItemName}
            />
            &nbsp;
            {log.itemDetails.name}
          </p>
        </StyledTableCell>
        <StyledTableCell className="align-items-center justify-content-center border_bottom_0 ">
          <div
            className={
              (totalMonths < 12 ? "bg_soft_red" : "bg_soft_green") +
              " " +
              "subtitle_box width_fit mx-auto"
            }
          >
            <p
              className={
                (totalMonths < 12 ? "color_red" : "color_green") +
                " " +
                "font_13_600"
              }
            >
              {totalMonths}/{log.offsetDetails[0].totalMonths} months
            </p>
          </div>
        </StyledTableCell>
        <StyledTableCell className="border_bottom_0">
          {totalEmission && totalEmission.toFixed(2).toLocaleString()} tCO₂e
        </StyledTableCell>
        <StyledTableCell className="border_bottom_0 border_right_0">
          <ButtonComp status={1} width="lg" onClick={() => handleToggle(true)}>
            Activate
          </ButtonComp>
        </StyledTableCell>
      </StyledTableRow>
      <ActiveOffsetDrawer
        open={open}
        getAllTables={getAllTables}
        handleToggle={handleToggle}
        data={log}
        disableOption1={totalMonths !== log.offsetDetails[0].totalMonths}
      />
    </>
  );
};

export default InActiveTableRow;
