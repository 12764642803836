import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useQuery from "../../../../../../../Utils/query";
//import dashboard_background_img from '../../../../../../../Assets/images/dashboard_background_img.png'
import FAQAccordion from "../FAQAccordion";
import axios from "../../../../../../../Api/index";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import parse from "html-react-parser";
import { useAuth } from "../../../../../../../Utils/Auth";

function TutorialCardDetails() {
  const {user} = useAuth()
  const query = useQuery();
  const [topicDetails, setTopicDetails] = useState({});
  const [faqs, setFaqs] = useState([]);
  const [tutorials, setTutorials] = useState([]);
  const itemId = query.get("id");
  const getTopicDetails = async () => {
    try {
      await axios.get(`/academy/topic/${itemId}`).then((response) => {
        if (response.data.success) {
          setTopicDetails(response.data.data);
          setFaqs(response.data.faqs);
          setTutorials(response.data.tutorials);
        }
      });
    } catch (error) {
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getTopicDetails();
  }, [itemId]);



  return (
    <div>
      <div className="align_text_left mt-50">
        <p className="breadcrumb_text_light_1 text_capital">
          <Link to={`/dashboard?tab=7`}>
            <span className="color_light_2">Tutorials</span>
          </Link>{" "}
          <span className="fw-700"> / {user.language === 'de' && topicDetails?.title_de ?  topicDetails?.title_de :topicDetails?.title}</span>
        </p>
      </div>

      <div className="mt-45">
        <div className="row card_design_2 pl-18 border_radius_10">
          <div className="col-6 pl-0">
            <div className="d-flex align-items-center mt-20">
              <div>
                <img
                  className="tutorials_container h-60px px-12 py-12"
                  src={topicDetails?.icon}
                />
              </div>
              {topicDetails.title && (
                <p className="font_36_700 ml-32 lh-15">
                  {user.language === 'de' && topicDetails?.title_de ?parse(topicDetails?.title_de) :parse(topicDetails?.title)}
                
                </p>
              )}
            </div>

            {topicDetails.description && (
              <div className="mt-28 mb-30 w-60">
                <p className="body_text_s_light">
                  {user.language === 'de' && topicDetails?.description_de ?parse(topicDetails?.description_de) :parse(topicDetails?.description)}
                </p>
              </div>
            )}
          </div>

          {topicDetails?.coverimage && (
            <div className="col-6 pr-0">
              <img
                src={topicDetails?.coverimage}
                className="w-100 h-220px border_rad_r_10"
              />
            </div>
          )}
        </div>
      </div>

      <div className="row mt-40 pb-40">
        {tutorials.map((currTuts, index) => {
          return (
            <div
              key={currTuts._id + currTuts.title + index}
              className={`col-3 content_width card_design_2 box_shadow_n mt-16 pl-22 pr-20 pb-24 border_radius_10 ${
                index % 3 != 0 ? "ml-20" : ""
              }`}
            >
              <Link
                to={`/dashboard?tab=7&acadTab=0&id=${currTuts._id}&tutItemId=${itemId}&step=2`}
              >
                <div>
                  <div className="mt-12">
                    <img
                      className="tutorials_container h-60px px-12 py-12"
                      src={currTuts.icon}
                    />
                  </div>

                  <div className="mt-16">
                    {currTuts.title && (
                      <p className="font_16_600">

                      {user.language === 'de' && currTuts?.title_de ?parse(currTuts?.title_de) :parse(currTuts?.title)}
           
                      
                      </p>
                    )}
                    {currTuts.description && (
                      <p className="body_text_s_light mt-12">
                        {user.language === 'de' && currTuts?.description_de ?parse(currTuts?.description_de) :parse(currTuts?.description)}
                      </p>
                    )}
                  </div>
                </div>
              </Link>
            </div>
          );
        })}
      </div>

      <div className="mt-72">
        {faqs?.length > 0 ? (
          <p className="font_17_700 text_capital">
            FAQs - {user.language === 'de' && topicDetails?.title_de ?parse(topicDetails?.title_de) :parse(topicDetails?.title)}{" "}
          </p>
        ) : (
          ""
        )}
        <div className="tutorials_accordion_container mt-26">
          {faqs.map((currFaq, index) => {
            return (
              <FAQAccordion
                key={index + currFaq.question}
                faqDetails={currFaq}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default TutorialCardDetails;
