import React, { useState, useMemo, useEffect } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import { useRef } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import { useNavigate } from "react-router-dom";
import CoverImageCropper from "../../../../Components/CommonComponents/CoverImageCropper/CoverImageCropper";
import Axios from "../../../../Api";
import { Close } from "@mui/icons-material";
Quill.register("modules/imageResize", ImageResize);

const fontSizeStyle = Quill.import("attributors/style/size");
fontSizeStyle.whitelist = [
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "28px",
  "32px",
  "36px",
  "40px",
  "48px",
  "50px",
  "56px",
  "60px",
  "64px",
  "68px",
  "70px",
  "72px",
  "76px",
  "80px",
  "96px",
  "100px",
];
Quill.register(fontSizeStyle, true);

const CreateKnowledge = () => {
  const quillRef = useRef();
  const inputFile = useRef();
  const inputFileCover = useRef();
  const [cover, setCover] = useState("");
  const [title, setTitle] = useState("");
  const [title_de, setTitle_de] = useState("");
  const [description, setDescription] = useState("");
  const [description_de, setDescription_de] = useState("");
  // const [keyword, setkeyword] = useState("");
  const [allValues, setAllValues] = useState({
    title: "",
    description: "",
    description_de: "",
    title_de: "",
    descriptionHeading: "",
    descriptionHeading_de: "",
    author: "",
    coverimage: null,
    content: [],
    privacy: 0,
    category: 1,
  });
  const [allAuthors, setAuthors] = useState([]);
  const [showKeywords, setShowKeywords] = useState(false);
  const [allKeywords, setAllKeywords] = useState([]);

  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const handleCreateKnowledge = async () => {
    // e.preventDefault();
    try {
      const url = kid
        ? `/admin/academy/update-knowledge/${kid}`
        : `/admin/academy/create-knowledge`;
      const response = await Axios({
        url: url,
        method: "post",
        data: {
          title: title,
          title_de: title_de,
          description: description,
          description_de: description_de,
          descriptionHeading: allValues.descriptionHeading,
          descriptionHeading_de: allValues.descriptionHeading_de,
          author: allValues.author,
          coverimage: allValues.coverimage,
          content: allValues.content,
          privacy: allValues.privacy,
          category: allValues.category,
          keywords: allKeywords.map((tag) => tag._id),
        },
      });
      if (response.data.success) {
        if (kid) {
          toast.success("Knowledgehub updated successfully");
        } else toast.success("Knowledgehub created successfully");
        navigate(
          `/admin/dashboard?tab=5&activetab=4&step=1&kid=${response.data?.data?._id}`
        );
        setAllValues({
          title: "",
          description: "",
          descriptionHeading: "",
          description_de: "",
        title_de: "",
          descriptionHeading_de: "",
          author: "",
          coverimage: null,
          content: [],
          privacy: 0,
          category: 1,
        });
        setAllKeywords([]);
      }
    } catch (error) {
      return toastResponseError(error);
    }
  };
  const handlePicUpload = async (e, type) => {
    const image = e.target.files[0];
    // if (image.size > 2048 * 1024) {
    //   toast.error("File size exceeded 2MB!", { position: "bottom-right" });
    //   return;
    // }
    const formdata = new FormData();
    formdata.append(`${type}`, image);

    await Axios({
      method: "post",
      url: `/admin/academy/${type}`,
      data: formdata,
    })
      .then((res) => {
        if (type === "knowledgecover") {
          setAllValues({ ...allValues, coverimage: res.data.location });
        } else if (type === "knowledgeimage") {
          const editor = quillRef.current.getEditor();
          editor.insertEmbed(editor.getSelection(), "image", res.data.location);
        }
        toast.success(`${type?.toUpperCase()} uploaded`);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };
  const [allTags, setAllTags] = useState([]);

  const handleCoverUpload = async (imageFile) => {
    const formdata = new FormData();
    formdata.append(`knowledgecover`, imageFile);
    try {
      const res = await Axios({
        method: "post",
        url: `/admin/academy/knowledgecover`,
        data: formdata,
      });
      if (res) {
        toast.success("Cover Image upload");
        setAllValues({ ...allValues, coverimage: res.data.location });
        return res.data.location;
      } else {
        toast.error("Something went wrong, please try again");
        return false;
      }
    } catch (error) {
      // if (error && error.response.status === 401) {
      //   navigate("/admin/login");
      // }
      toastResponseError(error);
      return false;
    }
  };

  const [paratext, setparaText] = useState("");
  const [paratext_de, setparaText_de] = useState("");
  const [paraheading, setparaheading] = useState("");
  const [paraheading_de, setparaheading_de] = useState("");
  const editRef = useRef();
  const [editsubcontent, seteditsubcontent] = useState();

  const handeleditsubcontent = (s) => {
    setshowEditor(true);
    seteditsubcontent(s);
    setparaheading(s.heading);
    setparaText(s.content);
    editRef?.current?.scrollIntoView({ behaviour: "smooth" });
  };
  const handleupdateParagraph = () => {
    let agendas = [...allValues.content];
    const updatedItems = agendas.map((item) =>
      item._id === editsubcontent._id
        ? { heading: paraheading, content: paratext,heading_de:paraheading_de,content_de:paratext_de }
        : item
    );
    setAllValues({ ...allValues, content: updatedItems });
    seteditsubcontent();
    setparaheading("");
    setparaText("");
    setparaText_de("")
    setparaheading_de("")
  };
  const [showEditor, setshowEditor] = useState(false);

  const handleAddParagraph = () => {
    let oldcontent = [...allValues.content];
    oldcontent.push({ heading: paraheading, content: paratext,heading_de:paraheading_de,content_de:paratext_de });
    setAllValues({ ...allValues, content: oldcontent });
    setparaText("");
    setparaheading("");
    setparaText_de("")
    setparaheading_de("")
  };
  const handleDeleteParagaph = (index) => {
    let oldcontent = [...allValues.content];
    setAllValues({
      ...allValues,
      content: oldcontent.filter((v, i) => i != index),
    });
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["image", "link"],
          [{ size: fontSizeStyle.whitelist }],
          [{ color: [] }, { background: [] }],
          ["clean"],
          ["code-block"],
        ],
        handlers: {
          image: () => {},
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "align",
    "link",
    "image",
    "font",
    "size",
    "color",
    "background",
    "code-block",
  ];
  const getdata = async () => {
    try {
      if (!kid) return;
      const response = await Axios.get(`/admin/academy/knowledge/${kid}`);
      if (response.data.success) {
        const { data } = response.data;
        data.author = data.author._id;
        setAllValues(data);
        setTitle(data.title);
        setDescription(data.description);
        setTitle_de(data.title_de)
        setDescription_de(data.description_de)
        setCover(data.coverimage);
        setAllKeywords(data.keywords);
      }
    } catch (error) {
      return toastResponseError(error);
    }
  };
  const kid = getUrlqueries("kid");
  useEffect(() => {
    getdata();
  }, [kid]);

  const getAuthors = async () => {
    await Axios({
      url: `/admin/academy/all-authors`,
      method: "get",
    })
      .then((res) => {
        setAuthors(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };

  const getTags = async () => {
    await Axios({
      url: `/admin/academy/all-tags`,
      method: "get",
    })
      .then((res) => {
        setAllTags(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };

  useEffect(() => {
    getAuthors();
    getTags();
  }, []);

  return (
    <div onClick={() => setShowKeywords(false)}>
      {/* <form onSubmit={handleCreateKnowledge}> */}
      <div className="form-group">
        <CoverImageCropper
          avatar={cover}
          setAvatar={setCover}
          handlePicUpload={handleCoverUpload}
          inputFileRef={inputFileCover}
          ratio={4 / 3}
        />
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="exampleFormControlSelect1">Choose Category</label>
        <select
          name="category"
          value={allValues.category}
          onChange={changeHandler}
          className="form-control"
          id="exampleFormControlSelect1"
        >
          <option value={1}>Sustainability Essentials </option>;
          <option value={2}>Policies & Regulations </option>;
          <option value={3}>Case Studies </option>;
          <option value={4}>Trends & Updates</option>;
          <option value={5}>Op-ed</option>;<option value={6}>More</option>;
        </select>
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">English Main Title</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={title}
          onChange={(v) => {
            setTitle(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write title in English..."
        />
        {/* <input
          value={allValues.title}
          onChange={changeHandler}
          type="text"
          required
          name="title"
          className="form-control"
          placeholder="Enter Title"
        /> */}
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">German Main Title</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={title_de}
          onChange={(v) => {
            setTitle_de(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write title in German ..."
        />
        {/* <input
          value={allValues.title}
          onChange={changeHandler}
          type="text"
          required
          name="title"
          className="form-control"
          placeholder="Enter Title"
        /> */}
      </div>
      <br />
      <h4>Summary </h4>
      <div className="form-group">
        <label>English Summary Heading</label>
        <input
          value={allValues.descriptionHeading}
          onChange={changeHandler}
          type="text"
          required
          name="descriptionHeading"
          className="form-control mb-12"
          placeholder="Enter Description heading in English"
        />
        <br/>
        <label>German Summary Heading</label>
        <input
          value={allValues.descriptionHeading_de}
          onChange={changeHandler}
          type="text"
          required
          name="descriptionHeading_de"
          className="form-control mb-12"
          placeholder="Enter Description heading in German"
        />
        <label>English Summary Description</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={description}
          onChange={(v) => {
            setDescription(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write Description in English..."
        />
        <br/>
        <label>German Summary Description</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={description_de}
          onChange={(v) => {
            setDescription_de(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write Description in German..."
        />
        {/* <textarea
          value={allValues.description}
          required
          onChange={changeHandler}
          type="text"
          name="description"
          className="form-control"
          placeholder="Enter Description"
        /> */}
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Author</label>
        {allAuthors.length > 0 ? (
          <select
            value={allValues.author}
            onChange={changeHandler}
            className="form-control"
            name="author"
          >
            <option value="">Select Author</option>
            {allAuthors.map((author) => (
              <option value={author._id} key={author._id}>
                {author.name}
              </option>
            ))}
          </select>
        ) : (
          <p
            className="pointer"
            onClick={() => navigate(`/admin/dashboard?tab=5&activetab=11`)}
          >
            Add Author
          </p>
        )}
        {/* <input
          value={allValues.author}
          onChange={changeHandler}
          type="text"
          required
          
          className="form-control"
          placeholder="Enter Title"
        /> */}
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="exampleFormControlSelect1">Choose Privacy</label>
        <select
          name="privacy"
          value={allValues.privacy}
          onChange={changeHandler}
          className="form-control"
          id="exampleFormControlSelect1"
        >
          <option value={0}>Public </option>
          <option value={1}>Private</option>
        </select>
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Add Keywords</label>

        {allTags.length > 0 ? (
          <div
            className="position-relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="form-control"
              onClick={(e) => {
                e.stopPropagation();
                setShowKeywords((prev) => !prev);
              }}
            >
              Select Keyword
            </div>
            {showKeywords && (
              <div className="show_keywords">
                {allTags.map((tag) => (
                  <div
                    className="pointer"
                    style={{
                      backgroundColor: tag.bg_color,
                      color: tag.text_color,
                      margin: "3px 0",
                    }}
                    key={tag._id}
                    onClick={() => {
                      const findIndexTag = allKeywords.findIndex(
                        (data) => data._id === tag._id
                      );
                      if (findIndexTag === -1) {
                        setAllKeywords((prev) => [...prev, tag]);
                      }
                    }}
                  >
                    {tag.tag_title}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <p
            className="pointer"
            onClick={() => navigate(`/admin/dashboard?tab=5&activetab=10`)}
          >
            Add Keyword
          </p>
        )}
      </div>
      {allKeywords.length > 0 && (
        <div className="d-flex align-items-center gap-4 flex-wrap mt-20">
          {allKeywords.map((tag) => (
            <div
              key={tag._id}
              style={{
                backgroundColor: tag.bg_color,
                padding: "4px 8px",
                borderRadius: 5,
              }}
            >
              <p className="heading_5" style={{ color: tag.text_color }}>
                {tag.tag_title}{" "}
                <Close
                  fontSize="small"
                  className="ml-8 pointer"
                  htmlColor={tag.text_color}
                  onClick={() => {
                    const newKeywords = allKeywords.filter(
                      (keyword) => keyword._id !== tag._id
                    );
                    setAllKeywords(newKeywords);
                  }}
                />
              </p>
            </div>
          ))}
        </div>
      )}

      <br />
      <div className="form-group">
        <ButtonComp
          status={2}
          onClick={() => {
            setshowEditor(!showEditor);
          }}
          type="button"
        >
          Add Content
        </ButtonComp>

        {showEditor && (
          <div ref={editRef}>
            <br />
            <br />
            <div className="form-group">
              <label>Enter Heading in English</label>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={paraheading}
                onChange={(v) => setparaheading(v)}
                modules={modules}
                formats={formats}
                className="blog-editor"
                placeholder="write Heading in English..."
              />
            </div>
            <br />
            <div className="form-group">
              <label>Enter Heading in German</label>
              <ReactQuill
                ref={quillRef}
                theme="snow"
                value={paraheading_de}
                onChange={(v) => setparaheading_de(v)}
                modules={modules}
                formats={formats}
                className="blog-editor"
                placeholder="write Heading in German..."
              />
            </div>
            <br />
            <div className="form-group">
              <label htmlFor="exampleFormControlFile1">Insert Image</label>
              <br />

              <input
                ref={inputFile}
                onChange={(e) => handlePicUpload(e, "knowledgeimage")}
                type="file"
                className="form-control-file"
                id="exampleFormControlFile1"
                accept="image/*"
              />
            </div>
            <br />

            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={paratext}
              onChange={(v) => setparaText(v)}
              modules={modules}
              formats={formats}
              className="blog-editor"
              placeholder="write paragraph in English..."
            />
            <br />
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={paratext_de}
              onChange={(v) => setparaText_de(v)}
              modules={modules}
              formats={formats}
              className="blog-editor"
              placeholder="write paragraph in German..."
            />
            <br />

            <div className="form-group">
              {editsubcontent && (
                <ButtonComp
                  status={1}
                  type="button"
                  onClick={() => handleupdateParagraph()}
                >
                  Update
                </ButtonComp>
              )}
              <ButtonComp
                status={2}
                type="button"
                onClick={() => handleAddParagraph("article")}
              >
                Add this Paragraph
              </ButtonComp>
            </div>
          </div>
        )}
        {allValues.content.map((v, i) => {
          return (
            <>
              <h4
                className="mt-16"
                dangerouslySetInnerHTML={{ __html: String(v.heading) }}
              ></h4>
              <h4
                className="mt-16"
                dangerouslySetInnerHTML={{ __html: String(v.heading_de) }}
              ></h4>
              <div
                key={i}
                id="blog-content"
                className={`blogpost_text_content`}
                dangerouslySetInnerHTML={{ __html: String(v.content) }}
              ></div>
              <div
                key={i}
                id="blog-content"
                className={`blogpost_text_content`}
                dangerouslySetInnerHTML={{ __html: String(v.content_de) }}
              ></div>
              <button
                className="btn btn-secondary me-2"
                onClick={() => handeleditsubcontent(v)}
                type="button"
              >
                Edit
              </button>
              <button
                className="btn btn-danger mt-2"
                onClick={() => handleDeleteParagaph(i)}
                type="button"
              >
                Delete this Paragraph
              </button>
            </>
          );
        })}
      </div>
      <br />
      <br />

      <div className="form-group">
        <ButtonComp
          onClick={() => handleCreateKnowledge()}
          status={1}
          type="button"
        >
          {kid ? "Update" : "Create"}
        </ButtonComp>
      </div>
      {/* </form> */}
    </div>
  );
};

export default CreateKnowledge;
