import React from "react";
import "./index.css";

const LinearProgressBar = ({
  barClassName,
  className,
  barBG,
  barWidth,
  barHeight,
  percent,
  bg,
  borderRadius,
  barBorderRadius,
  barBorder,
  barMaxWidth,
  barMinWidth,
}) => {
  return (
    <div
      className={"progressbar_root " + barClassName}
      style={{
        backgroundColor: barBG,
        width: barWidth,
        height: barHeight,
        borderRadius: borderRadius,
        border: barBorder,
        maxWidth: barMaxWidth,
        minWidth: barMinWidth,
      }}
    >
      <div
        className={"progressbar_main " + className}
        style={{
          width: `${percent >= 100 ? 100 : percent}%`,
          backgroundColor: bg,
          borderRadius: barBorderRadius,
        }}
      />
    </div>
  );
};

export default LinearProgressBar;
