import React, { useEffect, useState } from "react";
import "./productTarget.css";
// import LineBarChart from "./Components/LineBarChart";
// import SimpleProgressBar from "../../../ProgressBars/simpleProgressBar";
// import SpaceBarChart from "./Components/SpaceBarChart";
import TableTrackerImg from "../../../../../../../../Assets/images/targetTracker.png";
import CompanyTargetImg from "../../../../../../../../Assets/images/CompanyTarget.png";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import Axios from "../../../../../../../../Api";
import toast from "react-hot-toast";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../Utils/Auth";
import { notifedFeatures } from "../../../../../../../../Utils/CommonFunctions";
import TickMark from "../../../../../../../../Assets/icons/tickMark";

function ProductTarget() {
  const [isExist, setIsExist] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { setSessionExpire } = useAuth();
  const handleInviteFunc = async () => {
    setIsDisabled(true);
    await Axios({
      url: `/getnotified/add`,
      method: "POST",
      data: {
        feature: notifedFeatures.reduceTarget,
      },
    })
      .then((res) => {
        setIsDisabled(false);
        if (res.data.success) {
          toast.success(res.data.message);
          setIsExist(true);
        }
      })
      .catch((err) => {
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };
  const getAlreadyNotified = async () => {
    await Axios({
      url: `/getnotified/isExist/${notifedFeatures.reduceTarget}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          setIsExist(res.data.isExist);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getAlreadyNotified();
  }, []);

  return (
    <div className="container-fluid">
      <div className="container_design_1 p-50">
        <p className="heading_lg">Soon, you&apos;ll see your Targets here</p>
        <div className="subtitle_box bg_soft_blue  width_fit mt-24">
          <p className="subheading_4 color_blue">Launching in December</p>
        </div>
        <div className="row mt-16">
          <div className="col-6">
            <div className="d-flex justify-content-between h-100 flex-column">
              <p className="subheading_light">
                The companies of tomorrow need targets. We have created an easy
                process for you to confidently set and track targets for your
                entire company, or single accounting items.
              </p>
              {!isExist ? (
                <div className="border-top_ef pt-24">
                  <ButtonComp
                    status={5}
                    className="md width_fit"
                    onClick={handleInviteFunc}
                    disabled={isDisabled}
                  >
                    Get Notified
                  </ButtonComp>
                </div>
              ) : (
                <div className="border-top_ef pt-24">
                  <ButtonComp status={5} disabled={true} className="text-white">
                    <TickMark className="mr-10" cc={"#fff"} /> You’ll be
                    notified
                  </ButtonComp>
                </div>
              )}
            </div>
          </div>
          <div className="col-6">
            <div className="position-relative">
              <img
                src={TableTrackerImg}
                alt="table tracker"
                className="table-tracker-img"
              />
              <img
                src={CompanyTargetImg}
                alt="company Target"
                className="company-tracker-img"
                z
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="align_text_left">
        <p className="f-17 fw-700 font-inter">[Target Name]</p>
      </div>
      <div className="row mt-50 header_card_blue productTarget_headContainer_height">
        <div className="productTarget_sm_container col-md-4 col-lg-2 align_text_left p-16 ">
          <p className="f-30 fw-700 font-inter color_white">2020</p>
          <p className="overline_text color_white mt-2">BaseYear</p>
        </div>

        <div className="col-md-8 col-lg-3 align_text_left p-16 ml-32">
          <p className="f-30 fw-700 font-inter color_white">
            -49.373,22 <span className="body_text_sm">tCO2e</span>
          </p>
          <p className="overline_text color_white mt-2">Reduction Target</p>
        </div>

        <div className="col-md-4 col-lg-2 align_text_left  p-16">
          <p className="f-30 fw-700 font-inter color_white">-50%</p>
          <p className="overline_text color_white mt-2">Reduction Target</p>
        </div>

        <div className="col-md-4 col-lg-2 align_text_left p-16">
          <p className="f-30 fw-700 font-inter color_white">10%</p>
          <p className="overline_text color_white mt-2">Annual Reduction </p>
        </div>

        <div className="col-md-4 col-lg-2 align_text_left p-16">
          <p className="f-30 fw-700 font-inter color_white">5 years</p>
          <p className="overline_text color_white mt-2">Timehorizon </p>
        </div>
      </div>

      <div className="mt-50">
        <div className="align_text_left">
          <p className="f-17 fw-700">
            Target Tracker <span className="green_label">On Target</span>
          </p>
        </div>

        <div className="productDetail_lineBarChart_style">
          <LineBarChart />
        </div>

        <div className="row mt-32">
          <div className="col-lg-4">
            <p className="font_17_700">CO2e BUDGET</p>

            <div className="container_design_1 p-16 mt-20">
              <p className="heading_10 color_black">Company Reduction Target</p>

              <div className="d-flex justify-content-between mt-20">
                <div>
                <p className="heading_extra_large">-50% <span className="body_text_sm color_black">tCO2e</span></p>
                </div>
                <div>
                <p className="neutral_label f-14 fw-400">until 2030</p>
                </div>
              </div>

              <div className="row mt-20 ">
                <div className="col-md-4"><p className="neutral_label f-14 fw-400">for 2023</p></div>
                
                <div className="col-md-8 align_text_right mt-8">
                <SimpleProgressBar val={50}/>
                <span className="overline_text color_black">12t/50t</span>
                </div>
              </div>

              <div className="row mt-20">
                <div className="col-md-4"><p className="neutral_label f-14 fw-400">for 2023</p></div>
                
                <div className="col-md-8 align_text_right mt-8">
                <SimpleProgressBar val={50}/>
                <span className="overline_text color_black">12t/50t</span>
                </div>
              </div>

            </div>
          </div>
          <div className="col-lg-1"></div>
          <div className="col-lg-7">
              <p className="font_17_700">[TARGET NAME] ACTIONS</p>
              <div className="productDetail_lineBarChart_style mt-20">
              <SpaceBarChart/>
              </div>
          </div>

        </div>
      </div> */}
    </div>
  );
}

export default ProductTarget;
