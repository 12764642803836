import React from "react";
import { FormControl, MenuItem, Select } from "@mui/material";
import { selectSx } from "../../../../../../Utils/CommonFunctions";
import { KeyboardArrowDown } from "@mui/icons-material";

const SelectBaseYear = ({ selectBaseYear, setSelectBaseYear, years }) => {
  return (
    <React.Fragment>
      <p className="mt-40 font_12_600 color_7e line_height_18">
        Please choose a base year that will be used as a benchmark for measuring
        your emission reductions.
      </p>

      <div className="mt-30">
        <FormControl
          fullWidth
          sx={{ marginTop: "10px", height: "40px" }}
          size="small"
        >
          <Select
            value={selectBaseYear}
            onChange={(e) => {
              setSelectBaseYear(e.target.value, e.target);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={selectSx}
            IconComponent={KeyboardArrowDown}
            disabled={years.length === 0}
          >
            <MenuItem value={""}>Select</MenuItem>
            {years &&
              years.length > 0 &&
              years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>
    </React.Fragment>
  );
};

export default SelectBaseYear;
