import React, { useEffect, useState } from "react";
import axios from "../../Api";
import { toast } from "react-hot-toast";
import OtpInput from "react-otp-input";
import { toastResponseError } from "../../Utils/toastResponseError";
import { useAuth } from "../../Utils/Auth";
import BackForwardButton from "../../Assets/components/BackForwardButton/BackForwardButton";
import { maskMobileNumber } from "../../Utils/CommonFunctions";
import { useNavigate } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonComp from "../../Assets/components/Button";
import useQuery from "../../Utils/query";
import PressEnter from "../../Assets/components/PressEnter/PressEnter";

const PhoneOtpAuthentication = ({ setStep }) => {
  const phoneNumberRegex = /^\+?\d{1,3}?\d{10}$/;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [warning, setWarning] = useState("");
  const [otpStatus, setotpStatus] = useState(null);
  const [otp, setOtp] = useState();
  const [isDisabled, setIsDisabled] = useState(false);
  const [timer, setTimer] = useState(59);
  const [timerRunning, setTimerRunning] = useState(false);
  const { setUser, user, setSessionExpire, getUser } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const redirectPath = query.get("redirect");

  const handlePhoneNumberChange = (phoneNo) => {
    setPhoneNumber(phoneNo);
    setWarning("");
  };

  const sendOtp = async () => {
    if (!phoneNumberRegex.test(phoneNumber)) {
      return toast.error("Invalid phone number");
    }
    setOtp();
    setIsDisabled(true);
    await axios({
      url: `/two-fa/otp-request`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setotpStatus("sent");
          setTimer(59);
          setTimerRunning(true);
        } else {
          toast.error(res.data.error);
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const verifyOtp = async () => {
    if (!phoneNumberRegex.test(phoneNumber)) {
      return toast.error("Invalid phone number");
    }
    if (!otp) {
      return toast.error("Invalid OTP");
    }
    setIsDisabled(true);
    await axios({
      url: `/two-fa/otp-verify`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
        otp: otp,
      },
    })
      .then(async (response) => {
        if (response.data.status === "approved") {
          getUser();
          if (user.isBasePaid) {
            setUser({ ...user, is2FASet: true });
            if (redirectPath) {
              navigate(redirectPath);
            } else {
              navigate("/dashboard");
            }
          } else {
            navigate("/register/pending");
          }
        } else if (response.data.status === "pending") {
          toast.error("Invalid OTP");
          setOtp(null);
        } else {
          toast.error(response.data.message);
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    let interval = null;

    if (timerRunning) {
      interval = setInterval(() => {
        setTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    if (timer === 0) {
      setTimerRunning(false);
    }
  }, [timer]);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      if (otpStatus === "sent") {
        verifyOtp();
      } else {
        sendOtp();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [otpStatus, otp, phoneNumber]);

  return (
    <>
      <div className="subtitle_box bg_soft_blue width_fit">
        <p className="font_13_600 color_blue">SMS Authentication</p>
      </div>
      {otpStatus === "sent" ? (
        <>
          <p className="mt-32 offsetProjectTitle">
            Your Authentication Code was Sent to {maskMobileNumber(phoneNumber)}
          </p>
          <div className="otp_input mt-24">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span className="input_otp_span"></span>}
              className="otp_input"
              renderInput={(props) => <input {...props} />}
            />
            <div className="mt-12">
              {timerRunning && timer > 0 ? (
                <p className="overline_text">
                  Resend otp in : <span>{String(timer).padStart(2, "0")}</span>{" "}
                  sec
                </p>
              ) : (
                <p
                  className="overline_text pointer underline"
                  onClick={() => sendOtp()}
                >
                  Resend OTP
                </p>
              )}
            </div>
          </div>
          <div className="mt-6rem d-flex align-items-start justify-content-between">
            <BackForwardButton
              handleGoBack={() => setotpStatus("")}
              doNotShowForward={true}
            />
            <div>
              <ButtonComp
                className="ml-16"
                status={1}
                onClick={() => verifyOtp()}
              >
                Verify your Number
              </ButtonComp>
              <PressEnter />
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="mt-32 offsetProjectTitle">
            Please Enter your Phone Number to Receive the SMS Verification Code.
          </p>
          <div className="mt-64">
            <PhoneInput
              placeholder="Enter your Phone Number"
              value={phoneNumber}
              onChange={(e) => handlePhoneNumberChange(e)}
              className="sms_phone_box"
              international
              defaultCountry="DE"
            />
            {warning && <small>{warning}</small>}
          </div>
          <div className="mt-60 d-flex align-items-start justify-content-between">
            <BackForwardButton
              handleGoBack={() => setStep(0)}
              doNotShowForward={true}
            />
            <div>
              <ButtonComp
                status={1}
                className="ml-16"
                disabled={isDisabled}
                onClick={() => sendOtp()}
              >
                Send Verification Code
              </ButtonComp>
              <PressEnter />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PhoneOtpAuthentication;
