import React from "react";

const DeleteIcon = ({cc}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="15"
      fill="none"
      viewBox="0 0 13 15"
    >
      <g fill={cc || "#000"} clipPath="url(#clip0_1635_11840)">
        <path d="M12.064 2.68a.51.51 0 00-.446-.269H9.045v-.537a1.65 1.65 0 00-.452-1.14A1.511 1.511 0 007.502.262H4.414c-.41 0-.802.17-1.091.472a1.65 1.65 0 00-.452 1.14v.537H.538a.51.51 0 00-.446.27.559.559 0 000 .537.51.51 0 00.446.268h.275v8.061c0 .713.272 1.395.754 1.9a2.521 2.521 0 001.818.787h5.146c.682 0 1.336-.284 1.818-.788a2.751 2.751 0 00.755-1.899v-8.06h.514a.51.51 0 00.446-.27.56.56 0 000-.537zM3.9 1.874a.55.55 0 01.15-.38.503.503 0 01.365-.157h3.087c.136 0 .267.056.364.157.096.1.15.237.15.38v.537H3.9v-.537zm6.175 9.673c0 .428-.163.838-.452 1.14-.29.303-.682.473-1.092.473H3.385c-.41 0-.802-.17-1.091-.472a1.65 1.65 0 01-.452-1.14V3.486h8.232v8.061z"></path>
        <path d="M4.156 10.939c.159.096.355.096.514 0a.542.542 0 00.257-.466V6.174a.55.55 0 00-.15-.38.503.503 0 00-.728 0 .549.549 0 00-.15.38v4.3c0 .191.098.369.257.465zM7.242 10.939c.159.096.355.096.514 0a.542.542 0 00.257-.466V6.174a.55.55 0 00-.15-.38.503.503 0 00-.728 0 .549.549 0 00-.15.38v4.3c0 .191.097.369.257.465z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1635_11840">
          <path
            fill="#fff"
            d="M0 0H12.11V13.973H0z"
            transform="translate(.023 .262)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default DeleteIcon;
