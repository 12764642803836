import React, { useEffect, useState } from "react";
import "./itemSettings.css";
import accountingItemData from "../../../../../items-new.json";
import { Switch } from "@mui/material";
import TextInputSettings from "./Components/textInput";
import SelectInputSettings from "./Components/selectInput";
import industries from "../../../../../../../../DataJSON/DropdownTypes/industries.json";
import events from "../../../../../../../../DataJSON/DropdownTypes/events.json";
import facilities from "../../../../../../../../DataJSON/DropdownTypes/facilities.json";
import axios from "../../../../../../../../Api/index";
import toast from "react-hot-toast";
import NumberPercentInput from "./Components/numberPercentInput";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../Utils/Auth";
import EditIcon from "../../../../../../../../Assets/icons/EditIcon";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import AddressInput from "./Components/addressInput";
import { useNavigate } from "react-router-dom";

function ItemSettings(props) {
  const [accountingItemIndex] = useState(0);
  const history = useNavigate();
  const [answers, setAnswers] = useState({});
  const [questionRows, setQuestionRows] = useState([]);
  const [isLogActivate, setIsLogActivate] = useState(false);

  // const [connectionQuestion, setConnectionQuestion] = useState([]);
  // const [locationList, setLocationList] = useState([]);
  const [editFirstQuestionSet, setEditFirstQuestionSet] = useState(false);
  // const [editConnection, seteditConnection] = useState(false);
  const { setSessionExpire } = useAuth;
  const handleEditChange = () => {
    setEditFirstQuestionSet(!editFirstQuestionSet);
  };
  // useEffect(() => {
  //   setEditFirstQuestionSet(false);
  //   accountingItemData.forEach((item, index) => {
  //     if (item.name.toLowerCase() === props.itemName.toLowerCase()) {
  //       if (item.name.toLocaleLowerCase() === "event") {
  //         setConnectionQuestion([
  //           accountingItemData[index].secondQuestionSet[0].questions[1],
  //         ]);
  //         getConnectionItemDetails();
  //       }
  //       setAccountingItemIndex(index);
  //     }
  //   });
  // }, []);
  // const getLocations = async () => {
  //   try {
  //     if (!props.year) return;
  //     const response = await axios.get(
  //       `/log/get-log?itemId=${`6442af32ca73043a8ec66c78`}&year=${props.year}` // should get data less than equal to selected year
  //     );
  //     if (response.data.success) {
  //       let currLocations = [""];
  //       response.data.logs.forEach((location) => {
  //         currLocations.push(location.itemDetails.name);
  //       });
  //       setLocationList(currLocations);
  //     }
  //   } catch (error) {
  //     toastResponseError(error, setSessionExpire);
  //   }
  // };
  // const getConnectionItemDetails = async () => {
  //   getLocations();
  // };

  const getLogData = async () => {
    try {
      await axios.get(`log/getLogDetails?logId=${props.logId}`).then((res) => {
        //console.log("data is ", res.data.logDetails);
        setAnswers(res.data.logDetails);
        setIsLogActivate(res.data.isOffsetActivate);
      });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };
  const handleAnswerChange = (name, value) => {
    let currAnswers = { ...answers };
    currAnswers[name] = value;
    setAnswers(currAnswers);
  };
  useEffect(() => {
    if (props.logId) getLogData();
  }, [props.logId, props.isCrateCellUpdate]);

  useEffect(() => {
    getQuestionRow();
  }, [
    accountingItemIndex,
    answers,
    editFirstQuestionSet,
    props.isCrateCellUpdate,
  ]);

  const getQuestionCol = (index) => {
    let currQuestions = accountingItemData[accountingItemIndex].addService;
    let dropDownOptions = [];
    if (currQuestions[index] && currQuestions[index].type === "select") {
      if (currQuestions[index].name.toLowerCase() === "industry") {
        dropDownOptions = industries;
      }
      if (currQuestions[index].name.toLowerCase() === "event") {
        dropDownOptions = events;
      }
      if (currQuestions[index].name.toLowerCase() === "facilitytype") {
        dropDownOptions = facilities;
      }
    }
    return (
      <div className="col-lg-4 col-md-4 col-6 mb-40">
        {currQuestions.length > index && (
          <>
            {currQuestions[index].type === "text" ? (
              <TextInputSettings
                disabled={editFirstQuestionSet}
                title={currQuestions[index].title}
                type={currQuestions[index].type}
                name={currQuestions[index].name}
                handleAnswerChange={handleAnswerChange}
                answers={answers}
              />
            ) : currQuestions[index].type === "select" ? (
              <SelectInputSettings
                disabled={editFirstQuestionSet}
                title={currQuestions[index].title}
                type={currQuestions[index].type}
                dropDownList={dropDownOptions}
                handleAnswerChange={handleAnswerChange}
                name={currQuestions[index].name}
                answers={answers}
              />
            ) : currQuestions[index].type === "number" ? (
              <NumberPercentInput
                disabled={editFirstQuestionSet}
                title={currQuestions[index].title}
                type={currQuestions[index].type}
                dropDownList={dropDownOptions}
                handleAnswerChange={handleAnswerChange}
                name={currQuestions[index].name}
                answers={answers}
              />
            ) : currQuestions[index].type === "address" ? (
              <>
                <p
                  className="sr-only subheading_1 mb-10"
                  htmlFor="product_name"
                >
                  {props.title}
                </p>
                <AddressInput
                  disabled={editFirstQuestionSet}
                  handleAnswerChange={handleAnswerChange}
                  name={currQuestions[index].name}
                  answers={answers}
                />
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    );
  };
  const getQuestionRow = () => {
    let rows = [];
    accountingItemData[accountingItemIndex].addService.forEach(
      (currQuestion, index) => {
        rows.push(getQuestionCol(index));
      }
    );

    setQuestionRows(rows);
  };

  const handleSave = async () => {
    setEditFirstQuestionSet(false);
    try {
      await axios
        .post(`log/updateLogDetails?logId=${props.logId}`, {
          logDetails: answers,
        })
        .then((res) => {
          if (res.data.success) {
            toast.success("info has been updated");
          }
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      <p className="subheading_3 text-uppercase">Item Settings</p>

      <div className="border_radius_10 border-ef mt-20 ">
        <div className="d-flex p-32">
          <div className="row flex-grow-1">
            {questionRows.map((ques) => ques)}
          </div>
          <div className="">
            <button
              className={
                "profileEditbtn " +
                (editFirstQuestionSet === false ? "" : "display_none")
              }
              onClick={handleEditChange}
            >
              <EditIcon />
            </button>
            {/* <i
              className={`bi bi-pencil pointer ${
                editFirstQuestionSet === false ? "" : "display_none"
              }`}
            ></i> */}
          </div>
        </div>
        <div className="row border-ef w-100 ml-0 p-16 border_bottom_0">
          <div className=" mt-32">
            <div className="d-flex align-items-center mb-16">
              <p className="sr-only subheading_1">Carbon neutrality settings</p>
              <div className="bg_d9 color_white br_50 ml-8 d-flex align-items-center justify-content-center">
                <i className="bi bi-question "></i>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <Switch
                inputProps={{ "aria-label": "controlled" }}
                checked={isLogActivate}
                // onChange={(e) => {
                //   console.log(e.target.checked);
                //   setIsLogActivate(e.target.checked);
                // }}
              />
              <p className="font-inter f-12">
                <span className="fw-600"> Contributions Active</span>{" "}
                (Compensation Obligations until 11/2023)
              </p>
            </div>
            <p className="helper_text fw-400 mt-10">
              This action will terminate the auto-renewal of your Carbon Neutral
              label and your associated compensation obligations.
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-end p-4">
          {editFirstQuestionSet ? (
            <ButtonComp status={1} onClick={() => handleSave()} className="">
              Save Changes
            </ButtonComp>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="mt-50">
        <p className="sr-only subheading_1">Connections</p>

        <div className="d-flex justify-content-between align-items-center border_radius_10 border-ef mt-20 p-16">
          <div>
            {/* {editConnection === false ? ( */}
            <p className="neutral_label body_text_s">
              {answers["DoubleAccountingLocation"]
                ? answers["DoubleAccountingLocation"]
                : "NA"}
            </p>
            {/* ) : ( */}
            {/* connectionQuestion.map((curr, index) => {
                return (
                  <SelectInputSettings
                    key={index}
                    title={curr.title}
                    dropDownList={locationList}
                    handleAnswerChange={handleAnswerChange}
                    name={curr.name}
                    answers={answers}
                    disabled={editConnection}
                  />
                );
              })
            )} */}
          </div>
          <div>
            <ButtonComp
              status={5}
              onClick={() => history("/")}
              style={{ display: "none" }}
            >
              Edit Connection
            </ButtonComp>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemSettings;
