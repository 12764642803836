import { Backdrop, Box, Modal } from "@mui/material";
import React from "react";
import ButtonComp from "../../../../Assets/components/Button";
// import { ArrowBackIos } from "@mui/icons-material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 460,
  bgcolor: "white",
  padding: "20px",
  maxWidth: "90%",
  borderRadius: "10px",
};

const FileSizeExceedModal = ({ open, handleClose, handleFunction, size }) => {
  return (
    <Modal
      keepMounted
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        <p className="font_16_500">File Size Exceeded!</p>
        <p className="mt-10 font_12_500 color_7e">
          Your selected file exceeds {size || 2} MB. Please select a smaller file or
          reduce the image size before uploading.
        </p>
        <div
          className="d-flex align-items-center justify-content-end mt-20"
          style={{ gap: 20 }}
        >
          <ButtonComp status={5} style={{ backgroundColor: "#d9d9d9" }} onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="7"
              height="12"
              fill="none"
              viewBox="0 0 7 12"
              className="mr-10"
            >
              <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 1L1 6l5 5"
              ></path>
            </svg>{" "}
            Go Back
          </ButtonComp>
          <ButtonComp status={5} onClick={handleFunction}>Upload File</ButtonComp>
        </div>
      </Box>
    </Modal>
  );
};

export default FileSizeExceedModal;
