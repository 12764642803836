import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import "./index.css";
import Axios from "../../../../../Api";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
import Assessment from "./Assessment";
import AssessmentResult from "./AssessmentResult";

const CSRDAssessment = () => {
  // const navigate = useNavigate();
  const [assessmentQuestion, setAssessmentQuestion] = useState([
    {
      question: "What is your company’s main region of business?",
      answer: null,
      options: ["EU", "UK", "USA", "Elswhere"],
      type: "select",
      correctAnswer: null,
    },
    {
      question: "What is your company’s main industry?",
      answer: null,
      options: [
        "Construction & Real Estate",
        "Food & Beverage",
        "Consulting & Services",
        "Insurance",
      ],
      type: "select",
      correctAnswer: null,
    },
    {
      question: "How many employees does your company have?",
      answer: null,
      options: ["250 or less", "250 - 500", "Over 500"],
      type: "select",
      correctAnswer: ["250 - 500", "Over 500"],
    },
    {
      question: "What was your company’s last annual revenue?",
      answer: null,
      options: ["More than €40 million", "Less than €40 million"],
      type: "select",
      correctAnswer: "More than €40 million",
    },
    {
      question: "How much does your company have in assets?",
      answer: null,
      options: ["€20 million or more", "Less than €20 million"],
      type: "select",
      correctAnswer: "€20 million or more",
    },
    {
      question: "Is your company publicly listed on any stock exchange?",
      answer: null,
      options: ["Yes", "No"],
      type: "option",
      correctAnswer: "Yes",
    },
    {
      question: "Have you started to account your carbon footprint?",
      answer: null,
      options: ["Yes", "No"],
      type: "option",
      correctAnswer: null,
    },
  ]);
  const [resultState, setResultState] = useState(0);
  const { setSessionExpire } = useAuth();

  const CreateAssessment = async () => {
    await Axios({
      url: `/share/csrd/create`,
      method: "POST",
      data: {
        qna: [...assessmentQuestion],
      },
    })
      .then((res) => {
        if (res.data.success) {
          // navigate(`/dashboard?tab=6`);
          setResultState(1);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  return resultState === 0 ? (
    <Assessment
      setResultState={setResultState}
      CreateAssessment={CreateAssessment}
      assessmentQuestion={assessmentQuestion}
      setAssessmentQuestion={setAssessmentQuestion}
    />
  ) : (
    <AssessmentResult assessmentQuestion={assessmentQuestion} />
  );
};

export default CSRDAssessment;
