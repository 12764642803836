import React from "react";

function PaperCheck(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="21"
        fill="none"
        viewBox="0 0 23 21"
      >
        <path
          stroke={props.stroke}
          strokeWidth="2"
          d="M10.873 3.836h-3.25c-1.468 0-2.203 0-2.659.456-.456.457-.456 1.191-.456 2.66v7.79c0 1.468 0 2.203.456 2.659.456.456 1.19.456 2.66.456h4.673c1.47 0 2.204 0 2.66-.456.456-.456.456-1.19.456-2.66V8.376c0-.318 0-.478-.06-.62-.058-.144-.17-.256-.396-.482l-2.982-2.982c-.225-.225-.338-.337-.481-.397-.143-.06-.303-.06-.62-.06z"
        ></path>
        <path
          stroke={props.stroke}
          strokeLinecap="round"
          strokeWidth="2"
          d="M7.617 11.625h4.674M7.617 14.742h3.116"
        ></path>
        <path
          stroke={props.stroke}
          strokeWidth="2"
          d="M10.734 3.836v3.116c0 .734 0 1.101.229 1.33.228.228.595.228 1.33.228h3.115"
        ></path>
        <path
          stroke={props.stroke}
          fill="#000"
          fillRule="evenodd"
          d="M17.108 5.791a.779.779 0 01.55-.954l2.634-.705a.779.779 0 11.403 1.505l-2.633.705a.779.779 0 01-.954-.55zM19.05 1.28a.779.779 0 01-.066 1.099L17.68 3.535a.779.779 0 01-1.033-1.166l1.303-1.156a.779.779 0 011.1.066zM21.06 9.395a.78.78 0 01-.986.493l-1.652-.551a.779.779 0 11.492-1.478l1.653.55a.779.779 0 01.492.986z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default PaperCheck;
