import React, { useEffect } from "react";
import ButtonComp from "../../../../../../Assets/components/Button";
import PressEnter from "../../../../../../Assets/components/PressEnter/PressEnter";

const IntroScreen = ({ step, setStep }) => {
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      setStep(1);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [step]);

  return (
    <div className="d-flex h-100 flex-column justify-content-center">
      <div className="csrd_assessment_text_w">
        <div className="subtitle_box bg_soft_blue width_fit">
          <p className="color_blue font_12_700 text-uppercase">3 Minutes</p>
        </div>
        <p className="heading_lg mt-24">
          Find Guidance for the CSRD Regulations
        </p>
      </div>
      <div className="d-flex align-items-end flex-column mt-90">
        <ButtonComp status={2} size="large" onClick={() => setStep(1)}>
          Start
        </ButtonComp>
        <PressEnter />
      </div>
    </div>
  );
};

export default IntroScreen;
