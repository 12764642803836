import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import axios from "../../../Api";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { useAuth } from "../../../Utils/Auth";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const Invoices = () => {
  const {t} =  useTranslation()
  const { setSessionExpire } = useAuth();
  const [invocesArr, setInvoicesArr] = useState([]);
  // pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getInvoices = async () => {
    await axios({
      url: `/cart/company-carts`,
      method: "get",
    })
      .then((res) => {
        setInvoicesArr(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getInvoices();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      invocesArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, invocesArr]
  );

  return (
    <div className="user_box">
      <div className="border-btm_ef pb-24">
        <p className="offsetProjectTitle">{t("settings.invoices")}</p>
      </div>
      <div>
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640, borderRadius: "9px", boxShadow: "none" }}
          className="mr-50 mt-20"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  sx={{ borderLeft: "0" }}
                  component="th"
                  className="text-uppercase"
                >
                  {t("settings.invoiceName")}
                </HeadCell>
                <HeadCell component="th" className="text-uppercase">
                {t("settings.invoiceName")} / {t("settings.frequency")}
                </HeadCell>
                <HeadCell component="th" className="text-uppercase">
                {t("settings.billingDate")}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderRight: "0" }}
                >
                   {t("settings.autoRenewal")}
                </HeadCell>
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {visibleRows &&
                visibleRows.length > 0 &&
                visibleRows.map((invoice) => (
                  <StyledTableRow
                    key={invoice._id}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell
                      className="text-start"
                      component="th"
                      scope="row"
                      sx={{ borderLeft: "0" }}
                    >
                      Invoice {invoice.invoiceID}
                    </StyledTableCell>
                    <StyledTableCell className="text-start">
                      {invoice.grandTotal} EUR / annually
                    </StyledTableCell>
                    <StyledTableCell className="text-start">
                      {new Date(invoice.updatedAt).toLocaleDateString("en-GB")}
                    </StyledTableCell>
                    <StyledTableCell
                      className="text-start"
                      sx={{ borderRight: "0" }}
                    >
                      <p className="underline">
                        <a
                          href={invoice.invoicePdfLink}
                          target="_blank"
                          download={"invoice.pdf"}
                          rel="noopener noreferrer"
                          className="text-black"
                        >
                          Download PDF
                        </a>
                      </p>
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {invocesArr && invocesArr.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={invocesArr.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="table-pagiation"
          />
        )}
      </div>
    </div>
  );
};

export default Invoices;
