import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../Utils/TableComp";
import { useAuth } from "../../../../../../Utils/Auth";
import { useTranslation } from "react-i18next";
function ScopeDetailsTable(props) {
  const {user} = useAuth();
  const {t} = useTranslation()
  return (
    <div className="">
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 640,
          boxShadow: "0px 3px 16px 0px rgba(0, 0, 0, 0.06)",
          borderRadius: "10px",
        }}
      >
        <Table
          sx={{
            minWidth: 640,
            borderRadius: "10px",
            border: "0",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <StyledHeadRow>
              <HeadCell
                sx={{
                  borderLeft: "0",
                  borderBottom: "0",
                  width: "20%",
                  padding: "10px 10px 12px 24px !important",
                  backgroundColor: "#fff",
                  textDecorationLine: "underline",
                }}
                component="th"
                className="text-uppercase font_13_700"
              >
                Scope
              </HeadCell>
              <HeadCell
                sx={{
                  width: "50%",
                  padding: "10px 10px 12px 24px !important",
                  backgroundColor: "#fff",
                  textDecorationLine: "underline",
                }}
                component="th"
              >
                {
                  t("store.category")
                }
              </HeadCell>
              <HeadCell
                component="th"
                className="text-uppercase"
                sx={{
                  width: "30%",
                  padding: "10px 10px 12px 24px !important",
                  backgroundColor: "#fff",
                  textDecorationLine: "underline",
                  borderRight: "0px",
                }}
              >
                {
                  t("store.label")
                }
              </HeadCell>
            </StyledHeadRow>
          </TableHead>

          <TableBody>
            {props.tableData &&
              Object.keys(props.tableData).map((currScope, index) => {
                return (
                  <StyledTableRow
                    key={index + currScope}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell
                      sx={{
                        borderLeft: "0",
                        borderRight: "0",
                        padding: "8px 10px 8px 24px !important",
                      }}
                      component="th"
                      textleft={true}
                      className="text_overflow-1 align_text_left text_capital_f"
                    >
                      <div className="d-flex">
                        {props.tableData[currScope].scope == 1 ? (
                          <p className="font_13_400 flex-grow-0 border_radius_5 bg_light_yellow pt-4px pb-4px pl-12 pr-12">
                            Scope 1
                          </p>
                        ) : props.tableData[currScope].scope == 2 ? (
                          <p className="font_13_400 flex-grow-0 border_radius_5 color_bg_ede pt-4px pb-4px pl-12 pr-12">
                            Scope 2
                          </p>
                        ) : props.tableData[currScope].scope == 3 ? (
                          <p className="font_13_400 flex-grow-0 border_radius_5 color_bg_d4 pt-4px pb-4px pl-12 pr-12">
                            Scope 3
                          </p>
                        ) : (
                          <p className="font_13_400 flex-grow-0 border_radius_5 bg_light_yellow pt-4px pb-4px pl-12 pr-12">
                            Scope 4
                          </p>
                        )}
                      </div>
                    </StyledTableCell>

                    <StyledTableCell
                      textleft={true}
                      sx={{
                        borderLeft: "0",
                        borderRight: "0",
                        padding: "8px 10px 8px 24px !important",
                      }}
                      className="font_13_300"
                    >
                      {user.language === 'de' ? props.tableData[currScope].scope_name_de : currScope}
                    </StyledTableCell>

                    <StyledTableCell
                      textleft={true}
                      sx={{
                        borderLeft: "0",
                        borderRight: "0",
                        padding: "8px 10px 8px 24px !important",
                      }}
                      className="color_green"
                    >
                      {props.tableData[currScope].label == "mandatory" ? (
                        <span className="color_green">{t("store.mandatory")}</span>
                      ) : (
                        <span className="color_e3">{t("store.recommended")}</span>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default ScopeDetailsTable;
