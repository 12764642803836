import React, { useState, useEffect } from "react";
import QuestionMarkCircle from "../../../../../../Assets/icons/QuestionMarkCircle";
import { FormControl, MenuItem, Select } from "@mui/material";
import { selectSx } from "../../../../../../Utils/CommonFunctions";
import { KeyboardArrowDown } from "@mui/icons-material";
import ChartWithLine from "./Chart/ChartWithLine";
import Toggle from "react-styled-toggle";
import { NumericFormat } from "react-number-format";

const TargetSetting = ({
  selectBaseYear,
  includeScope3,
  setIncludeScope3,
  scopeWiseData,
  targetType,
  setTargetType,
  targetYear,
  setTargetYear,
  target1Reduction,
  setTarget1Reduction,
  target2Reduction,
  setTarget2Reduction,
  target3Reduction,
  setTarget3Reduction,
  reducedPercentage_scope1,
  setReducedPercentage_scope1,
  reducedPercentage_scope2,
  setReducedPercentage_scope2,
  reducedPercentage_scope3,
  setReducedPercentage_scope3,
  chartData,
  setChartData,
}) => {
  const [timeFrameYears, setTimeFrameYears] = useState([]);
  const [inputPercentage_scope1, setInputPercentage_scope1] = useState("");
  const [inputPercentage_scope2, setInputPercentage_scope2] = useState("");
  const [inputPercentage_scope3, setInputPercentage_scope3] = useState("");

  useEffect(() => {
    if (targetType === "Custom") {
      const years = Array.from({ length: 20 }, (_, i) => i + 1).reverse();
      setTimeFrameYears(years);
    } else {
      const years = Array.from({ length: 6 }, (_, i) => 5 + i).reverse();
      setTimeFrameYears(years);
    }
  }, [targetType]);

  useEffect(() => {
    if (targetType && targetYear && scopeWiseData !== null) {
      if (targetType === "1.5") {
        setTarget1Reduction((scopeWiseData.scope1 * 0.3).toFixed(2));
        setTarget2Reduction((scopeWiseData.scope2 * 0.3).toFixed(2));
        setTarget3Reduction((scopeWiseData.scope3 * 0.3).toFixed(2));
        setReducedPercentage_scope1(30);
        setReducedPercentage_scope2(30);
        setReducedPercentage_scope3(30);
        setInputPercentage_scope1(30);
        setInputPercentage_scope2(30);
        setInputPercentage_scope3(30);
      } else if (targetType === "2") {
        setTarget1Reduction((scopeWiseData.scope1 * 0.4).toFixed(2));
        setTarget2Reduction((scopeWiseData.scope2 * 0.4).toFixed(2));
        setTarget3Reduction((scopeWiseData.scope3 * 0.4).toFixed(2));
        setReducedPercentage_scope1(40);
        setReducedPercentage_scope2(40);
        setReducedPercentage_scope3(40);
        setInputPercentage_scope1(40);
        setInputPercentage_scope2(40);
        setInputPercentage_scope3(40);
      } else if (targetType === "gt2") {
        setTarget1Reduction((scopeWiseData.scope1 * 0.5).toFixed(2));
        setTarget2Reduction((scopeWiseData.scope2 * 0.5).toFixed(2));
        setTarget3Reduction((scopeWiseData.scope3 * 0.5).toFixed(2));
        setReducedPercentage_scope1(50);
        setReducedPercentage_scope2(50);
        setReducedPercentage_scope3(50);
        setInputPercentage_scope1(50);
        setInputPercentage_scope2(50);
        setInputPercentage_scope3(50);
      } else {
        setReducedPercentage_scope1(0);
        setReducedPercentage_scope2(0);
        setReducedPercentage_scope3(0);
        setInputPercentage_scope1(0);
        setInputPercentage_scope2(0);
        setInputPercentage_scope3(0);
        setTarget1Reduction(0);
        setTarget2Reduction(0);
        setTarget3Reduction(0);
      }
    }
  }, [targetType, targetYear, scopeWiseData]);

  useEffect(() => {
    if (scopeWiseData !== null) {
      const data = [
        {
          year: selectBaseYear,
          scope1: scopeWiseData.scope1,
          scope2: scopeWiseData.scope2,
          scope3: includeScope3 ? scopeWiseData.scope3 : 0,
          total:
            scopeWiseData.scope1 +
            scopeWiseData.scope2 +
            (includeScope3 ? scopeWiseData.scope3 : 0),
        },
      ];
      const scope1DataToReduced = target1Reduction / Number(targetYear);
      const scope2DataToReduced = target2Reduction / Number(targetYear);
      const scope3DataToReduced = target3Reduction / Number(targetYear);

      for (let i = 0; i < Number(targetYear); i++) {
        const scope1Data =
          scopeWiseData.scope1 === 0
            ? 0
            : scopeWiseData.scope1 - scope1DataToReduced * (i + 1);
        const scope2Data =
          scopeWiseData.scope2 === 0
            ? 0
            : scopeWiseData.scope2 - scope2DataToReduced * (i + 1);
        const scope3Data =
          includeScope3 === false || scopeWiseData.scope3 === 0
            ? 0
            : scopeWiseData.scope3 - scope3DataToReduced * (i + 1);

        const scopeData = {
          year: selectBaseYear + i + 1,
          scope1: scope1Data,
          scope2: scope2Data,
          scope3: scope3Data,
          total: scope1Data + scope2Data + scope3Data,
        };
        data.push(scopeData);
      }
      setChartData(data);
    }
  }, [
    target1Reduction,
    target2Reduction,
    target3Reduction,
    includeScope3,
    scopeWiseData,
    targetYear,
  ]);

  const isAllowed = (e) => {
    if (e.floatValue === undefined) return true;
    if (e.floatValue <= 100) return true;
    return false;
  };

  return (
    <div className="">
      <div className="d-flex align-items-center gap_20 mt-40">
        <div style={{ width: 280 }}>
          <p className="font_12_600 color_7e line_height_10 text-capitalize">
            Target Type
            <QuestionMarkCircle
              className={"ml-10"}
              circleColor={"#7E8999"}
              cc={"#fff"}
            />
          </p>
          <FormControl
            fullWidth
            sx={{ marginTop: "10px", height: "40px" }}
            size="small"
          >
            <Select
              value={targetType}
              onChange={(e) => {
                setTargetType(e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={selectSx}
              IconComponent={KeyboardArrowDown}
            >
              <MenuItem value={""}>Select</MenuItem>
              <MenuItem value={"1.5"}>1.5°C</MenuItem>
              <MenuItem value={"2"}>2°C</MenuItem>
              <MenuItem value={"gt2"}>&gt;2°C</MenuItem>
              <MenuItem value={"Custom"}>Custom</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="flex-1">
          <p className="font_12_600 color_7e line_height_10 text-capitalize">
            Target Time Frame
            <QuestionMarkCircle
              className={"ml-10"}
              circleColor={"#7E8999"}
              cc={"#fff"}
            />
          </p>
          <FormControl
            fullWidth
            sx={{ marginTop: "10px", height: "40px" }}
            size="small"
          >
            <Select
              value={targetYear}
              onChange={(e) => {
                setTargetYear(e.target.value);
              }}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={selectSx}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300,
                  },
                },
              }}
              IconComponent={KeyboardArrowDown}
            >
              <MenuItem value={""}>Select</MenuItem>
              {timeFrameYears.length > 0 &&
                timeFrameYears.map((year, i) => (
                  <MenuItem value={year} key={`${year}-${i}`}>
                    {year} Years
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <hr className="mt-30 mb-30" />
      <div className="">
        <p className="font_12_600 color_7e line_height_10 text-capitalize">
          Scope 1 Reduction
        </p>
        <div className="gap_10 d-flex align-items-center mt-10">
          <input
            type="number"
            className=" font_14_300 grey_input"
            placeholder="0,00 tCO2"
            style={{ width: 292 }}
            value={target1Reduction}
            onChange={(e) => {
              setTargetType("Custom");
              setTarget1Reduction(e.target.value);
              const per = (Number(e.target.value) * 100) / scopeWiseData.scope1;
              setReducedPercentage_scope1(per.toFixed(2));
              setInputPercentage_scope1(per.toFixed(2));
            }}
          />
          <NumericFormat
            suffix="%"
            className="font_14_300 grey_input targetPercentageInput"
            placeholder="0 %"
            style={{ width: 50 }}
            value={inputPercentage_scope1}
            max={100}
            isAllowed={isAllowed}
            onChange={(e) => {
              setTargetType("Custom");
              const value = Number(e.target.value.split("%")[0]);
              if (Number(value) <= 100) {
                setInputPercentage_scope1(e.target.value);
                setReducedPercentage_scope1(value);
                setTarget1Reduction(scopeWiseData.scope1 * (value / 100));
              }
            }}
          />
          <div className="bg_soft_green px-10 py-8 border_radius_10">
            <p className="font_14_600 color_green line_height_14">
              {reducedPercentage_scope1 > 0
                ? Number(reducedPercentage_scope1 / targetYear).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )
                : 0}
              % p.a.
            </p>
          </div>
        </div>
      </div>
      <div className="mt-30">
        <p className="font_12_600 color_7e line_height_10 text-capitalize">
          Scope 2 Reduction
        </p>
        <div className="gap_10 d-flex align-items-center mt-10">
          <input
            type="text"
            className=" font_14_300 grey_input"
            placeholder="0,00 tCO2"
            style={{ width: 292 }}
            value={target2Reduction}
            onChange={(e) => {
              setTargetType("Custom");
              setTarget2Reduction(e.target.value);
              const per = (Number(e.target.value) * 100) / scopeWiseData.scope2;
              setReducedPercentage_scope2(per.toFixed(2));
              setInputPercentage_scope2(per.toFixed(2));
            }}
          />
          <NumericFormat
            suffix="%"
            className="font_14_300 grey_input targetPercentageInput"
            placeholder="0 %"
            style={{ width: 50 }}
            value={inputPercentage_scope2}
            isAllowed={isAllowed}
            onChange={(e) => {
              setTargetType("Custom");
              const value = Number(e.target.value.split("%")[0]);
              if (value <= 100) {
                setInputPercentage_scope2(e.target.value);
                setTarget2Reduction(scopeWiseData.scope2 * (value / 100));
                setReducedPercentage_scope2(value);
              }
            }}
          />
          <div className="bg_soft_green px-10 py-8 border_radius_10">
            <p className="font_14_600 color_green line_height_14">
              {reducedPercentage_scope2 > 0
                ? Number(reducedPercentage_scope2 / targetYear).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )
                : 0}
              % p.a.
            </p>
          </div>
        </div>
      </div>
      <hr className="mt-30 mb-10" />
      <div className="d-flex align-items-center gap_10">
        <Toggle
          checked={includeScope3}
          onChange={(e) => {
            setIncludeScope3(e.target.checked);
          }}
          backgroundColorChecked="#F6F6F6"
          backgroundColorUnchecked="#F6F6F6"
          backgroundColorButton="#00915B"
        />
        <p className="font_12_600 color_7e line_height_10 text-capitalize">
          Include Scope 3
        </p>
      </div>
      <div className="mt-30">
        <p className="font_12_600 color_7e line_height_10 text-capitalize">
          Scope 3 Reduction
        </p>
        <div className="gap_10 d-flex align-items-center mt-10">
          <input
            type="text"
            className="font_14_300 grey_input"
            placeholder="0,00 tCO2"
            style={{ width: 292 }}
            disabled={!includeScope3}
            value={target3Reduction}
            onChange={(e) => {
              setTargetType("Custom");
              setTarget3Reduction(e.target.value);
              const per = (Number(e.target.value) * 100) / scopeWiseData.scope3;
              setReducedPercentage_scope3(per.toFixed(2));
              setInputPercentage_scope3(per.toFixed(2));
            }}
          />
          <NumericFormat
            suffix="%"
            className="font_14_300 grey_input targetPercentageInput"
            placeholder="0 %"
            style={{ width: 50 }}
            value={inputPercentage_scope3}
            disabled={!includeScope3}
            isAllowed={isAllowed}
            onChange={(e) => {
              setTargetType("Custom");
              const value = Number(e.target.value.split("%")[0]);
              if (value <= 100) {
                setInputPercentage_scope3(e.target.value);
                setTarget3Reduction(scopeWiseData.scope3 * (value / 100));
                setReducedPercentage_scope3(value);
              }
            }}
          />
          <div
            className={
              " px-10 py-8 border_radius_10 " +
              (includeScope3 ? "bg_soft_green" : "bg_f6")
            }
          >
            <p
              className={
                "line_height_14 " +
                (includeScope3 ? "color_green font_14_600" : "font_14_300")
              }
            >
              {reducedPercentage_scope3 > 0
                ? Number(reducedPercentage_scope3 / targetYear).toLocaleString(
                    undefined,
                    { maximumFractionDigits: 2 }
                  )
                : 0}
              % p.a.
            </p>
          </div>
        </div>
      </div>
      {chartData.length > 0 && (
        <>
          <hr className="mt-30 mb-30" />
          <div className="border_1_ef border_radius_5">
            <ChartWithLine
              data={chartData}
              includeScope3={includeScope3}
              setChartData={setChartData}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TargetSetting;
