import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import BackForwardButton from "../../../../Assets/components/BackForwardButton/BackForwardButton";
import KeyboardForwardIcon from "../../../../Assets/icons/KeyboardForwardIcon";
import ButtonComp from "../../../../Assets/components/Button";

export default function CheckoutForm({
  setValue,
  selectedPaymentInfo,
  setBillingStep,
  getPaymentMethods,
  log,
  unique_id,
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: `${
          process.env.REACT_APP_GA_KEY === ""
            ? "http://app.today.green"
            : "http://make.today.green"
        }/dashboard?tab=5&subtab=1&step=3&log=${log}&offset=${unique_id}`,
      },
    });

    if (result.error) {
      setErrorMessage(result.error.message);
    } else {
      getPaymentMethods();
      toast.success("Card added successfully!");
      setValue(0);
    }
  };

  return (
    <form className="paymentElementForm">
      {selectedPaymentInfo && (
        <div className="d-flex align-items-center border-btm_ef pb-16 mb-24">
          <img src={selectedPaymentInfo.img} alt="Sepa" />
          <p className="subheading_light_2 color_black ml-12">
            {selectedPaymentInfo.title}
          </p>
          <div className={"subtitle_box ml-12 " + selectedPaymentInfo.bg}>
            <p className={"subheading_4 " + selectedPaymentInfo.color}>
              {selectedPaymentInfo.fees}
            </p>
          </div>
        </div>
      )}
      <PaymentElement className="form-payment-element" />
      <div className="d-flex justify-content-between align-items-center mt-40">
        <BackForwardButton
          doNotShowForward={true}
          handleGoBack={() => setBillingStep(0)}
        />
        <ButtonComp
          className="lg"
          status={1}
          disabled={!stripe}
          onClick={handleSubmit}
        >
          {selectedPaymentInfo.buttonText || "Save & Continue"}{" "}
          {selectedPaymentInfo.forwardBtn && (
            <span className="ml-6">
              <KeyboardForwardIcon cc={"#ffffff"} />
            </span>
          )}
        </ButtonComp>
      </div>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
}
