import React from "react";
import { Handle, Position } from "reactflow";
import { Skeleton } from "@mui/material";
function SlotHolderNode() {
  return (
    <div className="w-250px h-320px bg_ef  border_radius_5 pl-24 pt-15 pr-24 pb-60">
      <Handle type="target" position={Position.Top} />
      <div className="d-flex align-items-center justify-content-between mt-20">
        <div>
          <Skeleton variant="rounded" width={160} height={10} />
          <div className="mt-10">
            <Skeleton variant="rounded" width={30} height={5} />
          </div>
        </div>
        <Skeleton variant="circular" width={10} height={10} />
      </div>
      <div className="mt-50">
        <Skeleton variant="rounded" width={200} height={30} />
      </div>

      <div className="mt-40">
        <Skeleton variant="rounded" width={200} height={30} />
      </div>

      <div className="mt-40 mb-40">
        <Skeleton variant="rounded" width={200} height={30} />
      </div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
}

export default SlotHolderNode;
