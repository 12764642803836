import React, { useState } from "react";
import "./index.css";
import CameraAddIcon from "../../../../Assets/icons/CameraAddIcon";
import PencilIcon from "../../../../Assets/icons/PencilIcon";
import LaptopIcon from "../../../../Assets/icons/LaptopIcon.png";
import LinearPlaceholder from "../Components/LinearPlaceholder";
import ClimateFactBox from "../Components/ClimateFactBox";
import MedelIcon from "../../../../Assets/icons/MedelIcon.png";
import TreeIcon from "../../../../Assets/icons/TreeIcon.png";
import CloudIcon from "../../../../Assets/icons/CloudIcon.png";
import MemberSinceIcon from "../Icons/MemberSince.png";
import TargetIcon from "../Icons/TargetIcon.png";
import GraphIcon from "../Icons/GraphIcon.png";
import ImpressionImagePlaceholder from "../Components/ImpressionImagePlaceholder";
import LabelPlaceholder from "../Icons/LabelPlaceholder";
import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../Utils/TableComp";
import ShowSectionSelect from "../Components/ShowSectionSelect";
import CompanyProfileModal from "../Components/CompanyProfileModal";
import WhyWeManageModal from "../Components/WhyWeManageModal";
import AboutModal from "../Components/AboutModal";
import ClimateFactsModal from "../Components/ClimateFactsModal";
import CarbonNeutralityModal from "../Components/CarbonNeutralityModal";
import DisClosureModal from "../Components/DisClosureModal";
import { useEffect } from "react";
import Axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
import ReportComponent from "../Components/ReportComponent";
import { KeyboardArrowDown } from "@mui/icons-material";
import { handleSaveData } from "../Components/CommonFunctions";
import HorizontalLabel from "../../../../Assets/components/CarbonNuetrolLabel/HorizontalLabel";
import parse from "html-react-parser";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 686,
  maxWidth: "90%",
  bgcolor: "#fff",
  outline: "none",
  padding: "20px",
  borderRadius: "10px",
  maxHeight: 670,
};

const TransparencyDirectory = () => {
  const [modalNumber, setModalNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [companyData, setCompanyData] = useState(null);
  const { profile, setSessionExpire } = useAuth();
  const [isShowPublicOpen, setShowPublicOpen] = useState(false);
  const [isPublic, setIsPublic] = useState(true);
  const [climateFacts, setClimateFacts] = useState(null);
  const [contributionHisory, setContributionHisory] = useState([]);
  const [labels, setLabels] = useState([]);
  const [labelYear, setLabelYear] = useState(null);

  const getCompanyData = async () => {
    await Axios({
      url: `/share/tranparancy`,
      method: "GET",
    }).then((res) => {
      setCompanyData(res.data.data);
      if (res.data.data && res.data.data.isPublic) {
        setIsPublic(res.data.data.isPublic);
      }
    });
    // .catch((err) => {
    //   toastResponseError(err, setSessionExpire);
    // });
  };

  const getClimateFacts = async () => {
    await Axios({
      url: `/share/tranparancy/climate-facts/${profile.labelId}`,
      method: "GET",
    })
      .then((res) => {
        setClimateFacts(res.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getContributionHistory = async () => {
    await Axios({
      url: `/share/tranparancy/contribution/${profile.labelId}`,
      method: "GET",
    })
      .then((res) => {
        setContributionHisory(res.data.projects);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getLabels = async () => {
    await Axios({
      url: `/neutral/transparency-labels/${profile.labelId}`,
      method: "GET",
    })
      .then((res) => {
        const { data } = res.data;
        setLabels(data);
        let year = 9999;
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            if (year > element.startDate.year) {
              year = element.startDate.year;
            }
          }
          setLabelYear(year === 9999 ? null : year);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getCompanyData();
    getClimateFacts();
    getLabels();
    getContributionHistory();
  }, [profile]);

  return (
    <div className="pb-40">
      <div className="carbon_neutron_root mt-20">
        <div className="d-flex justify-content-end">
          <div
            className="share_select_root position-relative"
            style={{ width: 193 }}
          >
            <div className="share_select_main2 ">
              <div
                className="d-flex align-items-center"
                onClick={() => setShowPublicOpen(!isShowPublicOpen)}
              >
                <span
                  className={
                    "circle_12 " + (isPublic ? "bg_light_green" : "bg_dark_red")
                  }
                ></span>
                <p className="font_14_500 text-black ml-10">
                  {isPublic ? "Public" : "Private"}
                </p>
                <KeyboardArrowDown
                  fontSize="small"
                  sx={{ marginLeft: "20px" }}
                />
              </div>
              {isShowPublicOpen && (
                <div
                  className="d-flex align-items-center share_select_option pointer"
                  onClick={() => {
                    setIsPublic(!isPublic);
                    const data = {
                      isPublic: !isPublic,
                    };

                    handleSaveData(
                      data,
                      getCompanyData,
                      setShowPublicOpen(false),
                      setSessionExpire
                    );
                  }}
                >
                  <span
                    className={
                      "circle_12 " +
                      (isPublic ? "bg_dark_red" : "bg_light_green")
                    }
                  ></span>
                  <p className="font_14_500 text-black ml-10">
                    {isPublic ? "Private" : "Public"}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="share_trans_company_info">
          {companyData && companyData.companyBackground ? (
            <div className="">
              <img
                className="share_trans_company_bg"
                src={companyData.companyBackground}
                alt={companyData?.companyName}
              />
            </div>
          ) : (
            <div className="share_trans_company_bg">
              <div className="share_trans_camera_cover">
                <CameraAddIcon />
              </div>
            </div>
          )}
          {companyData && companyData.companylogo ? (
            <img
              className="share_trans_company_logo"
              src={companyData.companylogo}
              alt={companyData?.companyName}
            />
          ) : profile && profile.logo ? (
            <img
              className="share_trans_company_logo"
              src={profile.logo}
              alt={profile?.companyName}
            />
          ) : (
            <div className="share_trans_company_logo">
              <div className="share_trans_camera_cover">
                <CameraAddIcon />
              </div>
            </div>
          )}
          <div className="d-flex gap_40">
            <div className="flex-1">
              <div className="d-flex align-items-center gap-4 mt-20">
                {companyData && companyData.companyName ? (
                  <p
                    className="plus_jakarta_24_800 text_overflow-1"
                    style={{ maxWidth: 500 }}
                  >
                    {companyData.companyName}
                  </p>
                ) : (
                  <p
                    className="plus_jakarta_24_800 text_overflow"
                    style={{ maxWidth: 500 }}
                  >
                    {profile?.companyName}
                  </p>
                )}
                {companyData && companyData.establishedYear ? (
                  <div className="chip_grey">
                    <p className="font_12_500 color_57 line_height_20">
                      est. {companyData.establishedYear}
                    </p>
                  </div>
                ) : (
                  <div className="chip_grey">
                    <p className="font_12_500 color_57">est. </p>
                  </div>
                )}
                <button
                  className="edit_button_share"
                  onClick={() => {
                    setModalNumber(1);
                    handleOpen();
                  }}
                >
                  <PencilIcon />
                </button>
              </div>
              <div className="d-flex align-items-center gap_10 mt-15">
                {companyData && companyData.companyIndustry ? (
                  <div className="green_chip">
                    <p className="font_12_700">{companyData.companyIndustry}</p>
                  </div>
                ) : (
                  <div className="green_chip">
                    <p className="font_12_700">{profile?.industry || "-"}</p>
                  </div>
                )}
                {companyData && companyData.companyEmployeesNo ? (
                  <div className="green_chip">
                    <p className="font_12_700">
                      {companyData.companyEmployeesNo} Employees
                    </p>
                  </div>
                ) : (
                  <div className="green_chip">
                    <p className="font_12_700">{profile?.empCount || "-"}</p>
                  </div>
                )}
                {companyData && companyData.companyLocation ? (
                  <div className="green_chip">
                    <p className="font_12_700">{companyData.companyLocation}</p>
                  </div>
                ) : (
                  <div className="green_chip">
                    <p className="font_12_700">
                      {profile?.stateCode}, {profile?.country}
                    </p>
                  </div>
                )}
              </div>
            </div>
            <div>
              <a
                href={
                  companyData && companyData.companyWebsite
                    ? companyData.companyWebsite
                    : profile?.website
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <div className="visit_website_box">
                  <p className="font_16_500 line_height_20">Visit Website</p>
                  <img src={LaptopIcon} alt={companyData?.companyWebsite} />
                </div>
              </a>
              <div
                className="bg_soft_green border_radius_5 mt-15"
                style={{
                  padding: "5px 10px",
                }}
              >
                <p className="color_green font_12_700">ID {profile?.labelId}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="share_transpency_manage_carbon_root mt-40">
        <div className="d-flex align-items-center justify-content-between">
          <div
            className="bg_black border_radius_5 width_fit"
            style={{ padding: "8px 10px" }}
          >
            <p className="text-uppercase font_16_700 text-white">
              Why We Manage our Carbon
            </p>
          </div>
          <button
            className="edit_button_share"
            onClick={() => {
              setModalNumber(2);
              handleOpen();
            }}
          >
            <PencilIcon />
          </button>
        </div>
        {companyData && companyData.whyManageCarbon ? (
          <p className="plus_jakarta_18_500 line_height_24 color_57 mt-15 text-justify">
            {companyData.whyManageCarbon}
          </p>
        ) : (
          <>
            <LinearPlaceholder className={"mt-20"} />
            <LinearPlaceholder className={"mt-20"} />
          </>
        )}
        <div className="mt-15 d-flex align-items-center">
          {companyData && companyData.userPicture ? (
            <img
              src={companyData.userPicture}
              alt={companyData.userFirstname}
              className="img_40"
            />
          ) : (
            <div className="img_placeholder"></div>
          )}
          <div
            className="d-flex justify-content-between flex-column ml-12"
            style={{ height: 40 }}
          >
            {companyData &&
            (companyData.userFirstname || companyData.userLastname) ? (
              <p className="font_16_700">
                {companyData.userFirstname} {companyData.userLastname}
              </p>
            ) : (
              <LinearPlaceholder width={100} />
            )}
            {companyData && companyData.userJobtitle ? (
              <p className="font_13_400">{companyData.userJobtitle}</p>
            ) : (
              <LinearPlaceholder width={40} />
            )}
          </div>
        </div>
      </div>
      <div className="share_transpency_manage_carbon_root share_transpency_climate_fact_root mt-40">
        <div className="d-flex align-items-center justify-content-between">
          <p className="font_32_800_plus_jakarta text-white">
            Climate action facts
          </p>
          <button
            className="edit_button_share bg_white"
            onClick={() => {
              setModalNumber(3);
              handleOpen();
            }}
          >
            <PencilIcon cc={"#fff"} />
          </button>
        </div>
        <div className="mt-30 share_climate_grid_main">
          <ClimateFactBox
            year={climateFacts?.memberSince}
            title={"Member since"}
            iconImage={MedelIcon}
            visible={companyData?.factMemberSince}
          />
          <ClimateFactBox
            year={climateFacts?.carbonSince}
            title={"Managing carbon since"}
            iconImage={MemberSinceIcon}
            visible={companyData?.factManagingCarbonSince}
          />
          <ClimateFactBox
            year={
              climateFacts && climateFacts.target
                ? Number(climateFacts.target).toLocaleString()
                : 0
            }
            title={"Emission target until 2030"}
            iconImage={TargetIcon}
            visible={companyData?.factEmisssionTarget}
          />
          <ClimateFactBox
            year={
              climateFacts && climateFacts.compensated
                ? Number(climateFacts.compensated).toLocaleString()
                : 0
            }
            title={"tCO2e compensated"}
            iconImage={TreeIcon}
            visible={companyData?.fact_tCo2_compensated}
          />
          <ClimateFactBox
            year={
              climateFacts && climateFacts.totalEmitted
                ? Number(climateFacts.totalEmitted).toLocaleString()
                : 0
            }
            title={"tCO2e emitted 2022"}
            iconImage={CloudIcon}
            visible={companyData?.fact_tCo2_emitted}
          />
          <ClimateFactBox
            year={
              climateFacts && climateFacts.reduced
                ? Number(climateFacts.reduced).toLocaleString()
                : 0
            }
            title={"tCO2e reduced"}
            iconImage={GraphIcon}
            visible={companyData?.fact_tCo2_reduced}
          />
        </div>
      </div>
      <div className="share_transpency_manage_carbon_root mt-40">
        <div className="d-flex align-items-center justify-content-between pb-30 border-btm_ef">
          <p className="font_32_800_plus_jakarta">
            About {companyData?.companyName}
          </p>
          <button
            className="edit_button_share"
            onClick={() => {
              setModalNumber(4);
              handleOpen();
            }}
          >
            <PencilIcon />
          </button>
        </div>
        {companyData && companyData.companyAbout ? (
          <p className="mt-30 font_16_400 line_height_24 color_57 text-justify">
            {parse(String(companyData.companyAbout).replace(/\n/g, "<br/>"))}
          </p>
        ) : (
          <div className="d-flex flex-column mt-30" style={{ gap: 20 }}>
            <LinearPlaceholder />
            <LinearPlaceholder />
            <LinearPlaceholder />
            <LinearPlaceholder />
            <LinearPlaceholder />
            <LinearPlaceholder />
          </div>
        )}
        <div className="mt-30">
          <p className="font_16_700 text-uppercase">IMPRESSIONS</p>
          <div className="mt-20 impression_placeholder_main">
            <ImpressionImagePlaceholder
              imgSource={companyData?.companyImpressionImg1}
            />
            <ImpressionImagePlaceholder
              imgSource={companyData?.companyImpressionImg2}
            />
            <ImpressionImagePlaceholder
              imgSource={companyData?.companyImpressionImg3}
            />
            <ImpressionImagePlaceholder
              imgSource={companyData?.companyImpressionImg4}
            />
          </div>
        </div>
      </div>
      <div className="carbon_neutron_root mt-40">
        <ShowSectionSelect
          name="showCarbonNeutrality"
          getCompanyData={getCompanyData}
          companyData={companyData}
        />
        <div className="share_transpency_manage_carbon_root">
          <div className="row pb-32 border-btm_ef">
            <div className="col-lg-6 col-md-6 col-12">
              <div
                className="bg_black border_radius_5 width_fit"
                style={{ padding: "8px 10px" }}
              >
                <p className="text-uppercase font_16_700 text-white">
                  Carbon Neutrality
                </p>
              </div>
              <p className="font_64_800_plus_jakarta mt-10">
                Meaningful contributions
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <CarbonNeutralityModal
                getCompanyData={getCompanyData}
                companyData={companyData}
              />
            </div>
          </div>
          <div className="d-flex mt-30 gap-5 justify-content-between">
            <div
              className={
                "width_fit carbon_neutrol_label_sideBox " +
                (labels.length > 0 && labelYear && "bg_white")
              }
            >
              <p className="font_42_800_plus_jakarta">
                {labels.length > 0 ? labelYear : "N/A"}
              </p>
              <p className="font_16_400 color_57 line_height_24">
                Carbon neutral items since
              </p>
            </div>
            {labels && labels.length > 0 ? (
              <div
                className="d-flex align-items-center flex-wrap justify-content-end"
                style={{ gap: 20 }}
              >
                {labels.map((label) => (
                  <HorizontalLabel
                    key={label._id}
                    item={label.logId?.accountingItemName}
                  />
                ))}
              </div>
            ) : (
              <div
                className="d-flex align-items-center flex-wrap justify-content-end"
                style={{ gap: 20 }}
              >
                <LabelPlaceholder />
                <LabelPlaceholder />
                <LabelPlaceholder />
                <LabelPlaceholder />
                <LabelPlaceholder />
                <LabelPlaceholder />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="carbon_neutron_root mt-40">
        <ShowSectionSelect
          name="showContributionHistory"
          getCompanyData={getCompanyData}
          companyData={companyData}
        />
        <div className="share_transpency_manage_carbon_root">
          <p className="font_32_800_plus_jakarta">Contribution history</p>
          <div className="mt-20 contribution_history_box">
            <p className="plus_jakarta_18_500 color_57">
              Every contribution tells a story. With traceability, it&apos;s
              told transparently
            </p>
          </div>
          <div className="mt-30 analytics_table_container">
            <TableContainer
              component={Paper}
              sx={{ minWidth: 640, borderRadius: "10px", boxShadow: "none" }}
              className="mr-50"
            >
              <Table sx={{ minWidth: 640 }} aria-label="simple table">
                <TableHead>
                  <StyledHeadRow sx={{ backgroundColor: "#FBFBFB !important" }}>
                    <HeadCell
                      component="th"
                      className="text-uppercase border_left_0 border_top_0"
                      sx={{
                        borderLeft: "0",
                        border: "1px solid #efefef",
                        padding: "14.7px 20px",
                      }}
                    >
                      Climate Protection Project
                    </HeadCell>
                    <HeadCell
                      component="th"
                      className="border_top_0"
                      sx={{
                        border: "1px solid #efefef",
                        padding: "14.7px 20px",
                      }}
                    >
                      Compensated Amount
                    </HeadCell>
                    <HeadCell
                      component="th"
                      className="border_right_0 border_top_0"
                      sx={{
                        border: "1px solid #efefef",
                        padding: "14.7px 20px",
                      }}
                    >
                      Date
                    </HeadCell>
                  </StyledHeadRow>
                </TableHead>
                <TableBody>
                  {contributionHisory && contributionHisory.length > 0 ? (
                    contributionHisory.map((contribution, i) => (
                      <StyledTableRow
                        sx={{
                          backgroundColor: i % 2 !== 0 ? "#fbfbfb" : "#fff",
                          "&:last-child td, &:last-child th": {
                            borderBottom: 0,
                          },
                        }}
                        key={contribution._id}
                      >
                        <StyledTableCell
                          component="th"
                          scope="row"
                          className="text-start border_left_0"
                          sx={{ padding: "14.7px 20px" }}
                        >
                          {contribution.name}
                        </StyledTableCell>
                        <StyledTableCell
                          className="text-start"
                          sx={{ padding: "14.7px 20px" }}
                        >
                          {contribution.emission} tCO2e
                        </StyledTableCell>
                        <StyledTableCell
                          className="text-start border_right_0"
                          sx={{ padding: "14.7px 20px" }}
                        >
                          {new Date(contribution.date).toLocaleDateString(
                            "en-GB",
                            {
                              dateStyle: "long",
                            }
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    ))
                  ) : (
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell
                        component="th"
                        scope="row"
                        className="text-start"
                      >
                        N/A
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        N/A
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        N/A
                      </StyledTableCell>
                    </StyledTableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <div className="carbon_neutron_root mt-40">
        <ShowSectionSelect
          name="showDisclosure"
          getCompanyData={getCompanyData}
          companyData={companyData}
        />
        <div className="share_transpency_manage_carbon_root">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-12 d-flex flex-column justify-content-between">
              <div>
                <div className="d-flex align-items-center">
                  <div
                    className="bg_black border_radius_5 width_fit"
                    style={{ padding: "7px 10px" }}
                  >
                    <p className="text-uppercase font_16_700 text-white">
                      disclosure
                    </p>
                  </div>
                  <button
                    className="edit_button_share bg_white ml-32"
                    onClick={() => {
                      setModalNumber(6);
                      handleOpen();
                    }}
                  >
                    <PencilIcon cc={"#fff"} />
                  </button>
                </div>
                <div className="mt-10">
                  <p className="font_32_800_plus_jakarta">
                    Transparency all the way
                  </p>
                  <p className="plus_jakarta_18_500 color_57 mt-10">
                    {companyData?.companyName} has shared one or more reports
                    with you!
                  </p>
                </div>
              </div>
              {companyData &&
              (companyData.esgReport || companyData.ghgReport) ? (
                <div className="d-flex flex-column" style={{ gap: 20 }}>
                  {companyData.esgReport && (
                    <ReportComponent
                      title="ESG Report"
                      year={companyData.esgReport?.timeFrame}
                      downloadLink={companyData.esgReport?.link}
                    />
                  )}
                  {companyData.ghgReport && (
                    <ReportComponent
                      title="GHG Report"
                      year={
                        companyData.ghgReport.timeFrame.startYear
                          ? companyData.ghgReport.timeFrame.startYear
                          : companyData.ghgReport.timeFrame.year
                      }
                      downloadLink={companyData.ghgReport?.reportUrl}
                    />
                  )}
                </div>
              ) : (
                <>
                  <LinearPlaceholder height={116} background={"#f6f6f6"} />
                  <LinearPlaceholder
                    height={116}
                    className={"mt-20"}
                    background={"#f6f6f6"}
                  />
                </>
              )}
              <div></div>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              {companyData && companyData.disclosureImg ? (
                <img
                  src={companyData.disclosureImg}
                  alt={companyData?.companyName}
                  className="impresssion_image_placeholder"
                  style={{ aspectRatio: "56/47" }}
                />
              ) : (
                <div
                  className="impresssion_image_placeholder position-relative"
                  style={{ aspectRatio: "56/47" }}
                >
                  <div className="share_trans_camera_cover">
                    <CameraAddIcon />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            {modalNumber === 1 ? (
              <CompanyProfileModal
                handleClose={handleClose}
                getCompanyData={getCompanyData}
                companyData={companyData}
              />
            ) : modalNumber === 2 ? (
              <WhyWeManageModal
                handleClose={handleClose}
                getCompanyData={getCompanyData}
                companyData={companyData}
              />
            ) : modalNumber === 3 ? (
              <ClimateFactsModal
                handleClose={handleClose}
                getCompanyData={getCompanyData}
                companyData={companyData}
                climateFacts={climateFacts}
              />
            ) : modalNumber === 4 ? (
              <AboutModal
                handleClose={handleClose}
                getCompanyData={getCompanyData}
                companyData={companyData}
              />
            ) : (
              modalNumber === 6 && (
                <DisClosureModal
                  handleClose={handleClose}
                  getCompanyData={getCompanyData}
                  companyData={companyData}
                />
              )
            )}
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default TransparencyDirectory;
