import React from "react";
import bulletImage from "../../../../Assets/images/bullets.png";
import ButtonComp from "../../../../Assets/components/Button";
import { useNavigate } from "react-router-dom";
import axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import { CopyToClipboard } from "react-copy-to-clipboard";
import parse from "html-react-parser";

const Tutorials = ({ tutorials, getdata }) => {
  const navigate = useNavigate();
  const handleDeleteTopic = async (cid) => {
    try {
      const response = await axios.delete(`/admin/academy/deletetut/${cid}`);
      if (response.data.success) {
        getdata();
        toast.success("Tutorial deleted successfully");
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };

  return (
    <>
      <h1 className="heading_9">Tutorials</h1>

      <div className="row mx-0">
        {tutorials.map((v, i) => (
          <div className="col-3" key={i}>
            <div className="container_design_1 p-3">
              {v.covertype === "video" || v.covertype === "image" ? (
                <img
                  className=""
                  src={v.cover.image}
                  width="100%"
                  height="auto"
                />
              ) : (
                <img src={bulletImage} width="100%" height="auto" />
              )}
              <p className="mt-8">
                <img src={v.icon} height="24px" width="24px" /> &nbsp;
                {parse(v.title)}
              </p>
              <p>{parse(v.description)}</p>
              {!v.isLive && <p style={{ color: "red" }}> Draft </p>}
              <br />
              <div className="d-flex justify-content-between align-item-center">
                <div className="">
                  <ButtonComp
                    status={1}
                    onClick={() =>
                      navigate(
                        `/admin/dashboard?tab=5&activetab=2&tutorialid=${v._id}`
                      )
                    }
                  >
                    Update
                  </ButtonComp>
                  &nbsp;
                  <ButtonComp
                    status={2}
                    onClick={() => handleDeleteTopic(v._id)}
                  >
                    Delete
                  </ButtonComp>
                </div>
                <CopyToClipboard
                  text={`http://localhost:3000/dashboard?tab=7&acadTab=0&id=${v._id}&step=2`}
                  onCopy={() => toast.success("link copied!")}
                >
                  <div className="copy-area">
                    <ButtonComp status={3}>Copy link</ButtonComp>
                  </div>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        ))}
      </div>
      <br />
      <br />

      {tutorials.map((v, i) => {
        return (
          <div key={i}>
            <h1 className="heading_9">
              Tutorial Title : <img src={v.icon} height="40px" width="40px" />{" "}
              &nbsp; {parse(v.title)}{" "}
            </h1>
            <p>Description : {parse(v.description)} </p>
            <div className="mt-40">
              <p>Author</p>
              {v.author && (
                <div className="d-flex align-items-center general_card p-14 width_fit mt-12">
                  <img
                    src={v.author.image}
                    alt={v.author.name}
                    className="img_40"
                  />
                  <div className="ml-8 flex-1">
                    <p className="font_14_500">{v.author.name}</p>
                    <p className="font_13_700 mt-6">{v.author.designation}</p>
                  </div>
                </div>
              )}
            </div>
            {v.keywords.length > 0 && (
              <div>
                <p>Keywords </p>
                <div className="d-flex align-items-center flex-wrap gap-4 mt-20">
                  {v.keywords.map((tag) => (
                    <div
                      key={tag._id}
                      style={{
                        backgroundColor: tag.bg_color,
                        padding: "4px 8px",
                        borderRadius: 5,
                      }}
                    >
                      <p
                        className="heading_5"
                        style={{ color: tag.text_color }}
                      >
                        {tag.tag_title}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <p>{v?.SummarydescriptionHeadig} </p>
            {v.Summarydescription && (
              <p>Summary Description : {parse(v.Summarydescription)} </p>
            )}

            {v.covertype === "image" ? (
              <div className="form-group">
                <label htmlFor="exampleFormControlFile1">Cover Image</label>
                <div hidden={!v.cover.image}>
                  <img src={v.cover.image} height="100px" width="100px" />
                </div>
              </div>
            ) : v.covertype === "video" ? (
              <>
                <br />

                <label htmlFor="exampleFormControlFile1">Thumbnail</label>
                <div hidden={!v.cover.image}>
                  <img src={v.cover.image} height="100px" width="auto" />
                </div>
                <br />
                <label htmlFor="exampleFormControlFile1">Tutorial Video </label>
                <div hidden={!v.cover.video}>
                  <video width="auto" height="360" controls>
                    <source src={v.cover.video} />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </>
            ) : v.covertype === "list" ? (
              <>
                <p> {v.cover.listheading} </p>
                <ul>
                  {v.cover.listbullets.map((p, i) => (
                    <li
                      key={i}
                      id="blog-content"
                      className={`blogpost_text_content`}
                      dangerouslySetInnerHTML={{ __html: String(p) }}
                    ></li>
                  ))}
                </ul>
              </>
            ) : (
              <></>
            )}
            {v.content.map((p, i) => {
              return (
                <>
                  <h4
                    className="mt-16"
                    dangerouslySetInnerHTML={{ __html: String(p.heading) }}
                  ></h4>
                  <div
                    key={i}
                    id="blog-content"
                    className={`blogpost_text_content`}
                    dangerouslySetInnerHTML={{ __html: String(p.content) }}
                  ></div>
                </>
              );
            })}
            <hr />
          </div>
        );
      })}
    </>
  );
};

export default Tutorials;
