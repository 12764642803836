import React, { useEffect, useState } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import axios from "../../../../Api";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../Utils/Auth";

const Glossary = () => {
  const [glossary, setglossary] = useState([]);
  const { setSessionExpire } = useAuth();
  const getdata = async () => {
    try {
      const response = await axios.get(`/admin/academy/glossary`);
      if (response.data.success) {
        setglossary(response.data.data);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    getdata();
  }, []);

  const handleDeleteTopic = async (cid) => {
    try {
      const response = await axios.delete(
        `/admin/academy/deleteglossary/${cid}`
      );
      if (response.data.success) {
        getdata();
        toast.success("Glossary deleted successfully");
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  const navigate = useNavigate();
  return (
    <>
      <div className="row mx-0">
        {glossary &&
          glossary.map((v, i) => {
            return (
              <>
                <Card className="mb-4 me-4" key={i} sx={{ maxWidth: 345 }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {v.keyword}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      <div
                        key={i}
                        id="blog-content"
                        className={`blogpost_text_content`}
                        dangerouslySetInnerHTML={{
                          __html: String(v.description),
                        }}
                      ></div>
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() =>
                        navigate(
                          `/admin/dashboard?tab=5&activetab=7&gid=${v._id}`
                        )
                      }
                      size="small"
                    >
                      Edit{" "}
                    </Button>
                    <Button
                      onClick={() => handleDeleteTopic(v._id)}
                      size="small"
                    >
                      Delete
                    </Button>
                  </CardActions>
                </Card>
              </>
            );
          })}
      </div>
    </>
  );
};

export default Glossary;
