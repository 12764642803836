import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "../../../../../../Api";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../Utils/Auth";
import { toast } from "react-hot-toast";
import ButtonComp from "../../../../../../Assets/components/Button";

const Step4 = ({ option, data, getAllTables }) => {
  const [acceptTerm, setAcceptTerm] = useState(false);
  const navigate = useNavigate();
  const { setSessionExpire } = useAuth();

  const handleSubmit = async () => {
    try {
      let response = await axios.get(
        `/log/select-option?option=${option}&logId=${data._id}`
      );
      if (response.data.success) {
        toast.success("offset activated!");
        getAllTables();
        navigate(
          `/dashboard?tab=5&subtab=1&step=1&log=${data._id}&offset=${response.data.uniqueId}`
        );
      } else toast.error(response.data.message);
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  return (
    <div>
      <p className="font_17_700 mb-40">
        3. Please commit to accounting & offset emissions
      </p>
      {option === 1 ? (
        <div className="inactive_offset_option_card">
          <div className="inactive_offset_option_body">
            <div className="d-flex align-items-center">
              <p className="offsetProjectTitle">Retrospective Offset</p>
              <div className="offsetBox retrospectiveBox ml-24">
                <p className="font_13_600">Option 1</p>
              </div>
            </div>
          </div>
          <div className="inactive_offset_option_footer justify-content-start align-items-start">
            <input
              type="radio"
              value={acceptTerm}
              checked={acceptTerm}
              onClick={() => setAcceptTerm(!acceptTerm)}
              className="mr-20"
              id="termpros"
            />
            <label htmlFor="termpros" className="font_13_500">
              I understand that I have to offset the emissions of the past 12
              months in one single offset now. For the next contribution period,
              I have the choice to do a one-time offset again, or offset at
              anytime during the year, but the latest at the end of my offset
              period. Because we are obliged to cooperate with german consumer
              protection regulations, you committ to our 1 month cancelation
              period to terminate the autorenewal of your carbon neutral period.
              However, you can always cancel your carbon neutrality with one
              click i the item settings.
            </label>
          </div>
        </div>
      ) : (
        option === 2 && (
          <div className="inactive_offset_option_card">
            <div className="inactive_offset_option_body">
              <div className="d-flex align-items-center">
                <p className="offsetProjectTitle">Prospective Offset</p>
                <div className="offsetBox propectiveBox ml-24">
                  <p className="font_13_600">Option 2</p>
                </div>
              </div>
              <p className="mt-10 body_text_s_light">
                Carbon offset, and the carbon neutrality associated with it,
                refers to the compensation of emissions caused by an accounting
                item. In the next step, we will explain how you can obtain a
                carbon neutral label for your accounting item and what data must
                be available.
              </p>
            </div>
            <div className="inactive_offset_option_footer justify-content-start align-items-start">
              <input
                type="radio"
                className="mr-20"
                value={acceptTerm}
                checked={acceptTerm}
                id="term"
                onClick={() => setAcceptTerm(!acceptTerm)}
              />
              <label htmlFor="term" className="font_13_500">
                I understand that by choosing Prospective Contribution (Option
                2), I am committing to contribution the upcoming 12 months of
                emission data, which needs to be logged without any missing
                data. I understand that I can contribution at anytime during the
                year, but the latest at the end of my contribution period (12
                months from today). Because we are obliged to cooperate with
                german consumer protection regulations, you committ to our 1
                month cancelation period to terminate the autorenewal of your
                carbon neutral period. However, you can always cancel your
                carbon neutrality with one click i the item settings.
              </label>
            </div>
          </div>
        )
      )}
      <div className="d-flex align-items-center justify-content-between mt-40">
        <div></div>
        <ButtonComp
          status={1}
          className="md"
          disabled={!acceptTerm}
          onClick={() => {
            handleSubmit();
          }}
        >
          Select & Continue to Offset
        </ButtonComp>
      </div>
    </div>
  );
};

export default Step4;
