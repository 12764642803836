import React, { useEffect, useState } from "react";
import "./timeFrameSelect.css";
import onClickOutside from "react-onclickoutside";

import ButtonComp from "../../../../../../../../Assets/components/Button";
import CustomDatePicker from "./Components/MonthDatePicker/customDatePicker";
import { monthNames } from "../../../../../../../../Utils/CommonFunctions";
function TimeFrameSelect({
  startDate,
  endDate,
  handleStartDate,
  handleEndDate,
  year,
  endYear,
  setYear,
  setEndYear,
  isEditable,
  timeFrameType,
  setTimeFrameType,
  getScopes,
}) {
  const [showOptionList, setShowOptionList] = useState(false);
  //const [timeFrameType, setTimeFrameType] = useState(0)
  TimeFrameSelect.handleClickOutside = () => {
    setShowOptionList(false);
  };

  const handleListDisplay = () => {
    setShowOptionList(!showOptionList);
  };

  useEffect(() => {
    if (!isEditable) setTimeFrameType(3, isEditable);
  }, [isEditable]);

  return (
    <div
      className={` ${
        !showOptionList ? "border-btm_ef border_radius_5" : ""
      } customSelect-container border-left_ef border-right_ef w-100`}
    >
      <div
        className={`h-100 d-flex justify-content-between align-items-center border_radius_5 pl-10`}
        onClick={handleListDisplay}
      >
        <p>
          {`${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}  - ${
            monthNames[endDate.getMonth()]
          } ${endDate.getFullYear()}`}
        </p>
        <p className="mr-20">
          {!showOptionList ? (
            <i className="bi bi-chevron-down"></i>
          ) : (
            <i className="bi bi-chevron-up"></i>
          )}
        </p>
      </div>
      {showOptionList && (
        <div className="bg_white border-ef">
          <div className="d-flex justify-content-around border-btm_ef pb-20 pt-16 mt-6">
            <p
              onClick={() => {
                setTimeFrameType(1, isEditable);
              }}
              className={`helper_text p-8 pr-16 pl-16 border-ef border_radius_25 pointer ${
                timeFrameType == 1 ? "color_main_blue bg_soft_blue" : ""
              }`}
            >
              Last month
            </p>
            <p
              onClick={() => {
                setTimeFrameType(2, isEditable);
              }}
              className={`helper_text p-8 pr-16 pl-16 border-ef border_radius_25 pointer ${
                timeFrameType == 2 ? "color_main_blue bg_soft_blue" : ""
              }`}
            >
              Last quarter
            </p>
            <p
              onClick={() => {
                setTimeFrameType(3, isEditable);
              }}
              className={`helper_text p-8 pr-16 pl-16 border-ef border_radius_25 pointer ${
                timeFrameType == 3 ? "color_main_blue bg_soft_blue" : ""
              }`}
            >
              Last year
            </p>
            <p
              onClick={() => {
                setTimeFrameType(4, isEditable);
              }}
              className={`helper_text p-8 pr-16 pl-16 border-ef border_radius_25 pointer ${
                timeFrameType == 4 ? "color_main_blue bg_soft_blue" : ""
              }`}
            >
              Custom
            </p>
          </div>
          <div className="d-flex justify-content-center pl-8 pr-8">
            <div className="w-100 ">
              {timeFrameType != 4 ? (
                <CustomDatePicker
                  isEditable={isEditable}
                  startDate={startDate}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  endDate={endDate}
                  timeFrameType={timeFrameType}
                  year={endYear}
                  setYear={setEndYear}
                />
              ) : (
                <div className="d-flex">
                  <div className="flex-grow-1">
                    <CustomDatePicker
                      isEditable={isEditable}
                      startDate={startDate}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      endDate={endDate}
                      timeFrameType={timeFrameType}
                      year={year}
                      setYear={setYear}
                    />
                  </div>
                  <div className="flex-grow-1 ml-10">
                    <CustomDatePicker
                      isEditable={isEditable}
                      startDate={startDate}
                      handleStartDate={handleStartDate}
                      handleEndDate={handleEndDate}
                      endDate={endDate}
                      timeFrameType={timeFrameType}
                      year={endYear}
                      setYear={setEndYear}
                    />
                  </div>
                </div>
              )}

              <div className="d-flex justify-content-end mt-18 mb-12 mr-12">
                <ButtonComp status={3}>
                  <p
                    onClick={() => {
                      getScopes();
                      setShowOptionList(!showOptionList);
                    }}
                  >
                    Confirm
                  </p>
                </ButtonComp>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
const clickOutsideConfig = {
  handleClickOutside: () => TimeFrameSelect.handleClickOutside,
};
export default onClickOutside(TimeFrameSelect, clickOutsideConfig);
