import React, { useState } from "react";
import "./index.css";
import axios from "../../Api";
// import OtpInput from "react-otp-input";
import GoogleAuthenticator from "./GoogleAuthenticator";
import PhoneOtpAuthentication from "./PhoneOtpAuthentication";
import { useAuth } from "../../Utils/Auth";
import { useNavigate } from "react-router-dom";
// import BackForwardButton from "../../Assets/components/BackForwardButton/BackForwardButton";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../Utils/toastResponseError";
// import bg from "../../Assets/images/Placeholder_GIF.gif";
import { useEffect } from "react";
import ButtonComp from "../../Assets/components/Button";
import useQuery from "../../Utils/query";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";
import PressEnter from "../../Assets/components/PressEnter/PressEnter";
import Step0 from "./Step0";
import Step1 from "./Step1";

const TwoFactor = () => {
  const { user, signOut, setSessionExpire, getUser, setUser } = useAuth();
  useEffect(() => {
    if (user) {
      if (user.is2FASet) {
        history(`/dashboard`);
      }
    }
  }, [user]);
  const [authType, setAuthType] = useState("");
  const [step, setStep] = useState(0);
  const [image, setImage] = useState(null);
  const [otp, setOtp] = useState(null);
  const navigate = useNavigate();
  const history = useNavigate();
  const query = useQuery();
  const redirectPath = query.get("redirect");

  const generateGoogleAuthenticatorToken = async () => {
    await axios({
      url: `/two-fa/register`,
      method: "POST",
      data: {
        twofactorType: "google",
      },
    })
      .then((res) => {
        setImage(res.data.image);
        setStep(2);
      })
      .catch((err) => console.error(err));
  };

  const verfifyGoogleAuthenticatorToken = async () => {
    await axios({
      url: `/two-fa/verify`,
      method: "POST",
      data: {
        code: otp,
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (user.isBasePaid) {
            setUser({ ...user, is2FASet: true });
            if (redirectPath) {
              navigate(redirectPath);
            } else {
              navigate("/dashboard");
            }
          } else {
            navigate("/register/pending");
          }
          toast.success("OTP Verified");
          getUser();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  React.useEffect(() => {
    if (step === 3) {
      signOut();
    }
  }, [step]);

  return (
    <div className="two_factor_root">
      <div className="row auth_background mx-0">
        <div
          className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
          style={{
            backgroundImage: `url(${AnimationScreens[7]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* {videoLoaded ? ( */}
          <video
            className="reg_vdo"
            src={AnimationVideos[7]}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
            controls={false}
            poster={AnimationScreens[7]}
          ></video>
          {/* ) : (
              <img src={AnimationScreens[7]} alt="video-thumn" className="reg_vdo_img" />
            )} */}
        </div>
        <div className="col-lg-5 col-md-6 col-12 auth_info_container">
          <div className=" two_factor_container max_width_552">
            {step === 0 ? (
              <Step0
                setAuthType={setAuthType}
                authType={authType}
                setStep={setStep}
              />
            ) : step === 1 ? (
              <Step1
                generateGoogleAuthenticatorToken={
                  generateGoogleAuthenticatorToken
                }
                authType={authType}
                setStep={setStep}
              />
            ) : step === 2 ? (
              authType === "google" ? (
                <GoogleAuthenticator
                  verfifyGoogleAuthenticatorToken={
                    verfifyGoogleAuthenticatorToken
                  }
                  otp={otp}
                  setOtp={setOtp}
                  image={image}
                  setStep={setStep}
                />
              ) : (
                <PhoneOtpAuthentication setStep={setStep} />
              )
            ) : (
              step === 3 && (
                <>
                  <div className="subtitle_box bg_soft_blue width_fit">
                    <p className="font_13_600 color_blue">Set-Up Complete!</p>
                  </div>
                  <p className="mt-32 offsetProjectTitle">
                    Welcome to today.green. We are looking forward to seeing you
                    manage to zero!
                  </p>
                  <p className="mt-24 font_18_600 fw-500">
                    Once your invoice was [aid, you are ready to log in and
                    start your carbon management journey.
                    <br /> <br />
                    Feel free to read more about our platform in the meantime!
                  </p>
                  <div className="mt-40 text-end">
                    <ButtonComp
                      status={1}
                      size="lg"
                      style={{ padding: "6px 35px", marginLeft: "16px" }}
                      // className="primary_button_lg"
                      onClick={() => {
                        navigate(`/`);
                      }}
                    >
                      Check out Features
                    </ButtonComp>
                    <PressEnter />
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactor;
