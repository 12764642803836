import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// import Tabs from "@mui/material/Tabs";
// import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DashboardIcon from "../../../Assets/icons/dashboardIcon";
import CheckBook from "../../../Assets/icons/checkbook";
import ChartIcon from "../../../Assets/icons/chartIcon";
import GraphFall from "../../../Assets/icons/graphFall";
// import PaperCheck from "../../../Assets/icons/papercheck";
import EduHat from "../../../Assets/icons/eduhat";
// import AddContact from "../../../Assets/icons/addContact";
// import AddIcon from "../../../Assets/icons/addIcon";
import ControlIcon from "../../../Assets/icons/controlIcon";
import LogoutIcon from "../../../Assets/icons/logoutIcon";
import Icon from "../../../Assets/components/logo_sm";
// import ProfileIcon from "./profileIcon";
// import "./sidebar.css";
import useQuery from "../../../Utils/query";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import { motion } from "framer-motion";
import { KeyboardArrowDown } from "@mui/icons-material";
import Organizations from "../../Pages/Organizations";
import Academy from "../Academy";
import CompanyProfile from "../../Pages/CompanySettings";
import AdminDashboard from "../AdminDashboard/AdminDashboard";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      className="w-100"
    >
      {value === index && <Box sx={{ width: "100%" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function AdminSidebar() {
  const [value, setValue] = useState(4);
  // const [subValue, setSubValue] = useState(0);
  const [activeStroke] = useState("white");
  const stroke = "black";
  const [active] = useState(true);
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const [subValueToggle, setSubValueToggle] = useState(true);
  const { signOutBasic } = useAuth();
  const query = useQuery();
  const tabNo = Number(query.get("tab"));
  const navigate = useNavigate();
  const sidebarRef = useRef();

  useEffect(() => {
    if (tabNo === 0) {
      setValue(2);
    } else if (tabNo) {
      setValue(tabNo);
    } else setValue(4);
  }, [tabNo]);

  const handleChange = (newValue) => {
    setValue(newValue);
    navigate(`/admin/dashboard?tab=${newValue}`);
  };
  const navopen = () => {
    setToggleNavbar(true);
  };
  const navclose = () => {
    setToggleNavbar(false);
  };
  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.addEventListener("mouseover", function () {
        sidebarRef.current.style.width = "180px";
        navopen();
      });
      sidebarRef.current.addEventListener("mouseleave", function () {
        navclose();
        if (sidebarRef.current) sidebarRef.current.style.width = "75px";
      });
    }
  }, [sidebarRef]);

  return (
    <div className="">
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: "flex",
          height: "100vh",
        }}
      >
        <div
          className="sidebar_container"
          ref={sidebarRef}
          id="sidebar_container"
        >
          <div className="side_tab_group_root">
            <div className="side_tab_logo">
              <Icon />
            </div>
            <div className="side_tab_group">
              <div className="">
                <div className="side_tab_subgroup">
                  <div
                    className="sidebar_tab"
                    onClick={() => {
                      handleChange(2);
                    }}
                  >
                    {value === 2 ? (
                      <DashboardIcon stroke={activeStroke} active={active} />
                    ) : (
                      <DashboardIcon stroke={stroke} active={!active} />
                    )}
                    <div>
                      {toggleNavbar && (
                        <span className="ml-12 mr-20 color_black ">
                          Dashboard
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="sidebar_tab mt-16"
                    onClick={() => {
                      handleChange(3);
                    }}
                  >
                    {value === 3 ? (
                      <CheckBook stroke={activeStroke} active={active} />
                    ) : (
                      <CheckBook stroke={stroke} active={!active} />
                    )}
                    <div>
                      {toggleNavbar && (
                        <p className="ml-12 mr-20 color_black">Log</p>
                      )}
                    </div>
                  </div>
                  <div
                    className="sidebar_tab mt-16"
                    onClick={() => {
                      handleChange(4);
                    }}
                  >
                    {value === 4 ? (
                      <ChartIcon stroke={activeStroke} active={active} />
                    ) : (
                      <ChartIcon stroke={stroke} active={!active} />
                    )}
                    <div>
                      {toggleNavbar && (
                        <p className="ml-12 mr-20 color_black ">
                          Organizations
                        </p>
                      )}
                    </div>
                  </div>
                  <div
                    className="sidebar_tab mt-16"
                    onClick={() => {
                      handleChange(5);
                    }}
                  >
                    {value === 5 ? (
                      <EduHat stroke={activeStroke} active={active} />
                    ) : (
                      <EduHat stroke={stroke} active={!active} />
                    )}
                    <div>
                      {toggleNavbar && (
                        <p className="ml-12 mr-20 color_black ">Academy</p>
                      )}
                    </div>
                  </div>
                  <div
                    className="sidebar_tab mt-16"
                    onClick={() => {
                      handleChange(6);
                    }}
                  >
                    {value === 6 ? (
                      <GraphFall stroke={activeStroke} active={active} />
                    ) : (
                      <GraphFall stroke={stroke} active={!active} />
                    )}
                    <div>
                      {toggleNavbar && (
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ width: "100%" }}
                        >
                          <p className="ml-12 mr-20 color_black">Act</p>
                          {value === 6 && (
                            <motion.span
                              initial={{ rotate: 0 }}
                              animate={{ rotate: subValueToggle ? 0 : 180 }}
                              transition={{ duration: 0.3, type: "tween" }}
                              onClick={() => setSubValueToggle(!subValueToggle)}
                            >
                              <KeyboardArrowDown />
                            </motion.span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="sidebar_tab mt-16"
                  onClick={() => {
                    handleChange(10);
                  }}
                >
                  <div className="sideprofile_icon">
                    <Icon />
                  </div>
                  <div>
                    {toggleNavbar && (
                      <p className="ml-12 mr-20 color_black ">Profile</p>
                    )}
                  </div>
                </div>
                <div
                  className="sidebar_tab mt-16"
                  onClick={() => {
                    handleChange(11);
                  }}
                >
                  {value === 11 ? (
                    <ControlIcon stroke={activeStroke} active={active} />
                  ) : (
                    <ControlIcon stroke={stroke} active={!active} />
                  )}
                  <div>
                    {toggleNavbar && (
                      <p className="ml-12 mr-20 color_black ">Settings</p>
                    )}
                  </div>
                </div>

                <div
                  className="sidebar_tab mt-16"
                  onClick={() => {
                    signOutBasic();
                    navigate("/admin/login");
                  }}
                >
                  {value === 12 ? (
                    <LogoutIcon stroke={activeStroke} active={active} />
                  ) : (
                    <LogoutIcon stroke={stroke} active={!active} />
                  )}
                  <div>
                    {toggleNavbar && (
                      <p className="ml-12 mr-20 color_black ">Logout</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <TabPanel value={value} index={2}>
          <AdminDashboard />
        </TabPanel>
        <TabPanel value={value} index={3}>
          {/* <Organizations /> */}
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Organizations />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <Academy />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <CompanyProfile />
        </TabPanel>
      </Box>
    </div>
  );
}
