import React, { useEffect, useState } from "react";
import {
  // ArrowRightAlt,
  Close,
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowUp,
  ShoppingCartOutlined,
} from "@mui/icons-material";
// import BusinessMan3d from "../../../../Assets/images/businessMan-3d.png";
import { IconButton, Backdrop, Box, Modal, Fade } from "@mui/material";
import axios from "../../../../Api";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useQuery from "../../../../Utils/query";
import { convertToTonnes } from "../../../../Utils/getAvatars";
import { motion } from "framer-motion";
import { useAuth } from "../../../../Utils/Auth";
import { botAvatarGroup } from "../../../../Utils/botAvatarsGroup";
import { getServiceIcon } from "../../../../Utils/itemIcon";
import ProductDetails from "./ProductDetails";
import EditIcon from "../../../../Assets/icons/EditIcon";
import Checkbox from "@mui/material/Checkbox";
import CropSquareRoundedIcon from "@mui/icons-material/CropSquareRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Cart from "./Cart";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import ButtonComp from "../../../../Assets/components/Button";
import unoFlag from "../../../../Assets/images/Uno Flag.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "white",
  border: "1px solid #EFEFEF",
  boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.0588235)",
  borderRadius: "8px",
};

const OffsetMarketPlace = ({ step, setStep }) => {
  const [showHeader, setShowHeader] = useState(true);
  const [products, setProducts] = useState([]);
  const navigate = useNavigate();
  const [selectedOption, setselectedOption] = useState(1);
  const query = useQuery();
  const logid = query.get("log");
  const logUnique = query.get("offset");
  const [projectCart, setprojectCart] = useState();
  // const [totalLoggedEmittion, setTotalLoggedEmittion] = useState(0);
  const [totalAddedEmittion, setTotalAddedEmittion] = useState(0);
  const [ItemDetail, setItemDetail] = useState(null);
  const [totalPrice, setTotalPrice] = useState(0);
  const [, setTotaloffsetPer] = useState(0);
  const [avgPrice, setAvgPrice] = useState(0);
  const [showProjects, setShowProjects] = useState(false);
  const [totalProjects, setTotalProjects] = useState([]);
  const [offsetLog, setOffsetLog] = useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [showMonths, setShowMonths] = useState(false);
  const { user, setSessionExpire } = useAuth();
  const [isFirstOffset, setisFirstOffset] = useState(false);
  const [allAccountingItems, setallAccountingItems] = useState(null);
  const [sortByName, setsortByName] = useState("");
  // const [totalEmissionLogged, settotalEmissionLogged] = useState(0);

  const getTotalEmission = (loggedMonths) => {
    let sum = 0;
    if (loggedMonths && loggedMonths.length > 0) {
      loggedMonths.map((log) => {
        sum += log.emission;
      });
    }
    return sum;
  };
  const getAllProducts = async () => {
    await axios({
      url: `/cloverly/all-project`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          const { products } = res.data;
          const newProducts = products.filter((prd) => {
            if (prd.id) {
              return prd;
            }
          });
          setProducts(newProducts);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  const getCartDetails = async (id) => {
    try {
      let res = await axios({
        url: `/project-cart/offset-cart/${id}`,
        method: "get",
      });
      if (res.data.success) {
        let projectCartData = res.data.data;
        setprojectCart(projectCartData);
        setselectedOption(
          projectCartData.logId && projectCartData.logId.optionNumber
        );
        let offsetLogitem =
          projectCartData.logId &&
          logUnique &&
          projectCartData.logId.offsetDetails.find(
            (obj) => obj.uniqueId === logUnique
          );
        setOffsetLog(offsetLogitem);
        setisFirstOffset(
          projectCartData.logId && projectCartData.logId.isFirstOffset
        );
        // settotalEmissionLogged(
        //   getTotalEmission(offsetLog && offsetLog.loggedMonthEmissions)
        // );
        const { projects } = projectCartData;
        setItemDetail(projectCartData.logId);
        setTotalProjects(projects);
        if (projects.length > 0) {
          let totalEmi = 0;
          let totalPri = 0;
          let totalPer = 0;

          for (let el of projects) {
            totalEmi += el.tonsOffset;
            totalPri += el.totalPrice;
          }
          totalPer =
            (Number(totalEmi) * 100) /
            Number(getTotalEmission(offsetLogitem.loggedMonthEmissions));
          setTotaloffsetPer(totalPer.toFixed(2));
          setTotalPrice(Number(totalPri).toFixed(2));
          setAvgPrice(Number(totalPri / totalEmi).toFixed(2));
          setTotalAddedEmittion(Number(totalEmi).toFixed(2));
        }
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const getallitems = async () => {
    try {
      let response = await axios({
        method: "get",
        url: "/log/all-user-items",
      });
      if (response.data.success) {
        setallAccountingItems(response.data.data);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    getAllProducts();
  }, []);

  useEffect(() => {
    if (logid && logid !== "null" && logid !== "") {
      getCartDetails(logid);
    } else getallitems();
  }, [logid]);

  const [selectedMonths, setselectedMonths] = useState([]);

  const [visibleEmission, setvisibleEmission] = useState(0);
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  useEffect(() => {
    if (offsetLog) {
      if (selectedOption === 1 || isFirstOffset) {
        let sortedArr = offsetLog.loggedMonthEmissions
          .filter(
            (obj) =>
              !obj.offset &&
              (obj.month <= currentMonth || obj.year < currentYear)
          )
          .sort((a, b) => a.month - b.month);
        setvisibleEmission(getTotalEmission(sortedArr));
        setselectedMonths(sortedArr);
      } else setvisibleEmission(1);
    }
  }, [offsetLog]);

  const handleMonthSelect = (event) => {
    const { value } = event.target;
    let valArr = value.split("-");
    let monthObj = {
      month: Number(valArr[0]),
      year: Number(valArr[1]),
      emission: Number(valArr[2]),
    };
    const updatedValues = [...selectedMonths];
    const index = updatedValues.findIndex(
      (obj) => obj.month === monthObj.month
    );
    if (index > -1) {
      setvisibleEmission(visibleEmission - monthObj.emission);
      updatedValues.splice(index, 1);
    } else {
      setvisibleEmission(visibleEmission + monthObj.emission);
      updatedValues.push(monthObj);
    }
    setselectedMonths(updatedValues);
  };

  // const sortByPrice = () => {
  //   const sortedProducts = [...products];
  //   sortedProducts.sort((a, b) => {
  //     if (sortByName === "asc") {
  //       return a.cost_per_kg_carbon_in_usd_cents - b.cost_per_kg_carbon_in_usd_cents;
  //     } else if (sortByName === "desc") {
  //       return b.cost_per_kg_carbon_in_usd_cents - a.cost_per_kg_carbon_in_usd_cents;
  //     }
  //     return 0;
  //   });
  //   console.log(sortedProducts)
  //   setProducts(sortedProducts);
  // };

  // useEffect(()=>{
  //   sortByPrice();
  // },[sortByName])
  const sortedProducts = [...products];
  if (sortByName === "asc") {
    sortedProducts.sort(
      (a, b) =>
        a.cost_per_kg_carbon_in_usd_cents - b.cost_per_kg_carbon_in_usd_cents
    );
  } else if (sortByName === "desc") {
    sortedProducts.sort(
      (a, b) =>
        b.cost_per_kg_carbon_in_usd_cents - a.cost_per_kg_carbon_in_usd_cents
    );
  }

  if (step === 1)
    return (
      <div
        className="offsetMarketPlace_root"
        onClick={() => setShowProjects(false)}
      >
        <div className="offsetMarketPlace_breadcrumbs d-flex align-items-center">
          <p
            className="breadcrumb_text_light_1 pointer"
            onClick={() => navigate("/dashboard?tab=5&subtab=1&step=0")}
          >
            <span className="">
              <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
            </span>
            Contribution Overview
          </p>{" "}
          <p>&nbsp;/&nbsp;</p>{" "}
          <p className="breadcrumb_text"> Contribution Marketplace</p>
        </div>
        {showHeader && (
          <div className="offsetMarketPlace_header mt-32 position-relative">
            <IconButton
              className="offsetMarketPlace_close_cta"
              onClick={() => setShowHeader(false)}
            >
              <Close fontSize="small" />
            </IconButton>
            <div className="pb-20">
              <p className="heading_lg">
                Carbon Neutrality vs. Carbon Contribution
              </p>
              <p className="subheading_light mt-16">
                It is up to you if you want to be carbon neutral or give back to
                our planet. If you want a location or a product to be carbon
                neutral, you select it and offset 100% of the items emissions.
                If you want to make a contribution, you can select a tonne
                amount of your choice and buy the desired amount. 🌱
              </p>
              <br />

              {/* <ButtonComp status={1}>
                Learn More{" "}
                <span>
                  <ArrowRightAlt />
                </span>
              </ButtonComp> */}
            </div>
            <img
              src={
                botAvatarGroup[user?.bot_avatar_id]?.purchasedSuccessful?.url
              }
              className="offsetMP_bImg ml-40"
              alt="avatar"
              style={{ maxHeight: 315, width: "auto", zIndex: 1 }}
            />
            {/* <hr className="bg_divider" /> */}
          </div>
        )}
        <div className="mt-40">
          <div className="row justify-content-between align-items-center ">
            <p className="card_heading bold fit-w">CONTRIBUTION MARKETPLACE</p>
          </div>
          <div className="offsetMarketPlace_details mt-22">
            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-6 col-12 offsetMarketPlace_detail_rb">
                <div className="offsetMarketPlace_detail_col p-20">
                  {ItemDetail ? (
                    <div className="subtitle_box border_1_ef width_fit d-flex align-items-center">
                      <p className="body_text_m">
                        <img
                          className="item_icon_img mb-1"
                          src={getServiceIcon(
                            ItemDetail ? ItemDetail.accountingItemName : ""
                          )}
                          alt={ItemDetail ? ItemDetail.accountingItemName : ""}
                        />
                        &nbsp;&nbsp;
                        <span className="mt-3">
                          {/* {ItemDetail?.itemDetails?.name} */}
                          {ItemDetail?.itemDetails?.name
                            .split("")
                            .map((letter, letterIndex) => {
                              return letterIndex < 25 ? letter : "";
                            })}
                          {ItemDetail?.itemDetails?.name.length >= 25
                            ? "..."
                            : ""}
                        </span>
                      </p>
                      <Close
                        className="ml-8 pointer"
                        fontSize="small"
                        onClick={() => {
                          setItemDetail(null);
                          navigate(`/dashboard?tab=5&subtab=1&step=1`);
                        }}
                      />
                    </div>
                  ) : (
                    <select
                      onChange={(e) => {
                        let selected = allAccountingItems.find(
                          (Obj) => Obj._id === e.target.value
                        );
                        navigate(
                          `/dashboard?tab=5&subtab=1&step=1&log=${selected._id}&offset=${selected.offsetDetails[0].uniqueId}`
                        );
                      }}
                      className="select_input"
                    >
                      <option value="">Select accounting item</option>
                      {allAccountingItems &&
                        allAccountingItems.length > 0 &&
                        allAccountingItems.map((item, i) => {
                          if (item.isOffsetActivate) {
                            return (
                              <option key={i} value={item._id}>
                                {item.itemDetails.name}
                              </option>
                            );
                          }
                        })}
                    </select>
                  )}
                  <p className="overline_text mt-10">
                    Selected Accounting Item
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-3 col-sm-6 col-12">
                <div className="offsetMarketPlace_detail_col p-20">
                  <p className="heading_2">
                    {totalAddedEmittion.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                    /
                    {selectedOption === 2 && !isFirstOffset
                      ? 1
                      : Number(visibleEmission).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                    {selectedOption === 1 || isFirstOffset ? (
                      <span
                        className="pointer edit_icon_border ml-8"
                        onClick={handleOpen}
                      >
                        <EditIcon />
                      </span>
                    ) : null}
                  </p>
                  <p className="overline_text mt-10">Selected Tons</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-6 col-12">
                <div className="offsetMarketPlace_detail_col p-20">
                  <p className="heading_2">{totalPrice.toLocaleString()}</p>
                  <p className="overline_text mt-10">Total Price €</p>
                </div>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-6 col-12">
                <div className="offsetMarketPlace_detail_col p-20">
                  <p className="heading_2">{avgPrice.toLocaleString()}</p>
                  <p className="overline_text mt-10">Average Price €/t</p>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-sm-6 col-12 d-flex align-items-center">
                <div className="project_cart_btn_root pointer mr-24">
                  <div
                    className="d-flex align-items-center project_cart_btn_main"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowProjects(!showProjects);
                    }}
                  >
                    <span
                      className="mr-8 project_cart_icon_"
                      onClick={() =>
                        navigate(
                          `/dashboard?tab=5&subtab=1&step=3&log=${logid}&offset=${logUnique}`
                        )
                      }
                    >
                      <ShoppingCartOutlined
                        fontSize="small"
                        htmlColor="#ffffff"
                      />
                    </span>
                    <div className="flex-grow-1 text-center">
                      <p className="font_15_500 color_black">
                        {totalProjects.length}{" "}
                        {totalProjects.length > 1 ? " Projects" : " Project"}
                      </p>
                    </div>
                    <div className="pr-16 pointer">
                      <KeyboardArrowDown />
                    </div>
                  </div>
                  {showProjects && (
                    <motion.div
                      className="position-relative"
                      initial={{ height: 0 }}
                      animate={{ height: "auto" }}
                      transition={{ duration: 0.3 }}
                    >
                      <motion.div className="project_cart_btn_projects_root">
                        {totalProjects &&
                          totalProjects.length > 0 &&
                          totalProjects.map((prj, i) => (
                            <motion.div
                              initial={{ opacity: 0 }}
                              animate={{ opacity: 1 }}
                              transition={{ duration: 1.2 }}
                              key={prj._id}
                              className={
                                "d-flex align-items-center project_cart_btn_projects_main p-16 " +
                                (i + 1 !== totalProjects.length &&
                                  "border_1_ef")
                              }
                            >
                              <img
                                src={prj.projectImage}
                                alt={prj.offsetProjectName}
                                className="cartbtn_project_img"
                              />
                              <div className="ml-12">
                                <p className="heading_4 fw-600 text_overflow-1">
                                  {prj.offsetProjectName}
                                </p>
                                <p className="subheading_light_2">
                                  {prj.totalPrice.toFixed(2).toLocaleString()}€
                                </p>
                                <p className="mt-8 font_15_600">
                                  {prj.tonsOffset.toFixed(2).toLocaleString()}t
                                </p>
                              </div>
                            </motion.div>
                          ))}
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          transition={{ duration: 1.2 }}
                          className="project_cart_btn_projects_card pointer"
                          onClick={() => {
                            setStep(3);
                            navigate(
                              `/dashboard?tab=5&subtab=1&step=3&log=${logid}&offset=${logUnique}`
                            );
                          }}
                        >
                          <span className="mr-16">
                            <ShoppingCartOutlined htmlColor="#ffffff" />
                          </span>{" "}
                          <p className="font_15_500 color_white">Go to Cart</p>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="offsetMarketPlace_products_root mt-32">
            <div className="d-flex justify-content-end">
              <div>
                <select
                  value={sortByName}
                  onChange={(e) => setsortByName(e.target.value)}
                  className="select_input width_fit"
                >
                  <option value="">Sort by</option>
                  <option value="desc">Price: High to Low</option>
                  <option value="asc">Price: Low to High</option>
                </select>
              </div>
            </div>
            <div className="row  gx-lg-5 gx-ms-5 gx-5 mt-16">
              {sortedProducts &&
                sortedProducts.length > 0 &&
                sortedProducts.map((product) => (
                  <div
                    className={`col-lg-4 col-ms-6 col-sm-6 col-12 mb-34 `}
                    key={product.id}
                  >
                    <div className="offsetMarketPlace_products_card">
                      <div className="offsetMarketPlace_products_img">
                        {product.image_list[0] && (
                          <img
                            src={product.image_list[0].url}
                            alt={product.name}
                            //  className="w-100 h-100 image-fit"
                          />
                        )}
                        {product.unsdg && product.unsdg.length > 0 && (
                          <div className="offsetMarketPlace_sdg_logo body_text_m">
                            <img src={unoFlag} alt="unoSDG" />{" "}
                            {product.unsdg.length}
                          </div>
                        )}
                      </div>
                      <div className="offsetMarketPlace_products_cmain">
                        <p className="heading_6">
                          <span className="color_ba text_capital">
                            {product.offset_classification}
                          </span>
                          <span className="ml-10 color_blue">
                            {product.country} •{" "}
                            {product.active_tranche.vintage_year}
                          </span>
                        </p>
                        <p className="mt-20 card_heading">{product.name}</p>
                        <p className="mt-10 body_text_s offset_project_descti">
                          {product.short_description}
                        </p>
                        <div className="mt-32 d-flex align-items-center justify-content-between">
                          <p className="font_16_600">
                            {convertToTonnes(
                              product.cost_per_kg_carbon_in_usd_cents
                            ).toLocaleString()}
                            € /t
                          </p>
                          <ButtonComp
                            status={3}
                            // className="tertiary_button_variant_1"
                            onClick={() => {
                              setStep(2);
                              navigate(
                                `/dashboard?tab=5&subtab=1&step=2&log=${logid}&offset=${logUnique}&productid=${product.id}&selectedOption=${selectedOption}`
                              );
                            }}
                          >
                            <p className="color_black">Details</p>
                          </ButtonComp>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <div className="p-50">
                <div className="d-flex align-items-center">
                  <p className="offsetProjectTitle">
                    Edit the amount of tons you want to offset today
                  </p>
                  <div className="subtitle_box border_1_ef ml-16">
                    <p className="body_text_m">
                      <img
                        className="item_icon_img"
                        src={getServiceIcon(
                          ItemDetail ? ItemDetail.accountingItemName : ""
                        )}
                        alt={ItemDetail ? ItemDetail.accountingItemName : ""}
                      />
                      &nbsp;&nbsp;
                      <span className="mt-3">
                        {ItemDetail?.itemDetails?.name}
                      </span>
                    </p>
                  </div>
                </div>

                <div className="mt-24">
                  <p className="heading_6 text_capital">
                    Select one or more completed months
                  </p>
                  <div className="mt-8 mp_choose_month_input_root">
                    <div className="mp_choose_month_input ">
                      {selectedMonths.length > 0 ? (
                        <div className="row flex-wrap px-2">
                          {selectedMonths.map((m) => (
                            <span key={m} className="offset_selected_months">
                              {m.month}/{m.year.toString().slice(-2)}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <p className="subheading_light_2 mt-2 mb-2">
                          Select Month(s)
                        </p>
                      )}

                      <span
                        className="pointer"
                        onClick={() => setShowMonths(!showMonths)}
                      >
                        {showMonths ? (
                          <KeyboardArrowUp htmlColor="rgba(0, 0, 0, 0.54)" />
                        ) : (
                          <KeyboardArrowDown htmlColor="rgba(0, 0, 0, 0.54)" />
                        )}
                      </span>
                    </div>
                    {showMonths ? (
                      <div className="mp_choose_month_choose_root">
                        <FormGroup>
                          <div className="row">
                            {offsetLog &&
                              offsetLog.loggedMonthEmissions
                                .sort((a, b) => a.month - b.month)
                                .map((date, i) => (
                                  <div className="col-4 px-0" key={i}>
                                    <div className="d-flex align-items-center">
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            icon={<CropSquareRoundedIcon />}
                                            disabled={
                                              date.offset ||
                                              (date.year >= currentYear &&
                                                date.month > currentMonth)
                                            }
                                            checked={
                                              selectedMonths.some(
                                                (dateOb) =>
                                                  dateOb.month === date.month
                                              ) || date.offset
                                            }
                                            onChange={handleMonthSelect}
                                          />
                                        }
                                        value={
                                          date.month +
                                          "-" +
                                          date.year +
                                          "-" +
                                          date.emission
                                        }
                                      />
                                      <label>
                                        {(date.month > 9 ? "" : "0") +
                                          date.month}
                                        /{date.year.toString().slice(-2)}{" "}
                                        {date.offset ? (
                                          <img
                                            className="item_icon_img"
                                            src="https://tg-icons.s3.eu-central-1.amazonaws.com/Profile+Picture+Placeholder_New.png"
                                            alt="offset"
                                          />
                                        ) : null}
                                      </label>
                                    </div>
                                  </div>
                                ))}
                          </div>
                        </FormGroup>
                      </div>
                    ) : null}
                  </div>
                  <p className="mt-12 overline_text">
                    Note that you can only select past months
                  </p>
                </div>
                <hr className="mt-32 mb-24" />
                <ButtonComp
                  status={1}
                  className="lg mt-8"
                  onClick={() => handleClose()}
                >
                  Confirm
                </ButtonComp>
              </div>
            </Box>
          </Fade>
        </Modal>
      </div>
    );
  else if (step === 2)
    return (
      <ProductDetails
        setStep={setStep}
        totalEmission={getTotalEmission(
          offsetLog && offsetLog.loggedMonthEmissions
        )}
        logItem={ItemDetail}
        totalProjects={totalProjects}
        setTotalProjects={setTotalProjects}
        visibleEmission={visibleEmission}
        getCartDetails={getCartDetails}
        selectedOption={selectedOption}
        unique_id={logUnique}
        isFirstOffset={isFirstOffset}
      />
    );
  else if (step === 3)
    return (
      <Cart
        step={step}
        setStep={setStep}
        totalEmission={getTotalEmission(
          offsetLog && offsetLog.loggedMonthEmissions
        )}
        logItem={ItemDetail}
        totalProjects={totalProjects}
        setTotalProjects={setTotalProjects}
        visibleEmission={visibleEmission}
        getCartDetails={getCartDetails}
        selectedOption={selectedOption}
        projectCart={projectCart}
        totalAddedEmittion={totalAddedEmittion}
        unique_id={logUnique}
        selectedMonths={selectedMonths}
        isFirstOffset={isFirstOffset}
      />
    );
};

export default OffsetMarketPlace;
