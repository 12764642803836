import React, { useState,useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

function AddressInputType(props) {
  const [origin, setOrigin] = useState(null);
 
  useEffect(() => {
    if(!origin) return
    let newAddress=origin && origin.value ? origin.value.description : ""
    props.handleAnswerChange(props.answerIndex,newAddress)
  }, [origin]);
  return (
    <>
      <GooglePlacesAutocomplete
        debounce={800}
        apiKey={process.env.REACT_APP_GOOGLE_MAP}
        selectProps={{
          origin,
          onChange: setOrigin,
          placeholder: !origin ? props.answers[props.answerIndex] : "Address",

          styles: {
            container: (p) => ({
              ...p,
              boxSizing: `border-box`,
             // border: `1px solid #efefef`,
              borderRadius: `5px`,
              fontSize: `15px`,
              fontWeight: "400",
              boxShadow: "none",
              fontFamily: '"Inter", sans-serif',
              outline: `none`,
              maxHeight: 40,
              backgroundColor:"#F6F6F6"
            }),
            valueContainer: (p) => ({
              ...p,
              border:`none`,
              borderRadius:"5px",
              color:"transparent",
              padding: "0px 10px",
              height: 42,
              backgroundColor:"#F6F6F6"
            }),
            control: (p) => ({
              ...p,
              border: `0`,
              "&:hover": {
                boxShadow: "none",
              },
            }),
            indicatorsContainer: (p) => ({
              ...p,
              display: "none",
            }),
            input: (p) => ({
              ...p,
              padding: "8px",
            }),
          },
        }}
      />
    </>
  );
}

export default AddressInputType;
