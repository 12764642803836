import React from "react";
import CardProgressBar from "../../../CardProgressBar";
import { Avatar } from "@mui/joy";
import { useMapStore } from "../../../../../../../Utils/CompanyMapStore";
function OverviewTab() {
  const { activeNodeData, totalS1Emission, totalS2Emission, totalS3Emission } =
    useMapStore();

  const getOffsetEmission = (planMonths) => {
    let offsetEmission = 0;
    if (planMonths) {
      let indexes = Object.keys(planMonths);
      indexes.forEach((element) => {
        let year = Object.keys(planMonths[element]);
        planMonths[element][year[0]].forEach((data) => {
          if (data.offset) {
            offsetEmission += data.emission;
          }
        });
      });
    }
    return offsetEmission;
  };

  return (
    <div>
      <div className="mt-31">
        <div className="d-flex align-items-center">
          <p className="font_24_600 line_height_30">
            {activeNodeData?.itemDetails?.name}
          </p>
          {activeNodeData?.isActivate ? (
            <div className="circle_14 bg_009 ml-60" />
          ) : (
            <div className="circle_14 bg_red ml-60" />
          )}
        </div>
        <p className="font_14_600 color_7e mt-20 ">
          {activeNodeData?.accountingItemName}
        </p>
      </div>

      <div className="mt-35">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bg_f6 border_radius_25"
            style={{ padding: "5px 7px" }}
          >
            <p className="font_14_500">% Total Emission</p>
          </div>

          <p className="font_14_600 color_7e">
            {Number(
              activeNodeData?.scopeWiseData?.Scope1 +
                activeNodeData?.scopeWiseData?.Scope2 +
                activeNodeData?.scopeWiseData?.Scope3
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            tCO2e
          </p>
        </div>

        <div className="mt-12">
          <CardProgressBar
            value={
              ((activeNodeData?.scopeWiseData?.Scope1 +
                activeNodeData?.scopeWiseData?.Scope2 +
                activeNodeData?.scopeWiseData?.Scope3) /
                (totalS1Emission + totalS2Emission + totalS3Emission)) *
              100
            }
            color={1}
            height={20}
          />
        </div>
      </div>

      <div className="mt-22 display_none">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bg_f6 border_radius_25"
            style={{ padding: "5px 7px" }}
          >
            <p className="font_14_500">CO2 Budget</p>
          </div>

          <p className="font_14_600 color_7e">100,00/200000 tCO2e</p>
        </div>

        <div className="mt-12">
          <CardProgressBar value={70} color={4} height={20} />
        </div>
      </div>

      <hr className="mt-30 mb-30" />

      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bg_f6 border_radius_25"
            style={{ padding: "5px 7px" }}
          >
            <p className="font_14_500">Scope 1</p>
          </div>

          <p className="font_14_600 color_7e">
            {Number(activeNodeData?.scopeWiseData?.Scope1).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
            tCO2e
          </p>
        </div>

        <div className="mt-12">
          <CardProgressBar
            value={
              (activeNodeData?.scopeWiseData?.Scope1 /
                (totalS1Emission + totalS2Emission + totalS3Emission)) *
              100
            }
            color={2}
            height={20}
          />
        </div>
      </div>

      <div className="mt-22">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bg_f6 border_radius_25"
            style={{ padding: "5px 7px" }}
          >
            <p className="font_14_500">Scope 2</p>
          </div>

          <p className="font_14_600 color_7e">
            {Number(activeNodeData?.scopeWiseData?.Scope2).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
            tCO2e
          </p>
        </div>

        <div className="mt-12">
          <CardProgressBar
            value={
              (activeNodeData?.scopeWiseData?.Scope2 /
                (totalS1Emission + totalS2Emission + totalS3Emission)) *
              100
            }
            color={2}
            height={20}
          />
        </div>
      </div>

      <div className="mt-22">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bg_f6 border_radius_25"
            style={{ padding: "5px 7px" }}
          >
            <p className="font_14_500">Scope 3</p>
          </div>

          <p className="font_14_600 color_7e">
            {Number(activeNodeData?.scopeWiseData?.Scope3).toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            )}
            tCO2e
          </p>
        </div>

        <div className="mt-12">
          <CardProgressBar
            value={
              (activeNodeData?.scopeWiseData?.Scope3 /
                (totalS1Emission + totalS2Emission + totalS3Emission)) *
              100
            }
            color={3}
            height={20}
          />
        </div>
      </div>

      <hr className="mt-30 mb-30" />

      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <div
            className="bg_f6 border_radius_25"
            style={{ padding: "5px 7px" }}
          >
            <p className="font_14_500">Emissions Offset</p>
          </div>

          <p className="font_14_600 color_7e">
            {getOffsetEmission(activeNodeData.planMonths)}/
            {Number(
              activeNodeData?.scopeWiseData?.Scope1 +
                activeNodeData?.scopeWiseData?.Scope2 +
                activeNodeData?.scopeWiseData?.Scope3
            ).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}{" "}
            tCO2e
          </p>
        </div>

        <div className="mt-12">
          <CardProgressBar
            value={
              (getOffsetEmission(activeNodeData.planMonths) /
                (activeNodeData?.scopeWiseData?.Scope1 +
                  activeNodeData?.scopeWiseData?.Scope2 +
                  activeNodeData?.scopeWiseData?.Scope3)) *
              100
            }
            color={3}
            height={20}
          />
        </div>
      </div>

      <div className="mt-22 mb-40">
        <div
          className="bg_f6 border_radius_25 width_fit"
          style={{ padding: "5px 7px" }}
        >
          <p className="font_14_500">Top Climate Projects</p>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-15">
          <div className="d-flex justify-content-start align-items-center">
            <Avatar sx={{ width: 19, height: 19, fontSize: 12 }}>X</Avatar>
            <p className="font_14_500 color_7e ml-10">Climate Project Name 1</p>
          </div>

          <p className="font_14_500 color_7e">30%</p>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-10">
          <div className="d-flex justify-content-start align-items-center">
            <Avatar sx={{ width: 19, height: 19, fontSize: 12 }}>X</Avatar>
            <p className="font_14_500 color_7e ml-10">Climate Project Name 2</p>
          </div>

          <p className="font_14_500 color_7e">10%</p>
        </div>

        <div className="d-flex justify-content-between align-items-center mt-10">
          <div className="d-flex justify-content-start align-items-center">
            <Avatar sx={{ width: 19, height: 19, fontSize: 12 }}>X</Avatar>
            <p className="font_14_500 color_7e ml-10">Climate Project Name 3</p>
          </div>

          <p className="font_14_500 color_7e">3%</p>
        </div>
      </div>
    </div>
  );
}

export default OverviewTab;
