import {
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Fade,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";
import React, { useState } from "react";
import { HeadCell, StyledHeadRow } from "../../../Utils/TableComp";
import { roles } from "../../../Utils/UserRoleConstAndFunc";
import { useRef } from "react";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { useAuth } from "../../../Utils/Auth";
import axios from "../../../Api";
import { toast } from "react-hot-toast";
import EditIcon from "../../../Assets/icons/EditIcon";
import ImagePlusIconUplaod from "../../../Assets/icons/ImagePlusIconUplaod";
import TreeBg from "../../../Assets/images/Eureka Seken.png";
import { useEffect } from "react";
import UserRow from "./UserRow";
import { modalStyle } from "../../../Utils/CommonFunctions";
import { useMemo } from "react";
import ButtonComp from "../../../Assets/components/Button";
import { useTranslation } from "react-i18next";

const email_regex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

const Users = () => {
  const {t} = useTranslation()
  const { user, setSessionExpire } = useAuth();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [logo, setLogo] = useState("");
  const [role, setRole] = useState(roles.viewer);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const inputRef = useRef();
  const [disabled, setDisable] = useState(false);
  const [allUsers, setAllUsers] = useState([]);

  // pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // invite modal variables
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleInvite = async () => {
    if (!email_regex.test(email)) {
      return toast.error("Only work email are allowed");
    }
    setDisable(true);
    await axios({
      url: `/company/invite-user`,
      method: "post",
      data: {
        email,
        firstName,
        lastName,
        role,
        logo,
      },
    })
      .then((res) => {
        setDisable(false);
        if (res.data.success) {
          getAllUsers();
          setEmail("");
          setFirstName("");
          setLastName("");
          setLogo("");
          setRole(roles.viewer);
          toast.success(res.data.message);
          handleClose();
        }
      })
      .catch((err) => {
        setDisable(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const handlePicUpload = async (e) => {
    const image = e.target.files[0];
    if (image.size > 4 * 1024 * 1024) {
      toast.error("File size exceeded 4MB!", { position: "bottom-right" });
      return;
    }
    const formdata = new FormData();
    formdata.append("logo", image);
    setIsLogoUploading(true);
    await axios({
      method: "post",
      url: `/user/auth/logo`,
      data: formdata,
    })
      .then((res) => {
        const { data } = res;
        setLogo(data.location);
        setIsLogoUploading(false);
        toast.success("Profile Image upload");
      })
      .catch((err) => {
        setIsLogoUploading(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const getAllUsers = async () => {
    await axios({
      url: `/company/all-users`,
      method: "get",
    })
      .then((res) => {
        setAllUsers(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const deleteInvitedUser = async (id, handleClose) => {
    await axios({
      url: `/company/delete/invite/${id}`,
      method: "post",
    })
      .then((res) => {
        if (res.data.success) {
          getAllUsers();
          toast.success(res.data.message);
          handleClose();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const deleteUserByAdmin = async (id, handleClose) => {
    await axios({
      url: `/company/delete-user/${id}`,
      method: "delete",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          getAllUsers();
          handleClose();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (user) {
      getAllUsers();
    }
  }, [user]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () =>
      allUsers
        .slice(1)
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, allUsers]
  );

  return (
    <div className="user_box">
      <div className="border-btm_ef pb-24">
        <p className="offsetProjectTitle">{t("settings.users")}</p>
      </div>
      <div className="mt-32 d-flex align-items-center justify-content-end">
        {/* <button className="mr-16 tertiary_button_variant_1">Bulk Import</button> */}
        <ButtonComp status={2} className="" onClick={handleOpen}>
          + {t("settings.newUser")}
        </ButtonComp>
      </div>
      <div>
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640, borderRadius: "9px", boxShadow: "none" }}
          className="mr-50 mt-20"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderLeft: "0" }}
                >
                  {t("common.name")}
                </HeadCell>
                <HeadCell component="th" className="text-uppercase">
                {t("common.email")}
                </HeadCell>
                <HeadCell component="th" className="text-uppercase">
                {t("common.role")}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderRight: "none" }}
                >
                  {t("settings.joined")}
                </HeadCell>
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {visibleRows &&
                visibleRows.length > 0 &&
                visibleRows.map((iuser) => {
                  if (user.id !== iuser._id) {
                    if (
                      iuser.inviteStatus &&
                      iuser.inviteStatus === "Pending"
                    ) {
                      return (
                        <UserRow
                          key={iuser._id}
                          name={`${iuser.firstName} ${iuser.lastName}`}
                          email={iuser.email}
                          role={iuser.role}
                          date={null}
                          isJoined={false}
                          getAllUsers={getAllUsers}
                          userid={iuser._id}
                          handleDelete={() => deleteInvitedUser(iuser._id)}
                        />
                      );
                    } else {
                      return (
                        <UserRow
                          key={iuser._id}
                          name={`${iuser.firstname} ${iuser.lastname}`}
                          email={iuser.email}
                          role={iuser.type}
                          date={new Date(iuser.createdAt).toLocaleDateString(
                            "en-GB"
                          )}
                          isJoined={true}
                          handleDelete={() => deleteUserByAdmin(iuser._id)}
                          getAllUsers={getAllUsers}
                          userid={iuser._id}
                        />
                      );
                    }
                  }
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {allUsers && allUsers.length - 1 > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={allUsers.length - 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="table-pagiation"
          />
        )}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={modalStyle}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">Add a new user</p>
              </div>
              <div className="row pb-24 border-btm_dc mb-32">
                <div className="col-lg-5 col-md-6 col-12">
                  <div className="d-flex">
                    <div>
                      {isLogoUploading ? (
                        <div className="border_1_ef br_50 d-flex align-items-center justify-content-center personlize_avt_main mr-24">
                          <CircularProgress />
                        </div>
                      ) : logo ? (
                        <div className="position-relative mr-24">
                          <Avatar
                            src={logo}
                            sx={{
                              height: 72,
                              width: 72,
                              border: "1px solid #efefef",
                            }}
                          />
                          <button
                            className="profileEditbtn profileImg_upload_btn"
                            onClick={() => inputRef.current.click()}
                          >
                            <EditIcon />
                          </button>
                        </div>
                      ) : (
                        <div
                          className="mr-24 border_1_ef br_50 d-flex align-items-center justify-content-center pointer personlize_avt_main"
                          style={{
                            backgroundImage: `url(${TreeBg})`,
                            backgroundPosition: "center",
                            backgroundSize: "auto",
                            backgroundRepeat: "no-repeat",
                          }}
                          onClick={() => inputRef.current.click()}
                        >
                          <ImagePlusIconUplaod />
                        </div>
                      )}
                      <input
                        type="file"
                        hidden
                        ref={inputRef}
                        onChange={handlePicUpload}
                      />
                    </div>
                    <div className="input_div flex-grow-1">
                      <label className="subheading_1" htmlFor="newPassword">
                        FIRST NAME
                      </label>
                      <input
                        type="text"
                        name="fname"
                        className="mt-12 subheading_2"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="input_div">
                    <label className="subheading_1" htmlFor="newPassword">
                      LAST NAME
                    </label>
                    <input
                      type="text"
                      name="lname"
                      className="mt-12 subheading_2"
                      placeholder="Last Name"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="input_div">
                    <label className="subheading_1" htmlFor="newPassword">
                      E-MAIL ADDRESS
                    </label>
                    <input
                      type="email"
                      name="email"
                      className="mt-12 subheading_2"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="col-lg-7 col-md-7 col-6">
                  <div className="input_div input_seperation">
                    <p className="font_14_500 align-self-center">User Role</p>
                    <select
                      className="subheading_2"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option className="subheading_2" value={roles.viewer}>
                        Team Member (Viewer)
                      </option>
                      <option className="subheading_2" value={roles.editor}>
                        Editor
                      </option>
                      <option className="subheading_2" value={roles.admin}>
                        Admin
                      </option>
                      <option className="subheading_2" value={roles.consultant}>
                        Consultant
                      </option>
                    </select>
                  </div>
                </div>
                <div className="col text-end">
                  <ButtonComp
                    status={1}
                    className="lg"
                    disabled={disabled}
                    onClick={handleInvite}
                  >
                    Send Invitation
                  </ButtonComp>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Users;
