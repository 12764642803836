import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useAuth } from "../../Utils/Auth";
import axios from "../../Api";
import { toastResponseError } from "../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
// import bg from "../../Assets/images/Placeholder_GIF.gif";
import ButtonComp from "../../Assets/components/Button";
import useQuery from "../../Utils/query";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";

const Mfa_Authentication = () => {
  const [otp, setOtp] = useState();
  const { setSessionExpire, setLoadCurrent, loadCurrent } = useAuth();
  const [timer, setTimer] = useState(59);
  const [timerRunning, setTimerRunning] = useState(false);
  const [disabled, setIsDisabled] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { twofactorType, lastdigit, email } = location.state;
  const query = useQuery();
  const redirectPath = query.get("redirect");

  const sendOtp = async () => {
    setOtp();
    setIsDisabled(true);
    await axios({
      url: `/user/auth/resend-otp`,
      method: "POST",
      data: {
        email,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Otp sent");
          setTimer(59);
          setTimerRunning(true);
        } else {
          toast.error(res.data.error);
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const verifyOtp = async () => {
    if (!otp) {
      return toast.error("Invalid OTP");
    }
    setIsDisabled(true);
    await axios({
      url: `/user/auth/login-otp-verify`,
      method: "POST",
      data: {
        code: otp,
        email,
      },
    })
      .then(async (response) => {
        if (response.data.status === "approved") {
          setLoadCurrent(!loadCurrent);
          if (redirectPath) {
            navigate(`/user-check?redirect=${redirectPath}`);
          } else {
            navigate("/user-check");
          }
          toast.success("Logged in");
        } else if (response.data.status === "pending") {
          toast.error("Invalid OTP");
          setOtp(null);
        } else {
          toast.error("Something went wrong");
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const verifyGoogleOtp = async () => {
    if (!otp) {
      return toast.error("Invalid OTP");
    }
    setIsDisabled(true);
    await axios({
      url: `/user/auth/login-googleOtp-verify`,
      method: "POST",
      data: {
        code: otp,
        email,
      },
    })
      .then(async (response) => {
        if (response.data.status === "approved" && response.data.success) {
          setLoadCurrent(!loadCurrent);
          if (redirectPath) {
            navigate(`/user-check?redirect=${redirectPath}`);
          } else {
            navigate("/user-check");
          }
          toast.success("Logged in");
        } else {
          toast.error(response.data.status);
          setOtp(null);
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    let interval = null;

    if (timerRunning) {
      interval = setInterval(() => {
        setTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    if (timer === 0) {
      setTimerRunning(false);
    }
  }, [timer]);

  useEffect(() => {
    setTimerRunning(true);
  }, []);

  return (
    <div className="two_factor_root">
      <div className="row auth_background mx-0">
        <div
          className="d-flex flex-column col-lg-7 col-md-6 col-12 justify-content-center align-items-center bg_left_onboard_img "
          style={{
            backgroundImage: `url(${AnimationScreens[7]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {/* {videoLoaded ? ( */}
          <video
            className="reg_vdo"
            src={AnimationVideos[7]}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
            controls={false}
            poster={AnimationScreens[7]}
          ></video>
          {/* ) : (
              <img src={AnimationScreens[7]} alt="video-thumn" className="reg_vdo_img" />
            )} */}
        </div>
        <div className="col-lg-5 col-md-6 col-12 d-flex col-md-5 auth_info_container two_factor_container">
          <div className="col-md-10 ml-32  max_width_552">
            <div className="subtitle_box bg_soft_blue width_fit">
              <p className="font_13_600 color_blue">Verify Otp</p>
            </div>
            {twofactorType === "google" ? (
              <>
                <p className="mt-32 offsetProjectTitle">
                  Enter the code generated by your authenticator app.
                </p>
                <div className="otp_input mt-24">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span className="input_otp_span"></span>}
                    className="otp_input"
                    renderInput={(props) => <input {...props} />}
                  />
                </div>
                <div className="mt-6rem d-flex align-items-center justify-content-end">
                  <div>
                    <ButtonComp
                      status={1}
                      className="ml-16"
                      onClick={() => verifyGoogleOtp()}
                    >
                      Verify
                    </ButtonComp>
                  </div>
                </div>
              </>
            ) : (
              <>
                <p className="mt-32 offsetProjectTitle">
                  Your Authentication Code was Sent to {lastdigit}
                </p>
                <div className="otp_input mt-24">
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={<span className="input_otp_span"></span>}
                    className="otp_input"
                    renderInput={(props) => <input {...props} />}
                  />
                  <div className="mt-12">
                    {timerRunning && timer > 0 ? (
                      <p className="overline_text">
                        Resend otp in :{" "}
                        <span>{String(timer).padStart(2, "0")}</span> sec
                      </p>
                    ) : (
                      <p
                        className="overline_text pointer underline"
                        onClick={() => sendOtp()}
                      >
                        Resend OTP
                      </p>
                    )}
                  </div>
                </div>
                <div className="mt-6rem d-flex align-items-center justify-content-end">
                  <ButtonComp
                    status={1}
                    className="ml-16"
                    onClick={() => verifyOtp()}
                    disabled={disabled}
                  >
                    Verify your Number
                  </ButtonComp>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mfa_Authentication;
