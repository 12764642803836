import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ green }) => ({
  height: 15,
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: green ? "#CDF1E3" : "#FAE6E1",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: green ? "#00915B" : "#E35700",
  },
}));

function SimpleProgressBar(props) {
  return (
    <BorderLinearProgress
      variant="determinate"
      green={props.val > 100 ? false : true}
      value={props.val >= 100 ? 100 : props.val}
    />
  );
}

export default SimpleProgressBar;
