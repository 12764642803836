import React from "react";

const CardViewIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="17"
      fill="none"
      viewBox="0 0 20 17"
    >
      <path
        stroke={cc || "#00915B"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 4.332H5v7.5h3v-7.5zM15 4.332h-3v4.167h3V4.332z"
      ></path>
      <path
        stroke={cc || "#00915B"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 1H3c-1.105 0-2 .746-2 1.667v11.666C1 15.253 1.895 16 3 16h14c1.105 0 2-.746 2-1.667V2.667C19 1.747 18.105 1 17 1z"
      ></path>
    </svg>
  );
};

export default CardViewIcon;
