import React from "react";
import {
  TextField,
  //  ,StepButton
} from "@mui/material";
import UserTable from "./Components/userTable";

function ReviewAndShare(props) {
  return (
    <div className="mr-110">
      <div className="mt-60">
        <div>
          <p className="font_13_700 color_49">1. Survey Name</p>

          <div className="d-flex align-items-center createSurvey_question_container ml-10">
            <TextField
              value={props.surveyName}
              onChange={(e) => {
                props.handleSurveyNameChange(e.target.value);
              }}
              className="mt-12"
              placeholder="Survey Name"
              size="small"
              fullWidth
            />
          </div>
        </div>

        <div className="mt-50">
          <p className="font_13_700 color_49">2. Description</p>

          <div className="d-flex align-items-center createSurvey_question_container ml-10">
            <TextField
              value={props.surveyDescription}
              onChange={(e) => {
                props.handleSurveyDespChange(e.target.value);
              }}
              className="mt-12"
              placeholder="Description Example for a survey that is about to be shared with the team and externals. "
              size="small"
              fullWidth
            />
          </div>
        </div>

        <div className="mt-50">
          <p className="font_13_700 color_49">3. Survey Questions</p>

          <div className="">
            {/* <div className="d-flex">
                                <div className="d-flex justify-content-center align-items-center bg_f6 h-40px w-40px">
                                    <p className="font_13_500 color_72">1</p>
                                </div>

                                <div className="flex-grow-1 createSurvey_question_container">
                                <TextField

                                // value={surveyName}
                                // onChange={(e) => {
                                //   setSurveyName(e.target.value)
                                // }}
                                className="font_14_400 ml-10"
                                placeholder="Question number one that is really interessting to everybody"
                                size="small" fullWidth />
                                </div>

                                <div className="d-flex align-items-center h-40px bg_f6 ml-10">
                                    <p className="font_12_500 color_f4 mr-8">Delete</p>
                                </div>
                            </div> */}
            {props.questions.map((currq, index) => {
              return (
                <div key={index} className="d-flex mt-10">
                  <div className="d-flex justify-content-center align-items-center bg_f6 h-40px w-40px">
                    <p className="font_13_500 color_72">{index + 1}</p>
                  </div>

                  <div className="flex-grow-1 createSurvey_question_container">
                    <TextField
                      disabled={true}
                      value={currq.question}
                      className="font_14_400 ml-10"
                      placeholder="Question number one that is really interessting to everybody"
                      size="small"
                      fullWidth
                    />
                  </div>

                  <div className="d-flex align-items-center h-40px bg_f6 ml-10">
                    <p
                      onClick={() => {
                        props.removeQuestion(currq.id);
                      }}
                      className="font_12_500 color_f4 pointer mr-8"
                    >
                      Delete
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mt-50">
          <div className="d-flex align-items-center ">
            <span className="font_13_700 color_49">4. Survey Participants</span>
          </div>
          <hr className="color_ea" />

          <UserTable allUsers={props.allUsers} handleGoBack={props.handleGoBack} />
        </div>
      </div>
    </div>
  );
}

export default ReviewAndShare;
