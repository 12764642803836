import React from "react";
import { useAuth } from "../../../Utils/Auth";
import LaunchingCard from "../../../Assets/components/LaunchingCard";
import { botAvatarGroup } from "../../../Utils/botAvatarsGroup";
import { notifedFeatures } from "../../../Utils/CommonFunctions";

const Connect = () => {
  const { user } = useAuth();
  return (
    <div className="offset_root ml-100 mr-50">
      <p className="offsetProjectTitle mt-40 ">Connect</p>
      <div className="mt-50">
        <LaunchingCard
          title={"Going green is easier together!"}
          chip={"Launching in December"}
          subtitle={
            "Post or find climate friendly or carbon neutral companies, services and products that help you to optimize your supply chain."
          }
          isShowWiki={true}
          img={
            botAvatarGroup[user?.bot_avatar_id]?.surveyConnectPlaceholder?.url
          }
          feature={notifedFeatures.connect}
        />
      </div>
    </div>
  );
};

export default Connect;
