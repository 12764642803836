import React from "react";

const KeyboardDownIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        stroke={cc || "#00915B"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M15 8l-7 7-7-7M8 1v14"
      ></path>
    </svg>
  );
};

export default KeyboardDownIcon;
