import React, { useState } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import axios from "../../../../Api";
import { useEffect } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import { useNavigate } from "react-router-dom";

const CreateFaq = () => {
  const [allValues, setAllValues] = useState({
    topicid: "",
    question: "",
    question_de: "",
    answer: "",
    answer_de:"",
    link: "",
  });
  const [topics, settopics] = useState([]);

  const getdata = async () => {
    try {
      const response = await axios.get(`/admin/academy/topics`);
      if (response.data.success) {
        settopics(response.data.data);
      }
    } catch (error) {
      toastResponseError(error);
    }
  };
  useEffect(() => {
    getdata();
  }, []);
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const handleCreateFaq = async (e) => {
    e.preventDefault();
    try {
      const url = fid
        ? `/admin/academy/update-faq/${fid}`
        : `/admin/academy/create-faq`;
      const response = await axios.post(url, allValues);
      if (response.data.success) {
        if (fid) {
          toast.success("Faq updated successfully");
        } else toast.success("Faq created successfully");
        navigate(`/admin/dashboard?tab=5&activetab=8`);
        setAllValues({
          topicid: "",
          question: "",
          question_de: "",
          answer: "",
           answer_de:"",
          link: "",
        });
      }
    } catch (error) {
      return toastResponseError(error);
    }
  };

  const getfdata = async () => {
    try {
      if (!fid) return;
      const response = await axios.get(`/admin/academy/faq/${fid}`);
      if (response.data.success) {
        setAllValues(response.data.data);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  const fid = getUrlqueries("fid");
  useEffect(() => {
    getfdata();
  }, [fid]);

  return (
    <div>
      <form onSubmit={handleCreateFaq}>
        <div className="form-group">
          <label htmlFor="exampleFormControlSelect1">Choose Topic</label>
          <select
            name="topicid"
            required
            value={allValues.topicid}
            onChange={changeHandler}
            className="form-control"
            id="exampleFormControlSelect1"
          >
            <option value="">Select </option>;
            <option value="general">General FAQ</option>;
            {topics.map((v, i) => {
              return (
                <option key={i} value={v._id}>
                  {v.title}
                </option>
              );
            })}
          </select>
        </div>
        <br />

        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Question in English</label>
          <input
            onChange={changeHandler}
            value={allValues.question}
            type="text"
            required
            name="question"
            className="form-control"
            placeholder="Enter Title"
          />
        </div>
        <br />
        <div className="form-group">
          <label htmlFor="exampleInputEmail1">Question in German</label>
          <input
            onChange={changeHandler}
            value={allValues.question_de}
            type="text"
            required
            name="question_de"
            className="form-control"
            placeholder="Enter Title"
          />
        </div>
        <br />

        <div className="form-group">
          <label>Answer in English</label>
          <textarea
            required
            value={allValues.answer}
            onChange={changeHandler}
            type="text"
            name="answer"
            className="form-control"
            placeholder="Enter Answer"
          />
        </div>
        <br />
        <div className="form-group">
          <label>Answer in German</label>
          <textarea
            required
            value={allValues.answer_de}
            onChange={changeHandler}
            type="text"
            name="answer_de"
            className="form-control"
            placeholder="Enter Answer"
          />
        </div>
        <br />

        <div className="form-group">
          <label>Link</label>
          <input
            value={allValues.link}
            onChange={changeHandler}
            type="url"
            name="link"
            className="form-control"
            placeholder="Enter Link"
          />
        </div>
        <br />

        <div className="form-group">
          <ButtonComp status={1} type="submit">
            {fid ? "Update" : "Create"}
          </ButtonComp>
        </div>
      </form>
    </div>
  );
};

export default CreateFaq;
