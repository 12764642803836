import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./selectSubClasses.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Drawer } from "@mui/material";
import location from "../../../../../../DataJSON/companyLocation.json";
import event from "../../../../../../DataJSON/event.json";
import hotel from "../../../../../../DataJSON/hotel.json";
import product from "../../../../../../DataJSON/product.json";
import service from "../../../../../../DataJSON/service.json";
import realEstateA from "../../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../../DataJSON/building.json";
import agency from "../../../../../../DataJSON/agency.json";
import education from "../../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../../DataJSON/shipping.json";
import skilledTrades from "../../../../../../DataJSON/skilledTradesSector.json";

import TabLabels from "../../../Components/TabLabels";
import { TextField, InputAdornment } from "@mui/material";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";
import subScopeCategory from "../../../../../../DataJSON/subScopes.json";
import ButtonComp from "../../../../../../Assets/components/Button";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function SelectSubClasses(props) {
  const [isFullYear, setIsFullYear] = useState(false);
  const [value, setValue] = useState(0);
  const [isSelectActive, setIsSelectActive] = useState(false);
  const [tabNames, setTabNames] = useState([]);
  const [tabsInfo, setTabsInfo] = useState([]);
  const [countTabItems, setCountTabItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState({});
  const [filteredTabInfo, setFilteredTabInfo] = useState([]);
  const [filterProp, setFilterProp] = useState([]);

  const data = {
    location,
    event,
    hotel,
    product,
    service,
    "real-estate agency": realEstateA,
    "real estate development": realEstateD,
    building,
    agency,
    "educational institution": education,
    shipping,
    "skilled trades company": skilledTrades,
  };
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "November",
    "October",
    "November",
    "December",
  ];
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const onFilterChange = (e) => {
    setFilterProp(e.target.value);
    let currFilteredData = [];
    tabsInfo[value].forEach((currVal) => {
      if (
        currVal.Subclass.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        currFilteredData.push(currVal);
      }
    });
    setFilteredTabInfo(currFilteredData);
  };
  useEffect(() => {
    setFilteredTabInfo(tabsInfo[value]);
  }, [value]);
  const handleCardClick = (index, subIndex) => {
    let markIndex = index + "." + subIndex;
    let makeDefaultSelectedItems = { ...selectedItems };
    Object.keys(makeDefaultSelectedItems).forEach((key) => {
      makeDefaultSelectedItems[key] = false;
    });
    makeDefaultSelectedItems = {
      ...makeDefaultSelectedItems,
      [markIndex]: !selectedItems[markIndex],
    };
    let selected = false;
    Object.keys(makeDefaultSelectedItems).forEach((key) => {
      if (makeDefaultSelectedItems[key]) {
        selected = true;
        return;
      }
    });
    setIsSelectActive(selected);
    setSelectedItems(makeDefaultSelectedItems);
  };

  const handleIsFullYearChange = (value) => {
    setIsFullYear(value);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (Object.keys(props.updateSubClassData).length > 0) {
      setUpStates();
      setIsSelectActive(true);
    }
  }, [props.updateSubClassData]);

  const setUpStates = () => {
    if (props.itemName.length === 0) return;

    let currItemName =
      props.itemName.charAt(0).toUpperCase() + props.itemName.slice(1);
    let currItem = data[props.itemName.toLowerCase()][currItemName];

    let subClassInfo = [];
    if (
      currItem[props.ScopeInfo.class] &&
      currItem[props.ScopeInfo.class][props.ScopeInfo.scope]
    ) {
      subClassInfo =
        currItem[props.ScopeInfo.class][props.ScopeInfo.scope].classes;
    }
    // console.log(currItem[props.ScopeInfo.class][props.ScopeInfo.scope]);
    let currTabs = [];
    let currTabsInfo = [];
    let countSubClasses = [];
    let currSelected = {};
    if (subClassInfo)
      subClassInfo.forEach((subClass, index) => {
        let keys = Object.keys(subClass);
        let currKey = keys[0];
        currTabs.push(...keys);
        currTabsInfo.push(subClass[currKey]);
        countSubClasses.push(subClass[currKey].length);
        for (let j = 0; j < subClass[currKey].length; j++) {
          currSelected[index + "." + j] = false;
          if (Object.keys(props.updateSubClassData).length > 0) {
            if (
              props.updateSubClassData.subClass == subClass[currKey][j].Subclass
            ) {
              currSelected[index + "." + j] = true;
            }
          }
        }
      });
    setValue(0);
    setSelectedItems(currSelected);
    setCountTabItems(countSubClasses);
    setFilteredTabInfo(currTabsInfo[0]);
    setTabNames(currTabs);
    setTabsInfo(currTabsInfo);
  };
  useEffect(() => {
    setUpStates();
  }, [props.ScopeInfo]);

  const handleSelectSubmit = () => {
    if (props.itemName.length === 0) return;
    let currItemName =
      props.itemName.charAt(0).toUpperCase() + props.itemName.slice(1);
    tabsInfo.forEach((tabInfo, index) => {
      tabInfo.forEach((currSubClass, subIndex) => {
        let position = index + "." + subIndex;
        if (selectedItems[position]) {
          let SubClassDetails = {
            itemName: currItemName,
            class: props.ScopeInfo.class,
            scope: props.ScopeInfo.scope,
            scopeClass: tabNames[index],
            subClass: currSubClass.Subclass,
            description: currSubClass.Description,
            calculationMethods: currSubClass.calculationMethods,
            month: props.ScopeInfo.month,
            logId: props.ScopeInfo.logId,
            scopeIndex: props.ScopeInfo.scopeIndex,
            year: props.ScopeInfo.year,
            isFullYear: isFullYear,
          };
          props.handleOpenSubClassDataFill(SubClassDetails);
          return;
        }
      });
    });
  };
  return (
    <Drawer
      anchor={"right"}
      open={props.toggleSelectSubClasses}
      onClose={() => {
        props.handleToggleSubClasses(false);
      }}
      PaperProps={{
        sx: { width: "70%" },
      }}
    >
      <div className="drawer_root row_gutter_19">
        <div className="productDetail_drawerHead pb-40">
          <div className="align_text_right pr-20 mt-10">
            <i
              className="bi bi-x-lg SideDrawer_close_icon pointer"
              onClick={() => props.handleToggleSubClasses(false)}
            ></i>
          </div>
          <div className="pl-35">
            <p className="faint_text_2">
              {props.ScopeInfo &&
                props.ScopeInfo.scope &&
                subScopeCategory[props.ScopeInfo.scope.toLowerCase()] &&
                "Scope " +
                  subScopeCategory[props.ScopeInfo.scope.toLowerCase()]}
            </p>
            <div className="d-flex justify-items-center">
              <p className="f-27 fw-700 align-items-center">
                {props.ScopeInfo.scope}
              </p>
              <div className="d-flex align-items-center ml-8">
                {props.ScopeInfo &&
                props.ScopeInfo.scope &&
                data[props.itemName.toLowerCase()][props.itemName][
                  props.ScopeInfo.class
                ][props.ScopeInfo.scope].isMandatory ? (
                  <span className="orange_label">Mandatory</span>
                ) : (
                  <span className="green_label">Recommended</span>
                )}
              </div>
            </div>
            <p className="subheading_light_2 w-60 mt-10">
              {props.ScopeInfo &&
                props.ScopeInfo.scope &&
                data[props.itemName.toLowerCase()][props.itemName][
                  props.ScopeInfo.class
                ][props.ScopeInfo.scope].description}
            </p>
            {/* <p className="subheading_light_2">
              This is not to be confused with the daily commute to the office.
            </p> */}
          </div>
        </div>
        <div className="p-32">
          <div className="main_box">
            <div className="d-flex justify-content-between">
              <div className="">
                <p className="heading_9">
                  1. SELECT THE TIMEFRAME FOR YOUR LOG
                </p>

                <div className="selectSubClasses_time_box border-ef d-flex mt-30 ml-0">
                  <button
                    onClick={() => {
                      handleIsFullYearChange(false);
                    }}
                    className={`button_style_none heading_4 
                  ${
                    isFullYear == false
                      ? "selectSubClasses_time_button_active"
                      : "selectSubClasses_time_button"
                  }
                `}
                  >
                    <span>{monthNames[props.ScopeInfo.month - 1]}</span>
                  </button>

                  <button
                    onClick={() => {
                      handleIsFullYearChange(true);
                    }}
                    className={`button_style_none heading_4 
                ${
                  isFullYear == true
                    ? "selectSubClasses_time_button_active"
                    : "selectSubClasses_time_button"
                }
              `}
                  >
                    {" "}
                    <span>{props.year} full year</span>
                  </button>
                </div>
              </div>
              <div>
                <ButtonComp status={3} className="text-black">
                  Missing Data
                </ButtonComp>
              </div>
            </div>

            <div className="mt-50">
              <p className="heading_9 text_capital ps-4">
                2. SELECT YOUR {props.ScopeInfo.scope} SUBCLASS
              </p>
            </div>
            <div className="mt-50">
              <Box sx={{ width: "100%", paddingLeft: "24px" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    aria-label="basic tabs example"
                    className="general_root_tabs"
                  >
                    {tabNames.map((currTab, index) => {
                      return (
                        <Tab
                          label={
                            <TabLabels
                              title={currTab}
                              count={countTabItems[index]}
                            />
                          }
                          {...a11yProps(index)}
                          key={index}
                        />
                      );
                    })}
                  </Tabs>
                </Box>

                {tabsInfo.map((tabInfo, index) => {
                  return (
                    <TabPanel
                      value={value}
                      index={index}
                      key={index}
                      className="general_tabpanel"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <TextField
                            value={filterProp}
                            onChange={(e) => onFilterChange(e)}
                            className="body_text_l"
                            id="input-with-icon-textfield"
                            placeholder={`Search`}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="right">
                                  <i className="bi bi-search"></i>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              "& .MuiInputBase-root": {
                                height: 40,
                                width: 250,
                              },
                            }}
                          />
                        </div>

                        <div className="d-flex justify-content-end mr-32 mb-32">
                          <button
                            className={
                              isSelectActive
                                ? "selectSubClasses_button"
                                : "selectSubClasses_button_inactive"
                            }
                            onClick={() => {
                              handleSelectSubmit();
                            }}
                          >
                            Select
                          </button>
                        </div>
                      </div>

                      <div className="row">
                        {filteredTabInfo &&
                          filteredTabInfo.map((currSubClass, subIndex) => {
                            return (
                              <div
                                className="col-4 mt-20"
                                key={index + subIndex}
                              >
                                <div
                                  onClick={() => {
                                    handleCardClick(index, subIndex);
                                  }}
                                  className={`selectSubClasses_card p-16 pointer ${
                                    selectedItems[index + "." + subIndex]
                                      ? "card_selection_border"
                                      : ""
                                  }`}
                                >
                                  <img
                                    src={getServiceIcon(currSubClass.Subclass)}
                                    className="h-35px"
                                  />
                                  <p className="faint_text_2 mt-14">
                                    {tabNames[index]}
                                  </p>
                                  <p className="mt-20 card_heading">
                                    {currSubClass.Subclass}
                                  </p>

                                  <p className="body_text_s_light mt-10">
                                    {currSubClass.Description}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </TabPanel>
                  );
                })}
              </Box>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default SelectSubClasses;
