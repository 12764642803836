import React, { useEffect, useState } from "react";
// import avatars from "../../../Utils/avatars.json";
import "./index.css";
import useQuery from "../../../Utils/query";
import { useNavigate } from "react-router";
import { useAuth } from "../../../Utils/Auth";
import { botAvatarGroup } from "../../../Utils/botAvatarsGroup";
import axios from "../../../Api";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../Utils/toastResponseError";
import PressEnter from "../../../Assets/components/PressEnter/PressEnter";
import BackForwardButton from "../../../Assets/components/BackForwardButton/BackForwardButton";
import ButtonComp from "../../../Assets/components/Button";

export default function Avatar() {
  const [step, setstep] = useState(0);
  const { getUser, setSessionExpire } = useAuth();
  const query = useQuery();
  const history = useNavigate();
  // const [active_index, setactive_index] = useState("");
  const [selected_avatar, setselected_avatar] = useState("");

  const qstep = Number(query.get("step"));

  useEffect(() => {
    if (qstep === 1 && selected_avatar) {
      setstep(qstep);
    } else {
      setstep(0);
    }
  }, [qstep]);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      if (step === 0) {
        if (selected_avatar) {
          history("/register/avatar?step=1");
          setstep(1);
        }
      } else {
        updateBotAvatar();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [selected_avatar, step]);

  const updateBotAvatar = async () => {
    await axios({
      url: `/user/auth/edit/user`,
      method: "put",
      data: {
        bot_avatar_id: selected_avatar,
      },
    })
      .then((res) => {
        if (res.data.success) {
          history(`/register/subscription?step=0`);
          getUser();
          toast.success("Avatar selected");
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  return (
    <div className="avatar_select_root">
      {step === 0 ? (
        <div className="a_container pt-40 text-start container">
          <p className="purple_label mb-16">Personalize</p>
          <p className="heading_1 mt-8 mb-16">Choose your Avatar</p>
          <p className="body_text_m grey-03 mb-24">
            This character will guide you through today.green and help you,
            whenever you need him or her.
          </p>
          <div className="row ">
            {Object.keys(botAvatarGroup).map((avt) => (
              <div
                className="col-lg-3 col-md-4 col-6 my-2 px-4 pointer"
                key={avt}
                onClick={() => setselected_avatar(avt)}
              >
                <div
                  className={`register_avatar_card ${
                    selected_avatar === avt && "register_avatar_card_active"
                  }`}
                >
                  <img src={botAvatarGroup[avt]?.face?.url} alt="Avatar" />
                </div>
              </div>
            ))}
          </div>
          <div className="text-end">
            <ButtonComp
              status={2}
              className="lg mt-32"
              onClick={() => {
                if (selected_avatar) {
                  history("/register/avatar?step=1");
                  setstep(1);
                }
              }}
            >
              Save Selection
            </ButtonComp>
            <PressEnter />
          </div>
        </div>
      ) : (
        <div className="container-fluid ">
          <div className="row register_background mx-0">
            <div className="col-md-7 text-center avatar_select_success">
              <img
                src={botAvatarGroup[selected_avatar].avatarSelect.url}
                alt="joy-avatar"
                className="img-fluid"
              />
            </div>
            <div className="d-flex col-md-5 align-items-center register_info_container">
              <div className="d-flex align-items-start flex-column justify-content-center ml-32 col-md-10">
                <p className="purple_label mb-16">Success!</p>
                <p className="heading_1 mt-16 mb-16 text-start">
                  Your Avatar was selected!
                </p>
                <div className="w-100 mt-30 d-flex align-items-start justify-content-between">
                  <BackForwardButton
                    handleGoBack={() => {
                      setstep(0);
                      history("/register/avatar?step=0");
                    }}
                    hanldeGoForward={() => updateBotAvatar()}
                  />
                  <div>
                    <ButtonComp
                      status={2}
                      className="lg"
                      onClick={() => updateBotAvatar()}
                    >
                      Save
                    </ButtonComp>
                    <PressEnter />
                  </div>
                </div>
                {/* <div className="mt-30 register_button text-end w-100">
                  <button
                    className="secondary_button_active"
                    onClick={() => updateBotAvatar()}
                  >
                    Continue to Subscription
                  </button>
                </div>
                <div className="d-flex justify-content-between mt-10 w-100">
                  <div>
                    <i
                      className={`pointer bi bi-chevron-left register_button_disable`}
                      //   onClick={() =>
                      //     props.decQuestionNo(props.regDetails[props.target])
                      //   }
                    ></i>
                    <i
                      className={`pointer bi bi-chevron-right register_button_disable
                  
                  `}
                      //   onClick={() =>
                      //     props.incQuestionNo(props.regDetails[props.target])
                      //   }
                    ></i>
                  </div>

                  <div className="d-flex">
                    <p className="register_enter"> Press Enter</p>
                    <i className="bi bi-arrow-return-left"></i>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
