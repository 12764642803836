import React, { useEffect } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../Utils/TableComp";
import Text from "../../../../../../Assets/components/Empty/text";
import UncheckIcon from "./Components/uncheckIcon";
import CheckIcon from "./Components/checkIcon";
import ButtonComp from "../../../../../../Assets/components/Button";
import DownloadGHGReport from "./Components/DownloadGHGReport";
//import Axios from "../../../../../../Api";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import Axios from "../../../../../../Api";
import { useAuth } from "../../../../../../Utils/Auth";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import useQuery from "../../../../../../Utils/query";

export default function GHGTable({
  scopes,
  c_scopes,
  isComparison,
  handleGHGDownloadDrawer,
  openGHGDownload,
  reportName,
  timeFrameType,
  timeFrameEndYear,
  timeFrameYear,
  selectedAccountingItems,
  accountingItemsLength,
  startDate,
  endDate,
  isProduct,
  scopeFromAPi,
}) {
  const navigate = useNavigate();
  const [allScopes, setallScopes] = React.useState([]);
  const [allCScopes, setallCScopes] = React.useState([]);

  const [totalEmission, settotalEmission] = React.useState(0);

  const [selectedScopes, setselectedScopes] = React.useState([]);
  const [selectedCScopes, setselectedCScopes] = React.useState([]); //comparision scopes
  const query = useQuery();
  const reportId = query.get("report_id");

  const [selectAll, setselectAll] = React.useState(true);
  const { setSessionExpire } = useAuth();
  let tempScopes = [];
  let totalEmissionTemp = 0;
  useEffect(() => {
    if (scopes) {
      if (!isProduct) {
        for (let i of scopes.scope1Data) {
          tempScopes.push({
            scope: "Scope 1",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }
        for (let j of scopes.scope2Data) {
          tempScopes.push({
            scope: "Scope 2",
            subscope: j.subScope,
            emission: j.totalEmission,
            _id: j._id,
          });
          totalEmissionTemp += j.totalEmission;
        }
        for (let k of scopes.scope3Data) {
          tempScopes.push({
            scope: "Scope 3",
            subscope: k.subScope,
            emission: k.totalEmission,
            _id: k._id,
          });
          totalEmissionTemp += k.totalEmission;
        }
      } else {
        for (let i of scopes.product1Data) {
          tempScopes.push({
            scope: "Material acquisition & pre-processing",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }
        for (let i of scopes.product2Data) {
          tempScopes.push({
            scope: "Production",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }

        for (let i of scopes.product3Data) {
          tempScopes.push({
            scope: "Distribution & storage",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }

        for (let i of scopes.product4Data) {
          tempScopes.push({
            scope: "Use",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }

        for (let i of scopes.product5Data) {
          tempScopes.push({
            scope: "End-Of-Life",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }
      }

      settotalEmission(totalEmissionTemp);
      setallScopes(tempScopes);
      setselectedScopes(tempScopes);
    }
  }, [scopes]);
  let tempCScopes = [];

  useEffect(() => {
    if (c_scopes) {
      if (!isProduct) {
        for (let i of c_scopes.c_scope1Data) {
          tempCScopes.push({
            scope: "Scope 1",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
        }
        for (let j of c_scopes.c_scope2Data) {
          tempCScopes.push({
            scope: "Scope 2",
            subscope: j.subScope,
            emission: j.totalEmission,
            _id: j._id,
          });
        }
        for (let k of c_scopes.c_scope3Data) {
          tempCScopes.push({
            scope: "Scope 3",
            subscope: k.subScope,
            emission: k.totalEmission,
            _id: k._id,
          });
        }
      } else {
        for (let i of c_scopes.c_product1Data) {
          tempScopes.push({
            scope: "Material acquisition & pre-processing",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }
        for (let i of c_scopes.c_product2Data) {
          tempScopes.push({
            scope: "Production",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }

        for (let i of c_scopes.c_product3Data) {
          tempScopes.push({
            scope: "Distribution & storage",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }

        for (let i of c_scopes.c_product4Data) {
          tempScopes.push({
            scope: "Use",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }

        for (let i of c_scopes.c_product5Data) {
          tempScopes.push({
            scope: "End-Of-Life",
            subscope: i.subScope,
            emission: i.totalEmission,
            _id: i._id,
          });
          totalEmissionTemp += i.totalEmission;
        }
      }
      setallCScopes(tempCScopes);
      setselectedCScopes(tempCScopes);
    }
  }, [c_scopes]);

  const EmptyTable = () => {
    let component = [];
    for (let i = 0; i < 5; i++) {
      component.push(
        <StyledTableRow
          sx={{
            "&:last-child td, &:last-child th": {
              borderBottom: 0,
            },
          }}
        >
          <StyledTableCell sx={{ borderLeft: "0" }} component="th" scope="row">
            <UncheckIcon />
          </StyledTableCell>
          <StyledTableCell component="th" scope="row" textleft={true}>
            <Text />
          </StyledTableCell>
          <StyledTableCell
            textleft={true}
            className="align-items-center justify-content-center"
          >
            <Text />
          </StyledTableCell>
          <StyledTableCell textleft={true}>
            <Text />
          </StyledTableCell>
          <StyledTableCell textleft={true} sx={{ borderRight: "0" }}>
            <Text />
          </StyledTableCell>
        </StyledTableRow>
      );
    }
    return component;
  };

  React.useEffect(() => {
    if (selectAll) {
      setselectedScopes(allScopes);
      setselectedCScopes(allCScopes);
    } else {
      setselectedScopes([]);
      setselectedCScopes([]);
    }
  }, [selectAll]);
  const handleGenerateReport = async () => {
    if (reportName.trim().length === 0) {
      toast.error("Please Enter Report Name");
      return;
    }

    handleGHGDownloadDrawer();
  };
  const saveReport = async (publish) => {
    if (reportName.trim().length === 0) {
      toast.error("Please Enter Report Name");
      return;
    }
    let accountingItemsId = [];
    selectedAccountingItems.forEach((currItem) => {
      accountingItemsId.push({
        id: currItem._id,
        accountingItemName: currItem.accountingItemName,
        name: currItem.name,
      });
    });
    let tempCount = {
      product: 0,
      location: 0,
      event: 0,
      service: 0,
      hotel: 0,
      building: 0,
      shipping: 0,
      "real estate development": 0,
      "real-estate agency": 0,
      "educational institution": 0,
      agency: 0,
      "skilled trades company": 0,
    };

    selectedAccountingItems.forEach((currItem) => {
      tempCount[currItem.accountingItemName.toLowerCase()]++;
    });
    let currType = "";

    let uniqueItemCount = 0;
    Object.keys(tempCount).forEach((currItemName) => {
      if (tempCount[currItemName]) {
        uniqueItemCount++;
      }
    });
    if (accountingItemsLength === selectedAccountingItems.length) {
      currType = "entire company";
    } else if (uniqueItemCount === 1) currType = "single";
    else currType = "multiple";
    let currtimeFrame = {};

    if (timeFrameType === 3) {
      currtimeFrame.year = timeFrameEndYear;
    }
    if (timeFrameType == 1) {
      currtimeFrame.year = timeFrameEndYear;
      currtimeFrame.month = startDate.getMonth() + 1;
    }
    if (timeFrameType == 2) {
      let currQuarter = 1;
      if (startDate.getMonth() == 3 && endDate.getMonth() === 5)
        currQuarter = 2;
      if (startDate.getMonth() == 6 && endDate.getMonth() === 8)
        currQuarter = 3;
      if (startDate.getMonth() == 9 && endDate.getMonth() === 11)
        currQuarter = 4;
      currtimeFrame.quarter = currQuarter;
      currtimeFrame.startMonth = startDate.getMonth() + 1;
      currtimeFrame.startYear = startDate.getFullYear();
      currtimeFrame.endMonth = endDate.getMonth() + 1;
      currtimeFrame.endYear = endDate.getFullYear();
    }
    if (timeFrameType === 4) {
      currtimeFrame.startMonth = startDate.getMonth() + 1;
      currtimeFrame.startYear = startDate.getFullYear();
      currtimeFrame.endMonth = endDate.getMonth() + 1;
      currtimeFrame.endYear = endDate.getFullYear();
    }

    try {
      let response = await Axios({
        url: `/ghg/${reportId ? `edit-scope/${reportId}` : "save-scopes"}`,
        method: reportId ? "put" : "post",
        data: {
          published: publish,
          name: reportName,
          type: currType, //calculate
          timeFrameOption: timeFrameType, //Done
          timeFrame: currtimeFrame, //Done
          isComparison: isComparison, //Done
          comparisonTimeFrame: {},
          scopesData: selectedScopes, ///Done
          compareScopesData: selectedCScopes,
          scope: selectedScopes,
          accountingItems: accountingItemsId,
        },
      });
      if (response.data.success) {
        // openDrawer()
        toast.success("report has been saved");
        navigate(`/dashboard?tab=6`);
        // console.log(response.data);
      }
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  useEffect(() => {
    if (scopeFromAPi.length > 0) {
      setallScopes(scopeFromAPi);
      setselectedScopes(scopeFromAPi);
      // tempCScopes = scopeFromAPi
    }
  }, [scopeFromAPi]);

  return (
    <div>
      <DownloadGHGReport
        openGHGDownload={openGHGDownload}
        handleGHGDownloadDrawer={handleGHGDownloadDrawer}
        selectedScopes={selectedScopes}
        selectedCScopes={selectedCScopes}
        selectedAccountingItems={selectedAccountingItems}
        accountingItemsLength={accountingItemsLength}
        isComparison={isComparison}
        startDate={startDate}
        endDate={endDate}
        reportName={reportName}
        timeFrameType={timeFrameType}
        timeFrameEndYear={timeFrameEndYear}
        timeFrameYear={timeFrameYear}
        isProduct={isProduct}
      />
      <div className="analytics_table_container pb-40 mt-3">
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640 }}
          className="mt-16"
        >
          <Table
            sx={{ minWidth: 640, borderRadius: "10px" }}
            aria-label="simple table"
          >
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  sx={{
                    borderLeft: "0",
                    width: "5%",
                  }}
                  component="th"
                  className="text-uppercase"
                  textCenter={true}
                >
                  <span onClick={() => setselectAll(!selectAll)}>
                    {selectAll ? <CheckIcon /> : <UncheckIcon />}
                  </span>
                </HeadCell>
                <HeadCell
                  sx={{
                    width: isComparison ? "18%" : "25%",
                  }}
                  component="th"
                  className="text-uppercase"
                >
                  {isProduct ? "Life Cycle" : "SCOPE"}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ width: isComparison ? "25%" : "35%" }}
                >
                  SUBSCOPE
                </HeadCell>
                <HeadCell component="th" className="text-uppercase">
                  EMISSION(tCO2e)
                </HeadCell>
                {isComparison && (
                  <HeadCell component="th" className="text-uppercase">
                    COMPARE EMISSION(tCO2e)
                  </HeadCell>
                )}
                <HeadCell
                  sx={{ borderRight: "0", width: "15%" }}
                  component="th"
                  className="text-uppercase"
                >
                  EMISSION SHARE
                </HeadCell>
              </StyledHeadRow>
            </TableHead>

            <TableBody>
              {allScopes && allScopes.length > 0 ? (
                <>
                  {allScopes.map((label, i) => (
                    <StyledTableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: 0,
                        },
                      }}
                      key={i}
                    >
                      <StyledTableCell
                        sx={{ borderLeft: "0" }}
                        component="th"
                        scope="row"
                        className="text_overflow-1"
                      >
                        {selectedScopes &&
                        selectedScopes.length > 0 &&
                        selectedScopes.findIndex(
                          (obj) => obj._id === label._id
                        ) !== -1 ? (
                          <span
                            onClick={() => {
                              setselectedScopes(
                                selectedScopes.filter(
                                  (obj) => obj._id !== label._id
                                )
                              );
                              setselectedCScopes(
                                selectedCScopes.filter(
                                  (obj) => obj._id !== allCScopes[i]._id
                                )
                              );
                            }}
                          >
                            <CheckIcon />
                          </span>
                        ) : (
                          <span
                            onClick={() => {
                              setselectedScopes([...selectedScopes, label]);
                              setselectedCScopes([
                                ...selectedCScopes,
                                allCScopes[i],
                              ]);
                            }}
                          >
                            <UncheckIcon />
                          </span>
                        )}
                      </StyledTableCell>

                      <StyledTableCell
                        textleft={true}
                        sx={{ borderRight: "0" }}
                      >
                        {label.scope}
                      </StyledTableCell>
                      <StyledTableCell
                        textleft={true}
                        sx={{ borderRight: "0" }}
                        className="text_capital_f"
                      >
                        {/* <div className="subtitle_box bg_soft_green width_fit">
                        <p className="font_13_600 color_green">
                          {daysDiffFromCurrent(label.endDate)} days remaining
                        </p>
                      </div> */}
                        {label.subscope}
                      </StyledTableCell>
                      <StyledTableCell textleft={true}>
                        {label.emission.toFixed(2).toLocaleString()}
                      </StyledTableCell>
                      {isComparison && (
                        <StyledTableCell textleft={true}>
                          {allCScopes[i]
                            ? allCScopes[i].emission.toFixed(2).toLocaleString()
                            : 0}
                        </StyledTableCell>
                      )}
                      <StyledTableCell
                        textleft={true}
                        sx={{ borderRight: "0" }}
                      >
                        {totalEmission === 0
                          ? 0
                          : ((label.emission / totalEmission) * 100)
                              .toFixed(1)
                              .toLocaleString() + "%"}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                  <StyledTableRow
                    sx={{
                      fontWeight: 700,
                    }}
                  >
                    <StyledTableCell
                      sx={{ borderLeft: "0", fontWeight: 700 }}
                      component="th"
                      scope="row"
                      className="text_overflow-1"
                    >
                      Total
                    </StyledTableCell>

                    <StyledTableCell textleft={true} sx={{ fontWeight: 700 }}>
                      {selectedScopes && selectedScopes.length > 0
                        ? new Set(selectedScopes.map((obj) => obj.scope)).size
                        : 0}{" "}
                      Scopes
                    </StyledTableCell>
                    <StyledTableCell
                      textleft={true}
                      sx={{ borderLeft: "0", fontWeight: 700 }}
                      className="text_capital_f"
                    >
                      {/* <div className="subtitle_box bg_soft_green width_fit">
                        <p className="font_13_600 color_green">
                          {daysDiffFromCurrent(label.endDate)} days remaining
                        </p>
                      </div> */}
                      {selectedScopes.length} Subscopes
                    </StyledTableCell>
                    <StyledTableCell textleft={true} sx={{ fontWeight: 700 }}>
                      {selectedScopes && selectedScopes.length > 0
                        ? selectedScopes
                            .reduce((acc, obj) => acc + obj.emission, 0)
                            .toFixed(2)
                            .toLocaleString()
                        : 0}{" "}
                      tCO2e
                    </StyledTableCell>
                    <StyledTableCell
                      textleft={true}
                      sx={{ borderRight: "0" }}
                    ></StyledTableCell>
                  </StyledTableRow>
                </>
              ) : (
                <EmptyTable />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      {selectedScopes && (
        <div className="d-flex justify-content-end">
          {!reportId && (
            <ButtonComp
              status={3}
              disabled={selectedScopes.length === 0}
              onClick={() => saveReport(false)}
            >
              Save as Draft
            </ButtonComp>
          )}
          &nbsp; &nbsp;
          <ButtonComp
            status={2}
            disabled={selectedScopes.length === 0}
            onClick={() => handleGenerateReport()}
          >
            {reportId ? "Edit GHG Report" : "Create GHG Report"}
          </ButtonComp>
        </div>
      )}
    </div>
  );
}
