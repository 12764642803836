import React from "react";

const PencilIcon = ({ cc, style, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      style={style}
      className={className}
    >
      <path
        stroke={cc || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.847 2.127a2.14 2.14 0 013.026 3.026L5.661 15.365 1.5 16.5l1.135-4.16L12.847 2.126z"
      ></path>
    </svg>
  );
};

export default PencilIcon;
