import React from "react";

const RightChevronIcon = ({ cc, className, style, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="12"
      fill="none"
      viewBox="0 0 7 12"
      className={className}
      style={style}
      onClick={onClick}
    >
      <path
        stroke={cc || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 11l5-5-5-5"
      ></path>
    </svg>
  );
};

export default RightChevronIcon;
