import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { chars } from "../../../../../../../../../../Utils/CommonFunctions";

// const data = [
//     { name: 'Group A', value: 400 },
//     { name: 'Group B', value: 300 },
//     { name: 'Group C', value: 300 },
//     { name: 'Group D', value: 200 },
//   ];

const COLORS = [
  "#4EAC8C",
  "#66ACFF",
  "#B09FFE",
  "#D679F7",
  "#FEB572",
  "#FFD572",
];

// const CustomTooltip = ({ active, payload }) => {
//   if (active) {
//     return (
//       <div className="survey_mcq_custom_tooltip">
//         {payload.map((entry, index) => (
//           <p key={`data-${index}`}>{`${chars[entry.name]}: ${entry.value}`}</p>
//         ))}
//       </div>
//     );
//   }

//   return null;
// };

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="d-flex align-items-center custom_tooltip_">
        {payload.map((entry, index) => (
          <div className="d-flex align-items-center" key={`data-${index}`}>
            <span
              className="span_cirlce mr-8"
              style={{ backgroundColor: entry.color }}
            ></span>
            <p className="tooltip_chart_text">
              {chars[entry.name]}: {entry.value}
            </p>
          </div>
        ))}
      </div>
    );
  }
  return null;
};

function SurveyPieChart(props) {
  return (
    <ResponsiveContainer height={200} width={200}>
      <PieChart>
        <Pie
          data={props.data}
          //   cx={120}
          //   cy={200}

          cx={"50%"}
          cy={"50%"}
          innerRadius={50}
          outerRadius={80}
          // fill="#8884d8"
          paddingAngle={0}
          dataKey="count"
        >
          {props.data &&
            props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
        </Pie>
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
    </ResponsiveContainer>
  );
}

export default SurveyPieChart;
