import React from 'react'
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { KeyboardArrowDown } from "@mui/icons-material";
import ButtonComp from '../../../../../../../../../../../../Assets/components/Button';
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none",
  borderBottom: "1px solid #E8EBEF",
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDown />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

function PaymentMethodDetails(props) {
  return (
    <Accordion
      expanded={props.expanded === props.cardIndex}
      onChange={props.handleExpandChange(props.cardIndex)}
    >
      <AccordionSummary
        aria-controls="panel1d-content"
        id="panel1d-header"
      >
        <div className="d-flex align-items-center justify-content-between w-100">
          <div 
           
          className="d-flex align-items-center">
            <img src={props.cardDetails.img} alt={props.cardDetails.title} />
            <p className="subheading_light_2 color_black ml-12">
              {props.cardDetails.title}
            </p>
            <div className="subtitle_box bg_soft_green ml-12">
              <p className="color_green subheading_4">{props.cardDetails.fees}</p>
            </div>
          </div>

          {props.cardDetails.bg.length > 0 ?
            <ButtonComp
              status={3}
              onClick={(e) => {
                e.stopPropagation();
                props.handleSelectedPaymentInfo({
                  img: props.cardDetails.img,
                  title: props.cardDetails.title,
                  fees: props.cardDetails.fees,
                  bg: props.cardDetails.bg,
                  color: props.cardDetails.color,
                  buttonText: props.cardDetails.buttonText,
                  forwardBtn: props.cardDetails.forwardBtn,
                });
                let intentTitle=""
                if( props.cardDetails.title=="SEPA Direct Debit") intentTitle="sepa_debit"
                else if(props.cardDetails.title=="Credit Card") intentTitle="card"
                else if(props.cardDetails.title=="Sofort") intentTitle="sofort"
                else if(props.cardDetails.title=="Bankcontact") intentTitle="bancontact"
                else if(props.cardDetails.title=="iDEAL") intentTitle="ideal"
               
                props.handleCardIntent(intentTitle);
              }}
            >
              Select
            </ButtonComp> :
            <ButtonComp
              status={3}
              onClick={(e) => {
                e.stopPropagation();

               props.handleBillingStepChange(2)
              }}
            >
              Select
            </ButtonComp>
          }
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <p className="body_text_s_light">
          {props.cardDetails.description}
        </p>
      </AccordionDetails>
    </Accordion>
  )
}

export default PaymentMethodDetails