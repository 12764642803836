import React, { useEffect, useRef, useState } from "react";
import "./productMainTable.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ScopeData from "./Components/ScopeData";
import SelectSubClasses from "../SelectSubClasses";
import SubClassDataFill from "../SubClassDataFill";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";
import MissingData from "../MissingData";
import { Fade, Tooltip, tooltipClasses } from "@mui/material";
import { useAuth } from "../../../../../../Utils/Auth";
import LogDrawer from "./Components/LogDrawer";
import location from "../../../../../../DataJSON/companyLocation.json";
import event from "../../../../../../DataJSON/event.json";
import hotel from "../../../../../../DataJSON/hotel.json";
import product from "../../../../../../DataJSON/product.json";
import service from "../../../../../../DataJSON/service.json";
import realEstateA from "../../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../../DataJSON/building.json";
import agency from "../../../../../../DataJSON/agency.json";
import education from "../../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../../DataJSON/shipping.json";
import skilledTrades from "../../../../../../DataJSON/skilledTradesSector.json";
import subscopesjson from "../../../../../../DataJSON/subscopesJson.json";

import RightChevronIcon from "../../../../../../Assets/icons/RightChevronIcon";

const data = {
  location,
  event,
  hotel,
  product,
  service,
  "real-estate agency": realEstateA,
  "real estate development": realEstateD,
  building,
  agency,
  "educational institution": education,
  shipping,
  "skilled trades company": skilledTrades,
};

const StyledTableCell = styled(TableCell)(() => ({
  "&:first-of-type": {
    borderRight: "1px solid #E6E6E6",
  },
  minWidth: "110px",
  borderBottom: "none",
  fontFamily: '"Inter", sans-serif',
  "&:first-child": {
    borderBottom: "1px solid red",
  },
}));

const FixedHeadCell = styled(TableCell)(() => ({
  borderRight: "1px solid #E6E6E6",
  position: "sticky !important",
  zIndex: 99,
  "&:nth-of-type(1)": {
    left: 0,
    borderRight: "1px solid #E6E6E6",
    minWidth: "118px",
    // backgroundColor: "white",
    backgroundColor: "#FBFBFB",
    // zIndex: 99,
  },
  "&:nth-of-type(2)": {
    left: 118,
    borderRight: "1px solid #E6E6E6",
    minWidth: "250px",
    // backgroundColor: "white",
    backgroundColor: "#FBFBFB",
    // zIndex: 99,
  },
  fontWeight: 600,
  fontFamily: '"Inter", sans-serif',
  fontSize: "13px",
}));

const FixedTableCell = styled(TableCell)(() => ({
  borderRight: "1px solid #E6E6E6",
  position: "sticky !important",
  zIndex: 99,
  backgroundColor: "white",
  "&:nth-of-type(1)": {
    left: 0,
    minWidth: "118px",
    boxShadow: "none !important",
    border: "none",
    borderRight: "1px solid #E6E6E6",
  },

  "&:nth-of-type(2)": {
    left: 118,
    // borderRight: "1px solid #E6E6E6",
    minWidth: "200px",
    boxShadow: "none !important",
    border: "none",
    borderRight: "1px solid #E6E6E6",
  },
  fontWeight: 400,
  fontFamily: '"Inter", sans-serif',
  fontSize: "14px",
}));
const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#FBFBFB",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(10)": {
    fontSize: "14px",
    fontWeight: "600",
  },
}));

const StyledHeadRow = styled(TableRow)(() => ({
  // backgroundColor: "#F9FAFC",
}));

const DisabledTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="right"
    TransitionProps={{ timeout: 600 }}
    TransitionComponent={Fade}
    {...props}
    classes={{ popper: className }}
    followCursor
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 220,
    backgroundColor: "transparent",
  },
}));

const SubscopeTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="right-start"
    TransitionComponent={Fade}
    TransitionProps={{ timeout: 600 }}
    {...props}
    classes={{ popper: className }}
    followCursor
  />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 420,
    backgroundColor: "#ffffff",
    borderRadius: 10,
  },
}));

function ProductMainTable(props) {
  console.log(props);
  const [toggleScopeDrawer, setToggleScopeDrawer] = useState(false);
  const [toggleSelectSubClasses, setToggleSelectSubClasses] = useState(false);
  const [toggleSubClassDataFill, setToggleSubClassDataFill] = useState(false);
  const [toggleMissingData, setToggleMissingData] = useState(false);
  const [updateMonthEmissionData, setUpdateMonthEmissionData] = useState({});
  const [updateSubClassData, setUpdateSubClassData] = useState({});
  const [selectedClassScopeName, setSelectedClassScopeName] = useState({});
  const [subClassData, setSubClassData] = useState({});
  const [ScopeInfo, setScopeInfo] = useState({});
  const [currRowMatrixCellCount, setCurrRowMatrixCellCount] = useState(0);
  const [missingDataInfo, setMissingDataInfo] = useState({});
  const [selectedCell, setselectedCell] = useState(null);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const tableref = useRef();
  let animationFrameId = null;
  const { profile } = useAuth();
  const [isMandatory, setisMandatory] = useState(false);

  // logs states
  const [selectedScope, setSelectedScope] = useState(null);
  const [classList, setClassList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(0);
  const [scopeIndex, setScopeIndex] = useState(null);

  useEffect(() => {
    if (!toggleSubClassDataFill) setUpdateMonthEmissionData({});
  }, [toggleSubClassDataFill]);

  const openUpdateMonthEmission = (data) => {
    let allData = data;
    if (allData) allData.cell = selectedCell;
    if (allData && allData.cell && allData.cell.particulars)
      delete allData.cell.particulars;
    setSubClassData(data);
    setUpdateMonthEmissionData(allData);
    setToggleScopeDrawer(false);
    setToggleSubClassDataFill(true);
  };
  const handleMissingDataDrawer = (data) => {
    setMissingDataInfo(data);
    setToggleMissingData(!toggleMissingData);
    setToggleScopeDrawer(false);
    setToggleSelectSubClasses(false);
    setToggleSubClassDataFill(false);
  };
  const handleScopeDrawer = (currItem, month, scopeIndex, cellData) => {
    setScopeInfo({
      ...currItem,
      month,
      logId: props.logId,
      scopeIndex: scopeIndex,
      year: props.year,
    });
    let currCellData = { ...cellData };
    setselectedCell(currCellData);
    setSelectedClassScopeName(currItem);
    setToggleScopeDrawer(!toggleScopeDrawer);
  };
  const handleToggleSubClasses = (value, matrixCellCount) => {
    setCurrRowMatrixCellCount(matrixCellCount);
    setToggleScopeDrawer(false);
    setToggleSelectSubClasses(value);
  };
  const handleToggleSubClassDataFill = (value) => {
    setToggleScopeDrawer(false);
    setToggleSelectSubClasses(false);
    setToggleSubClassDataFill(value);
  };
  const handleOpenSubClassDataFill = (data) => {
    setSubClassData(data);
    handleToggleSubClassDataFill(true);
  };
  const updateSubClassSelection = (data) => {
    setToggleSubClassDataFill(false);
    setUpdateSubClassData(data);
    setToggleSelectSubClasses(true);
  };

  const animateScroll = (scrollDistance) => {
    const tableContainer = tableref.current;
    if (tableContainer) {
      const startTime = performance.now();
      const startScrollLeft = tableContainer.scrollLeft;

      const scroll = (timestamp) => {
        const timeElapsed = timestamp - startTime;
        const newScrollLeft = easeInOutQuad(
          timeElapsed,
          startScrollLeft,
          scrollDistance,
          500
        ); // Adjust the animation duration as needed
        tableContainer.scrollLeft = newScrollLeft;

        if (timeElapsed < 500) {
          animationFrameId = requestAnimationFrame(scroll);
        }
      };

      cancelAnimationFrame(animationFrameId);
      animationFrameId = requestAnimationFrame(scroll);
    }
  };

  const easeInOutQuad = (t, b, c, d) => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  const handleScrollLeft = () => {
    animateScroll(-150); // Adjust the scroll distance as needed
  };

  const handleScrollRight = () => {
    animateScroll(150); // Adjust the scroll distance as needed
  };

  const [quickLogDrawer, setQuickLogDrawer] = useState(false);
  const handleQuickLogDrawerOpen = (currItem, monthIndex, sIndex) => {
    setSelectedScope(currItem);
    let accItemJsonData =
      data[props.itemName.toLowerCase()][props.itemName][currItem.class][
        currItem.scope
      ].classes;
    setClassList(accItemJsonData);
    setQuickLogDrawer(true);
    setSelectedMonth(monthIndex);
    setScopeIndex(sIndex);
  };
  const handleQuickLogDrawerClose = () => {
    setQuickLogDrawer(false);
  };

  return (
    <div className="matrix_table_root">
      <ScopeData
        openUpdateMonthEmission={openUpdateMonthEmission}
        toggleScopeDrawer={toggleScopeDrawer}
        handleScopeDrawer={handleScopeDrawer}
        handleMissingDataDrawer={handleMissingDataDrawer}
        emissionLogCount={props.emissionLogCount}
        currYearEmission={props.currYearEmission}
        ScopeInfo={ScopeInfo}
        handleToggleSubClasses={handleToggleSubClasses}
        logId={props.logId}
        year={props.year}
        itemName={props.itemName}
        selectedCell={selectedCell}
        setisMandatory={setisMandatory}
      />
      <SelectSubClasses
        ScopeInfo={ScopeInfo}
        year={props.year}
        itemName={props.itemName}
        toggleSelectSubClasses={toggleSelectSubClasses}
        handleToggleSubClasses={handleToggleSubClasses}
        handleOpenSubClassDataFill={handleOpenSubClassDataFill}
        updateSubClassData={updateSubClassData}
        selectedCell={selectedCell ? selectedCell._id : null}
        isMandatory={isMandatory}
      />
      <SubClassDataFill
        ScopeInfo={ScopeInfo}
        itemId={props.itemId}
        itemName={props.itemName}
        subClassData={subClassData}
        toggleSubClassDataFill={toggleSubClassDataFill}
        setToggleSubClassDataFill={setToggleSubClassDataFill}
        handleToggleSubClassDataFill={handleToggleSubClassDataFill}
        getMatrixCellData={props.getMatrixCellData}
        updateMonthEmissionData={updateMonthEmissionData}
        selectedClassScopeName={selectedClassScopeName}
        updateSubClassSelection={updateSubClassSelection}
        currRowMatrixCellCount={currRowMatrixCellCount}
        selectedCell={selectedCell ? selectedCell._id : null}
        openUpdateMonthEmission={openUpdateMonthEmission}
        isMandatory={isMandatory}
        handleOpenSubClassDataFill={handleOpenSubClassDataFill}
        setIsCrateCellUpdate={props.setIsCrateCellUpdate}
        isCrateCellUpdate={props.isCrateCellUpdate}
      />
      <MissingData
        missingDataInfo={missingDataInfo}
        handleMissingDataDrawer={handleMissingDataDrawer}
        toggleMissingData={toggleMissingData}
      />
      <TableContainer
        component={Paper}
        ref={tableref}
        className="productMainTable_table_container"
      >
        <Table
          className="productMainTable_table_container"
          sx={{ minWidth: "1425px" }}
          // style={{ tableLayout: "fixed" }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <StyledHeadRow>
              <FixedHeadCell>CATEGORY</FixedHeadCell>
              <FixedHeadCell sx={{ width: "200px" }}>SUBSCOPE</FixedHeadCell>
              {months.map((item, i) => {
                return (
                  <StyledTableCell
                    align="center"
                    key={i}
                    className={
                      (props.isOffset[item - 1]
                        ? "color_white bg-color-green border-btm_dc"
                        : "bg_light_greyish border-btm_dc") +
                      " " +
                      (!profile.hasBaseCredit && "p_disabled")
                    }
                  >
                    {item}/{props.year}
                  </StyledTableCell>
                );
              })}
            </StyledHeadRow>
          </TableHead>

          <TableBody>
            {props.matrixItems &&
              props.matrixItems.map((row, index) => (
                <StyledTableRow key={index}>
                  <FixedTableCell
                    align="center"
                    className={index % 2 ? "bg_light_pink" : ""}
                  >
                    <Tooltip
                      title={props.matrixItems[index].class}
                      placement="left-start"
                    >
                      <img
                        src={getServiceIcon(props.matrixItems[index].class)}
                        className="icon_sizing"
                      />
                    </Tooltip>
                  </FixedTableCell>

                  <FixedTableCell
                    className={`${
                      index % 2 ? "bg_light_pink" : ""
                    } log_scope_name_cell d-flex`}
                  >
                    {props.matrixItems[index].scope}
                    <SubscopeTooltip
                      title={
                        <div>
                          <p className="color_black f-27 fw-700">
                            {props.matrixItems[index].scope}
                          </p>
                          <p className="color_57 f-14 fw-600 mt-8">
                            Scope{" "}
                            {
                              subscopesjson.find((obj) => {
                                if (
                                  obj.name ===
                                  props.matrixItems[index].scope.toLowerCase()
                                )
                                  return obj;
                              })?.relatedGHG
                            }
                          </p>
                          <p className="color_31 f-14 fw-500 mt-16 mb-20">
                            {
                              data[props.itemName.toLowerCase()][
                                props.itemName
                              ][props.matrixItems[index].class][
                                props.matrixItems[index].scope
                              ].description
                            }
                          </p>
                        </div>
                      }
                    >
                      <span className="log_scope_right_icon ms-auto">
                        <RightChevronIcon cc={"#000000"} />
                      </span>
                    </SubscopeTooltip>
                  </FixedTableCell>
                  {props.planMonths.map((currMonth, subIndex) => {
                    return currMonth ? (
                      <StyledTableCell align="center" key={subIndex}>
                        <button
                          onClick={() => {
                            //console.log("this is some matrix item data ", props.matrixCellData[index][subIndex + 1])
                            if (!props.isOffset[subIndex]) {
                              if (
                                !props.matrixCellData[index][subIndex + 1] ||
                                (props.matrixCellData[index][subIndex + 1] &&
                                  !props.matrixCellData[index][subIndex + 1]
                                    .isOffset)
                              ) {
                                if (profile.hasBaseCredit && !props.isPaused) {
                                  handleQuickLogDrawerOpen(
                                    props.matrixItems[index],
                                    subIndex,
                                    index
                                  );
                                  // handleScopeDrawer(
                                  //   props.matrixItems[index],
                                  //   subIndex + 1,
                                  //   index,
                                  //   props.matrixCellData[index][subIndex + 1]
                                  // );
                                }
                              }
                            }
                          }}
                          className={
                            "button_style_none " +
                            (props.isOffset[subIndex] && "cursor_auto")
                          }
                        >
                          {props.matrixCellData.length > 0 &&
                          props.matrixCellData[index][subIndex + 1] != 0 ? (
                            <span
                              className={
                                (!profile.hasBaseCredit || props.isPaused) &&
                                "color_57 cursor_auto"
                              }
                            >
                              {Number(
                                props.matrixCellData[index][
                                  subIndex + 1
                                ].emission?.toFixed(2)
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </span>
                          ) : !profile.hasBaseCredit || props.isPaused ? (
                            <DisabledTooltip
                              title={
                                <div
                                  className={
                                    "subtitle_box width_fit bg_soft_red"
                                  }
                                >
                                  <p className={"font_13_600 color_red"}>
                                    Your subscription is paused
                                  </p>
                                </div>
                              }
                            >
                              <i className={"bi bi-plus p_disabled"}></i>
                            </DisabledTooltip>
                          ) : (
                            <i className={"bi bi-plus"}></i>
                          )}
                        </button>
                      </StyledTableCell>
                    ) : (
                      <StyledTableCell key={subIndex} align="center">
                        <button
                          className="button_style_none selectDisabled"
                          disabled={true}
                        >
                          <i
                            className={`bi bi-plus ${
                              index % 2 ? "bg_light_pink" : ""
                            }`}
                          ></i>
                        </button>
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              ))}

            <StyledTableRow>
              <FixedTableCell
                className="border-top_ef bg_light_greyish"
                align="center"
              ></FixedTableCell>
              <FixedTableCell
                className="f-14 fw-600 font-inter border-top_ef bg_light_greyish"
                align="right"
              >
                Total tCO2e
              </FixedTableCell>
              {props.monthWiseEmissionSum.map((currMonthSum, sumIndex) => (
                <StyledTableCell
                  className={
                    "f-14 fw-600 font-inter border-top_ef bg_light_greyish " +
                    (!profile.hasBaseCredit && "color_57 cursor_auto")
                  }
                  align="center"
                  key={sumIndex}
                >
                  {currMonthSum.toFixed(2).toLocaleString()}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className="d-flex justify-content-end mt-30 ">
        <div className="ml-20">
          <i
            className={`pointer bi bi-chevron-left productMainTable_arrow_border`}
            onClick={handleScrollLeft}
          ></i>

          <i
            className={`pointer bi bi-chevron-right ml-10 productMainTable_arrow_border`}
            onClick={handleScrollRight}
          ></i>
        </div>
      </div>

      <LogDrawer
        quickLogDrawer={quickLogDrawer}
        handleQuickLogDrawer={handleQuickLogDrawerClose}
        logId={props.logId}
        itemId={props.itemId}
        selectedScope={selectedScope}
        classList={classList}
        yearList={props.yearList}
        selectedMonth={selectedMonth}
        setSelectedMonth={setSelectedMonth}
        scopeIndex={scopeIndex}
        getMatrixCellData={props.getMatrixCellData}
        logItemName={props.logItemName}
      />
    </div>
  );
}

export default ProductMainTable;
