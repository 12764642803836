//import Icons from "./serviceIcons.json";
import Icons from "./serviceIconsJson.json";
export const getServiceIcon = (service) => {
  if (service && typeof service === "string") {
    return service &&
      Icons[service.toLowerCase()] &&
      Icons[service.toLowerCase()].icon
      ? Icons[service.toLowerCase()].icon
      : "";
  } else return "";
};
