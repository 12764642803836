import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import LabelCards from "./Components/LabelCards";
import TabLabels from "../../../../../Components/TabLabels";
import HorizontalLabel from "../../../../../../../../Assets/components/CarbonNuetrolLabel/HorizontalLabel";
import axios from "../../../../../../../../Api";
import useQuery from "../../../../../../../../Utils/query";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../Utils/Auth";
import DownloadIcon from "../../../../../../../../Assets/icons/DownloadIcon";
import VeritacalLabel from "../../../../../../../../Assets/components/CarbonNuetrolLabel/VeritacalLabel";
import HorizontalLabelPlaceholder from "../../../../../../../../Assets/components/CarbonNuetrolLabel/HorizontalLabelPlaceholder";
import { toJpeg } from "html-to-image";
// import getOffsetStatus from "../../../../../../../../Utils/getOffsetStatus";
import offsetImage from "../../../../../../../../Assets/images/label.png";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import { useNavigate } from "react-router-dom";
import { getServiceIcon } from "../../../../../../../../Utils/itemIcon";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function ProductDetailLabel(props) {
  const [value, setValue] = useState(0);
  const [labelInfo, setLabelInfo] = useState(null);
  const query = useQuery();
  const logId = query.get("logId");
  const { setSessionExpire } = useAuth();
  const horizontalref = useRef();
  const verticalref = useRef();
  const history = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProductLabelInformation = async (id) => {
    await axios({
      url: `/neutral/cn-stats/${id}`,
      method: "GET",
    })
      .then((res) => {
        setLabelInfo(res.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const downloadImage = () => {
    if (horizontalref.current === null) {
      return;
    }
    if (verticalref.current === null) {
      return;
    }

    toJpeg(horizontalref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "label1.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
    toJpeg(verticalref.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        link.download = "label2.png";
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (logId) {
      getProductLabelInformation(logId);
    }
  }, [logId, props.isCrateCellUpdate]);

  return (
    <div className="">
      <div className="d-flex align-items-center">
        <span className="text_capital font_17_70 fw-700">
          carbon neutrality
        </span>
        &nbsp; &nbsp;
        {labelInfo &&
        labelInfo.allCNLabels &&
        labelInfo.allCNLabels.length > 0 ? (
          <span className="green_label no_border">
            <img src={getServiceIcon("offset status")} height="24px" /> No
            offset required
          </span>
        ) : (
          <span className="grey_label no_border">
            <img src={getServiceIcon("offset status")} height="24px" /> Inactive
            Carbon Neutrality
          </span>
        )}
        {/* <p className="green_label ml-10">{getOffsetStatus(log).title}</p> */}
      </div>

      <div className="row mt-20 header_card_blue productTarget_headContainer_height mx-0">
        <div className="productTarget_sm_container col-6 col-md-4 col-lg-3  d-flex flex-column justify-content-center pl-24 ">
          <p className="f-30 fw-700 font-inter color_white">
            {labelInfo?.totalEmissions.toFixed(2).toLocaleString()}
          </p>
          <p className="overline_text color_white mt-2">
            Compensated Emissions
          </p>
        </div>
        <div className="col-6 col-md-4 col-lg-3 d-flex flex-column justify-content-center pl-35">
          <p className="f-30 fw-700 font-inter color_white">
            {labelInfo &&
            labelInfo.allCNLabels &&
            labelInfo.allCNLabels.length > 0
              ? labelInfo.allCNLabels[0].startDate.year
              : "-"}
          </p>
          <p className="overline_text color_white mt-2">Carbon Neutral Since</p>
        </div>
        <div className="col-6 col-md-4 col-lg-3 d-flex flex-column justify-content-center pl-24">
          <p className="f-30 fw-700 font-inter color_white">
            {labelInfo &&
            labelInfo.allCNLabels &&
            labelInfo.allCNLabels.length > 0
              ? labelInfo.allCNLabels[labelInfo.allCNLabels.length - 1].endDate
                  .month +
                "." +
                labelInfo.allCNLabels[labelInfo.allCNLabels.length - 1].endDate
                  .year
              : "-"}
          </p>
          <p className="overline_text color_white mt-2">Label Valid Until</p>
        </div>
        <div className="col-6 col-md-4 col-lg-3 d-flex flex-column justify-content-center pl-24">
          <p className="f-30 fw-700 font-inter color_white">
            {labelInfo?.number_of_projects}
          </p>
          <p className="overline_text color_white mt-2">
            No. of Contribution Projects
          </p>
        </div>
      </div>
      {labelInfo &&
      labelInfo.allCNLabels &&
      labelInfo.allCNLabels.length > 0 ? (
        ""
      ) : (
        <div className="d-flex align-items-center justify-content-between border-ef  border_radius_10 p-4 mb-40 mt-40">
          <div className="p-4 mr-40 ">
            <p className="heading_lg">Make a Contribution 🌳</p>
            <br />

            <p className="subheading_light">
              In a world grappling with the challenges of climate change,
              embracing carbon contribution is not just a responsible choice but
              a powerful opportunity for companies to make a tangible
              difference.
            </p>
            <br />
            <ButtonComp
              status={1}
              onClick={() => history("/dashboard?tab=5&subtab=1")}
            >
              Go to Contribution
            </ButtonComp>
          </div>
          <div>
            <img src={offsetImage} alt="no offset" />
          </div>
        </div>
      )}

      <div className="mt-48">
        <p className="text_capital font_17_70 fw-700">carbon neutral labels</p>
        <div className="container_design_1 mt-32">
          {labelInfo &&
          labelInfo.allCNLabels &&
          labelInfo.allCNLabels.length > 0 ? (
            <div className="p-24 border-btm_ef d-flex align-items-center">
              <HorizontalLabel
                imageRef={horizontalref}
                item={labelInfo.allCNLabels[0].logId?.accountingItemName}
              />
              <div className="ml-24">
                <VeritacalLabel imageRef={verticalref} />
              </div>
            </div>
          ) : (
            <div className="d-flex align-items-center mx-4 mt-4">
              <div>
                <HorizontalLabelPlaceholder />
              </div>
              <div className="ml-24">
                <HorizontalLabelPlaceholder />
              </div>
              <div className="ml-24">
                <HorizontalLabelPlaceholder />
              </div>
            </div>
          )}
          {labelInfo &&
          labelInfo.allCNLabels &&
          labelInfo.allCNLabels.length > 0 ? (
            <div className="p-24 d-flex align-items-center gap_20">
              <ButtonComp
                status={2}
                style={{ padding: "6px 30px" }}
                // className="secondary_button_variant_2 p-60x-20y"
                onClick={downloadImage}
              >
                Carbon Neutral Labels
                <span className="ml-16">
                  <DownloadIcon />
                </span>
              </ButtonComp>
              <ButtonComp
                status={3}
                style={{ padding: "6px 30px" }}
                // className="secondary_button_variant_1 p-60x-20y ml-20"
              >
                Communication Guide
                <span className="ml-16">
                  <DownloadIcon cc={"#000"} />
                </span>
              </ButtonComp>
            </div>
          ) : (
            <div className="p-24 d-flex align-items-center gap_20">
              <div
                className="border_1_ef border_radius_5 d-flex align-items-center"
                style={{ padding: "6.5px 30px" }}
              >
                <p className="font_15_500 line_height_10 color_8a">
                  Carbon Neutral Labels
                </p>
                <span className="ml-16">
                  <DownloadIcon cc={"#8A8A8A"} />
                </span>
              </div>
              <div
                className="border_1_ef border_radius_5 d-flex align-items-center"
                style={{ padding: "6.5px 30px" }}
              >
                <p className="font_15_500 line_height_10 color_8a">
                  Communication Guide
                </p>
                <span className="ml-16">
                  <DownloadIcon cc={"#8A8A8A"} />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-32">
        <p className="text_capital font_17_70 fw-700 pt-4">
          Contribution projects
        </p>
        <Box sx={{ width: "100%" }}>
          <div
            style={{
              borderBottom: "1px solid #ececec",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              className="general_root_tabs"
            >
              <Tab
                label={
                  <TabLabels
                    title="Active"
                    count={labelInfo?.allProjects?.length || 0}
                  />
                }
                {...a11yProps(0)}
              />
              <Tab
                label={<TabLabels title="Historic" count={0} />}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            <LabelCards data={labelInfo?.allProjects || []} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LabelCards data={labelInfo?.allProjects || []} />
            {/* item two */}
          </TabPanel>
        </Box>
      </div>
    </div>
  );
}

export default ProductDetailLabel;
