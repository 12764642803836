import React, { useEffect, useState } from "react";
import "./emissionSources.css";
import axios from "../../../../../Api/index";
import { toast } from "react-hot-toast";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Drawer } from "@mui/material";
import TabLabels from "../TabLabels";
import location from "../../../../../DataJSON/companyLocation.json";
import event from "../../../../../DataJSON/event.json";
import hotel from "../../../../../DataJSON/hotel.json";
import product from "../../../../../DataJSON/product.json";
import service from "../../../../../DataJSON/service.json";
import realEstateA from "../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../DataJSON/building.json";
import agency from "../../../../../DataJSON/agency.json";
import education from "../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../DataJSON/shipping.json";
import skilledTrades from "../../../../../DataJSON/skilledTradesSector.json";

import { getServiceIcon } from "../../../../../Utils/itemIcon";
import ButtonComp from "../../../../../Assets/components/Button";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}
TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function EmissionSources(props) {
  const [value, setValue] = useState(0);
  const [emissionData, setEmissionData] = useState({});
  const [emissionSourcesCount, setEmissionSourcesCount] = useState([]);
  const [emissionKeys, setEmissionKeys] = useState([]);
  const [selectScope, setSelectScope] = useState({});
  const [activateButton, setActivateButton] = useState([]);
  const handleTabChange = (event, newValue) => {
    // let selected = activateButton[value];
    // if (selected) {
    setValue(newValue);
    //  }
  };
  const data = {
    location,
    event,
    hotel,
    product,
    service,
    "real-estate agency": realEstateA,
    "real estate development": realEstateD,
    building,
    agency,
    "educational institution": education,
    shipping,
    "skilled trades company": skilledTrades,
  };

  useEffect(() => {
    if (props.itemName) {
      let item = props.itemName.toLowerCase();
      let itemKey;
      if (item === "real-estate agency") {
        itemKey = "Real-Estate Agency";
      } else
        itemKey = item
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");

      if (data[item] && data[item][itemKey]) {
        let currKeys = Object.keys(data[item][itemKey]);

        let currCount = [];
        let currEmissionData = [];
        let currSelectData = {};
        let currActivateButton = [];
        currKeys.map((name, index) => {
          //  currActivateButton.push(false);
          currCount.push(Object.keys(data[item][itemKey][name]).length);

          let currScopeData = [];
          let currScope = Object.keys(data[item][itemKey][name]);
          let mandatoryCount = 0;

          currScope.map((scope, subIndex) => {
            let temp = data[item][itemKey][name];
            let position = index + 1 + "." + (subIndex + 1);
            currSelectData[position] = temp[scope].isMandatory;

            if (temp[scope].isMandatory) mandatoryCount++;

            currScopeData.push({
              position: position,
              name: scope,
              isMandatory: temp[scope].isMandatory,
              description: temp[scope].description,
            });
          });

          mandatoryCount > 0
            ? currActivateButton.push(true)
            : currActivateButton.push(false);
          currEmissionData.push(currScopeData);
        });
        setActivateButton(currActivateButton);
        setSelectScope(currSelectData);
        setEmissionData(currEmissionData);
        setEmissionSourcesCount(currCount);
        setEmissionKeys(currKeys);
        checkEligible(value);
      }
    }
  }, [props.itemName, props.tabInfo, props.logId]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const checkEligible = (index) => {
    let selected = false;
    let count = 0;
    if (emissionData[index]) {
      emissionData[index].forEach((data) => {
        if (data.isMandatory) count++;
        if (selectScope[data.position]) {
          selected = true;
          return;
        }
      });
      let currActivateButton = [...activateButton];
      if (
        selected ||
        (emissionData &&
          emissionData[index] &&
          count === emissionData[index].length)
      ) {
        currActivateButton[index] = true;
      } else {
        currActivateButton[index] = false;
      }
      setActivateButton(currActivateButton);
    }
  };

  const handleCardClick = (position, index, subIndex) => {
    if (emissionData[index][subIndex].isMandatory) return;
    let currSelectArray = { ...selectScope };
    currSelectArray[position] = !currSelectArray[position];
    setSelectScope(currSelectArray);
  };
  useEffect(() => {
    checkEligible(value);
  }, [selectScope]);

  const handleNext = (event, index, value) => {
    event.preventDefault();
    if (value === emissionKeys.length - 1) {
      let notSelectedIndex =
        activateButton.findIndex((currButton) => currButton == false) === -1;
      if (notSelectedIndex) handleSubmit();
    } else {
      setValue(value + 1);
    }
  };
  const handleSubmit = async () => {
    //  event.preventDefault();
    let sendResponse = [];
    emissionData.forEach((currClass, index) => {
      currClass.forEach((currScope) => {
        let position = currScope.position;
        if (selectScope[position]) {
          sendResponse.push({
            class: emissionKeys[index],
            scope: currScope.name,
          });
        }
      });
    });
    await axios
      .patch("/log/matrix-log", {
        logId: props.logId,
        matrixItems: sendResponse,
      })
      .then((res) => {
        if (res.data.success) {
          toast.success("Log updated");
          setValue(0);
          props.getItemData();
          props.setToggleEmissionDrawer(false);
        }
      })
      .catch((err) => {
        toast.error(err.error);
      });
  };
  return (
    <div>
      <Drawer
        anchor={"right"}
        open={props.toggleEmissionDrawer}
        onClose={() => props.setToggleEmissionDrawer(false)}
        PaperProps={{
          sx: { width: "70%" },
        }}
        BackdropProps={{ style: { backgroundColor: "white", opacity: 0 } }}
      >
        <div className="fluid-container drawer_root">
          <div className="emissionSources_heading_container pb-60 ">
            <div className="align_text_right mt-20 pr-20">
              <i
                className="bi bi-x-lg SideDrawer_close_icon pointer"
                onClick={() => props.setToggleEmissionDrawer(false)}
              ></i>
            </div>
            <div className="pl-35 mt-10 w-60">
              <p className="f-27 fw-700 font-inter">
                Select your Emission Sources
              </p>
              <p className="subheading_light_2 mt-10">
                We have pre-selected the following mandatory and recommended
                sources based on the accounting item you selected. Please select
                the recommended emission sources you know you do emit CO2 in.
              </p>
            </div>
          </div>

          {/* This is tab bar down here */}
          <div className="mt-40 px-4">
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleTabChange}
                  aria-label="basic tabs example"
                  className="general_root_tabs"
                >
                  {emissionKeys.map((name, index) => {
                    return (
                      <Tab
                        key={index}
                        label={
                          <TabLabels
                            title={name}
                            count={emissionSourcesCount[index]}
                          />
                        }
                        {...a11yProps(index)}
                      />
                    );
                  })}
                </Tabs>
              </Box>
              {emissionKeys &&
                emissionKeys.map((data, index) => {
                  return (
                    <TabPanel value={value} index={index} key={index}>
                      <div className="row mt-30 ml-6">
                        {emissionData[index] &&
                          emissionData[index].map((currScope, subIndex) => {
                            return (
                              <div
                                onClick={() => {
                                  handleCardClick(
                                    currScope.position,
                                    index,
                                    subIndex
                                  );
                                }}
                                className={`emissionSources_card col-lg-3 p-16 mr-18 mb-24
                                  ${
                                    selectScope[currScope.position]
                                      ? "card_selection_border"
                                      : ""
                                  } `}
                                key={subIndex + index}
                              >
                                <div className="d-flex justify-content-between">
                                  <div>
                                    <img
                                      src={getServiceIcon(currScope.name)}
                                      className="icon_sizing"
                                    />
                                    {/* <p className="body_text_s_light mt-2">
                                      Scope {currScope.position}
                                    </p> */}
                                  </div>
                                  <div>
                                    <p
                                      className={`${
                                        currScope.isMandatory
                                          ? "orange_label"
                                          : "green_label"
                                      }`}
                                    >
                                      {currScope.isMandatory
                                        ? "Mandatory"
                                        : "Recommended"}
                                    </p>
                                  </div>
                                </div>

                                <div className="mt-20">
                                  <p className="card_heading">
                                    {currScope.name}
                                  </p>

                                  <p className="body_text_s_light mt-10">
                                    {currScope.description}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                      <div className="d-flex flex-column align-items-end mt-60 mr-20 pb-60">
                        <ButtonComp
                          onClick={(event) => {
                            handleNext(event, index, value);
                          }}
                          status={3}
                          // className={
                          //   activateButton[index]
                          //     ? "tertiary_button_active"
                          //     : "tertiary_button_inactive"
                          // }
                          disabled={
                            value == emissionKeys.length - 1 &&
                            activateButton.findIndex(
                              (currButton) => currButton == false
                            ) != -1
                              ? true
                              : false
                          }
                        >
                          {value == emissionKeys.length - 1
                            ? "Submit"
                            : "Next Category"}{" "}
                          &nbsp; <i className="bi bi-arrow-right mt-1"></i>
                        </ButtonComp>
                        {props.itemName === "Shipping" ? (
                          <p className="breadcrumb_text_light_1 mt-20">
                            Please select at least one of the recommended
                            emission sources{" "}
                          </p>
                        ) : null}
                      </div>
                    </TabPanel>
                  );
                })}
            </Box>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default EmissionSources;
