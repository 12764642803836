import React from "react";

function CheckBook(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 20 20"
      >
        <path
          stroke={props.stroke}
          strokeWidth="2"
          d="M15.883 9.984v3.032c0 1.885 0 2.828-.586 3.414-.586.586-1.529.586-3.414.586H5.336a1.953 1.953 0 01-1.953-1.953v0c0-1.08.874-1.954 1.953-1.954h6.547c1.885 0 2.828 0 3.414-.585.586-.586.586-1.529.586-3.415V6.953c0-1.885 0-2.828-.586-3.414-.586-.586-1.529-.586-3.414-.586h-4.5c-1.886 0-2.829 0-3.414.586-.586.586-.586 1.529-.586 3.414v8.11"
        ></path>
        <path
          stroke={props.stroke}
          strokeLinecap="round"
          strokeWidth="2"
          d="M7.29 8.422l.854.855a1 1 0 001.415 0l2.418-2.418"
        ></path>
      </svg>
    </div>
  );
}

export default CheckBook;
