import React from "react";
import AddUsers from "../AddUsers";
import { Avatar } from "@mui/joy";

function ScopeDataList(props) {
  return (
    <>
      {props.scopeWiseData.length > 0 &&
        props.scopeWiseData.map((ScopeData, index) => {
          return (
            <div key={`scope-${index}`} className="mt-30">
              <div>
                <p className="font_14_600 line_height_10 color_7e">
                  Scope {index + 1}
                </p>

                {ScopeData.map((currScope) => {
                  return (
                    <div
                      key={currScope._id + currScope.scope}
                      className="d-flex justify-content-between align-items-center bg_f6 pl-10 pr-10 border_radius_5 mt-10 h-40px"
                    >
                      <p className="font_14_500">{currScope.scope}</p>

                      <div className="d-flex justify-content-end align-items-center">
                        {props.companyUsers
                          .filter(
                            (currUser) =>
                              currUser.assigned &&
                              currUser?.assigned.find((assignedScope) => {
                                return assignedScope.scope === currScope.scope;
                              })
                          )
                          .slice(0, 3)
                          .map((currUser) => {
                            return (
                              <Avatar
                                key={currUser.email}
                                sx={{ height: "30px", width: "30px" }}
                              >
                                {currUser.firstname
                                  ? currUser.firstname[0].toUpperCase()
                                  : ""}
                              </Avatar>
                            );
                          })}

                        <div className="ml-10">
                          <AddUsers
                            addUserToCompany={props.addUserToCompany}
                            scopeDetails={currScope}
                            companyUsers={props.companyUsers}
                            assignUserToScope={props.assignUserToScope}
                            removeUserFromScope={props.removeUserFromScope}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
    </>
  );
}

export default ScopeDataList;
