import React,{useState, useEffect} from 'react'
import './accItemAccordion.css'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import MinusCounter from '../../../../../../../../../Assets/icons/minusCounter'
import PlusCounter from '../../../../../../../../../Assets/icons/plusCounter'

function AccItemAccordion(props) {
    const [quantity, setQuantity] = useState(0);
    const [isAccordionExpanded,setIsAccordionExpanded]=useState(false)
    useEffect(() => {
        if (props.cartDetails) {
          var itemFind = props.cartDetails.find(
            (items) => items.name === props.itemDetails.name
          );
          if (itemFind) {
            setQuantity(itemFind.quantity);
          } else {
            setQuantity(0);
          }
        }
      }, [props.cartDetails]);
    return (
        <div className="accItemAccordion_container">
            <Accordion
            expanded={isAccordionExpanded}
            >
                <AccordionSummary
                    expandIcon={<i 
                        onClick={() => {setIsAccordionExpanded(!isAccordionExpanded)}}
                        className="bi bi-caret-down-fill"></i>}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <p 
                        onClick={() =>{console.log("this is it ")}}
                        className="font_20_600">{props.itemDetails?.name[0].toUpperCase()}{props.itemDetails?.name.slice(1,props.itemDetails?.name.length)}</p>
                        <div className="d-flex justify-content-end align-items-center mr-20">
                            
                            <div
                            onClick={()=> {
                                if(quantity-1>=0)
                                props.handleQuantityChange(props.itemDetails._id,quantity-1)
                            }}
                            >
                            <MinusCounter />
                            </div>

                            <div className="font_14_600 color_009">{quantity}</div>
                            <div
                              onClick={()=> {
                                props.handleQuantityChange(props.itemDetails._id,quantity+1)

                            }}

                            >
                            <PlusCounter/>
                            </div>
                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>

                    <p className="font_16_400 w-50 color_31">Measure, analyse and reduce the emissions of your company {props.name}.</p>

                    <div className="mt-30">
                        <p className="d-flex align-items-center body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            Unlimited Logs
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            Scope 1, 2 & 3
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            GHG Reports
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            Consultant Database
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            Analytics
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            Targets
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            Offset
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            12 Month Access
                        </p>

                        <p className="mt-8 body_text_s">
                            <i className="bi bi-check-lg color_blue mr-14"></i>
                            Carbon Neutral Label

                        </p>

                        <div className="d-flex align-items-center mt-40">
                                <p className="heading_9 d-flex align-items-center">{props.itemDetails.price}€ <span className="body_text_s color_ba">/month</span></p>

                                <p className="body_text_s color_ba ml-60">(billed annually)</p>
                            </div>
                    </div>

                </AccordionDetails>
            </Accordion>


        </div>
    )
}

export default AccItemAccordion