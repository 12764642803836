import React, { useState } from "react";
import "./paymentCardInfo.css";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { CreditCardsJson } from "../../../../../../../../../../../Utils/CommonFunctions";
import { CreditCardsNameJson } from "../../../../../../../../../../../Utils/CommonFunctions";
import ButtonComp from "../../../../../../../../../../../Assets/components/Button";
import { useMapStore } from "../../../../../../../../../../../Utils/CompanyMapStore";
import Sepa from "../../../../../../../../../../../Assets/images/Sepa.png";
function PaymentCardInfo(props) {
  const { selectedPaymentMethod, handleSelectedPaymentMethodChange } =
    useMapStore();
  // const { setSessionExpire } = useAuth()
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  //  const [isEdit, setIsEdit] = useState(false)

  return (
    <div className="paymentCardInfo_container">
      <Accordion expanded={isAccordionExpanded}>
        <AccordionSummary
          expandIcon={""}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="d-flex justify-content-between align-items-center w-100">
            <div
              onClick={() => {
                setIsAccordionExpanded(!isAccordionExpanded);
              }}
              className="d-flex align-items-center"
            >
              <div>
                {props.paymentMethodDetails?.card ? (
                  <img
                    src={
                      CreditCardsJson[props.paymentMethodDetails?.card?.brand]
                    }
                    alt={props.paymentMethodDetails?.card?.brand}
                  />
                ) : props.paymentMethodDetails?.sepa_debit ? (
                  <img src={Sepa} alt="Sepa" />
                ) : null}
              </div>
              <p className="ml-20">Payment</p>
            </div>

            <div className="d-flex align-items-center">
              <p className="font_16_500 color_7e mr-35">
                **** **** ****{" "}
                {props.paymentMethodDetails?.card
                  ? props.paymentMethodDetails?.card.last4
                  : props.paymentMethodDetails?.sepa_debit?.last4}
              </p>

              <div className="d-flex justify-content-end align-items-center">
                <ButtonComp
                  status={3}
                  onClick={() =>
                    handleSelectedPaymentMethodChange(
                      props.paymentMethodDetails
                    )
                  }
                  disabled={
                    selectedPaymentMethod.id === props.paymentMethodDetails.id
                  }
                >
                  Select
                </ButtonComp>
              </div>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="mt-32 pl-32 pr-30">
            {props.paymentMethodDetails.type === "card" ? (
              <>
                <img
                  src={CreditCardsJson[props.paymentMethodDetails.card.brand]}
                  alt={props.paymentMethodDetails.card.brand}
                />
                <p className="mt-12 font_14_500">
                  {CreditCardsNameJson[props.paymentMethodDetails.card.brand]}:
                  <span className="ml-8">
                    {props.paymentMethodDetails.card.last4}
                  </span>
                </p>
                <p className="font_14_500 mt-1">
                  Exp. Date:
                  <span className="ml-8">
                    {props.paymentMethodDetails.card.exp_month}/
                    {props.paymentMethodDetails.card.exp_year}
                  </span>
                </p>
                {/* <p>Name: Maxine Murphy</p> */}
              </>
            ) : props.paymentMethodDetails.type === "sepa_debit" ? (
              <>
                <p className="font_14_500 mt-1">
                  {props.paymentMethodDetails.type}:
                  <span className="ml-8">
                    {props.paymentMethodDetails.sepa_debit.last4}
                  </span>
                </p>
                <p className="font_14_500 mt-1">
                  Bank :
                  <span className="ml-8">
                    {props.paymentMethodDetails.sepa_debit.country}
                    {props.paymentMethodDetails.sepa_debit.bank_code}
                  </span>
                </p>
                <p className="font_14_500 mt-1">
                  Name :
                  <span className="ml-8">
                    {props.paymentMethodDetails.billing_details.name}
                  </span>
                </p>
              </>
            ) : null}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default PaymentCardInfo;
