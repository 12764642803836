import React, { useRef, useState, useEffect } from "react";
import { Box, CircularProgress, Modal } from "@mui/material";
import CompnayBGCropper from "../../../CommonComponents/CompanyBGCropper/CompnayBGCropper";
import CameraAddIcon from "../../../../Assets/icons/CameraAddIcon";
import {
  dataURItoBlob,
  getRadianAngle,
  handlePicUpload,
} from "./CommonFunctions";
import { useAuth } from "../../../../Utils/Auth";
import FileSizeExceedModal from "./FileSizeExceedModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 461,
  maxWidth: "90%",
  bgcolor: "white",
  padding: "20px",
  borderRadius: "10px",
};

const CompanyLogoUpload = ({ avatar, setAvatar }) => {
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputFileRef = useRef();
  const [currentPage, setCurrentPage] = useState("choose-img");
  const { setSessionExpire } = useAuth();
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const handlesetWarningModalOpen = () => setWarningModalOpen(true);
  const handlesetWarningModalClose = () => setWarningModalOpen(false);
  const onImageSelected = (selectedImg) => {
    setImage(selectedImg);
    setCurrentPage("crop-img");
  };

  const updateBg = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = function () {
        onImageSelected(reader.result);
      };
      handlesetWarningModalClose();
    }
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.src = url;
    });

  const onCropDone = async (pixelCrop, rotation = 0) => {
    const img = await createImage(image);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const maxSize = Math.max(img.width, img.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    canvas.width = safeArea;
    canvas.height = safeArea;
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);
    ctx.drawImage(
      img,
      safeArea / 2 - img.width * 0.5,
      safeArea / 2 - img.height * 0.5
    );
    const data = ctx.getImageData(0, 0, safeArea, safeArea);
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + img.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + img.height * 0.5 - pixelCrop.y)
    );

    const dataURL = canvas.toDataURL("image/jpeg");
    const blobImage = dataURItoBlob(dataURL);
    setCurrentPage("img-cropped");
    const extension = blobImage.type.split("/")[1];
    const imageFile = new File([blobImage], `my-image.${extension}`, {
      type: blobImage.type,
    });
    if (imageFile.size > 2 * 1024 * 1024) {
      onCropCancel();
      handlesetWarningModalOpen();
      return;
    }
    handleClose();

    setIsLogoUploading(true);
    const location = await handlePicUpload(imageFile, setSessionExpire);
    setIsLogoUploading(false);
    if (location) {
      setAvatar(location);
      setCurrentPage("choose-img");
    }
  };

  // Handle Cancel Button Click
  const onCropCancel = () => {
    setCurrentPage("choose-img");
    setImage("");
    handleClose();
  };

  useEffect(() => {
    if (currentPage === "crop-img") {
      handleOpen();
    }
  }, [currentPage]);

  return (
    <>
      {isLogoUploading ? (
        <div
          className="share_trans_company_logo "
          style={{
            height: 92,
            width: 92,
            marginTop: "-80px",
            border: "1px solid #fff",
          }}
        >
          <div className="share_trans_camera_cover">
            <CircularProgress />
          </div>
        </div>
      ) : avatar ? (
        <div
          className="share_trans_company_logo "
          style={{
            height: 92,
            width: 92,
            marginTop: "-80px",
          }}
        >
          <img
            className="share_trans_company_bg"
            src={avatar}
            alt="company"
            style={{
              objectFit: "cover",
              height: 92,
              width: 92,
              border: "1px solid #fff",
            }}
          />
          <div
            className="share_trans_camera_cover share_trans_camera_absolute1 pointer"
            onClick={() => inputFileRef.current.click()}
          >
            <CameraAddIcon />
          </div>
        </div>
      ) : (
        <div
          className="share_trans_company_logo "
          style={{
            height: 92,
            width: 92,
            marginTop: "-80px",
            border: "1px solid #fff",
          }}
        >
          <div
            className="share_trans_camera_cover pointer"
            onClick={() => inputFileRef.current.click()}
          >
            <CameraAddIcon />
          </div>
        </div>
      )}
      <input
        type="file"
        accept="image/*"
        hidden
        ref={inputFileRef}
        onChange={updateBg}
      />

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CompnayBGCropper
            image={image}
            onCropDone={onCropDone}
            onCropCancel={onCropCancel}
            ratio={1 / 1}
            infoStr={"Edit Profile Image"}
          />
        </Box>
      </Modal>
      <FileSizeExceedModal
        open={warningModalOpen}
        handleClose={handlesetWarningModalClose}
        handleFunction={() => inputFileRef.current.click()}
      />
    </>
  );
};

export default CompanyLogoUpload;
