import React from "react";
import "./index.css";
import WhilteEarthLargeIcon from "../../icons/WhilteEarthLargeIcon";

const HorizontalLabelPlaceholder = () => {
  return (
    <div className="horizontal_label_root label_placeholder">
      <div className="horizontal_label_left">
        <WhilteEarthLargeIcon />
      </div>
      <div className="horizontal_label_right">
        <p className="carlabel_extrabold">Carbon Neutral</p>
        <p className="carlabel_semibold">Product</p>
        <p className="mt-6 carlabel_bold_italic">by compensation</p>
        <hr className="carlabel_hr" />
        <p className="carlabel_normal_italic">
          <a href="/" target="_blank" rel="noopener noreferrer">
            today.green/00000-000-0000
          </a>
        </p>
      </div>
    </div>
  );
};

export default HorizontalLabelPlaceholder;
