import React, { useEffect, useState } from "react";
import format from "date-fns/format";
import startOfWeek from "date-fns/startOfWeek";
import addDays from "date-fns/addDays";
import addMonths from "date-fns/addMonths";
//import subMonths from "date-fns/esm/subMonths";
import startOfMonth from "date-fns/startOfMonth";
import isSameMonth from "date-fns/isSameMonth";
import { isAfter, isBefore, isSameDay, isSameYear, sub, toDate } from "date-fns";
import "./customDatePicker.css";
const CustomDatePicker = (props) => {
  const [calenderCells, setCalenderCells] = useState([]);
  const renderHeader = () => {
    // const dateFormat = "yyyy";

    return (
      <div className="d-flex justify-content-center align-items-center">
        <p>
          <i
            onClick={() => {
              props.setYear(props.year - 1);
            }}
            className="bi bi-chevron-left f-10 p-1 border-ef border_radius_5"
          ></i>
        </p>
        <p className="font_13_500 ml-10 mr-10">{props.year}</p>
        <p>
          <i
            onClick={() => {
              props.setYear(props.year + 1);
            }}
            className="bi bi-chevron-right f-10 p-1 border-ef border_radius_5"
          ></i>
        </p>
      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "d";
    const days = [];

    let startDate = startOfWeek(props.startDate);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return "";
  };

  const renderCells = () => {
    const monthStart = startOfMonth(props.startDate);
    const currEndMonth = startOfMonth(props.endDate);
    const endDate = toDate(new Date(props.year, 11, 1));

    const dateFormat = "MMM"; //
    const rows = [];

    let days = [];
    let day = toDate(new Date(props.year, 0, 1));
    let formattedDate = "";
    while (day <= endDate) {
      for (let i = 0; i < 3; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;

        days.push(
          <div
            className={`col-4  d-flex ${i==1 ? "justify-content-center":i==0 ? "justify-content-start" : "justify-content-end"} align-items-center h-31
            ${
              (day.getMonth() + 1) % 3 === 0
                ? "customDatePicker_semicircle_right"
                : ""
            }
            ${
              day.getMonth() % 3 === 0 ? "customDatePicker_semicircle_left" : ""
            }

            ${
              isSameMonth(monthStart, currEndMonth) &&
              isSameYear(monthStart, currEndMonth)
                ? ""
                : isAfter(day, monthStart) &&
                  isBefore(day, currEndMonth) &&
                  !isSameDay(currEndMonth, monthStart)
                ? "bg-e9"
                : day.getMonth() === monthStart.getMonth() &&
                  day.getFullYear() === monthStart.getFullYear() &&
                  (day.getMonth() + 1) % 3 != 0
                ? "customDatePicker_hightLight_right"
                : day.getMonth() === currEndMonth.getMonth() &&
                  day.getFullYear() === currEndMonth.getFullYear() &&
                  day.getMonth() % 3 != 0
                ? "customDatePicker_hightLight_left"
                : ""
            }`}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <span
              className={`h-100 pointer customDatePicker_semicircle font_12_600 pt-9 pb-8 pr-16 pl-16 color_main_blue ${
                (isSameMonth(day, monthStart) && isSameYear(day, monthStart)) ||
                (isSameMonth(day, currEndMonth) &&
                  isSameYear(day, currEndMonth))
                  ? "bg_main_blue color_white"
                  : ""
              }`}
            >
              {formattedDate}
            </span>
          </div>
        );
        day = addMonths(day, 1);
      }
      rows.push(
        <div className="row mt-12 mb-12 customDatePicker_semicircle" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    //setCalenderCells(rows)
    setCalenderCells(
      <div className="customDatePicker_calender_row">{rows}</div>
    );
  };
  useEffect(() => {
    if (props.timeFrameType == 1) {
       let lastMonthDate= sub(new Date(),{months:1})

      props.setYear(lastMonthDate.getFullYear())
      props.handleStartDate(lastMonthDate);
      props.handleEndDate(lastMonthDate);

    } else if (props.timeFrameType === 2) {
      let selectedMonth = new Date().getMonth();
      let currStartMonth=0;
      let currEndMonth=0;
      if (selectedMonth >= 0 && selectedMonth <= 2) {
        currStartMonth=0
        currEndMonth=2;
      } else if (selectedMonth >= 3 && selectedMonth <= 5) {
        currStartMonth=3
        currEndMonth=5
      } else if (selectedMonth >= 6 && selectedMonth <= 8) {
        currStartMonth=6
        currEndMonth=8
      } else if (selectedMonth >= 9 && selectedMonth <= 11) {
        currStartMonth=9
        currEndMonth=11
      }
      let currStartDate=
      sub(toDate(new Date(props.year, currStartMonth, 1)),{months:3})

      let currEndDate=
      sub(toDate(new Date(props.year,currEndMonth, 1)),{months:3})

      props.setYear(currStartDate.getFullYear())
      props.handleStartDate(
        currStartDate
        );
      props.handleEndDate(
        currEndDate
        );
      return;
    } else if (props.timeFrameType === 3) {

      props.setYear(new Date().getFullYear()-1)

      let lastYear= new Date().getFullYear()-1

      props.handleStartDate(
        toDate(new Date(lastYear, 0, 1))
        );
      props.handleEndDate(
        toDate(new Date(lastYear, 11, 1))
        );
        
    }
  }, [props.timeFrameType]);

  useEffect(() => {
    renderCells();
    renderHeader()
  }, [props.startDate, props.endDate, props.year]);

  const onDateClick = (day) => {
    if (!props.isEditable) return;
    if (props.timeFrameType == 1 || props.timeFrameType == 3) return;
    if (props.timeFrameType === 2) {
      let selectedMonth = day.getMonth();
      if (selectedMonth >= 0 && selectedMonth <= 2) {
        props.handleStartDate(toDate(new Date(props.year, 0, 1)));
        props.handleEndDate(toDate(new Date(props.year, 2, 1)));
      } else if (selectedMonth >= 3 && selectedMonth <= 5) {
        props.handleStartDate(toDate(new Date(props.year, 3, 1)));
        props.handleEndDate(toDate(new Date(props.year, 5, 1)));
      } else if (selectedMonth >= 6 && selectedMonth <= 8) {
        props.handleStartDate(toDate(new Date(props.year, 6, 1)));
        props.handleEndDate(toDate(new Date(props.year, 8, 1)));
      } else if (selectedMonth >= 9 && selectedMonth <= 11) {
        props.handleStartDate(toDate(new Date(props.year, 9, 1)));
        props.handleEndDate(toDate(new Date(props.year, 11, 1)));
      }
      return;
    }
    //   console.log("this are months ",props.startDate.getMonth(),day.getMonth())
    //  console.log("this are years to compare ",props.startDate.getFullYear(),day.getFullYear())
    if (isSameDay(day, props.endDate)) {
      props.handleStartDate(day);
      props.handleEndDate(day);
    }
    if (
      (day.getMonth() >= props.startDate.getMonth() &&
        day.getFullYear() == props.startDate.getFullYear()) ||
      day.getFullYear() > props.startDate.getFullYear()
    ) {
      props.handleEndDate(day);
    } else {
      props.handleStartDate(day);
      props.handleEndDate(day);
    }
  };



  return (
    <div className="mt-6">
      {renderHeader()}
      {renderDays()}
      <div className="">{calenderCells}</div>

    </div>
  );
};

export default CustomDatePicker;
