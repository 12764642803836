import React from "react";
import "./index.css";

const Loader = () => {
  return (
    <div>
      <div className="overlay" />
      <div className="loader-container">
        <div className="loader" />
      </div>
    </div>
  );
};

export default Loader;
