import React from "react";

const MinusIconWithoutBox = ({cc}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="3"
      fill="none"
      viewBox="0 0 19 3"
    >
      <path
        stroke={cc || "#000"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M1.055 1.5h16"
      ></path>
    </svg>
  );
};

export default MinusIconWithoutBox;
