import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../../../Utils/TableComp";

const ScopeTable = ({
  scope,
  tableData,
  isComparison,
  compTableData,
  totalEmissionSum,
}) => {
  return (
    <div className="analytics_table_container">
      <TableContainer
        component={Paper}
        sx={{ minWidth: 640, boxShadow: "none" }}
        className="mt-16"
      >
        <Table
          sx={{
            minWidth: 640,
            borderRadius: "10px",
            border: "0",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <StyledHeadRow>
              <HeadCell
                sx={{
                  borderLeft: "0",
                  borderBottom: "0",
                  width: "15%",
                  padding:"10px !important"
                }}
                component="th"
                className="text-uppercase"
              >
                {scope}
              </HeadCell>
              <HeadCell
                sx={{
                  width: "20%",
                  padding:"10px !important"
                }}
                component="th"
              >
                EMISSIONS <span className="text_lowerCase">(t</span>
                <span className="text-uppercase">CO₂</span>
                <span className="text_lowerCase">e)</span>
              </HeadCell>
              <HeadCell
                component="th"
                className="text-uppercase"
                sx={{ width: "20%",padding:"10px !important" }}
              >
                Emission Share (%)
              </HeadCell>
              {isComparison && (
                <HeadCell
                  component="th"
                  // className="text-uppercase"
                  sx={{ width: "25%",padding:"10px !important" }}
                >
                  <span className="text-uppercase">Comparison Period</span>{" "}
                  <span className="text_lowerCase">(t</span>
                  <span className="text-uppercase">CO₂</span>
                  <span className="text_lowerCase">e)</span>
                </HeadCell>
              )}
              {isComparison && (
                <HeadCell
                  component="th"
                  // className="text-uppercase"
                  sx={{ width: "20%",padding:"10px !important" }}
                >
                  <span className="text-uppercase">Change (%)</span>
                </HeadCell>
              )}
            </StyledHeadRow>
          </TableHead>

          <TableBody>
            {tableData[scope].length > 0 &&
              tableData[scope].map((data, index) => (
                <StyledTableRow
                  key={data._id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      borderBottom: 0,
                    },
                  }}
                >
                  <StyledTableCell

                    sx={{ borderLeft: "0",padding:"10px !important" }}
                    component="th"
                    textleft={true}
                    className="text_overflow-1 align_text_left text_capital_f"
                  >
                    {data.subscope}
                  </StyledTableCell>

                  <StyledTableCell
                    textleft={true}
                    sx={{ borderRight: "0",padding:"10px !important"  }}
                    className="text_capital_f"
                  >
                    {data.emission}
                  </StyledTableCell>

                  <StyledTableCell
                    textleft={true}
                    sx={{ borderRight: "0",padding:"10px !important"  }}
                    className="text_capital_f"
                  >
                    {((data.emission / totalEmissionSum) * 100).toFixed(2).toLocaleString()}%
                  </StyledTableCell>
                  {isComparison && (
                    <StyledTableCell
                      textleft={true}
                      sx={{ borderRight: "0",padding:"10px !important"  }}
                      className="text_capital_f"
                    >
                      {compTableData[scope]
                        ? compTableData[scope][index]?.emission
                        : ""}
                    </StyledTableCell>
                  )}

                  {isComparison && (
                    <StyledTableCell
                      textleft={true}
                      sx={{ borderRight: "0",padding:"10px !important"  }}
                      className="text_capital_f"
                    >
                      {compTableData[scope] && (
                        <span
                          className={
                            compTableData[scope][index]?.emission -
                              data.emission >
                            0
                              ? "color_orange"
                              : "color_green"
                          }
                        >
                          {compTableData[scope][index]?.emission -
                            data.emission >
                          0
                            ? "+"
                            : "-"}
                          {(
                            (Math.abs(
                              compTableData[scope][index]?.emission -
                                data.emission
                            ) /
                              data.emission) *
                            100
                          ).toFixed(2).toLocaleString()}
                          %
                        </span>
                      )}
                    </StyledTableCell>
                  )}
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ScopeTable;
