import React, { useState, useEffect } from "react";
import useQuery from "../../../../../../../Utils/query";
import { FormatListBulleted } from "@mui/icons-material";
import TargetHeaderCard from "../TargetHeaderCard";
import LeftChevronIcon from "../../../../../../../Assets/icons/LeftChevronIcon";
import { useNavigate } from "react-router-dom";
// import SubScopeChart from "../Chart/SubScopeChart";
import Axios from "../../../../../../../Api";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCellWithoutBorder,
  StyledHeadRow,
} from "../../../../../../../Utils/TableComp";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../Utils/Auth";
import ScopeRow from "./ScopeRow";
import SubScopeDrawer from "./DrawerTarget/SubScopeDrawer";
import { localNumber } from "../../../../../../../Utils/CommonFunctions";
import LinearProgressBar from "../../../../../../../Assets/components/LinearProgrssBar/LinearProgressBar";
import SubClassChart from "../Chart/SubClassChart";

const TargetStep2 = ({
  targetData,
  handleTaskDrawerOpen,
  setstep,
  getYtd,
  getTarget,
  getTasks,
  getytdPerc,
  target2HeaderData,
  allTasks,
}) => {
  const { setSessionExpire } = useAuth();
  const [accItem, setaccItem] = useState();
  const [plannedReduction, setPlannedRedection] = useState(0);
  const query = useQuery();
  const itemId = query.get("accItem");
  const scopeNo = query.get("scope");
  const history = useNavigate();
  const [chartData, setchartData] = useState([]);

  const [subScopeTableData, setSubScopeTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [scopeTargetData, setScopeTargetData] = useState(null);
  const [selectedScope, setSelectedScope] = useState();
  const [scopeTargets, setScopeTargets] = useState(null);
  const [EditTargetData, setEditTargetData] = useState({
    isEdit: false,
    subClassHead: "",
    subClass: "",
    subscope: "",
    reductionValue: 0,
    subClassEmision: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setEditTargetData({
      isEdit: false,
      subClassHead: "",
      subClass: "",
      subscope: "",
      reductionValue: 0,
      subClassEmision: "",
      isCreateTask: false,
    });
    setOpen(false);
  };

  const getScopeInfo = async () => {
    await Axios({
      url: `/target/scope-table/${itemId}/${scopeNo}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setSubScopeTableData(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (itemId) {
      if (targetData) {
        let currIndex = targetData.targetScopes.findIndex(
          (item) => item._id === itemId
        );
        const scopeTargetObject = targetData.accScopes.find(
          (data) => data.id === itemId
        );
        setPlannedRedection(0);
        if (scopeTargetObject) {
          if (Number(scopeNo) === 1) {
            setPlannedRedection(
              Number(scopeTargetObject.scope1BaseEmission) -
                Number(scopeTargetObject.scope1TargetEmission)
            );
          } else if (Number(scopeNo) === 2) {
            setPlannedRedection(
              Number(scopeTargetObject.scope2BaseEmission) -
                Number(scopeTargetObject.scope2TargetEmission)
            );
          } else {
            setPlannedRedection(
              Number(scopeTargetObject.scope3BaseEmission) -
                Number(scopeTargetObject.scope3TargetEmission)
            );
          }
        }
        if (scopeTargetObject.subScopesTargets) {
          if (
            Number(scopeNo) === 1 &&
            scopeTargetObject.subScopesTargets.scope1
          ) {
            setScopeTargets(scopeTargetObject.subScopesTargets.scope1);
          } else if (
            Number(scopeNo) === 2 &&
            scopeTargetObject.subScopesTargets.scope2
          ) {
            setScopeTargets(scopeTargetObject.subScopesTargets.scope2);
          } else if (
            Number(scopeNo) === 3 &&
            scopeTargetObject.subScopesTargets.scope3
          ) {
            setScopeTargets(scopeTargetObject.subScopesTargets.scope3);
          }
        }
        setaccItem(targetData.targetScopes[currIndex]);

        let chartTemp = [];
        targetData.accItems.map((item) => {
          let Obj = {
            year: item.year,
            scope:
              scopeNo && scopeNo === "1"
                ? item.accItems[currIndex].scope1Emission
                : scopeNo && scopeNo === "2"
                ? item.accItems[currIndex].scope2Emission
                : scopeNo && scopeNo === "3"
                ? item.accItems[currIndex].scope3Emission
                : 0,
            // scope2: item.accItems[currIndex].scope2Emission,
            // scope3: item.accItems[currIndex].scope3Emission,
            // total:
            //   item.accItems[currIndex].scope1Emission +
            //   item.accItems[currIndex].scope2Emission +
            //   item.accItems[currIndex].scope3Emission,
          };
          chartTemp.push(Obj);
        });
        setchartData(chartTemp);
      }
    }
  }, [itemId, targetData]);

  useEffect(() => {
    // if (itemId) {
    getScopeInfo();
    // }
    // return () => {
    //   console.log("component unmounted");
    // };
  }, [itemId]);

  useEffect(() => {
    const scroll = () => window.scrollTo(0, 0);
    return () => scroll();
  }, []);

  return (
    <div className="row_20_gap py-20">
      <div className="between">
        <div className="d-flex align-items-center gap_10">
          <button
            className="h-35px w-35px center bg_green border-none border_radius_5"
            onClick={() => {
              setstep(0);
              history(`/dashboard?tab=5&subtab=0&step=1&accItem=${itemId}`);
            }}
          >
            <LeftChevronIcon cc={"#fff"} />
          </button>
          <div className="h-40px w-50px bg_soft_blue_bc border_radius_5 center">
            <p className="font_24_700 text-white">S{scopeNo}</p>
          </div>
          <div className="container_br_5_p_10_8 h-40px center">
            <p className="offsetProjectTitle line_height_24">
              Scope {scopeNo} Target
            </p>
          </div>
          <div className="container_br_5_p_10_8 h-40px center">
            <p className="offsetProjectTitle line_height_24">
              {accItem?.itemDetails.name}
            </p>
          </div>
        </div>
        <div className="d-flex align-items-center gap_30">
          <div
            className="w-40px h-40px bg_soft_green border_radius_5 center pointer position-relative"
            onClick={handleTaskDrawerOpen}
          >
            <FormatListBulleted htmlColor="#00915B" />
          </div>
        </div>
      </div>
      <hr className="mt-30 mb20" />

      <div className="row">
        <div className="col-lg-3 col-md-3 col-6">
          <TargetHeaderCard
            title={"Baseline"}
            percent={target2HeaderData?.baseline}
            papercent={target2HeaderData?.baseLineYear}
            footerValue={
              <span>
                Baseline emissions for {target2HeaderData?.baseLineYear}
              </span>
            }
            isBaseLine={true}
          />
        </div>
        <div className="col-lg-3 col-md-3 col-6">
          <TargetHeaderCard
            title={"Target Emissions"}
            percent={target2HeaderData?.targetEmission}
            papercent={new Date().getFullYear()}
            isBaseLine={true}
            footerValue={
              <span>
                <span className="color_green">
                  {localNumber(target2HeaderData?.currentEmission)} tCO2e
                </span>{" "}
                <span>Until now</span>
              </span>
            }
          />
        </div>
        <div className="col-lg-3 col-md-3 col-6">
          <TargetHeaderCard
            title={"Reduced YTD"}
            percent={target2HeaderData?.reducedYtd}
            papercent={`${String(target2HeaderData?.baseLineYear).slice(
              -2
            )}/${String(new Date().getFullYear()).slice(-2)}`}
            isBaseLine={true}
            footerValue={
              localNumber(
                getytdPerc(
                  target2HeaderData?.targetEmission,
                  accItem?.currentEmission
                )
              ) + "% of yearly target reduction"
            }
          />
        </div>
        <div className="col-lg-3 col-md-3 col-6">
          <TargetHeaderCard
            title={"Planned Reduction"}
            percent={"-" + localNumber(plannedReduction)}
            papercent={targetData.targetYear}
            isBaseLine={true}
            tonsValue={-3000}
            footerValue={String(
              localNumber(
                100 -
                  getytdPerc(
                    target2HeaderData?.targetEmission,
                    accItem?.currentEmission
                  )
              ) + "% left to meet yearly target"
            )}
          />
        </div>
      </div>
      <div className="container_br_10_p_20 mt-20">
        <SubClassChart data={chartData} scopeNo={scopeNo} />
      </div>
      <div className="mt-20 container_br_10_p_20 h-70px d-flex align-items-center">
        <p className="font_15_700 color_57">CO2e Budget</p>
        <LinearProgressBar
          percent={
            (target2HeaderData?.currentEmission * 100) /
            target2HeaderData?.targetEmission
          }
          bg={
            (target2HeaderData?.currentEmission * 100) /
              target2HeaderData?.targetEmission >
            100
              ? "#f45555"
              : "#CDF1E3"
          }
          barClassName={"flex-1 ml-40"}
        />

        <p className="font_14_700 color_7e ml-52">
          {localNumber(target2HeaderData?.currentEmission)}/
          {localNumber(target2HeaderData?.targetEmission)}
        </p>
        <span className="font_10_700 line_height_10 color_7e ml-6 pt-2">
          tCO2e
        </span>
      </div>

      <TableContainer
        component={Paper}
        sx={{
          minWidth: 640,
          borderRadius: "9px",
          boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.05)",
        }}
        className="mt-30 mb-20"
      >
        <Table aria-label="collapsible table">
          <TableHead>
            <StyledHeadRow>
              <HeadCellWithoutBorder>Subscope</HeadCellWithoutBorder>
              <HeadCellWithoutBorder>TASKS</HeadCellWithoutBorder>
              <HeadCellWithoutBorder>BASELINE</HeadCellWithoutBorder>
              <HeadCellWithoutBorder>SUBSCOPE TARGET</HeadCellWithoutBorder>
              <HeadCellWithoutBorder>REDUCED YTD</HeadCellWithoutBorder>
              <HeadCellWithoutBorder />
            </StyledHeadRow>
          </TableHead>
          <TableBody>
            {subScopeTableData.length > 0 &&
              subScopeTableData.map((data, i) => (
                <ScopeRow
                  key={i}
                  data={data}
                  handleOpen={handleOpen}
                  scopeTargetData={scopeTargetData}
                  setScopeTargetData={setScopeTargetData}
                  selectedScope={selectedScope}
                  setSelectedScope={setSelectedScope}
                  scopeTargets={scopeTargets}
                  setScopeTargets={setScopeTargets}
                  allTasks={allTasks}
                  getTarget={getTarget}
                  setEditTargetData={setEditTargetData}
                  getYtd={getYtd}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      <SubScopeDrawer
        open={open}
        handleClose={handleClose}
        scopeTargetData={scopeTargetData}
        setScopeTargetData={setScopeTargetData}
        targetData={targetData}
        selectedScope={selectedScope}
        getTarget={getTarget}
        getTasks={getTasks}
        scopeTargets={scopeTargets}
        setScopeTargets={setScopeTargets}
        EditTargetData={EditTargetData}
        setEditTargetData={setEditTargetData}
      />
    </div>
  );
};

export default TargetStep2;
