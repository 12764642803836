import React from "react";
import "./index.css";
// import AddBoxIcon from "@mui/icons-material/AddBox";
// import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import { getServiceIcon } from "../../../../Utils/itemIcon";
import MinusIconWithoutBox from "../../../../Assets/icons/MinusIconWithoutBox";
import PlusIcon from "../../../../Assets/icons/PlusIcon";

export default function CartItem({ item, handleIncrease }) {
  return (
    <div className="d-flex mx-0 my-4 cart_item_root align-items-center">
      <div className="col cart_item_root_icon fit-w">
        <img src={getServiceIcon(item.name)} alt={item.name} />
      </div>
      <div className="col-10 px-2">
        <div className="d-flex justify-content-between mb-16">
          <p className="fit-w card_heading">{item.name}</p>
          <p className="fit-w card_heading grey-03">
            {Number(item.price).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
            €/mo
          </p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <p className="fit-w helper_text">billed yearly</p>
          <div
            className={`d-flex align-items-center cart_item_details ${
              item.name === "Base Subscription" ? "disable_icons" : ""
            }`}
          >
            <div
              className="grey_box"
              onClick={() => {
                if (item.name !== "Base Subscription")
                  handleIncrease(item.itemId, item.quantity - 1);
              }}
            >
              <MinusIconWithoutBox />
            </div>
            {/* <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-dash-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm2.5 7.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1z" />
              </svg>
            </span> */}
            <span className="card_heading grey-03 mx-3">{item.quantity}</span>
            <div
              className="grey_box"
              onClick={() => {
                if (item.name !== "Base Subscription")
                  handleIncrease(item.itemId, item.quantity + 1);
              }}
            >
              <PlusIcon />
            </div>
            {/* <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus-square-fill"
                viewBox="0 0 16 16"
              >
                <path d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm6.5 4.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3a.5.5 0 0 1 1 0z" />
              </svg>
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
}
