import React, { useState } from "react";
import {
  Add,
  Close,
  HelpOutlined,
  KeyboardBackspaceOutlined,
} from "@mui/icons-material";
import ButtonComp from "../../../../Assets/components/Button";
import DisclosureImageUpload from "./DisclosureImageUpload";
import Axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
import { useEffect } from "react";
import { getDateString, monthNames } from "../../../../Utils/CommonFunctions";
import { handleSaveData } from "./CommonFunctions";

const DisClosureModal = ({ handleClose, companyData, getCompanyData }) => {
  const { setSessionExpire } = useAuth();
  const [disclosureImg, setDisclosureImg] = useState("");
  const [esgReports, setEsgReports] = useState([]);
  const [ghgReports, setGhgReports] = useState([]);
  const [selectedEsgReport, setSelectedEsgReport] = useState(null);
  const [selectedGhgReport, setSelectedGhgReport] = useState(null);
  const [step, setStep] = useState(0);

  const getEsgReports = async () => {
    await Axios({
      url: `/share/esg/all`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setEsgReports(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getGhgReports = async () => {
    await Axios({
      url: `/ghg/all`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setGhgReports(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getEsgReports();
    getGhgReports();
  }, []);

  const handleChange = () => {
    const data = {
      disclosureImg,
    };
    if (selectedEsgReport) {
      data.esgReport = selectedEsgReport._id;
    }
    if (selectedGhgReport) {
      data.ghgReport = selectedGhgReport._id;
    }
    handleSaveData(data, getCompanyData, handleClose, setSessionExpire);
  };

  useEffect(() => {
    if (companyData) {
      setDisclosureImg(companyData.disclosureImg);
      setSelectedEsgReport(companyData.esgReport);
      setSelectedGhgReport(companyData.ghgReport);
    }
  }, [companyData]);

  return (
    <div className="row_20_gap">
      {step === 0 ? (
        <>
          <div className="d-flex align-items-center justify-content-between">
            <p className="font_16_500">
              Disclosure
              <HelpOutlined
                htmlColor="#7E8999"
                fontSize="small"
                className="ml-10"
                sx={{ height: 14, width: 14 }}
              />
            </p>
            <Close className="pointer" onClick={handleClose} />
          </div>
          <div className="row mt-20">
            <div className="col-7">
              <div>
                <p className="font_12_600 color_7e">ESG Report</p>
                {selectedEsgReport ? (
                  <div className="d-flex mt-10" style={{ gap: 10 }}>
                    <div className="esg_report_box_share flex-1 d-flex align-items-center justify-content-between">
                      <p className="font_14_500">{selectedEsgReport.name}</p>
                      <div className="share_timeframe_box">
                        <p className="font_12_500">
                          {selectedEsgReport.timeFrame}
                        </p>
                      </div>
                    </div>
                    <div
                      className="share_trans_camera_cover pointer"
                      onClick={() => setSelectedEsgReport(null)}
                    >
                      <Close fontSize="large" htmlColor="#F45555" />
                    </div>
                  </div>
                ) : (
                  <div
                    className="share_trans_camera_cover mt-10 pointer"
                    onClick={() => setStep(1)}
                  >
                    <Add fontSize="large" htmlColor="#fff" />
                  </div>
                )}
              </div>
              <div className="mt-30">
                <p className="font_12_600 color_7e">GHG Report</p>
                {selectedGhgReport ? (
                  <div className="d-flex mt-10" style={{ gap: 10 }}>
                    <div className="esg_report_box_share flex-1 d-flex align-items-center justify-content-between">
                      <p className="font_14_500">
                        {selectedGhgReport.name} ({" "}
                        {selectedGhgReport.userId.firstname})
                      </p>
                      <div className="share_timeframe_box">
                        {selectedGhgReport.timeFrameOption == 1 ? (
                          <p className="font_12_500">
                            {selectedGhgReport.timeFrame?.year}
                          </p>
                        ) : selectedGhgReport.timeFrameOption == 2 ? (
                          <p className="font_12_500">
                            {selectedGhgReport.timeFrame?.startYear} -{" "}
                            {selectedGhgReport.timeFrame?.quarter}
                          </p>
                        ) : selectedGhgReport.timeFrameOption == 3 ? (
                          <p className="font_12_500">
                            {selectedGhgReport.timeFrame?.year}
                          </p>
                        ) : selectedGhgReport.timeFrameOption == 4 ? (
                          <p className="font_12_500">
                            {
                              monthNames[
                                selectedGhgReport.timeFrame?.startMonth
                              ]
                            }{" "}
                            {selectedGhgReport.timeFrame?.startYear} -{" "}
                            {monthNames[selectedGhgReport.timeFrame?.endMonth]}{" "}
                            {selectedGhgReport.timeFrame?.endYear}
                          </p>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className="share_trans_camera_cover pointer"
                      onClick={() => setSelectedGhgReport(null)}
                    >
                      <Close fontSize="large" htmlColor="#F45555" />
                    </div>
                  </div>
                ) : (
                  <div
                    className="share_trans_camera_cover mt-10 pointer"
                    onClick={() => setStep(2)}
                  >
                    <Add fontSize="large" htmlColor="#fff" />
                  </div>
                )}
              </div>
            </div>
            <div className="col-5">
              <DisclosureImageUpload
                avatar={disclosureImg}
                setAvatar={setDisclosureImg}
              />
            </div>
          </div>
          <div className="mt-20 d-flex justify-content-end">
            <ButtonComp status={5} onClick={handleChange}>Save</ButtonComp>
          </div>
        </>
      ) : step === 1 ? (
        <>
          <div className="d-flex align-items-center">
            <KeyboardBackspaceOutlined
              className="pointer"
              onClick={() => setStep(0)}
            />
            <p className="font_16_500 ml-32">Add ESG Report</p>
          </div>
          <div className="mt-20">
            <div className="d-flex" style={{ gap: 25 }}>
              <div className="row flex-1">
                <div className="col-8">
                  <p className="font_12_600 color_7e">Report Name</p>
                </div>
                <div className="col-2">
                  <p className="font_12_600 color_7e">Time Frame</p>
                </div>
                <div className="col-2">
                  <p className="font_12_600 color_7e">Created</p>
                </div>
              </div>
              <div
                className="share_trans_camera_cover opacity-0"
                style={{ height: 0 }}
              >
                .
              </div>
            </div>
            {esgReports.map((report) => (
              <div
                className="d-flex mt-10"
                key={report._id}
                style={{ gap: 25 }}
              >
                <div className="row align-items-center esg_report_box_share flex-1">
                  <div className="col-8">
                    <p className="font_14_500">{report.name}</p>
                  </div>
                  <div className="col-2">
                    <div className="share_timeframe_box">
                      <p className="font_12_500">{report.timeFrame}</p>
                    </div>
                  </div>
                  <div className="col-2">
                    <p className="font_12_500 ml-7">
                      {getDateString(report.createdAt)}
                    </p>
                  </div>
                </div>
                <div
                  className="share_trans_camera_cover pointer"
                  onClick={() => {
                    setSelectedEsgReport(report);
                    setStep(0);
                  }}
                >
                  <Add fontSize="large" htmlColor="#fff" />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : step === 2 ? (
        <>
          <div className="d-flex align-items-center">
            <KeyboardBackspaceOutlined
              className="pointer"
              onClick={() => setStep(0)}
            />
            <p className="font_16_500 ml-32">Add GHG Report</p>
          </div>
          <div className="mt-20">
            <div className="d-flex" style={{ gap: 25 }}>
              <div className="row flex-1">
                <div className="col-6">
                  <p className="font_12_600 color_7e">Report Name</p>
                </div>
                <div className="col-3">
                  <p className="font_12_600 color_7e">Type</p>
                </div>
                <div className="col-3 d-flex justify-content-end">
                  <p className="font_12_600 color_7e">Time Frame</p>
                </div>
              </div>
              <div
                className="share_trans_camera_cover opacity-0"
                style={{ height: 0 }}
              >
                .
              </div>
            </div>
            {ghgReports.map((report) => (
              <div
                className="d-flex mt-10"
                key={report._id}
                style={{ gap: 25 }}
              >
                <div className="row align-items-center esg_report_box_share flex-1">
                  <div className="col-6">
                    <p className="font_14_500">
                      {report.name} ( {report.userId.firstname})
                    </p>
                  </div>
                  <div className="col-3">
                    <div className="share_timeframe_box">
                      <p className="font_12_500">{report.type}</p>
                    </div>
                  </div>
                  <div className="col-3 d-flex justify-content-end">
                    {report.timeFrameOption == 1 ? (
                      <p className="font_12_500 ml-7">
                        {report.timeFrame?.year}
                      </p>
                    ) : report.timeFrameOption == 2 ? (
                      <p className="font_12_500 ml-7">
                        {report.timeFrame?.startYear} -{" "}
                        {report.timeFrame?.quarter}
                      </p>
                    ) : report.timeFrameOption == 3 ? (
                      <p className="font_12_500 ml-7">
                        {report.timeFrame?.year}
                      </p>
                    ) : report.timeFrameOption == 4 ? (
                      <p className="font_12_500 ml-7">
                        {monthNames[report.timeFrame?.startMonth]}{" "}
                        {report.timeFrame?.startYear} -{" "}
                        {monthNames[report.timeFrame?.endMonth]}{" "}
                        {report.timeFrame?.endYear}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div
                  className="share_trans_camera_cover pointer"
                  onClick={() => {
                    setSelectedGhgReport(report);
                    setStep(0);
                  }}
                >
                  <Add fontSize="large" htmlColor="#fff" />
                </div>
              </div>
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default DisClosureModal;
