import React from "react";
// import bg from "../../../Assets/images/Placeholder_GIF.gif";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import {
  AnimationScreens,
  AnimationVideos,
} from "../../../Utils/CommonFunctions";

const PaymentSuccess = () => {
  const [url, setUrl] = useState(null);
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [isDownloaded, setisDownloaded] = useState(false);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      navigate(`/set-two-factor`);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);

  useEffect(() => {
    if (state) {
      if (state.invoiceUrl) {
        setUrl(state.invoiceUrl);
      }
    }
  }, [state]);

  return (
    <div className="two_factor_root">
      <div className="row auth_background mx-0">
        <div
          className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
          style={{
            backgroundImage: `url(${AnimationScreens[7]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <video
            className="reg_vdo"
            src={AnimationVideos[7]}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
            controls={false}
            poster={AnimationScreens[7]}
          ></video>
        </div>
        <div className="col-lg-5 col-md-6 col-12 auth_info_container">
          <div className=" max_width_552 two_factor_container">
            <div className="subtitle_box bg_soft_blue width_fit">
              <p className="font_13_600 color_blue">Congratulations</p>
            </div>
            <p className="mt-32 offsetProjectTitle">
              Purchased Successfully! <br />
              Your Account is active now.
            </p>
            {url && (
              <div className="mt-40">
                {isDownloaded ? (
                  <p className=" font_18_400 ">
                    ✅ Invoice has been downloaded
                  </p>
                ) : (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="primary_button_lg text-white"
                    onClick={() => setisDownloaded(true)}
                  >
                    Download Invoice
                  </a>
                )}
              </div>
            )}
            <div className="mt-60 d-flex align-items-end justify-content-end flex-column">
              <button
                className="secondary_button_active ml-16"
                onClick={() => navigate(`/set-two-factor`)}
              >
                Complete Set-Up
              </button>
              <div className="text-end mt-12">
                <p className="overline_text color_black">Press Enter ↵</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
