import React, { useEffect, useState } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import useQuery from "../../../../../Utils/query";
import Axios from "../../../../../Api";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { Link, useNavigate } from "react-router-dom";
import meetingRoomImage from "../../../../../Assets/images/store_our_services.png";
import ItemDetails from "../../../LogDashboard/other-items.json";
import { KeyboardArrowLeft } from "@mui/icons-material";
import StoreCard from "../StoreCard";
import ButtonComp from "../../../../../Assets/components/Button";
import PhoneIcon from "../../../../../Assets/icons/PhoneIcon";
import Card from "./Card";
import TickMark from "../../../../../Assets/icons/tickMark";

const OtherItemDetails = ({
  cartDetails,
  setCartDetails,
  userCart,
  setUserCart,
}) => {
  const { setSessionExpire } = useAuth();
  const navigate = useNavigate();
  const query = useQuery();
  const itemId = query.get("item");
  const [otherItemDetails, setOtherItemDetails] = useState([]);
  const [itemInfo, setItemInfo] = useState({});

  const handleQuantityChange = async (id, quantity) => {
    try {
      var response = await Axios.patch(
        `/cart/store-cart-update/${userCart._id}`,
        {
          itemId: id,
          quantity: quantity,
        }
      );
      if (response.data.success) {
        // setcurQuantity(quantity);
        setUserCart(response.data.cart);
        setCartDetails(response.data.cart.items);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const getOtherItemsData = async () => {
    if (!itemInfo.category) return;
    try {
      const response = await Axios.get(`/items/all`);
      if (response.data.success) {
        let currItemDetails = response.data.items
          .filter(
            (currItem) =>
              currItem.category.toLowerCase() ==
                itemInfo.category.toLowerCase() && currItem._id != itemInfo._id
          )
          .slice(0, 2);
        setOtherItemDetails(currItemDetails);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    if (itemId && itemId.length) {
      //   let currItem = {}
      ItemDetails.forEach((currItem) => {
        if (currItem._id === itemId) {
          setItemInfo(currItem);
        }
      });
    }
  }, [itemId]);

  useEffect(() => {
    getOtherItemsData();
  }, [itemInfo]);

  //   const [curQuantity, setcurQuantity] = useState(0);

  //   useEffect(() => {
  //     if (cartDetails) {
  //       var itemFind = cartDetails.find((items) => items.itemId === itemId);

  //       if (itemFind) {
  //         setcurQuantity(itemFind.quantity);
  //       } else {
  //         setcurQuantity(0);
  //       }
  //     }
  //   }, [itemId]);

  return (
    <div>
      <p className="breadcrumb_text_light_1 text_capital mt-44">
        <Link to={`/dashboard?tab=9`}>
          <span className="color_light_2 mb-2">
            <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
          </span>
          <span className="color_light_2">store </span>
          <span className="color_light_2">/ general items </span>
        </Link>{" "}
        <span className="fw-700 text-uppercase"> / {itemInfo.name}</span>
      </p>

      <div className="row mt-50">
        <div className="col-8 d-flex">
          <div className="flow-grow-1">
            <div className="item_profile_cover_img_bg">
              <img src={itemInfo?.image} />
            </div>

            <p className="body_text_m mt-20 line_height_22">
              {itemInfo.description}
            </p>
          </div>
        </div>
        <div className="col ml-50">
          <div
            className="container_design_1"
            style={{ padding: "40px 27px 27px 27px", maxWidth: 358 }}
          >
            <p className="f-20 fw-600 font-inter">{itemInfo.name}</p>

            <p className="subheading_light mt-20 mr-46 color_57 line_height_22">
              {itemInfo?.provides?.description}
            </p>

            <div className="mt-32">
              <div className=" d-flex flex-column" style={{ gap: 8 }}>
                {itemInfo &&
                  itemInfo.provides &&
                  itemInfo.provides.features.map((feature, i) => (
                    <p className="body_text_s" key={i}>
                      <TickMark className={"mr-13"} />
                      {feature}
                    </p>
                  ))}
              </div>

              <div className="d-flex align-items-center mt-30">
                <p className="heading_9 d-flex align-items-center">
                  from {itemInfo.price}€{" "}
                  {/* <span className="body_text_s color_ba">/month</span> */}
                </p>

                {/* <p className="body_text_s color_ba ml-10">(billed annually)</p> */}
              </div>
              <ButtonComp
                status={2}
                className="mt-30"
                onClick={() =>
                  navigate(`/dashboard?tab=9&step=2&item=${itemInfo.name}`)
                }
              >
                <PhoneIcon className="mr-10" /> Book free Call
              </ButtonComp>

              {/* <div className="d-flex align-items-center mt-30 mb-28">
                <div>
                  <div className="d-flex align-items-center justify-content-between font_15_600  border-ef border_radius_5 p-6">
                    <span
                      className="pointer"
                      onClick={() =>
                        handleQuantityChange(itemId, curQuantity - 1)
                      }
                    >
                      {" "}
                      <div className="card_design_1 border-ef border_radius_5 p-8">
                        <i className="bi bi-dash-lg"></i>
                      </div>
                    </span>

                    <span className="mx-3">{curQuantity}</span>
                    <span
                      className="pointer"
                      onClick={() =>
                        handleQuantityChange(itemId, curQuantity + 1)
                      }
                    >
                      <div className="card_design_1 border-ef border_radius_5 p-8">
                        <i className="bi bi-plus-lg"></i>
                      </div>
                    </span>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="mt-60">
        <p className="font_17_700 text-uppercase">OUR APPROACH</p>
        <div className="row mt-30">
          {itemInfo &&
            itemInfo.approaches &&
            itemInfo.approaches.map((data, i) => (
              <div className="col-4" key={i}>
                <Card
                  isApproach={true}
                  stepNo={i + 1}
                  title={data.title}
                  description={data.description}
                />
              </div>
            ))}
        </div>
      </div>

      <div className="mt-60">
        <p className="font_17_700 text-uppercase">Your perks</p>
        <div className="row mt-30">
          {itemInfo &&
            itemInfo.perks &&
            itemInfo.perks.map((data, i) => (
              <div className="col-4" key={i}>
                <Card
                  isApproach={false}
                  imgIcon={data.icon}
                  title={data.title}
                  description={data.description}
                />
              </div>
            ))}
        </div>
      </div>

      <div className="row mt-60">
        <div className="col-8">
          <p className="font_17_700 text_capital">Other Services</p>

          <div className="row mt-30">
            {otherItemDetails &&
              otherItemDetails.length > 0 &&
              otherItemDetails.map((currItem, index) => {
                return (
                  <div key={currItem._id + index} className="col-md-6">
                    <StoreCard
                      item={currItem}
                      cartDetails={cartDetails}
                      handleQuantityChange={handleQuantityChange}
                    />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="row mt-30 mb-40  pb-32">
        <div className="col-8">
          <div className="border_1_ef border_radius_10">
            <div className="d-flex" style={{gap: 45}}>
              <div className=" py-20 pl-32">
                <p className="font_36_700_plus_jakarta">Our Services</p>
                <p className="body_text_s color_31 mt-20">
                  We provide a number of different services to support you on
                  your climate action journey.
                </p>
                <ButtonComp className="mt-20">View</ButtonComp>
              </div>

              <img src={meetingRoomImage} className="h-100 w-100 image-fit" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherItemDetails;
