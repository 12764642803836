import React from "react";
import {useAuth} from '../../../../../../Utils/Auth'
function TutorialCard({ topicDetails }) {
  const {user} = useAuth()
  return (
    <div className="content_width card_design_2 color_black pl-22 pr-20 pb-24 border_radius_10 box_shadow_n">
      <div>
        <div className="mt-12">
          <img
            className="tutorials_container tutorials_container_icon px-12 py-12"
            src={topicDetails?.icon}
          />
        </div>

        <div className="mt-16">
          <p className="font_16_600">{user.language === 'de' && topicDetails?.title_de?topicDetails?.title_de:topicDetails?.title}</p>
          <p className="body_text_s_light mt-12">
            {user.language === 'de' && topicDetails?.description_de ?
            topicDetails?.description_de?.substring(0, 65) +
              (topicDetails?.description_de.length > 65 ? "..." : "") :
              topicDetails?.description?.substring(0, 65) +
              (topicDetails?.description.length > 65 ? "..." : "")}
          </p>
        </div>
      </div>
    </div>
  );
}

export default TutorialCard;
