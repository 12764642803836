import React, { useRef, useEffect } from "react";
import "./newSurveyQuestions.css";
import { Button, TextField } from "@mui/material";
// import ButtonComp from "../../../../../../../../Assets/components/Button";
import SurveyQuestion from "../SurveyQuestion";
import TemplateSelect from "./Components/templateSelect";

function NewSurveyQuestions(props) {
  const textareaRef = useRef();
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Enter key was pressed, adjust the height
      adjustTextareaHeight();
    }
  };

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textareaRef) {
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  useEffect(() => {
    adjustTextareaHeight();
  }, [props.surveyDesp]);

  const handleCut = () => {
    // Text is being cut (e.g., via Ctrl+X), adjust the height
    setTimeout(adjustTextareaHeight, 0);
  };
  return (
    <>
      <div className="d-flex align-items-center createSurvey_select_br0 mt-30">
        <TemplateSelect
          templates={props.templates}
          setTemplateFormat={props.setTemplateFormat}
        />
      </div>

      <div className="createSurvey_question_container mt-40">
        <p className="font_13_700">Survey Name</p>

        <TextField
          value={props.surveyName}
          onChange={(e) => {
            props.setSurveyName(e.target.value);
          }}
          className="mt-12"
          size="small"
          fullWidth
        />

        <p className="font_13_400 mt-20">Description</p>
        <textarea
          style={{
            height: 40,
            padding: 10,
            maxHeight: 220,
            minHeight: 40,
          }}
          ref={textareaRef}
          className="mt-10 share_transpancy_input font_14_400"
          value={props.surveyDesp}
          onChange={(e) => props.setSurveyDesp(e.target.value)}
          onCut={handleCut}
          onKeyDown={handleKeyDown}
          onInput={adjustTextareaHeight}
        />
        {/* <TextField
          value={props.surveyDesp}
          onChange={(e) => {
            props.setSurveyDesp(e.target.value);
          }}
          className="mt-12"
          size="small"
          fullWidth
        /> */}
      </div>

      <div className="d-flex justify-content-between align-items-center mt-34">
        <p className="font_12_300 color_7e">Enter Survey Information</p>

        <Button
          // status={1}
          onClick={() => {
            props.handleAddQuestion();
          }}
          className=""
          sx={{
            height: "37px !important",
            backgroundColor: "#CDF1E3 !important",
            borderRadius: "5px",
            fontSize: "13px",
            fontWeight: 400,
            lineHeight: "10px",
            fontFamily: "Inter",
            color: "#00915B !important",
            width: "143px !important",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "capitalize",
            "&:hover": {
              backgroundColor: "#cdf1e37a +!important",
            },
          }}
        >
          <i className="bi bi-plus"></i> Add Question
        </Button>
      </div>

      <div className="mt-20">
        {props.boxes
          .sort((a, b) => a.order - b.order)
          .map((box, i) => (
            <SurveyQuestion
              key={box.id + i}
              id={box.id}
              handleDrag={props.handleDrag}
              handleDrop={props.handleDrop}
              removeQuestion={props.removeQuestion}
              order={box.order}
              questionDetails={box}
              selectQuestionType={props.selectQuestionType}
              removeMulOptions={props.removeMulOptions}
              addMulOptions={props.addMulOptions}
              editMulOption={props.editMulOption}
              handleQuestionChange={props.handleQuestionChange}
              setTemplateFormat={props.setTemplateFormat}
              handleQuestionRatingChange={props.handleQuestionRatingChange}
              addLikertScaleOptions={props.addLikertScaleOptions}
            />
          ))}
      </div>
    </>
  );
}

export default NewSurveyQuestions;
