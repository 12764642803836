import React, { useEffect } from "react";
import BackForwardButton from "../../../Assets/components/BackForwardButton/BackForwardButton";
import PressEnter from "../../../Assets/components/PressEnter/PressEnter";
import ButtonComp from "../../../Assets/components/Button";

function MultipleChoice(props) {
  // const [, setActive] = useState("");

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      props.handleSubmit(props.regDetails[props.target]);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);

  // useEffect(() => {
  //   setActive(props.empCount);
  // }, []);
  return (
    <div className="register_text_start col-md-10 max_width_552 register_question_container">
      <div className="purple_label register_question_no">
        {props.currQuestion + 1}/6
      </div>
      <div className="form-group register_text_start">
        <label
          className=" register_sm_label register_question_label"
          htmlFor="label1"
        >
          {props.question.question}
        </label>
        <div className="row">
          {props.question.fields.options.map((item, i) => {
            return (
              <div key={i} className="col-6 my-2">
                <button
                  className={`w-100 register_choice_body ${
                    item === props.regDetails[props.target]
                      ? "register_choice_active"
                      : ""
                  }
                `}
                  onClick={() => {
                    props.changeOption(props.target, item);
                  }}
                >
                  {item}
                </button>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-30 d-flex align-items-start justify-content-between">
        <BackForwardButton
          handleGoBack={() =>
            props.decQuestionNo(props.regDetails[props.target])
          }
          hanldeGoForward={() =>
            props.incQuestionNo(props.regDetails[props.target])
          }
          forwardDisabled={props.currQuestion === 5 && true}
        />
        <div>
          <ButtonComp
            size="large"
            status={2}
            onClick={() => props.handleSubmit(props.regDetails[props.target])}
          >
            Save
          </ButtonComp>
          <PressEnter />
        </div>
      </div>

      {/* <div className="mt-30 register_button d-flex justify-content-end">
        <button
          className="secondary_button_active secondary_button_sx_active"
          onClick={() => props.handleSubmit(props.regDetails[props.target])}
        >
          Save
        </button>
      </div> */}
      {/* <div className="d-flex justify-content-between mt-10">
        <div>
          <i
            className={
              props.currQuestion > 0
                ? "bi bi-chevron-left register_button_enable"
                : "bi bi-chevron-left register_button_disable"
            }
            onClick={() => props.decQuestionNo()}
          ></i>
          <i
            className={
              props.currQuestion < 5
                ? "bi bi-chevron-right register_button_enable"
                : "bi bi-chevron-right register_button_disable"
            }
            onClick={() => props.incQuestionNo()}
          ></i>
        </div>

        <div className="d-flex">
          <p className="register_enter"> Press Enter</p>
          <i className="bi bi-arrow-return-left"></i>
        </div>
      </div> */}
    </div>
  );
}

export default MultipleChoice;
