import React from "react";

const PressEnter = () => {
  return (
    <div className="text-end mt-10">
      <p className="helper_text">Press Enter ↵</p>
    </div>
  );
};

export default PressEnter;
