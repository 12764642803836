import "./App.css";
import React, { Suspense, lazy } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Signup from "./Authentication/Signup";
import Register from "./Authentication/Register";
import { ProvideAuth } from "./Utils/Auth"; 
import { ProvideMapStore } from "./Utils/CompanyMapStore";
import { Toaster } from "react-hot-toast";
import EmailVerified from "./Authentication/EmailVerify";
import UserCheck from "./Utils/UserCheck";
import Subscription from "./Authentication/Register/Subscription";
import Sidebar from "./Components/Layout/Sidebar";
import RegisterSuccess from "./Authentication/Register/Success";
import Avatar from "./Authentication/Register/Avatars";
import { CircularProgress } from "@mui/material";
import NotPaid from "./Authentication/NotPaid";
import CompleteRegisteration from "./Authentication/Register/completeRegisteration";
import ForgetPassword from "./Authentication/ForgetPassword";
import ResetPassword from "./Authentication/ForgetPassword/resetPassword";
import ProductDetail from "./Components/Companydashboard/LogDashboard/ProductDetail";
import NotFound from "./Components/CommonComponents/NotFound/NotFound";
import TwoFactor from "./Authentication/TwoFactor";
import Mfa_Authentication from "./Authentication/Mfa-authentication";
import ExpiryModal from "./Utils/ExpiryModal";
import AdminLogin from "./Admin/AdminLogin";
import AdminCheck from "./Utils/AdminCheck";
import AdminDashboard from "./Admin/Dashboard";
import PaymentSuccess from "./Authentication/Register/PaymentSuccess/PaymentSuccess";
import { IntercomProvider } from "react-use-intercom";
import Hotjar from "@hotjar/browser";
const INTERCOM_APP_ID = "t7n5mn8m";
// const Navbar = lazy(() => import("./Components/Layout/Navbar"));
const Login = lazy(() => import("./Authentication/Login"));
import ReactGA from "react-ga4";
import FillSurveyForm from "./Components/Companydashboard/Share/Surveys/Components/CreateSurvey/Components/FillSurveyForm";
import { ReactFlowProvider } from "reactflow";
function App() {
  // hotjar
  React.useEffect(() => {
    if (process.env.REACT_APP_GA_KEY !== "")
      ReactGA.initialize(process.env.REACT_APP_GA_KEY);

    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
      title: "Main",
    });
    if (process.env.REACT_APP_HOTJAR_KEY !== "") {
      const siteId = process.env.REACT_APP_HOTJAR_KEY;
      const hotjarVersion = 6;
      Hotjar.init(siteId, hotjarVersion);
    }
  }, []);

  return (
    <div className="App">
      <Toaster />
      <Router>
        
        <ProvideAuth>
          <ProvideMapStore>
          <ReactFlowProvider>
          <Suspense
            fallback={
              <div
                className=" v_center"
                // role="status"
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   backgroundColor: "white",
                // }}
              >
                {/* <span class="sr-only"></span> */}
                <CircularProgress />
              </div>
            }
          >
            <ExpiryModal />
            <Routes>
              <Route exact path="/fillSurvey" element={<FillSurveyForm/>} />
              <Route exact path="/" element={<Login />} />
              <Route exact path="/signup" element={<Signup />} />
              <Route
                exact
                path="/forget-password"
                element={<ForgetPassword />}
              />

              <Route exact path="/reset-password" element={<ResetPassword />} />
              <Route exact path="/user-check" element={<UserCheck />} />
              <Route exact path="/register" element={<Register />} />
              <Route exact path="/register/avatar" element={<Avatar />} />
              <Route
                exact
                path="/register/subscription"
                element={<Subscription />}
              />
              <Route
                exact
                path="/payment/success"
                element={<PaymentSuccess />}
              />
              <Route
                exact
                path="/register/success/:id"
                element={<RegisterSuccess />}
              />
              <Route exact path="/register/pending" element={<NotPaid />} />
              <Route
                exact
                path="/register/complete"
                element={<CompleteRegisteration />}
              />
              <Route exact path="/set-two-factor" element={<TwoFactor />} />
              <Route
                exact
                path="/mfa-authentication"
                element={<Mfa_Authentication />}
              />

              <Route exact path="/email-verify" element={<EmailVerified />} />
              <Route
                exact
                path="/dashboard"
                element={
                  <IntercomProvider appId={INTERCOM_APP_ID}>
                    <Sidebar />
                  </IntercomProvider>
                }
              />
              <Route
                extact
                path="/logdashboard/:id"
                element={<ProductDetail />}
              />

              {/* admin routes */}

              <Route extact path="/admin/login" element={<AdminLogin />} />
              <Route extact path="/admin-check" element={<AdminCheck />} />
              <Route
                extact
                path="/admin/dashboard"
                element={<AdminDashboard />}
              />
              <Route extact path="*" element={<NotFound />} />
            </Routes>
          </Suspense>

        </ReactFlowProvider>
          </ProvideMapStore>
        </ProvideAuth>
      </Router>
    </div>
  );
}

export default App;

//deploy comment
