import React, { memo, useEffect, useState } from "react";
import ButtonComp from "../../../../../Assets/components/Button";
import RightChevronIcon from "../../../../../Assets/icons/RightChevronIcon";
import { Close } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import LeftChevronIcon from "../../../../../Assets/icons/LeftChevronIcon";
import { getAccountingItems } from "../../../../../Utils/CommonApiRequests";
import itemData from "../../../LogDashboard/items-new.json";
import Axios from "../../../../../Api";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
import SelectBaseYear from "./Components/SelectBaseYear";
import SelectTargetScope from "./Components/SelectTargetScope";
import TargetSetting from "./Components/TargetSetting";

const CreateTargetDrawer = ({
  open,
  handleDrawerClose,
  getTarget,
  targetData,
  isEdit,
}) => {
  const { setSessionExpire } = useAuth();
  const [years, setYears] = useState([]);
  const [selectBaseYear, setSelectBaseYear] = useState("");
  const [step, setStep] = useState(0);
  const [accountingItemsList, setAccountingItemList] = useState([]);
  const [itemSelectionList, setItemSelectionList] = useState([]);
  const [selectedAccItems, setSelectedAccItems] = useState([]);
  const [includeScope3, setIncludeScope3] = useState(false);
  const [scopeWiseData, setScopeWiseData] = useState(null);
  const [targetType, setTargetType] = useState("");
  const [targetYear, setTargetYear] = useState("");
  const [target1Reduction, setTarget1Reduction] = useState("");
  const [target2Reduction, setTarget2Reduction] = useState("");
  const [target3Reduction, setTarget3Reduction] = useState("");
  const [reducedPercentage_scope1, setReducedPercentage_scope1] = useState("");
  const [reducedPercentage_scope2, setReducedPercentage_scope2] = useState("");
  const [reducedPercentage_scope3, setReducedPercentage_scope3] = useState("");
  const [chartData, setChartData] = useState([]);

  const getAllItems = async () => {
    try {
      let response = await Axios.get("/log/all-user-items");
      if (response.data.success) {
        setItemSelectionList(response.data.data);
        // setAllAccountingItemsData(response.data.data);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    if (targetData) {
      setSelectBaseYear(targetData.baseYear);
      setSelectedAccItems(targetData.targetScopes);
      setTargetType(targetData.targetType);
      setTargetYear(targetData.targetTimeFrame);
      setIncludeScope3(targetData.includeScope3);
    }
  }, [targetData]);

  useEffect(() => {
    (async () => {
      const res = await getAccountingItems();
      const maxYear = Math.max(...res.accountingItemYears);
      const minYear = Math.min(...res.accountingItemYears);
      const allYears = Array.from(
        { length: maxYear - minYear + 1 },
        (_, i) => minYear + i
      );
      setYears(allYears);
      const tempItemListFilter = itemData.filter((data) =>
        res.accountingItemCategories.includes(data.name)
      );
      let tempItemList = tempItemListFilter.map((currentItem) => {
        return {
          name: currentItem.name,
          id: currentItem._id,
        };
      });
      setAccountingItemList(tempItemList);
      getAllItems();
    })();
  }, []);

  const handleIncreaseStep = () => {
    setStep(step + 1);
  };

  const handleDecreaseStep = () => {
    if (step > 0) {
      setStep(step - 1);
    }
  };

  const getScopeEmission = async () => {
    await Axios({
      url: `/log/scope-accounting-emission-log`,
      method: "GET",
      params: {
        logIds: selectedAccItems.map((acc) => acc._id),
        year: Number(selectBaseYear),
      },
    })
      .then((res) => {
        setScopeWiseData(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (step === 2) {
      getScopeEmission();
    }
  }, [step]);

  const CreateTarget = async () => {
    await Axios({
      url: `/target/create-target`,
      method: "POST",
      data: {
        baseYear: selectBaseYear,
        targetScopes: selectedAccItems.map((acc) => acc._id),
        targetType: targetType,
        targetTimeFrame: targetYear,
        targetYear: Number(selectBaseYear) + Number(targetYear),
        overallReduction:
          Number(target1Reduction) +
          Number(target2Reduction) +
          Number(target3Reduction),
        totalEmission: scopeWiseData,
        resultedEmission: {
          scope1: scopeWiseData.scope1 - target1Reduction,
          scope2: scopeWiseData.scope2 - target2Reduction,
          scope3: scopeWiseData.scope3 - target3Reduction,
        },
        scope1Reduction: Number(target1Reduction),
        scope1ReductionPercentage: Number(reducedPercentage_scope1),
        scope2Reduction: Number(target2Reduction),
        scope2ReductionPercentage: Number(reducedPercentage_scope2),
        includeScope3: includeScope3,
        scope3Reduction: Number(target3Reduction),
        scope3ReductionPercentage: Number(reducedPercentage_scope3),
        chartData: chartData,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          await getTarget();
          cleanState();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const updateTarget = async () => {
    await Axios({
      url: `/target/edit-target/${targetData._id}`,
      method: "put",
      data: {
        baseYear: selectBaseYear,
        targetScopes: selectedAccItems.map((acc) => acc._id),
        targetType: targetType,
        targetTimeFrame: targetYear,
        targetYear: Number(selectBaseYear) + Number(targetYear),
        overallReduction:
          Number(target1Reduction) +
          Number(target2Reduction) +
          Number(target3Reduction),
        totalEmission: scopeWiseData,
        resultedEmission: {
          scope1: scopeWiseData.scope1 - target1Reduction,
          scope2: scopeWiseData.scope2 - target2Reduction,
          scope3: scopeWiseData.scope3 - target3Reduction,
        },
        scope1Reduction: Number(target1Reduction),
        scope1ReductionPercentage: Number(reducedPercentage_scope1),
        scope2Reduction: Number(target2Reduction),
        scope2ReductionPercentage: Number(reducedPercentage_scope2),
        includeScope3: includeScope3,
        scope3Reduction: Number(target3Reduction),
        scope3ReductionPercentage: Number(reducedPercentage_scope3),
        chartData: chartData,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          await getTarget();
          cleanState();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const cleanState = () => {
    setSelectBaseYear("");
    setSelectedAccItems([]);
    setIncludeScope3(false);
    setScopeWiseData(null);
    setTargetType("");
    setTargetYear("");
    setTarget1Reduction("");
    setTarget2Reduction("");
    setTarget3Reduction("");
    setReducedPercentage_scope1("");
    setReducedPercentage_scope2("");
    setReducedPercentage_scope3("");
    setChartData([]);
    setStep(0);
    handleDrawerClose();
  };

  return (
    <Drawer
      anchor={"right"}
      open={open}
      // onClose={() => {
      //   handleDrawerClose();
      // }}
      PaperProps={{
        sx: { overflow: "hidden" },
      }}
      // sx={{ zIndex: 10000000000 }}
    >
      <div className="quickLog-container">
        <div className="quickLog-main">
          <div className="d-flex justify-content-between align-items-center">
            <p className="font_24_500 line_height_10 ">
              Step {step + 1} -{" "}
              {step === 0
                ? "Base Year"
                : step === 1
                ? "Target Scope"
                : "Target Settings"}
            </p>
            <Close
              htmlColor="#4C4C4C"
              className="pointer"
              onClick={() => {
                cleanState();
              }}
            />
          </div>
          {step === 0 ? (
            <SelectBaseYear
              selectBaseYear={selectBaseYear}
              setSelectBaseYear={setSelectBaseYear}
              years={years}
            />
          ) : step === 1 ? (
            <SelectTargetScope
              accountingItemsList={accountingItemsList}
              itemSelectionList={itemSelectionList}
              selectedAccItems={selectedAccItems}
              setSelectedAccItems={setSelectedAccItems}
            />
          ) : (
            <TargetSetting
              selectBaseYear={selectBaseYear}
              includeScope3={includeScope3}
              setIncludeScope3={setIncludeScope3}
              scopeWiseData={scopeWiseData}
              targetType={targetType}
              setTargetType={setTargetType}
              targetYear={targetYear}
              setTargetYear={setTargetYear}
              target1Reduction={target1Reduction}
              setTarget1Reduction={setTarget1Reduction}
              target2Reduction={target2Reduction}
              setTarget2Reduction={setTarget2Reduction}
              target3Reduction={target3Reduction}
              setTarget3Reduction={setTarget3Reduction}
              reducedPercentage_scope1={reducedPercentage_scope1}
              setReducedPercentage_scope1={setReducedPercentage_scope1}
              reducedPercentage_scope2={reducedPercentage_scope2}
              setReducedPercentage_scope2={setReducedPercentage_scope2}
              reducedPercentage_scope3={reducedPercentage_scope3}
              setReducedPercentage_scope3={setReducedPercentage_scope3}
              chartData={chartData}
              setChartData={setChartData}
            />
          )}
        </div>
        <div className="quickLog-footer">
          {step > 0 && (
            <ButtonComp
              onClick={() => {
                handleDecreaseStep();
              }}
              status={6}
              size="sm"
              style={{ height: 40 }}
              className="bg_d9"
            >
              <LeftChevronIcon className={"mr-10"} /> Go Back
            </ButtonComp>
          )}
          {step === 2 ? (
            <ButtonComp
              onClick={() => {
                if (isEdit) {
                  updateTarget();
                } else {
                  CreateTarget();
                }
              }}
              status={5}
              size="sm"
              style={{ height: 40 }}
            >
              Set Target <RightChevronIcon className={"ml-10"} />
            </ButtonComp>
          ) : (
            <ButtonComp
              onClick={() => {
                handleIncreaseStep();
                // handleMatrixCellSubmit();
              }}
              status={5}
              size="sm"
              style={{ height: 40 }}
            >
              Next Step <RightChevronIcon className={"ml-10"} />
            </ButtonComp>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default memo(CreateTargetDrawer);
