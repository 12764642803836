import React, { useRef, useEffect, useState } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import KeyboardForwardIcon from "../../../../Assets/icons/KeyboardForwardIcon";
import BlackSmallEarthIcon from "../../../../Assets/icons/BlackSmallEarthIcon";
import { toJpeg } from "html-to-image";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../Utils/TableComp";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import axios from "../../../../Api";
import { useAuth } from "../../../../Utils/Auth";
import Name from "../../../../Assets/components/Empty/name";
import Text from "../../../../Assets/components/Empty/text";
import { getServiceIcon } from "../../../../Utils/itemIcon";
import { daysDiffFromCurrent } from "../../../../Utils/DayDifference";
import { useNavigate } from "react-router-dom";
import "./index.css";

const Labels = () => {
  const labelRef = useRef();
  const navigate = useNavigate();
  const [labels, setlabels] = useState([]);
  const { setSessionExpire } = useAuth();
  const downloadImage = () => {
    if (labelRef.current === null) {
      return;
    }

    toJpeg(labelRef.current, { cacheBust: true }).then((dataUrl) => {
      const link = document.createElement("a");
      link.download = "label.png";
      link.href = dataUrl;
      link.click();
    });
  };

  const getLabels = async () => {
    try {
      const response = await axios.get(`/neutral/cn-items`);
      if (response.data.success) {
        setlabels(response.data.cnItems);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    getLabels();
  }, []);

  return (
    <div>
      <p className="subheading_3 mt-32 text-uppercase">
        Carbon accounting certified label
      </p>
      <div className="no-data-root d-flex justify-content-between p-50 mt-20">
        <div className="d-flex justify-content-between flex-column">
          <div>
            <p className="heading_lg">What’s the Carbon Accounting label? 📢</p>
            <p className="subheading_light mt-16 w-65">
              This certification enables you to communicate transparently and
              with credibility to shareholders, stakeholders, partners and
              end-consumers.
            </p>
          </div>
          <div className="border-top_ef pt-24 mt-24">
            <ButtonComp status={1} onClick={() => downloadImage()}>
              Download my Label
              <KeyboardForwardIcon cc={"#fff"} className={"ml-8"} />
            </ButtonComp>
          </div>
        </div>
        <div>
          <div className="certified_label_root" ref={labelRef}>
            <div className="certified_label_body">
              <p className="black_label_headp mb-10">Carbon Accounting</p>
              <BlackSmallEarthIcon />
            </div>
            <div className="certified_label_footer">
              <p className="black_label_certified">Certified</p>
              <p className="black_label_year mt-16">2023</p>
            </div>
          </div>
          <div className="certified_label_border">
            <p className="certified_label_text">
              offically certified by today.green
            </p>
          </div>
        </div>
      </div>

      <p className="subheading_3 mt-60 text-uppercase">Carbon Neutral Items</p>
      <div className="analytics_table_container pb-40">
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640 }}
          className="mt-16"
        >
          <Table
            sx={{ minWidth: 640, borderRadius: "10px" }}
            aria-label="simple table"
          >
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  sx={{
                    borderLeft: "0",
                    width: "28%",
                    // borderRadius: "12px",
                  }}
                  component="th"
                  className="text-uppercase"
                >
                  NAME
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  // sx={{ width: "25%" }}
                >
                  C.N. Since
                </HeadCell>
                <HeadCell
                  sx={{ borderRight: "0" }}
                  component="th"
                  className="text-uppercase"
                >
                  Label Type
                </HeadCell>
                {/* <HeadCell
                  sx={{ borderRight: "0" }}
                  component="th"
                  className="text-uppercase"
                >
                  Offset Emissions
                </HeadCell> */}
                <HeadCell
                  sx={{ borderRight: "0", width: "15%" }}
                  component="th"
                  className="text-uppercase"
                >
                  Contribution Active
                </HeadCell>
                <HeadCell
                  sx={{ borderRight: "0" }}
                  component="th"
                  className="text-uppercase"
                ></HeadCell>
              </StyledHeadRow>
            </TableHead>

            <TableBody>
              {labels && labels.length > 0 ? (
                labels.map((label, i) => (
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        borderBottom: 0,
                      },
                    }}
                    key={i}
                  >
                    <StyledTableCell
                      sx={{ borderLeft: "0" }}
                      component="th"
                      scope="row"
                      textleft={true}
                      className="text_overflow-1"
                    >
                      <span className="text_overflow-1">
                        <img
                          height="20px"
                          className="mb-1"
                          src={getServiceIcon(label.logId?.accountingItemName)}
                        />{" "}
                        &nbsp;
                        {label.logId?.itemDetails.name}
                      </span>
                    </StyledTableCell>
                    <StyledTableCell
                      textleft={true}
                      className="align-items-center justify-content-center"
                    >
                      {label.startDate.year}
                    </StyledTableCell>
                    <StyledTableCell textleft={true} className="text_capital_f">
                      {label.logId?.accountingItemName}
                    </StyledTableCell>
                    {/* <StyledTableCell textleft={true} sx={{ borderRight: "0" }}>
                      X,XX tCO₂e
                    </StyledTableCell> */}
                    <StyledTableCell textleft={true} sx={{ borderRight: "0" }}>
                      <div className="subtitle_box bg_soft_green width_fit">
                        <p className="font_13_600 color_green">
                          {daysDiffFromCurrent(label.endDate)} days remaining
                        </p>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell sx={{ borderRight: "0" }}>
                      <ButtonComp
                        status={3}
                        width="lg"
                        onClick={() =>
                          navigate(
                            `/dashboard?tab=3&step=1&logId=${label.logId?._id}&subtab=3&view-label=true`
                          )
                        }
                      >
                        View
                      </ButtonComp>
                    </StyledTableCell>
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow
                  sx={{
                    "&:last-child td, &:last-child th": {
                      borderBottom: 0,
                    },
                  }}
                >
                  <StyledTableCell
                    sx={{ borderLeft: "0" }}
                    component="th"
                    scope="row"
                    textleft={true}
                  >
                    <Name />
                  </StyledTableCell>
                  <StyledTableCell
                    textleft={true}
                    className="align-items-center justify-content-center"
                  >
                    <Text />
                  </StyledTableCell>
                  <StyledTableCell textleft={true}>
                    <Text />
                  </StyledTableCell>
                  <StyledTableCell textleft={true} sx={{ borderRight: "0" }}>
                    <Text />
                  </StyledTableCell>

                  <StyledTableCell textleft={true} sx={{ borderRight: "0" }}>
                    <Text />
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default Labels;
