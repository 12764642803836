import { BarChart, Bar, ResponsiveContainer, Cell } from "recharts";

const barColors = ["#2441F5", "#CDD4FF"];

const OffsetTypeBarChartPlaceholder = () => {
  const data = [
    {
      name: "avoidance",
      amount: 100,
    },
    {
      name: "removal",
      amount: 75,
    },
  ];
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart width={150} height={40} data={data}>
        <Bar dataKey="amount" fill="#8884d8" radius={[10, 10, 10, 10]}>
          {data &&
            data.map((entry, index) => (
              <Cell
                opacity={0.5}
                key={`cell-${index}`}
                fill={barColors[index]}
              />
            ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};

export default OffsetTypeBarChartPlaceholder;
