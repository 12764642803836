import React, { useState } from "react";
import axios from "../../Api";
// import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import { useAuth } from "../../Utils/Auth";
import "../auth.css";
// import bg from "../../Assets/images/Placeholder_GIF.gif";
import { toast } from "react-hot-toast";
import ButtonComp from "../../Assets/components/Button";
import { toastResponseError } from "../../Utils/toastResponseError";
import { useAuth } from "../../Utils/Auth";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";

export default function EmailVerified() {
  const { setSessionExpire } = useAuth();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const history = useNavigate();

  const handleResendMail = async () => {
    try {
      await axios({
        url: `/user/auth/resend-email`,
        method: "post",
        data: {
          email,
        },
      })
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
          }
        })
        .catch((err) => {
          toast.error(err.message);
        });
    } catch (err) {
      // console.log("login error is ", err);
      // toast.error(err.message);
      toastResponseError(err, setSessionExpire);
    }
  };
  return (
    <div>
      <div className="container-fluid">
        <div className=" row  auth_background">
          <div
            className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
            style={{
              backgroundImage: `url(${AnimationScreens[7]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <video
              className="reg_vdo"
              src={AnimationVideos[7]}
              autoPlay
              playsInline
              loop
              preload="auto"
              muted
              controls={false}
              poster={AnimationScreens[7]}
            ></video>
          </div>
          <div className="d-flex col-md-5 align-items-center register_info_container">
            <div className="d-flex align-items-start flex-column justify-content-center ml-32 col-md-10 max_width_552">
              <p className="purple_label mb-16">E-mail Verification!</p>
              <p className="heading_1 mt-16 mb-16 text-start">
                Please verify your email
              </p>
              {step === 0 ? (
                <div className="d-flex mt-32">
                  <ButtonComp
                    type="submit"
                    status={1}
                    // className=" secondary_button_active"
                    onClick={() => history("/")}
                  >
                    Go to Login{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-arrow-right ml-8"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"
                      />
                    </svg>
                  </ButtonComp>
                  <ButtonComp
                    className=" ml-16"
                    status={4}
                    onClick={() => setStep(1)}
                  >
                    Resend Verification E-Mail
                  </ButtonComp>
                </div>
              ) : (
                <div className="input_div w-100">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Enter your email address"
                    className="w_100"
                  />
                  <div>
                    <ButtonComp
                      status={1}
                      className="mt-16"
                      onClick={handleResendMail}
                    >
                      Resend Verification E-Mail
                    </ButtonComp>
                  </div>
                </div>
              )}

              <div>
                <p className="faint_text pointer mt-20 pointer">
                  Not a member?{" "}
                  <span
                    className="pointer auth_login"
                    onClick={() => history("/signup")}
                  >
                    Signup
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
