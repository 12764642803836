import React, { useEffect } from "react";
import GoogleAutheticatorImg from "../../Assets/images/GoogleAuthenticator.png";
import PhoneAuthenticatorImg from "../../Assets/images/PhoneImage.png";
import BackForwardButton from "../../Assets/components/BackForwardButton/BackForwardButton";
import ButtonComp from "../../Assets/components/Button";
import PressEnter from "../../Assets/components/PressEnter/PressEnter";
import { Link } from "react-router-dom";

const Step1 = ({ generateGoogleAuthenticatorToken, authType, setStep }) => {
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      if (authType === "google") {
        generateGoogleAuthenticatorToken();
      } else {
        setStep(2);
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [authType, generateGoogleAuthenticatorToken, setStep]);

  return (
    <>
      <div className="subtitle_box bg_soft_blue width_fit">
        <p className="font_13_600 color_blue">
          {authType === "google"
            ? "Google Authenticator"
            : "SMS Authentication"}
        </p>
      </div>
      <p className="mt-32 offsetProjectTitle">
        Set-Up a Two-Factor <br /> Authentication for your Account
      </p>
      <div className="choosedAuthentic_box mt-32">
        <img
          src={
            authType === "google"
              ? GoogleAutheticatorImg
              : PhoneAuthenticatorImg
          }
          alt="authencation"
        />
        <div className="ml-32 onebyoneBox">
          <Link
            to="https://support.google.com/accounts/answer/1066447?hl=de&co=GENIE.Platform%3DiOS"
            target="_blank"
            rel="noreferer"
          >
            <p className="link_text_white color_black text-decoration-none">
              Open Step-by-Step Guide
            </p>
          </Link>
        </div>
      </div>
      <div className="mt-6rem d-flex align-items-start justify-content-between">
        <BackForwardButton
          handleGoBack={() => setStep(0)}
          doNotShowForward={true}
        />
        <div>
          <ButtonComp
            status={1}
            // className="secondary_button_active ml-16"
            size="lg"
            style={{ padding: "6px 35px", marginLeft: "16px" }}
            onClick={() => {
              if (authType === "google") {
                generateGoogleAuthenticatorToken();
              } else {
                setStep(2);
              }
            }}
          >
            Continue
          </ButtonComp>
          <PressEnter />
        </div>
      </div>
    </>
  );
};

export default Step1;
