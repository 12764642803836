import SDG1 from "../Assets/images/E-WEB-Goal-01.png";
import SDG2 from "../Assets/images/E-WEB-Goal-02.png";
import SDG3 from "../Assets/images/E-WEB-Goal-03.png";
import SDG4 from "../Assets/images/E-WEB-Goal-04.png";
import SDG5 from "../Assets/images/E-WEB-Goal-05.png";
import SDG6 from "../Assets/images/E-WEB-Goal-06.png";
import SDG7 from "../Assets/images/E-WEB-Goal-07.png";
import SDG8 from "../Assets/images/E-WEB-Goal-08.png";
import SDG9 from "../Assets/images/E-WEB-Goal-09.png";
import SDG10 from "../Assets/images/E-WEB-Goal-10.png";
import SDG11 from "../Assets/images/E-WEB-Goal-11.png";
import SDG12 from "../Assets/images/E-WEB-Goal-12.png";
import SDG13 from "../Assets/images/E-WEB-Goal-13.png";
import SDG14 from "../Assets/images/E-WEB-Goal-14.png";
import SDG15 from "../Assets/images/E-WEB-Goal-15.png";
import SDG16 from "../Assets/images/E-WEB-Goal-16.png";
import SDG17 from "../Assets/images/E-WEB-Goal-17.png";

export const getSDGImg = {
  1: {
    img: SDG1,
  },
  2: {
    img: SDG2,
  },
  3: {
    img: SDG3,
  },
  4: {
    img: SDG4,
  },
  5: {
    img: SDG5,
  },
  6: {
    img: SDG6,
  },
  7: {
    img: SDG7,
  },
  8: {
    img: SDG8,
  },
  9: {
    img: SDG9,
  },
  10: {
    img: SDG10,
  },
  11: {
    img: SDG11,
  },
  12: {
    img: SDG12,
  },
  13: {
    img: SDG13,
  },
  14: {
    img: SDG14,
  },
  15: {
    img: SDG15,
  },
  16: {
    img: SDG16,
  },
  17: {
    img: SDG17,
  },
};
