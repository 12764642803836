import React, { useState, useEffect } from "react";
import axios from "../../Api/index";
import { toast } from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../Utils/Auth";
// import fileDownload from "js-file-download";
// import bg from "../../Assets/images/Placeholder_GIF.gif";
import ButtonComp from "../../Assets/components/Button";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";

export default function NotPaid() {
  const { user, setSessionExpire, signOutBasic } = useAuth();
  const history = useNavigate();
  const [cart, setcart] = useState(null);
  const [isDisable, setisDisable] = useState(false);
  const getCart = async () => {
    try {
      setisDisable(true);
      const response = await axios.get(`/cart/user-cart`);
      if (response.data.success) {
        setcart(response.data.data);
        setisDisable(false);
        signOutBasic();
      }
    } catch (error) {
      setisDisable(true);
      if (error.response && error.response.status === 401) {
        setSessionExpire(true);
      }
      toast.error(error?.response?.message);
    }
  };
  useEffect(() => {
    getCart();
  }, []);

  // const handleDownload = async () => {
  //   if (cart) {
  //     const response = await axios.get(
  //       `/cart/download/invoice/${cart.invoiceDocument}`,
  //       {
  //         responseType: "arraybuffer",
  //         responseEncoding: "binary",
  //       }
  //     );
  //     fileDownload(response.data, cart.invoiceName + "-Invoice-Today-Green");
  //     //   setisDownloaded(true);
  //   }
  // };

  return (
    <div>
      <div className="container-fluid">
        <div className=" row register_background auth_background">
          <div
            className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
            style={{
              backgroundImage: `url(${AnimationScreens[7]})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <video
              className="reg_vdo"
              src={AnimationVideos[7]}
              autoPlay
              playsInline
              loop
              preload="auto"
              muted
              controls={false}
              poster={AnimationScreens[7]}
            ></video>
          </div>
          <div className="d-flex col-md-5 align-items-center register_info_container">
            <div className="d-flex align-items-start flex-column justify-content-center ml-32 col-md-10 max_width_552">
              <p className="purple_label mb-16">Hey There!</p>
              <p className="heading_1 mt-16 mb-16 text-start">
                Welcome Back {user && user.firstname}!
              </p>
              <p className="text-start body_text_l">
                After your invoice was [aid, you can log in and start your
                carbon management journey!
              </p>
              <div className="d-flex mt-72">
                <Link to={`${cart && cart.invoiceLink}`}>
                  <ButtonComp
                    type="submit"
                    status={1}
                    // className="primary_button_lg"
                    // onClick={() => handleDownload()}
                    disabled={isDisable}
                  >
                    Donwload Invoice
                  </ButtonComp>
                </Link>
                <ButtonComp
                  className="black ml-16"
                  onClick={() => history("/")}
                  status={4}
                >
                  Contact Customer Support
                </ButtonComp>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
