import { Drawer } from "@mui/material";
import React from "react";
import NotificationIcon from "../../../../Assets/icons/NotificationIcon";
import { Close } from "@mui/icons-material";
// import SeachBigCircleIcon from "../../../../Assets/icons/SeachBigCircleIcon";
import { monthNames } from "../../../../Utils/CommonFunctions";
import EmptyText from "../../../../Assets/components/Empty/EmptyText";
import NotificationData from "./notification.json";
import getTimeDifference from "../../../../Utils/TimeDifference";
import { useAuth } from "../../../../Utils/Auth";
import { useNavigate } from "react-router-dom";
import Axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";

const NotificationDrawer = ({
  open,
  handleClose,
  notificationData,
  getNotification,
}) => {
  const { user, setSessionExpire } = useAuth();
  const navigate = useNavigate();

  const readNotification = async (id) => {
    await Axios({
      url: `/cell/read-notification/${id}`,
      method: "PUT",
    })
      .then((res) => {
        if (res.data.success) {
          getNotification();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  //   const [filterValue, setFilterValue] = useState("");
  return (
    <Drawer
      anchor={"right"}
      open={open}
      onClose={() => {
        handleClose();
      }}
      PaperProps={{
        sx: { overflow: "hidden" },
      }}
      // sx={{ zIndex: 10000000000 }}
    >
      <div className="quickLog-container notification_container">
        <div className="between px-20 pt-30">
          <div className="align_center gap_10">
            <div className="h-40px w-40px center bg_soft_green border_radius_5 position-relative pointer">
              <NotificationIcon />
            </div>
            <p className="font_24_500 line_height_10">Notifications</p>
          </div>
          <Close
            htmlColor="#4c4c4c"
            className="pointer"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
        {/* <div className="mt-38 px-20">
          <TextField
            value={filterValue}
            onChange={(e) => setFilterValue(e.target.value)}
            className="font_12_500"
            id="input-with-icon-textfield"
            placeholder={"Search..."}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SeachBigCircleIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: "100%",
              height: 40,
              borderRadius: "5px",
              boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
              "& .MuiInputBase-root": {
                height: 40,
                width: "100%",
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-input": {
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "10px",
                color: "7E8999",
                paddingLeft: "4px",
              },
            }}
          />
        </div> */}
        <div className="notifcation_box_root mt-20">
          <div className="notifcation_box_main">
            {NotificationData.map((data) => (
              <div className="notifcation_box_" key={data.id}>
                <div className="between">
                  <p className="font_12_500 color_7e">{data.tag}</p>
                  <p className="font_12_500 color_7e">
                    {new Date(data.date).getDate()}{" "}
                    {monthNames[new Date(data.date).getMonth()]}
                  </p>
                </div>
                <p className="mt-10 font_14_500">{data.name}</p>
              </div>
            ))}
            {notificationData && notificationData.length > 0 ? (
              notificationData.slice(0, 0).map((notification) => (
                <div
                  className="notifcation_box_ pointer"
                  key={notification._id}
                  onClick={() => {
                    readNotification(notification._id);
                    if (notification.link) {
                      navigate(`${notification.link}`);
                    }
                  }}
                >
                  <div className="between">
                    <p className="font_12_500 color_7e">
                      {notification.type === 0 ||
                      notification.type === 1 ||
                      notification.type === 7
                        ? "Log"
                        : notification.type === 2 ||
                          notification.type === 3 ||
                          notification.type === 4 ||
                          notification.type === 5 ||
                          notification.type === 6
                        ? "Share"
                        : notification.type === 8 ||
                          notification.type === 9 ||
                          notification.type === 10
                        ? "Reduce"
                        : ""}
                    </p>
                    <p className="font_12_500 color_7e">
                      {getTimeDifference(notification.createdAt)}
                    </p>
                  </div>
                  <div className="align_center mt-10">
                    <p className="font_14_500 flex-1">
                      {notification.type === 0 ? (
                        <span>
                          Please offset your emissions for{" "}
                          <span className="fw-600">
                            {notification.logId?.itemDetails.name}
                          </span>
                        </span>
                      ) : notification.type === 1 ? (
                        <span>
                          Contribution required soon for{" "}
                          <span className="fw-600">
                            {notification.logId?.itemDetails.name}
                          </span>
                        </span>
                      ) : notification.type === 7 ? (
                        "Your supplier has added the requested emissions"
                      ) : notification.type === 2 ||
                        notification.type === 3 ||
                        notification.type === 4 ? (
                        notification?.description
                      ) : notification.type === 5 ? (
                        <span>{notification?.description}</span>
                      ) : notification.type === 6 ? (
                        <span>{notification?.description}</span>
                      ) : notification.type === 8 &&
                        user.email === notification?.userEmail ? (
                        <span>{notification?.description}</span>
                      ) : notification.type === 9 ? (
                        <span>{notification?.description}</span>
                      ) : notification.type === 10 ? (
                        <span>{notification?.description}</span>
                      ) : (
                        "add new feature"
                      )}
                    </p>
                    {!notification.read && (
                      <div className="circle_14 bg_green ml-60" />
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="notifcation_box_">
                <div className="between">
                  <EmptyText width={85} />
                  <EmptyText width={40} />
                </div>
                <EmptyText className={"mt-10"} />
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
