import React, { useEffect } from "react";
import SelectQuestionPlate from "./SelectQuestionPlate";
import OptionQuestionPlate from "./OptionQuestionPlate";
import ButtonComp from "../../../../../../Assets/components/Button";
import PressEnter from "../../../../../../Assets/components/PressEnter/PressEnter";
import BackForwardButton from "../../../../../../Assets/components/BackForwardButton/BackForwardButton";
import { memo } from "react";

const Question = ({
  question,
  currentQuestionNo,
  setCurrentQuestionNo,
  setAssessmentQuestion,
  setStep,
  assessmentQuestion,
  CreateAssessment,
  step,
}) => {
  const handleSelectAnswer = (answer) => {
    const newQuestion = { ...question };
    newQuestion.answer = answer;
    const newAssessmentQuestion = [...assessmentQuestion];
    newAssessmentQuestion[currentQuestionNo] = newQuestion;
    setAssessmentQuestion(newAssessmentQuestion);
  };

  const handleNext = () => {
    if (currentQuestionNo + 1 < 7) {
      if (question.answer) {
        setCurrentQuestionNo(() => currentQuestionNo + 1);
      }
    } else {
      setStep(2);
    }
  };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      if (currentQuestionNo + 1 < 7) {
        handleNext();
      } else {
        CreateAssessment();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [step, currentQuestionNo, question]);

  return (
    <div>
      {question.type === "select" ? (
        <SelectQuestionPlate
          currentQuestionNo={currentQuestionNo}
          setCurrentQuestionNo={setCurrentQuestionNo}
          setAssessmentQuestion={setAssessmentQuestion}
          question={question}
          assessmentQuestion={assessmentQuestion}
          handleSelectAnswer={handleSelectAnswer}
        />
      ) : (
        <OptionQuestionPlate
          currentQuestionNo={currentQuestionNo}
          setCurrentQuestionNo={setCurrentQuestionNo}
          setAssessmentQuestion={setAssessmentQuestion}
          question={question}
          assessmentQuestion={assessmentQuestion}
          handleSelectAnswer={handleSelectAnswer}
        />
      )}
      <div className="d-flex align-items-start justify-content-between  mt-90">
        <BackForwardButton
          hanldeGoForward={handleNext}
          forwardDisabled={!question.answer}
          handleGoBack={() => {
            if (currentQuestionNo > 0) {
              setCurrentQuestionNo(() => currentQuestionNo - 1);
            } else {
              setStep(0);
              setCurrentQuestionNo(0);
            }
          }}
        />
        {currentQuestionNo + 1 < 7 ? (
          <div>
            <ButtonComp
              status={2}
              onClick={handleNext}
              disabled={!question.answer}
              size="large"
            >
              Next
            </ButtonComp>
            <PressEnter />
          </div>
        ) : (
          <div>
            <ButtonComp
              status={2}
              onClick={CreateAssessment}
              disabled={!question.answer}
              size="large"
            >
              View Results
            </ButtonComp>
            <PressEnter />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Question);
