import React, { useEffect, useState } from "react";
import "./index.css";
// import KeyboardForwardIcon from "../../icons/KeyboardForwardIcon";
import { botAvatarGroup } from "../../../Utils/botAvatarsGroup";
import { useAuth } from "../../../Utils/Auth";
import ButtonComp from "../Button";
import Axios from "../../../Api";
import toast from "react-hot-toast";
import { toastResponseError } from "../../../Utils/toastResponseError";
// import { Done } from "@mui/icons-material";
import TickMark from "../../icons/tickMark";

const LaunchingCard = ({
  // isShowWiki,
  // wikiLink,
  title,
  subtitle,
  chip,
  feature,
  img,
}) => {
  const { user, setSessionExpire } = useAuth();
  const [isExist, setIsExist] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleInviteFunc = async () => {
    setIsDisabled(true);
    await Axios({
      url: `/getnotified/add`,
      method: "POST",
      data: {
        feature: feature,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setIsDisabled(false);
          toast.success(res.data.message);
          setIsExist(true);
        }
      })
      .catch((err) => {
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };
  const getAlreadyNotified = async () => {
    await Axios({
      url: `/getnotified/isExist/${feature}`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          setIsExist(res.data.isExist);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getAlreadyNotified();
  }, []);
  return (
    <>
      <div
        className="no-data-root d-flex justify-content-between p-32 p_relative"
        style={{ maxWidth: "1200px" }}
      >
        <div className="d-flex justify-content-between flex-column">
          <div>
            <p className="heading_lg">{title ? title : "Title"}</p>
            <div className="subtitle_box bg_soft_blue  width_fit mt-24">
              <p className="subheading_4 color_blue">
                {chip ? chip : "Launching Soon..."}
              </p>
            </div>
            <p className="subheading_light mt-16 w-65">
              {subtitle
                ? subtitle
                : " Build unlimited scenarios to make informed decisions about supply chains and predict your project&apos;s environmental and financial impact."}
            </p>
          </div>
          {isExist ? (
            <div className="pt-24 mt-24">
              <ButtonComp status={5} disabled={true} className="text-white">
                <TickMark className="mr-10" cc={"#fff"} /> You’ll be notified
              </ButtonComp>
            </div>
          ) : (
            <div className="pt-24 mt-24">
              <ButtonComp
                status={5}
                onClick={handleInviteFunc}
                disabled={isDisabled}
              >
                Get Notified
              </ButtonComp>
            </div>
          )}
        </div>

        <div style={{ zIndex: 1 }}>
          <img
            src={
              img
                ? img
                : botAvatarGroup[user?.bot_avatar_id]?.targetPlaceholder?.url
            }
            alt="launching"
            style={{ maxHeight: 310, width: "auto" }}
          />
        </div>
        <hr className="bg_divider" />
      </div>
      {/* {isShowWiki && (
        <div className="mt-24 d-flex align-items-center border_1_ef pl-24 pt-20 pb-20 pr-24 width_fit border_radius_10">
          <p className="card_heading">
            There is an article available about this feature
          </p>
          <p className="ml-60 subheading_5 color_blue">
            <a
              href={wikiLink ? wikiLink : "/"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Go to Wiki{" "}
              <span className="ml-24">
                <KeyboardForwardIcon cc={"#2441F5"} />
              </span>
            </a>
          </p>
        </div>
      )} */}
    </>
  );
};

export default LaunchingCard;
