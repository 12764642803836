import React from "react";

function CheckIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <rect
        width="14"
        height="14"
        x="0.516"
        y="1.01"
        fill="#CDD4FF"
        stroke="#EFEFEF"
        rx="4.5"
      ></rect>
      <path
        stroke="#2441F5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.57 8.71l1.805 2.45 4.086-6.3"
      ></path>
    </svg>
  );
}

export default CheckIcon;
