import React from "react";
import "./selectInputType.css";
import {
  FormControl,
  styled,
  OutlinedInput,
  MenuItem,
  Select,
} from "@mui/material";
const BootstrapInput = styled(OutlinedInput)(() => ({
  "& .MuiInputBase-input": {
    fontFamily: "var(--inter-font)",
    fontWeight: 400,
    fontSize: "13px",
    borderRadius: "5px",
    height: "45px !important",
    marginTop: "auto",
    padding: "0px 8px",
    display: "flex",
    alignItems: "center",
    border: "0",
    backgroundColor: "#F6F6F6",
    color: "#727E8F",
  },
}));
function SelectInputType(props) {
  return (
    <div className="selectInputType_select_container">
      <FormControl
        sx={{
          width: "100%",
          //  border: "1px solid #efefef",
          backgroundColor: "#f6f6f6",
          borderRadius: "5px",
          "& .MuiSelect-select:focus": {
            backgroundColor: "transparent !important",
          },
        }}
        // className="mt-12"
      >
        <Select
          value={
            props.type == "country"
              ? `${props.countryCode}` + "#" + `${props.countryName}`
              : props.type == "state"
              ? `${props.stateCode}` + "#" + `${props.stateName}`
              : props.selectedValue
          }
          input={<BootstrapInput />}
          onChange={(e) => {
            props.handleAnswerChange(props.answerIndex, e.target.value);
          }}
          displayEmpty
          renderValue={(selected) => {
            if (!selected) return props.selectedValue;

            return selected;
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 250,
                borderRadius: "5px",
                border: "1px solid #EDEDED",
                background: "#FFFFFF",
                boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
              },
            },
            // MenuListProps: {
            //   style: { padding: "0px" },
            // },
          }}
        >
          {props.options &&
            props.options.map((currOption, index) => {
              return (
                <MenuItem
                  key={index}
                  // className="color_72"
                  value={
                    props.type == "city"
                      ? `${currOption.name}`
                      : props.type == "country" || props.type == "state"
                      ? `${currOption.iso2}` + "#" + `${currOption.name}`
                      : currOption.item
                      ? currOption.item
                      : currOption
                  }
                  sx={{
                    borderBottom:
                      index === props.options.length - 1
                        ? "none"
                        : "1px solid #efefef",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#000000",
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  {currOption.icon ? (
                    <img src={currOption.icon} height="15px" />
                  ) : null}

                  <p className="">
                    {props.type == "city"
                      ? `${currOption.name}`
                      : props.type == "country" || props.type == "state"
                      ? `${currOption.name}`
                      : currOption.item
                      ? currOption.item
                      : currOption}
                  </p>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}

export default SelectInputType;
