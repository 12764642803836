import React, { useState, useEffect } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomToolTip from "./customToolTip";
import dayjs from "dayjs";
//import { TextField } from "@mui/material";
function DateInput(props) {
  const [currVal, setCurrVal] = useState(dayjs(new Date()));
  useEffect(() => {
    props.setAnswers((prev) => {
      return {
        ...prev,
        [props.questionNo]: currVal,
      };
    });
    // props.handleAnswerChange(currVal, props.questionNo);
  }, [currVal]);

  const handleChange = (value) => {
    setCurrVal(value);
  };
  return (
    <div>
      <div>
        <div className="d-flex align-items-top mb-8 ">
          <p className="sr-only subheading_1 ">{props.title}</p>
          {props.description && props.description.length > 0 ? (
            <div>
              <CustomToolTip title={props.description} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={["DatePicker"]}>
          <DatePicker
            value={currVal}
            onChange={(new_date) => {
              handleChange(new_date);
            }}
            slotProps={{ textField: { size: "small" } }}
            //openTo={props.baseYearField ? "year" : "month"}
            format={props.baseYearField ? "YYYY" : "MM-YYYY"}
            views={!props.baseYearField ? ["year", "month"] : ["year"]}
          />
        </DemoContainer>
        <p className="helper_text mt-2 ml-1">{props.helperText}</p>
      </LocalizationProvider>
    </div>
  );
}

export default DateInput;
