import React from "react";

const PlusIcon = ({onClick}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      fill="none"
      viewBox="0 0 28 28"
      onClick={onClick}
      className="pointer"
    >
      <rect width="27" height="27" x="0.5" y="0.5" fill="#fff" rx="4.5"></rect>
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeWidth="1.5"
        d="M14 17v-6M11 14h6"
      ></path>
      <rect
        width="27"
        height="27"
        x="0.5"
        y="0.5"
        stroke="#EFEFEF"
        rx="4.5"
      ></rect>
    </svg>
  );
};

export default PlusIcon;
