import React, { useState } from "react";
import { InputAdornment, TextField } from "@mui/material";
import SearchIconOutlined from "../../../../../../Assets/icons/SearchIconOutlined";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";

const SelectTargetScope = ({
  accountingItemsList,
  itemSelectionList,
  selectedAccItems,
  setSelectedAccItems,
}) => {
  const [filterValue, setFilterValue] = useState("");

  const getIconName = (item) => {
    if (item.itemDetails.facilityType) return item.itemDetails.facilityType;
    else if (item.itemDetails.productType) {
      return item.itemDetails.productType;
    } else if (item.itemDetails.event) {
      return item.itemDetails.event;
    } else if (item.itemDetails.hotelType) {
      return item.itemDetails.hotelType;
    } else if (item.itemDetails.buildingType) {
      return item.itemDetails.buildingType;
    } else return item.accountingItemName;
  };

  const selectAccItem = (log) => {
    const logItemIndex = selectedAccItems.findIndex(
      (item) => item._id === log._id
    );
    if (logItemIndex === -1) {
      setSelectedAccItems((prev) => [...prev, log]);
    } else {
      const newItems = selectedAccItems.filter((item) => item._id !== log._id);
      setSelectedAccItems(newItems);
    }
  };

  return (
    <React.Fragment>
      <p className="mt-40 font_12_600 color_7e line_height_18">
        Select the accounting items you want to include in your reduction
        target.
      </p>
      <div className="mt-30">
        <TextField
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          className="font_12_500"
          id="input-with-icon-textfield"
          placeholder={"Accounting Item"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIconOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            width: "100%",
            borderRadius: "5px",
            boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
            "& .MuiInputBase-root": {
              height: 40,
              width: "100%",
              border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input": {
              fontFamily: "Inter",
              fontSize: " 12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "10px",
              paddingLeft: "4px",
            },
          }}
        />
      </div>
      {accountingItemsList.map((currentItem) => {
        return (
          <div key={currentItem.id} className="mt-30">
            {itemSelectionList
              .filter((currentLog) => currentLog.itemId === currentItem.id)
              .filter((currentLog) => {
                if (filterValue === "") {
                  return currentLog;
                }
                return String(currentLog.itemDetails.name)
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              }).length > 0 && (
              <p className="font_14_600 line_height_10 color_7e">
                {currentItem.name}(s)
              </p>
            )}

            {itemSelectionList
              .filter((currentLog) => currentLog.itemId === currentItem.id)
              .filter((currentLog) => {
                if (filterValue === "") {
                  return currentLog;
                }
                return String(currentLog.itemDetails.name)
                  .toLowerCase()
                  .includes(filterValue.toLowerCase());
              })
              .map((currentLog) => {
                if (!currentLog.isPaused && currentLog.noOfScopes > 0) {
                  return (
                    <div
                      key={"item" + currentLog._id}
                      className="d-flex justify-content-between align-items-center bg_f6 pl-10 pt-10 pr-10 pb-10 mt-10"
                      onClick={() => {
                        selectAccItem(currentLog);
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <img
                          className="img_20"
                          src={getServiceIcon(getIconName(currentLog))}
                          alt="accounting item "
                        />
                        <p className="font_14_500 line_height_10 text-capitalize ml-10">
                          {currentLog.itemDetails.name}
                        </p>
                      </div>
                      <div className="">
                        {selectedAccItems.findIndex(
                          (item) => item._id === currentLog._id
                        ) !== -1 ? (
                          <div
                            onClick={() => {
                              selectAccItem(currentLog);
                            }}
                            className="quickLog-radio-container w-25px h-25px bg_009 br_round pointer"
                          ></div>
                        ) : (
                          <div
                            onClick={() => {
                              selectAccItem(currentLog);
                            }}
                            className="quickLog-radio-container w-25px h-25px bg_white br_round pointer"
                          ></div>
                        )}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        );
      })}
    </React.Fragment>
  );
};

export default SelectTargetScope;
