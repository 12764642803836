import {
  FormControl,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import SeachBigCircleIcon from "../../../Assets/icons/SeachBigCircleIcon";
import "./dashboard.css";
import ButtonComp from "../../../Assets/components/Button";
import LogIcon from "../../../Assets/icons/logIcon";
import NotificationIcon from "../../../Assets/icons/NotificationIcon";
import { FormatListBulleted } from "@mui/icons-material";
import LinearProgressBar from "../../../Assets/components/LinearProgrssBar/LinearProgressBar";
import RightChevronIcon from "../../../Assets/icons/RightChevronIcon";
import QuickLog from "./Components/QuickLog";
import Axios from "../../../Api";
import { useAuth } from "../../../Utils/Auth";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { localNumber } from "../../../Utils/CommonFunctions";
import DonwIntradayIcon from "../../../Assets/icons/DownIntraday";
import UpIntradayIcon from "../../../Assets/icons/UpIntradayIcon";
import ScopeOverview from "./Components/ScopeOverview";
import NotificationDrawer from "./Components/NotificationDrawer";
import { useNavigate } from "react-router-dom";
import { getServiceIcon } from "../../../Utils/itemIcon";
import TasksDrawer from "../Act/Reduce/Targets/TasksDrawer";
import { getAllLogItems } from "../../../Utils/CommonApiRequests";
import NotificationJson from "./Components/notification.json";
import { useMapStore } from "../../../Utils/CompanyMapStore";
import axios from "../../../Api";

const NewDashboard = () => {
  const { setSessionExpire } = useAuth();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [emissionyears, setEmissionYears] = useState([]);
  const [quickLogDrawer, setQuickLogDrawer] = useState(false);
  const [emissionData, setEmissionData] = useState(null);
  const [scopeEmission, setScopeEmission] = useState([]);
  const [targetData, setTargetData] = useState(null);
  const [emissionCard, setEmissionCard] = useState(null);
  const [emissionOverview, setEmissionOverview] = useState([]);
  const [emissionYearWise, setEmissionYearWise] = useState([]);
  const [isMonthWise, setIsMonthWise] = useState(true);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [filterLogCompData, setFilterLogCompData] = useState([]);
  const [notificationData, setNotificationData] = useState([]);
  const [taskOpen, setTaskOpen] = useState(false);
  const [allAccItemLength, setAllAccItemLength] = useState(0);
  const history = useNavigate();
  const handleTaskDrawerOpen = () => setTaskOpen(true);
  const { setbudgetData } = useMapStore();
  const handleTaskDrawerClose = useCallback(() => {
    setTaskOpen(false);
  }, [taskOpen]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getTotalEmissions = async () => {
    await Axios({
      url: `/analytics/total-emission?year=${selectedYear}`,
      method: "GET",
    })
      .then((res) => {
        const { totalEmission, comparedEmissions, totalLogs } = res.data;
        setEmissionData({
          totalEmission: totalEmission,
          comparedEmission: comparedEmissions,
          totalAccount: totalLogs,
        });
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getScopeEmission = async () => {
    await Axios({
      url: `/analytics/scope-emission?year=${selectedYear}`,
      method: "GET",
    })
      .then((res) => {
        const { scopeEmissions, Comparison } = res.data.data;
        const scope1Per =
          (scopeEmissions["Scope 1"] * 100) /
          (scopeEmissions["Scope 1"] +
            scopeEmissions["Scope 2"] +
            scopeEmissions["Scope 3"]);
        const scope2Per =
          (scopeEmissions["Scope 2"] * 100) /
          (scopeEmissions["Scope 1"] +
            scopeEmissions["Scope 2"] +
            scopeEmissions["Scope 3"]);
        const scope3Per =
          (scopeEmissions["Scope 3"] * 100) /
          (scopeEmissions["Scope 1"] +
            scopeEmissions["Scope 2"] +
            scopeEmissions["Scope 3"]);
        const scopePer = [scope1Per, scope2Per, scope3Per];
        const bg = ["#BCE0FF", "#79C1FF", "#FFCB11"];
        const convertedData = Object.entries(scopeEmissions).map(
          ([name, value], i) => ({
            name: name,
            value: Number(value.toFixed(2).toLocaleString()),
            compare:
              Comparison[i] === "NaN" || !Comparison[i] ? 0 : Comparison[i],
            bg: bg[i],
            percent: scopePer[i],
          })
        );
        setScopeEmission(convertedData);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getEmissionOverview = async () => {
    await Axios({
      url: `/analytics/scopes?year=${selectedYear}`,
      method: "GET",
    })
      .then((res) => {
        setEmissionOverview(res.data.stackBars);
        // setEmissionOverviewLoading(false);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const getEmissionOverviewYearWise = async () => {
    await Axios({
      url: `/analytics/scope-year`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setEmissionYearWise(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getTarget = async () => {
    await Axios({
      url: `/target/get-target`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          setTargetData(res.data.target);
          setEmissionCard(res.data.emissionCards);
          let budget = {
            currentEmission: res.data.emissionCards.currentEmission,
            targetEmission: res.data.emissionCards.targetEmissions,
          };
          setbudgetData(budget);
        } else {
          setTargetData(null);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getLogCompletionData = async () => {
    await Axios({
      url: `/analytics/log-completion?year=${selectedYear}&month=${0}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setFilterLogCompData(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleQuickLogDrawer = () => {
    setQuickLogDrawer(!quickLogDrawer);
  };

  const getNotification = async () => {
    await Axios({
      url: `/cell/notifications`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          setNotificationData(res.data.data);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getAllAccItem = async () => {
    const data = await getAllLogItems();
    setAllAccItemLength(data.length);
  };

  const getAccountingItems = async () => {
    try {
      await axios.get(`/log/logged-accounting-items`).then((res) => {
        setEmissionYears(res.data.accountingItemYears);
      });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  useEffect(() => {
    getAccountingItems();
  }, []);

  useEffect(() => {
    getTotalEmissions();
    getScopeEmission();
    getEmissionOverview();
    getTarget();
    getLogCompletionData();
    getNotification();
    getAllAccItem();
    getEmissionOverviewYearWise();
  }, [selectedYear]);

  const avgYTd = emissionCard?.baseLineEmission / 12;
  const tilYtd = avgYTd * (new Date().getMonth() + 1);

  const ytd =
    emissionCard &&
    emissionCard.baseLineEmission &&
    emissionCard.currentEmission
      ? Number(tilYtd - emissionCard.currentEmission) * -1
      : 0;
  let year = new Date().getFullYear();
  let month = new Date().getMonth() + 1;
  const getFilledScopes = (planMonths) => {
    let yearIndex = planMonths.findIndex((item) =>
      Object.keys(item).includes(year.toString())
    );
    if (yearIndex !== -1) {
      let monthIndex = planMonths[yearIndex][year.toString()].findIndex(
        (obj) => obj.month === month
      );
      let filledScopes =
        planMonths[yearIndex][year.toString()][monthIndex]?.filledSubScope;
      return filledScopes ? filledScopes : 0;
    } else return 0;
  };

  const getIconName = (item) => {
    if (item.itemDetails?.facilityType) return item.itemDetails.facilityType;
    else if (item.itemDetails?.productType) {
      return item.itemDetails?.productType;
    } else if (item.itemDetails?.event) {
      return item.itemDetails?.event;
    } else if (item.itemDetails?.hotelType) {
      return item.itemDetails?.hotelType;
    } else if (item.itemDetails?.buildingType) {
      return item.itemDetails?.buildingType;
    } else return item?.accountItem;
  };

  return (
    <div className="ml-116 mr-80">
      <div className="max_width_1160 pb-40">
        <div className="dashboard_header between">
          <TextField
            // value={filterValue}
            // onChange={(e) => setFilterValue(e.target.value)}
            className="font_12_500"
            id="input-with-icon-textfield"
            placeholder={"Search..."}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SeachBigCircleIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              width: 492,
              borderRadius: "5px",
              backgroundColor: "#f6f6f6",
              "& .MuiInputBase-root": {
                height: 40,
                width: "100%",
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiInputBase-input": {
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "10px",
                color: "7E8999",
                paddingLeft: "4px",
              },
            }}
          />
          <div className="align_center gap_20">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {emissionyears.map((year) => (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ButtonComp
              className="h-40px align_center"
              style={{ width: 130, padding: "0" }}
              status={5}
              onClick={() => {
                setQuickLogDrawer(true);
              }}
            >
              <LogIcon className={"mr-15"} /> Add Log
            </ButtonComp>
            <div
              className="h-40px w-40px center bg_soft_green border_radius_5 position-relative pointer"
              onClick={handleOpen}
            >
              <NotificationIcon />
              {notificationData && notificationData.length > 0 && (
                <div
                  className={
                    "dashboard_toggleCount  bg_red center center " +
                    (notificationData.filter((data) => !data.read).length +
                      NotificationJson.length >
                    9
                      ? "circle_18"
                      : "circle_15")
                  }
                >
                  <p className="font_12_600 line_height_10 text-white">
                    {notificationData.filter((data) => !data.read).length +
                      NotificationJson.length}
                  </p>
                </div>
              )}
            </div>
            <div
              className="h-40px w-40px center bg_soft_green border_radius_5 pointer"
              onClick={handleTaskDrawerOpen}
            >
              <FormatListBulleted htmlColor="#00915B" />
            </div>
          </div>
        </div>
        <div className="dashboard_card_calc d-flex gap_20 mt-30">
          <div className="dashboard_card_emission_root">
            <div className="dashboard_card_emission_main">
              <div className="align_center gap_10">
                <div className="h-30px w-30px center border_radius_5 bg_f7">
                  <UpIntradayIcon cc={"#575757"} />
                </div>
                <p className="font_18_700 color_57">Total Emissions</p>
              </div>
              <div className="mt-30 between">
                <p className="font_22_700">
                  {emissionData ? localNumber(emissionData.totalEmission) : 0}{" "}
                  <span className="font_13_600 color_7e">tCO2e</span>
                </p>
                {/* <p className="font_14_600 color_green line_height_10">
                  -XX,XX%
                </p> */}
              </div>
              <div className="mt-15">
                <p className="font_12_500 line_height_10 color_7e">
                  {selectedYear}
                </p>
              </div>
            </div>
          </div>
          <div className="dashboard_card_emission_root">
            <div className="dashboard_card_emission_main">
              <div className="align_center gap_10">
                <div className="h-30px w-30px center border_radius_5 bg_soft_green">
                  <DonwIntradayIcon />
                </div>
                <p className="font_18_700 color_57">
                  Target Emissions {new Date().getFullYear()}
                </p>
              </div>
              <div className="mt-30 between">
                <p className="font_22_700">
                  {localNumber(emissionCard ? emissionCard.targetEmissions : 0)}{" "}
                  <span className="font_13_600 color_7e">tCO2e</span>
                </p>
                {/* <p className="font_14_600 color_green line_height_10">
                  -XX,XX%
                </p> */}
              </div>
              <div className="mt-15">
                {targetData && (
                  <p className="font_12_500 line_height_10 color_7e">
                    {targetData?.targetType === "Custom"
                      ? "Custom"
                      : targetData?.targetType === "gt2"
                      ? "SBTi " + ">" + 2 + "°C"
                      : "SBTi " + targetData?.targetType + "°C"}
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard_card_emission_root">
            <div className="dashboard_card_emission_main">
              <div className="align_center gap_10">
                <div className="h-30px w-30px center border_radius_5 bg_soft_green">
                  <DonwIntradayIcon />
                </div>

                <p className="font_18_700 color_57">Reduced YTD</p>
              </div>
              <div className="mt-30 between">
                <p className={"font_22_700 " + (ytd > 0 ? "color_red" : "")}>
                  {ytd > 0 ? "+" : ""} {localNumber(ytd)}{" "}
                  <span className="font_13_600 color_7e">tCO2e</span>
                </p>
                {/* <p className="font_14_600 color_green line_height_10">
                  -XX,XX%
                </p> */}
              </div>
              <div className="mt-15">
                <p className="font_12_500 line_height_10 color_7e">
                  Reduced year to date
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-30 dashboard_card_scope_info_root">
          <div className="dashboard_card_scope_graph flex-1 p-20 flex-column">
            <div className="justify_end">
              <div className="dashboard_card_graph_switch">
                <div
                  className={
                    "dashboard_card_graph_switch_tab pointer " +
                    (isMonthWise && "activeTab")
                  }
                  onClick={() => setIsMonthWise(true)}
                >
                  <p className="font_12_500">Month</p>
                </div>
                <div
                  className={
                    "dashboard_card_graph_switch_tab pointer " +
                    (!isMonthWise && "activeTab")
                  }
                  onClick={() => setIsMonthWise(false)}
                >
                  <p className="font_12_500">Year</p>
                </div>
              </div>
            </div>
            <div className="w-100">
              <ScopeOverview
                data={isMonthWise ? emissionOverview : emissionYearWise}
                isMonthWise={isMonthWise}
              />
            </div>
          </div>
          <div className="dashboard_card_scopes_root">
            <div className="dashboard_card_scopes_main">
              {scopeEmission &&
                scopeEmission.length > 0 &&
                scopeEmission.map((scope, i) => (
                  <React.Fragment key={i + scope.name}>
                    <div>
                      <p className="font_14_600 color_57 line_height_10">
                        {scope.name}
                      </p>
                      <p className="font_16_600 line_height_20 mt-15">
                        {localNumber(scope.value)}{" "}
                        <span className="font_12_600 color_7e">tCO2e</span>
                      </p>
                      <div className="mt-10 align_center gap_10">
                        <LinearProgressBar
                          barHeight={4}
                          barClassName={"flex-1"}
                          percent={scope.percent}
                          bg={scope.bg}
                          barBorderRadius={5}
                          borderRadius={5}
                        />
                        <p className="font_12_600 line_height_10">
                          {localNumber(scope.percent)}%
                        </p>
                      </div>
                    </div>
                    {i !== scopeEmission.length - 1 && (
                      <hr className="mt-18 mb-24" />
                    )}
                  </React.Fragment>
                ))}
            </div>
            <div className=" align_center gap_10">
              <div className="align_center gap_10 border_radius_5 bg_white h-30px flex-1 pl-20">
                <p className="font_12_500 text-caption-top">
                  My Accounting items
                </p>
                <div className="circle_16 bg_soft_green center">
                  <p className="color_green font_12_600">{allAccItemLength}</p>
                </div>
              </div>
              <div
                className="h-30px w-30px bg_white border_1_ef border_radius_5 center pointer"
                onClick={() => navigate(`/dashboard?tab=3`)}
              >
                <RightChevronIcon cc={"#00915B"} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-30 container_br_10_p_20 h-70px d-flex align-items-center box_shadow_none">
          <p className="font_14_600 line_height_14 color_57">
            CO2e Budget {new Date().getFullYear()}
          </p>
          <LinearProgressBar
            percent={
              emissionCard
                ? emissionCard.currentEmission > emissionCard.targetEmissions
                  ? 100
                  : (emissionCard.currentEmission * 100) /
                    emissionCard.targetEmissions
                : 0
            }
            bg={
              emissionCard?.currentEmission > emissionCard?.targetEmissions
                ? "#e35700"
                : "#00915B"
            }
            barClassName={"ml-40 flex-1"}
            barWidth={"100%"}
          />
          <div className="align_baseline">
            {emissionCard ? (
              <p className="font_14_700 ml-52 line_height_12">
                <span>{localNumber(emissionCard.currentEmission)}</span>/
                <span className="color_57">
                  {localNumber(emissionCard?.targetEmissions)}
                </span>
              </p>
            ) : (
              <p className="font_14_700 color_7e ml-52">0 / 0</p>
            )}{" "}
            <span className="font_12_600 line_height_12 color_7e ml-2px">
              {" "}
              tCO2e
            </span>
          </div>
        </div>
        <div className="mt-30 dashboard_data_completion_root">
          <div className="dashboard_data_completion_main">
            <div className="dashboard_data_completion_head between">
              <p className="font_16_600 color_57">Data Completion</p>
            </div>
            <div className="dashboard_data_completion_table">
              {filterLogCompData &&
                filterLogCompData.length > 0 &&
                filterLogCompData.map((data, i) => (
                  <div
                    className="dashboard_data_completion_trow"
                    key={Date.now() + i}
                  >
                    <div className="align_center dashboard_data_completion_th_text gap_10">
                      <img
                        src={getServiceIcon(getIconName(data))}
                        alt={data?.name}
                        className="h-20px w-20px"
                      />
                      <p className="font_14_500 color_57 text_overflow-1">
                        {data?.itemDetails?.name}
                      </p>
                    </div>
                    <LinearProgressBar
                      percent={
                        data.noOfScopes
                          ? (getFilledScopes(data.planMonths) /
                              data.noOfScopes) *
                            100
                          : 0
                      }
                      barClassName={"ml-30 dashboard_data_progress mr-24 "}
                      barHeight={10}
                    />
                    <div className="align_center">
                      <p className="font_12_600 color_7e">
                        <span
                          className={
                            getFilledScopes(data.planMonths) === 0
                              ? "color_red"
                              : "color_green"
                          }
                        >
                          {getFilledScopes(data.planMonths)}
                        </span>
                        /<span>{data.noOfScopes}</span>
                      </p>
                      <p className="font_12_600 color_57 ml-10">Subscopes</p>
                      <div className="h-20px w-20px border_1_ef border_radius_5 center ml-20 pointer">
                        <RightChevronIcon
                          cc={"#00915B"}
                          onClick={() =>
                            history(`/dashboard?tab=3&step=1&logId=${data._id}`)
                          }
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      <QuickLog
        quickLogDrawer={quickLogDrawer}
        handleQuickLogDrawer={handleQuickLogDrawer}
      />
      <NotificationDrawer
        open={open}
        handleClose={handleClose}
        notificationData={notificationData}
        getNotification={getNotification}
      />
      <TasksDrawer
        open={taskOpen}
        handleClose={handleTaskDrawerClose}
        targetData={targetData}
        isDashboard={true}
      />
    </div>
  );
};

export default NewDashboard;
