import React, { useEffect, useState } from "react";
import SmallProgressBar from "../ProgressBars/smallProgressBar";
import "./ProductCards.css";

function ProductCard2(props) {
  const [monthCompleted, setMonthCompleted] = useState(0);
  const [totalActiveMonths, setTotalActiveMonths] = useState(12);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "November",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    let currActiveMonths = 0;
    let currTotalActiveMonths = 0;
    props.planMonths.forEach((currMonth, index) => {
      if (currMonth) {
        currTotalActiveMonths++;
        if (props.logStatusYear[index] === props.matrixItemLength)
          currActiveMonths++;
      }
    });
    setMonthCompleted(currActiveMonths);
    setTotalActiveMonths(currTotalActiveMonths);
  }, [props.logStatusYear, props.planMonths]);
  return (
    <div className="productCard_container px-3">
      <div className="productCard_content align_text_left mt-20">
        <p className="f-13 fw-600 align_text_left ">
          LOG STATUS ({props.year})
        </p>
        <div className="row mt-20 mb-2 me-2 justify-content-between">
          <div className="col-md-9 d-flex align-items-center justify-content-around pr-0">
            {props.planMonths &&
              props.planMonths.map((currMonth, index) => {
                if (
                  currMonth &&
                  props.logStatusYear[index] === props.matrixItemLength
                ) {
                  return (
                    <div
                      className="purple_progress_bar_round"
                      key={index}
                    ></div>
                  );
                }
                return <div className="white_progress_bar" key={index}></div>;
              })}
          </div>
          <div className="col-md-2 pl-0">
            <p className="purple_label f-13 fw-600 ml-4">
              {monthCompleted}/{totalActiveMonths}
            </p>
          </div>
        </div>
      </div>

      <div className="productCard_height mt-20">
        <div className="align_text_left">
          <p className="f-13 fw-600 align_text_left">
            LOG STATUS({monthNames[props.month]})
          </p>
        </div>
        <div className="row mt-20 me-1 align-items-center justify-content-between">
          <div className="col-md-9 align-items-center pr-0">
            <SmallProgressBar
              val={(props.logStatusMonth / props.matrixItemLength) * 100}
            />
          </div>
          <div className="col-md-2 pl-0">
            <p className="orange_label f-13 fw-600 ml-4 ">
              {((props.logStatusMonth / props.matrixItemLength) * 100)
                .toFixed(0)
                .toLocaleString()}
              %
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard2;
