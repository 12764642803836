import { VisibilityOutlined, VisibilityOffOutlined } from "@mui/icons-material";
import React from "react";

const ClimateFactComp = ({ year, title, isVisible, handleChange, name }) => {
  return (
    <div className={"climate_fact_comp "+(!isVisible && "bg_f6")}>
      <div>
        <p className="plus_jakarta_24_800">{year}</p>
        <p className="font_9_400 color_57">{title}</p>
      </div>
      {isVisible ? (
        <VisibilityOutlined
          sx={{ height: 20, width: 20 }}
          className="pointer"
          onClick={() => handleChange(false, name)}
        />
      ) : (
        <VisibilityOffOutlined
          sx={{ height: 20, width: 20 }}
          className="pointer"
          onClick={() => handleChange(true, name)}
        />
      )}
    </div>
  );
};

export default ClimateFactComp;
