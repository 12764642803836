import React from "react";

const FilterIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="16"
      fill="none"
      viewBox="0 0 18 16"
    >
      <ellipse
        cx="14"
        cy="12"
        stroke={cc || "#575757"}
        strokeLinecap="round"
        strokeWidth="2"
        rx="3"
        ry="3"
        transform="rotate(90 14 12)"
      ></ellipse>
      <ellipse
        cx="4"
        cy="4"
        stroke={cc || "#575757"}
        strokeLinecap="round"
        strokeWidth="2"
        rx="3"
        ry="3"
        transform="rotate(90 4 4)"
      ></ellipse>
      <path
        stroke={cc || "#575757"}
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 12h10M8 4h9"
      ></path>
    </svg>
  );
};

export default FilterIcon;
