import React from "react";

const SelectQuestionPlate = ({ question, handleSelectAnswer }) => {
  return (
    <div>
      <p className="heading_9">{question.question}</p>
      <div className="mt-40">
        {question.options.map((option) => (
          <div className="d-flex csrd_assessment_input_radio mb-24" key={option}>
            <input
              type="radio"
              id={option}
              name="answer"
              checked={question.answer === option}
              onChange={() => handleSelectAnswer(option)}
            />
            <label
              htmlFor={option}
              className="csrd_assessment_radio-label font_15_500 ml-8"
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SelectQuestionPlate;
