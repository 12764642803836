import React, { useState } from "react";
import "./productDetailsTabs.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ProductTarget from "./Components/ProductTarget";
import FeedTable from "./Components/FeedTable";
import ProductDetailAnalytics from "./Components/Analytics";
import ProductDetailLabel from "./Components/ProductDetailLabel";
import ItemSettings from "./Components/ItemSettings";
import { useNavigate } from "react-router-dom";
import useQuery from "../../../../../../Utils/query";
import { useAuth } from "../../../../../../Utils/Auth";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ProductDetailsTab(props) {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const query = useQuery();
  const logCreateMonth = Number(query.get("month"));
  const logCreateYear = Number(query.get("year"));
  const { profile } = useAuth();
  const handleChange = (event, newValue) => {
    setValue(newValue);
    props.setsubDetailsTab(newValue);
    navigate(
      `/dashboard?tab=3&step=1&logId=${props.logId}&subtab=${newValue}&year=${logCreateYear}&month=${logCreateMonth}`
    );
  };

  React.useEffect(() => {
    if (props.subDetailsTab || props.subDetailsTab === 0) {
      setValue(props.subDetailsTab);
    }
  }, [props.subDetailsTab]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          className="general_root_tabs"
        >
          <Tab label="Feed" {...a11yProps(0)} />
          <Tab
            label="Analytics"
            {...a11yProps(1)}
            disabled={!profile.hasBaseCredit || props.isPaused}
          />
          <Tab
            label="Targets"
            {...a11yProps(2)}
            disabled={!profile.hasBaseCredit || props.isPaused}
          />
          <Tab
            label="Label"
            {...a11yProps(3)}
            disabled={!profile.hasBaseCredit || props.isPaused}
          />
          <Tab
            label="Item Settings"
            {...a11yProps(4)}
            disabled={!profile.hasBaseCredit || props.isPaused}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="general_tabpanel">
        <FeedTable
          logId={props.logId}
          year={props.year}
          isPaused={props.isPaused}
          isCrateCellUpdate={props.isCrateCellUpdate}
        />
      </TabPanel>
      <TabPanel value={value} index={1} className="general_tabpanel">
        <ProductDetailAnalytics
          yearCreated={props.yearCreated}
          monthCreated={props.monthCreated}
          isCrateCellUpdate={props.isCrateCellUpdate}
          logId={props.logId}
          yearList={props.yearList}
        />
      </TabPanel>
      <TabPanel value={value} index={2} className="general_tabpanel">
        <ProductTarget isCrateCellUpdate={props.isCrateCellUpdate} />
      </TabPanel>
      <TabPanel value={value} index={3} className="general_tabpanel">
        <ProductDetailLabel isCrateCellUpdate={props.isCrateCellUpdate} />
      </TabPanel>
      <TabPanel value={value} index={4} className="general_tabpanel">
        <ItemSettings
          logId={props.logId}
          itemName={props.itemName}
          year={props.year}
          isCrateCellUpdate={props.isCrateCellUpdate}
        />
      </TabPanel>
    </Box>
  );
}
