import React, { useState, useEffect } from 'react'
import './addressInfo.css'
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'
import TextInputType from '../../../../../TextInputType'
import ButtonComp from '../../../../../../../../../../../Assets/components/Button'
import PlusCounter from '../../../../../../../../../../../Assets/icons/plusCounter'
import SelectInputType from '../../../../../../Components/SelectInputType'
import Axios from '../../../../../../../../../../../Api'
import { toastResponseError } from '../../../../../../../../../../../Utils/toastResponseError'
import { useAuth } from '../../../../../../../../../../../Utils/Auth'
import toast from 'react-hot-toast'
import { useMapStore } from '../../../../../../../../../../../Utils/CompanyMapStore'

function AddressInfo(props) {
    const {handleSelectedAddressChange,selectedAddress}= useMapStore()
    const { setSessionExpire } = useAuth()
    const [isAccordionExpanded, setIsAccordionExpanded] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [streetNumber, setStreetNumber] = useState("");
    const [houseNumber, setHouseNumber] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [VATnumber, setVATNumber] = useState("");
    const [countryCode, setCountryCode] = useState("");
    const [stateCode, setStateCode] = useState("");

    const setAddressToDefault = () => {
        setFirstName("")
        setLastName("")
        setEmail("")
        setStreetNumber("")
        setHouseNumber("")
        setZipCode("")
        setCity("")
        setCountry("")
        setCountryCode("")
        setStateCode("")
        setVATNumber("")
        setCompany("")
        setState("")
        setStates([])
        setCities([])
    }
    useEffect(() => {
        if (isEdit) {
            setFirstName(props.addressDetails.firstName)
            setLastName(props.addressDetails.lastName)
            setEmail(props.addressDetails.emailAddress)
            setStreetNumber(props.addressDetails.street)
            setHouseNumber(props.addressDetails.houseNumber)
            setZipCode(props.addressDetails.zipCode)
            setCity(props.addressDetails.City)
            setCountry(props.addressDetails.Country)
            setCountryCode(props.addressDetails.countryCode)
            setStateCode(props.addressDetails.stateCode)
            setVATNumber(props.addressDetails.VATnumber)
            setCompany(props.addressDetails.CompanyName)
            setState(props.addressDetails.State)
        }
        else setAddressToDefault()
    }, [isEdit])
    useEffect(() => {
        fetchCountries()
    }, [])

    useEffect(() => {
        if (countryCode.length > 0) fetchStates()
    }, [countryCode])

    useEffect(() => {
        if (countryCode && countryCode.length > 0 && stateCode && stateCode.length > 0) fetchCities()
    }, [stateCode])
    const fetchCountries = async () => {
        try {
            const response = await Axios.get(`/location/countries`);
            setCountries(response.data);
        } catch (error) {
            toastResponseError(error, setSessionExpire)
        }
    };
    const fetchStates = async () => {
        try {
            const response = await Axios.get(`/location/country/${countryCode}`);
            setStates(response.data);
        } catch (error) {
            toastResponseError(error, setSessionExpire)
        }
    };

    const fetchCities = async () => {
        try {
            const response = await Axios.get(
                `/location/cities/${countryCode}/${stateCode}`
            );
            setCities(response.data);
        } catch (error) {
            toastResponseError(error, setSessionExpire)
        }
    };

    const handleFirstNameChange = (index, value) => { setFirstName(value) }
    const handleLastNameChange = (index, value) => { setLastName(value) }
    const handleEmailChange = (index, value) => { setEmail(value) }
    const handleCompanyChange = (index, value) => { setCompany(value) }
    const handleStreetNumberChange = (index, value) => { setStreetNumber(value) }
    const handleHouseNumberChange = (index, value) => { setHouseNumber(value) }
    const handleZipCodeChange = (index, value) => { setZipCode(value) }
    const handleCityChange = (index, value) => { setCity(value) }
    const handleStateChange = (index, value) => { setState(value.split("#")[1]); setStateCode(value.split("#")[0]); }
    const handleCountryChange = (index, value) => { setCountry(value.split("#")[1]); setCountryCode(value.split("#")[0]); }
    const handleVATNumberChange = (index, value) => { setVATNumber(value) }

    const handleEditAddressSubmit = async () => {
        let address={
            _id:props.addressDetails._id,
            firstName: firstName,
            lastName: lastName,
            emailAddress: email,
            street: streetNumber,
            houseNumber: houseNumber,
            zipCode: zipCode,
            City: city,
            Country: country,
            countryCode: countryCode,
            stateCode: stateCode,
            VATnumber: VATnumber,
            CompanyName: company,
            State: state,
            isDefault: props.addressDetails.isDefault}
        try {
            await Axios.put(`/address/edit-address/${props.addressDetails._id}`,
                {
                    ...address
                }
            ).then(res => {
                setFirstName("")
                setLastName("")
                setEmail("")
                setStreetNumber("")
                setHouseNumber("")
                setZipCode("")
                setCity("")
                setCountry("")
                setCountryCode("")
                setStateCode("")
                setVATNumber("")
                setCompany("")
                setState("")
                setStates([])
                setCities([])
                setIsEdit(false)
                if (res.data.success) toast.success(res.data.message)
                props.getAllAddress()
            })
        } catch (error) {
            toastResponseError(error, setSessionExpire)
        }
    }
    const handleAddressSubmit = async () => {
        try {
            await Axios.post(props.isNewAddress ? "/address/add-address" :
                `/address/edit-address/${props.addressDetails._id}`,
                {
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: email,
                    street: streetNumber,
                    houseNumber: houseNumber,
                    zipCode: zipCode,
                    City: city,
                    Country: country,
                    countryCode: countryCode,
                    stateCode: stateCode,
                    VATnumber: VATnumber,
                    CompanyName: company,
                    State: state,
                    isDefault: false,
                }
            ).then(res => {
                setFirstName("")
                setLastName("")
                setEmail("")
                setStreetNumber("")
                setHouseNumber("")
                setZipCode("")
                setCity("")
                setCountry("")
                setCountryCode("")
                setStateCode("")
                setVATNumber("")
                setCompany("")
                setState("")
                setStates([])
                setCities([])
                if (res.data.success) toast.success(res.data.message)
                props.getAllAddress()
            })
        } catch (error) {
            toastResponseError(error, setSessionExpire)
        }
    }
    return (
        <div className="addressInfo_container">
            <Accordion
                expanded={isAccordionExpanded}
            >
                <AccordionSummary
                    expandIcon={""}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <div className="d-flex align-items-center">
                            <p
                                onClick={() => { setIsAccordionExpanded(!isAccordionExpanded) }}
                                className="font_18_500">
                                {props.isNewAddress ? "New Address" :
                                    props.addressDetails.CompanyName
                                }
                            </p>
                            {props.addressDetails && props.addressDetails?.isDefault ? <p className="green_label ml-25">Default</p> : ""}
                        </div>

                        <div>
                            {props.isNewAddress ?
                                <div
                                    onClick={() => {
                                        setIsAccordionExpanded(!isAccordionExpanded)
                                    }}
                                >
                                    <PlusCounter />
                                </div>
                                :
                                <div className="d-flex justify-content-end align-items-center">
                                    
                                    <ButtonComp
                                    status={3}
                                    onClick={() => handleSelectedAddressChange(props.addressDetails)}
                                    disabled={selectedAddress._id === props.addressDetails._id}
                                >
                                    Select
                                </ButtonComp>
                                   <div className="ml-20">
                                   <i
                                    onClick={() => {
                                        setIsEdit(true)
                                        setIsAccordionExpanded(true)
                                    }}
                                    className="bi bi-pencil"></i>
                                   </div>
                                </div>
                            }

                        </div>
                    </div>
                </AccordionSummary>
                <AccordionDetails>

                    {!isEdit && !props.isNewAddress ?
                        <div className="pl-30  pr-30 mt-35 mb-30">
                            <p className="font_14_400">{props.addressDetails.CompanyName}</p>
                            <p className="font_14_400">{props.addressDetails.firstName} {props.addressDetails.lastName}</p>
                            <p className="font_14_400">{props.addressDetails.City} {props.addressDetails.street} </p>
                            <p className="font_14_400">{props.addressDetails.zipCode}</p>
                            <p className="font_14_400">{props.addressDetails.State} {props.addressDetails.country}</p>

                        </div>
                        :
                        <div className="pl-30 pr-30 mt-30">

                            <div>
                                <p className="font_12_600 color_7e mb-10">Company Name</p>
                                <TextInputType
                                    answerValue={company}
                                    handleAnswerChange={handleCompanyChange}
                                />
                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-30">
                                <div>
                                    <p className="font_12_600 color_7e mb-10">First Name</p>
                                    <TextInputType
                                        answerValue={firstName}
                                        handleAnswerChange={handleFirstNameChange}
                                    />
                                </div>

                                <div className="ml-14">
                                    <p className="font_12_600 color_7e mb-10">Last Name</p>
                                    <TextInputType
                                        answerValue={lastName}
                                        handleAnswerChange={handleLastNameChange}
                                    />
                                </div>
                            </div>


                            <div className="d-flex justify-content-between align-items-center mt-30">
                                <div className="flex-grow-1">
                                    <p className="font_12_600 color_7e mb-10">Email</p>
                                    <TextInputType
                                        answerValue={email}
                                        type={"email"}
                                        handleAnswerChange={handleEmailChange}
                                    />
                                </div>

                                <div className="ml-14 flex-grow-1">
                                    <p className="font_12_600 color_7e">City</p>
                                    <SelectInputType
                                        options={cities}
                                        selectedValue={city}
                                        type={"city"}
                                        handleAnswerChange={handleCityChange}
                                    />
                                </div>
                            </div>


                            <div className="d-flex justify-content-between align-items-center mt-30">
                                <div className="flex-grow-1">
                                    <p className="font_12_600 color_7e">State</p>
                                    <SelectInputType
                                        options={states}
                                        stateName={state}
                                        stateCode={stateCode}
                                        type={"state"}
                                        handleAnswerChange={handleStateChange}
                                    />
                                </div>

                                <div className="ml-14 flex-grow-1">
                                    <p className="font_12_600 color_7e">Country</p>
                                    <SelectInputType
                                        options={countries}
                                        countryName={country}
                                        countryCode={countryCode}
                                        type={"country"}
                                        handleAnswerChange={handleCountryChange}
                                    />
                                </div>
                            </div>



                            <div className="d-flex justify-content-between align-items-center mt-30">
                                <div>
                                    <p className="font_12_600 color_7e mb-10">Street Number</p>
                                    <TextInputType
                                        answerValue={streetNumber}
                                        type={"text"}
                                        handleAnswerChange={handleStreetNumberChange}
                                    />
                                </div>

                                <div className="ml-14">
                                    <p className="font_12_600 color_7e mb-10">Zip Code</p>
                                    <TextInputType
                                        answerValue={zipCode}
                                        type={"text"}
                                        handleAnswerChange={handleZipCodeChange}
                                    />
                                </div>
                            </div>

                            <div className="d-flex justify-content-between align-items-center mt-30">
                                <div>
                                    <p className="font_12_600 color_7e mb-10">House Number</p>
                                    <TextInputType
                                        answerValue={houseNumber}
                                        type={"text"}
                                        handleAnswerChange={handleHouseNumberChange}
                                    />
                                </div>

                                <div className="ml-14">
                                    <p className="font_12_600 color_7e mb-10">VAT (Optional)</p>
                                    <TextInputType
                                        answerValue={VATnumber}
                                        type={"text"}
                                        handleAnswerChange={handleVATNumberChange}
                                    />
                                </div>
                            </div>



                            <div className="d-flex justify-content-end align-items-center mt-30">
                                {props.isNewAddress ?
                                    <ButtonComp
                                        onClick={() => { handleAddressSubmit() }}
                                        status={5}>
                                        Save
                                    </ButtonComp> :
                                    <ButtonComp
                                        onClick={() => { handleEditAddressSubmit() }}
                                        status={5}>
                                        Save
                                    </ButtonComp>
                                }


                            </div>

                        </div>
                    }

                </AccordionDetails>
            </Accordion>


        </div>
    )
}

export default AddressInfo