import React, { useEffect, useState } from "react";
import { useAuth } from "../Utils/Auth";
import Icon from "../Assets/components/logo_sm";
import CryptoJS from "crypto-js";
import axios from "../Api";
import { toast } from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import ButtonComp from "../Assets/components/Button";
import { AnimationScreens, AnimationVideos } from "../Utils/CommonFunctions";

const AdminLogin = () => {
  const {
    setIsUser,
    setUser,
    setProfile,
    setName,
    setIsVerified,
    setRole,
    setLoadAdminCurrent,
    loadAdminCurrent,
  } = useAuth();
  const navigate = useNavigate();
  const [otp, setOtp] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [showPassword, setShowPassword] = useState(false);
  const [otpStatus, setOtpStatus] = useState(null);
  const [timer, setTimer] = useState(59);
  const [timerRunning, setTimerRunning] = useState(false);
  const [disabled, setIsDisabled] = useState(false);

  useEffect(() => {
    setIsUser(false);
    setUser(null);
    setProfile(null);
    setName(null);
    setIsVerified(null);
    setRole(null);
    localStorage.clear();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsDisabled(true);
      await axios
        .post("/user/auth/admin/login", {
          email,
          password: CryptoJS.AES.encrypt(
            password,
            "H~jP,/%6R*'Tc|xg"
          ).toString(),
        })
        .then((res) => {
          setIsDisabled(false);
          if (res.data.success) {
            toast.success("OTP Sent");
            setTimerRunning(true);
            setOtpStatus("sent");
          }
        })
        .catch((err) => {
          setIsDisabled(false);
          toast.error(err.response.data.message);
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const sendOtp = async () => {
    setOtp();
    setIsDisabled(true);
    await axios({
      url: `/user/auth/admin/resend-otp`,
      method: "POST",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success("Otp sent");
          setTimer(59);
          setTimerRunning(true);
        } else {
          toast.error(res.data.error);
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        console.error(err);
        setIsDisabled(false);
        // toastResponseError(err, setSessionExpire);
      });
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    if (!otp) {
      return toast.error("Invalid OTP");
    }
    setIsDisabled(true);
    await axios({
      url: `/user/auth/admin/login-otp-verify`,
      method: "POST",
      data: {
        code: otp,
      },
    })
      .then((response) => {
        if (response.data.success) {
          setLoadAdminCurrent(!loadAdminCurrent);
          navigate(`/admin-check`);
          toast.success("Logged in");
        } else {
          toast.error("Invalid OTP");
          setOtp(null);
        }
        setIsDisabled(false);
      })
      .catch((err) => {
        setIsDisabled(false);
        console.error(err);
        // toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    let interval = null;

    if (timerRunning) {
      interval = setInterval(() => {
        setTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    if (timer === 0) {
      setTimerRunning(false);
    }
  }, [timer]);

  return (
    <div className="container-fluid">
      <div className=" row  auth_background">
        <div
          className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
          style={{
            backgroundImage: `url(${AnimationScreens[7]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <video
            className="reg_vdo"
            src={AnimationVideos[7]}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
            controls={false}
            poster={AnimationScreens[7]}
          ></video>
        </div>
        <div className="d-flex col-md-5  align-items-center auth_info_container">
          <div className="col-md-10 ml-32 max_width_552">
            <form className="d-flex flex-column">
              <div className="auth_text_start align_text_left ml-2 fw-600 mt-16">
                <Icon />
              </div>
              <div className="form-group auth_text_start align_text_left ml-2 fw-600 mt-24">
                <p className="f-26 mb-2">Admin Login</p>
              </div>
              {otpStatus === "sent" ? (
                <>
                  <div className="otp_input mt-24">
                    <OtpInput
                      value={otp}
                      onChange={setOtp}
                      numInputs={6}
                      renderSeparator={<span className="input_otp_span"></span>}
                      className="otp_input"
                      renderInput={(props) => <input {...props} />}
                    />
                    <div className="mt-12">
                      {timerRunning && timer > 0 ? (
                        <p className="overline_text">
                          Resend otp in :{" "}
                          <span>{String(timer).padStart(2, "0")}</span> sec
                        </p>
                      ) : (
                        <p
                          className="overline_text pointer underline"
                          onClick={() => sendOtp()}
                        >
                          Resend OTP
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="mt-6rem d-flex align-items-center justify-content-end">
                    <ButtonComp
                      status={2}
                      className="ml-16"
                      onClick={(e) => verifyOtp(e)}
                      disabled={disabled}
                    >
                      Verify
                    </ButtonComp>
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group auth_text_start align_text_left ml-2 fw-600 mt-24">
                    <input
                      type="email"
                      className="form-control auth_input  border-bottom  shadow-none"
                      id="email"
                      placeholder="Email"
                      aria-describedby="emailHelp"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group auth_text_start align_text_left ml-2 fw-600 mt-24">
                    <div className="d-flex ">
                      <div className="flex-grow-1">
                        <div className="reset_pass_input">
                          <input
                            type={"password"}
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                            name="password"
                            placeholder="Password"
                            id="password"
                            className="form-control auth_input border-bottom  shadow-none"
                          />
                          {/* <button
                            className="auth_input_pas_btn2"
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <ButtonComp
                    type="submit"
                    status={1}
                    className="lg mt-32 fw-600"
                    onClick={handleSubmit}
                  >
                    Login
                  </ButtonComp>
                  <ButtonComp
                    type="submit"
                    status={2}
                    className=" mt-32 fw-600"
                    onClick={() => navigate("/")}
                  >
                    Go to User Login
                  </ButtonComp>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
