import React from "react";

function GraphFall(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="10"
        fill="none"
        viewBox="0 0 16 10"
      >
        <path
          stroke={props.stroke}
          fill="#000"
          d="M13.07 8.357a.719.719 0 01-.51-.213L8.54 4.127 6.194 6.475a.723.723 0 01-1.023 0L.517 1.821A.723.723 0 111.54.798l4.144 4.144L8.03 2.594a.724.724 0 011.023 0l4.528 4.529a.722.722 0 01-.51 1.234z"
        ></path>
        <path
          stroke={props.stroke}
          fill="#000"
          fillRule="evenodd"
          d="M1.362.974a.472.472 0 10-.668.67L5.348 6.3a.473.473 0 00.67 0L8.54 3.772l4.195 4.195a.47.47 0 00.668 0h.001a.472.472 0 000-.668L8.876 2.77a.474.474 0 00-.67 0L5.683 5.296 1.362.974zM.34.621a.972.972 0 011.375 0l3.968 3.968 2.17-2.172a.975.975 0 011.377 0l4.528 4.529a.972.972 0 11-1.376 1.375l-3.84-3.84L6.37 6.651a.974.974 0 01-1.376 0L.34 1.998A.973.973 0 01.34.62z"
          clipRule="evenodd"
        ></path>
        <path
          stroke={props.stroke}
          fill="#000"
          d="M14.231 9.509h-3.976a.724.724 0 010-1.447h3.254V4.779a.722.722 0 111.445 0v4.008a.723.723 0 01-.723.723z"
        ></path>
        <path
          stroke={props.stroke}
          fill="#000"
          fillRule="evenodd"
          d="M14.231 4.305a.472.472 0 00-.472.473v3.535h-3.504a.473.473 0 100 .946h3.976a.473.473 0 00.473-.473V4.778a.472.472 0 00-.473-.473zm-.972.473a.972.972 0 111.945 0v4.008a.973.973 0 01-.973.973h-3.976a.974.974 0 010-1.947h3.004V4.779z"
          clipRule="evenodd"
        ></path>
      </svg>
    </div>
  );
}

export default GraphFall;
