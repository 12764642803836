import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import useQuery from "../../../../../../../../Utils/query";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import Axios from "../../../../../../../../Api";
import { toast } from "react-hot-toast";
import SubmitSurveyPopup from "./Components/submitSurveyPopup";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../../Utils/Auth";
import "./index.css";
import { KeyboardArrowRight } from "@mui/icons-material";

function FillSurveyForm(props) {
  const { setSessionExpire } = useAuth();
  const query = useQuery();
  //  const enSurveyId = query.get("surveyId")
  const [surveyName, setSurveyName] = useState("");
  const [surveyDesp, setSurveyDesp] = useState("");
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [isSubmitPopupOpen, setSubmitPopupOpen] = useState(false);
  const [enSurveyId, setEnSurveyId] = useState(
    query.get("surveyId") ? query.get("surveyId") : ""
  );
  const [enUserId, setEnUserId] = useState(
    query.get("user") ? query.get("user") : ""
  );
  //const enUserId= query.get("user")
  const handleSubmitPopupClose = () => {
    setSubmitPopupOpen(false);
  };
  useEffect(() => {
    if (props.surveyId && props.surveyId.length > 0)
      setEnSurveyId(props.surveyId);

    if (props.userId && props.userId.length > 0) setEnUserId(props.userId);
  }, [props.surveyId, props.userId]);

  const handleAnswerChange = (index, optionIndex) => {
    let tempAnswers = [...answers];
    tempAnswers[index] = optionIndex;
    setAnswers(tempAnswers);
  };
  const getSurveyQuestion = async () => {
    try {
      await Axios.post(`/surveys/getSurvey`, {
        surveyId: enSurveyId.toString(),
        userId: enUserId.toString(),
      }).then((res) => {
        // console.log("this is response ", res.data.data)

        if (res.data.success) {
          let tempAnswers = [];
          res.data.data.questions.forEach((currQ, index) => {
            if (currQ.type == 0) {
              tempAnswers[index] = -1;
            } else if (currQ.type == 1) {
              tempAnswers[index] = "";
            } else if (currQ.type == 2) {
              tempAnswers[index] = -1;
            } else if (currQ.type == 3) {
              tempAnswers[index] = -1;
            }
          });
          // console.log("this is it ",res.data.data)
          setAnswers(tempAnswers);
          setSurveyName(res.data.data.surveyName);
          setSurveyDesp(res.data.data.surveyDescription);
          setQuestions(res.data.data.questions);
        } else {
          toast.error("survey not available");
        }
      });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  useEffect(() => {
    // var surveyId= CryptoJS.AES.decrypt(enSurveyId,"H~jP,/%6R*'Tc|xg")
    // console.log("first ",surveyId)
    // let x=surveyId.toString(CryptoJS.enc.Utf8);
    // console.log("decreiptyed ",x)
    if (enSurveyId && enUserId && enSurveyId.length > 0 && enUserId.length > 0)
      getSurveyQuestion();
  }, [enSurveyId, enUserId]);

  const handleAnswerSubmit = async () => {
    let foundEmpty = false;
    questions.map((currQ, index) => {
      if (currQ.type == 0 && answers[index] == -1) {
        foundEmpty = true;
        toast.error(`please select option for question no: ${index + 1}`);
      } else if (currQ.type == 1 && answers[index].trim().length == 0) {
        foundEmpty = true;
        toast.error(`please answer question no: ${index + 1}`);
      } else if (currQ.type == 2 && answers[index] == -1) {
        foundEmpty = true;
        toast.error(`please share your rating for question no: ${index + 1}`);
      } else if (currQ.type == 3 && answers[index] == -1) {
        foundEmpty = true;
        toast.error(`please select option for question no: ${index + 1}`);
      }
    });
    if (foundEmpty) return;

    try {
      await Axios.post(`/surveys/submit-user-response`, {
        userId: enUserId,
        answers: answers,
        surveyId: enSurveyId,
      }).then(() => {
        setSubmitPopupOpen(true);
        if (props.isForInternal) {
          props.handleCloseDrawer();
          props.handleClose()
          props.getUserSurveys()
        } else {
          window.location.replace("https://today.green");
        }
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  return (
    <div
      className={
        props.isForInternal
          ? "survey_form_fill_internal_root"
          : "ml-140 mt-40 mr-140 pb-24"
      }
    >
      <SubmitSurveyPopup
        open={isSubmitPopupOpen}
        handleClose={handleSubmitPopupClose}
        isForInternal={props.isForInternal}
        handleCloseDrawer={()=>props.handleClose()}
      />
      <div
        className={
          props.isForInternal
            ? "survey_form_fill_internal_main"
            : "d-flex justify-content-center"
        }
      >
        <div
          className={
            props.isForInternal
              ? "fill_survey_internal_max_width flex-1"
              : "fill_survey_max_width"
          }
        >
          <div>
            <p className="font_20_700 border_radius_5 bg_007 color_white display_inline_block pl-10 pt-7 pb-7 pr-10 text-uppercase">
              {surveyName}
            </p>

            <p className="font_12_400 color_65 w-80 mt-20">{surveyDesp}</p>
          </div>
          <div className="mt-60">
            <div>
              {questions &&
                questions.map((currQuestion, index) => {
                  return (
                    <div key={index}>
                      {currQuestion.type == 0 ? (
                        <div className={`${index == 0 ? "" : "mt-20"} `}>
                          <p className="font_11_500 color_4c">
                            Question ({index + 1})
                          </p>

                          <div className="border-d7 border_radius_5 pt-24 pl-30 mt-6">
                            <p className="font_13_600 underline-e5">
                              {index + 1} {currQuestion.question}
                            </p>

                            <p className="font_9_500 color_ba mt-5px">
                              Multiple Choice
                            </p>

                            <div className="mt-30 mb-40">
                              {currQuestion.options.map(
                                (currOption, optionIndex) => {
                                  return (
                                    <div
                                      key={currOption + optionIndex}
                                      className={`d-flex pointer width_fit ${
                                        optionIndex != 0 ? "mt-10" : ""
                                      }`}
                                      onClick={() => {
                                        handleAnswerChange(index, optionIndex);
                                      }}
                                    >
                                      <p
                                        className={`span_cirlce h-14px w-14px ${
                                          answers[index] == optionIndex
                                            ? "border-009-4"
                                            : "border-e8"
                                        } bg_white`}
                                      ></p>

                                      <p className="font_12_500 color_4c ml-14">
                                        {currOption}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : currQuestion.type == 1 ? (
                        <div className={`${index == 0 ? "" : "mt-20"} `}>
                          <p className="font_11_500 color_4c">
                            Question ({index + 1})
                          </p>

                          <div className="border-d7 border_radius_5 pt-24 pl-30 mt-6">
                            <p className="font_13_600 underline-e5">
                              {index + 1} {currQuestion.question}
                            </p>

                            <p className="font_9_500 color_ba mt-5px">
                              Open Ended Question
                            </p>

                            <div className="createSurvey_question_container mt-30 mr-20 mb-40">
                              <TextField
                                value={answers[index]}
                                onChange={(e) => {
                                  handleAnswerChange(index, e.target.value);
                                }}
                                placeholder="Your Answer..."
                                className="mt-12"
                                size="small"
                                fullWidth
                              />
                            </div>
                          </div>
                        </div>
                      ) : currQuestion.type == 2 ? (
                        <div className={`${index == 0 ? "" : "mt-20"} `}>
                          <p className="font_11_500 color_4c">
                            Question ({index + 1})
                          </p>

                          <div className="border-d7 border_radius_5 pt-24 pl-30 mt-6">
                            <p className="font_13_600 underline-e5">
                              {index + 1} {currQuestion.question}
                            </p>

                            <p className="font_9_500 color_ba mt-5px">
                              Rating Scale
                            </p>

                            <div className="d-flex mt-30 mr-20 mb-40">
                              {new Array(parseInt(currQuestion.scaleEnd))
                                .fill(0)
                                .map((val, scaleIndex) => {
                                  return (
                                    <div
                                      key={scaleIndex + val}
                                      onClick={() => {
                                        handleAnswerChange(
                                          index,
                                          scaleIndex + 1
                                        );
                                      }}
                                      className={`${
                                        scaleIndex + 1 === answers[index]
                                          ? "border-55-2"
                                          : "border-e8"
                                      } border_radius_5 pl-20 pt-12 pr-20 pb-12 ml-24 pointer`}
                                    >
                                      {scaleIndex + 1}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      ) : currQuestion.type == 3 ? (
                        <div className={`${index == 0 ? "" : "mt-20"} `}>
                          <p className="font_11_500 color_4c">
                            Question ({index + 1})
                          </p>

                          <div className="border-d7 border_radius_5 pt-24 pl-30 mt-6">
                            <p className="font_13_600 underline-e5">
                              {index + 1} {currQuestion.question}{" "}
                            </p>

                            <p className="font_9_500 color_ba mt-5px">
                              Likert Scale
                            </p>

                            <div className="mt-30 mb-40">
                              {currQuestion.options.map(
                                (currOption, optionIndex) => {
                                  return (
                                    <div
                                      key={currOption + optionIndex}
                                      className={`d-flex pointer width_fit ${
                                        optionIndex != 0 ? "mt-10" : ""
                                      }`}
                                      onClick={() => {
                                        handleAnswerChange(index, optionIndex);
                                      }}
                                    >
                                      <p
                                        className={`span_cirlce h-14px w-14px ${
                                          answers[index] == optionIndex
                                            ? "border-009-4"
                                            : "border-e8"
                                        } bg_white`}
                                      ></p>

                                      <p className="font_12_500 color_4c ml-14">
                                        {currOption}
                                      </p>
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="d-flex justify-content-end mt-15 mb-30">
            {questions.length > 0 ? (
              <ButtonComp
                status={5}
                onClick={() => {
                  handleAnswerSubmit();
                }}
                className="h-40px"
              >
                Send{" "}
                <KeyboardArrowRight
                  htmlColor="#fff"
                  sx={{ marginLeft: "3px" }}
                />
              </ButtonComp>
            ) : (
              ""
            )}
          </div>
        </div>
        {props.isForInternal && (
          <div className="ml-52 pointer">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              fill="none"
              viewBox="0 0 36 36"
              onClick={() => {
                props.handleClose();
              }}
            >
              <path
                stroke="#4C4C4C"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M27 9L9 27M9 9l18 18"
              ></path>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
}

export default FillSurveyForm;
