import React, { useEffect, useState } from "react";
import useQuery from "../../../Utils/query";
// import axios from "../../../Api/index";
// import fileDownload from "js-file-download";
import { useNavigate } from "react-router";
// import { Link } from "react-router-dom";
// import bg from ".././../../Assets/images/Placeholder_GIF.gif";
import ButtonComp from "../../../Assets/components/Button";
import {
  AnimationScreens,
  AnimationVideos,
} from "../../../Utils/CommonFunctions";
import BackForwardButton from "../../../Assets/components/BackForwardButton/BackForwardButton";
import PressEnter from "../../../Assets/components/PressEnter/PressEnter";

export default function RegisterSuccess() {
  const [isDownloaded, setisDownloaded] = useState(false);
  const query = useQuery();
  const history = useNavigate();
  let invoiceDoc = query.get("invoice");

  // const handleDownload = async () => {
  //   // if (invoiceDoc && invoiceName) {
  //   //   const response = await axios.get(`/cart/download/invoice/${invoiceDoc}`, {
  //   //     responseType: "arraybuffer",
  //   //     responseEncoding: "binary",
  //   //   });
  //   //   fileDownload(response.data, invoiceName + "-Invoice-Today-Green");
  //   // }
  //   setisDownloaded(true);
  // };

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      if (isDownloaded) {
        history("/register/complete");
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [isDownloaded]);

  return (
    <div className="container-fluid">
      <div className="row register_background auth_background">
        <div
          className="d-flex flex-column col-md-7 justify-content-center align-items-center bg_left_onboard_img "
          style={{
            backgroundImage: `url(${AnimationScreens[7]})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <video
            className="reg_vdo"
            src={AnimationVideos[7]}
            autoPlay
            playsInline
            loop
            preload="auto"
            muted
            controls={false}
            poster={AnimationScreens[7]}
          ></video>
        </div>
        <div className="d-flex col-md-5 align-items-center register_info_container">
          <div className="max_width_552 d-flex align-items-start flex-column justify-content-center ml-32 col-md-10">
            <p className="purple_label mb-16">Congratulations</p>
            <p className="heading_1 mt-16 mb-16 text-start">
              Your account has been created! Download the invoice now.
            </p>
            {isDownloaded ? (
              <p className="py-3 blue_text ">✅ Invoice has been downloaded</p>
            ) : (
              <a href={invoiceDoc} target="_blank" rel="noopener noreferrer">
                <ButtonComp
                  status={1}
                  className="lg"
                  onClick={() => {
                    setisDownloaded(true);
                  }}
                >
                  Download Invoice
                </ButtonComp>
              </a>
            )}
            <div className="d-flex align-items-baseline justify-content-between mt-32 w-100">
              <BackForwardButton />
              <div>
                <ButtonComp
                  // className={`${
                  //   !isDownloaded
                  //     ? "secondary_button_inactive"
                  //     : "secondary_button_active"
                  // }`}
                  status={1}
                  onClick={() => history("/register/complete")}
                  disabled={!isDownloaded}
                >
                  Complete Set-Up
                </ButtonComp>
                <PressEnter />
              </div>
            </div>
            {/* <div className="mt-30 register_button text-end w-100">
              <ButtonComp
                // className={`${
                //   !isDownloaded
                //     ? "secondary_button_inactive"
                //     : "secondary_button_active"
                // }`}
                status={1}
                onClick={() => history("/register/complete")}
                disabled={!isDownloaded}
              >
                Complete Set-Up
              </ButtonComp>
            </div>
            <div className="d-flex justify-content-between mt-10 w-100">
              <div>
                <i
                  className={`pointer bi bi-chevron-left register_button_disable`}
                  //   onClick={() =>
                  //     props.decQuestionNo(props.regDetails[props.target])
                  //   }
                ></i>
                <i
                  className={`pointer bi bi-chevron-right register_button_disable
                  
                  `}
                  //   onClick={() =>
                  //     props.incQuestionNo(props.regDetails[props.target])
                  //   }
                ></i>
              </div>

              <div className="d-flex">
                <p className="register_enter"> Press Enter</p>
                <i className="bi bi-arrow-return-left"></i>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
