import React, { useState, useMemo, useRef } from "react";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { toast } from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Quill from "quill";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import { useEffect } from "react";
import ButtonComp from "../../../../Assets/components/Button";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import { useNavigate } from "react-router-dom";
import CoverImageCropper from "../../../../Components/CommonComponents/CoverImageCropper/CoverImageCropper";
import Axios from "../../../../Api";
import parse from "html-react-parser";
import { Close } from "@mui/icons-material";
Quill.register("modules/imageResize", ImageResize);

const fontSizeStyle = Quill.import("attributors/style/size");
fontSizeStyle.whitelist = [
  "8px",
  "10px",
  "12px",
  "14px",
  "16px",
  "18px",
  "20px",
  "22px",
  "24px",
  "28px",
  "32px",
  "36px",
  "40px",
  "48px",
  "50px",
  "56px",
  "60px",
  "64px",
  "68px",
  "70px",
  "72px",
  "76px",
  "80px",
  "96px",
  "100px",
];
Quill.register(fontSizeStyle, true);

const CreateTutorial = () => {
  const navigate = useNavigate();
  const inputFile = useRef();
  const inputFileCover = useRef();
  const quillRef = useRef();
  const editRef = useRef();

  const [allValues, setAllValues] = useState({
    topicid: "",
    title: "",
    description: "",
    SummarydescriptionHeadig: "",
    Summarydescription: "",
    icon: null,
    content: [],
    covertype: "",
    author: "",
    cover: { image: null, video: null, listheading: "", listbullets: [],listheading_de:"", listbullets_de:[]},
    GermanSummarydescriptionHeadig:""
  });
  const [coverImage, setCoverImage] = useState("");
  const [topics, settopics] = useState([]);
  const [editsubcontent, seteditsubcontent] = useState({
    edit: false,
    id: null,
  });
  const [allAuthors, setAuthors] = useState([]);
  const [showKeywords, setShowKeywords] = useState(false);
  const [allKeywords, setAllKeywords] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [isLogoUploading, setIsLogoUploading] = useState(false);
  const [text, setText] = useState("");
  const [text_de, setText_de] = useState("");
  const [paratext, setparaText] = useState("");
  const [paratext_de, setparaText_de] = useState("");
  const [heading, setheading] = useState("");
  const [heading_de,setHeading_de] =  useState('')
  const [paraheading, setparaheading] = useState("");
  const [paraheading_de, setparaheading_de] = useState("");
  const tutorialid = getUrlqueries("tutorialid");
  const [showEditor, setshowEditor] = useState(false);
  // react quill states
  const [mainTitle, setmainTitle] = useState("");
  const [mainDesc, setmainDesc] = useState("");
  const [summaryDesc, setSummaryDesc] = useState("");

  // German states

  const [germanMainTitle,setGermanMainTitle] =  useState('')
  const [germanMainDesc,setGermanMainDesc] =  useState('')
  const [germanSummaryDesc,setGermanSummaryDesc] =  useState('')

  const getTopics = async () => {
    try {
      const response = await Axios.get(`/admin/academy/topics`);
      if (response.data.success) {
        settopics(response.data.data);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      toastResponseError(error);
    }
  };
  const gettdata = async (id) => {
    try {
      const response = await Axios.get(`/admin/academy/tutorial/${id}`);
      if (response.data.success) {
        const { data } = response.data;
        data.author = data.author._id;
        console.log('cover',data.cover)
        console.log('content',data.content)
        const addingdeContent  = data.content.map((data)=>{
          if(!data?.heading_de || !data.content_de){
            return {...data, heading_de: '',content_de:""}
          }else{
            return data
          }
          
        })

        const coverWithGerman = {...data.cover};
        if(!coverWithGerman['listheading_de']){
          coverWithGerman.listheading_de = ""
        }

        setAllValues({
          topicid: data.topicid,
          title: data.title,
          description: data.description,
          SummarydescriptionHeadig: data.SummarydescriptionHeadig,
          GermanSummarydescriptionHeadig: data.SummarydescriptionHeadig_de ?data.SummarydescriptionHeadig_de :'',
          Summarydescription: data.Summarydescription,
          icon: data.icon,
          content: addingdeContent,
          covertype: data.covertype,
          author: data.author,
          cover: coverWithGerman,
        });
        setmainTitle(data.title);
        setmainDesc(data.description);
        setSummaryDesc(data.Summarydescription);
        setCoverImage(data.cover.image);
        setAllKeywords(data.keywords);
        setGermanMainTitle(data.title_de?data.title_de:"")
        setGermanMainDesc(data.description_de?data.description_de:"")
        setGermanSummaryDesc(data.Summarydescription_de?data.Summarydescription_de:"")
      }
    } catch (error) {
      if (error && error.response?.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  const getAuthors = async () => {
    await Axios({
      url: `/admin/academy/all-authors`,
      method: "get",
    })
      .then((res) => {
        setAuthors(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };
  const getTags = async () => {
    await Axios({
      url: `/admin/academy/all-tags`,
      method: "get",
    })
      .then((res) => {
        setAllTags(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err);
      });
  };
  const handlePicUpload = async (e, type) => {
    const image = e.target.files[0];
    // if (image.size > 2048 * 1024) {
    //   toast.error("File size exceeded 2MB!", { position: "bottom-right" });
    //   return;
    // }
    const formdata = new FormData();
    formdata.append(`${type}`, image);
    setIsLogoUploading(true);
    await Axios({
      method: "post",
      url: `/admin/academy/${type}`,
      data: formdata,
    })
      .then((res) => {
        if (type === "topiccover") {
          setAllValues({ ...allValues, coverimage: res.data.location });
        } else if (type === "topicicon") {
          setAllValues({ ...allValues, icon: res.data.location });
        } else if (type === "image") {
          setAllValues({
            ...allValues,
            cover: { ...allValues.cover, image: res.data.location },
          });
        } else if (type === "tutorialvideo") {
          setAllValues({
            ...allValues,
            cover: { ...allValues.cover, video: res.data.location },
          });
        } else if (type === "tutorialimage") {
          const editor = quillRef.current.getEditor();
          editor.insertEmbed(editor.getSelection(), "image", res.data.location);
        }

        setIsLogoUploading(false);

        toast.success(`${type?.toUpperCase()} uploaded`);
      })
      .catch((error) => {
        if (error && error.response.status === 401) {
          navigate("/admin/login");
        }
        toastResponseError(error);
      });
  };
  const handleCoverUpload = async (imageFile) => {
    const formdata = new FormData();
    formdata.append(`image`, imageFile);
    try {
      const res = await Axios({
        method: "post",
        url: `/admin/academy/image`,
        data: formdata,
      });
      if (res) {
        toast.success("Image upload");
        setAllValues({
          ...allValues,
          cover: { ...allValues.cover, image: res.data.location },
        });
        return res.data.location;
      } else {
        toast.error("Something went wrong, please try again");
        return false;
      }
    } catch (error) {
      // if (error && error.response.status === 401) {
      //   navigate("/admin/login");
      // }
      toastResponseError(error);
      return false;
    }
  };
  const handleCreateTutorial = async (type) => {
    try {
      if (mainTitle.trim() === "") return toast.error("Title is missing");
      if (allValues.topicid.trim() === "")
        return toast.error("Select Tutorial");
      let data = { ...allValues };
      if (type) {
        data.isLive = true;
      } else {
        data.isLive = false;
      }
      const url = tutorialid
        ? `/admin/academy/update-tutorial/${tutorialid}`
        : `/admin/academy/create-tutorial`;
      const response = await Axios({
        url: url,
        method: "POST",
        data: {
          topicid: allValues.topicid,
          title: mainTitle,
          title_de:germanMainTitle,
          description: mainDesc,
          description_de:germanMainDesc,
          icon: allValues.icon,
          SummarydescriptionHeadig: allValues.SummarydescriptionHeadig,
          SummarydescriptionHeadig_de: allValues.GermanSummarydescriptionHeadig,
          Summarydescription: summaryDesc,
          Summarydescription_de: germanSummaryDesc,

          content: allValues.content,
          covertype: allValues.covertype,
          cover: allValues.cover,
          isLive: data.isLive,
          author: allValues.author,
          keywords: allKeywords.map((tag) => tag._id),
        },
      });
      if (response.data.success) {
        if (tutorialid) {
          toast.success("Tutorial updated successfully");
        } else {
          toast.success("Tutorial created successfully");
        }
        navigate(
          `/admin/dashboard?tab=5&activetab=0&step=1&topicid=${allValues.topicid}`
        );
        setAllValues({
          topicid: "",
          title: "",
          description: "",
          icon: null,
          content: [],
          covertype: "",
          cover: { image: null, video: null, listheading: "", listbullets: [],listheading_de:"",listbullets_de:[] },
        });
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };
  const handleChange = (value) => {
    setText(value);
  };
  const handleChange_de = (value) => {
    setText_de(value);
  };
  const handleAddParagraph = (t) => {
    if (t === "article") {
      let oldcontent = [...allValues.content];
      oldcontent.push({ heading: paraheading, content: paratext,heading_de:paraheading_de,content_de:paratext_de });
      setAllValues({ ...allValues, content: oldcontent });
      setparaText("");
      setparaText_de("")
      setparaheading("");
      setparaheading_de("")
    } else if (t === "list") {
      let oldcontent = [...allValues.cover.listbullets];
      let oldcontent_de = [...allValues.cover.listbullets_de];
     
      oldcontent.push(text);
      oldcontent_de.push(text_de)
      setAllValues({
        ...allValues,
        cover: {
          ...allValues.cover,
          listbullets: oldcontent,
          listheading: heading,
          listbullets_de:oldcontent_de,
          listheading_de:heading_de
        },
      });
      setText("");
      setText_de("")
      seteditsubcontent();
    }
  };
  const handleDeleteParagaph = (index, t) => {
    if (t === "article") {
      let oldcontent = [...allValues.content];
      setAllValues({
        ...allValues,
        content: oldcontent.filter((v, i) => i != index),
      });
    } else if (t === "list") {
      let oldcontent = [...allValues.cover.listbullets];
      let oldcontent_de = [...allValues.cover.listbullets_de];
       
      setAllValues({
        ...allValues,
        cover: {
          ...allValues.cover,
          listbullets: oldcontent.filter((v, i) => i != index),
          listbullets_de: oldcontent_de.filter((v, i) => i != index),
        },
      });
    }
  };
  const handeleditsubcontent = (s, id) => {
    seteditsubcontent({
      edit: true,
      id: id,
    });
    setshowEditor(true);
    setparaheading(s.heading);
    setparaheading_de(s.heading_de)
    setparaText_de(s.content_de)
    setparaText(s.content);

    editRef?.current?.scrollIntoView({ behaviour: "smooth" });
  };
  const handleupdateParagraph = () => {
    let agendas = [...allValues.content];
    // let updateContent = allValues.content.filter((content)=> {
    //   if()
    // })
    agendas[editsubcontent.id] = { heading: paraheading, content: paratext,heading_de:paraheading_de,content_de:paratext_de };
    // const updatedItems = agendas.map((item, i) =>
    //   i === editsubcontent ? { heading: paraheading, content: paratext } : item
    // );
    setAllValues({ ...allValues, content: agendas });
    seteditsubcontent({
      edit: false,
      id: null,
    });
    setparaheading("");
    setparaText("");
    setparaText_de("")
    setparaheading_de("")
  };
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }, { font: [] }],
          ["bold", "italic", "underline", "strike"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          ["image", "link"],
          [{ size: fontSizeStyle.whitelist }],
          [{ color: [] }, { background: [] }],
          ["clean"],
          ["code-block"],
        ],
        handlers: {
          image: () => {},
        },
      },
      imageResize: {
        parchment: Quill.import("parchment"),
        modules: ["Resize", "DisplaySize"],
      },
    }),
    []
  );
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "align",
    "link",
    "image",
    "font",
    "size",
    "color",
    "background",
    "code-block",
  ];

  useEffect(() => {
    if (tutorialid) {
      gettdata(tutorialid);
    }
  }, [tutorialid]);

  useEffect(() => {
    getAuthors();
    getTags();
    getTopics();
  }, []);

  // useEffect(() => {
  //   console.log(allValues);
  // }, [allValues]);

  return (
    <div onClick={() => setShowKeywords(false)}>
      {/* <form> */}
      <div className="form-group">
        <label htmlFor="exampleFormControlSelect1">Choose Topic</label>
        <select
          name="topicid"
          value={allValues.topicid}
          onChange={changeHandler}
          className="form-control"
          id="exampleFormControlSelect1"
        >
          <option value="">Select </option>;
          {topics.map((v) => {
            return (
              <option key={v._id} value={v._id}>
                {v.title}
              </option>
            );
          })}
        </select>
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="exampleFormControlFile1">Icon Upload</label>
        <div hidden={!allValues.icon}>
          <p className="overline_text">
            Uploaded icon:
            <img src={allValues.icon} height="40px" width="40px" />
          </p>
        </div>
        <br />
        <input
          ref={inputFile}
          onChange={(e) => handlePicUpload(e, "topicicon")}
          type="file"
          className="form-control-file"
          id="exampleFormControlFile1"
          accept="image/*"
        />
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="exampleInputEmail1">English Main Title</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={mainTitle}
          onChange={(v) => {
            setmainTitle(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write title..."
        />
        {/* <input
          value={allValues.title}
          onChange={changeHandler}
          type="text"
          required
          name="title"
          className="form-control"
          placeholder="Enter Title"
        /> */}
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="exampleInputEmail1">German Main Title</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={germanMainTitle}
          onChange={(v) => {
            setGermanMainTitle(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write title..."
        />
        {/* <input
          value={allValues.title}
          onChange={changeHandler}
          type="text"
          required
          name="title"
          className="form-control"
          placeholder="Enter Title"
        /> */}
      </div>
      <br />

      <div className="form-group">
        <label>English Main Description</label>
        {/* <textarea
          value={allValues.description}
          required
          onChange={changeHandler}
          type="text"
          name="description"
          className="form-control"
          placeholder="Enter Description"
        /> */}

        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={mainDesc}
          onChange={(v) => {
            setmainDesc(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write description..."
        />
      </div>
      <br />
      <div className="form-group">
        <label>German Main Description</label>
        {/* <textarea
          value={allValues.description}
          required
          onChange={changeHandler}
          type="text"
          name="description"
          className="form-control"
          placeholder="Enter Description"
        /> */}

        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={germanMainDesc}
          onChange={(v) => {
            setGermanMainDesc(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write description..."
        />
      </div>
      <br />
      <h5>Summary </h5>
      <div className="form-group">
        <label>English Summary Heading</label>
        <input
          value={allValues.SummarydescriptionHeadig}
          onChange={changeHandler}
          type="text"
          required
          name="SummarydescriptionHeadig"
          className="form-control"
          placeholder="Enter Summary description Heading"
        />
      </div>
      <br />
      <div className="form-group">
        <label>German Summary Heading</label>
        <input
          value={allValues.GermanSummarydescriptionHeadig}
          onChange={changeHandler}
          type="text"
          required
          name="GermanSummarydescriptionHeadig"
          className="form-control"
          placeholder="Enter Summary description Heading"
        />
      </div>
      <br />
      <div className="form-group">
        <label>English Summary Description</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={summaryDesc}
          onChange={(v) => {
            setSummaryDesc(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write description..."
        />
      </div>
      <br />
      <div className="form-group">
        <label>German Summary Description</label>
        <ReactQuill
          ref={quillRef}
          theme="snow"
          value={germanSummaryDesc}
          onChange={(v) => {
            setGermanSummaryDesc(v);
          }}
          modules={modules}
          formats={formats}
          className="blog-editor"
          placeholder="write description..."
        />
      </div>
      <br />

      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Author</label>
        {allAuthors?.length > 0 ? (
          <select
            value={allValues.author}
            onChange={changeHandler}
            className="form-control"
            name="author"
          >
            <option value="">Select Author</option>
            {allAuthors.map((author) => (
              <option value={author._id} key={author._id}>
                {author.name}
              </option>
            ))}
          </select>
        ) : (
          <p
            className="pointer"
            onClick={() => navigate(`/admin/dashboard?tab=5&activetab=11`)}
          >
            Add Author
          </p>
        )}
      </div>
      <br />
      <div className="form-group">
        <label htmlFor="exampleInputEmail1">Add Keywords</label>

        {allTags?.length > 0 ? (
          <div
            className="position-relative"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              className="form-control"
              onClick={(e) => {
                e.stopPropagation();
                setShowKeywords((prev) => !prev);
              }}
            >
              Select Keyword
            </div>
            {showKeywords && (
              <div className="show_keywords">
                {allTags.map((tag) => (
                  <div
                    className="pointer"
                    style={{
                      backgroundColor: tag.bg_color,
                      color: tag.text_color,
                      margin: "3px 3px",
                      padding: "6px",
                    }}
                    key={tag._id}
                    onClick={() => {
                      const findIndexTag = allKeywords.findIndex(
                        (data) => data._id === tag._id
                      );
                      if (findIndexTag === -1) {
                        setAllKeywords((prev) => [...prev, tag]);
                      }
                    }}
                  >
                    {tag.tag_title}
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <p
            className="pointer"
            onClick={() => navigate(`/admin/dashboard?tab=5&activetab=10`)}
          >
            Add Keyword
          </p>
        )}
      </div>
      {allKeywords?.length > 0 && (
        <div className="d-flex align-items-center gap-4 flex-wrap mt-20">
          {allKeywords.map((tag) => (
            <div
              key={tag._id}
              style={{
                backgroundColor: tag.bg_color,
                padding: "4px 8px",
                borderRadius: 5,
              }}
            >
              <p className="heading_5" style={{ color: tag.text_color }}>
                {tag.tag_title}{" "}
                <Close
                  fontSize="small"
                  className="ml-8 pointer"
                  htmlColor={tag.text_color}
                  onClick={() => {
                    const newKeywords = allKeywords.filter(
                      (keyword) => keyword._id !== tag._id
                    );
                    setAllKeywords(newKeywords);
                  }}
                />
              </p>
            </div>
          ))}
        </div>
      )}
      <br />
      <div className="form-group">
        <label htmlFor="exampleFormControlSelect1">Choose Cover type</label>
        <select
          required
          value={allValues.covertype}
          name="covertype"
          onChange={changeHandler}
          className="form-control"
          id="exampleFormControlSelect1"
        >
          <option value="">Select </option>;
          <option value="image">Image </option>;
          <option value="video">Video </option>;
          <option value="list">Bullet points </option>;
        </select>
      </div>
      <br />

      {["image", "video"].includes(allValues.covertype) && (
        <div className="form-group">
          <label htmlFor="exampleFormControlFile1">
            {allValues.covertype === "video" ? "Thumbnail" : "Cover Image"}
          </label>
          {/* <div hidden={!allValues.cover.image}>
              <img src={allValues.cover.image} height="150px" width="auto" />
            </div> */}
          <br />
          <CoverImageCropper
            avatar={coverImage}
            setAvatar={setCoverImage}
            handlePicUpload={handleCoverUpload}
            inputFileRef={inputFileCover}
            ratio={2.35 / 1}
          />
          {/* <input
              ref={inputFile}
              onChange={(e) => handlePicUpload(e, "image")}
              type="file"
              className="form-control-file"
              id="exampleFormControlFile1"
              accept="image/*"
            /> */}
        </div>
      )}
      <br />

      {allValues.covertype === "video" && (
        <div className="form-group">
          <label htmlFor="exampleFormControlFile1">Tutorial Video </label>
          <div hidden={!allValues.cover.video}>
            Uploaded Tutorial Video :
            <video width="75%" controls>
              <source src={allValues.cover.video} type="video/mp4" />
              {/* <source
                src="https://tg-academy.s3.eu-central-1.amazonaws.com/academy/tutorialvideo/jKCLKWX9FiHMtPeuVYX0E.mp4"
                type="video/mp4"
              /> */}
            </video>
          </div>
          <br />
          {isLogoUploading && <p>Uploading...</p>}
          <input
            ref={inputFile}
            onChange={(e) => handlePicUpload(e, "tutorialvideo")}
            type="file"
            className="form-control-file"
            id="exampleFormControlFile1"
            accept="video/*"
          />
        </div>
      )}
      <br />

      {allValues.covertype === "list" && (
        <>
          <div className="d-flex align-items-center ">
          <div className="form-group mb-1">
            <input
              type="text"
              name="listheading"
              value={heading}
              onChange={(e) => setheading(e.target.value)}
              placeholder="Enter English Bullet Heading"
            />
          </div>
          <div className="form-group mb-1 ml-16">
            <input
              type="text"
              name="listheading"
              value={heading_de}
              onChange={(e) => setHeading_de(e.target.value)}
              placeholder="Enter German Bullet Heading"
            />
          </div>
          </div>

          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={text}
            onChange={handleChange}
            modules={modules}
            formats={formats}
            className="blog-editor"
            placeholder="write bullet point in English..."
          />
          

        
          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={text_de}
            onChange={handleChange_de}
            modules={modules}
            formats={formats}
            className="blog-editor mt-12"
            placeholder="write bullet point in german ..."
          />
          <div className="form-group mt-12">
            <ButtonComp
              type="button"
              onClick={() => handleAddParagraph("list")}
            >
              Add this Bullet
            </ButtonComp>
          </div>
          <p>{allValues.cover.listheading}
            {
              allValues.cover.listheading_de?.length > 0 &&  <span>{" | "}</span>
            }
             {allValues.cover.listheading_de}
          </p>
          <ul>
            {allValues.cover.listbullets.map((v, i) => {
              return (
                <div key={i}>
                  <li
                    id="blog-content"
                    className={`blogpost_text_content`}
                    dangerouslySetInnerHTML={{ __html: String(v) }}
                  ></li> 
                  <li
                    id="blog-content"
                    className={`blogpost_text_content`}
                    dangerouslySetInnerHTML={{ __html: String(allValues.cover.listbullets_de[i]) }}
                  ></li> 
                  <button
                    className="btn btn-danger mt-2"
                    onClick={() => handleDeleteParagaph(i, "list")}
                    type="button"
                  >
                    Delete this Bullet
                  </button>
                </div>
              );
            })}
          </ul>
        </>
      )}
      <br />

      <ButtonComp
        status={2}
        type="button"
        onClick={() => {
          setshowEditor(!showEditor);
        }}
      >
        Add Content
      </ButtonComp>
      <br />
      {showEditor && (
        <div ref={editRef}>
          <div className="form-group">
            <br />
            {/* <input
              type="text"
              value={paraheading}
              onChange={(e) => setparaheading(e.target.value)}
              placeholder=" Paragraph Heading"
            /> */}
            <label>Enter English Heading</label>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={paraheading}
              onChange={(v) => setparaheading(v)}
              modules={modules}
              formats={formats}
              className="blog-editor"
              placeholder="write Heading..."
            />
          </div>
          <br />
          <div className="form-group">
            <br />
            {/* <input
              type="text"
              value={paraheading}
              onChange={(e) => setparaheading(e.target.value)}
              placeholder=" Paragraph Heading"
            /> */}
            <label>Enter German Heading</label>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={paraheading_de}
              onChange={(v) => setparaheading_de(v)}
              modules={modules}
              formats={formats}
              className="blog-editor"
              placeholder="write Heading..."
            />
          </div>
          <br />

          <div className="form-group">
            <label htmlFor="exampleFormControlFile1">Insert Image</label>
            <br />

            <input
              ref={inputFile}
              onChange={(e) => handlePicUpload(e, "tutorialimage")}
              type="file"
              className="form-control-file"
              id="exampleFormControlFile1"
              accept="image/*"
            />
          </div>
          <br />
          <label>Enter English Content</label>

          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={paratext}
            onChange={(v) => setparaText(v)}
            modules={modules}
            formats={formats}
            className="blog-editor"
            placeholder="write paragraph..."
          />
          <br />
          <label>Enter German Content</label>

          <ReactQuill
            ref={quillRef}
            theme="snow"
            value={paratext_de}
            onChange={(v) => setparaText_de(v)}
            modules={modules}
            formats={formats}
            className="blog-editor"
            placeholder="write paragraph..."
          />
          <br />
          <div className="form-group">
            {editsubcontent?.edit && (
              <ButtonComp
                status={1}
                type="button"
                onClick={() => handleupdateParagraph()}
              >
                Update
              </ButtonComp>
            )}
            <ButtonComp
              status={3}
              type="button"
              onClick={() => handleAddParagraph("article")}
            >
              Add this Paragraph
            </ButtonComp>
          </div>
        </div>
      )}
      <br />
      {allValues.content.map((v, i) => (
        <div key={i}>
          <h4 className="mt-16">{parse(v.heading)} </h4>
          <h4 className="mt-16">{parse(v.heading_de)} </h4>
          <div id="blog-content" className={`blogpost_text_content me-2`}>
            {parse(v.content)}
          </div>
          <div id="blog-content" className={`blogpost_text_content me-2`}>
          {parse(v.content_de)}
          </div>
          <button
            className="btn btn-secondary me-2"
            onClick={() => handeleditsubcontent(v, i)}
            type="button"
          >
            Edit
          </button>
          <button
            className="btn btn-danger mt-2"
            onClick={() => handleDeleteParagaph(i, "article")}
            type="button"
          >
            Delete this Paragraph
          </button>
        </div>
      ))}
      <br />
      <br />

      <div className="form-group">
        <ButtonComp
          status={1}
          type="button"
          onClick={() => handleCreateTutorial(1)}
        >
          Publish
        </ButtonComp>
      </div>
      <div className="form-group mt-2">
        <ButtonComp
          status={2}
          type="button"
          onClick={() => handleCreateTutorial(0)}
        >
          Save as Draft
        </ButtonComp>
      </div>
      {/* </form> */}
    </div>
  );
};

export default CreateTutorial;
