import React from "react";

const PhoneIcon = ({ cc, className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      fill="none"
      viewBox="0 0 21 21"
      className={className}
    >
      <path
        stroke={cc || "#fff"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M19.25 14.805v2.625a1.752 1.752 0 01-1.908 1.75 17.316 17.316 0 01-7.551-2.686 17.062 17.062 0 01-5.25-5.25 17.316 17.316 0 01-2.686-7.586A1.75 1.75 0 013.596 1.75h2.625a1.75 1.75 0 011.75 1.505c.11.84.316 1.665.613 2.459A1.75 1.75 0 018.19 7.56L7.079 8.671a14 14 0 005.25 5.25l1.11-1.11a1.75 1.75 0 011.847-.395c.794.297 1.619.502 2.459.613a1.75 1.75 0 011.505 1.776z"
      ></path>
    </svg>
  );
};

export default PhoneIcon;
