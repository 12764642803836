// https://calendly.com/today_green/custom-pricing
import React from "react";
import {InlineWidget} from "react-calendly"
import { useAuth } from "../../../../Utils/Auth";
function ScheduleCallCustomPricing() {
    const { user } = useAuth();

  return (
    <div>
      <InlineWidget url={process.env.REACT_APP_CALENDLY_CP_URL} 
      prefill={{
        email: user.email,
        firstName: user.firstname,
        lastName: user.lastname,
        name: `${user.firstname} ${user.lastname}`,
      }}
      />
      
    </div>
  );
}

export default ScheduleCallCustomPricing;
