import React from "react";
import { styled, LinearProgress, linearProgressClasses } from "@mui/material";

const BorderLinearProgress = styled(LinearProgress)(
  ({ height, curr_color, theme }) => ({
    height: height ? height : 10,
    borderRadius: height ? 20 : 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#EFEFEF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: curr_color ? curr_color : theme.palette.common.white,
    },
  })
);

function CardProgressBar({ height, color, value }) {
  return (
    <div>
      <BorderLinearProgress
        variant="determinate"
        height={height}
        value={value ? value : 0}
        curr_color={
          color == 1
            ? "#9CF197"
            : color == 2
            ? "#79C1FF"
            : color == 3
            ? "#FFCB11"
            : "#E35700"
        }
        sx={{
          "& .MuiLinearProgress-bar": {
            borderRadius: "20px",
          },
        }}
      />
    </div>
  );
}

export default CardProgressBar;
