import React, { useState, useEffect } from "react";
import "./createGHGReport.css";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import CustomSelect from "./Components/CustomSelect";
import TimeFrameSelect from "./Components/TimeFrameSelect";
import { KeyboardArrowLeft } from "@mui/icons-material";
import GHGTable from "./GHGTable";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../Utils/Auth";
import Axios from "../../../../../../Api";
import { getServiceIcon } from "../../../../../../Utils/itemIcon";
import { sub } from "date-fns";
import toDate from "date-fns/toDate";
import useQuery from "../../../../../../Utils/query";
//import DownloadGHGReport from "./Components/DownloadGHGReport";
function CreateGHGReport() {
  const [reportName, setReportName] = useState("");
  const [scopes, setScopes] = React.useState(null);
  const [c_scopes, setc_scopes] = React.useState(null);
  const { setSessionExpire } = useAuth();
  const [isComparisonPeriod, setIsComparisonPeriod] = useState(false);
  const [startDate, setStartDate] = useState(
    toDate(new Date(new Date().getFullYear() - 1, 0, 1))
  );
  const [endDate, setEndDate] = useState(
    toDate(new Date(new Date().getFullYear() - 1, 11, 1))
  );
  const [comparisonStartDate, setComparisonStartDate] = useState(new Date());
  const [comparisonendDate, setComparisonEndDate] = useState(new Date());
  const [accountingItemName, setAllAccountingItemsName] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedAccountingItems, setSelectedAccountingItems] = useState([]);
  const [scopeFromAPi, setScopeFromAPi] = useState([]);

  const [timeFrameYear, setTimeFrameYear] = useState(
    sub(new Date(), { years: 1 }).getFullYear() - 1
  );
  const [timeFrameEndYear, setTimeFrameEndYear] = useState(
    sub(new Date(), { years: 1 }).getFullYear()
  );

  const [compTimeFrameYear, setCompTimeFrameYear] = useState(
    sub(new Date(), { years: 1 }).getFullYear() - 2
  );
  const [compTimeFrameEndYear, setCompTimeFrameEndYear] = useState(
    sub(new Date(), { years: 1 }).getFullYear() - 1
  );
  const [timeFrameType, setTimeFrameType] = useState(3);
  const [currQuarter, setCurrQuarter] = useState(1);

  const [openGHGDownload, setOpenGHGDownload] = useState(false);
  const [isProduct, setIsProduct] = useState(false);

  const query = useQuery();
  const reportId = query.get("report_id");

  const getReport = async (id) => {
    await Axios({
      url: `/ghg/get-ghgReport/${id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data;
          setReportName(data.name);
          setSelectedAccountingItems(data.accountingItems);
          setTimeFrameType(data.timeFrameOption);
          setIsComparisonPeriod(data.isComparison);
          setScopeFromAPi(data.scopesData);
          if (data.timeFrameOption === 4) {
            setStartDate(
              new Date(
                data.timeFrame?.startYear,
                data.timeFrame?.startMonth - 1
              )
            );
            setEndDate(
              new Date(data.timeFrame?.endYear, data.timeFrame?.endMonth - 1)
            );
          } else if (data.timeFrameOption === 3) {
            setTimeFrameEndYear(data.timeFrame?.year);
          } else if (data.timeFrameOption === 2) {
            setTimeFrameEndYear(data.timeFrame?.endYear);
            // currtimeFrame.year = timeFrameEndYear;
            setStartDate(
              new Date(data.timeFrame?.startYear, data.timeFrame?.startMonth - 1)
            );
            setEndDate(
              new Date(data.timeFrame?.endYear, data.timeFrame?.endMonth - 1)
            );
            setCurrQuarter(data.timeFrame?.quarter);
          } else if (data.timeFrameOption === 1) {
            setStartDate(
              new Date(
                data.timeFrame?.year,
                data.timeFrame?.month - 1
              )
            );
            setEndDate(
              new Date(data.timeFrame?.year, data.timeFrame?.month - 1)
            );
            setCurrQuarter(data.timeFrame?.quarter);
          }
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (reportId) {
      getReport(reportId);
    }
  }, [reportId]);

  const handleGHGDownloadDrawer = () => {
    setOpenGHGDownload(!openGHGDownload);
  };
  const handleTimeFrameType = (type, editAllow) => {
    if (!editAllow) {
      setTimeFrameType(3);
      return;
    }
    setTimeFrameType(type);
  };
  const handleTimeFrameEndYear = (newYear) => {
    if (newYear <= new Date().getFullYear() && newYear > timeFrameYear)
      setTimeFrameEndYear(newYear);
    setCompTimeFrameEndYear(newYear - 1);
  };

  const handleTimeFrameYear = (newYear) => {
    if (newYear < timeFrameEndYear) setTimeFrameYear(newYear);
    setCompTimeFrameYear(newYear - 1);
  };
  const handleCompTimeFrameEndYear = (newYear) => {
    if (newYear <= new Date().getFullYear() && newYear > compTimeFrameYear)
      setCompTimeFrameEndYear(newYear);
  };
  const handleCompTimeFrameYear = (newYear) => {
    if (newYear < compTimeFrameEndYear) setCompTimeFrameYear(newYear);
  };
  const removeItemFromList = (index) => {
    let tempFiltered = [...selectedAccountingItems];
    tempFiltered.splice(index, 1);
    setSelectedAccountingItems(tempFiltered);
  };
  const addItemtoList = (index, selectAll) => {
    if (selectAll) {
      if (selectedAccountingItems.length == accountingItemName.length) {
        setSelectedAccountingItems([]);
      } else {
        const newAccountingItems = accountingItemName.map((item) => {
          return {
            id: item._id,
            accountingItemName: item.accountingItemName,
            name: item.itemDetails.name,
          };
        });
        setSelectedAccountingItems(newAccountingItems);
      }
      return;
    }
    let isPresent = selectedAccountingItems.findIndex(
      (currItem) => currItem.id === filteredList[index]._id
    );
    // console.log(isPresent);
    if (isPresent != -1) {
      removeItemFromList(isPresent);
      return;
    }
    let tempFiltered = [...selectedAccountingItems];
    const data = {
      id: filteredList[index]._id,
      accountingItemName: filteredList[index].accountingItemName,
      name: filteredList[index].itemDetails.name,
    };
    tempFiltered.push(data);
    setSelectedAccountingItems(tempFiltered);
  };

  const getallitems = async () => {
    try {
      let response = await Axios.get("/log/all-user-items");
      if (response.data.success) {
        const { data } = response.data;
        setAllAccountingItemsName(data);
        setFilteredList(data);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    getallitems();
  }, []);
  useEffect(() => {
    let currIsProduct = selectedAccountingItems.length > 0 ? true : false;
    selectedAccountingItems.forEach((currItem) => {
      if (currItem.accountingItemName.toLowerCase() != "product")
        currIsProduct = false;
    });
    setIsProduct(currIsProduct);
  }, [selectedAccountingItems]);

  useEffect(() => {
    getScopes();
  }, [isComparisonPeriod]);
  const handleStartDate = (day) => {
    setStartDate(day);
    let x = sub(day, { years: 1 });
    setComparisonStartDate(x);
  };
  const handleEndDate = (day) => {
    setEndDate(day);
    let x = sub(day, { years: 1 });
    setComparisonEndDate(x);
  };
  const handleComparisonStartDate = (day) => {
    setComparisonStartDate(day);
  };
  const handleComparisonEndDate = (day) => {
    setComparisonEndDate(day);
  };

  const getScopes = async () => {
    try {
      let accountingItemsId = [];
      let isProduct = selectedAccountingItems.length > 0 ? true : false;
      selectedAccountingItems.forEach((currItem) => {
        accountingItemsId.push(currItem.id);

        if (currItem.accountingItemName.toLowerCase() != "product")
          isProduct = false;
      });
      let currtimeFrame = {};
      if (timeFrameType === 2) {
        currtimeFrame.year = timeFrameEndYear;
      }
      if (timeFrameType == 0) {
        currtimeFrame.year = timeFrameEndYear;
        currtimeFrame.month;
      }
      if (timeFrameType == 1) {
        let currQuarter = 1;
        if (startDate.getMonth() == 3 && endDate.getMonth() === 5)
          currQuarter = 2;
        if (startDate.getMonth() == 6 && endDate.getMonth() === 8)
          currQuarter = 3;
        if (startDate.getMonth() == 9 && endDate.getMonth() === 11)
          currQuarter = 4;
        currtimeFrame.quarter = currQuarter;
        currtimeFrame.startMonth = startDate.getMonth() + 1;
        currtimeFrame.startYear = startDate.getFullYear();
        currtimeFrame.endMonth = endDate.getMonth() + 1;
        currtimeFrame.endYear = endDate.getFullYear();
      }
      if (timeFrameType === 3) {
        currtimeFrame.startMonth = startDate.getMonth() + 1;
        currtimeFrame.startYear = startDate.getFullYear();
        currtimeFrame.endMonth = endDate.getMonth() + 1;
        currtimeFrame.endYear = endDate.getFullYear();
      }
      const response = await Axios.post(`/ghg/get-scopes`, {
        timeFrameOption: timeFrameType + 1,
        timeFrame: currtimeFrame,
        accountingItems: accountingItemsId,
        isComparison: isComparisonPeriod,
        comparisonTimeFrame: { year: compTimeFrameEndYear },
        isProduct,
      });
      if (response.data.success) {
        if (!isProduct) {
          let {
            scope1Data,
            scope2Data,
            scope3Data,
            c_scope1Data,
            c_scope2Data,
            c_scope3Data,
          } = response.data;
          setScopes({ scope1Data, scope2Data, scope3Data });
          if (c_scope1Data)
            setc_scopes({ c_scope1Data, c_scope2Data, c_scope3Data });
        } else {
          let {
            product1Data,
            product2Data,
            product3Data,
            product4Data,
            product5Data,
            c_product1Data,
            c_product2Data,
            c_product3Data,
            c_product4Data,
            c_product5Data,
          } = response.data;
          setScopes({
            product1Data,
            product2Data,
            product3Data,
            product4Data,
            product5Data,
          });
          if (c_product1Data)
            setc_scopes({
              c_product1Data,
              c_product2Data,
              c_product3Data,
              c_product4Data,
              c_product5Data,
            });
        }
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  // React.useEffect(() => {
  //   if (selectedAccountingItems && timeFrameType) getScopes();
  // }, [selectedAccountingItems, timeFrameType]);

  return (
    <div className="ml-116 mr-80">
      <div className="align_text_left mt-34">
        <p className="breadcrumb_text_light_1 text_capital">
          <Link to={`/dashboard?tab=6&step=0`}>
            <span className="color_light_2">
              <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
              share{" "}
            </span>
            <span className="color_light_2">/ reports </span>
          </Link>{" "}
          <span className="fw-700 text-uppercase">
            {" "}
            / {reportId ? "Edit ghg report" : "Create new ghg report"}
          </span>
        </p>

        <p className="font_24_600 mt-20">
          {reportId ? "Edit ghg report" : "Create new ghg report"}
        </p>
      </div>
      {/* pl-35 pt-28 pb-32 pr-80 mt-45 */}
      <div className="border-ef border_radius_10   mt-45">
        <div className="border-ef border_radius_10 p-4 m-4">
          <div className="row">
            <div className="createGHGReport_textField col-4">
              <p className="font_13_600 text_capital">Report Name</p>
              <TextField
                fullWidth
                value={reportName}
                onChange={(e) => {
                  setReportName(e.target.value);
                }}
                className="mt-12"
                variant="outlined"
                size="small"
              />

              <p className="helper_text color_ba fw-400 mt-12">
                This name will be used for the report library
              </p>
            </div>

            <div className="createGHGReport_selectField col-4">
              <p className="font_13_600 text_capital">
                Select Accounting Item(s)
              </p>
              <div className="mt-12">
                <CustomSelect
                  selectedItemLength={selectedAccountingItems.length}
                  selectedAccountingItems={selectedAccountingItems}
                  filteredList={filteredList}
                  setFilteredList={setFilteredList}
                  accountingItemName={accountingItemName}
                  addItemtoList={addItemtoList}
                />
              </div>

              <p className="helper_text color_ba fw-400 mt-12">
                Select the accounting items you want to include
              </p>
            </div>

            <div className="createGHGReport_selectField col-4">
              <p className="font_13_600 text_capital">Time Frame</p>
              <div className="mt-10 border-top_ef border_radius_5">
                <TimeFrameSelect
                  isEditable={true}
                  startDate={startDate}
                  endDate={endDate}
                  handleStartDate={handleStartDate}
                  handleEndDate={handleEndDate}
                  year={timeFrameYear}
                  endYear={timeFrameEndYear}
                  setEndYear={handleTimeFrameEndYear}
                  setYear={handleTimeFrameYear}
                  timeFrameType={timeFrameType}
                  setTimeFrameType={handleTimeFrameType}
                  getScopes={getScopes}
                />
              </div>
              <p className="helper_text color_ba fw-400 mt-12">
                Select the accounting items you want to include
              </p>
            </div>
          </div>
          <hr />

          <div className="row  mb-20">
            <div className="col-4">
              <input
                onClick={() => setIsComparisonPeriod(!isComparisonPeriod)}
                type="checkbox"
                className="mr-10"
              />
              <span className="font_13_600 text_capital ">
                Include comparison period
              </span>
              {isComparisonPeriod && (
                <>
                  <div className="mt-12 border-top_ef border_radius_5">
                    <TimeFrameSelect
                      isEditable={false}
                      startDate={comparisonStartDate}
                      endDate={comparisonendDate}
                      handleStartDate={handleComparisonStartDate}
                      handleEndDate={handleComparisonEndDate}
                      year={compTimeFrameYear}
                      endYear={compTimeFrameEndYear}
                      setEndYear={handleCompTimeFrameEndYear}
                      setYear={handleCompTimeFrameYear}
                      timeFrameType={timeFrameType}
                      setTimeFrameType={handleTimeFrameType}
                    />
                  </div>
                  <p className="helper_text color_ba fw-400 mt-12">
                    Select the accounting items you want to include
                  </p>{" "}
                </>
              )}
            </div>
          </div>

          <hr />

          <div className="mt-20">
            <p className="font_13_600 text_capital ml-10">
              your accounting item selection
              {selectedAccountingItems.length ? (
                <span className="blue_label ml-10">{`${selectedAccountingItems.length}`}</span>
              ) : (
                ""
              )}
            </p>

            <div className="d-flex flex-wrap mt-10">
              {selectedAccountingItems.map((currItem, index) => {
                return (
                  <p
                    key={currItem.id + index}
                    className="body_text_m d-flex align-items-center border_radius_25 mt-10 ml-20 border-ef pl-16 pb-8 pt-8"
                  >
                    <img
                      className="h-16px"
                      src={getServiceIcon(currItem.accountingItemName)}
                    />
                    <span className="ml-10">{currItem.name}</span>
                    <i
                      onClick={() => removeItemFromList(index)}
                      className="pointer bi bi-x-lg ml-10 mr-12"
                    ></i>
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <hr />
        <div className="border-ef border_radius_10 p-4 m-4">
          <p className="font_19_600 text_capital">Your GHG Emissions</p>
          <GHGTable
            reportName={reportName}
            scopes={scopes}
            isProduct={isProduct}
            timeFrameType={timeFrameType}
            timeFrameEndYear={timeFrameEndYear}
            timeFrameYear={timeFrameYear}
            c_scopes={c_scopes}
            startDate={startDate}
            endDate={endDate}
            isComparison={isComparisonPeriod}
            handleGHGDownloadDrawer={handleGHGDownloadDrawer}
            openGHGDownload={openGHGDownload}
            selectedAccountingItems={selectedAccountingItems}
            accountingItemsLength={accountingItemName.length}
            scopeFromAPi={scopeFromAPi}
            currQuarter={currQuarter}
            setCurrQuarter={setCurrQuarter}
          />
        </div>
      </div>
    </div>
  );
}

export default CreateGHGReport;
