import React, { useState } from "react";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import { MoreVert } from "@mui/icons-material";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { modalStyle } from "../../../Utils/CommonFunctions";
import axios from "../../../Api";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { useAuth } from "../../../Utils/Auth";
import ButtonComp from "../../../Assets/components/Button";

const AddressRow = ({ address, getAddresses, handleEditModalOpen }) => {
  const { setSessionExpire } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // delete modal variables
  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = () => setdltOpen(true);
  const handledltClose = () => setdltOpen(false);

  const deleteAddress = async () => {
    await axios({
      url: `/address/${address._id}`,
      method: "delete",
    })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          getAddresses();
          handledltClose();
          handleClose();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  return (
    <>
      <StyledTableRow
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}
      >
        <StyledTableCell
          sx={{ borderLeft: "0" }}
          className="text-start"
          component="th"
          scope="row"
        >
          {address.CompanyName}
        </StyledTableCell>
        <StyledTableCell className="text-start">
          {address.firstName} {address.lastName}
          <br />
          {address.emailAddress}
        </StyledTableCell>
        <StyledTableCell className="text-start">
          {address.City} {address.houseNumber}, {address.zipCode}{" "}
          {address.State}, {address.Country}
        </StyledTableCell>
        <StyledTableCell className="text-start" sx={{ borderRight: "0" }}>
          <div className="d-flex align-items-center gap-2">
            <p className="flex-grow-1">{address.VATnumber}</p>
            {address.isDefault && (
              <div className="subtitle_box bg_soft_blue">
                <p className="color_blue font_13_600">default</p>
              </div>
            )}
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </div>
        </StyledTableCell>
      </StyledTableRow>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="basic-popover-root">
          <div className="basic-popover">
            <p
              className="font_14_500 color_red"
              onClick={() => {
                handledltOpen();
                handleClose();
              }}
            >
              delete
            </p>
          </div>
          <div
            className="basic-popover"
            onClick={() => {
              handleEditModalOpen(address);
              handleClose();
            }}
          >
            <p className="font_14_500">edit</p>
          </div>
        </div>
      </Popover>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={dltOpen}
        onClose={handledltClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={dltOpen}>
          <Box sx={modalStyle}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">Delete User</p>
              </div>
              <TableContainer className="mt-24 pb-24 border-btm_dc">
                <Table>
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell component="th" className="text-uppercase">
                        Company
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        Name / E-Mail
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        Address
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        Vat
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        className="text-start"
                        component="th"
                        scope="row"
                      >
                        {address.CompanyName}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {address.firstName} {address.lastName}
                        <br />
                        {address.emailAddress}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {address.City} {address.houseNumber}, {address.zipCode}{" "}
                        {address.State}, {address.Country}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        <div className="d-flex align-items-center">
                          <p className="flex-grow-1">{address.VATnumber}</p>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="pb-24 border-btm_dc mt-24">
                <p className="overline_text">
                  After deleting the user, they won’t be able to log back into
                  the account.
                </p>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-24">
                <ButtonComp
                  status={3}
                  className="mr-12 text-black"
                  onClick={() => {
                    handledltClose();
                    handleClose();
                  }}
                >
                  Go Back
                </ButtonComp>
                <ButtonComp
                  status={3}
                  className="color_red"
                  onClick={() => {
                    deleteAddress();
                  }}
                >
                  Delete user
                </ButtonComp>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddressRow;
