import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { formatNumberIntoReadableFormat } from "../../../Utils/CommonFunctions";

const LogActivityPlaceholder = () => {
  const data = [
    {
      month: "Jan",
      count: 60,
    },
    {
      month: "Feb",
      count: 70,
    },
    {
      month: "Mar",
      count: 80,
    },
    {
      month: "Apr",
      count: 75,
    },
    {
      month: "May",
      count: 80,
    },
    {
      month: "Jun",
      count: 70,
    },
    {
      month: "Jul",
      count: 60,
    },
    {
      month: "Aug",
      count: 65,
    },
    {
      month: "Sep",
      count: 68,
    },
    {
      month: "Oct",
      count: 78,
    },
    {
      month: "Nov",
      count: 69,
    },
    {
      month: "Dec",
      count: 50,
    },
  ];
  return (
    <ResponsiveContainer height={300}>
      <BarChart
        data={data}
        barGap={"30%"}
        barCategoryGap="38%"
        bar
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#efefef" vertical={false} />
        <XAxis
          dataKey={"month"}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
        />
        <YAxis
          tickFormatter={(val) => `${formatNumberIntoReadableFormat(val)} Logs`}
          fontSize={12}
          fontFamily="Inter"
          fontWeight={400}
        />
        <Bar
          dataKey="count"
          opacity={0.5}
          stackId="a"
          fill="#00915b"
          radius={[0, 0, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default LogActivityPlaceholder;
