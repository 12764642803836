import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./companyMap.css";
import ReactFlow, {
  addEdge,
  MiniMap,
  // Controls,
  Background,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Panel,
} from "reactflow";
import AddAccountingItem from "./Components/NodeTypes/AddAccountingItem";
import itemData from "../LogDashboard/items-new.json";
//import { nodes as initialNodes, edges as initialEdges } from './initial-elements';
import CompanyNode from "./Components/NodeTypes/companyNode";
import AccountingItemNode from "./Components/NodeTypes/AccountingItemNode";
import SlotHolderNode from "./Components/NodeTypes/SlotHolderNode";
import DragAndDropDummyNode from "./Components/NodeTypes/DragAndDropDummyNode";
import { useMapStore } from "../../../Utils/CompanyMapStore";
import { FormControl, Select, MenuItem } from "@mui/material";
import "reactflow/dist/style.css";
import "./overview.css";
import AddAccountingItemDrawer from "./Components/AddAccountingItemDrawer";
import Loader from "../../../Assets/components/Loader";
import CenterIcon from "./Components/Icons/centerIcon";
import ZoomInIcon from "./Components/Icons/zoonIn";
import ZoomOutIcon from "./Components/Icons/zoomOut";
import { KeyboardArrowDown } from "@mui/icons-material";
import SeachBigCircleIcon from "../../../Assets/icons/SeachBigCircleIcon";

const minimapStyle = {
  height: 120,
};

const onInit = () => {};

const CompanyMap = () => {
  const [zoomLevel, setZoomLevel] = useState(50);
  // const [selectedAccountingItem, setSelectedAccountingItem] = useState("1")
  const [accItemList, setAccItemList] = useState([]);
  const { setCenter, zoomIn, zoomOut, getZoom } = useReactFlow();
  const [openSearch, setopenSearch] = useState(false);
  const [searchInput, setsearchInput] = useState("");
  const [searchResults, setsearchResults] = useState([]);
  const {
    getItemData,
    mapNodes,
    mapEdges,
    onNodeDrag,
    onNodeDragStop,
    selectedAccountingItem,
    handleSelectedAccountingItemChange,
    // initViewPort,
    mapCenter,
  } = useMapStore();
  const nodeTypes = useMemo(
    () => ({
      addNode: AddAccountingItem,
      companyNode: CompanyNode,
      AccountingItemNode: AccountingItemNode,
      SlotHolderNode: SlotHolderNode,
      DragAndDropDummyNode: DragAndDropDummyNode,
    }),
    []
  );

  const [nodes, setNodes, onNodesChange] = useNodesState(mapNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(mapEdges);
  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    []
  );

  useEffect(() => {
    setNodes(mapNodes);
    // setCenter(mapCenter.x + 300, mapCenter.y + 300, {
    //   zoom: 0.5,
    //   duration: 800,
    // });
  }, [mapNodes]);

  useEffect(() => {
    setEdges(mapEdges);
  }, [mapEdges]);

  useEffect(() => {
    let tempAccItem = itemData.map((currItem) => {
      return { name: currItem.name, id: currItem._id };
    });
    setAccItemList([{ name: "All Accounting Items", id: "1" }, ...tempAccItem]);
    getItemData();
  }, []);

  const edgesWithUpdatedTypes = edges.map((edge) => {
    if (edge.sourceHandle) {
      const edgeType = nodes.find((node) => node.type === "custom").data
        .selects[edge.sourceHandle];
      edge.type = edgeType;
    }
    return edge;
  });

  const handleTransform = () => {
    if (mapCenter) {
      setCenter(mapCenter.x + 300, mapCenter.y + 300, {
        zoom: 0.5,
        duration: 800,
      });
    }
  };

  const handleFindNode = (search) => {
    setsearchInput(search);
    // let results = [];
    if (search === "") {
      setsearchResults([]);
    } else {
      let results = mapNodes.filter((item) =>
        item.data.data?.itemDetails?.name
          .toLowerCase()
          .includes(search.toLowerCase())
      );
      setsearchResults(results);
    }
  };
  const handleFocusNode = (node) => {
    setsearchResults([]);
    if (node) {
      setCenter(node.position.x + 300, node.position.y + 100, {
        zoom: 0.9,
        duration: 800,
      });
    } else
      setCenter(mapCenter.x + 300, mapCenter.y + 300, {
        zoom: 0.5,
        duration: 800,
      });
  };

  return (
    <>
      {!nodes || nodes.length == 0 ? <Loader /> : ""}
      <AddAccountingItemDrawer />
      <ReactFlow
        nodes={nodes}
        edges={edgesWithUpdatedTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onNodeDrag={onNodeDrag}
        onNodeDragStop={onNodeDragStop}
        onConnect={onConnect}
        onInit={onInit}
        fitView
        attributionPosition="top-right"
        nodeTypes={nodeTypes}
      >
        <MiniMap style={minimapStyle} zoomable pannable />
        {/* <Controls position="top-right" /> */}
        <Background color="#f2f2f2" className="bg_f2" gap={16} />
        <Panel position="top-left" className="ml-100">
          <div className="d-flex">
            <div className="center bg_white border_radius_5 px-10 h-40px drop_shadow">
              <p className="font_24_700 ml-6 mt-10 mb-10 mr-6">Company Map</p>
            </div>
            <div
              className="center border_radius_5 bg_white pointer h-40px w-40px ml-10 drop_shadow"
              onClick={() => setopenSearch(!openSearch)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="#000"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 18.998l-4.35-4.35M9 17A8 8 0 109 1a8 8 0 000 16z"
                ></path>
              </svg>
            </div>
            <div
              className="ml-20 h-40px bg_white border_radius_5 align_center drop_shadow"
              style={{ padding: "0 5px" }}
            >
              <CenterIcon onClick={handleTransform} className={"mr-10"} />
              <ZoomOutIcon
                onClick={() => {
                  zoomOut({ duration: 500 });
                  setZoomLevel((getZoom() * 100).toFixed(0));
                  // alert(getZoom());
                }}
              />
              <div className="ml-8 mr-8">
                <p className="font_9_500 fw-600 line_height_10">{zoomLevel}%</p>
              </div>
              <ZoomInIcon
                onClick={() => {
                  zoomIn({ duration: 500 });
                  setZoomLevel((getZoom() * 100).toFixed(0));
                  // alert(getZoom());
                }}
              />
            </div>
            <FormControl
              sx={{ marginLeft: "20px", width: 200 }}
              className="drop_shadow"
              size="small"
            >
              <Select
                value={selectedAccountingItem}
                onChange={(e) =>
                  handleSelectedAccountingItemChange(e.target.value)
                }
                displayEmpty
                IconComponent={KeyboardArrowDown}
                sx={{
                  border: "none",
                  backgroundColor: "#fff",
                  "& .MuiInputBase-input": {
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#000000",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 250,
                      width: 200,
                      borderRadius: "5px",
                      border: "1px solid #EDEDED",
                      background: "#FFFFFF",
                      boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
                    },
                  },
                  // MenuListProps: {
                  //   style: { padding: "0px" },
                  // },
                }}
              >
                {accItemList.map((currentItem, i) => (
                  <MenuItem
                    sx={{
                      borderBottom:
                        i === accItemList.length - 1
                          ? "none"
                          : "1px solid #efefef",
                      fontFamily: "Inter",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: "500",
                      color: "#000000",
                    }}
                    key={currentItem.id}
                    value={currentItem.id}
                  >
                    {currentItem.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="center border_radius_5 bg_white pointer h-40px w-40px ml-10 drop_shadow">
              <div className="circle_20 center bg_black">
                <p className="font_14_600 text-white line_height_normal">?</p>
              </div>
            </div>
          </div>
          {openSearch ? (
            <div className="">
              <div className="map_search_input_div">
                <SeachBigCircleIcon />
                <input
                  className="map_search_input font_12_500"
                  placeholder="Search Accounting Item"
                  value={searchInput}
                  onChange={(e) => handleFindNode(e.target.value)}
                />
              </div>
              <div className=" border_radius_5 bg_white mt-5px w-250px ">
                {searchResults &&
                  searchResults.length > 0 &&
                  searchResults.map((result, i) => (
                    <div
                      key={i}
                      className="p-2 pointer border-btm_ef"
                      onClick={() => handleFocusNode(result)}
                    >
                      <p className="font_14_500">
                        {result.data.data.itemDetails.name}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ) : null}
        </Panel>
      </ReactFlow>
    </>
  );
};

export default CompanyMap;
