import React, { useEffect, useState } from "react";
import TabLabels from "../../LogDashboard/Components/TabLabels";
import PropTypes from "prop-types";
import { Box, Tab, Tabs } from "@mui/material";
import ActiveTable from "./ActiveTable";
import axios from "../../../../Api";
import useQuery from "../../../../Utils/query";
import OffsetMarketPlace from "./OffsetMarketPlace";
import "./index.css";
import InActiveTable from "./InActiveTable/InActiveTable";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
import { Link, useNavigate } from "react-router-dom";
import ButtonComp from "../../../../Assets/components/Button";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Offset = () => {
  const [value, setValue] = useState(0);
  // const [products, setProducts] = useState([]);
  const [activeLogs, setActiveLogs] = useState([]);
  const [inActiveLogs, setInActiveLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(0);
  const { setSessionExpire } = useAuth();
  const history = useNavigate();

  const query = useQuery();
  const currStep = Number(query.get("step"));
  const subtab = Number(query.get("subtab"));
  const activeTab = Number(query.get("activeTab"));

  useEffect(() => {
    if ((currStep || currStep === 0) && currStep != step) {
      setStep(currStep);
    }
  }, [currStep]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    history(`/dashboard?tab=5&subtab=${subtab}&activeTab=${newValue}`);
  };

  const getAllTables = async () => {
    setIsLoading(true);
    await axios({
      url: `/log/offset-logs`,
      method: "get",
    })
      .then((res) => {
        setIsLoading(false);
        if (res.data.success) {
          const { inactiveOffsetLogs, activeOffsetLogs } = res.data;
          setActiveLogs(activeOffsetLogs);
          setInActiveLogs(inactiveOffsetLogs);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    if (step === 0) getAllTables();
  }, []);

  useEffect(() => {
    if (activeTab || activeTab === 0) {
      setValue(activeTab);
    }
  }, [activeTab]);

  return (
    <div className="offset_root ml-100 mr-50">
      {step === 1 || step === 2 || step === 3 ? (
        <OffsetMarketPlace setStep={setStep} step={step} />
      ) : (
        <>
          <div className="logDashboard_width">
            <div className="d-flex justify-content-between logDashboard_heading mt-40 mb-16">
              <div>
                <p className="heading_1">Contribution Overview</p>
              </div>
              <div className="">
                <ButtonComp
                  status={2}
                  // className="secondary_button_variant_2 ml-20"
                  onClick={() => history("/dashboard?tab=5&subtab=1&step=1")}
                >
                  Contribution Marketplace
                </ButtonComp>
              </div>
            </div>
          </div>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                className="general_root_tabs"
              >
                <Tab
                  label={
                    <TabLabels
                      title={"Active"}
                      count={activeLogs ? activeLogs.length : 0}
                    />
                  }
                  {...a11yProps(0)}
                />
                <Tab
                  label={
                    <TabLabels
                      title={"Inactive"}
                      count={inActiveLogs ? inActiveLogs.length : 0}
                    />
                  }
                  {...a11yProps(1)}
                />
              </Tabs>
            </Box>
            <div className="text-right pt-20">
              <Link
                to="https://make.today.green/dashboard?tab=7&acadTab=0&id=65198ed58a7254d81a824262&step=1"
                target="_blank"
                rel="noreferrer"
              >
                <ButtonComp status={1}>
                  How Contribution Works <i className="bi bi-arrow-right"></i>
                </ButtonComp>
              </Link>
            </div>
            <TabPanel value={value} index={0} className="pb-32">
              <ActiveTable
                activeLogs={activeLogs}
                setStep={setStep}
                isLoading={isLoading}
              />
            </TabPanel>
            <TabPanel value={value} index={1} className="pb-32">
              <InActiveTable
                getAllTables={getAllTables}
                logs={inActiveLogs}
                setStep={setStep}
                isLoading={isLoading}
              />
            </TabPanel>
          </Box>
        </>
      )}
    </div>
  );
};

// Offset.displayName = "Offset";

export default Offset;
