import React from "react";

export default function Years() {
  let labels = [];
  for (let i = 0; i < 12; i++) {
    labels.push(<div className="white_progress_bar ml-1"></div>);
  }
  return (
    <div className="d-flex">
      <div className="flex-grow-1 d-flex align-items-center">{labels}</div>
      <p className="grey_label no_border f-13 ms-1 fw-600">
        <span style={{ opacity: 0, fontSize: "13px" }}>0/12</span>
      </p>
    </div>
  );
}
