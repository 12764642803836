import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// import { useNavigate } from "react-router-dom";
// import { getServiceIcon } from "../../../Utils/itemIcon";
import Row from "./Row";
import EmptyActiveOffsets from "./EmptyActiveOffsets";
import Text from "../../../../Assets/components/Empty/text";
import { StyledTableRow, StyledTableCell } from "../../../../Utils/TableComp";
// import Typography from '@mui/material/Typography';

const HeadCell = styled(TableCell)(() => ({
  "&:first-of-type": {
    borderRadius: "8px 0px 0px 0px",
  },
  "&:last-child": {
    borderRadius: "0px 8px 0px 0px",
  },
  border: "1px solid #E6E6E6",
  fontFamily: '"Inter", sans-serif',
  fontWeight: "600",
}));
// const StyledTableCell = styled(TableCell)(() => ({
//   borderBottom: "none",
//   fontFamily: '"Inter", sans-serif',
//   fontSize: "14px",
//   fontWeight: "400",
//   border: "1px solid #E6E6E6",
// }));

// const StyledTableRow = styled(TableRow)(() => ({}));

const StyledHeadRow = styled(TableRow)(() => ({
  "&:last-child": {
    backgroundColor: "#F9FAFC",
  },
}));

const ActiveTable = ({ activeLogs, setStep, isLoading }) => {
  // const navigate = useNavigate();

  return (
    <div className="main_box mt-20 pb-20">
      <div className="pb-40 analytics_table_container">
        {isLoading ? (
          <TableContainer
            component={Paper}
            sx={{ minWidth: 640, borderRadius: "8px" }}
            className="mr-50 "
          >
            <Table sx={{ minWidth: 640 }} aria-label="simple table">
              <TableHead>
                <StyledHeadRow>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_left_0 border_top_0"
                  >
                    Carbon Neutral Item
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_top_0"
                  >
                    Log Period
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_top_0"
                  >
                    Carbon Neutral
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_top_0"
                  >
                    Contribution progress
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase text-center border_top_0"
                  >
                    Contribution Active
                  </HeadCell>
                  <HeadCell
                    className="border_right_0 border_top_0"
                    component="th"
                  ></HeadCell>
                </StyledHeadRow>
              </TableHead>
              <TableBody>
                <StyledTableRow
                  sx={{
                    "&:last-child td, &:last-child th": { borderBottom: 0 },
                  }}
                >
                  <StyledTableCell
                    sx={{ width: "23%" }}
                    className="border_left_0"
                  >
                    <Text />
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Text />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Text />
                  </StyledTableCell>
                  <StyledTableCell sx={{ width: "20%" }}>
                    <Text />
                  </StyledTableCell>
                  <StyledTableCell>
                    <Text />
                  </StyledTableCell>
                  <StyledTableCell className="border_right_0">
                    <Text />
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : activeLogs && activeLogs.length > 0 ? (
          <TableContainer
            component={Paper}
            sx={{ minWidth: 640, borderRadius: "8px" }}
            className="mr-50 "
          >
            <Table sx={{ minWidth: 640 }} aria-label="simple table">
              <TableHead>
                <StyledHeadRow>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_left_0 border_top_0"
                  >
                    Carbon Neutral Item
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_top_0"
                  >
                    Log Period
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_top_0"
                  >
                    Carbon Neutral
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase border_top_0"
                  >
                    Contribution progress
                  </HeadCell>
                  <HeadCell
                    component="th"
                    className="text-uppercase text-center border_top_0"
                  >
                    Contribution Active
                  </HeadCell>
                  <HeadCell
                    className="border_right_0 border_top_0"
                    component="th"
                  ></HeadCell>
                </StyledHeadRow>
              </TableHead>
              <TableBody>
                {activeLogs &&
                  activeLogs.length > 0 &&
                  activeLogs.map((log, i) => (
                    <Row key={i} log={log} setStep={setStep} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <EmptyActiveOffsets isActiveTab={true} />
        )}
      </div>
    </div>
  );
};

export default ActiveTable;
