import React, { useState, useEffect } from "react";
//import GhGSideDrawer from "./GHGSideDrawer";
import "./index.css";
import ButtonComp from "../../../../Assets/components/Button";
import GHGReportTable from "./Components/GHGTable";
import ESGReportTable from "./Components/ESGReportTable";
import { Link } from "react-router-dom";
import { useAuth } from "../../../../Utils/Auth";
import { botAvatarGroup } from "../../../../Utils/botAvatarsGroup";
import axios from "../../../../Api/index";
const Reports = ({ setStep }) => {
  const { user } = useAuth();
  const [ghgTableData, setGhgTableData] = useState({});
  const [totalGhgPages, setTotalGhgPages] = useState(1);
  const getTableData = async (page) => {
    await axios.get(`/ghg/get-ghgReports?page=${page}`).then((response) => {
      //`console.log(response)
      setGhgTableData(response.data.data.data);
      setTotalGhgPages(response.data.data.totalPages);
    });
  };
  useEffect(() => {
    getTableData(1);
  }, []);
  return (
    <div className="pb-32">
      {ghgTableData.length > 0 ? (
        <div className="border-ef border_radius_10 pt-36 pl-30 pr-32 pb-32 mt-22">
          <div className="d-flex justify-content-between align-items-center ">
            <p className="font_17_700 text_capital">ghg reports</p>
            <ButtonComp status={2}>
              <Link to={`/dashboard?tab=6&report=1&step=1`}>
                <span className="color_white">Create GHG Report</span>
              </Link>
            </ButtonComp>
          </div>

          <div className="mt-22">
            <GHGReportTable
              totalGhgPages={totalGhgPages}
              ghgTableData={ghgTableData}
              getTableData={getTableData}
            />
          </div>
        </div>
      ) : (
        <div className="mt-60 border-ef border_radius_8 pl-32 pr-32 pt-36 pb-32 ">
          <div className="d-flex justify-content-between  ">
            <p className="font_17_600 text_capital">GHG Reports</p>
            <div>
              <ButtonComp status={2}>
                <Link to={`/dashboard?tab=6&report=1&step=1`}>
                  <span className="color_white">Create GHG Report</span>
                </Link>
              </ButtonComp>
            </div>
          </div>

          <div className="row border-ef border_radius_8 pt-50 pb-40 mt-36 mx-0 p_relative">
            <div className="col-8 pl-48">
              <p className="font_40_700">
                {" "}
                Let&apos;s create your first GHG Report!
              </p>

              <p className="font_16_400 w-60 mt-38 pb-20">
                A GHG report provides you with a comprehensive overview of your
                company&apos;s carbon footprint. It serves as the base for ESG
                reports and helps you to set targets, design policies for
                reducing emissions, and improving the overall environmental
                performance.
              </p>

              <div className="mt-100">
                <ButtonComp status={1}>
                  <Link to={`/dashboard?tab=6&report=1&step=1`}>
                    <span className="color_white">Create GHG Report</span>
                  </Link>
                </ButtonComp>
              </div>
            </div>

            <div className="col-4 text-center" style={{ zIndex: 10 }}>
              <img
                src={botAvatarGroup[user?.bot_avatar_id]?.targetPlaceholder.url}
                style={{ height: 330, width: "auto" }}
              />
            </div>
            <hr className="bg_divider" />
          </div>
        </div>
      )}
      <div className="border-ef border_radius_10 pt-36 pl-30 pr-32 pb-32 mt-30 mb-30">
        <ESGReportTable setStep={setStep} />
      </div>
    </div>
  );
};

export default Reports;
