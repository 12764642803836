import toast from "react-hot-toast";
import Axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";

export function dataURItoBlob(dataURI) {
  var byteString = atob(dataURI.split(",")[1]);
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], { type: mimeString });
  return blob;
}

export function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

export const handlePicUpload = async (imageFile, setSessionExpire) => {
  const formdata = new FormData();
  formdata.append("logo", imageFile);
  // setIsLogoUploading(true);
  try {
    const res = await Axios({
      method: "post",
      url: `/user/auth/logo`,
      data: formdata,
    });
    if (res) {
      toast.success("Image uploaded");
      return res.data.location;
    } else {
      toast.error("Something went wrong, please try again");
      return false;
    }
  } catch (error) {
    toastResponseError(error, setSessionExpire);
    return false;
  }
};

export const handleSaveData = async (data, getCompanyData, handleClose, setSessionExpire) => {
  await Axios({
    url: `/share/tranparancy/create`,
    method: "POST",
    data: data,
  })
    .then(async (res) => {
      if (res.data.success) {
        toast.success(res.data.message);
        await getCompanyData();
        handleClose();
      }
    })
    .catch((err) => {
      toastResponseError(err, setSessionExpire);
    });
};
