import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import BackForwardButton from "../../Assets/components/BackForwardButton/BackForwardButton";
import ButtonComp from "../../Assets/components/Button";
import PressEnter from "../../Assets/components/PressEnter/PressEnter";

const GoogleAuthenticator = ({
  verfifyGoogleAuthenticatorToken,
  otp,
  setOtp,
  image,
  setStep,
}) => {
  const [authStep, setAuthStep] = useState(0);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      if (authStep === 0) {
        setAuthStep(1);
      } else {
        verfifyGoogleAuthenticatorToken();
      }
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, [authStep, otp]);

  return (
    <div className="">
      <div className="subtitle_box bg_soft_blue width_fit">
        <p className="font_13_600 color_blue">Google Authenticator</p>
      </div>
      {authStep === 0 ? (
        <>
          <p className="mt-32 offsetProjectTitle">
            Please Scan the QR Code with your Google Authenticator App.
          </p>
          {image && (
            <div className="twofa_qr_image mt-32">
              <img src={image} alt="authencation" />
            </div>
          )}
          <div className="mt-6rem d-flex align-items-start justify-content-between">
            <BackForwardButton
              handleGoBack={() => setStep(0)}
              doNotShowForward={true}
            />
            <div>
              <ButtonComp
                status={1}
                className=" ml-16"
                onClick={() => setAuthStep(1)}
              >
                Enter Verification Code
              </ButtonComp>
              <PressEnter />
            </div>
          </div>
        </>
      ) : (
        <>
          <p className="mt-32 offsetProjectTitle">
            Please Enter the Code from your Google Authenticator App
          </p>
          <div className="otp_input mt-24">
            <OtpInput
              value={otp}
              onChange={setOtp}
              numInputs={6}
              renderSeparator={<span className="input_otp_span"></span>}
              className="otp_input"
              renderInput={(props) => <input {...props} />}
            />
          </div>
          <div className="mt-6rem d-flex align-items-start justify-content-between">
            <BackForwardButton
              handleGoBack={() => setAuthStep(0)}
              doNotShowForward={true}
            />
            <div>
              <ButtonComp
                status={1}
                className="ml-16"
                onClick={() => verfifyGoogleAuthenticatorToken()}
              >
                Verify Google Authenticator
              </ButtonComp>
              <PressEnter />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GoogleAuthenticator;
