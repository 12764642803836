import React from "react";
import { Box,  Modal } from "@mui/material";
import celebrationImg from '../../../../../../../../../Assets/images/celebration_image.png'
import ButtonComp from "../../../../../../../../../Assets/components/Button";
//import ButtonComp from "../../../../../../../../../Assets/components/Button";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  outline: "none",
  boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.0588235)",
  borderRadius: "8px",
};

const PaymentModel = (props) => {
  
 

  return (
    <Modal
    open={props.open}
    onClose={props.handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
     <div className="pt-20 pl-20 pb-20 pr-20">
     <p className="font_20_500">
        Success <img src={celebrationImg}/> Your Accounting Item is Activated.
     </p>
     <p className="mt-6">
     Thank You for Your Purchase!
     </p>

     <div className="d-flex justify-content-between align-items-center mt-54">
        <ButtonComp status={2}>
            Invoice
        </ButtonComp>

        <div className="d-flex justify-content-end align-items-center">
        <ButtonComp status={2}>
            Go to log
        </ButtonComp>

        <div className="ml-10">

        <ButtonComp status={5}>
            Continue
        </ButtonComp>
        </div>
        </div>

     </div>
     </div>


    </Box>
  </Modal>
  );
};

export default PaymentModel;
