import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import React from "react";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../Utils/TableComp";
import ItemDetails from "../../../../LogDashboard/product_lifecycle.json";

const ProductDetailsTable = () => {
  return (
    <div className="">
      <TableContainer
        component={Paper}
        sx={{
          minWidth: 640,
          boxShadow: "0px 3px 16px 0px rgba(0, 0, 0, 0.06)",
          borderRadius: "10px",
        }}
      >
        <Table
          sx={{
            minWidth: 640,
            borderRadius: "10px",
            border: "0",
          }}
          aria-label="simple table"
        >
          <TableHead>
            <StyledHeadRow>
              <HeadCell
                sx={{
                  borderLeft: "0",
                  borderBottom: "0",
                  //   width: "0%",
                  padding: "10px 10px 12px 24px !important",
                  backgroundColor: "#fff",
                  textDecorationLine: "underline",
                }}
                component="th"
                className="text-uppercase font_13_700"
              >
                Life cycle phase
              </HeadCell>
              <HeadCell
                sx={{
                  //   width: "50%",
                  padding: "10px 10px 12px 24px !important",
                  backgroundColor: "#fff",
                  textDecorationLine: "underline",
                }}
                component="th"
              >
                Category
              </HeadCell>
              <HeadCell
                component="th"
                className="text-uppercase"
                sx={{
                  //   width: "30%",
                  padding: "10px 10px 12px 24px !important",
                  backgroundColor: "#fff",
                  textDecorationLine: "underline",
                  borderRight: "0px",
                }}
              >
                Label
              </HeadCell>
            </StyledHeadRow>
          </TableHead>

          <TableBody>
            {ItemDetails &&
              ItemDetails.map((currScope, index) => {
                return (
                  <StyledTableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <StyledTableCell
                      sx={{
                        borderLeft: "0",
                        borderRight: "0",
                        padding: "8px 10px 8px 24px !important",
                      }}
                      component="th"
                      textleft={true}
                      className="text_overflow-1 align_text_left text_capital_f"
                    >
                      <div
                        className={
                          "d-flex justify-content-center align-items-center border_radius_5 width_fit " +
                          (currScope["name"] ===
                          "Material acquisition & pre-processing"
                            ? "bg_light_yellow"
                            : currScope["name"] === "Production"
                            ? "color_bg_ede"
                            : currScope["name"] === "distribution & storage"
                            ? "color_bg_d4"
                            : currScope["name"] === "use"
                            ? "bg_light_sky_blue"
                            : currScope["name"] === "end-of-life"
                            ? "bg_light_pink_2"
                            : "")
                        }
                        style={{ padding: "4px 12px" }}
                      >
                        <p className="font_13_400">{currScope["name"]}</p>
                      </div>
                    </StyledTableCell>

                    <StyledTableCell
                      textleft={true}
                      sx={{
                        borderLeft: "0",
                        borderRight: "0",
                        padding: "8px 10px 8px 24px !important",
                      }}
                      className="font_13_300"
                    >
                      {currScope["today-subscope"]}
                    </StyledTableCell>

                    <StyledTableCell
                      textleft={true}
                      sx={{
                        borderLeft: "0",
                        borderRight: "0",
                        padding: "8px 10px 8px 24px !important",
                      }}
                      className="color_green"
                    >
                      {currScope.label == "Mandatory" ? (
                        <span className="color_green">Mandatory</span>
                      ) : (
                        <span className="color_e3">Recommended</span>
                      )}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductDetailsTable;
