import React, { useState, useEffect } from "react";
// import industries from "../industries.json";
// import events from '../events.json'
import OutlinedInput from "@mui/material/OutlinedInput";
import { FormControl, Select, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
//import InputBase from "@mui/material/InputBase";
import CustomToolTip from "./customToolTip";
const BootstrapInput = styled(OutlinedInput)(() => ({
  "& .MuiInputBase-input": {
    fontFamily: "var(--inter-font)",
    fontWeight: 400,
    fontSize: "15px",
    borderRadius: "5px",
    height: "25px",
    marginTop: "auto",
    padding: "0px 5px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #efefef",
  },
}));

function SelectInput(props) {
  const [currVal, setCurrVal] = useState("");
  // const [selectData,setSelectData]=useState([])
  useEffect(() => {
    props.setAnswers((prev) => {
      return {
        ...prev,
        [props.questionNo]: currVal,
      };
    });
  }, [currVal]);

  const handleChange = (e) => {
    setCurrVal(e.target.value);
  };
  return (
    <div className="sideDrawer_select_input">
      <div>
        {props.title.length > 0 ? (
          <div className="d-flex align-items-center">
            <p className="sr-only subheading_1 mb-8">{props.title}</p>
            {props.description && props.description.length >0 ?<div>
              <CustomToolTip title={props.description} />
            </div>:""}
          </div>
        ) : (
          ""
        )}
      </div>
      <FormControl
        sx={{
          m: "4px 0",
          width: "100%",
          //  border: "1px solid #efefef",
          borderRadius: "5px",
          "& .MuiSelect-select:focus": {
            backgroundColor: "transparent !important",
          },
        }}
      >
        <Select
          displayEmpty
          input={<BootstrapInput />}
          onChange={(e) =>{
            handleChange(e)
          }}
          renderValue={(selected) => {
            if (!selected || selected.length === 0) {
              return "Select";
            }

            return selected;
          }}
          inputProps={{ "aria-label": "Without label" }}
        >
          <MenuItem disabled value="">
            Select
          </MenuItem>
          {props.dropDownList &&
            props.dropDownList.map((current_item, index) => {
              return (
                <MenuItem
                  className="SideDrawer_input"
                  value={current_item.item ? current_item.item : current_item}
                  key={index}
                >
                  {current_item.icon ? (
                    <img src={current_item.icon} height="15px" />
                  ) : (
                    ""
                  )}

                  <p className="ml-8">
                    {current_item.item ? current_item.item : current_item}
                  </p>
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <p className="helper_text fw-400 ml-1 mt-10">{props.helperText}</p>
    </div>
  );
}

export default SelectInput;
