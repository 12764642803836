import React from "react";
import { useMapStore } from "../../../../../../Utils/CompanyMapStore";
//import { v4 as uuidv4 } from 'uuid';
import { Handle, Position } from "reactflow";
function AddAccountingItem(props) {
  const {
    //  mapNodes,setMapNodes,mapEdges,setMapEdges,handleAccItemDrawer
    addNodeAtLevel1,
    addNodeAtlevel2,
  } = useMapStore();
  // const addNewNode= () =>{
  //   let tempNodes= [...mapNodes]
  //   let tempEdges= [...mapEdges]
  //   tempNodes.push({
  //     id:uuidv4(),
  //     type:"AccountingItemNode",
  //     data:{
  //       ...data,position
  //     },
  //     position:{
  //       x: tempNodes[tempNodes.length-1].position.x+300,
  //       y:tempNodes[tempNodes.length-1].position.y
  //     }
  //   })
  //   tempEdges.push(
  //     {
  //       id:uuidv4(),
  //       source:tempNodes[0].id,
  //       target:tempNodes[tempNodes.length-1].id,
  //       type: 'SmoothStepEdge',
  //     }
  //   )

  //   setMapNodes(tempNodes)
  //   setMapEdges(tempEdges)

  // }
  const handleMouseDown = (event) => {
    event.stopPropagation();
  };
  return (
    <div
      onClick={() => {
        if (props.data?.level == 1)
          addNodeAtLevel1(props.id, props.data, {
            x: props.xPos,
            y: props.yPos,
          });
        else if (props.data?.level == 2)
          addNodeAtlevel2(props.id, props.data, {
            x: props.xPos,
            y: props.yPos,
          });
      }}
      onMouseDown={handleMouseDown}
      className="d-flex align-items-center justify-content-center nodrag pointer border_radius_5 w-30px h-30px bg_009 "
    >
      <Handle
        type="target"
        // isConnectableStart={false}
        position={Position.Top}
        id="a"
      />

      <i className="bi bi-plus-lg color_white"></i>
    </div>
  );
}

export default AddAccountingItem;
