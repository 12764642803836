import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { IconButton, Collapse, TableContainer, Table } from "@mui/material";
import React, { useEffect, useState } from "react";
import { StyledTableCell, StyledTableRow } from "../../../../Utils/TableComp";
// import DonwIntradayIcon from "../../../../Assets/icons/DownIntraday";
import { getServiceIcon } from "../../../../Utils/itemIcon";

const SubScopeRow = ({ sc, totalEmission, totalSubEmissions, columnWidth }) => {
  const [open, setOpen] = useState(false);
  const [subClassData, setSubClassData] = useState([]);
  const [subClassEmission, setSubClassEmission] = useState(0);

  useEffect(() => {
    let groupedData = {};
    for (let i = 0; i < sc.data.length; i++) {
      let { particulars } = sc.data[i];
      for (let j = 0; j < particulars.length; j++) {
        for (let k = 0; k < particulars[j].amount.length; k++) {
          if (!groupedData[particulars[j].subClass]) {
            groupedData[particulars[j].subClass] = {
              subClass: particulars[j].subClass,
              emission: 0,
            };
          }
          groupedData[particulars[j].subClass].emission +=
            particulars[j].amount[k].emission;
        }
      }
    }

    const result = Object.values(groupedData);

    setSubClassEmission(sc.emission);
    setSubClassData(result);
    // console.log(result);
  }, [sc]);

  return (
    <React.Fragment>
      <StyledTableRow
        sx={{ "&:last-child td, &:last-child th": { borderBottom: 0 } }}
        style={{ boxShadow: open && "0px 3px 16px rgba(0, 0, 0, 0.059)" }}
      >
        <StyledTableCell
          sx={{ borderLeft: "0" }}
          component="th"
          scope="row"
          textleft={true}
        >
          <div className="d-flex align-items-center">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
            <img src={getServiceIcon(sc.subscope)} className="icon_sizing" />
            <p className="ml-8 body_text_m text_capital_f">{sc.subscope}</p>
          </div>
        </StyledTableCell>
        <StyledTableCell
          className="align-items-center justify-content-center"
          textleft={true}
        >
          {Number(sc.emission).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{" "}
          tCO₂e
        </StyledTableCell>
        <StyledTableCell textleft={true}>
          {totalEmission
            ? ((sc.emission * 100) / totalEmission).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })
            : 0}{" "}
          %
        </StyledTableCell>
        <StyledTableCell textleft={true}>
          {totalSubEmissions
            ? ((sc.emission * 100) / totalSubEmissions).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            : 0}{" "}
          %
        </StyledTableCell>
        {/* <StyledTableCell sx={{ borderRight: "0" }} textleft={true}>
            
          </StyledTableCell> */}
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <TableContainer sx={{ minWidth: 640 }} className="">
              <Table sx={{ minWidth: 640 }} aria-label="simple table">
                {subClassData &&
                  subClassData.length > 0 &&
                  subClassData.map((subClass) => (
                    <StyledTableRow
                      key={subClass.subClass}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell
                        style={{
                          width: columnWidth[0],
                          paddingLeft: 60,
                          borderLeft: "none",
                          borderRight: "none",
                          borderTop: "none",
                        }}
                        component="th"
                        scope="row"
                        className="text-start"
                        textleft={true}
                      >
                        <img
                          src={getServiceIcon(subClass.subClass)}
                          className="icon_sizing me-2"
                        />
                        {subClass.subClass}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          width: columnWidth[1],
                          borderTop: "none",
                        }}
                        className="align-items-center justify-content-center"
                        textleft={true}
                      >
                        {subClass.emission.toFixed(2).toLocaleString()} tCO₂e
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          width: columnWidth[2],
                          borderTop: "none",
                        }}
                        textleft={true}
                      >
                        {totalEmission
                          ? Number(
                              (subClass.emission * 100) / totalEmission
                            ).toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : 0}{" "}
                        %
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          width: columnWidth[3],
                          borderTop: "none",
                          borderRight: "none",
                        }}
                        textleft={true}
                      >
                        {(
                          (subClass.emission * 100) /
                          subClassEmission
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}{" "}
                        %
                      </StyledTableCell>
                      {/* <StyledTableCell
                          style={{
                            width: columnWidth[4],
                            borderRight: "none",
                            borderTop: "none",
                          }}
                          textleft={true}
                        >
                        
                        </StyledTableCell> */}
                    </StyledTableRow>
                  ))}
              </Table>
            </TableContainer>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default SubScopeRow;
