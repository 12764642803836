import React, { useState } from "react";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import { VisibleRoles, roles } from "../../../Utils/UserRoleConstAndFunc";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { useAuth } from "../../../Utils/Auth";
import { toast } from "react-hot-toast";
import axios from "../../../Api";
import { modalStyle } from "../../../Utils/CommonFunctions";
import ButtonComp from "../../../Assets/components/Button";

const UserRow = ({
  name,
  email,
  role,
  isJoined,
  date,
  handleDelete,
  userid,
  getInvites,
  getCompanyUsers,
}) => {
  const { setSessionExpire } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // delete modal variables
  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = () => setdltOpen(true);
  const handledltClose = () => setdltOpen(false);

  // edit modal variables
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  const [editedRoles, setEditedRoles] = useState(role);

  const handleEditRole = async () => {
    await axios({
      url: `/company/edit-role/${userid}`,
      method: "put",
      data: {
        role: editedRoles,
      },
    })
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          getInvites();
          getCompanyUsers();
          handleEditClose();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  return (
    <>
      <StyledTableRow
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}
      >
        <StyledTableCell
          className="text-start border_left_0"
          component="th"
          scope="row"
        >
          {name}
        </StyledTableCell>
        <StyledTableCell className="text-start">{email}</StyledTableCell>
        <StyledTableCell className="text-start">
          {VisibleRoles[role]}
        </StyledTableCell>
        <StyledTableCell className="text-start" sx={{ borderRight: "none" }}>
          <div className="d-flex align-items-center justify-content-between">
            {isJoined ? (
              <p className="">{date}</p>
            ) : (
              <div className="subtitle_box bg_soft_blue">
                <p className="color_blue font_13_600">Invitition Sent</p>
              </div>
            )}
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </div>
        </StyledTableCell>
      </StyledTableRow>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="basic-popover-root">
          <div className="basic-popover">
            <p
              className="font_14_500 color_red"
              onClick={() => {
                handledltOpen();
                handleClose();
              }}
            >
              delete
            </p>
          </div>
          {isJoined && (
            <div
              className="basic-popover"
              onClick={() => {
                handleEditOpen();
                handleClose();
              }}
            >
              <p className="font_14_500">Edit</p>
            </div>
          )}
        </div>
      </Popover>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={dltOpen}
        onClose={handledltClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={dltOpen}>
          <Box sx={modalStyle}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">Delete User</p>
              </div>
              <TableContainer
                sx={{ minWidth: 640 }}
                className="mt-24 pb-24 border-btm_dc"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell component="th" className="text-uppercase">
                        USER NAME
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        E-MAIL ADDRESS
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ROLE
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ACTIVE SINCE
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        className="text-start"
                        component="th"
                        scope="row"
                      >
                        {name}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {email}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {VisibleRoles[role]}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        <div className="d-flex align-items-center justify-content-between">
                          {isJoined ? (
                            <p className="">{date}</p>
                          ) : (
                            <div className="subtitle_box bg_soft_blue">
                              <p className="color_blue font_13_600">
                                Invitition Sent
                              </p>
                            </div>
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="pb-24 border-btm_dc mt-24">
                <p className="overline_text">
                  After deleting the user, they won’t be able to log back into
                  the account.
                </p>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-24">
                <ButtonComp
                  status={3}
                  className="mr-12 text-black"
                  onClick={() => {
                    handledltClose();
                    handleClose();
                  }}
                >
                  Go Back
                </ButtonComp>
                <ButtonComp
                  status={3}
                  className=" color_red"
                  onClick={async () => {
                    await handleDelete();
                    handledltClose();
                  }}
                >
                  Delete user
                </ButtonComp>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editOpen}
        onClose={handleEditClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editOpen}>
          <Box sx={modalStyle}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">Edit Role</p>
              </div>
              <TableContainer
                sx={{ minWidth: 640 }}
                className="mt-24 pb-24 border-btm_dc"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell component="th" className="text-uppercase">
                        USER NAME
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        E-MAIL ADDRESS
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ROLE
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ACTIVE SINCE
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        className="text-start"
                        component="th"
                        scope="row"
                      >
                        {name}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {email}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {VisibleRoles[role]}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        <div className="d-flex align-items-center justify-content-between">
                          {isJoined ? (
                            <p className="">{date}</p>
                          ) : (
                            <div className="subtitle_box bg_soft_blue">
                              <p className="color_blue font_13_600">
                                Invitition Sent
                              </p>
                            </div>
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="row justify-content-between pb-24 border-btm_dc mt-24">
                <div className="col-lg-7 col-md-8 col-12">
                  <div className="input_div input_seperation">
                    <p className="font_14_500 align-self-center">User Role</p>
                    <select
                      className="subheading_2"
                      value={editedRoles}
                      onChange={(e) => setEditedRoles(e.target.value)}
                    >
                      <option className="subheading_2" value={roles.viewer}>
                        Team Member (Viewer)
                      </option>
                      <option className="subheading_2" value={roles.editor}>
                        Editor
                      </option>
                      <option className="subheading_2" value={roles.admin}>
                        Admin
                      </option>
                      <option className="subheading_2" value={roles.consultant}>
                        Consultant
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-24">
                <ButtonComp
                  status={3}
                  className="mr-12 text-black"
                  onClick={() => {
                    handleEditClose();
                    handleClose();
                  }}
                >
                  Go Back
                </ButtonComp>
                <ButtonComp
                  status={3}
                  className="color_red"
                  onClick={() => {
                    handleEditRole();
                  }}
                >
                  Edit user
                </ButtonComp>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserRow;
