import React from 'react'
import { Handle,Position } from 'reactflow'
import './dragAndDropDummyNode.css'
function DragAndDropDummyNode() {
  return (
    <div className="dragAndDropDummyNode-container w-250px h-300px ">
        <Handle type="target" position={Position.Top} />


        <Handle type="source" position={Position.Bottom} />
    </div>
  )
}

export default DragAndDropDummyNode