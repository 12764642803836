import React from "react";
import { useAuth } from "../../../../../../Utils/Auth";
import { botAvatarGroup } from "../../../../../../Utils/botAvatarsGroup";
import ButtonComp from "../../../../../../Assets/components/Button";

const Step2 = ({ setStep, disableOption1 }) => {
  const { user } = useAuth();
  return (
    <div className="inactive_drawer_decision_time_box">
      <div>
        <div
          className={`${
            disableOption1 ? "bg_soft_orange" : "bg_soft_green"
          } " subtitle_box width_fit`}
        >
          {!disableOption1 ? (
            <p className="font_13_600 color_green">
              You have 12 complete months of log data!
            </p>
          ) : (
            <p className="font_13_600 color_red">
              You don’t have 12 complete months of log data!
            </p>
          )}
        </div>
        <p className="heading_lg mt-20">Heads Up, it&apos;s Decision Time!</p>
        <p className="mt-20 subheading_light">
          ...we are making an importante decision now: You have 12 months of
          complete data for your accounting item. This means that you can decide
          weather you want to offset in retrospective or in prospective.
          <br />
          <br />
          If you are unsure which option to choose in the next step, feel free
          to go back and watch the video again, go to our academy and learn more
          about the different ways to offset or contact our customer success
          team and we will help you choose the right way to go forward!
        </p>
        <hr className="mt-16" />
        <div className="mt-24">
          <ButtonComp
            status={3}
            className=""
            onClick={() => setStep(1)}
          >
            Go Back
          </ButtonComp>
          <ButtonComp
            status={1}
            className="md ml-16"
            onClick={() => setStep(3)}
          >
            Continue to Offset Options
          </ButtonComp>
        </div>
      </div>
      <div>
        <img
          src={botAvatarGroup[user?.bot_avatar_id]?.activatedSuccessful?.url}
          alt="decision time"
          style={{ maxHeight: 365, width: "auto" }}
        />
      </div>
    </div>
  );
};

export default Step2;
