import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import {
  VisibleRoles,
  email_regex,
  roles,
} from "../../../../../../Utils/UserRoleConstAndFunc";
import ButtonComp from "../../../../../../Assets/components/Button";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../Utils/Auth";
import toast from "react-hot-toast";
import Axios from "../../../../../../Api";

const MenuItemSx = {
  fontFamily: "Inter",
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: "500",
};

const InviteUser = ({ setInvitedUser, invitedUsers }) => {
  const { setSessionExpire } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(roles.viewer);
  const [disabled, setDisabled] = useState(false);

  const handleInvite = async () => {
    if (!email) {
      return toast.error("Please enter a valid email address");
    }
    if (!firstName) {
      return toast.error("First name is required");
    }
    if (!lastName) {
      return toast.error("First name is required");
    }
    if (!email_regex.test(email)) {
      return toast.error("Only work email are allowed");
    }
    const findIndex = invitedUsers.findIndex((user) => user.email === email);
    if (findIndex !== -1) {
      return toast.error("User already has invited");
    }
    setDisabled(true);
    await Axios({
      url: `/company/invite-user`,
      method: "post",
      data: {
        email,
        firstName,
        lastName,
        role,
        logo: "",
      },
    })
      .then((res) => {
        setDisabled(false);
        const { data } = res.data;
        if (res.data.success) {
          toast.success(`${firstName} is invited.`);
        }
        const findIndex = invitedUsers.findIndex(
          (user) => user.email === data.email
        );
        if (findIndex === -1) {
          setInvitedUser((prev) => [...prev, data]);
        } else {
          toast.error("user already assigned");
        }
        setFirstName("");
        setLastName("");
        setEmail("");
        setRole(roles.viewer);
      })
      .catch((err) => {
        setDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  return (
    <div className="mt-10 d-flex flex-column gap_10 assignee_user_overflow pr-10">
      <div className="align_center gap_10">
        <div className="flex-1">
          <p className="font_12_500 color_7e">First Name</p>
          <input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            type="text"
            className="grey_input font_12_500 w-100 mt-6"
          />
        </div>
        <div className="flex-1">
          <p className="font_12_500 color_7e">Last Name</p>
          <input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            type="text"
            className="grey_input font_12_500 w-100 mt-6"
          />
        </div>
      </div>
      <div className="flex-1 mt-8">
        <p className="font_12_500 color_7e">Email</p>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          className="grey_input font_12_500 w-100 mt-6"
        />
      </div>
      <div className="flex-1 mt-8">
        <p className="font_12_500 color_7e">Role</p>
        <FormControl sx={{ height: "40px", width: "100%" }} size="small">
          <Select
            value={role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              backgroundColor: "#f6f6f6",
              //   boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.03)",
              border: "none",
              "& .MuiInputBase-input": {
                fontFamily: "Inter",
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "500",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
          >
            {Object.entries(VisibleRoles).map(([key, value], index) => (
              <MenuItem sx={MenuItemSx} key={index} value={key}>
                {value}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="justify_end mt-8">
        <ButtonComp
          status={5}
          className="h-40px"
          disabled={disabled}
          onClick={handleInvite}
        >
          Add User
        </ButtonComp>
      </div>
    </div>
  );
};

export default InviteUser;
