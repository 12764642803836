import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { PropTypes } from "prop-types";
import TabLabels from "../LogDashboard/Components/TabLabels";
import Tutorials from "./Components/Tutorials";
import useQuery from "../../../Utils/query";
import TutorialCardDetails from "./Components/Tutorials/Components/TutorialCardDetails.js";
import PlatformTourVid from "./Components/Tutorials/Components/PlatformTourVid.js";
import KnowledgeHub from "./Components/KnowledgeHub";
import ArticlePage from "./Components/KnowledgeHub/Components/ArticlePage";
import Glossary from "./Components/Glossary";
import "./index.css";
import { useTranslation } from "react-i18next";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const Academy = () => {
  const query = useQuery();
  const {t} = useTranslation()
  //const itemId = query.get("id")
  const step = Number(query.get("step"));
  const academyTab = Number(query.get("acadTab"));
  const mostRead = query.get("mostRead");
  const [value, setValue] = useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return mostRead ? (
    <ArticlePage />
  ) : academyTab === 0 && step == 1 ? (
    <div className="ml-116 mr-80">
      <TutorialCardDetails />
    </div>
  ) : academyTab === 0 && step == 2 ? (
    <PlatformTourVid />
  ) : academyTab == 1 && step == 1 ? (
    <ArticlePage />
  ) : (
    <div className="ml-116 mr-80">
      <p className="offsetProjectTitle mt-40 mb-20">{t("academy.Academy")}</p>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleTabChange}
            variant="scrollable"
            aria-label="basic tabs example"
            className="general_root_tabs"
          >
            <Tab
              key={0}
              label={<TabLabels title={t("academy.tutorials")} count={0} />}
              {...a11yProps(0)}
            />
            <Tab
              key={0}
              label={<TabLabels title={t("academy.knowledgeHub")} count={0} />}
              {...a11yProps(0)}
            />
            {/* <Tab
              key={0}
              label={<TabLabels title={"Events"} count={0} />}
              {...a11yProps(0)}
            /> */}
            <Tab
              key={0}
              label={<TabLabels title={t("academy.glossary")} count={0} />}
              {...a11yProps(0)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Tutorials />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <KnowledgeHub />
        </TabPanel>
        {/* <TabPanel value={value} index={2}></TabPanel> */}
        <TabPanel value={value} index={2}>
          <Glossary />
        </TabPanel>
      </Box>
    </div>
  );
};

export default Academy;
