import React, { useState, useEffect } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import { geocodeByAddress } from "react-google-places-autocomplete";

function AddressInput(props) {
  const [origin, setOrigin] = useState(null);
  useEffect(() => {
    let newAddress = origin && origin.value ? origin.value.description : "";
    if (newAddress && newAddress.length > 0)
      props.handleAnswerChange(props.name, newAddress);
  }, [origin]);

  // useEffect(() => {
  //   // console.log("this is address ",props.answers)
  //   if (props.answers && props.answers[props.name] && !origin)
  // }, [props.answers])
  return (
    <>
      <label className="sr-only subheading_1 mb-12" htmlFor="service_name">
        Address
      </label>
      {!props.disabled ? (
        <div className="d-flex justify-content-start align-items-center border_radius_5 border-ef p-12 w-80">
          <p className="font_15_500 color_black">{props.answers[props.name]}</p>
        </div>
      ) : (
        <div className="w-80">
          <GooglePlacesAutocomplete
            debounce={800}
            defaultValue={props.answers[props.name]}
            apiKey={process.env.REACT_APP_GOOGLE_MAP}
            //   autocompletionRequest={}
            selectProps={{
              origin,

              onChange: (e) => {
                setOrigin(e);
              },
              placeholder: !origin ? props.answers[props.name] : "Address",
              styles: {
                container: (p) => ({
                  ...p,
                  boxSizing: `border-box`,
                  // border: `1px solid #efefef`,
                  borderRadius: `5px`,
                  fontSize: `15px`,
                  fontWeight: "400",
                  boxShadow: "none",
                  fontFamily: '"Inter", sans-serif',
                  outline: `none`,
                  maxHeight: 40,
                  backgroundColor: "#F6F6F6",
                }),
                valueContainer: (p) => ({
                  ...p,
                  border: `none`,
                  borderRadius: "5px",
                  color: "transparent",
                  padding: "0px 10px",
                  height: 42,
                  backgroundColor: "#F6F6F6",
                }),
                control: (p) => ({
                  ...p,
                  border: `0`,
                  "&:hover": {
                    boxShadow: "none",
                  },
                }),
                indicatorsContainer: (p) => ({
                  ...p,
                  display: "none",
                }),
                input: (p) => ({
                  ...p,
                  // padding: "8px",
                }),
              },
            }}
          />
        </div>
      )}
    </>
  );
}

export default AddressInput;
