import React, { useEffect, useState } from "react";
import { Drawer, IconButton } from "@mui/material";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import { Paper, Table, TableContainer, TableHead } from "@mui/material";
import {
  HeadCell,
  StyledHeadRow,
} from "../../../../../../../../Utils/TableComp";
// import ReactToPrint from "react-to-print";
import { Close } from "@mui/icons-material";
import "../../print.css";
import "../../generatePdf.css";
import ScopeTable from "./ScopeTable";
import Axios from "../../../../../../../../Api/index";
import { getServiceIcon } from "../../../../../../../../Utils/itemIcon";
import { useAuth } from "../../../../../../../../Utils/Auth";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// import html2pdf from "html2pdf.js";
import { dataURItoBlob } from "../../../../../Components/CommonFunctions";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { monthNames } from "../../../../../../../../Utils/CommonFunctions";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import Loader from "../../../../../../../../Assets/components/Loader";
import useQuery from "../../../../../../../../Utils/query";
// import { toJpeg } from "html-to-image";

function DownloadGHGReport(props) {
  const navigate = useNavigate();

  const { user, setSessionExpire } = useAuth();
  const [tableData, setTableData] = useState(null);
  const [compTableData, setCompTableData] = useState([]);
  const [totalEmissionSum, setTotalEmissionSum] = useState(1);
  const [tableDataLen, setTableDataLen] = useState(0);
  const [carbonFootPrintName, setCarbonFootPrintName] = useState("");
  const [pdfLoading, setPdfLoading] = useState(false);
  const ref = React.createRef();
  const query = useQuery();
  const reportId = query.get("report_id");
  useEffect(() => {
    let tempCount = {
      product: 0,
      location: 0,
      event: 0,
      service: 0,
      hotel: 0,
      building: 0,
      shipping: 0,
      "real estate development": 0,
      "real-estate agency": 0,
      "educational institution": 0,
      agency: 0,
      "skilled trades company": 0,
    };

    props.selectedAccountingItems.forEach((currItem) => {
      tempCount[currItem.accountingItemName.toLowerCase()]++;
    });
    let productItemPresent = false;
    let uniqueItemCount = 0;
    let uniqueItemName = "";

    Object.keys(tempCount).forEach((currItemName) => {
      if (tempCount[currItemName]) {
        uniqueItemName = currItemName;
        uniqueItemCount++;
      }
      if (currItemName == "product" && tempCount[currItemName] > 0)
        productItemPresent = true;
    });
    let currTitle = "";
    if (props.accountingItemsLength === props.selectedAccountingItems.length) {
      currTitle = "Corporate Carbon Footprint (CCF)";
    } else if (productItemPresent && uniqueItemCount > 1) {
      currTitle = "Corporate Carbon Footprint (CCF & PCF emissions combined)";
    } else if (productItemPresent) {
      currTitle = "Product Carbon Footprint (PCF)";
    } else if (uniqueItemCount > 1) {
      currTitle = "Custom Carbon Footprint";
    } else if (uniqueItemCount == 1) {
      currTitle = `${
        uniqueItemName[0].toUpperCase() + uniqueItemName.slice(1)
      } Carbon Footprint `;
    }
    setCarbonFootPrintName(currTitle);
  }, [props.selectedAccountingItems]);

  const saveReport = async (reportUrl) => {
    let accountingItemsId = [];
    props.selectedAccountingItems.forEach((currItem) => {
      accountingItemsId.push({
        id: currItem.id,
        accountingItemName: currItem.accountingItemName,
        name: currItem.name,
      });
    });
    let tempCount = {
      product: 0,
      location: 0,
      event: 0,
      service: 0,
      hotel: 0,
      building: 0,
      shipping: 0,
      "real estate development": 0,
      "real-estate agency": 0,
      "educational institution": 0,
      agency: 0,
      "skilled trades company": 0,
    };

    props.selectedAccountingItems.forEach((currItem) => {
      tempCount[currItem.accountingItemName.toLowerCase()]++;
    });
    let currType = "";

    let uniqueItemCount = 0;
    Object.keys(tempCount).forEach((currItemName) => {
      if (tempCount[currItemName]) {
        uniqueItemCount++;
      }
    });
    if (props.accountingItemsLength === props.selectedAccountingItems.length) {
      currType = "entire company";
    } else if (uniqueItemCount === 1) currType = "single";
    else currType = "multiple";
    let currtimeFrame = {};

    if (props.timeFrameType === 3) {
      currtimeFrame.year = props.timeFrameEndYear;
    }
    if (props.timeFrameType == 1) {
      currtimeFrame.year = props.timeFrameEndYear;
      currtimeFrame.month = props.startDate.getMonth() + 1;
    }
    if (props.timeFrameType == 2) {
      let currQuarter = 1;
      if (props.startDate.getMonth() == 3 && props.endDate.getMonth() === 5)
        currQuarter = 2;
      if (props.startDate.getMonth() == 6 && props.endDate.getMonth() === 8)
        currQuarter = 3;
      if (props.startDate.getMonth() == 9 && props.endDate.getMonth() === 11)
        currQuarter = 4;
      currtimeFrame.quarter = currQuarter;
      currtimeFrame.startMonth = props.startDate.getMonth() + 1;
      currtimeFrame.startYear = props.startDate.getFullYear();
      currtimeFrame.endMonth = props.endDate.getMonth() + 1;
      currtimeFrame.endYear = props.endDate.getFullYear();
    }
    if (props.timeFrameType === 4) {
      currtimeFrame.startMonth = props.startDate.getMonth() + 1;
      currtimeFrame.startYear = props.startDate.getFullYear();
      currtimeFrame.endMonth = props.endDate.getMonth() + 1;
      currtimeFrame.endYear = props.endDate.getFullYear();
    }

    let response = await Axios({
      url: `/ghg/${reportId ? `edit-scope/${reportId}` : "save-scopes"}`,
      method: reportId ? "put" : "post",
      data: {
        published: true,
        name: props.reportName,
        type: currType, //calculate
        timeFrameOption: props.timeFrameType, //Done
        timeFrame: currtimeFrame, //Done
        isComparison: props.isComparison, //Done
        comparisonTimeFrame: {},
        scopesData: props.selectedScopes, ///Done
        compareScopesData: props.selectedCScopes,
        scope: props.selectedScopes,
        accountingItems: accountingItemsId,
        reportUrl,
      },
    });

    if (response.data.success) {
      // openDrawer()
      toast.success("report has been saved");
    }
  };
  useEffect(() => {
    const data = {};
    const compData = {};
    let currTotalEmissionSum = 0;

    // setTableDataLen(props.selectedScopes.length);
    setTableDataLen(props.selectedScopes.length);
    // setTableDataLen(35);
    // setTableDataLen(selectedData.length);
    props.selectedScopes.forEach((item) => {
      if (!data[item.scope]) {
        data[item.scope] = [];
      }
      currTotalEmissionSum += item.emission;
      data[item.scope].push(item);
    });
    props.selectedCScopes.forEach((item) => {
      if (!compData[item.scope]) {
        compData[item.scope] = [];
      }
      compData[item.scope].push(item);
    });
    setTotalEmissionSum(currTotalEmissionSum);
    setCompTableData(compData);

    setTableData(data);
  }, [props.selectedScopes, props.selectedCScopes]);

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result.split(",")[1]; // Extract the base64 data portion
        resolve(base64Data);
      };
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  };

  const base64ToMIMEString = (base64Data, mimeType) => {
    return `data:${mimeType};base64,${base64Data}`;
  };

  const handleConvertToBase64 = async (sampleBlob) => {
    try {
      const base64Data = await blobToBase64(sampleBlob); // Replace sampleBlob with your actual blob
      const mimeType = "application/pdf";

      const mimeString = base64ToMIMEString(base64Data, mimeType);
      const file = dataURItoBlob(mimeString);
      return file;
    } catch (error) {
      console.error("Error converting blob to base64:", error);
    }
  };

  const handleFileUpload = async (file) => {
    const formdata = new FormData();
    formdata.append("esg-report", file, "design.pdf");
    try {
      const res = await Axios({
        method: "post",
        url: `/share/esg/upload/esg-pdf`,
        data: formdata,
      });
      return res.data.location;
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const generatePdf = async () => {
    if (
      !props.reportName ||
      props.reportName === null ||
      props.reportName === undefined
    ) {
      return toast.error("Please provide a report name");
    }
    const pdf = new jsPDF({
      orientation: "l",
      unit: "px",
      format: "a4",
    });

    const pdfElement = ref.current;
    setPdfLoading(true);
    pdfElement.style.display = "block";
    // finding elements
    const createghg_drawerleft_fp_main = document.getElementById(
      "createghg_drawerleft_fp_main"
    );
    // const createghg_drawerleft_fp_footer = document.getElementById(
    //   "createghg_drawerleft_fp_footer"
    // );
    const downloadGHGReport_contain_body1 = document.getElementById(
      "downloadGHGReport_contain_body1"
    );
    // const downloadGHGReport_contain_footer1 = document.getElementById(
    //   "downloadGHGReport_contain_footer1"
    // );
    const downloadGHGReport_contain_body2 = document.getElementById(
      "downloadGHGReport_contain_body2"
    );
    // const downloadGHGReport_contain_footer2 = document.getElementById(
    //   "downloadGHGReport_contain_footer2"
    // );
    const ghg_table_gt_8 = document.getElementById("ghg_table_gt_8");
    try {
      const section1 = document.getElementById("pdf_ghg1");
      section1.classList.add("createghg_drawerleft_fp");
      const section2 = document.getElementById("pdf_ghg2");
      const section3 = document.getElementById("pdf_ghg3");

      const imgWidth = pdf.internal.pageSize.getWidth();
      // pdf.internal.pageSize.height = 400 ;
      // console.log(pdf.internal.pageSize.getHeight())
      // const imgWidth = 201;
      let section1Canvas,
        imgData1,
        section2Canvas,
        imgData2,
        section3Canvas,
        imgData3,
        imgHeight1,
        imgHeight2,
        imgHeight3;
      // console.log((5 / 16) * (window.screen.width - 1280) + 700);
      if (section1) {
        // adding css
        // createghg_drawerleft_fp_main.style.height =
        //   (5 / 16) * (window.innerWidth - 1280) + 700 + "px";
        // createghg_drawerleft_fp_main.classList.add(
        //   "createghg_drawerleft_fp_main-gp"
        // );
        // createghg_drawerleft_fp_footer.classList.add(
        //   "createghg_drawerleft_fp_footer-gp"
        // );
        // making pdf file but now not adding to pdf
        section1Canvas = await html2canvas(section1);
        imgData1 = section1Canvas.toDataURL("image/jpeg");
        imgHeight1 = (section1Canvas.height * imgWidth) / section1Canvas.width;
        // remove classname
        // createghg_drawerleft_fp_main.style.height = "fit-content";
        createghg_drawerleft_fp_main.classList.remove(
          "createghg_drawerleft_fp_main-gp"
        );
        // createghg_drawerleft_fp_footer.classList.remove(
        //   "createghg_drawerleft_fp_footer-gp"
        // );
      }
      if (section2) {
        // add css
        // downloadGHGReport_contain_body1.style.height =
        //   (5 / 16) * (window.innerWidth - 1280) + 700 + "px";
        downloadGHGReport_contain_body1.classList.add(
          "downloadGHGReport_contain_body-gp"
        );
        // downloadGHGReport_contain_footer1.classList.add(
        //   "downloadGHGReport_contain_footer-gp"
        // );
        // making pdf file but now not adding to pdf -> down is pdf.add() fn, by which pdf add
        section2Canvas = await html2canvas(section2);
        imgData2 = section2Canvas.toDataURL("image/jpeg");
        imgHeight2 = (section2Canvas.height * imgWidth) / section2Canvas.width;
        // console.log(imgHeight2);
        // remove classname
        // downloadGHGReport_contain_body1.style.height = "fit-content";
        downloadGHGReport_contain_body1.classList.remove(
          "downloadGHGReport_contain_body-gp"
        );
        // downloadGHGReport_contain_footer1.classList.remove(
        //   "downloadGHGReport_contain_footer-gp"
        // );
      }
      if (section3) {
        // // add css
        // downloadGHGReport_contain_body2.style.height =
        //   (5 / 16) * (window.innerWidth - 1280) + 700 + "px";
        downloadGHGReport_contain_body2.classList.add(
          "downloadGHGReport_contain_body2-gp"
        );
        // downloadGHGReport_contain_footer2.classList.add(
        //   "downloadGHGReport_contain_footer-gp"
        // );
        ghg_table_gt_8.classList.add("ghg_table_gt_8-gp");
        section3Canvas = await html2canvas(section3);
        imgData3 = section3Canvas.toDataURL("image/jpeg");
        imgHeight3 = (section3Canvas.height * imgWidth) / section3Canvas.width;

        // remove class name
        // downloadGHGReport_contain_body2.style.height = "fit-content";
        downloadGHGReport_contain_body2.classList.remove(
          "downloadGHGReport_contain_body2-gp"
        );
        // downloadGHGReport_contain_footer2.classList.remove(
        //   "downloadGHGReport_contain_footer-gp"
        // );
        ghg_table_gt_8.classList.remove("ghg_table_gt_8-gp");
      }
      if (section1) {
        // creating the pdf and adding pages
        pdf.addImage(imgData1, "JPEG", 0, 0, imgWidth, imgHeight1);
        if (section2) {
          pdf.addPage();
          pdf.addImage(imgData2, "JPEG", 0, 0, imgWidth, imgHeight2);
          if (section3) {
            pdf.addPage();
            pdf.addImage(imgData3, "JPEG", 0, 0, imgWidth, imgHeight3);
          }
        }
      }

      section1.classList.remove("createghg_drawerleft_fp");
      pdfElement.style.display = "none";
      // convert the output in blob file
      const pdfBlob = pdf.output("blob");
      // convert to the base64 pdf so that it can be upload
      const file = await handleConvertToBase64(pdfBlob);
      const location = await handleFileUpload(file);
      await saveReport(location);
      navigate(`/dashboard?tab=6&step=0`);
      setPdfLoading(false);
      pdf.save(`${props.reportName}.pdf`);
    } catch (error) {
      // console.error(error);
      setPdfLoading(false);
    } finally {
      setPdfLoading(false);
    }
  };

  const content = (
    <div className="createghg_drawerleft">
      <div className="createghg_drawerleft_body">
        <div className="createghg_drawerleft_fp pdf_ghg" id="pdf_ghg1">
          <div
            className="createghg_drawerleft_fp_main"
            id="createghg_drawerleft_fp_main"
          >
            <div className="border_radius_10 border-ef createghg_drawerleft_fp_thumb">
              <div className="p-24 flex-1">
                <p className="heading_5">{user.companyId.companyName}</p>
                <p className="font_60_800 mt-12">GHG</p>
                <p className="font_60_800">Report</p>
              </div>

              <div className="border-top_ef py-20 px-24 mt-48 createghg_drawerleft_fp_pt">
                <p className="font_16_500 color_57">{carbonFootPrintName}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start flex-wrap align-items-center mt-34">
              {props.accountingItemsLength ===
              props.selectedAccountingItems.length ? (
                <p className="border-ef border_radius_25 px-16 pt-8 pb-8 ml-10">
                  Entire Company
                </p>
              ) : (
                props.selectedAccountingItems.map((currItem, index) => {
                  return (
                    <p
                      key={currItem._id + index}
                      className="d-flex align-items-center body_text_m border-ef border_radius_25 px-16 pt-8 pb-8 ml-10 mb-2"
                    >
                      <img
                        className="h-16px"
                        src={getServiceIcon(currItem.accountingItemName)}
                      />{" "}
                      <span className="ml-5">{currItem.name}</span>
                    </p>
                  );
                })
              )}
            </div>
          </div>
          <div
            className="createghg_drawerleft_fp_footer createghg_drawerleft_footer"
            id="createghg_drawerleft_fp_footer"
          >
            <p className="font_12_700 mr-10">
              This GHG report was made with today.green
            </p>
          </div>
        </div>
        <div className="downloadGHGReport_container pdf_ghg" id="pdf_ghg2">
          <div
            className="downloadGHGReport_contain_body"
            id="downloadGHGReport_contain_body1"
          >
            <div className="">
              <p className="font_17_600">{props.reportName}</p>
              <p className="font_13_400 mt-10">
                {props.reportName} | Time Frame:{" "}
                {monthNames[props.startDate?.getMonth()]}{" "}
                {props.startDate?.getFullYear()} -
                {monthNames[props.endDate?.getMonth()]}{" "}
                {props.endDate?.getFullYear()}{" "}
                {props.isComparison ? (
                  <span>
                    | Comparison period:{" "}
                    {monthNames[props.startDate?.getMonth()]}{" "}
                    {props.startDate?.getFullYear() - 1} -
                    {monthNames[props.endDate?.getMonth()]}{" "}
                    {props.endDate?.getFullYear() - 1}
                  </span>
                ) : (
                  ""
                )}
              </p>
            </div>

            <div className="mt-32 analytics_table_container">
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640, boxShadow: "none" }}
                className="mt-16"
              >
                <Table
                  sx={{
                    minWidth: 640,
                    borderRadius: "10px",
                    border: "0",
                    boxShadow: "no",
                  }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        sx={{
                          borderLeft: "0",
                          borderBottom: "0",
                          width: "15%",
                          // boxShadow: "none"
                        }}
                        component="th"
                        className="text-uppercase"
                      >
                        {props.isProduct ? "Life Cycle" : "SCOPE"}
                      </HeadCell>
                      <HeadCell
                        sx={{
                          width: "20%",
                          borderBottom: "0",
                        }}
                        component="th"
                      >
                        EMISSIONS
                        <span className="text_lowerCase">(t</span>
                        <span className="text-uppercase">CO₂</span>
                        <span className="text_lowerCase">e)</span>
                      </HeadCell>
                      <HeadCell
                        component="th"
                        className="text-uppercase"
                        sx={{ width: "20%", borderBottom: "0" }}
                      >
                        Emission Share (%)
                      </HeadCell>

                      {props.isComparison && (
                        <HeadCell
                          component="th"
                          // className="text-uppercase"
                          sx={{ width: "25%" }}
                        >
                          <span className="text-uppercase">
                            Comparison Period
                          </span>
                          <span className="text_lowerCase">(t</span>
                          <span className="text-uppercase">CO₂</span>
                          <span className="text_lowerCase">e)</span>
                        </HeadCell>
                      )}
                      {props.isComparison && (
                        <HeadCell
                          component="th"
                          // className="text-uppercase"
                          sx={{ width: "20%" }}
                        >
                          <span className="text-uppercase">Change (%)</span>
                        </HeadCell>
                      )}
                    </StyledHeadRow>
                  </TableHead>
                </Table>
              </TableContainer>
            </div>
            {tableData &&
              Object.keys(tableData).length > 0 &&
              Object.keys(tableData).map((scope) => {
                if (tableDataLen > 7 && scope === "Scope 3") {
                  return false;
                } else {
                  return (
                    <ScopeTable
                      key={scope}
                      scope={scope}
                      tableData={tableData}
                      isComparison={props.isComparison}
                      compTableData={compTableData}
                      totalEmissionSum={totalEmissionSum}
                    />
                  );
                }
              })}
          </div>
          <div
            className="downloadGHGReport_contain_footer"
            id="downloadGHGReport_contain_footer1"
          >
            <p className="font_10_700 color_desp_grey">
              Page 1/{tableDataLen > 7 ? 2 : 1}
            </p>
            <p className="">
              <span className="font_10_700">Contact </span>{" "}
              <span className="body_text_xs">hello@today.green</span>
            </p>
          </div>
        </div>
        {tableDataLen > 7 && (
          <div className="downloadGHGReport_container pdf_ghg" id="pdf_ghg3">
            <div
              className="downloadGHGReport_contain_body"
              id="downloadGHGReport_contain_body2"
            >
              <div
                className="analytics_table_container ghg_table_gt_8"
                id="ghg_table_gt_8"
              >
                <TableContainer
                  component={Paper}
                  sx={{ minWidth: 640, boxShadow: "none" }}
                  className="mt-16"
                >
                  <Table
                    sx={{
                      minWidth: 640,
                      borderRadius: "10px",
                      border: "0",
                      boxShadow: "no",
                    }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <StyledHeadRow>
                        <HeadCell
                          sx={{
                            borderLeft: "0",
                            borderBottom: "0",
                            width: "15%",
                            // boxShadow: "none"
                          }}
                          component="th"
                          className="text-uppercase"
                        >
                          SCOPE
                        </HeadCell>
                        <HeadCell
                          sx={{
                            width: "20%",
                            borderBottom: "0",
                          }}
                          component="th"
                        >
                          EMISSIONS
                          <span className="text_lowerCase">(t</span>
                          <span className="text-uppercase">CO₂</span>
                          <span className="text_lowerCase">e)</span>
                        </HeadCell>
                        <HeadCell
                          component="th"
                          className="text-uppercase"
                          sx={{ width: "20%", borderBottom: "0" }}
                        >
                          Emission Share (%)
                        </HeadCell>
                        {props.isComparison && (
                          <HeadCell
                            component="th"
                            // className="text-uppercase"
                            sx={{ width: "25%", borderBottom: "0" }}
                          >
                            <span className="text-uppercase">
                              Comparison Period
                            </span>
                            <span className="text_lowerCase">(t</span>
                            <span className="text-uppercase">CO₂</span>
                            <span className="text_lowerCase">e)</span>
                          </HeadCell>
                        )}
                        {props.isComparison && (
                          <HeadCell
                            component="th"
                            // className="text-uppercase"
                            sx={{ width: "20%" }}
                          >
                            <span className="text-uppercase">Change (%)</span>
                          </HeadCell>
                        )}
                      </StyledHeadRow>
                    </TableHead>
                  </Table>
                </TableContainer>
              </div>
              {tableData &&
                Object.keys(tableData).length > 0 &&
                Object.keys(tableData).map((scope) => {
                  if (tableDataLen > 7 && scope === "Scope 3") {
                    return (
                      <ScopeTable
                        key={scope}
                        scope={scope}
                        tableData={tableData}
                        isComparison={props.isComparison}
                        compTableData={compTableData}
                        totalEmissionSum={totalEmissionSum}
                      />
                    );
                  }
                })}
            </div>
            <div
              className="downloadGHGReport_contain_footer"
              id="downloadGHGReport_contain_footer2"
            >
              <p className="font_10_700 color_desp_grey">Page 2/2</p>
              <p className="">
                <span className="font_10_700">Contact </span>{" "}
                <span className="body_text_xs">hello@today.green</span>
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="createghg_drawerleft_footer">
        <p className="font_12_700">This GHG report was made with today.green</p>
      </div>
    </div>
  );

  return (
    <div>
      {pdfLoading && <Loader />}
      <div className="ghg_content_root" ref={ref}>
        {content}
      </div>
      <Drawer
        anchor={"right"}
        open={props.openGHGDownload}
        onClose={() => {
          props.handleGHGDownloadDrawer();
        }}
        PaperProps={{
          sx: { width: "70%" },
        }}
        className="ghg_drawer"
      >
        <div className="drawer_root">
          <div className="createghg_drawerHead">
            <IconButton
              className="offsetMarketPlace_close_cta"
              onClick={() => props.handleGHGDownloadDrawer()}
            >
              <Close />
            </IconButton>
            <div className="">
              <p className="font_13_600 color_8a text_capital">
                CONGRATULATIONS!
              </p>
              <p className="font_27_700 mt-12">Download your GHG Report</p>
              <p className="subheading_light_2 mt-6">
                Your GHG report has been created successfully. You can now
                download
                <br />
                your report or skip this step and download it from your report
                library later.
              </p>
            </div>
          </div>
          <div className="createghg_drawer_body">
            {content}
            <div className="createghg_drawer_right">
              {/* <ReactToPrint
                // pageStyles={
                //   "@media print { body { -webkit-print-color-adjust: exact; } @page { size: A4; margin: 200mm !important }}"
                // }
                // onAfterPrint={() => {
                //   navigate("/dashboard?tab=6&step=0");
                // }}
                trigger={() => (
                  <ButtonComp className="">
                    <span
                      onClick={() => {
                        saveReport();
                      }}
                    >
                      Download
                    </span>
                  </ButtonComp>
                )}
                content={() => ref.current}
              /> */}

              <ButtonComp onClick={generatePdf}>Download</ButtonComp>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default DownloadGHGReport;