import { GppGood } from "@mui/icons-material";
import {
  Avatar,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Backdrop,
  Box,
  Modal,
  Fade,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./index.css";
import EditIcon from "../../../Assets/icons/EditIcon";
import { useAuth } from "../../../Utils/Auth";
import EditProfile from "./EditProfile";
import { useNavigate } from "react-router-dom";
import { toastResponseError } from "../../../Utils/toastResponseError";
import axios from "../../../Api";
import { toast } from "react-hot-toast";
import { botAvatarGroup } from "../../../Utils/botAvatarsGroup";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import { maskMobileNumber } from "../../../Utils/CommonFunctions";
import OtpInput from "react-otp-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ButtonComp from "../../../Assets/components/Button";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 850,
  maxWidth: "90%",
  bgcolor: "#ffffff",
  outline: "none",
  borderRadius: "10px",
  boxShadow: 24,
};

const phoneNumberRegex = /^\+?\d{1,3}?\d{10}$/;

const Profile = () => {
  const { profile, user, getUser, setSessionExpire, setUser } = useAuth();
  const [profileEditStep, setprofileEditStep] = useState(1);
  const [personalizeAvartarEdit, setPersonlizeAvartarEdit] = useState(false);
  const navigate = useNavigate();
  const [bot_avatar, setBot_avatar] = useState(user?.bot_avatar_id);
  const [selectedBotAvatar, setSelectedBot_avatar] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [Googleopen, setGoogleOpen] = useState(false);
  const handleGoogleOpen = () => setGoogleOpen(true);
  const handleGoogleClose = () => setGoogleOpen(false);

  const changeProfileDetails = async () => {
    await axios({
      url: `/user/auth/edit/user`,
      method: "put",
      data: {
        bot_avatar_id: selectedBotAvatar,
      },
    })
      .then((res) => {
        if (res.data.success) {
          getUser();
          setPersonlizeAvartarEdit(false);
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const [phoneAuthStep, setPhoneAuthStep] = useState(2);
  const [newphoneNumber, setNewPhoneNumber] = useState("");
  const [timer, setTimer] = useState(59);
  const [timerRunning, setTimerRunning] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [phoneOtp, setPhoneOtp] = useState();
  const [phonePrevOtp, setPhonePrevOtp] = useState();
  const [isSent, setIsSent] = useState(false);
  const [activateOtp, setActivateOtp] = useState(false);

  // google auth states
  // const [isGoogleAuthExist, setIsGoogleAuthExist] = useState(false);
  const [scannerImage, setScannerImage] = useState(null);
  const [googleAuthStep, setGoogleAuthStep] = useState(0);
  const [googleOtp, setGoogleOtp] = useState(null);
  const [googlePrevOtp, setGooglePrevOtp] = useState(null);

  useEffect(() => {
    if (user) {
      setBot_avatar(user?.bot_avatar_id);
    }
  }, [user]);

  const sendOtp = async (phoneNumber, step) => {
    if (!phoneNumberRegex.test(phoneNumber)) {
      return toast.error("Invalid phone number");
    }
    setPhoneOtp();
    await axios({
      url: `/two-fa/otp-request`,
      method: "POST",
      data: {
        phoneNumber: phoneNumber,
      },
    })
      .then((res) => {
        if (res.data.success) {
          setTimer(59);
          setTimerRunning(true);
          setPhoneAuthStep(step);
          setIsSent(true);
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const verifyOtp = async (phone, otp, step) => {
    if (!phoneNumberRegex.test(phone)) {
      return toast.error("Invalid phone number");
    }
    if (!otp) {
      return toast.error("Invalid OTP");
    }
    setIsDisabled(true);
    await axios({
      url: `/two-fa/otp-verify`,
      method: "POST",
      data: {
        phoneNumber: phone,
        otp: otp,
      },
    })
      .then(async (response) => {
        setIsDisabled(false);
        if (response.data.status === "approved") {
          // await getUser();
          setUser({
            ...user,
            is2FASet: true,
            twofactorType: "otp",
            phone: phone,
          });
          toast.success("Verified");
          setPhoneOtp(null);
          setPhonePrevOtp(null);
          setPhoneAuthStep(step);
          setNewPhoneNumber(null);
          setActivateOtp(false);
          setIsSent(false);
        } else if (response.data.status === "pending") {
          toast.error("Invalid OTP");
          setPhoneOtp(null);
          setPhonePrevOtp(null);
        } else {
          setPhoneOtp(null);
          setPhonePrevOtp(null);
          toast.error(response.data.message);
        }
      })
      .catch((err) => {
        setPhoneOtp(null);
        setPhonePrevOtp(null);
        setIsDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  // google auth function
  const generateGoogleAuthenticatorToken = async () => {
    await axios({
      url: `/two-fa/register`,
      method: "POST",
      data: {
        twofactorType: "google",
      },
    })
      .then((res) => {
        setScannerImage(res.data.image);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const generateEditedGoogleAuth = async () => {
    await axios({
      url: `/two-fa/edit/google/auth`,
      method: "put",
    })
      .then((res) => {
        setScannerImage(res.data.image);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const getExitanceOfGoogleAuth = async () => {
    await axios({
      url: `/two-fa/google/auth/exist`,
      method: "get",
    })
      .then((res) => {
        if (res.data.success) {
          if (res.data.qrCodeExist) {
            // setIsGoogleAuthExist(true);
          } else {
            generateGoogleAuthenticatorToken();
            // setIsGoogleAuthExist(false);
          }
          setGoogleAuthStep(1);
          handleGoogleOpen();
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const verifyGoogleOtp = async (otp, step) => {
    await axios({
      url: `/two-fa/verify`,
      method: "POST",
      data: {
        code: otp,
      },
    })
      .then((res) => {
        if (res.data.success) {
          if (step === 1) {
            generateEditedGoogleAuth();
          }
          setGoogleOtp(null);
          setGooglePrevOtp(null);
          setGoogleAuthStep(step);
          toast.success("OTP Verified");
          setUser({
            ...user,
            is2FASet: true,
            twofactorType: "google",
          });
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    let interval = null;

    if (timerRunning) {
      interval = setInterval(() => {
        setTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [timerRunning]);

  useEffect(() => {
    if (timer === 0) {
      setTimerRunning(false);
    }
  }, [timer]);

  useEffect(() => {
    if (phoneOtp && phoneOtp.length === 6) {
      (async () => {
        if (activateOtp) {
          await verifyOtp(user.phone, phoneOtp, 0);
        } else {
          await verifyOtp(newphoneNumber, phoneOtp, 0);
        }
        handleClose();
      })();
    }
  }, [phoneOtp]);

  // google authorization
  useEffect(() => {
    if (googlePrevOtp && googlePrevOtp.length === 6) {
      (async () => {
        // if (activateOtp) {
        //   await verifyOtp(user.phone, phoneOtp, 0);
        // } else {
        //   await verifyOtp(newphoneNumber, phoneOtp, 0);
        // }
        await verifyGoogleOtp(googlePrevOtp, 1);
        // handleClose();
      })();
    }
  }, [googlePrevOtp]);
  useEffect(() => {
    if (googleOtp && googleOtp.length === 6) {
      (async () => {
        await verifyGoogleOtp(googleOtp, 0);
        handleGoogleClose();
      })();
    }
  }, [googleOtp]);

  useEffect(() => {
    if (phonePrevOtp && phonePrevOtp.length === 6) {
      verifyOtp(user.phone, phonePrevOtp, 1);
    }
  }, [phonePrevOtp]);

  //  console.log(user);

  // Language setup
  const { i18n,t } = useTranslation();
  useEffect(()=>{
    if(user) i18n.changeLanguage(user.language);
  },[user])

 
  
  return (
    <div className="profile_root ml-100 mr-50">
      <div className="row">
        <div className="col-lg-10 col-md-11 col-12">
          <div className="main_box">
            {profileEditStep === 1 ? (
              <div className="profile_setting_card">
                <div className="border-btm_dc d-flex align-items-center justify-content-between pb-24">
                  <p className="offsetProjectTitle">{t("profile.profileSettings")}</p>
                  <button
                    className="profileEditbtn"
                    onClick={() => {
                      setprofileEditStep(2);
                      navigate(`/dashboard?tab=10&editStep=2`);
                    }}
                  >
                    <EditIcon fontSize="small" />
                  </button>
                </div>
                <div className="border-btm_dc d-flex align-items-center pt-24 pb-24">
                  <Avatar
                    src={user?.avatar}
                    sx={{ height: 72, width: 72, border: "1px solid #efefef" }}
                  >
                    {String(user?.firstname).charAt(0).toUpperCase()}
                  </Avatar>
                  <div className="ml-24">
                    <div className="d-flex align-items-center">
                      <p className="card_heading">
                        {user?.firstname} {user?.lastname}
                      </p>
                      <div className="square_box bg_soft_blue ml-16">
                        <p className="color_blue text-capitalize font_12_500">
                          {user?.type}
                        </p>
                      </div>
                    </div>
                    <p className="mt-6 subheading_light_2">{user?.email}</p>
                  </div>
                </div>
                <div className="pt-32">
                  <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                      <p className="font_14_500">{t("common.company")}</p>
                    </div>
                    <div className="col">
                      <p className="body_text_m">{profile?.companyName}</p>
                    </div>
                  </div>
                  <div className="row mt-24">
                    <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                      <p className="font_14_500">{t("profile.language")}</p>
                    </div>
                    <div className="col">
                      <p className="body_text_m">{user.language === 'de' ? "German": "English"}</p>
                    </div>
                   
                  </div>
                  {/* <div className="row mt-24">
                  <div className="col-lg-2 col-md-3 col-sm-3 col-4">
                    <p className="font_14_500">Time Zone</p>
                  </div>
                  <div className="col">
                    <p className="body_text_m">Kvadrat LLC</p>
                  </div>
                </div> */}
                </div>
              </div>
            ) : (
              profileEditStep === 2 && (
                <EditProfile setprofileEditStep={setprofileEditStep} />
              )
            )}
            <div>
              {user?.is2FASet ? (
                <div>
                  <p className="subheading_1 mt-40">
                    Two Factor Authentication
                  </p>
                  <TableContainer sx={{ minWidth: 640 }} className="mt-20">
                    <Table aria-label="simple table">
                      <TableHead>
                        <StyledHeadRow>
                          <HeadCell component="th" className="text-uppercase">
                            Method
                          </HeadCell>
                          <HeadCell>Details</HeadCell>
                          <HeadCell>Status</HeadCell>
                          <HeadCell></HeadCell>
                        </StyledHeadRow>
                      </TableHead>
                      <TableBody>
                        <StyledTableRow>
                          <StyledTableCell scope="row" className="text-start">
                            SMS Verification
                          </StyledTableCell>
                          <StyledTableCell scope="row" className="text-start">
                            {user.phone ? maskMobileNumber(user.phone) : " - "}
                          </StyledTableCell>
                          <StyledTableCell scope="row" className="text-start">
                            {user.twofactorType === "otp"
                              ? "Active"
                              : "Not Configured"}
                          </StyledTableCell>
                          <StyledTableCell scope="row">
                            {user.twofactorType === "otp" ? (
                              <ButtonComp
                                status={3}
                                className="text-black"
                                onClick={async () => {
                                  sendOtp(user.phone, 0);
                                  handleOpen();
                                }}
                              >
                                Edit
                              </ButtonComp>
                            ) : (
                              <ButtonComp
                                status={3}
                                className="text-black"
                                onClick={() => {
                                  if (user.phone) {
                                    setActivateOtp(2);
                                    sendOtp(user.phone, 2);
                                  } else {
                                    setPhoneAuthStep(1);
                                  }
                                  handleOpen();
                                }}
                              >
                                Activate
                              </ButtonComp>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <StyledTableCell scope="row" className="text-start">
                            Google Authenticator
                          </StyledTableCell>
                          <StyledTableCell scope="row" className="text-start">
                            -
                          </StyledTableCell>
                          <StyledTableCell scope="row" className="text-start">
                            {user.twofactorType === "google"
                              ? "Active"
                              : "Not Configured"}
                          </StyledTableCell>
                          <StyledTableCell scope="row">
                            {user.twofactorType === "google" ? (
                              <ButtonComp
                                status={3}
                                className="text-black"
                                onClick={handleGoogleOpen}
                              >
                                Edit
                              </ButtonComp>
                            ) : (
                              <ButtonComp
                                status={3}
                                className="text-black"
                                onClick={getExitanceOfGoogleAuth}
                              >
                                Activate
                              </ButtonComp>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div className="mt-48 secureAccount_learn_box">
                  <p className="card_heading">
                    <span className="mr-14">
                      <GppGood fontSize="small" htmlColor="#00915B" />
                    </span>
                    {t("profile.secureYourAccount")}
                  </p>
                  <p
                    className="mt-8 ml-32 subheading_light_2"
                    style={{ color: "#8a8a8a" }}
                  >
                    {t("profile.twoFactorAuth")}{" "}
                    <span
                      className="link_text_blue pointer"
                      onClick={() => {
                        if (user.phone) {
                          setActivateOtp(2);
                          sendOtp(user.phone, 2);
                        } else {
                          setPhoneAuthStep(1);
                        }
                        handleOpen();
                      }}
                    >
                      {t("profile.setupTwoFactor")}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <div className="profile_setting_card mt-56">
              <div className="border-btm_dc d-flex align-items-center justify-content-between pb-24">
                <p className="offsetProjectTitle">{t("profile.personalize")}</p>
                {personalizeAvartarEdit ? (
                  <ButtonComp
                    status={1}
                    className="md"
                    onClick={() => changeProfileDetails()}
                  >
                    {t("common.save")}
                  </ButtonComp>
                ) : (
                  <button
                    className="profileEditbtn"
                    onClick={() => setPersonlizeAvartarEdit(true)}
                  >
                    <EditIcon fontSize="small" />
                  </button>
                )}
              </div>
              <div className="border-btm_dc d-flex align-items-center pt-24 pb-24">
                <Avatar
                  src={botAvatarGroup[bot_avatar]?.face?.url}
                  sx={{
                    height: 72,
                    width: 72,
                    border: "1px solid #efefef",
                    objectFit: "cover",
                  }}
                  className="avatar-img-contain"
                >
                  {String(user?.firstname).charAt(0).toUpperCase()}
                </Avatar>
                <p className="card_heading ml-24">{t("profile.yourAvatar")}</p>
              </div>
              {personalizeAvartarEdit && (
                <div className="mt-48 d-flex align-items-center flex-wrap">
                  {Object.keys(botAvatarGroup).map((avt, i) => (
                    <div
                      className="personlize_avt_main mb-24 mr-24"
                      key={i}
                      onClick={() => setSelectedBot_avatar(avt)}
                    >
                      <img
                        src={botAvatarGroup[avt].face.url}
                        className={
                          selectedBotAvatar === avt && "card_selection_border"
                        }
                        alt="personlize_avt"
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <div className="profile_edit_modal">
              {phoneAuthStep === 0 ? (
                <>
                  <div className="pb-24 border-btm_dc mb-24">
                    <p className="offsetProjectTitle">
                      Enter the Code to Edit this 2FA Method
                    </p>
                  </div>
                  <div className="pb-24 border-btm_dc mb-16">
                    <p className="subheading_1">
                      Enter 6-didgit code from the verification SMS
                    </p>
                    <div className="otp_input mt-24">
                      <div className="d-flex align-items-center">
                        <OtpInput
                          value={phonePrevOtp}
                          onChange={setPhonePrevOtp}
                          numInputs={6}
                          renderSeparator={
                            <span className="input_otp_span"></span>
                          }
                          className="otp_input"
                          renderInput={(props) => <input {...props} />}
                        />
                        {isDisabled && <CircularProgress />}
                      </div>
                      {isSent && (
                        <div className="mt-12">
                          {timerRunning && timer > 0 ? (
                            <p className="overline_text">
                              Resend otp in :{" "}
                              <span>{String(timer).padStart(2, "0")}</span> sec
                            </p>
                          ) : (
                            <p
                              className="overline_text pointer underline"
                              onClick={() => sendOtp(user.phone, 0)}
                            >
                              Resend OTP
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : phoneAuthStep === 1 ? (
                <>
                  <div className="pb-24 border-btm_dc mb-24">
                    <p className="offsetProjectTitle">
                      Activate 2FA SMS Method
                    </p>
                  </div>
                  <div className="row align-items-end pb-24 border-btm_dc mb-16">
                    <div className="col-lg-6 col-md-6 col-12">
                      <div className="input_div">
                        <label
                          className="subheading_1"
                          htmlFor="currentPassword"
                        >
                          New Phone Number
                        </label>
                        <PhoneInput
                          placeholder="Enter your Phone Number"
                          value={newphoneNumber}
                          onChange={(e) => setNewPhoneNumber(e)}
                          className="mt-12 input_div flex-row otp_phone_box "
                          international
                          defaultCountry="DE"
                        />
                        {/* <input
                          type="text"
                          name="currentPassword"
                          id="currentPassword"
                          className="mt-12 subheading_2"
                          value={newphoneNumber}
                          onChange={(e) => setNewPhoneNumber(e.target.value)}
                          placeholder="Enter Phone Number"
                        /> */}
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-12">
                      <div>
                        <ButtonComp
                          status={3}
                          className="text-black"
                          onClick={() => {
                            sendOtp(newphoneNumber, 2);
                          }}
                          disabled={isDisabled}
                        >
                          Send Verification SMS
                        </ButtonComp>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                phoneAuthStep === 2 && (
                  <>
                    <div className="pb-24 border-btm_dc mb-24">
                      <p className="offsetProjectTitle">Edit 2FA SMS Method</p>
                    </div>
                    <div className="pb-24 border-btm_dc mb-16">
                      <p className="subheading_1">
                        Enter 6-didgit code from the verification SMS
                      </p>
                      <div className="otp_input mt-24">
                        <div className="d-flex align-items-center">
                          <OtpInput
                            value={phoneOtp}
                            onChange={setPhoneOtp}
                            numInputs={6}
                            renderSeparator={
                              <span className="input_otp_span"></span>
                            }
                            className="otp_input mr-12"
                            renderInput={(props) => <input {...props} />}
                          />
                          {isDisabled && <CircularProgress />}
                        </div>
                        <div className="mt-12">
                          {timerRunning && timer > 0 ? (
                            <p className="overline_text">
                              Resend otp in :{" "}
                              <span>{String(timer).padStart(2, "0")}</span> sec
                            </p>
                          ) : (
                            <p
                              className="overline_text pointer underline"
                              onClick={() => sendOtp(newphoneNumber, 2)}
                            >
                              Resend OTP
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )
              )}
            </div>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={Googleopen}
        onClose={handleGoogleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={Googleopen}>
          <Box sx={style}>
            <div className="profile_edit_modal">
              {googleAuthStep === 0 ? (
                <>
                  <div className="pb-24 border-btm_dc mb-24">
                    <p className="offsetProjectTitle">
                      Enter the Code to Edit this 2FA Method
                    </p>
                  </div>
                  <div className="pb-24 border-btm_dc mb-16">
                    <p className="subheading_1">Enter Verification Code</p>
                    <div className="otp_input mt-24">
                      <div className="d-flex align-items-center">
                        <OtpInput
                          value={googlePrevOtp}
                          onChange={setGooglePrevOtp}
                          numInputs={6}
                          renderSeparator={
                            <span className="input_otp_span"></span>
                          }
                          className="otp_input mr-24"
                          renderInput={(props) => <input {...props} />}
                        />
                        {isDisabled && <CircularProgress />}
                      </div>
                    </div>
                  </div>
                </>
              ) : googleAuthStep === 1 ? (
                <>
                  <div className="pb-24 border-btm_dc">
                    <p className="offsetProjectTitle">
                      Activate Google Authenticator Method
                    </p>
                  </div>
                  {scannerImage && (
                    <div className="mt-32 pb-24 border-btm_dc">
                      <div className="twofa_qr_image mt-32 pb-24 border-btm_dc">
                        <img src={scannerImage} alt="authencation" />
                      </div>
                      <p>
                        Please delete old saved data in your authenticator app
                        and scan this scan to verify yourself from now
                      </p>
                    </div>
                  )}
                  <div className="pb-24 border-btm_dc mb-16 mt-24">
                    <p className="subheading_1">Enter Verification Code</p>
                    <div className="otp_input mt-24">
                      <div className="d-flex align-items-center">
                        <OtpInput
                          value={googleOtp}
                          onChange={setGoogleOtp}
                          numInputs={6}
                          renderSeparator={
                            <span className="input_otp_span"></span>
                          }
                          className="otp_input mr-24"
                          renderInput={(props) => <input {...props} />}
                        />
                        {isDisabled && <CircularProgress />}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                googleAuthStep === 2 && <></>
              )}
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Profile;
