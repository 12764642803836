import React from "react";

function AddContact(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="20"
        fill="none"
        viewBox="0 0 20 20"
      >
        <circle
          cx="8.076"
          cy="7.076"
          r="3.122"
          stroke={props.stroke}
          strokeLinecap="round"
          strokeWidth="2"
        ></circle>
        <path
          stroke={props.stroke}
          strokeLinecap="round"
          strokeWidth="2"
          d="M12.606 16.792a4.683 4.683 0 00-9.048 0M15.102 8.633v4.683M17.445 10.977h-4.683"
        ></path>
      </svg>
    </div>
  );
}

export default AddContact;
