import React from "react";

const SearchIconOutlined = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        stroke={cc || "#000"}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M13 13.002l-2.9-2.9M6.333 11.668A5.334 5.334 0 106.333 1a5.334 5.334 0 000 10.668z"
      ></path>
    </svg>
  );
};

export default SearchIconOutlined;
