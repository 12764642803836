import React from "react";

function ZoomOutIcon({ onClick, className }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill="none"
      viewBox="0 0 30 30"
      className={"pointer " + " " + className}
      onClick={onClick}
    >
      <rect width="30" height="30" fill="#00915B" rx="5"></rect>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M24 23.998l-4.35-4.35M14 22a8 8 0 100-16 8 8 0 000 16zM11 14h6"
      ></path>
    </svg>
  );
}

export default ZoomOutIcon;
