import { TableCell, TableRow } from "@mui/material";
import { styled } from "@mui/material/styles";

export const HeadCell = styled(TableCell)(({ textCenter, width }) => ({
  border: "1px solid #E6E6E6",
  borderTop: "none",
  "&:nth-type-of(1)": {
    borderLeft: "0",
  },
  fontFamily: '"Inter", sans-serif',
  fontWeight: "700",
  fontSize: 13,
  textTransform: "uppercase",
  textAlign: textCenter ? "center" : "left",
  width: width || "auto",
}));
export const HeadCellWithoutBorder = styled(TableCell)(({ textCenter, width }) => ({
  border: "none",
  "&:nth-type-of(1)": {
    borderLeft: "0",
  },
  fontFamily: '"Inter", sans-serif',
  fontWeight: "700",
  fontSize: 13,
  textTransform: "uppercase",
  textAlign: textCenter ? "center" : "left",
  width: width || "auto",
}));

export const StyledHeadRow = styled(TableRow)(() => ({
  "&:last-child": {
    backgroundColor: "#F9FAFC",
  },
}));

export const StyledTableCell = styled(TableCell)(
  ({ textleft, capitalize, width, border }) => ({
    fontFamily: '"Inter", sans-serif',
    fontSize: "14px",
    fontWeight: "500",
    border: border ? border : "1px solid #E6E6E6",
    borderBottom: "none",
    textAlign: textleft ? "left" : "center",
    textTransform: capitalize && "capitalize",
    width: width && `${width}px`,
  })
);

export const StyledTableCellWithoutBorder = styled(TableCell)(
  ({ textleft, capitalize, width }) => ({
    fontFamily: '"Inter", sans-serif',
    fontSize: "14px",
    fontWeight: "500",
    border: "none",
    textAlign: textleft ? "left" : "center",
    textTransform: capitalize && "capitalize",
    width: width && `${width}px`,
  })
);

export const StyledTableRow = styled(TableRow)(() => ({}));
