import { FormControl, MenuItem, Select } from "@mui/material";
import React from "react";
import PlusIcon from "./PlusIcon";
import { KeyboardArrowDown } from "@mui/icons-material";
import { formatNumberIntoReadableFormat } from "../../../../../../Utils/CommonFunctions";
import CloseWIthRedCross from "../../../../../../Assets/icons/CloseWIthRedCross";
import TravelMap from "./TravelMap";

const sx = {
  border: "none",
  backgroundColor: "#F6F6F6",
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#000000",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

const CalculationMethod = ({
  selectedSubClass,
  selectedCalcMethodName,
  setSelectedCalcMethodName,
  selectedActivityName,
  setSelectedActivityName,
  selectedActivityTypeName,
  setSelectedActivityTypeName,
  selectedActivitySubTypeName,
  setSelectedActivitySubTypeName,
  activities,
  setActivities,
  activityTypes,
  setActivityTypes,
  activitySubTypes,
  setActivitySubTypes,
  allUnits,
  setAllUnits,
  selectedUnit,
  setSelectedUnit,
  amounts,
  setAmounts,
  amountStructure,
}) => {
  // to set activities after selecting calulation method name
  // if activities are "" then set subactivity ................
  const handleChangeMethodName = (e) => {
    setSelectedCalcMethodName(e.target.value);
    // reseting the states to prevent the errrors from null values or changing the values because values are dependent of each other
    setSelectedActivityName("");
    setSelectedActivityTypeName("");
    setSelectedActivitySubTypeName("");
    setSelectedUnit("");
    setActivityTypes(null);
    setActivitySubTypes(null);
    setAllUnits([]);
    setAmounts([{ ...amountStructure }]);

    // set activites array
    if (
      e.target.value &&
      e.target.value !== "Direct tCO2e Entry" &&
      selectedSubClass.calculationMethods.length > 0
    ) {
      const activity = selectedSubClass.calculationMethods.find(
        (data) => data.calcMethodName === e.target.value
      );

      setActivities(activity.Activities);
      const [activityKeys, activityValues] = Object.entries(
        activity.Activities
      )[0];
      if (activityKeys === "") {
        setActivityTypes(activityValues);
        setActivities(activity.Activities);
        // values for activityType
        const [activityTypeKeys, activityTypeValues] =
          Object.entries(activityValues)[0];
        if (activityTypeKeys === "") {
          setActivitySubTypes(activityTypeValues);
          // values for activitySubType
          const [activitySubTypeKeys, activitySubTypeValues] =
            Object.entries(activityTypeValues)[0];
          if (activitySubTypeKeys === "") {
            setAllUnits(activitySubTypeValues);
          }
        }
      } else {
        setActivities(activity.Activities);
      }
    } else if (e.target.value === "Direct tCO2e Entry") {
      setSelectedUnit({
        unit: "tCO2e",
      });
    }
  };

  // set activity types
  const handleChangeActivityName = (e) => {
    setSelectedActivityTypeName("");
    setSelectedActivitySubTypeName("");
    setSelectedUnit("");
    setSelectedActivityName(e.target.value);

    if (e.target.value && selectedCalcMethodName && activities !== null) {
      setActivityTypes(activities[e.target.value]);
      const [activityTypekeys, activityTypeValues] = Object.entries(
        activities[e.target.value]
      )[0];
      if (activityTypekeys === "") {
        setActivitySubTypes(activityTypeValues);
        const [activitySubTypesKeys, activitySubTypesValues] =
          Object.entries(activityTypeValues)[0];

        if (activitySubTypesKeys === "") {
          setAllUnits(activitySubTypesValues);
        }
      }
    }
  };
  // select sub activity types
  const handleChangeActivityTypeName = (e) => {
    setSelectedActivityTypeName(e.target.value);
    setSelectedActivitySubTypeName("");
    setSelectedUnit("");
    if (selectedCalcMethodName && e.target.value) {
      setActivitySubTypes(activities[selectedActivityName][e.target.value]);
      const [keys, values] = Object.entries(
        activities[selectedActivityName][e.target.value]
      )[0];
      if (keys === "" && values.length > 0) {
        setAllUnits(values);
      }
    }
  };

  const handleActivitySubTypeNameChange = (e) => {
    setSelectedActivitySubTypeName(e.target.value);
    setSelectedUnit("");
    if (selectedCalcMethodName && e.target.value) {
      setAllUnits(
        activities[selectedActivityName][selectedActivityTypeName][
          e.target.value
        ]
      );
    }
  };

  const handleAmountChange = (index, event) => {
    const updatedAmounts = [...amounts];
    updatedAmounts[index].amount = event.target.value;
    updatedAmounts[index].emission =
      selectedCalcMethodName === "Direct tCO2e Entry"
        ? Number(event.target.value) * 1 * updatedAmounts[index].noOfUnit
        : (Number(event.target.value) *
            selectedUnit.kgCO2e *
            updatedAmounts[index].noOfUnit) /
          1000;
    setAmounts(updatedAmounts);
  };
  const handleCalculateTravelDistanceOfMap = (index, value) => {
    const updatedAmounts = [...amounts];
    updatedAmounts[index].amount = value;
    updatedAmounts[index].emission =
      (Number(value) * selectedUnit.kgCO2e * updatedAmounts[index].noOfUnit) /
      1000;
    setAmounts(updatedAmounts);
  };

  const handleCommentChange = (index, event) => {
    const updatedAmounts = [...amounts];
    if (event.target.value.length <= 260) {
      updatedAmounts[index].comment = event.target.value;
    } else {
      if (event.target.value.length > 260) {
        return;
      }
    }
    setAmounts(updatedAmounts);
  };

  const handleRemoveAmount = (index) => {
    const newAmounts = amounts.filter((_, i) => i !== index);
    setAmounts(newAmounts);
  };

  return (
    <div className="mt-30">
      <p className="font_12_600 color_7e line_height_10">Calculation Method</p>
      <FormControl
        fullWidth
        sx={{ marginTop: "10px", height: "40px" }}
        size="small"
      >
        <Select
          value={selectedCalcMethodName}
          onChange={(e) => {
            handleChangeMethodName(e);
          }}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          sx={sx}
          IconComponent={KeyboardArrowDown}
        >
          <MenuItem value={""}>Select</MenuItem>
          <MenuItem value={"Direct tCO2e Entry"}>Direct CO2e Entry</MenuItem>
          {selectedSubClass.calculationMethods.map((method, i) => (
            <MenuItem
              key={"method.calcMethodName" + i}
              value={method.calcMethodName}
            >
              {method.calcMethodName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {selectedCalcMethodName &&
        selectedCalcMethodName !== "Direct tCO2e Entry" && (
          <div className="mt-30">
            <p className="font_12_600 color_7e line_height_10">
              Emission Factor
            </p>
            {selectedCalcMethodName &&
              activities &&
              Object.keys(activities).length > 0 &&
              Object.keys(activities)[0] && (
                <FormControl
                  fullWidth
                  sx={{ marginTop: "10px", height: "40px" }}
                  size="small"
                >
                  <Select
                    value={selectedActivityName}
                    onChange={(e) => {
                      handleChangeActivityName(e);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={sx}
                    IconComponent={KeyboardArrowDown}
                  >
                    <MenuItem value={""}>Select Activity</MenuItem>
                    {Object.keys(activities).map((activity) => (
                      <MenuItem key={activity} value={activity}>
                        {activity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

            {activityTypes &&
              Object.keys(activityTypes).length > 0 &&
              Object.keys(activityTypes)[0] && (
                <FormControl
                  fullWidth
                  sx={{ marginTop: "10px", height: "40px" }}
                  size="small"
                >
                  <Select
                    value={selectedActivityTypeName}
                    onChange={(e) => {
                      handleChangeActivityTypeName(e);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={sx}
                    IconComponent={KeyboardArrowDown}
                  >
                    <MenuItem value={""}>Select Activity Type</MenuItem>
                    {Object.keys(activityTypes).map((activity) => (
                      <MenuItem key={activity} value={activity}>
                        {activity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

            {activitySubTypes &&
              Object.keys(activitySubTypes).length > 0 &&
              Object.keys(activitySubTypes)[0] && (
                <FormControl
                  fullWidth
                  sx={{ marginTop: "10px", height: "40px" }}
                  size="small"
                >
                  <Select
                    value={selectedActivitySubTypeName}
                    onChange={(e) => {
                      handleActivitySubTypeNameChange(e);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={sx}
                    IconComponent={KeyboardArrowDown}
                  >
                    <MenuItem value={""}>Select Activity Sub Type</MenuItem>
                    {Object.keys(activitySubTypes).map((activity) => (
                      <MenuItem key={activity} value={activity}>
                        {activity}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            {allUnits.length > 0 && (
              <FormControl
                fullWidth
                sx={{ marginTop: "10px", height: "40px" }}
                size="small"
              >
                <Select
                  value={selectedUnit}
                  onChange={(e) => setSelectedUnit(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={sx}
                  IconComponent={KeyboardArrowDown}
                >
                  <MenuItem value={""}>Select Unit</MenuItem>
                  {allUnits.map((unit, i) => (
                    <MenuItem key={unit.unit + i} value={unit}>
                      {unit.unit}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </div>
        )}
      {selectedCalcMethodName && selectedUnit && (
        <>
          {amounts &&
            amounts.length > 0 &&
            amounts.map((amount, i) => (
              <React.Fragment key={"amount" + i}>
                <hr className="mt-30 mb-30" />
                <div className="quick_log_amount_structure position-relative">
                  {i !== 0 && (
                    <div
                      className="task_remove_amount"
                      onClick={() => handleRemoveAmount(i)}
                    >
                      <CloseWIthRedCross />
                    </div>
                  )}
                  <div className="quick_log_amount_data d-flex justify-content-between align-items-center gap_20">
                    <div className="d-flex gap_20 align-items-center">
                      <div>
                        <p className="font_12_600 color_7e line_height_10">
                          Activity Data
                        </p>
                        <input
                          type="number"
                          className="mt-10 grey_input font_14_500"
                          style={{ width: 140 }}
                          value={amount.amount}
                          onChange={(e) => handleAmountChange(i, e)}
                        />
                      </div>
                      <div>
                        <p className="font_12_600 color_7e line_height_10">
                          Unit
                        </p>
                        <div
                          className="mt-10 grey_input d-flex justify-content-start align-items-center"
                          style={{ width: 140 }}
                        >
                          <p className="font_14_500">{selectedUnit.unit}</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p className="font_12_600 color_7e line_height_10 opacity-0">
                        .
                      </p>
                      <p className="mt-10">
                        <span className="line_height_10 font_20_700 color_31">
                          {String(amount.emission).length > 7
                            ? formatNumberIntoReadableFormat(
                                Number(amount.emission)
                              )
                            : Number(amount.emission).toLocaleString(
                                undefined,
                                {
                                  maximumFractionDigits: 2,
                                  minimumFractionDigits: 2,
                                }
                              )}
                        </span>
                        <span className="font_10_700 fw-600 color_31 line_height_10">
                          {" "}
                          tCO2e
                        </span>
                      </p>
                    </div>
                  </div>
                  <div
                    className="quick_log_amount_note mt-20"
                    style={{ marginRight: 72 }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="font_12_600 color_7e line_height_10">
                        Notes
                      </p>
                      <p
                        className={
                          "font_12_600 line_height_10 " +
                          (amount.comment.length > 260
                            ? "color_red"
                            : "color_7e")
                        }
                      >
                        {amount.comment.length}/260
                      </p>
                    </div>
                    <textarea
                      value={amount.comment}
                      onChange={(e) => handleCommentChange(i, e)}
                      className="grey_textarea font_14_500 line_height_20 mt-10 w-100"
                    ></textarea>
                  </div>
                </div>
                {(selectedCalcMethodName === "Distance Traveled" ||
                  selectedCalcMethodName === "Vehicle Distance Traveled") &&
                  selectedUnit.unit && (
                    <>
                      <hr />
                      <TravelMap
                        handleAmountChange={handleCalculateTravelDistanceOfMap}
                        index={i}
                        unit={selectedUnit.unit}
                      />
                    </>
                  )}
              </React.Fragment>
            ))}
          <PlusIcon
            className="mt-10"
            onClick={() =>
              setAmounts((prev) => [...prev, { ...amountStructure }])
            }
          />
        </>
      )}
    </div>
  );
};

export default CalculationMethod;
