import { useLocation } from "react-router-dom";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const getUrlqueries = (queryname) => {
  const query = useQuery();
  return query.get(queryname);
};

export default getUrlqueries;
