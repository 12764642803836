import React, { useEffect, useState } from "react";
import CustomToolTip from "./customToolTip";
function TextInput(props) {
  const [currVal, setCurrVal] = useState("");
  useEffect(() => {
    props.setAnswers((prev) => {
      return {
        ...prev,
        [props.questionNo]: currVal,
      };
    });
    //props.handleAnswerChange(currVal, props.questionNo)
  }, [currVal]);

  const handleChange = (value) => {
    setCurrVal(value);
  };
  return (
    <div>
      <div className="d-flex align-items-center">
        <p className="sr-only subheading_1 mb-8">{props.title}</p>
        {props.description && props.description.length>0 ? <div>
          <CustomToolTip title={props.description} />
        </div>:""}
      </div>
      <input
        type={props.type}
        value={currVal}
        className={`form-control shadow-none SideDrawer_input ml-0`}
        id="service_name"
        placeholder={props.placeholder ? `${props.placeholder}`:`Name`}
        onChange={
          (event) => handleChange(event.target.value)
          //props.handleAnswerChange(event.target.value, props.questionNo)
        }
      />
      <p className="helper_text fw-400 ml-2 mt-14">{props.helperText}</p>
    </div>
  );
}

export default TextInput;
