import React, { useEffect, useState } from "react";
import EmissionChart from "./Charts/EmissionChart";
// import TargetTracker from "./Charts/TargetTracker";
import Scops from "./Charts/Scops";
import DonwIntradayIcon from "../../../../Assets/icons/DownIntraday";
import {
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../Utils/TableComp";
import EmissionCategory from "./Charts/EmissionCategory";
import axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import { useAuth } from "../../../../Utils/Auth";
// import TableTrackerImg from "../../../../Assets/images/targetTracker.png";
// import CompanyTargetImg from "../../../../Assets/images/CompanyTarget.png";
import UpIntradayIcon from "../../../../Assets/icons/UpIntradayIcon";
import SubScopeTables from "./SubScopeTables";
import { getServiceIcon } from "../../../../Utils/itemIcon";
import itemJson from "../../../Companydashboard/LogDashboard/items-new.json";
// import subScopeCategory from "../../../../DataJSON/subScopes.json";
// import ButtonComp from "../../../../Assets/components/Button";
import Name from "../../../../Assets/components/Empty/name";
import Text from "../../../../Assets/components/Empty/text";
import EmissionChartPlaceholder from "../../../CommonComponents/Placeholders/EmissonChartPlaceholder";
import ScopePlaceholder from "../../../CommonComponents/Placeholders/ScopePlaceholder";
import EmissionCategoryPlaceholder from "../../../CommonComponents/Placeholders/EmissionCategoryPlaceholder";
// import Axios from "../../../../Api";
import {
  localNumber,
  // notifedFeatures,
} from "../../../../Utils/CommonFunctions";
// import toast from "react-hot-toast";
// import TickMark from "../../../../Assets/icons/tickMark";

const Emission = () => {
  const [selectProtocol, setselectProtocol] = useState(1);
  const { setSessionExpire } = useAuth();
  const [emissionData, setEmissionData] = useState({
    totalEmission: 0,
    comparedEmission: 0,
    totalAccount: 0,
  });
  const [emissionOverview, setEmissionOverview] = useState([]);
  const [emissionOverviewLoading, setEmissionOverviewLoading] = useState(true);
  const [scopeEmission, setScopeEmission] = useState([]);
  const [scopeEmissionLoading, setScopeEmissionLoading] = useState(true);
  const [scope1Table, setScope1Table] = useState([]);
  const [scope1Emission, setScope1Emission] = useState(0);
  const [scope2Emission, setScope2Emission] = useState(0);
  const [scope3Emission, setScope3Emission] = useState(0);
  const [scope2Table, setScope2Table] = useState([]);
  const [scope3Table, setScope3Table] = useState([]);
  const [years, setYears] = useState([]);
  const [emissionyears, setEmissionYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // const [selectedEmissionYear, setSelectedEmissionYear] = useState(
  //   new Date().getFullYear()
  // );
  const [emissionCategoryData, setEmissionCategoryData] = useState([]);
  const [emissionCategoryBarData, setEmissionCategoryBarData] = useState([]);
  // const [emissionCategoryBarLoading, setEmissionCategoryBarLoading] =
  //   useState(false);
  const [accountingItemCategory, setAccountingItemCategory] = useState("");
  const [allAccountingItemCategories, setAllAccountingItemCategories] =
    useState([]);
  const [accountingItemList, setAccountingItemList] = useState([]);
  const [selectedAccountingItem, setSelectedAccountingItem] = useState("");

  const getLogByCategory = async () => {
    try {
      await axios
        .get(`/log/get-log-by-category?itemId=${accountingItemCategory}`)
        .then((res) => {
          if (res.data.success) {
            setAccountingItemList(res.data.logs);
            setYears(res.data.accountingItemYears);
          }
        });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const getAccountingItems = async () => {
    try {
      await axios.get(`/log/logged-accounting-items`).then((res) => {
        const accountingItems = itemJson.filter((item) =>
          res.data.accountingItemCategories.includes(item.name)
        );
        setAllAccountingItemCategories(accountingItems);
        setYears(res.data.accountingItemYears);
        setEmissionYears(res.data.accountingItemYears);
      });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  useEffect(() => {
    getAccountingItems();
  }, []);
  useEffect(() => {
    if (accountingItemCategory && accountingItemCategory.length > 0)
      getLogByCategory();
  }, [accountingItemCategory]);

  const getTotalEmissions = async () => {
    await axios({
      url: `/analytics/total-emission`,
      method: "get",
      params: {
        year: selectedYear,
      },
    })
      .then((res) => {
        const { totalEmission, comparedEmissions, totalLogs } = res.data;
        setEmissionData({
          totalEmission: totalEmission,
          comparedEmission: comparedEmissions,
          totalAccount: totalLogs,
        });
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const getEmissionOverview = async () => {
    await axios({
      url: `/analytics/scopes`,
      method: "get",
      params: {
        year: selectedYear,
      },
    })
      .then((res) => {
        setEmissionOverviewLoading(false);
        setEmissionOverview(res.data.stackBars);
      })
      .catch((err) => {
        setEmissionOverviewLoading(false);
        toastResponseError(err, setSessionExpire);
      });
  };
  const getScopeEmission = async () => {
    await axios({
      url: `/analytics/scope-emission?logId=${selectedAccountingItem}`,
      method: "get",
      params: {
        year: selectedYear,
        itemId: accountingItemCategory,
      },
    })
      .then((res) => {
        setScopeEmissionLoading(false);
        const {
          scopeEmissions,
          Comparison,

          scope1DataN,
          scope2DataN,
          scope3DataN,
        } = res.data.data;
        const s1TotalEmmission =
          scope1DataN.length > 0
            ? scope1DataN.reduce(
                (prev, cur) =>
                  prev + (cur.totalEmission ? cur.totalEmission : 0),
                0
              )
            : 0;
        const s2TotalEmmission =
          scope2DataN.length > 0
            ? scope2DataN.reduce(
                (prev, cur) =>
                  prev + (cur.totalEmission ? cur.totalEmission : 0),
                0
              )
            : 0;
        const s3TotalEmmission =
          scope3DataN.length > 0
            ? scope3DataN.reduce(
                (prev, cur) =>
                  prev + (cur.totalEmission ? cur.totalEmission : 0),
                0
              )
            : 0;
        setScope1Emission(s1TotalEmmission);
        setScope2Emission(s2TotalEmmission);
        setScope3Emission(s3TotalEmmission);
        setScope1Table(scope1DataN);
        setScope2Table(scope2DataN);
        setScope3Table(scope3DataN);
        const bg = ["bg_soft_blue_bc", "bg_scope2", "bg_scope3"];
        const convertedData = Object.entries(scopeEmissions).map(
          ([name, value], i) => ({
            name: name,
            value: value,
            compare: Comparison[i],
            bg: bg[i],
          })
        );
        setScopeEmission(convertedData);
      })
      .catch((err) => {
        setScopeEmissionLoading(false);
        toastResponseError(err, setSessionExpire);
      });
  };
  const getScopeEmissionCategory = async () => {
    // setEmissionCategoryBarLoading(true);
    await axios({
      url: `/analytics/emission-catergories?logId=${selectedAccountingItem}`,
      method: "get",
      params: {
        year: selectedYear,
      },
    })
      .then((res) => {
        // setEmissionCategoryBarLoading(false);
        const { allCatergories } = res.data;
        setEmissionCategoryData(allCatergories);
        const convertData = allCatergories.map((obj) => ({
          name: obj._id,
          value: obj.years[0] ? obj.years[0].totalEmission : 0,
        }));

        setEmissionCategoryBarData(convertData);
      })
      .catch((err) => {
        // setEmissionCategoryBarLoading(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getTotalEmissions();
    getEmissionOverview();
    getScopeEmission();
    getScopeEmissionCategory();
  }, [selectedYear, selectedAccountingItem, accountingItemCategory]);

  useEffect(() => {
    getTotalEmissions();
  }, [selectedYear]);

  // const [isExist, setIsExist] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(false);

  // const handleInviteFunc = async () => {
  //   setIsDisabled(true);
  //   await Axios({
  //     url: `/getnotified/add`,
  //     method: "POST",
  //     data: {
  //       feature: notifedFeatures.reduceTarget,
  //     },
  //   })
  //     .then((res) => {
  //       setIsDisabled(false);
  //       if (res.data.success) {
  //         toast.success(res.data.message);
  //         setIsExist(true);
  //       }
  //     })
  //     .catch((err) => {
  //       setIsDisabled(false);
  //       toastResponseError(err, setSessionExpire);
  //     });
  // };
  // const getAlreadyNotified = async () => {
  //   await Axios({
  //     url: `/getnotified/isExist/${notifedFeatures.reduceTarget}`,
  //     method: "get",
  //   })
  //     .then((res) => {
  //       if (res.data.success) {
  //         setIsExist(res.data.isExist);
  //       }
  //     })
  //     .catch((err) => {
  //       toastResponseError(err, setSessionExpire);
  //     });
  // };

  // useEffect(() => {
  //   getAlreadyNotified();
  // }, []);

  return (
    <div className=" pb-32">
      <div className="emission_analytic_container mt-24">
        <div className="d-flex align-items-center justify-content-between">
          <h1 className="heading_3 flex-grow-1">YOUR EMISSIONS</h1>
          {/* <select
            className="select_input w-auto subheading_2"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </select> */}
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {emissionyears.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="row mt-10 header_card_blue mx-0 mb-10">
          <div className="productDetailsTabs_sm_container col-4 d-flex justify-content-between  p-16 pb-4">
            <div className="mt-10">
              <p className="font_30_700">
                {Number(emissionData.totalEmission).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                <span className="body_text_sm">tCO2e</span>
              </p>
              <p className="overline_text color_white mt-10">Total Emissions</p>
            </div>
            <div>
              <p className="neutral_label fw-400">{selectedYear}</p>
            </div>
          </div>

          <div className="col-4 d-flex justify-content-around align_text_left p-16 ml-20">
            <div className="mt-10">
              <p className="font_30_700">
                {emissionData.comparedEmission
                  ? Number(emissionData.comparedEmission).toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )
                  : 0}{" "}
                <span className="body_text_sm">tCO2e</span>
              </p>
              <p className="overline_text color_white mt-10">
                Compared Emissions (YOY)
              </p>
            </div>
            <div className="">
              <p className="neutral_label fw-400 ">
                {selectedYear} vs {selectedYear - 1}
              </p>
            </div>
          </div>

          <div className="col-3 align_text_left p-16 ml-20">
            <div className="mt-10">
              <p className="font_30_700">{emissionData.totalAccount}</p>
              <p className="overline_text color_white mt-10">
                Number of Accounting Items
              </p>
            </div>
          </div>
        </div>

        <h1 className="heading_3 mt-50 text_capital">Emission Overview</h1>

        <div className="emission-overview mt-24">
          {emissionOverviewLoading ? (
            <EmissionChart data={emissionOverview} year={selectedYear} />
          ) : emissionOverview.every((val) => val["Scope 1"] === 0) &&
            emissionOverview.every((val) => val["Scope 2"] === 0) &&
            emissionOverview.every((val) => val["Scope 3"] === 0) ? (
            <EmissionChartPlaceholder />
          ) : (
            <EmissionChart data={emissionOverview} year={selectedYear} />
          )}
          <div className="emission-overview-footer border_bottom_0 border_left_0 border_right_0 d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="span_cirlce bg_soft_blue_bc mr-8"></span>
              <p className="overline_text">Scope 1</p>
            </div>
            <div className="d-flex align-items-center ml-16">
              <span className="span_cirlce bg_scope2 mr-8"></span>
              <p className="overline_text">Scope 2</p>
            </div>
            <div className="d-flex align-items-center ml-16">
              <span className="span_cirlce bg_scope3 mr-8"></span>
              <p className="overline_text">Scope 3</p>
            </div>
          </div>
        </div>
      </div>
      <div className="emission_analytic_container mt-56">
        <div className="d-flex align-items-center justify-content-between mb-40">
          <div className="protocol_tab_root pointer border-ef">
            <div
              className={
                "protocol_tab_ " +
                (selectProtocol === 1 && "protocol_tab_selected")
              }
              onClick={() => setselectProtocol(1)}
            >
              <p
                className={
                  selectProtocol === 1
                    ? "heading_4 color_white line_height_normal "
                    : "subheading_light_2 color_8a line_height_normal "
                }
              >
                GHG Protocol
              </p>
            </div>
            <div
              className={
                "protocol_tab_ " +
                (selectProtocol === 2 && "protocol_tab_selected ")
              }
              onClick={() => setselectProtocol(2)}
            >
              <p
                className={
                  selectProtocol === 2
                    ? "heading_4 color_white  line_height_normal "
                    : "subheading_light_2 color_8a line_height_normal "
                }
              >
                Emission Details
              </p>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div>
              <FormControl sx={{ width: 300 }} size="small">
                <Select
                  value={accountingItemCategory}
                  onChange={(e) => setAccountingItemCategory(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={""}>Accounting Item Category</MenuItem>
                  {allAccountingItemCategories.map((currItem, index) => (
                    <MenuItem key={index} value={currItem._id}>
                      {currItem.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <select
                onChange={(e) => setAccountingItemCategory(e.target.value)}
                className="select_input"
              >
                <option value="">Accounting Item Category</option>

                {allAccountingItemCategories.map((currItem, index) => {
                  return (
                    <option key={index} value={currItem._id}>
                      {currItem.name}
                    </option>
                  );
                })}
              </select> */}
            </div>
            <div className="ml-16">
              <FormControl sx={{ width: 180 }} size="small">
                <Select
                  value={selectedAccountingItem}
                  onChange={(e) => setSelectedAccountingItem(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={""}>All accounting items</MenuItem>
                  {accountingItemList.map((currItem, index) => (
                    <MenuItem key={index} value={currItem._id}>
                      {currItem.itemDetails.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {/* <select
                onChange={(e) => setSelectedAccountingItem(e.target.value)}
                className="select_input"
                name=""
                id=""
              >
                <option value="">All accounting items</option>
                {accountingItemList.map((currItem, index) => {
                  return (
                    <option key={index} value={currItem._id}>
                      {currItem.itemDetails.name}
                    </option>
                  );
                })}
              </select> */}
            </div>
            <div className="ml-16">
              <FormControl sx={{ minWidth: 145 }} size="small">
                <Select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {years.map((year) => (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>
        {selectProtocol === 1 ? (
          <>
            <div className="row mt-40">
              <div className="col-4">
                {scopeEmissionLoading ? null : scopeEmission.every(
                    (val) => val.value === 0
                  ) ? (
                  <ScopePlaceholder />
                ) : (
                  <Scops data={scopeEmission} />
                )}
              </div>
              <div className="col-8">
                <div className="reduction_target_root">
                  <div className="row py-1">
                    {scopeEmission.length > 0 &&
                      scopeEmission.map((scope) => (
                        <div
                          className="col-4 d-flex align-items-center"
                          key={scope.name}
                        >
                          <div className="flex-grow-1">
                            <p className="card_heading">{scope.name}</p>
                            <p className="mt-30">
                              <span className="heading_lg2 ">
                                {Number(scope.value).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                              <span className="subheading_4 ml-6">tCO₂e</span>
                            </p>
                            <div
                              className={"scope_tabLine mt-12 " + scope.bg}
                            ></div>
                            <p className="overline_text mt-32 color_black">
                              Comparison (YOY)
                            </p>
                            <div className="subtitle_box border_1_ef width_fit mt-12 d-flex align-items-center comparison_circle">
                              <span className="mr-6">
                                {scope.compare > 0 ? (
                                  <UpIntradayIcon />
                                ) : (
                                  <DonwIntradayIcon />
                                )}
                              </span>
                              <p
                                className={`subheading_light_2 ${
                                  scope.compare > 0
                                    ? "color_red"
                                    : "color_green"
                                }`}
                              >
                                {scope.compare}%
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="analytics_table_container mt-56 mb-20">
              <p className="font_17_700">SCOPE 1</p>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640 }}
                className="mr-50 mt-20"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        component="th"
                        sx={{ borderLeft: "0", width: "29%" }}
                        className="text-uppercase"
                      >
                        Subscope Name & Number
                      </HeadCell>
                      <HeadCell
                        component="th"
                        className="text-uppercase"
                        sx={{ width: "20%" }}
                      >
                        emissions
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        % of total emissions
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        % of Scope emissions
                      </HeadCell>
                      <HeadCell
                        component="th"
                        sx={{ borderRight: "0" }}
                        className="text-uppercase"
                      >
                        Comparison (YOY)
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    {scope1Table && scope1Table.length > 0 ? (
                      scope1Table
                        .sort((a, b) => a.exact - b.exact)
                        .map((data, i) => (
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                borderBottom: 0,
                              },
                            }}
                            key={i}
                          >
                            <StyledTableCell
                              component="th"
                              scope="row"
                              className="text-capitalize"
                              sx={{ borderLeft: "0" }}
                              textleft={true}
                            >
                              <img
                                src={getServiceIcon(data.Scope)}
                                className="icon_sizing"
                              />
                              &nbsp; &nbsp;
                              {data.Scope} ({data.exact})
                            </StyledTableCell>
                            <StyledTableCell className="align-items-center justify-content-center text-start">
                              {localNumber(data.totalEmission)} tCO₂e
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              {emissionData.totalEmission
                                ? localNumber(
                                    (Number(data.totalEmission) * 100) /
                                      emissionData.totalEmission
                                  )
                                : 0}{" "}
                              %
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              {localNumber(
                                (Number(data.totalEmission) * 100) /
                                  scope1Emission
                              )}{" "}
                              %
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ borderRight: "0" }}
                              className="text-start"
                            >
                              <p>
                                <span className="mr-16">
                                  {Number(data.comparison) < 0 ? (
                                    <DonwIntradayIcon />
                                  ) : (
                                    <UpIntradayIcon />
                                  )}
                                </span>
                                <span
                                  className={
                                    Number(data.comparison) < 0
                                      ? "color_green"
                                      : "color_red"
                                  }
                                >
                                  {localNumber(
                                    Number(
                                      data.comparison ? data.comparison : 0
                                    ) /
                                      (Number(data.totalEmission) * 100)
                                  )}{" "}
                                  %
                                </span>
                              </p>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            borderBottom: 0,
                          },
                        }}
                      >
                        <StyledTableCell align="left" className="border_left_0">
                          <Name />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell
                          className="border_right_0"
                          align="left"
                        >
                          <Text />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="analytics_table_container mt-40 mb-20">
              <p className="font_17_700">SCOPE 2</p>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640 }}
                className="mr-50 mt-20"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        sx={{ borderLeft: "0", width: "29%" }}
                        component="th"
                        className="text-uppercase"
                      >
                        Subscope Name & Number
                      </HeadCell>
                      <HeadCell
                        component="th"
                        className="text-uppercase"
                        sx={{ width: "20%" }}
                      >
                        emissions
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        % of total emissions
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        % of Scope emissions
                      </HeadCell>
                      <HeadCell
                        sx={{ borderRight: "0" }}
                        component="th"
                        className="text-uppercase"
                      >
                        Comparison (YOY)
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    {scope2Table && scope2Table.length > 0 ? (
                      scope2Table
                        .sort((a, b) => a.exact - b.exact)
                        .map((data, i) => (
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                borderBottom: 0,
                              },
                            }}
                            key={i}
                          >
                            <StyledTableCell
                              component="th"
                              scope="row"
                              className="text-capitalize"
                              sx={{ borderLeft: "0" }}
                              textleft={true}
                            >
                              <img
                                src={getServiceIcon(data.Scope)}
                                className="icon_sizing"
                              />
                              &nbsp; &nbsp;
                              {data.Scope} ({data.exact})
                            </StyledTableCell>
                            <StyledTableCell className="align-items-center justify-content-center text-start">
                              {localNumber(data.totalEmission)} tCO₂e
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              {emissionData.totalEmission
                                ? localNumber(
                                    (Number(data.totalEmission) * 100) /
                                      emissionData.totalEmission
                                  )
                                : 0}{" "}
                              %
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              {localNumber(
                                (Number(data.totalEmission) * 100) /
                                  scope2Emission
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              %
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ borderRight: "0" }}
                              className="text-start"
                            >
                              <p>
                                <span className="mr-16">
                                  {Number(data.comparison) < 0 ? (
                                    <DonwIntradayIcon />
                                  ) : (
                                    <UpIntradayIcon />
                                  )}
                                </span>
                                <span
                                  className={
                                    Number(data.comparison) < 0
                                      ? "color_green"
                                      : "color_red"
                                  }
                                >
                                  {localNumber(
                                    Number(
                                      data.comparison ? data.comparison : 0
                                    ) /
                                      (Number(data.totalEmission) * 100)
                                  )}{" "}
                                  %
                                </span>
                              </p>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            borderBottom: 0,
                          },
                        }}
                      >
                        <StyledTableCell align="left" className="border_left_0">
                          <Name />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell
                          className="border_right_0"
                          align="left"
                        >
                          <Text />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="analytics_table_container mt-40 mb-20">
              <p className="font_17_700">SCOPE 3</p>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640 }}
                className="mr-50 mt-20"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        component="th"
                        sx={{ borderLeft: "0", width: "29%" }}
                        className="text-uppercase"
                      >
                        Subscope Name & Number
                      </HeadCell>
                      <HeadCell
                        component="th"
                        className="text-uppercase"
                        sx={{ width: "20%" }}
                      >
                        emissions
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        % of total emissions
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        % of Scope emissions
                      </HeadCell>
                      <HeadCell
                        component="th"
                        sx={{ borderRight: "0" }}
                        className="text-uppercase"
                      >
                        Comparison (YOY)
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    {scope3Table && scope3Table.length > 0 ? (
                      scope3Table
                        .sort((a, b) => a.exact - b.exact)
                        .map((data, i) => (
                          <StyledTableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                borderBottom: 0,
                              },
                            }}
                            key={i}
                          >
                            <StyledTableCell
                              component="th"
                              scope="row"
                              className="text-capitalize"
                              sx={{ borderLeft: "0" }}
                              textleft={true}
                            >
                              <img
                                src={getServiceIcon(data.Scope)}
                                className="icon_sizing"
                              />
                              &nbsp; &nbsp;
                              {data.Scope} ({data.exact})
                            </StyledTableCell>
                            <StyledTableCell className="align-items-center justify-content-center text-start">
                              {isNaN(localNumber(data.totalEmission))
                                ? localNumber(0)
                                : localNumber(data.totalEmission)}{" "}
                              tCO₂e
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              {isNaN(
                                localNumber(
                                  (Number(data.totalEmission) * 100) /
                                    emissionData.totalEmission
                                )
                              )
                                ? localNumber(0)
                                : localNumber(
                                    (Number(data.totalEmission) * 100) /
                                      emissionData.totalEmission
                                  )}{" "}
                              %
                            </StyledTableCell>
                            <StyledTableCell className="text-start">
                              {isNaN(
                                localNumber(
                                  (Number(data.totalEmission) * 100) /
                                    scope3Emission
                                )
                              )
                                ? localNumber(0)
                                : localNumber(
                                    (Number(data.totalEmission) * 100) /
                                      scope3Emission
                                  )}{" "}
                              %
                            </StyledTableCell>
                            <StyledTableCell
                              sx={{ borderRight: "0" }}
                              className="text-start"
                            >
                              <p>
                                <span className="mr-16">
                                  {Number(data.comparison) < 0 ? (
                                    <DonwIntradayIcon />
                                  ) : (
                                    <UpIntradayIcon />
                                  )}
                                </span>
                                <span
                                  className={
                                    Number(data.comparison) < 0
                                      ? "color_green"
                                      : "color_red"
                                  }
                                >
                                  {isNaN(
                                    localNumber(
                                      Number(
                                        data.comparison ? data.comparison : 0
                                      ) /
                                        (Number(data.totalEmission) * 100)
                                    )
                                  )
                                    ? localNumber(0)
                                    : localNumber(
                                        Number(
                                          data.comparison ? data.comparison : 0
                                        ) /
                                          (Number(data.totalEmission) * 100)
                                      )}{" "}
                                  %
                                </span>
                              </p>
                            </StyledTableCell>
                          </StyledTableRow>
                        ))
                    ) : (
                      <StyledTableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            borderBottom: 0,
                          },
                        }}
                      >
                        <StyledTableCell align="left" className="border_left_0">
                          <Name />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell
                          className="border_right_0"
                          align="left"
                        >
                          <Text />
                        </StyledTableCell>
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        ) : (
          <div className=" p-3 mx-3 border_radius_10 border-ef">
            <p className="subheading_3 text_capital mb-32 mt-16 ms-4">
              Emission Details
            </p>

            <div className="border_radius_10 border-ef pb-3">
              {emissionCategoryBarData && emissionCategoryBarData.length > 0 ? (
                <EmissionCategory data={emissionCategoryBarData} />
              ) : (
                <EmissionCategoryPlaceholder />
              )}
            </div>
            <div className="mt-40 analytics_table_container">
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640 }}
                className="mr-50 mt-50"
              >
                <Table
                  sx={{ minWidth: 640, borderRadius: "10px" }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        sx={{
                          borderLeft: "0",
                          width: "32%",
                          // borderRadius: "12px",
                        }}
                        component="th"
                        className="text-uppercase"
                      >
                        Emission Category Name
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        emissions
                      </HeadCell>
                      <HeadCell
                        component="th"
                        className="text-uppercase"
                        sx={{ width: "25%" }}
                      >
                        % of total emissions
                      </HeadCell>
                      <HeadCell
                        sx={{ borderRight: "0", width: "18%" }}
                        component="th"
                        className="text-uppercase"
                      >
                        Comparison (YOY)
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    {emissionCategoryData && emissionCategoryData.length > 0 ? (
                      emissionCategoryData.map((data, i) => (
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              borderBottom: 0,
                            },
                          }}
                          key={data._id + i}
                        >
                          <StyledTableCell
                            sx={{ borderLeft: "0", width: "28%" }}
                            component="th"
                            scope="row"
                            textleft={true}
                          >
                            <div className="d-flex">
                              <img
                                src={getServiceIcon(data._id)}
                                className="icon_sizing"
                              />
                              &nbsp; &nbsp;
                              <p className="ml-10">{data._id}</p>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            textleft={true}
                            className="align-items-center justify-content-center"
                          >
                            {data.years[0]
                              ? Number(
                                  data.years[0].totalEmission
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}{" "}
                            tCO₂e
                          </StyledTableCell>
                          <StyledTableCell textleft={true}>
                            {data.years[0] && emissionData.totalEmission
                              ? Number(
                                  (data.years[0] &&
                                    data.years[0].totalEmission * 100) /
                                    emissionData.totalEmission
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : 0}{" "}
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            textleft={true}
                            sx={{ borderRight: "0" }}
                          >
                            <p>
                              <span className="mr-16">
                                {Number(
                                  data.years[0] && data.years[0].comparison
                                ) < 0 ? (
                                  <DonwIntradayIcon />
                                ) : (
                                  <UpIntradayIcon />
                                )}
                              </span>
                              <span
                                className={
                                  Number(
                                    data.years[0] && data.years[0].comparison
                                  ) < 0
                                    ? "color_green"
                                    : "color_red"
                                }
                              >
                                {data.years[0]
                                  ? data.years[0].comparison === null
                                    ? 100
                                    : Number(
                                        Number(
                                          data.years[0] &&
                                            data.years[0].comparison
                                        ) /
                                          (Number(
                                            data.years[0] &&
                                              data.years[0].totalEmission
                                          ) *
                                            100)
                                      ).toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                      })
                                  : 0}{" "}
                                %
                              </span>
                            </p>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))
                    ) : (
                      <StyledTableRow>
                        <StyledTableCell align="left" className="border_left_0">
                          <Name />
                        </StyledTableCell>

                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        <StyledTableCell align="left">
                          <Text />
                        </StyledTableCell>
                        {/* <StyledTableCell
                          className="border_right_0"
                          align="left"
                        >
                          <Text />
                        </StyledTableCell> */}
                      </StyledTableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="mt-40">
              {emissionCategoryData &&
                emissionCategoryData.length > 0 &&
                emissionCategoryData.map((data) => (
                  <SubScopeTables
                    totalEmission={emissionData.totalEmission}
                    key={data._id}
                    data={data}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Emission;
