function nextYearDate(curDate) {
  let date = new Date(curDate);
  let nextYearDate = new Date(
    date.getFullYear() + 1,
    date.getMonth(),
    date.getDate()
  );
  return nextYearDate.toLocaleDateString();
}

export default nextYearDate;
