import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import InActiveTableRow from "./InActiveTableRow";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../Utils/TableComp";
import { monthNames } from "../../../../../Utils/CommonFunctions";
import Text from "../../../../../Assets/components/Empty/text";
import EmptyActiveOffsets from "../EmptyActiveOffsets";

const InActiveTable = ({ logs, getAllTables, isLoading }) => {
  let lastMonth = monthNames[new Date().getMonth() - 1];
  let lastYearMonth = monthNames[new Date().getMonth()];

  return (
    <div className="main_box mt-20 analytics_table_container">
      {isLoading ? (
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640, borderRadius: "8px" }}
          className="mr-50"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderLeft: "0", width: "40%" }}
                >
                  Accounting Item
                </HeadCell>
                <HeadCell component="th" className="text-uppercase text-center">
                  Data Completion (
                  {lastYearMonth +
                    " " +
                    String(new Date().getFullYear() - 1).slice(-2) +
                    " - " +
                    lastMonth +
                    " " +
                    String(new Date().getFullYear()).slice(-2)}
                  )
                </HeadCell>
                <HeadCell component="th" className="text-uppercase text-center">
                  Emissions Amount
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderRight: "0", width: "16%" }}
                ></HeadCell>
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              <StyledTableRow
                sx={{
                  "&:last-child td, &:last-child th": { borderBottom: 0 },
                }}
              >
                <StyledTableCell
                  component="th"
                  scope="row"
                  className="border_left_0 border_bottom_0"
                >
                  <Text />
                </StyledTableCell>
                <StyledTableCell className="align-items-center justify-content-center border_bottom_0 ">
                  <Text />
                </StyledTableCell>
                <StyledTableCell className="border_bottom_0">
                  <Text />
                </StyledTableCell>
                <StyledTableCell className="border_bottom_0 border_right_0">
                  <Text />
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      ) : logs && logs.length > 0 ? (
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640, borderRadius: "8px" }}
          className="mr-50"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderLeft: "0", width: "40%" }}
                >
                  Accounting Item
                </HeadCell>
                <HeadCell component="th" className="text-uppercase text-center">
                  Data Completion (
                  {lastYearMonth +
                    " " +
                    String(new Date().getFullYear() - 1).slice(-2) +
                    " - " +
                    lastMonth +
                    " " +
                    String(new Date().getFullYear()).slice(-2)}
                  )
                </HeadCell>
                <HeadCell component="th" className="text-uppercase text-center">
                  Emissions Amount
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderRight: "0", width: "16%" }}
                ></HeadCell>
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {logs.map((log, i) => (
                <InActiveTableRow
                  getAllTables={getAllTables}
                  log={log}
                  key={i}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <EmptyActiveOffsets isActiveTab={false} />
      )}
    </div>
  );
};

export default InActiveTable;
