function nexDate(curDate) {
  let nDate = {};
  if (curDate.month === 12) {
    (nDate.month = 1), (nDate.year = curDate.year + 1);
  } else {
    (nDate.month = curDate.month + 1), (nDate.year = curDate.year);
  }
  return nDate;
}

export default nexDate;
