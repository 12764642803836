import { ArrowBackIosTwoTone } from "@mui/icons-material";
import React from "react";
import { useNavigate } from "react-router-dom";
import getUrlqueries from "../../../../Utils/getUrlQueries";
import { useState } from "react";
import { useEffect } from "react";
import axios from "../../../../Api";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import parse from "html-react-parser";

const PRIVACY = ["PUBLIC", "PRIVATE"];
const CATEGORY = [
  "",
  "Sustainability Essentials",
  "Policies & Regulations",
  "Case Studies",
  "Trends & Updates",
  "Op-ed",
  "More",
];
const KnowledgeProfile = () => {
  const navigate = useNavigate();
  const kid = getUrlqueries("kid");
  const [data, setdata] = useState();

  const getdata = async () => {
    try {
      if (!kid) return;
      const response = await axios.get(`/admin/academy/knowledge/${kid}`);
      if (response.data.success) {
        setdata(response.data.data);
      }
    } catch (error) {
      if (error && error.response.status === 401) {
        navigate("/admin/login");
      }
      return toastResponseError(error);
    }
  };

  useEffect(() => {
    getdata(kid);
  }, [kid]);
  if (data)
    return (
      <>
        <div
          className="pointer"
          onClick={() => navigate(`/admin/dashboard?tab=5&activetab=4`)}
        >
          <ArrowBackIosTwoTone /> Back
        </div>
        <br />

        <div>
          <h3 className="bold"> {parse(data?.title)}</h3>
          <br />
          <p>{data?.descriptionHeading}</p>
          <p> {parse(data?.description)}</p>
          <br />
          <img src={data?.coverimage} height="150px" width="auto" />
          <br />
          <div className="mt-40">
            <p>Author</p>
            {data.author && (
              <div className="d-flex align-items-center general_card p-14 width_fit mt-12">
                <img
                  src={data.author.image}
                  alt={data.author.name}
                  className="img_40"
                />
                <div className="ml-8 flex-1">
                  <p className="font_14_500">{data.author.name}</p>
                  <p className="font_13_700 mt-6">{data.author.designation}</p>
                </div>
              </div>
            )}
          </div>
          <br />

          <p>Category : {CATEGORY[data?.category]}</p>
          <br />

          <p>Privacy : {PRIVACY[data?.privacy]}</p>
          <br />
          {data.keywords.length > 0 && (
            <div>
              <p>Keywords </p>
              <div className="d-flex align-items-center flex-wrap gap-4 mt-20">
                {data.keywords.map((tag) => (
                  <div
                    key={tag._id}
                    style={{
                      backgroundColor: tag.bg_color,
                      padding: "4px 8px",
                      borderRadius: 5,
                    }}
                  >
                    <p className="heading_5" style={{ color: tag.text_color }}>
                      {tag.tag_title}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {/* {data.keywords.map((v, i) => {
            return (
              <div key={i}>
                <li>{v}</li>
              </div>
            );
          })} */}
          <br />

          {data.content.map((p, i) => {
            return (
              <>
                <h4
                  className="mt-16"
                  dangerouslySetInnerHTML={{ __html: String(p.heading) }}
                ></h4>
                <div
                  key={i}
                  id="blog-content"
                  className={`blogpost_text_content`}
                  dangerouslySetInnerHTML={{ __html: String(p.content) }}
                ></div>
              </>
            );
          })}
        </div>
      </>
    );
};

export default KnowledgeProfile;
