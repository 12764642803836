import React, { useState, useEffect } from "react";
import "./ProductDetailAnalytics.css";
import ProductDetailEmissionChart from "./Components/EmissionChart";
import ScopePieChart from "./Components/ScopePieChart";
import AnalyticsTable from "./Components/AnalyticsTable";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "../../../../../../../../Api/index";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../../../../../../Utils/Auth";
import Paper from "@mui/material/Paper";
import { Table, TableBody, TableContainer, TableHead } from "@mui/material";
import DonwIntradayIcon from "../../../../../../../../Assets/icons/DownIntraday";
import UpIntradayIcon from "../../../../../../../../Assets/icons/UpIntradayIcon";
import SubScopeTables from "../../../../../../Analytics/Emissions/SubScopeTables";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../../../Utils/TableComp";
import EmissionCategory from "../../../../../../Analytics/Emissions/Charts/EmissionCategory";
import { getServiceIcon } from "../../../../../../../../Utils/itemIcon";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { FullMonthNames } from "../../../../../../../../Utils/CommonFunctions";
const ProductDetailAnalytics = (props) => {
  const [isGHGProtocol, setIsGHGProtocol] = useState(true);
  const [emissionYear, setEmissionYear] = useState(new Date().getFullYear());
  const [emissionCategoriesYear, setEmissionCategoriesYear] = useState(
    new Date().getFullYear()
  );
  const [yearList, setYearList] = useState([]);
  const [emissionData, setEmissionData] = useState({});
  const [emissionOverview, setEmissionOverview] = useState([]);
  const [scope1Emission, setScope1Emission] = useState(0);
  const [scope2Emission, setScope2Emission] = useState(0);
  const [scope3Emission, setScope3Emission] = useState(0);
  const [scope1Table, setScope1Table] = useState([]);
  const [scope2Table, setScope2Table] = useState([]);
  const [scope3Table, setScope3Table] = useState([]);
  const [scopeEmission, setScopeEmission] = useState([]);
  const [emissionCategoryBarData, setEmissionCategoryBarData] = useState([]);
  const [emissionCategoryData, setEmissionCategoryData] = useState([]);

  const { setSessionExpire } = useAuth();
  const getScopeEmissionCategory = async () => {
    await axios({
      url: `/analytics/emission-catergories?logId=${props.logId}&year=${emissionCategoriesYear}`,
      method: "get",
      // params: {
      //   year: selectedYear,
      // },
    })
      .then((res) => {
        const { allCatergories } = res.data;
        setEmissionCategoryData(allCatergories);
        const convertData = allCatergories.map((obj) => ({
          name: obj._id,
          value: obj.years[0].totalEmission,
        }));
        setEmissionCategoryBarData(convertData);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const getTotalEmissions = async () => {
    try {
      await axios
        .get(
          `/analytics/total-emission?year=${emissionYear}&logId=${props.logId}`
        )
        .then((res) => {
          const {
            totalEmission,
            comparedEmissions,
            totalLogs,
            reducedEmission,
          } = res.data;
          setEmissionData({
            totalEmission: totalEmission,
            comparedEmission: comparedEmissions,
            totalAccount: totalLogs,
            reducedEmission: reducedEmission,
          });
        });
    } catch (err) {
      toast.error(err.message);
    }
  };

  const getEmissionOverview = async () => {
    try {
      await axios
        .get(`/analytics/scopes?year=${emissionYear}&logId=${props.logId}`)
        .then((res) => {
          setEmissionOverview(res.data.stackBars);
        });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const getScopeEmission = async () => {
    try {
      await axios
        .get(
          `/analytics/scope-emission?year=${emissionCategoriesYear}&logId=${props.logId}`
        )
        .then((res) => {
          const {
            scopeEmissions,
            Comparison,
            // scope1Data,
            // scope2Data,
            // scope3Data,
            scope1DataN,
            scope2DataN,
            scope3DataN,
          } = res.data.data;

          const s1TotalEmmission =
            scope1DataN.length > 0
              ? scope1DataN.reduce(
                  (prev, cur) =>
                    prev + (cur.totalEmission ? cur.totalEmission : 0),
                  0
                )
              : 0;
          const s2TotalEmmission =
            scope2DataN.length > 0
              ? scope2DataN.reduce(
                  (prev, cur) =>
                    prev + (cur.totalEmission ? cur.totalEmission : 0),
                  0
                )
              : 0;
          const s3TotalEmmission =
            scope3DataN.length > 0
              ? scope3DataN.reduce(
                  (prev, cur) =>
                    prev + (cur.totalEmission ? cur.totalEmission : 0),
                  0
                )
              : 0;
          setScope1Emission(s1TotalEmmission);
          setScope2Emission(s2TotalEmmission);
          setScope3Emission(s3TotalEmmission);

          setScope1Table(scope1DataN);
          setScope2Table(scope2DataN);
          setScope3Table(scope3DataN);

          const bg = ["bg_soft_blue_bc", "bg_scope2", "bg_scope3"];
          const convertedData = Object.entries(scopeEmissions).map(
            ([name, value], i) => ({
              name: name,
              value: value,
              compare: Comparison[i],
              bg: bg[i],
            })
          );
          setScopeEmission(convertedData);
        });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  useEffect(() => {
    setYearList(props.yearList);
    setEmissionYear(props.yearList[props.yearList.length - 1]);
    setEmissionCategoriesYear(props.yearList[props.yearList.length - 1]);
  }, [props.yearList]);

  useEffect(() => {
    getScopeEmission();
    getScopeEmissionCategory();
  }, [props.isCrateCellUpdate, emissionCategoriesYear]);

  useEffect(() => {
    getEmissionOverview();
    getTotalEmissions();
  }, [emissionYear, props.isCrateCellUpdate]);

  const handleEmissionYearChange = (event) => {
    setEmissionYear(event.target.value);
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <p className="subheading_3 text_capital mt-20">Your Emission</p>
        <div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select
              value={emissionYear}
              onChange={handleEmissionYearChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              {yearList.map((eachYear) => (
                <MenuItem key={eachYear} value={eachYear}>
                  {eachYear}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </div>
      <div className="row mt-10 header_card_blue mx-0 mb-10">
        <div className=" productDetailsTabs_sm_container col-md-4 d-flex justify-content-between  p-16">
          <div className="mt-10">
            <p className="font_30_700">
              {Number(emissionData.totalEmission).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{" "}
              <span className="body_text_sm">tCO2e</span>
            </p>
            <p className="overline_text color_white mt-10">Total Emission</p>
          </div>
          <div>
            <p className="neutral_label fw-400">{emissionYear}</p>
          </div>
        </div>

        <div className="col-md-4 d-flex justify-content-around align_text_left p-24 ml-20">
          <div className="mt-10">
            <p className="font_30_700">
              {emissionData.reducedEmission
                ? Number(emissionData.reducedEmission).toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )
                : 0}{" "}
              <span className="body_text_sm">tCO2e</span>
            </p>
            <p className="overline_text color_white mt-10">
              Reduced Emissions (YOY)
            </p>
          </div>
          <div className="">
            <p className="neutral_label fw-400 ">
              {emissionYear} vs {emissionYear - 1}
            </p>
          </div>
        </div>

        <div className="col-md-3 align_text_left p-24 ml-20">
          <div className="mt-10">
            <p className="font_30_700">
              {FullMonthNames[parseInt(props.monthCreated) - 1]} {props.yearCreated}
            </p>
            <p className="overline_text color_white mt-10">
              Creation Date of Accounting Item
            </p>
          </div>
        </div>
      </div>

      <div className="mt-48">
        <p className="subheading_3 text_capital">Emissions over time</p>
        <div className=" mt-32 productDetailAnalytics_ghg_container">
          <ProductDetailEmissionChart
            data={emissionOverview}
            year={emissionYear}
          />
          <div className="emission-overview-footer d-flex align-items-center">
            <div className="d-flex align-items-center">
              <span className="span_cirlce bg_soft_blue_bc mr-8"></span>
              <p className="overline_text">Scope 1</p>
            </div>
            <div className="d-flex align-items-center ml-16">
              <span className="span_cirlce bg_scope2 mr-8"></span>
              <p className="overline_text">Scope 2</p>
            </div>
            <div className="d-flex align-items-center ml-16">
              <span className="span_cirlce bg_scope3 mr-8"></span>
              <p className="overline_text">Scope 3</p>
            </div>
          </div>
        </div>
      </div>

      <div className="productDetailAnalytics_ghg_container mt-50 p-16">
        <div className="d-flex justify-content-between align-items-center mt-20 mb-40 ">
          <div className="productDetailAnalytics_switch border-ef d-flex align-items-center">
            <button
              onClick={() => {
                setIsGHGProtocol(true);
              }}
              className={`button_style_none heading_4 
                  ${
                    isGHGProtocol == true
                      ? "productDetailAnalytics_time_button_active"
                      : "productDetailAnalytics_time_button"
                  }
                `}
            >
              <span>GHG Protocol</span>
            </button>
            <button
              onClick={() => {
                setIsGHGProtocol(false);
              }}
              className={`button_style_none heading_4 
                ${
                  isGHGProtocol == false
                    ? "productDetailAnalytics_time_button_active"
                    : "productDetailAnalytics_time_button"
                }
              `}
            >
              <span>Emission Categories</span>
            </button>
          </div>

          <div>
            <FormControl sx={{ minWidth: 120 }} size="small">
              <Select
                value={emissionCategoriesYear}
                onChange={(e) => setEmissionCategoriesYear(e.target.value)}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
              >
                {yearList.map((eachYear) => (
                  <MenuItem key={eachYear} value={eachYear}>
                    {eachYear}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        {isGHGProtocol ? (
          <div>
            <div className="row  mt-32">
              <div className="col-4">
                <div className="productDetailAnalytics_pieChart">
                  <ScopePieChart data={scopeEmission} />
                </div>
              </div>

              <div className="col-8">
                <div className="reduction_target_root">
                  <div className="row justify-content-around">
                    {scopeEmission.length > 0 &&
                      scopeEmission.map((scope) => (
                        <div
                          className="col-3 d-flex align-items-center"
                          key={scope.name}
                        >
                          <div className="flex-grow-1">
                            <p className="card_heading">{scope.name}</p>
                            <p className="mt-30">
                              <span className="heading_lg2 ">
                                {Number(scope.value).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </span>
                              <span className="subheading_4 ml-6">tCO₂e</span>
                            </p>
                            <div
                              className={"scope_tabLine mt-12 " + scope.bg}
                            ></div>
                            <p className="overline_text mt-32 color_black">
                              Comparison (YOY)
                            </p>
                            <div className="subtitle_box border_1_ef width_fit mt-12 d-flex align-items-center comparison_circle">
                              <span className="mr-6">
                                {scope.compare > 0 ? (
                                  <UpIntradayIcon />
                                ) : (
                                  <DonwIntradayIcon />
                                )}
                              </span>
                              <p
                                className={`subheading_light_2 ${
                                  scope.compare > 0
                                    ? "color_red"
                                    : "color_green"
                                }`}
                              >
                                {scope.compare}%
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="productDetailAnalytics_table_container mt-50">
              <p className="subheading_3">Scope 1</p>
              <div className="mt-32">
                <AnalyticsTable
                  data={scope1Table}
                  totalEmission={emissionData.totalEmission}
                  scopeEmission={scope1Emission}
                />
              </div>
            </div>

            <div className="productDetailAnalytics_table_container mt-50">
              <p className="subheading_3">Scope 2</p>
              <div className="mt-32">
                <AnalyticsTable
                  data={scope2Table}
                  totalEmission={emissionData.totalEmission}
                  scopeEmission={scope2Emission}
                />
              </div>
            </div>

            <div className="productDetailAnalytics_table_container mt-50">
              <p className="subheading_3">Scope 3</p>
              <div className="mt-32">
                <AnalyticsTable
                  data={scope3Table}
                  totalEmission={emissionData.totalEmission}
                  scopeEmission={scope3Emission}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className=" p-3 mx-3 border_radius_10 border-ef">
            <p className="subheading_3 text_capital mb-32 mt-16 ms-4">
              Emission Details
            </p>

            <div className="border_radius_10 border-ef pb-3">
              <EmissionCategory data={emissionCategoryBarData} />
            </div>
            <div className="mt-40 matrix_table_root">
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640 }}
                className="mr-50 mt-50"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell
                        sx={{ BorderLeft: "0" }}
                        component="th"
                        className="text-uppercase"
                      >
                        Emission Categories
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        emissions
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        % of total emissions
                      </HeadCell>
                      <HeadCell
                        sx={{ BorderRight: "0" }}
                        component="th"
                        className="text-uppercase"
                      >
                        Comparison (YOY)
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    {emissionCategoryData &&
                      emissionCategoryData.length > 0 &&
                      emissionCategoryData.map((data, i) => (
                        <StyledTableRow
                          sx={{
                            "&:last-child td, &:last-child th": {
                              borderBottom: 0,
                            },
                          }}
                          key={data._id + i}
                        >
                          <StyledTableCell
                            sx={{ borderLeft: "0" }}
                            component="th"
                            scope="row"
                          >
                            <div className="d-flex">
                              <img
                                src={getServiceIcon(data._id)}
                                className="icon_sizing"
                              />
                              &nbsp; &nbsp;
                              <p className="ml-10">{data._id}</p>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell className="text-start">
                            {data.years[0] &&
                              Number(
                                data.years[0].totalEmission
                              ).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                            tCO₂e
                          </StyledTableCell>
                          <StyledTableCell className="text-start">
                            {emissionData.totalEmission
                              ? (
                                  data.years[0] &&
                                  Number(data.years[0].totalEmission * 100) /
                                    emissionData.totalEmission
                                ).toLocaleString(undefined, {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })
                              : "0"}{" "}
                            %
                          </StyledTableCell>
                          <StyledTableCell
                            className="text-start"
                            sx={{ borderRight: "0" }}
                          >
                            <p>
                              <span className="mr-16">
                                {Number(
                                  data.years[0] && data.years[0].comparison
                                ) < 0 ? (
                                  <DonwIntradayIcon />
                                ) : (
                                  <UpIntradayIcon />
                                )}
                              </span>
                              <span
                                className={
                                  Number(
                                    data.years[0] && data.years[0].comparison
                                  ) < 0
                                    ? "color_green"
                                    : "color_red"
                                }
                              >
                                {data.years[0] &&
                                data.years[0].comparison === null
                                  ? 100
                                  : Number(
                                      Number(
                                        data.years[0] &&
                                          data.years[0].comparison
                                      ) /
                                        (Number(
                                          data.years[0] &&
                                            data.years[0].totalEmission
                                        ) *
                                          100)
                                    ).toLocaleString(undefined, {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    })}{" "}
                                %
                              </span>
                            </p>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
            <div className="mt-40">
              {emissionCategoryData &&
                emissionCategoryData.length > 0 &&
                emissionCategoryData.map((data) => (
                  <SubScopeTables
                    totalEmission={emissionData.totalEmission}
                    key={data._id}
                    data={data}
                  />
                ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductDetailAnalytics;
