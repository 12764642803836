import React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(
  ({ curr_color, theme }) => ({
    height: 5,
    borderRadius: 2,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: "#EFEFEF",
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 2,
      backgroundColor: curr_color ? curr_color : theme.palette.common.white,
    },
  })
);
function LogTableProgressBar(props) {
  return (
    <BorderLinearProgress
      variant="determinate"
      value={props.val}
      curr_color={
        props.val <= 20
          ? "#e35700"
          : props.val > 20 && props.val < 80
          ? "#ED6C3A"
          : "#00915B"
      }
    />
  );
}

export default LogTableProgressBar;
