import React, { useEffect, useState } from "react";
import "./surveys.css";
import ButtonComp from "../../../../Assets/components/Button";
import { TextField, InputAdornment } from "@mui/material";
import { useAuth } from "../../../../Utils/Auth";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { HeadCell, StyledHeadRow } from "../../../../Utils/TableComp";
import CreateSurvey from "./Components/CreateSurvey";
import Axios from "../../../../Api";
import TakeSurvey from "./Components/CreateSurvey/Components/TakeSurvey";
import SurveyStats from "./Components/CreateSurvey/Components/SurveyStats";
import toast from "react-hot-toast";
import { toastResponseError } from "../../../../Utils/toastResponseError";
import Row from "./Components/Row";
import SeachBigCircleIcon from "../../../../Assets/icons/SeachBigCircleIcon";
import { botAvatarGroup } from "../../../../Utils/botAvatarsGroup";

function Surveys() {
  const { user, setSessionExpire } = useAuth();
  const [openCreateSurvey, setOpenCreateSurvey] = useState(false);
  const [userSurveys, setUserSurveys] = useState([]);
  const [filterSurvey, setFilterSurvey] = useState("");
  // const [anchorElRowMenu, setAnchorElRowMenu] = useState([]);
  // const [openRowMenu, setOpenRowMenu] = useState([]);
  const [isTakeSurveyOpen, setIsTakeSurveyOpen] = useState(false);
  const [isSurveyStatsOpen, setIsSurveyStatsOpen] = useState(false);
  const [takeSurveyId, setTakeSurveyId] = useState("");
  const [takeSurveyUserId, setTakeSurveyUserId] = useState("");
  const [editId, setEditId] = useState(null);

  const handleCheckSurveyStats = (surveyIndex) => {
    setTakeSurveyId(userSurveys[surveyIndex]._id);
    setIsSurveyStatsOpen(true);
  };
  const handleTakeSurvey = (surveyIndex) => {
    userSurveys[surveyIndex].participants.forEach((currUser) => {
      if (currUser.userId === user.id) {
        setTakeSurveyId(userSurveys[surveyIndex]._id);
        setTakeSurveyUserId(currUser._id);
        setIsTakeSurveyOpen(true);
      }
    });
  };

  const handleSurveyStatsOpen = () => {
    setIsSurveyStatsOpen(!isSurveyStatsOpen);
  };
  const handleTakeSurveyOpen = () => {
    setIsTakeSurveyOpen(!isTakeSurveyOpen);
  };

  const handleOpenCreateSurvey = () => {
    if (openCreateSurvey) {
      setEditId(null);
    }
    setOpenCreateSurvey(!openCreateSurvey);
  };
  useEffect(() => {
    getUserSurveys();
    //  console.log("this are user ", user)
  }, []);
  const getUserSurveys = async () => {
    try {
      await Axios.get("/surveys/get-user-surveys").then((res) => {
        if (res.data.success) {
          setUserSurveys(res.data.data);
        }
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const deleteSurvey = async (id) => {
    try {
      await Axios.get(`/surveys/delete-survey/${id}`).then((res) => {
        if (res.data.success) {
          getUserSurveys();
          toast.success("survey has been deleted");
        }
      });
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };

  const callChildSetTemplateFunc = (id) => {
    setEditId(id);
    handleOpenCreateSurvey();
  };
  return (
    <div>
      <CreateSurvey
        openCreateSurvey={openCreateSurvey}
        handleOpenCreateSurvey={handleOpenCreateSurvey}
        getUserSurveys={getUserSurveys}
        editId={editId}
        setEditId={setEditId}
      />
      <TakeSurvey
        userId={takeSurveyUserId}
        takeSurveyId={takeSurveyId}
        handleTakeSurveyOpen={handleTakeSurveyOpen}
        isTakeSurveyOpen={isTakeSurveyOpen}
        getUserSurveys={getUserSurveys}
      />
      <SurveyStats
        isSurveyStatsOpen={isSurveyStatsOpen}
        handleSurveyStatsOpen={handleSurveyStatsOpen}
        surveyId={takeSurveyId}
        deleteSurvey={deleteSurvey}
      />
      {userSurveys.length > 0 ? (
        <>
          <div className="d-flex align-items-center mt-30">
            <TextField
              value={filterSurvey}
              onChange={(e) => {
                setFilterSurvey(e.target.value);
              }}
              className="body_text_l"
              id="input-with-icon-textfield"
              placeholder="Search..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SeachBigCircleIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  height: 40,
                  width: 277,
                  borderColor: "#efefef",
                  borderStyle: "solid",
                  borderWidth: 1,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "1px solid #efefef",
                },
                "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline ":
                  {
                    borderColor: "#efefef",
                    borderStyle: "solid",
                    borderWidth: 1,
                  },
                "& .MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline ":
                  {
                    borderColor: "#efefef",
                    borderStyle: "solid",
                    borderWidth: 1,
                  },
                input: {
                  // paddingTop: "20px",
                  fontSize: "13px",
                  fontWeight: "500",
                  color: "#7E8999",
                  fontFamily: "Inter",
                  fontStyle: "normal",
                  lineHeight: "11px" /* 84.615% */,
                },
              }}
            />
            <div className="ml-20">
              <ButtonComp
                status={5}
                onClick={() => {
                  handleOpenCreateSurvey();
                }}
                className="h-40px"
              >
                <i className="bi bi-plus"></i>{" "}
                <p className="font_14_600">New Survey</p>
              </ButtonComp>
            </div>
          </div>

          <div className="mt-30 analytics_table_container pb-40">
            <TableContainer
              component={Paper}
              sx={{ minWidth: 640, borderRadius: "8px" }}
            >
              <Table sx={{ minWidth: 640 }} aria-label="simple table">
                <TableHead>
                  <StyledHeadRow
                    sx={{ backgroundColor: "#fbfbfb !important", height: 62 }}
                  >
                    <HeadCell
                      component="th"
                      sx={{ borderLeft: "0", borderRight: "0", width: "123px"}}
                    >
                      <p className="text_capital_f font_13_700 line_height_10">
                        Status
                      </p>
                    </HeadCell>
                    <HeadCell
                      sx={{ borderLeft: "0", borderRight: "0" }}
                      component="th"
                    >
                      <p className="text_capital_f font_13_700 line_height_10">
                        Name
                      </p>
                    </HeadCell>
                    <HeadCell
                      sx={{ borderLeft: "0", borderRight: "0" }}
                      component="th"
                    >
                      <p className="text_capital_f font_13_700 line_height_10">
                        Creator
                      </p>
                    </HeadCell>
                    <HeadCell
                      sx={{ borderLeft: "0", borderRight: "0" }}
                      component="th"
                    >
                      <p className="text_capital_f font_13_700 line_height_10">
                        Created on
                      </p>
                    </HeadCell>
                    <HeadCell
                      sx={{ borderLeft: "0", borderRight: "0" }}
                      component="th"
                    >
                      <p className="text_capital_f font_13_700 line_height_10">
                        Response
                      </p>
                    </HeadCell>
                    <HeadCell
                      component="th"
                      sx={{ borderLeft: "0", borderRight: "0" }}
                    >
                      <p className="text_capital_f font_13_700 line_height_10">
                        Participants
                      </p>
                    </HeadCell>
                  </StyledHeadRow>
                </TableHead>
                <TableBody>
                  {userSurveys
                    .filter((currSurvey) => {
                      if (
                        filterSurvey.trim() === null ||
                        filterSurvey.trim() === "" ||
                        filterSurvey.trim() === undefined
                      ) {
                        return currSurvey;
                      }
                      return String(currSurvey.surveyName)
                        .toLowerCase()
                        .includes(filterSurvey.toLowerCase());
                    })
                    .map((currSurvey, i) => {
                      return (
                        <Row
                          key={currSurvey._id}
                          currSurvey={currSurvey}
                          callChildSetTemplateFunc={callChildSetTemplateFunc}
                          userSurveys={userSurveys}
                          index={i}
                          deleteSurvey={deleteSurvey}
                          handleTakeSurvey={handleTakeSurvey}
                          handleCheckSurveyStats={handleCheckSurveyStats}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      ) : (
        <div
          className="no-data-root d-flex justify-content-between p-32 mt-20 p_relative"
          style={{ maxWidth: "1200px" }}
        >
          <div className="d-flex justify-content-between flex-column">
            <div>
              <p className="heading_lg">Surveys</p>
              <p className="subheading_light mt-16 w-65">
                Net Zero is a team effort. With this in mind, we’ve created
                surveys for you, to get regular feedback on reduction measures,
                carbon contributions and carbon data. Create your first survey
                today.
              </p>
            </div>

            <div className=" mt-12">
              <ButtonComp
                status={5}
                onClick={() => {
                  handleOpenCreateSurvey();
                }}
              >
                Create Survey
              </ButtonComp>
            </div>
          </div>

          <div style={{ zIndex: 1 }}>
            <img
              src={
                botAvatarGroup[user?.bot_avatar_id]?.surveyConnectPlaceholder
                  ?.url
              }
              alt="launching"
              style={{ maxHeight: 250, width: "auto" }}
            />
          </div>
          <hr className="bg_divider" />
        </div>
      )}
    </div>
  );
}

export default Surveys;
