function isNotAllowedCancel(targetDateStr) {
  const currentDate = new Date();
  let date = new Date(targetDateStr);
  let targetDate = new Date(
    date.getFullYear() + 1,
    date.getMonth(),
    date.getDate()
  );
  const dateDifferenceInMilliseconds =
    targetDate.getTime() - currentDate.getTime();
  const dateDifferenceInDays =
    dateDifferenceInMilliseconds / (1000 * 3600 * 24);
  return parseInt(dateDifferenceInDays) <= 30;
}

export default isNotAllowedCancel;
