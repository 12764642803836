import React, { useState } from "react";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import { VisibleRoles, roles } from "../../../Utils/UserRoleConstAndFunc";
import {
  Backdrop,
  Box,
  Fade,
  IconButton,
  Modal,
  Popover,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { useAuth } from "../../../Utils/Auth";
import { toast } from "react-hot-toast";
import axios from "../../../Api";
import { modalStyle } from "../../../Utils/CommonFunctions";
import ButtonComp from "../../../Assets/components/Button";
import generatePassword from "../../../Utils/GeneratePassword";
const regex = new RegExp(
  "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
);

const UserRow = ({
  name,
  email,
  role,
  isJoined,
  date,
  handleDelete,
  userid,
  getAllUsers,
}) => {
  const { setSessionExpire } = useAuth();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // delete modal variables
  const [dltOpen, setdltOpen] = useState(false);
  const handledltOpen = () => setdltOpen(true);
  const handledltClose = () => setdltOpen(false);

  // edit modal variables
  const [editOpen, setEditOpen] = useState(false);
  const handleEditOpen = () => {
    setisChangingPass(false);
    setEditOpen(true);
  };
  const handleEditClose = () => setEditOpen(false);
  const [editedRoles, setEditedRoles] = useState(role);

  const handleEditRole = async () => {
    await axios({
      url: `/admin/edit-role/${userid}`,
      method: "put",
      data: {
        role: editedRoles,
      },
    })
      .then((res) => {
        if (res.data.success) {
          handleEditClose();
          getAllUsers();
          handleClose();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        toastResponseError(err, setSessionExpire);
      });
  };
  const [password, setPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);
  const [isChangingPass, setisChangingPass] = useState(false);
  const [values, setValues] = React.useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  React.useEffect(() => {
    setPassword(generatePassword());
  }, [values.showPassword]);
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    const txt = e.target.value;
    if (
      // txt.length >= 8 &&
      // isNumberRegx.test(txt) &&
      regex.test(txt)
    ) {
      setValidPassword(true);
    } else {
      setValidPassword(false);
    }
  };

  const handleUpdatePassword = async () => {
    if (!confirm("Are you sure you want to update password?")) {
      return;
    }
    await axios({
      url: `/admin/updatepass/${userid}`,
      method: "put",
      data: {
        password: password,
      },
    })
      .then((res) => {
        if (res.data.success) {
          handleClose();
          handleEditClose();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  return (
    <>
      <StyledTableRow
        sx={{
          "&:last-child td, &:last-child th": { borderBottom: 0 },
        }}
      >
        <StyledTableCell
          className="text-start border_left_0"
          component="th"
          scope="row"
        >
          {name}
        </StyledTableCell>
        <StyledTableCell className="text-start">{email}</StyledTableCell>
        <StyledTableCell className="text-start">
          {VisibleRoles[role]}
        </StyledTableCell>
        <StyledTableCell className="text-start" sx={{ borderRight: "none" }}>
          <div className="d-flex align-items-center justify-content-between">
            {isJoined ? (
              <p className="">{date}</p>
            ) : (
              <div className="subtitle_box bg_soft_blue">
                <p className="color_blue font_13_600">Invitition Sent</p>
              </div>
            )}
            <IconButton onClick={handleClick}>
              <MoreVert />
            </IconButton>
          </div>
        </StyledTableCell>
      </StyledTableRow>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="basic-popover-root">
          <div className="basic-popover">
            <p
              className="font_14_500 color_red"
              onClick={() => {
                handledltOpen();
                handleClose();
              }}
            >
              delete
            </p>
          </div>
          {isJoined && (
            <div
              className="basic-popover"
              onClick={() => {
                handleEditOpen();
                handleClose();
              }}
            >
              <p className="font_14_500">Edit</p>
            </div>
          )}
        </div>
      </Popover>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={dltOpen}
        onClose={handledltClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={dltOpen}>
          <Box sx={modalStyle}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">Delete User</p>
              </div>
              <TableContainer
                sx={{ minWidth: 640 }}
                className="mt-24 pb-24 border-btm_dc"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell component="th" className="text-uppercase">
                        USER NAME
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        E-MAIL ADDRESS
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ROLE
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ACTIVE SINCE
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        className="text-start"
                        component="th"
                        scope="row"
                      >
                        {name}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {email}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {VisibleRoles[role]}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        <div className="d-flex align-items-center justify-content-between">
                          {isJoined ? (
                            <p className="">{date}</p>
                          ) : (
                            <div className="subtitle_box bg_soft_blue">
                              <p className="color_blue font_13_600">
                                Invitition Sent
                              </p>
                            </div>
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="pb-24 border-btm_dc mt-24">
                <p className="overline_text">
                  After deleting the user, they won’t be able to log back into
                  the account.
                </p>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-24">
                <ButtonComp
                  status={3}
                  className="mr-12 text-black"
                  onClick={() => {
                    handledltClose();
                    handleClose();
                  }}
                >
                  Go Back
                </ButtonComp>
                <ButtonComp
                  status={3}
                  className=" color_red"
                  onClick={async () => {
                    await handleDelete();
                    handledltClose();
                  }}
                >
                  Delete user
                </ButtonComp>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={editOpen}
        onClose={handleEditClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={editOpen}>
          <Box sx={modalStyle}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">Edit Role</p>
              </div>
              <TableContainer
                sx={{ minWidth: 640 }}
                className="mt-24 pb-24 border-btm_dc"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableHead>
                    <StyledHeadRow>
                      <HeadCell component="th" className="text-uppercase">
                        USER NAME
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        E-MAIL ADDRESS
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ROLE
                      </HeadCell>
                      <HeadCell component="th" className="text-uppercase">
                        ACTIVE SINCE
                      </HeadCell>
                    </StyledHeadRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell
                        className="text-start"
                        component="th"
                        scope="row"
                      >
                        {name}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {email}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        {VisibleRoles[role]}
                      </StyledTableCell>
                      <StyledTableCell className="text-start">
                        <div className="d-flex align-items-center justify-content-between">
                          {isJoined ? (
                            <p className="">{date}</p>
                          ) : (
                            <div className="subtitle_box bg_soft_blue">
                              <p className="color_blue font_13_600">
                                Invitition Sent
                              </p>
                            </div>
                          )}
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="row justify-content-between pb-24 border-btm_dc mt-24">
                <div className="col-lg-7 col-md-8 col-12">
                  <div className="input_div input_seperation">
                    <p className="font_14_500 align-self-center">User Role</p>
                    <select
                      className="subheading_2"
                      value={editedRoles}
                      onChange={(e) => setEditedRoles(e.target.value)}
                    >
                      <option className="subheading_2" value={roles.viewer}>
                        Team Member (Viewer)
                      </option>
                      <option className="subheading_2" value={roles.editor}>
                        Editor
                      </option>
                      <option className="subheading_2" value={roles.admin}>
                        Admin
                      </option>
                      <option className="subheading_2" value={roles.consultant}>
                        Consultant
                      </option>
                    </select>
                  </div>
                </div>
                <div
                  hidden={!isChangingPass}
                  className="col-lg-4 col-md-6 col-12"
                >
                  <label className="auth_label">
                    <input
                      className="auth_input auth_input_password"
                      placeholder="Enter your password"
                      required
                      type={!values.showPassword ? "text" : "text"}
                      value={password}
                      onChange={(e) => handlePasswordChange(e)}
                    />
                    <button
                      className="auth_input_pas_btn"
                      type="button"
                      onClick={() => handleClickShowPassword()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="white"
                        className="bi bi-arrow-clockwise"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fillRule="evenodd"
                          d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
                        />
                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z" />
                      </svg>
                    </button>
                  </label>
                  <div style={{ textAlign: "left" }}>
                    <p
                      className="p_small_white no-margin"
                      style={{ display: validPassword ? "none" : "block" }}
                    >
                      Password should include atleast an uppercase letter, a
                      lowercase letter,a number and a special character like
                      @,!,etc.
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center mt-24">
                <ButtonComp
                  status={3}
                  className="mr-12 text-black"
                  onClick={() => {
                    handleEditClose();
                    handleClose();
                  }}
                >
                  Go Back
                </ButtonComp>
                <ButtonComp
                  status={3}
                  className="color_red"
                  onClick={() => {
                    handleEditRole();
                  }}
                >
                  Change Role
                </ButtonComp>
                <ButtonComp
                  status={3}
                  className="color_red"
                  onClick={() => {
                    if (isChangingPass) {
                      handleUpdatePassword();
                    } else {
                      setisChangingPass(true);
                    }
                  }}
                >
                  {isChangingPass ? "Update Password" : "Change Password"}
                </ButtonComp>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default UserRow;
