import React from "react";
import "../../logDashboard.css";
import Drawer from "@mui/material/Drawer";
import { useAuth } from "../../../../../Utils/Auth";
import { botAvatarGroup } from "../../../../../Utils/botAvatarsGroup";
import ButtonComp from "../../../../../Assets/components/Button";
function SuccessfullyActivated(props) {
  const { user } = useAuth();
  return (
    <div>
      <Drawer
        anchor={"right"}
        open={props.toggleZeroCredit}
        onClose={() => props.setToggleZeroCredit(false)}
        PaperProps={{
          sx: { width: "70%" },
        }}
        BackdropProps={{ style: { backgroundColor: "white", opacity: 0 } }}
      >
        <div className="fluid-container drawer_root">
          <div className="credit_heading_container pb-60 ">
            <div className="align_text_right mt-20 pr-20">
              <i
                className="bi bi-x-lg SideDrawer_close_icon pointer"
                onClick={() => props.setToggleZeroCredit(false)}
              ></i>
            </div>
            <div className="pl-35 mt-10">
              <p className="f-27 fw-700 font-inter">
                Activate your {props.itemName}
              </p>
              <p className="subheading_light_2 mt-10">
                Set up a new accounting {props.itemName}
              </p>
            </div>
          </div>

          <div className="row credit_card_container p-40 ml-40 mr-40 mt-30">
            <div className="col-lg-7 d-flex flex-column justify-content-between">
              <div>
                <div>
                  <p className="f-40 fw-700 font-inter">
                    Successfully Activated
                  </p>
                  <p className="body_text mt-8">
                    We have sent you the invoice via mail. You can also
                    downloaded it in the billing section in your settings panel.
                  </p>
                </div>
              </div>
              <div className="credit_items_center_md">
                <div className="mb-2"></div>
                <ButtonComp
                  status={1}
                  // onClick={()=>{handleUseCredit()}}
                  // className="md"
                >
                  Continue to Accounting Set-up{" "}
                  <i className="bi bi-arrow-right"></i>
                </ButtonComp>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-4 p-16 mt-10">
              <div className="d-flex align-items-center">
                <img
                  className="credit_img"
                  src={
                    botAvatarGroup[user?.bot_avatar_id]?.activatedSuccessful
                      ?.url
                  }
                  style={{ maxHeight: 305, width: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default SuccessfullyActivated;
