import React, { useEffect, useState } from "react";
import { BarChart, Bar, ResponsiveContainer, XAxis, Tooltip } from "recharts";

// const data = [
//     {
//       name: 'Page A',
//       uv: 4000,
//       pv: 2400,
//       amt: 2400,
//     },
//     {
//       name: 'Page B',
//       uv: 3000,
//       pv: 1398,
//       amt: 2210,
//     },
//     {
//       name: 'Page C',
//       uv: 2000,
//       pv: 9800,
//       amt: 2290,
//     },
//     {
//       name: 'Page D',
//       uv: 2780,
//       pv: 3908,
//       amt: 2000,
//     },
//     {
//       name: 'Page E',
//       uv: 1890,
//       pv: 4800,
//       amt: 2181,
//     },
//     {
//       name: 'Page F',
//       uv: 2390,
//       pv: 3800,
//       amt: 2500,
//     },
//     {
//       name: 'Page G',
//       uv: 3490,
//       pv: 4300,
//       amt: 2100,
//     },
//   ];

const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="d-flex align-items-center custom_tooltip_">
        <div className="d-flex align-items-center">
          <span
            className="span_cirlce mr-8"
            style={{ backgroundColor: payload[0].color }}
          ></span>
          <p className="tooltip_chart_text">
            count: {Number(payload[0].value)}
          </p>
        </div>
      </div>
    );
  }
  return null;
};

function SurveyBarChart(props) {
  const [data, setData] = useState([]);

  useEffect(() => {
    let tempData = [];
    if (props.data) {
      props.data.forEach((currScale, index) => {
        if (index > 0) {
          tempData.push({
            name: index,
            count: currScale,
          });
        }
      });
      setData(tempData);
    }
  }, [props.data]);
  return (
    <ResponsiveContainer width={"100%"} height={200}>
      <BarChart data={data}>
        <XAxis
          dataKey="name"
          tickLine={false}
          style={{
            color: "#4C4C4C",
            fontFamily: "Inter",
            fontSize: 10,
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "14px",
            // whiteSpace: "nowrap",
          }}
        />
        <Bar
          dataKey="count"
          fill="#49A585"
          radius={[5, 5, 0, 0]}
          barSize={26}
        />
        <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default SurveyBarChart;
