import React, { useState, useEffect } from "react";
import axios from "../../../Api";
import { useAuth } from "../../../Utils/Auth";
import BackForwardButton from "../../../Assets/components/BackForwardButton/BackForwardButton";
import PressEnter from "../../../Assets/components/PressEnter/PressEnter";
import ButtonComp from "../../../Assets/components/Button";

function Address(props) {
  // const handleChange = (e) => {
  //   console.log("target value is ", e.target.name);
  //   props.setRegDetails({
  //     ...props.regDetails,
  //     [e.target.name]: e.target.value,
  //   });
  //   console.log("main object is ",props.regDetails)
  // };
  const { profile } = useAuth();
  const [countries, setcountries] = useState([]);
  const [countrycode, setcountrycode] = useState(
    profile && profile.countryCode
  );
  const [states, setstates] = useState([]);
  const [statecode, setstatecode] = useState(profile && profile.stateCode);
  const [cities, setcities] = useState([]);
  const incrementQuestion = () => {
    props.incQuestionNo(
      props.regDetails["street"],
      props.regDetails["streetNumber"],
      props.regDetails["city"],
      props.regDetails["state"],
      props.regDetails["zip"],
      props.regDetails["country"]
    );
  };
  const decrementQuestion = () => {
    props.decQuestionNo(
      props.regDetails["street"],
      props.regDetails["streetNumber"],
      props.regDetails["city"],
      props.regDetails["zip"],
      props.regDetails["country"]
    );
  };
  const fetchcountries = async () => {
    const response = await axios.get(`/location/countries`);
    setcountries(response.data);
  };

  const fetchstates = async () => {
    if (countrycode) {
      const response = await axios.get(`/location/country/${countrycode}`);
      setstates(response.data);
    }
  };

  const fetchcities = async () => {
    if (countrycode && statecode) {
      const response = await axios.get(
        `/location/cities/${countrycode}/${statecode}`
      );
      setcities(response.data);
    }
  };
  useEffect(() => {
    fetchcountries();
  }, []);

  useEffect(() => {
    fetchstates();
  }, [countrycode]);

  useEffect(() => {
    fetchcities();
  }, [statecode]);

  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      props.handleSubmit();
      incrementQuestion();
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);

  return (
    <div className="register_text_start col-md-10 max_width_552 register_question_container">
      <div className="purple_label register_question_no">
        {props.currQuestion + 1}/6
      </div>
      <form>
        <div className="form-group register_text_start">
          <label className=" register_sm_label register_question_label">
            {props.question.question}
          </label>
          <input
            type="text"
            className="mb-16 py-3 border-bottom  shadow-none register_input"
            value={props.regDetails["street"]}
            name="street"
            onChange={(e) => {
              props.handleChange(e, "text");
            }}
            placeholder="Street"
          />
          <input
            type="text"
            className="mb-16 py-3   border-bottom  shadow-none register_input"
            value={props.regDetails["streetNumber"]}
            name="streetNumber"
            onChange={(e) => {
              props.handleChange(e, "text");
            }}
            placeholder="Street Number"
          />

          {/* <input
          type="text"
          className="mb-16 pb-16 form-control  border-bottom  shadow-none register_input"
          value={props.regDetails["country"]}
          name="country"
          onChange={(e) => {
            props.handleChange(e, "text");
          }}
          placeholder="Country"
        /> */}
          <select
            type="text"
            className="mb-16 py-3 border-bottom  shadow-none register_input"
            // value={props.regDetails.country}
            value={`${countrycode}` + "#" + `${props.regDetails.country}`}
            name="country"
            onChange={(e) => {
              props.setRegDetails((prev) => ({
                ...prev,
                country: e.target.value.split("#")[1],
                countryCode: e.target.value.split("#")[0],
              }));
              setcountrycode(e.target.value.split("#")[0]);
            }}
          >
            <option value="">Choose Country</option>
            {countries &&
              countries.map((option, i) => (
                <option
                  className=""
                  key={i}
                  value={`${option.iso2}` + "#" + `${option.name}`}
                >
                  {option.name}
                </option>
              ))}
          </select>

          <select
            type="text"
            className={`mb-16 py-3 border-bottom  shadow-none register_input ${
              states.length === 0 && "selectDisabled"
            }`}
            value={`${statecode}` + "#" + `${props.regDetails.state}`}
            name="state"
            onChange={(e) => {
              props.setRegDetails((prev) => ({
                ...prev,
                state: e.target.value.split("#")[1],
                stateCode: e.target.value.split("#")[0],
              }));
              setstatecode(e.target.value.split("#")[0]);
            }}
            disabled={states.length > 0 ? false : true}
          >
            <option value="">Choose State</option>
            {states &&
              states.map((option, i) => (
                <option
                  className=""
                  key={i}
                  value={`${option.iso2}` + "#" + `${option.name}`}
                >
                  {option.name}
                </option>
              ))}
          </select>

          <select
            type="text"
            className={`mb-16 py-3 border-bottom  shadow-none register_input ${
              cities.length === 0 && "selectDisabled"
            }`}
            value={props.regDetails["city"]}
            name="city"
            onChange={(e) => {
              props.setRegDetails((prev) => ({
                ...prev,
                city: e.target.value,
              }));
            }}
            disabled={cities.length > 0 ? false : true}
          >
            <option value="">Choose City</option>
            {cities &&
              cities.map((option, i) => (
                <option className="" key={i} value={option.name}>
                  {option.name}
                </option>
              ))}
          </select>

          {/* <input
          type="text"
          className="mb-16 pb-16 form-control  border-bottom  shadow-none register_input"
          value={props.regDetails["state"]}
          name="state"
          onChange={(e) => {
            props.handleChange(e, "text");
          }}
          placeholder="State"
        /> */}
          {/* <input
          type="text"
          className="mb-16 pb-16 form-control  border-bottom  shadow-none register_input"
          value={props.regDetails["city"]}
          name="city"
          onChange={(e) => {
            props.handleChange(e, "text");
          }}
          placeholder="City"
        /> */}

          <input
            type="text"
            className="mb-20 py-3  border-bottom  shadow-none register_input"
            value={props.regDetails["zip"]}
            name="zip"
            onChange={(e) => {
              props.handleChange(e, "text");
            }}
            placeholder="Zip"
          />
        </div>
        <div className="mt-30 d-flex align-items-start justify-content-between">
          <BackForwardButton
            handleGoBack={() => decrementQuestion()}
            hanldeGoForward={() => {
              props.handleSubmit();
              incrementQuestion();
            }}
          />
          <div>
            <ButtonComp
              status={2}
              size="large"
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                props.handleSubmit();
                incrementQuestion();
              }}
            >
              Save
            </ButtonComp>
            <PressEnter />
          </div>
        </div>
      </form>

      {/* <div className="mt-30 register_button d-flex justify-content-end">
        <button
          className=" secondary_button_active secondary_button_sx_active"
          onClick={() => {
            props.handleSubmit();
            incrementQuestion();
          }}
        >
          Save
        </button>
      </div>
      <div className="d-flex justify-content-between mt-10">
        <div>
          <i
            className={`pointer bi bi-chevron-left ${
              props.currQuestion > 0
                ? "register_button_enable"
                : "register_button_disable"
            }`}
            onClick={() => decrementQuestion()}
          ></i>
          <i
            className={`pointer bi bi-chevron-right ${
              props.currQuestion < 5
                ? "register_button_enable"
                : "register_button_disable"
            }`}
            onClick={() => {
              props.handleSubmit();
              incrementQuestion();
            }}
          ></i>
        </div>

        <div className="d-flex">
          <p className="register_enter"> Press Enter</p>
          <i className="bi bi-arrow-return-left"></i>
        </div>
      </div> */}
    </div>
  );
}

export default Address;
