import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useQuery from "../../../../../../../Utils/query";
import axios from "../../../../../../../Api/index";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import parse from "html-react-parser";
import BookYellow from "../../../../../../../Assets/icons/BookYellow.png";
import {
  extractTitleFromHTML,
  knowledgeCategories,
  knowledgeCategories_de,
} from "../../../../../../../Utils/CommonFunctions";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../../../../Utils/Auth";

function ArticlePage() {
  const {t} = useTranslation()
  const {user} = useAuth()
  const query = useQuery();
  let knowledgeId = query.get("kid");
  const mostRead = query.get("mostRead");
  const [articleData, setArticleData] = useState({});
  // const [activeSection, setActiveSection] = useState(0);
  // const monthNames = [
  //   "January",
  //   "February",
  //   "March",
  //   "April",
  //   "May",
  //   "June",
  //   "July",
  //   "August",
  //   "November",
  //   "October",
  //   "November",
  //   "December",
  // ];
  const getArticleDetails = async () => {
    try {
      if (mostRead) {
        await axios.get(`/academy/mostread`).then((response) => {
          if (response.data.success) {
            setArticleData(response.data.data);
            //  let x = new Date(response.data.data.updatedAt).getMonth()
            //  console.log(x)
          }
        });
      } else {
        await axios
          .get(`/academy/oneknowledge/${knowledgeId}`)
          .then((response) => {
            if (response.data.success) {
              //  console.log("this is data ", response.data.data, articleData)
              setArticleData(response.data.data);
              // let x = new Date(response.data.data.updatedAt).getMonth()
              // console.log(x)
            }
          });
      }
    } catch (error) {
      toastResponseError(error);
    }
  };

  function scrollToContent(id) {
    const element = document.getElementById(`content-${id}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }

  useEffect(() => {
    getArticleDetails();
  }, [knowledgeId]);

  return (
    <div className="row ml-116">
      <div className="align_text_left mt-40">
        <p className="breadcrumb_text_light_1 text_capital">
          <Link to={`/dashboard?tab=7`}>
            <span className="color_light_2">{t("academy.knowledgeHub")}</span>
          </Link>{" "}
          <span className="text_capital fw-700"> / Platform Tour</span>
        </p>
      </div>
      {articleData && (
        <div className="col-lg-11 col-md-11 col-12 mb-40">
          <div className="border-ef border_radius_10 row mx-0 mt-30">
            <div className="col-6 p-30">
              <div className="d-flex justify-content-between flex-column h-100">
                {articleData && (
                  <div>
                    <p className="font_16_700">
                      {user.language === 'de' ? knowledgeCategories_de[Number(articleData.category) - 1] : knowledgeCategories[Number(articleData.category) - 1]}
                    </p>
                    {articleData.title && (
                      <p className="font_36_700 mt-16">
                        {user.language === 'de'&&articleData.title_de?parse(articleData.title_de):parse(articleData.title)}
                      </p>
                    )}
                  </div>
                )}
                {articleData.keywords && articleData.keywords.length > 0 && (
                  <div className="mt-20 d-flex align-items-center flex-wrap gap-3 mt-64">
                    {articleData.keywords.map((keyword) => (
                      <div
                        key={keyword._id}
                        style={{
                          backgroundColor: keyword.bg_color,
                          padding: "3px 8px",
                          borderRadius: 5,
                        }}
                      >
                        <p
                          className="font_12_700"
                          style={{
                            color: keyword.text_color,
                          }}
                        >
                          {user.language === 'de'&&keyword.tag_title_de?keyword.tag_title_de:keyword.tag_title}
                          
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <div className="mt-36">
              <Link to={`/dashboard?tab=7&mostRead=1`}>
                <ButtonComp status={3}>
                  Read Now
                  <ArrowForward className="ml-10" htmlColor="#3574E0" />
                </ButtonComp>
              </Link>
            </div> */}
            </div>
            <div className="col-6 p-20 d-flex justify-content-end">
              <img
                src={articleData.coverimage}
                className="border_radius_10"
                style={{ height: 300 }}
              />
            </div>
          </div>
          {articleData.descriptionHeading && (
            <div className="border-ef border_radius_10 p-30 mt-40">
              {articleData.descriptionHeading && (
                <div className="black_box_small width_fit">
                  <p className="font_16_700 text_capital">
                  {user.language === 'de'&&articleData.descriptionHeading_de?articleData.descriptionHeading_de:articleData.descriptionHeading}
                  </p>
                </div>
              )}
              {articleData.description && (
                <div className="mt-16">
                  {user.language === 'de'&&articleData.description_de?parse(articleData.description_de):parse(articleData.description)}
                </div>
              )}
            </div>
          )}
          <div className="d-flex gap-4 mt-30">
            <div className="knowledge_post_aboout">
              {articleData.author && (
                <div className="knowledge_post_aboout_author">
                  <img
                    src={articleData.author.image}
                    alt={articleData.author.name}
                    className="img_40"
                  />
                  <p className="mt-10 font_12_700 color_57 ">{t("academy.author")}</p>
                  <p className="mt-6 font_15_500 mb-10">
                    {articleData.author.name}
                  </p>
                  <p className="mt-6 overline_text color_57">
                    {user.language === 'de'&&articleData.author.designation_de?
                    articleData.author.designation_de:articleData.author.designation}
                  </p>
                  <p className="mt-10 font_12_700 color_57 mt-3">{t("academy.published")}</p>
                  <p className="mt-6 font_15_500">
                    {new Date(articleData.createdAt).toLocaleDateString(
                      "en-GB",
                      {
                        dateStyle: "medium",
                      }
                    )}
                  </p>
                </div>
              )}
              <div className="m-2"></div>
              {articleData.content && articleData.content.length > 0 && (
                <div className="knowledge_post_aboout_content">
                  <div className="knowledge_post_aboout_content_footer">
                    <img src={BookYellow} alt="Contents" />
                    <p className="font_16_700 text-uppercase">{t("academy.content")}</p>
                  </div>
                  <div className="knowledge_post_aboout_content_body">
                    {articleData.content.map((article) => (
                      <p
                        className="font_13_400 color_57 pointer"
                        key={article._id}
                        onClick={() => scrollToContent(article._id)}
                      >
                        {user.language === 'de'&&article.heading_de?
                    extractTitleFromHTML(article.heading_de):extractTitleFromHTML(article.heading)}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
            {articleData.content && articleData.content.length > 0 && (
              <div className="knowledge_post_blog">
                {articleData.content &&
                  articleData.content.length > 0 &&
                  articleData.content.map((article) => (
                    <div key={article._id} id={`content-${article._id}`}>
                      <div>{user.language === 'de'&&article.heading_de?
                    parse(article.heading_de):parse(article.heading)}</div>
                      <div className="mt-10 ">
                      {user.language === 'de'&&article.content_de?
                    parse(article.content_de):parse(article.content)}
                        
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ArticlePage;
