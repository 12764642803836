import React, { useEffect, useState } from "react";
import { Box, Backdrop, Modal, Fade } from "@mui/material";
import { useAuth } from "../Auth";
import "./index.css";
import TimeUpImg from "../../Assets/images/TimeUp.png";
import KeyboardForwardIcon from "../../Assets/icons/KeyboardForwardIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { botAvatarGroup } from "../botAvatarsGroup";
import ButtonComp from "../../Assets/components/Button";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  maxWidth: "90%",
  bgcolor: "background.paper",
  outline: "none",
  boxShadow: "0px 3px 8px rgba(0, 0, 0, 0.0588235)",
  borderRadius: "8px",
};

const ExpiryModal = () => {
  const [open, setOpen] = useState(false);
  const { sessionExpire, setSessionExpire, signOutBasic, user } = useAuth();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSessionExpire(false);
    setOpen(false);
  };
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (sessionExpire) {
      handleOpen();
    }
  }, [sessionExpire]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <div className="expiry_modal_root">
            <div className="d-flex justify-content-between flex-column">
              <div>
                <p className="heading_lg">Time Out...</p>
                <p className="body_text mt-24">
                  Your Green Break is Over: We logged you out due to inactivity.
                  Login again to continue your mission 🌱
                </p>
              </div>
              <div className="expiry_modal-loginbtn mt-20 ">
                <ButtonComp
                  status={1}
                  onClick={async () => {
                    const redirectTo = `${location.pathname}${location.search}`;
                    await signOutBasic(redirectTo);
                    // signOut(redirectTo);
                    handleClose();
                    navigate(`/?redirect=${redirectTo}`);
                  }}
                >
                  Go to Login
                  <span className="ml-8">
                    <KeyboardForwardIcon cc={"#ffffff"} />
                  </span>
                </ButtonComp>
              </div>
            </div>
            <div className="expiry_modal_img ml-40" style={{ zIndex: 1 }}>
              <img
                src={
                  user
                    ? botAvatarGroup[user?.bot_avatar_id]?.sentSuccessful?.url
                    : TimeUpImg
                }
                alt="time-up"
                className="img-fluid"
                style={{ maxHeight: 250, width: "auto" }}
              />
            </div>
            <hr className="bg_divider" />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ExpiryModal;
