import React, { useEffect } from "react";
import { useAuth } from "../Auth";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import NotFoundPage from "../../Pages/notFoundPage";
import useQuery from "../query";

export default function userCheck() {
  const { user, isLoading } = useAuth();
  const history = useNavigate();
  const query = useQuery();
  const redirectPath = query.get("redirect");

  useEffect(() => {
    if (!isLoading) {
      if (user) {
        if (!user.isCompanyCreated) {
          history("/register");
        } else if (!user.isInvoiceGenerated) {
          history(`/register/subscription`);
        } else if (!user.isBasePaid) {
          history(`/register/pending`);
        } else if (!user.is2FASet) {
          if (redirectPath) {
            history(`/set-two-factor?redirect=${redirectPath}`);
          } else {
            history(`/set-two-factor`);
          }
        } else if (user.isBasePaid) {
          if (redirectPath) {
            history(redirectPath);
          } else {
            history(`/dashboard`);
          }
        }
      } else {
        <NotFoundPage />;
      }
    }
  }, [isLoading]);

  return (
    <div className="v_center">
      <CircularProgress color="success" />
    </div>
  );
}
