import React from "react";
import ButtonComp from "../../../../../../Assets/components/Button";

const EndScreen = ({ cleanAllStates, handleQuickLogDrawer }) => {
  return (
    <div className="container_br_10_p_20 mt-50">
      <p className="font_16_600 line_height_10">
        Do you want to add another emissions log?
      </p>
      <div className="mt-30 d-flex align-items-center gap_10">
        <ButtonComp
          status={6}
          onClick={() => {
            cleanAllStates();
            handleQuickLogDrawer();
          }}
        >
          Return to Dashboard
        </ButtonComp>
        <ButtonComp status={5} onClick={cleanAllStates}>
          Add Log
        </ButtonComp>
      </div>
    </div>
  );
};

export default EndScreen;
