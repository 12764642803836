import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import React from "react";
import {
  StyledTableCellWithoutBorder,
  StyledTableRow,
} from "../../../../../../../Utils/TableComp";
import { Collapse, TableBody, Table } from "@mui/material";
import ButtonComp from "../../../../../../../Assets/components/Button";
import DonwIntradayIcon from "../../../../../../../Assets/icons/DownIntraday";
import { localNumber } from "../../../../../../../Utils/CommonFunctions";
import { getServiceIcon } from "../../../../../../../Utils/itemIcon";
import SubClassRow from "./SubClassRow";
import useQuery from "../../../../../../../Utils/query";
import Axios from "../../../../../../../Api";
import toast from "react-hot-toast";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../Utils/Auth";

const ScopeRow = ({
  data,
  handleOpen,
  setScopeTargetData,
  setSelectedScope,
  scopeTargets,
  allTasks,
  getTarget,
  setEditTargetData,
  getYtd,
}) => {
  const [open, setOpen] = React.useState(false);
  const { setSessionExpire } = useAuth();
  const query = useQuery();
  const itemId = query.get("accItem");
  const scopeNo = query.get("scope");

  const handleDelete = async (subscope, subclassHead, subClass) => {
    await Axios({
      url: `/target/delete-scope-target`,
      method: "put",
      data: {
        scopeNo: Number(scopeNo),
        subscope: String(subscope).toLowerCase(),
        subclassHead: subclassHead,
        subClass: subClass,
        logid: itemId,
      },
    })
      .then(async (res) => {
        if (res.data.success) {
          await getTarget();
          toast.success(res.data.message);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const getSubScopeTarget = (data) => {
    if (!data) {
      return 0;
    }
    const val = Object.keys(data).reduce((sum, key) => {
      const subScopeTotal = data[key].reduce(
        (total, acc) => total + acc.subClassEmissionReduced,
        0
      );
      return sum + subScopeTotal;
    }, 0);
    return val;
  };
  return (
    <React.Fragment>
      <StyledTableRow sx={{ "& > *": { borderBottom: "1px solid #efefef" } }}>
        <StyledTableCellWithoutBorder
          sx={{ width: 350, border: "none" }}
          capitalize
          textleft
          component="th"
          scope="row"
        >
          <div className="align_center gap_10">
            <img
              className="w-30px h-30px border_radius_5"
              src={getServiceIcon(data.subScope)}
              alt={data.subScope}
            />
            <p>{data.subScope}</p>
          </div>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 120 }}>
          <div className="circle_30 bg_soft_green center">
            <p className="font_14_600 color_green">
              {allTasks.length > 0
                ? allTasks.filter(
                    (task) =>
                      task.logId._id === itemId &&
                      task.subScope.toLowerCase() ===
                        data.subScope.toLowerCase()
                  ).length
                : 0}
            </p>
          </div>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 235 }}>
          {localNumber(data.emission.baseYearEmission)}{" "}
          <span className="color_7e">tCO2e</span>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 235 }}>
          {scopeTargets
            ? getSubScopeTarget(
                scopeTargets[String(data.subScope).toLowerCase()]
              )
            : 0}
          <span className="color_7e">tCO2e</span>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 235 }}>
          {localNumber(
            getYtd(
              data.emission.baseYearEmission,
              data.emission.currentYearEmission
            )
          )}{" "}
          <span className="color_7e">tCO2e</span>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder
          className="justify_end"
          sx={{ display: "flex" }}
        >
          <button
            className="h-30px w-30px center bg_green border-none border_radius_5"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <KeyboardArrowUp htmlColor="#fff" />
            ) : (
              <KeyboardArrowDown htmlColor="#fff" />
            )}
          </button>
        </StyledTableCellWithoutBorder>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCellWithoutBorder style={{ padding: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {scopeTargets === null ||
            !scopeTargets[String(data.subScope).toLowerCase()] ? (
              <div
                className="px-20 pt-10 pb-10"
                style={{ backgroundColor: "#fbfbfb" }}
              >
                <ButtonComp
                  status={5}
                  className="h-30px gap_6 p-0 center"
                  style={{ width: "220px" }}
                  onClick={() => {
                    setScopeTargetData(data.subclasses);
                    setSelectedScope(data.subScope);
                    handleOpen();
                  }}
                >
                  <DonwIntradayIcon cc={"#fff"} /> Add Subscope Target
                </ButtonComp>
              </div>
            ) : (
              <div>
                <Table size="small" aria-label="purchases">
                  <TableBody>
                    {Object.keys(
                      scopeTargets[String(data.subScope).toLowerCase()]
                    ).length > 0 &&
                      Object.keys(
                        scopeTargets[String(data.subScope).toLowerCase()]
                      ).map((scopeHeadData, index1) => {
                        return scopeTargets[
                          String(data.subScope).toLowerCase()
                        ][scopeHeadData].map((subclassData, index2) => (
                          <SubClassRow
                            subclassData={subclassData}
                            dataSubClasses={data.subclasses[scopeHeadData]}
                            key={scopeHeadData + index1 + index2}
                            handleDelete={() =>
                              handleDelete(
                                data.subScope,
                                scopeHeadData,
                                subclassData.subClass
                              )
                            }
                            handleEdit={() => {
                              setScopeTargetData(data.subclasses);
                              setSelectedScope(data.subScope);
                              setEditTargetData({
                                isEdit: true,
                                subClassHead: scopeHeadData,
                                subClass: subclassData.subClass,
                                subscope: data.subScope,
                                subClassEmision: subclassData.subClassEmission,
                                reductionValue:
                                  subclassData.subClassEmissionReduced,
                                isCreateTask: false,
                              });
                              handleOpen();
                            }}
                            handleOpenTask={() => {
                              setScopeTargetData(data.subclasses);
                              setSelectedScope(data.subScope);
                              setEditTargetData({
                                isEdit: true,
                                subClassHead: scopeHeadData,
                                subClass: subclassData.subClass,
                                subscope: data.subScope,
                                subClassEmision: subclassData.subClassEmission,
                                reductionValue:
                                  subclassData.subClassEmissionReduced,
                                isCreateTask: true,
                              });
                              handleOpen();
                            }}
                            setEditTargetData={setEditTargetData}
                            allTasks={allTasks.filter(
                              (task) =>
                                task.logId._id === itemId &&
                                task.subScope.toLowerCase() ===
                                  data.subScope.toLowerCase()
                            )}
                            getYtd={getYtd}
                          />
                        ));
                      })}
                  </TableBody>
                </Table>
                <div
                  className="px-20 pt-10 pb-10"
                  style={{ backgroundColor: "#fbfbfb" }}
                >
                  <ButtonComp
                    status={5}
                    className="h-30px gap_6 p-0 center"
                    style={{ width: "130px" }}
                    onClick={() => {
                      setScopeTargetData(data.subclasses);
                      setSelectedScope(data.subScope);
                      handleOpen();
                    }}
                  >
                    <DonwIntradayIcon cc={"#fff"} /> Add Target
                  </ButtonComp>
                </div>
              </div>
            )}
          </Collapse>
        </StyledTableCellWithoutBorder>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default ScopeRow;
