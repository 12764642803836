// import React, { useEffect, useState } from "react";
import React, { useEffect } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import industries from "../industries.json";
import TextField from "@mui/material/TextField";
import BackForwardButton from "../../../Assets/components/BackForwardButton/BackForwardButton";
import PressEnter from "../../../Assets/components/PressEnter/PressEnter";
import ButtonComp from "../../../Assets/components/Button";
// import { useSearchParams } from "react-router-dom";

function Select(props) {
  const handleEnterPress = (e) => {
    if (e.key === "Enter") {
      props.handleSubmit(props.regDetails[props.target]);
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleEnterPress);
    return () => {
      document.removeEventListener("keydown", handleEnterPress);
    };
  }, []);
  return (
    <div className="register_text_start col-md-10 max_width_552 register_question_container">
      <div className="purple_label register_question_no">
        {props.currQuestion + 1}/6
      </div>
      <div className="form-group register_text_start">
        <label
          className=" register_sm_label register_question_label"
          htmlFor="label1"
        >
          {props.question.question}
        </label>
        {/* <input
          type="text"
          className="py-3 border-bottom  shadow-none register_input"
          id="label1"
          value={props.regDetails[props.target]}
          onChange={(e) => props.handleChange(e, props.question.fields.type)}
          placeholder={props.question.fields.placeholder}
          name={props.target}
        /> */}
        <Autocomplete
          className="py-3 border-bottom  shadow-none register_input"
          disablePortal
          id="combo-box-demo"
          options={industries}
          value={props.regDetails.industry}
          name={props.target}
          onChange={(e, value) => {
            props.setRegDetails((prev) => ({ ...prev, industry: value }));
          }}
          //   sx={{ width: 300 }}
          // slotProps={(params) => <TextField {...params} variant="standard" />}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </div>
      <div className="mt-30 d-flex align-items-start justify-content-between">
        <BackForwardButton
          handleGoBack={() =>
            props.decQuestionNo(props.regDetails[props.target])
          }
          hanldeGoForward={() =>
            props.incQuestionNo(props.regDetails[props.target])
          }
        />
        <div>
          <ButtonComp
            size="large"
            status={2}
            onClick={() => props.handleSubmit(props.regDetails[props.target])}
          >
            Save
          </ButtonComp>
          <PressEnter />
        </div>
      </div>
    </div>
  );
}

export default Select;

// press enter icon change: figma
