import React from "react";
import "./ProductCards.css";
import SimpleProgressBar from "../ProgressBars/simpleProgressBar";
function ProductCard1(props) {
  return (
    <div className="productCard_container px-3">
      <div className="productCard_content align_text_left ">
        <p className="overline_text mt-20 ">Total tCO2e ({props.year})</p>
        <p className="f-28 fw-700 pt-12 pb-2">
          {Number(props.currTotalEmission.toFixed(2)).toLocaleString()}{" "}
          <span className="f-13 fw-600">tCO2e</span>
        </p>
      </div>
      {/* test  */}

      <div className="productCard_height mt-20">
        <div className="align_text_left">
          <p className="breadcrumb_text_light_1">Comparison (YOY)</p>
        </div>
        <div className="row align-items-center mt-20 justify-content-between">
          <div className="col-md-2">
            <p
              className={
                props.lastYearEmission === 0
                  ? "green_label"
                  : ((props.currTotalEmission + props.lastYearEmission) /
                      props.lastYearEmission) *
                      100 >
                    100
                  ? "red_label"
                  : "green_label"
              }
            >
              {props.lastYearEmission === 0
                ? "0"
                : (
                    ((props.currTotalEmission + props.lastYearEmission) /
                      props.lastYearEmission) *
                    100
                  )
                    .toFixed(0)
                    .toLocaleString()}
              %
            </p>
          </div>
          <div className="col-md-8 align-items-center ml-8 ">
            <SimpleProgressBar
              val={
                props.lastYearEmission === 0
                  ? 0
                  : (
                      ((props.currTotalEmission + props.lastYearEmission) /
                        props.lastYearEmission) *
                      100
                    )
                      .toFixed(0)
                      .toLocaleString()
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard1;
