import React from "react";

const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 16 15"
    >
      <path
        stroke="#000"
        strokeWidth="1.5"
        d="M8.323 4.016l.53.53-.53-.53h0L4.301 8.038l-.038.038c-.156.156-.324.323-.443.534l.653.37-.653-.37c-.119.21-.176.44-.229.654l-.013.052-.252 1.008-.008.032c-.038.15-.084.336-.1.496-.017.18-.02.537.27.826.29.29.646.287.826.27.16-.016.345-.062.496-.1l.032-.008 1.008-.252.052-.013c.214-.053.444-.11.655-.23l-.36-.635.36.636c.21-.12.377-.287.533-.443l.038-.038h0l4.022-4.022c.01-.012.022-.023.033-.034.128-.128.262-.26.359-.405a1.75 1.75 0 000-1.949c-.097-.144-.23-.277-.359-.405l-.033-.034c-.012-.01-.023-.022-.034-.033a2.824 2.824 0 00-.405-.359 1.75 1.75 0 00-1.949 0c-.144.097-.277.23-.405.359l-.034.033z"
      ></path>
      <path
        fill="#000"
        d="M8.266 4.546L10.03 3.37l1.766 1.766L10.62 6.9 8.266 4.546z"
      ></path>
    </svg>
  );
};

export default EditIcon;
