import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import SearchIconOutlined from "../../../../../Assets/icons/SearchIconOutlined";
import FilterIcon from "../../../../../Assets/icons/FilterIcon";
// import AddIcon from "./Icons/AddIcon";
import ButtonComp from "../../../../../Assets/components/Button";
import GridViewCard from "./Components/GridViewCard";
import ListViewCard from "./Components/ListViewCard";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Axios from "../../../../../Api";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
import subscopeJson from "../../../../../DataJSON/subscopesJson.json";
import { Close, FormatListBulleted, KeyboardArrowDown } from "@mui/icons-material";
import CardViewIcon from "../../../../../Assets/icons/CardViewIcons";
import ListViewIcon from "../../../../../Assets/icons/ListViewIcon";
const priority = {
  high: "high",
  medium: "medium",
  low: "low",
};

const type = {
  todo: "Todo",
  progress: "In Progress",
  complete: "Completed",
};

const AllTasks = ({
  isCardView,
  setStep,
  allTasks,
  setAllTasks,
  targetData,
  setTaskInfo,
  setIsCardView,
  step,
  handleClose
}) => {
  const [filterValue, setFilterValue] = useState("");
  const { setSessionExpire } = useAuth();
  const [allAccItems, setallAccItems] = useState([]);
  const [allSubScopes, setAllSubscopes] = useState([]);
  const [selectedAcItem, setselectedAcItem] = useState("");
  const [selectedSubScope, setSelectedSubScope] = useState("");

  useEffect(() => {
    if (allTasks) {
      let acitems = [];
      let subscopes = [...new Set(allTasks.map((data) => data.subScope))];
      subscopes = subscopes.filter(
        (data) => data !== undefined || data !== null
      );
      allTasks.forEach((task) => {
        acitems.push(task.logId.accountingItemName);
        // logitems.push(task.logId.itemDetails.name);
      });

      acitems = acitems.filter((val, i) => acitems.indexOf(val) === i);
      // logitems = logitems.filter((val, i) => logitems.indexOf(val) === i);

      setallAccItems(acitems);
      setAllSubscopes(subscopes);
    }
  }, [allTasks]);

  const updateTaskStatus = async (id, status, sourceStatus, index) => {
    await Axios({
      url: `/target/update-task-status/${id}/${targetData._id}`,
      method: "PUT",
      data: {
        status: type[status],
        index: index,
        sourceStatus: type[sourceStatus],
      },
    }).catch((err) => {
      toastResponseError(err, setSessionExpire);
    });
  };

  const updateTaskIndex = async (id, status, sourceIndex, destinationIndex) => {
    await Axios({
      url: `/target/update-task-index/${id}/${targetData._id}`,
      method: "PUT",
      data: {
        status: type[status],
        sourceIndex: sourceIndex,
        destinationIndex: destinationIndex,
      },
    }).catch((err) => {
      toastResponseError(err, setSessionExpire);
    });
  };

  const handledragend = async (result) => {
    if (!result.destination) return;
    if (
      result.source.droppableId === result.destination.droppableId &&
      result.source.index === result.destination.index
    )
      return;
    if (result.source.droppableId === result.destination.droppableId) {
      updateTaskIndex(
        result.draggableId,
        result.destination.droppableId,
        result.source.index,
        result.destination.index
      );
      let temp_newTasks = [...allTasks];
      temp_newTasks.sort((a, b) => a.index - b.index);
      const sourceIndex = result.source.index;
      const destinationIndex = result.destination.index;
      const relatableTasks = temp_newTasks
        .filter((task) => task.status === type[result.source.droppableId])
        .sort((a, b) => a.index - b.index);

      if (relatableTasks.length === 2) {
        const findSourceIndex = temp_newTasks.findIndex(
          (task) => task._id === relatableTasks[0]._id
        );
        const findDestinationIndex = temp_newTasks.findIndex(
          (task) => task._id === relatableTasks[1]._id
        );
        temp_newTasks[findSourceIndex].index = 1;
        temp_newTasks[findDestinationIndex].index = 0;
      } else if (destinationIndex < sourceIndex) {
        for (let i = destinationIndex; i < sourceIndex; i++) {
          const findIndex = temp_newTasks.findIndex(
            (task) => task._id === relatableTasks[i]._id
          );
          temp_newTasks[findIndex].index += 1;
        }
        const findIndexToUpdate = temp_newTasks.findIndex(
          (task) => task._id === relatableTasks[sourceIndex]._id
        );
        temp_newTasks[findIndexToUpdate].index = destinationIndex;
      } else if (destinationIndex > sourceIndex) {
        for (let i = sourceIndex + 1; i <= destinationIndex; i++) {
          const findIndex = temp_newTasks.findIndex(
            (task) => task._id === relatableTasks[i]._id
          );
          temp_newTasks[findIndex].index -= 1;
        }
        const findIndexToUpdate = temp_newTasks.findIndex(
          (task) => task._id === relatableTasks[sourceIndex]._id
        );
        temp_newTasks[findIndexToUpdate].index = destinationIndex;
      }
      setAllTasks(temp_newTasks);
    } else {
      let temp_newTasks = [...allTasks];
      temp_newTasks.sort((a, b) => a.index - b.index);
      const updateTaskIndex = temp_newTasks.findIndex(
        (task) => result.draggableId === task._id
      );
      if (updateTaskIndex !== -1) {
        let destinationTasks = temp_newTasks.filter(
          (task) => task.status === type[result.destination.droppableId]
        );
        let sourceTasks = temp_newTasks.filter(
          (task) => task.status === type[result.source.droppableId]
        );
        for (
          let i = result.destination.index;
          i < destinationTasks.length;
          i++
        ) {
          const destinationTaskindex = temp_newTasks.findIndex(
            (task) => task._id === destinationTasks[i]._id
          );
          temp_newTasks[destinationTaskindex].index += 1;
        }
        for (
          let i = result.destination.index + 1;
          i < sourceTasks.length;
          i++
        ) {
          const sourceTaskIndex = temp_newTasks.findIndex(
            (task) => task._id === sourceTasks[i]._id
          );
          temp_newTasks[sourceTaskIndex].index -= 1;
        }

        temp_newTasks[updateTaskIndex].status =
          type[result.destination.droppableId];
        temp_newTasks[updateTaskIndex].index = result.destination.index;
        setAllTasks(temp_newTasks);
        updateTaskStatus(
          result.draggableId,
          result.destination.droppableId,
          result.source.droppableId,
          result.destination.index
        );
      }
    }
  };

  const getScopeNumber = (scopeName) => {
    if (scopeName) {
      let ghgScope = subscopeJson.find((obj) => {
        return obj.name.toLowerCase() === scopeName.toLowerCase();
      });
      return ghgScope ? "S" + ghgScope.relatedGHG : 0;
    } else return 0;
  };

  return (
    <React.Fragment>
      <div className="tasks_drawer_header">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap_10">
            <div className="w-40px h-40px bg_soft_green border_radius_5 center pointer position-relative">
              <span
                onClick={() => {
                  if (step === 0) {
                    handleClose();
                  } else {
                    setStep(0);
                  }
                }}
              >
                <FormatListBulleted htmlColor="#00915B" />
              </span>
              {/* <div className="task_toggleCount center">
                  <p className="font_12_600 line_height_10 text-white">3</p>
                </div> */}
            </div>
            <div className="container_br_5_p_10_8 h-40px center">
              <p className="offsetProjectTitle line_height_24">
                {step === 0 ? "Tasks" : "New Task"}
              </p>
            </div>
          </div>
          <Close
            htmlColor="#4C4C4C"
            className="pointer"
            onClick={() => {
              if (step === 0) {
                handleClose();
              } else {
                setTaskInfo(null);
                setStep(0);
              }
            }}
          />
        </div>
        <div className="mt-50 between">
          {step === 0 ? (
            <div className="d-flex align-items-center gap_25">
              <div
                className="d-flex align-items-center pointer"
                onClick={() => setIsCardView(true)}
              >
                <CardViewIcon cc={isCardView ? "#00915B" : "#575757"} />
                <p
                  className={
                    "font_14_500 line_height_14 ml-6 " +
                    (isCardView ? "color_green" : "color_57")
                  }
                >
                  Card View
                </p>
              </div>
              <div
                className="d-flex align-items-center pointer"
                onClick={() => setIsCardView(false)}
              >
                <ListViewIcon cc={isCardView ? "#575757" : "#00915B"} />
                <p
                  className={
                    "font_14_500 line_height_14 ml-6 " +
                    (isCardView ? "color_57" : "color_green")
                  }
                >
                  List View
                </p>
              </div>
            </div>
          ) : (
            <div />
          )}
          {/* <div className="align_center bg_soft_green gap_10 px-10 h-30px border_radius_5 pointer">
              <LibraryIcon />
              <p className="color_green font_14_500 line_height_14">Library</p>
            </div> */}
        </div>
      </div>
      <div className="tasks_search_items d-flex align-items-center gap_10 mt-10 pb-10 border-btm_ef pl-20">
        <TextField
          value={filterValue}
          onChange={(e) => setFilterValue(e.target.value)}
          className="font_12_500"
          id="input-with-icon-textfield"
          placeholder={"Search..."}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIconOutlined />
              </InputAdornment>
            ),
          }}
          sx={{
            width: "300px",
            borderRadius: "5px",
            boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
            border: "1px solid #efefef",

            "& .MuiInputBase-root": {
              height: 40,
              width: "100%",
              border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
            "& .MuiInputBase-input": {
              fontFamily: "Inter",
              fontSize: " 12px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "10px",
              paddingLeft: "4px",
            },
          }}
        />

        <FormControl sx={{ height: "40px", width: 270 }} size="small">
          <Select
            value={selectedAcItem}
            onChange={(e) => setselectedAcItem(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.03)",
              border: "1px solid #efefef",
              "& .MuiInputBase-input": {
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              PaperProps: {
                style: {
                  // maxHeight: 250,
                  // width: 200,
                  borderRadius: "5px",
                  border: "1px solid #EDEDED",
                  background: "#FFFFFF",
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
                },
              },
              // MenuListProps: {
              //   style: { padding: "0px" },
              // },
            }}
          >
            <MenuItem
              sx={{
                borderBottom: "1px solid #efefef",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                color: "#000000",
              }}
              value={""}
            >
              Accounting items ({allAccItems.length})
            </MenuItem>
            {allAccItems &&
              allAccItems.map((item, i) => (
                <MenuItem
                  sx={{
                    borderBottom:
                      i === allAccItems.length - 1
                        ? "none"
                        : "1px solid #efefef",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#000000",
                  }}
                  key={i}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <FormControl sx={{ height: "40px", width: 270 }} size="small">
          <Select
            value={selectedSubScope}
            onChange={(e) => setSelectedSubScope(e.target.value)}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              backgroundColor: "#ffffff",
              boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.03)",
              border: "1px solid #efefef",
              "& .MuiInputBase-input": {
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "600",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  // maxHeight: 250,
                  // width: 200,
                  borderRadius: "5px",
                  border: "1px solid #EDEDED",
                  background: "#FFFFFF",
                  boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.06)",
                },
              },
              // MenuListProps: {
              //   style: { padding: "0px" },
              // },
            }}
            IconComponent={KeyboardArrowDown}
          >
            <MenuItem
              sx={{
                borderBottom: "1px solid #efefef",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                color: "#000000",
              }}
              value={""}
            >
              Subscopes ({allSubScopes.length})
            </MenuItem>
            {allSubScopes &&
              allSubScopes.map((item, i) => (
                <MenuItem
                  sx={{
                    borderBottom:
                      i === allSubScopes.length - 1
                        ? "none"
                        : "1px solid #efefef",
                    fontFamily: "Inter",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: "500",
                    color: "#000000",
                  }}
                  key={i}
                  value={item}
                >
                  {item}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        <div className="w-40px h-40px bg_white border_radius_5 center border_1_ef">
          <FilterIcon />
        </div>
        <ButtonComp
          status={5}
          onClick={() => setStep(1)}
          className="h-40px ms-auto mr-24"
        >
          Add Task
        </ButtonComp>
      </div>
      <div className={isCardView ? "task_grid" : "list_stack"}>
        <DragDropContext onDragEnd={handledragend}>
          <Droppable droppableId="todo">
            {(provided) => (
              <div
                className="task_grid_column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="">
                  <p className="font_12_600 color_7e line_height_10">To Do</p>
                  {/* <AddIcon className={"pointer"} /> */}
                </div>
                <div className="mt-20 d-flex flex-column gap_10">
                  {allTasks &&
                    allTasks.length > 0 &&
                    allTasks
                      .filter((task) => task.status === type.todo)
                      .filter((task) => {
                        if (filterValue === null || filterValue === "")
                          return task;
                        return String(task.taskName)
                          .toLowerCase()
                          .includes(filterValue.toLowerCase());
                      })
                      .filter((task) => {
                        if (selectedAcItem)
                          return (
                            task.logId.accountingItemName === selectedAcItem
                          );
                        else return task;
                      })
                      .filter((task) => {
                        if (selectedSubScope)
                          return task.subScope === selectedSubScope;
                        else return task;
                      })
                      .sort((a, b) => a.index - b.index)
                      .map((task, i) => {
                        if (isCardView) {
                          return (
                            <Draggable
                              draggableId={`${task._id}`}
                              key={task._id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <GridViewCard
                                    key={task._id}
                                    title={task.taskName}
                                    priority={priority[task.priority]}
                                    type={task.status}
                                    scope={getScopeNumber(
                                      task?.subclass?.subscope
                                    )}
                                    name={task.logId.itemDetails.name}
                                    isCardView={isCardView}
                                    date={task.due_date}
                                    assignee={task.assignee}
                                    invitedAssignee={task.invitedAssignee}
                                    handleEdit={() => {
                                      setTaskInfo(task);
                                      setStep(1);
                                    }}
                                  />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          );
                        } else {
                          return (
                            <Draggable
                              draggableId={`${task._id}`}
                              key={task._id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListViewCard
                                    key={task._id}
                                    title={task.taskName}
                                    priority={priority[task.priority]}
                                    type={task.status}
                                    scope={getScopeNumber(
                                      task?.subclass?.subscope
                                    )}
                                    name={task.logId.itemDetails.name}
                                    isCardView={isCardView}
                                    date={task.due_date}
                                    assignee={task.assignee}
                                    invitedAssignee={task.invitedAssignee}
                                    handleEdit={() => {
                                      setTaskInfo(task);
                                      setStep(1);
                                    }}
                                  />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="progress">
            {(provided) => (
              <div
                className="task_grid_column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="">
                  <p className="font_12_600 color_7e line_height_10">
                    In Progress
                  </p>
                  {/* <AddIcon className={"pointer"} /> */}
                </div>
                <div className="mt-20 d-flex flex-column gap_10">
                  {allTasks &&
                    allTasks.length > 0 &&
                    allTasks
                      .filter((task) => task.status === type.progress)
                      .filter((task) => {
                        if (filterValue === null || filterValue === "")
                          return task;
                        return String(task.taskName)
                          .toLowerCase()
                          .includes(filterValue.toLowerCase());
                      })
                      .filter((task) => {
                        if (selectedAcItem)
                          return (
                            task.logId.accountingItemName === selectedAcItem
                          );
                        else return task;
                      })
                      .filter((task) => {
                        if (selectedSubScope)
                          return task.subScope === selectedSubScope;
                        else return task;
                      })
                      .sort((a, b) => a.index - b.index)
                      .map((task, i) => {
                        if (isCardView) {
                          return (
                            <Draggable
                              draggableId={`${task._id}`}
                              key={task._id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <GridViewCard
                                    key={task._id}
                                    title={task.taskName}
                                    priority={priority[task.priority]}
                                    type={task.status}
                                    scope={getScopeNumber(
                                      task?.subclass?.subscope
                                    )}
                                    name={task.logId.itemDetails.name}
                                    isCardView={isCardView}
                                    date={task.due_date}
                                    assignee={task.assignee}
                                    invitedAssignee={task.invitedAssignee}
                                    handleEdit={() => {
                                      setTaskInfo(task);
                                      setStep(1);
                                    }}
                                  />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          );
                        } else {
                          return (
                            <Draggable
                              draggableId={`${task._id}`}
                              key={task._id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListViewCard
                                    key={task._id}
                                    title={task.taskName}
                                    priority={priority[task.priority]}
                                    type={task.status}
                                    scope={getScopeNumber(
                                      task?.subclass?.subscope
                                    )}
                                    name={task.logId.itemDetails.name}
                                    isCardView={isCardView}
                                    date={task.due_date}
                                    assignee={task.assignee}
                                    invitedAssignee={task.invitedAssignee}
                                    handleEdit={() => {
                                      setTaskInfo(task);
                                      setStep(1);
                                    }}
                                  />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="complete">
            {(provided) => (
              <div
                className="task_grid_column"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="">
                  <p className="font_12_600 color_7e line_height_10">
                    Complete
                  </p>
                  {/* <AddIcon className={"pointer"} /> */}
                </div>
                <div className="mt-20 d-flex flex-column gap_10">
                  {allTasks &&
                    allTasks.length > 0 &&
                    allTasks
                      .filter((task) => task.status === type.complete)
                      .filter((task) => {
                        if (filterValue === null || filterValue === "")
                          return task;
                        return String(task.taskName)
                          .toLowerCase()
                          .includes(filterValue.toLowerCase());
                      })
                      .filter((task) => {
                        if (selectedAcItem)
                          return (
                            task.logId.accountingItemName === selectedAcItem
                          );
                        else return task;
                      })
                      .filter((task) => {
                        if (selectedSubScope)
                          return task.subScope === selectedSubScope;
                        else return task;
                      })
                      .sort((a, b) => a.index - b.index)
                      .map((task, i) => {
                        if (isCardView) {
                          return (
                            <Draggable
                              draggableId={`${task._id}`}
                              key={task._id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <GridViewCard
                                    key={task._id}
                                    title={task.taskName}
                                    priority={priority[task.priority]}
                                    type={task.status}
                                    scope={getScopeNumber(
                                      task?.subclass?.subscope
                                    )}
                                    name={task.logId.itemDetails.name}
                                    isCardView={isCardView}
                                    date={task.due_date}
                                    assignee={task.assignee}
                                    invitedAssignee={task.invitedAssignee}
                                    handleEdit={() => {
                                      setTaskInfo(task);
                                      setStep(1);
                                    }}
                                  />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          );
                        } else {
                          return (
                            <Draggable
                              draggableId={`${task._id}`}
                              key={task._id}
                              index={i}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <ListViewCard
                                    key={task._id}
                                    title={task.taskName}
                                    priority={priority[task.priority]}
                                    type={task.status}
                                    scope={getScopeNumber(
                                      task?.subclass?.subscope
                                    )}
                                    name={task.logId.itemDetails.name}
                                    isCardView={isCardView}
                                    date={task.due_date}
                                    assignee={task.assignee}
                                    invitedAssignee={task.invitedAssignee}
                                    handleEdit={() => {
                                      setTaskInfo(task);
                                      setStep(1);
                                    }}
                                  />
                                  {provided.placeholder}
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      })}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </React.Fragment>
  );
};

export default AllTasks;
