import React, { useState, useEffect } from "react";
import axios from "../../../Api/index";
import { Tabs, Tab, Box } from "@mui/material";
import { useAuth } from "../../../Utils/Auth";
// import Item from "./Item";
import CartItem from "./CartItem";
import BillAddress from "./BillAddress";
import { useNavigate } from "react-router";
import useQuery from "../../../Utils/query";
import PropTypes from "prop-types";
import { toastResponseError } from "../../../Utils/toastResponseError";
import ScheduleCallCustomPricing from "./CallShedule";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import ButtonComp from "../../../Assets/components/Button";
import LearnMore from "./LearnMore/LearnMore";
import ItemDetails from "./ItemDetails";
import Card from "./Item/Card";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Subscription() {
  const { setSessionExpire } = useAuth();
  // useEffect(() => {
  //   if (user) {
  //     if (user.isBasePaid && !user.is2FASet) {
  //       history(`/set-two-factor`);
  //     }
  //     if (user.isBasePaid && user.is2FASet) {
  //       history(`/dashboard`);
  //     }
  //   }
  // }, [user]);
  const query = useQuery();
  const queryStep = Number(query.get("step"));
  const [step, setstep] = useState(0);
  const history = useNavigate();
  const [generalItems, setgeneralItems] = useState(null);
  // const [industryItems, setindustryItems] = useState(null);
  // const [otherItems, setotherItems] = useState(null);
  const [isdisabled, setisdisabled] = useState(false);
  const [itemName, setitemName] = useState("general");
  const [value, setValue] = React.useState(0);
  const [cart, setcart] = useState(null);
  const [callHandleSubmit, setcallHandleSubmit] = useState(false);
  const [openDropDown, setopenDropDown] = useState(false);
  const [d_code, setd_code] = useState("");
  const [submitCode, setsubmitCode] = useState(false);
  const [cartItems, setcartItems] = useState([
    {
      name: "Base Subscription",
      price: 390.0,
      quantity: 1,
    },
  ]);

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
      setitemName("industry");
    }
    if (newValue === 2) {
      setitemName("other");
    }
    setValue(newValue);
  };

  const getdata = async () => {
    try {
      const response = await axios.get(`/items/all`);
      if (response.data.success) {
        // if (response.data.items.category === "general") {
        setgeneralItems(response.data.items);
        // } else if (itemName === "industry") {
        //   setindustryItems(response.data.items);
        // } else if (itemName === "other") {
        //   setotherItems(response.data.items);
        // }
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const getCart = async () => {
    try {
      const response = await axios.get(`/cart/user-cart`);
      if (response.data.success) {
        setcart(response.data.data);
        setcartItems(response.data.data.items);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    getdata();
  }, [itemName]);
  useEffect(() => {
    getCart();
  }, []);
  useEffect(() => {
    if (queryStep || queryStep === 0) {
      setstep(queryStep);
    }
  }, [queryStep]);

  const handleIncrease = async (id, quantity) => {
    try {
      var response = await axios.patch(`/cart/service/${cart._id}`, {
        itemId: id,
        quantity: quantity,
      });
      if (response.data.success) {
        setcart(response.data.cart);
        setcartItems(response.data.cart.items);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const handleContactUs = () => {
    setstep(2);
    history(`/register/subscription?step=${2}`);
  };
  const handleStepChange = () => {
    if (step === 0) {
      setstep(1);
      history(`/register/subscription?step=${1}`);
    } else if (step === 1) {
      history(`/register/success/`);
    }
  };

  useEffect(() => {
    if (d_code && d_code.length === 0) {
      setsubmitCode(false);
    }
  }, [d_code]);

  return (
    <div className="container-fluid subscription_root">
      <div className="row register_background mx-0">
        {step === 0 ? (
          <div className="col-md-7 text-start a_container subscription_root_items">
            <div className="d-flex align-items-center mt-16 mb-16">
              <p className="heading_1">Subscription</p>
              <div className="subtitle_box width_fit bg_soft_blue ml-20">
                <p className="color_blue font_13_600">Select your credit</p>
              </div>
            </div>
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  className="general_root_tabs"
                >
                  <Tab label="General Items" {...a11yProps(0)} />
                  <Tab label="Industry Specific Items" {...a11yProps(1)} />
                  {/* <Tab label="Other Services & Products" {...a11yProps(2)} /> */}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} className="general_tabpanel">
                <div className="row pb-40 store_card_body">
                  {generalItems &&
                    generalItems
                      .filter((item) => item.category === "general")
                      .map((item, i) => (
                        <div key={i} className={`col-6 mt-30`}>
                          <Card
                            item={item}
                            cart={cartItems}
                            handleIncrease={handleIncrease}
                            handleContactUs={handleContactUs}
                          />
                        </div>
                      ))}
                </div>
              </TabPanel>
              <TabPanel value={value} index={1} className="general_tabpanel">
                <div className="row pb-40 store_card_body">
                  {generalItems &&
                    generalItems
                      .filter((item) => item.category === "industry")
                      .map((item, i) => (
                        <div key={i} className={`col-6 mt-30`}>
                          <Card
                            item={item}
                            cart={cartItems}
                            handleIncrease={handleIncrease}
                            handleContactUs={handleContactUs}
                          />
                        </div>
                      ))}
                </div>
              </TabPanel>
              <TabPanel value={value} index={2} className="general_tabpanel">
                <div className="row pb-40 store_card_body">
                  {generalItems &&
                    generalItems
                      .filter((item) => item.category === "other")
                      .map((item, i) => (
                        <div key={i} className={`col-6 mt-30`}>
                          <Card
                            item={item}
                            cart={cartItems}
                            handleIncrease={handleIncrease}
                            handleContactUs={handleContactUs}
                            isOther={true}
                          />
                        </div>
                      ))}
                </div>
              </TabPanel>
            </Box>
          </div>
        ) : step === 2 ? (
          <div className="ml-116 mr-62">
            <ScheduleCallCustomPricing />
          </div>
        ) : step === 3 ? (
          <div className="ml-116 mr-62">
            <LearnMore />
          </div>
        ) : step === 4 ? (
          <ItemDetails
            cartDetails={cartItems}
            setCartDetails={setcartItems}
            handleQuantityChange={handleIncrease}
          />
        ) : (
          <div className="col-md-7 text-start a_container subscription_root_items">
            <BillAddress
              cart={cart}
              setcallHandleSubmit={setcallHandleSubmit}
              callHandleSubmit={callHandleSubmit}
              setisdisabled={setisdisabled}
            />
          </div>
        )}
        {step !== 2 && step !== 3 && step !== 4 && (
          <div className="col-md-5 register_info_container subscription_root_cart">
            <div>
              <h5 className="card_heading font-17 fw-700">CART DETAILS</h5>
              {cartItems &&
                cartItems.length > 0 &&
                cartItems.map((item, i) => (
                  <CartItem
                    item={item}
                    handleIncrease={handleIncrease}
                    key={i}
                  />
                ))}
            </div>

            <div className="">
              <label
                className="body_text_m fw-600 w-100 mb-10 pointer"
                onClick={() => setopenDropDown(!openDropDown)}
              >
                Discount Code{" "}
                {openDropDown ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
              </label>
              {openDropDown ? (
                <>
                  <div className="row mx-0">
                    <input
                      className="border_input mb-16 col-5 py-2"
                      placeholder="Discount Code"
                      value={d_code}
                      onChange={(e) => setd_code(e.target.value)}
                    />
                    {d_code && d_code.length > 0 ? (
                      <div className="col-md-4 col-6 col-lg-4">
                        <ButtonComp
                          status={1}
                          onClick={() => setsubmitCode(true)}
                        >
                          Apply Code{" "}
                        </ButtonComp>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {submitCode && d_code.length > 0 && (
                    <p className="color_red subheading_light_2">
                      The code is invalid, please try entering again.
                    </p>
                  )}
                </>
              ) : null}
              <div className="d-flex justify-content-between my-2 mt-20">
                <p className="grey-01">Subtotal</p>
                <p className="grey-01">
                  {cart &&
                    Number(cart.total).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  €
                </p>
              </div>
              <div className="d-flex justify-content-between my-2">
                <p className="grey-01">Discount</p>
                <p className="grey-01">
                  {cart &&
                    Number(cart.discount).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  €
                </p>
              </div>
              <div className="d-flex justify-content-between my-2">
                <p className="grey-01">Tax</p>
                <p className="grey-01">
                  {cart &&
                    cart.tax?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  €
                </p>
              </div>
              <hr />
              <div className="d-flex justify-content-between mt-20">
                <p className="heading_9">Total</p>
                <p className="heading_9">
                  {cart &&
                    Number(cart.grandTotal).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                  €
                </p>
              </div>
              {step === 0 && (
                <div className="text-end mt-32">
                  <ButtonComp
                    status={2}
                    onClick={() => {
                      if (step === 0) handleStepChange();
                      else setcallHandleSubmit(true);
                    }}
                    disabled={isdisabled}
                  >
                    {step === 0
                      ? "Select Payment Method"
                      : "Checkout & Download Invoice"}
                  </ButtonComp>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
