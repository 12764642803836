import { Table, TableBody, TableContainer, TableHead } from "@mui/material";
import React, { useState } from "react";
import {
  HeadCell,
  StyledHeadRow,
  StyledTableCell,
  StyledTableRow,
} from "../../../Utils/TableComp";
import { useNavigate } from "react-router-dom";

const ActiveOrg = ({ companies }) => {
  const navigate = useNavigate();
  const [searchQ, setSearchQ] = useState("");
  return (
    <div>
      <div className="d-flex justify-content-end">
        <div>
          <select
            className="select_input w-auto subheading_2"
            //   value={selectedYear}
            //   onChange={(e) => setSelectedYear(e.target.value)}
          >
            <option value="">Sort by</option>
            {/* {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))} */}
          </select>
          <input
            className="ml-16 select_input w-auto subheading_2"
            type="text"
            placeholder="search organization"
            value={searchQ}
            onChange={(e) => setSearchQ(e.target.value)}
          />
        </div>
      </div>
      <TableContainer sx={{ minWidth: 640 }} className="mt-20">
        <Table sx={{ minWidth: 640 }} aria-label="simple table">
          <TableHead>
            <StyledHeadRow>
              <HeadCell component="th" className="text-uppercase">
                Organization Name
              </HeadCell>
              <HeadCell component="th" className="text-uppercase">
                Industry
              </HeadCell>
              <HeadCell component="th" className="text-uppercase">
                No. of users
              </HeadCell>
              <HeadCell component="th" className="text-uppercase">
                No. of Credits
              </HeadCell>
              <HeadCell component="th" className="text-uppercase">
                Carbon Status
              </HeadCell>
            </StyledHeadRow>
          </TableHead>
          <TableBody>
            {companies &&
              companies.length > 0 &&
              companies
                .filter((comp) => {
                  if (
                    searchQ.trim() === null ||
                    searchQ.trim() === "" ||
                    searchQ.trim() === undefined
                  ) {
                    return comp;
                  } else {
                    return String(comp.companyName)
                      .toLowerCase()
                      .includes(searchQ.toLowerCase());
                  }
                })
                .map((comp) => (
                  <StyledTableRow
                    onClick={() =>
                      navigate(`/admin/dashboard?tab=7&cid=${comp._id}`)
                    }
                    className="pointer"
                    key={comp._id}
                  >
                    <StyledTableCell
                      className="text-start"
                      component="th"
                      scope="row"
                    >
                      {comp.companyName}
                    </StyledTableCell>
                    <StyledTableCell className="text-start">
                      {comp.industry}
                    </StyledTableCell>
                    <StyledTableCell className="text-start">
                      {comp.userCount}
                    </StyledTableCell>
                    <StyledTableCell className="text-start">
                      {comp.totalCreditPrice - 1}
                    </StyledTableCell>
                    <StyledTableCell className="text-start"></StyledTableCell>
                  </StyledTableRow>
                ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ActiveOrg;
