import React, { useEffect, useState } from "react";
import { Drawer, Menu, MenuItem, IconButton } from "@mui/material";
import SurveyPieChart from "./Components/SurveyPieChart";
import SurveyBarChart from "./Components/SurveyBarChart";
import { useAuth } from "../../../../../../../../Utils/Auth";
import LikertScaleChart from "./Components/LikertScaleChart";
import Axios from "../../../../../../../../Api";
import toast from "react-hot-toast";
import ButtonComp from "../../../../../../../../Assets/components/Button";
import { toastResponseError } from "../../../../../../../../Utils/toastResponseError";
import { Close, Done, MoreVert } from "@mui/icons-material";
import onClickOutside from "react-onclickoutside";
import TickMark from "../../../../../../../../Assets/icons/tickMark";
import * as XLSX from "xlsx";

function SurveyStats(props) {
  const { setSessionExpire } = useAuth();
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [participants, setParticipants] = useState([]);
  const [surveyName, setSurveyName] = useState("");
  const [surveyOptionAnchorEl, setSurveyOptionAnchorEl] = useState(null);
  const surveyOptionOpen = Boolean(surveyOptionAnchorEl);
  const [open, setOpen] = useState(false);

  SurveyStats.handleClickOutside = () => {
    setOpen(false);
  };

  const handleSurveyOptionClick = (event) => {
    setSurveyOptionAnchorEl(event.currentTarget);
  };

  const handleSurveyOptionClose = () => {
    setSurveyOptionAnchorEl(null);
  };

  const getData = async () => {
    try {
      await Axios.get(`/surveys/get-survey-stats/${props.surveyId}`).then(
        (res) => {
          if (res.data.success) {
            setQuestions(res.data.questions);
            setAnswers(res.data.answerStats);
            setParticipants(res.data.participants);
            setSurveyName(res.data.name);
          }
        }
      );
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    if (props.surveyId) {
      getData();
    }
  }, [props.surveyId]);

  const sendReminderToUser = async (id) => {
    try {
      await Axios.get(`/surveys/survey-reminder/${id}`).then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);
          getData();
        }
      });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const handleDownload = () => {
    const wb = XLSX.utils.book_new();
    // const headers = [['']];
    const QuestionType = [
      "Multiple Choice",
      "Open Ended Question",
      "Rating Scale",
      "Likert Scale",
    ];
    const formattedOverviewData = questions.map((question, i) => {
      let freqAns = null;
      if (question.type === 0) {
        const result = answers[i].reduce((max, current) =>
          current.count > max.count ? current : max
        );
        freqAns = result.option;
      }
      if (question.type === 2) {
        const result = answers[i].reduce(
          (acc, value, index) => {
            return value > acc.max ? { max: value, index: index } : acc;
          },
          { max: answers[i][0], index: 0 }
        );
        freqAns = result.index;
      }
      if (question.type === 3) {
        const result = answers[i].reduce(
          (acc, value, index) => {
            return value > acc.max ? { max: value, index: index } : acc;
          },
          { max: answers[i][0], index: 0 }
        );
        freqAns = question.options[result.index];
      }
      return [
        i + 1,
        QuestionType[question.type],
        question.question,
        freqAns || "-",
        i + 2,
      ];
    });

    // Create the Overview sheet
    const overviewData = [
      [`Survey Name: ${surveyName}`],
      [""],
      [
        `Description: Participate in our survey to shape a greener workplace! Share your insights, ideas, and suggestions for a more sustainable future. Your input drives our commitment to reducing our ecological footprint. Join us in creating a better tomorrow for our company and the planet.`,
      ],
      [""],
      [`Participants: ${participants.length}`],
      [
        `Total Responses: ${
          participants.filter((currUser) => currUser.submitted).length
        }`,
      ],
      [""],
      [""],
      [
        "Question No.",
        "Question Type",
        "Question",
        "Most Frequent Response",
        "Tab",
      ],
      ...formattedOverviewData,
    ];
    const wsOverview = XLSX.utils.aoa_to_sheet(overviewData);
    XLSX.utils.book_append_sheet(wb, wsOverview, "Overview");

    questions.forEach((item, index) => {
      let totalResponses = 0;
      let freqAns = null;
      const questionData = [[`Question : ${item.question}`], [""]];
      if (item.type === 0) {
        let systmeticData = answers[index].map((data) => [
          data.option,
          data.count,
        ]);
        totalResponses = answers[index].reduce(
          (prev, curr) => prev + curr.count,
          0
        );
        const result = answers[index].reduce((max, current) =>
          current.count > max.count ? current : max
        );
        freqAns = result.option;
        questionData.push(
          ...systmeticData,
          [],
          [`Total Response`, totalResponses],
          [],
          ["Most Frequent Response", freqAns]
        );
      }
      if (item.type === 1) {
        let systmeticData = answers[index].map((data, i) => [
          `Response ${i + 1}`,
          data,
        ]);
        totalResponses = answers[index].length;
        questionData.push(
          ...systmeticData,
          [],
          [`Total Response`, totalResponses]
        );
      }
      if (item.type === 2) {
        let systmeticData = answers[index].map((data, i) => {
          if (i !== 0) {
            return [i, data];
          }
        });
        systmeticData = systmeticData.slice(1);
        totalResponses = answers[index].reduce((prev, curr) => prev + curr, 0);
        const result = answers[index].reduce(
          (acc, value, index) => {
            return value > acc.max ? { max: value, index: index } : acc;
          },
          { max: answers[index][0], index: 0 }
        );
        freqAns = result.index;
        const totalRating = Array.from(
          { length: answers[index].length },
          (_, index) => index
        ).reduce((prev, curr) => prev + curr, 0);

        questionData.push(
          ...systmeticData,
          [],
          [`Total Response`, totalResponses],
          [],
          ["Most Frequent Response", freqAns],
          [],
          [
            "Average Rating",
            Number(totalRating / totalResponses).toLocaleString(undefined, {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }),
          ]
        );
      }
      if (item.type === 3) {
        let systmeticData = answers[index].map((data, i) => [
          item.options[i],
          data,
        ]);
        totalResponses = answers[index].reduce((prev, curr) => prev + curr, 0);
        const result = answers[index].reduce(
          (acc, value, index) => {
            return value > acc.max ? { max: value, index: index } : acc;
          },
          { max: answers[index][0], index: 0 }
        );
        freqAns = item.options[result.index];

        questionData.push(
          ...systmeticData,
          [],
          [`Total Response`, totalResponses],
          [],
          ["Most Frequent Response", freqAns]
        );
      }

      // const ws = XLSX.utils.json_to_sheet([item], {
      //   header: ["question", "options", "type", "scaleStart", "scaleEnd"],
      // });
      // ws['A1'] = { t: 's', v: `Question: ${item.question}` };
      const questionShet = XLSX.utils.aoa_to_sheet(questionData);
      XLSX.utils.book_append_sheet(wb, questionShet, `Question ${index + 1}`);
    });

    XLSX.writeFile(wb, "survey_data.xlsx");
  };

  const handleSingleChartDownload = (question, answers) => {
    const wb = XLSX.utils.book_new();

    let totalResponses = 0;
    let freqAns = null;
    const questionData = [[`Question : ${question.question}`], [""]];

    if (question.type === 0) {
      let systmeticData = answers.map((data) => [data.option, data.count]);
      totalResponses = answers.reduce((prev, curr) => prev + curr.count, 0);
      const result = answers.reduce((max, current) =>
        current.count > max.count ? current : max
      );
      freqAns = result.option;
      questionData.push(
        ...systmeticData,
        [],
        [`Total Response`, totalResponses],
        [],
        ["Most Frequent Response", freqAns]
      );
      console.log(questionData);
    }
    if (question.type === 1) {
      let systmeticData = answers.map((data, i) => [`Response ${i + 1}`, data]);
      totalResponses = answers.length;
      questionData.push(
        ...systmeticData,
        [],
        [`Total Response`, totalResponses]
      );
    }
    if (question.type === 2) {
      let systmeticData = answers.map((data, i) => {
        if (i !== 0) {
          return [i, data];
        }
      });
      systmeticData = systmeticData.slice(1);
      totalResponses = answers.reduce((prev, curr) => prev + curr, 0);
      const result = answers.reduce(
        (acc, value, index) => {
          return value > acc.max ? { max: value, index: index } : acc;
        },
        { max: answers[0], index: 0 }
      );
      freqAns = result.index;
      const totalRating = Array.from(
        { length: answers.length },
        (_, index) => index
      ).reduce((prev, curr) => prev + curr, 0);

      questionData.push(
        ...systmeticData,
        [],
        [`Total Response`, totalResponses],
        [],
        ["Most Frequent Response", freqAns],
        [],
        [
          "Average Rating",
          Number(totalRating / totalResponses).toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          }),
        ]
      );
    }
    if (question.type === 3) {
      let systmeticData = answers.map((data, i) => [question.options[i], data]);
      totalResponses = answers.reduce((prev, curr) => prev + curr, 0);
      const result = answers.reduce(
        (acc, value, index) => {
          return value > acc.max ? { max: value, index: index } : acc;
        },
        { max: answers[0], index: 0 }
      );
      freqAns = question.options[result.index];

      questionData.push(
        ...systmeticData,
        [],
        [`Total Response`, totalResponses],
        [],
        ["Most Frequent Response", freqAns]
      );
    }

    const wsOverview = XLSX.utils.aoa_to_sheet(questionData);
    XLSX.utils.book_append_sheet(wb, wsOverview, "Overview");
    XLSX.writeFile(wb, "survey_data.xlsx");
  };

  const MCQCOLORS = [
    "#4EAC8C",
    "#66ACFF",
    "#B09FFE",
    "#D679F7",
    "#FEB572",
    "#FFD572",
  ];

  return (
    <Drawer
      anchor={"right"}
      open={props.isSurveyStatsOpen}
      onClose={() => {
        props.handleSurveyStatsOpen();
      }}
      onClick={() => setOpen(false)}
      PaperProps={{
        sx: {
          minWidth: 1080,
          maxWidth: "90%",
          width: 1080,
          borderRadius: "5px 0px 0px 5px !important",
        },
      }}
    >
      <div className="ml-140 mr-52 mt-40 d-flex">
        <div className="flex-1">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <p className="font_24_400 line_height_10">{surveyName}</p>
              {participants.filter((currUser) => currUser.submitted).length ===
              participants.length ? (
                <div className="w-75px center border_radius_10 color_bg_d4 ml-32" style={{height: 28}}>
                  <p className="font_13_400">
                    Complete
                  </p>
                </div>
              ) : (
                <div className="w-75px center border_radius_10 bg_light_yellow ml-32" style={{height: 28}}>
                  <p className="font_13_400 ">
                    Pending
                  </p>
                </div>
              )}
            </div>

            <div className="d-flex align-items-center">
              <div
                className="position-relative"
                onClick={(e) => e.stopPropagation()}
              >
                <p
                  className="font_13_600 color_black mr-30 pointer"
                  onClick={() => setOpen(!open)}
                >
                  {participants.filter((currUser) => currUser.submitted).length}
                  /{participants.length} Responses
                </p>
                {open && (
                  <div
                    className="suvey_partcipants_remind_modal"
                    onClick={(e) => e.stopPropagation()}
                  >
                    {participants.map((currUser) => (
                      <div
                        key={currUser._id}
                        className="survey_partcipants_remind_body"
                      >
                        <div className="d-flex align-items-center">
                          {currUser.submitted ? (
                            <div
                              className="d-flex justify-content-center align-items-center br_round bg_green_07 mr-10"
                              style={{ width: 21, height: 21 }}
                            >
                              <TickMark cc={"#ffffff"} />
                            </div>
                          ) : (
                            <div
                              className="d-flex justify-content-center align-items-center br_round mr-10 border_1_ef"
                              style={{ width: 21, height: 21 }}
                            >
                              <Close
                                sx={{ height: 11, widows: 11 }}
                                htmlColor="#F4635A"
                              />
                            </div>
                          )}
                          <p className="font_13_400 color_72">
                            {currUser.firstName} {currUser.lastName}
                          </p>
                        </div>
                        {!currUser.submitted && (
                          <div className="">
                            {currUser.reminded ? (
                              <ButtonComp status={5} className="p-10x-4y">
                                <Done
                                  htmlColor="#ffffff"
                                  style={{ marginRight: "3px" }}
                                />{" "}
                                Reminded
                              </ButtonComp>
                            ) : (
                              <ButtonComp
                                onClick={() => {
                                  sendReminderToUser(currUser._id);
                                }}
                                status={7}
                                className="p-10x-4y"
                              >
                                Remind Again
                              </ButtonComp>
                            )}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* <div className="mr-30">
                <i className="bi bi-download"></i>
              </div> */}
              <div className="">
                <IconButton
                  id="basic-button"
                  aria-controls={surveyOptionOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={surveyOptionOpen ? "true" : undefined}
                  onClick={handleSurveyOptionClick}
                >
                  <MoreVert />
                </IconButton>

                <Menu
                  id="basic-menu"
                  anchorEl={surveyOptionAnchorEl}
                  open={surveyOptionOpen}
                  onClose={handleSurveyOptionClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  className="h-245px overflow_y zIndex-100"
                >
                  <MenuItem onClick={handleDownload}>
                    <i className="bi bi-download color_72"></i>{" "}
                    <span className="font_13_500 color_72 ml-10">Download</span>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      props.deleteSurvey(props.surveyId);
                      props.handleSurveyStatsOpen();
                    }}
                  >
                    <i className="bi bi-trash color_f4"></i>{" "}
                    <span className="font_13_500 color_f4 ml-10">Delete</span>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>

          <p className="font_12_400 color_65 mt-40">
            Participate in our survey to shape a greener workplace! Share your
            insights, ideas, and suggestions for a more sustainable future. Your
            input drives our commitment to reducing our ecological footprint.
            Join us in creating a better tomorrow for our company and the
            planet.
          </p>

          <div className="mt-40 mb-30">
            {answers.map((currAns, index) => {
              return questions[index].type == 0 ? (
                <div className="border-d7 border_radius_5 pt-24  mt-15">
                  <div className="d-flex justify-content-between gap-4 pl-30">
                    <div>
                      <p className="font_13_600 underline-e5">
                        {index + 1}. {questions[index].question}
                      </p>

                      <p className="font_9_500 color_ba mt-5px">
                        Multiple Choice
                      </p>
                    </div>

                    <div
                      className="mr-20 pointer"
                      onClick={() =>
                        handleSingleChartDownload(questions[index], currAns)
                      }
                    >
                      <i className="bi bi-download"></i>
                    </div>
                  </div>

                  <div className="d-flex mb-20">
                    <div>
                      <SurveyPieChart data={currAns} />
                    </div>
                    <div className="d-flex flex-column mt-40 ml-60">
                      {questions[index].options.map(
                        (currOption, optionIndex) => {
                          return (
                            <div
                              key={optionIndex + index + currOption}
                              className={`d-flex ${
                                optionIndex != 0 ? "mt-10" : ""
                              }`}
                            >
                              <p
                                className={`span_cirlce h-14px w-14px`}
                                style={{
                                  backgroundColor: MCQCOLORS[optionIndex],
                                }}
                              ></p>
                              <p className="font_12_500 color_4c ml-10">
                                {currOption}
                                <span className="font_9_500">
                                  ({currAns[optionIndex].count})
                                </span>
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                </div>
              ) : questions[index].type == 1 ? (
                <div className="border-d7 border_radius_5 pt-24 pl-30 mt-15">
                  <div className="d-flex justify-content-between gap-4">
                    <div>
                      <p className="font_13_600 underline-e5">
                        {index + 1}. {questions[index].question}
                      </p>

                      <p className="font_9_500 color_ba mt-5px">
                        Open Ended Question
                      </p>
                    </div>

                    <div
                      className="mr-20 pointer"
                      onClick={() =>
                        handleSingleChartDownload(questions[index], currAns)
                      }
                    >
                      <i className="bi bi-download"></i>
                    </div>
                  </div>

                  <div
                    className="mb-30 mt-30 mr-20 d-flex flex-column"
                    style={{ gap: 6 }}
                  >
                    {currAns.map((currOption, optionIndex) => {
                      return (
                        <div
                          key={currOption + optionIndex}
                          className={"px-10 py-12 bg_f8f9fa border_radius_5 "}
                        >
                          <p
                            className="font_11_400"
                            style={{ color: "rgba(32, 33, 36, 0.79)" }}
                          >
                            {currOption}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : questions[index].type == 2 ? (
                <div className="border-d7 border_radius_5 pt-24 mt-15">
                  <div className="d-flex justify-content-between gap-4 pl-30">
                    <div>
                      <p className="font_13_600 underline-e5">
                        {index + 1}. {questions[index].question}
                      </p>

                      <p className="font_9_500 color_ba mt-5px">Rating Scale</p>
                    </div>

                    <div
                      className="mr-20 pointer"
                      onClick={() =>
                        handleSingleChartDownload(questions[index], currAns)
                      }
                    >
                      <i className="bi bi-download"></i>
                    </div>
                  </div>

                  <div className="d-flex mb-20 w-100 mt-16">
                    <SurveyBarChart data={currAns} />
                  </div>
                </div>
              ) : questions[index].type == 3 ? (
                <div className="border-d7 border_radius_5 pt-24 mt-15">
                  <div className="d-flex justify-content-between gap-4 pl-30">
                    <div>
                      <p className="font_13_600 underline-e5">
                        {index + 1}. {questions[index].question}
                      </p>

                      <p className="font_9_500 color_ba mt-5px">Likert Scale</p>
                    </div>

                    <div
                      className="mr-20 pointer"
                      onClick={() =>
                        handleSingleChartDownload(questions[index], currAns)
                      }
                    >
                      <i className="bi bi-download"></i>
                    </div>
                  </div>

                  <div className="d-flex mt-30 mb-20 w-100 pl-20 pr-118">
                    <LikertScaleChart
                      data={currAns}
                      options={questions[index].options}
                    />
                  </div>
                </div>
              ) : (
                ""
              );
            })}
          </div>
        </div>
        <div className="ml-52 pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            fill="none"
            viewBox="0 0 36 36"
            onClick={() => {
              props.handleSurveyStatsOpen();
            }}
          >
            <path
              stroke="#4C4C4C"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M27 9L9 27M9 9l18 18"
            ></path>
          </svg>
        </div>
      </div>
    </Drawer>
  );
}
const clickOutsideConfig = {
  handleClickOutside: () => SurveyStats.handleClickOutside,
};
export default onClickOutside(SurveyStats, clickOutsideConfig);
