import React from "react";

const ImagePlusIconUplaod = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="25"
      fill="none"
      viewBox="0 0 27 25"
    >
      <path
        fill="#313B3E"
        d="M4.972 24.81h16.314c2.7 0 4.894-2.194 4.894-4.894V5.234c0-2.7-2.195-4.894-4.894-4.894H4.972A4.898 4.898 0 00.078 5.234v14.682c0 2.7 2.194 4.894 4.894 4.894zM3.341 5.234c0-.902.73-1.631 1.631-1.631h16.314c.901 0 1.631.73 1.631 1.63v14.683c0 .591-.318 1.105-.79 1.391l-9.284-9.283a5.048 5.048 0 00-3.589-1.488 5.03 5.03 0 00-3.589 1.488L3.341 14.35V5.234zm0 13.728l4.629-4.629c.338-.339.807-.53 1.284-.53s.947.191 1.285.53l7.219 7.219H4.977a1.63 1.63 0 01-1.632-1.632v-.954l-.004-.005v.001z"
      ></path>
      <path
        fill="#313B3E"
        d="M15.076 9.418l1.35.673.673 1.35a.574.574 0 001.027 0l.673-1.35 1.35-.674a.574.574 0 000-1.027l-1.35-.673-.672-1.35a.574.574 0 00-1.028 0l-.673 1.35-1.35.673a.574.574 0 000 1.027v.001z"
      ></path>
    </svg>
  );
};

export default ImagePlusIconUplaod;
