import React from "react";
import "./textInputType.css";
import { TextField, InputAdornment } from "@mui/material";
function TextInputType(props) {
  return (
    <div className="textInputType_question_container">
      <TextField
        type={props.type == "email" ? "email" : "text"}
        InputProps={{
          startAdornment: props.searchIcon ? (
            <InputAdornment position="start">
              <i className="bi bi-search"></i>
            </InputAdornment>
          ) : (
            ""
          ),
        }}
        value={
          props.answerValue
            ? props.answerValue
            : props.answers != undefined && props.answerIndex != undefined
            ? props.answers[props.answerIndex]
            : ""
        }
        onChange={(e) => {
          props.handleAnswerChange(props.answerIndex, e.target.value);
        }}
        // className="mt-12"
        size="small"
        fullWidth
        sx={{
          "& .MuiInputBase-input": {
            fontFamily: "Inter",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "10px",
          },
        }}
      />
    </div>
  );
}

export default TextInputType;
