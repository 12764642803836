import React, { useEffect, useState } from "react";
import "./activationAccItem.css";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import ButtonComp from "../../../../../../../Assets/components/Button";
import AccItemAccordion from "./Components/AccItemAccordion";
import ItemData from "../../../../../LogDashboard/items-new.json";
import Axios from "../../../../../../../Api";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../Utils/Auth";
import BillingAndPayments from "./Components/BillingAndPayments";
import BuyCredits from "./Components/BuyCredits";
import toast from "react-hot-toast";
import { useMapStore } from "../../../../../../../Utils/CompanyMapStore";

function ActivationAccItem() {
  const { activeNodeData, getItemData } = useMapStore();
  const { setSessionExpire } = useAuth();
  const [creditOptions, setCreditOptions] = useState(0);
  // const [accItemCreditAccount, setAccItemCreditAccount] = useState({})
  // const [accItemDefaultCredits, setAccItemDefaultCredits] = useState({})
  const [cartDetails, setCartDetails] = useState({});
  const [userCart, setUserCart] = useState({});
  const [grandTotal, setGrandTotal] = useState(0);
  const [allAccountingItems, setAllAccountingItems] = useState([]);
  const [itemCreditCount, setItemCreditCount] = useState(0);
  useEffect(() => {
    getAccountingItemsDetails();
    getCart();
    getItemCreditCount();
  }, []);

  const handleUseCredit = async () => {
    await Axios.patch(`/credit/activate-credit/${activeNodeData._id}`)
      .then(() => {
        getItemData();
        toast.success("Credit activated successfully");
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const handleCreditOptionChange = (value) => {
    setCreditOptions(value);
  };
  const getAccountingItemsDetails = () => {
    let currDetails = ItemData.map((currData) => {
      return {
        name: currData.name,
        _id: currData._id,
        price: currData.price,
      };
    });
    setAllAccountingItems(currDetails);
  };
  const getCart = async () => {
    try {
      const response = await Axios.get("/cart/user-store-cart");
      if (response.data.success) {
        setCartDetails(response.data.data.items);
        setUserCart(response.data.data);
        setGrandTotal(response.data.data.grandTotal);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const handleQuantityChange = async (id, quantity) => {
    try {
      var response = await Axios.patch(
        `/cart/store-cart-update/${userCart._id}`,
        {
          itemId: id,
          quantity: quantity,
        }
      );
      if (response.data.success) {
        setUserCart(response.data.cart);
        setCartDetails(response.data.cart.items);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const getItemCreditCount = async () => {
    try {
      const response = await Axios.get(
        `/credit/iscredit/${activeNodeData.itemId}`
      );
      if (response.data.success) {
        setItemCreditCount(response.data.count);
      } else {
        setItemCreditCount(0);
      }
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };
  return (
    <div className="">
      <p className="font_16_600 color_7e mt-15">
        Activate your accounting item
      </p>

      <div className=" d-flex align-items-center mt-30">
        <p className="font_16_400 bg_ef border-ef border_radius_5 pl-10 pt-5px pb-5px pr-10">
          {" "}
          Credit Balance{" "}
          <span className="color_009 ml-10">{itemCreditCount}</span>
        </p>
      </div>

      {creditOptions < 3 ? (
        <div className="activationAccItem_option_cont ">
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
          >
            <div className="d-flex align-items-center border-ef border_radius_5 pl-20 pt-20 pb-20 mt-20">
              <FormControlLabel
                control={
                  <Radio
                    onChange={() => {
                      setCreditOptions(0);
                    }}
                    sx={{
                      color: "#00915B",
                      "&.Mui-checked": {
                        color: "#00915B",
                      },
                    }}
                  />
                }
                label=""
                value={1}
              />

              <p className="font_16_500 ml-20">Use Available Credits</p>
            </div>

            <div className="d-flex align-items-center border-ef border_radius_5 pl-20 pt-20 pb-20 mt-20">
              <FormControlLabel
                value={2}
                control={
                  <Radio
                    onChange={() => {
                      setCreditOptions(1);
                    }}
                    sx={{
                      color: "#00915B",
                      "&.Mui-checked": {
                        color: "#00915B",
                      },
                    }}
                  />
                }
                label=""
              />

              <p className="font_16_500 ml-20">Add More Credits</p>
            </div>
          </RadioGroup>

          <div className="activationAccItem_actionBtn_cont w-100 d-flex justify-content-between align-items-center mb-10">
            <div>
              <ButtonComp
                onClick={() => {
                  if (creditOptions == 0 && itemCreditCount > 0)
                    handleUseCredit();
                }}
                status={7}
                disabled={itemCreditCount === 0}
              >
                Activate
              </ButtonComp>
            </div>
            <div className="ml-50">
              <ButtonComp
                onClick={() => {
                  if (creditOptions == 1) setCreditOptions(3);
                }}
                disabled={itemCreditCount === 0 && creditOptions === 0}
                status={5}
              >
                Next
              </ButtonComp>
            </div>
          </div>
        </div>
      ) : creditOptions == 3 ? (
        <div>
          <div className="overflow_y h-600px mt-20">
            {allAccountingItems.map((currItem, itemIndex) => {
              return (
                <div
                  key={currItem}
                  className={`${itemIndex != 0 ? "mt-20" : ""} `}
                >
                  <AccItemAccordion
                    handleQuantityChange={handleQuantityChange}
                    cartDetails={cartDetails}
                    itemDetails={currItem}
                  />
                </div>
              );
            })}
          </div>

          <div className="d-flex justify-content-between align-items-center mt-60 pb-40">
            <div>
              <ButtonComp status={5}>Activate</ButtonComp>
            </div>

            <div className="d-flex justify-content-end align-items-center ">
              <ButtonComp
                onClick={() => {
                  setCreditOptions(1);
                }}
                status={7}
              >
                Back
              </ButtonComp>

              <div className="ml-20">
                <ButtonComp
                  status={5}
                  onClick={() => {
                    setCreditOptions(4);
                  }}
                >
                  Payment
                </ButtonComp>
              </div>
            </div>
          </div>
        </div>
      ) : creditOptions == 4 ? (
        <div>
          <BillingAndPayments
            activationStep={creditOptions}
            handleCreditOptionChange={handleCreditOptionChange}
          />
        </div>
      ) : creditOptions == 5 ? (
        <BuyCredits
          cartDetails={cartDetails}
          userCart={userCart}
          grandTotal={grandTotal}
        />
      ) : (
        ""
      )}
    </div>
  );
}

export default ActivationAccItem;
