import React from "react";

const KeyboardLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      fill="none"
      viewBox="0 0 8 14"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.633.82L.727 6.727l5.906 5.906"
      ></path>
    </svg>
  );
};

export default KeyboardLeftIcon;
