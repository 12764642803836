import React, { useEffect, useState } from "react";
import "./logTable.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import ActivateService from "../ActivateService";
import EmissionSources from "../EmissionSources";
import ZeroCredits from "../ZeroCredits";
import SuccessfullyActivated from "../SuccessfullyActivated";
import axios from "../../../../../Api/index";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import {
  Select,
  MenuItem,
  FormControl,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { getServiceIcon } from "../../../../../Utils/itemIcon";
import getOffsetStatus from "../../../../../Utils/getOffsetStatus";
// import InfoQuestion from "../../../../../Assets/icons/InfoToottip";
// import { Info } from "@mui/icons-material";
import ButtonComp from "../../../../../Assets/components/Button";
import Name from "../../../../../Assets/components/Empty/name";
import Years from "../../../../../Assets/components/Empty/years";
import Action from "../../../../../Assets/components/Empty/action";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
// import Text from "../../../../../Assets/components/Empty/text";
import ReactiveSubscription from "../../../../../Assets/components/ReactiveSubscription/ReactiveSubscription";
// import KeyboardForwardIcon from "../../../../../Assets/icons/KeyboardForwardIcon";

const HeadCell = styled(TableCell)(() => ({
  border: "1px solid #E6E6E6",
  borderTop: "none",
  borderLeft: "0",
  fontFamily: '"Inter", sans-serif',
  fontWeight: "600",
}));
const StyledTableCell = styled(TableCell)(() => ({
  fontFamily: '"Inter", sans-serif',
  fontSize: "14px",
  fontWeight: "400",
  border: "1px solid #E6E6E6",
}));

const StyledTableRow = styled(TableRow)(() => ({}));

const StyledHeadRow = styled(TableRow)(() => ({
  "&:last-child": {
    backgroundColor: "#F9FAFC",
  },
}));
const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
  },
}));
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: "13px",
    fontWeight: "500px",
    fontFamily: "Inter",
    color: "rgba(0, 0, 0, 0.87)",
    width: "160px",
    border: "1px solid #efefef",
    borderRadius: "4px",
  },
}));

function LogTable(props) {
  const { setSessionExpire, profile } = useAuth();
  const [logId, setLogId] = useState("");
  const [activateDrawer, setActivateDrawer] = useState(false);
  const [toggleEmissionDrawer, setToggleEmissionDrawer] = useState(false);
  const [toggleZeroCredit, setToggleZeroCredit] = useState(false);
  const [yearList, setYearList] = useState([]);
  const [YearStatus, setYearStatus] = useState([]);
  const [countYearStatus, setCountYearStatus] = useState([]);
  const [, setsubScopeCount] = useState([]);
  const [logStatusMonthBars, setLogStatusMonthBars] = useState([]);
  const handleActivateToggle = (id) => {
    setLogId(id);
    if (props.itemCreditCount > 0) setActivateDrawer(true);
    else setToggleZeroCredit(true);
  };

  useEffect(() => {
    let currYearList = [];
    for (let year = 2015; year <= new Date().getFullYear(); year++)
      currYearList.push(year);

    setYearList(currYearList.reverse());
  }, []);

  useEffect(() => {
    let temp = [];
    let tempCount = [];
    let subScopeTemp = [];
    props.tabInfo.forEach((row) => {
      let currBar = [];
      if (props && row && props.logYear >= row.year) {
        let currlogged = 0;
        let labels = [];
        if (row.matrixItems && row.matrixItems.length > 0) {
          for (let i = 0; i < row.matrixItems.length; i++) {
            labels.push(<div className="white_progress_bar ml-1"></div>);
          }
          subScopeTemp.push({ count: row.matrixItems.length, labels: labels });
        } else {
          for (let i = 0; i < 12; i++) {
            labels.push(<div className="white_progress_bar ml-1"></div>);
          }
          subScopeTemp.push({ count: 0, labels: labels });
        }

        let planMonthIndex = row.planMonths.findIndex(
          (currYear) => Object.keys(currYear)[0] === props.logYear.toString()
        );
        if (planMonthIndex !== -1) {
          row.planMonths[planMonthIndex][props.logYear].forEach((currMonth) => {
            if (currMonth.logged) currlogged++;
          });
          tempCount.push(currlogged);
          for (let i = 0; i < currlogged; i++) {
            currBar.push(
              <div className="flex-1 purple_progress_bar_round ml-1"></div>
            );
          }
          for (
            let i = 0;
            i <
            row.planMonths[planMonthIndex][props.logYear].length - currlogged;
            i++
          )
            currBar.push(
              <div className="flex-1 white_progress_bar ml-1"></div>
            );
        } else {
          for (let i = 0; i < 12; i++)
            currBar.push(
              <div className="flex-1 white_progress_bar ml-1"></div>
            );
        }
      } else {
        for (let i = 0; i < 12; i++)
          currBar.push(<div className="flex-1 white_progress_bar ml-1"></div>);
      }
      temp.push(currBar);
    });
    setCountYearStatus(tempCount);
    setYearStatus(temp);
    setsubScopeCount(subScopeTemp);
    getMonthStatusBars();
  }, [props.tabInfo]);

  const getMonthStatusBars = () => {
    if (!props.tabInfo) return;
    let tempBars = [];
    props.tabInfo.map((row) => {
      let currBars = [];
      let planMonthIndex = row.planMonths.findIndex(
        (currYear) => Object.keys(currYear)[0] === props.logYear.toString()
      );
      if (planMonthIndex === -1) {
        for (let currIndex = 0; currIndex < row.noOfScopes; currIndex++) {
          currBars.push(<div className="white_progress_bar ml-1"></div>);
        }
        tempBars.push(currBars);
        return;
      }
      let monthIndex = row.planMonths[planMonthIndex][props.logYear].findIndex(
        (obj) => obj.month === new Date().getMonth() + 1
      );
      let whiteNo = row.noOfScopes;
      let coloredNo = 0;

      if (monthIndex != -1) {
        let currFilledSubScope =
          row.planMonths[planMonthIndex][props.logYear][
            row.planMonths[planMonthIndex][props.logYear].findIndex(
              (obj) => obj.month === new Date().getMonth() + 1
            )
          ].filledSubScope;
        whiteNo -= currFilledSubScope ? currFilledSubScope : 0;
        let coloredScopes = currFilledSubScope;
        if (coloredScopes)
          coloredNo =
            row.planMonths[planMonthIndex][props.logYear][
              row.planMonths[planMonthIndex][props.logYear].findIndex(
                (obj) => obj.month === new Date().getMonth() + 1
              )
            ].filledSubScope;
      }

      for (let currIndex = 0; currIndex < coloredNo; currIndex++) {
        currBars.push(
          <div className="flex-grow-0 purple_progress_bar_round ml-1"></div>
        );
      }
      for (let currIndex = 0; currIndex < whiteNo; currIndex++) {
        currBars.push(<div className="white_progress_bar ml-1"></div>);
      }

      tempBars.push(currBars);
    });
    setLogStatusMonthBars(tempBars);
  };
  const handleToggleEmissionChange = (id) => {
    setLogId(id);
    setToggleEmissionDrawer(true);
  };

  const handleUseCredit = async () => {
    await axios
      .patch(`/credit/activate-credit/${logId}`)
      .then(() => {
        props.getItemData();
        setActivateDrawer(false);
        props.getItemCreditCount();
        toast.success("Credit activated successfully");
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const getIconName = (item) => {
    if (item.itemDetails.facilityType) return item.itemDetails.facilityType;
    else if (item.itemDetails.productType) {
      return item.itemDetails.productType;
    } else if (item.itemDetails.event) {
      return item.itemDetails.event;
    } else if (item.itemDetails.hotelType) {
      return item.itemDetails.hotelType;
    } else if (item.itemDetails.buildingType) {
      return item.itemDetails.buildingType;
    } else return item.accountingItemName;
  };

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "November",
    "October",
    "November",
    "December",
  ];
  const handleReactive = () => {};
  return (
    <div className="mt-24">
      <ActivateService
        toggleActivateDrawer={activateDrawer}
        setActivateDrawer={setActivateDrawer}
        itemCreditCount={props.itemCreditCount}
        logId={logId}
        itemName={props.itemName}
        handleUseCredit={handleUseCredit}
      />
      <EmissionSources
        toggleEmissionDrawer={toggleEmissionDrawer}
        setToggleEmissionDrawer={setToggleEmissionDrawer}
        getItemData={props.getItemData}
        itemName={props.itemName}
        tabInfo={props.tabInfo}
        logId={logId}
      />
      <ZeroCredits
        item_id={props.item_id}
        itemName={props.itemName}
        toggleZeroCredit={toggleZeroCredit}
        setToggleZeroCredit={setToggleZeroCredit}
        creditPrice={props.creditPrice}
        getItemCreditCount={props.getItemCreditCount}
        setActivateDrawer={setActivateDrawer}
      />

      <SuccessfullyActivated
        itemName={props.itemName}
        // toggleZeroCredit={toggleZeroCredit}
        // setToggleZeroCredit={setToggleZeroCredit}
      />

      <div className="mb-40">
        <ReactiveSubscription />
        <div className="d-flex justify-content-between  align-items-center">
          <p className="font_17_700 text_capital">Your accounting items</p>
          <div className="d-flex align-items-center justify-content between">
            <div className="mr-8">
              {/* <button
              className="secondary_button_variant_2 "
              onClick={() => {
                props.toggleServiceDrawer(!props.serviceDrawer);
              }}
            >
              Add {props.itemName}
            </button> */}
              <ButtonComp
                onClick={() => {
                  props.toggleServiceDrawer(!props.serviceDrawer);
                }}
                status={2}
              >
                {" "}
                Add {props.itemName}
              </ButtonComp>
            </div>
            <div>
              <FormControl
                sx={{ m: 1, marginRight: 0, minWidth: 120 }}
                size="small"
              >
                <Select
                  value={props.logYear}
                  onChange={(e) => {
                    props.setLogYear(e.target.value);
                  }}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {yearList.reverse().map((eachYear) => (
                    <MenuItem key={eachYear} value={eachYear}>
                      {eachYear}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </div>

        <div className="logTable_container">
          <TableContainer
            component={Paper}
            sx={{ minWidth: 640 }}
            className="logTable_container mr-50 mt-20"
          >
            <Table aria-label="simple table">
              <TableHead>
                <StyledHeadRow>
                  <HeadCell
                    component="th"
                    sx={{ borderLeft: "0", width: "20%" }}
                    className="text-uppercase"
                  >
                    Name
                  </HeadCell>
                  <HeadCell className="w-18 text-uppercase">
                    LOG STATUS ({monthNames[new Date().getMonth()]}) &nbsp;
                    <LightTooltip
                      title={
                        "This column shows the completion of your emissions across the selected subscopes for the current month."
                      }
                      placement="right-start"
                    >
                      {/* <InfoQuestion /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        fill="none"
                        viewBox="0 0 18 19"
                      >
                        <circle
                          cx="9.305"
                          cy="9.422"
                          r="8.102"
                          fill="#fff"
                          stroke="#EFEFEF"
                        ></circle>
                        <path
                          fill="#313B3E"
                          d="M8.347 13.422v-6h1.91v6h-1.91zm.957-6.7a.987.987 0 01-.691-.265.855.855 0 01-.29-.648.84.84 0 01.29-.641.979.979 0 01.691-.27c.271 0 .502.09.692.27a.84.84 0 01.289.64.855.855 0 01-.29.649.978.978 0 01-.69.266z"
                        ></path>
                      </svg>
                    </LightTooltip>
                  </HeadCell>
                  <HeadCell className="w-18 text-uppercase">
                    LOG STATUS ({props.logYear})&nbsp;
                    <LightTooltip
                      title={
                        "This column shows the completion of your emissions across all months."
                      }
                      placement="right-start"
                    >
                      {/* <InfoQuestion /> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        fill="none"
                        viewBox="0 0 18 19"
                      >
                        <circle
                          cx="9.305"
                          cy="9.422"
                          r="8.102"
                          fill="#fff"
                          stroke="#EFEFEF"
                        ></circle>
                        <path
                          fill="#313B3E"
                          d="M8.347 13.422v-6h1.91v6h-1.91zm.957-6.7a.987.987 0 01-.691-.265.855.855 0 01-.29-.648.84.84 0 01.29-.641.979.979 0 01.691-.27c.271 0 .502.09.692.27a.84.84 0 01.289.64.855.855 0 01-.29.649.978.978 0 01-.69.266z"
                        ></path>
                      </svg>
                    </LightTooltip>
                  </HeadCell>
                  <HeadCell className=" text-uppercase" sx={{ width: "16%" }}>
                    ACTION STATUS
                  </HeadCell>
                  <HeadCell
                    className="w-15"
                    sx={{ borderRight: "0" }}
                  ></HeadCell>
                </StyledHeadRow>
              </TableHead>
              <TableBody>
                {props.tabInfo?.length > 0 ? (
                  props.tabInfo.map((row, index) => (
                    <StyledTableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          borderBottom: 0,
                        },
                      }}
                    >
                      <StyledTableCell
                        className="border_left_0"
                        component="th"
                        scope="row"
                      >
                        {!row.isActivate ? (
                          <div
                            className="d-flex align-items-end pointer"
                            onClick={() => handleActivateToggle(row._id)}
                          >
                            <div>
                              <img
                                src={getServiceIcon(getIconName(row))}
                                className="icon_sizing"
                              />
                            </div>
                            <p className="body_text_m ml-6 text_overflow-1">
                              {/* {row.itemDetails.name
                                .split("")
                                .map((letter, letterIndex) => {
                                  return letterIndex < 25 ? letter : "";
                                })} */}
                              {/* {row.itemDetails.name.length >= 25 ? "..." : ""} */}
                              {row.itemDetails.name}
                            </p>
                          </div>
                        ) : row.noOfScopes > 0 ? (
                          <Link
                            to={`/dashboard?tab=3&step=1&logId=${
                              row._id
                            }&year=${row.createdAt.slice(
                              0,
                              4
                            )}&month=${row.createdAt.slice(5, 7)}`}
                          >
                            <div className="d-flex align-items-end pointer">
                              <div>
                                <img
                                  src={getServiceIcon(getIconName(row))}
                                  className="icon_sizing"
                                />
                              </div>
                              <p className="body_text_m ml-6 text_overflow-1">
                                {/* {row.itemDetails.name
                                  .split("")
                                  .map((letter, letterIndex) => {
                                    return letterIndex < 25 ? letter : "";
                                  })}
                                {row.itemDetails.name.length >= 25 ? "..." : ""} */}
                                {row.itemDetails.name}
                              </p>
                            </div>
                          </Link>
                        ) : (
                          <>
                            <div
                              className="d-flex align-items-end pointer"
                              onClick={() =>
                                handleToggleEmissionChange(row._id)
                              }
                            >
                              <div>
                                <img
                                  src={getServiceIcon(getIconName(row))}
                                  className="icon_sizing"
                                />
                              </div>
                              <p className="body_text_m ml-6 text_overflow-1">
                                {row.itemDetails.name}
                                {/* {row.itemDetails.name
                                  .split("")
                                  .map((letter, letterIndex) => {
                                    return letterIndex < 25 ? letter : "";
                                  })}
                                {row.itemDetails.name.length >= 25 ? "..." : ""} */}
                              </p>
                            </div>
                          </>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* {row.month} */}
                        {logStatusMonthBars[index] && row.noOfScopes > 0 ? (
                          <div className="d-flex align-items-center justify-content-center">
                            <div className="flex-grow-1 d-flex align-items-center">
                              {logStatusMonthBars[index] &&
                                logStatusMonthBars[index].map((curr) => curr)}
                            </div>
                            <div className="ml-8">
                              <p className="purple_label f-13 fw-600">
                                {row.planMonths.findIndex(
                                  (currYear) =>
                                    Object.keys(currYear)[0] ===
                                    props.logYear.toString()
                                ) != -1 &&
                                row.planMonths[
                                  row.planMonths.findIndex(
                                    (currYear) =>
                                      Object.keys(currYear)[0] ===
                                      props.logYear.toString()
                                  )
                                ][props.logYear].findIndex(
                                  (obj) =>
                                    obj.month === new Date().getMonth() + 1
                                ) != -1 &&
                                row.planMonths[
                                  row.planMonths.findIndex(
                                    (currYear) =>
                                      Object.keys(currYear)[0] ===
                                      props.logYear.toString()
                                  )
                                ][props.logYear][
                                  row.planMonths[
                                    row.planMonths.findIndex(
                                      (currYear) =>
                                        Object.keys(currYear)[0] ===
                                        props.logYear.toString()
                                    )
                                  ][props.logYear].findIndex(
                                    (obj) =>
                                      obj.month === new Date().getMonth() + 1
                                  )
                                ].filledSubScope
                                  ? row.planMonths[
                                      row.planMonths.findIndex(
                                        (currYear) =>
                                          Object.keys(currYear)[0] ===
                                          props.logYear.toString()
                                      )
                                    ][props.logYear][
                                      row.planMonths[
                                        row.planMonths.findIndex(
                                          (currYear) =>
                                            Object.keys(currYear)[0] ===
                                            props.logYear.toString()
                                        )
                                      ][props.logYear].findIndex(
                                        (obj) =>
                                          obj.month ===
                                          new Date().getMonth() + 1
                                      )
                                    ].filledSubScope
                                  : 0}
                                /{row.noOfScopes}
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex align-items-center">
                            <span className="col-9">
                              {/* <Text /> */}
                              <div className="white_progress_bar ml-1"></div>
                            </span>
                            <span className="col-3">
                              <p className="purple_label f-13 fw-600">
                                &nbsp;&nbsp;-&nbsp;&nbsp;
                              </p>
                            </span>
                          </div>
                        )}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {/* {row.year_log} */}
                        <div className="d-flex align-items-center ">
                          <div className="flex-grow-1 d-flex align-items-center">
                            {YearStatus[index] &&
                              YearStatus[index].map((curr) => curr)}
                          </div>
                          <div className="ml-8">
                            <p className="purple_label f-13 fw-600">
                              {countYearStatus[index] || 0}/
                              {(YearStatus[index] &&
                                YearStatus[index].length) ||
                                12}
                            </p>
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <div className="d-flex justify-content-around align-items-center">
                          <BootstrapTooltip
                            title={
                              <div
                                className={`d-flex align-items-center f-13 ${
                                  getOffsetStatus(row).class
                                }`}
                              >
                                <img
                                  src={getServiceIcon("offset status")}
                                  className="h-20px mr-4"
                                />
                                <span>{getOffsetStatus(row).title}</span>
                              </div>
                            }
                            placement="bottom"
                          >
                            <img
                              src={getServiceIcon("offset status")}
                              className={`${
                                getOffsetStatus(row).class
                              } p-4x-10y h-25px`}
                            />
                          </BootstrapTooltip>
                          &nbsp; &nbsp;
                          <BootstrapTooltip
                            title={
                              <div className="d-flex align-items-center green_label">
                                <img
                                  src={getServiceIcon("reporting status")}
                                  className="h-20px mr-4"
                                />
                                <span>Launching in December</span>
                              </div>
                            }
                            placement="bottom"
                          >
                            <img
                              src={getServiceIcon("reporting status")}
                              className="green_label p-4x-10y h-25px"
                            />
                          </BootstrapTooltip>
                          &nbsp; &nbsp;
                          <BootstrapTooltip
                            title={
                              <div className="d-flex align-items-center green_label">
                                <img
                                  src={getServiceIcon("target status")}
                                  className="h-20px mr-4"
                                />
                                <span>Launching in December</span>
                              </div>
                            }
                            placement="bottom"
                          >
                            <img
                              src={getServiceIcon("target status")}
                              className="green_label p-4x-10y h-25px"
                            />
                          </BootstrapTooltip>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        sx={{ borderRight: "none" }}
                        className="border_right_0"
                      >
                        {!row.isActivate ? (
                          // <button
                          //   className="primary_button_md"
                          //   onClick={() => handleActivateToggle(row._id)}
                          // >
                          //   Activate
                          // </button>
                          <ButtonComp
                            onClick={() => {
                              if (row.isPaused || !profile.hasBaseCredit)
                                handleReactive();
                              else handleActivateToggle(row._id);
                            }}
                            status={1}
                            width="lg"
                          >
                            Activate
                          </ButtonComp>
                        ) : row.noOfScopes > 0 ? (
                          <Link
                            to={`/dashboard?tab=3&step=1&logId=${
                              row._id
                            }&year=${row.createdAt.slice(
                              0,
                              4
                            )}&month=${row.createdAt.slice(5, 7)}`}
                          >
                            {/* <button className="tertiary_button_variant_1">
                            
                            </button> */}
                            <ButtonComp
                              onClick={() => handleActivateToggle(row._id)}
                              status={3}
                              width="lg"
                              // className={(!profile.hasBaseCredit || row.isPaused) && "text-black"}
                            >
                              {row.isPaused || !profile.hasBaseCredit
                                ? "View"
                                : "Add logs"}
                              {/* {profile.hasBaseCredit || !row.isPaused
                                ? "Add logs"
                                : "View"} */}
                            </ButtonComp>
                          </Link>
                        ) : (
                          <>
                            {/* <button
                              className="tertiary_button_variant_1"
                              onClick={() =>
                                handleToggleEmissionChange(row._id)
                              }
                            >
                              Complete Now
                            </button> */}
                            <ButtonComp
                              onClick={() =>
                                handleToggleEmissionChange(row._id)
                              }
                              status={3}
                              width="lg"
                            >
                              Complete Now
                            </ButtonComp>
                          </>
                        )}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                ) : (
                  <StyledTableRow
                    sx={{
                      "&:last-child td, &:last-child th": { borderBottom: 0 },
                    }}
                  >
                    <StyledTableCell
                      className="border_left_0"
                      component="th"
                      scope="row"
                    >
                      <Name />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Years />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Years />
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Action />
                    </StyledTableCell>
                    <StyledTableCell
                      align="center"
                      sx={{ borderRight: "none" }}
                      className="border_right_0"
                    >
                      <ButtonComp status={3} disabled={true} width="lg">
                        Add log
                      </ButtonComp>
                    </StyledTableCell>
                  </StyledTableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="d-flex justify-content-end align-items-center mt-30">
            <img src={getServiceIcon("offset status")} className="h-10px" />{" "}
            <span className="overline_text ml-6">Contribution Status</span>
            <img
              src={getServiceIcon("reporting status")}
              className="h-10px ml-32"
            />{" "}
            <span className="overline_text ml-6">Reporting Status</span>
            <img
              src={getServiceIcon("target status")}
              className="h-10px ml-32"
            />{" "}
            <span className="overline_text ml-6">Target Status</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LogTable;
