import React, { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import "./Register.css";
import axios from "../../Api/index";
import Address from "./Questions/address";
import Questions from "./Questions";
import MultipleChoice from "./Questions/multipleChoice";
import { toast } from "react-hot-toast";
import { useAuth } from "../../Utils/Auth";
import Select from "./Questions/select";
import NumberQues from "./Questions/number";
import { useNavigate } from "react-router";
import NotFoundPage from "../../Pages/notFoundPage";
// import bg from "../../Assets/images/bg.jpg";
import { AnimationScreens, AnimationVideos } from "../../Utils/CommonFunctions";
// import bg from "../../Assets/GIF/Background.gif";
// import boat from "../../Assets/GIF/Top Layer GIF.gif";
// import car from "../../Assets/GIF/car_top.gif";

function Register() {
  // const { id } = useParams();
  const history = useNavigate();
  const { profile, user } = useAuth();
  const [questions] = useState([
    {
      question: "What's your company name?",
      fields: { type: "text", placeholder: "Company Name" },
    },
    {
      question: "Company Details",
      fields: [
        { type: "text", placeholder: "Street" },
        { type: "text", placeholder: "Number" },
        { type: "text", placeholder: "Zip" },
        { type: "text", placeholder: "Country" },
        { type: "text", placeholder: "State" },
        { type: "text", placeholder: "City" },
      ],
    },
    {
      question: "Company Website",
      fields: { type: "text", placeholder: "www.company.com" },
    },
    {
      question: "What's your industry?",
      fields: { type: "text", placeholder: "Industry" },
    },
    {
      question: "How many facilities does your Company rent/own",
      fields: { type: "number", placeholder: "0" },
    },
    {
      question: "How many employees do you have?",
      fields: {
        type: "multiple",
        options: ["1-10", "11-50", "51-250", "250+"],
      },
    },
  ]);
  const [currQuestion, setCurrQuestion] = useState(0);
  const [regDetails, setRegDetails] = useState({
    currQuestion: 0,
    companyName: "",
    street: "",
    streetNumber: "",
    state: "",
    city: "",
    zip: "",
    country: "",
    website: "",
    industry: "",
    facilities: 0,
    empCount: "",
    countryCode: "",
    stateCode: "",
  });

  const [videos] = useState([
    AnimationVideos[1],
    AnimationVideos[2],
    AnimationVideos[3],
    AnimationVideos[4],
    AnimationVideos[5],
    AnimationVideos[6],
  ]);
  const [screens] = useState([
    AnimationScreens[1],
    AnimationScreens[2],
    AnimationScreens[3],
    AnimationScreens[4],
    AnimationScreens[5],
    AnimationScreens[6],
  ]);

  // const [videoLoaded, setVideoLoaded] = useState(false);

  // useEffect(() => {
  //   const video = document.createElement("video");
  //   video.src = videos[currQuestion];

  //   video.addEventListener("canplaythrough", () => {
  //     setVideoLoaded(true);
  //   });

  //   video.addEventListener("error", () => {
  //     console.error("Error loading video:", videos[currQuestion]);
  //   });

  //   return () => {
  //     // Cleanup event listeners and remove the video element
  //     video.removeEventListener("canplaythrough", () => {
  //       setVideoLoaded(true);
  //     });
  //     video.removeEventListener("error", () => {
  //       console.error("Error loading video:", videos[currQuestion]);
  //     });
  //     video.remove();
  //   };
  // }, [currQuestion, videos]);

  // const [videosLoaded, setVideosLoaded] = useState([
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  //   false,
  // ]);

  // useEffect(() => {
  //   // Function to load a video
  //   const loadVideo = (videoIndex) => {
  //     const video =  document.createElement('video');
  //     video.preload = "auto";
  //     video.src = videos[videoIndex];
  //     video.load();

  //     video.addEventListener("loadeddata", () => {
  //       const newVideosLoaded = [...videosLoaded];
  //       newVideosLoaded[videoIndex] = true;
  //       setVideosLoaded(newVideosLoaded);
  //     });
  //   };

  //   // Load all videos simultaneously
  //   videos.forEach((_, index) => {
  //     loadVideo(index);
  //   });
  // }, [videos, videosLoaded]);

  // useEffect(() => {
  //   // Function to play the next video
  //   const playNextVideo = () => {
  //     const nextVideo = (currQuestion + 1) % videos.length;
  //     setcurrQuestion(nextVideo);
  //   };

  //   // Play the next video when the current one ends
  //   const video = document.getElementById('video-background');
  //   video.addEventListener('ended', playNextVideo);

  //   // Cleanup event listener on component unmount
  //   return () => {
  //     video.removeEventListener('ended', playNextVideo);
  //   };
  // }, [currQuestion, videos]);

  // reduce it : make object make only one object

  useEffect(() => {
    if (profile) {
      if (profile.companyName)
        setRegDetails((prev) => ({
          ...prev,
          companyName: profile.companyName,
        }));
      if (profile.street)
        setRegDetails((prev) => ({
          ...prev,
          street: profile.street,
        }));
      if (profile.streetNumber)
        setRegDetails((prev) => ({
          ...prev,
          streetNumber: profile.streetNumber,
        }));
      if (profile.city)
        setRegDetails((prev) => ({
          ...prev,
          city: profile.city,
        }));
      if (profile.empCount)
        setRegDetails((prev) => ({
          ...prev,
          empCount: profile.empCount,
        }));
      if (profile.zip)
        setRegDetails((prev) => ({
          ...prev,
          zip: profile.zip,
        }));
      if (profile.country)
        setRegDetails((prev) => ({
          ...prev,
          country: profile.country,
        }));
      if (profile.state)
        setRegDetails((prev) => ({
          ...prev,
          state: profile.state,
        }));
      if (profile.website)
        setRegDetails((prev) => ({
          ...prev,
          website: profile.website,
        }));
      if (profile.industry)
        setRegDetails((prev) => ({
          ...prev,
          industry: profile.industry,
        }));
      if (profile.facilities)
        setRegDetails((prev) => ({
          ...prev,
          facilities: profile.facilities,
        }));
      if (profile.countryCode)
        setRegDetails((prev) => ({
          ...prev,
          countryCode: profile.countryCode,
        }));
      if (profile.stateCode)
        setRegDetails((prev) => ({
          ...prev,
          stateCode: profile.stateCode,
        }));
    }
  }, [profile]);

  const changeOption = (name, count) => {
    setRegDetails({
      ...regDetails,
      [name]: count,
    });
  };
  const incQuestionNo = (...args) => {
    let val_empty = false;
    for (let index = 0; index < args.length; index++) {
      if (typeof args[index] === "string" && args[index].length == "") {
        val_empty = true;
        break;
      } else if (args[index] === undefined) {
        val_empty = true;
        break;
      }
    }
    if (val_empty) {
      toast.error("Empty fields");
      return;
    }
    // handleSubmit();
    // setVideoLoaded(false);
    if (currQuestion < questions.length - 1) setCurrQuestion(currQuestion + 1);
  };
  const decQuestionNo = () => {
    // let val_empty = false;
    // for (let index = 0; index < args.length; index++) {
    //   if (typeof args[index] === "string" && args[index].length == "") {
    //     val_empty = true;
    //     break;
    //   } else if (args[index] === undefined) {
    //     val_empty = true;
    //     break;
    //   }
    // }
    // if (val_empty) {
    //   toast.error("Empty fields");
    //   return;
    // }
    // handleSubmit();
    if (currQuestion > 0) setCurrQuestion(currQuestion - 1);
  };
  const handleChange = (e, type) => {
    const { name, value } = e.target;
    if (type === "number") {
      if (/^\d+$/.test(value)) {
        setRegDetails({
          ...regDetails,
          [name]: parseInt(value),
        });
      } else if (value.length === 0) {
        setRegDetails({
          ...regDetails,
          [name]: "",
        });
      }
    } else {
      setRegDetails({
        ...regDetails,
        [name]: e.target.value,
      });
    }
  };
  const handleSubmit = async (steps) => {
    try {
      // const userId = "";
      const response = await axios.patch(
        `/company/edit-company?question=${currQuestion + 1}`, // find one and update for patch
        { regDetails }
      );
      if (response.data.success) {
        // setVideoLoaded(false);
        if (currQuestion === 5 && user) {
          toast.success("Company details submitted!");
          history(`/register/avatar?step=0`);
        } else if (steps) incQuestionNo(steps);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  if (user)
    return (
      <div>
        <div className="container-fluid">
          <div className="row auth_background">
            <div
              className="d-flex flex-column col-md-6 justify-content-center align-items-center bg_left_onboard_img position-relative p-0"
              style={{
                backgroundImage: `url(${AnimationScreens[currQuestion]})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              {/* {videoLoaded ? ( */}
              <video
                className="reg_vdo"
                src={videos[currQuestion]}
                autoPlay
                playsInline
                loop
                preload="auto"
                muted
                controls={false}
                poster={screens[currQuestion]}
              ></video>
              {/* ) : (
                <img
                  src={screens[currQuestion]}
                  alt="video-thumn"
                  className="reg_vdo_img"
                />
              )} */}
              <div hidden>
                {videos.map((v, i) => (
                  <video
                    key={i}
                    className="reg_vdo"
                    src={v}
                    autoPlay
                    playsInline
                    loop
                    preload="auto"
                    muted
                  ></video>
                ))}
              </div>
              {/* {currQuestion === 0 ? (
                <video
                  className="reg_vdo"
                  src="https://tg-icons.s3.eu-central-1.amazonaws.com/Animations/step_1.mp4"
                  autoPlay
                  loop
                  preload="auto"
                  muted
                ></video>
              )} */}
            </div>
            <div className="col-md-6 d-flex align-items-center register_info_container">
              {currQuestion === 0 ? (
                <Questions
                  question={questions[0]}
                  currQuestion={currQuestion}
                  incQuestionNo={incQuestionNo}
                  decQuestionNo={decQuestionNo}
                  regDetails={regDetails}
                  setRegDetails={setRegDetails}
                  target="companyName"
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              ) : currQuestion === 1 ? (
                <Address
                  question={questions[1]}
                  currQuestion={currQuestion}
                  incQuestionNo={incQuestionNo}
                  decQuestionNo={decQuestionNo}
                  regDetails={regDetails}
                  setRegDetails={setRegDetails}
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              ) : currQuestion === 2 ? (
                <Questions
                  question={questions[2]}
                  currQuestion={currQuestion}
                  incQuestionNo={incQuestionNo}
                  decQuestionNo={decQuestionNo}
                  regDetails={regDetails}
                  setRegDetails={setRegDetails}
                  target="website"
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              ) : currQuestion === 3 ? (
                <Select
                  question={questions[3]}
                  currQuestion={currQuestion}
                  incQuestionNo={incQuestionNo}
                  decQuestionNo={decQuestionNo}
                  regDetails={regDetails}
                  setRegDetails={setRegDetails}
                  target="industry"
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              ) : currQuestion === 4 ? (
                <NumberQues
                  question={questions[4]}
                  currQuestion={currQuestion}
                  incQuestionNo={incQuestionNo}
                  decQuestionNo={decQuestionNo}
                  regDetails={regDetails}
                  setRegDetails={setRegDetails}
                  target="facilities"
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              ) : currQuestion === 5 ? (
                <MultipleChoice
                  question={questions[5]}
                  currQuestion={currQuestion}
                  changeOption={changeOption}
                  incQuestionNo={incQuestionNo}
                  decQuestionNo={decQuestionNo}
                  regDetails={regDetails}
                  setRegDetails={setRegDetails}
                  target="empCount"
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  else {
    <NotFoundPage />;
  }
}

export default Register;
