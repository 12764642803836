import React, { useState } from "react";
import {
  StyledTableCellWithoutBorder,
  StyledTableRow,
} from "../../../../../../../Utils/TableComp";
import { getServiceIcon } from "../../../../../../../Utils/itemIcon";
import { IconButton, Popover } from "@mui/material";
import { Add, MoreVert } from "@mui/icons-material";
import { localNumber } from "../../../../../../../Utils/CommonFunctions";

const SubClassRow = ({
  subclassData,
  allTasks,
  handleDelete,
  handleEdit,
  handleOpenTask,
  dataSubClasses,
  getYtd,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const currentEmission = dataSubClasses.find(
    (data) => Object.keys(data)[0] === subclassData.subClass
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <React.Fragment>
      <StyledTableRow
        sx={{
          "& > *": { borderBottom: "1px solid #efefef" },
          backgroundColor: "#fbfbfb",
        }}
      >
        <StyledTableCellWithoutBorder
          capitalize
          textleft
          component="th"
          scope="row"
          sx={{ width: 350 }}
        >
          <div className="align_center gap_10">
            <img
              className="w-30px h-30px border_radius_5"
              src={getServiceIcon(subclassData.subClass)}
              alt={subclassData.subClass}
            />
            <p>{subclassData.subClass}</p>
          </div>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 120 }}>
          <div className="align_center gap_10">
            {allTasks &&
            allTasks.length > 0 &&
            allTasks.filter(
              (task) =>
                task?.subclass?.subClass.toLowerCase() ===
                subclassData.subClass.toLowerCase()
            ).length ? (
              <div className="circle_30 bg_soft_green center">
                <p className="font_14_600 color_green">
                  {
                    allTasks.filter(
                      (task) =>
                        task?.subclass?.subClass.toLowerCase() ===
                        subclassData.subClass.toLowerCase()
                    ).length
                  }
                </p>
              </div>
            ) : null}

            <div
              className="circle_30 bg_f6 center pointer"
              style={{ border: "2px solid #efefef" }}
              onClick={handleOpenTask}
            >
              <Add htmlColor="#00915B" fontSize="small" />
            </div>
          </div>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 235 }}>
          {localNumber(subclassData.subClassEmission)}{" "}
          <span className="color_7e">tCO2e</span>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 235 }}>
          {localNumber(subclassData.subClassEmissionReduced)}{" "}
          <span className="color_7e">tCO2e</span>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder textleft sx={{ width: 235 }}>
          {localNumber(
            getYtd(
              currentEmission[subclassData.subClass].baseYearEmission,
              currentEmission[subclassData.subClass].currentYearEmission
            )
          )}{" "}
          <span className="color_7e">tCO2e</span>
        </StyledTableCellWithoutBorder>
        <StyledTableCellWithoutBorder
          className="justify_end"
          sx={{ display: "flex" }}
        >
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>
        </StyledTableCellWithoutBorder>
      </StyledTableRow>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <div className="basic-popover-root">
          <div className="basic-popover">
            <p
              className="font_14_500 color_red"
              onClick={() => {
                handleDelete();
                handleClose();
              }}
            >
              Delete
            </p>
          </div>
          <div
            className="basic-popover"
            onClick={() => {
              handleEdit();
              handleClose();
            }}
          >
            <p className="font_14_500">Edit</p>
          </div>
        </div>
      </Popover>
    </React.Fragment>
  );
};

export default SubClassRow;
