import React from "react";

const PencilSmallIcon = ({ cc }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      fill="none"
      viewBox="0 0 13 13"
    >
      <path
        stroke={cc || "#727E8F"}
        strokeWidth="1.5"
        d="M3.42 10.861l2.001-.725c.276-.1.526-.259.733-.466l5.139-5.139a1 1 0 000-1.414l-1.41-1.41a1 1 0 00-1.414 0L3.33 6.848a2 2 0 00-.466.733l-.725 2a1 1 0 001.281 1.281z"
      ></path>
      <path
        fill={cc || "#727E8F"}
        d="M12 3.824H13.997V7.817H12z"
        transform="rotate(135 12 3.824)"
      ></path>
    </svg>
  );
};

export default PencilSmallIcon;
