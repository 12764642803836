import React from "react";
import "../../logDashboard.css";
import Drawer from "@mui/material/Drawer";
import TickMark from "../../../../../Assets/icons/tickMark";
import ButtonComp from "../../../../../Assets/components/Button";
// import axios from "../../../../../Api/index";
// import { toast } from "react-hot-toast";

function ActivateService(props) {
  // const handleUseCredit = async () => {
  //   await axios
  //     .patch(`/credit/activate-credit/${props.logId}`)
  //     .then(() => {
  //       toast.success("Credit activated successfully");
  //     })
  //     .catch((err) => {
  //       toast.error("Error occurred ",err.message);
  //     });
  // };

  return (
    <div className="SideDrawer_width">
      <Drawer
        anchor={"right"}
        open={props.toggleActivateDrawer}
        onClose={() => props.setActivateDrawer(false)}
        PaperProps={{
          sx: { width: "70%" },
        }}
        BackdropProps={{ style: { backgroundColor: "white", opacity: 0 } }}
      >
        <div className="fluid-container drawer_root">
          <div className="credit_heading_container pb-60 ">
            <div className="align_text_right mt-20 pr-20">
              <i
                className="bi bi-x-lg SideDrawer_close_icon pointer"
                onClick={() => props.setActivateDrawer(false)}
              ></i>
            </div>
            <div className="pl-35 mt-10">
              <p className="f-27 fw-700 font-inter">
                Activate your {props.itemName}
              </p>
              <p className="subheading_light_2 mt-10">
                Set up new accounting {props.itemName}
              </p>
            </div>
          </div>

          <div className="row credit_card_container p-40 ml-40 mr-40 mt-30">
            <div className="col-lg-6 ">
              <div className="credit_items_center_md">
                <p className="f-40 fw-700 font-inter">Connect a Credit</p>
                <p className="body_text mt-16">
                  This credit was purchased by you and can now
                </p>
                <p className="body_text">
                  be connected to this {props.itemName} item, in order to
                </p>
                <p className="body_text">account and offset it.</p>
                <p className="purple_label fw-600 mt-16">
                  You have {props.itemCreditCount} available {props.itemName}{" "}
                  Credits
                </p>
              </div>
              <div className="mt-60 credit_items_center_md">
                {/* <button
                  onClick={() => {
                    props.handleUseCredit();
                  }}
                  className="primary_button_extra_lg"
                >
                  Use Credit
                </button> */}
                <ButtonComp
                  onClick={() => {
                    props.handleUseCredit();
                  }}
                  status={1}
                  size="xl"
                >
                  Use Credit
                </ButtonComp>
              </div>
            </div>
            <div className="col-lg-1"></div>
            <div className="col-lg-5 p-16 mt-10">
              <div className="credit_details ">
                <div className="credit_border_bottom p-4">
                  <p className="card_heading">New {props.itemName}</p>
                  {/* 
                  <p className="body_text_s_light mt-24">
                    Product can be anything from Consumables,
                  </p>
                  <p className="body_text_s_light">
                    Cosmetic,Toys,Clothing,Furniture,Lorem epsum
                  </p>
                  <p className="body_text_s_light">dolor sit emit,conset.</p> */}
                </div>

                <div className="p-4 f-13 fw-600 font-inter">
                  <div className="mt-2">
                    <TickMark /> Accounting for 1 year
                  </div>
                  <div className="mt-2">
                    <TickMark /> Carbon Neutral Label(excl. offset expences)
                  </div>
                  <div className="mt-2">
                    <TickMark /> Unlimited GHG Reports
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default ActivateService;
