import React from "react";
import { Tooltip, styled, tooltipClasses } from "@mui/material";
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    fontSize: "13px",
    fontWeight: "500px",
    fontFamily: "Inter",
    color: "rgba(0, 0, 0, 0.87)",
    width: "180px",
    border: "1px solid #efefef",
  },
}));
function CustomToolTip(props) {
  return props.title && props.title.length > 0 ? (
    <LightTooltip title={props.title} placement="right-start">
      <i className="bi bi-question ml-2 mb-2"></i>
    </LightTooltip>
  ) : (
    ""
  );
}

export default CustomToolTip;
