import React, { useState, useEffect } from "react";
import "./GhGTable.css";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import DeleteModal from "../../../../../../Assets/components/DeleteModal/DeleteModal";
import GhgTableRow from "./GhgTableRow";
//import { useAuth } from "../../../../../../Utils/Auth";
const HeadCell = styled(TableCell)(() => ({
  "&:first-of-type": {
    borderRight: "1px solid #E6E6E6",
  },
  "&:nth-of-type(2)": {
    borderRight: "1px solid #E6E6E6",
  },
  border: "1px solid #E6E6E6",
  borderTop: "0",
  fontFamily: '"Inter", sans-serif',
}));

const StyledHeadRow = styled(TableRow)(() => ({
  "&:last-child": {
    backgroundColor: "#F9FAFC",
  },
}));

function TablePaginationActions(props) {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };
  const gotoPage = (event, newPage) => {
    onPageChange(event, newPage);
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 1}
        aria-label="previous page"
        className="border_radius_8 p-4px"
      >
        <KeyboardArrowLeft />
      </IconButton>
      {page === props.count ? (
        <IconButton
          sx={{ fontSize: "13px", fontWeight: 400 }}
          onClick={(event) => gotoPage(event, page - 1)}
        >
          {page - 1}
        </IconButton>
      ) : (
        ""
      )}
      <IconButton
        sx={{ fontSize: "13px", fontWeight: 400, color: "black" }}
        onClick={(event) => gotoPage(event, page)}
      >
        {page}
      </IconButton>

      {page < props.count ? (
        <IconButton
          sx={{ fontSize: "13px", fontWeight: 400 }}
          onClick={(event) => gotoPage(event, page + 1)}
        >
          {page + 1}
        </IconButton>
      ) : (
        ""
      )}

      <IconButton>...</IconButton>
      <IconButton
        sx={{ fontSize: "13px", fontWeight: 400 }}
        onClick={(event) => gotoPage(event, props.count)}
      >
        {props.count}
      </IconButton>

      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        className="border_radius_8 p-4px"
      >
        <KeyboardArrowRight />
      </IconButton>
    </Box>
  );
}
function GHGReportTable(props) {
  const [page, setPage] = useState(1);
  const [rowPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    setTotalPages(props.totalGhgPages);
  }, [props.ghgTableData]);

  useEffect(() => {
    props.getTableData(page);
  }, [page]);

  const tableHeads = [
    "Name",
    "TYPE",
    "ACCOUNTING ITEM(s)",
    "TIME FRAME",
    "CREATED",
  ];

  const handleChangePage = (event, newPage) => {
    if (newPage <= 0 || newPage > totalPages) return;
    setPage(newPage);
  };

  return (
    <>
      <div className="matrix_table_root">
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <StyledHeadRow sx={{ fontWeight: "600" }}>
                {tableHeads.map((item, i) => {
                  return i == 0 ? (
                    <HeadCell
                      sx={{ borderLeft: "0", width: "20%", fontWeight: "600" }}
                      align="left"
                      key={i}
                      className="text_capital font_13_600"
                    >
                      {item}
                    </HeadCell>
                  ) : i == tableHeads.length - 1 ? (
                    <HeadCell
                      sx={{ borderRight: "0", fontWeight: "600" }}
                      align="left"
                      key={i}
                      className="text_capital font_13_600"
                    >
                      {item}
                    </HeadCell>
                  ) : (
                    <HeadCell
                      align="left"
                      key={i}
                      sx={{ fontWeight: "600" }}
                      className="text_capital font_13_600"
                    >
                      {item}
                    </HeadCell>
                  );
                })}
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {props.ghgTableData.map((currReport, index) => {
                return (
                  <GhgTableRow
                    key={currReport._id}
                    currReport={currReport}
                    cr={index}
                    getTableData={() => props.getTableData(page)}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="d-flex justify-content-end">
        <TablePaginationActions
          count={totalPages}
          rowsPerPage={rowPerPage}
          page={page}
          onPageChange={handleChangePage}
        />
      </div>
      <DeleteModal />
    </>
  );
}

export default GHGReportTable;
