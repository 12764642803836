import React, { useState, useEffect } from "react";
import { useAuth } from "../../../../../Utils/Auth";
import axios from "../../../../../Api";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import Loader from "../../../../../Assets/components/Loader";
import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { KeyboardArrowDown } from "@mui/icons-material";
import BankTransfer from "../../../../../Assets/images/BankTransfer.png";
import CreditCard from "../../../../../Assets/images/CreditCard.png";
import IDEAL from "../../../../../Assets/images/iDEAL.png";
import BankContact from "../../../../../Assets/images/BankContact.png";
import Sofort from "../../../../../Assets/images/Sofort.png";
import EditIcon from "../../../../../Assets/icons/EditIcon";
import Sepa from "../../../../../Assets/images/Sepa.png";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import BackForwardButton from "../../../../../Assets/components/BackForwardButton/BackForwardButton";
import {
  CreditCardsJson,
  CreditCardsNameJson,
} from "../../../../../Utils/CommonFunctions";
import ButtonComp from "../../../../../Assets/components/Button";
import { useTranslation } from "react-i18next";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  border: "none",
  borderBottom: "1px solid #E8EBEF",
  // "&:not(:last-child)": {
  //   borderBottom: 0,
  // },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<KeyboardArrowDown />} {...props} />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export default function StorePayment({
  userCart,
  isAcceptTerms,
  submitPayment,
  setsubmitPayment,
  userAddress,
  setUserAddress,
  renew_id,
}) {
  const { profile, user, setSessionExpire, getUser } = useAuth();
  const [paymentMethods, setPaymentMethods] = useState([
    { id: null, type: "bank_transfer" },
  ]);
  const [selectedPayment, setselectedPayment] = useState(null);
  const history = useNavigate();
  const [billingLoading, setBillingLoading] = useState(false);
  const [step, setStep] = useState(0);
  const [billingStep, setBillingStep] = useState(0);

  const [expanded, setExpanded] = React.useState("panel1");
  const [selectedPaymentInfo, setSelectedPaymentInfo] = useState(null);
  const [options, setoptions] = useState(null);
  const [selectBankTransfer, setSelectBankTransfer] = useState(false);

  const [firstname, setfirstname] = useState(user && user.firstname);
  const [lastName, setlastName] = useState(user && user.lastname);
  const [email, setemail] = useState(user && user.email);
  const [companyName, setcompanyName] = useState(
    profile && profile.companyName
  );
  const [street, setstreet] = useState(profile && profile.street);
  const [streetNumber, setstreetNumber] = useState(
    profile && profile.streetNumber
  );
  const [state, setstate] = useState(profile && profile.state);
  const [city, setcity] = useState(profile && profile.city);
  const [zip, setzip] = useState(profile && profile.zip);
  const [country, setcountry] = useState(profile && profile.country);
  const [vat, setVat] = useState("");
  const [allAddressess, setallAddressess] = useState(null);

  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [isDisabled, setisdisabled] = useState(false);

  const [countrycode, setcountrycode] = useState(
    profile && profile.countryCode
  );
  const [statecode, setstatecode] = useState(profile && profile.stateCode);

  const appearance = {
    theme: "stripe",
    rules: {
      ".Label": {
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "20px",
        textTransform: "uppercase",
        color: "#313B3E",
        marginBottom: "8px",
      },
    },
  };
  const handleExpandChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleCardIntent = async (method) => {
    setBillingLoading(true);
    try {
      const response = await axios({
        url: `/stripe/payment-intent`,
        method: "get",
        params: {
          methodType: [method],
        },
      });
      setoptions({
        clientSecret: response.data.data.client_secret,
        appearance: appearance,
      });
      // setSelectBankTransfer(false);
      setBillingLoading(false);
      setBillingStep(1);
    } catch (error) {
      setBillingLoading(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  const getAddress = async () => {
    try {
      const res = await axios({
        url: `/address/address`,
        method: "get",
      });

      if (res.data.success) {
        setallAddressess(res.data.data);
        setUserAddress(res.data.data[0]);
      }
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const getPaymentMethods = async () => {
    await axios({
      url: `/stripe/paymentMethod`,
      method: "GET",
    })
      .then((res) => {
        if (res.data.success) {
          const { data } = res.data.data;
          let parArr = paymentMethods;
          parArr = [...parArr, ...data];
          setPaymentMethods(parArr);
          setselectedPayment(data[0] ? data[0] : null);
        }
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  useEffect(() => {
    getAddress();
    getPaymentMethods();
  }, []);

  const handleUpdateFinalCart = async (invoice, paymentIntent) => {
    try {
      setBillingLoading(true);
      const res = await axios.post(
        `/cart/update-success-cart/${userCart._id}?renew=${renew_id}`,
        {
          invoice_id: invoice.id,
          invoice_url: invoice.hosted_invoice_url,
          invoice_pdf: invoice.invoice_pdf,
          paymentMethod: paymentIntent
            ? paymentIntent.payment_method
            : "bank_transfer",
          paymentIntent: paymentIntent ? paymentIntent.id : "",
          isPaid: paymentIntent ? true : false,
        }
      );
      if (res.data.success) {
        setBillingLoading(false);
        getUser();
        toast.success("Purchase Complete");
        history(`/dashboard?tab=3`);
      }
    } catch (error) {
      setBillingLoading(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  const handleGenerateInvoiceAfterPayment = async (paymentIntent, status) => {
    try {
      setBillingLoading(true);
      let response = await axios({
        url: `/stripe/make/store-invoice/${userCart._id}`, // update cart is paid, add all invoice items,
        method: "POST",
        data: {
          paymentType: selectedPayment.type,
          // paymentMethodId: selectedPayment.id,
          // paymentIntentId: paymentIntent_id,
          // date: new Date(),
          status: status,
          billing_id: userAddress._id,
        },
      });
      if (response.data.success) {
        setBillingLoading(false);

        return handleUpdateFinalCart(response.data.invoice, paymentIntent);
        // toast.success("Purchase Complete");
        // return history(`/dashboard?tab=3`);
      }
      return toast.error("Invoice could not be processed");
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  const handleConfirmPayment = async (intent) => {
    if (intent.status === "succeeded") {
      setBillingLoading(false);
      await handleGenerateInvoiceAfterPayment(intent, intent.status);
    } else if (
      intent.status === "requires_action" ||
      intent.status === "requires_confirmation"
    ) {
      var stripe = await stripePromise;
      if (selectedPayment.type === "card") {
        const { error: errorAction, paymentIntent } =
          await stripe.confirmCardPayment(intent.client_secret);

        if (errorAction) {
          setBillingLoading(false);
          return toast.error("Error in payment, please try again later");
        } else if (paymentIntent.status === "succeeded") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent,
            paymentIntent.status
          );
        } else if (
          paymentIntent.status === "requires_action" ||
          paymentIntent.status === "requires_confirmation"
        ) {
          await handlePayment(false, intent.id);
        } else {
          setBillingLoading(false);
          toast.error("Please try again later");
        }
      } else if (selectedPayment.type === "sepa_debit") {
        const { error: errorAction, paymentIntent } =
          await stripe.confirmSepaDebitPayment(intent.client_secret);
        if (errorAction) {
          setBillingLoading(false);
          return toast.error("Error in payment, please try again later");
        } else if (paymentIntent.status === "succeeded") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent,
            paymentIntent.status
          );
        } else if (paymentIntent.status === "processing") {
          setBillingLoading(false);
          await handleGenerateInvoiceAfterPayment(
            paymentIntent,
            paymentIntent.status
          );
        }
      } else {
        setBillingLoading(false);
        toast.error("Please try again later");
      }
    } else {
      setBillingLoading(false);
      toast.error("Please try again later");
    }
  };

  const handlePayment = async (isBankTransfer, intent) => {
    try {
      if (!isAcceptTerms) {
        return toast.error("Please accept terms and conditions");
      }
      setBillingLoading(true);
      let response = await axios.post(
        `/stripe/store-purchase?cart=${userCart._id}`,
        {
          data: {
            payment_method: isBankTransfer
              ? null
              : selectedPayment
              ? selectedPayment.id
              : null,
            paymentType: selectedPayment ? selectedPayment.type : null,
            billing_id: userAddress._id,
            paymentIntent: intent,
          },
        }
      );
      if (response.data.success) {
        setBillingLoading(false);
        let paymentIntent = response.data.data;
        let invoice = response.data.invoice;
        if (selectedPayment) {
          handleConfirmPayment(paymentIntent);
        } else if (isBankTransfer) {
          handleUpdateFinalCart(invoice, null);
        } else {
          handleUpdateFinalCart(invoice, null);
        }
        setSelectBankTransfer(false);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
      setBillingLoading(false);
    }
  };

  const handleSubmit = async () => {
    if (firstname === "" || firstname === null) {
      return toast.error("Please enter first name");
    }
    if (lastName === "" || lastName === null) {
      return toast.error("Please enter last name");
    }
    if (email === "" || email === null) {
      return toast.error("Please enter email");
    }
    if (
      street === "" ||
      street === null ||
      streetNumber === "" ||
      streetNumber === null ||
      country === "" ||
      country === null ||
      state === "" ||
      state === null ||
      city === "" ||
      city === null ||
      zip === "" ||
      zip === null ||
      countrycode === "" ||
      countrycode === null
    ) {
      return toast.error("Please enter full address");
    }
    try {
      setisdisabled(true);
      const response = await axios.post(
        `/address/billing-address/${userCart._id}`,
        {
          firstName: firstname,
          lastName: lastName,
          emailAddress: email,
          CompanyName: companyName,
          street: street,
          houseNumber: streetNumber,
          zipCode: zip,
          State: state,
          City: city,
          Country: country,
          VATnumber: vat,
          countryCode: countrycode,
        }
      );
      if (response.data.success) {
        getAddress();
        toast.success("Details Submitted");
        setStep(0);
        setisdisabled(false);
      }
    } catch (error) {
      setisdisabled(false);
      toastResponseError(error, setSessionExpire);
    }
  };

  useEffect(() => {
    if (submitPayment) {
      if (selectBankTransfer) {
        handlePayment(true);
      } else {
        handlePayment(false);
      }
      setsubmitPayment(false);
    }
  }, [submitPayment]);

  const fetchcountries = async () => {
    const response = await axios.get(`/location/countries`);
    setcountries(response.data);
  };
  const fetchstates = async () => {
    const response = await axios.get(`/location/country/${countrycode}`);
    setstates(response.data);
  };

  const fetchcities = async () => {
    const response = await axios.get(
      `/location/cities/${countrycode}/${statecode}`
    );
    setcities(response.data);
  };

  useEffect(() => {
    if (!countries || !countries.length > 0) fetchcountries();
  }, []);

  useEffect(() => {
    if (countrycode) fetchstates();
  }, [countrycode]);

  useEffect(() => {
    if (statecode) fetchcities();
  }, [statecode]);
  const {t} = useTranslation()
  return (
    <div className="mt-34">
      {billingLoading ? <Loader /> : null}
      <p className=" breadcrumb_text fw-400 color_ba">
        <span
          onClick={() => history("/dashboard?tab=9&checkout=0")}
          className="pointer"
        >
          store
        </span>
        {" / "}
        <span className="fw-700">Checkout</span>
      </p>
      {step === 0 ? (
        <>
          <p className="text_capital heading_3 mt-40">
            🚀 {t("store.almostText")}
          </p>
          <div className="mt-32 d-flex mx-0 mb-32">
            {userAddress && (
              <div className="col-6 pe-3 ps-0">
                <div className="general_card">
                  <div className="d-flex justify-content-between">
                    <p className="heading_6 text_capital">
                      {t("settings.billingAddress")}{" "}
                      <span className="purple_label ms-3 py-2 text_lowerCase">
                        default
                      </span>
                    </p>
                  </div>
                  <p className="mt-12 f-14">
                    {userAddress.CompanyName}
                    <br />
                    {userAddress.firstName} {userAddress.lastName}
                    <br />
                    {userAddress.City} {userAddress.street}
                    <br />
                    {userAddress.zipCode}
                    <br />
                    {userAddress.State}, {userAddress.Country}
                  </p>
                </div>
              </div>
            )}
            {selectedPayment ? (
              <div className="col-6 ps-2 pe-0">
                <div className="general_card">
                  <div className="d-flex justify-content-between">
                    <p className="heading_6 mb-12">
                      {t("store.payment")}{" "}
                      {/* <span className="purple_label ms-3">default</span> */}
                    </p>
                  </div>
                  {selectedPayment.type === "card" ? (
                    <>
                      <img
                        src={CreditCardsJson[selectedPayment.card.brand]}
                        alt={selectedPayment.card.brand}
                      />
                      <p className="mt-12 font_14_500">
                        {CreditCardsNameJson[selectedPayment.card.brand]}:
                        <span className="ml-8">
                          {selectedPayment.card.last4}
                        </span>
                      </p>
                      <p className="font_14_500 mt-1">
                        Exp. Date:
                        <span className="ml-8">
                          {selectedPayment.card.exp_month}/
                          {selectedPayment.card.exp_year}
                        </span>
                      </p>
                      {/* <p>Name: Maxine Murphy</p> */}
                    </>
                  ) : selectedPayment.type === "sepa_debit" ? (
                    <>
                      <p className="font_14_500 mt-1">
                        {selectedPayment.type}:
                        <span className="ml-8">
                          {selectedPayment.sepa_debit.last4}
                        </span>
                      </p>
                      <p className="font_14_500 mt-1">
                        Bank :
                        <span className="ml-8">
                          {selectedPayment.sepa_debit.country}
                          {selectedPayment.sepa_debit.bank_code}
                        </span>
                      </p>
                      <p className="font_14_500 mt-1">
                        Name :
                        <span className="ml-8">
                          {selectedPayment.billing_details.name}
                        </span>
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="text-uppercase mb-10 heading_6">
                        Name on Invoice
                      </p>
                      <p className="subheading_2">
                        {user.firstname} {user.lastname}
                      </p>
                      <p className="subheading_2">{user.email}</p>
                    </>
                  )}
                </div>
              </div>
            ) : (
              <div className="register_address_sub">
                <p className="heading_6 mb-12">
                {t("store.payment")} <span className="purple_label ms-3">default</span>
                </p>
                <p className="text-uppercase mb-10 heading_6">
                  Name on Invoice
                </p>
                <p className="subheading_2">
                  {user.firstname} {user.lastname}
                </p>
                <p className="subheading_2">{user.email}</p>
              </div>
            )}
          </div>

          <hr />
          <div className="row mt-24 mb-24">
            <div className="col-6">
              <ButtonComp status={3} onClick={() => setStep(2)}>
                + {t("store.newBillingAddress")}
              </ButtonComp>
              {/* <button
                className="tertiary_button_variant_1 fit-w"
                onClick={() => setStep(2)}
              >
                + New Billing Address
              </button> */}
            </div>
            <div className="col-6">
              {/* <button
                className="tertiary_button_variant_1 fit-w"
                onClick={() => setStep(1)}
              >
                + New Payment Method
              </button> */}
              <ButtonComp status={3} onClick={() => setStep(1)}>
                + {t("store.newPaymentMethod")}
              </ButtonComp>
            </div>
          </div>
          <hr />
          <p className="mb-20">{t("store.otherPaymentOpt")}</p>
          <div className="row mx-0">
            <div className="col-6 ps-0">
              <div className="d-flex flex-column">
                {allAddressess &&
                  allAddressess.map((address, i) => (
                    <div className="general_card w-100  mb-20" key={i}>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="heading_6 text_capital">
                        {t("settings.billingAddress")}
                        </p>
                        {/* <button
                          className="tertiary_button_active fit-w"
                          onClick={() => setUserAddress(address)}
                          disabled={userAddress._id === address._id}
                        >
                          Select
                        </button> */}
                        <ButtonComp
                          status={3}
                          disabled={userAddress._id === address._id}
                          onClick={() => setUserAddress(address)}
                        >
                          {t("store.select")}
                        </ButtonComp>
                      </div>
                      <p className="mt-12 f-14">
                        {address.CompanyName}
                        <br />
                        {address.firstName} {address.lastName}
                        <br />
                        {address.City} {address.street}
                        <br />
                        {address.zipCode}
                        <br />
                        {address.State}, {address.Country}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
            <div className="col-6 pe-0">
              <div className="d-flex flex-column">
                {paymentMethods &&
                  paymentMethods.map((pay, i) => (
                    <div className="general_card w-100 mb-20 ml-0" key={i}>
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="heading_6 text_capital">{t("store.payment")}</p>
                        <ButtonComp
                          status={3}
                          onClick={() => {
                            setselectedPayment(pay);
                            // setSelectBankTransfer(false);
                          }}
                          disabled={
                            selectedPayment && selectedPayment.id === pay.id
                          }
                        >
                          {t("store.select")}
                        </ButtonComp>
                      </div>
                      {pay.type === "card" ? (
                        <>
                          <img
                            src={CreditCardsJson[pay.card.brand]}
                            alt={pay.card.brand}
                          />
                          <p className="mt-12 font_14_500">
                            {CreditCardsNameJson[pay.card.brand]}:
                            <span className="ml-8">{pay.card.last4}</span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Exp. Date:
                            <span className="ml-8">
                              {pay.card.exp_month}/{pay.card.exp_year}
                            </span>
                          </p>
                          {/* <p>Name: Maxine Murphy</p> */}
                        </>
                      ) : pay.type === "sepa_debit" ? (
                        <>
                          <p className="font_14_500 mt-1">
                            {pay.type}:
                            <span className="ml-8">{pay.sepa_debit.last4}</span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Bank :
                            <span className="ml-8">
                              {pay.sepa_debit.country}
                              {pay.sepa_debit.bank_code}
                            </span>
                          </p>
                          <p className="font_14_500 mt-1">
                            Name :
                            <span className="ml-8">
                              {pay.billing_details.name}
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="text-uppercase mb-10 heading_6">
                            Name on Invoice
                          </p>
                          <p className="subheading_2">
                            {user.firstname} {user.lastname}
                          </p>
                          <p className="subheading_2">{user.email}</p>
                        </>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
      ) : step === 1 ? (
        <div className="mt-32">
          {billingStep === 0 ? (
            <>
              <Accordion
                expanded={expanded === 1}
                onChange={handleExpandChange(1)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <img src={Sepa} alt="Sepa" />
                      <p className="subheading_light_2 color_black ml-12">
                        SEPA Direct Debit
                      </p>
                      <div className="subtitle_box bg_soft_green ml-12">
                        <p className="color_green subheading_4">0% Fees</p>
                      </div>
                    </div>

                    <ButtonComp
                      status={3}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: Sepa,
                          title: "SEPA Direct Debit",
                          fees: "0% Fees",
                          bg: "bg_soft_green",
                          color: "color_green",
                          buttonText: "Authorize Mandate",
                          forwardBtn: false,
                        });
                        handleCardIntent("sepa_debit");
                        setSelectBankTransfer(false);
                      }}
                    >
                      Select
                    </ButtonComp>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="body_text_s_light">
                    Select &apos;SEPA Direct Debit&apos; for an automatic
                    payment withdrawl. Provide your IBAN number, name and email
                    adress. Once the transaction has been completed, you&apos;ll
                    gain instant access to today.green. This payment method does
                    not include any additional charges.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 2}
                onChange={handleExpandChange(2)}
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <img src={BankTransfer} alt="Sepa" />
                      <p className="subheading_light_2 color_black ml-12">
                        Bank Transfer (Invoice)
                      </p>
                      <div className="subtitle_box bg_soft_green ml-12">
                        <p className="color_green subheading_4">0% Fees</p>
                      </div>
                    </div>
                    {/* <button
                      className="tertiary_button_active"
                      onClick={(e) => {
                        e.stopPropagation();
                        setBillingStep(2);
                      }}
                    >
                      Select
                    </button> */}
                    <ButtonComp
                      status={3}
                      onClick={(e) => {
                        e.stopPropagation();
                        setBillingStep(2);
                      }}
                    >
                      Select
                    </ButtonComp>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="body_text_s_light">
                    This payment method provides flexibility when you prefer to
                    work with traditional invoices. Enter your billing
                    information and confirm your selection by clicking
                    &apos;Purchase & Download Invoice&apos; - the invoice will
                    download automatically. Make sure to submit your payment
                    within 14 days. Once the transaction has been completed, you
                    can start using today.green.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 3}
                onChange={handleExpandChange(3)}
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <img src={CreditCard} alt="Sepa" />
                      <p className="subheading_light_2 color_black ml-12">
                        Credit Card
                      </p>
                      <div className="subtitle_box bg_soft_orange ml-12">
                        <p className="color_orange subheading_4">+1.9%</p>
                      </div>
                    </div>
                    {/* <button
                      className="tertiary_button_active"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: CreditCard,
                          title: "Credit Card",
                          fees: "+1.9%",
                          bg: "bg_soft_orange",
                          color: "color_orange",
                          buttonText: "Save & Continue",
                          forwardBtn: false,
                        });
                        handleCardIntent("card");
                      }}
                    >
                      Select
                    </button> */}
                    <ButtonComp
                      status={3}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: CreditCard,
                          title: "Credit Card",
                          fees: "+1.9%",
                          bg: "bg_soft_orange",
                          color: "color_orange",
                          buttonText: "Save & Continue",
                          forwardBtn: false,
                        });
                        handleCardIntent("card");
                        setSelectBankTransfer(false);
                      }}
                    >
                      Select
                    </ButtonComp>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="body_text_s_light">
                    Pay with ease using the credit card payment method. Enter
                    your credit card information and get instant access to
                    today.green. This payment method includes an additional
                    charge of 1.9%.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 4}
                onChange={handleExpandChange(4)}
              >
                <AccordionSummary
                  aria-controls="panel4d-content"
                  id="panel4d-header"
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <img src={Sofort} alt="Sofort" />
                      <p className="subheading_light_2 color_black ml-12">
                        Sofort
                      </p>
                      <div className="subtitle_box bg_soft_orange ml-12">
                        <p className="color_orange subheading_4">+1.4%</p>
                      </div>
                    </div>
                    <ButtonComp
                      status={3}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: Sofort,
                          title: "Sofort",
                          fees: "+1.4%",
                          bg: "bg_soft_orange",
                          color: "color_orange",
                          buttonText: "Continue to SOFORT",
                          forwardBtn: true,
                        });
                        handleCardIntent("sofort");
                        setSelectBankTransfer(false);
                      }}
                    >
                      Select
                    </ButtonComp>
                    {/* <button
                      className="tertiary_button_active"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: Sofort,
                          title: "Sofort",
                          fees: "+1.4%",
                          bg: "bg_soft_orange",
                          color: "color_orange",
                          buttonText: "Continue to SOFORT",
                          forwardBtn: true,
                        });
                        handleCardIntent("sofort");
                      }}
                    >
                      Select
                    </button> */}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="body_text_s_light">
                    Choose &apos;Sofort&apos; for a quick and secure bank
                    transfer. You&apos;ll be redirected to your bank&apos;s
                    online portal to authenticate your payment. Please note, it
                    may take 2 to 14 days for transaction confirmation. Once the
                    transaction has been completed, you can start using
                    today.green. This payment method includes and additional
                    charge of 1.4%.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 5}
                onChange={handleExpandChange(5)}
              >
                <AccordionSummary
                  aria-controls="panel5d-content"
                  id="panel5d-header"
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <img src={BankContact} alt="Bankcontact" />
                      <p className="subheading_light_2 color_black ml-12">
                        Bankcontact
                      </p>
                      <div className="subtitle_box bg_soft_orange ml-12">
                        <p className="color_orange subheading_4">+1.4%</p>
                      </div>
                    </div>
                    <ButtonComp
                      status={3}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: BankContact,
                          title: "Bankcontact",
                          fees: "+1.4%",
                          bg: "bg_soft_orange",
                          color: "color_orange",
                          buttonText: "Continue to Bankcontact",
                          forwardBtn: true,
                        });
                        handleCardIntent("bancontact");
                        setSelectBankTransfer(false);
                      }}
                    >
                      Select
                    </ButtonComp>
                    {/* <button
                      className="tertiary_button_active"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: BankContact,
                          title: "Bankcontact",
                          fees: "+1.4%",
                          bg: "bg_soft_orange",
                          color: "color_orange",
                          buttonText: "Continue to Bankcontact",
                          forwardBtn: true,
                        });
                        handleCardIntent("bancontact");
                      }}
                    >
                      Select
                    </button> */}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="body_text_s_light">
                    Select &apos;Bankcontact&apos; for a quick and secure bank
                    transfer. You&apos;ll be redirected to Bancontact to
                    authorize your payment. Once the transaction has been
                    completed, you can start using today.green. This payment
                    method includes and additional charge of 1.4%.
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === 6}
                onChange={handleExpandChange(6)}
              >
                <AccordionSummary
                  aria-controls="panel6d-content"
                  id="panel6d-header"
                >
                  <div className="d-flex align-items-center justify-content-between w-100">
                    <div className="d-flex align-items-center">
                      <img src={IDEAL} alt="iDEAL" />
                      <p className="subheading_light_2 color_black ml-12">
                        iDEAL
                      </p>
                      <div className="subtitle_box bg_soft_green ml-12">
                        <p className="color_green subheading_4">0% Fees</p>
                      </div>
                    </div>
                    <ButtonComp
                      status={3}
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: IDEAL,
                          title: "iDEAL",
                          fees: "0% Fees",
                          bg: "bg_soft_green",
                          color: "color_green",
                          buttonText: "Continue to iDEAL",
                          forwardBtn: true,
                        });
                        handleCardIntent("ideal");
                        setSelectBankTransfer(false);
                      }}
                    >
                      Select
                    </ButtonComp>
                    {/* <button
                      className="tertiary_button_active"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedPaymentInfo({
                          img: IDEAL,
                          title: "iDEAL",
                          fees: "0% Fees",
                          bg: "bg_soft_green",
                          color: "color_green",
                          buttonText: "Continue to iDEAL",
                          forwardBtn: true,
                        });
                        handleCardIntent("ideal");
                      }}
                    >
                      Select
                    </button> */}
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p className="body_text_s_light">
                    iDeal allows for a quick and secure payment. Provide your
                    bank name and account holder information to continue to
                    iDeal. Confirm your payment and instantly gain access to
                    today.green. No additional charges are included.
                  </p>
                </AccordionDetails>
              </Accordion>
              <div className="text-right mt-4 pe-3">
                <ButtonComp status={3} onClick={() => setStep(0)}>
                  Go Back
                </ButtonComp>
              </div>
            </>
          ) : billingStep === 1 ? (
            options && (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                  selectedPaymentInfo={selectedPaymentInfo}
                  setValue={setStep}
                  setBillingStep={setBillingStep}
                  getPaymentMethods={getPaymentMethods}
                />
              </Elements>
            )
          ) : (
            billingStep === 2 && (
              <div>
                <div className="d-flex align-items-center border-btm_ef pb-16 mb-24">
                  <img src={BankTransfer} alt="Sepa" />
                  <p className="subheading_light_2 color_black ml-12">
                    Bank Transfer (Invoice)
                  </p>
                  <div className="subtitle_box bg_soft_green ml-12">
                    <p className="color_green subheading_4">0% Fees</p>
                  </div>
                </div>
                <div className="row border-btm_ef pb-20 mb-20">
                  <div className="col">
                    <p className="text-uppercase mb-10 heading_6">
                      Name on Invoice
                    </p>
                    <p className="subheading_2">
                      {user.firstname} {user.lastname}
                    </p>
                    <p className="subheading_2">{user.email}</p>
                  </div>
                  <div className="col">
                    <p className="text_uppercase mb-10 heading_6">
                      Billing Address
                    </p>
                    <p className="mt-12 subheading_2">
                      {userAddress.CompanyName}
                      <br />
                      {userAddress.firstName} {userAddress.lastName}
                      <br />
                      {userAddress.City} {userAddress.street}
                      <br />
                      {userAddress.zipCode}
                      <br />
                      {userAddress.State}, {userAddress.Country}
                    </p>
                  </div>
                  <div className="" style={{ width: "fit-content" }}>
                    <button
                      className="profileEditbtn"
                      onClick={() => setStep(2)}
                    >
                      <EditIcon />
                    </button>
                  </div>
                </div>
                <div className="  d-flex justify-content-between ">
                  <BackForwardButton
                    doNotShowForward={true}
                    handleGoBack={() => setBillingStep(0)}
                  />
                  <ButtonComp
                    status={1}
                    onClick={() => {
                      setStep(0);
                      setSelectBankTransfer(true);
                    }}
                    size="lg"
                  >
                    Confirm & Continue
                  </ButtonComp>
                  {/* <button
                    onClick={() => {
                      // setSelectBankTransfer(true);
                      setStep(0);
                    }}
                    className="primary_button_lg"
                  >
                    Confirm & Continue
                  </button> */}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        step === 2 && (
          <div className="mb-24">
            <div className="row mt-24 mb-32">
              <div className="col-6">
                <label className="heading_6 mb-8">{t("common.firstName")}</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="First Name"
                  type="text"
                  value={firstname}
                  onChange={(e) => setfirstname(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label className="heading_6 mb-8">{t("common.firstName")}</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="Last Name"
                  type="text"
                  value={lastName}
                  onChange={(e) => setlastName(e.target.value)}
                />
              </div>
              <div className="col-6 mt-16">
                <label className="heading_6 mb-8">E-MAIL ADDRESS</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="E-mail Address"
                  type="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                />
              </div>
              <div className="col-6 mt-16">
                <label className="heading_6 mb-8">COMPANY NAME</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="Company Name"
                  type="text"
                  value={companyName}
                  onChange={(e) => setcompanyName(e.target.value)}
                />
              </div>
            </div>
            <div className="row mt-56">
              <div className="col-6">
                <label className="heading_6 mb-8">STREET</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="Street"
                  type="text"
                  value={street}
                  onChange={(e) => setstreet(e.target.value)}
                />
              </div>
              <div className="col-6">
                <label className="heading_6 mb-8">NUMBER</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="Street Number"
                  type="text"
                  value={streetNumber}
                  onChange={(e) => setstreetNumber(e.target.value)}
                />
              </div>
              <div className="col-6 mt-16">
                <label className="heading_6 mb-8">COUNTRY</label>
                <select
                  className="border_input w-100 subheading_2"
                  placeholder="Country"
                  type="text"
                  value={`${countrycode}` + "#" + `${country}`}
                  onChange={(e) => {
                    setcountry(e.target.value.split("#")[1]);
                    setcountrycode(e.target.value.split("#")[0]);
                  }}
                >
                  <option value="">Choose Country</option>
                  {countries &&
                    countries.map((option, i) => (
                      <option
                        className=""
                        key={i}
                        value={`${option.iso2}` + "#" + `${option.name}`}
                      >
                        {option.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-6 mt-16">
                <label className="heading_6 mb-8">STATE</label>
                <select
                  className="border_input w-100 subheading_2"
                  placeholder="State"
                  type="text"
                  value={`${statecode}` + "#" + `${state}`}
                  onChange={(e) => {
                    setstate(e.target.value.split("#")[1]);
                    setstatecode(e.target.value.split("#")[0]);
                  }}
                >
                  <option value="">Choose State</option>
                  {states &&
                    states.map((option, i) => (
                      <option
                        className=""
                        key={i}
                        value={`${option.iso2}` + "#" + `${option.name}`}
                      >
                        {option.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-4 mt-16">
                <label className="heading_6 mb-8">CITY</label>
                <select
                  className="border_input w-100 subheading_2"
                  placeholder="City"
                  type="text"
                  value={city}
                  onChange={(e) => setcity(e.target.value)}
                >
                  <option value="">Choose City</option>
                  {cities &&
                    cities.map((option, i) => (
                      <option className="" key={i} value={option.name}>
                        {option.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-4 mt-16">
                <label className="heading_6 mb-8">ZIP</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="Zip"
                  type="text"
                  value={zip}
                  onChange={(e) => setzip(e.target.value)}
                />
              </div>
              <div className="col-4 mt-16">
                <label className="heading_6 mb-8">VAT (OPTIONAL)</label>
                <input
                  className="border_input w-100 subheading_2"
                  placeholder="VAT"
                  type="text"
                  value={vat}
                  onChange={(e) => setVat(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-24 d-flex justify-content-end">
              <ButtonComp status={3} onClick={() => setStep(0)}>
                Go Back
              </ButtonComp>
              &nbsp; &nbsp;
              <ButtonComp
                status={1}
                disabled={isDisabled}
                onClick={handleSubmit}
                // size="lg"
              >
                Create Address
              </ButtonComp>
              {/* <button
                disabled={isDisabled}
                onClick={handleSubmit}
                className="primary_button_lg"
              >
                Continue
              </button> */}
            </div>
          </div>
        )
      )}
    </div>
  );
}
