import React from "react";

function CompanyMapIcon(props) {
  return (
    <div className={props.active ? "icon_active_click" : "icon_inActive_click"}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="17"
      fill="none"
      viewBox="0 0 19 17"
    >
      <g stroke={props.stroke} strokeWidth="2">
        <path d="M5.944 13.5v-12"></path>
        <path d="M13.056 15.5v-12"></path>
        <path
          strokeLinejoin="round"
          d="M17.5 13.209V2.196a.45.45 0 00-.635-.41l-3.66 1.647a.45.45 0 01-.307.023L6.102 1.544a.45.45 0 00-.307.023l-4.03 1.814a.45.45 0 00-.265.41v11.013a.45.45 0 00.635.41l3.66-1.647a.45.45 0 01.307-.023l6.796 1.912a.45.45 0 00.307-.023l4.03-1.814a.45.45 0 00.265-.41z"
        ></path>
      </g>
    </svg>
    </div>
  );
}

export default CompanyMapIcon;
