import React, { useState } from "react";
import "./addParticipants.css";
import {
  TextField,
  //  ,StepButton
} from "@mui/material";
import PropTypes from "prop-types";
import TabLabels from "../../../../../../LogDashboard/Components/TabLabels";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  styled,
  FormControl,
  OutlinedInput,
  MenuItem,
  Tabs,
  Tab,
  Box,
  InputAdornment,
  Select,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { Avatar } from "@mui/joy";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../../../../../../Utils/TableComp";
import SearchIcon from "../../../../../../../../Assets/icons/SearchIcon";
import {
  VisibleRoles,
  roles,
} from "../../../../../../../../Utils/UserRoleConstAndFunc";
import { Remove } from "@mui/icons-material";

const sx = {
  border: "none",
  backgroundColor: "#F6F6F6",
  "& .MuiInputBase-input": {
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "500",
    color: "#000000",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
};

const BootstrapInput = styled(OutlinedInput)(() => ({
  "& .MuiInputBase-input": {
    fontFamily: "var(--inter-font)",
    fontWeight: 400,
    fontSize: "13px",
    borderRadius: "5px",
    height: "40px !important",
    marginTop: "auto",
    padding: "0px 5px",
    display: "flex",
    alignItems: "center",
    border: "0",
    backgroundColor: "#F6F6F6",
    color: "#727E8F",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  // children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function AddParticipants(props) {
  const [tabIndex, setTabIndex] = useState(0);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState(roles.viewer);
  const [userType, setUserType] = useState("Internal");
  const [userSearchFilter, setUserSearchFilter] = useState("");
  const handleTabIndexChange = (event, newValue) => {
    if (newValue > 1 || (!newValue && newValue != 0)) return;
    setTabIndex(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <>
      <div className="mt-50">
        <p className="font_12_300 color_7e">
          Fill in the information and invite a user
        </p>
        <div className="d-flex justify-content-start align-items-center mt-30">
          {/* <p className="d-flex align-items-center font_13_500 color_72 bg_f6 border_radius_5 pt-10 pb-10 pl-12 pr-20">
                        <i className="bi bi-caret-down-fill mr-6"></i> Type
                    </p> */}
          <div className="addParticipants_select_container">
            <FormControl
              sx={{
                m: "4px 0",
                width: "100%",
                //  border: "1px solid #efefef",
                borderRadius: "5px",
                "& .MuiSelect-select:focus": {
                  backgroundColor: "transparent !important",
                },
              }}
            >
              <Select
                displayEmpty
                input={<BootstrapInput />}
                onChange={(e) => {
                  setUserType(e.target.value);
                }}
                renderValue={(selected) => {
                  if (!selected || selected.length === 0) {
                    return "Internal";
                  }
                  return selected;
                }}
                sx={sx}
              >
                <MenuItem className="color_72" value={"Internal"}>
                  <p className="font_13_400">Internal</p>
                </MenuItem>
                <MenuItem className="color_72" value={"External"}>
                  <p className="font_13_400">External</p>
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="flex-grow-0 d-flex align-items-center createSurvey_question_container ml-10">
            <TextField
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
              placeholder="First Name"
              size="small"
              fullWidth
            />
          </div>

          <div className="flex-grow-0 createSurvey_question_container ml-10">
            <TextField
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
              placeholder="Last Name"
              size="small"
              fullWidth
            />
          </div>
          {userType === "Internal" && (
            <div className="flex-grow-0 addParticipants_select_container ml-10">
              <FormControl
                sx={{
                  m: "4px 0",
                  width: 186,
                  //  border: "1px solid #efefef",
                  borderRadius: "5px",
                  "& .MuiSelect-select:focus": {
                    backgroundColor: "transparent !important",
                  },
                }}
              >
                <Select
                  displayEmpty
                  input={<BootstrapInput />}
                  onChange={(e) => {
                    setRole(e.target.value);
                  }}
                  value={role}
                  sx={sx}
                >
                  {Object.entries(VisibleRoles).map((obj) => (
                    <MenuItem className="color_72" value={obj[0]} key={obj[0]}>
                      <p className="font_13_400">{obj[1]}</p>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="flex-grow-0 createSurvey_question_container ml-10">
            <TextField
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="Email"
              size="small"
              fullWidth
            />
          </div>

          <div className="d-flex align-items-center h-40px ml-10">
            <div
              onClick={() => {
                props.addUserToList(firstName, lastName, email, userType, role);
                setFirstName("");
                setLastName("");
                setEmail("");
              }}
              className="h-40px w-40px center bg_green text-white border_radius_5"
              style={{
                minWidth: "40px",
                maxWidth: "40px",
              }}
            >
              <i className="bi bi-plus-lg"></i>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-40">
        {props.allUsers.filter((x) => x.selected).length > 0 && (
          <>
            <div
              className="align_center gap_10 pointer"
              onClick={() => props.removeAllUsers()}
            >
              <div className="h-14px w-14px bg_red border_radius_5 center">
                <Remove
                  htmlColor="#ffffff"
                  sx={{
                    color: "#fff",
                    // backgroundColor: "#fff",
                    width: "9px",
                    height: "9px",
                  }}
                />
              </div>
              <p className="font_12_600 color_red">Remove All</p>
            </div>
            <hr className="color_ea" />

            <div className="createSurvey_userTable_container mb-58">
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640, borderRadius: "8px" }}
                className="mr-50"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableBody>
                    {props.allUsers
                      .filter((x) => x.selected)
                      .map((currUser) => (
                        <StyledTableRow
                          key={uuidv4()}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              borderBottom: 0,
                            },
                          }}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            sx={{ border: 0 }}
                          >
                            <div className="d-flex align-items-center">
                              <Avatar
                                className="ml-10"
                                sx={{
                                  bgcolor: "pink",
                                  width: "30px",
                                  height: "30px",
                                }}
                              >
                                {currUser.firstName
                                  ? currUser.firstName[0]
                                  : currUser.firstname[0]}
                              </Avatar>

                              <p className="font_13_400 color_72 ml-10">
                                {currUser.firstName
                                  ? currUser.firstName
                                  : currUser.firstname}{" "}
                                {currUser.lastName
                                  ? currUser.lastName
                                  : currUser.lastname}
                              </p>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ border: 0 }}
                            // className="border_bottom_0 "
                          >
                            <div className="d-flex">
                              {currUser.isExternal ? (
                                <div
                                  className="w-75px center border_radius_10 bg_light_yellow "
                                  style={{ height: 28 }}
                                >
                                  <p className="font_13_400 color_72">
                                    External
                                  </p>
                                </div>
                              ) : (
                                <div
                                  className="w-75px center border_radius_10 bg_d4 "
                                  style={{ height: 28 }}
                                >
                                  <p className="font_13_400 color_72">
                                    My Team
                                  </p>
                                </div>
                              )}
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ border: 0, borderTop: "0" }}
                            //className="border_bottom_0"
                          >
                            <div className="d-flex align-items-center">
                              <p className="font_13_400 color_72">
                                {currUser.email}
                              </p>
                            </div>
                          </StyledTableCell>
                          <StyledTableCell
                            sx={{ border: 0 }}
                            //className="border_bottom_0"
                          >
                            <div className="d-flex justify-content-between">
                              <p
                                onClick={() => {
                                  props.handleUserClick(currUser.email);
                                }}
                                className="font_14_400 align_text_left color_f4 pointer"
                              >
                                Remove
                              </p>
                              <i className="bi bi-three-dots-vertical"></i>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </>
        )}

        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleTabIndexChange}
              sx={() => ({
                "& .MuiTouchRipple-child": {
                  backgroundColor: `#cacaca !important`,
                },
              })}
              indicatorColor="#00915B"
              aria-label="basic tabs example"
              className="general_root_tabs w-100 h-50px"
            >
              <Tab
                label={<TabLabels title={"My Team"} count={0} />}
                {...a11yProps(0)}
              />

              <Tab
                label={<TabLabels title={"Externals"} count={0} />}
                {...a11yProps(1)}
              />
              <div className="w-100 justify_end">
                <div className="createSurvey_user_search d-flex justify-content-end align-items-center">
                  <TextField
                    value={userSearchFilter}
                    onChange={(e) => {
                      setUserSearchFilter(e.target.value);
                    }}
                    className="body_text_l bg_f6 border_radius_5"
                    id="input-with-icon-textfield"
                    placeholder="Search..."
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 40,
                        width: 400,
                      },
                      input: {
                        fontSize: "13px",
                        fontWeight: "500",
                      },
                    }}
                  />
                </div>
              </div>
            </Tabs>
          </Box>

          <TabPanel value={tabIndex} index={0}>
            <div
              className="d-flex align-items-center mt-24 width_fit pointer"
              onClick={() => {
                props.selectAllUsers("internal");
              }}
            >
              <i
                className="bi bi-plus f-12 color_white bg_green_07 pointer border_radius_5 d-flex justify-content-center align-items-center fw-800"
                style={{ height: 14, width: 14 }}
              ></i>
              <p className="font_12_600 color_07 ml-14">Add All</p>
            </div>
            <hr className="color_ea" />

            <div className="createSurvey_userTable_container">
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640, borderRadius: "8px" }}
                className="mr-50"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableBody>
                    {props.allUsers
                      .filter(
                        (curr) =>
                          !curr.isExternal &&
                          (curr.email
                            .toLowerCase()
                            .includes(
                              userSearchFilter.toLocaleLowerCase().trim()
                            ) ||
                            curr.firstName
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ) ||
                            curr.firstname
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ) ||
                            curr.lastName
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ) ||
                            curr.lastname
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ))
                      )
                      .map((currUser) => {
                        if (!currUser.isExternal) {
                          return (
                            <StyledTableRow
                              key={uuidv4()}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderBottom: 0,
                                },
                              }}
                            >
                              <StyledTableCell
                                component="th"
                                scope="row"
                                sx={{ border: 0 }}
                              >
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    className="ml-10"
                                    sx={{
                                      bgcolor: "pink",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    {currUser.firstName
                                      ? currUser.firstName[0]
                                      : currUser.firstname[0]}
                                  </Avatar>

                                  <p className="font_13_400 color_72 ml-10">
                                    {currUser.firstName
                                      ? currUser.firstName
                                      : currUser.firstname}{" "}
                                    {currUser.lastName
                                      ? currUser.lastName
                                      : currUser.lastname}
                                  </p>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ border: 0 }}
                                // className="border_bottom_0 "
                              >
                                <div
                                  className="w-75px center border_radius_10 bg_d4 "
                                  style={{ height: 28 }}
                                >
                                  <p className="font_13_400 color_72">
                                    My Team
                                  </p>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ border: 0, borderTop: "0" }}
                                //className="border_bottom_0"
                              >
                                <div className="d-flex align-items-center">
                                  <p className="font_13_400 color_72">
                                    {currUser.email}
                                  </p>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ border: 0 }}
                                //className="border_bottom_0"
                              >
                                <div className="d-flex justify-content-between">
                                  {currUser.selected ? (
                                    <p
                                      onClick={() => {
                                        props.handleUserClick(currUser.email);
                                      }}
                                      className="font_14_400 align_text_left color_f4 pointer"
                                    >
                                      Remove
                                    </p>
                                  ) : (
                                    <p
                                      onClick={() => {
                                        props.handleUserClick(currUser.email);
                                      }}
                                      className="font_14_400 align_text_left color_07 pointer line_height_normal"
                                    >
                                      Add
                                    </p>
                                  )}
                                  <i className="bi bi-three-dots-vertical"></i>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>

          <TabPanel value={tabIndex} index={1}>
            <div
              className="width_fit d-flex align-items-center mt-24 pointer"
              onClick={() => {
                props.selectAllUsers("external");
              }}
            >
              <i
                className="bi bi-plus f-12 color_white bg_green_07 pointer border_radius_5 d-flex justify-content-center align-items-center fw-800"
                style={{ height: 14, width: 14 }}
              ></i>

              <span className="font_12_600 color_07 ml-14">Add All</span>
            </div>
            <hr className="color_ea" />

            <div className="createSurvey_userTable_container">
              <TableContainer
                component={Paper}
                sx={{ minWidth: 640, borderRadius: "8px" }}
                className="mr-50"
              >
                <Table sx={{ minWidth: 640 }} aria-label="simple table">
                  <TableBody>
                    {props.allUsers
                      .filter(
                        (curr) =>
                          curr.isExternal &&
                          (curr.email
                            .toLowerCase()
                            .includes(
                              userSearchFilter.toLocaleLowerCase().trim()
                            ) ||
                            curr.firstName
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ) ||
                            curr.firstname
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ) ||
                            curr.lastName
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ) ||
                            curr.lastname
                              ?.toLowerCase()
                              .includes(
                                userSearchFilter.toLocaleLowerCase().trim()
                              ))
                      )
                      .map((currUser) => {
                        if (currUser.isExternal) {
                          return (
                            <StyledTableRow
                              key={uuidv4()}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  borderBottom: 0,
                                },
                              }}
                            >
                              <StyledTableCell
                                component="th"
                                scope="row"
                                sx={{ border: 0 }}
                              >
                                <div className="d-flex align-items-center">
                                  <Avatar
                                    className="ml-10"
                                    sx={{
                                      bgcolor: "pink",
                                      width: "30px",
                                      height: "30px",
                                    }}
                                  >
                                    {currUser.firstName
                                      ? currUser.firstName[0]
                                      : currUser.firstname[0]}
                                  </Avatar>

                                  <p className="font_13_400 color_72 ml-10">
                                    {currUser.firstName
                                      ? currUser.firstName
                                      : currUser.firstname}{" "}
                                    {currUser.lastName
                                      ? currUser.lastName
                                      : currUser.lastname}
                                  </p>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ border: 0 }}
                                // className="border_bottom_0 "
                              >
                                <div
                                  className="w-75px center border_radius_10 bg_light_yellow "
                                  style={{ height: 28 }}
                                >
                                  <p className="font_13_400 color_72">
                                    External
                                  </p>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ border: 0, borderTop: "0" }}
                                //className="border_bottom_0"
                              >
                                <div className="d-flex align-items-center">
                                  <p className="font_13_400 color_72">
                                    {currUser.email}
                                  </p>
                                </div>
                              </StyledTableCell>
                              <StyledTableCell
                                sx={{ border: 0 }}
                                //className="border_bottom_0"
                              >
                                <div className="d-flex justify-content-between">
                                  {currUser.selected ? (
                                    <p
                                      onClick={() => {
                                        props.handleUserClick(currUser.email);
                                      }}
                                      className="font_14_400 align_text_left color_f4 pointer"
                                    >
                                      Remove
                                    </p>
                                  ) : (
                                    <p
                                      onClick={() => {
                                        props.handleUserClick(currUser.email);
                                      }}
                                      className="font_14_400 align_text_left color_07 pointer line_height_normal"
                                    >
                                      Add
                                    </p>
                                  )}
                                  <i className="bi bi-three-dots-vertical"></i>
                                </div>
                              </StyledTableCell>
                            </StyledTableRow>
                          );
                        }
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </TabPanel>
        </Box>
      </div>
    </>
  );
}

export default AddParticipants;
