import React, { useEffect, useState } from "react";
import "./missingData.css";
import { Drawer, TextField } from "@mui/material";
//import Select from "@mui/material/Select";
import { botAvatarGroup } from "../../../../../../Utils/botAvatarsGroup";
import { useAuth } from "../../../../../../Utils/Auth";
import axios from "../../../../../../Api/index";
import { toast } from "react-hot-toast";
import { toastResponseError } from "../../../../../../Utils/toastResponseError";
import subScopeCategory from "../../../../../../DataJSON/subScopes.json";
import location from "../../../../../../DataJSON/companyLocation.json";
import event from "../../../../../../DataJSON/event.json";
import hotel from "../../../../../../DataJSON/hotel.json";
import product from "../../../../../../DataJSON/product.json";
import service from "../../../../../../DataJSON/service.json";
import realEstateA from "../../../../../../DataJSON/realEstateAgency.json";
import realEstateD from "../../../../../../DataJSON/realEstateDevelopment.json";
import building from "../../../../../../DataJSON/building.json";
import agency from "../../../../../../DataJSON/agency.json";
import education from "../../../../../../DataJSON/educationalInstitute.json";
import shipping from "../../../../../../DataJSON/shipping.json";
import skilledTrades from "../../../../../../DataJSON/skilledTradesSector.json";
import ButtonComp from "../../../../../../Assets/components/Button";

function MissingData(props) {
  const { user, setSessionExpire } = useAuth();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [subScopeMissing, setSubScopeMissing] = useState(false);
  const [emissionFactorMissing, setEmissionFactorMissing] = useState(false);
  const [dataSubmitted, setDataSubmitted] = useState(false);
  const data = {
    location,
    event,
    hotel,
    product,
    service,
    "real-estate agency": realEstateA,
    "real estate development": realEstateD,
    building,
    agency,
    "educational institution": education,
    shipping,
    "skilled trades company": skilledTrades,
  };

  const resetMissingDataStates = () => {
    setName("");
    setDescription("");
    setSubScopeMissing(false);
    setEmissionFactorMissing(false);
  };
  const handleCloseMissingDrawer = () => {
    setName("");
    setDescription("");
    setDataSubmitted(false);
    setSubScopeMissing(false);
    setEmissionFactorMissing(false);
    props.handleMissingDataDrawer({});
  };

  useEffect(() => {
    if (subScopeMissing && props.missingDataInfo.subScope)
      setName(props.missingDataInfo.subScope);
    else setName("");
  }, [subScopeMissing]);

  const handleSave = async () => {
    try {
      const response = await axios.post("/log/addMissingDetails", {
        logId: props.missingDataInfo.logId,
        name: name,
        description,
        headClass: props.missingDataInfo.class,
        subScope: props.missingDataInfo.subScope,
        month: props.missingDataInfo.month,
        year: props.missingDataInfo.year,
        option: subScopeMissing
          ? "I don't have this data"
          : "Missing Emission Factor",
      });

      if (response.data.success) {
        setDataSubmitted(true);
        toast.success(response.data.message);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  return (
    <div>
      <Drawer
        anchor={"right"}
        open={props.toggleMissingData}
        onClose={() => {
          handleCloseMissingDrawer();
        }}
        PaperProps={{
          sx: { width: "70%" },
        }}
      >
        <div className="drawer_root">
          <div className="productDetail_drawerHead pb-40">
            <div className="align_text_right pr-20 mt-10">
              <i
                className="bi bi-x-lg SideDrawer_close_icon pointer"
                onClick={() => handleCloseMissingDrawer()}
              ></i>
            </div>
            {!dataSubmitted ? (
              <div className="pl-35">
                <p className="faint_text_2">
                  {" "}
                  {props.missingDataInfo &&
                    props.missingDataInfo.subScope &&
                    subScopeCategory[
                      props.missingDataInfo.subScope.toLowerCase()
                    ] &&
                    "Scope " +
                      subScopeCategory[
                        props.missingDataInfo.subScope.toLowerCase()
                      ]}
                </p>
                <div className="d-flex justify-items-center">
                  <p className="f-27 fw-700 align-items-center">
                    {props.missingDataInfo.subScope}
                  </p>
                  <div className="d-flex align-items-center ml-8">
                    {props.missingDataInfo &&
                    props.missingDataInfo.subScope &&
                    data[props.missingDataInfo.itemName.toLowerCase()][
                      props.missingDataInfo.itemName.charAt(0).toUpperCase() +
                        props.missingDataInfo.itemName.slice(1).toLowerCase()
                    ][props.missingDataInfo.class][
                      props.missingDataInfo.subScope
                    ].isMandatory ? (
                      <span className="orange_label">Mandatory</span>
                    ) : (
                      <span className="green_label">Recommended</span>
                    )}
                  </div>
                </div>
                <p className="subheading_light_2 mt-10">
                  Here, all your business travel related emissions you created
                  this year are accounted for.
                </p>
                <p className="subheading_light_2">
                  This is not to be confused with the daily commute to the
                  office.
                </p>
              </div>
            ) : (
              <div className="pl-35 mt-10">
                <div className="d-flex justify-items-center">
                  <p className="f-27 fw-700 align-items-center">
                    {subScopeMissing
                      ? "I don't have this data"
                      : "Missing Emission Factor"}
                  </p>
                </div>
                <p className="subheading_light_2 mt-10">
                  {subScopeMissing
                    ? "Tell us why you want to skip this mandatory subscope"
                    : "Let us know about the Emission Factor you need, so we can add them as quickly as possible"}
                </p>
              </div>
            )}
          </div>
          {!dataSubmitted && !subScopeMissing && !emissionFactorMissing && (
            <div className="row container_design_1 container ml-40 mt-40 p-40 w-90">
              <div className="col-lg-8 col-md-9">
                <p className="font_40_700">Log Support</p>

                <p className="subheading_light mt-22">
                  Please choose one of the two options. If the mandatory
                  Subscope isn&apos;t applicable to your company, please choose
                  “I don&apos;t have the data”. If you can&apos;t enter your
                  emission dada due to a missing emission factor, please choose
                  the secound option. Otherwise, just ask you any questions to
                  [Bot Name]!
                </p>

                <div className="d-flex flex-wrap align-items-center mt-40">
                  <ButtonComp
                    status={2}
                    onClick={() => setSubScopeMissing(true)}
                    className="mt-10"
                  >
                    I don&apos;t have data
                  </ButtonComp>

                  <ButtonComp
                    status={2}
                    onClick={() => setEmissionFactorMissing(true)}
                    className="ml-20 mt-10"
                  >
                    Missing emission factor
                  </ButtonComp>

                  <a
                    onClick={() => handleCloseMissingDrawer()}
                    className="link_text ml-20 mt-10 pointer"
                  >
                    {" "}
                    Go Back
                  </a>
                </div>
              </div>

              <div className="col-lg-4 col-md-3 text-center">
                <img
                  src={botAvatarGroup[user?.bot_avatar_id]?.noActiveOffset.url}
                  style={{ height: 330, width: "auto" }}
                />
              </div>
            </div>
          )}
          {!dataSubmitted && subScopeMissing && (
            <div className="row mx-0">
              <div className="col-8 mt-50 ml-40">
                <p className="offsetProjectTitle">
                  I don&apos;t have this data
                </p>

                <p className="mt-16 body_text_s_light">
                  Tell us why you want to skip this mandatory subscope
                </p>

                {/* <p className="mt-50 subheading_1">subscope</p> */}

                <div className="missingData_select mt-10">
                  {/* <Select 
              disabled
              value={name}
              onChange={e =>setName(e.target.value)}
              >
                <MenuItem value={props.missingDataInfo.subScope}>{props.missingDataInfo.subScope}</MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
                <MenuItem value={"None of the above"}>
                  None of the above
                </MenuItem>
              </Select>

              <p className="overline_text mt-12">
                Select the Subscope you don’t have the data for
              </p> */}

                  <p className="subheading_1 mt-32">
                    Please explain why you can’t enter the emission data for
                    this subscope
                  </p>

                  <textarea
                    className="mt-10 border-ef p-8 w-100"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    type="textarea"
                    rows="5"
                    placeholder="type here"
                  ></textarea>

                  <p className="overline_text mt-12">
                    Select the Subscope you don’t have the data for
                  </p>

                  <div className="mt-60">
                    <ButtonComp
                      status={1}
                      onClick={handleSave}
                      className="md p-10x-40y"
                    >
                      Send
                    </ButtonComp>

                    <ButtonComp
                      status={3}
                      onClick={() => resetMissingDataStates()}
                      className="text-black ml-20 p-10x-40y"
                    >
                      Cancel
                    </ButtonComp>
                  </div>
                </div>
              </div>
            </div>
          )}

          {!dataSubmitted && emissionFactorMissing && (
            <div className="row mx-0">
              <div className="col-8 mt-50 ml-40">
                <p className="offsetProjectTitle">Missing Emission Factor</p>

                <p className="mt-16 body_text_s_light">
                  Let us know about the Emission Factor you need, so we can add
                  them as quickly as possible
                </p>

                <p className="mt-50 subheading_1">Name</p>

                <div className="missingData_select mt-10">
                  <TextField
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    placeholder="Name the missing Emission Factor"
                    fullWidth
                  />

                  <p className="overline_text mt-12">
                    Please give the emission factor you are missing a name like
                    for example “raw aliminum”.
                  </p>

                  <p className="subheading_1 mt-32">Description</p>

                  <textarea
                    className="mt-10 border-ef p-8 w-100"
                    type="textarea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows="5"
                    placeholder="Describe the Emission Factor you are missing"
                  ></textarea>

                  <p className="overline_text mt-12">
                    Give us some context around the emission factor you need.
                  </p>

                  <div className="mt-60">
                    <ButtonComp
                      status={1}
                      onClick={handleSave}
                      className="md p-10x-40y"
                    >
                      Send
                    </ButtonComp>

                    <ButtonComp
                      status={3}
                      onClick={() => resetMissingDataStates()}
                      className="text-black ml-20 p-10x-40y"
                    >
                      Cancel
                    </ButtonComp>
                  </div>
                </div>
              </div>
            </div>
          )}
          {dataSubmitted && (
            <div className="row container_design_1 container ml-40 mt-40 p-40 w-90">
              <div className="col-lg-8 col-md-9">
                <p className="font_40_700">📧 Successfully Sent</p>

                <p className="subheading_light mt-22">
                  Because we issue a carbon neutrality label for services, it is
                  our duty to understand why a mandatory subscope cannot be
                  accounted for. We will go ahead and take a look at your issue
                  and get back to you, if we know how you could still account
                  the emissions.
                </p>

                <div className="mt-40">
                  <ButtonComp
                    status={1}
                    onClick={handleCloseMissingDrawer}
                    className="md"
                  >
                    Continue Logging <i className="bi bi-arrow-right"></i>
                  </ButtonComp>
                </div>
              </div>

              <div className="col-lg-4 col-md-3 text-center">
                <img
                  src={botAvatarGroup[user?.bot_avatar_id]?.noActiveOffset.url}
                  style={{ height: 330, width: "auto" }}
                />
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}

export default MissingData;
