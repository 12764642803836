import React, { useEffect, useState } from "react";
import {
  AddCircleOutlineOutlined,
  KeyboardBackspaceOutlined,
  RemoveCircleOutlineOutlined,
} from "@mui/icons-material";
import { Slider, useTheme } from "@mui/material";
import Cropper from "react-easy-crop";
import ButtonComp from "../../../Assets/components/Button";
import "./index.css";

const CompnayBGCropper = ({
  image,
  onCropDone,
  onCropCancel,
  ratio,
  infoStr,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [zoomPercent, setZoomPercent] = useState(0);
  const [croppedArea, setCroppedArea] = useState(null);
  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };
  const theme = useTheme();
  const fixedRatio = 14 / 5;

  useEffect(() => {
    let diff = zoom - 1;
    const mainDiff = 2; // 3-1
    const per = (diff * 100) / mainDiff;
    setZoomPercent(per);
  }, [zoom]);
  return (
    <div className="">
      <div className="d-flex align-items-center">
        <KeyboardBackspaceOutlined className="pointer" onClick={onCropCancel} />
        <p className="font_16_500 ml-30">{infoStr || "Edit Cover Image"}</p>
      </div>
      <div
        style={{ height: 380, width: "100%" }}
        className="position-relative mt-16"
      >
        <Cropper
          image={image}
          aspect={ratio ? ratio : fixedRatio}
          crop={crop}
          zoom={zoom}
          cropShape="rect"
          showGrid={true}
          onCropChange={setCrop}
          onZoomChange={setZoom}
          onCropComplete={onCropComplete}
          style={{
            containerStyle: {
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              borderRadius: "10px",
            },
            mediaStyle: {
              borderRadius: 10,
            },
            cropAreaStyle: {
              borderRadius: 10,
              border:"2px solid #00915B"
              //   color: "#fff",
              //   boxShadow: "0px 0px 16px 0px rgba(0, 0, 0, 0.04)",
            },
          }}
        />
      </div>

      <div className="mt-20 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <RemoveCircleOutlineOutlined
            onClick={() => {
              if (zoom >= 1.5) {
                setZoom((prev) => prev - 0.5);
              } else if (zoom >= 1 && zoom <= 1.5) {
                setZoom(1);
              }
            }}
            htmlColor="#7E8999"
            sx={{ height: 15, width: 15 }}
          />
          <Slider
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="companyBg_slider ml-10 mr-10"
            sx={{
              minWidth: 200,
              color:
                theme.palette.mode === "dark" ? "#fff" : "rgba(0,0,0,0.87)",
              "& .MuiSlider-track": {
                border: "none",
                color: "#7e8999",
              },
              "& .MuiSlider-root": {
                color: "#d9d9d9",
                backgroundColor: "#d9d9d9",
              },
              "& .MuiSlider-colorPrimary": {
                color: "#d9d9d9",
                backgroundColor: "#d9d9d9",
              },
              "& .MuiSlider-thumb": {
                width: 11,
                height: 11,
                backgroundColor: "#7e8999",
                "&:before": {
                  boxShadow: "none",
                },
                "&:hover, &.Mui-focusVisible, &.Mui-active": {
                  boxShadow: "none",
                },
              },
            }}
          />
          <AddCircleOutlineOutlined
            onClick={() => {
              if (zoom <= 3 && zoom >= 2.5) {
                setZoom(3);
              } else if (zoom < 2.5) {
                setZoom((prev) => prev + 0.5);
              }
            }}
            htmlColor="#7E8999"
            sx={{ height: 15, width: 15 }}
          />
          <p className="font_14_500 line_height_24 " style={{ marginLeft: 5 }}>
            {Math.ceil(zoomPercent)}%
          </p>
        </div>
        <ButtonComp
          status={5}
          onClick={() => {
            onCropDone(croppedArea);
          }}
        >
          Save
        </ButtonComp>
      </div>
    </div>
  );
};

export default CompnayBGCropper;
