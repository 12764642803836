import React, { useEffect } from "react";
import { useAuth } from "../Auth";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import NotFoundPage from "../../Pages/notFoundPage";

export default function AdminCheck() {
  const { isAdmin, isAdminLoading } = useAuth();
  const history = useNavigate();

  useEffect(() => {
    if (!isAdminLoading) {
      if (isAdmin) {
        history("/admin/dashboard");
      } else {
        <NotFoundPage />;
      }
    }
  }, [isAdminLoading]);

  return (
    <div className="v_center">
      <CircularProgress color="success" />
    </div>
  );
}
