import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const COLORS = ["#BCE0FF", "#79C1FF", "#FFCB11"];

const ScopePlaceholder = () => {
  const data = [
    { name: "Scope 1", value: 400 },
    { name: "Scope 2", value: 300 },
    { name: "Scope 3", value: 300 },
  ];
  return (
    <ResponsiveContainer height={270} minWidth={150}>
      <PieChart>
        <Pie
          data={data}
          cx={"50%"}
          cy={"50%"}
          innerRadius={75}
          outerRadius={115}
          fill="#8884d8"
          paddingAngle={3}
          dataKey="value"
          startAngle={360}
          endAngle={0}
        >
          {data.map((entry, index) => (
            <Cell opacity={0.5} key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <text
          x={"48%"}
          dy={"51%"}
          fill="#000"
          fontSize={30}
          fontFamily="Inter"
          fontWeight="700"
          opacity={0.5}
        >
          3
        </text>
        <text
          x={"45%"}
          dy={"56%"}
          fill="#000"
          fontSize={13.5}
          fontFamily="Inter"
          fontWeight="600"
          opacity={0.5}
        >
          Scopes
        </text>
      </PieChart>
    </ResponsiveContainer>
  );
};

export default ScopePlaceholder;
