import {
  Backdrop,
  Box,
  Fade,
  Modal,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { HeadCell, StyledHeadRow } from "../../../Utils/TableComp";
import axios from "../../../Api";
import { useAuth } from "../../../Utils/Auth";
import { toastResponseError } from "../../../Utils/toastResponseError";
import { modalStyle } from "../../../Utils/CommonFunctions";
import { toast } from "react-hot-toast";
import AddressRow from "./AddressRow";
import { useMemo } from "react";
import ButtonComp from "../../../Assets/components/Button";
import { useTranslation } from "react-i18next";

const Billing = () => {
  const [addresses, setAddresses] = useState([]);
  const { setSessionExpire } = useAuth();
  const {t} = useTranslation()
  // billing address modal variables
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [streetNumber, setStreetNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setcountries] = useState([]);
  const [states, setstates] = useState([]);
  const [cities, setcities] = useState([]);
  const [VATnumber, setVATnumber] = useState("");
  const [countrycode, setcountrycode] = useState();
  const [statecode, setstatecode] = useState();
  const [isDefault, setisDefault] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [editAddressId, seteditAddressId] = useState(null);

  // pagination states
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const getAddresses = async () => {
    await axios({
      url: `/address/company-address`,
      method: "get",
    })
      .then((res) => {
        setAddresses(res.data.data);
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };
  const fetchcountries = async () => {
    const response = await axios.get(`/location/countries`);
    setcountries(response.data);
  };
  const fetchstates = async () => {
    const response = await axios.get(`/location/country/${countrycode}`);
    setstates(response.data);
  };

  const fetchcities = async () => {
    const response = await axios.get(
      `/location/cities/${countrycode}/${statecode}`
    );
    setcities(response.data);
  };

  useEffect(() => {
    if (!countries || !countries.length > 0) fetchcountries();
  }, []);

  useEffect(() => {
    if (countrycode) fetchstates();
  }, [countrycode]);

  useEffect(() => {
    if (statecode) fetchcities();
  }, [statecode]);

  useEffect(() => {
    getAddresses();
  }, []);

  const handleAddresses = async () => {
    if (
      !firstName ||
      !lastName ||
      !email ||
      !country ||
      !state ||
      !city ||
      !company ||
      !zipCode
    ) {
      return toast.error("Please fill all the required fields");
    }

    setDisabled(true);
    await axios({
      url: editAddressId
        ? `/address/edit-address/${editAddressId}`
        : "/address/add-address",
      method: editAddressId ? "put" : "post",
      data: {
        firstName: firstName,
        lastName: lastName,
        emailAddress: email,
        street: streetNumber,
        houseNumber,
        zipCode,
        City: city,
        Country: country,
        countryCode: countrycode,
        stateCode: statecode,
        VATnumber: VATnumber,
        CompanyName: company,
        State: state,
        isDefault: isDefault,
      },
    })
      .then((res) => {
        setDisabled(false);
        if (res.data.success) {
          toast.success(res.data.message);
          getAddresses();
          handleClose();
          seteditAddressId(null);
          setFirstName("");
          setLastName("");
          setEmail("");
          setCity("");
          setState("");
          setCountry("");
          setcountrycode("");
          setstatecode("");
          setStreetNumber("");
          setZipCode("");
          setHouseNumber("");
          setVATnumber("");
          setCompany("");
          setisDefault("");
        }
      })
      .catch((err) => {
        setDisabled(false);
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleEditModalOpen = (address) => {
    seteditAddressId(address._id);
    setFirstName(address.firstName);
    setLastName(address.lastName);
    setEmail(address.emailAddress);
    setCity(address.City);
    setState(address.State);
    setCountry(address.Country);
    setcountrycode(address.countryCode);
    setstatecode(address.stateCode);
    setStreetNumber(address.street);
    setZipCode(address.houseNumber);
    setHouseNumber(address.zipCode);
    setVATnumber(address.VATnumber);
    setCompany(address.CompanyName);
    setisDefault(address.isDefault);
    handleOpen();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows = useMemo(
    () => addresses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [page, rowsPerPage, addresses]
  );

  return (
    <div className="user_box mt-56">
      <div className="border-btm_ef pb-24">
        <p className="offsetProjectTitle">{t("settings.billingAddress")}</p>
      </div>
      <div className="mt-32 text-end">
        <ButtonComp status={2} className="" onClick={handleOpen}>
          + New Address
        </ButtonComp>
      </div>
      <div>
        <TableContainer
          component={Paper}
          sx={{ minWidth: 640, borderRadius: "9px", boxShadow: "none" }}
          className="mr-50 mt-20"
        >
          <Table sx={{ minWidth: 640 }} aria-label="simple table">
            <TableHead>
              <StyledHeadRow>
                <HeadCell
                  sx={{ borderLeft: "0" }}
                  component="th"
                  className="text-uppercase"
                >
                  {t("common.company")}
                </HeadCell>
                <HeadCell component="th" className="text-uppercase">
                {t("common.name")} / {t("common.email")}
                </HeadCell>
                <HeadCell component="th" className="text-uppercase">
                  {t("settings.address")}
                </HeadCell>
                <HeadCell
                  component="th"
                  className="text-uppercase"
                  sx={{ borderRight: "0" }}
                >
                  {t("settings.vat")}
                </HeadCell>
              </StyledHeadRow>
            </TableHead>
            <TableBody>
              {visibleRows &&
                visibleRows.length > 0 &&
                visibleRows.map((address) => (
                  <AddressRow
                    getAddresses={getAddresses}
                    address={address}
                    key={address._id}
                    handleEditModalOpen={handleEditModalOpen}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {addresses && addresses.length > 5 && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={addresses.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            className="table-pagiation"
          />
        )}
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={{ ...modalStyle, top: "50%" }}>
            <div className="p-24">
              <div className="pb-24 border-btm_dc mb-24">
                <p className="offsetProjectTitle">New Billing Address</p>
              </div>
              <div className="pb-24 border-btm_dc mb-24">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="input_div flex-grow-1">
                      <label className="subheading_1" htmlFor="newPassword">
                        FIRST NAME
                      </label>
                      <input
                        type="text"
                        name="fname"
                        className="mt-12 subheading_2"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="input_div">
                      <label className="subheading_1" htmlFor="newPassword">
                        LAST NAME
                      </label>
                      <input
                        type="text"
                        name="lname"
                        className="mt-12 subheading_2"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="input_div">
                      <label className="subheading_1" htmlFor="newPassword">
                        E-MAIL ADDRESS
                      </label>
                      <input
                        type="email"
                        name="email"
                        className="mt-12 subheading_2"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-24 border-btm_dc mb-24">
                <div className="row">
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="input_div flex-grow-1">
                      <label className="subheading_1" htmlFor="newPassword">
                        Company
                      </label>
                      <input
                        type="text"
                        className="mt-12 subheading_2"
                        placeholder="Kvadrat LLC."
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="input_div">
                      <label className="subheading_1" htmlFor="newPassword">
                        Street & Number
                      </label>
                      <input
                        type="text"
                        className="mt-12 subheading_2"
                        placeholder="Bahrenfelder Straße 10"
                        value={streetNumber}
                        onChange={(e) => setStreetNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12">
                    <div className="input_div">
                      <label className="subheading_1" htmlFor="newPassword">
                        House Number
                      </label>
                      <input
                        type="text"
                        className="mt-12 subheading_2"
                        placeholder="123456"
                        value={houseNumber}
                        onChange={(e) => setHouseNumber(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-24">
                    <div className="input_div">
                      <label className="subheading_1" htmlFor="newPassword">
                        Zip Code
                      </label>
                      <input
                        type="text"
                        className="mt-12 subheading_2"
                        placeholder="123456"
                        value={zipCode}
                        onChange={(e) => setZipCode(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-24">
                    <div className="input_div">
                      <label className="subheading_1">Country</label>
                      <select
                        className="mt-12 subheading_2"
                        value={`${countrycode}` + "#" + `${country}`}
                        onChange={(e) => {
                          setCountry(e.target.value.split("#")[1]);
                          setcountrycode(e.target.value.split("#")[0]);
                        }}
                      >
                        <option value="">Choose Country</option>
                        {countries &&
                          countries.map((option, i) => (
                            <option
                              className=""
                              key={i}
                              value={`${option.iso2}` + "#" + `${option.name}`}
                            >
                              {option.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-24">
                    <div className="input_div">
                      <label className="subheading_1">State</label>
                      <select
                        className="mt-12 subheading_2"
                        value={`${statecode}` + "#" + `${state}`}
                        onChange={(e) => {
                          setState(e.target.value.split("#")[1]);
                          setstatecode(e.target.value.split("#")[0]);
                        }}
                      >
                        <option value="">Choose State</option>
                        {states &&
                          states.map((option, i) => (
                            <option
                              className=""
                              key={i}
                              value={`${option.iso2}` + "#" + `${option.name}`}
                            >
                              {option.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-24">
                    <div className="input_div">
                      <label className="subheading_1">City</label>
                      <select
                        className="mt-12 subheading_2"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                      >
                        <option value="">Choose City</option>
                        {cities &&
                          cities.map((option, i) => (
                            <option className="" key={i} value={option.name}>
                              {option.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 col-12 mt-24">
                    <div className="input_div">
                      <label className="subheading_1" htmlFor="newPassword">
                        VAT
                      </label>
                      <input
                        type="text"
                        className="mt-12 subheading_2"
                        placeholder="DE********"
                        value={VATnumber}
                        onChange={(e) => setVATnumber(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    value={isDefault}
                    onChange={(e) => setisDefault(e.target.checked)}
                    name="defaultAddress"
                    id="defaultAddress"
                  />
                  <label htmlFor="defaultAddress" className="body_text_s_light">
                    {" "}
                    &nbsp; Make this the default billing address
                  </label>
                </div>
                {editAddressId ? (
                  <ButtonComp
                    status={1}
                    className="lg"
                    disabled={disabled}
                    onClick={handleAddresses}
                  >
                    Edit Address
                  </ButtonComp>
                ) : (
                  <ButtonComp
                    status={1}
                    className="lg"
                    disabled={disabled}
                    onClick={handleAddresses}
                  >
                    Add Address
                  </ButtonComp>
                )}
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Billing;
