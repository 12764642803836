import React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import celebrationImage from "../../../../../../../../../Assets/images/celebration_image.png";
import ButtonComp from "../../../../../../../../../Assets/components/Button";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function SubmitSurveyPopup(props) {
  return (
    <BootstrapDialog
      onClose={props.handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <div className=" ml-50 mr-50">
        <div className="d-flex align-items-center mt-70">
          <div>
            <img className="w-30px h-30px" src={celebrationImage} />
          </div>
          <p className="font_40_700 ml-20 ">Survey Completed!</p>
        </div>

        <div className="mt-40">
          <p className="font_20_500 color_54">
            Thank you for participating in this survey. Your information has
            been received. Together, we&apos;re making a positive impact.
          </p>
        </div>
        <div className="d-flex justify-content-end mt-40 mb-50">
          <ButtonComp
            status={5}
            onClick={() => {
              if (props.isForInternal) {
                props.handleCloseDrawer();
                props.handleClose()
              } else {
                window.location.replace("https://today.green");
              }
            }}
          >
            Continue
          </ButtonComp>
        </div>
      </div>
    </BootstrapDialog>
  );
}

export default SubmitSurveyPopup;
