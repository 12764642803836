import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import startingPointImg from "../../../../../../../../Assets/images/Starting Point.png";
import stopOverImg from "../../../../../../../../Assets/images/Stopover.png";
import destinationIcon from "../../../../../../../../Assets/images/Destination.png";
import ButtonComp from "../../../../../../../../Assets/components/Button";

function GetPath(props) {
  const [path, setPath] = useState(null);
  const [travelMode] = useState("DRIVING");
  const [startDestination, setStartDestination] = useState("");
  const [endDestination, setEndDestination] = useState("");
  const [origin, setOrigin] = useState(null);
  const [pathChanged, setPathChanged] = useState(false);
  const [destination, setDestination] = useState(null);
  const [wayPoints, setWayPoints] = useState([]);
  const [wayPointsCount, setWayPointsCount] = useState(0);
  const [wayPointsLabel, setWayPointsLabel] = useState([]);
  const [wayPointComp, setWayPointComp] = useState([]);
  //const [pathSum, setPathSum] = useState(0);
  const directionsCallback = (response) => {
    if (!pathChanged) return;
    setPathChanged(false);
    if (response !== null) {
      if (response.status === "OK") {
        setPath(response);
        return;
      }
    }
  };
  const placeChange = (place, index) => {
    if (place == null) return;
    let curr = [...wayPoints];
    curr[index] = place;
    setWayPoints(curr);
  };

  const handleWayPointChange = () => {
    let curr = [...wayPoints];
    curr[wayPointsCount] = null;
    let y = curr.map((currPoint, index) => {
      return (
        <div key={index} className="mt-20">
          <div className="d-flex align-items-center mb-10">
            <img src={stopOverImg} height={15} width={15} />
            <p className="subheading_1 ml-4">Stopover</p>
          </div>
          <GooglePlacesAutocomplete
            debounce={800}
            apiKey={process.env.REACT_APP_GOOGLE_MAP}
            selectProps={{
              currPoint,
              onChange: (e) => placeChange(e, index),
              placeholder: "Add wayPoint",
              styles: {
                container: (p) => ({
                  ...p,
                  boxSizing: `border-box`,
                  border: `1px solid #efefef`,
                  borderRadius: `3px`,
                  fontSize: `14px`,
                  outline: `none`,
                  textOverflow: `ellipses`,
                }),
                indicatorsContainer: (p) => ({
                  ...p,
                  display: "none",
                }),
                input: (p) => ({
                  ...p,
                  // padding: "8px",
                }),
              },
            }}
          />
          <p className="outline_text mt-20">Enter the address of stop over</p>
        </div>
      );
    });
    setWayPointComp(y);
    setWayPointsCount(wayPointsCount + 1);
    setWayPoints(curr);
  };
  //   useEffect(() => {
  //     if (startDestination)
  //       geocodeByAddress(startDestination.label)
  //         .then((results) => getLatLng(results[0]))
  //         .then(({ lat, lng }) => {
  //           setStartCoords({ lat:parseFloat(lat), lng:parseFloat(lng) });
  //         });
  //   }, [startDestination]);

  //   useEffect(() => {
  //     if (endDestination)
  //       geocodeByAddress(endDestination.label)
  //         .then((results) => getLatLng(results[0]))
  //         .then(({ lat, lng }) => {
  //           setEndCoords({ lat: parseFloat(lat),lng:parseFloat(lng) });
  //         });
  //   }, [endDestination]);

  useEffect(() => {
    getDirectionAndDistance();
  }, [origin, destination, wayPoints]);

  const getDirectionAndDistance = () => {
    if (origin && destination && origin.label && destination.label) {
      setPathChanged(true);
      setStartDestination(origin.label);
      setEndDestination(destination.label);
      let emptyField = false;
      let currLabels = [];
      wayPoints.forEach((point) => {
        if (point != null) {
          currLabels.push({
            location: point.label,
            stopover: true,
          });
        } else {
          emptyField = true;
          //  toast.error("please fill the stop over point");
          return;
        }
      });
      if (emptyField) return;
      setWayPointsLabel(currLabels);
      try {
        //  var service = new window.google.maps.DistanceMatrixService();
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: origin.label,
            destination: destination.label,
            waypoints: currLabels.map((waypoint) => ({
              location: waypoint.location,
            })),
            travelMode: "DRIVING",
          },
          calculateFinalDistance
        );
      } catch (err) {
        toast.error("error occurred while calculating distance");
      }
    }
  };

  function calculateFinalDistance(response, status) {
    if (status === "OK") {
      let currDistSum = 0;
      response.routes[0].legs.forEach((route) => {
        currDistSum += route.distance.value;
      });
      props.handleAmountChange(currDistSum, props.index); //metres
      if (props.unit == "km") {
        props.handleAmountChange(currDistSum / 1000, props.index);
      } else {
        props.handleAmountChange(currDistSum * 0.000621371192, props.index);
      }
    }
  }

  return (
    <div>
      <div>
        <hr className="mt-0 mb-3" />
      </div>
      <div className="row p-16">
        <div className="col-md-7">
          {/* Origin input Start*/}
          <div className="w-90">
            <div className="d-flex align-items-center mb-10">
              <img src={startingPointImg} height={15} width={15} />
              <p className="subheading_1 ml-4">Starting Point</p>
            </div>
            <GooglePlacesAutocomplete
              debounce={800}
              apiKey={process.env.REACT_APP_GOOGLE_MAP}
              selectProps={{
                origin,
                onChange: setOrigin,
                placeholder: "Starting Point Address",
                styles: {
                  container: (p) => ({
                    ...p,
                    boxSizing: `border-box`,
                    border: `1px solid #efefef`,
                    borderRadius: `5px`,
                    fontSize: `15px`,
                    fontWeight: "400",
                    boxShadow: "none",
                    fontFamily: '"Inter", sans-serif',
                    outline: `none`,
                  }),
                  indicatorsContainer: (p) => ({
                    ...p,
                    display: "none",
                  }),
                  input: (p) => ({
                    ...p,
                    // padding: "8px",
                  }),
                },
              }}
            />
            <p className="overline_text mt-20">
              Enter the address of the starting point{" "}
            </p>
          </div>
          {/* Origin input end*/}

          <div className="w-90">{wayPointComp.map((stopOver) => stopOver)}</div>
          {/* Destination input Starts*/}
          <div className="mt-20 w-90">
            <div className="d-flex align-items-center mb-10">
              <img src={destinationIcon} height={15} width={15} />
              <p className="subheading_1 ml-4">Destination</p>
            </div>

            <GooglePlacesAutocomplete
              placeholder="Destination Address"
              debounce={800}
              apiKey={process.env.REACT_APP_GOOGLE_MAP}
              selectProps={{
                destination,
                onChange: setDestination,
                placeholder: "Destination Address",
                styles: {
                  container: (p) => ({
                    ...p,
                    boxSizing: `border-box`,
                    border: `1px solid #efefef`,
                    borderRadius: `3px`,
                    fontSize: `14px`,
                    outline: `none`,
                    textOverflow: `ellipses`,
                  }),
                  indicatorsContainer: (p) => ({
                    ...p,
                    display: "none",
                  }),
                  input: (p) => ({
                    ...p,
                    // padding: "8px",
                  }),
                },
              }}
            />
            <p className="overline_text mt-20">
              Enter the address of the destination
            </p>
          </div>
          {/* Destination input End*/}

          <ButtonComp
            status={3}
            onClick={() => handleWayPointChange()}
            className="text-black p-30x-10y border_radius_5 mt-20"
          >
            <i className="bi bi-plus"></i> Add Stop
          </ButtonComp>
        </div>

        <div className="col-md-5">
          <GoogleMap
            mapContainerStyle={{
              height: "300px",
              width: "100%",
              borderRadius: "10px",
            }}
            zoom={2}
            center={{
              lat: 0,
              lng: -180,
            }}
            options={{
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
          >
            {endDestination !== "" && startDestination !== "" && (
              <DirectionsService
                // required
                options={{
                  destination: endDestination,
                  origin: startDestination,
                  travelMode: travelMode,
                  waypoints: wayPointsLabel,
                }}
                // required
                callback={directionsCallback}
              />
            )}

            {path !== null && (
              <DirectionsRenderer
                // required
                options={{
                  directions: path,
                }}
              />
            )}
          </GoogleMap>
        </div>
      </div>
    </div>
  );
}
export default GetPath;
