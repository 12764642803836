import React from "react";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../Utils/Auth";
import ButtonComp from "../../../Assets/components/Button";

const NotFound = () => {
  const history = useNavigate();
  const { user } = useAuth();
  return (
    <div className="not_found_pageRoot">
      <div className="not_found_pagecard d-flex align-items-center">
        <div className="flex-grow-1">
          <p className="heading_lg">404</p>
          <div className="subtitle_box bg_soft_blue width_fit mt-24">
            <p className="color_blue font_13_600">Don&apos;t worry!</p>
          </div>
          <p className="subheading_light mt-16">
            Ooops...Looks like something wen&apos;t wrong here, sorry! <br />
            Let&apos;s take you home again! 💙
          </p>
          <hr className="mt-24 mb-24" />
          <ButtonComp
            className="lg"
            status={1}
            onClick={() => {
              if (!user) history("/");
              else history("/dashboard");
            }}
          >
            Go to Dashboard
          </ButtonComp>
        </div>
        <div>
          <img
            src="https://tg-icons.s3.eu-central-1.amazonaws.com/404.png"
            alt="not-found"
          />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
