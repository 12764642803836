import React from "react";
import ThreeUpArrow from "../../../../../../Assets/icons/ThreeUpArrow";
import TwoUpArrow from "../../../../../../Assets/icons/TwoUpArrow";
import TopChevronIcon from "../../../../../../Assets/icons/TopChevronIcon";
import { Avatar, AvatarGroup } from "@mui/material";
import TickMark from "../../../../../../Assets/icons/tickMark";
import moment from "moment";
import { memo } from "react";

const prioritySelect = {
  high: "high",
  medium: "medium",
  low: "low",
};

const typeSelected = {
  todo: "Todo",
  progress: "In Progress",
  complete: "Completed",
};

const GridViewCard = ({
  title,
  priority,
  type,
  name,
  scope,
  date,
  assignee,
  invitedAssignee,
  handleEdit
}) => {
  return (
    <div
      className="task_grid_card"
      onClick={handleEdit}
    >
      <div className="between">
        <p className="font_14_500">{title || ""}</p>
        {typeSelected.todo === type ? (
          <div className="circle_20 bg_d9"></div>
        ) : typeSelected.progress === type ? (
          <div className="circle_20 center bg_d9">
            <TickMark cc={"#fff"} />
          </div>
        ) : (
          <div className="circle_20 center bg_green_07">
            <TickMark cc={"#fff"} />
          </div>
        )}
      </div>
      <div className="between mt-20">
        <div className="d-flex align-items-center">
          {prioritySelect.high === priority ? (
            <ThreeUpArrow />
          ) : prioritySelect.medium === priority ? (
            <TwoUpArrow />
          ) : (
            <TopChevronIcon />
          )}
          <p
            className="text-capitalize font_12_600 color_7e"
            style={{ marginLeft: 5 }}
          >
            {priority} Priority
          </p>
        </div>
        <p className="font_12_600 color_57 line_height_10">
          {moment(date).format("DD.MM.YYYY")}
        </p>
      </div>
      <hr className="mt-30 mb-10" />
      <div className="between">
        <div className="align_center">
          {scope ? (
            <div className="h-20px w-35px bg_soft_blue_bc border_radius_100 center px-10">
              <p className="font_13_600 text-white">{scope}</p>
            </div>
          ) : null}
          <p
            className="font_12_500 line_height_normal color_57"
            style={{ marginLeft: 5 }}
          >
            {name}
          </p>
        </div>
        {assignee.length + invitedAssignee.length > 0 && (
          <AvatarGroup
            sx={{
              flexDirection: "row-reverse",
              "&.MuiAvatarGroup-root .MuiAvatar-root ": {
                // marginLeft: "-8px",
                height: 30,
                width: 30,
                color: "#000",
                fontSize: "13px",
                fontWeight: 700,
                lineHeight: "46px",
                border: "1px solid #fff",
                backgroundColor: "#e7e7e7",
              },
            }}
            max={3}
          >
            {assignee.length > 0 &&
              assignee.map((user) => (
                <Avatar key={user._id} src={user.avatar} />
              ))}
            {invitedAssignee.length > 0 &&
              invitedAssignee.map((user) => (
                <Avatar key={user._id}>
                  {String(user.firstName).charAt(0)}
                </Avatar>
              ))}
          </AvatarGroup>
        )}
      </div>
    </div>
  );
};

export default memo(GridViewCard);
