import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { KeyboardArrowLeft } from "@mui/icons-material";
import useQuery from "../../../../../Utils/query";
import ScopeDetailsTable from "./Components/scopeDetailsTable";
import StoreCard from "../StoreCard";
// import meetingRoomImage from "../../../../../Assets/images/store_our_services.png";
import ItemDetails from "../../../LogDashboard/items-new.json";
import { toastResponseError } from "../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../Utils/Auth";
import Axios from "../../../../../Api";
import TickMark from "../../../../../Assets/icons/tickMark";
import MinusIcon from "../StoreCard/MinusIcon";
import PlusIcon from "../StoreCard/PlusIcon";
import ProductDetailsTable from "./Components/productDetailsTable";
import HorizontalLabel from "../../../../../Assets/components/CarbonNuetrolLabel/HorizontalLabel";
import { useTranslation } from "react-i18next";
import ButtonComp from "../../../../../Assets/components/Button";
// import ButtonComp from "../../../../../Assets/components/Button";

function ItemDetailsPage({
  cartDetails,
  setCartDetails,
  userCart,
  setUserCart,
}) {
  const { setSessionExpire,user } = useAuth();
  const query = useQuery();
  const itemId = query.get("item");
  const [otherItemDetails, setOtherItemDetails] = useState([]);
  const [itemInfo, setItemInfo] = useState({});
  const handleQuantityChange = async (id, quantity) => {
    try {
      var response = await Axios.patch(
        `/cart/store-cart-update/${userCart._id}`,
        {
          itemId: id,
          quantity: quantity,
        }
      );
      if (response.data.success) {
        setcurQuantity(quantity);
        setUserCart(response.data.cart);
        setCartDetails(response.data.cart.items);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  const getOtherItemsData = async () => {
    if (!itemInfo.category) return;
    try {
      const response = await Axios.get(`/items/all`);
      if (response.data.success) {
        let currItemDetails = response.data.items
          .filter(
            (currItem) =>
              currItem.category.toLowerCase() ==
                itemInfo.category.toLowerCase() && currItem._id != itemInfo._id
          )
          .slice(0, 2);
        setOtherItemDetails(currItemDetails);
      }
    } catch (error) {
      toastResponseError(error, setSessionExpire);
    }
  };
  useEffect(() => {
    if (itemId && itemId.length) {
      //   let currItem = {}
      ItemDetails.forEach((currItem) => {
        if (currItem._id == itemId) {
          setItemInfo({
            _id: currItem._id,
            name: currItem.name,
            price: currItem.price,
            image: currItem.image,
            description: currItem.description,
            category: currItem.category,
            thumbnailImage: currItem.thumbnailImage,
            coverImage: currItem.coverImage,
            labelImage: currItem.labelImage,
            scopes: currItem.scopes,
            germanDescription:currItem.germanDescription,
            name_de:currItem.name_de
          });
        }
      });
    }
  }, [itemId]);

  useEffect(() => {
    getOtherItemsData();
  }, [itemInfo]);

  const [curQuantity, setcurQuantity] = useState(0);

  useEffect(() => {
    if (cartDetails) {
      var itemFind = cartDetails.find((items) => items.itemId === itemId);

      if (itemFind) {
        setcurQuantity(itemFind.quantity);
      } else {
        setcurQuantity(0);
      }
    }
  }, [itemId]);

  const {t} = useTranslation()
  return (
    <div>
      <p className="breadcrumb_text_light_1 text_capital mt-44">
        <Link to={`/dashboard?tab=9`}>
          <span className="color_light_2 mb-2">
            <KeyboardArrowLeft fontSize="small" htmlColor="#8a8a8a" />
          </span>
          <span className="color_light_2">store </span>
          <span className="color_light_2">/ general items </span>
        </Link>{" "}
        <span className="fw-700 text-uppercase"> / {itemInfo.name}</span>
      </p>

      <div className="row mt-50">
        <div className="col-md-8 d-flex flex-column">
          <div className="flow-grow-1">
            <div className="item_profile_cover_img_bg">
              <img src={itemInfo?.image} />
            </div>

            <p className="body_text_m mt-20">{user?.language === 'de'?itemInfo?.germanDescription:itemInfo.description}</p>
          </div>

          <div className="mt-60">
            <p className="font_17_700 text_capital mb-30">{t("store.INCLUDEDSCOPES")}</p>
            {String(itemInfo.name).toLowerCase() === "product" ? (
              <ProductDetailsTable tableData={itemInfo.scopes} />
            ) : (
              <ScopeDetailsTable tableData={itemInfo.scopes} />
            )}
          </div>

          <div className="" style={{ marginTop: 59 }}>
            <p className="font_17_700 text_capital">{t("store.label")}</p>

            <div className="d-flex gap_60 mt-10 align-items-center">
              <p className="font_14_400 line_height_22 flex-1">
              {t("store.carbonLabelText")}
              </p>
              <div className="item_details_label_card">
                <HorizontalLabel item={user.language === 'de' ?itemInfo.name_de:itemInfo.name} isProfile_zero={false} />
              </div>
            </div>
          </div>

          <div className="mt-60">
            <hr />
          </div>

          <div className="mt-60 pb-32">
            <p className="font_17_700 text_capital">{t("store.OTHERACCOUNTINGITEMS")}</p>

            <div className="row mt-30">
              {otherItemDetails.map((currItem, index) => {
                return (
                  <div key={currItem._id + index} className="col-md-6">
                    <StoreCard
                      item={currItem}
                      cartDetails={cartDetails}
                      handleQuantityChange={handleQuantityChange}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          {/* <div
            className="d-flex mt-30 mb-32 border_1_ef border_radius_10"
            style={{ gap: 45 }}
          >
            <div className=" py-20 pl-32">
              <p className="font_36_700_plus_jakarta">Our Services</p>
              <p className="body_text_s color_31 mt-20">
                We provide a number of different services to support you on your
                climate action journey.
              </p>
              <ButtonComp className="mt-20">View</ButtonComp>
            </div>

            <img src={meetingRoomImage} className="h-100 w-100 image-fit" />
          </div> */}
        </div>
        <div className="col ml-50">
          <div
            className="container_design_1 pos_sticky_20"
            style={{ padding: "42px 27px 28px 27px", maxWidth: 358 }}
          >
            <p className="f-20 fw-600 font-inter">{user.language === 'de' ?itemInfo.name_de:itemInfo.name}</p>

            <p className="subheading_light mt-20 mr-46 color_57 line_height_22">
              {t("store.measureAnalysisText")}
              {user.language === 'de' ?itemInfo.name_de:itemInfo.name}.
            </p>

            <div className="mt-32">
              <p className="body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.unlimitedLogs")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.scope")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.ghgReport")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.consultantDatabase")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.Analytics")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.Targets")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.Offset")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark className={"mr-13"} />
                {t("store.monthAccess")}
              </p>

              <p className="mt-8 body_text_s">
                <TickMark cc={"#00915B"} className={"mr-13"} />
                {t("store.carbonNeutralLabel")} Label*
              </p>

              <div className="d-flex align-items-center mt-30">
                <p className="heading_9 d-flex align-items-center">
                  {Number(itemInfo.price).toFixed(2).toLocaleString()} €{" "}
                  <span className="body_text_s color_ba">/{t("common.month")}</span>
                </p>

                <p className="body_text_s color_ba ml-10">({t("store.billedAnnually")})</p>
              </div>
              <div className="d-flex mt-30 justify-content-between">
                <div className="store_card_cart_add_root ">
                  <MinusIcon
                    onClick={() => {
                      if (curQuantity > 0) {
                        handleQuantityChange(itemId, curQuantity - 1);
                      }
                    }}
                  />

                  <span className="font_15_600 flex-1 d-flex align-items-center justify-content-center line_height_normal">
                    {curQuantity}
                  </span>
                  <PlusIcon
                    onClick={() =>
                      handleQuantityChange(itemId, curQuantity + 1)
                    }
                  />
                </div>
                <div>
                  <ButtonComp
                    status={2}
                    onClick={() =>
                      handleQuantityChange(itemId, curQuantity + 1)
                    }
                  >
                    ADD TO CART
                  </ButtonComp>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ItemDetailsPage;
