import React, { useEffect, useState } from "react";
import itemData from "../../../../../LogDashboard/items-new.json";
import CustomToolTip from "../../../../../LogDashboard/Components/SideDrawer/Components/customToolTip";
import { Switch } from "@mui/material";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import TextInputType from "../TextInputType";
import SelectInputType from "../SelectInputType";
import AddressInputType from "../AddressInputType";
import NumberInputType from "../NumberInputType";
import DateInputType from "../DateInputType";
import events from "../../../../../../../DataJSON/DropdownTypes/events.json";
import facilities from "../../../../../../../DataJSON/DropdownTypes/facilities.json";
import industries from "../../../../../../../DataJSON/DropdownTypes/industries.json";
import products from "../../../../../../../DataJSON/DropdownTypes/product.json";
import hotels from "../../../../../../../DataJSON/hotel.json";
import Axios from "../../../../../../../Api";
import toast from "react-hot-toast";
import ButtonComp from "../../../../../../../Assets/components/Button";
import { useMapStore } from "../../../../../../../Utils/CompanyMapStore";
import { toastResponseError } from "../../../../../../../Utils/toastResponseError";
import { useAuth } from "../../../../../../../Utils/Auth";
function DetailTab() {
  const { setSessionExpire } = useAuth();
  const {
    activeNodeData,
    handleAccItemDrawer,
    newNodeAddedLevel1,
    addNewNodeEventData,
    getItemData,
  } = useMapStore();
  const [selectedAccItem, setSelectedAccItem] = useState();
  const [itemFirstQuestionSet, setItemQFirstQuestionSet] = useState([]);
  const [itemSecondQuestionSet, setItemSecondQuestionSet] = useState([]);
  const [baseYearField, setBaseYearField] = useState(false);
  const [answers, setAnswers] = useState([]);
  const [baseYearAccount, setBaseYearAccount] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [baseYear, setBaseYear] = useState(dayjs(new Date()));
  const [accItemList, setAccItemList] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (activeNodeData._id) {
      setIsEdit(activeNodeData.isActivate);
    }
  }, [activeNodeData]);

  useEffect(() => {
    if (isEdit && activeNodeData._id) {
      let accItemName = "";
      itemData.forEach((currItem) => {
        if (currItem._id === activeNodeData.itemId) {
          accItemName = currItem.name;
          setSelectedAccItem(currItem.name);
        }
      });
      // setSelectedAccItem(accItemName)
      let AccItemIndex = itemData.findIndex(
        (currAcc) => currAcc.name === accItemName
      );
      let questions = itemData[AccItemIndex].addService;

      let current_answers = [];
      questions.forEach((ques, index) => {
        if (ques.type === "date") {
          current_answers[index] = dayjs(new Date());

          if (ques.name == "realEstateStartDate") {
            current_answers[index] = dayjs(
              activeNodeData.itemDetails.realEstateStartDate
            );
          } else if (activeNodeData.itemId == "6442af32ca73043a8ec66c7b") {
            if (ques.name == "startDate") {
              let startDate = dayjs(
                `${activeNodeData.year}-${activeNodeData.month}-1`
              );
              current_answers[index] = startDate;
            } else if (ques.name == "endDate") {
              let endDate = dayjs(
                `${activeNodeData.endYear}-${activeNodeData.endMonth}-1`
              );
              current_answers[index] = endDate;
            }
          }
        } else if (ques.name == "name") {
          current_answers[index] = activeNodeData.itemDetails.name;
        } else if (ques.name == "facilityType")
          current_answers[index] = activeNodeData.itemDetails.facilityType;
        else if (ques.name == "productType")
          current_answers[index] = activeNodeData.itemDetails.productType;
        else if (ques.name == "hotelType")
          current_answers[index] = activeNodeData.itemDetails.hotelType;
        else if (ques.name == "industry")
          current_answers[index] = activeNodeData.itemDetails.industry;
        else if (ques.name == "event")
          current_answers[index] = activeNodeData.itemDetails.event;
        else if (ques.name == "equityShare")
          current_answers[index] = activeNodeData.itemDetails.equityShare;
        else if (ques.name == "address") {
          current_answers[index] = activeNodeData.itemDetails.address;
        } else if (ques.name == "numberOfProductSold")
          current_answers[index] =
            activeNodeData.itemDetails.numberOfProductSold;
        else if (ques.name == "M2")
          current_answers[index] = activeNodeData.itemDetails.M2;
        else if (ques.name === "agencyAddress")
          current_answers[index] = activeNodeData.itemDetails.agencyAddress;
        else if (ques.name === "NumberOfEmployees")
          current_answers[index] = activeNodeData.itemDetails.NumberOfEmployees;
        else if (ques.name === "educationalInstitutionAddress")
          current_answers[index] =
            activeNodeData.itemDetails.educationalInstitutionAddress;
      });
      setBaseYear(dayjs(`${activeNodeData.year}-${activeNodeData.month}-1`));
      setItemQFirstQuestionSet(questions);
      setAnswers(current_answers);
    }
  }, [isEdit]);

  useEffect(() => {
    let firstLevelOptions = [
      "Location",
      "Hotel",
      "Real-Estate Agency",
      "Agency",
      "Educational Institution",
      "Skilled Trades Company",
    ];
    let secondLevelOptions = [
      "Product",
      "Service",
      "Building",
      "Real Estate Development",
      "Event",
      "Shipping",
    ];
    let tempAccItem = itemData.map((currItem) => currItem.name);

    if (
      addNewNodeEventData?.data?.childNode &&
      addNewNodeEventData?.data?.childNode.length > 0 &&
      addNewNodeEventData?.data?.level === 1
    ) {
      setAccItemList(firstLevelOptions);
    } else if (
      addNewNodeEventData?.data?.parentNode &&
      addNewNodeEventData?.data?.parentNode.length > 0 &&
      addNewNodeEventData?.data?.level === 2
    ) {
      setAccItemList(secondLevelOptions);
    } else {
      setAccItemList(tempAccItem);
    }
  }, [addNewNodeEventData]);

  const handleAccItemChange = (index, value) => {
    setSelectedAccItem(value);
  };

  const getCurrentQuestionIndex = (index, subIndex) => {
    let current = itemFirstQuestionSet.length;
    for (let i = 0; i < itemSecondQuestionSet.length; i++) {
      if (i < index) {
        current += itemSecondQuestionSet[i].questions.length;
      }
    }
    return current + subIndex;
  };

  const handleToggleChange = (value, index, subIndex) => {
    let currentIndex = getCurrentQuestionIndex(index, subIndex);

    let prevAnswers = [...answers];
    prevAnswers[currentIndex] = value;

    if (itemSecondQuestionSet[index].questions.length > subIndex + 1)
      prevAnswers[currentIndex + 1] = "";

    setAnswers(prevAnswers);
  };
  const getLocations = async () => {
    const response = await Axios.get(
      `/log/get-log?itemId=${`6442af32ca73043a8ec66c78`}&year=${new Date().getFullYear()}` // should get data less than equal to selected year
    );
    if (response.data.success) {
      let currLocations = [];
      response.data.logs.forEach((location) => {
        currLocations.push(location.itemDetails.name);
      });

      setLocationList(currLocations);
    }
  };
  const handleAnswerChange = (index, value) => {
    let prevAnswers = [...answers];
    prevAnswers[index] = value;
    setAnswers(prevAnswers);
  };
  const handleBaseYearChange = (year) => {
    setBaseYear(year);
  };
  const handleIsBaseYearChange = (event, value) => {
    event.preventDefault();
    setBaseYearAccount(value);
  };
  const setAnswersToDefaults = (firstQuestionSet, secondQuestionSet) => {
    let current_answers = [];
    let currentIndex = firstQuestionSet.length;
    firstQuestionSet.forEach((ques, index) => {
      if (ques.type === "date") {
        current_answers[index] = dayjs(new Date());
      } else current_answers[index] = "";
    });
    secondQuestionSet.forEach((questionSet) => {
      questionSet.questions.forEach((question, subIndex) => {
        if (question.type === "toggle") {
          current_answers[currentIndex + subIndex] = true;
        } else if (question.type === "date") {
          current_answers[currentIndex + subIndex] = dayjs(new Date());
        } else
          current_answers[currentIndex + subIndex] = question.defaultValue
            ? question.defaultValue
            : "";
      });

      currentIndex += questionSet.questions.length;
    });
    setAnswers(current_answers);
  };
  useEffect(() => {
    if (isEdit || !selectedAccItem || selectedAccItem.length == 0) return;
    let AccItemIndex = itemData.findIndex(
      (currAcc) => currAcc.name === selectedAccItem
    );
    setAnswersToDefaults(
      itemData[AccItemIndex].addService,
      itemData[AccItemIndex].secondQuestionSet
    );
    setItemQFirstQuestionSet(itemData[AccItemIndex].addService);
    setItemSecondQuestionSet(itemData[AccItemIndex].secondQuestionSet);
    setBaseYearField(itemData[AccItemIndex].baseYearField);
  }, [selectedAccItem]);

  useEffect(() => {
    getLocations();
  }, []);
  const handleSubmit = async () => {
    if (answers.length == 0) {
      toast.error("please select Accounting item from the list");
      return;
    }
    let currStartDate = new Date();
    let currEndDate = new Date();
    for (let i = 0; i < itemFirstQuestionSet.length; i++) {
      if (
        answers[i] === null ||
        answers[i] === undefined ||
        (typeof answers[i] === "string" && answers[i].trim().length === 0)
      ) {
        toast.error(`Some fields are incomplete`);
        return;
      }
      if (itemFirstQuestionSet[i].name === "startDate")
        currStartDate = answers[i];
      if (itemFirstQuestionSet[i].name === "endDate") currEndDate = answers[i];
    }

    if (currStartDate["$y"] > currEndDate["$y"]) {
      toast.error("start date cannot be greater than end date");
      return;
    }
    if (
      currStartDate["$y"] === currEndDate["$y"] &&
      currStartDate["$M"] > currEndDate["$M"]
    ) {
      toast.error("start date cannot be greater than end date");
      return;
    }
    let currentYear = new Date().getFullYear();
    if (currEndDate["$y"] > currentYear || currStartDate["$y"] > currentYear) {
      toast.error("dates cannot be greater than current running year");
      return;
    }

    if (isEdit) {
      handleEditLog();
      return;
    }
    if (baseYearField && baseYear === "") {
      toast.error("Please fill correct base year");
      return;
    }

    let currentIndex = itemFirstQuestionSet.length;
    let foundError = false;
    itemSecondQuestionSet.forEach((questionSet) => {
      let skipNext = false;
      questionSet.questions.forEach((question, subIndex) => {
        if (skipNext) {
          skipNext;
          skipNext = !skipNext;
        } else if (question.type === "toggle") {
          if (answers[currentIndex + subIndex]) {
            if (
              subIndex + 1 < questionSet.questions.length &&
              answers[currentIndex + subIndex + 1] === ""
            ) {
              toast.error(`${question.name} field is invalid`);
              foundError = true;
              return;
            }
          } else if (answers[currentIndex + subIndex] === undefined) {
            toast.error(`${question.name} field is not valid`);
            foundError = true;
            return;
          }
          skipNext = true;
        } else {
          if (answers[currentIndex + subIndex] === "") {
            toast.error(
              `Please select an option from dropdown ${question.name}`
            );
            foundError = true;
            return;
          }
        }
      });
      currentIndex += questionSet.questions.length;
    });
    if (foundError) return;
    handleAddServiceSubmit();
    // props.handleAddServiceSubmit(
    //   answers,
    //   baseYearAccount,
    //   baseYear["$y"],
    //   baseYear["$M"] + 1
    // );
    // setAnswers({});
    // setBaseYearAccount(false);
    // props.toggleDrawer(false);
  };
  const handleAddServiceSubmit = async () => {
    let baseMonth = baseYear["$M"] + 1;
    let isDoubleAccount = false;
    const Details = {};
    let startYear = 1;
    let startMonth = 1;
    let endYear = 1;
    let endMonth = 1;
    if (!baseYearAccount) startMonth = baseMonth;

    itemFirstQuestionSet.forEach((question, index) => {
      if (question.name == "startDate") {
        startYear = parseInt(answers[index]["$y"]);
        startMonth = parseInt(answers[index]["$M"]) + 1;
      } else if (question.name == "endDate") {
        endYear = parseInt(answers[index]["$y"]);
        endMonth = parseInt(answers[index]["$M"]) + 1;
      } else Details[question.name] = answers[index];
    });

    let currentIndex = itemFirstQuestionSet.length;

    itemSecondQuestionSet.forEach((questionSet) => {
      let skipNext = false;
      questionSet.questions.forEach((question, subIndex) => {
        if (!skipNext) {
          if (question.name === "isDoubleAccount") {
            isDoubleAccount = answers[currentIndex + subIndex];
            if (!answers[currentIndex + subIndex]) {
              skipNext = true;
            }
          } else {
            Details[question.name] = answers[currentIndex + subIndex];
          }
        } else {
          Details[question.name] = "";
          skipNext = false;
        }
      });
      currentIndex += questionSet.questions.length;
    });
    if (startYear == 1) {
      startYear = parseInt(baseYear["$y"]);
    }
    let accItemIndex = itemData.findIndex(
      (currItem) => currItem.name === selectedAccItem
    );

    await Axios.post(`/log/log-create/${itemData[accItemIndex]._id}`, {
      accountingItemName: itemData[accItemIndex].name,
      itemDetails: Details,
      year: startYear,
      month: startMonth,
      endYear: endYear,
      endMonth: endMonth,
      isBaseYear: baseYearAccount,
      isDoubleAccount: isDoubleAccount,
      loggedEmissions: "",
      offsetEmissions: "",
      offsetPeriod: "",
      connectedLogId:
        addNewNodeEventData.data?.level === 1
          ? addNewNodeEventData.data?.childLogId
          : addNewNodeEventData.parentLogId,

      isParent: addNewNodeEventData.data?.level == 1 ? true : false,
    })
      .then((res) => {
        if (res.data.success) {
          handleAccItemDrawer(true, false);
          //if(addNewNodeEventData.data?.level==1)
          newNodeAddedLevel1(res.data.logData);
          getItemData();
          // else if(addNewNodeEventData.data?.level==2)
        }

        toast.success("Log Created");
      })
      .catch((err) => {
        toastResponseError(err, setSessionExpire);
      });
  };

  const handleEditLog = async () => {
    let startYear = 1;
    let startMonth = 1;
    let endYear = 1;
    let endMonth = 1;
    let Details = {};
    itemFirstQuestionSet.forEach((question, index) => {
      if (question.name == "startDate") {
        startYear = parseInt(answers[index]["$y"]);
        startMonth = parseInt(answers[index]["$M"]) + 1;
      } else if (question.name == "endDate") {
        endYear = parseInt(answers[index]["$y"]);
        endMonth = parseInt(answers[index]["$M"]) + 1;
      } else Details[question.name] = answers[index];
    });
    if (startYear == 1) {
      startYear = parseInt(baseYear["$y"]);
      startMonth = parseInt(baseYear["$M"]);
    }
    let dateDetails = {
      month: startMonth,
      year: startMonth,
    };
    if (endYear != 1) {
      dateDetails = {
        ...dateDetails,
        endYear,
        endMonth,
      };
    }
    try {
      await Axios.post(`log/updateLogDetails?logId=${activeNodeData._id}`, {
        logDetails: Details,
        ...dateDetails,
      }).then((res) => {
        if (res.data.success) {
          toast.success("info has been updated");
        }
      });
    } catch (err) {
      toastResponseError(err, setSessionExpire);
    }
  };

  // const getLogName = (logid) => {};

  return (
    <div className="mt-14">
      <div className="mt-20">
        {/* <p className="font_12_600 color_7e">Item Name</p> */}

        {/* <div className="mt-10">
          <TextInputType
            answerIndex={0}
            handleAnswerChange={handleAnswerChange}
            answers={answers}
          />
        </div> */}
      </div>

      <div className="mt-20">
        <p className="font_12_600 color_7e">Item type</p>

        <div className="mt-10">
          <SelectInputType
            options={accItemList}
            selectedValue={selectedAccItem}
            handleAnswerChange={handleAccItemChange}
          />
        </div>
      </div>

      {itemFirstQuestionSet.map((currQuestion, questionIndex) => {
        return (
          <div className="mt-20" key={currQuestion.title + questionIndex}>
            <p className="font_12_600 color_7e">{currQuestion.title}</p>
            <div className="mt-10">
              {currQuestion.type == "text" ? (
                <TextInputType
                  answerIndex={questionIndex}
                  handleAnswerChange={handleAnswerChange}
                  answers={answers}
                />
              ) : currQuestion.type == "select" ? (
                <SelectInputType
                  answerIndex={questionIndex}
                  handleAnswerChange={handleAnswerChange}
                  selectedValue={answers[questionIndex]}
                  answers={answers}
                  options={
                    currQuestion.name.toLowerCase() === "industry"
                      ? industries
                      : currQuestion.name.toLowerCase() === "event"
                      ? events
                      : currQuestion.name.toLowerCase() === "facilitytype"
                      ? facilities
                      : currQuestion.name.toLowerCase() === "producttype"
                      ? products
                      : currQuestion.name.toLowerCase() === "hoteltype"
                      ? hotels
                      : []
                  }
                />
              ) : currQuestion.type == "number" ? (
                <NumberInputType
                  answerIndex={questionIndex}
                  handleAnswerChange={handleAnswerChange}
                  answers={answers}
                  unit={currQuestion.unit}
                  defaultValue={currQuestion.defaultValue}
                  percentage={currQuestion.percentage}
                />
              ) : currQuestion.type == "address" ? (
                <AddressInputType
                  answerIndex={questionIndex}
                  handleAnswerChange={handleAnswerChange}
                  answers={answers}
                />
              ) : currQuestion.type == "date" ? (
                <DateInputType
                  answerIndex={questionIndex}
                  handleAnswerChange={handleAnswerChange}
                  answers={answers}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        );
      })}

      {isEdit && selectedAccItem != "Event" ? (
        <div className="mt-30">
          <p className="font_12_600 color_7e">Carbon Accounting Start Date</p>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                value={baseYear}
                onChange={(new_date) => {
                  handleBaseYearChange(new_date);
                }}
                slotProps={{ textField: { size: "small" } }}
                views={baseYearAccount ? ["year"] : ["month", "year"]}
                sx={{ width: "100%" }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>
      ) : (
        ""
      )}

      {baseYearField ? (
        <div className="mt-50">
          <p className="purple_label">Recommended</p>
          <div className="row mt-20">
            <div className="col-md-6 col-lg-5 col-5">
              <div className="d-flex align-items-center">
                <p className="subheading_1 mb-8">
                  DO YOU WANT TO ACCOUNT A BASE YEAR?
                </p>
                <CustomToolTip
                  title={
                    "A base year refers to a specific year against which you compare your GHG emissions in the following years. It serves as a reference point for tracking and measuring progress in reducing emissions over time."
                  }
                />
              </div>
              <div className="SideDrawer_toggle_section d-flex mt-2 align-items-center">
                <Switch
                  onChange={(event) => {
                    handleIsBaseYearChange(event, event.target.checked);
                  }}
                  className="side_drawer-toggle"
                  defaultValue={baseYearAccount}
                />

                <div className="">
                  <p className="f-12 fw-600 ml-4">
                    {baseYearAccount ? "Yes" : "No"}
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-5 col-5 sidebar_baseyear_select">
              <p className="subheading_1 mb-2 ml-8">
                {baseYearAccount === true
                  ? "Select your baseyear"
                  : "CARBON ACCOUNTING START DATE"}
              </p>
              <div className="mt-2">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      onChange={(new_date) => {
                        handleBaseYearChange(new_date);
                      }}
                      slotProps={{ textField: { size: "small" } }}
                      views={baseYearAccount ? ["year"] : ["month", "year"]}
                      sx={{ width: "100%" }}
                    />
                  </DemoContainer>
                  {/* <p className="helper_text mt-2 ml-8">
                              This name will be used to refer to this item in
                              the future
                            </p> */}
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {itemSecondQuestionSet.length > 0 && (
        <div className="border-top_ef mt-32 pt-32">
          {selectedAccItem !== "Location" &&
            itemSecondQuestionSet.map((questionSet, index) => {
              return (
                <div className="mb-32 row mx-0" key={index}>
                  <p className="subheading_1 px-0">{questionSet.title}</p>
                  <div
                    className={`col-md-8 col-lg-5 w-440px ${
                      questionSet.isNoBg ? "" : "SideDrawer_card_background"
                    }`}
                  >
                    <div className="">
                      {questionSet.questions.map((currQuestion, subIndex) => {
                        let dropDownList = [];
                        if (currQuestion.type === "select") {
                          if (
                            currQuestion.name === "DoubleAccountingLocation"
                          ) {
                            dropDownList = locationList;
                          }
                        }
                        return (
                          <div className="" key={subIndex}>
                            <p className="f-14 fw-400 mt-20">
                              {currQuestion.title}
                            </p>
                            {currQuestion.type === "toggle" ? (
                              <div className="SideDrawer_toggle_section d-flex align-items-center mt-10">
                                <div>
                                  {" "}
                                  <Switch
                                    onChange={(event) => {
                                      handleToggleChange(
                                        event.target.checked,
                                        index,
                                        subIndex
                                      );
                                    }}
                                    defaultChecked
                                    className="side_drawer-toggle"
                                  />
                                </div>
                                <div>
                                  <p className="f-12 fw-600 ml-4">
                                    {answers[
                                      getCurrentQuestionIndex(index, subIndex)
                                    ]
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </div>
                            ) : currQuestion.type === "select" ? (
                              <div className=" mt-10">
                                <SelectInputType
                                  value={
                                    answers[
                                      getCurrentQuestionIndex(index, subIndex)
                                    ]
                                  }
                                  answerIndex={getCurrentQuestionIndex(
                                    index,
                                    subIndex
                                  )}
                                  selectedValue={
                                    answers[
                                      getCurrentQuestionIndex(index, subIndex)
                                    ]
                                  }
                                  data={currQuestion.data}
                                  options={dropDownList}
                                  handleAnswerChange={handleAnswerChange}
                                  questionNo={getCurrentQuestionIndex(
                                    index,
                                    subIndex
                                  )}
                                  answers={answers}
                                  setAnswers={setAnswers}
                                />
                                <p className={"helper_text fw-400 mt-10"}>
                                  {currQuestion.helperText}
                                </p>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}

      <div className="d-flex justify-content-end align-items-center mt-20 mb-40">
        {isEdit ? (
          <ButtonComp
            status={5}
            onClick={() => handleSubmit()}
            className="h-40px"
          >
            Edit
          </ButtonComp>
        ) : (
          <ButtonComp
            status={5}
            onClick={() => handleSubmit()}
            className="h-40px"
          >
            Submit
          </ButtonComp>
        )}
      </div>
    </div>
  );
}

export default DetailTab;
